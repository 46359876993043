import client from "apis/config";

export const getAllConfigurationsAPI = async () => {
  return await client.get(`configurations/data`);
};
export const getApplicationsDataAPI = async () => {
  return await client.get(`applications/data`);
};
export const getSCHApplicationMetaData = async () => {
  return await client.get(`scholarship/data`);
};
export const getTCCApplicationMetaData = async () => {
  return await client.get(`tcc/data`);
};
export const getFSMApplicationMetaData = async () => {
  return await client.get(`facilities/meta`);
};
export const getSccConductCaseMetaData = async () => {
  return await client.get(`stages?applicationType=scc`);
};
export const getSccDocumentConfigurations = async (page,limit=10) => {
  return await client.get(`document-generator/configurations?page=${page}&limit=${limit}`);
};
export const addSccDocumentConfigurations = async (values) => {
  return await client.post(`document-generator/configurations`,values);
};
export const editSccDocumentConfigurations = async (id,params) => {
  return await client.put(`document-generator/configurations/${id}`,params);
};
export const deleteSccDocumentConfigurations = async (id) => {
  return await client.delete(`document-generator/configurations/${id}`);
};
export const getStageConfigDataAPI = async (
  id,
  queryParams,
  isFSMStageConfig = false
) => {
  if (!isFSMStageConfig) {
    return await client.get(`stages/configuration/${id}` + queryParams);
  }
  return await client.get(
    `stages/configuration/facilities/${id}` + queryParams
  );
};
export const addStageDataAPI = async (item, isFSMStageConfig = false) => {
  if (!isFSMStageConfig) {
    return await client.post(`stages/configuration`, item);
  }
  return await client.post(`stages/configuration/facilities`, item);
};
export const editStageDataAPI = async (
  id,
  params,
  isFSMStageConfig = false
) => {
  if (!isFSMStageConfig) {
    return await client.put(`stages/configuration/${id}`, params);
  }
  return await client.put(`stages/configuration/facilities/${id}`, params);
};
export const deleteStageDataAPI = async (id, isFSMStageConfig = false) => {
  if (!isFSMStageConfig) {
    return await client.delete(`stages/configuration/${id}`);
  }
  return await client.delete(`stages/configuration/facilities/${id}`);
};
