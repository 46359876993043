export const GET_RULES = "GET_RULES";
export const ADD_RULES = "ADD_RULES";
export const UPDATE_RULES = "UPDATE_RULES";
export const DELETE_RULES = "DELETE_RULES";
export const GET_RULES_CONFIG = "GET_RULES_CONFIG";
export const ADD_RULES_CONFIG = "ADD_RULES_CONFIG";
export const UPDATE_RULES_CONFIG = "UPDATE_RULES_CONFIG";
export const DELETE_RULES_CONFIG = "DELETE_RULES_CONFIG";

export const _getRulesAction = (data) => (dispatch) =>
  dispatch({
    type: GET_RULES,
    payload: data,
  });

export const _addRulesAction = (data) => (dispatch) =>
  dispatch({
    type: ADD_RULES,
    payload: data,
  });

export const _updateRulesAction = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_RULES,
    payload: data,
  });

export const _deleteRulesAction = (id) => (dispatch) =>
  dispatch({
    type: DELETE_RULES,
    payload: id,
  });

export const _getRulesConfigAction = (data) => (dispatch) =>
  dispatch({
    type: GET_RULES_CONFIG,
    payload: data,
  });

export const _addRulesConfigAction = (data) => (dispatch) =>
  dispatch({
    type: ADD_RULES_CONFIG,
    payload: data,
  });

export const _updateRulesConfigAction = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_RULES_CONFIG,
    payload: data,
  });

export const _deleteRulesConfigAction = (id) => (dispatch) =>
  dispatch({
    type: DELETE_RULES_CONFIG,
    payload: id,
  });
