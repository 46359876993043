import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Spin,
  Tooltip,
  Form,
  Select,
  Image,
  DatePicker,
  Input,
  Col,
  Row,
} from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import {
  addNewSccOffenseManagement,
  deleteSccOffenseManagement,
  fetchAllSccOffenseManagementRequests,
  handleProcessConductCase,
  updateSccOffenseManagement,
} from "./api";
import SccOffenseManagementForm from "./SccOffenseManagementForm";
import moment from "moment";
import { useSelector } from "react-redux";
import { fetchAllSccCategories } from "../scc-Categories/api";
import { fetchAllSccStandards } from "../scc-standards/api";
import { generateUniqueColumnKey } from "utils/helpers/helpers";
import ExportCaseModal from "./ExportCaseModal";
import {
  fetchAllIndicators,
  fetchAllSccOffenseIndicators,
} from "../scc-offense-indicators/api";
import { _terms } from "utils/constants";
import { getAllYearsAndHisTerms } from "../configurations/api";
import { getGeneralConfigurations } from "apis/general-configuration/generalConfigurationapi";
import { formatedDate } from "utils/helpers/helpers";
import "./caseStyle.css";

const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const SccOffenseBehaviourManagement = (props) => {
  const _caseType = "SCC";
  const [exportCaseForm] = Form.useForm();
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _allschools = useSelector((state) => state.metaData.schools);
  const _years = useSelector((state) => state.metaData.SCC_years);

  const _caseStatuses = useSelector(
    (state) => state.metaData.applicationStatuses
  );

  const _caseStages = useSelector((state) => state.metaData.stages)?.filter(
    (caseType) => caseType.applicationType === _caseType
  );

  const reportCasePermission = useSelector(
    (state) => state.login?.userInfo?.reportCasePermission
  );

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [recordId, setRecordId] = useState("");
  const [sccBehIndicatorForm] = Form.useForm();

  const [schools, setSchools] = useState(_allschools);
  const [categories, setCategories] = useState([]);
  const [standards, setStandards] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [isTermOpen, setIsTermOpen] = useState(false);

  const [categoryId, setCategoryId] = useState(null);
  const [standardId, setStandardId] = useState(null);
  const [user, setUserData] = useState(null);
  const [teacher, setTeacherData] = useState(null);
  const [employee, setEmployeeData] = useState(null);
  const [caseStageStatuses, setCaseStageStatuses] = useState([]);

  const navigationParams = props.history.location?.state;

  const dashboardCampus = navigationParams?.dashboardCampus;
  const dashboardTerm = navigationParams?.dashboardTerm;
  const dashboardYear = navigationParams?.dashboardYear;
  const dashboardCaseStage = navigationParams?.dashboardCaseStage;
  const dashboardCaseStatus = navigationParams?.dashboardCaseStatus;
  const dashboardGroupStatus = navigationParams?.dashboardGroupStatus;
  const fromDashboard = navigationParams?.fromDashboard;

  const [filters, setFilters] = useState({
    campus: dashboardCampus ? dashboardCampus : "",
    school: "",
    category: "",
    standard: "",
    indicator: "",
    caseStage: dashboardCaseStage ? dashboardCaseStage : "",
    caseStageStatus: "",
    caseStatus: dashboardCaseStatus ? dashboardCaseStatus : "",
    year: dashboardYear ? dashboardYear : "",
    term: dashboardTerm ? dashboardTerm : "",
  });

  const [search, setSearch] = useState(
    fromDashboard && dashboardGroupStatus ? dashboardGroupStatus : ""
  );
  const [apiKey, setApiKey] = useState("");

  const [showExportCaseModal, setShowExportCaseModal] = useState(false);
  const [exportCasefilters, setExportCasefilters] = useState(null);

  useEffect(() => {
    if (fromDashboard) {
      const _stageStatuses = _caseStages.find(
        (caseStage) => caseStage.slug === dashboardCaseStage
      );
      if (_stageStatuses) {
        setCaseStageStatuses(_stageStatuses?.statuses);
      }
    }
  }, []);

  const getStageStatuses = useCallback(
    (slug) => {
      const _stageStatuses = _caseStages.find(
        (caseStage) => caseStage.slug === slug
      );
      if (_stageStatuses) {
        setCaseStageStatuses(_stageStatuses?.statuses);
      }
    },
    [caseStageStatuses, setCaseStageStatuses, dashboardCaseStage]
  );

  useEffect(() => {
    fetchSccCategories();
    fetchSccStandards();
    fetchSccIndicators();
  }, []);
  useEffect(() => {
    fetchAllYearsAndTerms();
  }, []);

  const fetchSccCategories = async () => {
    try {
      const response = await fetchAllSccCategories({ limit: 1000 });
      if (response.status === 200) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchSccStandards = async () => {
    try {
      const response = await fetchAllSccStandards({ limit: 1000 });
      if (response.status === 200) {
        setStandards(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchSccIndicators = async () => {
    try {
      const response = await fetchAllIndicators();
      if (response.status === 200) {
        setIndicators(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchAllYearsAndTerms = async () => {
    try {
      setLoading(true);
      const response = await getAllYearsAndHisTerms();
      if (response.status === 200) {
        const responseData = response.data.data;
        const hasOpenTerm =
          responseData.filter(
            (term) =>
              term.firstTermStatus === "open" ||
              term.secondTermStatus === "open"
          ).length > 0;
        setIsTermOpen(hasOpenTerm);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  useEffect(() => {
    getApiKeyFromGeneralConfigurations();
  }, []);
  const getApiKeyFromGeneralConfigurations = async () => {
    try {
      const response = await getGeneralConfigurations();
      if (response.status == 200) {
        const data = response.data.data;
        setApiKey(data.api_key);
      }
    } catch (error) {
      await requestErrorHandel({ error: error });
    }
  };

  const handleChangeCaseStageStatus = (slug) => {
    setFilters({ ...filters, caseStage: slug });
    getStageStatuses(slug);
  };

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            sccBehIndicatorForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          disabled={!isTermOpen || !reportCasePermission}
          headerTitle={"Conduct Case Management"}
          headerSubTitle={"Conduct Case Management"}
          headerBtnTitle={"Create New Case"}
        />
        <Row gutter={10}>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter by campus"
                onChange={(e) => handleCampusChange(e)}
                // bordered={false}
                defaultValue={filters.campus || undefined}
              >
                <Option value={"#"}>All</Option>
                {_campuses?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter by school"
                onChange={(e) => setFilters({ ...filters, school: e })}
                defaultValue={filters.school || undefined}
              >
                <Option value={""}>All</Option>
                {schools?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter by category"
                onChange={(e) => handleCategoryChange(e)}
                bordered={false}
                defaultValue={filters.category || undefined}
              >
                <Option value={"#"}>All</Option>

                {categories?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter by standard"
                onChange={(e) => handleChangeStandards(e)}
                bordered={false}
                defaultValue={filters.standard || undefined}
              >
                {categoryId &&
                  standards
                    .filter((el) => Number(el.categoryId) === categoryId)
                    .map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter by indicator"
                onChange={(e) => setFilters({ ...filters, indicator: e })}
                bordered={false}
                defaultValue={filters.indicator || undefined}
              >
                {standardId &&
                  indicators
                    .filter((el) => Number(el.standardId) === standardId)
                    .map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter by year"}
                bordered={false}
                onChange={(e) => setFilters({ ...filters, year: e })}
                value={filters?.year || undefined}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_years?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter by term"}
                bordered={false}
                onChange={(e) => setFilters({ ...filters, term: e })}
                value={filters?.term || undefined}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_terms?.map((el, i) => (
                  <Option key={i} value={el.value}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter by case stage"}
                bordered={false}
                defaultValue={filters?.caseStage || undefined}
                onChange={(e) => handleChangeCaseStageStatus(e)}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_caseStages?.map((el, i) => (
                  <Option key={i} value={el.slug}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter by case stage status"}
                bordered={false}
                defaultValue={filters?.caseStageStatus || undefined}
                onChange={(e) => setFilters({ ...filters, caseStageStatus: e })}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {caseStageStatuses?.map((el, i) => (
                  <Option key={i} value={el.code}>
                    {el.status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter by case status"}
                bordered={false}
                onChange={(e) => setFilters({ ...filters, caseStatus: e })}
                value={filters?.caseStatus || undefined}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_caseStatuses?.map((el, i) => (
                  <Option key={i} value={el.code}>
                    {el.externalStatus}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Input
                placeholder="Search"
                defaultValue={search}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={"middle"} justify={"end"}>
          <Col>
            <Button
              type="primary"
              onClick={onExportModalOpen}
              className="ant__primary__button"
            >
              Export to Excel
            </Button>
          </Col>
        </Row>
        <br />
      </>
    );
  };

  const onExportModalOpen = () => {
    const _filters = {
      campus: filters.campus,
      school: filters.school,
      category: filters.category,
      standard: filters.standard,
      indicator: filters.indicator,
      term: filters.term,
      year: filters.year,
      stage: filters.caseStage,
      stageStatus: filters.caseStageStatus,
      status: filters.caseStatus,
      stageStatuses: caseStageStatuses,
    };
    setExportCasefilters(_filters);
    setShowExportCaseModal(true);
  };

  useEffect(() => {
    if (fromDashboard && dashboardCampus) {
      setSchools(_allschools.filter((el) => el.campusId === dashboardCampus));
    } else return;
  }, []);
  useEffect(() => {
    getAllSccOffenseManagementRequests();
  }, [page, filters, search]);

  const getAllSccOffenseManagementRequests = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSccOffenseManagementRequests({
        page: page,
        campus: filters.campus,
        category: filters.category,
        indicator: filters.indicator,
        standard: filters.standard,
        school: filters.school,
        year: filters.year,
        term: filters.term,
        search: search,
        stage: filters.caseStage,
        stageStatus: filters.caseStageStatus,
        status: filters.caseStatus,
      });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addSccOffenseMangenetRequest = async (data) => {
    try {
      setLoading(true);
      const response = await addNewSccOffenseManagement({ data: data });
      if (response.status === 200) {
        successMessage({
          message: "Case has been created successfully",
        });
        await getAllSccOffenseManagementRequests();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteSccOffenseMangementRequest = async (id) => {
    try {
      const data = {
        caseId: id,
        action: "delete",
        notes: "",
      };
      setLoading(true);

      const response = await handleProcessConductCase({ payload: data });
      if (response.status === 200) {
        successMessage({
          message: "Case deleted successfully",
        });
        await getAllSccOffenseManagementRequests();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const _teacher = {
      firstName: teacher?.FIRSTNAME,
      middleName: teacher?.MIDDLENAME || "",
      lastName: teacher?.LASTNAME || "",
      campusCode: teacher?.CAMPUS,
      costCenter: teacher?.COSTCENTER,
      category: teacher?.CATEGORY,
      department: teacher?.DEPARTMENT ?? "",
      employeeId: teacher?.EMPLOYEEID,
      email: teacher?.Email,
    };
    const _reportedBy = {
      firstName: employee?.FIRSTNAME,
      middleName: employee?.MIDDLENAME || "",
      lastName: employee?.LASTNAME || "",
      campusCode: employee?.CAMPUS,
      costCenter: employee?.COSTCENTER,
      category: employee?.CATEGORY,
      department: employee?.DEPARTMENT ?? "",
      employeeId: employee?.EMPLOYEEID,
      email: employee?.Email,
    };
    const data = {
      ...values,
      studentNumber: user?.STUDENT_NUMBER.toString(),
      teacher: _teacher,
      reportedBy: _reportedBy,
    };

    await addSccOffenseMangenetRequest(data);
  };

  const onViewDetials = (record) => {
    props.history.push({
      pathname: `scc/conduct-case-details`,
      state: { conductCaseId: record.id },
    });
  };
  // Handlers for selecting campus and category
  const handleCampusChange = (e) => {
    if (e === "#") {
      setFilters({ ...filters, campus: "" });
      setSchools(_allschools);
    } else {
      const campusId = parseInt(e);
      setFilters({ ...filters, campus: campusId });
      setSchools(_allschools.filter((el) => el.campusId === campusId));
    }
  };

  const handleCategoryChange = (e) => {
    if (e === "#") {
      setFilters({ ...filters, category: "", standard: "" });
      setCategoryId(null);
      setStandardId(null);
    } else {
      const categoryId = parseInt(e);
      setFilters({ ...filters, category: categoryId });
      setCategoryId(categoryId);
    }
  };

  const handleChangeStandards = (e) => {
    const standardId = parseInt(e);
    setFilters({ ...filters, standard: standardId });
    setStandardId(standardId);
  };

  const setUser = (user) => {
    setUserData(user);
  };
  const setTeaacher = (user) => {
    setTeacherData(user);
  };
  const setEmployee = (employee) => {
    setEmployeeData(employee);
  };

  const getBehaviorClass = (type) => {
    switch (type) {
      case "offense":
        return "negative-behavior";
      case "positive":
        return "positive-behavior";
      default:
        return "distinguished-behavior";
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Case No",
              dataIndex: "title",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Student",
              // dataIndex: "studentNumber",
              key: generateUniqueColumnKey(),
              render: (record) => {
                return (
                  <div>
                    <p>
                      {record?.student?.firstName} {record?.student?.middleName}{" "}
                      {record?.student?.lastName}
                    </p>
                    <p>{record?.student?.campus}</p>
                    <p>{record?.student?.studentNumber}</p>
                  </div>
                );
              },
            },
            {
              title: "Indicator",
              key: generateUniqueColumnKey(),
              render: (record) => <p>{record?.indicator?.title}</p>,
            },

            {
              title: "Type",
              key: generateUniqueColumnKey(),
              render: (record) => {
                return (
                  <div>
                    <span className={getBehaviorClass(record?.indicator?.type)}>
                      {record?.indicator?.type === "offense"
                        ? "Negative Behavior"
                        : record?.indicator?.type === "positive"
                        ? "Positive Behavior"
                        : "Distinguished Behavior"}
                    </span>
                  </div>
                );
              },
            },
            {
              title: "Occurrence Date",
              // dataIndex: "campusCode",
              key: generateUniqueColumnKey(),
              render: (record) => formatedDate(record?.dateOfOccurrence),
            },

            {
              title: "Stage",
              key: generateUniqueColumnKey(),
              render: (record) => <p>{record?.currentStage?.title}</p>,
            },
            {
              title: "Stage Status",
              key: generateUniqueColumnKey(),
              render: (record) => (
                <p>{record?.currentStage?.status?.internalStatus}</p>
              ),
            },
            {
              title: "Status",
              // dataIndex: "status",
              key: generateUniqueColumnKey(),
              render: (record) => <p>{record?.status?.internalStatus}</p>,
            },

            {
              title: "Action",
              dataIndex: "",
              key: generateUniqueColumnKey(),

              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Show Details">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EyeOutlined />}
                          onClick={() => onViewDetials(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() =>
                        handleDeleteSccOffenseMangementRequest(item.id)
                      }
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          // visibility={true}
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Conduct Case`}
        >
          <SccOffenseManagementForm
            onSubmit={onSubmit}
            formName={sccBehIndicatorForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            getUser={(user) => setUser(user)}
            getTeacher={(teacher) => setTeaacher(teacher)}
            getEmployee={(employee) => setEmployee(employee)}
            apiKey={apiKey}
          />
        </CustomModal>
        <CustomModal
          visibility={showExportCaseModal}
          handleCancel={() => setShowExportCaseModal(false)}
          handleOk={() => setShowExportCaseModal(false)}
          // title={`Export ${serviceType} Applications`}
          title={"Export Cases"}
          className="export_application_modal"
        >
          <ExportCaseModal
            formName={exportCaseForm}
            exportCasefilters={exportCasefilters}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default SccOffenseBehaviourManagement;
