import client from "apis/config";

export const fetchAllVats = async ({ limit = "10", page }) => {
  return await client.get(`/vat?limit=${limit}&page=${page}`);
};

export const addNewVat = async ({ data }) => {
  return await client.post("/vat", data);
};

export const updateVat = async ({ id, data }) => {
  return await client.put(`/vat/${id}`, data);
};

export const deleteVat = async ({ id }) => {
  return await client.delete(`/vat/${id}`);
};
