import {
  CalendarOutlined,
  EditOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import * as React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";

const ReviewLogs = ({ data }) => {
  const renderStageDescription = (oldStatus, newStatus, meta) => {
    if (meta?.action === "cancel_acceptance") {
      return "Acceptance Cancelled";
    }
    if (meta.action === "service_amount_updated") {
      return "Service Amount Updated";
    }
    switch (oldStatus) {
      // TRP APPLICATIONS
      case "SG700":
        return `New Application - Submitted`;
      case "SG710":
        return `Under Review`;
      case "SG720":
        return `Pending Amendment`;
      case "SG760":
        return `ERP Integrated`;
      case "SG750":
        return `SIS Integrated`;
      case "SG740":
        return `Accepted`;
      case "SG780":
        return `Approval initialized`;
      case "SG770":
        return `DMS Integrated`;

      // ASR APPLICATIONS
      case "SG100":
        return `New Application - Submitted`;
      case "SG110":
        return `Under Review`;
      case "SG120":
        return `Pending Amendment`;
      case "SG160":
        return `ERP Integrated`;
      case "SG150":
        return `SIS Integrated`;
      case "SG140":
        return `Accepted`;
      case "SG180":
        return `Approval initialized`;
      case "SG170":
        return `DMS Integrated`;

      // TFD APPLICATIONS
      case "SG400":
        return `New Application - Submitted`;
      case "SG410":
        return `Under Review`;
      case "SG420":
        return `Pending Amendment`;
      case "SG430":
        return `Amendment Review`;
      case "SG440":
        return `Waiting Confirmation`;
      case "SG480":
        return `Waiting For Approval Initialization`;
      case "SG490":
        return `Submitted For Approval`;
      case "SG500":
        return `Cancelled`;

      // UPD APPLICATIONS
      case "SG1000":
        return `New Application - Submitted`;
      case "SG1010":
        return `Under Review`;
      case "SG1020":
        return `Pending Amendment`;
      case "SG1030":
        return `Amendment Review`;
      case "SG1040":
        return `Waiting Acceptance`;
      case "SG1080":
        return `Waiting For Approval Initialization`;
      case "SG1090":
        return `Submitted For Approval`;
      case "SG1100":
        return `Cancelled`;

      // SCH application
      case "SG1300":
        return `New Application - Submitted`;
      case "SG1320":
        return "Under Review";
      case "SG1340":
        return "Pending Amendment";
      case "SG1360":
        return "Amendment Review";
      case "SG1380":
        if (oldStatus === "SG1380" && newStatus === "SG1400") {
          return "Accepted";
        } else if (oldStatus === "SG1380" && newStatus === "SG1420") {
          return "Rejected";
        }
      case "SG1400":
        return "Accepted";
      case "SG1420":
        return "Not Accepted";
      case "SG1440":
        return "Cancelled";
      case "SG1460":
        return "Inactive";
      case "SG1480":
        if (oldStatus === "SG1480" && newStatus === "SG1500") {
          return "Evaluated";
        } else if (oldStatus === "SG1480" && newStatus === "SG1520") {
          return "Sent for committe approval";
        }
      case "SG1500":
        return "Sent for committee approval";
      case "SG1520":
        return "Sent for committee approval";
      case "SG1540":
        return "Cancelled";
      case "SG1560":
        return "Inactive";
      case "SG1580":
        return "Waiting Approval";
      case "SG1600":
        if (oldStatus === "SG1600" && newStatus === "SG1620") {
          return "Approved";
        } else if (oldStatus === "SG1600" && newStatus === "SG1640") {
          return "Rejected";
        } else if (oldStatus === "SG1600" && newStatus === "SG1660") {
          return "Cancelled";
        }
      case "SG1620":
        return "Approved";
      case "SG1640":
        return "Rejected";
      case "SG1660":
        return "Cancelled";
      case "SG1680":
        return "Inactive";
      case "SG1700":
        if (oldStatus === "SG1700" && newStatus === "SG1720") {
          return "Pending approval";
        } else if (oldStatus === "SG1700" && newStatus === "SG1740") {
          return "Approved";
        } else if (oldStatus === "SG1600" && newStatus === "SG1660") {
          return "Rejected";
        }
        return "Pending Reward Assigning";
      case "SG1720":
        return "Pending approval";
      case "SG1740":
        return "Approved";
      case "SG1760":
        return "Rejected";
      case "SG1780":
        return "Cancelled";

      // TCC application
      case "SG2000":
        return `New Application - Submitted`;
      case "SG2020":
        return "Under Review";
      case "SG2040":
        return "Pending Amendment";
      case "SG2060":
        return "Amendment Review";
      case "SG2080":
        if (oldStatus === "SG2080" && newStatus === "SG2100") {
          return "Accepted";
        } else if (oldStatus === "SG2080" && newStatus === "SG2120") {
          return "Rejected";
        }
      case "SG2080":
        return "Accepted";
      case "SG2100":
        return "Not Accepted";
      case "SG2120":
        return "Cancelled";
      case "SG2140":
        return "Inactive";
      case "SG2180":
        if (oldStatus === "SG2180" && newStatus === "SG2200") {
          return "Accepted";
        } else if (oldStatus === "SG2180" && newStatus === "SG2220") {
          return "Declined";
        }
      case "SG2180":
        return "Waiting approval";

      default:
        return oldStatus;
    }
  };
  return (
    <div>
      <h6>
        {renderStageDescription(data.oldStatus, data.newStatus, data.meta)}
      </h6>
      <div className="rh-log-item">
        {data.meta?.action === "service_amount_updated" && (
          <div>
            <p>Old Amount: {data.meta.oldAmount} AED</p>
            <p>New Amount: {data.meta.newAmount} AED</p>
          </div>
        )}
        {data.meta &&
        (data.meta?.serviceFeeAmount !== null ||
          data.meta?.serviceFeeAmount !== undefined) ? (
          <p>Service Fee Amount: {data.meta.serviceFeeAmount} AED</p>
        ) : null}
        <div className="rh-log-row">
          <CalendarOutlined className="rh-log-icon" />
          <p>
            Date of Action :{" "}
            {formateDateWithMoment(data.createdAt, "DD MMM YYYY hh:mm A")}
          </p>
        </div>
        <div className="rh-log-row">
          <UserOutlined className="rh-log-icon" />
          <p>Action Creator : {data.user?.userName}</p>
        </div>
        {/* application fees starts */}
        {data.oldStatus === "SG008" ? (
          <>
            <p>
              Payment Date :
              <b>
                {formateDateWithMoment(
                  data?.meta.dateTime,
                  "DD MMM YYYY hh:mm A"
                )}
              </b>
            </p>
            <p>
              {" "}
              Amount :<b>{data?.meta.amount} AED</b>
            </p>
          </>
        ) : (
          ""
        )}
        {/* application fee ends */}
        {data.meta?.Data && (
          <div>
            {typeof data.meta.Data === "string" ? (
              <div className="rh-log-row">
                <InfoCircleOutlined className="rh-log-icon" />
                <p>{data.meta.Data}</p>
              </div>
            ) : (
              <div>
                <div className="rh-log-row">
                  <InfoCircleOutlined className="rh-log-icon" />
                  <p>
                    ERP Update Message:{" "}
                    {data.meta.Data.ERPUpdateMessage || "None"}
                  </p>
                </div>
                <div className="rh-log-row">
                  <InfoCircleOutlined className="rh-log-icon" />

                  <p>
                    SIS Update Message:{" "}
                    {data.meta.Data.SISUpdateMessage || "None"}
                  </p>
                </div>
                <div className="rh-log-row">
                  <InfoCircleOutlined className="rh-log-icon" />

                  <p>StudentID: {data.meta.Data.StudentID || "None"}</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewLogs;
