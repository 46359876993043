import client from "apis/config";
const endPointUrl = "scc/levels";

export const fetchAllSccOffenseLevels = async ({ limit = "10", page }) => {
  return await client.get(`/${endPointUrl}?limit=${limit}&page=${page}`);
};

export const addNewSccOffenseLevel = async ({ data }) => {
  return await client.post(`/${endPointUrl}`, data);
};

export const updateSccOffenseLevel = async ({ id, data }) => {
  return await client.put(`/${endPointUrl}/${id}`, data);
};

export const deleteSccOffenseLevel = async ({ id }) => {
  return await client.delete(`/${endPointUrl}/${id}`);
};
