import { Button, Form, Input, Select, Row, Col, Checkbox } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const { Option } = Select;
const EducationSystemForm = ({
  onSubmit,
  loading,
  formName,
  valuesForEdit,
}) => {
  const _grades = useSelector((state) => state.metaData.grades);
  const [isIBSystem, setIsIBSystem] = useState(false);
  const [preIsIBSystem, setPreIsIBSystem] = useState(true);

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} required`,
      },
    ];
  };

  useEffect(() => {
    if (valuesForEdit !== null) {
      setPreIsIBSystem(valuesForEdit.isIBSystem);
    } else {
      setPreIsIBSystem(true);
    }
  }, [valuesForEdit]);

  useEffect(() => {
    setIsIBSystem(preIsIBSystem);
  }, [preIsIBSystem]);

  return (
    <div>
      <Form layout="vertical" onFinish={onSubmit} form={formName}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"title"}
              label="Title (EN)"
              rules={setRules("Title")}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name={"titleAr"}
              label="Title (AR)"
              rules={setRules("Title Ar")}
            >
              <Input type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 20]}>
          <Col md={12}>
            <Form.Item
              name={"isIBSystem"}
              label=""
              valuePropName="checked"
              initialValue={isIBSystem}
            >
              <Checkbox
                value={isIBSystem}
                onChange={() => setIsIBSystem(!isIBSystem)}
                checked={isIBSystem}
              >
                Is IB System?
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {_grades.map((item, index) => {
          return (
            <div key={index}>
              <Row gutter={10} key={index}>
                <Col md={!isIBSystem ? 8 : 12}>
                  <Form.Item
                    name={`points_${item.id}`}
                    label={`${item.code}`}
                    rules={setRules("Points")}
                    initialValue={0}
                  >
                    <Input
                      type="number"
                      style={{ width: "100%" }}
                      placeholder={"Enter points"}
                    />
                  </Form.Item>
                </Col>

                {!isIBSystem && (
                  <>
                    <Col md={8}>
                      <Form.Item
                        name={`label_${item.id}`}
                        label="IB Equivalent Grade (EN)"
                        rules={setRules("IB Equivalent Grade (EN)")}
                      >
                        <Input type="text" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item
                        name={`labelAr_${item.id}`}
                        label="IB Equivalent Grade (AR)"
                        rules={setRules("IB Equivalent Grade (AR)")}
                      >
                        <Input type="text" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          );
        })}

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default EducationSystemForm;
