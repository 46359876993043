import React, { useEffect, useState } from "react";

import { getAdmissionStatuses } from "apis/statuses-apis/appandStageStatuses";
import { requestErrorHandel } from "utils/helpers/helpers";
import { Button, Card, Row, Spin } from "antd";
import NotificationTriger from "components/notifications-trigers/NotificationTriger";
import { updategetAdmissionStatusesNotification } from "apis/statuses-apis/appandStageStatuses";
import { LoadingOutlined } from "@ant-design/icons";
import { successMessage } from "utils/helpers/helpers";
import { updateStageNotificationTemplateAPI } from "apis/statuses-apis/appandStageStatuses";
// import { NotificationManager } from "react-notifications";

const SccNotificationsTriggers = (props) => {
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [admissionStatuses, setAdmissionStatuses] = useState([]);

  const serviceName = "SCC";

  useEffect(() => {
    fetchAdmissionStatuses();
  }, []);
  const fetchAdmissionStatuses = async () => {
    try {
      setLoading(true);
      const response = await getAdmissionStatuses({
        type: "stages",
        applicationType: serviceName,
      });
      if (response.status === 200) {
        setAdmissionStatuses(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onSaveStatusesNotification = async () => {
    try {
      setIsDirty(false);
      const data = {
        notifications: admissionStatuses,
      };
      const response = await updategetAdmissionStatusesNotification({
        data: data,
      });
      if (response.status === 200) {
        successMessage({
          message: "Notifications trigers updated successfully ",
        });
        fetchAdmissionStatuses();
      }
    } catch (error) {
      await requestErrorHandel({ error: error });
    }
  };

  const toggleCheckbox = (index, type, newValue) => {
    setIsDirty(true);
    let _newArr = [...admissionStatuses];
    if (type === "parent") {
      _newArr[index].parentNotification = newValue;
    } else {
      _newArr[index].teamNotification = newValue;
    }
    setAdmissionStatuses(_newArr);
  };
  const onUpdateTemplate = async (formValues, onSubmit) => {
    try {
      setLoading(true);
      const response = await updateStageNotificationTemplateAPI(formValues);
      if (response.status === 200) {
        setLoading(false);
        onSubmit && onSubmit();
        successMessage({ message: "Notification template updated" });
        fetchAdmissionStatuses();
      } else throw response;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };
  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  return (
    <div>
      <Card className="antCardBorder antCard">
        <Row justify="space-between" align="middle">
          <h5>SCC Notification Triggers</h5>
          <Button
            type="primary"
            style={{ width: "140px" }}
            onClick={onSaveStatusesNotification}
            disabled={!isDirty}
          >
            Update
          </Button>
        </Row>
      </Card>
      <Spin spinning={loading} indicator={loader}>
        <Card style={{ overflow: "auto" }}>
          <div
            className="notificationTriggerCardWrapper"
            style={{
              display: "flex",
              // width: "100%",
              // marginBottom: "20px",
              backgroundColor: "#f7f6ff",
              height: "50px",
              alignItems: "center",
              padding: "5px",
              borderRadius: "5px",
              width: "100%",
            }}
          >
            <div className="notificationTableColumn">
              <b>Identifier</b>
            </div>
            <div className="notificationTableColumn">
              <b>Stage</b>
            </div>
            <div className="notificationTableColumn">
              <b>Dashboard</b>
            </div>
            <div className="notificationTableColumn1">
              <b>Internal Status</b>
            </div>
            <div className="actionTableColumn">
              <b>Parent</b>
            </div>
            <div className="actionTableColumn">
              <b>Team</b>
            </div>
            <div className="actionTableColumn">
              <b>Template</b>
            </div>
          </div>
          {admissionStatuses.map((item, i) => {
            return (
              <div key={item.code} className="stause">
                <NotificationTriger
                  item={item}
                  index={i}
                  toggleCheckbox={toggleCheckbox}
                  onUpdateTemplate={onUpdateTemplate}
                />
              </div>
            );
          })}
        </Card>
      </Spin>
    </div>
  );
};

export default SccNotificationsTriggers;
