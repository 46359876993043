import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Popconfirm, Row, Spin, Tooltip } from "antd";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import ConfigureDiscountRulesForm from "components/DiscountComponents/DiscountRulesForm/ConfigureDiscountRulesForm";
import { successMessage } from "utils/helpers/helpers";
import { getRulesConfigurationAPI } from "apis/discount-rules/DiscountRulesApi";
import { _getRulesConfigAction } from "actions/discount-rules/DiscountRulesActions";
import { deleteRulesConfigurationAPI } from "apis/discount-rules/DiscountRulesApi";
import { _updateRulesConfigAction } from "actions/discount-rules/DiscountRulesActions";
import { _addRulesConfigAction } from "actions/discount-rules/DiscountRulesActions";
import { addRulesConfigurationAPI } from "apis/discount-rules/DiscountRulesApi";
import { updateRulesConfigurationAPI } from "apis/discount-rules/DiscountRulesApi";
import { _deleteRulesConfigAction } from "actions/discount-rules/DiscountRulesActions";

const DiscountRuleConfiguration = (props) => {
  const ruleName = props.location.state.title;
  const ruleId = props.location.state.id;
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [showConfigureRuleModal, toggleConfigureRuleModal] =
    React.useState(false);
  const [configureDiscountForm] = Form.useForm();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [editId, setEditId] = React.useState();
  const [autofillValues, setAutoFillValue] = React.useState();

  const _grades = useSelector((state) => state.metaData.grades.length);
  const _campuses = useSelector((state) => state.metaData.campuses.length);
  const _allSchools = useSelector((state) => state.metaData.schools.length);
  const _rulesConfiguration = useSelector(
    (state) => state.rules.configurationData
  );
  const _totalRecords = useSelector(
    (state) => state.rules.configurationDataTotal
  );

  React.useEffect(() => {
    fetchConfigurations();
  }, [currentPage]);

  const fetchConfigurations = async () => {
    try {
      setLoading(true);
      const paginationParams = `page=${currentPage}&limit=10`;
      const response = await getRulesConfigurationAPI(ruleId, paginationParams);
      if (response.data && response.status === 200) {
        dispatch(_getRulesConfigAction(response.data.data));
        setLoading(false);
      } else throw response.error;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  const loadMore = (page) => {
    setCurrentPage(page);
  };

  const onAdd = () => {
    toggleConfigureRuleModal(true);
    configureDiscountForm.resetFields();
  };

  const onSubmitAddConfig = async (payload, clearFields) => {
    setLoading(true);
    try {
      if (editId) {
        const response = await updateRulesConfigurationAPI(editId, {
          ruleId,
          ...payload,
        });
        if (response.status == 200) {
          dispatch(_updateRulesConfigAction(response.data.data));
          setLoading(false);
          toggleConfigureRuleModal(false);
          setAutoFillValue(undefined);
          successMessage({ message: "Configuration updated!" });
          configureDiscountForm.resetFields();
          clearFields();
        } else throw response;
      } else {
        const response = await addRulesConfigurationAPI({ ruleId, ...payload });
        if (response.status == 200) {
          dispatch(_addRulesConfigAction(response.data.data));
          setLoading(false);
          toggleConfigureRuleModal(false);
          successMessage({ message: "Configuration added!" });
          configureDiscountForm.resetFields();
          clearFields();
        } else throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
      setAutoFillValue(undefined);
    }
  };

  const onEditRule = (record) => {
    configureDiscountForm.setFieldsValue(record);
    setEditId(record.id);
    toggleConfigureRuleModal(true);
    setAutoFillValue(record);
  };
  const onDelete = async (record) => {
    try {
      setLoading(true);
      const response = await deleteRulesConfigurationAPI(record.id);
      if (response.status == 200) {
        dispatch(_deleteRulesConfigAction(record.id));
        setLoading(false);
        successMessage({ message: response.message });
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          key={"#"}
          data={_rulesConfiguration}
          header={() => (
            <TableHeader
              headerTitle={ruleName}
              headerSubTitle={"Discount Rules Configuration"}
              headerBtnTitle="Add New"
              onAddNew={onAdd}
            />
          )}
          columns={[
            {
              title: "Campuses",
              dataIndex: "campuses",
              key: "id",
              render: (record) =>
                record.length === _campuses
                  ? "All"
                  : record.map((campus) => campus.code).join(","),
            },
            {
              title: "Grades",
              dataIndex: "grades",
              key: "id",
              render: (record) =>
                record.length === _grades.length
                  ? "All"
                  : record.map((grade) => grade.code).join(","),
            },
            {
              title: "Schools",
              dataIndex: "schools",
              key: "id",
              render: (record) =>
                record.length === _allSchools
                  ? "All"
                  : record.map((school) => school.abbreviation).join(","),
            },
            {
              title: "Students",
              dataIndex: "students",
              key: "id",
              render: (record) => record || "All",
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <Row justify="space-evenly" gutter={[20]}>
                  <Tooltip title={"Edit"}>
                    <Button
                      className="d-flex justify-content-center align-items-center"
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => onEditRule(item)}
                    />
                  </Tooltip>

                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </Row>
              ),
            },
          ]}
          totalRecords={_totalRecords}
          currentPage={currentPage}
          fetchRecords={loadMore}
        ></CommonTable>

        <ConfigureDiscountRulesForm
          key={"configureRule"}
          visibility={showConfigureRuleModal}
          formName={configureDiscountForm}
          onHide={() => {
            toggleConfigureRuleModal(false);
          }}
          title={`Add Discount Rule's Configuration`}
          loading={loading}
          onSubmit={onSubmitAddConfig}
          autofillValues={autofillValues}
        />
      </Spin>
    </div>
  );
};
export default DiscountRuleConfiguration;
