import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select } from "antd";
import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import SCHRewardsForm from "components/Forms/sch-rewards-from/SCHRewardsForms";
import { fetchAllRewards } from "apis/SCH/schRequests";
import { addNewReward } from "apis/SCH/schRequests";
import { updateReward } from "apis/SCH/schRequests";
import { deleteReward } from "apis/SCH/schRequests";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const ScholarshipRewards = () => {
  const _requestTypes = useSelector((state) => state.metaData.requestTypes);
  const [loading, setLoading] = useState(true);
  const [rewardsData, setRewardsData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [rewardId, setRewardId] = useState("");

  const [schRewardsForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            schRewardsForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Scholarship Rewards"}
          headerSubTitle={"Manage Scholarship Rewards"}
          headerBtnTitle={"Add New"}
        />
        {/* <Row gutter={10}>
          <Col md={12}>
            <Select
              style={{ width: 170, marginRight: 50 }}
              value={appType}
              onChange={(e) => {
                setAppType(e);
              }}
            >
              <Option key={"#"} value={""}>
                All
              </Option>
              {_requestTypes?.map((el, i) => {
                return (
                  <Option key={i} value={`${el.identifier}`}>
                    {el.identifier}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row> */}
      </>
    );
  };
  useEffect(() => {
    getAllRewards();
  }, []);

  const getAllRewards = async () => {
    try {
      setLoading(true);
      const response = await fetchAllRewards();
      if (response.status === 200) {
        setRewardsData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addReward = async (data) => {
    try {
      setLoading(true);
      const response = await addNewReward({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Reward added successfully" });
        await getAllRewards();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateReward = async (data) => {
    try {
      setLoading(true);
      const response = await updateReward({ id: rewardId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Reward updated successfully" });
        await getAllRewards();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteReward = async (id) => {
    try {
      setLoading(true);
      const response = await deleteReward({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Reward deleted successfully" });
        await getAllRewards();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const newValues = {
      ...values,
      value: Number(values.value),
      totalSeats: Number(values.totalSeats),
    };

    if (modalTitle === ModalType.EDIT) {
      await handleUpdateReward(newValues);
    } else {
      await addReward(newValues);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    schRewardsForm.setFieldsValue({
      ...record,
    });
    setRewardId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={rewardsData}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: "title",

              render: (record) => (
                <p className="answer_para">
                  {record.replace(/(<([^>]+)>)/gi, "")}
                </p>
              ),
            },

            {
              title: "Scholarship %",
              dataIndex: "value",
              key: "value",
              render: (record) => <p className="answer_para">{record}</p>,
            },

            // {
            //   title: "Total Seats",
            //   dataIndex: "totalSeats",
            //   key: "totalSeats",
            //   render: (record) => <p className="answer_para">{record}</p>,
            // },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Rewards Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Reward?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteReward(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Scholarship Reward`}
        >
          <SCHRewardsForm
            onSubmit={onSubmit}
            formName={schRewardsForm}
            loading={loading}
            valuesForEdit={null}
            _requestTypes={_requestTypes}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default ScholarshipRewards;
