import React, { useState } from "react";
// import AllApplicationsTable from "components/Tables/AllApplicationsTable";

import CreateNewDocumentListForm from "components/Forms/CreateNewDocumentListForm";
import CustomModal from "components/CustomModal/CustomModal";
import "styles/shared.css";
import CommonTable from "components/Tables/CommonTable";
import { Popconfirm, Tooltip, Button, Form, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import TableHeader from "components/TableHeader/TableHeader";
import { allDocumentsLists } from "apis/documents-list/documentsListapis";
import { useEffect } from "react";
import { addDocumentToList } from "apis/documents-list/documentsListapis";
import { requestErrorHandel } from "utils/helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import { _getAllDocumentsLists } from "actions/documents-list/documentslistAction";
import { _addNewDocument } from "actions/documents-list/documentslistAction";
import { _deleteDocument } from "actions/documents-list/documentslistAction";
import { deleteDocument } from "apis/documents-list/documentsListapis";
import { successMessage } from "utils/helpers/helpers";
import { updateDocumentinLists } from "apis/documents-list/documentsListapis";
import { _updateDocument } from "actions/documents-list/documentslistAction";
import { convertToTitleCase } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
// import { getSignUrl } from "apis/get-sign-url/getSignUrl";
// import axios from "axios";

export default function DocumentsList(props) {
  const [form] = Form.useForm();

  // const applicationType = props.location.state.serviceName;

  const [visibllityState, setVisibility] = useState(false);
  const [editApplicationFields, setEditApplicationFiels] = useState(null);
  const [loading, setLoading] = React.useState(true);
  const [uploading, setUpLoading] = React.useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [componentSize, setComponentSize] = useState("large");
  const [application_id, setApplication_id] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [document, setDocument] = useState(null);

  const _allDocumentsLists = useSelector(
    (state) => state.documents.documentsLists
  );
  const _totalRecords = useSelector((state) => state.documents.total);

  const disptach = useDispatch();
  useEffect(() => {
    fetchAllDocumentsLists();
  }, [currentPage]);

  useEffect(() => {
    if (editApplicationFields) {
      form.setFieldsValue(editApplicationFields);
    }
    // else {
    //   form.resetFields();
    // }
  }, [editApplicationFields]);

  const onSubmitForm = (values) => {
    if (editApplicationFields !== null) {
      updateDocumentinList(values);
    } else {
      addNewDocumentToList(values);
    }
  };
  const fetchAllDocumentsLists = async () => {
    try {
      setLoading(true);
      const queryParams = `?page=${currentPage}&limit=${pageSize}`;
      const resposne = await allDocumentsLists(queryParams);
      if (resposne.status == 200) {
        setLoading(false);
        disptach(_getAllDocumentsLists(resposne.data));
        // setTotalRecords(resposne.data.total);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const fetchRecords = async (page) => {
    setCurrentPage(page);
  };

  const showNewApplicationModal = (status) => {
    if (status === true) {
      setVisibility(true);
      form.resetFields();
    }
  };
  const handleOk = () => {
    setVisibility(false);
  };

  const handleCancel = () => {
    setVisibility(false);
    setEditApplicationFiels(null);
  };
  const editApplication = (record) => {
    form.setFieldsValue({
      enableDownload: record.template.length > 0,
    });
    setVisibility(true);
    setEditApplicationFiels(record);
    setApplication_id(record.id);
    setDocument(record.template);
  };

  const handelPickFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "documentlist"
        );
        setDocument(path);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };
  const updateDocumentinList = async (values) => {
    let newValues = { ...values, template: document };
    try {
      setLoading(true);
      const resposne = await updateDocumentinLists({
        id: application_id,
        data: newValues,
      });
      if (resposne.status == 200) {
        setLoading(false);
        setVisibility(false);
        form.resetFields();
        successMessage({ message: "Document updated successfully." });
        disptach(_updateDocument(application_id, resposne.data.data));
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  const addNewDocumentToList = async (values) => {
    let newValues = { ...values, template: document };
    try {
      setLoading(true);
      const response = await addDocumentToList({ data: newValues });
      if (response.status == 200) {
        setVisibility(false);
        setLoading(false);
        successMessage({ message: "Document added successfully." });
        disptach(_addNewDocument(response.data.data));
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  const deleteDocumentsFromList = async (id) => {
    try {
      setLoading(true);
      const resposne = await deleteDocument({ id: id });
      if (resposne.status == 200) {
        setLoading(false);
        successMessage({ message: resposne.data.message });
        disptach(_deleteDocument(id));
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          data={_allDocumentsLists}
          totalRecord={_totalRecords}
          fetchRecords={fetchRecords}
          header={() => (
            <TableHeader
              onAddNew={() => showNewApplicationModal(true)}
              headerTitle={`All Documents`}
              headerSubTitle={"Manage Documents"}
              headerBtnTitle={"Add New"}
            />
          )}
          columns={[
            {
              title: "Title (EN)",
              dataIndex: "title",
              key: "id",
            },
            {
              title: "Title (AR)",
              dataIndex: "titleAr",
              key: "id",
            },
            {
              title: "Description (EN)",
              dataIndex: "description",
              key: "id",
              width: "10%",
            },
            {
              title: "Description (AR)",
              dataIndex: "descriptionAr",
              key: "id",
              width: "10%",
            },
            {
              title: "Document Type",
              key: "id",
              render: (record) => convertToTitleCase(record.documentOf),
            },
            {
              title: "Required For",
              render: (record) =>
                record.requiredFor === "both"
                  ? "LOCAL + EXPAT"
                  : convertToTitleCase(record.requiredFor),
              key: "id",
            },
            {
              title: "Mime Type",
              //dataIndex: "fileType",
              key: "id",
              render: (record) =>
                record.fileType === "both"
                  ? "Pdf + Image"
                  : convertToTitleCase(record.fileType),
            },
            {
              title: "Document Mapping",
              key: "id",
              render: (record) =>
                record.mappingName ? record.mappingName : "None",
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (record) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update Document">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => editApplication(record)}
                      />
                    </Tooltip>
                  </div>

                  <Popconfirm
                    title="Are you sure you want to delete this document?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => deleteDocumentsFromList(record.id)}
                  >
                    <Tooltip title="Delete Document">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={visibllityState}
          handleCancel={handleCancel}
          handleOk={handleOk}
          title={
            editApplicationFields ? "Update Document" : " Add New Document"
          }
        >
          <Form
            layout={formLayout}
            form={form}
            initialValues={{ layout: formLayout }}
            size={componentSize}
            onFinish={onSubmitForm}
          >
            <CreateNewDocumentListForm
              form={form}
              loading={loading}
              uploading={uploading}
              valuesForEdit={editApplicationFields}
              documentFor={["parent", "child"]}
              handelPickFile={handelPickFile}
              document={document}
              isFromDoc={true}
            />
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
}
