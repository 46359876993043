import { Button, Form, Input, Select, Row, Col } from "antd";
import React from "react";

import { useSelector } from "react-redux";
const { Option } = Select;

const SccCategoriesForm = ({ onSubmit, loading, formName }) => {
  const _campuses = useSelector((state) => state.metaData.campuses);

  const types = [
    {
      id: "1",
      title: "Positive / Distinguish Behaviour",
      value: "positive",
    },
    {
      id: "2",
      title: "Negative Behaviour",
      value: "negative",
    },
  ];

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Unique Code"}
              name={"identifier"}
              rules={setRules("Unique Code")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Case Type"}
              name={"type"}
              rules={setRules("Case Type")}
            >
              <Select placeholder="Select">
                {types.map((el, i) => (
                  <Option value={el.value} key={i}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={setRules("Title EN")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title AR")}
            >
              <Input placeholder="Add here" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        <br />

        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant__primary__button"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default SccCategoriesForm;
