import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Image, Popconfirm, Spin, Tooltip } from "antd";
import { addNewsAPI } from "apis/news-cms/NewsCMSApi";
import { deleteNewsAPI } from "apis/news-cms/NewsCMSApi";
import { updateNewsAPI } from "apis/news-cms/NewsCMSApi";
import { getNewsAPI } from "apis/news-cms/NewsCMSApi";
import CustomModal from "components/CustomModal/CustomModal";
import NewsForm from "components/Forms/cms/NewsForm";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const NewsCMS = () => {
  const _campuses = useSelector((state) => state.metaData.campuses);
  const [page, setPage] = useState(1);
  const [news, setNews] = useState();
  const [totalRecords, setTotal] = useState(0);
  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [loading, setLoading] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [uploading, setUpLoading] = useState(false);
  const [file, setFile] = useState();
  const [AddNewsForm] = Form.useForm();

  // API FUNCTIONS

  const getNews = async () => {
    try {
      setLoading(true);
      const response = await getNewsAPI(page);
      setLoading(false);
      if (response.status === 200 && response.data?.data) {
        setNews(response.data.data);
        setTotal(response.data.total);
      } else {
        setNews();
        throw response;
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const addNews = async (data) => {
    try {
      setLoading(true);
      const response = await addNewsAPI(data);
      setLoading(false);
      if (response.status == 200) {
        successMessage({ message: "Record added successfully" });
        await getNews();
        onCloseForm();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const updateEvents = async (data) => {
    try {
      setLoading(true);
      const response = await updateNewsAPI(data, valuesForEdit.id);
      setLoading(false);
      if (response.status == 200) {
        successMessage({ message: "Record updated successfully" });
        await getNews();
        onCloseForm();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const deleteNews = (id) => async () => {
    try {
      setLoading(true);
      const response = await deleteNewsAPI(id);
      if (response.status == 200) {
        successMessage({ message: "Record deleted successfully" });
        await getNews();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNews();
  }, [page]);

  const renderHeader = () => {
    return (
      <TableHeader
        onAddNew={() => {
          AddNewsForm.resetFields();
          toggleModal(true);
          setModalTitle(ModalType.ADD);
        }}
        headerTitle={"News"}
        headerSubTitle={"Manage news content for ENS Website"}
        headerBtnTitle={"Add New"}
      />
    );
  };
  const onChooseFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "news"
        );
        setFile(path);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  const onSubmit = async (values) => {
    let campuses = values.campuses;
    if (campuses === "all") {
      campuses = _campuses.map((el) => el.code);
    }
    const data = { ...values, image: file, campuses };
    if (modalTitle === ModalType.EDIT) {
      await updateEvents(data);
    } else {
      await addNews(data);
    }
  };
  const handleEdit = (record) => {
    setValuesForEdit(record);
    record.image && setFile(record.image);
    AddNewsForm.setFieldsValue({
      ...record,
      startAt: moment(record.startAt),
      endAt: moment(record.endAt),
    });
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
    setFile(undefined);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={news}
          fetchRecords={(page) => {
            setPage(page);
          }}
          totalRecord={totalRecords}
          columns={[
            {
              title: "Subject",
              key: "id",
              dataIndex: "subject",
              ellipsis: {
                showTitle: true,
              },
              render: (record) => (
                <Tooltip placement="topLeft" title={record}>
                  {record}
                </Tooltip>
              ),
            },
            {
              title: "Campus",
              key: "id",
              dataIndex: "campuses",
              render: (record) => record?.map((el) => el).join(", "),
            },
            {
              title: "Start Date",
              key: "id",
              dataIndex: "startAt",
              render: (record) => formateDateWithMoment(record, "DD-MM-YYYY"),
            },
            {
              title: "End Date",
              key: "id",
              dataIndex: "endAt",
              render: (record) => formateDateWithMoment(record, "DD-MM-YYYY"),
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "id",
              render: (record) =>
                record ? (
                  <span style={{ color: "green" }}>Active</span>
                ) : (
                  <span style={{ color: "red" }}>Inactive</span>
                ),
              // render: (record) => (record ? "Active" : "Inactive"),
            },
            {
              title: "Image",
              key: "id",
              dataIndex: "image",
              render: (record) => (
                <Image src={record} width={90} placeholder={true} />
              ),
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update News">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this news?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={deleteNews(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} News`}
        >
          <NewsForm
            onSubmit={onSubmit}
            formName={AddNewsForm}
            loading={loading}
            uploading={uploading}
            onChooseFile={onChooseFile}
            file={file}
            valuesForEdit={null}
            _campuses={_campuses}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default NewsCMS;
