import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { getEServicesConfigAPI } from "apis/e-services-config-apis/EServicesConfigApi";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { deleteEServicesConfigAPI } from "apis/e-services-config-apis/EServicesConfigApi";
import { updateEServicesConfigAPI } from "apis/e-services-config-apis/EServicesConfigApi";
import { addEServicesConfigAPI } from "apis/e-services-config-apis/EServicesConfigApi";
import TFDEServiceConfiguration from "components/DiscountComponents/TFDEserviceConfiguration/TFDEserviceConfiguration";
import _ from "lodash";
import { addDiscountEServiceConfigAPI } from "apis/e-services-config-apis/EServicesConfigApi";
import { successMessage } from "utils/helpers/helpers";
import { showValidationError } from "utils/helpers/helpers";
import { getServiceItemsApi } from "apis/service-items/ServiceItemsApi";
import { useState } from "react";
import { Capitalize } from "utils/helpers/helpers";

const EServicesConfiguration = (props) => {
  const eServiceData = props.location.state;
  const isFreeService = eServiceData.type === "free";
  const [loading, setLoading] = React.useState(false);
  const [showModal, toggleModal] = React.useState(false);
  const [showDiscountModal, toggleDiscountModal] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const [tfdToConfigure] = React.useState();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [totalRecord, setTotalRecords] = React.useState();
  const [currentPage,setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const [employeeDiscount, setEmployeeDiscount] = useState(false);
  
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _allSchools = useSelector((state) => state.metaData.schools);
  const _grades = useSelector((state) =>
    state.metaData.grades?.filter((grade) => grade.code !== "GRADUATED")
  );
  const [serviceItems, setServiceItems] = useState([]);
  const [selectedServiceItems, selectServiceItems] = useState([]);

  const [selectedCampuses, setSelectedCampuses] = React.useState([]);
  const [selectedSchools, setSelectedSchools] = React.useState([]);
  const [selectedGrades, setSelectedGrades] = React.useState([]);
  const [campusesFilteredSchools, setCampusesFilteredSchools] = React.useState(
    []
  );

  const [eServiceConfigData, setEServiceConfigData] = React.useState();
  const [discountForm] = Form.useForm();
  const _tfdEservices = useSelector((state) =>
    state.eservices.data.filter(
      (eservice) =>
        eservice.applicationType === "TFD" && eservice.id !== eServiceData.id
    )
  );

  const fetchServiceItems = async () => {
    try {
      setLoading(true);
      const response = await getServiceItemsApi();
      let data = response.data.data;
      data = data.filter((el) =>
        eServiceData?.itemTypes?.includes(el.itemType)
      );
      data = data.map((item) => {
        return {
          ...item,
          variants: item.variants?.map((el) => {
            return {
              ...el,
              enabled: false,
            };
          }),
        };
      });
      setServiceItems(data);
      selectServiceItems(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (eServiceData?.applicationType === "ASR") {
      fetchServiceItems();
    }
  }, [eServiceData?.applicationType]);

  React.useEffect(() => {
    fetchEServiceConfigurations(eServiceData.id);
  }, [currentPage, eServiceData.id]);

  React.useEffect(() => {
    filterSchoolsByCampus();
  }, [selectedCampuses]);

  //  filter all schools againt the selected campuses
  const filterSchoolsByCampus = () => {
    const selectedCampusesesIds = selectedCampuses.map((el) => el.id);
    setCampusesFilteredSchools(
      _allSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
    setSelectedSchools(
      selectedSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
  };

  const fetchEServiceConfigurations = async (id) => {
    try {
      setLoading(true);
      const paginationParams = `page=${currentPage}&limit=${pageSize}`;
      const eServiceResponse = await getEServicesConfigAPI(
        paginationParams,
        id
      );
      if (eServiceResponse.data && eServiceResponse.status === 200) {
        setEServiceConfigData(eServiceResponse.data.data);
        setTotalRecords(eServiceResponse.data.total);
        setLoading(false);
      } else throw eServiceResponse.error;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  const onAdd = () => {
    setEditId(null);
    form.resetFields();
    setSelectedCampuses([]);
    setSelectedGrades([]);
    setSelectedSchools([]);
    selectServiceItems(
      serviceItems.map((item) => {
        return {
          ...item,
          variants: item.variants?.map((el) => {
            return {
              ...el,
              enabled: false,
            };
          }),
        };
      })
    );
    setEmployeeDiscount(false);
    toggleModal(true);
  };

  const onEdit = (record) => {
    setSelectedCampuses(record.campuses);
    setSelectedSchools(record.schools);
    setSelectedGrades(record.grades);
    setEditId(record.id);

    setEmployeeDiscount(record?.allowEmployeeDiscount);
    form.setFieldValue("allowEmployeeDiscount", record?.allowEmployeeDiscount);
    form.setFieldsValue({ students: record.students });
    // if (record.items?.length > 0) {
    //let allVariantdIds = [];
    let selectedItems = [];
    // record.items.forEach((i) => {
    //   i.variants.forEach((v) => {
    //     allVariantdIds.push(v.id);
    //   });
    // });

    serviceItems.forEach((serviceItem, itemNo) => {
      let variants = [];

      serviceItem.variants.forEach((v, variantNo) => {
        let price;
        let enabled = false;
        /*
        if (allVariantdIds.includes(v.id)) {
          record.items.find((el) => {
            let _variant = el.variants.find((i) => i.id === v.id);
            price = _variant ? _variant.price : null;
          })
          price &&
            form.setFieldsValue({
              [`variants-${itemNo}-${variantNo}`]: isFreeService ? 0 : price,
            });
        }
        
        */
        record.items.forEach((_item) => {
          _item.variants.forEach((_variant) => {
            if (
              serviceItem.id === _item.id &&
              v.variant === _variant.variant &&
              v.variantType === _variant.variantType
            ) {
              enabled = true;
              price = _variant.price;
              form.setFieldsValue({
                [`variants-${itemNo}-${variantNo}`]: isFreeService ? 0 : price,
              });
            }
          });
        });
        variants.push({
          ...v,
          enabled: enabled,
          price: isFreeService ? 0 : price ?? v.price,
        });
      });
      selectedItems.push({
        ...serviceItem,
        variants: variants,
      });
    });
    selectServiceItems(selectedItems);
    // }
    toggleModal(true);
  };

  const onConfigureDiscount = (record) => {
    props.history.push({
      pathname: `/admin/e-services/configuration/rules`,
      state: record,
    });
  };

  const onSubmit = async (values) => {
    try {
      if (
        selectedCampuses.length > 0 &&
        selectedSchools.length > 0 &&
        selectedGrades.length > 0 &&
        eServiceData.id
      ) {
        let payload = {
          eService: Number(eServiceData.id),
          campuses: selectedCampuses.map((param) => param.id),
          schools: selectedSchools.map((param) => param.id),
          grades: selectedGrades.map((param) => param.id),
          students: values.students,
          allowEmployeeDiscount: employeeDiscount,
        };
        if (selectedServiceItems.length > 0) {
          let items = selectedServiceItems.filter(
            (item) =>
              item.variants?.findIndex((variant) => variant.enabled) > -1
          );
          items = items.map((item) => {
            return {
              itemId: item.id,
              variants: item.variants
                .filter((v) => v.enabled)
                ?.map((el) => {
                  return { id: el.id, price: isFreeService ? 0 : el.price };
                }),
            };
          });
          payload = { ...payload, items };
        }
        setLoading(true);
        if (editId) {
          const editConfigResponse = await updateEServicesConfigAPI(
            editId,
            payload
          );
          if (editConfigResponse.status === 200 && editConfigResponse.data) {
            NotificationManager.success("Record updated!");
            setEditId(null);
            fetchEServiceConfigurations(eServiceData.id);
            setLoading(false);
            toggleModal(false);
          }
        } else {
          const addConfigResponse = await addEServicesConfigAPI(payload);
          if (addConfigResponse.status === 200 && addConfigResponse.data) {
            NotificationManager.success("Record added!");
            setEditId(null);
            fetchEServiceConfigurations(eServiceData.id);
            toggleModal(false);
            setLoading(false);
          }
        }
      } else {
        NotificationManager.error("Please provide value for all fields");
        return;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
      toggleModal(false);
      setLoading(false);
    }
    selectServiceItems([]);
  };
  const onDelete = async (record) => {
    try {
      setLoading(false);
      const deleteEServiceResponse = await deleteEServicesConfigAPI(record.id);
      if (deleteEServiceResponse.status === 200) {
        fetchEServiceConfigurations(eServiceData.id);
        setLoading(false);
        NotificationManager.success("E-Service deleted successfully");
      } else throw deleteEServiceResponse.error;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  const handleSelectAllCampus = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedCampuses(_campuses);
    } else {
      return value;
    }
  };

  const handleSelectAllSchools = (value) => {
    if (value && value.length && value.includes("all")) {
      const selectedCampusIds = selectedCampuses.map((el) => el.id);
      const _campuseSchools = _allSchools.filter((school) =>
        selectedCampusIds.includes(school.campusId)
      );
      setSelectedSchools(_campuseSchools);
    } else {
      return value;
    }
  };

  const handleSelectAllGrades = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedGrades(_grades);
    } else {
      return value;
    }
  };

  const closeDiscountModal = () => {
    toggleDiscountModal(false);
    discountForm.resetFields();
  };

  const onSubmitTFDConfiguration = async (value) => {
    let {
      noOfKids,
      gradesRestrictions,
      allowedWithDiscounts,
      excludeHighestGrades,
    } = value;
    setLoading(true);
    try {
      const grades = _.flow(
        Object.entries,
        (formValues) =>
          formValues.filter(
            ([formKey, formValue]) =>
              formKey.includes("gradeId") && formValue.type && formValue.value
          ),
        Object.fromEntries,
        Object.values
      )(value);
      if (!grades.length > 0) {
        showValidationError(
          "Enter discount type & value for atleast one of the grades"
        );
        setLoading(false);
        return;
      }
      excludeHighestGrades =
        excludeHighestGrades === undefined ? false : excludeHighestGrades;
      const params = {
        configurationId: tfdToConfigure.id,
        noOfKids,
        allowedWithDiscounts,
        excludeHighestGrades,
        gradesRestrictions,
        grades,
      };
      const response = await addDiscountEServiceConfigAPI(params);
      if (response.status === 200) {
        setLoading(false);
        successMessage({
          message: "Discount configuration added successfully",
        });
        fetchEServiceConfigurations(eServiceData.id);
        closeDiscountModal();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
      closeDiscountModal();
    }
  };

  const onToggleVariantEnable = (itemNo, variantNo) => (e) => {
    let temp = [...selectedServiceItems];
    temp[itemNo].variants[variantNo].enabled = e.target.checked;
    selectServiceItems(temp);
  };

  const onChangeVariantPrice = (itemNo, variantNo) => (e) => {
    let temp = [...selectedServiceItems];
    temp[itemNo].variants[variantNo].price = Number(e.target.value);
    selectServiceItems(temp);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          key={"#"}
          loading={loading}
          data={eServiceConfigData}
          header={() => (
            <TableHeader
              headerTitle={eServiceData.name}
              headerSubTitle={"EService Configuration"}
              headerBtnTitle="Add New"
              onAddNew={onAdd}
            />
          )}
          columns={[
            {
              key: "id",
              title: "Campuses",
              dataIndex: "campuses",
              render: (item) => {
                return item
                  ? item.length === _campuses.length
                    ? "All"
                    : item.map((el) => el.code).join(", ")
                  : "";
              },
            },
            {
              key: "id",
              title: "Schools",
              dataIndex: "schools",
              render: (item) => {
                return item
                  ? item.length === _allSchools.length
                    ? "All"
                    : item.map((el) => el.abbreviation).join(", ")
                  : "";
              },
            },
            {
              key: "id",
              title: "Grades",
              dataIndex: "grades",
              render: (item) => {
                return item
                  ? item.length === _grades.length
                    ? "All"
                    : item.map((el) => el.code).join(", ")
                  : "";
              },
            },
            {
              key: "id",
              title: "Students",
              dataIndex: "students",
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <Row justify="space-evenly" gutter={[20]}>
                  <Tooltip title="Update">
                    <Button
                      className="d-flex justify-content-center align-items-center"
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => onEdit(item)}
                    />
                  </Tooltip>

                  {eServiceData.applicationType === "TFD" && (
                    <Tooltip title={"Configure Rules"}>
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<SettingOutlined />}
                        onClick={() => onConfigureDiscount(item)}
                      />
                    </Tooltip>
                  )}

                  <Popconfirm
                    title="Are you sure you want to delete this configuration?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </Row>
              ),
            },
          ]}
          totalRecord={totalRecord}
          fetchRecords={(page) => setCurrentPage(page)}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={() => {
            selectServiceItems([]);
            toggleModal(false);
          }}
          title={`E-Service Configuration`}
        >
          <Form form={form} layout="vertical" size="large" onFinish={onSubmit}>
            <Form.Item name="id" id="id" style={{ display: "none" }}>
              <input defaultValue={eServiceData.id} />
            </Form.Item>

            <Form.Item label={"Campuses"}>
              <Select
                placeholder="Select campus"
                mode={"multiple"}
                value={
                  selectedCampuses.length > 0
                    ? selectedCampuses.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={handleSelectAllCampus}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {_campuses.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedCampuses.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedCampuses(
                          e.target.checked
                            ? [...selectedCampuses, el]
                            : selectedCampuses.filter((i) => i.id !== el.id)
                        );
                      }}
                    >
                      {el.code}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={"Schools"}>
              <Select
                placeholder="Select school"
                mode="multiple"
                value={
                  selectedSchools.length > 0
                    ? selectedSchools
                        .map((item) => item.abbreviation)
                        ?.join(", ")
                    : []
                }
                onChange={handleSelectAllSchools}
              >
                {campusesFilteredSchools.length > 1 && (
                  <Option key="all" value="all">
                    Select All
                  </Option>
                )}

                {campusesFilteredSchools.map((el) => (
                  <Option value={el.id}>
                    <Checkbox
                      checked={
                        selectedSchools.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedSchools(
                          e.target.checked
                            ? [...selectedSchools, el]
                            : selectedSchools.filter((i) => i.id !== el.id)
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      {el.abbreviation}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={"Grades"}>
              <Select
                placeholder="Select grade"
                mode="multiple"
                value={
                  selectedGrades.length > 0
                    ? selectedGrades.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={handleSelectAllGrades}
              >
                <Option key="all" value="all">
                  Select All
                </Option>

                {/* hide the graduated grade for tfd eservices */}
                {_grades.map((el, i) =>
                  el.code === "GRADUATED" &&
                  eServiceData.applicationType === "TFD" ? null : (
                    <Option value={el.id} key={i}>
                      <Checkbox
                        checked={
                          selectedGrades.findIndex((i) => i.id === el.id) > -1
                        }
                        onChange={(e) => {
                          setSelectedGrades(
                            e.target.checked
                              ? [...selectedGrades, el]
                              : selectedGrades.filter((i) => i.id !== el.id)
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.code}
                      </Checkbox>
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>

            <Form.Item label={"Students"} name={"students"}>
              <Input placeholder={"Students"} />
            </Form.Item>
            {eServiceData.applicationType === "ASR" && (
              <div style={{ marginTop: 35 }}>
                <Row gutter={[10,10]}>
              <Col lg={12} md={12} sm={24}  >
              <Form.Item name={"allowEmployeeDiscount"} valuePropName={"checked"}>
                <Checkbox defaultChecked={false} onChange={(e) => setEmployeeDiscount(e.target.checked)}>Enable Employee Discount</Checkbox>
              </Form.Item>
            </Col>

            
          </Row>
                <strong style={{ color: "#007B85", fontSize: 15 }}>
                  Service Items
                </strong>

                {selectedServiceItems.map((items, itemNo) => {
                  let variantList = items.variants.sort((a, b) =>
                    b.variant.localeCompare(a.variant)
                  );

                  return (
                    <div key={items.id} style={{ marginTop: 10 }}>
                      <p style={{ fontWeight: 500 }}>
                        {Capitalize(items.title)} (Variants Prices)
                      </p>
                      <Row gutter={[10]} style={{ marginTop: 5 }}>
                        {variantList
                          ?.sort((a) => a.variant)
                          .map((variant, variantNo) => (
                            <>
                              <Col md={8}>
                                <Checkbox
                                  checked={variant.enabled}
                                  defaultChecked={variant.enabled}
                                  value={variant.enabled}
                                  onChange={onToggleVariantEnable(
                                    itemNo,
                                    variantNo
                                  )}
                                >
                                  {Capitalize(variant.variant)}
                                </Checkbox>
                                <Form.Item
                                  style={{ marginTop: "10px" }}
                                  name={`variants-${itemNo}-${variantNo}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Variant price is required",
                                    },
                                  ]}
                                  initialValue={
                                    isFreeService ? 0 : variant.price
                                  }
                                >
                                  <Input
                                    type={"number"}
                                    style={{ width: "100%" }}
                                    placeholder="Price in AED"
                                    disabled={!variant.enabled || isFreeService}
                                    defaultValue={
                                      isFreeService ? 0 : variant.price
                                    }
                                    value={isFreeService ? 0 : variant.price}
                                    onChange={onChangeVariantPrice(
                                      itemNo,
                                      variantNo
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ))}
                      </Row>
                    </div>
                  );
                })}
              </div>
            )}

            <Row justify="end">
              <Button htmlType="submit" loading={loading} type="primary">
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>

        {/* tfd configure modal */}
        <TFDEServiceConfiguration
          key={"tfdConfigure"}
          visibility={showDiscountModal}
          formName={discountForm}
          data={tfdToConfigure}
          onHide={closeDiscountModal}
          title={`${eServiceData.name} Configuration`}
          tfdServices={_tfdEservices}
          onSubmit={onSubmitTFDConfiguration}
          loading={loading}
        />
      </Spin>
    </div>
  );
};

export default EServicesConfiguration;
