/* eslint-disable no-unused-vars */
import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
const { Option } = Select;

function AddRemarks(props) {
  const [remarksType, setRemarksType] = React.useState([
    {
      id: "1",
      title: "Recommended",
      value: true,
    },
    {
      id: "2",
      title: " Not Recommended",
      value: false,
    },
  ]);
  return (
    <div className="both-side-padding">
      {/* <Form size="large"> */}
      <Row gutter={[20, 20]}>
        <Col lg={24}>
          <Form.Item
            label="Recommendation"
            name="isRecommended"
            rules={[
              {
                required: true,
                message: "Recommendation type is required",
              },
            ]}
          >
            <Select
              // defaultValue={"Recommended"}
              style={{ width: "100%" }}
            >
              {remarksType.map((type) => {
                return <Option value={type.value}>{type.title}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item
            label="Notes"
            name="notes"
            rules={[
              {
                required: true,
                message: "Notes are required",
                whitespace: true,
              },
            ]}
          >
            <Input.TextArea
              placeholder="Notes"
              style={{ height: "40px" }}
            ></Input.TextArea>
          </Form.Item>
        </Col>
      </Row>
      <div style={{ marginTop: "20px" }}>
        <Button
          className="appButton"
          type="primary"
          // onClick={props.addRemarksHandler}
          htmlType="submit"
          loading={props.loading}
        >
          {props.loading ? "Submitting...." : "Submit"}
        </Button>
      </div>
      {/* </Form> */}
    </div>
  );
}

export default AddRemarks;
