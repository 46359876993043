export const GET_ALL_DOCUMENT_LIST_CONFIGURATION =
  "GET_ALL_DOCUMENT_LISTS_CONFIGURATION";
export const ADD_NEW_DOCUMENT_CONFIGURATION = "ADD_NEW_DOCUMENT_CONFIGURATION";
export const DELETE_DOCUMENT_CONFIGURATION = "DELETE_DOCUMENT_CONFIGURATION";
export const UPDATE_DOCUMENT_CONFIGURATION = "UPDATE_DOCUMENT_CONFIGURATION";

const getAllDocumentsListsConfiguration = (data) => {
  return {
    type: GET_ALL_DOCUMENT_LIST_CONFIGURATION,
    payload: data,
  };
};

const addNewDocumentConfiguration = (data) => {
  return {
    type: ADD_NEW_DOCUMENT_CONFIGURATION,
    payload: data,
  };
};

const deleteDocumentConfiguration = (id) => {
  return {
    type: DELETE_DOCUMENT_CONFIGURATION,
    payload: id,
  };
};

const updateDocumentConfiguration = (data) => {
  return {
    type: UPDATE_DOCUMENT_CONFIGURATION,
    payload: data,
  };
};
export const _getAllDocumentsListsConfiguration = (data) => {
  return (dispatch) => {
    dispatch(getAllDocumentsListsConfiguration(data));
  };
};

export const _addNewDocumentConfiguration = (data) => {
  return (dispatch) => {
    dispatch(addNewDocumentConfiguration(data));
  };
};

export const _deleteDocumentConfiguration = (id) => {
  return (dispatch) => {
    dispatch(deleteDocumentConfiguration(id));
  };
};

export const _updateDocumentConfiguration = (data) => {
  return (dispatch) => {
    dispatch(updateDocumentConfiguration(data));
  };
};
