/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import { _updateApplications } from "actions/applications/applications-actions";
import { Button, Col, Input, Popconfirm, Row, Spin, Tooltip } from "antd";
import { applicationProcess } from "apis/applications-apis/applications";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ReviewNotCompleteCodes } from "utils/constants";
import { PopconfirmProps } from "utils/constants";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { Capitalize } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { formateDateWithMoment } from "utils/helpers/helpers";
import CustomModal from "components/CustomModal/CustomModal";
import { NotificationManager } from "react-notifications";
import CancelApplicationForm from "components/Forms/CancelApplicationForm";
import ActivateApplicationForm from "components/Forms/ActivateApplicationForm";

function ApplicationInfo({ application, refetch }) {
  const [loading, setLoading] = useState(false);
  const [serviceFeeValue, setServiceFeeValue] = useState();
  const [editServiceFee, setEditServiceFee] = useState();
  const [isCancelApplicationModalOpen, setIsCancelApplicationModalOpen] =
    useState(false);

  const [
    isActivatingApplicationModalOpen,
    setIsActivatingApplicationModalOpen,
  ] = useState(false);

  const hasCancelAccess = useSelector(
    (state) =>
      state.login.userInfo?.hasCancelAccess ||
      state.login.userInfo?.role === "super-admin"
  );
  const stageCode = application?.stage?.status?.code;

  const applicationType = application.applicationType;

  const openCancelApplicationModal = () => {
    setIsCancelApplicationModalOpen(true);
  };

  const openActivateApplicationModal = () => {
    setIsActivatingApplicationModalOpen(true);
  };

  const handelApplicationProcess = async (
    action,
    serviceFee,
    note,
    initialStage = false
  ) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: application?.id,
        action: action,
        schoolId: "",
        initialStage: initialStage,
        serviceFeeAmount: serviceFee ? Number(serviceFee) : null,
        note: note,
      });

      if (response?.status == 200) {
        if (action === "cancel") {
          setIsCancelApplicationModalOpen(false);
        }
        refetch && refetch();
        successMessage({ message: "Application status updated successfully" });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const allowServiceFeeEdit =
    // allow edit until not accepted in the final approval stage
    application?.status.code !== "AS007" &&
    (applicationType === "ENR"
      ? application.stage.slug === "final_approval"
        ? ["SG049", "SG050"].includes(stageCode)
        : application.stage.slug !== "registration_fees"
      : application.stage.slug === "final_approval"
      ? ["SG280", "SG290", "SG880", "SG890"].includes(stageCode)
      : application.stage.slug !== "payment");

  const onTripleClickServiceFee = (e) => {
    if (
      e.detail === 3 &&
      allowServiceFeeEdit &&
      application.eService?.allowAmountUpdate
    ) {
      setEditServiceFee(true);
    }
  };

  const onDoneServiceFeeEdit = () => {
    if (serviceFeeValue) {
      handelApplicationProcess("update_service_fee", serviceFeeValue);
      setEditServiceFee(false);
    }
  };

  const onCancelServiceFeeEdit = () => {
    setServiceFeeValue(application.serviceFeeAmount);
    setEditServiceFee(false);
  };

  useEffect(() => {
    if (application) {
      setServiceFeeValue(application.serviceFeeAmount);
    }
  }, [application]);

  const onSave = (values) => {
    setIsActivatingApplicationModalOpen(false);
    handelApplicationProcess(
      "activate_cancelled",
      undefined,
      values.notes,
      values.initialStage
    );
  };

  return (
    <Spin spinning={loading} indicator={renderLoader}>
      {application ? (
        <>
          <CancelApplicationForm
            visibility={isCancelApplicationModalOpen}
            handleCancel={() => setIsCancelApplicationModalOpen(false)}
            onSubmit={(notes) =>
              handelApplicationProcess("cancel", undefined, notes)
            }
            loading={loading}
          />

          <ActivateApplicationForm
            visibility={isActivatingApplicationModalOpen}
            handleCancel={() => setIsActivatingApplicationModalOpen(false)}
            onSubmit={onSave}
            // onSubmit={(notes) =>
            //   handelApplicationProcess("activate_cancelled", undefined, notes)
            // }
            loading={loading}
            application={application}
          />

          <h5>Application ID - {application?.title}</h5>
          <br />
          <p>
            Submitted on{" "}
            {formateDateWithMoment(application?.createdAt, "DD MMM YYYY")}
          </p>
          <p>
            Application Status : {application?.status?.internalStatus}{" "}
            {application.autoApproval ? "(Auto Approved)" : ""}
          </p>
          <p>
            Current Stage : {application?.stage?.title} -{" "}
            {application?.stage?.status?.internalStatus}
          </p>
          {applicationType === "UPD" && (
            <p>Request For: {Capitalize(application.target)}</p>
          )}

          {(applicationType !== "UPD" || application.target === "student") && (
            <div>
              {applicationType === "ENR" && (
                <p>
                  Student Name :{" "}
                  {application.childDetails?.firstName?.value +
                    " " +
                    application.childDetails?.lastName?.value || ""}
                </p>
              )}
              <p>
                Student No :{" "}
                {applicationType === "TFD"
                  ? application?.students
                      ?.map((el) => el.studentNumber)
                      .join(", ") || ""
                  : application.studentNumber}
              </p>

              {applicationType === "TFD" ? null : applicationType === "ENR" ? (
                <>
                  <p>
                    Target School :{" "}
                    {application.childDetails?.school?.valueMeta || ""}
                  </p>
                  <p>
                    Applied Grade:{" "}
                    {application.childDetails?.newGrade?.valueMeta || ""}
                  </p>
                  <p>Expected Start Date : {application.expectedDate || ""}</p>
                  <p style={{ overflowX: "clip" }}>
                    Enrollment Notes : {application.enrollmentNotes || ""}
                  </p>
                </>
              ) : (
                <>
                  {/* trp, asr, upd */}
                  {applicationType === "SCH" || applicationType === "TCC" ? (
                    <p>
                      Student Name:{" "}
                      {application.studentDetails?.firstName?.value +
                        application.studentDetails?.lastName?.value || "N/A"}
                    </p>
                  ) : (
                    <p>Student Name: {application.student?.name || "N/A"}</p>
                  )}
                  {applicationType === "SCH" ||
                  applicationType === "TCC" ? null : (
                    <>
                      <p>Campus: {application.student?.campus || "N/A"}</p>
                      <p>School: {application.student?.school || "N/A"}</p>
                      <p>Grade: {application.student?.grade || "N/A"}</p>
                    </>
                  )}
                </>
              )}
              {/* {application.type === "SCH" ? <>this is SCh</> : null} */}
            </div>
          )}
          <p>
            Father Name:{" "}
            {(application.fatherInfo?.name ?? application.father?.name.value) ||
              "N/A"}{" "}
          </p>
          <p>
            Father Phone:{" "}
            {(application.fatherInfo?.phoneNo ??
              application.father?.phoneNo.value) ||
              "N/A"}{" "}
          </p>
          <p>
            Mother Name:{" "}
            {(application.motherInfo?.name ?? application.mother?.name.value) ||
              "N/A"}{" "}
          </p>
          <p>
            Mother Phone:{" "}
            {(application.motherInfo?.phoneNo ??
              application.mother?.phoneNo.value) ||
              "N/A"}{" "}
          </p>
          <p>
            Preferred Communication Language :{" "}
            {application?.language === "ar" ? "Arabic" : "English"}
          </p>

          {applicationType === "SCH" &&
            application?.stage?.slug === "review" && (
              <p>
                Service : {Capitalize(application?.eService?.name || "N/A")}
              </p>
            )}
          {/* {applicationType === "SCH" && (
            <p>Reward : {application?.reward?.title || "N/A"}</p>
          )} */}
          {/* {applicationType === "SCH" && (
            <p>Evaluation Points : {application?.evaluationPoints || "N/A"}</p>
          )} */}

          {(applicationType === "ENR" ||
            applicationType === "ASR" ||
            applicationType === "TRP") &&
            !ReviewNotCompleteCodes.includes(stageCode) &&
            (editServiceFee ? (
              <Row gutter={[10]}>
                <Col md={12}>
                  <Input
                    placeholder="Enter service fee"
                    value={serviceFeeValue}
                    defaultValue={serviceFeeValue}
                    onChange={(e) => setServiceFeeValue(e.target.value)}
                    type={"number"}
                  />
                </Col>
                <Col>
                  <Button onClick={onDoneServiceFeeEdit}>Done</Button>
                </Col>
                <Col>
                  <Button onClick={onCancelServiceFeeEdit}>Cancel</Button>
                </Col>
              </Row>
            ) : (
              <Tooltip
                title={
                  application.eService.allowAmountUpdate && allowServiceFeeEdit
                    ? "Triple click here to edit service fee"
                    : ""
                }
              >
                <Button
                  onClick={onTripleClickServiceFee}
                  type="text"
                  style={{ padding: 0 }}
                >
                  Service Fee Amount: {application.serviceFeeAmount} AED
                </Button>
              </Tooltip>
            ))}
          <br />
          <br />

          {["AS001", "AS002", "AS003"].includes(application?.status.code) &&
          application?.stage?.slug !== "registration" &&
          application?.stage?.slug !== "review" &&
          hasCancelAccess ? (
            <Popconfirm
              {...PopconfirmProps}
              title={"Are you sure you want to cancel this application"}
              onConfirm={() => openCancelApplicationModal()}
            >
              <Button danger type="primary">
                Cancel Application
              </Button>
            </Popconfirm>
          ) : null}

          {application?.status.code === "AS006" ||
          application?.status.code === "AS007" ||
          application?.status.code === "AS008" ? (
            // (application?.applicationType === "SCH" && (!["SG1640", "SG1760"].includes(application?.stage?.status.code)))
            application?.hasStagePermission ? (
              <Popconfirm
                {...PopconfirmProps}
                title={"Are you sure you want to activate this application"}
                onConfirm={() => openActivateApplicationModal()}
              >
                <Button type="primary" style={{ marginLeft: 10 }}>
                  Activate Application
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </>
      ) : (
        renderNoDataPlaceholder(loading)
      )}
    </Spin>
  );
}

export default ApplicationInfo;
