/* eslint-disable eqeqeq */
import React from "react";
import Login from "../components/Forms/LoginForm.jsx";
import { login } from "../apis/login-apis/login-user-api";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { requestErrorHandel } from "utils/helpers/helpers";
import { _setLoginUser } from "actions/auth/authAction.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const params = {
        userName: this.state.email,
        password: this.state.password,
      };
      this.setState({
        isLoading: true,
      });
      const response = await login(params);

      if (response.status == 200 && response.data) {
        localStorage.setItem(
          "userToken",
          JSON.stringify(response.data.data?.authToken)
        );
        localStorage.setItem(
          "currentUserRol",
          JSON.stringify(response.data.data?.role)
        );
        // Rearrange bookingPermissions from server response
        const bookingPermissions = {
          DPB: response.data.data?.bookingPermissions?.DPB,
          PHB: response.data.data?.bookingPermissions?.PHB,
          FFB: response.data.data?.bookingPermissions?.FFB,
          EVB: response.data.data?.bookingPermissions?.EVB,
          LTB: response.data.data?.bookingPermissions?.LTB,
        };

        this.props.setUserInfo({
          role: response.data.data?.role,
          hasCashierAccess: response.data.data?.hasCashierAccess,
          hasCancelAccess: response.data.data?.hasCancelAccess,
          hasCmsAccess: response.data.data?.hasCmsAccess,
          hasDocumentValidatorAccess:response.data.data.hasDocumentValidatorAccess,
          documentValidatorAccess:response.data.data?.documentValidatorAccess,
          permissions: response.data.data?.permissions,
          fmsPermissions: bookingPermissions,
          sccPermission: response.data.data?.sccPermission,
          hasHTMLAccess: response.data.data?.hasTemplatesAccess,
          userName: response.data.data?.userName,
          hasFSMAccess: response.data.data?.hasFSMAccess,
          hasBookingCreateAccess:
            response?.data?.data?.bookingCreationPermissions,
          reportCasePermission: response?.data?.data?.reportCasePermission,
        });
        this.redirectBasedOnPermission(response.data.data);
      }
    } catch (error) {
      console.log("error",error)
      this.setState({
        isLoading: false,
      });

      await requestErrorHandel({ error: error });
      // NotificationManager.error("email or password is incorrect");
    }
  };

  redirectBasedOnPermission(userPermissions) {
    const hasApplicationsPermission = Object.values(
      userPermissions.permissions
    ).some((value) => value === true);

    const hasBookingsPermission = Object.values(
      userPermissions.bookingPermissions
    ).some((value) => value === true);
    
    const hasCmsPermission = userPermissions.hasCmsAccess;

    const isCashier = userPermissions?.hasCashierAccess;
    const hasCmsAccess = userPermissions?.hasCmsAccess;
    const hasDocumentValidatorAccess = userPermissions?.documentValidatorAccess;

    if (hasApplicationsPermission) {
      this.props.history.push("/admin/dashboard");
    } else if (hasBookingsPermission) {
      this.props.history.push("/admin/fsm-dashboard");
    } else if (userPermissions.sccPermission) {
      this.props.history.push("/admin/scc-dashboard");
    } else if (isCashier) {
      this.props.history.push("/admin/cashier-app");
    } else if(hasCmsPermission){
      this.props.history.push("/admin/events");
    
    } else if(hasDocumentValidatorAccess){
      this.props.history.push("/admin/qr-document-generator");
    } else {
      NotificationManager.error("You have not assign any permission yet");
      this.setState({
        isLoading: false,
      });
      return;

      // no permission page needed
    }
  }
  render() {
    return (
      <>
        <Login
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          isLoading={this.state.isLoading}
        />
        <NotificationContainer />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setUserInfo: (payload) => dispatch(_setLoginUser(payload)),
});

export default connect(null, mapDispatchToProps)(withRouter(LoginForm));
