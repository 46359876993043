import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  InputNumber,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import { catMapOptions } from "utils/constants";
const { Option } = Select;

const CatMapForm = ({
  onSubmit,
  loading,
  formName,
  valuesForEdit,
  modalTitle,
}) => {
  const [isApplicable, setIsApplicable] = useState(true);

  const [pointsArray, setPointsArray] = useState([]);
  const [readingPointsArray, setReadingPointsArray] = useState([]);
  const [testType, setTestType] = useState("cat");

  useEffect(() => {
    if (valuesForEdit !== null) {
      const points = [];
      const readingPoints = [];
      valuesForEdit.scores.forEach((score) => {
        if (valuesForEdit.examType === "map") {
          if (score.isMath) {
            points.push(score);
          } else {
            readingPoints.push(score);
          }
        } else if (valuesForEdit.examType === "cat") {
          points.push(score);
        }
      });
      setPointsArray(points.length > 0 ? points : [{ id: "1" }]);
      setReadingPointsArray(
        readingPoints.length > 0 ? readingPoints : [{ id: "1" }]
      );
      setTestType(valuesForEdit?.examType);
    } else {
      setPointsArray([{ id: "1" }]);
      setReadingPointsArray([{ id: "1" }]);
    }
  }, [valuesForEdit]);

  const addMoreOptions = () => {
    let _pointsArray = [...pointsArray];
    const data = {
      id: (Math.random(Math.floor) / 0.1) * 100,
    };
    _pointsArray.push(data);
    setPointsArray(_pointsArray);
  };
  const addMoreOptionsToReadingArray = () => {
    let _readingOptionsArray = [...readingPointsArray];
    const data = {
      id: (Math.random(Math.floor) / 0.1) * 100,
    };
    _readingOptionsArray.push(data);
    setReadingPointsArray(_readingOptionsArray);
  };

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const handelToggelRangeApplicable = () => {
    setIsApplicable(!isApplicable);
    // setPointsArray([]);
  };

  const onRemoveFieldFromPointsArray = (i) => {
    let _pointsArray = [...pointsArray];
    if (i === 0) {
      return;
    }
    _pointsArray = _pointsArray.filter((_, index) => index !== i);
    setPointsArray(_pointsArray);
  };
  const onRemoveFieldFromReadingArray = (i) => {
    let _readingOptionsArray = [...readingPointsArray];
    if (i === 0) {
      return;
    }
    _readingOptionsArray = _readingOptionsArray.filter(
      (_, index) => index !== i
    );
    setReadingPointsArray(_readingOptionsArray);
  };
  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"examType"}
              rules={setRules("Exam Type")}
              label="Exam Type"
            >
              <Select
                placeholder="Select Exam Type"
                onChange={(value) => {
                  setTestType(value);
                }}
              >
                {catMapOptions?.map((el, i) => (
                  <Option key={i} value={el.value}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Title"} name={"title"} rules={setRules("Title")}>
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title Ar")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"isRange"}
              valuePropName={"checked"}
              label=""
              hidden
            >
              <Checkbox checked={true}>Range Applicable</Checkbox>
            </Form.Item>
          </Col>
          <Col md={12}></Col>
        </Row>
        {isApplicable && (
          <Row gutter={10}>
            <Col md={12}>
              <Form.Item
                label={"Minimum Value"}
                name={"minValue"}
                rules={setRules("MinValue")}
              >
                <Input placeholder="" type="Number" min={0} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={"Maximum Value"}
                name={"maxValue"}
                rules={setRules("MaxValue")}
              >
                <Input placeholder="" type="Number" min={0} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Card className="antCardBorder antCard">
          <Row gutter={10}>
            <Col md={12}>
              {testType === "cat" ? (
                <h6 className="tcc_reson_tag">Options</h6>
              ) : (
                <h6 className="tcc_reson_tag"> Math Options</h6>
              )}
            </Col>
            <Col md={12}>
              <div
                style={{
                  alignItems: "flex-end",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button type="primary" onClick={addMoreOptions}>
                  + Add
                </Button>
              </div>
            </Col>
          </Row>

          <br />

          <>
            {pointsArray.map((item, index) => {
              return (
                <Row gutter={10} align={"middle"} key={index}>
                  <Col md={8}>
                    <Form.Item
                      label={"From"}
                      name={`from_${item.id}`}
                      rules={setRules("From")}
                    >
                      <Input
                        placeholder=""
                        type="number"
                        min={0}
                        step={"any"}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item
                      label={"To"}
                      name={`to_${item.id}`}
                      rules={setRules("To")}
                    >
                      <Input
                        placeholder=""
                        type="number"
                        min={0}
                        step={"any"}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={7}>
                    <Form.Item
                      label={"Points"}
                      name={`points_${item.id}`}
                      rules={setRules("Points")}
                    >
                      <Input placeholder="" type="number" min={0} />
                    </Form.Item>
                  </Col>
                  <Col md={1}>
                    {index === 0 ? null : (
                      <img
                        onClick={() => onRemoveFieldFromPointsArray(index)}
                        src={require("../../../assets/img/declinedIcon.svg")}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </>
        </Card>
        <br />
        {testType === "map" && (
          <Card className="antCardBorder antCard">
            <Row gutter={10}>
              <Col md={12}>
                <h6 className="tcc_reson_tag"> Reading Options</h6>
              </Col>
              <Col md={12}>
                <div
                  style={{
                    alignItems: "flex-end",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button type="primary" onClick={addMoreOptionsToReadingArray}>
                    + Add
                  </Button>
                </div>
              </Col>
            </Row>

            <br />

            <>
              {readingPointsArray.map((item, index) => {
                return (
                  <Row gutter={10} align={"middle"} key={index}>
                    <Col md={8}>
                      <Form.Item
                        label={"From"}
                        name={`readfrom_${item.id}`}
                        rules={setRules("From")}
                      >
                        <Input
                          placeholder=""
                          type="number"
                          min={0}
                          step={"any"}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item
                        label={"To"}
                        name={`readto_${item.id}`}
                        rules={setRules("To")}
                      >
                        <Input
                          placeholder=""
                          type="number"
                          min={0}
                          step={"any"}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={7}>
                      <Form.Item
                        label={"Points"}
                        name={`readpoints_${item.id}`}
                        rules={setRules("Points")}
                      >
                        <Input placeholder="" type="number" min={0} />
                      </Form.Item>
                    </Col>
                    <Col md={1}>
                      {index === 0 ? null : (
                        <img
                          onClick={() => onRemoveFieldFromReadingArray(index)}
                          src={require("../../../assets/img/declinedIcon.svg")}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
            </>
          </Card>
        )}
        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default CatMapForm;
