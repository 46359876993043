import client from "apis/config";
const endPointUrl = "scc/categories";

export const fetchAllSccCategories = async ({
  limit = "10",
  page,
  type = "",
}) => {
  return await client.get(
    `/${endPointUrl}?limit=${limit}&page=${page}&type=${type}`
  );
};

export const addNewSccCategory = async ({ data }) => {
  return await client.post(`/${endPointUrl}`, data);
};

export const updateSccCategory = async ({ id, data }) => {
  return await client.put(`/${endPointUrl}/${id}`, data);
};

export const deleteSccCategory = async ({ id }) => {
  return await client.delete(`/${endPointUrl}/${id}`);
};
