import client from "apis/config";

export const getDistributionAPI = async (pagination) => {
  return await client.get(`discount-distributions?${pagination}`);
};

export const addDistributionAPI = async (data) => {
  return await client.post(`discount-distributions`, data);
};

export const updateDistributionAPI = async (data, id) => {
  return await client.put(`discount-distributions/${id}`, data);
};

export const deleteDistributionAPI = async (id) => {
  return await client.delete(`discount-distributions/${id}`);
};
