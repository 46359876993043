import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Input, Row, Tooltip } from "antd";
import { removeUndercore } from "utils/helpers/helpers";
import CustomModal from "components/CustomModal/CustomModal";
import { EditOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";

const NotificationTriger = ({
  item,
  index,
  toggleCheckbox,
  onUpdateTemplate,
}) => {
  const [showModal, setShowModal] = useState(false);
  const onModalHide = () => setShowModal(false);
  const [templateForm] = Form.useForm();

  useEffect(() => {
    templateForm.setFieldsValue({
      parentTemplateEn: item.parentTemplateEn,
      parentTemplateAr: item.parentTemplateAr,
      teamTemplateEn: item.teamTemplateEn,
      teamTemplateAr: item.teamTemplateAr,
      emailNotifications: item.emailNotifications,
      smsNotifications: item.smsNotifications,
      mobileNotifications: item.mobileNotifications,
    });
  }, [item]);

  const onDone = () => {
    onModalHide();
    templateForm.resetFields();
  };

  const onSubmitTemplateForm = (val) => {
    let updatedValues = { ...val };

    if (updatedValues.emailNotifications === undefined) {
      updatedValues.emailNotifications = false;
    }
    if (updatedValues.smsNotifications === undefined) {
      updatedValues.smsNotifications = false;
    }
    if (updatedValues.mobileNotifications === undefined) {
      updatedValues.mobileNotifications = false;
    }
    onUpdateTemplate({ ...updatedValues, code: item.code }, onDone);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div className="notificationTableColumn">
          <p>{item.code}</p>
        </div>
        <div className="notificationTableColumn">
          <p>{capitalize(removeUndercore(item.stage))}</p>
        </div>
        <div className="notificationTableColumn">
          <p>{item.dashboardGroupStatus}</p>
        </div>
        <div className="notificationTableColumn1">
          <p>{item.internalStatus}</p>
        </div>
        <div className="actionTableColumn">
          <Checkbox
            onChange={(e) => toggleCheckbox(index, "parent", e.target.checked)}
            //   value={parentNotification}
            defaultChecked={item.parentNotification}
          />
        </div>
        <div className="actionTableColumn">
          <Checkbox
            onChange={(e) => toggleCheckbox(index, "team", e.target.checked)}
            //   value={teamNotification}
            defaultChecked={item.teamNotification}
          />
        </div>
        <div className="actionTableColumn">
          <Tooltip title={"Template"}>
            <Button
              onClick={() => setShowModal(true)}
              icon={<EditOutlined />}
              shape="circle"
              className="d-flex justify-content-center align-items-center"
            ></Button>
          </Tooltip>
        </div>
      </div>
      <CustomModal
        visibility={showModal}
        title={"Update Template"}
        handleCancel={onModalHide}
        handleOk={onModalHide}
      >
        <Form
          form={templateForm}
          layout="vertical"
          onFinish={onSubmitTemplateForm}
        >
          <Form.Item
            label={"Team (EN)"}
            name={"teamTemplateEn"}
            rules={[
              {
                required: false,
                //message: "Field is required",
              },
            ]}
          >
            <Input.TextArea placeholder="Notification template for team in english"></Input.TextArea>
          </Form.Item>
          <Form.Item
            label={"Team (AR)"}
            name={"teamTemplateAr"}
            rules={[
              {
                required: false,
                //message: "Field is required",
              },
            ]}
          >
            <Input.TextArea
              dir="rtl"
              placeholder="Notification template for team in arabic"
            ></Input.TextArea>
          </Form.Item>
          <Form.Item
            label={"Parent (EN)"}
            name={"parentTemplateEn"}
            rules={[
              {
                required: false,
                //message: "Field is required",
              },
            ]}
          >
            <Input.TextArea placeholder="Notification template for parents in english"></Input.TextArea>
          </Form.Item>
          <Form.Item
            label={"Parent (AR)"}
            name={"parentTemplateAr"}
            rules={[
              {
                required: false,
                //message: "Field is required",
              },
            ]}
          >
            <Input.TextArea
              dir="rtl"
              placeholder="Notification template for parents in arabic"
            ></Input.TextArea>
          </Form.Item>
          <p>Notifications: </p>
          <Row style={{ marginTop: 20 }} gutter={[20, 0]}>
            <Col>
              <Form.Item name="emailNotifications" valuePropName="checked">
                <Checkbox defaultChecked={item.emailNotifications}>
                  Email
                </Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="smsNotifications" valuePropName="checked">
                <Checkbox defaultChecked={item.smsNotifications}>SMS</Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="mobileNotifications" valuePropName="checked">
                <Checkbox>Mobile</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Button htmlType="submit">Save</Button>
          </Row>
        </Form>
      </CustomModal>
    </div>
  );
};

export default NotificationTriger;
