import * as React from "react";
import { DatePicker, Form } from "antd";
import moment from "moment";

const ScheduleDateTimePicker = ({ label, name, rules }) => {
  const range = (start, end) => {
    const result = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }

    return result;
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment();
  };

  const disabledDateTime = (current) => {
    if (current && current < moment())
      return {
        disabledHours: () => range(0, moment().get("h")),
        disabledMinutes: () => range(0, moment().get("m")),
      };
  };
  return (
    <Form.Item label={label} name={name} rules={rules}>
      <DatePicker
        className="date_time_picker"
        style={{ width: "100%" }}
        format="YYYY-MM-DD hh:mm A"
        showTime={{ defaultValue: moment("00:00:00", "hh:mm A") }}
        disabledDate={disabledDate}
        disabledTime={disabledDateTime}
      />
    </Form.Item>
  );
};

export default ScheduleDateTimePicker;
