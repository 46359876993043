import client from "apis/config";

export const getSccCasesReports = async ({
  term = "",
  year = "",
  campus = "",
  type = "postive",
}) => {
  return await client.get(
    `scc/cases/report?campus=${campus}&year=${year}&term=${term}&type=${type}`
  );
};

export const getSccCasesMetaData = async () => {
  return await client.get("scc/cases/dashboard/meta");
};
