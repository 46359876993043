import React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";

function FinalRemarks({ remarks }) {
  if (remarks.length) {
    return (
      <div>
        <h6>Final Remarks</h6>
        <h4>{remarks[0]?.isRecommended ? "Recommended" : "Not Recommended"}</h4>
        <hr />
        <p style={{ overflowX: "clip" }}>{remarks[0]?.notes}</p>
        <div
          style={{
            backgroundColor: "#f0f0f0",
            padding: "2px 12px ",
            width: "100%",
            maxWidth: "350px",
            borderRadius: "5px",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CalendarOutlined style={{ marginRight: "10px" }} />
            <p style={{ margin: "7px 0" }}>
              {" "}
              {formateDateWithMoment(
                remarks[0].createdAt,
                "DD MMM YYYY hh:mm A"
              )}
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserOutlined style={{ marginRight: "10px" }} />
            <p style={{ margin: "7px 0" }}>
              {remarks[0].user?.name + " - " + remarks[0].user?.userName}
            </p>
          </div>
        </div>
      </div>
    );
  } else return null;
}

export default FinalRemarks;
