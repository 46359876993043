import { SyncJobActionKey } from "utils/constants";
import { UserActionKey } from "utils/constants";
const initialState = {
  syncJobs: [],
  syncJobTotal: 0,
  cashiers: [],
  loading: false,
};
const updateSyncJobHelper = (syncJobs, updatedItem) => {
  let newSyncJobs = [...syncJobs];
  const index = syncJobs.findIndex((el) => el.id === updatedItem.id);
  if (index > -1) {
    newSyncJobs[index] = updatedItem;
    return newSyncJobs;
  } else return syncJobs;
};
const SyncJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case SyncJobActionKey.getAllSyncJobs:
      return {
        ...state,
        loading: true,
      };
    case SyncJobActionKey.getSyncJobsSuccess:
      return {
        ...state,
        loading: false,
        syncJobs: action.payload.data,
        syncJobTotal: action.payload.total,
      };
    case SyncJobActionKey.getSyncJobsFailure:
      return {
        ...state,
        loading: false,
      };
    case SyncJobActionKey.searchSyncJobsReq:
      return {
        ...state,
        loading: true,
      };
    case SyncJobActionKey.searchSyncJobsSuccess:
      return {
        ...state,
        loading: false,
        syncJobs: action.payload.data,
        syncJobTotal: action.payload.total,
      };
    case SyncJobActionKey.searchSyncJobFailure:
      return {
        ...state,
        loading: false,
      };

    case SyncJobActionKey.addSyncJobReq:
      return {
        ...state,
        loading: true,
      };
    case SyncJobActionKey.addSyncJobSuccess:
      return {
        ...state,
        loading: false,
        syncJobs: [action.payload, ...state.syncJobs],
        syncJobTotal: state.syncJobTotal + 1,
      };
    case SyncJobActionKey.addSyncJobFailure:
      return {
        ...state,
        loading: false,
      };
    case SyncJobActionKey.editUserReq:
      return {
        ...state,
        loading: true,
      };
    case SyncJobActionKey.editSyncJobSuccess:
      return {
        ...state,
        loading: false,
        syncJobs: updateSyncJobHelper(state.syncJobs, action.payload),
      };
    case SyncJobActionKey.editUserFailure:
      return {
        ...state,
        loading: false,
      };
    case SyncJobActionKey.deleteSyncJobReq:
      return {
        ...state,
        loading: true,
      };
    case SyncJobActionKey.deleteSyncJobSuccess:
      return {
        ...state,
        loading: false,
        syncJobs: state.syncJobs.filter((el) => el.id !== action.payload),
        syncJobTotal: state.syncJobTotal - 1,
      };
    case SyncJobActionKey.deleteSyncJobFailure:
      return {
        ...state,
        loading: false,
      };

   

    default:
      return state;
  }
};
export default SyncJobReducer;
