import { Form } from "antd";
import "./style.css";
import CustomModal from "components/CustomModal/CustomModal";
import React, { useState } from "react";
import YearAndTermsForm from "./YearAndTermsForm";
import { showValidationError } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { toggleSccYearTerm } from "../api";
import { requestErrorHandel } from "utils/helpers/helpers";

const ExpandableYear = ({ props, record, data, reftech }) => {
  const [termYearForm] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTerm, setCurrentTerm] = useState({});
  const hasOpenTerm =
    data.filter(
      (term) =>
        term.firstTermStatus === "open" || term.secondTermStatus === "open"
    ).length > 0;

  const onViewLogs = (indentifer) => {
    props.history.push({
      pathname: `scc/term-logs`,
      state: { termId: record?.id, term: record, indentifer: indentifer },
    });
  };

  const onCloseForm = () => {
    setIsOpen(false);
    setCurrentTerm({});
  };

  const onSubmit = (values) => {
    onHandleTermOfYear(values?.notes);
  };

  // Function to handle opening/closing of term
  const handleTermToggle = async (term) => {
    setIsOpen(true);
    setCurrentTerm(term);
  };

  const onHandleTermOfYear = async (notes) => {
    try {
      setLoading(true);
      const payload = {
        yearId: record?.id,
        term: currentTerm,
        remarks: notes,
      };

      const response = await toggleSccYearTerm({ payload: payload });
      if (response.status === 200) {
        successMessage({ message: "Term has been active successfully" });
        setIsOpen(false);
        setLoading(false);
        reftech();
      }
      setIsOpen(false);
    } catch (error) {
      setIsOpen(false);
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  return (
    <div>
      <div className="expanded__row__header">
        <div className="expanded__row__header_column">
          <p>Term</p>
        </div>

        <div className="expanded__row__header_column">
          <p>Status</p>
        </div>
        <div className="expanded__row__header_column">
          <p>Open Cases</p>
        </div>
        <div className="expanded__row__header_column">
          <p>Completed Cases</p>
        </div>

        <div className="expanded__row__header_column">
          <p>Action</p>
        </div>
      </div>

      <div className="expanded__row">
        <div className="expanded__row__value">
          <p>Term 1</p>
        </div>
        <div className="expanded__row__value">
          <p>{record.firstTermStatus === "open" ? "Opened" : "Closed"}</p>
        </div>
        <div className="expanded__row__value">
          <p>{record.firstTermPendingCases}</p>
        </div>
        <div className="expanded__row__value">
          <p>{record.firstTermCompletedCases}</p>
        </div>
        <div className="expanded__row__value">
          <div className="expanded__row__values">
            <div
              className="terms__action_button"
              onClick={() => onViewLogs("Term1")}
            >
              Logs
            </div>
            <div
              className="terms__action_button"
              onClick={() => {
                if (hasOpenTerm && record?.firstTermStatus === "closed") {
                  return;
                }
                handleTermToggle("first");
              }}
              style={{
                cursor:
                  hasOpenTerm && record?.firstTermStatus === "closed"
                    ? "no-drop"
                    : "pointer",
                opacity:
                  hasOpenTerm && record?.firstTermStatus === "closed" ? 0.5 : 1,
              }}
            >
              {record.firstTermStatus === "open" ? "Close" : "Open"}
            </div>
          </div>
        </div>
      </div>
      <div className="expanded__row">
        <div className="expanded__row__value">
          <p>Term 2</p>
        </div>
        <div className="expanded__row__value">
          <p>{record.secondTermStatus === "open" ? "Opened" : "Closed"}</p>
        </div>
        <div className="expanded__row__value">
          <p>{record.secondTermPendingCases}</p>
        </div>
        <div className="expanded__row__value">
          <p>{record.secondTermCompletedCases}</p>
        </div>

        <div className="expanded__row__value">
          <div className="expanded__row__values">
            <div
              className="terms__action_button"
              onClick={() => onViewLogs("Term2")}
            >
              Logs
            </div>
            <div
              className="terms__action_button"
              onClick={() => {
                if (hasOpenTerm && record?.secondTermStatus === "closed") {
                  return;
                }
                handleTermToggle("second");
              }}
              style={{
                cursor:
                  hasOpenTerm && record?.secondTermStatus === "closed"
                    ? "no-drop"
                    : "pointer",
                opacity:
                  hasOpenTerm && record?.secondTermStatus === "closed"
                    ? 0.5
                    : 1,
              }}
            >
              {record.secondTermStatus === "open" ? "Close" : "Open"}
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        visibility={isOpen}
        handleCancel={onCloseForm}
        handleOk={() => null}
        title={`${
          (currentTerm === "first" && record.firstTermStatus === "open"
            ? "Close"
            : "Open") ||
          (currentTerm === "second" && record.secondTermStatus === "open"
            ? "Close"
            : "Open")
        } ${currentTerm === "first" ? "Term1" : "Term2"} of ${record?.name}`}
      >
        <YearAndTermsForm
          onSubmit={onSubmit}
          formName={termYearForm}
          loading={loading}
        />
      </CustomModal>
    </div>
  );
};

export default ExpandableYear;
