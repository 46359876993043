import EntranceTestScheduleDetails from "views/screens/enr_stages/entrance_test/EntranceTestScheduleDetails.js";
import ObservationDetails from "views/screens/enr_stages/observations/ObservationDetails.jsx";
import InterviewDetails from "views/screens/enr_stages/interview-details/InterviewDetails.jsx";
import RegistrationDetails from "../views/screens/enr_stages/registration/RegistrationDetails";
import PreFinalApproval from "views/screens/enr_stages/pre-final-approval/PreFinalApproval.jsx";
import ApprovalSteps from "views/screens/enr_stages/registration/ApprovalSteps.jsx";
import TranscriptReview from "views/screens/enr_stages/transcriptReview/TranscriptReview.jsx";
import RegistrationFee from "views/screens/enr_stages/registraionFee/RegistrationFee.jsx";
import FinalApproval from "views/screens/enr_stages/finalApproval/FinalApproval.jsx";
import AllApplications from "views/screens/all_applications/AllApplications.jsx";
import Review from "views/screens/other_application_stages/OtherApplicationsReview.jsx";
import OtherApplicationPreFinalApproval from "views/screens/other_application_stages/OtherApplicationsPreFinalApproval.jsx";
import OtherApplicationsFinalApproval from "views/screens/other_application_stages/OtherApplicationsFinalApproval.jsx";
import Payment from "views/screens/other_application_stages/OtherApplicationsPayment.jsx";
import ApplicationDetails from "views/screens/all_applications/ApplicationDetails.jsx";
import Logs from "views/screens/logs/Logs";

import allApplicationIcon from "../assets/img/sibar/allApplicationIcon.png";
import asrIcon from "../assets/img/sibar/ens-asr-icon.svg";
import trpIcon from "../assets/img/sibar/ens-trp-icon.svg";
import tfdIcon from "../assets/img/sibar/ens-tfd-icon.svg";
import updIcon from "../assets/img/sibar/ens-upd-icon.svg";
import enrIcon from "../assets/img/sibar/ens-enr-icon.svg";
import schIcon from "../assets/img/sibar/ens-sch-icon.svg";
import tccIcon from "../assets/img/sibar/ens-tcc-icon.svg";
import DiscountApplicationDetails from "views/screens/discount-applications/DiscountApplicationDetails";

const AllApplicationsRoutes = [
  {
    path: "/applications",
    name: "Applications",
    icon: allApplicationIcon,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "applications",
    subMenu: [
      {
        path: "/enr-applications",
        name: "New Enrollments",
        icon: enrIcon,
        component: AllApplications,
        state: { serviceType: "ENR" },
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Enrolment",
      },
      {
        path: "/trp-applications",
        name: "Transportation",
        icon: trpIcon,
        component: AllApplications,
        state: { serviceType: "TRP" },
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Transportation",
      },
      {
        path: "/asr-applications",
        name: "Additional Services Registration",
        icon: asrIcon,
        component: AllApplications,
        state: { serviceType: "ASR" },
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Additional Services",
      },
      {
        path: "/discount-applications",
        name: "Tuition Fees Discount",
        icon: tfdIcon,
        component: AllApplications,
        state: { serviceType: "TFD" },
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Discount",
      },
      {
        path: "/update-details-applications",
        name: "Update Personal Details",
        icon: updIcon,
        component: AllApplications,
        state: { serviceType: "UPD" },
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Update Details",
      },
      {
        path: "/sch-applications",
        name: "scholarships Details",
        icon: schIcon,
        component: AllApplications,
        state: { serviceType: "SCH" },
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Scholarships",
      },
      {
        path: "/tcc-applications",
        name: "TCC Courses Enrollments",
        icon: tccIcon,
        component: AllApplications,
        state: { serviceType: "TCC" },
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "TCC  Enrollments",
      },
      // {
      //   path: "/scholarship-details-applications",
      //   name: "scholarships Details",
      //   icon: schIcon,
      //   component: AllApplications,
      //   state: { serviceType: "SCH" },
      //   layout: "/admin",
      //   subPath: true,
      //   sideMenuTitle: "Scholarships",
      // },
    ],
  },
  // ---------- enr applications --------
  {
    path: "/enr-applications/details",
    name: "Admission Application Details",
    icon: "nc-icon nc-bag-16",
    component: ApplicationDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/enr-applications/registration_details",
    name: "Registration Details",
    icon: "nc-icon nc-bag-16",
    component: RegistrationDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/enr-applications/approval-steps",
    name: "Approval Steps",
    icon: "nc-icon nc-bag-16",
    component: ApprovalSteps,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/enr-applications/interview_details",
    name: "Interview Schedule",
    icon: "nc-icon nc-bag-16",
    component: InterviewDetails,
    layout: "/admin",
    subPath: true,
  },

  {
    path: "/enr-applications/observation_details",
    name: "Observation Schedule",
    icon: "nc-icon nc-bag-16",
    component: ObservationDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/enr-applications/entrance_test_details",
    name: "Entrance Test",
    icon: "",
    component: EntranceTestScheduleDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/enr-applications/pre_final_approval",
    name: "Pre Final Approval",
    icon: "",
    component: PreFinalApproval,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/enr-applications/transcript_review",
    name: "Transcript Review",
    icon: "",
    component: TranscriptReview,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/enr-applications/registration_fee",
    name: "Registration Fee",
    icon: "",
    component: RegistrationFee,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/enr-applications/final_approval",
    name: "Final Approval",
    icon: "",
    component: FinalApproval,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/enr-applications/logs",
    name: "Application Logs",
    icon: "nc-icon nc-settings",
    component: Logs,
    layout: "/admin",
    subPath: true,
  },
  // {
  //   path: "/enr-applications",
  //   name: "Admission Applications",
  //   icon: allApplicationIcon,
  //   component: AllApplications,
  //   state: { serviceType: "ENR" },
  //   layout: "/admin",
  //   subPath: true,
  // },
  // ---------- trp applications --------
  {
    path: "/trp-applications/details",
    name: "Transport Application Details",
    icon: "nc-icon nc-bag-16",
    component: ApplicationDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/trp-applications/review",
    name: "Review",
    icon: "",
    component: Review,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/trp-applications/prefinal-approval",
    name: "Pre Final Approval",
    icon: "",
    component: OtherApplicationPreFinalApproval,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/trp-applications/final-approval",
    name: "Final Approval",
    icon: "",
    component: OtherApplicationsFinalApproval,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/trp-applications/payment",
    name: "Payments",
    icon: "",
    component: Payment,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/trp-applications/approval-steps",
    name: "Approval Steps",
    icon: "nc-icon nc-bag-16",
    component: ApprovalSteps,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/trp-applications/logs",
    name: "Application Logs",
    icon: "nc-icon nc-settings",
    component: Logs,
    layout: "/admin",
    subPath: true,
  },
  // {
  //   path: "/trp-applications",
  //   name: "Transportation Applications",
  //   icon: trpIcon,
  //   component: AllApplications,
  //   state: { serviceType: "TRP" },
  //   layout: "/admin",
  //   subPath: true,
  // },

  // ---------- asr applications --------

  {
    path: "/asr-applications/details",
    name: "Additional Services Application Details",
    icon: "nc-icon nc-bag-16",
    component: ApplicationDetails,
    layout: "/admin",
    subPath: true,
  },

  {
    path: "/asr-applications/review",
    name: "Review",
    icon: "",
    component: Review,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/asr-applications/approval-steps",
    name: "Approval Steps",
    icon: "nc-icon nc-bag-16",
    component: ApprovalSteps,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/asr-applications/prefinal-approval",
    name: "Pre Final Approval",
    icon: "",
    component: OtherApplicationPreFinalApproval,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/asr-applications/final-approval",
    name: "Final Approval",
    icon: "",
    component: OtherApplicationsFinalApproval,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/asr-applications/payment",
    name: "Payments",
    icon: "",
    component: Payment,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/asr-applications/logs",
    name: "Application Logs",
    icon: "nc-icon nc-settings",
    component: Logs,
    layout: "/admin",
    subPath: true,
  },
  // {
  //   path: "/asr-applications",
  //   name: "ASR Applications",
  //   icon: asrIcon,
  //   component: AllApplications,
  //   state: { serviceType: "ASR" },
  //   layout: "/admin",
  //   subPath: true,
  // },
  // ---------- tfd applications --------
  // {
  //   path: "/discount-applications",
  //   name: "Discount Applications",
  //   icon: tfdIcon,
  //   component: AllApplications,
  //   state: { serviceType: "TFD" },
  //   layout: "/admin",
  //   subPath: true,
  // },
  {
    path: "/discount-applications/approval-steps",
    name: "Approval Steps",
    icon: "nc-icon nc-bag-16",
    component: ApprovalSteps,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/discount-applications/details",
    name: "Discount Applications",
    icon: tfdIcon,
    component: DiscountApplicationDetails,
    state: { serviceType: "TFD" },
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/tfd-applications/logs",
    name: "Application Logs",
    icon: "nc-icon nc-settings",
    component: Logs,
    layout: "/admin",
    subPath: true,
  },
  // ---------- udp applications --------
  {
    path: "/update-details-applications",
    name: "Update Personal Detail Applications",
    icon: allApplicationIcon,
    component: AllApplications,
    state: { serviceType: "UPD" },
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/update-details-applications/details",
    name: "Update Personal Details Application",
    icon: "nc-icon nc-bag-16",
    component: ApplicationDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/update-details-applications/approval-steps",
    name: "Approval Steps",
    icon: "nc-icon nc-bag-16",
    component: ApprovalSteps,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/update-details-applications/logs",
    name: "Application Logs",
    icon: "nc-icon nc-settings",
    component: Logs,
    layout: "/admin",
    subPath: true,
  },
  // ------------ sch applications ----------
  {
    path: "/sch-applications",
    name: "Scholarship Detail Applications",
    icon: allApplicationIcon,
    component: AllApplications,
    state: { serviceType: "SCH" },
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/sch-applications/details",
    name: "Scholarship Detail Applications",
    icon: "nc-icon nc-bag-16",
    component: ApplicationDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/sch-applications/logs",
    name: "Applications Logs",
    icon: "nc-icon nc-bag-16",
    component: Logs,
    layout: "/admin",
    subPath: true,
  },
  // ------------ TCC applications ----------
  {
    path: "/tcc-applications",
    name: "TCC Detail Applications",
    icon: allApplicationIcon,
    component: AllApplications,
    state: { serviceType: "SCH" },
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/tcc-applications/details",
    name: "TCC Details Applications",
    icon: "nc-icon nc-bag-16",
    component: ApplicationDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/tcc-applications/logs",
    name: "Applications Logs",
    icon: "nc-icon nc-bag-16",
    component: Logs,
    layout: "/admin",
    subPath: true,
  },
];
export default AllApplicationsRoutes;
