import eventsIcon from "../assets/img/sibar/requestStatusIcon.png";
import cmsIcon from "../assets/img/sibar/ens-enr-icon.svg";
import newsIcon from "../assets/img/sibar/ens-asr-icon.svg";
import vacancyIcon from "../assets/img/sibar/ens-upd-icon.svg";

import TemplateHeader from "views/html-templates/template-header/TemplateHeader";
import TemplateFooter from "views/html-templates/template-footer/TemplateFooter";
import Template from "views/html-templates/template-body/Template";

const HtmlTemplatesRoutes = [
  {
    path: "/htmlTemplates",
    name: "HTML Templates",
    icon: cmsIcon,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "template",
    subMenu: [
      {
        path: "/headers",
        name: "Headers",
        icon: eventsIcon,
        component: TemplateHeader,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Headers",
      },
      {
        path: "/footers",
        name: "Footers",
        icon: newsIcon,
        component: TemplateFooter,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Footers",
      },
      {
        path: "/template",
        name: "Template",
        icon: vacancyIcon,
        component: Template,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Template",
      },
    ],
  },
];

export default HtmlTemplatesRoutes;
