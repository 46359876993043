import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select } from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import ScoringSystemForm from "components/Forms/sch-evalution-forms/ScoringSystemForm";
import { fetchAllScoringSystems } from "apis/SCH/schRequests";
import { addNewScoringSystem } from "apis/SCH/schRequests";
import { updateScoringSystem } from "apis/SCH/schRequests";
import { deleteScoringSystem } from "apis/SCH/schRequests";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const ScoringSystems = () => {
  const _requestTypes = useSelector((state) => state.metaData.requestTypes);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [id, setID] = useState("");

  const [scoringSystemForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            scoringSystemForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Scoring Systems"}
          headerSubTitle={"Manage Scoring Systems"}
          headerBtnTitle={"Add New"}
        />
        {/* <Row gutter={10}>
          <Col md={12}>
            <Select
              style={{ width: 170, marginRight: 50 }}
              value={appType}
              onChange={(e) => {
                setAppType(e);
              }}
            >
              <Option key={"#"} value={""}>
                All
              </Option>
              {_requestTypes?.map((el, i) => {
                return (
                  <Option key={i} value={`${el.identifier}`}>
                    {el.identifier}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row> */}
      </>
    );
  };
  useEffect(() => {
    getAllScoringSystems();
  }, []);

  const getAllScoringSystems = async () => {
    try {
      setLoading(true);
      const response = await fetchAllScoringSystems();
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const addScoringSystem = async (data) => {
    try {
      setLoading(true);
      const response = await addNewScoringSystem({ data: data });
      if (response.status === 200) {
        successMessage({
          message: "Scoring system added successfully",
        });
        await getAllScoringSystems();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateScoringSystem = async (data) => {
    try {
      setLoading(true);
      const response = await updateScoringSystem({ id: id, data: data });
      if (response.status === 200) {
        successMessage({
          message: "Scoring system updated successfully",
        });
        await getAllScoringSystems();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteScoringSystem = async (id) => {
    try {
      setLoading(true);
      const response = await deleteScoringSystem({ id: id });
      if (response.status === 200) {
        successMessage({
          message: "Scoring system deleted successfully",
        });
        await getAllScoringSystems();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    let pointsArray = [];

    if (values.isRange) {
      for (const key in values) {
        if (key.startsWith("from_")) {
          const fromKey = key;
          const toKey = "to_" + key.substring(5);
          const pointsKey = "points_" + key.substring(5);

          pointsArray.push({
            from: parseFloat(values[fromKey]),
            to: parseFloat(values[toKey]),
            points: parseInt(values[pointsKey]),
          });
        }
      }
    } else {
      for (const key in values) {
        if (key.startsWith("letter_")) {
          const pointsKey = key.replace("letter_", "points_");
          const letter = values[key];
          const points = values[pointsKey];

          pointsArray.push({
            letter,
            points: parseInt(points),
          });
        }
      }
    }

    const newValues = {
      ...values,
      isRange: values.isRange ? values.isRange : false,
      minValue: !isNaN(Number(values.minValue)) ? Number(values.minValue) : 0,
      maxValue: !isNaN(Number(values.maxValue)) ? Number(values.maxValue) : 0,
      points: pointsArray,
    };

    if (modalTitle === ModalType.EDIT) {
      await handleUpdateScoringSystem(newValues);
    } else {
      await addScoringSystem(newValues);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    const scoringOptionFields = record?.scores.reduce((field, score) => {
      const { id, letter, from, to, points } = score;
      field[`from_${id}`] = from;
      field[`to_${id}`] = to;
      field[`points_${id}`] = points;
      field[`letter_${id}`] = letter;
      return field;
    }, {});
    scoringSystemForm.setFieldsValue({
      ...record,
      ...scoringOptionFields,
    });
    setID(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
              render: (record) => (
                <p className="answer_para">
                  {record.replace(/(<([^>]+)>)/gi, "")}
                </p>
              ),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              // width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Scoring System">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this scoring system?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteScoringSystem(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Scoring System`}
        >
          <ScoringSystemForm
            onSubmit={onSubmit}
            formName={scoringSystemForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            _requestTypes={_requestTypes}
            modalTitle={modalTitle}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default ScoringSystems;
