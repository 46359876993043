import React, { useState } from "react";
import { Input } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import { set } from "lodash";
import { useEffect } from "react";
const { TabPane } = Tabs;
function EditableJSON(props) {
  const [data, setData] = useState(props.data);
  const [editingKey, setEditingKey] = useState("");

  const handleClick = (key) => {
    setEditingKey(key);
  };

  const handleChange = (key, value) => {
    const newData = set({ ...data }, key, value);
    setData(newData);
  };

  const handleSave = (key) => {
    setEditingKey("");
    props.onUpdate(data);
  };

  const handleCancel = () => {
    setData(props.data);
    setEditingKey("");
  };
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const renderParagraphs = (obj, path = "") => {
    if (obj === undefined || obj === null) return;
    return Object.entries(obj).map(([key, value]) => {
      const nestedPath = path + key;
      if (typeof value === "object") {
        return (
          <div key={nestedPath}>
            <h5>{key}</h5>
            {renderParagraphs(value, nestedPath + ".")}
          </div>
        );
      }
      return (
        <div className="form-group row" key={nestedPath}>
          <label className="col-sm-2 col-form-label">{key}</label>
          <div className="col-sm-8">
            {editingKey === nestedPath ? (
              <div className="input-group">
                <Input
                  defaultValue={value}
                  onChange={(e) => handleChange(nestedPath, e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => handleSave(nestedPath)}
                  >
                    &#x2714;
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCancel}
                  >
                    &#x2716;
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0">{value}</p>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => handleClick(nestedPath)}
                >
                  &#x270E;
                </button>
              </div>
            )}
          </div>
        </div>
      );
    });
  };
  const renderTabs = () => {
    const mainKeys = Object?.keys(data);
    return (
      <Tabs defaultActiveKey={mainKeys[0]}>
        {mainKeys.map((key) => (
          <TabPane tab={key} key={key}>
            {renderParagraphs(data[key], key + ".")}
          </TabPane>
        ))}
      </Tabs>
    );
  };

  return (
    <div className="container my-4">
      {/* <iframe src="https://www.ens.sch.ae/" width="1200" height="800" /> */}
      <h1>Editable JSON</h1>
      {renderTabs()}
    </div>
  );
}

export default EditableJSON;
