import { Button, Card, Popconfirm, Tooltip } from "antd";
import React from "react";
import "./style.css";
import { DeleteOutlined } from "@ant-design/icons";

function TCCCourseAssignedReadOnlySection({ section }) {
  if (Object.keys(section).length > 0) {
    return (
      <div className="section__container">
        <div className="section_title__continer">
          <div className="title_body">
            <p className="section_title">Section</p>
          </div>
          <div className="title_body">
            <p className="section_title">Teacher</p>
          </div>
          <div className="title_body">
            <p className="section_title">Schedule</p>
          </div>
          <div className="title_body">
            <p className="section_title">Location</p>
          </div>
          <div className="title_body">
            <p className="section_title">School</p>
          </div>
        </div>
        <div className="section_values__continer">
          <div className="title_body">
            <p className="section_title">{section?.title}</p>
          </div>
          <div className="title_body">
            <p className="section_title">
              {section?.teacher?.firstName +
                " " +
                section?.teacher?.middleName +
                " " +
                section?.teacher?.lastName}
            </p>
          </div>
          <div className="title_body">
            <p className="section_title">{section?.schedule?.title}</p>
          </div>
          <div className="title_body section_location">
            <p className="section_title">{section?.location}</p>
          </div>
          <div className="title_body ">
            <p className="section_title">
              {section?.school?.abbreviation.toUpperCase()}
            </p>
          </div>
        </div>
      </div>
    );
  } else return null;
}

export default TCCCourseAssignedReadOnlySection;
