/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
    Button,
    Form,
    Popconfirm,
    Row,
    Select,
    Spin,
    Tooltip,
    Input,
    Checkbox,
    Upload
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import { NotificationManager } from "react-notifications";

import { _addNewDocumentConfiguration, _deleteDocumentConfiguration, _getAllDocumentsListsConfiguration, _updateDocumentConfiguration } from "actions/documents-configurations/documentsConfigurationsAction";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
import { addNewDocumentConfigurationAPI, allDocumentsListConfiguration, deleteDocumentConfigurationAPI, updateDocumentConfigurationAPI } from "apis/document-list-configuration/documentListConfigurationApis";
import { getEServicesAPI } from "apis/e-services-api/EServicesApi";
import { getAllQrAllowedUsersList, getQrTypeList } from "apis/qr-configuration/qrConfigurationApis";
import { renderLoader, requestErrorHandel, successMessage } from "utils/helpers/helpers";
const { Option } = Select;

const ModalType = {
  ADD: "Add Qr Configuration",
  EDIT: "Update Qr Configuration",
};

// Function to convert file to base64
const getBase64 = (file) => {

  return new Promise((resolve, reject) => {
  
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const QrTypeValidator = (props) => {
  const dispatch = useDispatch();

  const applicationType = props.location.state?.serviceName;

  const [form] = Form.useForm();
  const [showModal, toggleModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  const [editId, setEditId] = React.useState("");
  const [loading, setLoading] = React.useState();

  const [selectedCampuses, setSelectedCampuses] = React.useState([]);
  const [selectedSchools, setSelectedSchools] = React.useState([]);
  const [selectedGrades, setSelectedGrades] = React.useState([]);
  const [selectedDocuments, setSelectedDocuments] = React.useState([]);
  const [mandatory, setMandatory] = React.useState([]);
  const [selectedMandatory, setSelectedMandatory] = React.useState([]);
  const [allowNotAvailable, setAllowNotAvailable] = React.useState([]);
  const [selectedAllowNotAvailable, setSelectedAllowNotAvailable] =
    React.useState([]);
  const [campusesFilteredSchools, setCampusesFilteredSchools] = React.useState(
    []
  );
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _allSchools = useSelector((state) => state.metaData.schools);
  const _grades = useSelector((state) =>
    state.metaData.grades.filter((grade) => grade.code !== "GRADUATED")
  );
  const _allDocumentsLists = useSelector((state) => state.metaData.documents);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const _docConfigurationsList = useSelector(
    (state) => state.documentsListConfig.documentsListConfiguration
  );
  const _totalRecords = useSelector((state) => state.documentsListConfig.total);

  const _eServices = useSelector((state) =>
    state.eservices?.data?.filter(
      (eService) => eService?.applicationType === "ASR"
    )
  );
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [usersWithValidatorAccess, setUsersWithValidatorAccess] =
    React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedValidatorType, setSelectedValidatorType] =
    React.useState(null);

  const _validatorTypes = useSelector((state) => state.validatorTypes);
  const [validatorTypes, setValidatorTypes] = React.useState([]);
  // Fetch users with validator access
  const fetchUsersWithValidatorAccess = async () => {
    try {
      const response = await getAllQrAllowedUsersList();
      const response2 = await getQrTypeList();
      if (response.status === 200) {
        setValidatorTypes(response2.data.data);
        setUsersWithValidatorAccess(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching users:", error);
    }
  };

  const fetchRecords = async (page) => {
    setCurrentPage(page);
  };

  const fetchEServices = async () => {
    try {
      const paginationParams = `page=${1}&limit=${1000}`;
      const eServiceResponse = await getEServicesAPI(paginationParams);
      if (eServiceResponse.data && eServiceResponse.status === 200) {
        dispatch(_getEServicesAction(eServiceResponse.data));
      } else throw eServiceResponse.error;
    } catch (e) {
      console.log({ e });
    }
  };

  React.useEffect(() => {
    //   fetchAllDocumentsListConfigurations();
    //   dispatch(getStageMetaDataAction());
    fetchUsersWithValidatorAccess(); // Fetch users on component mount
  }, [currentPage]);
  // React.useEffect(() => {
  //   applicationType === "ASR" && fetchEServices();
  // }, []);

  const fetchAllDocumentsListConfigurations = async () => {
    try {
      setLoading(true);
      const queryParams = `?applicationType=${applicationType}&page=${currentPage}&limit=${pageSize}`;
      const response = await allDocumentsListConfiguration(queryParams);
      if (response.status == 200) {
        setLoading(false);
        // setTotalRecords(response.data.total);
        dispatch(_getAllDocumentsListsConfiguration(response.data));
      } else throw response;
    } catch (error) {
      dispatch(_getAllDocumentsListsConfiguration({ data: [], total: 0 }));
      setLoading(false);
    }
  };

  let selectedCampusesesIds = [];

  // React.useEffect(() => {
  //   filterSchoolsByCampus();
  // }, [selectedCampuses]);

  // React.useEffect(() => {
  //   if (campusesFilteredSchools?.length > 0 && applicationType === "TFD") {
  //     setSelectedSchools(campusesFilteredSchools);
  //   }
  // }, [campusesFilteredSchools]);

  const filterSchoolsByCampus = () => {
    selectedCampuses.forEach((campus) => {
      selectedCampusesesIds.push(campus.id);
    });
    setCampusesFilteredSchools(
      _allSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
  };

  const onEdit = (item) => {
    toggleModal(true);
    setModalTitle(ModalType.EDIT);
    setEditId(item.id);
    form.setFieldsValue({
      title: item.title,
      titleAr: item.titleAr,
      enableDownload: item.enableDownload,
      hasExpiry: item.hasExpiry,
      expiryValue:item.expiryValue,
      background_image: item.background_image,
      school_logo: item.school_logo,
      arrow_icon: item.arrow_icon,
      verify_icon: item.verify_icon,
    });
  };

  const onAdd = () => {
    setModalTitle(ModalType.ADD);
    form.resetFields();
    toggleModal(true);
  };

  const onSubmit = (value) => {
    if (!value.title || !value.titleAr) {
      NotificationManager.error("Please submit all required fields");
      return;
    }

    const params = {
      title: value.title,
      titleAr: value.titleAr,
      enableDownload: value.enableDownload,
      hasExpiry: value.hasExpiry,
      expiryValue:value.expiryValue,
      background_image: value.background_image,
      school_logo: value.school_logo,
      arrow_icon: value.arrow_icon,
      verify_icon: value.verify_icon,
    };

    modalTitle === ModalType.ADD
      ? addValidatorType(params)
      : editValidatorType(editId, params);
  };

  const fetchValidatorTypes = async () => {
    try {
      const response = await fetch(`${baseURL}/validation-type?page=1&limit=10`);
      if (response.ok) {
        const data = await response.json();
        setValidatorTypes(data.data);
      } else {
        throw new Error("Failed to fetch validator types");
      }
    } catch (error) {
      console.error("Error fetching validator types:", error);
    }
  };

  const addValidatorType = async (newType) => {
    try {
      const response = await fetch(`${baseURL}/validation-type`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newType),
      });
      if (response.ok) {
        fetchValidatorTypes(); // Refresh the list
        successMessage({ message: "Validator type added!" });
        toggleModal(false);

      } else {
        throw new Error("Failed to add validator type");

      }
    } catch (error) {
      console.error("Error adding validator type:", error);

    }
  };

  const editValidatorType = async (id, updatedType) => {
    try {
      const response = await fetch(`${baseURL}/validation-type/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedType),
      });
      if (response.ok) {
        fetchValidatorTypes(); // Refresh the list
        successMessage({ message: "Validator type updated!" });
        toggleModal(false);
        setEditId("");
      } else {
        throw new Error("Failed to update validator type");
      }
    } catch (error) {
      console.error("Error updating validator type:", error);
    }
  };

  const deleteValidatorType = async (id) => {
    try {
      const response = await fetch(`${baseURL}/validation-type/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        fetchValidatorTypes(); // Refresh the list
        successMessage({ message: "Validator type deleted!" });
      } else {
        throw new Error("Failed to delete validator type");
      }
    } catch (error) {
      console.error("Error deleting validator type:", error);
    }
  };

  React.useEffect(() => {
    fetchValidatorTypes(); // Fetch validator types on component mount
  }, []);

  const onFileChange = async (info, fieldName) => {
    console.log(info) 
    const file = info?.fileList?.[0]?.originFileObj || info?.file?.originFileObj;
    if (file) {

      const base64 = await getBase64(file);
      form.setFieldsValue({ [fieldName]: base64 });
    }
  };

  const renderBase64Image = (base64String) => {
    if (base64String) {
      return <img src={base64String} alt="icon" style={{ width: '50px', height: '50px' }} />;
    }
    return null; 
  };
  
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          key={"#"}
          loading={loading}
          data={validatorTypes}
          totalRecord={validatorTypes.length}
          fetchRecords={fetchValidatorTypes}
          header={() => (
            <TableHeader
              headerTitle="Validator Types"
              headerSubTitle="Manage validator types"
              headerBtnTitle="Add New"
              onAddNew={() => onAdd()} // Adjust onAdd to handle validator types
            />
          )}
          columns={[
            {
              key: "title",
              title: "Title",
              dataIndex: "title",
            },
            {
              key: "titleAr",
              title: "Title (Arabic)",
              dataIndex: "titleAr",
            },
            {
              key: "enableDownload",
              title: "Enable Download",
              dataIndex: "enableDownload",
              render: (value) => (value ? "Yes" : "No"),
            },
            {
              key: "hasExpiry",
              title: "Has Expiry",
              dataIndex: "hasExpiry",
              render: (value) => (value ? "Yes" : "No"),  
            },
            {
              key: "expiryValue",
              title: "Expiry Value",
              dataIndex: "expiryValue",
            },
            {
              key: "background_image",
              title: "Background Image",
              dataIndex: "background_image",
              render: renderBase64Image,

            },
            {
              key: "school_logo",
              title: "School Logo",
              dataIndex: "school_logo",
              render: renderBase64Image,

            },
            {
              key: "arrow_icon",
              title: "Arrow Icon",
              dataIndex: "arrow_icon",
              render: renderBase64Image,

            },
            {
              key: "verify_icon",
              title: "Verify Icon",
              dataIndex: "verify_icon",
              render: renderBase64Image,

            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onEdit(item)} // Adjust onEdit to handle validator types
                      />
                    </Tooltip>
                  </div>

                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => deleteValidatorType(item.id)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        ></CommonTable>
        {/* Add/Edit Modal for Validator Types */}
        <CustomModal
          visibility={showModal}
          handleCancel={() => {
            toggleModal(false);
          }}
          title={editId !== "" ? "Update Validator Type" : "Add Validator Type"}
        >
          <Form form={form} layout="vertical" size="large" onFinish={onSubmit}>
            <Form.Item label={"Title"} name={"title"} rules={[{ required: true, message: 'Please enter the title' }]}>
              <Input placeholder="Enter title" />
            </Form.Item>
            <Form.Item label={"Title (Arabic)"} name={"titleAr"} rules={[{ required: true, message: 'Please enter the title in Arabic' }]}>
              <Input placeholder="Enter title in Arabic" />
            </Form.Item>
            <div style={{display:"grid",gridTemplateColumns:"1fr 1fr",width:"100%"}}>
            <Form.Item label={"Enable Download"} name={"enableDownload"} valuePropName="checked">
              <Checkbox>Enable Download</Checkbox>
            </Form.Item>
            <Form.Item label={"Has Expiry"} name={"hasExpiry"} valuePropName="checked">
              <Checkbox>Has Expiry</Checkbox>
            </Form.Item>
            </div>
            <Form.Item  label={"Expiry Value"} name={"expiryValue"} placeholder="Select Expiry Value">
              <Select style={{border:"1px solid #d9d9d9"	}}>
                <Option value={"1 Day"}>1 Day</Option>
                <Option value={"1 Week"}>1 Week</Option>
                <Option value={"2 Week"}>2 Week </Option>
                <Option value={"1 Month"}>1 Month </Option>
                <Option value={"2 Month"}>2 Month </Option>
                <Option value={"3 Month"}>3 Month </Option>
                <Option value={"1 Year"}>1 Year </Option>
                <Option value={"2 Year"}>2 Year </Option>
                <Option value={"3 Year"}>3 Year </Option>
              </Select>
            </Form.Item>

            <div style={{display:"grid",gridTemplateColumns:"1fr 1fr"}}>

            <Form.Item label={"Background Image"} name={"background_image"}>
              <Upload
                listType="picture"
                maxCount={1}
                onChange={(info) => onFileChange(info, "background_image")}
              >
                <Button style={{width:"250px"}} icon={<UploadOutlined />}>Upload Background Image</Button>
              </Upload>
            </Form.Item>
            <Form.Item label={"School Logo"} name={"school_logo"}>
              <Upload
                listType="picture"
                maxCount={1}
                onChange={(info) => onFileChange(info, "school_logo")}
              >
                <Button style={{width:"250px"}} icon={<UploadOutlined />}>Upload School Logo</Button>
              </Upload>
            </Form.Item>
            <Form.Item label={"Arrow Icon"} name={"arrow_icon"}>
              <Upload
                listType="picture"
                maxCount={1}
                onChange={(info) => onFileChange(info, "arrow_icon")}
              >
                <Button style={{width:"250px"}} icon={<UploadOutlined />}>Upload Arrow Icon</Button>
              </Upload>
            </Form.Item>
            <Form.Item label={"Verify Icon"} name={"verify_icon"}>
              <Upload
                listType="picture"
                maxCount={1}
                onChange={(info) => onFileChange(info, "verify_icon")}
              >
                <Button style={{width:"250px"}} icon={<UploadOutlined />}>Upload Verify Icon</Button>
              </Upload>
            </Form.Item>
            </div>

            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving" : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default QrTypeValidator;