import React from "react";
import "./style.css";
import { Button, Row, Tooltip } from "antd";
import {
  CheckCircleOutlined,
  CheckOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { formatedDate } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { getFullDayName } from "utils/helpers/helpers";

const ExpandedTableRow = ({
  serviceType,
  onAproveMultipleSchedule,
  record,
}) => {
  const schedules = record?.schedules;

  const details = schedules[0]?.details;

  const uniqueDays =
    details && details.length
      ? [...new Set(details.map((item) => item.dayOfWeek))].filter(Boolean)
      : [];

  if (serviceType === "EVB") {
    return (
      <div>
        <div className="expanded__row__header">
          <div className="expanded__row__header_column">
            <p>Refrence No</p>
          </div>
          {schedules[0]?.eventType && (
            <div className="expanded__row__header_column">
              <p>Event Type</p>
            </div>
          )}
          <div className="expanded__row__header_column">
            <p>Schedule</p>
          </div>
          <div className="expanded__row__header_column">
            <p>Open Hours</p>
          </div>

          <div className="expanded__row__header_column">
            <p>Number of Attendees</p>
          </div>
          <div className="expanded__row__header_column">
            <p>Status</p>
          </div>
        </div>
        {schedules.map((item, index) => {
          return (
            <div className="expanded__row" key={index}>
              <div className="expanded__row__value">
                <p>{item?.title}</p>
              </div>
              {item?.eventType && (
                <div className="expanded__row__value">
                  <p>{item?.eventType?.title}</p>
                </div>
              )}
              <div className="expanded__row__value">
                <p>{formatedDate(item?.startAt)}</p>
              </div>
              <div className="expanded__row__value">
                {item?.scheduleType === "slots" ? (
                  item.details.map((detail, index) => (
                    <p key={index}>
                      {formatedDateWithAmAndPm(detail.startTime)} -{" "}
                      {formatedDateWithAmAndPm(detail.endTime)}
                    </p>
                  ))
                ) : (
                  <p>Full Day</p>
                )}
              </div>
              <div className="expanded__row__value">
                <p>{item.noOfAttendees}</p>
              </div>
              <div className="expanded__row__value">
                <p style={{ textTransform: "capitalize" }}>{item.status}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else if (serviceType === "LTB") {
    return (
      <div>
        <div className="expanded__row__header">
          <div className="expanded__row__header_column">
            <p>Refrence No</p>
          </div>
          {schedules[0]?.eventType && (
            <div className="expanded__row__header_column">
              <p>Event Type</p>
            </div>
          )}
          <div className="expanded__row__header_column">
            <p>Date Range</p>
          </div>
          <div className="expanded__row__header_column">
            <p>Days</p>
          </div>
          <div className="expanded__row__header_column">
            <p>Number of Attendees</p>
          </div>

          <div className="expanded__row__header_column">
            <p>Status</p>
          </div>
        </div>
        {schedules.map((item, index) => {
          return (
            <div className="expanded__row" key={index}>
              <div className="expanded__row__value">
                <p>{item.title}</p>
              </div>
              {item?.eventType && (
                <div className="expanded__row__value">
                  <p>{item?.eventType?.title}</p>
                </div>
              )}
              <div className="expanded__row__value">
                <p>
                  {formatedDate(item.startAt)} <br />
                  {formatedDate(item.endAt)}
                </p>
              </div>
              <div className="expanded__row__value">
                {uniqueDays?.map((day, index) => {
                  return <p key={index}>{getFullDayName(day)}</p>;
                })}
              </div>
              <div className="expanded__row__value">
                <p>{item.noOfAttendees}</p>
              </div>
              <div className="expanded__row__value">
                <p style={{ textTransform: "capitalize" }}>{item.status}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <div>
        <div className="expanded__row__header">
          <div className="expanded__row__header_column">
            <p>Refrence No</p>
          </div>

          <div className="expanded__row__header_column">
            <p>Schedule</p>
          </div>
          {schedules[0]?.eventType && (
            <div className="expanded__row__header_column">
              <p>Event Type</p>
            </div>
          )}
          <div className="expanded__row__header_column">
            <p>Slots</p>
          </div>
          <div className="expanded__row__header_column">
            <p>Status</p>
          </div>
        </div>
        {schedules.map((item, index) => {
          return (
            <div className="expanded__row" key={index}>
              <div className="expanded__row__value">
                <p>{item?.title}</p>
              </div>
              <div className="expanded__row__value">
                <p>{formatedDate(item?.startAt)}</p>
              </div>
              {item?.eventType && (
                <div className="expanded__row__value">
                  <p>{item?.eventType?.title}</p>
                </div>
              )}
              <div className="expanded__row__value">
                {item?.scheduleType === "slots" ? (
                  item.details.map((detail, index) => (
                    <p key={index}>
                      {formatedDateWithAmAndPm(detail.startTime)} -{" "}
                      {formatedDateWithAmAndPm(detail.endTime)}
                    </p>
                  ))
                ) : (
                  <p>Full Day</p>
                )}
              </div>
              <div className="expanded__row__value">
                <p style={{ textTransform: "capitalize" }}>{item.status}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

export default ExpandedTableRow;
