import React, { useState } from "react";
import "./style.css";
import { renderEventsBackgroundColor } from "utils/helpers/helpers";
import { calculateTopAndHeight } from "utils/helpers/helpers";

import { BOOKING_TIME } from "utils/helpers/helpers";
import { renderEventTitleFromIndentifer } from "utils/helpers/helpers";
import { getFontSizesForEvent } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { onViewBookingDetails } from "utils/helpers/helpers";
import { Button, Col, Row } from "antd";
import CustomModal from "components/CustomModal/CustomModal";
import { formatedDate } from "utils/helpers/helpers";

const DailySchedule = ({ dailyBookings, props }) => {
  const [openModal, setOpenModal] = useState(false);
  const [_booking, setBooking] = useState({});

  const renderFullDayBookings = () => {
    const fullDayBookings = dailyBookings.filter(
      (b) => b.startTime === undefined && b.endTime === undefined
    );
    return (
      <>
        {fullDayBookings.map((booking) => {
          return (
            <div
              className="full_day_schedule_container"
              style={{
                backgroundColor: renderEventsBackgroundColor(
                  booking?.booking?.bookingType?.identifier
                ),
                height: 28,
                display: "flex",
                width: "89.6%",
                left: "10.5%",
              }}
              key={booking.id}
              onClick={() => handleClickOnBooking(booking)}
            >
              <div className="single_schedule_detail">
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  {booking?.booking?.title} -{" "}
                  {renderEventTitleFromIndentifer(
                    booking?.booking?.bookingType?.identifier
                  )}
                </p>
              </div>
              <div className="single_schedule_detail_timing">
                <p
                  style={{
                    fontSize: 12,
                  }}
                >
                  Full Day
                </p>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const singleBookingSchedule = () => {
    const filteredBookings = dailyBookings.filter(
      (booking) =>
        booking.startTime !== undefined && booking.endTime !== undefined
    );

    const bookingsBySlot = {};

    // Group bookings by start and end time
    dailyBookings.forEach((booking) => {
      const key = `${booking.startTime}-${booking.endTime}`;
      if (!bookingsBySlot[key]) {
        bookingsBySlot[key] = [];
      }
      bookingsBySlot[key].push(booking);
    });

    Object.keys(bookingsBySlot).forEach((slotKey) => {
      const bookingsInSlot = bookingsBySlot[slotKey];
    });

    return (
      <>
        {filteredBookings.map((booking) => {
          const bookingHeightAndWidth = calculateTopAndHeight(
            booking,
            filteredBookings
          );
          const { titleFontSize, subtitleFontSize, timeFontSize } =
            getFontSizesForEvent(bookingHeightAndWidth);

          const slotKey = `${booking.startTime}-${booking.endTime}`;
          const bookingsCount = (bookingsBySlot[slotKey] || []).length;
          return (
            <div
              className="single_schedule_container"
              style={{
                top: bookingHeightAndWidth.top + "px",
                backgroundColor: renderEventsBackgroundColor(
                  booking?.booking?.bookingType?.identifier
                ),
                height: bookingHeightAndWidth.height + "px",
                display: bookingHeightAndWidth.height < 40 ? "flex" : "",
                width: bookingHeightAndWidth.width + "%",
                left: bookingHeightAndWidth.left + "%",
              }}
              key={booking.id}
              onClick={() => handleClickOnBooking(booking)}
            >
              {/* {SessionDetailsOnHover(booking)} */}
              <div className="single_schedule_detail">
                {bookingsCount > 5 ? (
                  <p
                    style={{
                      fontSize: titleFontSize,
                    }}
                  >
                    {renderEventTitleFromIndentifer(
                      booking?.booking?.bookingType?.identifier
                    )}
                  </p>
                ) : bookingsCount > 10 ? (
                  <p
                    style={{
                      fontSize: titleFontSize,
                    }}
                  >
                    {booking?.booking?.bookingType?.identifier}
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: titleFontSize,
                    }}
                  >
                    {booking?.booking?.title} -{" "}
                    {renderEventTitleFromIndentifer(
                      booking?.booking?.bookingType?.identifier
                    )}
                  </p>
                )}
              </div>

              {bookingsCount > 5 ? null : (
                <div className="single_schedule_detail_timing">
                  {booking.startTime === undefined &&
                  booking.endTime === undefined ? (
                    <p
                      style={{
                        fontSize: timeFontSize,
                      }}
                    >
                      Full Day
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: timeFontSize,
                      }}
                    >
                      {formatedDateWithAmAndPm(booking.startTime)} -{" "}
                      {formatedDateWithAmAndPm(booking.endTime)}
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const SessionDetailsOnHover = (booking) => {
    return (
      <div className="single_event_full_detail">
        <div className="single_event_inner_detail">
          <p>{booking?.booking?.title}</p>
          <p>
            {renderEventTitleFromIndentifer(
              booking?.booking?.bookingType?.identifier
            )}
          </p>
          <p>
            {formatedDateWithAmAndPm(booking.startTime)} -{" "}
            {formatedDateWithAmAndPm(booking.endTime)}
          </p>

          <div
            className="details__button_schedule"
            onClick={() => onViewBookingDetails(booking, props)}
          >
            Details
          </div>
        </div>
      </div>
    );
  };

  const handleClickOnBooking = (booking) => {
    setBooking(booking);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onShowBookingDetails = (booking) => {
    onViewBookingDetails(booking, props);
  };

  return (
    <div className="booking_schedule__container">
      <div>{renderFullDayBookings()}</div>
      <div className="booking_schedule__layout">
        {singleBookingSchedule()}
        {BOOKING_TIME.map((item) => {
          return (
            <div className="booking_schedule__body" key={item.id}>
              <div className="booking_schedule__left">
                <p>{item.time}</p>
              </div>
              <div className="booking_schedule__right"></div>
            </div>
          );
        })}
      </div>
      <CustomModal
        visibility={openModal}
        handleCancel={onCloseModal}
        handleOk={() => null}
        title={"Booking Schedule"}
        modalSmall={true}
      >
        {/* {Object.keys(_booking).length > 0 && (
          <div>
            <Row gutter={20}>
              <Col md={24}>
                <p>
                  {_booking?.booking?.title} -{" "}
                  {renderEventTitleFromIndentifer(
                    _booking?.booking?.bookingType?.identifier
                  )}
                </p>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col md={24}>
                <p> {formatedDate(_booking?.startAt)}</p>
              </Col>
            </Row>

            {_booking?.scheduleType === "slots" ? (
              <Row gutter={20}>
                <Col md={24}>
                  {formatedDateWithAmAndPm(_booking?.startTime)} -
                  {formatedDateWithAmAndPm(_booking?.endTime)}
                </Col>
              </Row>
            ) : (
              <Row gutter={20}>
                <Col md={24}>Full Day</Col>
              </Row>
            )}
            <br />
            <div>
              <h4>User Information</h4>
              <div className="booking_user_details">
                <p>
                  Full Name:{" "}
                  <span>
                    {_booking?.booking?.user?.firstName +
                      " " +
                      _booking?.booking?.user?.lastName}
                  </span>
                </p>
                <p>
                  Phone Number:{" "}
                  <span>
                    {_booking?.booking?.user?.phoneNo
                      ? _booking?.booking?.user?.phoneNo
                      : "N/A"}
                  </span>
                </p>
                <p>
                  Email:{" "}
                  <span>
                    {_booking?.booking?.user?.email
                      ? _booking?.booking?.user?.email
                      : "N/A"}
                  </span>
                </p>
                <p>
                  Gender:{" "}
                  <span>
                    {_booking?.booking?.user?.gender
                      ? _booking?.booking?.user?.gender
                      : "N/A"}
                  </span>
                </p>
                <p>
                  Campus:{" "}
                  <span>{_booking?.booking?.facility?.campus?.name}</span>
                </p>
                <p>
                  Facility: <span>{_booking?.booking?.facility?.title}</span>
                </p>
                <p>
                  Booking Status:{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {_booking?.status}
                  </span>
                </p>
              </div>
            </div>
            <br />
            <Row justify="end">
              <Button className="ant__primary__button" onClick={onCloseModal}>
                Close
              </Button>
              <div style={{ width: 30 }} />

              <Button
                type="primary"
                className="ant__primary__button"
                onClick={() => onShowBookingDetails(_booking)}
              >
                Show Details
              </Button>
            </Row>
          </div>
        )} */}
        {Object.keys(_booking).length > 0 && (
          <div>
            <b style={{ fontSize: 16 }}>Booking Information</b>
            <Row gutter={10}>
              <Col md={24}>
                <div className="booking_user_details">
                  <p>
                    Title:{" "}
                    <span>
                      {_booking?.booking?.title} -{" "}
                      {renderEventTitleFromIndentifer(
                        _booking?.booking?.bookingType?.identifier
                      )}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col md={24}>
                <div className="booking_user_details">
                  <p>
                    Date: <span>{formatedDate(_booking?.startAt)}</span>
                  </p>
                </div>
              </Col>
            </Row>

            {_booking?.scheduleType === "slots" ? (
              <Row gutter={10}>
                <Col md={24}>
                  <div className="booking_user_details">
                    <p>
                      Slot:{" "}
                      <span>
                        {formatedDateWithAmAndPm(_booking?.startTime)} -
                        {formatedDateWithAmAndPm(_booking?.endTime)}
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row gutter={10}>
                <Col md={24}>
                  <div className="booking_user_details">
                    <p>
                      Slot: <span>Full Day</span>
                    </p>
                  </div>
                </Col>
              </Row>
            )}
            <Row gutter={10}>
              <Col md={24}>
                <div className="booking_user_details">
                  <p>
                    Campus:{" "}
                    <span>{_booking?.booking?.facility?.campus?.name}</span>
                  </p>
                  <p>
                    Facility: <span>{_booking?.booking?.facility?.title}</span>
                  </p>
                  <p>
                    Booking Status:{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {_booking?.status}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
            <br />
            <div>
              <b style={{ fontSize: 16 }}>User Information</b>
              <div className="booking_user_details">
                <p>
                  Full Name:{" "}
                  <span>
                    {_booking?.booking?.user?.firstName +
                      " " +
                      _booking?.booking?.user?.lastName}
                  </span>
                </p>
                <p>
                  Phone Number:{" "}
                  <span>
                    {_booking?.booking?.user?.phoneNo
                      ? _booking?.booking?.user?.phoneNo
                      : "N/A"}
                  </span>
                </p>
                <p>
                  Email:{" "}
                  <span>
                    {_booking?.booking?.user?.email
                      ? _booking?.booking?.user?.email
                      : "N/A"}
                  </span>
                </p>
                <p>
                  Gender:{" "}
                  <span>
                    {_booking?.booking?.user?.gender
                      ? _booking?.booking?.user?.gender
                      : "N/A"}
                  </span>
                </p>
              </div>
            </div>
            <br />
            <Row justify="end">
              <Button className="ant__primary__button" onClick={onCloseModal}>
                Close
              </Button>
              <div style={{ width: 30 }} />

              <Button
                type="primary"
                className="ant__primary__button"
                onClick={() => onShowBookingDetails(_booking)}
              >
                Show Details
              </Button>
            </Row>
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default DailySchedule;
