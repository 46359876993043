import { Button, Form, Input, Select, Row, Col } from "antd";
import React from "react";

import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
const { Option } = Select;
const EventsForm = ({ onSubmit, loading, formName, onCloseForm }) => {
  const _campuses = useSelector((state) => state.metaData.campuses);

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={24}>
            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={setRules("Title (EN)")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="start">
          <Button
            htmlType="submit"
            loading={loading}
            type="primary"
            className="ant__primary__button"
          >
            {loading ? "Updating..." : "Update"}
          </Button>
          <div style={{ width: 30 }} />
          <Button onClick={onCloseForm} className="ant__primary__button">
            Cancel
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default EventsForm;
