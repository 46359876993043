import { Card, Col, Row, Table } from "antd";
import React from "react";
import { convertToTitleCase } from "utils/helpers/helpers";
import moment from "moment";

function ScheduleDetail({ data }) {
  const columns = [
    {
      title: "Subject Name",
      dataIndex: "",
      render: (record) => convertToTitleCase(record.subjectName),
    },
    {
      title: "Date and Time",
      dataIndex: "time",
      key: "time",
      render: (record) => moment(record).format("DD MMM YYYY, h:mm A"),
    },
  ];
  return (
    <div>
      <Card className="antCardBorder" style={{ marginTop: "10px" }}>
        <div style={{ marginBottom: "10px" }}>
          <h5>Subject Detail</h5>
        </div>
        <Row>
          <Col lg={24}>
            <div
              style={{
                width: "100%",
              }}
            >
              <div>
                <Table dataSource={data} columns={columns} pagination={false} />
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default ScheduleDetail;
