import { Button, Card, Col, Row } from "antd";
import CustomModal from "components/CustomModal/CustomModal";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getFullDayName } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import SchduleDetailInformation from "./SchduleDetailInformation";
import BookingRescheduleModal from "./BookingRescheduleModal";
import moment from "moment";
import AttendeesDetailInformation from "./AttendeesDetailInformation";

const FSMBookingDetailsInformation = ({ booking, refetch }) => {
  const [showAttendees, setShowAttendees] = useState(false);
  const [reschudleModalOpen, setRescheduleModalOpen] = useState(false);
  const [selectedReschedule, setSelectedReschedule] = useState({});
  const [attendees, setAttendees] = useState([]);
  const fields = booking?.fields;
  const facility = booking?.facility;
  const schedules = booking?.schedules;
  const discount = booking?.discount;
  const bookingType = booking?.bookingType?.identifier;

  const onHandleViewAll = (values) => {
    setAttendees(values);
    setShowAttendees(true);
  };

  const details = schedules[0]?.details;
  const uniqueDays =
    details && details.length
      ? [...new Set(details.map((item) => item.dayOfWeek))].filter(Boolean)
      : [];

  const handleResheduleModal = (schedule) => {
    setRescheduleModalOpen(true);
    setSelectedReschedule(schedule);
  };

  const findCommonSlots = (details) => {
    const commonSlotsMap = new Map();

    details.forEach((detail) => {
      const key = `${detail.startTime}-${detail.endTime}`;
      if (!commonSlotsMap.has(key)) {
        commonSlotsMap.set(key, {
          startTime: detail.startTime,
          endTime: detail.endTime,
        });
      }
    });

    return Array.from(commonSlotsMap.values());
  };

  const commonSlots = findCommonSlots(details);

  return (
    <React.Fragment>
      <br />
      <Row>
        <Col md={6}>
          <div className="fields_snipets_container">
            <div className="field_label">
              <span>Campus</span>
            </div>
            <div className="field_snipet_row">
              <div className="field_value">
                <span>{facility?.campus?.name}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="fields_snipets_container">
            <div className="field_label">
              <span>Facility</span>
            </div>
            <div className="field_snipet_row">
              <div className="field_value">
                <span>{facility?.title}</span>
              </div>
            </div>
          </div>
        </Col>
        {bookingType === "LTB" && (
          <Col md={6}>
            <div className="fields_snipets_container">
              <div className="field_label">
                <span>Activity Type</span>
              </div>
              <div className="field_snipet_row">
                <div className="field_value">
                  <span>{schedules[0]?.activityType?.title}</span>
                </div>
              </div>
            </div>
          </Col>
        )}
        {Object.keys(discount).length > 0 && (
          <Col md={6}>
            <div className="fields_snipets_container">
              <div className="field_label">
                <span>Discount Applied</span>
              </div>
              <div className="field_snipet_row">
                <div className="field_value">
                  <span>{discount?.title}</span>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <div className="schedule__snipets">
        <p>Schedules</p>
        <div className="schedule__snipet_line" />
      </div>

      {schedules?.map((schedule, index) => {
        const startAtDate = moment(schedule.startAt);
        const currentDate = moment();
        const arrived = schedule?.details[index]?.status === "arrived";

        return (
          <React.Fragment key={index}>
            <Row>
              <Col md={12}>
                <span className="refrence_number">
                  Referece No:{schedule?.title}
                </span>
              </Col>
              {booking?.bookingStatus?.code !== "AS007" &&
                booking?.currentStage?.slug !== "cancellation_and_refunds" &&
                bookingType !== "LTB" &&
                !arrived &&
                startAtDate.isSameOrAfter(currentDate, "day") && (
                  <Col md={12}>
                    {booking?.hashasPermission && (
                      <div className="booking_reschedule_button">
                        <p onClick={() => handleResheduleModal(schedule)}>
                          Reschedule
                        </p>
                      </div>
                    )}
                  </Col>
                )}
            </Row>

            <div className="schedules__row">
              <Row>
                <Col md={6}>
                  <div className="fields_snipets_container">
                    <div className="field_label">
                      <span>
                        {bookingType === "LTB" ? "Date Range" : "Date"}
                      </span>
                    </div>
                    <div className="field_snipet_row">
                      <div className="field_value">
                        {bookingType === "LTB" ? (
                          <span>
                            {formatedDate(schedule?.startAt)} <br />
                            To
                            <br />
                            {formatedDate(schedule?.endAt)}
                          </span>
                        ) : (
                          <span>{formatedDate(schedule?.startAt)}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="fields_snipets_container">
                    <div className="field_label">
                      <span>Number of People</span>
                    </div>
                    <div className="field_snipet_row">
                      <div className="field_value">
                        <span>{schedule?.noOfAttendees}</span>
                      </div>
                    </div>
                  </div>
                </Col>
                {bookingType === "LTB" && (
                  <Col md={6}>
                    <div className="fields_snipets_container">
                      <div className="field_label">
                        <span>Week Days</span>
                      </div>
                      <div className="field_snipet_row">
                        <Row>
                          {uniqueDays.map((dayTitle, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Col md={12}>
                                  <div className="schedule_date_container">
                                    <p className="schedule_date">
                                      {dayTitle && getFullDayName(dayTitle)}
                                    </p>
                                  </div>
                                </Col>
                              </React.Fragment>
                            );
                          })}
                        </Row>
                      </div>
                    </div>
                  </Col>
                )}
                {bookingType !== "LTB" && schedule?.activityType && (
                  <Col md={6}>
                    <div className="fields_snipets_container">
                      <div className="field_label">
                        <span>Activity Type</span>
                      </div>
                      <div className="field_snipet_row">
                        <div className="field_value">
                          <span>{schedule?.activityType?.title}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
                {schedule?.scheduleType === "slots" ? (
                  <Col md={6}>
                    <div className="fields_snipets_container">
                      <div className="field_label">
                        <span>Slot</span>
                      </div>
                      {bookingType === "LTB" ? (
                        <div className="field_snipet_row">
                          {commonSlots?.map((slot) => {
                            return (
                              <div className="field_value">
                                <span>
                                  {formatedDateWithAmAndPm(slot.startTime)}
                                  {" - "}
                                  {formatedDateWithAmAndPm(slot.endTime)}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : bookingType === "PHB" || bookingType === "FFB" ? (
                        <div className="field_snipet_row">
                          {schedule?.details.map((sch) => {
                            return (
                              <div className="field_value">
                                <span>
                                  {formatedDateWithAmAndPm(sch?.startTime)}
                                  {" - "}
                                  {formatedDateWithAmAndPm(sch?.endTime)}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="field_snipet_row">
                          <div className="field_value">
                            <span>
                              {formatedDateWithAmAndPm(
                                schedule?.details[0]?.startTime
                              )}
                              {" - "}
                              {formatedDateWithAmAndPm(
                                schedule?.details[0]?.endTime
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                ) : (
                  <Col md={6}>
                    <div className="fields_snipets_container">
                      <div className="field_label">
                        <span>Slot</span>
                      </div>
                      <div className="field_snipet_row">
                        <div className="field_value">
                          <span>Full Day</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
                {/* {bookingType !== "LTB" && schedule?.attendees.length > 0 && (
                  <Col md={6}>
                    <div className="fields_snipets_container">
                      <div className="field_label">
                        <span>Attendees</span>
                      </div>
                      <div className="field_snipet_row">
                        <Row>
                          {schedule?.attendees
                            ?.slice(0, 10)
                            .map((attendee, index) => {
                              return (
                                <Col md={12} key={index}>
                                  <div className="field_value">
                                    <span>{attendee.firtName}</span>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                        <br />
                        {schedule?.attendees?.length > 10 && (
                          <Row>
                            <Col md={12}></Col>
                            <Col md={12}>
                              <div className="field_value">
                                <span
                                  className="view_all"
                                  onClick={() =>
                                    onHandleViewAll(schedule?.attendees)
                                  }
                                >
                                  view all
                                </span>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </Col>
                )}
                {bookingType === "LTB" && (
                  <Col md={24}>
                    <div className="fields_snipets_container">
                      <div className="field_label">
                        <span>Additional Notes</span>
                      </div>
                      <div className="field_snipet_row">
                        <div className="field_value">
                          <span>{fields[0]?.value}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                )} */}
              </Row>
              <AttendeesDetailInformation attendees={schedule?.attendees} />
            </div>
          </React.Fragment>
        );
      })}

      <Card>
        <SchduleDetailInformation booking={booking} />
      </Card>

      <CustomModal
        visibility={showAttendees}
        handleCancel={() => setShowAttendees(false)}
        handleOk={() => null}
        title={"Attendees"}
      >
        <div className="attendee__container">
          <Row gutter={20}>
            {attendees.map((attendee, index) => {
              return (
                <Col md={12} key={index}>
                  <p
                    className="attendee__title"
                    style={{ textTransform: "capitalize" }}
                  >
                    {attendee}
                  </p>
                </Col>
              );
            })}
          </Row>
        </div>
      </CustomModal>

      <CustomModal
        visibility={reschudleModalOpen}
        handleCancel={() => setRescheduleModalOpen(false)}
        handleOk={() => null}
        title={`Reschedule -  ${selectedReschedule?.title}`}
        modalSmall={true}
      >
        <BookingRescheduleModal
          schedule={selectedReschedule}
          type={bookingType}
          facility={facility}
          handleCancel={() => setRescheduleModalOpen(false)}
          refetch={refetch}
          bookingType={bookingType}
        />
      </CustomModal>
    </React.Fragment>
  );
};

export default FSMBookingDetailsInformation;
