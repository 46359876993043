import GeneralConfiguration from "views/screens/general_configuration/GeneralConfiguration.jsx";
import DropdownConfigurations from "views/screens/dropdown-configuration/DropdownConfiguration.jsx";
import DocumentsList from "views/screens/documents-list/DocumentsList";
import RequestStatuses from "../views/statuses-tables/requests-statuses/RequestStatuses.jsx";
import RequestTypes from "views/screens/request-types/RequestTypes.jsx";

import dropdownicon from "../assets/img/sibar/dropdownicon.png";
import generalCnfigIcon from "../assets/img/sibar/generalCnfigIcon.png";
import documentListIcon from "../assets/img/sibar/documentListIcon.png";
import documentConfigIcon from "../assets/img/sibar/documentConfigIcon.png";
import requestTypesIcon from "../assets/img/sibar/requestStatusIcon.png";

import admissionStatusIcon from "../assets/img/sibar/admissionStatusIcon.png";
import ASRServiceItems from "views/screens/asr_service_items/ASRServiceItems.jsx";
import DropdownGroupsConfig from "views/screens/dropdown-configuration/DropdownGroups.jsx";
import asrIcon from "../assets/img/sibar/ens-asr-icon.svg";
import faqsCategoriesIcon from "../assets/img/sibar/faqs-categories-icon.svg";
import faqsIcon from "../assets/img/sibar/faqs-icon.svg";

import BusesCMS from "views/screens/buses-crud/BusesCMS";
import Faqs from "views/screens/faqs-crud/Faqs.jsx";
import FaqsCategory from "views/screens/faqs-category-crud/FaqsCategory.jsx";
import Placeholders from "views/html-templates/placeholders/Placeholders.jsx";
import EnsOnboarding from "views/screens/ens-onboarding/EnsOnboarding.jsx";
import CashierConfiguration from "views/screens/cashier-configuration/CashierConfiguration.jsx";

const AdministrationRoutes = [
  {
    path: "/administration",
    name: "Administration",
    icon: admissionStatusIcon,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "administration",
    subMenu: [
      {
        path: "/request-types",
        name: "Request Types",
        icon: requestTypesIcon,
        component: RequestTypes,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Request Types",
        state: { serviceType: "request-types" },
      },
      {
        path: "/RequestStatuses",
        name: "Request Statuses",
        icon: admissionStatusIcon,
        component: RequestStatuses,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Request Statuses",
        state: { serviceType: "RequestStatuses" },
      },
      {
        path: "/documents-list",
        name: "All Documents",
        icon: documentListIcon,
        component: DocumentsList,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "All Documents",
        state: { serviceType: "documents-list" },
      },

      {
        path: "/dropdown-groups",
        name: "Dropdowns Groups",
        icon: dropdownicon,
        component: DropdownGroupsConfig,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Dropdowns Groups",
        state: null,
      },

      {
        path: "/dropdown-content",
        name: "Dropdowns Content",
        icon: dropdownicon,
        component: DropdownConfigurations,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Dropdowns Content",
        state: { serviceType: "dropdown-content" },
      },

      {
        path: "/cashier-configuration",
        name: "Cashier Configuration",
        icon: documentConfigIcon,
        component: CashierConfiguration,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Cashier Configuration",
        state: { serviceType: "cashier-configurationt" },
      },

      {
        path: "/general-configuration",
        name: "General Configuration",
        icon: generalCnfigIcon,
        component: GeneralConfiguration,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "General Configuration",
        state: { serviceType: "general-configuration" },
      },

      // {
      //   path: "/service-items",
      //   name: "Service Items",
      //   icon: asrIcon,
      //   component: ASRServiceItems,
      //   layout: "/admin",
      //   subPath: true,
      //   sideMenuTitle: "Service Items",
      //   state: null,
      // },

      {
        path: "/placeholder",
        name: "Placeholder",
        icon: asrIcon,
        component: Placeholders,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Placeholder",
        state: null,
      },
      // {
      //   path: "/buses-cms",
      //   name: "Buses",
      //   icon: asrIcon,
      //   component: BusesCMS,
      //   layout: "/admin",
      //   subPath: true,
      //   sideMenuTitle: "Buses",
      //   state: null,
      // },
      {
        path: "/faqs-categories",
        name: "Faqs Categories",
        icon: faqsCategoriesIcon,
        component: FaqsCategory,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Faqs Categories",
        state: null,
      },
      {
        path: "/faqs",
        name: "Faqs",
        icon: faqsIcon,
        component: Faqs,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Faqs",
        state: null,
      },
      {
        path: "/onboarding",
        name: "OnBoarding",
        icon: generalCnfigIcon,
        component: EnsOnboarding,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "OnBoarding",
        state: null,
      },
    ],
  },
];
export default AdministrationRoutes;
