/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AllApplicationsTable from "components/Tables/AllApplicationsTable";
import "styles/shared.css";
import { Row, Col, Form, Select, Input, Spin, Button } from "antd";

import { getAllApplications } from "apis/applications-apis/applications";
import { _getAllApplications } from "actions/applications/applications-actions";
import { requestErrorHandel } from "utils/helpers/helpers";
import * as _ from "lodash";
import { renderLoader } from "utils/helpers/helpers";
import { _saveApplicationId } from "actions/applications/applications-actions";
import { getEServicesAPI } from "apis/e-services-api/EServicesApi";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
import { fetchAllRewards } from "apis/SCH/schRequests";
import { useNavigate } from "react-router-dom";
import ExportApplicationModal from "components/export-application-modal/ExportApplicationModal";
import CustomModal from "components/CustomModal/CustomModal";
import AllApplicationsRoutes from "routes/AllApplicationsRoutes";

const { Option } = Select;

export default function AllApplications(props) {
  const [exportApplicationsForm] = Form.useForm();

  const serviceType = props.location.state.serviceType;
  const filterLayout = { md: 6 };
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _terms = useSelector((state) => state.metaData.terms);
  const _grades = useSelector((state) => state.metaData.grades);

  const _scholarshipTypes = useSelector(
    (state) => state.metaData.dropDowns?.scholarshipTypes
  );
  const _stages = useSelector((state) =>
    state.metaData.stages.filter(
      (stage) => stage.applicationType === serviceType
    )
  );
  const _allschools = useSelector((state) => state.metaData.schools);
  const applicationStatuses = useSelector(
    (state) => state.metaData.applicationStatuses
  );
  const navigationParams = props.history.location?.state;
  const dashboardEService = navigationParams?.eService;
  const dashboardItem = navigationParams?.asrItem;
  const dashboardCampus = navigationParams?.dashboardCampus;
  const dashboardTerm = navigationParams?.dashboardTerm;
  const dashboardStage = navigationParams?.dashboardStage;
  const dashboardStageStatus = navigationParams?.dashboardStageStatus;
  const dashboardApplicationStatus =
    navigationParams?.dashboardApplicationStatus;
  const fromDashboard = navigationParams?.fromDashboard;
  const dashboardGroupStatus = navigationParams?.dashboardGroupStatus;

  const dispatch = useDispatch();
  const [visibllityState, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editApplicationFields, setEditApplicationFiels] = useState(null);
  const [showApproveModal, toggleApproveModal] = React.useState(false);
  // const [approvalStatus, setApprove] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const totalRecords = useSelector((state) => state.allApplications.total);

  const [stageStatuses, setStageStatuses] = useState([]);

  const [_schools, setFilteredSchools] = useState(_allschools);

  /// filters states
  const [eService, setEService] = useState(Number(dashboardEService) || "");
  const [ASRItem, selectASRItem] = useState(Number(dashboardItem) || "");
  const [campus, setCampus] = useState(dashboardCampus ? dashboardCampus : "");
  const [school, setSchool] = useState("");
  const [term, setTerm] = useState(dashboardTerm ? dashboardTerm : "");
  const [grade, setGrade] = useState("");
  const [stage, setStage] = useState(
    dashboardStage
      ? dashboardStage === "application_posting"
        ? "final_approval"
        : dashboardStage === "bus_allocation"
        ? "payment"
        : dashboardStage
      : ""
  );
  const [filterSchType, setFilterSchType] = useState("");
  const [filterSchReward, setFilterSchReward] = useState("");
  const _asrEservices = useSelector((state) => state.metaData.asrEservices);
  const [stageChanged, setStageChanged] = useState(false);

  const [defaultStageStatus, setDefaultStageStatus] = useState(
    fromDashboard && dashboardStageStatus ? dashboardStageStatus : ""
  );

  const [searchKeyword, setSearchKeyword] = useState(
    fromDashboard && dashboardGroupStatus ? dashboardGroupStatus : ""
  );

  const [stageStatus, setStageStatus] = useState(
    fromDashboard && dashboardStageStatus
      ? dashboardStageStatus
      : !fromDashboard && defaultStageStatus
      ? defaultStageStatus
      : ""
  );

  const [applicationStatus, setApplicationStatus] = useState(
    fromDashboard && dashboardApplicationStatus !== ""
      ? dashboardApplicationStatus
      : ""
  );

  const [rewardsTypes, setRewardsTypes] = useState([]);
  const [showExortApplicationModal, setShowExortApplicationModal] =
    useState(false);
  const [exportApplicationsfilters, setExportApplicationsfilters] =
    useState(null);

  useEffect(() => {
    let _loadEservices = false;

    if (!_loadEservices) fetchEServices();
    return () => {
      _loadEservices = true;
    };
  }, []);

  const onExportModalOpen = () => {
    const _filters = {
      school: school,
      term: term,
      grade: grade,
      campus: campus,
      stage: stage,
      stageStatus: stageStatus,
      filterSchType: filterSchType,
      filterSchReward: filterSchReward,
      applicationStatus: applicationStatus,
      stageStatuses: stageStatuses,
      ASRItem: ASRItem,
      eService: eService,
    };
    setExportApplicationsfilters(_filters);
    setShowExortApplicationModal(true);
  };

  const fetchEServices = async () => {
    try {
      const paginationParams = `page=${1}&limit=${1000}`;
      const eServiceResponse = await getEServicesAPI(paginationParams);
      if (eServiceResponse.data && eServiceResponse.status === 200) {
        dispatch(_getEServicesAction(eServiceResponse.data));
      } else throw eServiceResponse.error;
    } catch (e) {
      console.log({ e });
    }
  };
  const handleSelectEService = (value) => {
    setEService(value);
    selectASRItem("");
    sessionStorage.setItem("currentPage", "1");
  };
  const handleSelectASRItem = (value) => {
    selectASRItem(value);
    sessionStorage.setItem("currentPage", "1");
  };

  const handleSelectCampus = (value) => {
    setCampus(value);
    filterSchoolsByCampus(value);
    sessionStorage.setItem("currentPage", "1");
  };

  const filterSchoolsByCampus = (value) => {
    setStageStatus("");
    if (value === "") {
      setFilteredSchools(_allschools);
      return;
    }
    setFilteredSchools(
      _allschools.filter((school) => school.campusId == value)
    );
  };
  const handleSelectSchool = (value) => {
    setSchool(value);
    sessionStorage.setItem("currentPage", "1");
  };
  const handleSelectGrade = (value) => {
    setGrade(value);
    sessionStorage.setItem("currentPage", "1");
  };
  const handleSelectTerm = (value) => {
    setTerm(value);
    sessionStorage.setItem("currentPage", "1");
  };

  const handleSelectStage = (value) => {
    setStage(value);
    handleStagesStatuses(value);
    sessionStorage.setItem("currentPage", "1");
  };

  const handleStagesStatuses = (value) => {
    setStageChanged(true);

    if (!fromDashboard && dashboardStageStatus !== "") setStageStatus("");
    if (value === "") {
      setStageStatuses([]);
      setStageChanged(false);
      return;
    }
    const _selectedStage = _stages.find((stage) => stage.slug === value);
    setStageStatuses(_selectedStage?.statuses ?? []);
  };

  const handleSelectStageStatus = (value) => {
    setDefaultStageStatus("");
    setStageStatus(value);
    sessionStorage.setItem("currentPage", "1");
  };
  const handleSelectApplicationStatus = (value) => {
    setApplicationStatus(value);
    sessionStorage.setItem("currentPage", "1");
  };

  const handelSelectScholarshipType = (value) => {
    setFilterSchType(value);
    sessionStorage.setItem("currentPage", "1");
  };
  const handelSelectScholarshipReward = (value) => {
    setFilterSchReward(value);
    sessionStorage.setItem("currentPage", "1");
  };
  useEffect(() => {
    if (dashboardCampus) setCampus(dashboardCampus);
  }, [dashboardCampus]);

  useEffect(() => {
    if (dashboardStage === "application_posting") {
      handleSelectStage("final_approval");
    }
  }, [dashboardStage]);

  useEffect(() => {
    //sessionStorage.setItem('currentPage', '1');
    fetchAllApplications();
  }, [
    campus,
    school,
    term,
    stageStatus,
    stage,
    applicationStatus,
    grade,
    searchKeyword,
    eService,
    ASRItem,
    filterSchReward,
    filterSchType,
    // currentPage,
  ]);

  useEffect(() => {
    if (stage !== "") {
      handleStagesStatuses(stage);
    }
  }, []);

  useEffect(() => {
    getRewardsTypes();
  }, []);
  const getRewardsTypes = async () => {
    try {
      const response = await fetchAllRewards(1000);
      if (response.status === 200) {
        setRewardsTypes(response.data.data);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const fetchAllApplications = async () => {
    const storedPageNumber = sessionStorage.getItem("currentPage");
    const pageNumber = storedPageNumber ? parseInt(storedPageNumber, 10) : 1;
    const storedPageSize = sessionStorage.getItem("pageSize");
    const itemsPerPage = storedPageSize ? parseInt(storedPageSize, 10) : 10;
    setCurrentPage(pageNumber);
    setPageSize(itemsPerPage);
    try {
      setLoading(true);
      const stageParam =
        dashboardStage === "bus_allocation" ||
        dashboardStage === "application_posting"
          ? dashboardStage
          : stage;
      const queryParams = `applicationType=${serviceType}&stage=${stageParam}&status=${applicationStatus}&stageStatus=${stageStatus}&campus=${campus}&school=${school}&grade=${grade}&term=${term}&search=${searchKeyword}&eService=${eService}&item=${ASRItem}&page=${pageNumber}&limit=${itemsPerPage}&scholarshipType=${filterSchType}&rewardId=${filterSchReward}`;

      const response = await getAllApplications(queryParams);
      if (response.status === 200) {
        dispatch(_getAllApplications(response.data));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      dispatch(_getAllApplications({ data: [] }));
      await requestErrorHandel({ error: error });
    }
  };

  const showNewApplicationModal = (status) => {
    setVisibility(true);
  };

  const editApplication = (record) => {
    setVisibility(true);
    setEditApplicationFiels(record);
  };

  const viewApplication = (record) => {
    sessionStorage.setItem("currentPage", currentPage.toString());
    dispatch(_saveApplicationId(record.id));
    switch (serviceType) {
      case "ENR":
        props.history.push({
          pathname: `enr-applications/details`,
          state: { applicationId: record.id, serviceType },
        });
        break;
      case "TRP":
        props.history.push({
          pathname: "trp-applications/details",
          state: { applicationId: record.id, serviceType },
        });
        break;
      case "ASR":
        props.history.push({
          pathname: "asr-applications/details",
          state: { applicationId: record.id, serviceType },
        });
        break;
      case "TFD":
        props.history.push({
          pathname: "discount-applications/details",
          state: { applicationId: record.id, serviceType: "TFD" },
        });
        break;
      case "UPD":
        props.history.push({
          pathname: "update-details-applications/details",
          state: { applicationId: record.id, serviceType: "UPD" },
        });
        break;
      case "SCH":
        props.history.push({
          pathname: "sch-applications/details",
          state: { applicationId: record.id, serviceType: "SCH" },
        });
        break;
      case "TCC":
        props.history.push({
          pathname: "tcc-applications/details",
          state: { applicationId: record.id, serviceType: "TCC" },
        });
        break;
      default:
        break;
    }
  };
  const deleteApplication = (val) => {};

  const fetchRecords = (page, pageSize) => {
    sessionStorage.setItem("currentPage", page.toString());
    sessionStorage.setItem("pageSize", pageSize.toString());
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchAllApplications();
  };
  const handelApplicationLogs = (record) => {
    switch (serviceType) {
      case "ENR":
        props.history.push("enr-applications/logs", {
          application: record,
        });
        break;
      case "TRP":
        props.history.push("trp-applications/logs", {
          application: record,
        });
        break;
      case "ASR":
        props.history.push("asr-applications/logs", {
          application: record,
        });
        break;
      case "TFD":
        props.history.push("tfd-applications/logs", {
          application: record,
        });
        break;
      case "UPD":
        props.history.push("update-details-applications/logs", {
          application: record,
        });
        break;
      case "SCH":
        props.history.push("sch-applications/logs", {
          application: record,
        });
        break;
      case "TCC":
        props.history.push("tcc-applications/logs", {
          application: record,
        });
        break;

      default:
        return;
    }
  };
  const updateQuery = (e) => {
    setSearchKeyword(e?.target?.value);
  };
  const debounceOnChange = _.debounce(updateQuery, 500);

  const exportApplicationsModalTitle = (_serviceType) => {
    const _eService = AllApplicationsRoutes[0].subMenu.find(
      (item) => item.state.serviceType === _serviceType
    );
    return `Export ${_eService?.sideMenuTitle} Applications`;
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <Form name="filterForm">
          <Row gutter={[10, 0]} align="middle">
            {
              <>
                <Col md={6} className="filterColumn">
                  <Form.Item name="campus" className="dropdown">
                    <Select
                      placeholder="Filter By Campus"
                      onChange={handleSelectCampus}
                      bordered={false}
                      defaultValue={campus || undefined}
                    >
                      <Option value={""}>All</Option>
                      {_campuses?.map((el, i) => (
                        <Option key={i} value={`${el.id}`}>
                          {el.code}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={6} className="filterColumn">
                  <Form.Item name="terms" className="dropdown">
                    <Select
                      name="term"
                      placeholder="Filter By Term"
                      onChange={handleSelectTerm}
                      bordered={false}
                      defaultValue={term || undefined}
                      value={term}
                    >
                      <Option value={""}>All</Option>
                      {_terms?.map((el, i) => (
                        <Option key={el.id} value={el.id}>
                          {el.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {serviceType !== "SCH" && (
                  <Col md={6} className="filterColumn">
                    <Form.Item name="school" className="dropdown">
                      <Select
                        placeholder="Filter By School"
                        onChange={handleSelectSchool}
                        bordered={false}
                      >
                        <Option value={""}>All</Option>
                        {_schools?.map((el, i) => (
                          <Option key={i} value={`${el.id}`}>
                            {el.abbreviation}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                <Col md={6} className="filterColumn">
                  <Form.Item name="grades" className="dropdown">
                    <Select
                      placeholder="Filter By Grade"
                      // onChange={handleSelectFilter}
                      onChange={handleSelectGrade}
                      bordered={false}
                    >
                      <Option value={""}>All</Option>
                      {_grades?.map((el, i) => (
                        <Option key={i} value={`${el.id}`}>
                          {el.code}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            }
            {serviceType === "ASR" && (
              <>
                <Col {...filterLayout} className="filterColumn">
                  <Form.Item name="eService" className="dropdown">
                    <Select
                      placeholder="Filter By E-Service"
                      onChange={handleSelectEService}
                      defaultValue={eService || ""}
                      bordered={false}
                    >
                      <Option value={""}>All Additional Services</Option>
                      {_asrEservices?.map((el) => (
                        <Option key={el.id} value={el.id}>
                          {el.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...filterLayout} className="filterColumn">
                  <Form.Item className="dropdown">
                    <Select
                      placeholder="Filter By Service Item"
                      onChange={handleSelectASRItem}
                      defaultValue={ASRItem}
                      value={ASRItem}
                      bordered={false}
                    >
                      <Option value={""}>All Service Items</Option>
                      {_asrEservices
                        ?.find((i) => i.id === eService)
                        ?.items?.map((el) => (
                          <Option key={el.id} value={el.id}>
                            {el.title}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
            <Col {...filterLayout} className="filterColumn">
              <Form.Item name="stage" className="dropdown">
                <Select
                  placeholder="Filter By Stage"
                  onChange={handleSelectStage}
                  defaultValue={stage ? stage : "All Stages"}
                  bordered={false}
                >
                  <Option value={""}>All Stages</Option>
                  {_stages?.map((el, i) => (
                    <Option key={i} value={el.slug}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...filterLayout} className="filterColumn">
              {stageChanged ? (
                <Form.Item className="dropdown">
                  <Select
                    onChange={handleSelectStageStatus}
                    placeholder="Filter By Stage Status"
                    value={stageStatus}
                    bordered={false}
                  >
                    <Option value={""}>All</Option>
                    {stageStatuses?.map((el, i) => (
                      <Option key={i} value={el.code}>
                        {el.status}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item className="dropdown">
                  <Select
                    onChange={handleSelectStageStatus}
                    placeholder="Filter By Stage Status"
                    bordered={false}
                  >
                    <Option value={""}>All</Option>
                    {stageStatuses?.map((el, i) => (
                      <Option key={i} value={el.code}>
                        {el.status}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>

            <Col {...filterLayout} className="filterColumn">
              <Form.Item name="applicationStatus" className="dropdown">
                <Select
                  placeholder="Filter By Application Status"
                  onChange={handleSelectApplicationStatus}
                  defaultValue={
                    applicationStatus ? applicationStatus : "All Applications"
                  }
                  bordered={false}
                >
                  <Option value={""}>All Applications</Option>
                  {applicationStatuses?.map((el, i) => (
                    <Option key={i} value={`${el.code}`}>
                      {el.internalStatus}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {serviceType === "SCH" && (
              <>
                <Col {...filterLayout} className="filterColumn">
                  <Form.Item name="scholarshipType" className="dropdown">
                    <Select
                      placeholder="Filter By Scholarship Type"
                      onChange={handelSelectScholarshipType}
                      // defaultValue={applicationStatus ? applicationStatus : ""}
                      bordered={false}
                    >
                      {/* <Option value={""}>All Applications</Option> */}
                      {_scholarshipTypes?.map((el, i) => (
                        <Option key={i} value={`${el.id}`}>
                          {el.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...filterLayout} className="filterColumn">
                  <Form.Item name="scholarshipRewards" className="dropdown">
                    <Select
                      placeholder="Filter By Scholarship Reward"
                      onChange={handelSelectScholarshipReward}
                      // defaultValue={applicationStatus ? applicationStatus : ""}
                      bordered={false}
                    >
                      {/* <Option value={""}>All Applications</Option> */}
                      {rewardsTypes?.map((el, i) => (
                        <Option key={i} value={`${el.id}`}>
                          {el.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <Col {...filterLayout} className="filterColumn">
              <Form.Item className="dropdown">
                <Input
                  style={{
                    // maxWidth: "200px",
                    // height: "37px",
                    // borderRadius: "5px",
                    border: "0px",
                  }}
                  placeholder="Search by keyword"
                  defaultValue={searchKeyword}
                  onChange={(e) => {
                    e.persist();
                    sessionStorage.setItem("currentPage", "1");
                    debounceOnChange(e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Row align={"middle"} justify={"end"}>
          <Col>
            <Button type="primary" onClick={() => onExportModalOpen()}>
              Export to Excel
            </Button>
          </Col>
        </Row>
        <br />

        <AllApplicationsTable
          showNewApplicationModal={showNewApplicationModal}
          editApplication={editApplication}
          viewApplication={viewApplication}
          onApproveApplication={() => toggleApproveModal(true)}
          deleteApplication={deleteApplication}
          loading={loading}
          fetchRecords={fetchRecords}
          totalRecord={totalRecords}
          seeApplicationLogs={handelApplicationLogs}
          currentPage={currentPage}
          pageSize={pageSize}
          onChangePageSize={setPageSize}
          applicationType={serviceType}
          fetchAllApplications={fetchAllApplications}
        />

        <CustomModal
          visibility={showExortApplicationModal}
          handleCancel={() => setShowExortApplicationModal(false)}
          handleOk={() => setShowExortApplicationModal(false)}
          // title={`Export ${serviceType} Applications`}
          title={exportApplicationsModalTitle(serviceType)}
          className="export_application_modal"
        >
          <ExportApplicationModal
            serviceType={serviceType}
            schoolFilter={school}
            termFilter={term}
            formName={exportApplicationsForm}
            exportApplicationsfilters={exportApplicationsfilters}
            // filters={{ term: term, campus: campus, school: school }}
          />
        </CustomModal>
      </Spin>
    </div>
  );
}
