import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  Checkbox,
  DatePicker,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { CloseCircleOutlined } from "@ant-design/icons";
import { fetchAllSccCategories } from "../scc-Categories/api";
import { fetchAllSccStandards } from "../scc-standards/api";
import { fetchAllSccBehaviourIndicators } from "../scc-indicators/api";
import { requestErrorHandel } from "utils/helpers/helpers";
import { fetchAllSccOffenseIndicators } from "../scc-offense-indicators/api";
import { renderLoader } from "utils/helpers/helpers";
import {
  getStudentListsFromENS,
  getTeacherOrEmployeesListsFromENS,
} from "./api";
import { fetchAllSccDistinguishIndicators } from "../distinguish-indicators/api";
const { Option } = Select;

const types = [
  {
    id: "1",
    title: "Positive Behaviour",
    value: "positive",
  },
  {
    id: "1",
    title: "Distinguish Behaviour",
    value: "distinguish",
  },
  {
    id: "2",
    title: "Negative Behaviour",
    value: "negative",
  },
];

const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} is required`,
    },
  ];
};

const SccOffenseManagementForm = ({
  onSubmit,
  loading,
  formName,
  getUser,
  getTeacher,
  getEmployee,
  apiKey,
}) => {
  const debounceTimeout = useRef(null);
  const debounceTimeoutTeacher = useRef(null);
  const debounceTimeoutEmployee = useRef(null);
  const [isloading, setLoading] = useState(false);

  const [searchLoading, setSearchLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [userError, setUserError] = useState("");

  const [searchTeachersLoading, setSearchTeachersLoading] = useState(false);
  const [isTeacherModalVisible, setTeacherModalVisible] = useState(false);
  const [searchTeacherQuery, setSearchTeacherQuery] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState({});
  const [teacherError, setTeacherError] = useState("");

  const [searchEmployeeLoading, setSearchEmployeeLoading] = useState(false);
  const [isEmployeeModalVisible, setEmployeeModalVisible] = useState(false);
  const [searchEmployeeQuery, setSearchEmployeeQuery] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [employeeError, setEmployeeError] = useState("");

  const [caseType, setCaseType] = useState("");
  const [categories, setCategories] = useState([]);
  const [standards, setStandards] = useState([]);
  const [behaIndicators, setBehaIndicators] = useState([]);
  const [offenseIndicators, setOffenseIndicators] = useState([]);
  const [distingusihIndicators, setDistingusihIndicators] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [standardId, setStandardId] = useState("");

  useEffect(() => {
    fetchSccCategories();
  }, [caseType]);

  useEffect(() => {
    fetchSccStandards();
  }, [categoryId]);

  useEffect(() => {
    getAllSccBehaviourIndicators();
    getAllSccOffenseIndicators();
    getAllSccDistinguishIndicators();
  }, []);

  const fetchSccCategories = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSccCategories({
        limit: 1000,
        type: caseType === "distinguish" ? "positive" : caseType,
      });
      if (response.status === 200) {
        setCategories(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const fetchSccStandards = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSccStandards({
        limit: 1000,
        page: 1,
        categoryId: categoryId == null ? "" : categoryId,
      });
      if (response.status === 200) {
        setStandards(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const getAllSccBehaviourIndicators = async () => {
    try {
      const response = await fetchAllSccBehaviourIndicators({
        page: 1,
        limit: 1000,
      });
      if (response.status === 200) {
        setBehaIndicators(response.data.data);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };
  const getAllSccOffenseIndicators = async () => {
    try {
      const response = await fetchAllSccOffenseIndicators({
        page: 1,
        limit: 1000,
      });
      if (response.status === 200) {
        setOffenseIndicators(response.data.data);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };
  const getAllSccDistinguishIndicators = async () => {
    try {
      const response = await fetchAllSccDistinguishIndicators({
        page: 1,
        limit: 1000,
      });
      if (response.status === 200) {
        console.log("data", response.data.data);
        setDistingusihIndicators(response.data.data);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const handelSearchUser = useCallback(async () => {
    try {
      setSearchLoading(true);
      const response = await getStudentListsFromENS({
        searchKey: searchQuery,
      });
      if (response.status === 200) {
        setUsers(response.data?.data);
        setSearchLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setSearchLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (searchQuery.trim() !== "") {
        handelSearchUser();
      } else {
        // Call the API when searchQuery is empty (cleared)
        handelSearchUser();
        setSelectedUser({});
      }
    }, 1000); // Adjust the delay time as needed

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    }; // Cleanup the timeout
  }, [searchQuery, handelSearchUser]);

  const handleBlur = () => {
    // Delay hiding modal to allow for item selection
    setTimeout(() => setModalVisible(false), 200);
  };

  const handleFocus = () => {
    setModalVisible(true);
    setUserError("");
  };
  const handleSelectUser = async (user) => {
    setSelectedUser(user);
    setModalVisible(false);
    getUser(user);
  };

  const handleClear = () => {
    setSelectedUser({});
    setSearchQuery("");
  };

  // teacher queries
  const handleOnTeacherFieldSearch = (e) => {
    const query = e.target.value;
    setSearchTeacherQuery(query);
  };

  const handelSearchTeacher = useCallback(async () => {
    try {
      setSearchTeachersLoading(true);
      const payload = {
        ApiKey: apiKey,
        Language: "",
        SearchKeyword: searchTeacherQuery,
        EmployeeType: "teacher",
      };
      const response = await getTeacherOrEmployeesListsFromENS({
        payload: payload,
      });
      if (response.status === 201) {
        const data = response.data?.Data;
        setTeachers(Array.isArray(data) ? data : []);
        setSearchTeachersLoading(false);
      } else {
        setSearchTeachersLoading(false);

        setTeachers([]);
      }
    } catch (error) {
      setTeachers([]);
      setSearchTeachersLoading(false);
    }
  }, [searchTeacherQuery]);

  useEffect(() => {
    if (debounceTimeoutTeacher.current) {
      clearTimeout(debounceTimeoutTeacher.current);
    }

    debounceTimeoutTeacher.current = setTimeout(() => {
      if (searchTeacherQuery.trim() !== "") {
        handelSearchTeacher();
      } else {
        // Call the API when searchQuery is empty (cleared)
        handelSearchTeacher();
        setSelectedTeacher({});
      }
    }, 1000); // Adjust the delay time as needed

    return () => {
      if (debounceTimeoutTeacher.current) {
        clearTimeout(debounceTimeoutTeacher.current);
      }
    }; // Cleanup the timeout
  }, [searchTeacherQuery, handelSearchTeacher]);

  const handleOnTeacherFieldBlur = () => {
    setTimeout(() => setTeacherModalVisible(false), 200);
  };

  const handleOnTeacherFieldFocus = () => {
    setTeacherModalVisible(true);
    setTeacherError("");
  };
  const handleSelectTeacher = async (teacher) => {
    setSelectedTeacher(teacher);
    setTeacherModalVisible(false);
    getTeacher(teacher);
  };
  const handleOnTeacherFieldClear = () => {
    setSelectedTeacher({});
    setSearchTeacherQuery("");
  };

  //   employee queries

  const handleOnEmployeeFieldSearch = (e) => {
    const query = e.target.value;
    setSearchEmployeeQuery(query);
  };

  const handelSearchEmployee = useCallback(async () => {
    try {
      setSearchEmployeeLoading(true);
      const payload = {
        ApiKey: apiKey,
        Language: "",
        SearchKeyword: searchTeacherQuery,
        EmployeeType: "employee",
      };
      const response = await getTeacherOrEmployeesListsFromENS({
        payload: payload,
      });
      if (response.status === 201) {
        const data = response.data?.Data;
        setEmployees(Array.isArray(data) ? data : []);
        setSearchEmployeeLoading(false);
      } else {
        setSearchEmployeeLoading(false);

        setEmployees([]);
      }
    } catch (error) {
      setEmployees([]);
      setSearchEmployeeLoading(false);
    }
  }, [searchEmployeeQuery]);

  useEffect(() => {
    if (debounceTimeoutEmployee.current) {
      clearTimeout(debounceTimeoutEmployee.current);
    }

    debounceTimeoutEmployee.current = setTimeout(() => {
      if (searchEmployeeQuery.trim() !== "") {
        handelSearchEmployee();
      } else {
        // Call the API when searchQuery is empty (cleared)
        handelSearchEmployee();
        setSelectedEmployee({});
      }
    }, 1000); // Adjust the delay time as needed

    return () => {
      if (debounceTimeoutEmployee.current) {
        clearTimeout(debounceTimeoutEmployee.current);
      }
    }; // Cleanup the timeout
  }, [searchEmployeeQuery, handelSearchEmployee]);

  const handleOnEmployeeFieldBlur = () => {
    // Delay hiding modal to allow for item selection
    setTimeout(() => setEmployeeModalVisible(false), 200);
  };

  const handleOnEmployeeFieldFocus = () => {
    setEmployeeModalVisible(true);
    setEmployeeError("");
  };
  const handleSelectEmployee = async (employee) => {
    setSelectedEmployee(employee);
    setEmployeeModalVisible(false);
    getEmployee(employee);
  };
  const handleOnEmployeeFieldClear = () => {
    setSelectedEmployee({});
    setSearchEmployeeQuery("");
  };

  const handleChangeCategory = (e) => {
    setCategoryId(e);
  };
  const handleSubmitCase = (values) => {
    if (
      !selectedUser ||
      (typeof selectedUser === "object" &&
        Object.keys(selectedUser).length === 0)
    ) {
      setUserError("Student is required");

      return;
    } else if (
      !selectedTeacher ||
      (typeof selectedTeacher === "object" &&
        Object.keys(selectedTeacher).length === 0)
    ) {
      setTeacherError("Teacher is required");

      return;
    } else {
      onSubmit(values);
      setSelectedUser({});
      setSelectedTeacher({});
    }
  };

  return (
    <div className="scc__genenral_container">
      <Spin spinning={isloading} indicator={renderLoader}>
        <Form layout="vertical" form={formName} onFinish={handleSubmitCase}>
          <Row gutter={10}>
            <Col md={12}>
              <div className="customer-search-container">
                <Form.Item label={"Student"}>
                  <Input
                    style={{ border: userError ? "1px solid red" : "" }}
                    id="customer-search"
                    type="text"
                    placeholder="Search Student"
                    onFocus={handleFocus}
                    onChange={handleSearch}
                    value={
                      (Object.keys(selectedUser).length &&
                        selectedUser?.FIRST_NAME +
                          " " +
                          selectedUser?.LAST_NAME +
                          " (" +
                          selectedUser?.STUDENT_NUMBER +
                          ") ") ||
                      searchQuery
                    }
                    onBlur={handleBlur}
                    autoComplete="off"
                    suffix={
                      <CloseCircleOutlined
                        onClick={handleClear}
                        style={{
                          visibility:
                            searchQuery || selectedUser?.STUDENTID
                              ? "visible"
                              : "hidden",
                          cursor: "pointer",
                        }}
                      />
                    }
                  />
                </Form.Item>
                {isModalVisible && (
                  <div className="customer-search-modal">
                    {searchLoading ? (
                      <div className="spiner">
                        <Spin />
                      </div>
                    ) : users?.length === 0 ? (
                      <div className="custom_empty_div">
                        <p>No student found</p>
                      </div>
                    ) : (
                      <React.Fragment>
                        {users?.map((user, index) => {
                          return (
                            <div
                              key={index}
                              className="customer-search-result"
                              onClick={() => handleSelectUser(user)}
                            >
                              <div className="customer-search_result_container">
                                <div className="search_result_info">
                                  <p className="custom_title">
                                    {user?.FIRST_NAME +
                                      " " +
                                      user?.LAST_NAME +
                                      " (" +
                                      user?.STUDENTID +
                                      ") "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </div>
                )}
                <p className="form_custom_error">{userError}</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="customer-search-container">
                <Form.Item label={"Teacher"}>
                  <Input
                    style={{ border: userError ? "1px solid red" : "" }}
                    id="customer-search"
                    type="text"
                    placeholder="Search Teacher"
                    onFocus={handleOnTeacherFieldFocus}
                    onChange={handleOnTeacherFieldSearch}
                    value={
                      (Object.keys(selectedTeacher).length &&
                        selectedTeacher?.FIRSTNAME +
                          " " +
                          selectedTeacher?.LASTNAME) ||
                      searchTeacherQuery
                    }
                    onBlur={handleOnTeacherFieldBlur}
                    autoComplete="off"
                    suffix={
                      <CloseCircleOutlined
                        onClick={handleOnTeacherFieldClear}
                        style={{
                          visibility:
                            searchTeacherQuery || selectedTeacher?.FIRSTNAME
                              ? "visible"
                              : "hidden",
                          cursor: "pointer",
                        }}
                      />
                    }
                  />
                </Form.Item>
                {isTeacherModalVisible && (
                  <div className="customer-search-modal">
                    {searchTeachersLoading ? (
                      <div className="spiner">
                        <Spin />
                      </div>
                    ) : teachers?.length === 0 ? (
                      <div className="custom_empty_div">
                        <p>No teacher found</p>
                      </div>
                    ) : (
                      <React.Fragment>
                        {teachers?.map((teacher, index) => {
                          return (
                            <div
                              key={index}
                              className="customer-search-result"
                              onClick={() => handleSelectTeacher(teacher)}
                            >
                              <div className="customer-search_result_container">
                                <div className="search_result_info">
                                  <p className="custom_title">
                                    {teacher?.FIRSTNAME +
                                      " " +
                                      teacher?.MIDDLENAME +
                                      " (" +
                                      teacher?.LASTNAME +
                                      ") "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </div>
                )}
                <p className="form_custom_error">{teacherError}</p>
              </div>
            </Col>
          </Row>
          <br />
          <Row gutter={10}>
            <Col md={12}>
              <Form.Item
                label={"Student Class"}
                name={"studentClass"}
                rules={setRules("Student Class")}
              >
                <Input placeholder="Add here" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <br />

          <Row gutter={10}>
            <Col md={12}>
              <Form.Item
                label="Incident Date & Time"
                name="dateOfOccurrence"
                rules={setRules("Incident Date & Time")}
              >
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  style={{ width: "100%" }}
                  placeholder="Select Date & Time"
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={"Incident Occurrence Location"}
                name={"locationOfOccurrence"}
                rules={setRules("Incident Occurrence Location")}
              >
                <Input placeholder="Add here" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <br />

          <Row gutter={10}>
            <Col md={12}>
              <div className="customer-search-container">
                <Form.Item label={"Employees"}>
                  <Input
                    style={{ border: userError ? "1px solid red" : "" }}
                    id="customer-search"
                    type="text"
                    placeholder="Search Employee"
                    onFocus={handleOnEmployeeFieldFocus}
                    onChange={handleOnEmployeeFieldSearch}
                    value={
                      (Object.keys(selectedEmployee).length &&
                        selectedEmployee?.FIRSTNAME +
                          " " +
                          selectedEmployee?.LASTNAME) ||
                      searchEmployeeQuery
                    }
                    onBlur={handleOnEmployeeFieldBlur}
                    autoComplete="off"
                    suffix={
                      <CloseCircleOutlined
                        onClick={handleOnEmployeeFieldClear}
                        style={{
                          visibility:
                            searchEmployeeQuery || selectedEmployee?.FIRSTNAME
                              ? "visible"
                              : "hidden",
                          cursor: "pointer",
                        }}
                      />
                    }
                  />
                </Form.Item>
                {isEmployeeModalVisible && (
                  <div className="customer-search-modal">
                    {searchEmployeeLoading ? (
                      <div className="spiner">
                        <Spin />
                      </div>
                    ) : employees?.length === 0 ? (
                      <div className="custom_empty_div">
                        <p>No employee found</p>
                      </div>
                    ) : (
                      <React.Fragment>
                        {employees?.map((employee, index) => {
                          return (
                            <div
                              key={index}
                              className="customer-search-result"
                              onClick={() => handleSelectEmployee(employee)}
                            >
                              <div className="customer-search_result_container">
                                <div className="search_result_info">
                                  <p className="custom_title">
                                    {employee?.FIRSTNAME +
                                      " " +
                                      employee?.MIDDLENAME +
                                      " (" +
                                      employee?.LASTNAME +
                                      ") "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </div>
                )}
                <p className="form_custom_error">{employeeError}</p>
              </div>
            </Col>

            <Col md={12}>
              <Form.Item
                label={"Case Type"}
                name={"caseType"}
                rules={setRules("Case Type")}
              >
                <Select
                  placeholder="Select"
                  onChange={(e) => {
                    setCaseType(e);
                    setCategoryId(null);
                    setStandardId("");
                    formName.resetFields(["category"]);
                    formName.resetFields(["standard"]);
                    formName.resetFields(["indicator"]);
                  }}
                >
                  {types.map((el, i) => (
                    <Option value={el.value} key={i}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <br />

          <Row gutter={10}>
            <Col md={12}>
              <Form.Item
                label={"Category"}
                name={"category"}
                rules={setRules("Category")}
              >
                <Select
                  placeholder="Select"
                  onChange={(e) => handleChangeCategory(e)}
                  disabled={!caseType.length}
                >
                  {categories.map((el, i) => (
                    <Option value={el.id} key={i}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={caseType == "negative" ? "Offence Type" : "Standard"}
                name={"standard"}
                rules={setRules("Standard")}
              >
                <Select
                  placeholder="Select"
                  disabled={categoryId == null}
                  onChange={(e) => {
                    setStandardId(e);
                    formName.resetFields(["indicator"]);
                  }}
                >
                  {standards.map((el, i) => (
                    <Option value={el.id} key={i}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <br />

          {caseType === "positive" && caseType !== "" ? (
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Behaviour Indicator"}
                  name={"indicator"}
                  rules={setRules("Behaviour Indicator")}
                >
                  <Select placeholder="Select" disabled={!standardId}>
                    {standardId &&
                      behaIndicators
                        .filter((el) => Number(el.standardId) === standardId)
                        .map((el, i) => (
                          <Option value={el.id} key={i}>
                            {el.title}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          {caseType === "negative" && caseType !== "" ? (
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Offense Indicator"}
                  name={"indicator"}
                  rules={setRules("Offense Indicator")}
                >
                  <Select placeholder="Select" disabled={!standardId}>
                    {standardId &&
                      offenseIndicators
                        .filter((el) => Number(el.standardId) === standardId)
                        .map((el, i) => (
                          <Option value={el.id} key={i}>
                            {el.title}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          {caseType === "distinguish" && caseType !== "" ? (
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Distingusih Indicator"}
                  name={"indicator"}
                  rules={setRules("Distingusih Indicator")}
                >
                  <Select placeholder="Select" disabled={!standardId}>
                    {standardId &&
                      distingusihIndicators
                        .filter((el) => Number(el.standardId) === standardId)
                        .map((el, i) => (
                          <Option value={el.id} key={i}>
                            {el.title}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          <br />
          <Row gutter={10}>
            <Col md={12}>
              <Form.Item name={"needAcknowledgment"} valuePropName={"checked"}>
                <Checkbox>Enable Acknowledgment Stage</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                label={"Act / Incidence / Situation (EN)"}
                name={"description"}
                rules={[
                  {
                    required: true,
                    message: "Act / Incidence / Situation (EN) is required",
                  },
                ]}
                valuePropName={"data"}
                getValueFromEvent={(event, editor) =>
                  editor ? editor.getData() : ""
                }
              >
                <CKEditor
                  config={{
                    language: "en",
                    //toolbar: EditorToolbar,
                  }}
                  editor={ClassicEditor}
                  key={"description"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                label={"Act / Incidence / Situation (AR)"}
                name={"descriptionAr"}
                valuePropName={"data"}
                rules={[
                  {
                    required: true,
                    message: "Act / Incidence / Situation (AR) is required",
                  },
                ]}
                getValueFromEvent={(event, editor) =>
                  editor ? editor.getData() : ""
                }
              >
                <CKEditor
                  config={{
                    language: "ar",
                    //toolbar: EditorToolbar,
                  }}
                  editor={ClassicEditor}
                  key={"descriptionAr"}
                />
              </Form.Item>
            </Col>
          </Row>

          <br />

          <Row justify="end">
            <Button
              htmlType="submit"
              loading={loading}
              className="ant__primary__button"
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default SccOffenseManagementForm;
