import { Button, Card, Popconfirm, Tooltip } from "antd";
import React from "react";
import "./style.css";
import { DeleteOutlined } from "@ant-design/icons";

function TCCCourseAssignedSection({ section, onRemoveSection }) {
  if (Object.keys(section).length > 0) {
    return (
      <div className="section__container">
        <div className="section_title__continer">
          <div className="title_body">
            <p className="section_title">Section</p>
          </div>
          <div className="title_body">
            <p className="section_title">Teacher</p>
          </div>
          <div className="title_body">
            <p className="section_title">Schedule</p>
          </div>
          <div className="title_body">
            <p className="section_title">Location</p>
          </div>
          <div className="title_body">
            <p className="section_title">School</p>
          </div>
          <div className="title_body">
            <p className="section_title">Action</p>
          </div>
        </div>
        <div className="section_values__continer">
          <div className="title_body">
            <p className="section_title">{section?.title}</p>
          </div>
          <div className="title_body">
            <p className="section_title">
              {section?.teacher?.firstName +
                " " +
                section?.teacher?.middleName +
                " " +
                section?.teacher?.lastName}
            </p>
          </div>
          <div className="title_body">
            <p className="section_title">{section?.schedule?.title}</p>
          </div>
          <div className="title_body">
            <p className="section_title">{section?.location}</p>
          </div>
          <div className="title_body">
            <p className="section_title">
              {section?.school?.abbreviation.toUpperCase()}
            </p>
          </div>
          <div className="title_body">
            <Popconfirm
              title="Are you sure you want to remove this section?"
              okText="Yes"
              cancelText="No"
              style={{ marginLeft: "30px" }}
              onConfirm={onRemoveSection}
            >
              <Tooltip title="Remove Section">
                <Button
                  className="d-flex justify-content-center align-items-center"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        </div>
      </div>
    );
  } else return null;
}

export default TCCCourseAssignedSection;
