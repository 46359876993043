/* eslint-disable no-unused-vars */
import { Button, Col, Input, Row, Select } from "antd";
import React from "react";
const { Option } = Select;

function ScheduleInterviewModal() {
  const [recomendation, setRecomendation] = React.useState([
    {
      id: "1",
      title: "Recomended",
      value: "recomended",
    },
    {
      id: "2",
      title: "Not Recomended",
      value: "not recomended",
    },
  ]);
  return (
    <div className="both-side-padding">
      <Row gutter={[20, 20]}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Col lg={12}>
            <p>Interview Link</p>
            <Input placeholder="Interview Link" style={{ width: "100%" }} />
          </Col>
          <Col lg={12}>
            <p>Recomendation</p>
            <Select
              defaultValue={"Recomended"}
              style={{ width: "100%" }}
              //   onChange={handleChange}
            >
              {recomendation.map((type) => {
                return <Option value={type.value}>{type.title}</Option>;
              })}
            </Select>
          </Col>
        </div>
      </Row>
      <Row>
        <Col lg={24}>
          <p>Notes</p>
          <Input.TextArea placeholder="Notes" style={{ width: "100%" }} />
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Button>OK</Button>
      </div>
    </div>
  );
}

export default ScheduleInterviewModal;
