import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import {
  addNewDiscountType,
  deleteDiscountType,
  fetchAllDiscountTypes,
  updateDiscountType,
} from "./api";
import DiscountTypeForm from "./DiscountTypeForm";
import { generateUniqueColumnKey } from "utils/helpers/helpers";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const DiscountTypes = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [recordId, setRecordId] = useState("");

  const [fsmDiscounTypeForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            fsmDiscounTypeForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Discount Types"}
          headerSubTitle={"Manage Discount Types"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllDiscountTypes();
  }, [page]);

  const getAllDiscountTypes = async () => {
    try {
      setLoading(true);
      const response = await fetchAllDiscountTypes({ page: page });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addDiscountType = async (data) => {
    try {
      setLoading(true);
      const response = await addNewDiscountType({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Discount type added successfully" });
        await getAllDiscountTypes();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateDiscountType = async (data) => {
    try {
      setLoading(true);
      const response = await updateDiscountType({ id: recordId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Discount Type updated successfully" });
        await getAllDiscountTypes();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteDiscountType = async (id) => {
    try {
      setLoading(true);
      const response = await deleteDiscountType({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Discount Type deleted successfully" });
        await getAllDiscountTypes();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      type: "discount_type",
    };
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateDiscountType(data);
    } else {
      await addDiscountType(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    fsmDiscounTypeForm.setFieldsValue({
      ...record,
    });
    setRecordId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Posting Account",
              dataIndex: "postingAccount",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Rule Id",
              dataIndex: "ruleId",
              key: generateUniqueColumnKey(),
            },

            {
              title: "Action",
              dataIndex: "",
              key: generateUniqueColumnKey(),
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Discount Type Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Discount Type?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteDiscountType(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Discount Type`}
        >
          <DiscountTypeForm
            onSubmit={onSubmit}
            formName={fsmDiscounTypeForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default DiscountTypes;
