import { SettingOutlined } from "@ant-design/icons";
import { Button, Tooltip, Spin } from "antd";
import { getSccConductCaseMetaData } from "apis/stages-api/stages-api";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import * as React from "react";
import { Link } from "react-router-dom";
import { renderLoader } from "utils/helpers/helpers";
import { Capitalize } from "utils/helpers/helpers";

const SccStageList = (props) => {
  const [stages, setStages] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchSCCStagesList();
  }, []);
  const fetchSCCStagesList = async () => {
    try {
      setLoading(true);
      const response = await getSccConductCaseMetaData();
      if (response.status === 200) {
        setStages(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      console.log("error", error);
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          data={stages}
          header={() => (
            <TableHeader
              headerTitle={`SCC Stages`}
              headerSubTitle="Stage Configuration"
              headerBtnTitle=""
              onAddNew={() => null}
            />
          )}
          columns={[
            {
              key: "id",
              title: "#",
              dataIndex: "id",
              render: (_, __, index) => {
                return index + 1;
              },
            },
            {
              key: "title",
              title: "Title",
              dataIndex: "title",
              render: (data) => Capitalize(data),
            },

            {
              key: "",
              title: "Configure",
              render: (record) => {
                return (
                  <div>
                    <Tooltip title="Configure Stage">
                      <Link
                        to={{
                          pathname: "/admin/scc/stage-configuration",
                          state: record,
                        }}
                      >
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<SettingOutlined />}
                        ></Button>
                      </Link>
                    </Tooltip>
                  </div>
                );
              },
            },
          ]}
          fetchRecords={() => null}
        ></CommonTable>
      </Spin>
    </div>
  );
};
export default SccStageList;
