import { Button, Form, Input, Select, Row, Col } from "antd";
import React from "react";

import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;
const UserManagementForm = ({ onSubmit, loading, formName }) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Title"} name={"title"} rules={setRules("Title")}>
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"description"}
              rules={setRules("Description")}
              label={"Description"}
            >
              <TextArea placeholder="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name={"descriptionAr"}
              rules={setRules("Description (AR)")}
              label={"Description (AR)"}
            >
              <TextArea placeholder="" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default UserManagementForm;
