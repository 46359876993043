import fsmBookingIcon from "../../assets/img/fsm/fsmBooking.png";
import fsmCalenderIcon from "../../assets/img/fsm/fsmCalender.png";
import fsmDashboardIcon from "../../assets/img/fsm/fsmDashboard.png";
import fsmfullfaciltiyIcon from "../../assets/img/fsm/fsmfullfaciltiy.png";
import fsmlogntermIcon from "../../assets/img/fsm/fsmlognterm.png";
import fsmeventbookingIcon from "../../assets/img/fsm/fsmeventbooking.png";
import fsmperheadIcon from "../../assets/img/fsm/fsmperhead.png";
import fsmrequesttypesIcon from "../../assets/img/fsm/fsmrequesttypes.png";
import fsmrequeststatusesIcon from "../../assets/img/fsm/fsmrequeststatuses.png";
import fsmMangementIcon from "../../assets/img/fsm/fsmMangement.png";
import fsmallIcon from "../../assets/img/fsm/fsmall.png";
import fsmscheduleIcon from "../../assets/img/fsm/fsmschedule.png";
import fsmfeaturesIcon from "../../assets/img/fsm/fsmfeatures.png";
import fsmeventstypesIcon from "../../assets/img/fsm/fsmeventstypes.png";
import fsmcategoryIcon from "../../assets/img/fsm/fsmcategory.png";
import fsmsubcategoryIcon from "../../assets/img/fsm/fsmsubcategory.png";
import fsmpaymentsIcon from "../../assets/img/fsm/fsmpayments.png";
import fsmcustomermanagIcon from "../../assets/img/fsm/fsmcustomermanag.png";
import fsmdiscountIcon from "../../assets/img/fsm/fsmdiscount.png";
import fsmalldiscountIcon from "../../assets/img/fsm/fsmalldiscount.png";
import fsmdiscounttypesIcon from "../../assets/img/fsm/fsmdiscounttypes.png";
import fsmvatIcon from "../../assets/img/fsm/fsmvat.png";
import fsmbannersIcon from "../../assets/img/fsm/fsmbanners.png";

import FSMDashboad from "views/FSM/dashboard/FSMDashboad";
import FSMCalender from "views/FSM/calender/FSMCalender";
import FSMRequestTypes from "views/FSM/request-types/FSMRequestTypes";
import FSMRequestStatus from "views/FSM/request-statuses/FSMRequestStatus";
import Categories from "../../views/FSM/facility-mangement/categories/Categories";
import SubCategories from "views/FSM/facility-mangement/sub-categories/SubCategories";
import AllDiscounts from "views/FSM/discount/all-discount/AllDiscount";
import DiscountTypes from "views/FSM/discount/disocunt-types/DisocuntTypes";
import Vat from "views/FSM/vat/Vat";
import FSMBanners from "views/FSM/fsm-banners/FSMBanners";
import EventTypes from "views/FSM/facility-mangement/events-types/EventTypes";
import AllFsmFeatures from "views/FSM/facility-mangement/features/AllFeatures";
import AllFacilities from "views/FSM/facility-mangement/all-facilities/AllFacilities";
import AddNewFacility from "views/FSM/facility-mangement/all-facilities/AddNewFacility";
import FacilitySchedules from "views/FSM/facility-mangement/faciltity-schedule/FacilitySchedules";
import FSMUserMangement from "views/FSM/user-management/UserManagement";

// import AllTypeBookings from "views/FSM/bookings/day-pas/DayPassBookings";
import FSMPayments from "views/FSM/payments/FSMPayments";
import AddNewPerHeadBooking from "views/FSM/bookings/per-head/AddNewPerHeadBooking";
import PerHeadBookings from "views/FSM/bookings/per-head/PerHeadBookings";
import BookingDetails from "views/FSM/bookings/BookingDetails";
import AddNewDayPassBooking from "views/FSM/bookings/day-pas/AddNewDayPassBooking";
import AddNewFullFacilityBooking from "views/FSM/bookings/full-facility/AddNewFullFacilityBooking";
import FullFacilityBookings from "views/FSM/bookings/full-facility/FullFacilityBookings";
import EventBookings from "views/FSM/bookings/event-booking/EventBookings";
import AddNewEventBooking from "views/FSM/bookings/event-booking/AddNewEventBooking";
import AddLongTermBooking from "views/FSM/bookings/long-term/AddLongTermBooking";
import LongTermBooking from "views/FSM/bookings/long-term/LongTermBooking";
import EditFacility from "views/FSM/facility-mangement/all-facilities/EditFacility";
import FSMPaymentDetails from "views/FSM/facility-mangement/fsm-application-detail-header/FSMPaymentDetails";
import FSMRefunds from "views/FSM/payments/FSMRefunds";
import AllTypeBookings from "views/FSM/bookings/allBookings/AllTypeBookings";
import FsmCashierConfiguration from "views/FSM/request-types/cashier/FsmCashierConfiguration";

const fsmRoutes = [
  {
    path: "/facility-services",
    name: "Facility Services",
    icon: fsmallIcon,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "fsm",
    subMenu: [
      {
        path: "/fsm-dashboard",
        name: "Dashboard",
        icon: fsmDashboardIcon,
        component: FSMDashboad,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Dashboard",
        state: null,
      },
      {
        path: "/fsm-calender",
        name: "Calender",
        icon: fsmCalenderIcon,
        component: FSMCalender,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Calendar",
        state: null,
      },
      {
        path: "/fsm-bookings",
        name: "Bookings ",
        icon: fsmBookingIcon,
        component: null,
        layout: "/admin",
        subPath: true,
        type: "fsm",
        subType: "fsm-sub",
        sideMenuTitle: "Bookings",
        state: null,
        subMenu: [
          {
            path: "/fsm-day-pass",
            name: "Day Pass",
            icon: fsmBookingIcon,
            component: AllTypeBookings,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Day Pass",
            state: { serviceType: "DPB" },
          },
          {
            path: "/fsm-per-head",
            name: "Per Head",
            icon: fsmperheadIcon,
            component: AllTypeBookings,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Per Head",
            state: { serviceType: "PHB" },
          },

          {
            path: "/fsm-full-facility",
            name: "Full Facility",
            icon: fsmfullfaciltiyIcon,
            component: AllTypeBookings,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Full Facility",
            state: { serviceType: "FFB" },
          },
          {
            path: "/fsm-events-booking",
            name: "Events Booking",
            icon: fsmeventbookingIcon,
            component: AllTypeBookings,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Events Booking",
            state: { serviceType: "EVB" },
          },
          {
            path: "/fsm-long-term",
            name: "Long Term",
            icon: fsmlogntermIcon,
            component: AllTypeBookings,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Long Term",
            state: { serviceType: "LTB" },
          },
        ],
      },
      {
        path: "/fsm-booking-types",
        name: "Booking Types",
        icon: fsmrequesttypesIcon,
        component: FSMRequestTypes,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Booking Types",
        state: null,
      },
      {
        path: "/fsm-request-statuses",
        name: "Booking Statuses",
        icon: fsmrequeststatusesIcon,
        component: FSMRequestStatus,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Booking Statuses",
        state: null,
      },
      {
        path: "/facility-management",
        name: "Facilities Setup",
        icon: fsmMangementIcon,
        component: null,
        layout: "/admin",
        subPath: true,
        type: "fsm",
        subType: "fsm-sub",
        // sideMenuTitle: "Facility Management",
        subMenu: [
          {
            path: "/fsm-all-facilities",
            name: "All Facilities",
            icon: fsmallIcon,
            component: AllFacilities,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "All Facilities",
            state: null,
          },
          {
            path: "/fsm-facilities-features",
            name: "Facilities Features",
            icon: fsmfeaturesIcon,
            component: AllFsmFeatures,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Facilities Features",
            state: null,
          },
          {
            path: "/fsm-facilities-schedule",
            name: "Facilities Schedules",
            icon: fsmscheduleIcon,
            component: FacilitySchedules,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Facilities Schedules",
            state: null,
          },

          {
            path: "/fsm-activity-types",
            name: "Activity Types",
            icon: fsmeventstypesIcon,
            component: EventTypes,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Activity Types",
            state: null,
          },
          {
            path: "/fsm-categories",
            name: "Categories",
            icon: fsmcategoryIcon,
            component: Categories,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Categories",
            state: null,
          },
          {
            path: "/fsm-sub-categories",
            name: "Sub Categories",
            icon: fsmsubcategoryIcon,
            component: SubCategories,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Sub Categories",
            state: null,
          },
        ],
      },
      {
        path: "/fsm-payment",
        name: "Payments",
        icon: fsmpaymentsIcon,
        component: FSMPayments,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Payments",
        state: null,
      },
      {
        path: "/fsm-refunds",
        name: "Refunds",
        icon: fsmpaymentsIcon,
        component: FSMRefunds,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Refunds",
        state: null,
      },
      // {
      //   path: "/fsm-user-management",
      //   name: "user Management",
      //   icon: fsmcustomermanagIcon,
      //   component: FSMUserMangement,
      //   layout: "/admin",
      //   subPath: true,
      //   sideMenuTitle: "User Management",
      //   state: null,
      // },

      {
        path: "/fsm-discount",
        name: "Discounts",
        icon: fsmdiscountIcon,
        component: null,
        layout: "/admin",
        subPath: true,
        type: "fsm",
        subType: "fsm-sub",
        sideMenuTitle: "Discounts",
        state: null,
        subMenu: [
          {
            path: "/fsm-all-discount",
            name: "All Discount",
            icon: fsmalldiscountIcon,
            component: AllDiscounts,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "All Discount",
            state: null,
          },
          {
            path: "/fsm-discount-types",
            name: "Discount Types",
            icon: fsmdiscounttypesIcon,
            component: DiscountTypes,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Discount Types",
            state: null,
          },
        ],
      },
      {
        path: "/fsm-vat",
        name: "Vat",
        icon: fsmvatIcon,
        component: Vat,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Vat",
        state: null,
      },
      {
        path: "/fsm-banners",
        name: "Banners",
        icon: fsmbannersIcon,
        component: FSMBanners,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Banners",
        state: null,
      },
    ],
  },
  // details screens

  {
    path: "/payment/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: FSMPaymentDetails,
    layout: "/admin",
    subPath: true,
  },
  // add facility
  {
    path: "/add-facility",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddNewFacility,
    layout: "/admin",
    subPath: true,
  },
  // edit/update facility
  {
    path: "/edit-facility",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: EditFacility,
    layout: "/admin",
    subPath: true,
  },
  // add per head booking
  {
    path: "/add-per-head-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddNewPerHeadBooking,
    layout: "/admin",
    subPath: true,
  },
  // add day pass booking
  {
    path: "/add-day-pass-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddNewDayPassBooking,
    layout: "/admin",
    subPath: true,
  },
  // add full facility booking
  {
    path: "/add-full-facility-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddNewFullFacilityBooking,
    layout: "/admin",
    subPath: true,
  },
  // add new event booking
  {
    path: "/add-event-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddNewEventBooking,
    layout: "/admin",
    subPath: true,
  },
  // add new long term booking
  {
    path: "/add-long-term-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddLongTermBooking,
    layout: "/admin",
    subPath: true,
  },
  // booking details
  {
    path: "/per-head-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/day-pass-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/full-facility-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/event-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/long-term-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/fsm-cashier-configuration",
    name: "Cashier Configuration",
    icon: "",
    component: FsmCashierConfiguration,
    layout: "/admin",
    subPath: true,
  },
];
export default fsmRoutes;
