import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import placeholderImage from "../../../assets/img/default.png";

import { useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;
const TCCSectionForm = ({
  onSubmit,
  loading,
  formName,
  courses,
  teachers,
  schedules,
}) => {
  const _tccSchools = useSelector((state) => state.metaData.TCC_schools);

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Title"} name={"title"} rules={setRules("Title")}>
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"teacherId"}
              rules={setRules("Teachers")}
              label={"Teachers"}
            >
              <Select placeholder="Select Teacher">
                {teachers?.map((el, i) => (
                  <Option key={el.id} value={el.id}>
                    {el.firstName + " " + el.middleName + " " + el.lastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name={"courseId"}
              rules={setRules("Courses")}
              label={"Courses"}
            >
              <Select placeholder="Select Course">
                {courses?.map((el, i) => (
                  <Option key={el.id} value={el.id}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"scheduleId"}
              rules={setRules("Schedules")}
              label={"Schedules"}
            >
              <Select placeholder="Select Schedule">
                {schedules?.map((el, i) => (
                  <Option key={el.id} value={el.id}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name={"schoolId"}
              rules={setRules("Schools")}
              label={"Schools"}
            >
              <Select placeholder="Select School">
                {_tccSchools?.map((el, i) => (
                  <Option key={el.id} value={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"totalSeats"}
              rules={setRules("Total Seats")}
              label={"Total Seats"}
            >
              <Input placeholder="" type="number" min={0} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name={"location"}
              rules={setRules("Location")}
              label={"Location"}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"allowIfFull"}
              valuePropName={"checked"}
              initialValue={false}
            >
              <Checkbox defaultChecked={false}>
                Allow Applications Beyond Seats Limit
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default TCCSectionForm;
