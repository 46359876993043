import client from "apis/config";

export const getRequestApplicationStatuses = async ({ type }) => {
  return await client.get(`status?type=${type}`);
};

export const updateRequestApplicationStatuses = async ({ data }) => {
  return await client.put("status", data);
};
export const getAdmissionStatuses = async ({
  type,
  applicationType,
  queryParams,
}) => {
  return await client.get(
    `status?type=${type}&application_type=${applicationType}&` + queryParams
  );
};

export const updategetAdmissionStatuses = async ({ data }) => {
  return await client.put("status", data);
};

export const updategetAdmissionStatusesNotification = async ({ data }) => {
  return await client.put("status/notifications", data);
};

export const updateStageNotificationTemplateAPI = async (data) => {
  return await client.put("status/templates", data);
};
