import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { requestErrorHandel } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { onPartialRefundRequest } from "views/FSM/bookings/api";

const { Option } = Select;

const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} is required`,
    },
  ];
};

const PartialyRefund = ({ booking, refetch, closeModal }) => {
  const [partialReFundForm] = Form.useForm();
  const schedules = booking?.schedules;
  const combinedArray = booking?.schedules?.reduce((combined, schedule) => {
    if (schedule.details && schedule.details.length > 0) {
      // Push each detail into the combined array
      combined.push(...schedule.details);
    }
    return combined;
  }, []);

  const [selectedRange, setSelectedRange] = useState({
    start: null,
    end: null,
  });
  const [loading, setLoading] = useState(false);
  const [selectedSlots, setSelectdSlots] = useState([]);
  const [combinedDetails, setCombinedDetails] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedScheduleDetailIds, setSelectedScheduleDetailIds] = useState(
    []
  );

  const bookingType = booking?.bookingType?.identifier;

  // Find the lowest and highest dates from schedules
  const lowestDate = moment.min(
    schedules.map((schedule) => moment(schedule.startAt))
  );
  const highestDate = moment.max(
    schedules.map((schedule) => moment(schedule.startAt))
  );

  // Function to disable dates
  const disabledDate = (current) => {
    return (
      current && (current < lowestDate || current > highestDate.endOf("day"))
    );
  };

  const handleDateChange = useCallback((dates) => {
    setSelectedRange({ start: dates[0], end: dates[1] });
  }, []);

  //   const calculateTotalAmount = useCallback(() => {
  //     const values = partialReFundForm.getFieldsValue(["startAt", "endAt"]);

  //     const startDate = moment(values.startAt).format("YYYY-MM-DD");
  //     const endDate = moment(values.endAt).format("YYYY-MM-DD");

  //     const totalAmount = schedules
  //       .filter((schedule) => {
  //         const scheduleDate = moment(schedule.startAt);
  //         return scheduleDate.isBetween(startDate, endDate, null, "[]");
  //       })
  //       .reduce((sum, schedule) => sum + schedule.grandTotal, 0);

  //     return totalAmount;
  //   }, [partialReFundForm, selectedRange]);

  //   useEffect(() => {
  //     const totalAmount = calculateTotalAmount();
  //     partialReFundForm.setFieldsValue({ refundAmount: totalAmount });
  //   }, [calculateTotalAmount, partialReFundForm]);

  useEffect(() => {
    const values = partialReFundForm.getFieldsValue(["startAt", "endAt"]);
    const startDate = moment(values.startAt).format("YYYY-MM-DD");
    const endDate = moment(values.endAt).format("YYYY-MM-DD");

    const filteredSchedules = schedules.filter((schedule) => {
      const scheduleDate = moment(schedule.startAt);
      return scheduleDate.isBetween(startDate, endDate, null, "[]");
    });

    const _combinedDetails = filteredSchedules.reduce(
      (details, schedule) => details.concat(schedule.details),
      []
    );

    setCombinedDetails(_combinedDetails);
  }, [partialReFundForm, schedules, selectedRange]);

  const onFinish = async (values) => {
    // Assuming values is your object containing the form field values
    const { refundAmount, ...remainingValues } = values;
    const refundAmountValue = parseFloat(refundAmount);
    try {
      const paylaod = {
        // ...values,
        remainingValues,
        refundAmount: refundAmountValue,
        startAt: moment(values?.startAt).format("YYYY-MM-DD"),
        endAt: moment(values?.endAt).format("YYYY-MM-DD"),
        bookingId: booking?.id,
        isApproved: true,
        partialRefund: true,
        slots: selectedSlots,
        scheduleDetailIds: selectedScheduleDetailIds,
      };

      setLoading(true);
      const response = await onPartialRefundRequest({ paylaod: paylaod });
      if (response.status === 200) {
        successMessage({ message: "Schdule has been udpated" });
        refetch();
        closeModal();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = !updatedSelectedRows[index];
    setSelectedRows(updatedSelectedRows);

    // Calculate totalAmount and create array of slots
    const selectedSchedules = combinedDetails.filter(
      (_, i) => updatedSelectedRows[i]
    );
    const totalAmount = selectedSchedules.reduce(
      (sum, schedule) => sum + schedule.grandTotal,
      0
    );
    if (bookingType !== "DPB" && bookingType !== "EVB") {
      const selectedSlots = selectedSchedules.map((schedule) => ({
        startTime: schedule.startTime,
        endTime: schedule.endTime,
      }));
      setSelectdSlots(selectedSlots);
    }
    if (bookingType === "DPB") {
      const selectedDetailIds = selectedSchedules.map(
        (schedule) => schedule.id
      );
      setSelectedScheduleDetailIds(selectedDetailIds);
    }

    // Set totalAmount in refundAmount field
    partialReFundForm.setFieldsValue({ refundAmount: totalAmount });
    // Do something with selectedSlots array as needed
  };

  return (
    <div>
      <Form
        layout="vertical"
        name={partialReFundForm}
        form={partialReFundForm}
        onFinish={onFinish}
      >
        <Row gutter={[20, 20]}>
          <Col md={12}>
            <Form.Item
              label="Start Date"
              name={"startAt"}
              rules={setRules("Start Date")}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                disabledDate={disabledDate}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="End Date"
              name={"endAt"}
              rules={setRules("End Date")}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                disabledDate={disabledDate}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>
        </Row>

        {combinedDetails.length > 0 && (
          <div className="refund_schedule_table">
            <div className="refund_schedule_table__header">
              <p>Date</p>
              <p>Slots</p>
              <p>TotalAmount</p>
              <p>Select</p>
            </div>

            {combinedDetails.map((row, index) => (
              <div key={index} className="refund_schedule_table__row">
                <p>{formatedDate(row.date)}</p>
                {row.startTime && row.endTime ? (
                  <p>
                    {formatedDateWithAmAndPm(row.startTime)} -{" "}
                    {formatedDateWithAmAndPm(row.endTime)}
                  </p>
                ) : (
                  <p>Full Day</p>
                )}
                <p>{row.grandTotal} AED</p>
                <p>
                  <Checkbox
                    checked={selectedRows[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </p>
              </div>
            ))}
          </div>
        )}
        <Row gutter={[20, 20]}>
          <Col md={12}>
            <Form.Item
              label="Refundable Amount"
              name={"refundAmount"}
              rules={setRules("Refundable Amount")}
            >
              <Input
                placeholder="Amount"
                type="number"
                disabled={selectedRows.length === 0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col md={24}>
            <Form.Item label="Notes" name={"notes"} rules={setRules("Notes")}>
              <TextArea placeholder="Notes" />
            </Form.Item>
          </Col>
        </Row>
        <br />

        <Row justify="end">
          <Button className="ant__primary__button" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            // loading={loading}
            type="primary"
            className="ant__primary__button"
          >
            {loading ? "Updating" : "Update"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default PartialyRefund;
