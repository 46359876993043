import client from "apis/config";

export const getFAQS = async ({ type, page }) => {
  return await client.get(`faqs?applicationType=${type}&limit=10&page=${page}`);
};

export const addNewFaq = async ({ data }) => {
  return await client.post("/faqs", data);
};

export const updateFaq = async ({ id, data }) => {
  return await client.put(`/faqs/${id}`, data);
};

export const deleteFaq = async ({ id }) => {
  return await client.delete(`/faqs/${id}`);
};

export const getFAQSCategories = async ({ limit, page }) => {
  return await client.get(`/faqs/categories?limit=${limit}&page=${page}`);
};

export const addNewFaqsCategory = async ({ data }) => {
  return await client.post("/faqs/categories", data);
};

export const updateFaqsCategory = async ({ id, data }) => {
  return await client.put(`/faqs/categories/${id}`, data);
};

export const deleteFaqCategory = async ({ id }) => {
  return await client.delete(`/faqs/categories/${id}`);
};
