import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  Image,
  Card,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./style.css";

import { useSelector } from "react-redux";
import { fetchAllPlaceholders } from "apis/templates/templatesApis";
import { getAllDropdownsApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import { getDropdownCnfigurationApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { saveAs } from "@progress/kendo-file-saver";



const { Option } = Select;
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
  Pdf,
  Print,
} = EditorTools;
const {
  pasteCleanup,
  sanitize,
  sanitizeClassAttr,
  sanitizeStyleAttr,
  removeAttribute,
  replaceImageSourcesFromRtf,
  setHtml,
} = EditorUtils;

const TemplateForm = ({
  onSubmit,
  loading,
  formName,
  valuesForEdit,
  sections,
  placeholderData,
}) => {
  const dropDowns = useSelector((state) => state.metaData.dropDowns);

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isloading, setisLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedFormTemplate, setSelectedFormTemplate] = useState();

  const editorRefEn = useRef(null);
  const editorRefAr = useRef(null);

  const _headers = sections.filter((section) => section.type === "header");
  const _footers = sections.filter((section) => section.type === "footer");
  
  const PdfTool = (props) => {
    console.log(props, "this is props");
    return (
      <Pdf
        {...props}
        // https://www.telerik.com/kendo-react-ui/components/pdfprocessing/api/PDFExportProps/
        savePdfOptions={{
          fileName: selectedFormTemplate,
          paperSize: "A4",
          margin: {
            left: "2cm",
            right: "2cm",
            top: "3cm",
            bottom: "2cm",
          },
          fonts: [
            {
              name: "Amiri",
              url: "../../../assets/fonts/Amiri/Amiri-Regular.ttf", // Update this path to where your font file is located
              style: "normal",
              weight: "normal",
            },
          ],
          scale: 0.7,
          pageTemplate: (propsLast) => (
            <div style={{ width: "100%", height: "100%" }}>
              {/* Green sides */}
              {/* <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '0.5cm', backgroundColor: 'green' }} />
              <div style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: '0.5cm', backgroundColor: 'green' }} /> */}
  
              {/* Header */}
              <div
                style={{
                  position: "absolute",
                  top: "0.5cm",
                  left: "1cm",
                  right: "1cm",
                  height: "2cm",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #888",
                }}
              >
                {/* <img src="/path/to/your/logo.png" alt="Emirates Nation Schools Logo" style={{ height: '1.5cm' }} />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#333' }}>EMIRATES NATION SCHOOLS</div> */}
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "12pt",
                    lineHeight: "1.5",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: _headers?.find(
                      (header) => header.title === "Default Header"
                    ).content,
                  }}
                />
              </div>
  
              {/* Footer */}
              <div
                style={{
                  position: "absolute",
                  bottom: "0.5cm",
                  left: "1cm",
                  right: "1cm",
                  textAlign: "center",
                  color: "#888",
                  borderTop: "1px solid #888",
                  paddingTop: "0.2cm",
                  fontSize: "10px",
                }}
              >
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "12pt",
                    lineHeight: "1.5",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: _footers?.find(
                      (footer) => footer.title === "Default Footer"
                    ).content,
                  }}
                />
             
                {/* <div>© {new Date().getFullYear()} Emirates Nation Schools. All rights reserved.</div>
                <div>Page {props.pageNum} of {props.totalPages}</div> */}
              </div>
            </div>
          ),
        }}
      />
    );
  };
  
  useEffect(() => {
    getAllPlaceholders();
  }, []);

  const getAllPlaceholders = async () => {
    try {
      setisLoading(true);
      const response = await fetchAllPlaceholders(10000, 1);
      if (response.status === 200) {
        setData(response.data.data);
        setFilteredData(response.data.data);
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  const searchPlaceholderByCategory = (cat) => {
    const _data = [...data];
    if (cat === "all") {
      setFilteredData(_data);
    } else {
      const newFilterData = data.filter(
        (placholder) => placholder.category === cat
      );
      setFilteredData(newFilterData);
    }
  };

  const fetchAllDropdowns = async () => {
    const response = await getDropdownCnfigurationApi("group=modules");
    const modules = response.data.data.filter((el) => el.group === "modules");
    setCategories(modules);
  };
  const [contentEn, setContentEn] = useState(valuesForEdit?.content || "");
  const [contentAr, setContentAr] = useState(valuesForEdit?.contentAr || "");

  const handleSubmitForm = (values) => {
    const formData = {
      content: contentEn,
      contentAr: contentAr,
      ...values,
    };
    onSubmit({
      ...formData,
    });
  };

  const handleEditorChangeEn = (data) => {
    setContentEn(data.html);
  };

  const handleEditorChangeAr = (data) => {
    setContentAr(data.html);
  };

  useEffect(() => {
    if (valuesForEdit) {
      setContentEn(valuesForEdit.content || "");
      setContentAr(valuesForEdit.contentAr || "");
      formName.setFieldsValue({
        content: valuesForEdit.content || "",
        contentAr: valuesForEdit.contentAr || "",
      });
    }
  }, [valuesForEdit, formName]);

  useEffect(() => {
    fetchAllDropdowns();
  }, []);
  return (
    <div>
      <Row gutter={12}>
        <Card className="antCardBorder" style={{ width: "60%" }}>
          <Form layout="vertical" form={formName} onFinish={handleSubmitForm}>
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  label={"Title"}
                  name={"title"}
                  rules={setRules("Title")}
                >
                  <Input placeholder="" type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Headers"}
                  name={"headerId"}
                  rules={setRules("Headers")}
                >
                  <Select
                    value={_headers?.find((el) => el.id)}
                    placeholder="Select header"
                  >
                    {_headers?.map((header) => (
                      <Option value={header.id}>{header.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Footers"}
                  name={"footerId"}
                  rules={setRules("Footers")}
                >
                  <Select
                    value={_footers?.find((el) => el.id)}
                    placeholder="Select footer"
                  >
                    {_footers?.map((footer) => (
                      <Option value={footer.id}>{footer.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Modules"}
                  name={"modules"}
                  rules={setRules("Modules")}
                >
                  <Select
                    placeholder="Select"
                    onChange={(e) => formName.setFieldValue("modules", [e])}
                    // disabled={!caseType.length}
                  >
                    {categories?.map((placeholder) => (
                      <Option value={placeholder.value}>
                        {placeholder.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item name={"isActive"} valuePropName={"checked"}>
                  <Checkbox defaultChecked={false}>Active</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={24}>
                {/* <Form.Item
            rules={setRules("Content (EN)")}
            label={"Content (EN)"}
            name={"content"}
          > */}
                <Editor
                  ref={editorRefEn}
                  tools={[
                    [
                      Bold,
                      Italic,
                      PdfTool, Print,
                      Underline,
                      Strikethrough,
                    ],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    [FontSize],
                    [FontName],
                    [FormatBlock],
                    [Undo, Redo],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable],
                    [
                      AddRowBefore,
                      AddRowAfter,
                      AddColumnBefore,
                      AddColumnAfter,
                    ],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell],
                  ]}
                  contentStyle={{
                    height: 430,
                  }}
                  defaultContent={contentEn}
                  onChange={handleEditorChangeEn}
                  // onChange={(e) => handleEditorChange(e, 'content')}
                />
                {/* </Form.Item> */}
              </Col>
            </Row>

            <Row gutter={10}>
              <Col md={24}>
                {/* <Form.Item
            rules={setRules("Content (AR)")}
            label={"Content (AR)"}
            name={"contentAr"}
          > */}
                <Editor
                  ref={editorRefAr}
                  tools={[
                    [
                      Bold,
                      Italic,
                      PdfTool, Print,
                      Underline,
                      Strikethrough,
                    ],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    [FontSize],
                    [FontName],
                    [FormatBlock],
                    [Undo, Redo],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable],
                    [
                      AddRowBefore,
                      AddRowAfter,
                      AddColumnBefore,
                      AddColumnAfter,
                    ],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell],
                  ]}
                  contentStyle={{
                    height: 430,
                  }}
                  defaultContent={contentAr}
                  onChange={handleEditorChangeAr}
                />
                {/* </Form.Item> */}
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </Card>

        <Card
          className="antCardBorder"
          style={{ width: "38%", marginLeft: "2%" }}
        >
          <p className="category_title">Use a dynamic field</p>
          <Select
            style={{ width: "100%" }}
            value={_headers?.find((el) => el.category)}
            placeholder="Select Category"
            onSelect={(e) => searchPlaceholderByCategory(e)}
          >
            <Option key={"#"} value={"all"}>
              All
            </Option>
            {placeholderData?.map((placeholder, index) => (
              <Option value={placeholder.category} key={index}>
                {placeholder.category}
              </Option>
            ))}
          </Select>
          <div>
            <p className="placeholder_info_description">
              Copy and paste the field you want in the content (english or
              arabic) to automatically include the corresponding information.
              Make sure you copy the brackets with the field name.
            </p>
          </div>

          <div className="placeholder_table_header">
            <p className="title_one">Field Name</p>
            <p className="title_two">FieldEn</p>
            <p className="title_two">FieldAr</p>
          </div>
          {isloading ? (
            "Loading...."
          ) : (
            <div className="placeholder_table_data_container">
              {filteredData.map((placeholder) => {
                return (
                  <div className="placeholder_table_data">
                    <p className="placeholder_table_row_item">
                      {placeholder.placeholderFor}
                    </p>
                    <p className="placeholder_table_row_item">
                      {placeholder.labelEn}
                    </p>
                    <p className="placeholder_table_row_item">
                      {placeholder.labelAr}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </Card>
      </Row>
    </div>
  );
};

export default TemplateForm;
