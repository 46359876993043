import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  InputNumber,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
const { Option } = Select;

const ScoringSystemForm = ({
  onSubmit,
  loading,
  formName,
  valuesForEdit,
  modalTitle,
}) => {
  const [isApplicable, setIsApplicable] = useState(false);

  const [pointsArray, setPointsArray] = useState([]);

  useEffect(() => {
    if (valuesForEdit !== null) {
      setPointsArray(valuesForEdit?.scores);
      setIsApplicable(valuesForEdit.isRange);
    } else {
      setIsApplicable(false);
      setPointsArray([{ id: "1" }]);
    }
  }, [valuesForEdit]);

  const addMoreOptions = () => {
    let _pointsArray = [...pointsArray];
    const data = {
      id: (Math.random(Math.floor) / 0.1) * 100,
    };
    _pointsArray.push(data);
    setPointsArray(_pointsArray);
  };

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const handelToggelRangeApplicable = () => {
    setIsApplicable(!isApplicable);
    // setPointsArray([]);
  };

  const onRemoveFieldFromPointsArray = (i) => {
    let _pointsArray = [...pointsArray];
    if (i === 0) {
      return;
    }
    _pointsArray = _pointsArray.filter((_, index) => index !== i);
    setPointsArray(_pointsArray);
  };
  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Title"} name={"title"} rules={setRules("Title")}>
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title Ar")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item name={"isRange"} valuePropName={"checked"} label="">
              <Checkbox
                defaultChecked={isApplicable}
                onChange={handelToggelRangeApplicable}
              >
                Range Applicable
              </Checkbox>
            </Form.Item>
          </Col>
          <Col md={12}></Col>
        </Row>
        {isApplicable && (
          <Row gutter={10}>
            <Col md={12}>
              <Form.Item
                label={"Minimum Value"}
                name={"minValue"}
                rules={setRules("MinValue")}
              >
                <Input placeholder="" type="Number" min={0} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={"Maximum Value"}
                name={"maxValue"}
                rules={setRules("MaxValue")}
              >
                <Input placeholder="" type="Number" min={0} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Card className="antCardBorder antCard">
          <Row gutter={10}>
            <Col md={12}>
              <h6 className="tcc_reson_tag">Options</h6>
            </Col>
            <Col md={12}>
              <div
                style={{
                  alignItems: "flex-end",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button type="primary" onClick={addMoreOptions}>
                  + Add
                </Button>
              </div>
            </Col>
          </Row>

          <br />
          {!isApplicable ? (
            <>
              {pointsArray.map((item, index) => {
                return (
                  <Row gutter={10} justify={"center"} align={"middle"}>
                    <Col md={11}>
                      <Form.Item
                        label={"Value"}
                        name={`letter_${item.id}`}
                        rules={setRules("Value")}
                      >
                        <Input placeholder="" type="text" />
                      </Form.Item>
                    </Col>
                    <Col md={11}>
                      <Form.Item
                        label={"Points"}
                        name={`points_${item.id}`}
                        rules={setRules("Points")}
                      >
                        <Input placeholder="" type="number" min={0} />
                      </Form.Item>
                    </Col>
                    <Col md={2}>
                      {index === 0 ? null : (
                        <img
                          onClick={() => onRemoveFieldFromPointsArray(index)}
                          src={require("../../../assets/img/declinedIcon.svg")}
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : (
            <>
              {pointsArray.map((item, index) => {
                return (
                  <Row gutter={10} align={"middle"}>
                    <Col md={8}>
                      <Form.Item
                        label={"From"}
                        name={`from_${item.id}`}
                        rules={setRules("From")}
                      >
                        <Input
                          placeholder=""
                          type="number"
                          min={0}
                          step={"any"}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item
                        label={"To"}
                        name={`to_${item.id}`}
                        rules={setRules("To")}
                      >
                        <Input
                          placeholder=""
                          type="number"
                          min={0}
                          step={"any"}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={7}>
                      <Form.Item
                        label={"Points"}
                        name={`points_${item.id}`}
                        rules={setRules("Points")}
                      >
                        <Input placeholder="" type="number" min={0} />
                      </Form.Item>
                    </Col>
                    <Col md={1}>
                      {index === 0 ? null : (
                        <img
                          onClick={() => onRemoveFieldFromPointsArray(index)}
                          src={require("../../../assets/img/declinedIcon.svg")}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        </Card>
        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default ScoringSystemForm;
