/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from "antd";
import { useEffect, useState } from "react";

import { getApplicationDetails } from "apis/applications-apis/applications";
import { renderLoader, requestErrorHandel } from "utils/helpers/helpers";
// stages screens

import { getSyncJobsHistoryAPI } from "apis/sync-jobs-api/sync-jobs-api";
import { Collapse, Card, Typography, Space } from "antd";
import Title from "antd/lib/skeleton/Title";

const { Panel } = Collapse;
const { Text } = Typography;

const SyncJobDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const applicationId = props.location.state.applicationId;
  console.log(props, "props");
  const [application, setApplication] = useState();

  useEffect(() => {
    applicationId && getDetails();
  }, [applicationId]);

  const [syncJobHistory, setSyncJobHistory] = useState();

  const getDetails = async () => {
    try {
      setLoading(true);
      const syncJobHistoryResponse = await getSyncJobsHistoryAPI();
      if (syncJobHistoryResponse?.data?.data) {
        const filteredData = syncJobHistoryResponse.data.data.filter(
          (item) => item.syncTable === applicationId
        );
        console.log(filteredData, "syncJobHistoryResponse");
        setSyncJobHistory(filteredData);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  return (
    <Spin spinning={loading && !application} indicator={renderLoader}>
      <div style={{ padding: "20px", width: "100%" }}>
        <h4>Sync Job Logs</h4>
        <Card style={{ width: "100%" }} bordered={false}>
          <Space
            direction="horizontal"
            style={{
              padding: "18px",
              width: "100%",
              fontSize: "18px",
              fontWeight: "medium",
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              justifyContent: "space-between",
            }}
          >
            <Text> Status</Text>
            <Text style={{ marginLeft: "-3rem" }}> Source</Text>
            <Text style={{ marginLeft: "-5rem" }}>Start DateTime</Text>
            <Text>End DateTime</Text>
            <Text style={{ marginLeft: "4rem" }}>Duration</Text>
          </Space>
        </Card>
        <Collapse
          accordion
          defaultActiveKey={[]}
          expandIconPosition="right"
          style={{ maxWidth: "100%" }}
        >
          {syncJobHistory &&
            syncJobHistory.slice(0, 50).map((item, index) => (
              <>
                <Panel
                  header={
                    <Card
                      style={{ width: "100%", position: "relative" }}
                      bordered={false}
                    >
                      <Text
                        style={{
                          position: "absolute",
                          top: "4Ö0px",
                          left: "-10px",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        {index + 1}
                      </Text>
                      <Title level={4}>{item.syncTable}</Title>
                      <Space
                        direction="horizontal"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text> {item.status}</Text>
                        <Text> {item.source}</Text>
                        <Text>{item.startDateTime}</Text>
                        <Text>{item.endDateTime}</Text>
                        <Text>{item.duration}</Text>
                      </Space>
                    </Card>
                  }
                  key={item.id}
                >
                  <Card bordered={false}>
                    <p>
                      <Text strong>Message:</Text> {item.message}
                    </p>
                  </Card>
                </Panel>
              </>
            ))}
        </Collapse>
      </div>
    </Spin>
  );
};
export default SyncJobDetails;
