/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Link, Redirect } from "react-router-dom";

import logo from "../../assets/img/athena1.png";
import { Collapse } from "antd";
import { connect } from "react-redux";
import { MetaDataActionKey } from "utils/constants";
import { UserRoles } from "utils/constants";
import { CaretDownFilled, CaretRightFilled, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"; // Import icons for toggle
import { LOCAL_STORAGE_KEYS } from "utils/constants";
const { Panel } = Collapse;

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.sidebar = React.createRef();
    this.state = {
      subMenuActive: false,
      isCollapsed: false, // State to manage sidebar collapse
    };
  }
  permissions = this.props?.user?.permissions;
  fmsPermissions = this.props?.user?.fmsPermissions;
  sccPermission = this.props?.user?.sccPermission;
  isSuperAdmin = this.props?.user?.role === UserRoles.superAdmin;
  hasCashierAccess = this.props?.user?.hasCashierAccess;
  hasCmsAccess = this.props?.user?.hasCmsAccess;
  hasDocumentValidatorAccess = this.props?.user?.hasDocumentValidatorAccess;
  hasHTMLAccess = this.props?.user?.hasHTMLAccess;

  // verifies if routeName is the one active (in browser input)

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  renderCashier(prop) {
    return this.isSuperAdmin || this.hasCashierAccess ? (
      <NavLink
        to={{
          pathname: `${prop.layout}${prop.path}`,
          state: prop.state,
        }}
        className="nav-link"
        activeClassName="active"
        exact
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={prop.icon}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
            alt="icon"
          />
          <p>{prop.name}</p>
        </div>
      </NavLink>
    ) : null;
  }
  shouldRenderApplications = () => {
    return (
      this.isSuperAdmin ||
      this.permissions?.ENR ||
      this.permissions?.ASR ||
      this.permissions?.TRP ||
      this.permissions?.UPD ||
      this.permissions?.TFD ||
      this.permissions?.SCH ||
      this.permissions?.TCC
      // this.fmsPermissions?.DPB
    );
  };
  shouldRenderBookings = () => {
    return (
      this.isSuperAdmin ||
      this.fmsPermissions?.DPB ||
      this.fmsPermissions?.PHB ||
      this.fmsPermissions?.FFB ||
      this.fmsPermissions?.EVB ||
      this.fmsPermissions?.LTB
    );
  };
  shouldRenderConductCases = () => {
    return this.isSuperAdmin || this.sccPermission;
  };
  shouldRenderCMS = () => {
    return this.hasCmsAccess;
  };
  shouldRenderDocumentValidator = () => {
    return this.hasDocumentValidatorAccess;
  };

  renderGroupedMenu(type, props) {
    switch (type) {
      case "applications":
        return this.renderApplications(props);
      case "administration":
        return this.renderAdministration(props);
      case "cms":
        return this.renderAdministration(props);
      case "template":
        return this.renderAdministration(props);
      case "fsm":
        return this.renderBookings(props);
      case "scc":
        return this.renderStudentsCodeOfConducts(props);
      default:
        return null;
    }
  }

  renderAdministration(prop) {
    return (
      <NavLink
        to={{
          pathname: `${prop.layout}${prop.path}`,
          state: prop.state,
        }}
        className="nav-link"
        activeClassName="active"
        exact
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={prop.icon}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
            alt="icon"
          />
          <p>{prop?.sideMenuTitle}</p>
        </div>
      </NavLink>
    );
    // }
  }
  renderApplications(prop) {
    if (this.isSuperAdmin) {
      return (
        <NavLink
          to={{
            pathname: `${prop.layout}${prop.path}`,
            state: prop.state,
          }}
          className="nav-link"
          activeClassName="active"
          exact
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={prop.icon}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
              alt="icon"
            />
            <p>{prop?.sideMenuTitle}</p>
          </div>
        </NavLink>
      );
    } else {
      switch (prop.path) {
        case "/enr-applications":
          return this.permissions?.ENR ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/trp-applications":
          return this.permissions?.TRP ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/asr-applications":
          return this.permissions?.ASR ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/update-details-applications":
          return this.permissions?.UPD ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/discount-applications":
          return this.permissions?.TFD ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/sch-applications":
          return this.permissions?.SCH ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/tcc-applications":
          return this.permissions?.TCC ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;

        default:
          return null;
      }
    }
  }
  renderDashboard(prop) {
    return this.isSuperAdmin ||
      this.permissions?.ENR ||
      this.permissions?.ASR ||
      this.permissions?.TRP ||
      this.permissions?.UPD ||
      this.permissions?.TFD ||
      this.permissions?.SCH ||
      this.permissions?.TCC ? (
      <NavLink
        to={{
          pathname: `${prop.layout}${prop.path}`,
          state: prop.state,
        }}
        className="nav-link"
        activeClassName="active"
        exact
        onClick={() =>
          localStorage.removeItem(LOCAL_STORAGE_KEYS.DASHBOARD_TAB)
        }
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={prop.icon}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
            alt="icon"
          />
          <p>{prop.name}</p>
        </div>
      </NavLink>
    ) : null;
  }

  // for FSM
  renderBookings(prop) {
    if (this.isSuperAdmin) {
      return (
        <NavLink
          to={{
            pathname: `${prop.layout}${prop.path}`,
            state: prop.state,
          }}
          className="nav-link"
          activeClassName="active"
          exact
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={prop.icon}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
              alt="icon"
            />
            <p>{prop?.sideMenuTitle}</p>
          </div>
        </NavLink>
      );
    } else {
      switch (prop.path) {
        case "/fsm-day-pass":
          return this.fmsPermissions?.DPB ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/fsm-per-head":
          return this.fmsPermissions?.PHB ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/fsm-full-facility":
          return this.fmsPermissions?.FFB ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/fsm-events-booking":
          return this.fmsPermissions?.EVB ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/fsm-long-term":
          return this.fmsPermissions?.LTB ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;
        case "/fsm-dashboard":
          return (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          );
        case "/fsm-calender":
          return this.shouldRenderBookings() ? (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.sideMenuTitle}</p>
              </div>
            </NavLink>
          ) : null;

        default:
          return null;
      }
    }
  }
  // for SCC
  renderStudentsCodeOfConducts(prop) {
    if (this.isSuperAdmin) {
      return (
        <NavLink
          to={{
            pathname: `${prop.layout}${prop.path}`,
            state: prop.state,
          }}
          className="nav-link"
          activeClassName="active"
          exact
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={prop.icon}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
              alt="icon"
            />
            <p>{prop?.sideMenuTitle}</p>
          </div>
        </NavLink>
      );
    } else if (this.sccPermission) {
      return (
        <NavLink
          to={{
            pathname: `${prop.layout}${prop.path}`,
            state: prop.state,
          }}
          className="nav-link"
          activeClassName="active"
          exact
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={prop.icon}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
              }}
              alt="icon"
            />
            <p>{prop?.sideMenuTitle}</p>
          </div>
        </NavLink>
      );
    } else return null;
  }

  renderSubMenuItems(prop, index) {
    if (prop.path === "/facility-services") {
      localStorage.setItem(LOCAL_STORAGE_KEYS.DASHBOARD_TAB, "");
      if (!this.shouldRenderBookings()) return null;
    }
    if (prop.path === "/students-code-of-conducts") {
      localStorage.setItem(LOCAL_STORAGE_KEYS.DASHBOARD_TAB, "");
      if (!this.shouldRenderConductCases()) return null;
    }

    if (prop.path === "/applications") {
      if (!this.shouldRenderApplications()) return null;
    }

    if (prop.path === "/cms") {
      if (!this.shouldRenderCMS()) return null;
    }
    if (prop.path === "/qr-document-generator" && !this.props.user.hasDocumentValidatorAccess) {
      return null; // Do not render this menu item if the user does not have access
    }
    return (
      <li
        className={
          this.activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
        }
        key={index}
        style={{ marginLeft: prop.subMenu ? 0 : 0 }} // Adjusted margin for submenus
      >
        {prop.subMenu &&
        (this.shouldRenderApplications() ||
          this.hasCmsAccess ||
          this.shouldRenderBookings()) ? (
          <>
            {(prop.type == "cms" && !this.hasCmsAccess) ||
            (prop.type == "template" && !this.hasHTMLAccess) ? null : (
              <>
                <Collapse
                  activeColor={true}
                  ghost
                  expandIcon={(panel) =>
                    panel.isActive ? (
                      <CaretDownFilled
                        className="ensBrandColor expand-icon"
                        style={{
                          opacity: 0.6,
                          marginRight: 0,
                          // marginLeft: "1rem",
                          marginTop: 10,
                        }}
                      />
                    ) : (
                      <CaretRightFilled
                        className="ensBrandColor expand-icon"
                        style={{
                          opacity: 0.6,
                          marginTop: 5,
                          marginRight: 0,
                          // marginLeft: "1rem",
                          marginTop: 10,
                        }}
                      />
                    )
                  }
                  expandIconPosition={"end"}
                  onChange={(e) =>
                    this.setState({ subMenuActive: e.length > 0 })
                  }
                >
                  <Panel
                    showArrow={true}
                    header={
                      <div
                        style={{
                          textTransform: "uppercase",
                          margin: "10px 0 8px 0px !important",
                        }}
                        className="nav-stages"
                        activeclassname="active"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: prop.subType === "fsm-sub" ? "10px" : 0,
                          }}
                        >
                          <img
                            src={prop.icon}
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                            alt="icon"
                          />
                          <p
                            style={{
                              display:
                                prop.type === "scc" ? "inline-block" : "block",
                              width: prop.type === "scc" ? "150px" : "auto",
                            }}
                          >
                            {prop.name}
                          </p>
                        </div>
                      </div>
                    }
                  >
                    {prop.subMenu.map((el, i) =>
                      el?.subMenu?.length ? (
                        this.renderSubMenuItems(el, i)
                      ) : (
                        <>
                          {prop.subType === "fsm-sub" ? (
                            <li
                              className={
                                this.activeRoute(el.path) +
                                (prop.pro ? " active-pro" : "")
                              }
                              key={prop.path + i}
                              style={{
                                marginLeft: prop.subType ? 40 : 0, // Adjusted margin for sub-menu items
                              }}
                            >
                              {this.renderGroupedMenu(prop.type, el)}
                            </li>
                          ) : (
                            <li
                              className={
                                this.activeRoute(el.path) +
                                (prop.pro ? " active-pro" : "")
                              }
                              key={prop.path + i}
                              style={{
                                marginLeft: 20,
                              }}
                            >
                              {this.renderGroupedMenu(prop.type, el)}
                            </li>
                          )}
                        </>
                      )
                    )}
                  </Panel>
                </Collapse>{" "}
              </>
            )}
          </>
        ) : !prop.subPath ? (
          prop.path === "/cashier-app" ? (
            this.renderCashier(prop)
          ) : prop.path === "/dashboard" ? (
            this.renderDashboard(prop)
          ) : (
            <NavLink
              to={{
                pathname: `${prop.layout}${prop.path}`,
                state: prop.state,
              }}
              className="nav-link"
              activeClassName="active"
              exact
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={prop.icon}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                  }}
                  alt="icon"
                />
                <p>{prop.name}</p>
              </div>
            </NavLink>
          )
        ) : null}
      </li>
    );
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({ isCollapsed: !prevState.isCollapsed }));
  };

  render() {
    const currentUser = JSON.parse(localStorage.getItem("currentUserRol"));
    return (
      <div
        className={`sidebar ${this.state.isCollapsed ? "collapsed" : ""}`} // Apply collapsed class
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo d-flex justify-content-between align-items-center mt-2">
          <Link to={"/admin"}>
            <img
              src={logo}
              alt="app-logo"
              style={{ height: 35, objectFit: "contain" }}
            />
          </Link>
          <div onClick={this.toggleSidebar} style={{ cursor: 'pointer' }}>
            {this.state.isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} {/* Toggle icon */}
          </div>
        </div>
        <div className="sidebar-wrapper" ref={this.sidebar}>
          {!currentUser ? (
            <Redirect to="/" />
          ) : (
            <Nav>
              <>
                {this.props.routes.map((prop, key) => {
                  return this.renderSubMenuItems(prop, key);
                })}
              </>
            </Nav>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.login.userInfo,
    requestTypes: state.metaData?.requestTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentStage: (stage) =>
      dispatch({ type: MetaDataActionKey.setCurrentStage, payload: stage }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);