import { Button, Card, Col, Form, Input, Row, Select, Spin, Tabs } from "antd";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import { _updateApplications } from "actions/applications/applications-actions";

import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";

import { addStageApproval } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { LoadingOutlined } from "@ant-design/icons";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { _getDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";

import SCHApplicationInfo from "components/schApplicationInfo/SCHApplicationInfo";
import TCCCursesReadOnlyTabDetails from "components/tcc-courses-tab/TCCCoursesReadOnlyTabDetails";
import { ApproveOptions } from "utils/constants";
const { TabPane } = Tabs;
const { Option } = Select;

const TCCFinalApproval = (props) => {
  const [showApproveModal, toggleApproveModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const [activeTab, setActiveTab] = React.useState("tcc_father_info");

  const [remarksForm] = Form.useForm();

  // .............for accept application at finalApproval stage
  const applicationId = props?.application?.id;

  const stageStatusCode =
    props.application?.stage?.status?.code ||
    currentStageDetails?.stageStatus?.code;

  React.useEffect(() => {
    if (applicationId) {
      getLogs();
    }
  }, [applicationId]);

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} required`,
      },
    ];
  };

  const getLogs = async () => {
    try {
      const response = await getApplicationStagesLogs({ id: applicationId });
      if (response.status == 200) {
        let applicationCurrentStage = response.data.data.find(
          (item) => item.stageSlug == "final_approval"
        );
        setcurrentStageDetails(applicationCurrentStage);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onApproveClick = () => toggleApproveModal(true);

  const onSave = (values) => {
    const data = {
      appStageId: props.application?.stage?.id,
      isApproved: values.isApproved,
      notes: values.notes,
    };
    onAddStagesApproval(data);
    toggleApproveModal(false);
  };

  const onAddStagesApproval = async (values) => {
    try {
      setLoading(true);
      const response = await addStageApproval({ data: values });
      if (response.status == 200) {
        successMessage({ message: "Remarks added successfully" });
        // getDetails();
        props.refetch();
        getLogs();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const renderRemarksDetails = () => {
    if (!props.application.hasStagePermission) return null;
    if (!currentStageDetails?.approvals.length) return null;
    return (
      <Card
        hoverable
        className="antCardBorder antCard"
        style={{ marginTop: 20 }}
      >
        <h6>Final Remarks</h6>
        <h4>
          {currentStageDetails?.approvals[0].isApproved
            ? "Accepted"
            : "Declined"}
        </h4>
        <hr />
        <p style={{ overflowX: "clip" }}>
          {currentStageDetails?.approvals[0].notes}
        </p>
      </Card>
    );
  };

  const getBtnTitle = () => {
    // waiting approval
    if (stageStatusCode === "SG2280") {
      return "Add Remarks";
    } else return null;
  };

  const getTitle = () => {
    return props.application.stage?.status?.internalStatus || "";
  };

  const getDescription = () => {
    if (
      stageStatusCode == "SG830" ||
      stageStatusCode == "SG230" ||
      stageStatusCode == "SG530" ||
      stageStatusCode === "SG1130" ||
      stageStatusCode === "SG2180" ||
      stageStatusCode === "SG2280"
    ) {
      return "Please review the application and add your remarks";
    } else if (
      [
        "SG840",
        "SG850",
        "SG240",
        "SG250",
        "SG540",
        "SG550",
        "SG1140",
        "SG1150",
        "SG1190",
        "SG2200",
        "SG2340",
      ].includes(stageStatusCode)
    ) {
      return "The stage has been completed successfully";
    } else if (
      stageStatusCode == "SG860" ||
      stageStatusCode == "SG260" ||
      stageStatusCode == "SG560" ||
      stageStatusCode === "SG1160" ||
      stageStatusCode === "SG2220" ||
      stageStatusCode === "SG2320"
    ) {
      return "The stage has been cancelled";
    } else return null;
  };

  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={loader}>
        {props.application ? (
          <div>
            <Row gutter={[20, 0]}>
              <Col sm={24} md={12}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>
              {props.application.hasStagePermission && (
                <Col sm={24} md={12}>
                  <Row
                    gutter={[16, 10]}
                    justify={"space-between"}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Card hoverable className="antCardBorder antCard">
                        <Row align="middle" justify="space-between">
                          <div>
                            <h5>{getTitle()}</h5>
                            <p style={{ fontSize: "12px" }}>
                              {getDescription()}
                            </p>
                          </div>
                          <Row>
                            <div style={{ width: "20px" }}></div>

                            <Button
                              type="primary"
                              className="appButton"
                              onClick={onApproveClick}
                              disabled={[
                                "SG840",
                                "SG850",
                                "SG860",
                                "SG240",
                                "SG250",
                                "SG260",
                                "SG1140",
                                "SG1150",
                                "SG1160",
                              ].includes(stageStatusCode)}
                            >
                              {getBtnTitle()}
                            </Button>
                          </Row>
                        </Row>
                      </Card>
                      {props.application?.applicationType !== "TCC" && (
                        <SCHApplicationInfo application={props.application} />
                      )}
                    </div>
                  </Row>
                </Col>
              )}
            </Row>
            {/* {["SG840", "SG850", "SG240", "SG250"].includes(stageStatusCode)
              ? renderRemarksDetails()
              : null} */}
            {renderRemarksDetails()}
            <Tabs
              activeKey={activeTab}
              onChange={(tab) => setActiveTab(tab)}
              style={{ marginTop: "1rem" }}
              tabBarGutter={40}
              // centered
            >
              <TabPane tab={<h6>Father</h6>} key="tcc_father_info">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"tcc_father_info"}
                />
              </TabPane>
              <TabPane tab={<h6>Mother</h6>} key="tcc_mother_info">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"tcc_mother_info"}
                />
              </TabPane>

              <TabPane tab={<h6>Students info</h6>} key="tcc_students_info">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"tcc_students_info"}
                />
              </TabPane>
              <TabPane tab={<h6>Courses</h6>} key="tcc_courses">
                <TCCCursesReadOnlyTabDetails
                  application={props.application}
                  //   isFieldsDisabled={isFieldsDisabled}
                  //   onFinish={onFinish}
                  //   form={coursesInfoForm}
                />
              </TabPane>

              <TabPane
                tab={<h6>Documents</h6>}
                // tab={<h5 className={styles.tabTitle}>Documents</h5>}
                key="4"
              >
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>

              <TabPane tab={<h6>Stages History</h6>} key="logs">
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>

            <CustomModal
              visibility={showApproveModal}
              handleCancel={() => toggleApproveModal(false)}
              title="Final Approval Remarks"
            >
              <Form layout="vertical" onFinish={onSave} form={remarksForm}>
                <Row gutter={10}>
                  <Col md={24}>
                    <Form.Item
                      label="Remarks"
                      name="isApproved"
                      rules={[
                        {
                          required: true,
                          message: "Approve or decline option is required",
                        },
                      ]}
                    >
                      <Select
                        // defaultValue={selectedInterViewType}
                        style={{ width: "100%" }}
                        // onChange={handleChange}
                        placeholder="Type"
                      >
                        {ApproveOptions.map((type) => {
                          return (
                            <Option value={type.value}>{type.title}</Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item
                      name={"notes"}
                      label="Notes"
                      rules={setRules("Notes")}
                    >
                      <Input.TextArea
                        placeholder="Notes"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <br />
                <Row justify="end">
                  <Button htmlType="submit" loading={loading}>
                    {loading ? "Saving..." : "Save"}
                  </Button>
                </Row>
              </Form>
            </CustomModal>
          </div>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default TCCFinalApproval;
