import React, { useState } from "react";
import FSMBookingHeader from "views/FSM/facility-mangement/fsm-application-detail-header/FSMBookingHeader";
import FSMDynamicInputReadOnlyFields from "../dynamicInputFieldsFormItems/FSMDynamicInputReadOnlyFields";
import { Card, Spin } from "antd";
import { bookingStagesActionRequest } from "views/FSM/bookings/api";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import FSMTabs from "views/FSM/facility-mangement/fsm-application-detail-header/FSMTabs";
import FSMAplicationLogs from "views/FSM/facility-mangement/fsm-application-detail-header/FSMAplicationLogs";
import FSMBookingDetailsInformation from "views/FSM/facility-mangement/fsm-application-detail-header/FSMBookingDetailsInformation";

const BookingPreFinalApprovalStage = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleBookingStagesAction = async (action, notes) => {
    setLoading(true);
    try {
      const payload = {
        bookingId: props?.bookingDetails?.id,
        isApproved: action,
        notes: notes,
      };

      const response = await bookingStagesActionRequest(payload);

      if (response.status === 200) {
        if (action) {
          successMessage({ message: "Booking has been Approved" });
        } else {
          successMessage({ message: "Booking has been Rejected" });
        }
      }
      props.refetch();
      setLoading(false);
    } catch (error) {
      setLoading(false);

      requestErrorHandel({ error: error });
    }
  };

  const renderCaseView = (tab) => {
    switch (tab) {
      case 0:
        return (
          <FSMBookingDetailsInformation
            booking={props.bookingDetails}
            refetch={props.refetch}
          />
        );
        break;
      case 1:
        return (
          <FSMAplicationLogs
            bookingId={props.bookingDetails?.id}
            autoApproved={props.bookingDetails?.autoApproval}
          />
        );
      default:
        break;
    }
  };

  return (
    <div>
      <Spin spinning={loading} indicator={renderLoader}>
        <FSMBookingHeader
          booking={props.bookingDetails}
          onStageAction={handleBookingStagesAction}
          props={props}
        />
        <FSMTabs
          selectedTab={selectedTab}
          onLogsTab={() => setSelectedTab(1)}
          onDetialsTab={() => setSelectedTab(0)}
        />
        <div className="dynamic_form_items_container">
          {renderCaseView(selectedTab)}
        </div>
      </Spin>
    </div>
  );
};

export default BookingPreFinalApprovalStage;
