import * as React from "react";
import { EditOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "components/CustomModal/CustomModal";
import { updateRequestTypes } from "../../../apis/request_types_api/requestTypeapis";
import { requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { getStageMetaDataAction } from "actions/meta-data/metaDataAction";
import CommonTable from "components/Tables/CommonTable";
import "../../../styles/shared.css";
import "../../../styles/requestTypes.css";
import { BOOKING_TYPES_ROUTES } from "../../../routes/ApplicationTypeRoutes";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditorToolbar } from "utils/constants";

const FSMRequestTypes = (props) => {
  const _bookingRequestTypes = useSelector(
    (state) => state.metaData?.bookingRequestTypes
  );
  const [loading, setLoading] = React.useState(false);
  const [showModal, toggleModal] = React.useState(false);
  const [editId, setEditId] = React.useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const updateRecord = (record) => {
    toggleModal(true);
    setEditId(record.id);
    form.setFieldsValue(record);
  };
  const onSubmit = (values) => {
    setLoading(true);
    updateSingleRequestType(values);
  };
  const updateSingleRequestType = async (data) => {
    try {
      const response = await updateRequestTypes({ data: data });
      if (response.status == 200) {
        toggleModal(false);
        dispatch(getStageMetaDataAction());
        setLoading(false);
        successMessage({ message: "Request Type successfully updated" });
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const renderRoutes = (routes, serviceType) => {
    return (
      <div className="menu-list">
        {routes.map((el) => (
          <Row
            justify="space-between"
            align="middle"
            className="menu-links"
            onClick={() =>
              props.history.push({
                pathname: el.path,
                state: { serviceName: serviceType },
              })
            }
          >
            <p>{el.title}</p>
            <RightOutlined className="menu-links-icon" />
          </Row>
        ))}
      </div>
    );
  };
  const getMenuContent = (serviceType) => {
    return renderRoutes(BOOKING_TYPES_ROUTES, serviceType);
  };

  return (
    <div className="both-side-padding request-type-main">
      <Card className="antCardBorder" key={"admissionstatuses"}>
        <CommonTable
          key={"requestTypeTable"}
          loading={loading}
          data={_bookingRequestTypes?.length ? [..._bookingRequestTypes] : []}
          columns={[
            {
              title: "Identifier",
              dataIndex: "identifier",
              key: "identifier",
              render: (record) => (
                <Popover content={getMenuContent(record)} placement={"right"}>
                  <div className="request-id-wrapper">
                    <h6 className="ensBrandColor">{record}</h6>
                    <RightOutlined className="ensBrandColor request-right-arrow" />
                  </div>
                </Popover>
              ),
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Title(AR)",
              dataIndex: "titleAr",
              key: "titleAr",
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
              width: "15%",
            },
            {
              title: "Description(AR)",
              dataIndex: "descriptionAr",
              key: "descriptionAr",
              width: "15%",
            },
            {
              title: "Active",
              dataIndex: "isEnabled",
              key: "isEnabled",
              render: (record) => {
                return record ? "Yes" : "No";
              },
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (record) => (
                <div key={"action"}>
                  <Tooltip title="Update">
                    <Button
                      className="d-flex justify-content-center align-items-center"
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => updateRecord(record)}
                    />
                  </Tooltip>
                </div>
              ),
            },
          ]}
        />
      </Card>
      <CustomModal
        visibility={showModal}
        handleCancel={() => {
          toggleModal(false);
        }}
        title={editId !== "" ? "Update Request Type" : "Add Request Type"}
      >
        <Form form={form} layout="vertical" size="large" onFinish={onSubmit}>
          <Form.Item label={"Identifier"} name={"identifier"}>
            <Input placeholder="Title for request type" readOnly disabled />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Title is required",
                whitespace: true,
              },
            ]}
            label={"Title"}
            name={"title"}
          >
            <Input placeholder="Title for request type" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "TitleAr is required",
                whitespace: true,
              },
            ]}
            label={"Title(AR)"}
            name={"titleAr"}
          >
            <Input placeholder="Title for request type in Arabic" dir="rtl" />
          </Form.Item>
          <Form.Item label={"Description"} name={"description"}>
            <Input />
          </Form.Item>
          <Form.Item label={"Description(AR)"} name={"descriptionAr"}>
            <Input dir="rtl" />
          </Form.Item>
          <Row gutter={[30]}>
            <Col>
              <Form.Item name={"isEnabled"} valuePropName={"checked"}>
                <Checkbox>Active</Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={"sisRequired"} valuePropName={"checked"}>
                <Checkbox>SIS Required</Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={"dmsRequired"} valuePropName={"checked"}>
                <Checkbox>DMS Required</Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={"erpRequired"} valuePropName={"checked"}>
                <Checkbox>ERP Required</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={"Terms & Conditions"}
            name={"terms"}
            rules={[
              {
                required: true,
                message: "Terms & Conditions is required",
                whitespace: true,
              },
            ]}
            valuePropName={"data"}
            getValueFromEvent={(event, editor) =>
              editor ? editor.getData() : ""
            }
          >
            <CKEditor
              editor={ClassicEditor}
              config={
                {
                  //toolbar: EditorToolbar,
                }
              }
              key={"requestTypeTC"}
            />
          </Form.Item>
          <Form.Item
            label={"Terms & Conditions (AR)"}
            name={"termsAr"}
            rules={[
              {
                required: true,
                message: "Terms & Conditions (Arabic) is required",
                whitespace: true,
              },
            ]}
            valuePropName={"data"}
            getValueFromEvent={(event, editor) =>
              editor ? editor.getData() : ""
            }
          >
            <CKEditor
              config={{
                language: "ar",
                //toolbar: EditorToolbar,
              }}
              editor={ClassicEditor}
              key={"requestTypeTCAR"}
            />
          </Form.Item>
          <Form.Item
            label={"Guidelines"}
            name={"guidelines"}
            rules={[
              {
                required: true,
                message: "Guidelines is required",
                whitespace: true,
              },
            ]}
            valuePropName={"data"}
            getValueFromEvent={(event, editor) =>
              editor ? editor.getData() : ""
            }
          >
            <CKEditor
              config={
                {
                  //toolbar: EditorToolbar,
                }
              }
              editor={ClassicEditor}
              key={"requestTypeGuidelines"}
            />
          </Form.Item>
          <Form.Item
            label={"Guidelines (AR)"}
            name={"guidelinesAr"}
            rules={[
              {
                required: true,
                message: "Guidelines (Arabic) is required",
                whitespace: true,
              },
            ]}
            valuePropName={"data"}
            getValueFromEvent={(event, editor) =>
              editor ? editor.getData() : ""
            }
          >
            <CKEditor
              config={{
                language: "ar",
                //toolbar: EditorToolbar,
              }}
              editor={ClassicEditor}
              key={"requestTypeGuidelinesAR"}
            />
          </Form.Item>
          <Form.Item
            label={"Cancellation Terms"}
            name={"cancellationTerms"}
            rules={[
              {
                required: true,
                message: "Cancellation Terms is required",
                whitespace: true,
              },
            ]}
            valuePropName={"data"}
            getValueFromEvent={(event, editor) =>
              editor ? editor.getData() : ""
            }
          >
            <CKEditor
              config={
                {
                  //toolbar: EditorToolbar,
                }
              }
              editor={ClassicEditor}
              key={"requestTypeCancellationTerms"}
            />
          </Form.Item>
          <Form.Item
            label={"Cancellation Terms (AR)"}
            name={"cancellationTermsAr"}
            rules={[
              {
                required: true,
                message: "Cancellation Terms (Arabic) is required",
                whitespace: true,
              },
            ]}
            valuePropName={"data"}
            getValueFromEvent={(event, editor) =>
              editor ? editor.getData() : ""
            }
          >
            <CKEditor
              config={{
                language: "ar",
                //toolbar: EditorToolbar,
              }}
              editor={ClassicEditor}
              key={"requestTypeCancellationTermsAr"}
            />
          </Form.Item>

          <Row justify="end">
            <Button htmlType="submit">Save</Button>
          </Row>
        </Form>
      </CustomModal>
    </div>
  );
};
export default FSMRequestTypes;
