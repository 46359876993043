import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Image, Popconfirm, Spin, Tooltip, Input, Row, Col } from "antd";
import { updateServiceItemsApi } from "apis/service-items/ServiceItemsApi";
import { deleteServiceItemsApi } from "apis/service-items/ServiceItemsApi";
import { addServiceItemsApi } from "apis/service-items/ServiceItemsApi";
import { getServiceItemsApi } from "apis/service-items/ServiceItemsApi";
import CustomModal from "components/CustomModal/CustomModal";
import ServiceItemForm from "components/Forms/service-items/ServiceItemForm";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { Capitalize } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { useCallback } from "react";
import debounce from "lodash/debounce";

const ASRServiceItems = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [editValues, setEditValues] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const itemTypeValues = useSelector(
    (state) => state.metaData?.dropDowns?.ItemType
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      const filtered = data.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }, 300),
    [data]
  );

  // Update search term and trigger debounced search
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const fetchRecords = async () => {
    try {
      setLoading(true);
      const response = await getServiceItemsApi();
      setData(response.data.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const onAddRecord = () => {
    setEditValues(null);
    form.resetFields();
    toggleModal(true)();
  };

  const addRecord = async (values) => {
    try {
      setLoading(true);
      const response = await addServiceItemsApi(values);
      await fetchRecords();
      setLoading(false);
      successMessage({
        message: "Service item added successfully",
      });
      setShowAddModal(false);
      form.resetFields();
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const onEditRecord = (value) => {
    setEditValues(value);
    form.setFieldsValue(value);
    toggleModal(true)();
  };

  const editRecord = async (values, id) => {
    try {
      setLoading(true);
      const response = await updateServiceItemsApi(id, values);
      await fetchRecords();
      setLoading(false);
      successMessage({
        message: "Service item updated successfully",
      });
      setShowAddModal(false);
      form.resetFields();
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const onDeleteRecord = async (id) => {
    try {
      setLoading(true);
      const response = await deleteServiceItemsApi(id);
      await fetchRecords();
      setLoading(false);
      successMessage({ message: "Record deleted successfully" });
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const toggleModal = (toggleValue) => () => {
    setShowAddModal(toggleValue);
    !toggleValue && setEditValues(null);
  };

  const onSubmitForm = async (values) => {
    // using the same form to add and edit
    if (editValues) editRecord(values, editValues.id);
    else addRecord(values);
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={onAddRecord}
          headerTitle={`All Service Items`}
          headerSubTitle={"Manage Service Items"}
          headerBtnTitle={"Add New"}
        />
        <Row gutter={10}>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Input
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <br />
      </>
    );
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          data={filteredData}
          totalRecord={filteredData.length}
          fetchRecords={fetchRecords}
          header={renderHeader}
          columns={[
            {
              title: "Image",
              dataIndex: "image",
              render: (record) => (
                <Image src={record} width={"90px"} heigh={"60px"} />
              ),
              key: "id",
            },
            {
              title: "Title (EN)",
              dataIndex: "title",
              key: "id",
            },
            // {
            //   title: "Title (AR)",
            //   dataIndex: "titleAr",
            //   key: "id",
            // },
            {
              title: "Item Type",
              dataIndex: "itemType",
              key: "id",
              render: (record) => {
                let value = itemTypeValues.find((el) => el.value === record);
                value = value.title ?? "";
                return value;
              },
            },
            {
              title: "Variant Type",
              dataIndex: "variantType",
              key: "id",
              render: (record) => Capitalize(record),
            },
            {
              title: "Default Price (AED)",
              dataIndex: "price",
              key: "id",
            },
            {
              title: "Variants",
              dataIndex: "variants",
              render: (record) => {
                {
                  return record.length > 0
                    ? record.map((el, index) => (
                        <div>
                          <span>Variant {index + 1}</span>
                          <br />

                          <span>
                            {Capitalize(el.variantType)}:{" "}
                            {Capitalize(el.variant)}
                          </span>
                          <br />

                          <span>Price: {el.price} AED</span>
                          <br />
                          <br />
                        </div>
                      ))
                    : "N/A";
                }
              },
              key: "id",
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (record) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update Item">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onEditRecord(record)}
                      />
                    </Tooltip>
                  </div>

                  <Popconfirm
                    title="Are you sure you want to delete this item?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => onDeleteRecord(record.id)}
                  >
                    <Tooltip title="Delete Item">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showAddModal}
          handleCancel={toggleModal(false)}
          handleOk={toggleModal(false)}
          title={editValues ? "Edit Service Item" : "Add New Service Item"}
        >
          <ServiceItemForm
            formName={form}
            onSubmit={onSubmitForm}
            editValues={editValues}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default ASRServiceItems;
