import {
  CalendarOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import * as React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";

const FinalApprovalHistory = ({ data }) => {
  const renderStageDescription = (oldStatus, newStatus) => {
    switch (oldStatus) {
      // TRP APPLICATIONS
      case "SG914":
        return `ERP Integrated`;
      case "SG912":
        return `SIS Integrated`;
      case "SG916":
        return `DMS Integrated`;

      // ASR APPLICATIONS
      case "SG314":
        return `ERP Integrated`;
      case "SG312":
        return `SIS Integrated`;
      case "SG316":
        return `DMS Integrated`;
      case "SG317":
        return `Completed and Delivered`;

      // TFD APPLICATIONS
      case "SG614":
        return `ERP Integrated`;
      case "SG612":
        return `SIS Integrated`;
      case "SG616":
        return `DMS Integrated`;
      case "SG617":
        return `Posting Updated`;
      case "SG618":
        return `Accepted And Completed`;

      // UPD APPLICATIONS
      case "SG1240":
        return `SIS Integrated`;
      case "SG1250":
        return `ERP Integrated`;
      case "SG1260":
        return `DMS Integrated`;
      case "SG1270":
        return `Completed`;

      // SCH application
      case "SG1300":
        return `New Application - Submitted`;
      case "SG1320":
        return "Under Review";
      case "SG1340":
        return "Pending Amendment";
      case "SG1360":
        return "Amendment Review";
      case "SG1380":
        return "Waiting Acceptance";
      case "SG1400":
        return "Accepted";
      case "SG1420":
        return "Not Accepted";
      case "SG1440":
        return "Cancelled";
      case "SG1460":
        return "Inactive";
      case "SG1480":
        return "Pending Evaluation";
      case "SG1500":
        return "Evaluated";
      case "SG1520":
        return "Sent for committee approval";
      case "SG1540":
        return "Cancelled";
      case "SG1560":
        return "Inactive";
      case "SG1580":
        return "Waiting Approval";
      case "SG1600":
        return "Partial Approved";
      case "SG1620":
        return "Approved";
      case "SG1640":
        return "Rejected";
      case "SG1660":
        return "Cancelled";
      case "SG1680":
        return "Inactive";
      case "SG1700":
        if (oldStatus === "SG1700" && newStatus === "SG1720") {
          return "Reward Assigned";
        }
      case "SG1720":
        return "Pending approval";
      case "SG1740":
        return "Approved";
      case "SG1760":
        return "Rejected";
      case "SG1780":
        return "Cancelled";

      default:
        return oldStatus;
    }
  };
  return data ? (
    <div>
      <h6>{renderStageDescription(data.oldStatus, data.newStatus)}</h6>
      <div className="rh-log-item">
        <div className="rh-log-row">
          <CalendarOutlined className="rh-log-icon" />
          <p>
            Date of Action :{" "}
            {formateDateWithMoment(data.createdAt, "DD MMM YYYY hh:mm A")}
          </p>
        </div>
        <div className="rh-log-row">
          <UserOutlined className="rh-log-icon" />
          <p>Action Creator : {data.user?.userName}</p>
        </div>
        {/* application fee ends */}
        {data.meta?.Data && (
          <div>
            {typeof data.meta.Data === "string" ? (
              <div className="rh-log-row">
                <InfoCircleOutlined className="rh-log-icon" />
                <p>{data.meta.Data}</p>
              </div>
            ) : (
              <div>
                <div className="rh-log-row">
                  <InfoCircleOutlined className="rh-log-icon" />
                  <p>
                    ERP Update Message:{" "}
                    {data.meta.Data.ERPUpdateMessage || "None"}
                  </p>
                </div>
                <div className="rh-log-row">
                  <InfoCircleOutlined className="rh-log-icon" />

                  <p>
                    SIS Update Message:{" "}
                    {data.meta.Data.SISUpdateMessage || "None"}
                  </p>
                </div>
                <div className="rh-log-row">
                  <InfoCircleOutlined className="rh-log-icon" />

                  <p>StudentID: {data.meta.Data.StudentID || "None"}</p>
                </div>
              </div>
            )}
          </div>
        )}
        {/* meta data for update posting in discount */}
        {data.oldStatus === "SG617" && (
          <div className="rh-log-row">
            <InfoCircleOutlined className="rh-log-icon" />
            <p>Message: {data.meta?.Message ?? "N/A"}</p>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default FinalApprovalHistory;
