import client from "../config";
export const getAllApplications = async (queryParams) => {
  return await client.get(`applications/get/?${queryParams}`);
};

export const getApplicationDetails = async (id) => {
  return await client.get(`applications/details/${id}`);
};

export const applicationFeedback = async (data) => {
  return await client.post("applications/feedback", data);
};

export const applicationProcess = async (payload) => {
  return await client.post("applications/process", payload);
};

export const documentsFeedback = async (data) => {
  return await client.post("applications/documents/feedback", data);
};
export const addAdditionalDocumentsAPI = async (params) => {
  return await client.post(`applications/documents/additional`, params);
};

export const deleteAdditionalDocumentAPI = async (id) => {
  return await client.delete(`applications/documents/additional/${id}`);
};

export const getApplicationDefaultStages = async ({ id }) => {
  return await client.get(`applications/default/stages/${id}`);
};

export const assignApplicationStages = async ({
  appId,
  stages,
  email_notifications,
  sms_notifications,
  mobile_notifications,
}) => {
  return await client.post("applications/stages", {
    applicationId: appId,
    stages: stages,
    email_notifications: email_notifications,
    sms_notifications: sms_notifications,
    mobile_notifications: mobile_notifications,
  });
};

export const addStageApproval = async ({ data }) => {
  return await client.post("applications/stage/approval", data);
};

export const addMultipleStageApproval = async (data) => {
  return await client.post("applications/approval", data);
};

export const applicationStageLogs = async ({ id }) => {
  return await client.get(`applications/stages/log/${id}`);
};

export const getApplicationStagesStats = async ({
  term = "",
  campus = "",
  eService = "",
  applicationType = "",
  item = "",
}) => {
  return await client.get(
    `applications/statistics?campus=${campus}&term=${term}&eService=${eService}&item=${item}&applicationType=${applicationType}`
  );
};
export const getApplicationScholls = async ({ id }) => {
  return await client.get(`schools/application/${id}`);
};

export const getApplicationStudents = async (id) => {
  return await client.get(`applications/students/${id}`);
};

export const exportApplicationToExcel = async ({ data }) => {
  return await client.post("/applications/export", data);
};

export const assingSectionToCourse = async ({ data }) => {
  return await client.put("/applications/course/section", data);
};
export const removeSectionFromCourse = async ({ courseId }) => {
  return await client.delete(`/applications/course/section/${courseId}`);
};
