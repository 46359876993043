import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
const { Option } = Select;
const FinancialStatusForm = ({
  onSubmit,
  loading,
  formName,
  _requestTypes,
}) => {
  const _faqsCategories = useSelector(
    (state) => state.faqsCategories.faqsCategories
  );
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const validateToNextValue = (_, value) => {
    const { getFieldValue } = formName;
    const fromValue = getFieldValue("from");
    if (fromValue && value && fromValue <= value) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("To value must be greater than From value")
    );
  };
  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        {/* <Form.Item
          rules={setRules("Title (EN)")}
          label={"Title (EN)"}
          name={"title"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        >
          <CKEditor
            config={
              {
                //toolbar: EditorToolbar,
              }
            }
            editor={ClassicEditor}
            key={"title"}
          />
        </Form.Item>
        <Form.Item
          rules={setRules("Title (AR)")}
          label={"Title (AR)"}
          name={"titleAr"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        >
          <CKEditor
            config={{
              //toolbar: EditorToolbar,
              language: "ar",
            }}
            editor={ClassicEditor}
            key={"questionAr"}
          />
        </Form.Item> */}

        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Title"} name={"title"} rules={setRules("Title")}>
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title Ar")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={"points"} rules={setRules("Points")} label={"Points"}>
          <Input placeholder="Add Points" type="number" />
        </Form.Item>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"From"}
              name={"from"}
              rules={[
                { required: true, message: "From field is required" },
                { validator: validateToNextValue },
              ]}
            >
              <Input placeholder="Add From" type="number" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"To"}
              name={"to"}
              rules={[
                { required: true, message: "To field is required" },
                { validator: validateToNextValue },
              ]}
            >
              <Input placeholder="Add To" type="number" />
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default FinancialStatusForm;
