import React from "react";
import "./style.css";

const TitleWithLine = ({ title }) => {
  return (
    <div className="title_with_line_container">
      <p className="line_title">{title}</p>
    </div>
  );
};

export default TitleWithLine;
