const GET_NOTIFICATION_TOPIC_ACTION = "GET_NOTIFICATION_TOPIC_ACTION";

const getNotificationTopicsAction = (data) => {
  return {
    type: GET_NOTIFICATION_TOPIC_ACTION,
    payload: data,
  };
};
export const _getNotificationTopicsAction = (data) => {
  return (dispatch) => dispatch(getNotificationTopicsAction(data));
};
