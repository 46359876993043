import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  checkBookingAvailableDate,
  createBookingRequest,
  getFacilitiesAndDiscountsForUser,
  getUsersForBookingRequest,
  searchAttendeesByParent,
} from "../api";
import { fetchAllFsmFacilities } from "views/FSM/facility-mangement/all-facilities/api";
import { renderLoader } from "utils/helpers/helpers";
import moment from "moment";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { fetchAllDiscounts } from "views/FSM/discount/all-discount/api";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { isValidUAEPhoneNumber } from "utils/helpers/helpers";
import { renderTotalPriceForBooking } from "utils/helpers/helpers";
import { getStudentsList } from "views/SCC/scc-offense-management/api";
import { convertToReadableFormat } from "utils/helpers/helpers";

const { Option } = Select;

const AddNewPerHeadBooking = (props) => {
  const _BOOKINGTYPE = "PHB";

  const [addBookingForm] = Form.useForm();
  const debounceTimeout = useRef(null);

  const dropDowns = [
    {
      id: 1,
      value: "Male",
      title: "Male",
    },
    { id: 2, value: "Female", title: "Female" },
  ];

  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [facilities, setFacilities] = useState([]);
  const [availableDates, setAvailabeDates] = useState([]);
  const [scheduleFields, setScheduleFields] = useState([
    {
      startAt: "",
      noOfAttendees: "",
      attendeesNames: [],
      attendees: [],
      startTime: "",
      endTime: "",
      scheduleType: "slots",
      slots: [],
    },
  ]);
  const [availabeCapacityPerHeadList, setAvailabeCapacityPerHeadList] =
    useState(Array(scheduleFields.length).fill(""));

  // const [inputValueForAttendee, setInputValueForAttendee] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [facilityId, setFacilityId] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState({});
  const [
    availableCapacityForSelectedDate,
    setAvailableCapacityForSelectedDate,
  ] = useState([]);

  const [availabeCapacityPerHead, setAvailabeCapacityPerHead] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [inputValuesForAttendees, setInputValuesForAttendees] = useState(
    Array(scheduleFields.length).fill("")
  );
  const [fieldErrors, setFieldErrors] = useState([]);
  const [discountTypes, setDiscountTypes] = useState([]);
  const [discountId, setDiscountId] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [facilityBookingPrice, setFacilityBookingPrice] = useState({});
  const [options, setOptions] = useState([]);
  const [attendeeFields, setAttendeeFields] = useState([]);
  const [showManualFields, setShowManualFields] = useState(false);

  const isUserSelected = Object.keys(selectedUser).length > 0;

  const getFacilitiesAndDiscountsForSelectedUser = useCallback(
    async (phoneNo) => {
      try {
        setLoading(true);
        const response = await getFacilitiesAndDiscountsForUser({
          phoneNo: phoneNo,
          bookingType: _BOOKINGTYPE,
        });
        if (response.status === 200) {
          const filteredFicilities = response.data.data.filter((item) => {
            // Check if the pricing array contains an object with the typeIdentifier matching bookingType
            const hasBookingType = item.pricing.some(
              (price) => price.typeIdentifier === _BOOKINGTYPE
            );

            // Return true if pricing array length is greater than 0 and it has the booking type
            return item.pricing.length > 0 && hasBookingType;
          });
          setFacilities(filteredFicilities);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        requestErrorHandel({ error: error });
      }
    },
    [selectedUser]
  );
  const getStudentsByParentPhoneNumber = useCallback(
    async (phoneNo) => {
      try {
        setLoading(true);
        const response = await searchAttendeesByParent({
          parent: phoneNo,
        });
        if (response.status === 200) {
          const data = response.data?.data;
          setOptions(data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [selectedUser]
  );

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const handelSearchUser = useCallback(async () => {
    try {
      setSearchLoading(true);
      const response = await getUsersForBookingRequest({
        search: searchQuery,
      });
      if (response.status === 200) {
        setUsers(response.data?.data);
        setSearchLoading(false);
      }
    } catch (error) {
      setSearchLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (searchQuery.trim() !== "") {
        handelSearchUser();
      } else {
        // Call the API when searchQuery is empty (cleared)
        handelSearchUser();
        setSelectedUser({});
      }
    }, 1000); // Adjust the delay time as needed

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    }; // Cleanup the timeout
  }, [searchQuery, handelSearchUser]);

  const handleBlur = () => {
    // Delay hiding modal to allow for item selection
    setTimeout(() => setModalVisible(false), 200);
  };

  const handleFocus = () => {
    setModalVisible(true);
  };
  const handelFocusOutFromUserPhoneNumberField = async () => {
    try {
      const values = await addBookingForm.validateFields(["phoneNumber"]);
      const { phoneNumber } = values;
      const isValid = isValidUAEPhoneNumber(phoneNumber);

      if (!isValid) {
        addBookingForm.setFields([
          {
            name: "phoneNumber",
            errors: ["Please enter a valid phone number"],
          },
        ]);
        return;
      }

      await getFacilitiesAndDiscountsForSelectedUser(phoneNumber);
      await getStudentsByParentPhoneNumber(phoneNumber);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSelectUser = async (user) => {
    setSelectedUser(user);
    setModalVisible(false);
    addBookingForm.setFieldsValue({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.phoneNo,
      emiratesId: user?.emiratesId,
      dob: user?.dob ? moment(user?.dob) : "",
      gender: user.gender,
    });
    addBookingForm.resetFields(["studentList"]);
    addBookingForm.resetFields(["facilityId"]);
    await getFacilitiesAndDiscountsForSelectedUser(user?.phoneNo);
    await getStudentsByParentPhoneNumber(user?.phoneNo);
  };
  const handleClear = () => {
    setSelectedUser({});
    setSearchQuery("");
    addBookingForm.setFieldsValue({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      emiratesId: "",
      dob: "",
      gender: "",
    });
  };

  const addScheduleField = () => {
    setScheduleFields([
      ...scheduleFields,
      {
        startAt: "",
        noOfAttendees: 0,
        attendeesNames: [],
        attendees: [],
        startTime: "",
        endTime: "",
        scheduleType: "slots",
        slots: [],
      },
    ]);
  };

  const removeScheduleField = (index) => {
    const updatedFields = [...scheduleFields];
    updatedFields.splice(index, 1);
    setScheduleFields(updatedFields);
  };

  const handleFieldChange = (index, fieldName, value) => {
    if (fieldName === "startAt") {
      addBookingForm.resetFields(["slots"]);
      setAvailabeCapacityPerHeadList(Array(scheduleFields.length).fill(""));
    }

    if (
      availabeCapacityPerHeadList[index] !== "" &&
      availabeCapacityPerHeadList[index] !== undefined &&
      availabeCapacityPerHeadList[index] !== null &&
      fieldName === "noOfAttendees"
    ) {
      if (value > availabeCapacityPerHeadList[index]) {
        const newErrors = [...fieldErrors];
        newErrors[index] = {
          noOfAttendees: `Available capacity is ${availabeCapacityPerHeadList[index]}`,
        };
        setFieldErrors(newErrors);
      } else {
        const newErrors = [...fieldErrors];
        newErrors[index] = { noOfAttendees: "" };
        setFieldErrors(newErrors);
      }
    }
    const updatedFields = [...scheduleFields];

    updatedFields[index][fieldName] = value;
    setScheduleFields(updatedFields);
    onDateSelected(index);
  };

  const handleFacilityChange = (id) => {
    setFacilityId(id);
    getBookingsAvailableDate(id);
    addBookingForm.resetFields(["discountId"]);
    addBookingForm.resetFields(["studentList"]);

    const _selectedFacility = facilities.find((facility) => facility.id === id);
    if (_selectedFacility) {
      setSelectedFacility(_selectedFacility);
      setDiscountTypes(_selectedFacility?.discounts);
      const findFacilityBookingPrice = _selectedFacility?.pricing?.find(
        (type) => type.typeIdentifier === "PHB"
      );
      if (findFacilityBookingPrice) {
        setFacilityBookingPrice(findFacilityBookingPrice);
      }
      setAvailabeCapacityPerHead("");
      addBookingForm.resetFields(["slots"]);
      setAvailabeCapacityPerHeadList(Array(scheduleFields.length).fill(""));
      // Prepare attendee fields based on selected facility
      const attendeeFields = [];
      if (_selectedFacility.attendeesFirstNameRequired) {
        attendeeFields.push("firstName");
      }
      if (_selectedFacility.attendeesLastNameRequired) {
        attendeeFields.push("lastName");
      }
      if (_selectedFacility.attendeesEmailRequired) {
        attendeeFields.push("email");
      }
      if (_selectedFacility.attendeesGenderRequired) {
        attendeeFields.push("gender");
      }
      if (_selectedFacility.attendeesDobRequired) {
        attendeeFields.push("dob");
      }
      setAttendeeFields(attendeeFields);
    }

    setScheduleFields([
      {
        startAt: "",
        noOfAttendees: 0,
        attendeesNames: [],
        attendees: [],
        startTime: "",
        endTime: "",
        scheduleType: "slots",
        slots: [],
      },
    ]);
    setAvailableCapacityForSelectedDate([]);
  };

  const getBookingsAvailableDate = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const startDate = moment(currentMonth)
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDate = moment(currentMonth)
          .endOf("month")
          .format("YYYY-MM-DD");
        const response = await checkBookingAvailableDate({
          facility: id,
          startAt: startDate,
          endAt: endDate,
          isSpecial: 0,
          bookingType: _BOOKINGTYPE,
        });

        if (response.status === 200) {
          setAvailabeDates(response.data?.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [setFacilityId, facilityId, currentMonth, setCurrentMonth]
  );

  const disabledDatesAndDays = (calendarDate) => {
    const formattedCalendarDate = calendarDate.format("YYYY-MM-DD");
    const todayDate = moment().format("YYYY-MM-DD");

    // Check if the calendarDate matches any date in availableDates
    const isDateInAvailableDates = availableDates.some(
      (item) => item.date === formattedCalendarDate
    );

    return !isDateInAvailableDates;
    // return formattedCalendarDate === todayDate || !isDateInAvailableDates;
  };

  const handleMonthChange = async (date, dateString) => {
    try {
      setCurrentMonth(date);
      if (facilityId) {
        setLoading(true);
        const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
        const endDate = moment(date).endOf("month").format("YYYY-MM-DD");
        const response = await checkBookingAvailableDate({
          facility: facilityId,
          startAt: startDate,
          endAt: endDate,
          isSpecial: 0,
          bookingType: _BOOKINGTYPE,
        });

        if (response.status === 200) {
          setAvailabeDates(response.data?.data);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
    // You can perform any action with the month name here
  };

  const onDateSelected = (index) => {
    const selectedStartDate = moment(scheduleFields[index].startAt).format(
      "YYYY-MM-DD"
    );

    const isToday = moment(selectedStartDate).isSame(moment(), "day");

    const _selectedDateCapacity = availableDates.find(
      (d) => d.date === selectedStartDate
    );

    let slotsAvailability = _selectedDateCapacity.slotsAvailability;

    if (isToday) {
      const currentTime = moment().format("HH:mm");
      const nextHourTime = moment()
        .add(1, "hour")
        .startOf("hour")
        .format("HH:mm");

      // Filter slots starting from the next hour
      slotsAvailability = slotsAvailability.map((slot) => {
        const slotStart = moment(`${selectedStartDate} ${slot.startTime}`);
        slot.disabled = slotStart.isBefore(moment(nextHourTime, "HH:mm"));
        return slot;
      });
    }

    const updatedCapacity = [...availableCapacityForSelectedDate];
    updatedCapacity[index] = {
      ..._selectedDateCapacity,
      slotsAvailability,
    };

    setAvailableCapacityForSelectedDate(updatedCapacity);
  };

  const handleInputChangeForAttendee = (e, index) => {
    const newInputValues = [...inputValuesForAttendees];
    newInputValues[index] = e.target.value;
    setInputValuesForAttendees(newInputValues);
  };

  const handleInputKeyPressForAttendee = (e, index) => {
    const currentInputValue = inputValuesForAttendees[index]; // Get the specific input value based on index

    if (e.key === "Enter" && currentInputValue.trim() !== "") {
      e.preventDefault();
      const name = currentInputValue.trim();
      const updatedScheduleFields = [...scheduleFields];
      const currentAttendeesCount =
        updatedScheduleFields[index]?.attendeesNames.length || 0;
      const enteredCapacity =
        parseInt(updatedScheduleFields[index]?.noOfAttendees, 10) || 0;

      if (currentAttendeesCount < enteredCapacity) {
        updatedScheduleFields[index].attendeesNames.push(name);
        setScheduleFields(updatedScheduleFields);
        setInputValuesForAttendees((prevInputValues) => {
          const newInputValues = [...prevInputValues];
          newInputValues[index] = ""; // Clear the input value for this specific index
          return newInputValues;
        });
        setErrorMessage("");
      } else {
        // Handle error or display a message indicating that the maximum capacity is reached
        const newErrors = [...fieldErrors];
        newErrors[index] = { attendeesNames: "Maximum capacity reached" };
        setFieldErrors(newErrors);
      }
    }
  };

  const onRemoveAttendee = (nameToRemove, fieldIndex) => {
    setScheduleFields((prevScheduleFields) => {
      const updatedScheduleFields = [...prevScheduleFields];
      const updatedAttendees = updatedScheduleFields[
        fieldIndex
      ].attendees.filter((name) => name !== nameToRemove);
      updatedScheduleFields[fieldIndex].attendees = updatedAttendees;

      return updatedScheduleFields;
    });
  };

  const handleSelectSlots = (selectedSlotsArray, index, field) => {
    // Collect all start and end times from the selected slots array
    const slotTimes = selectedSlotsArray.map((slot) => slot.split("-"));
    const selectedDate = moment(scheduleFields[index].startAt).format(
      "YYYY-MM-DD"
    );

    // Find and set available capacity for the selected slots based on the selected date
    const matchingDateSlot = availableCapacityForSelectedDate.find(
      (dateSlot) => dateSlot.date === selectedDate
    );

    let minAvailableCapacity = null; // Initialize the minimum available capacity

    if (matchingDateSlot) {
      // Collect capacities for all selected slots
      const capacities = slotTimes.map(([startTime, endTime]) => {
        const matchingSlot = matchingDateSlot.slotsAvailability.find(
          (slot) => slot.startTime === startTime && slot.endTime === endTime
        );

        if (matchingSlot) {
          return matchingSlot.availableCapacity;
        }
        return null;
      });

      // Filter out null capacities before finding the minimum
      const validCapacities = capacities.filter(
        (capacity) => capacity !== null
      );

      // Check if there are any valid capacities
      if (validCapacities.length > 0) {
        // Find the minimum available capacity
        minAvailableCapacity = Math.min(...validCapacities);
      } else {
        // If no valid capacities found, set minAvailableCapacity to 0
        minAvailableCapacity = 0;
      }
    }

    // Update the available capacity for this schedule with the minimum capacity found
    const updatedCapacityList = [...availabeCapacityPerHeadList];
    updatedCapacityList[index] = minAvailableCapacity;
    setAvailabeCapacityPerHeadList(updatedCapacityList);

    // Update selected slots

    const newSelectedSlots = slotTimes.map(([startTime, endTime]) => ({
      startTime,
      endTime,
      date: selectedDate,
    }));

    const mergedSlots = [
      ...selectedSlots.filter((slot) => slot.date !== selectedDate),
      ...newSelectedSlots,
    ];

    setSelectedSlots(mergedSlots);

    // Update schedule fields with new selected slots
    const updatedScheduleFields = [...scheduleFields];
    updatedScheduleFields[index] = {
      ...updatedScheduleFields[index],
      slots: newSelectedSlots,
    };

    setScheduleFields(updatedScheduleFields);
  };

  const replaceUndefinedWithEmptyString = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        replaceUndefinedWithEmptyString(obj[key]);
      } else if (obj[key] === undefined) {
        obj[key] = "";
      }
    }
  };

  const validateScheduleFields = () => {
    const errors = [];

    for (let i = 0; i < scheduleFields.length; i++) {
      const schedule = scheduleFields[i];
      let fieldError = {}; // Error object for each field

      // Check validation rules for each field in a schedule
      if (schedule.startAt === "") {
        fieldError = {
          ...fieldError,
          startAt: `Date is required`,
        };
      }
      if (schedule.noOfAttendees === 0) {
        fieldError = {
          ...fieldError,
          noOfAttendees: "Number of day pass required",
        };
      }
      if (
        selectedFacility?.attendeesInfoRequired &&
        schedule.attendees.length < schedule.noOfAttendees
      ) {
        fieldError = {
          ...fieldError,

          attendeesInfoRequiredError: `The number of attendees must match the number of day passes. Please ensure you have ${
            schedule.noOfAttendees
          } attendee${schedule.noOfAttendees > 1 ? "s" : ""}.`,

          //`Attendees name are required`,
        };
      }
      // Check if attendees.length > noOfAttendees
      if (
        selectedFacility?.attendeesInfoRequired &&
        schedule.attendees.length > schedule.noOfAttendees
      ) {
        fieldError.attendeesInfoRequiredError =
          "The number of attendees cannot exceed the number of day passes. Please reduce the number of attendees to match the day passes available.";
      }
      if (schedule.slots.length === 0) {
        fieldError = {
          ...fieldError,
          startTime: `Slot is required`,
        };
      }

      errors.push(fieldError); // Push individual field error to the array
    }

    const hasErrors = errors.some(
      (errorObj) => Object.keys(errorObj).length > 0
    );

    return hasErrors ? errors : null;
  };

  const onSubmit = async (values) => {
    try {
      const errors = validateScheduleFields();
      if (!errors) {
        const formattedScheduleFields = scheduleFields.flatMap((schedule) => {
          return schedule.slots.map((slot) => {
            const { slots, ...restSchedule } = schedule; // Destructure to exclude the slots key
            return {
              ...restSchedule,
              startAt: moment(schedule.startAt).format("YYYY-MM-DD"), // Format the startAt field
              noOfAttendees: Number(schedule.noOfAttendees), // Ensure noOfAttendees is a number
              startTime: slot.startTime, // Use slot's start time
              endTime: slot.endTime, // Use slot's end time
              date: slot.date, // Ensure the date is included from the slot
            };
          });
        });

        const payload = {
          ...values,
          dob: moment(values.dob).isValid()
            ? moment(values.dob).format("YYYY-MM-DD")
            : "",
          userId: isUserSelected ? selectedUser?.id : "",
          bookingType: "PHB",
          schedules: formattedScheduleFields,
        };
        replaceUndefinedWithEmptyString(payload);

        setLoading(true);

        const response = await createBookingRequest(payload);
        if (response.status === 200) {
          successMessage({
            message: "Booking has been created successfuly",
          });
          setLoading(false);
          props.history.goBack();
        }
      } else {
        setFieldErrors(errors);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  // Function to clear the error for the respective field upon focus
  const onFieldFocus = (index, fieldName) => {
    const updatedErrors = [...fieldErrors];
    updatedErrors[index] = { ...updatedErrors[index], [fieldName]: "" };
    setFieldErrors(updatedErrors);
  };

  const onCancelBooking = () => {
    props.history.goBack();
  };
  const disabledDateForDob = (current) => {
    // Allow dates up to a certain year (e.g., 100 years ago from now)
    return current && current > moment().endOf("day");
  };

  // In the function component definition of AttendeesSelection
  const handleAddAttendee = (index, selectedOptions) => {
    const updatedScheduleFields = [...scheduleFields];

    const attendees = selectedOptions.map((optionString) =>
      JSON.parse(optionString)
    );

    const attendeesObject = attendees.map((option) => ({
      firstName: option.firstName,
      lastName: option.lastName,
      email: option.email ? option.email : "",
      gender: option.gender ? option.gender : "",
      dob: option.dob,
      studentNumber: option.studentNumber,
      type: "student",
      // Add more properties as needed
    }));

    const manualFields = updatedScheduleFields[index].attendees.filter(
      (item) => item.type !== "student"
    );
    updatedScheduleFields[index].attendees = [
      ...attendeesObject,
      ...manualFields,
    ];

    setScheduleFields(updatedScheduleFields);
  };

  const handleAddManualAttendee = (index) => {
    // Extract the attendee fields to validate
    const attendeeFieldsToValidate = attendeeFields.map(
      (field) => `attendee_${field}`
    );

    // Get current form values
    // Validate only the attendee fields
    addBookingForm
      .validateFields(attendeeFieldsToValidate)
      .then((values) => {
        // Initialize the attendee object with empty string values for all keys
        const attendee = {
          firstName: "",
          lastName: "",
          email: "",
          gender: "",
          dob: "",
          studentNumber: "", // Ensure studentNumber is initialized with an empty string
          type: "manual",
          // Add other keys here as needed
        };
        // Check if selectedFacility and attendeeFields are defined
        if (!selectedFacility || !attendeeFields) {
          console.error("selectedFacility or attendeeFields is undefined");
          return;
        }
        // Validate each dynamic field and check if any are missing
        let missingField = false;
        const fieldsErrors = [];
        attendeeFields.forEach((key) => {
          attendee[key] = values[`attendee_${key}`] || "";
          if (!attendee[key]) {
            fieldsErrors.push({
              name: `attendee_${key}`,
              errors: [
                `${
                  convertToReadableFormat(key).charAt(0).toUpperCase() +
                  convertToReadableFormat(key).slice(1)
                } is required`,
              ],
            });

            missingField = true;
          }
        });

        if (missingField) {
          addBookingForm.setFields(fieldsErrors);
          return;
        }

        attendeeFields.forEach((key) => {
          switch (key) {
            case "firstName":
              attendee[key] = values[`attendee_${key}`];
              break;
            case "lastName":
              attendee[key] = values[`attendee_${key}`];
              break;
            case "email":
              attendee[key] = values[`attendee_${key}`]
                ? values[`attendee_${key}`]
                : "";
              break;
            case "gender":
              attendee[key] = values[`attendee_${key}`]
                ? values[`attendee_${key}`]
                : "";
              break;
            case "dob":
              const dobValue = values[`attendee_${key}`];
              if (dobValue) {
                const formattedDob = moment(dobValue).format("DD/MM/YYYY");
                attendee[key] = formattedDob;
              } else {
                attendee[key] = dobValue;
              }
              // attendee[key] = values[`attendee_${key}`];
              break;

            // Add more cases as needed for additional attendeeFields
            default:
              break;
          }
        });
        onFieldFocus(index, "attendeesInfoRequiredError");
        // Create a copy of the scheduleFields state
        const updatedScheduleFields = [...scheduleFields];

        // Check if there are existing attendees for the specified index
        if (!updatedScheduleFields[index].attendees) {
          updatedScheduleFields[index].attendees = [];
        }

        // Merge the manually added attendee with existing attendees
        updatedScheduleFields[index].attendees = [
          ...updatedScheduleFields[index].attendees,
          attendee,
        ];

        // Update the state with the new scheduleFields
        setScheduleFields(updatedScheduleFields);
        // Reset form fields after adding attendee
        addBookingForm.resetFields(attendeeFieldsToValidate);
      })
      .catch((errorInfo) => {
        console.error("Failed to add attendee:", errorInfo);
      });
  };

  const priceDetails = renderTotalPriceForBooking({
    selectedFacility,
    bookingType: "PHB",
    scheduleFields,
    discountId,
  });

  return (
    <div className="genenral_container">
      <Spin spinning={loading} indicator={renderLoader}>
        <Card className="antCardBorder antCard">
          <h5>Create Per Head Booking</h5>
          <br />
          <Form
            layout="vertical"
            name={addBookingForm}
            form={addBookingForm}
            onFinish={onSubmit}
          >
            <Row gutter={40}>
              <Col md={8}>
                <div className="customer-search-container">
                  <Form.Item label={"Select User"}>
                    <Input
                      id="customer-search"
                      type="text"
                      placeholder="Search User"
                      onFocus={handleFocus}
                      onChange={handleSearch}
                      value={selectedUser?.phoneNo || searchQuery}
                      onBlur={handleBlur}
                      autoComplete="off"
                      suffix={
                        <CloseCircleOutlined
                          onClick={handleClear}
                          style={{
                            visibility:
                              searchQuery || selectedUser?.phoneNo
                                ? "visible"
                                : "hidden",
                            cursor: "pointer",
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  {isModalVisible && (
                    <div className="customer-search-modal">
                      {searchLoading ? (
                        <div className="spiner">
                          <Spin />
                        </div>
                      ) : users?.length === 0 ? (
                        <div className="custom_empty_div">
                          <p>No Users found</p>
                        </div>
                      ) : (
                        <React.Fragment>
                          {users?.map((user, index) => {
                            return (
                              <div
                                key={index}
                                className="customer-search-result"
                                onClick={() => handleSelectUser(user)}
                              >
                                <div className="customer-search_result_container">
                                  <div className="search_result_info">
                                    <p className="custom_title">
                                      {user?.phoneNo + " " + user?.firstName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <div className="schedule__day_title">
              <div style={{ width: "25%" }}>
                <p className="schedule__day">Or enter details manually</p>
              </div>
              <div className="hr_row" />
            </div>
            <br />
            <Row gutter={40}>
              <Col md={6}>
                <Form.Item
                  label={"First Name"}
                  name={"firstName"}
                  rules={setRules("First Name")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label={"Last Name"}
                  name={"lastName"}
                  rules={setRules("Last Name")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label={"Email"}
                  name={"email"}
                  rules={setRules("Email")}
                >
                  <Input placeholder="Add here" type="email" />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label={"Phone Number"}
                  name={"phoneNumber"}
                  rules={setRules("Phone Number")}
                >
                  <Input
                    placeholder="05xxxxxxxx"
                    type="text"
                    onBlur={handelFocusOutFromUserPhoneNumberField}
                  />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label={"Emirate id"}
                  name={"emiratesId"}
                  rules={setRules("Emirate id")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label={"Gender"}
                  name={"gender"}
                  rules={setRules("Gender")}
                >
                  <Select placeholder="Select">
                    {dropDowns.map((el, i) => (
                      <Option value={el.value} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label={"Date Of Birth"}
                  name={"dob"}
                  rules={setRules("Date Of Birth")}
                >
                  <DatePicker
                    placeholder="date"
                    className="date__custom_width"
                    disabledDate={disabledDateForDob}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={40}>
              <Col md={12}>
                <Form.Item
                  label={"Select Facility"}
                  rules={setRules("Facility")}
                  name={"facilityId"}
                >
                  <Select
                    placeholder="Select"
                    onChange={(e) => handleFacilityChange(e)}
                  >
                    {facilities.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Select Discount"}
                  // rules={setRules("Discount")}
                  name={"discountId"}
                >
                  <Select
                    placeholder="Select"
                    disabled={!facilityId}
                    allowClear
                    onChange={(e) => setDiscountId(e)}
                  >
                    {discountTypes.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div className="card__snippet">
              <div className="schedule_and_addmore_section">
                <p>Schedule</p>
                <span onClick={addScheduleField}>+ Add More</span>
              </div>
              <br />
              <div id="scheduleFields">
                {scheduleFields.map((field, index) => (
                  <div key={index}>
                    <Row gutter={40}>
                      <Col md={12}>
                        <Form.Item label="Date">
                          <DatePicker
                            disabled={
                              Object.keys(selectedFacility).length === 0
                            }
                            placeholder="date"
                            className="date__custom_width"
                            value={field.startAt}
                            onChange={(date) =>
                              handleFieldChange(index, "startAt", date)
                            }
                            disabledDate={disabledDatesAndDays}
                            onPanelChange={handleMonthChange}
                            onFocus={() => onFieldFocus(index, "startAt")}
                          />
                          <p style={{ color: "red", marginTop: "5px" }}>
                            {fieldErrors[index]?.startAt}
                          </p>
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item label={"Select Slot"} name={"slots"}>
                          <Select
                            mode="multiple"
                            placeholder="Select"
                            allowClear
                            disabled={
                              !moment(scheduleFields[index].startAt).isValid()
                            }
                            onChange={(el) =>
                              handleSelectSlots(el, index, field)
                            }
                            onFocus={() => onFieldFocus(index, "startTime")}
                          >
                            {availableCapacityForSelectedDate[
                              index
                            ]?.slotsAvailability.map((el, i) => {
                              const selectedDate = moment(
                                scheduleFields[index].startAt
                              ).format("YYYY-MM-DD");
                              const currentTime = moment().format("HH:mm");
                              const isToday = moment(selectedDate).isSame(
                                moment(),
                                "day"
                              );

                              const isSlotInPast =
                                isToday && el.startTime < currentTime;

                              const isSlotSelectedForOtherSchedule =
                                scheduleFields.some((schedule, j) => {
                                  return (
                                    index !== j &&
                                    moment(schedule.startAt).format(
                                      "YYYY-MM-DD"
                                    ) === selectedDate &&
                                    Array.isArray(schedule.slots) &&
                                    schedule.slots.some(
                                      (slot) =>
                                        slot.startTime === el.startTime &&
                                        slot.endTime === el.endTime
                                    )
                                  );
                                });

                              return (
                                <Option
                                  value={`${el.startTime}-${el.endTime}`}
                                  key={i}
                                  disabled={
                                    isSlotInPast ||
                                    isSlotSelectedForOtherSchedule ||
                                    el.disabled
                                  }
                                >
                                  {formatedDateWithAmAndPm(el.startTime)} -{" "}
                                  {formatedDateWithAmAndPm(el.endTime)}
                                </Option>
                              );
                            })}
                          </Select>
                          <p style={{ color: "red", marginTop: "5px" }}>
                            {fieldErrors[index]?.startTime}
                          </p>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={40}>
                      <Col md={12}>
                        <Form.Item
                          label={`Available Capacity${
                            availabeCapacityPerHeadList[index] !== "" &&
                            availabeCapacityPerHeadList[index] !== undefined &&
                            availabeCapacityPerHeadList[index] !== null
                              ? ` (${availabeCapacityPerHeadList[index]})`
                              : ""
                          }`}
                        >
                          <Input
                            disabled={
                              !moment(
                                scheduleFields[index].startAt
                              ).isValid() ||
                              selectedSlots.length === 0 ||
                              availabeCapacityPerHeadList[index] === 0 ||
                              availabeCapacityPerHeadList[index] === ""
                            }
                            placeholder="Add here"
                            type="number"
                            value={field.noOfAttendees}
                            onChange={(e) =>
                              handleFieldChange(
                                index,
                                "noOfAttendees",
                                e.target.value
                              )
                            }
                            min={0}
                            onFocus={() => onFieldFocus(index, "noOfAttendees")}
                          />
                          <p style={{ color: "red", marginTop: "5px" }}>
                            {fieldErrors[index]?.noOfAttendees}
                          </p>
                        </Form.Item>
                      </Col>
                      {/* <Col md={12}>
                        <Form.Item label="Attendees Name">
                          <Input
                            key={index}
                            disabled={scheduleFields[index].noOfAttendees === 0}
                            type="text"
                            value={inputValuesForAttendees[index]}
                            onChange={(e) =>
                              handleInputChangeForAttendee(e, index)
                            }
                            onKeyPress={(e) =>
                              handleInputKeyPressForAttendee(e, index)
                            }
                            placeholder="Add here and press Enter to create attendee"
                            onFocus={() => {
                              setErrorMessage("");
                              onFieldFocus(index, "attendeesNames");
                            }}
                          />

                          <p style={{ color: "red", marginTop: "5px" }}>
                            {fieldErrors[index]?.attendeesNames}
                          </p>
                        </Form.Item>
                        <div>
                          {scheduleFields[index]?.attendeesNames?.map(
                            (name, i) => (
                              <div className="facility_tag" key={i}>
                                <span key={i}>{name}</span>
                                <p
                                  onClick={() => onRemoveAttendee(name, index)}
                                >
                                  x
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </Col> */}
                    </Row>
                    {Object.keys(selectedFacility).length > 0 && (
                      <>
                        <p style={{ margin: "10px 0" }}>
                          Attendees Informations
                        </p>
                        <Card>
                          {selectedFacility?.attendeesInfoMethod === "both" && (
                            <Row justify={"end"}>
                              {!showManualFields && (
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    setShowManualFields(!showManualFields)
                                  }
                                  disabled={
                                    scheduleFields[index].noOfAttendees < 1
                                  }
                                >
                                  {"Add Manually"}
                                </Button>
                              )}
                            </Row>
                          )}
                          <Row gutter={40}>
                            <>
                              {selectedFacility?.attendeesInfoMethod ===
                                "students" &&
                                selectedUser?.hasEnsAccount && (
                                  <Col md={12}>
                                    <Form.Item
                                      label={"Select from list"}
                                      name={"studentList"}
                                    >
                                      <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Select student"
                                        onChange={(value) =>
                                          handleAddAttendee(index, value)
                                        }
                                        onFocus={() =>
                                          onFieldFocus(
                                            index,
                                            "attendeesInfoRequiredError"
                                          )
                                        }
                                        disabled={
                                          scheduleFields[index].noOfAttendees <
                                          1
                                        }
                                      >
                                        {options.map((option, index) => (
                                          <Option
                                            key={index}
                                            value={JSON.stringify(option)}
                                          >
                                            {option.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                )}
                              {selectedFacility?.attendeesInfoMethod ===
                                "manual" && (
                                <>
                                  <Card
                                    style={{
                                      margin: "0 20px",
                                      width: "100%",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginBottom: 20,
                                        fontSize: 16,
                                      }}
                                    >
                                      Add Manually
                                    </p>
                                    <Row gutter={40}>
                                      {attendeeFields.map((key, index) => (
                                        <>
                                          <Col md={12} key={index}>
                                            {key === "dob" ? (
                                              <Form.Item
                                                label={convertToReadableFormat(
                                                  key
                                                )}
                                                key={key}
                                                name={`attendee_${key}`}
                                              >
                                                <DatePicker
                                                  placeholder="Date Of Birth"
                                                  className="date__custom_width"
                                                />
                                              </Form.Item>
                                            ) : key === "gender" ? (
                                              <Form.Item
                                                label={convertToReadableFormat(
                                                  key
                                                )}
                                                name={`attendee_${key}`}
                                              >
                                                <Select placeholder="Select">
                                                  {dropDowns.map((el, i) => (
                                                    <Option
                                                      value={el.value}
                                                      key={i}
                                                    >
                                                      {el.title}
                                                    </Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            ) : (
                                              <Form.Item
                                                label={convertToReadableFormat(
                                                  key
                                                )}
                                                key={key}
                                                name={`attendee_${key}`}
                                              >
                                                <Input
                                                  placeholder={`Enter ${convertToReadableFormat(
                                                    key
                                                  )}`}
                                                />
                                              </Form.Item>
                                            )}
                                          </Col>
                                          {attendeeFields?.lenght === 1 && (
                                            <Col md={12} />
                                          )}
                                        </>
                                      ))}
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                          justifyContent: "flex-end",
                                          width: "100%",
                                          margin: "0 20px",
                                        }}
                                      >
                                        <Button
                                          type="primary"
                                          className="ant__primary__button"
                                          onClick={() =>
                                            handleAddManualAttendee(index)
                                          }
                                        >
                                          {"Add Attendee"}
                                        </Button>
                                      </div>
                                    </Row>
                                  </Card>
                                </>
                              )}
                              {selectedFacility?.attendeesInfoMethod ===
                                "both" && (
                                <>
                                  {selectedUser?.hasEnsAccount && (
                                    <Col md={12}>
                                      <Form.Item
                                        label={"Select from list"}
                                        name={"studentList"}
                                      >
                                        <Select
                                          mode="multiple"
                                          style={{ width: "100%" }}
                                          placeholder="Select student"
                                          onChange={(value) =>
                                            handleAddAttendee(index, value)
                                          }
                                          onFocus={() =>
                                            onFieldFocus(
                                              index,
                                              "attendeesInfoRequiredError"
                                            )
                                          }
                                          disabled={
                                            scheduleFields[index]
                                              .noOfAttendees < 1
                                          }
                                        >
                                          {options.map((option, index) => (
                                            <Option
                                              key={index}
                                              value={JSON.stringify(option)}
                                            >
                                              {option.name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  )}
                                  <Col md={12} />
                                  <br />

                                  {showManualFields && (
                                    <Card
                                      style={{
                                        margin: "0 20px",
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                          justifyContent: "flex-end",
                                          marginBottom: 10,
                                        }}
                                      >
                                        {showManualFields && (
                                          <CloseCircleOutlined
                                            onClick={() =>
                                              setShowManualFields(false)
                                            }
                                          />
                                        )}
                                      </div>
                                      <Row gutter={40}>
                                        {attendeeFields.map((key, index) => (
                                          <Col md={12} key={index}>
                                            {key === "dob" ? (
                                              <Form.Item
                                                label={convertToReadableFormat(
                                                  key
                                                )}
                                                key={key}
                                                name={`attendee_${key}`}
                                              >
                                                <DatePicker
                                                  placeholder="Date Of Birth"
                                                  className="date__custom_width"
                                                />
                                              </Form.Item>
                                            ) : key === "gender" ? (
                                              <Form.Item
                                                label={convertToReadableFormat(
                                                  key
                                                )}
                                                name={`attendee_${key}`}
                                              >
                                                <Select placeholder="Select">
                                                  {dropDowns.map((el, i) => (
                                                    <Option
                                                      value={el.value}
                                                      key={i}
                                                    >
                                                      {el.title}
                                                    </Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            ) : (
                                              <Form.Item
                                                label={convertToReadableFormat(
                                                  key
                                                )}
                                                key={key}
                                                name={`attendee_${key}`}
                                              >
                                                <Input
                                                  placeholder={`Enter ${convertToReadableFormat(
                                                    key
                                                  )}`}
                                                />
                                              </Form.Item>
                                            )}
                                          </Col>
                                        ))}
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                            width: "100%",
                                            margin: "0 20px",
                                          }}
                                        >
                                          <Button
                                            type="primary"
                                            className="ant__primary__button"
                                            onClick={() =>
                                              handleAddManualAttendee(index)
                                            }
                                          >
                                            {"Add Attendee"}
                                          </Button>
                                        </div>
                                      </Row>
                                    </Card>
                                  )}
                                </>
                              )}
                            </>
                          </Row>
                          <p style={{ color: "red", marginTop: "5px" }}>
                            {fieldErrors[index]?.attendeesInfoRequiredError}
                          </p>
                        </Card>
                      </>
                    )}

                    <Row justify={"end"}>
                      {index !== 0 && scheduleFields.length > 1 && (
                        <div style={{ marginBottom: 20 }}>
                          <DeleteOutlined
                            shape="circle"
                            onClick={() => removeScheduleField(index)}
                          />
                        </div>
                      )}
                    </Row>
                    {scheduleFields.length > 1 && (
                      <div className="hr_row" style={{ marginTop: 30 }} />
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </div>
            <br />

            {Object.keys(facilityBookingPrice).length > 0 && (
              <div className="card__snippet">
                <h4>Pricing</h4>
                <Row gutter={40}>
                  <Col md={8}>
                    <div className="booking_price_tags">
                      <div className="price_tag">
                        <p>Sub Total:</p>
                        <b>{priceDetails?.subTotal} AED</b>
                      </div>
                      {priceDetails?.autoDiscountAmmount > 0 && (
                        <div className="price_tag">
                          <p style={{ textTransform: "capitalize" }}>
                            {priceDetails?.autoDisocuntTitle}:
                          </p>
                          <b>{priceDetails?.autoDiscountAmmount} AED</b>
                        </div>
                      )}
                      {priceDetails.maxDiscount > 0 ? (
                        <div className="price_tag">
                          <p>MaxDiscount:</p>
                          <b>{priceDetails?.maxDiscount} AED</b>
                        </div>
                      ) : (
                        <>
                          {priceDetails?.discountAmount !== 0 && (
                            <div className="price_tag">
                              <p>
                                {`Discount (${priceDetails?.discountPercentage}  %)`}
                                :
                              </p>

                              <b>{priceDetails?.discountAmount} AED</b>
                            </div>
                          )}
                        </>
                      )}
                      {priceDetails?.vatAmount !== 0 && (
                        <div className="price_tag">
                          <p>{`VAT (${selectedFacility?.vat?.rate}  %)`}:</p>
                          <b>{priceDetails?.vatAmount} AED</b>
                        </div>
                      )}
                      <div className="price_tag">
                        <p>Total:</p>
                        <b>{priceDetails?.totalAmount} AED</b>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            <br />
            <Row justify="end">
              <Button
                className="ant__primary__button"
                onClick={onCancelBooking}
              >
                Cancel
              </Button>
              <div style={{ width: 30 }} />

              <Button
                htmlType="submit"
                // loading={loading || uploading}
                type="primary"
                className="ant__primary__button"
              >
                {"Create Booking"}
              </Button>
            </Row>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default AddNewPerHeadBooking;
