import { Spin } from "antd";
import { getApplicationDetails } from "apis/applications-apis/applications";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import DiscountFinalApproval from "../discount-stages/DiscountFinal";
import DiscountPreFinalApproval from "../discount-stages/DiscountPrefinal";
import DiscountReview from "../discount-stages/DiscountReview";

const DiscountApplicationDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const applicationId = useSelector((state) => {
    return state.allApplications.currentApplicationId;
  });
  const [application, setApplication] = useState();

  useEffect(() => {
    applicationId && getDetails();
  }, [applicationId]);

  const getDetails = async () => {
    try {
      setLoading(true);
      const detailsResponse = await getApplicationDetails(applicationId);
      if (detailsResponse?.data?.data) {
        setApplication(detailsResponse.data.data);
      }
      setLoading(false);
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };
  const renderStageDetails = (stage) => {
    let stageProps = { application, refetch: getDetails, loading, ...props };
    switch (stage) {
      case "review":
        return <DiscountReview {...stageProps} />;
      case "pre_final_approval":
        return <DiscountPreFinalApproval {...stageProps} />;
      case "final_approval":
        return <DiscountFinalApproval {...stageProps} />;
      default:
        return;
    }
  };
  return (
    <Spin spinning={loading && !application} indicator={renderLoader}>
      {application
        ? renderStageDetails(application.stage.slug)
        : renderNoDataPlaceholder(loading)}
    </Spin>
  );
};
export default DiscountApplicationDetails;
