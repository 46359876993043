/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { Card, Table } from "antd";
import React from "react";
import { convertToTitleCase } from "utils/helpers/helpers";
import FinalRemarks from "../final-remarks/FinalRemarks";

function Result({ data }) {
  const columns = [
    {
      title: "Subject Name",
      dataIndex: "",
      render: (record) => convertToTitleCase(record.subjectName),
    },
    {
      title: "Obtained Marks",
      dataIndex: "obtainedMarks",
      key: "obtainedMarks",
    },
    {
      title: "Total Marks",
      dataIndex: "totalMarks",
      key: "totalMarks",
    },
    {
      title: "Percentage",
      dataIndex: "",
      render: (record) => {
        if (record.totalMarks) {
          return (
            parseFloat(
              record.obtainedMarks == 0
                ? 0
                : ((record.obtainedMarks / record.totalMarks) * 100).toFixed(2)
            ) + "%"
          );
        } else {
          return "N/A";
        }
      },
    },
  ];
  return (
    <div>
      <Card className="antCardBorder" style={{ marginTop: "10px" }}>
        <div style={{ marginBottom: "10px" }}>
          <h5>Entrance Exam Result</h5>
        </div>
        <Table dataSource={data} columns={columns} pagination={false} />
      </Card>
    </div>
  );
}

export default Result;
