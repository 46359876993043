import client from "apis/config";

export const getEventsAPI = async (page = 1) => {
  return await client.get(`events?page=${page}&limit=10`);
};
export const addEventsAPI = async (data) => {
  return await client.post("events", data);
};
export const updateEventsAPI = async (data, id) => {
  return await client.put(`events/${id}`, data);
};
export const deleteEventsAPI = async (id) => {
  return await client.delete(`events/${id}`);
};
