import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import {
  addNewFsmBanner,
  deleteFsmBanner,
  fetchAllFSMBanners,
  updateFsmBanner,
} from "./api";
import FSMBannerForm from "./FSMBannerForm";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
import { useSelector } from "react-redux";
import { fetchAllFsmFacilities } from "../facility-mangement/all-facilities/api";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const FSMBanners = () => {
  const _campuses = useSelector((state) => state.metaData.FSM_campuses);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [recordId, setRecordId] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [facilities, setFacilities] = useState([]);

  const [uploading, setUpLoading] = useState(false);
  const [file, setFile] = useState();

  const [fsmBannerForm] = Form.useForm();

  const targetOptions = [
    { title: "Website", value: "website" },
    { title: "Mobile App", value: "mobile_application" },
    { title: "Website + Mobile App", value: "both" },
  ];
  const actionTypeOptions = [
    { title: "Open In App", value: "Application" },
    { title: "Open In Browser", value: "ExternalWeb" },
  ];

  const screenOptions = [
    { title: "Facilities List", value: "facilityList" },
    { title: "Facility Details", value: "facilityDetail" },
  ];

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            fsmBannerForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Faciltiy Management Banners"}
          headerSubTitle={"Manage Banners"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };

  useEffect(() => {
    getAllFsmFacilities();
  }, []);

  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };
  useEffect(() => {
    getAllBanners();
  }, [page]);

  const getAllBanners = async () => {
    try {
      setLoading(true);
      const response = await fetchAllFSMBanners({ page: page });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const addFsmBanner = async (data) => {
    try {
      setLoading(true);
      const response = await addNewFsmBanner({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Banner added successfully" });
        await getAllBanners();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateFsmBanner = async (data) => {
    try {
      setLoading(true);
      const response = await updateFsmBanner({ id: recordId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Banner updated successfully" });
        await getAllBanners();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteFsmBanner = async (id) => {
    try {
      setLoading(true);
      const response = await deleteFsmBanner({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Banner deleted successfully" });
        await getAllBanners();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
    setSelectedRecord(null);
    setFile(null);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      type: "facility-management",
      target: "mobile_application",
      image: file,
      campuses: values?.campuses === undefined ? [] : [values?.campuses],
    };
    // return;
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateFsmBanner(data);
    } else {
      await addFsmBanner(data);
    }
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setValuesForEdit(record);

    fsmBannerForm.setFieldsValue({
      ...record,
      campuses: record?.campuses[0],
      facilityId: Number(record?.facilityId),
    });
    record.image && setFile(record.image);
    setRecordId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  const onChooseFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "fsmBanners"
        );
        setFile(path);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Image",
              // dataIndex: "image",
              key: "image",
              render: (record) => (
                <img src={record?.image} style={{ height: 50, width: 70 }} />
              ),
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },

            {
              title: "Status",
              // dataIndex: "status",
              key: "status",
              render: (record) => (record?.status ? "Active" : "Not Active"),
            },
            {
              title: "Action Type",
              dataIndex: "actionType",
              key: "actionType",
            },
            {
              title: "Facility",
              // dataIndex: "facilityId",
              // key: "facilityId",
              render: (record) =>
                facilities?.find(
                  (facility) => facility.id === record?.facilityId
                )?.title,
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Banner Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Banner?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteFsmBanner(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Banner`}
        >
          <FSMBannerForm
            onSubmit={onSubmit}
            formName={fsmBannerForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            uploading={uploading}
            onChooseFile={onChooseFile}
            file={file}
            selectedRecord={selectedRecord}
            targetOptions={targetOptions}
            actionTypeOptions={actionTypeOptions}
            screenOptions={screenOptions}
            _campuses={_campuses}
            _facilities={facilities}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default FSMBanners;
