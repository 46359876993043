import {
  CalendarOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import * as React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";
import "./RegistrationHistory.css";

const RegistrationLogs = ({ data }) => {
  const renderStageDescription = (oldStatus, newStatus, meta) => {
    if (meta?.action === "cancel_acceptance") {
      return "Acceptance Cancelled";
    }
    if (meta.action === "service_amount_updated") {
      return "Service Amount Updated";
    }
    if (meta.action === "transferred") {
      return "Application Transferred";
    }
    if (meta.action === "activate_cancelled") {
      return "Application Activated";
    }
    if (meta.action === "cancel") {
      return "Application Cancelled";
    }
    switch (oldStatus) {
      case "SG001":
        return `New Application - Submitted`;
      case "SG002":
        return `Under Review`;
      case "SG003":
        return `Pending Amendment`;
      case "SG060":
        return `ERP Integrated`;
      case "SG059":
        return `ERP Integrated`;
      case "SG006":
        return `SIS Integrated`;
      case "SG005":
        return `Accepted`;
      case "SG010":
        return `Approval initialized`;
      case "SG009":
        return `Paid and waiting for approval`;
      case "SG007":
        return `DMS Integrated`;
      case "SG008":
        return `Application Fees Paid`;
      default:
        return oldStatus;
    }
  };
  return (
    <div>
      <h6>
        {renderStageDescription(data.oldStatus, data.newStatus, data.meta)}
      </h6>
      <div className="rh-log-item">
        {data.meta?.action === "service_amount_updated" && (
          <div>
            <p>Old Amount: {data.meta.oldAmount} AED</p>
            <p>New Amount: {data.meta.newAmount} AED</p>
          </div>
        )}
        {data.meta?.action === "transferred" && (
          <div>
            <p>Application Transferred To Another Campus</p>
            <p>Old Campus: {data.meta?.oldCampus?.code}</p>
            <p>New Campus: {data.meta?.newCampus?.code}</p>
          </div>
        )}
        {data.meta?.action === "cancel" &&
          data.meta?.cancelNote?.length > 0 && (
            <p>Note: {data.meta?.cancelNote}</p>
          )}

        {data.meta?.serviceFeeAmount && (
          <p>Service Fee Amount: {data.meta.serviceFeeAmount} AED</p>
        )}
        <div className="rh-log-row">
          <CalendarOutlined className="rh-log-icon" />
          <p>
            Date of Action :{" "}
            {formateDateWithMoment(data.createdAt, "DD MMM YYYY hh:mm A")}
          </p>
        </div>
        <div className="rh-log-row">
          <UserOutlined className="rh-log-icon" />
          <p>Action Creator : {data.user?.userName}</p>
        </div>
        {/* application fees starts */}
        {data.oldStatus === "SG008" ? (
          <>
            <p>
              Payment Date :
              <b>
                {formateDateWithMoment(
                  data?.meta.dateTime,
                  "DD MMM YYYY hh:mm A"
                )}
              </b>
            </p>
            <p>
              {" "}
              Amount :<b>{data?.meta.amount} AED</b>
            </p>
          </>
        ) : (
          ""
        )}
        {/* application fee ends */}
        {data.meta?.Data && (
          <div>
            {typeof data.meta.Data === "string" ? (
              <div className="rh-log-row">
                <InfoCircleOutlined className="rh-log-icon" />
                <p>{data.meta.Data}</p>
              </div>
            ) : (
              <div>
                <div className="rh-log-row">
                  <InfoCircleOutlined className="rh-log-icon" />
                  <p>
                    ERP Update Message:{" "}
                    {data.meta.Data.ERPUpdateMessage || "None"}
                  </p>
                </div>
                <div className="rh-log-row">
                  <InfoCircleOutlined className="rh-log-icon" />

                  <p>
                    SIS Update Message:{" "}
                    {data.meta.Data.SISUpdateMessage || "None"}
                  </p>
                </div>
                <div className="rh-log-row">
                  <InfoCircleOutlined className="rh-log-icon" />

                  <p>StudentID: {data.meta.Data.StudentID || "None"}</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationLogs;
