/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col, Card, Modal } from "antd";
import styles from "../../styles/documentsReadOnly.module.css";
import defaultImg from "../../assets/img/default.png";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { FileOutlined, FilePdfOutlined } from "@ant-design/icons";
// eslint-disable-next-line jsx-a11y/img-redundant-alt

export default function DocumentsReadOnlyForm(props) {
  const [imageView, setImageViewer] = useState(null);
  const [visible, setVisible] = useState(false);
  const targetImage = (item) => {
    setImageViewer(item);
  };

  const checkIfImageOrPdf = (url) => {};

  const checkValidUrl = (url) => {};

  const renderImageOrPdf = (url) => {
    if (checkValidUrl(url)) {
      //if(checkIfImageOrPdf() == )
    }

    // <img
    //   alt="file"
    //   src={
    //     doc.value && doc.value.includes("http")
    //       ? doc.value.includes(".pdf")
    //         ? defaultImg
    //         : doc.value
    //       : defaultImg
    //   }
    //   onClick={(e) => {
    //     setVisible(true);

    //     targetImage(doc.value);
    //   }}
    // />;
  };
  const renderFiles = (item) => {
    const path = item.split(".");
    const fileName = path[path.length - 1];

    if (fileName.toLowerCase() === "pdf") {
      return (
        <div
          // style={{ display: "flex", gap: "20px", flexDirection: "column" }}
          onClick={() => window.open(item)}
        >
          <div>
            <FilePdfOutlined
              style={{
                fontSize: "505%",
                color: "red",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            />
            <br />{" "}
            <p
              style={{
                width: "105px",
                padding: "0.5rem 0.1rem 0 0",
                fontSize: "12px",
              }}
            >
              {/* {profileImagePreviewState?.name} */}
            </p>
          </div>
        </div>
      );
    } else if (item && item.includes("http")) {
      return (
        <>
          <img
            className="readOnlyDocCoverImage"
            src={item}
            alt="File"
            onClick={(e) => {
              setVisible(true);
              targetImage(item);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <img
            className="readOnlyDocCoverImage"
            src={defaultImg}
            alt="File"
            onClick={(e) => {
              setVisible(true);
              targetImage(item);
            }}
          />
        </>
      );
    }
  };
  return (
    <div className={styles.documentsReadOnlyForm}>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12}>
          <h6 style={{ marginBottom: "1rem" }}>Parents Documents</h6>

          {props?.application?.documents.map((doc, i) => {
            if (doc.documentOf === "parent") {
              return (
                <Card
                  bordered
                  hoverable
                  key={i}
                  style={{
                    width: 270,
                    margin: "1rem 0",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                  cover={
                    renderFiles(doc.value)
                    // <img
                    //   alt="file"
                    //   src={
                    //     renderFile(doc.value)
                    //     // doc.value && doc.value.includes("http")
                    //     //   ? doc.value
                    //     //   : defaultImg
                    //   }
                    // />
                  }
                >
                  <div>
                    <h6>{doc.title}</h6>
                    <small>Issue Date: &nbsp;</small>
                    <small>
                      {doc.issueDate
                        ? formateDateWithMoment(doc.issueDate, "DD MMM YYYY")
                        : "N/A"}
                    </small>
                    <br />
                    <small>Expiry Date:</small>
                    <small>
                      {doc.expiryDate
                        ? formateDateWithMoment(doc.expiryDate, "DD MMM YYYY")
                        : "N/A"}
                    </small>
                    <br />
                    <small>Parent Note:</small>
                    <small>{doc.parentNote}</small>
                  </div>
                </Card>
              );
            } else return null;
          })}
        </Col>
        <Col xs={24} sm={24} md={12}>
          <h6 style={{ marginBottom: "1rem" }}>Child Documents</h6>
          {props?.application?.documents.map((doc, i) => {
            if (doc.documentOf === "child") {
              return (
                <Card
                  bordered
                  hoverable
                  key={i}
                  style={{
                    width: 270,
                    margin: "1rem 0",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                  cover={renderFiles(doc.value)}
                >
                  <div>
                    <h6>{doc.title}</h6>
                    <small>Issue Date: &nbsp;</small>
                    <small>
                      {doc.issueDate
                        ? formateDateWithMoment(doc.issueDate, "DD MMM YYYY")
                        : "N/A"}
                    </small>
                    <br />
                    <small>Expiry Date:</small>
                    <small>
                      {doc.expiryDate
                        ? formateDateWithMoment(doc.expiryDate, "DD MMM YYYY")
                        : "N/A"}
                    </small>
                    <br />
                    <small>Parent Note:</small>
                    <small>{doc.parentNote}</small>
                  </div>
                </Card>
              );
            } else return null;
          })}
        </Col>
      </Row>
      <Modal
        className="image-previewer-modal"
        getContainer={false}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {imageView ? (
          <img src={imageView} width={"100%"} height={"100%"} alt="file" />
        ) : (
          <img src={defaultImg} width={"100%"} height={"100%"} alt="file" />
        )}
      </Modal>
    </div>
  );
}
