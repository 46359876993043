/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Button,
  Checkbox,
  Form,
  Row,
  Select,
  Tooltip,
  Popconfirm,
  Spin,
  Input,
  // Steps,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import * as React from "react";
import CommonTable from "components/Tables/CommonTable";

import { useDispatch, useSelector } from "react-redux";

import { NotificationManager } from "react-notifications";

import { allDocumentsListConfiguration } from "apis/document-list-configuration/documentListConfigurationApis";
import { _getAllDocumentsListsConfiguration } from "actions/documents-configurations/documentsConfigurationsAction";
import { addNewDocumentConfigurationAPI } from "apis/document-list-configuration/documentListConfigurationApis";
import { _addNewDocumentConfiguration } from "actions/documents-configurations/documentsConfigurationsAction";
import { requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { updateDocumentConfigurationAPI } from "apis/document-list-configuration/documentListConfigurationApis";
import { _updateDocumentConfiguration } from "actions/documents-configurations/documentsConfigurationsAction";
import { _deleteDocumentConfiguration } from "actions/documents-configurations/documentsConfigurationsAction";
import { deleteDocumentConfigurationAPI } from "apis/document-list-configuration/documentListConfigurationApis";
import { getStageMetaDataAction } from "actions/meta-data/metaDataAction";
import { renderLoader } from "utils/helpers/helpers";
import { getEServicesAPI } from "apis/e-services-api/EServicesApi";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
const { Option } = Select;

const ModalType = {
  ADD: "Add Document Configuration",
  EDIT: "Update Document Configuration",
};

const DocumentsConfiguration = (props) => {
  const dispatch = useDispatch();

  const applicationType = props.location.state.serviceName;

  const [form] = Form.useForm();
  const [showModal, toggleModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  const [editId, setEditId] = React.useState("");
  const [loading, setLoading] = React.useState();

  const [selectedCampuses, setSelectedCampuses] = React.useState([]);
  const [selectedSchools, setSelectedSchools] = React.useState([]);
  const [selectedGrades, setSelectedGrades] = React.useState([]);
  const [selectedDocuments, setSelectedDocuments] = React.useState([]);
  const [mandatory, setMandatory] = React.useState([]);
  const [selectedMandatory, setSelectedMandatory] = React.useState([]);
  const [allowNotAvailable, setAllowNotAvailable] = React.useState([]);
  const [selectedAllowNotAvailable, setSelectedAllowNotAvailable] =
    React.useState([]);
  const [campusesFilteredSchools, setCampusesFilteredSchools] = React.useState(
    []
  );
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _allSchools = useSelector((state) => state.metaData.schools);
  const _grades = useSelector((state) =>
    state.metaData.grades.filter((grade) => grade.code !== "GRADUATED")
  );
  const _allDocumentsLists = useSelector((state) => state.metaData.documents);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const _docConfigurationsList = useSelector(
    (state) => state.documentsListConfig.documentsListConfiguration
  );
  const _totalRecords = useSelector((state) => state.documentsListConfig.total);

  const _eServices = useSelector((state) =>
    state.eservices?.data?.filter(
      (eService) => eService?.applicationType === "ASR"
    )
  );

  const fetchRecords = async (page) => {
    setCurrentPage(page);
  };

  const fetchEServices = async () => {
    try {
      const paginationParams = `page=${1}&limit=${1000}`;
      const eServiceResponse = await getEServicesAPI(paginationParams);
      if (eServiceResponse.data && eServiceResponse.status === 200) {
        dispatch(_getEServicesAction(eServiceResponse.data));
      } else throw eServiceResponse.error;
    } catch (e) {
      console.log({ e });
    }
  };

  React.useEffect(() => {
    fetchAllDocumentsListConfigurations();
    dispatch(getStageMetaDataAction());
  }, [currentPage]);
  React.useEffect(() => {
    applicationType === "ASR" && fetchEServices();
  }, []);

  const fetchAllDocumentsListConfigurations = async () => {
    try {
      setLoading(true);
      const queryParams = `?applicationType=${applicationType}&page=${currentPage}&limit=${pageSize}`;
      const response = await allDocumentsListConfiguration(queryParams);
      if (response.status == 200) {
        setLoading(false);
        // setTotalRecords(response.data.total);
        dispatch(_getAllDocumentsListsConfiguration(response.data));
      } else throw response;
    } catch (error) {
      dispatch(_getAllDocumentsListsConfiguration({ data: [], total: 0 }));
      setLoading(false);
    }
  };

  let selectedCampusesesIds = [];

  React.useEffect(() => {
    filterSchoolsByCampus();
  }, [selectedCampuses]);

  React.useEffect(() => {
    if (campusesFilteredSchools?.length > 0 && applicationType === "TFD") {
      setSelectedSchools(campusesFilteredSchools);
    }
  }, [campusesFilteredSchools]);

  const filterSchoolsByCampus = () => {
    selectedCampuses.forEach((campus) => {
      selectedCampusesesIds.push(campus.id);
    });
    setCampusesFilteredSchools(
      _allSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
  };

  const onEdit = (item) => {
    toggleModal(true);
    setModalTitle(ModalType.EDIT);
    form.setFieldsValue({ eServiceId: item.eServiceId });
    setEditId(item.id);
    setSelectedCampuses(item.campuses);
    setSelectedSchools(item.schools);
    setSelectedGrades(item.grades);
    setSelectedDocuments(item.documents);
    setSelectedMandatory(item.documents.filter((doc) => !doc.isOptional));
    setMandatory(item.documents);
    setAllowNotAvailable(item.documents);
    setSelectedAllowNotAvailable(
      item.documents.filter((doc) => doc.allowNotAvailable)
    );
  };
  const onAdd = () => {
    //  open modal to create new config
    setModalTitle(ModalType.ADD);
    form.resetFields();
    setSelectedCampuses([]);
    setSelectedGrades(applicationType === "TFD" ? _grades : []);
    setSelectedDocuments([]);
    setSelectedCampuses([]);
    setSelectedSchools([]);
    setSelectedMandatory([]);
    setMandatory([]);
    setAllowNotAvailable([]);
    setSelectedAllowNotAvailable([]);
    toggleModal(true);
  };
  const onDelete = (item) => {
    setLoading(true);
    deleteDocumentConfigurationAPI(item.id)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setLoading(false);
          dispatch(_deleteDocumentConfiguration(item.id));
          successMessage({ message: "Document configuration deleted!" });
        } else throw res.data;
      })
      .catch((e) => {
        requestErrorHandel({ error: e });
        setLoading(false);
      });
  };
  const addDocumentConfig = (params) => {
    setLoading(true);
    addNewDocumentConfigurationAPI({ ...params, applicationType })
      .then((res) => {
        if (res.data.statusCode === 200 && res.data.data) {
          setLoading(false);
          dispatch(_addNewDocumentConfiguration(res.data.data));
          toggleModal(false);
          successMessage({ message: "Document configuration added!" });
        } else throw res.data;
      })
      .catch((e) => {
        requestErrorHandel({ error: e });
        setLoading(false);
        // toggleModal(false);
      });
  };
  const editDocumentConfig = async (params) => {
    setLoading(true);
    updateDocumentConfigurationAPI(editId, { ...params, applicationType })
      .then((res) => {
        if (res.data.statusCode === 200 && res.data.data) {
          setLoading(false);
          dispatch(_updateDocumentConfiguration(res.data.data));
          toggleModal(false);
          successMessage({ message: "Document configuration updated!" });
        } else throw res.data;
      })
      .catch((e) => {
        requestErrorHandel({ error: e });
        // toggleModal(false);
        setLoading(false);
      });
  };

  const onSubmit = (value) => {
    if (
      selectedCampuses.length > 0 &&
      //selectedSchools.length > 0 &&
      selectedGrades.length > 0 &&
      selectedDocuments.length > 0
    ) {
      if (applicationType === "ASR" && value.eServiceId == undefined) {
        NotificationManager.error("Please submit all fields");
        return;
      }
      let params = {
        campuses: selectedCampuses.map((param) => param.id),
        //schools: selectedSchools.map((param) => param.id),
        grades: selectedGrades.map((param) => param.id),
        documents: selectedDocuments.map((param) => {
          return {
            id: param.id,
            isOptional:
              selectedMandatory.findIndex(
                (selectedDoc) => selectedDoc.id == param.id
              ) < 0,
            allowNotAvailable:
              selectedAllowNotAvailable.findIndex(
                (selectedDoc) => selectedDoc.id == param.id
              ) > -1,
          };
        }),
      };
      if (applicationType === "ASR") {
        params = { ...params, eServiceId: value.eServiceId };
      }
      modalTitle === ModalType.ADD
        ? addDocumentConfig(params)
        : editDocumentConfig(params);
    } else {
      NotificationManager.error("Please submit all fields");
      return;
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          key={"#"}
          loading={loading}
          data={_docConfigurationsList}
          totalRecord={_totalRecords}
          fetchRecords={fetchRecords}
          header={() => (
            <TableHeader
              headerTitle={`${applicationType} - Documents Configuration`}
              headerSubTitle={`Manage configuration for single or multiple documents`}
              headerBtnTitle="Add New"
              onAddNew={onAdd}
            />
          )}
          columns={[
            {
              key: "",
              title: "Campuses",
              dataIndex: "campuses",
              render: (item) => {
                return item.length === _campuses.length
                  ? "All"
                  : item.map((el) => el.code).join(", ");
              },
            },

            {
              key: "",
              title: "Grades",
              dataIndex: "grades",
              render: (item) => {
                return item.length === _grades.length
                  ? "All"
                  : item.map((el) => el.code).join(", ");
              },
            },
            {
              key: "documents",
              title: "Documents",
              dataIndex: "documents",
              render: (item) => {
                return item.length === _allDocumentsLists.length
                  ? "All"
                  : item.map((el) => el.title).join(", ");
              },
            },
            {
              ...(applicationType === "ASR"
                ? {
                    title: "Service",
                    dataIndex: "eServiceId",
                    key: "id",
                    render: (record) =>
                      _eServices?.find((el) => el.id == record)?.name || "N/A",
                  }
                : {}),
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onEdit(item)}
                      />
                    </Tooltip>
                  </div>

                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={() => {
            toggleModal(false);
          }}
          title={
            editId !== ""
              ? "Update Documents Configuration"
              : "Add Documents Configuration"
          }
        >
          <Form form={form} layout="vertical" size="large" onFinish={onSubmit}>
            {applicationType === "ASR" && (
              <Form.Item label={"Service"} name={"eServiceId"}>
                <Select placeholder={"Select Service"}>
                  {_eServices?.map((ASRService) => (
                    <Option value={ASRService.id} key={ASRService.id}>
                      {ASRService.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label={"Documents"}>
              <Select
                mode="multiple"
                value={
                  selectedDocuments.length > 0
                    ? selectedDocuments.map((el) => el.title)?.join(", ")
                    : []
                }
                placeholder={"Select documents"}
                showSearch
                filterOption
              >
                <Option key="all" value="all">
                  <Checkbox
                    checked={
                      selectedDocuments.length === _allDocumentsLists.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedDocuments(_allDocumentsLists);
                        setMandatory(_allDocumentsLists);
                        setSelectedMandatory(selectedMandatory);
                        setSelectedAllowNotAvailable(selectedAllowNotAvailable);
                        setAllowNotAvailable(allowNotAvailable);
                      } else {
                        setSelectedDocuments([]);
                        setMandatory([]);
                        setSelectedMandatory([]);
                        setSelectedAllowNotAvailable([]);
                        setAllowNotAvailable([]);
                      }
                    }}
                  >
                    Select All
                  </Checkbox>
                </Option>
                {_allDocumentsLists.map((el, i) =>
                  applicationType === "TFD" &&
                  el.documentOf === "child" ? null : (
                    <Option value={el.title} key={i}>
                      <Checkbox
                        checked={
                          selectedDocuments.findIndex((i) => i.id === el.id) >
                          -1
                        }
                        onChange={(e) => {
                          setSelectedDocuments(
                            e.target.checked
                              ? [...selectedDocuments, el]
                              : selectedDocuments.filter((i) => i.id !== el.id)
                          );
                          setMandatory(
                            e.target.checked
                              ? [...mandatory, el]
                              : mandatory.filter((i) => i.id !== el.id)
                          );

                          setSelectedMandatory(
                            e.target.checked
                              ? selectedMandatory
                              : selectedMandatory.filter((i) => i.id !== el.id)
                          );
                          setSelectedAllowNotAvailable(
                            e.target.checked
                              ? selectedAllowNotAvailable
                              : selectedAllowNotAvailable.filter(
                                  (i) => i.id !== el.id
                                )
                          );
                          setAllowNotAvailable(
                            e.target.checked
                              ? allowNotAvailable
                              : allowNotAvailable.filter((i) => i.id !== el.id)
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.title}
                      </Checkbox>
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
            {/* optional documensts */}
            <Form.Item label={"Mandatory Documents"}>
              <Select
                mode="multiple"
                value={
                  selectedMandatory.length > 0
                    ? selectedMandatory.map((el) => el.title)?.join(", ")
                    : []
                }
                placeholder={"Select mandatory documents"}
                showSearch
                filterOption
              >
                {/* mandatory is empty when no documents are selected, hide select all when mandatory is empty */}
                {mandatory.length > 0 && (
                  <Option key="all" value="all">
                    <Checkbox
                      checked={selectedMandatory.length === mandatory.length}
                      onChange={(e) => {
                        setSelectedMandatory(e.target.checked ? mandatory : []);
                        setAllowNotAvailable(e.target.checked ? mandatory : []);
                        setSelectedAllowNotAvailable(
                          e.target.checked ? selectedAllowNotAvailable : []
                        );
                      }}
                    >
                      Select All
                    </Checkbox>
                  </Option>
                )}
                {mandatory.map((el, i) => (
                  <Option value={el.title} key={i}>
                    <Checkbox
                      checked={
                        selectedMandatory.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedMandatory(
                          e.target.checked
                            ? [...selectedMandatory, el]
                            : selectedMandatory.filter((i) => i.id !== el.id)
                        );
                        setAllowNotAvailable(
                          e.target.checked
                            ? [...allowNotAvailable, el]
                            : allowNotAvailable.filter((i) => i.id !== el.id)
                        );
                        setSelectedAllowNotAvailable(
                          e.target.checked
                            ? selectedAllowNotAvailable
                            : selectedAllowNotAvailable.filter(
                                (i) => i.id !== el.id
                              )
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      {el.title}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* optional documensts */}

            {/* allow user to select documents */}
            <Form.Item label={"Allow Not Available"}>
              <Select
                mode="multiple"
                value={
                  selectedAllowNotAvailable.length > 0
                    ? selectedAllowNotAvailable
                        .map((el) => el.title)
                        ?.join(", ")
                    : []
                }
                placeholder={'Select documents allowed as "Not Available"'}
                showSearch
                filterOption
              >
                {/* allownotavaliable array is empty when no mandatory documents are 
              selected, hide select all when mandatory / allownotavaialble is empty */}

                {allowNotAvailable.length > 0 && (
                  <Option>
                    <Checkbox
                      checked={
                        selectedAllowNotAvailable.length ===
                        allowNotAvailable.length
                      }
                      onChange={(e) => {
                        setSelectedAllowNotAvailable(
                          e.target.checked ? allowNotAvailable : []
                        );
                      }}
                    >
                      Select All
                    </Checkbox>
                  </Option>
                )}
                {allowNotAvailable.map((el, i) => (
                  <Option value={el.title} key={i}>
                    <Checkbox
                      checked={
                        selectedAllowNotAvailable.findIndex(
                          (i) => i.id === el.id
                        ) > -1
                      }
                      onChange={(e) => {
                        setSelectedAllowNotAvailable(
                          e.target.checked
                            ? [...selectedAllowNotAvailable, el]
                            : selectedAllowNotAvailable.filter(
                                (i) => i.id !== el.id
                              )
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      {el.title}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* allow user to select documents */}
            <Form.Item
              // name="campuses"
              label={"Campuses"}
            >
              <Select
                placeholder="Select campus"
                mode={"multiple"}
                value={
                  selectedCampuses.length > 0
                    ? selectedCampuses.map((item) => item.code)?.join(", ")
                    : []
                }
              >
                <Option>
                  <Checkbox
                    checked={selectedCampuses.length === _campuses.length}
                    onChange={(e) => {
                      setSelectedCampuses(e.target.checked ? _campuses : []);
                    }}
                  >
                    Select All
                  </Checkbox>
                </Option>
                {_campuses.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedCampuses.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedCampuses(
                          e.target.checked
                            ? [...selectedCampuses, el]
                            : selectedCampuses.filter((i) => i.id !== el.id)
                        );
                      }}
                      // onBlur={(e) => {
                      //   filterSchoolsByCampus();
                      // }}
                    >
                      {el.code}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item label={"Schools"}>
            <Select
              placeholder="Select school"
              mode="multiple"
              value={
                selectedSchools.length > 0
                  ? selectedSchools.map((item) => item.abbreviation)?.join(", ")
                  : []
              }
            >
              {campusesFilteredSchools.map((el) => (
                <Option value={el.id}>
                  <Checkbox
                    checked={
                      selectedSchools.findIndex((i) => i.id === el.id) > -1
                    }
                    onChange={(e) => {
                      setSelectedSchools(
                        e.target.checked
                          ? [...selectedSchools, el]
                          : selectedSchools.filter((i) => i.id !== el.id)
                      );
                    }}
                    style={{ width: "100%" }}
                  >
                    {el.abbreviation}
                  </Checkbox>
                </Option>
              ))}
            </Select>
          </Form.Item> */}
            <Form.Item label={"Grades"}>
              {applicationType === "TFD" ? (
                <Input disabled value={"All"}></Input>
              ) : (
                <Select
                  placeholder="Select grade"
                  mode="multiple"
                  value={
                    selectedGrades.length > 0
                      ? selectedGrades.map((item) => item.code)?.join(", ")
                      : []
                  }
                >
                  <Option>
                    <Checkbox
                      checked={selectedGrades.length === _grades.length}
                      onChange={(e) => {
                        setSelectedGrades(e.target.checked ? _grades : []);
                      }}
                    >
                      Select All
                    </Checkbox>
                  </Option>
                  {_grades.map((el, i) => (
                    <Option value={el.id} key={i}>
                      <Checkbox
                        checked={
                          selectedGrades.findIndex((i) => i.id === el.id) > -1
                        }
                        onChange={(e) => {
                          setSelectedGrades(
                            e.target.checked
                              ? [...selectedGrades, el]
                              : selectedGrades.filter((i) => i.id !== el.id)
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.code}
                      </Checkbox>
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving" : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default DocumentsConfiguration;
