/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Card, Spin } from "antd";
import { applicationStageLogs } from "apis/applications-apis/applications";
import StageApprovalRemarks from "components/resuable/approval/StageApprovalRemarks";
import EntranceTest from "components/resuable/entrance-test/EntranceTest";
import ScheduleObservation from "components/resuable/observation/ScheduleObservation";
import SingleInterview from "components/resuable/singleInterview/SingleInterview";
import React, { useEffect, useState } from "react";
import { requestErrorHandel } from "utils/helpers/helpers";
import styles from "styles/applicationDetails.module.css";
import RegistrationLogs from "components/resuable/registration/RegistrationHistoryLogs";
//"../../styles/applicationDetails.module.css";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { getStageDuration } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import ReviewLogs from "components/resuable/review/ReviewStageLogs";
import RegistrationFeesLogs from "components/resuable/registration-fees/RegistrationFeesLogs";
import CancellationLogs from "components/resuable/application-cancel-logs/CancelledLogs";
import FinalApprovalHistory from "components/resuable/other-requests-final-approval-log/FinalApprovalHistory";
import EmployeeLogs from "components/EmployeeLogs/EmployeeLogs";
import ServiceFeeLogs from "components/ServiceFeeLogs/ServiceFeeLogs";
import {
  CheckCircleOutlined,
  CommentOutlined,
  TrophyOutlined,
  UserOutlined,
} from "@ant-design/icons";

function ApplicationLogs(props) {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const application = props?.application;

  const hideStage = props?.hideStageName;

  useEffect(() => {
    getApplicationStageLogs();
  }, [application]);

  const getApplicationStageLogs = async () => {
    try {
      setLoading(true);
      const response = await applicationStageLogs({ id: application?.id });
      if (response.status == 200) {
        setLogs(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  const showStageTimeStats = (log) => {
    return log && log.timeLogs && log.timeLogs?.length > 0 ? (
      <>
        <span className={styles.logsStageStartEndTime}>Started: </span>
        <span>
          {formateDateWithMoment(
            log.timeLogs[0].startedAt,
            "DD, MMM YYYY, hh:mm A"
          )}
        </span>
        <br></br>
        <span className={styles.logsStageStartEndTime}>Completed: </span>
        <span>
          {log.timeLogs[0].endedAt
            ? formateDateWithMoment(
                log.timeLogs[0].endedAt,
                "DD, MMM YYYY, hh:mm A"
              )
            : "In Progress"}
        </span>
        <br />
        <span className={styles.logsStageStartEndTime}>Duration: </span>
        <span>{getStageDuration(log.timeLogs)}</span>
        <div style={{ marginBottom: "30px" }}></div>
      </>
    ) : null;
  };
  const renderLogsByStageSlug = () => {
    if (logs.length) {
      return logs.map((log) => {
        if (application.applicationType === "ENR") {
          if (log.stageSlug === "registration") {
            return (
              <div key={log.stageId}>
                {!hideStage && (
                  <>
                    <p className={styles.logsStageName}>Registration Stage</p>
                    {showStageTimeStats(log)}
                  </>
                )}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                {log.history?.length > 0 ? (
                  log.history?.map((history) => (
                    <RegistrationLogs
                      data={history}
                      key={history.id}
                    ></RegistrationLogs>
                  ))
                ) : (
                  <p>No logs available</p>
                )}
                {/* <CancellationLogs history={log.history} /> */}
              </div>
            );
          } else if (
            log.stageSlug === "interview" &&
            (log.schedules?.length > 0 || log.history?.length > 0)
          ) {
            return (
              <>
                {!hideStage && (
                  <>
                    <p className={styles.logsStageName}>Interview Stage</p>
                    {showStageTimeStats(log)}
                  </>
                )}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}

                <SingleInterview currentStageDetails={log} />
                <ServiceFeeLogs history={log.history} />
                {/* <CancellationLogs history={log.history} /> */}
              </>
            );
          } else if (log.stageSlug == "observation") {
            return (
              <>
                {log.schedules?.length || log.history?.length ? (
                  <div style={{ marginTop: "20px" }}>
                    {!hideStage && (
                      <>
                        <p className={styles.logsStageName}>Observation Step</p>
                        {showStageTimeStats(log)}
                      </>
                    )}
                  </div>
                ) : null}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}

                <ScheduleObservation currentStageDetails={log} />
                <ServiceFeeLogs history={log.history} />
                <CancellationLogs history={log.history} />
              </>
            );
          } else if (log.stageSlug == "transcript_review") {
            return (
              <>
                {log.approvals?.length || log.history?.length ? (
                  <div style={{ marginTop: "20px" }}>
                    {!hideStage && (
                      <>
                        <p className={styles.logsStageName}>
                          Transcript Review Stage
                        </p>
                        {showStageTimeStats(log)}
                      </>
                    )}
                  </div>
                ) : null}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}

                <StageApprovalRemarks remarks={log} isTranscript={true} />
                <ServiceFeeLogs history={log.history} />
                <CancellationLogs history={log.history} />
              </>
            );
          } else if (log.stageSlug == "entrance_test") {
            return (
              <>
                {log.schedules?.length || log.history.length ? (
                  <div style={{ marginTop: "20px" }}>
                    {!hideStage && (
                      <>
                        <p className={styles.logsStageName}>
                          Entrance Test Stage
                        </p>
                        {showStageTimeStats(log)}
                      </>
                    )}
                  </div>
                ) : null}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}

                <EntranceTest currentStageDetails={log} />
                <ServiceFeeLogs history={log.history} />
                <CancellationLogs history={log.history} />
              </>
            );
          } else if (log.stageSlug == "pre_final_approval") {
            return (
              <>
                {log.approvals?.length ||
                log.stageStatus?.code === "SG046" ||
                log.history?.length ? (
                  <div style={{ marginTop: "20px" }}>
                    {!hideStage && (
                      <>
                        <p className={styles.logsStageName}>
                          Pre Final Approval Stage
                        </p>
                        {showStageTimeStats(log)}
                      </>
                    )}
                  </div>
                ) : null}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}

                <StageApprovalRemarks remarks={log} />
                <ServiceFeeLogs history={log.history} />
                <CancellationLogs history={log.history} />
              </>
            );
          } else if (log.stageSlug == "final_review_approval") {
            return (
              <>
                {log.approvals?.length ||
                log.stageStatus?.code === "SG053" ||
                log.history?.length ? (
                  <div style={{ marginTop: "20px" }}>
                    {!hideStage && (
                      <>
                        <p className={styles.logsStageName}>
                          Final Approval Stage
                        </p>

                        {showStageTimeStats(log)}
                      </>
                    )}
                  </div>
                ) : null}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}

                <StageApprovalRemarks remarks={log} />
                <ServiceFeeLogs history={log.history} />
                <CancellationLogs history={log.history} />
              </>
            );
          } else if (log.stageSlug == "registration_fees") {
            return (
              <div style={{ marginTop: "20px" }}>
                {!hideStage &&
                (log.history?.length > 0 || log.approvals?.length > 0) ? (
                  <>
                    <p className={styles.logsStageName}>
                      Registration Fee Stage
                    </p>
                    <p className={styles.logsStageStartEndTime}>
                      Status: {log.stageStatus?.internalStatus}
                    </p>
                    {showStageTimeStats(log)}
                  </>
                ) : null}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}

                {log.history?.map((historyLogs) => (
                  <RegistrationFeesLogs data={historyLogs} />
                ))}
                <CancellationLogs history={log.history} />
              </div>
            );
          }
        } else if (application.applicationType === "SCH") {
          if (log.stageSlug === "review") {
            return (
              <div key={log.stageId}>
                <p className={styles.logsStageName}>Review Stage</p>
                {showStageTimeStats(log)}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                {log.history?.length > 0 ? (
                  <div>
                    {log.history?.map((history, i) => (
                      <ReviewLogs data={history} key={i} />
                    ))}
                    <CancellationLogs history={log.history} />
                  </div>
                ) : (
                  <p>No logs available</p>
                )}
              </div>
            );
          } else if (log.stageSlug === "evaluation") {
            return (
              <>
                {
                  <div style={{ marginTop: "20px" }}>
                    <p className={styles.logsStageName}>Evaluation Stage</p>
                    {showStageTimeStats(log)}
                  </div>
                }

                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                {log.history?.length > 0 ? (
                  <div>
                    {log.history?.map((history, i) => (
                      <ReviewLogs data={history} key={i} />
                    ))}
                    <CancellationLogs history={log.history} />
                  </div>
                ) : (
                  <p>No logs available</p>
                )}
                <br />
              </>
            );
          } else if (log.stageSlug === "committee_approval") {
            return (
              <>
                {
                  <div style={{ marginTop: "20px" }}>
                    <p className={styles.logsStageName}>
                      Committee Approval Stage
                    </p>
                    {showStageTimeStats(log)}
                  </div>
                }

                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                {log.history?.length > 0 ? (
                  <div>
                    {log.history?.map((history, i) => (
                      <ReviewLogs data={history} key={i} />
                    ))}
                    <CancellationLogs history={log.history} />
                  </div>
                ) : (
                  <p>No logs available</p>
                )}
                <>
                  {log?.history.length > 0 && (
                    <Card hoverable className="antCardBorder antCard">
                      <p style={{ color: "#007B85", fontSize: 17 }}>
                        Committee Approvals
                      </p>
                      {log?.history?.flatMap(
                        (item) =>
                          ["SG1620", "SG1640"].indexOf(item?.newStatus) > -1 &&
                          Array.isArray(item?.meta) &&
                          item?.meta?.map((employee) => {
                            return (
                              <div
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  paddingLeft: 10,
                                  marginTop: 10,
                                  width: "auto",
                                  borderRadius: 10,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <UserOutlined className="rh-log-icon" />
                                  <p
                                    style={{ color: "#007B85", paddingTop: 12 }}
                                  >
                                    {employee.name}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <CheckCircleOutlined className="rh-log-icon" />
                                  <p style={{ paddingTop: 12 }}>Approval: </p>
                                  {""}
                                  <p
                                    style={{ color: "#007B85", paddingTop: 12 }}
                                  >
                                    {employee.hasSubmittedFeedback
                                      ? employee.hasApproved
                                        ? "Approved"
                                        : "Rejected"
                                      : "Pending"}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <CommentOutlined className="rh-log-icon" />
                                  <p style={{ paddingTop: 12 }}>Notes: </p>
                                  {""}
                                  <p
                                    style={{ color: "#007B85", paddingTop: 12 }}
                                  >
                                    {employee.notes}
                                  </p>
                                </div>
                              </div>
                            );
                          })
                      )}
                    </Card>
                  )}
                </>
              </>
            );
          } else if (log.stageSlug === "final_approval") {
            return (
              <>
                {
                  <div style={{ marginTop: "20px" }}>
                    <p className={styles.logsStageName}>
                      Final Approval Stage{" "}
                      {application.autoApproval ? "(Auto Approved)" : ""}
                    </p>

                    {showStageTimeStats(log)}
                  </div>
                }

                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                <>
                  {log?.approvals.length > 0 && (
                    <Card hoverable className="antCardBorder antCard">
                      <p style={{ color: "#007B85", fontSize: 17 }}>
                        Approvals
                      </p>
                      {log?.approvals?.map((employee) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#f0f0f0",
                              paddingLeft: 10,
                              marginTop: 10,
                              width: "auto",
                              borderRadius: 10,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <UserOutlined className="rh-log-icon" />
                              <p style={{ color: "#007B85", paddingTop: 12 }}>
                                {employee.user?.name}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CheckCircleOutlined className="rh-log-icon" />
                              <p style={{ paddingTop: 12 }}>Approval: </p>
                              {""}
                              <p style={{ color: "#007B85", paddingTop: 12 }}>
                                {employee.isApproved ? "Approved" : "Rejected"}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CommentOutlined className="rh-log-icon" />
                              <p style={{ paddingTop: 12 }}>Notes: </p>
                              {""}
                              <p style={{ color: "#007B85", paddingTop: 12 }}>
                                {employee.notes}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </Card>
                  )}
                </>

                <br />
                {log?.history &&
                  log?.history?.map((item) => (
                    <FinalApprovalHistory data={item} />
                  ))}
                <CancellationLogs history={log.history} />

                {log?.history?.map((employee) => {
                  return (
                    <div
                      style={{
                        backgroundColor: "#f0f0f0",
                        paddingLeft: 10,
                        marginTop: 10,
                        width: 350,
                        borderRadius: 7,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TrophyOutlined className="rh-log-icon" />
                        <p style={{ paddingTop: 12 }}>Reward: </p>
                        <p style={{ color: "#007B85", paddingTop: 12 }}>
                          {employee.meta?.title}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            );
          }
        } else if (application.applicationType === "TCC") {
          if (log.stageSlug === "review") {
            return (
              <div key={log.stageId}>
                <p className={styles.logsStageName}>Review Stage</p>
                {showStageTimeStats(log)}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                {log.history?.length > 0 ? (
                  <div>
                    {log.history?.map((history, i) => (
                      <ReviewLogs data={history} key={i} />
                    ))}
                    <CancellationLogs history={log.history} />
                  </div>
                ) : (
                  <p>No logs available</p>
                )}
              </div>
            );
          } else if (log.stageSlug === "pre_final_approval") {
            return (
              <>
                {
                  <div style={{ marginTop: "20px" }}>
                    <p className={styles.logsStageName}>
                      Pre-Final Approval Stage
                    </p>
                    {showStageTimeStats(log)}
                  </div>
                }

                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                {log.history?.length > 0 ? (
                  <div>
                    {log.history?.map((history, i) => (
                      <ReviewLogs data={history} key={i} />
                    ))}
                    <CancellationLogs history={log.history} />
                  </div>
                ) : (
                  <>
                    <StageApprovalRemarks remarks={log} />
                    <ServiceFeeLogs history={log.history} />
                    <CancellationLogs history={log.history} />
                  </>
                )}
                <br />
              </>
            );
          } else if (log.stageSlug === "final_approval") {
            return (
              <>
                <div style={{ marginTop: "20px" }}>
                  <p className={styles.logsStageName}>Final Approval Stage</p>
                  {showStageTimeStats(log)}
                </div>

                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                {log.history?.length > 0 ? (
                  <div>
                    {log.history?.map((history, i) => (
                      <ReviewLogs data={history} key={i} />
                    ))}
                  </div>
                ) : (
                  <>
                    <StageApprovalRemarks remarks={log} />
                    <ServiceFeeLogs history={log.history} />
                    <CancellationLogs history={log.history} />
                  </>
                )}
              </>
            );
          } else if (log.stageSlug === "payment") {
            return (
              <>
                {logs?.history?.length > 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <p className={styles.logsStageName}>
                      Payment Stage{" "}
                      {application.autoApproval ? "(Auto Approved)" : ""}
                    </p>

                    {showStageTimeStats(log)}
                  </div>
                )}

                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}

                <br />
              </>
            );
          }
        } else {
          // ----- trp, asr -----
          if (log.stageSlug === "review") {
            return (
              <div key={log.stageId}>
                <p className={styles.logsStageName}>Review Stage</p>
                {showStageTimeStats(log)}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                {log.history?.length > 0 ? (
                  <div>
                    {log.history?.map((history, i) => (
                      <ReviewLogs data={history} key={i} />
                    ))}
                    <CancellationLogs history={log.history} />
                  </div>
                ) : (
                  <p>No logs available</p>
                )}
              </div>
            );
          } else if (log.stageSlug === "pre_final_approval") {
            return (
              <>
                {
                  <div style={{ marginTop: "20px" }}>
                    <p className={styles.logsStageName}>
                      Pre Final Approval Stage
                    </p>
                    {showStageTimeStats(log)}
                  </div>
                }
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                <ServiceFeeLogs history={log.history} />
                <StageApprovalRemarks remarks={log} />
                <br />
                <CancellationLogs history={log.history} />
              </>
            );
          } else if (log.stageSlug == "final_approval") {
            return (
              <>
                {
                  <div style={{ marginTop: "20px" }}>
                    <p className={styles.logsStageName}>
                      Final Approval Stage{" "}
                      {application.autoApproval ? "(Auto Approved)" : ""}
                    </p>

                    {showStageTimeStats(log)}
                  </div>
                }
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                <ServiceFeeLogs history={log.history} />
                <StageApprovalRemarks remarks={log} />
                <br />
                {log.history &&
                  log.history.map((item) =>
                    item.meta.action === "service_amount_updated" ? null : (
                      <FinalApprovalHistory data={item} />
                    )
                  )}
                <CancellationLogs history={log.history} />
              </>
            );
          } else if (log.stageSlug == "payment") {
            return log.approvals?.length || log.history?.length ? (
              <>
                <div style={{ marginTop: "20px" }}>
                  <p
                    style={{ marginBottom: 5 }}
                    className={styles.logsStageName}
                  >
                    Payment Stage{" "}
                    {application.autoApproval ? "(Auto Approved)" : ""}
                  </p>
                  <br />
                  {showStageTimeStats(log)}
                  {log.isCurrentStage && (
                    <EmployeeLogs employees={log.employees} />
                  )}
                  {/* <ServiceFeeLogs history={log.history} /> */}
                  {log.history?.length > 0
                    ? log.history?.map((item) => {
                        return item.meta?.action ===
                          "service_amount_updated" ? null : (
                          <div class="rh-log-item">
                            <span className={styles.logsStageStartEndTime}>
                              Date:&nbsp;
                            </span>
                            <span>
                              {formateDateWithMoment(
                                item.meta.dateTime,
                                "DD MMM YYYY hh:mm A"
                              )}
                            </span>
                            <br />
                            <span className={styles.logsStageStartEndTime}>
                              Status:&nbsp;
                            </span>
                            <span>{item?.meta?.status}</span>
                            <br />

                            <span className={styles.logsStageStartEndTime}>
                              Amount:&nbsp;
                            </span>
                            <span> {item?.meta?.amount || 0} AED</span>
                            <br />
                          </div>
                        );
                      })
                    : null}

                  {/* 
                  
                  
                  */}

                  <CancellationLogs history={log.history} />
                </div>
              </>
            ) : null;
          } else if (log.stageSlug == "integration") {
            return (
              <>
                {log.approvals?.length || log.history?.length ? (
                  <div style={{ marginTop: "20px" }}>
                    <p className={styles.logsStageName}>Integration Stage</p>

                    {showStageTimeStats(log)}
                  </div>
                ) : null}
                {log.isCurrentStage && (
                  <EmployeeLogs employees={log.employees} />
                )}
                <br />
                {log.history &&
                  log.history.map((item) => (
                    <FinalApprovalHistory data={item} />
                  ))}
                <CancellationLogs history={log.history} />
              </>
            );
          } else if (log.stageSlug == "bus_allocation") {
            return (
              <>
                {log.isCurrentStage && (
                  <>
                    <div style={{ marginTop: "20px" }}>
                      <p className={styles.logsStageName}>
                        Bus Allocation Stage
                      </p>

                      {showStageTimeStats(log)}
                    </div>
                    <EmployeeLogs employees={log.employees} />
                  </>
                )}
                <br />
              </>
            );
          }
        }
      });
    }
  };
  return (
    <Spin spinning={loading} indicator={renderLoader}>
      <div>
        <Card className="antCardBorder">
          {logs.length > 0 ? renderLogsByStageSlug() : "Logs does not exist."}
        </Card>
      </div>
    </Spin>
  );
}

export default ApplicationLogs;
