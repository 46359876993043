import QrStampGenerator from "views/Document Validator/QRGenerator/QrStampGenerator";
import cmsIcon from "../assets/img/sibar/ens-enr-icon.svg";
import licenseIcon from "../assets/img/sidebarIcons/licensing.png";
import standardsIcon from "../assets/img/sidebarIcons/standards.png";
import QrConfiguration from "views/Document Validator/QrConfiguration/QrConfiguration";
import QrTypeValidator from "views/Document Validator/QrTypeValidator/QrTypeValidator";


const DocumentValidatorRoutes = [
  {
    path: "/document-validator",
    name: "Document Validator",
    icon: cmsIcon,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "applications",
    subMenu: [
      {
        path: "/qr-document-generator",
        name: "QR Document Generator",
        icon: licenseIcon,
        component: QrStampGenerator,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "QR Stamp Generator",
        state: null,
      },
     
      {
        path: "/qr-validator-type",
        name: "QR Validator Type",
        icon: cmsIcon,
        component: QrTypeValidator,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "QR Validator Type",
        state: null,
      },
     
      {
        path: "/qr-configuration",
        name: "QR Configuration",
        icon: standardsIcon,
        component: QrConfiguration,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "QR Configuration",
        state: null,
      },
     
    ],
  },
];

export default DocumentValidatorRoutes;
