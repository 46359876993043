import React from "react";
import "./style.css";

const SccDashboardTabs = ({ onTab1, onTab2, tabIndex }) => {
  return (
    <div className="scc_dashboard_tabs_container" id="tabs">
      <div className="scc_d_t_body">
        <div
          className="scc_d_tab"
          onClick={onTab1}
          style={{
            backgroundColor: tabIndex === 0 ? "#007b85" : "",
          }}
        >
          ENS Wide Comparison
        </div>
        <div
          className="scc_d_tab"
          onClick={onTab2}
          style={{
            backgroundColor: tabIndex === 1 ? "#007b85" : "",
          }}
        >
          Monthly Report
        </div>
      </div>
    </div>
  );
};

export default SccDashboardTabs;
