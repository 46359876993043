import { UserActionKey } from "utils/constants";
const initialState = {
  users: [],
  userTotal: 0,
  cashiers: [],
  loading: false,
};
const updateUserHelper = (users, updatedItem) => {
  let newUsers = [...users];
  const index = users.findIndex((el) => el.id === updatedItem.id);
  if (index > -1) {
    newUsers[index] = updatedItem;
    return newUsers;
  } else return users;
};
const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionKey.getUsersReq:
      return {
        ...state,
        loading: true,
      };
    case UserActionKey.getUsersSuccess:
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        userTotal: action.payload.total,
      };
    case UserActionKey.getUsersFailure:
      return {
        ...state,
        loading: false,
      };
    case UserActionKey.searchUsersReq:
      return {
        ...state,
        loading: true,
      };
    case UserActionKey.searchUsersSuccess:
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        userTotal: action.payload.total,
      };
    case UserActionKey.searchUsersFailure:
      return {
        ...state,
        loading: false,
      };

    case UserActionKey.addUserReq:
      return {
        ...state,
        loading: true,
      };
    case UserActionKey.addUserSuccess:
      return {
        ...state,
        loading: false,
        users: [action.payload, ...state.users],
        userTotal: state.userTotal + 1,
      };
    case UserActionKey.addUserFailure:
      return {
        ...state,
        loading: false,
      };
    case UserActionKey.editUserReq:
      return {
        ...state,
        loading: true,
      };
    case UserActionKey.editUserSuccess:
      return {
        ...state,
        loading: false,
        users: updateUserHelper(state.users, action.payload),
      };
    case UserActionKey.editUserFailure:
      return {
        ...state,
        loading: false,
      };
    case UserActionKey.deleteUserReq:
      return {
        ...state,
        loading: true,
      };
    case UserActionKey.deleteUserSuccess:
      return {
        ...state,
        loading: false,
        users: state.users.filter((el) => el.id !== action.payload),
        userTotal: state.userTotal - 1,
      };
    case UserActionKey.deleteUserFailure:
      return {
        ...state,
        loading: false,
      };

    case UserActionKey.getCashiersReq:
      return {
        ...state,
        loading: true,
      };

    case UserActionKey.getCashiersSuccess:
      return {
        ...state,
        loading: false,
        cashiers: action.payload,
      };

    case UserActionKey.getCashiersFailure:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
export default UserReducer;
