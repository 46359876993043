import { Card } from "antd";
import React from "react";
import { Capitalize } from "utils/helpers/helpers";
import "./styles.css";

const SCHApplicationInfo = (props) => {

  return (
    <div>
      <Card>
        <h6 className="sch_info_header">Application Details</h6>
        <div className="sch_info_view">
          <p className="sch_info_title">Service :</p>
          <p className="sch_info_value">
            {Capitalize(props.application?.eService?.name || "N/A")}
          </p>
        </div>

        <div className="sch_info_view">
          <p className="sch_info_title">Award Campus :</p>
          <p className="sch_info_value">
            {props.application?.awardedCampus !== "" ? props.application?.awardedCampus : "N/A"}
          </p>
        </div>


        <div className="sch_info_view">
          <p className="sch_info_title">Reward :</p>
          <p className="sch_info_value">
            {props.application?.reward?.title || "N/A"}
          </p>
        </div>



        <div className="sch_info_view">
          <p className="sch_info_title">Evaluation Points :</p>
          <p className="sch_info_value">
            {props.application?.evaluationPoints || "N/A"}
          </p>
        </div>
        <div className="sch_info_view">
          <p className="sch_info_title">Ranking :</p>
          <p className="sch_info_value">
            {props.application?.ranking || "N/A"}
          </p>
        </div>
      </Card>
    </div>
  );
};

export default SCHApplicationInfo;
