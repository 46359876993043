/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Spin } from "antd";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import React, { useState } from "react";
import { renderLoader } from "utils/helpers/helpers";

//"../../styles/applicationDetails.module.css";

function Logs(props) {
  const [loading, setLoading] = useState(false);
  // const [logs, setLogs] = useState([]);
  const application = props?.history?.location?.state?.application;

  return (
    <Spin spinning={loading} indicator={renderLoader}>
      <div className="both-side-padding">
        <ApplicationLogs application={application} hideStageName={false} />
      </div>
    </Spin>
  );
}

export default Logs;
