import { CloseOutlined } from "@ant-design/icons";
import React from "react";
const TemplateModal = ({
  visibility,
  handleCancel,
  handleOk,
  title,
  isFormModalOpen,
  handleClose,
  children,
}) => {
  return (
    <>
      {isFormModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "-3px !important",
            left: " 0 !important",
            width: "100vw",
            inset: 0,
            backgroundColor: "#00000080",
            zIndex: 2,
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "98vh",
              width: "70%",
              overflowY: "scroll",
              background: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              padding: "2rem",
            }}
          >
            {" "}
            <CloseOutlined
              onClick={handleClose}
              style={{
                cursor: "pointer",
                margintop:"-5px"
              }}
            />
            {children}
          </div>
        </div>
      )}
    </>
  );
};
export default TemplateModal;
