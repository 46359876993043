/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import {
  Input,
  Form,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  Select,
  Button,
  Modal,
  DatePicker,
  Image,
  Card,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../assets/img/default.png";
import checkedImg from "../../assets/img/checked.png";
import uncheckedImg from "../../assets/img/unchecked.png";
import moment from "moment";

import {
  CommentOutlined,
  SyncOutlined,
  FilePdfOutlined,
  EnvironmentOutlined,
  EditOutlined,
} from "@ant-design/icons";
import styles from "../UploadedDocumentListItem/style.module.css";
import { useState } from "react";
import { _getRulesAction } from "actions/discount-rules/DiscountRulesActions";
import { getRulesAPI } from "apis/discount-rules/DiscountRulesApi";
import { useCallback } from "react";
import { Capitalize } from "utils/helpers/helpers";
import ASRFormItem from "components/ASRFormItem/ASRFormItem";
import { NotificationManager } from "react-notifications";
import CustomModal from "components/CustomModal/CustomModal";
import { getEServicesAPI } from "apis/e-services-api/EServicesApi";
import { getAllEducationSystems } from "apis/SCH/schRequests";
import { _getSCHApplicationMetaData } from "actions/meta-data/metaDataAction";
import CommonTable from "components/Tables/CommonTable";
import { fetchAllSections } from "apis/TCC/tccRequestTypes";
import { requestErrorHandel } from "utils/helpers/helpers";
import TCCCourseAssignedSection from "./TCCCourseAssignedSection";
import { convertToAMPMTime } from "utils/constants";
import { assingSectionToCourse } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { removeSectionFromCourse } from "apis/applications-apis/applications";
import { _toggleAllSectionsAssignedKey } from "actions/applications/applications-actions";
const { Option } = Select;

export default function TCCCursesTabDetails({
  isFieldsDisabled,
  approveAllFields,
  application,
  onFinish,
  name,
  form,
}) {
  const ref = useRef();

  const _tccCourses = useSelector((state) => state.metaData.TCC_courses);
  const [tccCourses, setTCCCourses] = useState([]);

  const [courses, setCourse] = useState(application?.courses);

  const [courseId, setCourseId] = useState("");
  const [courseItemId, setCourseItemId] = useState("");
  const [sections, setSections] = useState([]);
  const [courseModalOpen, setCourseModalOpen] = useState(false);
  const [courseSections, setCourseSection] = useState([]);

  const inputFieldRef = useRef();
  const [approveField, setApproveField] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [allSectionAssigned, setAllSectionAssigned] = useState(true);
  const dispatch = useDispatch();

  let mainCourseNo = 0;
  let alternativeCourseNo = 0;

  useEffect(() => {
    filterCourses();
  }, [courses]);

  useEffect(() => {
    getAllSections();
  }, []);

  useEffect(() => {
    setCourseSection(
      sections.filter((section) => section.courseId === courseId)
    );
  }, [courseId]);

  const getAllSections = async () => {
    try {
      const response = await fetchAllSections(application?.eService?.id);
      if (response.status === 200) {
        setSections(response.data.data);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const filterCourses = () => {
    const filteredCourses = _tccCourses.filter(
      (course) => courses.map((c) => c.courseId).indexOf(course.id) < 0
    );
    setTCCCourses(filteredCourses);
  };

  const onChanegStatusForField = (index, status) => {
    let _courses = [...courses];
    if (status === "approved") {
      _courses[index].status = "submitted";
      _courses[index].note = "";
    } else {
      _courses[index].status = "approved";
      _courses[index].note = "";
      _courses[index].canChangeCourse = false;
      _courses[index].canChangeSchedule = false;
    }
    setCourse(_courses);
    onFinish(_courses);
  };

  const onChangeAmendment = (index, e) => {
    let _courses = [...courses];
    _courses[index].status = "needAmendment";
    _courses[index].note = e.target.value;

    setCourse(_courses);
    onFinish(_courses);
  };
  const handelChnageCourse = (item, index) => {
    let _courses = [...courses];
    _courses[index].courseId = item;

    setCourse(_courses);
    onFinish(_courses);
  };

  const onChanegCourseCheckBox = (index, item) => {
    let _courses = [...courses];
    _courses[index].canChangeCourse = !_courses[index].canChangeCourse;

    setCourse(_courses);
    onFinish(_courses);
  };
  const onChanegScheduleCheckBox = (index, item) => {
    let _courses = [...courses];
    _courses[index].canChangeSchedule = !_courses[index].canChangeSchedule;
    setCourse(_courses);
    onFinish(_courses);
  };
  const handelSectionModal = (courseId, item) => {
    let recordKeys = [];
    setCourseId(courseId);
    setCourseItemId(item.id);
    recordKeys.push(item?.section?.id);
    setSelectedRowKeys(recordKeys);
    setSelectedRecord(item);
    setCourseModalOpen(true);
  };

  const checkIfAllSectionAssigned = (_courses) => {
    let allSectionAssigned = true;
    _courses.forEach((_course) => {
      if (!_course?.isAlternative) {
        if (Object.keys(_course?.section).length === 0) {
          allSectionAssigned = false;
        }
      }
    });
    dispatch(_toggleAllSectionsAssignedKey(allSectionAssigned));
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRecord(selectedRows[0]);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys: selectedRowKeys,
    // selectedRecord ? [selectedRecord.id] : [],
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled:
        !record?.allowIfFull && record.totalSeats - record.seatsAvailed < 1,
      // Column configuration not to be checked
      name: record.totalSeats,
    }),
  };

  const onAssingSectionToCourse = async () => {
    try {
      const data = {
        courseId: courseItemId,
        sectionId: selectedRecord.id,
      };
      const response = await assingSectionToCourse({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Section has been assigned" });
        setCourse(response.data?.data?.courses);

        setCourseModalOpen(false);
        checkIfAllSectionAssigned(response.data?.data?.courses);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const onRemoveSection = async (id) => {
    try {
      const response = await removeSectionFromCourse({ courseId: id });
      if (response.status === 200) {
        successMessage({ message: "Section has been removed successfully" });
        setCourse(response.data?.data?.courses);
        checkIfAllSectionAssigned(response.data?.data?.courses);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  return (
    <div>
      <Form
        // onFinish={onFinishInfoForm}
        form={form}
        layout={"vertical"}
        scrollToFirstError={"true"}
        className="applicationDetailsTabForm"
        size={"large"}
      >
        <p style={{ marginBottom: 10, fontSize: 16, fontWeight: "600" }}>
          Main Courses
        </p>
        {courses.map((item, index) => {
          if (!item.isAlternative) {
            mainCourseNo = mainCourseNo + 1;
            return (
              <Card
                className="antCardBorder antCard"
                style={{ marginBottom: 15 }}
              >
                <Row align={"middle"} justify={"end"}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => handelSectionModal(item.courseId, item)}
                    >
                      {Object.keys(item.section).length > 0
                        ? "Change Section"
                        : "Assign Section"}
                    </Button>
                  </Col>
                </Row>
                <Row
                  align="bottom"
                  className="compactFormItemRow"
                  justify="start"
                >
                  <Col className="compactFormItemCol" md={8}>
                    <Form.Item
                      label={`Course ${mainCourseNo}`}
                      name={`courses-${item.id}`}
                      initialValue={item.title}
                      rules={[
                        {
                          required: true,
                          message: "Courses required",
                        },
                      ]}
                    >
                      <Select
                        ref={inputFieldRef}
                        value={item.courseId}
                        disabled={
                          isFieldsDisabled || item.status === "approved"
                            ? true
                            : false
                        }
                        placeholder=""
                        onClick={(e) => {
                          if (Object.keys(item?.section).length > 0) {
                            alert(
                              "Please remove the section form this course first"
                            );
                            e.stopPropagation();
                            return;
                          }
                        }}
                        onChange={(e) => {
                          handelChnageCourse(e, index);
                        }}
                      >
                        {tccCourses.map((el, i) => (
                          <Option value={el.id} key={i}>
                            <Row justify="space-between">
                              <p>{el.title}</p>
                            </Row>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="compactFormItemCol" md={8}>
                    <Form.Item
                      name={[name, "status"]}
                      wrapperCol={{ offset: 8, span: 16 }}
                      // hidden={true}
                    >
                      {/* for input fields checkbox */}

                      <img
                        style={{
                          maxHeight: "18px",
                          maxWidth: "18px",
                          cursor: "pointer",
                          cursor:
                            isFieldsDisabled ||
                            application?.stage?.status?.code === "SG003"
                              ? "not-allowed"
                              : "pointer",
                          opacity: isFieldsDisabled ? 0.3 : 1,
                        }}
                        onClick={(e) => {
                          // return;
                          if (
                            isFieldsDisabled ||
                            application?.stage?.status?.code === "SG003"
                          ) {
                            return;
                          }
                          onChanegStatusForField(index, item.status);
                        }}
                        // src={uncheckedImg}
                        src={
                          item.status === "approved" || approveAllFields
                            ? checkedImg
                            : uncheckedImg
                        }
                      ></img>
                      {/* <span style={{ marginLeft: "4px" }}>Approve</span> */}
                      <span style={{ marginLeft: "4px" }}>
                        {item.status === "approved" ? "Approved" : "Approve"}
                      </span>
                    </Form.Item>
                  </Col>

                  <Col className="compactFormItemCol" md={8}>
                    {/* {formElement.status} */}
                    <Form.Item
                      name={`notes_${item.id}`}
                      rules={[
                        {
                          required:
                            approveField || item.status === "submitted"
                              ? false
                              : true,
                          message: "Note for amendment is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={item.status === "approved" ? true : false}
                        ref={ref}
                        placeholder="Add note for amendment"
                        prefix={<CommentOutlined />}
                        onChange={(e) => {
                          // onChangeNotes(e);
                          onChangeAmendment(index, e);
                        }}
                        defaultValue={
                          item.status === "needAmendment"
                            ? item.note
                            : undefined
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* Can chnage course */}
                <Row>
                  <Col className="compactFormItemCol" md={24}>
                    <Form.Item
                      name={[name, "canCourseChange"]}
                      wrapperCol={{ offset: 0, span: 16 }}
                      // hidden={true}
                    >
                      {/* for input fields checkbox */}

                      <img
                        style={{
                          maxHeight: "18px",
                          maxWidth: "18px",
                          cursor: "pointer",
                          cursor:
                            isFieldsDisabled ||
                            item.status === "approved" ||
                            application?.stage?.status?.code === "SG003"
                              ? "not-allowed"
                              : "pointer",
                          opacity: isFieldsDisabled ? 0.3 : 1,
                        }}
                        onClick={(e) => {
                          // return;
                          if (
                            isFieldsDisabled ||
                            item.status === "approved" ||
                            application?.stage?.status?.code === "SG003"
                          ) {
                            return;
                          }
                          onChanegCourseCheckBox(index, item.canChangeCourse);
                        }}
                        // src={uncheckedImg}
                        src={
                          item.canChangeCourse || approveAllFields
                            ? checkedImg
                            : uncheckedImg
                        }
                      ></img>
                      {/* <span style={{ marginLeft: "4px" }}>Approve</span> */}
                      <span style={{ marginLeft: "10px" }}>Change Course</span>
                    </Form.Item>
                  </Col>
                </Row>

                {/* Can chnage schdule */}

                <Row align={"middle"} justify={"start"}>
                  <Col className="compactFormItemCol" md={24}>
                    <Form.Item
                      name={[name, "canCourseChange"]}
                      wrapperCol={{ offset: 0, span: 16 }}
                      // hidden={true}
                    >
                      {/* for input fields checkbox */}

                      <img
                        style={{
                          maxHeight: "18px",
                          maxWidth: "18px",
                          cursor: "pointer",
                          cursor:
                            isFieldsDisabled ||
                            item.status === "approved" ||
                            application?.stage?.status?.code === "SG003"
                              ? "not-allowed"
                              : "pointer",
                          opacity: isFieldsDisabled ? 0.3 : 1,
                        }}
                        onClick={(e) => {
                          // return;
                          if (
                            isFieldsDisabled ||
                            item.status === "approved" ||
                            application?.stage?.status?.code === "SG003"
                          ) {
                            return;
                          }
                          // onChanegCourseCheckBox(index, item.canChangeCourse);
                          onChanegScheduleCheckBox(
                            index,
                            item.canChangeSchedule
                          );
                        }}
                        // src={uncheckedImg}
                        src={
                          item.canChangeSchedule || approveAllFields
                            ? checkedImg
                            : uncheckedImg
                        }
                      ></img>
                      {/* <span style={{ marginLeft: "4px" }}>Approve</span> */}
                      <span style={{ marginLeft: "10px" }}>
                        Change Schedule
                      </span>
                    </Form.Item>
                  </Col>
                </Row>
                <div>
                  <strong className="tcc_reson_tag">
                    Course Interest Explanation
                  </strong>
                  <p>{item?.reasonObj?.title ?? item?.reason}</p>
                </div>
                <div>
                  <strong className="tcc_reson_tag">
                    Relevant Strength For Course
                  </strong>
                  <p>{item?.strengthObj?.title ?? item?.strength}</p>
                </div>

                <TCCCourseAssignedSection
                  section={item.section}
                  onRemoveSection={() => onRemoveSection(item?.id)}
                />
              </Card>
            );
          }
        })}
        <p style={{ marginBottom: 10, fontSize: 16, fontWeight: "600" }}>
          Alternative Courses
        </p>
        {courses.map((item, index) => {
          if (item.isAlternative) {
            alternativeCourseNo = alternativeCourseNo + 1;
            return (
              <Card
                className="antCardBorder antCard"
                style={{ marginBottom: 15 }}
              >
                <Row
                  align="bottom"
                  className="compactFormItemRow"
                  justify="start"
                >
                  <Col className="compactFormItemCol" md={8}>
                    <Form.Item
                      label={`Course ${alternativeCourseNo}`}
                      name={`courses-${item.id}`}
                      initialValue={item.title}
                      rules={[
                        {
                          required: true,
                          message: "Courses required",
                        },
                      ]}
                    >
                      <Select
                        ref={inputFieldRef}
                        value={item.courseId}
                        disabled={
                          isFieldsDisabled || item.status === "approved"
                            ? true
                            : false
                        }
                        placeholder=""
                      >
                        {tccCourses.map((el, i) => (
                          <Option value={el.id} key={i}>
                            <Row justify="space-between">
                              <p>{el.title}</p>
                            </Row>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="compactFormItemCol" md={8}>
                    <Form.Item
                      name={[name, "status"]}
                      wrapperCol={{ offset: 8, span: 16 }}
                      // hidden={true}
                    >
                      {/* for input fields checkbox */}

                      <img
                        style={{
                          maxHeight: "18px",
                          maxWidth: "18px",
                          cursor: "pointer",
                          cursor:
                            isFieldsDisabled ||
                            application?.stage?.status?.code === "SG003"
                              ? "not-allowed"
                              : "pointer",
                          opacity: isFieldsDisabled ? 0.3 : 1,
                        }}
                        onClick={(e) => {
                          // return;
                          if (
                            isFieldsDisabled ||
                            application?.stage?.status?.code === "SG003"
                          ) {
                            return;
                          }
                          onChanegStatusForField(index, item.status);
                        }}
                        // src={uncheckedImg}
                        src={
                          item.status === "approved" || approveAllFields
                            ? checkedImg
                            : uncheckedImg
                        }
                      ></img>
                      {/* <span style={{ marginLeft: "4px" }}>Approve</span> */}
                      <span style={{ marginLeft: "4px" }}>
                        {item.status === "approved" ? "Approved" : "Approve"}
                      </span>
                    </Form.Item>
                  </Col>

                  <Col className="compactFormItemCol" md={8}>
                    {/* {formElement.status} */}
                    <Form.Item
                      name={`notes_${item.id}`}
                      rules={[
                        {
                          required:
                            approveField || item.status === "submitted"
                              ? false
                              : true,
                          message: "Note for amendment is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={item.status === "approved" ? true : false}
                        ref={ref}
                        placeholder="Add note for amendment"
                        prefix={<CommentOutlined />}
                        onChange={(e) => {
                          // onChangeNotes(e);
                          onChangeAmendment(index, e);
                        }}
                        defaultValue={
                          item.status === "needAmendment"
                            ? item.note
                            : undefined
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* Can chnage course */}
                <Row>
                  <Col className="compactFormItemCol" md={24}>
                    <Form.Item
                      name={[name, "canCourseChange"]}
                      wrapperCol={{ offset: 0, span: 16 }}
                      // hidden={true}
                    >
                      {/* for input fields checkbox */}

                      <img
                        style={{
                          maxHeight: "18px",
                          maxWidth: "18px",
                          cursor: "pointer",
                          cursor:
                            isFieldsDisabled ||
                            item.status === "approved" ||
                            application?.stage?.status?.code === "SG003"
                              ? "not-allowed"
                              : "pointer",
                          opacity: isFieldsDisabled ? 0.3 : 1,
                        }}
                        onClick={(e) => {
                          // return;
                          if (
                            isFieldsDisabled ||
                            item.status === "approved" ||
                            application?.stage?.status?.code === "SG003"
                          ) {
                            return;
                          }
                          onChanegCourseCheckBox(index, item.canChangeCourse);
                        }}
                        // src={uncheckedImg}
                        src={
                          item.canChangeCourse || approveAllFields
                            ? checkedImg
                            : uncheckedImg
                        }
                      ></img>
                      {/* <span style={{ marginLeft: "4px" }}>Approve</span> */}
                      <span style={{ marginLeft: "10px" }}>Change Course</span>
                    </Form.Item>
                  </Col>
                </Row>

                {/* Can chnage schdule */}

                {/* <Row align={"middle"} justify={"start"}>
                  <Col className="compactFormItemCol" md={24}>
                    <Form.Item
                      name={[name, "canCourseChange"]}
                      wrapperCol={{ offset: 0, span: 16 }}
                    >
                      <img
                        style={{
                          maxHeight: "18px",
                          maxWidth: "18px",
                          cursor: "pointer",
                          cursor:
                            isFieldsDisabled ||
                            item.status === "approved" ||
                            application?.stage?.status?.code === "SG003"
                              ? "not-allowed"
                              : "pointer",
                          opacity: isFieldsDisabled ? 0.3 : 1,
                        }}
                        onClick={(e) => {
                          if (
                            isFieldsDisabled ||
                            item.status === "approved" ||
                            application?.stage?.status?.code === "SG003"
                          ) {
                            return;
                          }
                          onChanegScheduleCheckBox(
                            index,
                            item.canChangeSchedule
                          );
                        }}
                        src={
                          item.canChangeSchedule || approveAllFields
                            ? checkedImg
                            : uncheckedImg
                        }
                      ></img>
                      <span style={{ marginLeft: "10px" }}>
                        Change Schedule
                      </span>
                    </Form.Item>
                  </Col>
                </Row> */}
                <div>
                  <strong className="tcc_reson_tag">
                    Course Interest Explanation
                  </strong>
                  <p>{item?.reasonObj?.title ?? item?.reason}</p>
                </div>
                <div>
                  <strong className="tcc_reson_tag">
                    Relevant Strength For Course
                  </strong>
                  <p>{item?.strengthObj?.title ?? item?.strength}</p>
                </div>
              </Card>
            );
          }
        })}
      </Form>

      <CustomModal
        visibility={courseModalOpen}
        handleCancel={() => {
          setSelectedRecord(null);
          setCourseModalOpen(false);
        }}
        handleOk={() => null}
        title={"Assign Section "}
      >
        <CommonTable
          rowSelection={rowSelection}
          rowKey={(record) => record.id}
          data={courseSections}
          pagination={false}
          // fetchRecords={(page) => setPage(page)}
          // totalRecord={total}
          columns={[
            {
              title: "Section",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Over Allocation",
              key: "allowIfFull",
              render: (record) =>
                record?.allowIfFull ? "Enabled" : "Disabled",
            },
            {
              title: "Teacher",
              // dataIndex: "seatsAvailed",
              key: "teacher",
              render: (record) =>
                record?.teacher?.firstName +
                " " +
                record?.teacher?.middleName +
                " " +
                record?.teacher?.lastName,
            },

            {
              title: "Remaining Seats",
              // dataIndex: "seatsAvailed",
              key: "seatsAvailed",
              render: (record) => {
                return (
                  <>
                    {record?.seatsAvailed > record?.totalSeats ? (
                      <p style={{ color: "red" }}>{`Overallocated by ${
                        record?.seatsAvailed - record?.totalSeats
                      } seats`}</p>
                    ) : (
                      record?.totalSeats - record?.seatsAvailed
                    )}
                  </>
                );
              },
            },
            {
              title: "Schedule",
              // dataIndex: "seatsAvailed",
              key: "schedule",
              render: (record) => (
                <div>
                  {/* <p>{record?.schedule?.title}</p> */}
                  <p style={{ padding: 0, margin: 0 }}>
                    {convertToAMPMTime(record?.schedule?.fromTime)} -{" "}
                    {convertToAMPMTime(record?.schedule?.toTime)}
                  </p>
                </div>
              ),
            },
            {
              title: "Location",
              dataIndex: "location",
              key: "location",
            },
          ]}
        ></CommonTable>
        <br />
        <Row align={"middle"} justify={"end"}>
          <Button
            type="default"
            onClick={onAssingSectionToCourse}
            disabled={selectedRecord === null ? true : false}
          >
            Save
          </Button>
        </Row>
      </CustomModal>
    </div>
  );
}
