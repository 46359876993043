import * as React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { getSignUrl } from "apis/get-sign-url/getSignUrl";
import { Redirect } from "react-router";
import LoginForm from "views/Login";
import _ from "lodash";
import { uploadFileToENSServer } from "apis/general-configuration/generalConfigurationapi";

export const requestErrorHandel = async ({ error }) => {
  if (error?.response?.status === 401) {
    NotificationManager.error(
      error?.response?.data?.message ?? "Internal Server Errror"
    );
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/";
    }, 500);
    return;
  } else if (!navigator.onLine) {
    NotificationManager.info(
      "No internet connection, Please check your internet"
    );
  } else {
    return NotificationManager.error(
      error?.response?.data?.message ?? "Internal Server Errror"
    );
  }
};

export const successMessage = ({ message }) => {
  return NotificationManager.success(message);
};
export const errorMessage = ({ message }) => {
  return NotificationManager.error(message);
};

export const showValidationError = (errorMsg) => {
  const debounceAlert = _.debounce(
    () => NotificationManager.error(errorMsg),
    800
  );
  debounceAlert();
};

export const formateDateWithMoment = (date, formate) => {
  return moment(date).format(formate);
};

export const Capitalize = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const removeUndercore = (value) => {
  return value.replace(/_/g, " ");
};

export const convertToTitleCase = (value) => {
  return value.replace(/_/g, " ").toUpperCase();
};
export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const modifyStageStatus = (stageSlug, stageStatus) => {
  let stageStatusStr = "";
  // interview
  if (stageSlug === "interview") {
    if (stageStatus === "need_scheduling") {
      stageStatusStr = "Under Scheduling";
    } else if (
      stageStatus === "interview_video_needed" ||
      stageStatus === "remarks_needed"
    ) {
      stageStatusStr = "Waiting Results";
    } else {
      stageStatusStr = "Remarks Added";
    }
  }
  // observation
  else if (stageSlug === "observation") {
    if (stageStatus === "need_scheduling") {
      stageStatusStr = "Under Scheduling";
    } else if (stageStatus === "remarks_needed") {
      stageStatusStr = "Waiting Results";
    } else {
      stageStatusStr = "Remarks Added";
    }
  }
  // transcript_review
  else if (stageSlug === "transcript_review") {
    if (stageStatus === "approval_needed") {
      stageStatusStr = "Under Review";
    } else if (stageStatus === "remarks_needed") {
      stageStatusStr = "Waiting Results";
    } else {
      stageStatusStr = "Remarks Added";
    }
  }
  // transcript_review
  else if (stageSlug === "entrance_test") {
    if (stageStatus === "need_scheduling") {
      stageStatusStr = "Under Scheduling";
    } else if (stageStatus === "remarks_needed") {
      stageStatusStr = "Waiting Tests Results";
    } else {
      stageStatusStr = "Remarks Added";
    }
  }

  // pre_final_approval
  else if (stageSlug === "pre_final_approval") {
    if (stageStatus === "approval_needed") {
      stageStatusStr = "Waiting Approval";
    } else {
      stageStatusStr = "Remarks Added";
    }
  }
  // pre_final_approval
  else if (stageSlug === "final_review_approval") {
    if (stageStatus === "approval_needed") {
      stageStatusStr = "Waiting Approval";
    } else {
      stageStatusStr = "Remarks Added";
    }
  }

  //registration_fees
  else if (stageSlug === "registration_fees") {
    if (stageStatus === "approval_needed") {
      stageStatusStr = "Waiting Payment";
    } else {
      stageStatusStr = "Paid";
    }
  }

  return stageStatusStr !== ""
    ? convertToTitleCase(stageStatusStr)
    : convertToTitleCase(stageStatus);
};
export const renderLoader = (
  <LoadingOutlined
    style={{ fontSize: 45, textAlign: "center", alignSelf: "center" }}
  />
);

export const renderNoDataPlaceholder = (isLoading, msg, icon) => (
  <div
    style={{
      minHeight: "50vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {icon && icon}
    <br />
    {isLoading ? <></> : msg ? msg : <h4>No Data Found</h4>}
  </div>
);

export const capitalizeFirstLetters = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const uploadSingleFileToAWS = async (file) => {
  try {
    const fileType = file.type;
    let mimeType = fileType.split("/");
    mimeType = mimeType[mimeType.length - 1];
    const response = await getSignUrl(mimeType);
    const requestObject = {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    };
    const awsresposne = await fetch(response.data.data, requestObject);
    return awsresposne;
  } catch (error) {
    console.log("error", error);
  }
};

// now this function will use for uploading images to ENS server,
// which require file and type

export const handleUploadFileToENSServer = async (file, type) => {
  try {
    const fd = new FormData();
    if (file !== null) {
      fd.append("file", file, file.name);
    }
    const response = await uploadFileToENSServer({ type: type, data: fd });
    if (response.status === 200) {
      return response.data?.data?.path;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const loginRedirect = (props) => {
  const token = localStorage.getItem("userToken");
  return token ? (
    <Redirect to={"/admin/dashboard"} />
  ) : (
    <LoginForm {...props} />
  );
};

export const stringifyNumber = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

export const isSameDateUntilMinutes = (date1, date2) => {
  return moment(date1).isSame(date2, "minute");
};

export const getStageDuration = (timeLogs) => {
  const startedAt = timeLogs[0].startedAt;
  const endedAt = timeLogs[0].endedAt
    ? timeLogs[0].endedAt
    : new Date().toJSON();

  if (endedAt && startedAt) {
    let seconds = moment(endedAt).diff(startedAt, "s");
    let minutes = seconds / 60;
    let hours = minutes / 60;
    let days = hours / 24;

    return days >= 1
      ? `${days.toFixed(0)} day(s)`
      : hours >= 1
      ? `${hours.toFixed(0)} hour(s)`
      : minutes >= 1
      ? `${minutes.toFixed(0)} minute(s)`
      : `${seconds.toFixed(0)} second(s)`;
  }
  return "N/A";
};
export const renderFeesUnits = (type = "percentage") => {
  return type === "percentage" ? "%" : " AED";
};
export const calculateRemainingFees = (
  record,
  studentNo,
  installmentNo,
  discountType,
  discountValue,
  readOnly,
  data
) => {
  let amount = record.amount;
  let discount = readOnly
    ? record.discount
    : discountValue.length == data.length
    ? discountValue[studentNo][installmentNo]
    : 0;
  // if (discountType == "percentage") {
  //   discount = (amount * discount) / 100;
  // }
  let remaining = amount - discount;
  remaining = roundToTwo(remaining);
  return isNaN(remaining) ? "-" : remaining;
};
export const getSumOfFees = (feesArray) =>
  feesArray.reduce((partailSum, invoice) => {
    return partailSum + invoice.amount;
  }, 0);

export const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
};

export const getFullDayName = (abbreviatedDay) => {
  const daysOfWeek = {
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
  };

  const dayAbbreviation = abbreviatedDay.toLowerCase();

  if (daysOfWeek.hasOwnProperty(dayAbbreviation)) {
    return daysOfWeek[dayAbbreviation];
  } else {
    return "Invalid Day"; // Return a default value or handle invalid input as per your requirement
  }
};

export const mapping = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

export const renderEventsBackgroundColor = (type) => {
  if (type === "FFB") {
    return "#007B85";
  } else if (type === "LTB") {
    return "#804BAE";
  } else if (type === "EVB") {
    return "#F97878";
  } else if (type === "DPB") {
    return "#857300";
  } else if (type === "PHB") {
    return "#008535";
  } else return "transparent";
};
export const renderEventTitleFromIndentifer = (indentifer) => {
  if (indentifer === "FFB") {
    return "Full Facility Booking";
  } else if (indentifer === "LTB") {
    return "Long Term Booking";
  } else if (indentifer === "EVB") {
    return "Event Booking";
  } else if (indentifer === "PHB") {
    return "Per Head Booking";
  } else if (indentifer === "DPB") {
    return "Day Pass Booking";
  } else return "";
};
export const renderFacilityTitleFromIndentifer = (indentifer) => {
  if (indentifer === "FFB") {
    return "Full Facility Booking";
  } else if (indentifer === "LTB") {
    return "Long Term Booking";
  } else if (indentifer === "EVB") {
    return "Event Booking";
  } else if (indentifer === "PHB") {
    return "Per Head Booking";
  } else if (indentifer === "DPB") {
    return "Day Pass Booking";
  } else return "";
};
export const renderFacilityPriceSlotFromIndentifer = (indentifer) => {
  if (indentifer === "FFB") {
    return "Full Facility Booking Price Per Hourly Slot:";
  } else if (indentifer === "LTB") {
    return "Long Term Booking Price Per Hourly Slot:";
  } else if (indentifer === "EVB") {
    return "Event Booking Price Per Hourly Slot:";
  } else if (indentifer === "PHB") {
    return "Per Head Booking Price Per Hourly Slot:";
  } else if (indentifer === "DPB") {
    return "Day Pass Booking Price Per Full Day";
  } else return "";
};
export const renderFacilityFullDayFromIndentifer = (indentifer) => {
  if (indentifer === "FFB") {
    return "Full Facility Booking Price Per Hourly Slot:";
  } else if (indentifer === "LTB") {
    return "Long Term Booking Full Day Price:";
  } else if (indentifer === "EVB") {
    return "Event Booking Full Day Price:";
  } else if (indentifer === "PHB") {
    return "Per Head Booking Price Per Hourly Slot:";
  } else if (indentifer === "DPB") {
    return "Day Pass Booking Full Day Price:";
  } else return "";
};

export const getFontSizesForEvent = (bookingDimension) => {
  const { height, width } = bookingDimension;

  let titleFontSize, subtitleFontSize, timeFontSize;

  if (height < 20) {
    titleFontSize = 9;
    subtitleFontSize = 8;
    timeFontSize = 8;
  } else if (height < 30) {
    titleFontSize = 11;
    subtitleFontSize = 10;
    timeFontSize = 10;
  } else if (height < 40) {
    titleFontSize = 12;
    subtitleFontSize = 11;
    timeFontSize = 11;
  } else {
    titleFontSize = 14;
    subtitleFontSize = 13;
    timeFontSize = 13;
  }

  // Adjust font sizes based on the provided width
  if (width <= 44.8) {
    titleFontSize -= 3;
    subtitleFontSize -= 2;
    timeFontSize -= 2;
  } else if (width <= 29.866666666666664) {
    titleFontSize -= 2;
    subtitleFontSize -= 2;
    timeFontSize -= 5;
  }

  return { titleFontSize, subtitleFontSize, timeFontSize };
};

export const getFileTypeFromUrl = (fileUrl) => {
  const fileExtension = fileUrl.split(".").pop().toLowerCase();

  // List of known file extensions and their corresponding types
  const extensionTypes = {
    pdf: "pdf",
    doc: "doc",
    docx: "doc",
    xls: "doc",
    xlsx: "doc",
    ppt: "doc",
    pptx: "doc",
    txt: "doc",
    jpg: "image",
    jpeg: "image",
    png: "image",
    gif: "image",
    bmp: "image",
    svg: "image",
    // Add more extensions and their types as needed
  };

  // Check if the extension matches known types
  if (extensionTypes[fileExtension]) {
    return extensionTypes[fileExtension];
  }

  // If unable to determine the file type, return 'unknown'
  return "unknown";
};

export const formatedDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};
export const formatedDateWithAmAndPm = (time) => {
  return moment(time, "HH:mm").format("hh:mm A");
};

export const onViewBookingDetails = (record, props) => {
  const serviceType = record?.booking?.bookingType?.identifier;
  switch (serviceType) {
    case "LTB":
      props.history.push({
        pathname: `long-term-booking/details`,
        state: { bookingId: record?.booking?.id, serviceType },
      });
      break;
    case "PHB":
      props.history.push({
        pathname: `per-head-booking/details`,
        state: { bookingId: record?.booking?.id, serviceType },
      });
      break;
    case "DPB":
      props.history.push({
        pathname: `day-pass-booking/details`,
        state: { bookingId: record?.booking?.id, serviceType },
      });
      break;
    case "FFB":
      props.history.push({
        pathname: `full-facility-booking/details`,
        state: { bookingId: record?.booking?.id, serviceType },
      });
      break;
    case "EVB":
      props.history.push({
        pathname: `event-booking/details`,
        state: { bookingId: record?.booking?.id, serviceType },
      });
      break;

    default:
      break;
  }
};

export const BOOKING_TIME = [
  {
    id: "1",
    time: "12:00 AM",
  },
  {
    id: "2",
    time: "01:00 AM",
  },
  {
    id: "3",
    time: "02:00 AM",
  },
  {
    id: "4",
    time: "03:00 AM",
  },
  {
    id: "5",
    time: "04:00 AM",
  },
  {
    id: "6",
    time: "05:00 AM",
  },
  {
    id: "7",
    time: "06:00 AM",
  },
  {
    id: "8",
    time: "07:00 AM",
  },
  {
    id: "9",
    time: "08:00 AM", //jhdhjhj
  },
  {
    id: "10",
    time: "09:00 AM",
  },
  {
    id: "11",
    time: "10:00 AM",
  },
  {
    id: "12",
    time: "11:00 AM",
  },
  {
    id: "13",
    time: "12:00 PM",
  },
  {
    id: "14",
    time: "1:00 PM",
  },
  {
    id: "15",
    time: "2:00 PM",
  },
  {
    id: "16",
    time: "3:00 PM",
  },
  {
    id: "17",
    time: "4:00 PM",
  },
  {
    id: "18",
    time: "5:00 PM",
  },
  {
    id: "19",
    time: "6:00 PM",
  },
  {
    id: "20",
    time: "7:00 PM",
  },
  {
    id: "21",
    time: "8:00 PM",
  },
  {
    id: "22",
    time: "9:00 PM",
  },
  {
    id: "23",
    time: "10:00 PM",
  },
  {
    id: "24",
    time: "11:00 PM",
  },
];

export const BOOKINGS_RANGE = [
  // 12 to 1 AM

  {
    startAt: "00:00",
    endAt: "00:15",
    top: "0",
  },
  {
    startAt: "00:15",
    endAt: "00:30",
    top: "17.5",
  },
  {
    startAt: "00:30",
    endAt: "00:45",
    top: "35",
  },
  {
    startAt: "00:45",
    endAt: "01:00",
    top: "52.5",
  },
  //1 AM to 2 Am
  {
    startAt: "01:00",
    endAt: "01:15",
    top: "69.5",
  },
  {
    startAt: "01:15",
    endAt: "01:30",
    top: "87",
  },
  {
    startAt: "01:30",
    endAt: "01:45",
    top: "104.5",
  },
  {
    startAt: "01:45",
    endAt: "02:00",
    top: "122",
  },
  //2am to 3am
  {
    startAt: "02:00",
    endAt: "02:15",
    top: "139.5",
  },
  {
    startAt: "02:15",
    endAt: "02:30",
    top: "157",
  },
  {
    startAt: "02:30",
    endAt: "02:45",
    top: "174.5",
  },
  {
    startAt: "02:45",
    endAt: "03:00",
    top: "192",
  },
  //3am to 4am
  {
    startAt: "03:00",
    endAt: "03:15",
    top: "209.5",
  },
  {
    startAt: "03:15",
    endAt: "03:30",
    top: "227",
  },
  {
    startAt: "03:30",
    endAt: "03:45",
    top: "244.5",
  },
  {
    startAt: "03:45",
    endAt: "04:00",
    top: "262",
  },
  //4am to 5am
  {
    startAt: "04:00",
    endAt: "04:15",
    top: "279.5",
  },
  {
    startAt: "04:15",
    endAt: "04:30",
    top: "297",
  },
  {
    startAt: "04:30",
    endAt: "04:45",
    top: "314.5",
  },
  {
    startAt: "04:45",
    endAt: "05:00",
    top: "332",
  },
  //5am to 6am
  {
    startAt: "05:00",
    endAt: "05:15",
    top: "349.5",
  },
  {
    startAt: "05:15",
    endAt: "05:30",
    top: "367",
  },
  {
    startAt: "05:30",
    endAt: "05:45",
    top: "384.5",
  },
  {
    startAt: "05:45",
    endAt: "06:00",
    top: "402",
  },
  //6am to 7am
  {
    startAt: "06:00",
    endAt: "06:15",
    top: "419.5",
  },
  {
    startAt: "06:15",
    endAt: "06:30",
    top: "437",
  },
  {
    startAt: "06:30",
    endAt: "06:45",
    top: "454.5",
  },
  {
    startAt: "06:45",
    endAt: "07:00",
    top: "472",
  },
  //7am to 8am
  {
    startAt: "07:00",
    endAt: "07:15",
    top: "489.5",
  },
  {
    startAt: "07:15",
    endAt: "07:30",
    top: "507",
  },
  {
    startAt: "07:30",
    endAt: "07:45",
    top: "524.5",
  },
  {
    startAt: "07:45",
    endAt: "08:00",
    top: "542",
  },
  //8am to 9am
  {
    startAt: "08:00",
    endAt: "08:15",
    top: "559.5",
  },
  {
    startAt: "08:15",
    endAt: "08:30",
    top: "577",
  },
  {
    startAt: "08:30",
    endAt: "08:45",
    top: "594.5",
  },
  {
    startAt: "08:45",
    endAt: "09:00",
    top: "612",
  },
  //9am to 10am
  {
    startAt: "09:00",
    endAt: "09:15",
    top: "629.5",
  },
  {
    startAt: "09:15",
    endAt: "09:30",
    top: "647",
  },
  {
    startAt: "09:30",
    endAt: "09:45",
    top: "664.5",
  },
  {
    startAt: "09:45",
    endAt: "10:00",
    top: "682",
  },
  //10am to 11am
  {
    startAt: "10:00",
    endAt: "10:15",
    top: "699.5",
  },
  {
    startAt: "10:15",
    endAt: "10:30",
    top: "717",
  },
  {
    startAt: "10:30",
    endAt: "10:45",
    top: "734.5",
  },
  {
    startAt: "10:45",
    endAt: "11:00",
    top: "752",
  },
  //11am to 12pm
  {
    startAt: "11:00",
    endAt: "11:15",
    top: "769.5",
  },
  {
    startAt: "11:15",
    endAt: "11:30",
    top: "787",
  },
  {
    startAt: "11:30",
    endAt: "11:45",
    top: "804.5",
  },
  {
    startAt: "11:45",
    endAt: "12:00",
    top: "822",
  },
  //12pm to 13pm
  {
    startAt: "12:00",
    endAt: "12:15",
    top: "839.5",
  },
  {
    startAt: "12:15",
    endAt: "12:30",
    top: "857",
  },
  {
    startAt: "12:30",
    endAt: "12:45",
    top: "874.5",
  },
  {
    startAt: "12:45",
    endAt: "13:00",
    top: "892",
  },
  //13pm to 14pm
  {
    startAt: "13:00",
    endAt: "13:15",
    top: "909.5",
  },
  {
    startAt: "13:15",
    endAt: "13:30",
    top: "927",
  },
  {
    startAt: "13:30",
    endAt: "13:45",
    top: "944.5",
  },
  {
    startAt: "13:45",
    endAt: "14:00",
    top: "962",
  },
  //14pm to 15pm
  {
    startAt: "14:00",
    endAt: "14:15",
    top: "979.5",
  },
  {
    startAt: "14:15",
    endAt: "14:30",
    top: "997",
  },
  {
    startAt: "14:30",
    endAt: "14:45",
    top: "1014.5",
  },
  {
    startAt: "14:45",
    endAt: "15:00",
    top: "1032",
  },
  //15pm to 16pm
  {
    startAt: "15:00",
    endAt: "15:15",
    top: "1049.5",
  },
  {
    startAt: "15:15",
    endAt: "15:30",
    top: "1067",
  },
  {
    startAt: "15:30",
    endAt: "15:45",
    top: "1084.5",
  },
  {
    startAt: "15:45",
    endAt: "16:00",
    top: "1102",
  },
  //16pm to 17pm
  {
    startAt: "16:00",
    endAt: "16:15",
    top: "1119.5",
  },
  {
    startAt: "16:15",
    endAt: "16:30",
    top: "1137",
  },
  {
    startAt: "16:30",
    endAt: "16:45",
    top: "1154.5",
  },
  {
    startAt: "16:45",
    endAt: "17:00",
    top: "1172",
  },
  //17pm to 18pm
  {
    startAt: "17:00",
    endAt: "17:15",
    top: "1189.5",
  },
  {
    startAt: "17:15",
    endAt: "17:30",
    top: "1207",
  },
  {
    startAt: "17:30",
    endAt: "17:45",
    top: "1224.5",
  },
  {
    startAt: "17:45",
    endAt: "18:00",
    top: "1242",
  },
  //18pm to 19pm
  {
    startAt: "18:00",
    endAt: "18:15",
    top: "1259.5",
  },
  {
    startAt: "18:15",
    endAt: "18:30",
    top: "1277",
  },
  {
    startAt: "18:30",
    endAt: "18:45",
    top: "1294.5",
  },
  {
    startAt: "18:45",
    endAt: "19:00",
    top: "1312",
  },
  //19pm to 20pm
  {
    startAt: "19:00",
    endAt: "19:15",
    top: "1329.5",
  },
  {
    startAt: "19:15",
    endAt: "19:30",
    top: "1347",
  },
  {
    startAt: "19:30",
    endAt: "19:45",
    top: "1364.5",
  },
  {
    startAt: "19:45",
    endAt: "20:00",
    top: "1382",
  },
  //20pm to 21pm
  {
    startAt: "20:00",
    endAt: "20:15",
    top: "1399.5",
  },
  {
    startAt: "20:15",
    endAt: "20:30",
    top: "1417",
  },
  {
    startAt: "20:30",
    endAt: "20:45",
    top: "1434.5",
  },
  {
    startAt: "20:45",
    endAt: "21:00",
    top: "1452",
  },
  //21pm to 22pm
  {
    startAt: "21:00",
    endAt: "21:15",
    top: "1469.5",
  },
  {
    startAt: "21:15",
    endAt: "21:30",
    top: "1487",
  },
  {
    startAt: "21:30",
    endAt: "21:45",
    top: "1504.5",
  },
  {
    startAt: "21:45",
    endAt: "22:00",
    top: "1522",
  },
  //22pm to 23pm
  {
    startAt: "22:00",
    endAt: "22:15",
    top: "1539.5",
  },
  {
    startAt: "22:15",
    endAt: "22:30",
    top: "1557",
  },
  {
    startAt: "22:30",
    endAt: "22:45",
    top: "1574.5",
  },
  {
    startAt: "22:45",
    endAt: "23:00",
    top: "1592",
  },
  //23pm to 24am
  {
    startAt: "23:00",
    endAt: "23:15",
    top: "1609.5",
  },
  {
    startAt: "23:15",
    endAt: "23:30",
    top: "1627",
  },
  {
    startAt: "23:30",
    endAt: "23:45",
    top: "1644.5",
  },
  {
    startAt: "23:45",
    endAt: "24:00",
    top: "1662",
  },
];

export const calculateTopAndHeight = (booking, allBookings) => {
  let counter = 0;
  let top = 0;
  let height = 0;
  let width = 89.6;
  let left = 10.5;

  const bookingsAtSameSlot = allBookings.filter(
    (b) => b.startTime === booking.startTime
  );
  for (const range of BOOKINGS_RANGE) {
    if (
      (booking.startTime >= range.startAt && booking.startTime < range.endAt) ||
      (booking.startTime < range.startAt && booking.endTime >= range.endAt)
    ) {
      counter++;
      if (counter == 1) {
        top = range.top;
      }
    }
  }
  height = counter * 16.5;
  // Logic for normal bookings
  if (counter > 1) {
    width = 89.6 / bookingsAtSameSlot.length;
  }

  left += width * bookingsAtSameSlot.indexOf(booking);

  return { height, top, width, left };
};

let columnKeyCounter = 0;

export const generateUniqueColumnKey = () => {
  columnKeyCounter += 1; // Increment the counter for each column
  return `column_${columnKeyCounter}`; // Generate a unique key
};

export const convertToCamelCase = (str) => {
  return str.replace(/_([a-z])/g, (match, char) => char.toUpperCase());
};

export const renderTotalPriceForBooking = ({
  selectedFacility,
  bookingType,
  scheduleFields,
  discountId,
  selectedScheduleType,
  selectedSlots,
}) => {
  let priceDetails = {
    subTotal: 0,
    discountAmount: 0,
    autoDiscountAmmount: 0,
    autoDisocuntTitle: "",
    maxDiscountAmount: 0,
    discountPercentage: 0,
    vatAmount: 0,
    totalAmount: 0,
  };

  if (!selectedFacility || !selectedFacility.pricing || !scheduleFields) {
    return priceDetails;
  }

  const facilityBookingPrice = selectedFacility.pricing.find(
    (type) => type.typeIdentifier === bookingType
  );

  if (!facilityBookingPrice) {
    return priceDetails;
  }
  const discounts = selectedFacility?.discounts;

  // const groupDiscount = getDiscountByType(discounts, "group");
  // const incrementalDiscount = getDiscountByType(discounts, "incremental");

  const facilityDiscount = discounts.find(
    (discount) => discount.id === discountId
  );
  const groupDiscount = discounts.find(
    (discount) => discount.id === discountId
  );
  const incrementalDiscount = discounts.find(
    (discount) => discount.id === discountId
  );

  const { typeIdentifier, hourlyPrice, fullDayPrice, daysBasedPricing } =
    facilityBookingPrice;

  if (typeIdentifier === "DPB") {
    scheduleFields.forEach((element) => {
      if (element.startAt && moment(element.startAt).isValid()) {
        if (groupDiscount) {
          const totalPrice = element.noOfAttendees * fullDayPrice;
          const _groupThresoldDiscountAmount =
            calculateAutoDiscountedAmountForGroup(
              totalPrice,
              element.noOfAttendees,
              groupDiscount?.thresholds
            );
          priceDetails.autoDiscountAmmount = _groupThresoldDiscountAmount;
          priceDetails.autoDisocuntTitle = groupDiscount?.title;
        } else if (incrementalDiscount) {
          const _groupThresoldDiscountAmount =
            calculateAutoDiscountedAmountForIncremental(
              fullDayPrice,
              element.noOfAttendees,
              incrementalDiscount?.thresholds
            );
          priceDetails.autoDiscountAmmount = _groupThresoldDiscountAmount;
          priceDetails.autoDisocuntTitle = incrementalDiscount?.title;
        }

        priceDetails.subTotal += element.noOfAttendees * fullDayPrice;
      }
    });
  } else if (typeIdentifier === "PHB") {
    let totalPrice = 0;

    scheduleFields.forEach((element) => {
      if (
        element.startAt &&
        moment(element.startAt).isValid() &&
        element.slots
      ) {
        element.slots.forEach((slot) => {
          const matchingSchedule = selectedFacility?.schedule?.days?.find(
            (schedule) =>
              schedule.day.toLowerCase() ===
              moment(element.startAt).format("ddd").toLowerCase()
          );

          if (matchingSchedule) {
            const duration = matchingSchedule.duration;

            const calculatedPrice = (duration / 60) * hourlyPrice;
            priceDetails.subTotal += element.noOfAttendees * calculatedPrice;
            totalPrice += element.noOfAttendees * calculatedPrice;

            if (groupDiscount) {
              totalPrice += element.noOfAttendees * fullDayPrice;
              const _groupThresoldDiscountAmount =
                calculateAutoDiscountedAmountForGroup(
                  totalPrice,
                  element.noOfAttendees,
                  groupDiscount?.thresholds
                );
              priceDetails.autoDiscountAmmount = _groupThresoldDiscountAmount;
              priceDetails.autoDisocuntTitle = groupDiscount?.title;
            } else if (incrementalDiscount) {
              const _groupThresoldDiscountAmount =
                calculateAutoDiscountedAmountForIncremental(
                  hourlyPrice,
                  element.noOfAttendees,
                  incrementalDiscount?.thresholds
                );
              priceDetails.autoDiscountAmmount = _groupThresoldDiscountAmount;
              priceDetails.autoDisocuntTitle = incrementalDiscount?.title;
            }
          }
        });
      }
    });
  } else if (typeIdentifier === "FFB") {
    let totalPrice = 0;

    scheduleFields.forEach((element) => {
      if (
        element.startAt &&
        moment(element.startAt).isValid() &&
        element.slots
      ) {
        element.slots.forEach((slot) => {
          const matchingSchedule = selectedFacility?.schedule?.days?.find(
            (schedule) =>
              schedule.day.toLowerCase() ===
              moment(element.startAt).format("ddd").toLowerCase()
          );

          if (matchingSchedule) {
            const duration = matchingSchedule.duration;
            const calculatedPrice = (duration / 60) * hourlyPrice;
            totalPrice += element.noOfAttendees * calculatedPrice;
          }
        });
      }
    });

    priceDetails.subTotal = totalPrice;
  } else if (typeIdentifier === "EVB") {
    scheduleFields.forEach((element) => {
      let totalPrice = 0;
      if (element.startAt && moment(element.startAt).isValid()) {
        const matchingSchedule = selectedFacility?.schedule?.days?.find(
          (schedule) =>
            schedule.day.toLowerCase() ===
            moment(element.startAt).format("ddd").toLowerCase()
        );

        if (matchingSchedule) {
          const duration = matchingSchedule.duration;

          if (selectedScheduleType === "fullDay") {
            priceDetails.subTotal += element.noOfAttendees * fullDayPrice;
            totalPrice += element.noOfAttendees * fullDayPrice;
          } else if (selectedScheduleType === "slots") {
            selectedSlots.forEach((slot) => {
              // Check if duration is 30 and apply different logic

              const calculatedPrice = (duration / 60) * hourlyPrice;
              priceDetails.subTotal += element.noOfAttendees * calculatedPrice;
              totalPrice += element.noOfAttendees * calculatedPrice;
            });
          }
          if (groupDiscount) {
            const _groupThresoldDiscountAmount =
              calculateAutoDiscountedAmountForGroup(
                totalPrice,
                element.noOfAttendees,
                groupDiscount?.thresholds
              );
            priceDetails.autoDiscountAmmount = _groupThresoldDiscountAmount;
            priceDetails.autoDisocuntTitle = groupDiscount?.title;
          } else if (incrementalDiscount) {
            const _groupThresoldDiscountAmount =
              calculateAutoDiscountedAmountForIncremental(
                totalPrice,
                element.noOfAttendees,
                incrementalDiscount?.thresholds
              );
            priceDetails.autoDiscountAmmount = _groupThresoldDiscountAmount;
            priceDetails.autoDisocuntTitle = incrementalDiscount?.title;
          }
        }
      }
    });
  } else if (typeIdentifier === "LTB") {
    let { totalPrice, thresoldDiscountAmount, discountTitle } =
      calculatePricingForLongTermBooking(
        scheduleFields[0],
        selectedFacility?.schedule,
        selectedScheduleType,
        hourlyPrice,
        fullDayPrice,
        selectedSlots,
        daysBasedPricing,
        groupDiscount,
        incrementalDiscount
      );
    priceDetails.subTotal += totalPrice;
    priceDetails.autoDiscountAmmount += thresoldDiscountAmount;
    priceDetails.autoDisocuntTitle = discountTitle;
  }

  // amount after auto discount
  let subTotalAmountAfterDiscount =
    priceDetails.subTotal - priceDetails.autoDiscountAmmount;

  if (facilityDiscount) {
    priceDetails.discountPercentage = facilityDiscount?.discount;
    const calculatedDiscountAmount =
      (facilityDiscount.discount / 100) * subTotalAmountAfterDiscount;

    const maxDiscount = facilityDiscount.maxDiscount;

    // Apply maximum discount check
    if (maxDiscount === 0) {
      // When maxDiscount is 0, apply the calculated discount directly
      priceDetails.discountAmount = calculatedDiscountAmount;
      priceDetails.maxDiscountAmount = 0;
    } else if (calculatedDiscountAmount > maxDiscount) {
      priceDetails.discountAmount = maxDiscount;
      priceDetails.maxDiscountAmount = maxDiscount;
    } else {
      priceDetails.discountAmount = calculatedDiscountAmount;
      priceDetails.maxDiscountAmount = 0;
    }
  }
  // subtotal after standard discount
  subTotalAmountAfterDiscount =
    subTotalAmountAfterDiscount - priceDetails.discountAmount;

  // Calculate VAT and total amount

  if (selectedFacility.vatIncluded) {
    // If VAT is included in the price
    const originalPrice =
      subTotalAmountAfterDiscount / (1 + selectedFacility.vat.rate / 100);
    priceDetails.vatAmount = subTotalAmountAfterDiscount - originalPrice;
    priceDetails.subTotal = originalPrice; // Update subTotalAmountAfterDiscount to exclude VAT

    priceDetails.totalAmount = subTotalAmountAfterDiscount; // Total amount remains the same as subTotalAmountAfterDiscount includes VAT
  } else {
    // If VAT is not included in the price
    priceDetails.vatAmount =
      subTotalAmountAfterDiscount * (selectedFacility.vat.rate / 100);
    priceDetails.totalAmount =
      subTotalAmountAfterDiscount + priceDetails.vatAmount; // Add VAT amount to the total
  }

  return {
    subTotal: priceDetails.subTotal.toFixed(2),
    discountAmount: priceDetails.discountAmount.toFixed(2),
    discountPercentage: priceDetails.discountPercentage,
    vatAmount: priceDetails.vatAmount.toFixed(2),
    totalAmount: priceDetails.totalAmount.toFixed(2),
    maxDiscount: priceDetails.maxDiscountAmount.toFixed(2),
    autoDiscountAmmount: priceDetails?.autoDiscountAmmount.toFixed(2),
    autoDisocuntTitle: priceDetails?.autoDisocuntTitle,
  };
};

// long term pricing calculation
function calculatePricingForLongTermBooking(
  schedule,
  facilitySchedule,
  selectedScheduleType,
  hourlyPrice,
  fullDayPrice,
  selectedSlots,
  daysBasedPricing,
  groupDiscount,
  incrementalDiscount
) {
  const startDate = moment(schedule.startAt);
  const endDate = moment(schedule.endAt);

  const facilityDays = {};
  facilitySchedule.days.forEach((dayInfo) => {
    facilityDays[dayInfo.day] = dayInfo;
  });

  let totalPrice = 0;
  let totalDays = 0;
  let itemPrice = 0;
  let thresoldDiscountAmount = 0;
  let discountTitle = "";
  for (
    let currentDate = startDate;
    currentDate.isSameOrBefore(endDate);
    currentDate.add(1, "days")
  ) {
    const dayAbbr = currentDate.format("ddd").toLowerCase();
    if (schedule.day.includes(dayAbbr)) {
      totalDays++;
    }
  }

  //get pricing for full day
  if (selectedScheduleType === "fullDay" && daysBasedPricing.length > 0) {
    let _result = getTotalforLongTermFullDay(
      totalDays,
      schedule.noOfAttendees,
      daysBasedPricing
    );
    totalPrice = _result.subTotal;
    itemPrice = _result.overridedPrice;
  } else if (selectedScheduleType === "slots") {
    let _daySlotsPriceInfo = Object.values(facilityDays);
    itemPrice = (_daySlotsPriceInfo[0].duration / 60) * hourlyPrice;
    totalPrice =
      totalDays * itemPrice * selectedSlots.length * schedule.noOfAttendees;
  }

  if (groupDiscount) {
    const _groupThresoldDiscountAmount = calculateAutoDiscountedAmountForGroup(
      totalPrice,
      schedule.noOfAttendees,
      groupDiscount?.thresholds
    );
    thresoldDiscountAmount = _groupThresoldDiscountAmount;
    discountTitle = groupDiscount?.title;
  } else if (incrementalDiscount) {
    const _groupThresoldDiscountAmount =
      calculateAutoDiscountedAmountForIncremental(
        itemPrice,
        schedule.noOfAttendees,
        incrementalDiscount?.thresholds
      );
    thresoldDiscountAmount = _groupThresoldDiscountAmount;
    discountTitle = incrementalDiscount?.title;
  }

  return { totalPrice, thresoldDiscountAmount, discountTitle };
}

function getTotalforLongTermFullDay(
  totalDays,
  noOfAttendees,
  daysBasedPricing
) {
  let overridedPrice = 0; // default price if no range is matched
  if (daysBasedPricing.length > 0) {
    const overridedPricings = daysBasedPricing.filter(
      (p) => p.noOfDays >= totalDays
    );
    if (overridedPricings.length > 0) {
      // Find the pricing with the closest number of days that does not exceed the totalDays
      const closestPricing = overridedPricings.reduce((prev, curr) =>
        Math.abs(curr.noOfDays - totalDays) <
        Math.abs(prev.noOfDays - totalDays)
          ? curr
          : prev
      );
      overridedPrice = closestPricing.price;
    } else {
      // If no pricing is found that meets or exceeds totalDays, find the closest lower tier
      const closestLowerTier = daysBasedPricing.reduce((prev, curr) =>
        Math.abs(curr.noOfDays - totalDays) <
        Math.abs(prev.noOfDays - totalDays)
          ? curr
          : prev
      );
      overridedPrice = closestLowerTier.price;
    }
  }
  let subTotal = overridedPrice * noOfAttendees * totalDays;
  return { subTotal, overridedPrice };
}

// Auto discount calculation for group discount
function calculateAutoDiscountedAmountForGroup(
  totalPrice,
  noOfAttendees,
  thresholds
) {
  let discountPercent = 0; // Determine the highest discount applicable based on the number of attendees
  for (let threshold of thresholds) {
    if (noOfAttendees >= threshold.noOfPersons) {
      discountPercent = threshold.discount;
    }
  }
  const discountAmount = totalPrice * (discountPercent / 100);
  return discountAmount;
}

// Auto discount calculation for incremental discount
function calculateAutoDiscountedAmountForIncremental(
  price,
  noOfAttendees,
  thresholds
) {
  let subtotal = 0;
  for (let i = 1; i <= noOfAttendees; i++) {
    let discountPercent = 0; // Determine the discount based on the number of persons
    for (let threshold of thresholds) {
      if (i >= threshold.noOfPersons) {
        discountPercent = threshold.discount;
      }
    }
    let discountedPrice = price * (1 - discountPercent / 100);
    subtotal += price - discountedPrice;
  }

  return subtotal;
}

export const isValidUAEPhoneNumber = (phoneNumber) => {
  // UAE phone number regular expression
  const uaePhoneNumberRegex =
    /^(?:\+971|0)(?:-)?(?:50|51|52|53|54||55|56|57|58|2|3|4|6|7|9)(?:-)?\d{7}$/;

  // Check if the provided phone number matches the regular expression
  const isValid = uaePhoneNumberRegex.test(phoneNumber);

  return isValid;
};

export const getFormattedMonth = (month) => {
  const [monthName, year] = month.split(" ");
  const lastTwoDigitsOfYear = year.slice(-2);
  return `${monthName} ${lastTwoDigitsOfYear}`;
};

export const calculateOptimalColumnWidth = (numMonths) => {
  let optimalColumnWidthPercent;

  if (numMonths < 5) {
    optimalColumnWidthPercent = 10; // Base width
  } else if (numMonths >= 5 && numMonths < 10) {
    optimalColumnWidthPercent = 20; // Base width + 20%
  } else {
    optimalColumnWidthPercent = 30; // Base width + 50%
  }

  return optimalColumnWidthPercent / numMonths + "%";
};

export const getColorForLevel = (level) => {
  switch (level) {
    case "Level 3":
      return "#FF6E6E";
    case "Level 4":
      return "#F85252";
    default:
      return "#FFA8A8"; // Default color for other levels
  }
};
export const findFirstImage = (gallery) => {
  for (let i = 0; i < gallery.length; i++) {
    if (
      gallery[i].endsWith(".jpeg") ||
      gallery[i].endsWith(".jpg") ||
      gallery[i].endsWith(".png")
    ) {
      return gallery[i];
    }
  }
  return null; // Return null if no image found
};

export const getMimeType = (url) => {
  const extension = url.split(".").pop().toLowerCase();

  switch (extension) {
    case "mp4":
    case "webm":
    case "ogg":
      return "video/"; // Video MIME type
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return "image/"; // Image MIME type
    default:
      return "application/octet-stream"; // Default for unknown types
  }
};

export const discountsCategoryFromTypeGen = (type) => {
  if (type === "group") {
    return "Group Booking Discount";
  } else if (type == "incremental") {
    return "Incremental Person Discount";
  } else {
    return "Standard Discount";
  }
};

export const convertToReadableFormat = (key) => {
  // Split the key by camelCase or snake_case and join with space
  return key
    .replace(/([A-Z])/g, " $1") // Add space before capital letters
    .replace(/^./, (str) => str.toUpperCase()); // Uppercase the first letter
};
