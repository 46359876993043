/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select, Checkbox } from "antd";
import { useSelector } from "react-redux";

const AdmissionStatusesFrom = ({ documentFor, loading, form }) => {
  const { Option } = Select;

  return (
    <div>
      <Row gutter={[20, 0]}>
        <Col className="admissionStatusFormCol" md={12} sm={24}>
          <Form.Item
            label="Indetifier"
            name="code"
            rules={[
              {
                required: true,
                message: "Indetifier is required",
              },
            ]}
          >
            <Input placeholder="Indetifier" readOnly disabled />
          </Form.Item>
        </Col>
        <Col className="admissionStatusFormCol" md={12} sm={24}>
          <Form.Item
            label="Dashboard Group Status"
            name="dashboardGroupStatus"
            rules={[
              {
                required: true,
                message: "dashboard Group Status is required",
              },
            ]}
          >
            <Input placeholder="dashboard Group Status" />
          </Form.Item>
        </Col>

        <Col className="admissionStatusFormCol" md={12} sm={24}>
          <Form.Item
            label="Internal Status (EN)"
            name="internalStatus"
            rules={[
              {
                required: true,
                message: "InternalStatusEN is required",
              },
            ]}
          >
            <Input placeholder="InternalStatusEN" />
          </Form.Item>
        </Col>

        <Col className="admissionStatusFormCol" md={12} sm={24}>
          <Form.Item
            label="Internal Status (AR)"
            name="internalStatusAr"
            rules={[
              {
                required: true,
                message: "InternalStatusAR is required",
              },
            ]}
          >
            <Input placeholder="EnternalStatusAR" dir="rtl" />
          </Form.Item>
        </Col>
        <Col className="admissionStatusFormCol" md={12} sm={24}>
          <Form.Item
            label="External Status (EN)"
            name="externalStatus"
            rules={[
              {
                required: true,
                message: "ExternalStatusEN is required",
              },
            ]}
          >
            <Input placeholder="ExternalStatusEN" />
          </Form.Item>
        </Col>

        <Col className="admissionStatusFormCol" md={12} sm={24}>
          <Form.Item
            label="External Status (AR)"
            name="externalStatusAr"
            rules={[
              {
                required: true,
                message: "ExternalStatusAR is required",
              },
            ]}
          >
            <Input placeholder="ExternalStatusAR" dir="rtl" />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            name="isEnablePayment"
            valuePropName={"checked"}
            rules={[
              {
                required: false,
                message: "Activate Payment is required",
              },
            ]}
          >
            <Checkbox
            >
              Activate Payment
            </Checkbox>
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            valuePropName={"checked"}
            name="isPaymentNotification"
            rules={[
              {
                required: false,
                message: "Payment notification is required",
              },
            ]}
          >
            <Checkbox
            >
              Change notification type to Payment notification 
            </Checkbox>
          </Form.Item>
        </Col>


      </Row>
      <Row justify={"end"}>
        <Button loading={loading} htmlType="submit">
          Update
        </Button>
      </Row>
    </div>
  );
};
export default AdmissionStatusesFrom;
