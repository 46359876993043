import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  InputNumber,
} from "antd";
import ImagePicker from "components/ImagePicker/ImagePicker";
import { capitalize } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
import { Capitalize } from "utils/helpers/helpers";

const { Option } = Select;

const ServiceItemForm = ({ formName, onSubmit, editValues }) => {
  const [file, setFile] = useState();
  const [uploading, setUpLoading] = useState(false);
  const dropDowns = useSelector((state) => state.metaData.dropDowns);
  const [variantType, setVariantType] = useState();
  const [variantList, setVariantList] = useState();

  useEffect(() => {
    if (editValues) {
      setFile(editValues.image);
      setVariantType(editValues.variantType);
      editValues.variants.forEach((el) => {
        formName.setFieldsValue({ [`variants-${el.variant}`]: el.price });
      });
    } else {
      setFile();
      setVariantType();
      setVariantList();
    }
  }, [editValues]);

  useEffect(() => {
    if (variantType)
      setVariantList(
        dropDowns[variantType]?.map((el) => {
          const isEnabled = editValues
            ? editValues?.variants?.findIndex((v) => v.variant === el.value) >
              -1
            : false;
          return { ...el, enabled: isEnabled };
        })
      );
  }, [variantType]);

  const onChooseFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "additionalServices"
        );
        setFile(path);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  const onSubmitForm = (values) => {
    let selectedVariants = [];
    let filterVariants = Object.entries(values).filter((value) =>
      value[0].includes("variants-")
    );
    filterVariants.forEach((variant) => {
      let variantName = variant[0].split("variants-")[1];
      let variantPrice = variant[1];
      let isEnabled;
      let mapppedItem = variantList?.find((v) => v.value === variantName);
      isEnabled = mapppedItem ? mapppedItem.enabled : false;
      if (isEnabled) {
        selectedVariants.push({
          variant: variantName,
          variantTitle: mapppedItem.title,
          variantTitleAr: mapppedItem.titleAr,
          price: variantPrice ? Number(variantPrice) : values.price,
        });
      }
    });
    const params = {
      title: values.title,
      titleAr: values.titleAr,
      image: file,
      itemType: values.itemType,
      variantType,
      price: Number(values.price),
      allowedQuantity: Number(values.allowedQuantity),
      variants: selectedVariants,
      isPhysicalProduct: values.isPhysicalProduct ? true : false,
      gender: values.gender,
    };
    onSubmit(params);
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmitForm}>
        <Row gutter={[20]}>
          <Col md={12}>
            <Form.Item
              label="Title (EN)"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Title (AR)"
              name="titleAr"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20]}>
          <Col md={12}>
            <Form.Item
              label={"Max Allowed Quantity"}
              name={"allowedQuantity"}
              rules={[
                {
                  required: true,
                  message: "Max quantity is required",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Default Price (AED)"}
              name={"price"}
              rules={[
                {
                  required: true,
                  message: "Default price is required",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20]} align="bottom" justify="space-between">
          <Col md={12}>
            <Form.Item
              name={"gender"}
              label={"Gender"}
              rules={[{ required: true, message: "Gender is required" }]}
            >
              <Select>
                {dropDowns.serviceItemGenders?.map((options) => (
                  <Option
                    key={options.value[0]?.toUpperCase()}
                    value={options.value == "both"?"both":options.value[0]?.toUpperCase()}
                  >
                    {Capitalize(options.title)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item name={"isPhysicalProduct"} valuePropName="checked">
              <Checkbox>Is Physical Product ?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20]}>
          <Col md={12}>
            <Form.Item
              label={"Item Type"}
              name={"itemType"}
              rules={[
                {
                  required: true,
                  message: "Item type is required",
                },
              ]}
            >
              <Select>
                {dropDowns.ItemType.map((el) => (
                  <Option value={el.value}>{el.title}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item
              label={"Variant Type"}
              name={"variantType"}
              rules={[
                {
                  required: true,
                  message: "Variant type is required",
                },
              ]}
            >
              <Select onChange={(value) => setVariantType(value)}>
                {dropDowns?.variantType?.map((el) => (
                  <Option value={el.value}>{el.title}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {variantType && (
          <Row gutter={[20]}>
            <br />

            <Col md={24} style={{ marginTop: 20 }}>
              {/* <strong>{Capitalize(variantType)}s</strong> */}
              <strong style={{ color: "#007B85" }}>Variants Prices</strong>
              <br />
              <br />
            </Col>
            {variantList?.map((el, index) => (
              <>
                <Col md={8}>
                  <Checkbox
                    defaultChecked={el.enabled}
                    value={el.enabled}
                    checked={el.enabled}
                    onChange={(e) => {
                      let tempVariant = [...variantList];
                      tempVariant[index].enabled = e.target.checked;
                      setVariantList(tempVariant);
                    }}
                  >
                    {capitalize(el.title)}
                  </Checkbox>
                  <Form.Item
                    name={`variants-${el.value}`}
                    style={{ marginTop: "10px" }}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Price in AED"
                      disabled={!el.enabled}
                    />
                  </Form.Item>
                </Col>
              </>
            ))}
          </Row>
        )}

        <ImagePicker file={file} onChooseFile={onChooseFile} required />
        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={uploading} type="primary">
            {uploading ? "Saving ..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default ServiceItemForm;
