import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Spin,
  Tooltip,
  Form,
  Select,
  Row,
  Col,
  Input,
} from "antd";

import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { deleteFsmFacility, fetchAllFsmFacilities } from "./api";
import { useSelector } from "react-redux";
import { formatedDate } from "utils/helpers/helpers";
import { fetchAllCategories } from "../categories/api";
import { fetchAllSubCategories } from "../sub-categories/api";
import * as _ from "lodash";
import { findFirstImage } from "utils/helpers/helpers";

const { Option } = Select;

const AllFacilities = (props) => {
  const _campuses = useSelector((state) => state.metaData.FSM_campuses);
  const _bookingStatus = useSelector(
    (state) => state.metaData.applicationStatuses
  );

  const addFaacilityForm = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    campus: "",
    category: "",
    subCategory: "",
  });

  const updateQuery = (e) => {
    setSearch(e?.target?.value);
  };
  const debounceOnChange = _.debounce(updateQuery, 500);

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={onAddNewFacility}
          headerTitle={"All Facilities"}
          headerSubTitle={"Manage Facilities"}
          headerBtnTitle={"Add Facility"}
        />
        <Row gutter={20}>
          <Col md={12} className="filterColumn">
            <Form.Item className="dropdown">
              <Input
                placeholder="Search"
                defaultValue={search}
                onChange={(e) => {
                  e.persist();
                  debounceOnChange(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter By Campus"
                onChange={(e) => setFilters({ ...filters, campus: e })}
                bordered={false}
                defaultValue={filters.campus || undefined}
              >
                <Option value={""}>All</Option>
                {_campuses?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={12} className="filterColumn">
            <Form.Item name="category" className="dropdown">
              <Select
                placeholder="Filter By Category"
                onChange={(e) => setFilters({ ...filters, category: e })}
                bordered={false}
                defaultValue={filters.category || undefined}
              >
                <Option value={""}>All</Option>
                {categories?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} className="filterColumn">
            <Form.Item name="category" className="dropdown">
              <Select
                placeholder="Filter By Sub Category"
                onChange={(e) => setFilters({ ...filters, subCategory: e })}
                bordered={false}
                defaultValue={filters.subCategory || undefined}
              >
                <Option value={""}>All</Option>
                {subCategories?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };
  useEffect(() => {
    getAllFacilities();
  }, [filters, page, search]);
  useEffect(() => {
    getAllCategories();
    getAllSubCategories();
  }, []);

  const getAllCategories = async () => {
    try {
      const response = await fetchAllCategories({
        page: page,
        type: "parent",
        limit: 1000,
      });
      if (response.status === 200) {
        setCategories(response.data.data);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };
  const getAllSubCategories = async () => {
    try {
      const response = await fetchAllSubCategories({
        page: page,
        type: "child",
        limit: 1000,
      });
      if (response.status === 200) {
        setSubCategories(response.data.data);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const getAllFacilities = async () => {
    try {
      setLoading(true);
      const response = await fetchAllFsmFacilities({
        page: page,
        campus: filters.campus,
        subCategory: filters.subCategory,
        category: filters.category,
        search: search,
      });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onAddNewFacility = () => {
    props.history.push({
      pathname: `add-facility`,
      //    state: { applicationId: record.id, serviceType },
    });
  };
  const onDeleteFacility = async (id) => {
    try {
      const response = await deleteFsmFacility(id);
      if (response.status === 200) {
        successMessage({ message: "Facility has been deleted successfully" });
        await getAllFacilities();
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };
  const onEditFacility = (record) => {
    props.history.push({
      pathname: `edit-facility`,
      state: { record: record },
    });
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Image",
              // dataIndex: "gallery",
              key: "gallery",
              render: (item) => {
                const firstImage = findFirstImage(item?.gallery);
                return firstImage ? (
                  <img
                    src={firstImage}
                    style={{ height: 50, width: 50, objectFit: "cover" }}
                    alt="First Image"
                  />
                ) : (
                  <span>No Image</span>
                );
              },
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Campus",
              // dataIndex: "campus",
              key: "campus",
              render: (record) => record?.campus?.code,
            },
            {
              title: "Customer ID",
              dataIndex: "erpIntegrationId",
              key: "erpIntegrationId",
            },
            {
              title: "Service ID",
              dataIndex: "serviceId",
              key: "serviceId",
            },
            {
              title: "Is Active",
              // dataIndex: "isOpen",
              key: "isOpen",
              render: (record) => (
                <p style={{ textTransform: "capitalize" }}>
                  {record?.isOpen ? "Active" : "Not Active"}
                </p>
              ),
            },

            {
              title: "Schedule",
              // dataIndex: "subCategory",
              key: "subCategory",
              render: (record) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>{formatedDate(record?.schedule?.startAt)}</span> to
                  <span>{formatedDate(record?.schedule?.endAt)}</span>
                </div>
              ),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      {/* <Tooltip title="View Details">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EyeOutlined />}
                          onClick={() => onEditFacility(item)}
                        />
                      </Tooltip> */}
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Edit">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => onEditFacility(item)}
                        />
                      </Tooltip>
                    </div>
                    {/* <div style={{ marginRight: "10px" }}>
                      <Tooltip title="View Details">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<CalendarOutlined />}
                          onClick={onAddNewFacility}
                        />
                      </Tooltip>
                    </div> */}

                    <Popconfirm
                      title="Are you sure you want to delete this?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => onDeleteFacility(item?.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
      </Spin>
    </div>
  );
};

export default AllFacilities;
