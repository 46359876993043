import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import { fetchAllSections } from "apis/TCC/tccRequestTypes";

import { addNewSection } from "apis/TCC/tccRequestTypes";
import { updateSection } from "apis/TCC/tccRequestTypes";
import { deleteSection } from "apis/TCC/tccRequestTypes";
import TCCSectionForm from "components/Forms/tcc-forms/TCCSectionForm";
import { fetchAllCourses } from "apis/TCC/tccRequestTypes";
import { fetchAllTeachers } from "apis/TCC/tccRequestTypes";
import { fetchAllSchedules } from "apis/TCC/tccRequestTypes";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const TCCSectionConfig = (props) => {
  const eServiceData = props.location.state;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [sectionId, setSectionId] = useState("");
  const [courses, setCourses] = React.useState([]);
  const [teachers, setTeachers] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);

  const [tccSectionForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            tccSectionForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"TCC Sections"}
          headerSubTitle={"Manage TCC Sections"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllCourses();
    getAllTeachers();
    getAllSchedules();
  }, []);
  const getAllCourses = async () => {
    try {
      setLoading(true);
      const response = await fetchAllCourses(1000);
      if (response.status === 200) {
        setCourses(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const getAllTeachers = async () => {
    try {
      setLoading(true);
      const response = await fetchAllTeachers(1000);
      if (response.status === 200) {
        setTeachers(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const getAllSchedules = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSchedules(1000);
      if (response.status === 200) {
        setSchedules(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  useEffect(() => {
    getAllSections();
  }, []);

  const getAllSections = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSections(eServiceData?.serviceName?.id);
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addSection = async (data) => {
    try {
      setLoading(true);
      const response = await addNewSection({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Section added successfully" });
        await getAllSections();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateSection = async (data) => {
    try {
      setLoading(true);
      const response = await updateSection({ id: sectionId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Section updated successfully" });
        await getAllSections();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteSection = async (id) => {
    try {
      setLoading(true);
      const response = await deleteSection({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Section deleted successfully" });
        await getAllSections();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      eServiceId: eServiceData?.serviceName?.id,
      totalSeats: Number(values?.totalSeats),
    };

    if (modalTitle === ModalType.EDIT) {
      await handleUpdateSection(data);
    } else {
      await addSection(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    tccSectionForm.setFieldsValue({
      ...record,
    });
    setSectionId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: "title",

              // render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "Teacher",
              // dataIndex: "teacher",
              key: "teacher",

              render: (record) => (
                <p className="answer_para">
                  {record?.teacher?.firstName +
                    " " +
                    record?.teacher?.middleName +
                    " " +
                    record?.teacher?.lastName}
                </p>
              ),
            },
            {
              title: "Location",
              dataIndex: "location",
              key: "location",

              // render: (record) => <p className="answer_para">{record}</p>,
            },

            {
              title: "Total Seats",
              dataIndex: "totalSeats",
              key: "totalSeats",

              // render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "Seats Availed",
              dataIndex: "seatsAvailed",
              key: "seatsAvailed",

              // render: (record) => <p className="answer_para">{record}</p>,
            },

            {
              title: "Over Allocation",
              //   dataIndex: "isEnabled",
              key: "allowIfFull",
              render: (record) => (
                <p className="answer_para">
                  {record?.allowIfFull ? "Enabled" : "Disabled"}
                </p>
              ),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Section Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Section?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteSection(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Section`}
        >
          <TCCSectionForm
            onSubmit={onSubmit}
            formName={tccSectionForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            courses={courses}
            teachers={teachers}
            schedules={schedules}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default TCCSectionConfig;
