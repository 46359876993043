import { Button, Col, Row } from "antd";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";
import { getFullDayName } from "utils/helpers/helpers";

const ViewSchedules = ({ data, handleEditSchedule, selectedTab }) => {
  const blockSchedules = data?.blockSchedules;

  const renderOpenSchedules = () => {
    const { startAt, endAt, days } = data;
    return (
      <div>
        <Row gutter={20}>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <div>
                  <p className="schedule__title">Start Date</p>
                  <p className="schedule__value">
                    {startAt ? formatedDate(startAt) : "No End Date"}
                  </p>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <p className="schedule__title">End Date</p>
                  <p className="schedule__value">
                    {endAt ? formatedDate(endAt) : "No End Date"}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <div className="schedule__slots">
          {days?.map((item, index) => {
            return (
              <div key={index}>
                <div className="schedule__day_title">
                  <div>
                    <p className="schedule__day">{getFullDayName(item?.day)}</p>
                  </div>
                  <div className="hr_row" />
                </div>
                <Row gutter={[20, 20]}>
                  <Col md={24}>
                    <Row gutter={10}>
                      <Col md={12}>
                        <div>
                          <p className="schedule__title">Start Time</p>
                          <p className="schedule__value">
                            {formatedDateWithAmAndPm(item?.startTime)}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div>
                          <p className="schedule__title">End Time</p>
                          <p className="schedule__value">
                            {formatedDateWithAmAndPm(item?.endTime)}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
        <br />
        <Row justify="end">
          <Button
            type="primary"
            className="ant__primary__button"
            onClick={() => handleEditSchedule("open", data)}
          >
            Edit
          </Button>
        </Row>
      </div>
    );
  };
  const renderBlockSchedules = () => {
    if (blockSchedules.length === 0) {
      // No data found, render a button to add new block schedules
      return (
        <div
          style={{
            height: 300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <p>No block schedules available.</p>
          </div>
          <Button
            type="primary"
            onClick={() => handleEditSchedule("block", data)}
          >
            Add Block Schedule
          </Button>
        </div>
      );
    }
    return blockSchedules.map((item, index) => {
      return (
        <div key={index}>
          <Row gutter={20}>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <div>
                    <p className="schedule__title">Start Date</p>
                    <p className="schedule__value">
                      {item?.startAt
                        ? formatedDate(item?.startAt)
                        : "No End Date"}
                    </p>
                  </div>
                </Col>
                <Col md={12}>
                  <div>
                    <p className="schedule__title">End Date</p>
                    <p className="schedule__value">
                      {item?.endAt ? formatedDate(item?.endAt) : "No End Date"}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <div className="schedule__slots">
            {item?.days?.map((day, dayIndex) => {
              return (
                <div key={dayIndex}>
                  <div className="schedule__day_title">
                    <div>
                      <p className="schedule__day">
                        {getFullDayName(day?.day)}
                      </p>
                    </div>
                    <div className="hr_row" />
                  </div>
                  <Row gutter={[20, 20]}>
                    <Col md={24}>
                      {day?.fullDay ? (
                        <Row>
                          <Col md={12}>
                            <div>
                              <p className="schedule__title">Full Day</p>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row gutter={10}>
                          <Col md={12}>
                            <div>
                              <p className="schedule__title">Start Time</p>
                              <p className="schedule__value">
                                {formatedDateWithAmAndPm(day?.startTime)}
                              </p>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div>
                              <p className="schedule__title">End Time</p>
                              <p className="schedule__value">
                                {formatedDateWithAmAndPm(day?.endTime)}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
          <br />
          <Row justify="end">
            <Button
              type="primary"
              className="ant__primary__button"
              onClick={() => handleEditSchedule("block", data)}
            >
              Edit
            </Button>
          </Row>
        </div>
      );
    });
  };

  const renderOpenAndBlockSchedules = (tab) => {
    switch (tab) {
      case 0:
        return renderOpenSchedules();
        break;
      case 1:
        return renderBlockSchedules();
        break;

      default:
        return renderOpenSchedules();
        break;
    }
  };

  return (
    <div style={{ minHeight: 400 }}>
      {renderOpenAndBlockSchedules(selectedTab)}
    </div>
  );
};
export default ViewSchedules;
