import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Form, Input, Select, Row, Col } from "antd";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { fetchAllSccCategories } from "../scc-Categories/api";
import { fetchAllSccStandards } from "../scc-standards/api";
import { fetchAllTemplates } from "apis/templates/templatesApis";
const { Option } = Select;

const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} is required`,
    },
  ];
};

const SccDistinguishIndicatorForm = ({
  onSubmit,
  loading,
  formName,
  valuesForEdit,
}) => {
  const [categories, setCategories] = useState([]);
  const [standards, setStandards] = useState([]);
  const [tempplatesData, setTempplatesData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    if (valuesForEdit !== null) {
      if (valuesForEdit?.categoryId) {
        setSelectedCategoryId(valuesForEdit?.categoryId);
      }
    }
  }, [valuesForEdit]);

  useEffect(() => {
    fetchSccCategories();
    fetchSccStandards();
    getAllTemplates();
  }, []);
  const fetchSccCategories = async () => {
    try {
      const response = await fetchAllSccCategories({
        limit: 1000,
        type: "positive",
      });
      if (response.status === 200) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchSccStandards = async () => {
    try {
      const response = await fetchAllSccStandards({ limit: 1000 });
      if (response.status === 200) {
        setStandards(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getAllTemplates = async () => {
    try {
      const response = await fetchAllTemplates(10000);
      if (response.status === 200) {
        const distinguishTemplates = response.data.data.filter(template => template.modules.includes("SCC Distinguish"));
        setTempplatesData(distinguishTemplates);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChangeCategory = (id) => {
    const categoryId = parseInt(id);
    setSelectedCategoryId(categoryId);
    formName.resetFields(["standardId"]);
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Unique Code"}
              name={"identifier"}
              rules={setRules("Unique Code")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Template ID"}
              name={"templateId"}
              rules={setRules("Template ID")}
            >
              <Select
                style={{ border: "#d9d9d9 1px solid" }}
                // value={tempplatesData?.find((el) => el.id)}
                placeholder="Select Template"
              >
                {tempplatesData?.map((el) => (
                  <Option value={el.id}>{el.title}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Tilte (EN)"}
              name={"title"}
              rules={setRules("Title (EN)")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Tilte (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="Add here" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Category"}
              name={"categoryId"}
              rules={setRules("Category")}
            >
              <Select
                placeholder="Select"
                onChange={(e) => handleChangeCategory(e)}
              >
                {categories.map((el, i) => (
                  <Option value={el.id} key={i}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Standard"}
              name={"standardId"}
              rules={setRules("Standard")}
            >
              <Select placeholder="Select" disabled={!selectedCategoryId}>
                {selectedCategoryId &&
                  standards
                    .filter(
                      (el) => Number(el.categoryId) === selectedCategoryId
                    )
                    .map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Score"} name={"score"} rules={setRules("score")}>
              <Input placeholder="Add here" type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Form.Item
              label={"Description (EN)"}
              name={"description"}
              valuePropName={"data"}
              rules={[
                { required: true, message: "Description (EN) is required" },
              ]}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "en",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"descriptiveText"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Form.Item
              label={"Description (AR)"}
              name={"descriptionAr"}
              valuePropName={"data"}
              rules={[
                { required: true, message: "Description (AR) is required" },
              ]}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "ar",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"descriptiveText"}
              />
            </Form.Item>
          </Col>
        </Row>

        <br />

        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant__primary__button"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default SccDistinguishIndicatorForm;
