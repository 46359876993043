import React, { useState } from "react";
import FSMAplicationLogs from "views/FSM/facility-mangement/fsm-application-detail-header/FSMAplicationLogs";
import FSMBookingDetailsInformation from "views/FSM/facility-mangement/fsm-application-detail-header/FSMBookingDetailsInformation";
import FSMBookingHeader from "views/FSM/facility-mangement/fsm-application-detail-header/FSMBookingHeader";
import FSMTabs from "views/FSM/facility-mangement/fsm-application-detail-header/FSMTabs";

const BookingCancelAndRefundStage = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const renderCaseView = (tab) => {
    switch (tab) {
      case 0:
        return (
          <FSMBookingDetailsInformation
            booking={props.bookingDetails}
            refetch={props.refetch}
          />
        );
        break;
      case 1:
        return (
          <FSMAplicationLogs
            bookingId={props.bookingDetails?.id}
            autoApproved={props.bookingDetails?.autoApproval}
          />
        );
      default:
        break;
    }
  };
  return (
    <div>
      <FSMBookingHeader
        booking={props.bookingDetails}
        refetch={props.refetch}
        props={props}
      />
      <FSMTabs
        selectedTab={selectedTab}
        onLogsTab={() => setSelectedTab(1)}
        onDetialsTab={() => setSelectedTab(0)}
      />
      <div className="dynamic_form_items_container">
        {renderCaseView(selectedTab)}
      </div>
    </div>
  );
};

export default BookingCancelAndRefundStage;
