import * as React from "react";
import styles from "styles/IframeScreen.module.css";
import { Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";
const IFrameCashierScreen = () => {
  const [_, setLoading] = React.useState(true);
  return (
    <div className="both-side-padding">
      <div style={{ marginRight: "2rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "1rem",
          }}
        >
          <Button
            className="d-flex justify-content-center align-items-center"
            icon={<LinkOutlined />}
            onClick={() =>
              window.open(process.env.REACT_APP_CASHIER_APP_LINK, "_blank")
            }
          >
            Open in New Tab
          </Button>
        </div>
      </div>

      {/* <Spin spinning={loading}> */}
      <iframe
        title="ENS"
        src={process.env.REACT_APP_CASHIER_APP_LINK}
        // style={{ width: "100%", height: "100vh", border: "none" }}
        onLoad={() => setLoading(false)}
        width="140%"
        height="900px"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        className={styles.iframe}
      ></iframe>
      {/* </Spin> */}
      {/* </div> */}
    </div>
  );
};

export default IFrameCashierScreen;
