import { combineReducers } from "redux";

import applicationsReducer from "./applications/applications-reducer";
import docuementlistReducer from "./documents-list/docuementlistReducer";
import metaDataReducer from "./meta-data/metaDataReducer";
import StagesReducer from "./stages/stageReducer";
import UserReducer from "./user-list/userReducer";
import documentlistConfigurationReducer from "./documents-configurations/documentsConfigurationsReducer";
import authReducer from "./auth/authReducer";
import DropdownConfigurationReducer from "./dropdown-configuration/DropdownConfigurationReducer";
import NotificationReducers from "./notifications/NotificationReducers";
import EServicesReducer from "./eservices/eServicesReducers";
import RulesReducer from "./discount-rules/DiscountRulesReducers";
import DiscountDistributionsReducer from "./discount-distributions/DiscountDistributionReducers";
import faqsCategoriesReducer from "./faqs-category/faqsCategoryReducer";
import SyncJobReducer from "./sync-jobs/syncJobReducer";

export default combineReducers({
  allApplications: applicationsReducer,
  documents: docuementlistReducer,
  allUsers: UserReducer,
  syncJobs:SyncJobReducer,
  metaData: metaDataReducer,
  stages: StagesReducer,
  documentsListConfig: documentlistConfigurationReducer,
  login: authReducer,
  dropdownData: DropdownConfigurationReducer,
  notifications: NotificationReducers,
  eservices: EServicesReducer,
  rules: RulesReducer,
  distributions: DiscountDistributionsReducer,
  faqsCategories: faqsCategoriesReducer,
});
