/* eslint-disable no-unreachable */
import {
  DELETE_ADDITONAL_DOCUMENT,
  GET_ALL_APPLICATIONS,
  UPDATE_APPLICATION,
  DELETE_APPLICATION,
  SAVE_APPLICATION_ID,
  TCC_ALL_SECTIONS_ASSIGNED,
} from "../../actions/applications/applications-actions";

const initialState = {
  applications: [],
  total: 0,
  loading: false,
  allSectionAssigned: true,
};

const UpdateApplicationsAfterDeleteDocument = (
  applications,
  documentId,
  appId
) => {
  let data = [...applications];
  let updatedAppIndex = data.findIndex((el) => el.id === appId);
  if (updatedAppIndex > -1) {
    let updatedApp = data[updatedAppIndex];
    let newDocuments = updatedApp.documents.filter(
      (el) => el.id !== documentId
    );
    updatedApp.documents = newDocuments;
    data[updatedAppIndex] = updatedApp;
    return data;
  } else return applications;
};

// const DeleteApplication = (applications, appId) => {
//   let data = [...applications];
//   let deletedAppIndex = data.findIndex((el) => el.id === appId);
//   if (deletedAppIndex > -1) {
//     data.splice(deletedAppIndex, 1);
//   }
//   return data;
// };

export default function applicationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_APPLICATIONS:
      return {
        ...state,
        applications: action.payload.data,
        total: action.payload.total,
      };
      break;
    case UPDATE_APPLICATION:
      const indexOfApplication = state.applications.findIndex(
        (_application) => _application.id === action.id
      );
      state.applications[indexOfApplication] = action.payload;
      const udpatedApplications = [...state.applications];
      return {
        ...state,
        applications: udpatedApplications,
      };
      break;
    case DELETE_ADDITONAL_DOCUMENT:
      return {
        ...state,
        applications: UpdateApplicationsAfterDeleteDocument(
          state.applications,
          action.payload.documentId,
          action.payload.appId
        ),
      };
      break;
    case DELETE_APPLICATION:
      // const filterDocuments = state.documentsLists.filter(
      //   (_doc) => _doc.id !== action.id
      // );
      // return {
      //   ...state,
      //   documentsLists: filterDocuments,
      // };
      // break;
      return {
        ...state,
        applications: state.applications.filter((app) => {
          return app.id !== action.id;
        }),
        total: state.total - 1,
      };
    case SAVE_APPLICATION_ID:
      return {
        ...state,
        currentApplicationId: action.payload,
      };
      break;
    case TCC_ALL_SECTIONS_ASSIGNED:
      return {
        ...state,
        allSectionAssigned: action.payload,
      };
    default:
      return state;
  }
}
