/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  DatePicker,
  Input,
  Menu,
  Select,
  Form,
} from "antd";
import moment from "moment";
import ScheduleDateTimePicker from "components/resuable/schedule-detail/ScheduleDateTimePicker";
const { Option } = Select;

export default function EntranceTestScheduleFormForKG({
  onFinish,
  autoFill,
  loading,
}) {
  const [typeOfInterview, setTypeOfInterview] = React.useState([
    {
      id: "1",
      title: "In Person",
      value: "in_person",
    },
    {
      id: "2",
      title: "Online",
      value: "online",
    },
  ]);
  const [selectedInterViewType, setSelectedInterViewType] =
    React.useState("Online");
  const [testScheduleForm] = Form.useForm();

  useEffect(() => {
    if (autoFill) {
      testScheduleForm.setFieldsValue(autoFill);
      setSelectedInterViewType(autoFill.interviewType);
    }
  }, [autoFill]);
  const handleChange = (event) => {
    setSelectedInterViewType(event);
  };

  return (
    <div className="both-side-padding">
      <Form
        size="large"
        name="entranceTestFormForKG"
        layout={"vertical"}
        onFinish={onFinish}
        form={testScheduleForm}
      >
        <Row gutter={[20, 20]}>
          <Col lg={12} sm={24}>
            <Form.Item
              label="Type"
              name="interviewType"
              rules={[
                {
                  required: true,
                  message: "Type is required",
                },
              ]}
            >
              <Select onChange={handleChange}>
                {typeOfInterview.map((type) => {
                  return <Option value={type.value}>{type.title}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} sm={24}>
            <ScheduleDateTimePicker
              label="Select Date and time"
              name="dateAndTime"
              rules={[
                {
                  required: true,
                  message: "Date and time is required",
                },
              ]}
            />
          </Col>

          <Col lg={24}>
            {selectedInterViewType === "in_person" ? (
              <Form.Item
                label="Instructions"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Instructions are required",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Instructions"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            ) : null}
          </Col>

          <Col lg={24}>
            <Form.Item
              label="Notes"
              name="notes"
              rules={[
                {
                  required: true,
                  message: "Notes are required",
                },
              ]}
            >
              <Input.TextArea placeholder="Notes" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? "Sending..." : "Send To Parent"}
          </Button>
        </Row>
      </Form>
    </div>
  );
}
