/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col, Button, DatePicker, Input, Select, Form } from "antd";
import { useEffect } from "react";
import { useRef } from "react";

const { Option } = Select;

export default function TestResultHigherGradesForm({
  onFinish,
  loading,
  application,
}) {
  const [typeOfRecommendation, setTypeOfRecommendation] = React.useState([
    {
      id: "1",
      title: "Recommended",
      value: true,
    },
    {
      id: "2",
      title: "Not Recommended",
      value: false,
    },
  ]);
  let stageData = application?.stage;
  // State variable to keep track of whether it's a retest or normal scheduling
  const [isRetest, setIsRetest] = React.useState(false);

  // State variable to keep track of subjects to display
  const [displayedSubjects, setDisplayedSubjects] = React.useState([]);

  let subjectsToCheck = ["math", "english", "arabic"];

  useEffect(() => {
    // Check if there are subjectsTestTiming available in the last schedule
    if (stageData?.schedules && stageData.schedules.length > 0) {
      const lastSchedule = stageData.schedules[stageData.schedules.length - 1];
      if (
        lastSchedule &&
        lastSchedule.subjectsTestTiming &&
        lastSchedule.subjectsTestTiming.length > 0
      ) {
        const subjectsTiming = lastSchedule.subjectsTestTiming.map(
          (timing) => timing.subjectName
        );
        setDisplayedSubjects(subjectsTiming);
        setIsRetest(true); // Set the isRetest state to true if there are subjectsTestTiming
      } else {
        setDisplayedSubjects(subjectsToCheck); // If no subjectsTestTiming found, display all subjects
        setIsRetest(false); // Set the isRetest state to false
      }
    } else {
      setDisplayedSubjects(subjectsToCheck); // If no schedules found, display all subjects
      setIsRetest(false); // Set the isRetest state to false
    }
  }, [stageData]);

  const handleChange = (event) => {
    // setTypeOfRecommendation(event);
  };
  return (
    <div className="both-side-padding">
      <Form
        size="large"
        name="entranceResultForm"
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Row align="bottom" gutter={[20, 20]}>
          {displayedSubjects.includes("math") && (
            <Col lg={12}>
              <Form.Item
                name={["subjectMath", "obtainMarks"]}
                rules={[{ required: true, message: "Obtained marks required" }]}
                label="Math"
              >
                <Input type="number" placeholder="Obtained marks" />
              </Form.Item>
            </Col>
          )}
          {displayedSubjects.includes("english") && (
            <Col lg={12}>
              <Form.Item
                name={["subjectEnglish", "obtainMarks"]}
                rules={[{ required: true, message: "Obtained marks required" }]}
                label="English"
              >
                <Input type="number" placeholder="Obtained marks" />
              </Form.Item>
            </Col>
          )}
        </Row>

        {displayedSubjects.includes("arabic") && (
          <Row align="bottom" gutter={[20, 20]}>
            <Col lg={12}>
              <Form.Item
                name={["subjectArabic", "totalMarks"]}
                label="Arabic"
                rules={[
                  { required: true, message: "Total marks required" },
                  () => ({
                    validator(_, value) {
                      if (value < 0)
                        return Promise.reject(
                          new Error("Total marks can not be negative")
                        );
                      else return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input type="number" style={{}} placeholder="Total Marks" />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                label="Arabic"
                name={["subjectArabic", "obtainMarks"]}
                rules={[{ required: true, message: "Obtained marks required" }]}
              >
                <Input type="number" placeholder="Obtained marks" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[20, 20]}>
          <Col lg={24}>
            <Form.Item
              label="Recommendation"
              name="recommendation"
              rules={[
                {
                  required: true,
                  message: "Recommendation is required",
                },
              ]}
            >
              <Select onChange={handleChange}>
                {typeOfRecommendation.map((type) => {
                  return <Option value={type.value}>{type.title}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={24} sm={24}>
            <Form.Item
              label="Notes"
              name="notes"
              rules={[
                {
                  required: true,
                  message: "Notes are required",
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea placeholder="Notes" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Row>
      </Form>
    </div>
  );
}
