import { Button, Form, Input, Select, Row, Checkbox, Col } from "antd";
import ImagePicker from "components/ImagePicker/ImagePicker";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const { Option } = Select;

const FSMBannerForm = ({
  onSubmit,
  loading,
  uploading,
  formName,
  onChooseFile,
  file,
  selectedRecord,
  targetOptions,
  actionTypeOptions,
  screenOptions,
  _campuses,
  _facilities,
}) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };
  const FSM_categories = useSelector((state) => state.metaData.FSM_categories);
  const [selectedCategoryId, setSelectedCategoryId] = React.useState(null);

  let [target, setTarget] = React.useState("mobile_application");
  let [actionType, setActionType] = React.useState("");
  let [screenName, setScreenName] = React.useState("");

  useEffect(() => {
    // setTarget(
    //   selectedRecord != null ? selectedRecord.target : "mobile_application"
    // );
    setActionType(selectedRecord != null ? selectedRecord.actionType : "");
    setScreenName(selectedRecord != null ? selectedRecord.screenName : "");
    setSelectedCategoryId(
      selectedRecord != null ? selectedRecord.categoryId : ""
    );
  }, [selectedRecord]);

  const rowProps = { justify: "space-between", gutter: [20] };
  const colProps = { md: 12 };
  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        {/* title */}
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={setRules("Title (EN)")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        {/* subtitle */}
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item label={"Subtitle (EN)"} name={"subTitle"}>
              <Input />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item label={"Subtitle (AR)"} name={"subTitleAr"}>
              <Input dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        {/* Action Text */}
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item label={"Action Text (EN)"} name={"actionText"}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={"Action Text (AR)"} name={"actionTextAr"}>
              <Input dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        {/* Action Button */}
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item label={"Action Button (EN)"} name={"actionButton"}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={"Action Button (AR)"} name={"actionButtonAr"}>
              <Input dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        {/* Tags */}
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item label={"Tag (EN)"} name={"tag"}>
              <Input />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item label={"Tag (AR)"} name={"tagAr"}>
              <Input dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        {target === "mobile_application" ? (
          <Row {...rowProps}>
            <Col {...colProps}>
              <Form.Item
                label={"Mobile Action Link (EN)"}
                name={"actionMblLink"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item
                label={"Mobile Action Link (AR)"}
                name={"actionMblLinkAr"}
              >
                <Input dir="rtl" />
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {/* Action Type and Screen name */}
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item
              rules={setRules("Action Type")}
              name={"actionType"}
              label={"Action Type"}
            >
              <Select
                onChange={(value) => {
                  setActionType(value);
                }}
              >
                {actionTypeOptions?.map((el) => (
                  <Option key={el.value} value={el.value}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {actionType === "Application" ? (
            <Col {...colProps}>
              <Form.Item
                rules={setRules("Screen Name")}
                name={"screenName"}
                label={"Screen Name"}
              >
                <Select onChange={(e) => setScreenName(e)}>
                  {screenOptions?.map((el) => (
                    <Option key={el.value} value={el.value}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
        </Row>

        {/* if screen name is facilityList */}
        {screenName === "facilityList" ? (
          <Row {...rowProps}>
            <Col {...colProps}>
              <Form.Item name={"categoryId"} label={"Category"}>
                <Select
                  placeholder="Select"
                  onChange={(e) => setSelectedCategoryId(e)}
                >
                  {FSM_categories.map((el, i) => (
                    <Option value={el.id} key={i}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col {...colProps}>
              <Form.Item name={"subCategoryId"} label={"Sub Category"}>
                <Select placeholder="Select" disabled={!selectedCategoryId}>
                  {selectedCategoryId &&
                    FSM_categories.find(
                      (cat) => cat.id === selectedCategoryId
                    )?.children.map((el) => (
                      <Option value={el.id} key={el.id}>
                        {el.title}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {/* campuses */}
            <Col {...colProps}>
              <Form.Item label={"Campus"} name={"campuses"}>
                <Select>
                  {_campuses?.map((campus, index) => (
                    <Option value={campus.code} key={index}>
                      {campus.code}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        {/* if screen name is facilitydetails */}
        {screenName === "facilityDetail" ? (
          <Row {...rowProps}>
            <Col {...colProps}>
              <Form.Item
                name={"facilityId"}
                label={"Facility"}
                rules={setRules("Facility")}
              >
                <Select
                  placeholder="Select"
                  onChange={(e) => setSelectedCategoryId(e)}
                >
                  {_facilities.map((el, i) => (
                    <Option value={el.id} key={i}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        <Form.Item
          initialValue={true}
          label={"Status"}
          name={"status"}
          valuePropName={"checked"}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
        <br />

        <ImagePicker file={file} onChooseFile={onChooseFile} required />

        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading || uploading}
            className="ant__primary__button"
          >
            {loading ? "Saving..." : uploading ? "Uploading..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default FSMBannerForm;
