import client from "apis/config";
const endPointUrl = "scc/indicators";

export const fetchAllSccOffenseIndicators = async ({
  type = "offense",
  limit = "10",
  page,
}) => {
  return await client.get(
    `/${endPointUrl}?type=${type}&limit=${limit}&page=${page}`
  );
};
export const fetchAllIndicators = async () => {
  return await client.get(`/${endPointUrl}`);
};

export const addNewSccOffenseIndicator = async ({ data }) => {
  return await client.post(`/${endPointUrl}`, data);
};

export const updateSccOffenseIndicator = async ({ id, data }) => {
  return await client.put(`/${endPointUrl}/${id}`, data);
};

export const deleteSccOffenseIndicator = async ({ id }) => {
  return await client.delete(`/${endPointUrl}/${id}`);
};
