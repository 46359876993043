import "./index.css";
import "./index.scss";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import AdminLayout from "./layouts/Admin.jsx";
import MarkedArrived from "./components/public-pages/MarkedArrived";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./styles/result.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "react-notifications/lib/notifications.css";
import { loginRedirect as LoginRedrect } from "utils/helpers/helpers";
import { NotificationContainer } from "react-notifications";

import "@progress/kendo-theme-default/dist/all.css";
import VerifyPinPage from "views/Document Validator/QRGenerator/VerifyPin";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <NotificationContainer />
      <Router>
        <Switch>
          <Route path="/verify-document" component={()=><VerifyPinPage />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/mark-arrival/:id" component={MarkedArrived} />
          <Route path="/" render={() => <LoginRedrect />} />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);
