import client from "apis/config";
// REWARDS
export const fetchAllRewards = async (limit = "10") => {
  return await client.get(`/scholarship-rewards?limit=${limit}`);
};

export const addNewReward = async ({ data }) => {
  return await client.post("/scholarship-rewards", data);
};

export const updateReward = async ({ id, data }) => {
  return await client.put(`/scholarship-rewards/${id}`, data);
};

export const deleteReward = async ({ id }) => {
  return await client.delete(`/scholarship-rewards/${id}`);
};

// EVALUATION POINTS

//  1. FINAANCIAL STUTUS

export const fetchAllFinancialStutus = async () => {
  return await client.get(`/scholarship-evaluations?type=financial_status`);
};
export const addNewFinancialStatus = async ({ data }) => {
  return await client.post("/scholarship-evaluations", data);
};
export const updateFinancialStatus = async ({ id, data }) => {
  return await client.put(`/scholarship-evaluations/${id}`, data);
};
export const deleteFinancialStatus = async ({ id }) => {
  return await client.delete(`/scholarship-evaluations/${id}`);
};

//  2. SCHOOL RATING

export const fetchAllSchoolRatings = async () => {
  return await client.get(`/scholarship-evaluations?type=school_rating`);
};
export const addNewSchoolRating = async ({ data }) => {
  return await client.post("/scholarship-evaluations", data);
};
export const updateSchoolRating = async ({ id, data }) => {
  return await client.put(`/scholarship-evaluations/${id}`, data);
};
export const deleteSchoolRating = async ({ id }) => {
  return await client.delete(`/scholarship-evaluations/${id}`);
};
//  3. Educational Allowance

export const fetchAllEducationalAllowance = async () => {
  return await client.get(
    `/scholarship-evaluations?type=educational_allowance`
  );
};
export const addNewEducationalAllowance = async ({ data }) => {
  return await client.post("/scholarship-evaluations", data);
};
export const updateEducationalAllowance = async ({ id, data }) => {
  return await client.put(`/scholarship-evaluations/${id}`, data);
};
export const deleteEducationalAllowance = async ({ id }) => {
  return await client.delete(`/scholarship-evaluations/${id}`);
};

//  4. Academic Achievement

export const fetchAllScoringSystems = async () => {
  return await client.get(`/scoring-systems`);
};
export const addNewScoringSystem = async ({ data }) => {
  return await client.post("/scoring-systems", data);
};
export const updateScoringSystem = async ({ id, data }) => {
  return await client.put(`/scoring-systems/${id}`, data);
};
export const deleteScoringSystem = async ({ id }) => {
  return await client.delete(`/scoring-systems/${id}`);
};
//  4. Non-Academic Achievement

export const fetchAllNonAcademicAchievement = async () => {
  return await client.get(
    `/scholarship-evaluations?type=non_academic_achievement`
  );
};
export const addNewNonAcademicAchievement = async ({ data }) => {
  return await client.post("/scholarship-evaluations", data);
};
export const updateNonAcademicAchievement = async ({ id, data }) => {
  return await client.put(`/scholarship-evaluations/${id}`, data);
};
export const deleteNonAcademicAchievement = async ({ id }) => {
  return await client.delete(`/scholarship-evaluations/${id}`);
};
//  5. cat/map

export const fetchAllCATMAP = async () => {
  return await client.get(`/assessment-exams`);
};
export const addNewCATMAP = async ({ data }) => {
  return await client.post("/assessment-exams", data);
};
export const updateCATMAP = async ({ id, data }) => {
  return await client.put(`/assessment-exams/${id}`, data);
};
export const deleteCATMAP = async ({ id }) => {
  return await client.delete(`/assessment-exams/${id}`);
};

// 6. personal Interview

export const fetchAllPersonalInterview = async () => {
  return await client.get(`/scholarship-evaluations?type=personal_interview`);
};
export const addNewPersonalInterview = async ({ data }) => {
  return await client.post("/scholarship-evaluations", data);
};
export const updatePersonalInterview = async ({ id, data }) => {
  return await client.put(`/scholarship-evaluations/${id}`, data);
};
export const deletePersonalInterview = async ({ id }) => {
  return await client.delete(`/scholarship-evaluations/${id}`);
};
// 7. Community Participation

export const fetchAllCommunityParticipation = async () => {
  return await client.get(
    `/scholarship-evaluations?type=social_and_community_activities`
  );
};
export const addNewCommunityParticipation = async ({ data }) => {
  return await client.post("/scholarship-evaluations", data);
};
export const updateCommunityParticipation = async ({ id, data }) => {
  return await client.put(`/scholarship-evaluations/${id}`, data);
};
export const deleteCommunityParticipation = async ({ id }) => {
  return await client.delete(`/scholarship-evaluations/${id}`);
};
// 8. Research contribution

export const fetchAllResearchContribution = async () => {
  return await client.get(
    `/scholarship-evaluations?type=contribution_to_research`
  );
};
export const addNewResearchContribution = async ({ data }) => {
  return await client.post("/scholarship-evaluations", data);
};
export const updateResearchContribution = async ({ id, data }) => {
  return await client.put(`/scholarship-evaluations/${id}`, data);
};
export const deleteResearchContribution = async ({ id }) => {
  return await client.delete(`/scholarship-evaluations/${id}`);
};
// 9. Certificates And Awards

export const fetchAllCertificatesAndAwards = async () => {
  return await client.get(
    `/scholarship-evaluations?type=certificates_and_awards`
  );
};
export const addNewCertificatesAndAwards = async ({ data }) => {
  return await client.post("/scholarship-evaluations", data);
};
export const updateCertificatesAndAwards = async ({ id, data }) => {
  return await client.put(`/scholarship-evaluations/${id}`, data);
};
export const deleteCertificatesAndAwards = async ({ id }) => {
  return await client.delete(`/scholarship-evaluations/${id}`);
};

//   Education System

export const getAllEducationSystems = async () => {
  return await client.get("/education-systems");
};
export const addNewEducationSystem = async ({ data }) => {
  return await client.post("/education-systems", data);
};
export const updateEducationSystem = async ({ id, data }) => {
  return await client.put(`/education-systems/${id}`, data);
};
export const deleteEducationSystem = async ({ id }) => {
  return await client.delete(`/education-systems/${id}`);
};

// update evaluation rubrics

export const updateEvaluationRubricPoints = async ({ data }) => {
  return await client.put("applications/sch/score", data);
};
// committe aproval and rejection
export const addCommitteeApproval = async ({ data }) => {
  return await client.post("/applications/committee-approval", data);
};
export const rejectCommitteeApproval = async ({ data }) => {
  return await client.post("/applications/committee-approval", data);
};

// evaluation final approval
export const onAssignRewardsForFinalApproval = async ({ data }) => {
  return await client.post("/applications/sch/reward", data);
};
export const onAddRemarksForAcceptSchForFinalApproval = async ({ data }) => {
  return await client.post("/applications/stage/approval", data);
};
export const onAddRemarksForRejectSchForFinalApproval = async ({ data }) => {
  return await client.post("/applications/stage/approval", data);
};
