import { Button, Form, Input, Select, Row, Col } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";

const { Option } = Select;
const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} is required`,
    },
  ];
};

const YearAndTermsForm = ({ formName, onSubmit, loading }) => {
  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={24}>
            <Form.Item
              label={"Please provide only remarks for this term"}
              name={"notes"}
              rules={setRules("Remarks")}
            >
              <TextArea
                placeholder="Add here"
                type="text"
                style={{ height: 100 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <br />

        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant__primary__button"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default YearAndTermsForm;
