import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select } from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import { fetchAllTeachers } from "apis/TCC/tccRequestTypes";
import { updateTeacher } from "apis/TCC/tccRequestTypes";
import { deleteTeacher } from "apis/TCC/tccRequestTypes";
import { addNewTeacher } from "apis/TCC/tccRequestTypes";
import TCCTeacherForm from "components/Forms/tcc-forms/TCCTeacherForm";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const TeachersConfig = () => {
  const tcc_schools = useSelector((state) => state.metaData.TCC_schools);

  const gendersDropDowns = useSelector(
    (state) => state.metaData?.dropDowns?.gender
  );

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [teacherId, setTeacherId] = useState("");

  const [tccTeacherForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            tccTeacherForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"TCC Teachers"}
          headerSubTitle={"Manage TCC Teachers"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllTeachers();
  }, []);

  const getAllTeachers = async () => {
    try {
      setLoading(true);
      const response = await fetchAllTeachers();
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addTeacher = async (data) => {
    try {
      setLoading(true);
      const response = await addNewTeacher({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Teacher added successfully" });
        await getAllTeachers();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateTeacher = async (data) => {
    try {
      setLoading(true);
      const response = await updateTeacher({ id: teacherId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Teacher updated successfully" });
        await getAllTeachers();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteTeacher = async (id) => {
    try {
      setLoading(true);
      const response = await deleteTeacher({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Teacher deleted successfully" });
        await getAllTeachers();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateTeacher(values);
    } else {
      await addTeacher(values);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    tccTeacherForm.setFieldsValue({
      ...record,
    });
    setTeacherId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Name",
              // dataIndex: "firstName",
              key: "firstName",

              render: (record) => (
                <p className="answer_para">
                  {record?.firstName +
                    " " +
                    record?.middleName +
                    " " +
                    record?.lastName}
                </p>
              ),
            },
            {
              title: "Gender",
              // dataIndex: "gender",
              key: "gender",

              render: (record) => (
                <p className="answer_para">
                  {record && record?.gender === "M" ? "Male" : "Female"}
                </p>
              ),
            },

            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              render: (record) => <p className="answer_para">{record}</p>,
            },

            {
              title: "Phone Number",
              dataIndex: "phone",
              key: "phone",
              render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "School",
              // dataIndex: "phone",
              key: "school",
              render: (record) => (
                <p className="answer_para">
                  {record && record?.school
                    ? record?.school?.abbreviation?.toUpperCase()
                    : "N/A"}
                </p>
              ),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Teacher Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Teacher?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteTeacher(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Teacher`}
        >
          <TCCTeacherForm
            onSubmit={onSubmit}
            formName={tccTeacherForm}
            loading={loading}
            valuesForEdit={null}
            tcc_schools={tcc_schools}
            gendersDropDowns={gendersDropDowns}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default TeachersConfig;
