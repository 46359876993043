export const GET_ESERVICES_ACTION = "GET_ESERVICES_ACTION";
export const ADD_ESERVICES_ACTION = "ADD_ESERVICES_ACTION";
export const UPDATE_ESERVICES_ACTION = "UPDATE_ESERVICES_ACTION";
export const DELETE_ESERVICES_ACTION = "DELETE_ESERVICES_ACTION";

// get data
const getEServicesAction = (data) => {
  return {
    type: GET_ESERVICES_ACTION,
    payload: data,
  };
};

export const _getEServicesAction = (data) => {
  return (dispatch) => dispatch(getEServicesAction(data));
};

// add

const addEServicesAction = (data) => {
  return {
    type: ADD_ESERVICES_ACTION,
    payload: data,
  };
};

export const _addEServicesAction = (data) => {
  return (dispatch) => dispatch(addEServicesAction(data));
};

// update

const updateEServicesAction = (data) => {
  return {
    type: UPDATE_ESERVICES_ACTION,
    payload: data,
  };
};

export const _updateEServicesAction = (data) => {
  return (dispatch) => dispatch(updateEServicesAction(data));
};

// delete

const deleteEServicesAction = (data) => {
  return {
    type: DELETE_ESERVICES_ACTION,
    payload: data,
  };
};

export const _deleteEServicesAction = (data) => {
  return (dispatch) => dispatch(deleteEServicesAction(data));
};
