import client from "apis/config";

export const getEServicesConfigAPI = async (pagination, id) => {
  return await client.get(`eservices/configurations/${id}?${pagination}`);
};

export const addEServicesConfigAPI = async (data) => {
  return await client.post(`eservices/configurations`, data);
};

export const updateEServicesConfigAPI = async (id, data) => {
  return await client.put(`eservices/configurations/${id}`, data);
};

export const deleteEServicesConfigAPI = async (id) => {
  return await client.delete(`eservices/configurations/${id}`);
};
export const addDiscountEServiceConfigAPI = async (data) => {
  return await client.post(`eservices/discount`, data);
};
