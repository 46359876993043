/* eslint-disable no-unreachable */
import {
  ADD_NEW_DOCUMENT_CONFIGURATION,
  DELETE_DOCUMENT_CONFIGURATION,
  GET_ALL_DOCUMENT_LIST_CONFIGURATION,
  UPDATE_DOCUMENT_CONFIGURATION,
} from "../../actions/documents-configurations/documentsConfigurationsAction";

const initialState = {
  documentsListConfiguration: [],
  total: 0,
};

const updateDocumentHelper = (data, item) => {
  const newData = [...data];
  const index = data.findIndex((el) => el.id === item.id);
  if (index > -1) {
    newData[index] = item;
    return newData;
  } else return data;
};

export default function documentlistConfigurationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_DOCUMENT_LIST_CONFIGURATION:
      return {
        ...state,
        documentsListConfiguration: action.payload.data,
        total: action.payload.total,
      };
    case ADD_NEW_DOCUMENT_CONFIGURATION:
      return {
        ...state,
        documentsListConfiguration: [
          action.payload,
          ...state.documentsListConfiguration,
        ],
        total: state.total + 1,
      };
    case UPDATE_DOCUMENT_CONFIGURATION:
      return {
        ...state,
        documentsListConfiguration: updateDocumentHelper(
          state.documentsListConfiguration,
          action.payload
        ),
      };
    case DELETE_DOCUMENT_CONFIGURATION:
      return {
        ...state,
        documentsListConfiguration: state.documentsListConfiguration.filter(
          (el) => el.id !== action.payload
        ),
        total: state.total - 1,
      };

    default:
      return state;
  }
}
