import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import React, { useRef } from "react";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  generateUniqueColumnKey,
  renderLoader,
  requestErrorHandel,
  successMessage,
} from "utils/helpers/helpers";
import {
  addNewSccForm,
  deleteSccForm,
  fetchAllSccForms,
  getSearchedStudentsList,
  updateSccForm,
} from "./api";
import SccDocumentGeneratorForm from "./SccDocumentGeneratorForm";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import SccDocumentModal from "./SccDocumentModal";

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;
const {
  pasteCleanup,
  sanitize,
  sanitizeClassAttr,
  sanitizeStyleAttr,
  removeAttribute,
  replaceImageSourcesFromRtf,
  setHtml,
} = EditorUtils;
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const SccDocumentGenerator = (props) => {
  const [sccDocumentForm] = Form.useForm();

  const _campuses = useSelector((state) => state.metaData.campuses);
  const _allschools = useSelector((state) => state.metaData.schools);
  const _years = useSelector((state) => state.metaData.SCC_years);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [schools, setSchools] = useState(_allschools);
  const [isTermOpen, setIsTermOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const editorRefEn = useRef(null);
  const editorRefAr = useRef(null);

  const [user, setUserData] = useState(null);

  const [filters, setFilters] = useState({
    campus: "",
    school: "",
    year: "",
  });
  const [search, setSearch] = useState("");

  const handleCampusChange = (e) => {
    if (e === "#") {
      setFilters({ ...filters, campus: "" });
      setSchools(_allschools);
    } else {
      const campusId = parseInt(e);
      const campusName = _campuses.find((el) => el.id === campusId).code;

      setFilters({ ...filters, campus: campusName });

      setSchools(_allschools.filter((el) => el.campusId === campusId));
    }
  };

  const handleDownloadPdf = async (id, lan) => {
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;

      const url = `${baseURL}/scc-documents/getPDF/${id}/${lan}`;
      

      window.open(url, "_blank");
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  useEffect(() => {
    getAllSccDocumentForms();
  }, [page, filters, search]);

  const getAllSccDocumentForms = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSccForms({
        campus: filters.campus,
        school: filters.school,
        year: filters.year,
        search: search,
      });
      if (response.status === 200) {
        // Sort the documents by creation date (assuming 'createdAt' is the field)
        const sortedData = response.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setData(sortedData);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
};
  const addSccDocumentForm = async (data) => {
    try {
      setLoading(true);
      const response = await addNewSccForm({ data: data });
      if (response.status === 200) {
        successMessage({
          message: "Case has been created successfully",
        });
        await getAllSccDocumentForms();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const updateSccDocumentForm = async (updatedData) => {
    try {
      setLoading(true);
      const response = await updateSccForm(updatedData);
      if (response.status === 200) {
        successMessage({
          message: "Document has been updated successfully",
        });
        await getAllSccDocumentForms();
        if (updatedData.data.isPdfDownloadAction) {
          return response?.data?.data;
        }
        return onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteSccDocumentForm = async (id) => {
    try {
      setLoading(true);
      const response = await deleteSccForm({ id: id });
      if (response.status === 200) {
        successMessage({
          message: "Case deleted successfully",
        });
        await getAllSccDocumentForms();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    if (modalTitle === ModalType.ADD) {
      const data = {
        ...values,
        studentNumber: JSON.stringify(user?.STUDENT_NUMBER),
        campus: user?.CAMPUS,
        grade: user?.GRADE,
        school: user?.SCHOOL,
      };
      await addSccDocumentForm(data);
    } else {
      const res = await updateSccDocumentForm({
        id: valuesForEdit.id,
        data: values,
      });
      return res;
    }
  };

  const fetchStudents = async (studentNumber) => {
    const parsedStudentNumber = parseInt(studentNumber);
    try {
      const response = await getSearchedStudentsList({
        search: studentNumber,
      });
      const searchedStudent = response.data?.data?.find(
        (user) => parsedStudentNumber == studentNumber
      );
      return searchedStudent;
    } catch (error) {
      console.log("error", error);
    }
  };

  const onEditDetails = async (record) => {
    const fetchedStudent = await fetchStudents(record.studentNumber);
    setValuesForEdit({ ...record, selectedUser: fetchedStudent });

    sccDocumentForm.setFieldsValue({
      ...record,
      studentNumber: record.studentNumber,
    });
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  // Handlers for selecting campus and category

  const setUser = (user) => {
    setUserData(user);
  };

  useEffect(() => {
    if (sccDocumentForm.getFieldValue("status") === "complete" && !isChecked) {
      setIsChecked(true);
    }
    setIsChecked(false);
  }, [showModal]);

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            sccDocumentForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          // disabled={!isTermOpen}
          headerTitle={"Documents Generator"}
          headerSubTitle={"Code of Conduct Documents Management"}
          headerBtnTitle={"Create New Document"}
        />
        <Row gutter={10}>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter by campus"
                onChange={(e) => handleCampusChange(e)}
                // bordered={false}
                defaultValue={filters.campus || undefined}
              >
                <Option value={"#"}>All</Option>
                {_campuses?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter by school"
                onChange={(e) => setFilters({ ...filters, school: e })}
                defaultValue={filters.school || undefined}
              >
                <Option value={""}>All</Option>
                {schools?.map((el, i) => {
                  return (
                    <Option key={i} value={el.abbreviation}>
                      {el.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter by year"}
                bordered={false}
                onChange={(e) => setFilters({ ...filters, year: e })}
                value={filters?.year || undefined}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_years?.map((el, i) => {
                  return (
                    <Option key={i} value={el.name}>
                      {el.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={6} className="filterColumn">
            <Form.Item className="dropdown">
              <Input
                placeholder="Search"
                defaultValue={search}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <br />
      </>
    );
  };

  return (
    <div style={{ position: "relative" }} className="both-side-padding">
      <SccDocumentModal
        isFormModalOpen={showModal}
        handleClose={onCloseForm}
        visibility={showModal}
        handleCancel={onCloseForm}
        handleOk={() => null}
        title={`${modalTitle} Form Document`}
      >
        <SccDocumentGeneratorForm
          onSubmit={onSubmit}
          formName={sccDocumentForm}
          loading={loading}
          valuesForEdit={valuesForEdit}
          getUser={(user) => setUser(user)}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
        />
      </SccDocumentModal>

      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Document Sequence Number",
              dataIndex: "documentSequenceNumber",
              key: generateUniqueColumnKey(),
              // render: (record) => (
              //   <p>
              //     {record?.student?.FIRST_NAME} {record?.student?.LAST_NAME}
              //   </p>
              // ),
            },
            {
              title: "Student",
              dataIndex: "studentNumber",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Year",
              dataIndex: "year",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Updated At",
              dataIndex: "",
              key: generateUniqueColumnKey(),
              render: (record) => {
                if (record?.updatedAt) {
                  return (
                    <p>
                      {moment(record?.updatedAt).format("YYYY-MM-DD HH:mm")}
                    </p>
                  );
                }
              },
            },
            {
              title: "Campus",
              dataIndex: "campus",
              key: generateUniqueColumnKey(),
            },
            {
              title: "School",
              dataIndex: "school",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Section",
              dataIndex: "section",
              key: generateUniqueColumnKey(),
            },

            {
              title: "Status",
              dataIndex: "",
              key: generateUniqueColumnKey(),
              render: (record) => (
                <p style={{ textTransform: "capitalize" }}>{record?.status}</p>
              ),
            },

            {
              title: "Action",
              dataIndex: "",
              key: generateUniqueColumnKey(),

              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    {item.status === "draft" && (
                      <div style={{ marginRight: "10px" }}>
                        <Tooltip title="Edit Details">
                          <Button
                            className="d-flex justify-content-center align-items-center"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={() => onEditDetails(item)}
                          />
                        </Tooltip>
                      </div>
                    )}

                    <Popconfirm
                      title="Are you sure you want to delete this?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteSccDocumentForm(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                    {/* {(item?.status).toLowerCase() === "complete" && ( */}
                    <div
                      style={{
                        marginLeft: "10px",
                        display: "flex ",
                        gap: "2px",
                      }}
                    >
                      <Tooltip title="Download English PDF">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DownloadOutlined />}
                          onClick={() => handleDownloadPdf(item.id, "en")}
                        />
                      </Tooltip>
                      <Tooltip title="Download Arabic PDF">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DownloadOutlined />}
                          onClick={() => handleDownloadPdf(item.id, "ar")}
                        />
                      </Tooltip>
                    </div>
                    {/* )} */}
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        {/* <CustomModal
          // visibility={true}
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Form Document`}
        >
          <SccDocumentGeneratorForm
            onSubmit={onSubmit}
            formName={sccDocumentForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            getUser={(user) => setUser(user)}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
        </CustomModal> */}
      </Spin>
    </div>
  );
};

export default SccDocumentGenerator;
