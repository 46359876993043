/* eslint-disable no-unused-vars */
import { Card } from "antd";
import React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";
import FinalRemarks from "../final-remarks/FinalRemarks";
import { convertToTitleCase } from "utils/helpers/helpers";
import ScheduleDetail from "../schedule-detail/ScheduleDetail";
import Result from "../result/Result";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";

function EntranceTest({ currentStageDetails }) {
  const reTestDetails = () => {
    if (currentStageDetails?.schedules?.length === 1) return null;
    return currentStageDetails?.schedules.map((_schedule, index) => {
      if (index === 1) {
        return (
          <Card className="antCardBorder" style={{ marginTop: "10px" }}>
            <div
              style={{
                alignItems: "center",
              }}
            >
              <div>
                <div>
                  <div>
                    <h4>Retest</h4>
                    <h5>Entrance Test Details</h5>
                  </div>
                  <div>
                    <p>
                      {!currentStageDetails?.schedules[1].subjectsTestTiming
                        .length && (
                        <>
                          Date for rested:{" "}
                          {formateDateWithMoment(
                            currentStageDetails?.schedules[1].scheduleTime,
                            "DD MMM YYYY hh:mm A"
                          )}
                        </>
                      )}
                    </p>
                    <p>
                      Type:{" "}
                      {convertToTitleCase(
                        currentStageDetails?.schedules[1].scheduleType
                      )}
                    </p>
                    {currentStageDetails?.schedules[1].scheduleType !==
                    "online" ? (
                      <p>
                        Instructions:{" "}
                        {currentStageDetails?.schedules[1].address}
                      </p>
                    ) : null}
                    <p style={{ overflowX: "clip" }}>
                      Notes: {currentStageDetails?.schedules[1].notes}
                    </p>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#f0f0f0",
                      padding: "2px 12px ",
                      width: "100%",
                      maxWidth: "350px",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CalendarOutlined style={{ marginRight: "10px" }} />
                      <p style={{ margin: "7px 0" }}>
                        Date of Action :{" "}
                        {formateDateWithMoment(
                          currentStageDetails?.schedules[1].createdAt,
                          "DD MMM YYYY hh:mm A"
                        )}
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <UserOutlined style={{ marginRight: "10px" }} />
                      <p style={{ margin: "7px 0" }}>
                        Action Creator :{" "}
                        {currentStageDetails?.schedules[1].user?.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {currentStageDetails.schedules[1].subjectsTestTiming.length ? (
                  <ScheduleDetail
                    data={currentStageDetails.schedules[1].subjectsTestTiming}
                  />
                ) : null}
              </div>
              <div>
                {currentStageDetails.schedules[1].remarks[0]?.subjectResults
                  .length ? (
                  <>
                    <Result
                      data={
                        currentStageDetails.schedules[1].remarks[0]
                          ?.subjectResults
                      }
                    />
                    <div
                      style={{
                        backgroundColor: "#f0f0f0",
                        padding: "2px 12px ",
                        width: "50%",
                        borderRadius: "5px",
                        marginBottom: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CalendarOutlined style={{ marginRight: "10px" }} />
                        <p style={{ margin: "7px 0" }}>
                          {formateDateWithMoment(
                            currentStageDetails?.schedules[1].createdAt,
                            "DD MMM YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <UserOutlined style={{ marginRight: "10px" }} />
                        <p style={{ margin: "7px 0" }}>
                          {currentStageDetails?.schedules[1].user?.name +
                            " - " +
                            currentStageDetails?.schedules[1].user?.userName}
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div style={{ marginTop: "20px" }}>
                <FinalRemarks
                  remarks={currentStageDetails?.schedules[1].remarks}
                />
              </div>
            </div>
          </Card>
        );
      } else return null;
    });
  };
  if (currentStageDetails.schedules?.length) {
    return (
      <>
        <Card className="antCardBorder" style={{ marginTop: "10px" }}>
          <div
            style={{
              alignItems: "center",
            }}
          >
            <div>
              <div>
                <div>
                  <h5>Entrance Test Details</h5>
                </div>
                <div>
                  <p>
                    {!currentStageDetails.schedules[0].subjectsTestTiming
                      .length && (
                      <>
                        Date:{" "}
                        {formateDateWithMoment(
                          currentStageDetails?.schedules[0].scheduleTime,
                          "DD MMM YYYY hh:mm A"
                        )}
                      </>
                    )}
                  </p>
                  <p>
                    Type:{" "}
                    {convertToTitleCase(
                      currentStageDetails?.schedules[0].scheduleType
                    )}
                  </p>
                  {currentStageDetails?.schedules[0].scheduleType !==
                  "online" ? (
                    <p>
                      Instructions: {currentStageDetails?.schedules[0].address}
                    </p>
                  ) : null}
                  <p style={{ overflowX: "clip" }}>
                    Notes: {currentStageDetails?.schedules[0].notes}
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#f0f0f0",
                    padding: "2px 12px ",
                    width: "100%",
                    maxWidth: "350px",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CalendarOutlined style={{ marginRight: "10px" }} />
                    <p style={{ margin: "7px 0" }}>
                      Date of Action :{" "}
                      {formateDateWithMoment(
                        currentStageDetails?.schedules[0].createdAt,
                        "DD MMM YYYY hh:mm A"
                      )}
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <UserOutlined style={{ marginRight: "10px" }} />
                    <p style={{ margin: "7px 0" }}>
                      Action Creator :{" "}
                      {currentStageDetails?.schedules[0].user?.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {currentStageDetails.schedules[0].subjectsTestTiming.length ? (
                <ScheduleDetail
                  data={currentStageDetails.schedules[0].subjectsTestTiming}
                />
              ) : null}
            </div>
            <div>
              {currentStageDetails.schedules[0].remarks[0]?.subjectResults
                .length ? (
                <>
                  <Result
                    data={
                      currentStageDetails.schedules[0].remarks[0]
                        ?.subjectResults
                    }
                  />
                  <div
                    style={{
                      backgroundColor: "#f0f0f0",
                      padding: "2px 12px ",
                      width: "50%",
                      borderRadius: "5px",
                      marginBottom: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CalendarOutlined style={{ marginRight: "10px" }} />
                      <p style={{ margin: "7px 0" }}>
                        {formateDateWithMoment(
                          currentStageDetails?.schedules[0].createdAt,
                          "DD MMM YYYY hh:mm A"
                        )}
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <UserOutlined style={{ marginRight: "10px" }} />
                      <p style={{ margin: "7px 0" }}>
                        {currentStageDetails?.schedules[0].user?.name +
                          " - " +
                          currentStageDetails?.schedules[0].user?.userName}
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div style={{ marginTop: "20px" }}>
              <FinalRemarks
                remarks={currentStageDetails?.schedules[0].remarks}
              />
            </div>
          </div>
          {reTestDetails()}
        </Card>
        <br />
      </>
    );
  } else return null;
}

export default EntranceTest;
