import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { _deleteDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";
import { _addDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";
import { _updateDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";
import { _getDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";
import { Button, Row, Spin, Tooltip, Popconfirm, Form, Tag } from "antd";
import { deleteDistributionAPI } from "apis/distribution-apis/DistributionApis";
import { addDistributionAPI } from "apis/distribution-apis/DistributionApis";
import { updateDistributionAPI } from "apis/distribution-apis/DistributionApis";
import { getDistributionAPI } from "apis/distribution-apis/DistributionApis";
import AddDistributionForm from "components/DiscountComponents/DiscountDistributionForm/AddDistributionForm";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showValidationError } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { stringifyNumber } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { Capitalize } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";

const DiscountDistribution = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [installemnts, setInstallments] = useState();
  const _distributions = useSelector((state) => state.distributions.data);
  const _total = useSelector((state) => state.distributions.dataTotal);

  useEffect(() => {
    fetchDistributions();
  }, [currentPage]);

  const fetchDistributions = async () => {
    try {
      setLoading(true);
      const response = await getDistributionAPI(`?page=${currentPage}`);
      if (response.status == 200 && response.data) {
        dispatch(_getDistributionAction(response.data));
        setLoading(false);
      } else throw response.data;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };
  const fetchMore = (page) => setCurrentPage(page);

  const onAdd = () => {
    setIsEdit("");
    form.resetFields();
    setInstallments();
    setShowAddModal(true);
  };

  const onEdit = (item) => {
    setShowAddModal(true);
    setIsEdit(item.id);
    form.setFieldsValue({ title: item.title, installments: item.instalments });
    setInstallments(item.instalments);
  };

  const onSubmitForm = async (values) => {
    const totalSum = values.installments.reduce(
      (a, b) => Number(a) + Number(b)
    );
    if (totalSum === 100) {
      let params = {
        title: values.title,
        instalments: values.installments.map((el) => Number(el) || 0),
      };
      try {
        setLoading(true);
        if (isEdit) {
          const response = await updateDistributionAPI(params, isEdit);
          if (response.status == 200 && response.data) {
            dispatch(_updateDistributionAction(response.data.data));
            successMessage({ message: "Distribution updated successfully" });
            setLoading(false);
            setShowAddModal(false);
          } else throw response;
        } else {
          const response = await addDistributionAPI(params);
          if (response.status == 200 && response.data) {
            dispatch(_addDistributionAction(response.data.data));
            successMessage({ message: "Distribution added successfully" });
            setShowAddModal(false);
            setLoading(false);
          } else throw response;
        }
      } catch (e) {
        requestErrorHandel({ error: e });
        setLoading(false);
      }
    } else {
      showValidationError("Sum of all installments must be equal to 100");
    }
  };

  const onDelete = async (item) => {
    try {
      setLoading(true);
      const response = await deleteDistributionAPI(item.id);
      if (response.status == 200) {
        dispatch(_deleteDistributionAction(item.id));
        successMessage({ message: "Distribution deleted successfully" });
        setLoading(false);
      }
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          key={"distributions_table"}
          data={_distributions}
          header={() => (
            <TableHeader
              headerTitle={`Discount Distribution`}
              headerSubTitle={""}
              headerBtnTitle="Add New"
              onAddNew={onAdd}
            />
          )}
          columns={[
            {
              key: "id",
              title: "Title",
              dataIndex: "title",
              width: "20%",
              render: (record) => Capitalize(record),
            },
            {
              key: "Distributions",
              title: "Distributions",
              dataIndex: "instalments",
              width: "60%",
              render: (record) =>
                record?.map((installment, index) => (
                  <div
                    key={`installment_${index}`}
                    style={{ paddingBottom: "5px" }}
                  >
                    <Tag key={index} style={{ width: 100 }}>
                      {`${stringifyNumber(index + 1)} Installment`}
                    </Tag>
                    <span style={{ paddingRight: "5px" }}>{` -  `}</span>
                    <Tag key={index} style={{ width: 45 }}>
                      {`${installment}`}%
                    </Tag>
                  </div>

                  // <div key={index}>{`${index + 1}-   ${installment} `}</div>
                )),
            },
            {
              title: "Action",
              dataIndex: "",
              key: "id",
              width: "20%",
              render: (item) => (
                <Row>
                  <Tooltip title={"Edit"}>
                    <Button
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => onEdit(item)}
                    />
                  </Tooltip>

                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        style={{ marginLeft: 10 }}
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </Row>
              ),
            },
          ]}
          totalRecord={_total}
          pageSize={10}
          fetchRecords={fetchMore}
        ></CommonTable>

        <AddDistributionForm
          loading={loading}
          visibility={showAddModal}
          onHide={() => setShowAddModal(false)}
          title={`${isEdit ? "Update" : "Add"} Discount Distributions`}
          onSubmit={onSubmitForm}
          formName={form}
          filledInstallments={installemnts}
        />
      </Spin>
    </div>
  );
};

export default DiscountDistribution;
