import { Card } from "antd";
import React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";
import FinalRemarks from "../final-remarks/FinalRemarks";
import { convertToTitleCase } from "utils/helpers/helpers";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";

function ScheduleObservation({ currentStageDetails }) {
  if (currentStageDetails?.schedules?.length) {
    return (
      <div>
        <Card className="antCardBorder" style={{ marginTop: "10px" }}>
          <div
            style={{
              alignItems: "center",
            }}
          >
            <div>
              <div>
                <div>
                  <h5>Observation Details</h5>
                </div>
                <div>
                  <p>
                    Date:{" "}
                    {formateDateWithMoment(
                      currentStageDetails?.schedules[0]?.scheduleTime,
                      "DD MMM YYYY hh:mm A"
                    )}
                  </p>
                  <p>
                    Type:{" "}
                    {convertToTitleCase(
                      currentStageDetails?.schedules[0]?.scheduleType
                    )}
                  </p>
                  {currentStageDetails?.schedules[0].scheduleType !==
                  "online" ? (
                    <p>
                      Instructions: {currentStageDetails?.schedules[0]?.address}
                    </p>
                  ) : null}
                  <p style={{ overflowX: "clip" }}>
                    Notes: {currentStageDetails?.schedules[0]?.notes}
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: "#f0f0f0",
                    padding: "2px 12px ",
                    maxWidth: "350px",
                    width: "100%",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CalendarOutlined style={{ marginRight: "10px" }} />
                    <p style={{ margin: "7px 0" }}>
                      {formateDateWithMoment(
                        currentStageDetails?.schedules[0].createdAt,
                        "DD MMM YYYY hh:mm A"
                      )}
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <UserOutlined style={{ marginRight: "10px" }} />
                    <p style={{ margin: "7px 0 " }}>
                      {currentStageDetails?.schedules[0].user?.name +
                        " - " +
                        currentStageDetails?.schedules[0].user?.userName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <FinalRemarks
                remarks={currentStageDetails?.schedules[0]?.remarks}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  } else return null;
}

export default ScheduleObservation;
