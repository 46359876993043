import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../styles/shared.css";
import CustomModal from "components/CustomModal/CustomModal";

import { evaluationPointsConfig } from "utils/constants";

const { Option } = Select;

function EServicesForm(props) {
  const _terms = useSelector((state) => state.metaData.terms);
  const _requestTypes = useSelector((state) => state.metaData.requestTypes);
  const _serviceItemTypes = useSelector(
    (state) => state.metaData?.dropDowns?.ItemType
  );
  const applicationStatuses = useSelector(
    (state) => state.metaData.applicationStatuses
  );
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _grades = useSelector((state) =>
    state.metaData.grades?.filter((grade) => grade.code !== "GRADUATED")
  );

  const _discountTypeOptions = useSelector(
    (state) => state.metaData.dropDowns["discountType"]
  );

  const [selectedCampuses, setSelectedCampuses] = React.useState([]);
  const [selectedGrades, setSelectedGrades] = React.useState([]);
  const [selectedStatuses, setSelectedStatuses] = React.useState([]);
  const [selectedAllowedTCCGrades, setSelectedAllowedTCCGrades] = useState([]);
  const [selectedApplicableEvaluation, setSelectedApplicableEvaluation] =
    useState([]);
  const [selectedVisibleEvaluationFields, setSelectedVisibleEvaluationFields] =
    useState([]);

  const categories = [
    {
      id: "1",
      title: "Free",
      value: "free",
    },
    {
      id: "2",
      title: "Paid",
      value: "paid",
    },
  ];
  const [selectedCategory, selectCategory] = useState("paid");
  const [selectedApplicationType, selectApplicationType] = useState("");
  const [allowItemTypeChange, setAllowItemTypeChange] = useState(false);
  const [showItemTypeConfirmation, setShowItemTypeConfirmation] =
    useState(false);
  const [hideNote, setHideNote] = useState(false);
  const [employeeDiscount, setEmployeeDiscount] = useState(false);

  useEffect(() => {
    if (!props.isVisible) setAllowItemTypeChange(false);
  }, [props.isVisible]);

  useEffect(() => {
    if (props.valuesForEdit?.applicationType === "ENR") {
      setTypeForENR();
    }
    selectApplicationType(props.valuesForEdit?.applicationType);
    setEmployeeDiscount(props.valuesForEdit?.allowEmployeeDiscount);
    selectCategory(props.valuesForEdit?.type || "");
    const _prevGrades =
      props.valuesForEdit == null
        ? []
        : _grades?.filter((grade) =>
            props.valuesForEdit.availableGrades.includes(grade.id)
          );
    const _prevCampuses =
      props.valuesForEdit == null
        ? []
        : _campuses?.filter((campus) =>
            props.valuesForEdit.availableCampuses.includes(campus.id)
          );

    const _prevTCCAlllowedGrades =
      props.valuesForEdit == null
        ? []
        : _grades.filter((grade) => {
            return props.valuesForEdit?.allowedGradeCourses?.some(
              (allowedGrade) => allowedGrade.id === grade.id
            );
          });
    const _prevAllowedApplicationStatuses =
      props.valuesForEdit == null
        ? []
        : applicationStatuses.filter((appStatus) => {
            return props.valuesForEdit?.allowNewApplicationOnStatuses?.some(
              (allowedStatus) => allowedStatus === appStatus.code
            );
          });

    const _prevApplicableEvaluations =
      props.valuesForEdit == null ||
      props.valuesForEdit.applicationType !== "SCH"
        ? []
        : evaluationPointsConfig?.filter((evaPoint) =>
            props.valuesForEdit?.applicableEvaluations.includes(evaPoint.value)
          );
    const _prevVisibleEvaluationsFields =
      props.valuesForEdit == null ||
      props.valuesForEdit.applicationType !== "SCH"
        ? []
        : evaluationPointsConfig?.filter((evaPoint) =>
            props.valuesForEdit?.visibleFieldsForEvaluations.includes(
              evaPoint.value
            )
          );

    setSelectedCampuses(_prevCampuses);
    setSelectedGrades(_prevGrades);
    setSelectedAllowedTCCGrades(_prevTCCAlllowedGrades);
    setSelectedStatuses(_prevAllowedApplicationStatuses);
    setSelectedApplicableEvaluation(_prevApplicableEvaluations);
    setSelectedVisibleEvaluationFields(_prevVisibleEvaluationsFields);
    props.setAvailableGrades(_prevGrades);
    props.setAvailableCampuses(_prevCampuses);
    props.setAllowedStatuses(_prevAllowedApplicationStatuses);
    props.setApplicableEvaluation(_prevApplicableEvaluations);
    props.setVisibleEvaluationfields(_prevVisibleEvaluationsFields);
  }, [props.valuesForEdit]);

  const setTypeForENR = () => {
    props.form && props.form.setFieldsValue({ type: "paid" });
    selectCategory("paid");
    selectApplicationType("ENR");
  };

  const handleSelectAllGrades = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedGrades(_grades);
      props.setAvailableGrades(_grades);
    } else {
      return value;
    }
  };
  const handleSelectAllEvaluationApplied = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedApplicableEvaluation(evaluationPointsConfig);
      props.setApplicableEvaluation(evaluationPointsConfig);
    } else {
      return value;
    }
  };
  const handleSelectAllVisibleEvaluationFields = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedVisibleEvaluationFields(evaluationPointsConfig);
      props.setVisibleEvaluationfields(evaluationPointsConfig);
    } else {
      return value;
    }
  };

  const handleSelectAllCampus = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedCampuses(_campuses);
      props.setAvailableCampuses(_campuses);
    } else {
      return value;
    }
  };
  const handleSelectAllowedTCCGrade = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedAllowedTCCGrades(_grades);
      props.setAllowedTCCGrades(_grades);
    } else {
      return value;
    }
  };
  const handleSelectApplicationStatuses = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedStatuses(applicationStatuses);
      props.setAllowedStatuses(applicationStatuses);
    } else {
      return value;
    }
  };
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Form.Item label={"id"} name={"id"} style={{ display: "none" }} />
        <Col lg={12} md={12} sm={24}>
          <Form.Item
            label={"Select Request Type"}
            name={"applicationType"}
            rules={[
              {
                required: true,
                message: "Field is required",
              },
            ]}
          >
            <Select
              style={{ border: "#d9d9d9 1px solid" }}
              disabled={!!props.valuesForEdit}
              onChange={(value) => {
                if (value === "ENR") {
                  setTypeForENR();
                }
                selectApplicationType(value);
              }}
              placeholder={"Request Type"}
            >
              {_requestTypes?.map((el, i) => {
                return (
                  <Option key={i} value={`${el.identifier}`}>
                    {el.identifier}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24}>
          <Form.Item
            label={"Template"}
            name={"templateId"}
            rules={[
              {
                required: true,
                message: "Field is required",
              },
            ]}
          >
            <Select
              style={{ border: "#d9d9d9 1px solid" }}
              value={props.tempplatesData?.find((el) => el.id)}
              placeholder="Select Template"
            >
              {props.tempplatesData?.map((el, index) => (
                <Option value={el.id} key={index}>
                  {el.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24}>
          <Form.Item
            label={"Select Term"}
            name={"term"}
            rules={[
              {
                required: true,
                message: "Field is required",
              },
            ]}
          >
            <Select
              style={{ border: "#d9d9d9 1px solid" }}
              placeholder={"Term"}
            >
              {_terms?.map((el, i) => (
                <Option key={el.id} value={el.id}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24}>
          <Form.Item
            label={"Select Category"}
            name={"type"}
            rules={[
              {
                required: true,
                message: "Field is required",
              },
            ]}
          >
            <Select
              style={{ border: "#d9d9d9 1px solid" }}
              placeholder={"Category"}
              onChange={(e) => selectCategory(e)}
              // defaultValue={selectedCategory}
            >
              {categories?.map((el, i) => {
                if (el.value === "free" && selectedApplicationType === "ENR")
                  return;
                return (
                  <Option key={i} value={`${el.value}`}>
                    {el.title}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24}>
          <Form.Item
            label={"Select Start Date"}
            name={"startAt"}
            rules={[
              {
                required: true,
                message: "Field is required",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD"
              // disabledDate={(current) => {
              //   let customDate = moment().format("YYYY-MM-DD");
              //   return current && current < moment(customDate, "YYYY-MM-DD");
              // }}
            />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24}>
          <Form.Item
            label={"Select End Date"}
            name={"endAt"}
            rules={[
              {
                required: true,
                message: "Field is required",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (moment(value).isAfter(getFieldValue("startAt"))) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        "End date can not be earlier than the start date"
                      )
                    );
                  }
                },
              }),
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD"
              // disabledDate={(current) => {
              //   let customDate = moment().format("YYYY-MM-DD");
              //   return current && current < moment(customDate, "YYYY-MM-DD");
              // }}
            />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24}>
          <Form.Item
            label={"Service Name (EN)"}
            name={"name"}
            rules={[
              {
                required: true,
                message: "Field is required",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter Service Name (EN)"></Input>
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24}>
          <Form.Item
            label={"Service Name (AR)"}
            name={"nameAr"}
            rules={[
              {
                required: true,
                message: "Field is required",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter Service Name (AR)" dir="rtl"></Input>
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Form.Item label={"Allow Application"}>
            <Select
              placeholder="Allow Application"
              mode="multiple"
              value={
                selectedStatuses.length > 0
                  ? selectedStatuses
                      .map((item) => item.internalStatus)
                      ?.join(", ")
                  : []
              }
              onChange={handleSelectApplicationStatuses}
            >
              <Option key="all" value="all">
                Select All
              </Option>

              {applicationStatuses.map((el, i) => (
                <Option value={el.id} key={i}>
                  <Checkbox
                    checked={
                      selectedStatuses.findIndex((i) => i.code === el.code) > -1
                    }
                    onChange={(e) => {
                      setSelectedStatuses(
                        e.target.checked
                          ? [...selectedStatuses, el]
                          : selectedStatuses.filter((i) => i.code !== el.code)
                      );

                      props.setAllowedStatuses(
                        e.target.checked
                          ? [...selectedStatuses, el]
                          : selectedStatuses.filter((i) => i.code !== el.code)
                      );
                    }}
                    style={{ width: "100%" }}
                  >
                    {el.internalStatus}
                  </Checkbox>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {selectedApplicationType === "ASR" && (
          <Col lg={12} md={12} sm={24}>
            <Form.Item
              name={"itemTypes"}
              label={"Item Types"}
              rules={[
                {
                  required: true,
                  message: "Item type is required",
                },
              ]}
            >
              <Select
                placeholder={"Select item types"}
                onClick={
                  props.valuesForEdit && !allowItemTypeChange
                    ? () => {
                        if (!allowItemTypeChange) {
                          setShowItemTypeConfirmation(true);
                        }
                      }
                    : () => null
                }
                open={
                  props.valuesForEdit && !allowItemTypeChange
                    ? allowItemTypeChange
                    : undefined
                }
              >
                {_serviceItemTypes.map((el, index) => (
                  <Option value={el.value} key={index}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {selectedCategory === "paid" && (
          <>
            {selectedApplicationType === "ENR" && (
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  label={"Service Id (Application Fees)"}
                  name={"applicationFeeServiceId"}
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Application Fees Service Id"></Input>
                </Form.Item>
              </Col>
            )}
            {
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  label={
                    selectedApplicationType === "ENR"
                      ? "Service Id (Registration Fees)"
                      : "Service Id"
                  }
                  name={"feeServiceId"}
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder={
                      selectedApplicationType === "ENR"
                        ? "Registration Fees Service Id"
                        : "Service Id"
                    }
                  ></Input>
                </Form.Item>
              </Col>
            }
            {(selectedApplicationType === "ENR" ||
              selectedApplicationType === "ASR" ||
              selectedApplicationType === "TRP") && (
              <Col sm={24}>
                <Form.Item name={"allowAmountUpdate"} valuePropName={"checked"}>
                  <Checkbox defaultChecked={false}>
                    Allow Service Amount Update
                  </Checkbox>
                </Form.Item>
              </Col>
            )}
          </>
        )}
      </Row>

      {(selectedApplicationType === "SCH" ||
        selectedApplicationType === "TCC") && (
        <>
          <Row gutter={[10, 10]}>
            <Col lg={12} md={12} sm={24}>
              <Form.Item label={"Available Campuses"}>
                <Select
                  placeholder="Select campus"
                  mode="multiple"
                  value={
                    selectedCampuses.length > 0
                      ? selectedCampuses.map((item) => item.code)?.join(", ")
                      : []
                  }
                  onChange={handleSelectAllCampus}
                >
                  <Option key="all" value="all">
                    Select All
                  </Option>

                  {_campuses.map((el, i) => (
                    <Option value={el.id} key={i}>
                      <Checkbox
                        checked={
                          selectedCampuses.findIndex((i) => i.id === el.id) > -1
                        }
                        onChange={(e) => {
                          setSelectedCampuses(
                            e.target.checked
                              ? [...selectedCampuses, el]
                              : selectedCampuses.filter((i) => i.id !== el.id)
                          );
                          props.setAvailableCampuses(
                            e.target.checked
                              ? [...selectedCampuses, el]
                              : selectedCampuses.filter((i) => i.id !== el.id)
                          );
                        }}
                      >
                        {el.code}
                      </Checkbox>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {selectedApplicationType === "SCH" ? (
              <Col lg={12} md={12} sm={24}>
                <Form.Item label={"Available Grades"}>
                  <Select
                    placeholder="Select grade"
                    mode="multiple"
                    value={
                      selectedGrades.length > 0
                        ? selectedGrades.map((item) => item.code)?.join(", ")
                        : []
                    }
                    onChange={handleSelectAllGrades}
                  >
                    <Option key="all" value="all">
                      Select All
                    </Option>

                    {_grades.map((el, i) => (
                      <Option value={el.id} key={i}>
                        <Checkbox
                          checked={
                            selectedGrades.findIndex((i) => i.id === el.id) > -1
                          }
                          onChange={(e) => {
                            setSelectedGrades(
                              e.target.checked
                                ? [...selectedGrades, el]
                                : selectedGrades.filter((i) => i.id !== el.id)
                            );

                            props.setAvailableGrades(
                              e.target.checked
                                ? [...selectedGrades, el]
                                : selectedGrades.filter((i) => i.id !== el.id)
                            );
                          }}
                          style={{ width: "100%" }}
                        >
                          {el.code}
                        </Checkbox>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <Col lg={12} md={12} sm={24}>
                <Form.Item label={"Allowed Grades"}>
                  <Select
                    placeholder="Select Grade"
                    mode="multiple"
                    value={
                      selectedAllowedTCCGrades.length > 0
                        ? selectedAllowedTCCGrades
                            .map((item) => item.code)
                            ?.join(", ")
                        : []
                    }
                    onChange={handleSelectAllowedTCCGrade}
                  >
                    <Option key="all" value="all">
                      Select All
                    </Option>

                    {_grades.map((el, i) => (
                      <Option value={el.id} key={i}>
                        <Checkbox
                          checked={
                            selectedAllowedTCCGrades.findIndex(
                              (i) => i.id === el.id
                            ) > -1
                          }
                          onChange={(e) => {
                            setSelectedAllowedTCCGrades(
                              e.target.checked
                                ? [...selectedAllowedTCCGrades, el]
                                : selectedAllowedTCCGrades.filter(
                                    (i) => i.id !== el.id
                                  )
                            );

                            // props.setAllowedTCCGrades(
                            //   e.target.checked
                            //     ? [...selectedAllowedTCCGrades, el]
                            //     : selectedAllowedTCCGrades.filter((i) => i.id !== el.id)
                            // );
                          }}
                          style={{ width: "100%" }}
                        >
                          {el.code}
                        </Checkbox>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          {selectedApplicationType === "SCH" && (
            <Row gutter={10}>
              <Col sm={12}>
                <Form.Item
                  label={
                    <div>
                      Applicable Evaluations
                      <div className="sub-description">
                        (Select which evaluation criteria are applicable for
                        this application)
                      </div>
                    </div>
                  }
                >
                  <Select
                    placeholder="Select Evaluation"
                    mode="multiple"
                    value={
                      selectedApplicableEvaluation.length > 0
                        ? selectedApplicableEvaluation
                            .map((item) => item.title)
                            ?.join(", ")
                        : []
                    }
                    onChange={handleSelectAllEvaluationApplied}
                  >
                    <Option key="all" value="all">
                      Select All
                    </Option>

                    {evaluationPointsConfig.map((el, i) => (
                      <Option value={el.value} key={i}>
                        <Checkbox
                          checked={
                            selectedApplicableEvaluation.findIndex(
                              (i) => i.value === el.value
                            ) > -1
                          }
                          onChange={(e) => {
                            setSelectedApplicableEvaluation(
                              e.target.checked
                                ? [...selectedApplicableEvaluation, el]
                                : selectedApplicableEvaluation.filter(
                                    (i) => i.value !== el.value
                                  )
                            );

                            props.setApplicableEvaluation(
                              e.target.checked
                                ? [...selectedApplicableEvaluation, el]
                                : selectedApplicableEvaluation.filter(
                                    (i) => i.value !== el.value
                                  )
                            );
                          }}
                          style={{ width: "100%" }}
                        >
                          {el.title}
                        </Checkbox>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  label={
                    <div>
                      Visible Evaluation Fields
                      <div className="sub-description">
                        (Select which evaluation criteria fields will be shown
                        to parents while submitting the application)
                      </div>
                    </div>
                  }
                >
                  <Select
                    placeholder="Select Evaluation"
                    mode="multiple"
                    value={
                      selectedVisibleEvaluationFields.length > 0
                        ? selectedVisibleEvaluationFields
                            .map((item) => item.title)
                            ?.join(", ")
                        : []
                    }
                    onChange={handleSelectAllVisibleEvaluationFields}
                  >
                    <Option key="all" value="all">
                      Select All
                    </Option>

                    {evaluationPointsConfig
                      .filter((eva) => eva.value !== "personalInterview")
                      .map((el, i) => (
                        <Option value={el.value} key={i}>
                          <Checkbox
                            checked={
                              selectedVisibleEvaluationFields.findIndex(
                                (i) => i.value === el.value
                              ) > -1
                            }
                            onChange={(e) => {
                              setSelectedVisibleEvaluationFields(
                                e.target.checked
                                  ? [...selectedVisibleEvaluationFields, el]
                                  : selectedVisibleEvaluationFields.filter(
                                      (i) => i.value !== el.value
                                    )
                              );

                              props.setVisibleEvaluationfields(
                                e.target.checked
                                  ? [...selectedVisibleEvaluationFields, el]
                                  : selectedVisibleEvaluationFields.filter(
                                      (i) => i.value !== el.value
                                    )
                              );
                            }}
                            style={{ width: "100%" }}
                          >
                            {el.title}
                          </Checkbox>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Divider />
          {selectedApplicationType === "SCH" && (
            <Row>
              <Col sm={24}>
                <Form.Item
                  name={"perFamilyOneAllowed"}
                  valuePropName={"checked"}
                >
                  <Checkbox defaultChecked={false}>
                    Allow one application per family ?
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Divider />

          {selectedApplicationType === "SCH" && (
            <>
              <b>Scholarship Rewards</b>
              <br />
              {props._scholarshipTypes.map((schType, index) => {
                return (
                  <>
                    <Divider key={index} />
                    <p
                      style={{ paddingBottom: -10 }}
                      className="tcc_reson_tag"
                      key={index}
                    >
                      {schType.title}
                    </p>
                    <Row gutter={[10, 10]} key={schType.id}>
                      {props.rewardsData.map((reward, index) => {
                        return (
                          <Col md={8} key={index}>
                            <Form.Item
                              label={reward.title}
                              name={`totalSeats_${schType.id}_${reward.id}`}
                            >
                              <Input
                                placeholder="Total Seats"
                                type="number"
                                min={0}
                              ></Input>
                            </Form.Item>
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                );
              })}
            </>
          )}
        </>
      )}

      {/* allowed grades  start */}
      {selectedApplicationType === "TCC" && (
        <>
          {selectedAllowedTCCGrades.map((allowGrade, index) => {
            return (
              <Row gutter={[10, 10]} key={index}>
                <Col lg={8} md={8} sm={24}>
                  <Form.Item label={"Grade"} name={`gradeId_${allowGrade.id}`}>
                    <Input type="text" placeholder={allowGrade.code} disabled />
                  </Form.Item>
                </Col>
                <Col lg={8} md={8} sm={24}>
                  <Form.Item
                    label={"No Of Main Courses"}
                    name={`mainCourses_${allowGrade.id}`}
                    rules={[
                      {
                        required: true,
                        message: "Field is required",
                      },
                    ]}
                  >
                    <Input type="number" min={0} />
                  </Form.Item>
                </Col>
                <Col lg={8} md={8} sm={24}>
                  <Form.Item
                    label={"No Of Alternative Courses"}
                    name={`alternativeCourses_${allowGrade.id}`}
                    rules={[
                      {
                        required: true,
                        message: "Field is required",
                      },
                    ]}
                  >
                    <Input type="number" min={0} />
                  </Form.Item>
                </Col>
              </Row>
            );
          })}
        </>
      )}
      {/* allowed grades  end*/}
      <Row>
        {selectedApplicationType === "TCC" && (
          <Col sm={24}>
            <Form.Item name={"allowGuest"} valuePropName={"checked"}>
              <Checkbox defaultChecked={false}>Allow Guest ?</Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row>
        {selectedApplicationType === "TCC" && (
          <Col sm={24}>
            <Form.Item
              name={"scheduleSelectionRequired"}
              valuePropName={"checked"}
            >
              <Checkbox defaultChecked={false}>
                Schedule Selection Required
              </Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[10, 10]}>
        {selectedApplicationType === "ASR" && (
          <Col sm={24}>
            <Form.Item name={"hideOnDashboard"} valuePropName={"checked"}>
              <Checkbox defaultChecked={false}>Hide on Dashboard</Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row>
        {selectedApplicationType === "ASR" && (
          <Col lg={12} md={12} sm={24}>
            <Form.Item name={"autoApproval"} valuePropName={"checked"}>
              <Checkbox defaultChecked={false}>Enable Auto Approval</Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>
      {selectedApplicationType === "ASR" && (
        <Row gutter={[10, 10]}>
          <Col lg={12} md={12} sm={24}>
            <Form.Item name={"allowEmployeeDiscount"} valuePropName={"checked"}>
              <Checkbox
                defaultChecked={false}
                onChange={(e) => setEmployeeDiscount(e.target.checked)}
              >
                Enable Employee Discount
              </Checkbox>
            </Form.Item>
          </Col>

          {employeeDiscount ? (
            <>
              <Col>
                <Form.Item
                  // key={record.id}
                  // name={[`gradeId${record.id}`, "type"]}
                  // label={"Discount Type"}
                  name={"discountType"}
                  style={{ padding: 0, margin: 0 }}
                  rules={[
                    {
                      message: "Discount type is required",
                      required: true,
                    },
                  ]}
                  initialValue={
                    _discountTypeOptions?.find(
                      (type) => type?.value === "percentage"
                    )?.value
                  }
                >
                  <Select
                    placeholder={"Select Discount Type"}
                    defaultValue={
                      _discountTypeOptions?.find(
                        (type) => type?.value === "percentage"
                      )?.value
                    }
                  >
                    {_discountTypeOptions?.map((type, index) => (
                      <Option key={index} value={type?.value}>
                        {type?.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  // label={"Discount Value"}
                  name={"discountValue"}
                  style={{ padding: 0, margin: 0 }}
                  rules={[
                    {
                      message: "Discount value is required",
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Enter Discount Value"
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}
        </Row>
      )}

      <Row>
        {selectedApplicationType === "TRP" && (
          <Col sm={24}>
            <Form.Item name={"allocateBus"} valuePropName={"checked"}>
              <Checkbox defaultChecked={false}>Enable Allocate Bus</Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>

      <Divider />
      <Form.Item
        label={"Terms & Conditions"}
        name={"terms"}
        valuePropName={"data"}
        getValueFromEvent={(event, editor) => (editor ? editor.getData() : "")}
      >
        <CKEditor
          editor={ClassicEditor}
          config={
            {
              //toolbar: EditorToolbar,
            }
          }
          key={"requestTypeTC"}
        />
      </Form.Item>

      <Form.Item
        label={"Terms & Conditions (AR)"}
        name={"termsAr"}
        valuePropName={"data"}
        getValueFromEvent={(event, editor) => (editor ? editor.getData() : "")}
      >
        <CKEditor
          config={{
            language: "ar",
            //toolbar: EditorToolbar,
          }}
          editor={ClassicEditor}
          key={"requestTypeTCAR"}
        />
      </Form.Item>

      <Form.Item
        label={"Guidelines"}
        name={"guidelines"}
        valuePropName={"data"}
        getValueFromEvent={(event, editor) => (editor ? editor.getData() : "")}
      >
        <CKEditor
          config={
            {
              //toolbar: EditorToolbar,
            }
          }
          editor={ClassicEditor}
          key={"requestTypeGuidelines"}
        />
      </Form.Item>

      <Form.Item
        label={"Guidelines (AR)"}
        name={"guidelinesAr"}
        valuePropName={"data"}
        getValueFromEvent={(event, editor) => (editor ? editor.getData() : "")}
      >
        <CKEditor
          config={{
            language: "ar",
            //toolbar: EditorToolbar,
          }}
          editor={ClassicEditor}
          key={"requestTypeGuidelinesAR"}
        />
      </Form.Item>

      <Row justify="end">
        <Button loading={props.loading} htmlType="submit">
          {props.loading ? "Saving...." : "Save"}
        </Button>
      </Row>

      <CustomModal
        visibility={showItemTypeConfirmation}
        handleCancel={() => setShowItemTypeConfirmation(false)}
        handleOk={() => setShowItemTypeConfirmation(false)}
        title={"Confirmation"}
        modalSmall={true}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          All items in this eservice will be deleted, are you sure you want to
          change the item type?
        </p>
        <Row
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Button
            type={"primary"}
            onClick={() => {
              setShowItemTypeConfirmation(false);
              setTimeout(() => setAllowItemTypeChange(true), 200);
            }}
          >
            Yes
          </Button>
          <Button
            type={"primary"}
            onClick={() => setShowItemTypeConfirmation(false)}
            style={{ marginLeft: 10 }}
          >
            No
          </Button>
        </Row>
      </CustomModal>
    </div>
  );
}

export default EServicesForm;
