import { Card, Spin } from "antd";
import React, { useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import SccHeader from "../scc-header/SccHeader";
import SccConductCaseDetails from "./SccConductCaseDetails";
import SccConductCaseLogs from "./SccConductCaseLogs";
import SccTabs from "./SccTabs";

const SccAcknowledgement = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const renderCaseView = (tab) => {
    switch (tab) {
      case 0:
        return <SccConductCaseDetails caseDetails={props.conductCaseDetails} />;
        break;
      case 1:
        return <SccConductCaseLogs caseId={props.conductCaseDetails?.id} />;
      default:
        break;
    }
  };

  return (
    <div>
      <Spin spinning={false} indicator={renderLoader}>
        <SccHeader
          conductCaseDetails={props.conductCaseDetails}
          refetch={props.refetch}
          finalApproval={false}
        />

        <SccTabs
          selectedTab={selectedTab}
          onLogsTab={() => setSelectedTab(1)}
          onDetialsTab={() => setSelectedTab(0)}
        />
        <div className="scc_general_tabs_container">
          {renderCaseView(selectedTab)}
        </div>
      </Spin>
    </div>
  );
};

export default SccAcknowledgement;
