import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  TimePicker,
  DatePicker,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";

import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useSelector } from "react-redux";
import { fetchAllFsmFacilities } from "../all-facilities/api";
const { Option } = Select;
const FacilityScheduleForm = ({
  onSubmit,
  loading,
  formName,
  daysOfWeek,
  valuesForEdit,
  facilities,
}) => {
  const [enabledFields, setEnabledFields] = useState(Array(7).fill(false));
  // const [minSteps, setMinSteps] = useState("");
  const [minSteps, setMinSteps] = useState(Array(7).fill(""));

  useEffect(() => {
    // Check if valuesForEdit is available and contains the 'days' array
    if (valuesForEdit && Array.isArray(valuesForEdit.days)) {
      const updatedEnabledFields = Array(7).fill(false);
      valuesForEdit.days.forEach((dayData) => {
        // Check if the day exists in the valuesForEdit data
        const dayIndex = daysOfWeek.findIndex(
          (day) => day.slice(0, 3).toLowerCase() === dayData.day
        );

        // Enable the fields for this day
        if (dayIndex !== -1) {
          updatedEnabledFields[dayIndex] = true;
        }
      });
      // Debugging: Print relevant values
      setEnabledFields(updatedEnabledFields);
    } else {
      // Set all fields to false if valuesForEdit is not as expected
      setEnabledFields(Array(7).fill(false));
    }
  }, [valuesForEdit, daysOfWeek]);

  const toggleFields = (index, value) => {
    const updatedEnabledFields = [...enabledFields];
    updatedEnabledFields[index] = value;
    setEnabledFields(updatedEnabledFields);
  };

  const disabledEndTime = (current, index) => {
    const startTimeValue = formName.getFieldValue(`startTime[${index}]`);

    if (!current || !startTimeValue) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    }

    const selectedStartTime = moment(startTimeValue);

    const disabledHours = () => {
      const hours = [];
      for (let i = 0; i <= selectedStartTime.hours(); i++) {
        hours.push(i);
      }
      return hours;
    };

    const disabledMinutes = (selectedHour) => {
      if (selectedHour === selectedStartTime.hours()) {
        const minutes = [];
        for (let i = 0; i < selectedStartTime.minutes(); i++) {
          minutes.push(i);
        }
        return minutes;
      }
      return [];
    };

    const disabledSeconds = (selectedHour, selectedMinute) => {
      if (
        selectedHour === selectedStartTime.hours() &&
        selectedMinute === selectedStartTime.minutes()
      ) {
        return [0, 1, 2, /*...*/ 59];
      }
      return [];
    };

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
      hideDisabledOptions: true,
    };
  };
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const handleDurationChange = (value, index) => {
    const updatedMinSteps = [...minSteps];
    updatedMinSteps[index] = value === "1 hour" ? 60 : 30;
    setMinSteps(updatedMinSteps);
    formName.setFieldsValue({
      [`startTime[${index}]`]: null,
      [`endTime[${index}]`]: null,
    });
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <div>
          <Row gutter={10}>
            <Col md={24}>
              <Form.Item
                name="facilityId"
                rules={setRules("Facility")}
                label="Select Facility"
              >
                <Select placeholder="Select Facility">
                  {facilities?.map((el, i) => (
                    <Option key={i} value={el.id}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col md={12}>
              <p>Start Date</p>
              <Form.Item
                name={"startAt"}
                rules={[
                  {
                    required: true,
                    message: `Start date is required`,
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Date"
                  disabledDate={(currentDate) => {
                    // Disable dates before today
                    return currentDate && currentDate < moment().startOf("day");
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <p>End Date</p>
              <Form.Item
                name={"endAt"}
                rules={[
                  {
                    required: true,
                    message: `End date is required`,
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Date"
                  disabledDate={(currentDate) => {
                    // Disable dates before today and the same as the start date
                    const scheduleStartAt = formName.getFieldValue("startAt");
                    return (
                      currentDate &&
                      (currentDate < moment().startOf("day") ||
                        (scheduleStartAt &&
                          (currentDate.isSameOrBefore(scheduleStartAt, "day") ||
                            currentDate.year() < scheduleStartAt.year())))
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          {daysOfWeek.map((day, index) => (
            <Form.Item key={index}>
              <Row gutter={8}>
                <Col md={6}>
                  <Form.Item
                    name={`day[${index}]`}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      onChange={(e) => toggleFields(index, e.target.checked)}
                    >
                      {day}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    name={`duration[${index}]`}
                    rules={[
                      {
                        required: enabledFields[index],
                        message: `Duration is required`,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Slot Duration"
                      disabled={!enabledFields[index]}
                      onChange={(value) => handleDurationChange(value, index)}
                    >
                      <Option value="30">30 minutes</Option>
                      <Option value="60">1 hour </Option>
                      <Option value="90">1 hour 30 minutes</Option>
                      <Option value="120">2 hours</Option>
                      <Option value="150">2 hours 30 minutes</Option>
                      <Option value="180">3 hours</Option>
                      <Option value="210">3 hours 30 minutes</Option>
                      <Option value="240">4 hours</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    name={`startTime[${index}]`}
                    rules={[
                      {
                        required: enabledFields[index],
                        message: `Start time required`,
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%" }}
                      placeholder="Start Time"
                      format={"HH:mm"}
                      clearIcon
                      minuteStep={minSteps[index] || 30}
                      disabled={!enabledFields[index]}
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    name={`endTime[${index}]`}
                    rules={[
                      {
                        required: enabledFields[index],
                        message: `End time required`,
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%" }}
                      placeholder="End Time"
                      format={"HH:mm"}
                      clearIcon
                      disabled={!enabledFields[index]}
                      disabledTime={(current) =>
                        disabledEndTime(current, index)
                      }
                      minuteStep={minSteps[index] || 30}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          ))}
        </div>

        <br />
        <Row justify="start">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant__primary__button"
            type="primary"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default FacilityScheduleForm;
