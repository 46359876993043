import {
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Spin,
  Tooltip,
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  Input,
} from "antd";

import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { fetchAllFSMBookingsWithTypes, processBooingRequest } from "../api";
import { useSelector } from "react-redux";
import ExpandedTableRow from "../ExpandedTableRow";
import { fetchAllFsmFacilities } from "views/FSM/facility-mangement/all-facilities/api";
import { successMessage } from "utils/helpers/helpers";
import moment from "moment";

const { Option } = Select;

const PerHeadBookings = (props) => {
  const _campuses = useSelector((state) => state.metaData.campuses);
  const serviceType = props.location.state.serviceType;
  const navigationParams = props.history.location?.state;

  const _bookingStatus = useSelector(
    (state) => state.metaData.applicationStatuses
  );

  const _bookingStages = useSelector(
    (state) => state.metaData.FSM_bookingTypes
  )?.find((bookingType) => bookingType.identifier === serviceType)?.stages;

  const dashboardCampus = navigationParams?.dashboardCampus;
  const dashboardFacility = navigationParams?.dashboardFacility;
  const dashboardStage = navigationParams?.dashboardStage;
  const dashboardStageStatus = navigationParams?.dashboardStageStatus;
  const dashboardApplicationStatus =
    navigationParams?.dashboardApplicationStatus;
  const dashboardGroupStatus = navigationParams?.dashboardGroupStatus;
  const fromDashboard = navigationParams?.fromDashboard;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [filters, setFilters] = useState({
    campus: dashboardCampus ? dashboardCampus : "",
    facility: dashboardFacility ? dashboardFacility : "",
    startAt: "",
    endAt: "",
    search: fromDashboard && dashboardGroupStatus ? dashboardGroupStatus : "",
    bookingStatus: dashboardApplicationStatus ? dashboardApplicationStatus : "",
    stage: dashboardStage ? dashboardStage : "",
    stageStatus: "",
  });
  const [stageStatuses, setStageStatuses] = useState([]);

  useEffect(() => {
    if (fromDashboard) {
      const _stageStatuses = _bookingStages.find(
        (bookingStage) => bookingStage.slug === dashboardStage
      );
      if (_stageStatuses) {
        setStageStatuses(_stageStatuses?.statuses);
      }
    }
  }, []);

  useEffect(() => {
    getAllPerHeadBookings();
  }, [filters, page]);
  useEffect(() => {
    getAllFsmFacilities();
  }, []);

  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };

  const getAllPerHeadBookings = async () => {
    try {
      setLoading(true);
      const response = await fetchAllFSMBookingsWithTypes({
        page: page,
        type: serviceType,
        campus: filters.campus,
        facility: filters.facility,
        startAt: moment(filters.startAt).isValid() ? filters.startAt : "",
        endAt: moment(filters.endAt).isValid() ? filters.endAt : "",
        search: filters.search,
        stage: filters.stage,
        status: filters.bookingStatus,
        stageStatus: filters.stageStatus,
      });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onAddNewPerHeadBooking = () => {
    props.history.push({
      pathname: `add-per-head-booking`,
      //  state: { bookingId: record.id, serviceType },
    });
  };
  const onViewDetials = (record) => {
    switch (serviceType) {
      case "PHB":
        props.history.push({
          pathname: `per-head-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;

      default:
        break;
    }
  };
  const expandedRowRender = (record) => {
    return (
      <div className="card__snippet">
        {/* Additional details specific to the selected row */}
        {/* <p>Additional details for Booking ID: {record.bookingId}</p> */}
        <ExpandedTableRow record={record} serviceType={serviceType} />
      </div>
    );
  };
  // Define the expandable configuration
  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record]);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record));
    }
  };

  const deleteBookig = async (id) => {
    const payload = {
      bookingId: id,
      action: "delete",
      notes: "",
    };
    try {
      const response = await processBooingRequest(payload);
      if (response.status === 200) {
        successMessage({ message: "Booking has been deleted successfully" });
        getAllPerHeadBookings();
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const getStageStatuses = useCallback(
    (slug) => {
      const _stageStatuses = _bookingStages.find(
        (bookingStage) => bookingStage.slug === slug
      );
      if (_stageStatuses) {
        setStageStatuses(_stageStatuses?.statuses);
      }
    },
    [stageStatuses, setStageStatuses]
  );

  const handleChangeStageStatus = (slug) => {
    setFilters({ ...filters, stage: slug });
    getStageStatuses(slug);
  };

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={onAddNewPerHeadBooking}
          headerTitle={"Per Head Bookings"}
          headerSubTitle={""}
          headerBtnTitle={"Add New Booking"}
        />

        <Row gutter={10}>
          <Col md={6} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by campus"
                onChange={(e) => setFilters({ ...filters, campus: e })}
                bordered={false}
                defaultValue={filters.campus || undefined}
              >
                <Option value={""}>All</Option>
                {_campuses?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by facility"
                onChange={(e) => setFilters({ ...filters, facility: e })}
                bordered={false}
                defaultValue={filters.facility || undefined}
              >
                <Option value={""}>All</Option>
                {facilities?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Filter by start date"
                value={filters.startAt}
                onChange={(date) =>
                  setFilters({
                    ...filters,
                    startAt: moment(date).format("YYYY-MM-DD"),
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Filter by end date"
                value={filters.endAt}
                onChange={(date) =>
                  setFilters({
                    ...filters,
                    endAt: moment(date).format("YYYY-MM-DD"),
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={6} className="filterColumn">
            <Form.Item name="stage" className="dropdown">
              <Select
                placeholder="Filter by stage"
                onChange={(e) => handleChangeStageStatus(e)}
                bordered={false}
                defaultValue={filters.stage || undefined}
              >
                <Option value={""}>All Stages</Option>
                {_bookingStages?.map((el, i) => (
                  <Option key={i} value={el.slug}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by stage status"
                onChange={(e) => setFilters({ ...filters, stageStatus: e })}
                bordered={false}
                defaultValue={filters.stageStatus || undefined}
              >
                <Option value={""}>All</Option>
                {stageStatuses?.map((el, i) => (
                  <Option key={i} value={el.code}>
                    {el.internalStatus}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by booking status"
                onChange={(e) => setFilters({ ...filters, bookingStatus: e })}
                bordered={false}
                defaultValue={filters.bookingStatus || undefined}
              >
                <Option value={""}>All Status</Option>
                {_bookingStatus?.map((el, i) => (
                  <Option key={i} value={el.code}>
                    {el.externalStatus}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="search" className="dropdown">
              <Input
                placeholder="Search"
                defaultValue={filters.search}
                value={filters.search}
                onChange={(e) =>
                  setFilters({ ...filters, search: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          expandable={{
            expandedRowRender: expandedRowRender,
            expandedRowKeys: expandedRowKeys,
            onExpand: onExpand,
          }}
          columns={[
            {
              title: "Booking ID",
              dataIndex: "title",
              key: "title",
              render: (record) => (
                <span
                  onClick={() =>
                    onExpand(!expandedRowKeys.includes(record), record)
                  }
                >
                  <div className="row_expand_container">
                    <p style={{ color: "#007B85", padding: 0, margin: 0 }}>
                      {record}
                    </p>
                    {!expandedRowKeys.includes(record) ? (
                      <DownOutlined
                        style={{ color: "#007B85", marginLeft: 10 }}
                      />
                    ) : (
                      <UpOutlined
                        style={{ color: "#007B85", marginLeft: 10 }}
                      />
                    )}
                  </div>
                </span>
              ),
            },
            {
              title: "User",
              key: "userName",
              render: (record) => (
                <>
                  {record?.user?.firstName + " " + record?.user?.lastName}
                  <br />
                  {record?.user?.phoneNo}
                </>
              ),
            },
            {
              title: "Campus",
              key: "campus",
              render: (record) => record?.facility?.campus?.code,
            },
            {
              title: "Facility",
              key: "facility",
              render: (record) => record?.facility?.title,
            },
            {
              title: "Status",
              key: "bookingStatus",
              render: (record) => record?.bookingStatus?.internalStatus,
            },
            {
              title: "Current Stage",
              key: "currentStage",
              render: (record) => record?.currentStage?.title,
            },
            {
              title: "Current Stage Status",
              key: "currentStageStatus",
              render: (record) => record?.currentStage?.status?.internalStatus,
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="View Details">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EyeOutlined />}
                          onClick={() => onViewDetials(item)}
                        />
                      </Tooltip>
                    </div>
                    {item?.hasPermission && (
                      <Popconfirm
                        title="Are you sure you want to delete this?"
                        okText="Yes"
                        cancelText="No"
                        style={{ marginLeft: "30px" }}
                        onConfirm={() => deleteBookig(item.id)}
                      >
                        <Tooltip title="Delete">
                          <Button
                            className="d-flex justify-content-center align-items-center"
                            shape="circle"
                            icon={<DeleteOutlined />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    )}
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
      </Spin>
    </div>
  );
};

export default PerHeadBookings;
