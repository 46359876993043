import client from "apis/config";

export const getAnnouncementAPI = async (page) => {
  return await client.get(`/announcements?page=${page}&limit=10`);
};

export const addAnnouncementAPI = async (newAnnouncement) => {
  return await client.post("/announcements", newAnnouncement);
};
export const editAnnouncementAPI = async (updatedAnnouncement) => {
  return await client.put(`/announcements/${updatedAnnouncement.id}`, updatedAnnouncement);
};

export const deleteAnnouncementApi = async (announcementId) => {
  return await client.delete(`/announcements/${announcementId}`);
};

