import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Form, Popconfirm, Row, Spin, Tooltip } from "antd";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import AddDiscountRulesForm from "components/DiscountComponents/DiscountRulesForm/AddDiscountRulesForm";
import { getRulesAPI } from "apis/discount-rules/DiscountRulesApi";
import { _getRulesAction } from "actions/discount-rules/DiscountRulesActions";
import { addRulesAPI } from "apis/discount-rules/DiscountRulesApi";
import { _addRulesAction } from "actions/discount-rules/DiscountRulesActions";
import { successMessage } from "utils/helpers/helpers";
import { Capitalize } from "utils/helpers/helpers";
import { updateRulesAPI } from "apis/discount-rules/DiscountRulesApi";
import { _updateRulesAction } from "actions/discount-rules/DiscountRulesActions";
import { deleteRulesAPI } from "apis/discount-rules/DiscountRulesApi";
import { _deleteRulesAction } from "actions/discount-rules/DiscountRulesActions";
import { useCallback } from "react";

const DiscountRules = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [showAddRuleModal, toggleAddRuleModal] = React.useState(false);
  const [addDiscountForm] = Form.useForm();
  const [editRuleId, setEditRuleId] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize] = React.useState(10);

  const _rules = useSelector((state) => state.rules.data);
  const _otherRules = editRuleId
    ? _rules?.filter((rule) => rule.id !== editRuleId)
    : _rules;
  const _totalRecords = useSelector((state) => state.rules.dataTotal);

  const fetchRules = useCallback(async () => {
    try {
      setLoading(true);
      const paginationParams = `page=${currentPage}&limit=1000`;
      const response = await getRulesAPI(paginationParams);
      if (response.data && response.status === 200) {
        dispatch(_getRulesAction(response.data));
        setLoading(false);
      } else throw response.error;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  }, [currentPage, dispatch]);

  React.useEffect(() => {
    fetchRules();
  }, [currentPage, fetchRules]);

  const fetchRecords = (page) => {
    setCurrentPage(page);
  };

  const onAddRule = () => {
    toggleAddRuleModal(true);
    addDiscountForm.resetFields();
  };

  const onSubmitAddRule = async (values) => {
    const payload = {
      ...values,
      excludeHighestGrades: values.excludeHighestGrades ? true : false,
      isEnabled: values.isEnabled ? true : false,
    };
    setLoading(true);
    try {
      if (editRuleId) {
        const response = await updateRulesAPI(payload, editRuleId);
        if (response.status === 200) {
          dispatch(_updateRulesAction(response.data.data));
          successMessage({ message: "Rule updated successfully" });
          setLoading(false);
          toggleAddRuleModal(false);
          setEditRuleId(undefined);
        } else {
          throw response;
        }
      } else {
        const response = await addRulesAPI(payload);
        if (response.status === 200) {
          dispatch(_addRulesAction(response.data.data));
          successMessage({ message: "Rule added successfully" });
          setLoading(false);
          toggleAddRuleModal(false);
        } else throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  const onEditRule = (record) => {
    addDiscountForm.setFieldsValue(record);
    setEditRuleId(record.id);
    toggleAddRuleModal(true);
  };
  const onDelete = async (record) => {
    try {
      setLoading(true);
      const response = await deleteRulesAPI(record.id);
      if (response.status === 200) {
        dispatch(_deleteRulesAction(record.id));
        setLoading(false);
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };
  const onCloseAddModal = () => {
    setEditRuleId(undefined);
    addDiscountForm.resetFields();
    toggleAddRuleModal(false);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          key={"#"}
          data={_rules}
          header={() => (
            <TableHeader
              headerTitle={`Discount Rules`}
              headerSubTitle={""}
              headerBtnTitle="Add New"
              onAddNew={onAddRule}
            />
          )}
          columns={[
            {
              key: "id",
              title: "Title",
              dataIndex: "title",
              render: (record) => Capitalize(record),
              width: "25%",
            },
            {
              key: "postingAccount",
              title: "Posting Account",
              dataIndex: "postingAccount",
            },
            {
              key: "ruleId",
              title: "Rule Id",
              dataIndex: "ruleId",
              render: (record) => record ?? "N/A",
            },
            // {
            //   key: "id",
            //   title: "Title (AR)",
            //   dataIndex: "titleAr",
            // },
            {
              key: "id",
              title: "No Of Kids",
              dataIndex: "noOfKids",
            },
            {
              key: "id",
              title: "Grades Restrictions",
              dataIndex: "gradesRestrictions",
              render: (record) => Capitalize(record),
            },
            // {
            //   key: "id",
            //   title: "Allowed Discount",
            //   dataIndex: "allowedWithDiscounts",
            //   ellipsis: true,
            //   render: (record) =>
            //     _rules
            //       ?.filter((el) => record.includes(el.id))
            //       ?.map((i) => i.title)
            //       .join(", ") || "N/A",
            // },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <Row justify="space-evenly">
                  <Tooltip title={"Configure"}>
                    <Button
                      shape="circle"
                      icon={<SettingOutlined />}
                      onClick={() => {
                        props.history.push({
                          pathname:
                            "/admin/request-types/discount-rules/configuration",
                          state: item,
                        });
                      }}
                    />
                  </Tooltip>

                  <Tooltip title={"Edit"}>
                    <Button
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => onEditRule(item)}
                    />
                  </Tooltip>

                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button shape="circle" icon={<DeleteOutlined />} />
                    </Tooltip>
                  </Popconfirm>
                </Row>
              ),
            },
          ]}
          totalRecord={_totalRecords}
          pageSize={pageSize}
          fetchRecords={fetchRecords}
        ></CommonTable>

        <AddDiscountRulesForm
          key={"addRule"}
          visibility={showAddRuleModal}
          formName={addDiscountForm}
          onHide={onCloseAddModal}
          title={`Add Discount Rule`}
          otherRules={_otherRules}
          onSubmit={onSubmitAddRule}
          loading={loading}
        />
      </Spin>
    </div>
  );
};

export default DiscountRules;
