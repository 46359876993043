import React, { useEffect, useState } from "react";
import FSMApplicationHeader from "../facility-mangement/fsm-application-detail-header/FSMBookingHeader";
import { fetchFSMBookingsDetails } from "./api";
import { requestErrorHandel } from "utils/helpers/helpers";
import { Spin } from "antd";
import { renderLoader } from "utils/helpers/helpers";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import BookingReviewStage from "components/fsm-booking-stages/review-stage/BookingReviewStage";
import BookingPaymentStage from "components/fsm-booking-stages/payment-stage/BookingPaymentStage";
import BookingPreFinalApprovalStage from "components/fsm-booking-stages/pre-final-stage/BookingPreFinalApprovalStage";
import BookingFinalApprovalStage from "components/fsm-booking-stages/final-approval-stage/BookingFinalApprovalStage";
import BookingCancelAndRefundStage from "components/fsm-booking-stages/cancel-refund-stage/BookingCancelAndRefundStage";

const BookingDetails = (props) => {
  const serviceType = props.location.state.serviceType;
  const bookingId = props.location.state.bookingId;

  const [loading, setLoading] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);

  useEffect(() => {
    bookingId && getDetails();
  }, [bookingId]);

  const getDetails = async () => {
    try {
      setLoading(true);
      const response = await fetchFSMBookingsDetails({ id: bookingId });
      if (response.status === 200) {
        setBookingDetails(response.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const renderApplicationOnStageBase = (stage) => {
    let stageProps = { bookingDetails, refetch: getDetails, loading, ...props };
    switch (serviceType) {
      case "EVB":
        switch (stage) {
          case "review":
            return <BookingReviewStage {...stageProps} />;
          case "pre_final_approval":
            return <BookingPreFinalApprovalStage {...stageProps} />;
          case "final_approval":
            return <BookingFinalApprovalStage {...stageProps} />;
          case "payment":
            return <BookingPaymentStage {...stageProps} />;
          case "cancellation_and_refunds":
            return <BookingCancelAndRefundStage {...stageProps} />;
        }
        break;
      case "PHB":
        switch (stage) {
          case "review":
            return <BookingReviewStage {...stageProps} />;
          case "pre_final_approval":
            return <BookingPreFinalApprovalStage {...stageProps} />;
          case "final_approval":
            return <BookingFinalApprovalStage {...stageProps} />;
          case "payment":
            return <BookingPaymentStage {...stageProps} />;
          case "cancellation_and_refunds":
            return <BookingCancelAndRefundStage {...stageProps} />;
        }
        break;
      case "LTB":
        switch (stage) {
          case "review":
            return <BookingReviewStage {...stageProps} />;
          case "pre_final_approval":
            return <BookingPreFinalApprovalStage {...stageProps} />;
          case "final_approval":
            return <BookingFinalApprovalStage {...stageProps} />;
          case "payment":
            return <BookingPaymentStage {...stageProps} />;
          case "cancellation_and_refunds":
            return <BookingCancelAndRefundStage {...stageProps} />;
        }
        break;
      case "FFB":
        switch (stage) {
          case "review":
            return <BookingReviewStage {...stageProps} />;
          case "pre_final_approval":
            return <BookingPreFinalApprovalStage {...stageProps} />;
          case "final_approval":
            return <BookingFinalApprovalStage {...stageProps} />;
          case "payment":
            return <BookingPaymentStage {...stageProps} />;
          case "cancellation_and_refunds":
            return <BookingCancelAndRefundStage {...stageProps} />;
        }
        break;
      case "DPB":
        switch (stage) {
          case "review":
            return <BookingReviewStage {...stageProps} />;
          case "pre_final_approval":
            return <BookingPreFinalApprovalStage {...stageProps} />;
          case "final_approval":
            return <BookingFinalApprovalStage {...stageProps} />;
          case "payment":
            return <BookingPaymentStage {...stageProps} />;
          case "cancellation_and_refunds":
            return <BookingCancelAndRefundStage {...stageProps} />;
        }
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <Spin spinning={loading && !bookingDetails} indicator={renderLoader}>
        {bookingDetails
          ? renderApplicationOnStageBase(bookingDetails?.currentStage?.slug)
          : renderNoDataPlaceholder(loading)}
      </Spin>
    </div>
  );
};

export default BookingDetails;
