import client from "apis/config";

export const getMonthlyBookings = async ({
  date,
  campus = "",
  facility = "",
  status = "",
  type = "",
}) => {
  return await client.get(
    `/bookings/schedules/monthly?date=${date}&campus=${campus}&facility=${facility}&status=${status}&type=${type}`
  );
};
export const getDailyBookings = async ({
  date,
  status = "",
  campus = "",
  facility = "",
  type = "",
}) => {
  return await client.get(
    `/bookings/schedules/daily?date=${date}&campus=${campus}&status=${status}&facility=${facility}&type=${type}`
  );
};
export const getYearlyBookings = async ({
  type = "",
  year,
  campus = "",
  facility = "",
  status = "",
  startAt = "",
  endAt = "",
  page = 1,
  limit = 10,
}) => {
  return await client.get(
    `/bookings/all?type=${type}&year=${year}&campus=${campus}&facility=${facility}&status=${status}&statAt=${startAt}&endAt=${endAt}&page=${page}&limit=${limit}`
  );
};
