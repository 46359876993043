import client from "apis/config";

export const getFSMBookingsDashboardData = async ({
  campus = "",
  facility = "",
  bookingType = "",
}) => {
  return await client.get(
    `/bookings/dashboard?campus=${campus}&facility=${facility}&bookingType=${bookingType}`
  );
};
