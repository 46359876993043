import client from "apis/config";

export const getGeneralConfigurations = async () => {
  return await client.get("configurations");
};
export const addGeneralConfigurations = async ({ data }) => {
  return await client.post("configurations", data);
};

export const uploadFileToENSServer = async ({ type, data }) => {
  return await client.post(`files/upload?type=${type}`, data);
};
