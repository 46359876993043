import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  Image,
  TimePicker,
} from "antd";
import React, { useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import placeholderImage from "../../../assets/img/default.png";

import { Dayjs } from "dayjs";

import { useSelector } from "react-redux";
import moment from "moment";
const { Option } = Select;
const TCCScheduleForm = ({ onSubmit, loading, formName }) => {
  const _grades = useSelector((state) => state.metaData.grades);
  const days = [
    { title: "Monday", value: "MON" },
    { title: "Tuesday", value: "TUE" },
    { title: "Wednesday", value: "WED" },
    { title: "Thursday", value: "THU" },
    { title: "Friday", value: "FRI" },
    { title: "Saturday", value: "SAT" },
    { title: "Sunday", value: "SUN" },
  ];

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };
  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Title"} name={"title"} rules={setRules("Title")}>
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={24} lg={24}>
            <Form.Item
              name={"days"}
              label="Days"
              rules={setRules("Days  requred")}
            >
              <Select
                mode="multiple"
                placeholder="Select Days"
                value={days?.map((el) => el.value)}
              >
                {days?.map((day) => (
                  <Option value={day.value}>{day.title}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col lg={12}>
            <Form.Item
              name={"fromTime"}
              rules={setRules("From Time")}
              label={"From Time"}
              initialValue={moment()}
            >
              <TimePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              name={"toTime"}
              rules={setRules("To Time ")}
              label={"To Time"}
              initialValue={moment()}
            >
              <TimePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default TCCScheduleForm;
