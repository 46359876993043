import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import {
  addNewSccOffenseIndicator,
  deleteSccOffenseIndicator,
  fetchAllSccOffenseIndicators,
  updateSccOffenseIndicator,
} from "./api";
import SccOffenseIndicatorForm from "./SccOffenseIndicatorForm";
import { generateUniqueColumnKey } from "utils/helpers/helpers";

const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const SccOffenseIndicators = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [recordId, setRecordId] = useState("");

  const [sccBehIndicatorForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            sccBehIndicatorForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Offense Indicators"}
          headerSubTitle={"Manage Offense Indicators"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllSccOffenseIndicators();
  }, [page]);

  const getAllSccOffenseIndicators = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSccOffenseIndicators({ page: page });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addSccOffenseIndicator = async (data) => {
    try {
      setLoading(true);
      const response = await addNewSccOffenseIndicator({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Offense Indicator added successfully" });
        await getAllSccOffenseIndicators();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateSccOffenseIndicator = async (data) => {
    try {
      setLoading(true);
      const response = await updateSccOffenseIndicator({
        id: recordId,
        data: data,
      });
      if (response.status === 200) {
        successMessage({ message: "Offense Indicator updated successfully" });
        await getAllSccOffenseIndicators();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteSccOffenseIndicator = async (id) => {
    try {
      setLoading(true);
      const response = await deleteSccOffenseIndicator({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Offense Indicator deleted successfully" });
        await getAllSccOffenseIndicators();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      type: "offense",
      categoryId: Number(values?.categoryId),
      standardId: Number(values?.standardId),
      levelId: Number(values?.levelId),
      score: Number(values?.score),
      secondTimeScore: Number(values?.secondTimeScore),
      thirdTimeScore: Number(values?.thirdTimeScore),
      fourthTimeScore: Number(values?.fourthTimeScore),
    };
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateSccOffenseIndicator(data);
    } else {
      await addSccOffenseIndicator(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    sccBehIndicatorForm.setFieldsValue({
      ...record,
      categoryId: record?.categoryId,
      standardId: record?.standardId,
      levelId: record?.levelId,
    });
    setRecordId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Unique Code",
              dataIndex: "identifier",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Title",
              dataIndex: "title",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Category",
              key: generateUniqueColumnKey(),

              render: (record) => (
                <span style={{ textTransform: "capitalize" }}>
                  {record?.category?.title}
                </span>
              ),
            },
            {
              title: "Standard",
              key: generateUniqueColumnKey(),

              render: (record) => (
                <span style={{ textTransform: "capitalize" }}>
                  {record?.standard?.title}
                </span>
              ),
            },
            {
              title: "Offense Level",
              key: generateUniqueColumnKey(),

              render: (record) => (
                <span style={{ textTransform: "capitalize" }}>
                  {record?.level?.title}
                </span>
              ),
            },
            // {
            //   title: "Deduction Score",
            //   dataIndex: "Deduction",
            //   key: "Deduction",
            // },
            {
              title: "Action",
              dataIndex: "",
              key: generateUniqueColumnKey(),

              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Offense Indicator">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Offense Indicator?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteSccOffenseIndicator(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Offense Indicator`}
        >
          <SccOffenseIndicatorForm
            onSubmit={onSubmit}
            formName={sccBehIndicatorForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default SccOffenseIndicators;
