import React from "react";
import "./style.css";

import { Card, Col, Row, Button } from "antd";
import CommonTable from "components/Tables/CommonTable";
import SchFinalApprovalAddRewards from "./SchFinalApprovalAddRFewards";
import { useSelector } from "react-redux";

const SchEvaluationTabReadOnlyDetails = ({
  application,
  onSubmit,
  loading,
  handelApplicationProcess,
}) => {
  const evaluationData = application?.evaluations;

  const stageStatusCode = application?.stage?.status?.code;
  const ifSuperAdmin = useSelector(
    (state) =>
      state.login.userInfo?.hasCancelAccess ||
      state.login.userInfo?.role === "super-admin"
  );

  const renderDescriptionForEvaluation = (record) => {
    if (record && record.type === "score") {
      if (record?.valueMeta && record?.valueMeta?.scoringSystem?.title) {
        return record.valueMeta.scoringSystem.title;
      } else {
        return record.value;
      }
    } else {
      return record?.value;
    }
  };

  return (
    <div>
      {application ? (
        <Row gutter={[20, 0]}>
          <Col sm={24} md={24}>
            <Card hoverable className="antCardBorder antCard">
              <Row align="middle" justify="space-between">
                <div>
                  <h5>Application Scoring Rubric</h5>
                  <p style={{ fontSize: "12px" }}></p>
                </div>
                {ifSuperAdmin &&
                  !["SG1640", "SG1760"].includes(
                    application?.stage?.status.code
                  ) && (
                    <Button
                      style={{ marginLeft: "1rem" }}
                      type="primary"
                      className="appButton"
                      onClick={() =>
                        handelApplicationProcess("recalculate_evaluation_score")
                      }
                      disabled={false}
                    >
                      Recalculate
                    </Button>
                  )}
              </Row>

              <br />
              <Card hoverable className="antCardBorder antCard">
                <Row align="middle" justify="space-between">
                  <Col>
                    <p style={{ color: "#007B85", fontSize: 18 }}>
                      Evaluator Comment
                    </p>
                    <p>{application?.evaluationComment}</p>
                  </Col>
                </Row>
              </Card>

              <br />

              <CommonTable
                data={evaluationData}
                // fetchRecords={(page) => setPage(page)}
                // totalRecord={total}
                pagination={false}
                columns={[
                  {
                    title: "Criteria",
                    dataIndex: "title",
                    key: "title",
                    render: (record) => <p className="answer_para">{record}</p>,
                  },
                  {
                    title: "Description",
                    // dataIndex: "value",
                    key: "value",
                    render: (record) => (
                      <p className="answer_para">
                        {" "}
                        {renderDescriptionForEvaluation(record)}
                      </p>
                    ),
                  },
                  {
                    title: "Points in numbers",
                    // dataIndex: "points",
                    key: "points",
                    render: (record) => (
                      <p className="answer_para">
                        {record?.isMarked ? record?.points : "N/A"}
                      </p>
                    ),
                  },
                ]}
              ></CommonTable>
            </Card>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default SchEvaluationTabReadOnlyDetails;
