import React, { useState } from "react";
import { Button } from "antd";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import logo from "../../../../assets/img/athena.png";

const GeneratePdfButton = ({ pdfData }) => {
  const [loading, setLoading] = useState(false);

  const handleGeneratePdf = async () => {
    setLoading(true);

    try {
      const generateButton = document.getElementById("generate-button");
      const tabs = document.getElementById("tabs");
      const pdfFilters = document.getElementById("pdf-filters");
      const reportCounter = document.getElementById("report-counter");

      if (generateButton) generateButton.style.display = "none";
      if (tabs) tabs.style.display = "none";
      if (reportCounter) reportCounter.style.display = "none";
      if (pdfFilters) pdfFilters.style.marginBottom = "5px";

      // Capture screenshot using html2canvas
      const element = document.getElementById("capture");
      const canvas = await html2canvas(element);

      // Convert screenshot to base64 image data
      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      // Configure options for html2pdf
      const options = {
        margin: 10,
        filename: `${pdfData.title}.pdf`,
        image: { type: "jpeg", quality: 1.0 },
        jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      };

      // Use html2pdf to create the PDF
      const contentElement = document.createElement("div");
      contentElement.style.width = "100%";
      contentElement.style.padding = "20px";

      // Add logo in header
      const header = document.createElement("div");
      header.style.display = "flex";
      header.style.justifyContent = "flex-end";
      const logoImg = new Image();
      logoImg.src = logo;
      logoImg.style.width = "150px";
      logoImg.style.height = "auto";
      header.appendChild(logoImg);
      contentElement.appendChild(header);

      // Add title section
      const titleSection = document.createElement("div");
      const title = document.createElement("p");
      title.style.bottom = "20px";
      title.style.fontSize = "20px";
      title.textContent = pdfData.title;
      titleSection.appendChild(title);
      contentElement.appendChild(titleSection);

      // Add filters row
      const filtersRow = document.createElement("div");
      filtersRow.style.display = "flex";
      // filtersRow.style.justifyContent = "center";
      filtersRow.style.marginBottom = "5px";
      filtersRow.style.gap = "15px";
      const filters = pdfData.filters;
      const filterKeys = Object.keys(filters);

      filterKeys.forEach((key) => {
        // Create container div
        const filterContainer = document.createElement("div");
        filterContainer.style.width = "auto"; // Adjust as needed
        filterContainer.style.height = "30px";
        filterContainer.style.borderRadius = "5px";
        filterContainer.style.backgroundColor = "#f0f0f0"; // Light grey background color
        filterContainer.style.display = "inline-flex"; // Ensure inline display for horizontal alignment
        filterContainer.style.padding = "2px 10px";
        // Create span for key
        const filterKey = document.createElement("span");
        filterKey.style.color = "black"; // Black color for keys
        // filterKey.style.fontSize = "14px";
        filterKey.style.textTransform = "capitalize";
        filterKey.textContent = `${key}: `;

        filterContainer.appendChild(filterKey);

        // Create span for value
        const filterValue = document.createElement("span");
        filterValue.style.color = "#888"; // Grey color for values
        // filterValue.style.fontSize = "13px";
        filterValue.style.marginLeft = "3px";

        filterValue.textContent = filters[key] || "All";
        filterContainer.appendChild(filterValue);

        // Append container to filtersRow
        filtersRow.appendChild(filterContainer);
      });

      contentElement.appendChild(filtersRow);

      // Add cases row
      const casesRow = document.createElement("div");
      casesRow.style.display = "flex";
      casesRow.style.gap = "20px";
      casesRow.style.marginBottom = "-25px"; // Adjust margin as needed
      casesRow.style.marginTop = "15px"; // Adjust margin as needed
      // const totalPositiveCases = document.createElement("p");
      // totalPositiveCases.style.margin = "0px";
      // totalPositiveCases.style.padding = "0px";
      // totalPositiveCases.style.fontSize = "14px";
      // totalPositiveCases.textContent = `Total Positive Behaviour Cases: ${pdfData.totalPositiveCase}`;
      // casesRow.appendChild(totalPositiveCases);
      // const totalNegativeCases = document.createElement("p");
      // totalNegativeCases.style.fontSize = "14px";
      // totalNegativeCases.textContent = `Total Negative Behaviour Cases: ${pdfData.totalNegativeCase}`;
      // casesRow.appendChild(totalNegativeCases);
      // contentElement.appendChild(casesRow);

      // Add screenshot image
      const screenshotImg = document.createElement("img");
      screenshotImg.src = imgData;
      screenshotImg.style.width = "100%";
      screenshotImg.style.marginTop = "10px";
      contentElement.appendChild(screenshotImg);

      // Add footer
      const footer = document.createElement("div");
      footer.style.position = "absolute";
      footer.style.bottom = "10px";
      footer.style.marginTop = "10px";
      footer.style.right = "10px";
      footer.textContent = "ens@2024";
      contentElement.appendChild(footer);

      // Generate PDF
      html2pdf().set(options).from(contentElement).toPdf().save();

      // Restore element visibility after capturing the screenshot
      if (generateButton) generateButton.style.display = "block";
      if (tabs) tabs.style.display = "block";
      if (reportCounter) reportCounter.style.display = "block";
      if (pdfFilters) pdfFilters.style.marginBottom = "0"; // Reset margin bottom for filters

      setLoading(false);
    } catch (error) {
      console.error("Failed to generate PDF:", error);
      setLoading(false);
    }
  };

  return (
    <Button
      type="primary"
      className="ant__primary__button pdf__button"
      onClick={handleGeneratePdf}
      disabled={loading}
      id="generate-button"
    >
      {loading ? "Exporting PDF..." : "Export to PDF"}
    </Button>
  );
};

export default GeneratePdfButton;
