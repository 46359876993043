import client from "apis/config";

export const fetchAllDiscountTypes = async ({ limit = "10", page }) => {
  return await client.get(
    `/facility-discount/types?limit=${limit}&page=${page}`
  );
};

export const addNewDiscountType = async ({ data }) => {
  return await client.post("/facility-discount/types", data);
};

export const updateDiscountType = async ({ id, data }) => {
  return await client.put(`/facility-discount/types/${id}`, data);
};

export const deleteDiscountType = async ({ id }) => {
  return await client.delete(`/facility-discount/types/${id}`);
};
