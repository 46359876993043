import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import { fetchAllPlaceholders } from "apis/templates/templatesApis";
import { addNewPlaceholder } from "apis/templates/templatesApis";
import { updatePlaceholder } from "apis/templates/templatesApis";
import { deletePlaceholder } from "apis/templates/templatesApis";
import PlaceholerForm from "components/html-templates-forms/PlaceholderForm";
import { useSelector } from "react-redux";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const Placeholders = () => {
  const dropDowns = useSelector((state) => state.metaData.dropDowns);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sections, setSections] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [placeholderId, setplaceholderId] = useState("");

  const [placeholderForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            placeholderForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Placeholders"}
          headerSubTitle={"Manage Placeholders"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };

  useEffect(() => {
    getAllPlaceholders();
  }, [page]);

  const getAllPlaceholders = async () => {
    try {
      setLoading(true);
      const response = await fetchAllPlaceholders(10, page);
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addPlaceholder = async (data) => {
    try {
      setLoading(true);
      const response = await addNewPlaceholder({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Placeholder added successfully" });
        await getAllPlaceholders();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdatePlaceholder = async (data) => {
    try {
      setLoading(true);
      const response = await updatePlaceholder({
        id: placeholderId,
        data: data,
      });
      if (response.status === 200) {
        successMessage({ message: "Placeholder updated successfully" });
        await getAllPlaceholders();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeletePlaceholder = async (id) => {
    try {
      setLoading(true);
      const response = await deletePlaceholder({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Placeholder deleted successfully" });
        await getAllPlaceholders();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
    };
    if (modalTitle === ModalType.EDIT) {
      await handleUpdatePlaceholder(data);
    } else {
      await addPlaceholder(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    placeholderForm.setFieldsValue({
      ...record,
    });
    setplaceholderId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  const renderCategoryName = (record) => {
    const categoryObj = dropDowns.placeholderCategories?.find(
      (el) => el.value === record.category
    );

    return categoryObj !== undefined ? categoryObj.title : record.category;
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Label",
              dataIndex: "labelEn",
              key: "labelEn",

              // render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "Placeholder",
              dataIndex: "placeholderFor",
              key: "placeholderFor",

              // render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "Category",
              //   dataIndex: "category",
              key: "category",

              render: (record) => renderCategoryName(record),
              // <p className="answer_para">
              //   {
              //     dropDowns.placeholderCategories?.find(
              //       (el) => el.value === record.category
              //     ).title
              //   }
              // </p>
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Placeholder Header Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Placeholder?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeletePlaceholder(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Placeholder`}
        >
          <PlaceholerForm
            onSubmit={onSubmit}
            formName={placeholderForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            sections={sections}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default Placeholders;
