import { Col, Row, Form, Select, List, Button, Typography, Input } from "antd";
import { fetchAllRewards } from "apis/SCH/schRequests";
import React, { useEffect, useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";

import { useSelector } from "react-redux";
import { ENRExportFields } from "utils/constants";
import { requestErrorHandel } from "utils/helpers/helpers";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { TRPExportFields } from "utils/constants";
import { UPDExportFields } from "utils/constants";
import { TFDExportFields } from "utils/constants";
import { SCHExportFields } from "utils/constants";
import { TCCExportFields } from "utils/constants";
import { ASRExportFields } from "utils/constants";
import { exportApplicationToExcel } from "apis/applications-apis/applications";
import AllApplicationsRoutes from "routes/AllApplicationsRoutes";
import { DefaultTCCExportFields } from "utils/constants";
import { successMessage } from "utils/helpers/helpers";
import { showValidationError } from "utils/helpers/helpers";

const { Title, Text } = Typography;

const { Option } = Select;

const ExportApplicationModal = ({
  serviceType,
  schoolFilter,
  termFilter,
  formName,
  exportApplicationsfilters,
}) => {
  const filterLayout = { md: 6 };
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _terms = useSelector((state) => state.metaData.terms);
  const _grades = useSelector((state) => state.metaData.grades);
  const _allschools = useSelector((state) => state.metaData.schools);
  const [_schools, setFilteredSchools] = useState(_allschools);

  const _scholarshipTypes = useSelector(
    (state) => state.metaData.dropDowns?.scholarshipTypes
  );
  const _stages = useSelector((state) =>
    state.metaData.stages.filter(
      (stage) => stage.applicationType === serviceType
    )
  );
  const applicationStatuses = useSelector(
    (state) => state.metaData.applicationStatuses
  );
  const _asrEservices = useSelector((state) => state.metaData.asrEservices);

  const [stageStatuses, setStageStatuses] = useState([]);
  const [stageStatus, setStageStatus] = useState("");
  const [stageChanged, setStageChanged] = useState(false);
  const [eService, setEService] = useState("");
  const [rewardsTypes, setRewardsTypes] = useState([]);
  const [campus, setCampus] = useState("");
  const [stage, setStage] = useState("");
  const [defaultStageStatus, setDefaultStageStatus] = useState("");
  const [ASRItem, selectASRItem] = useState("");
  const [school, setSchool] = React.useState("");
  const [term, setTerm] = useState("");
  const [grade, setGrade] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [filterSchType, setFilterSchType] = useState("");
  const [filterSchReward, setFilterSchReward] = useState("");
  const [csvData, setCsvData] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRewardsTypes();
  }, []);
  const getRewardsTypes = async () => {
    try {
      const response = await fetchAllRewards(1000);
      if (response.status === 200) {
        setRewardsTypes(response.data.data);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const handleSelectCampus = (value) => {
    setCampus(value);
    filterSchoolsByCampus(value);
  };

  const filterSchoolsByCampus = (value) => {
    setStageStatus("");
    if (value === "") {
      setFilteredSchools(_allschools);
      return;
    }
    setFilteredSchools(
      _allschools.filter((school) => school.campusId == value)
    );
  };

  const handleSelectStage = (value) => {
    setStage(value);
    handleStagesStatuses(value);
  };

  const handleStagesStatuses = (value) => {
    setStageChanged(true);
    setStageStatus("");
    if (value === "") {
      setStageStatuses([]);
      setStageChanged(false);
      return;
    }
    const _selectedStage = _stages.find((stage) => stage.slug === value);
    setStageStatuses(_selectedStage?.statuses ?? []);
  };
  const handleSelectStageStatus = (value) => {
    setDefaultStageStatus("");
    setStageStatus(value);
  };
  const handleSelectTerm = (value) => {
    setTerm(value);
  };
  const handleSelectSchool = (value) => {
    setSchool(value);
  };
  const handleSelectGrade = (value) => {
    setGrade(value);
  };
  const handleSelectEService = (value) => {
    setEService(value);
    selectASRItem("");
  };
  const handleSelectASRItem = (value) => {
    selectASRItem(value);
  };
  const handleSelectApplicationStatus = (value) => {
    setApplicationStatus(value);
  };
  const handelSelectScholarshipType = (value) => {
    setFilterSchType(value);
  };
  const handelSelectScholarshipReward = (value) => {
    setFilterSchReward(value);
  };

  const handleItemClick = (item) => {
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.filter((prevItem) => prevItem !== item);
      return [...updatedItems, item];
    });
    setFields((prevFields) =>
      prevFields.filter((prevItem) => prevItem !== item)
    );
  };

  const handleRemoveItemClick = (item) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.filter((prevItem) => prevItem !== item);
      return [...updatedFields, item];
    });
    setSelectedItems((prevItems) =>
      prevItems.filter((prevItem) => prevItem !== item)
    );
  };

  useEffect(() => {
    checkServiceTypeAndSetTheFieldsInState();
  }, []);
  const checkServiceTypeAndSetTheFieldsInState = () => {
    if (serviceType === "ENR") {
      setFields(ENRExportFields);
    } else if (serviceType === "TRP") {
      setFields(TRPExportFields);
    } else if (serviceType === "UPD") {
      setFields(UPDExportFields);
    } else if (serviceType === "TFD") {
      setFields(TFDExportFields);
    } else if (serviceType === "SCH") {
      setFields(SCHExportFields);
    } else if (serviceType === "TCC") {
      // const filteredFields = TCCExportFields.filter(
      //   (field) =>
      //     !DefaultTCCExportFields.some(
      //       (defaultField) => defaultField.value == field.value
      //     )
      // );
      setFields(TCCExportFields);
      // setSelectedItems(DefaultTCCExportFields);
    } else {
      setFields(ASRExportFields);
    }
  };
  const submitForExportToExcel = async () => {
    try {
      if (selectedItems.length === 0) {
        showValidationError("Fields must contain at least 1 element");
        return;
      }
      const data = {
        applicationType: serviceType,
        term: term.toString(),
        campus: Number(campus),
        school: Number(school),
        grade: Number(grade),
        stage: stage,
        stageStatus: stageStatus,
        status: applicationStatus,
        eService: eService,
        item: ASRItem,
        scholarshipType: Number(filterSchType),
        rewardId: Number(filterSchReward),
        fields: selectedItems.map((item) => item.value),
      };

      setLoading(true);
      const response = await exportApplicationToExcel({ data });
      if (response.status === 200) {
        if (response.data?.data.length === 0) {
          successMessage({ message: "No Application found" });
          setLoading(false);
        } else {
          setCsvData(response.data?.data);
          successMessage({ message: "Application exported successfully" });

          setLoading(false);
        }

        setCsvData(response.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const exportApplicationsModalTitle = () => {
    const _eService = AllApplicationsRoutes[0].subMenu.find(
      (item) => item.state.serviceType === serviceType
    );
    return `${_eService?.sideMenuTitle}.csv`;
  };

  useEffect(() => {
    if (
      exportApplicationsfilters !== undefined &&
      exportApplicationsfilters !== null
    ) {
      setSchool(exportApplicationsfilters?.school);
      setTerm(exportApplicationsfilters?.term);
      setGrade(exportApplicationsfilters?.grade);
      setStage(exportApplicationsfilters?.stage);
      setStageStatus(exportApplicationsfilters?.stageStatus);
      setCampus(exportApplicationsfilters?.campus);
      setFilterSchReward(exportApplicationsfilters?.filterSchReward);
      setFilterSchType(exportApplicationsfilters?.filterSchType);
      setApplicationStatus(exportApplicationsfilters?.applicationStatus);
      setStageStatuses(exportApplicationsfilters?.stageStatuses);
      setEService(exportApplicationsfilters?.eService);
      selectASRItem(exportApplicationsfilters?.ASRItem);
    }
  }, [exportApplicationsfilters]);

  return (
    <div>
      <Form form={formName}>
        <Row gutter={[10, 0]} align="middle">
          {
            <>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Campus"
                    onChange={handleSelectCampus}
                    bordered={false}
                    value={campus || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_campuses?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.code}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Term"
                    onChange={handleSelectTerm}
                    bordered={false}
                    value={term || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_terms?.map((el, i) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {serviceType !== "SCH" && (
                <Col md={6} className="filterColumn">
                  <Form.Item className="dropdown">
                    <Select
                      placeholder="Filter By School"
                      onChange={handleSelectSchool}
                      bordered={false}
                      value={school || undefined}
                    >
                      <Option value={""}>All</Option>
                      {_schools?.map((el, i) => (
                        <Option key={i} value={`${el.id}`}>
                          {el.abbreviation}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Grade"
                    onChange={handleSelectGrade}
                    bordered={false}
                    value={grade || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_grades?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.code}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          }
          {serviceType === "ASR" && (
            <>
              <Col {...filterLayout} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By E-Service"
                    onChange={handleSelectEService}
                    value={eService || undefined}
                    bordered={false}
                  >
                    <Option value={""}>All Additional Services</Option>
                    {_asrEservices?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col {...filterLayout} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Service Item"
                    onChange={handleSelectASRItem}
                    defaultValue={ASRItem}
                    value={ASRItem}
                    bordered={false}
                  >
                    <Option value={""}>All Service Items</Option>
                    {_asrEservices
                      ?.find((i) => i.id === eService)
                      ?.items?.map((el) => (
                        <Option key={el.id} value={el.id}>
                          {el.title}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
          <Col {...filterLayout} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter By Stage"
                onChange={handleSelectStage}
                // defaultValue={stage ? stage : "All Stages"}
                bordered={false}
                value={stage || undefined}
              >
                <Option value={""}>All Stages</Option>
                {_stages?.map((el, i) => (
                  <Option key={i} value={el.slug}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...filterLayout} className="filterColumn">
            {stageChanged ? (
              <Form.Item className="dropdown">
                <Select
                  onChange={handleSelectStageStatus}
                  placeholder="Filter By Stage Status"
                  value={stageStatus || undefined}
                  bordered={false}
                >
                  <Option value={""}>All</Option>
                  {stageStatuses?.map((el, i) => (
                    <Option key={i} value={el.code}>
                      {el.status}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item className="dropdown">
                <Select
                  onChange={handleSelectStageStatus}
                  placeholder="Filter By Stage Status"
                  bordered={false}
                  value={stageStatus || undefined}
                >
                  <Option value={""}>All</Option>
                  {stageStatuses?.map((el, i) => (
                    <Option key={i} value={el.code}>
                      {el.status}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col {...filterLayout} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter By Application Status"
                onChange={handleSelectApplicationStatus}
                // defaultValue={"All Applications"}
                bordered={false}
                value={applicationStatus}
              >
                <Option value={""}>All Applications</Option>
                {applicationStatuses?.map((el, i) => (
                  <Option key={i} value={`${el.code}`}>
                    {el.internalStatus}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {serviceType === "SCH" && (
            <>
              <Col {...filterLayout} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Scholarship Type"
                    onChange={handelSelectScholarshipType}
                    bordered={false}
                    value={filterSchType || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_scholarshipTypes?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col {...filterLayout} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Scholarship Reward"
                    onChange={handelSelectScholarshipReward}
                    bordered={false}
                    value={filterSchReward || undefined}
                  >
                    <Option value={""}>All</Option>
                    {rewardsTypes?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
      <Row gutter={[16, 16]}>
        <Col md={12} sm={24}>
          <div style={{ backgroundColor: "#F7F7F7" }}>
            <div
              style={{
                backgroundColor: "#007B85",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#FFF", padding: 0, margin: 0 }}>
                Available Fields
              </p>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <List
                dataSource={fields}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="text"
                        onClick={() => handleItemClick(item)}
                        icon={<ArrowRightOutlined />}
                      />,
                    ]}
                  >
                    {item.title}
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Col>
        <Col md={12} sm={24}>
          <div style={{ backgroundColor: "#F7F7F7" }}>
            <div
              style={{
                backgroundColor: "#007B85",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#FFF", padding: 0, margin: 0 }}>
                Selected Fields
              </p>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <List
                dataSource={selectedItems}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="text"
                        onClick={() => handleRemoveItemClick(item)}
                        icon={<ArrowLeftOutlined />}
                      />,
                    ]}
                  >
                    {item.title}
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <Row justify={"end"} gutter={[20, 10]}>
        <Button type="primary" style={{ marginRight: 20 }}>
          {csvData.length > 0 && (
            <CSVLink
              data={csvData}
              filename={exportApplicationsModalTitle()}
              target="_blank"
            >
              Download
            </CSVLink>
          )}
        </Button>
        <Button type="primary" onClick={submitForExportToExcel}>
          {loading ? "Exporting..." : "Export to Excel"}
        </Button>
      </Row>
    </div>
  );
};
export default ExportApplicationModal;
