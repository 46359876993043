import client from "apis/config";

export const getServiceItemsApi = async () => {
  return await client.get(`/eservices/items?limit=1000&page=1`);
};

export const addServiceItemsApi = async (data) => {
  return await client.post(`/eservices/items`, data);
};

export const updateServiceItemsApi = async (id, data) => {
  return await client.put(`/eservices/items/${id}`, data);
};

export const deleteServiceItemsApi = async (id) => {
  return await client.delete(`/eservices/items/${id}`);
};
