import React, { useEffect, useState } from "react";
import "./style.css";

import { Button, Card, Col, Row, Select, Tooltip, Input, Form } from "antd";
import CommonTable from "components/Tables/CommonTable";
import {
  CheckCircleOutlined,
  EditOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CustomModal from "components/CustomModal/CustomModal";
import SCHEvaluationForm from "components/Forms/sch-evalution-forms/SCHEvaluationForm";
import { useSelector } from "react-redux";

import { capitalizeFirstLetters } from "utils/helpers/helpers";

import { toTitleCase } from "utils/helpers/helpers";
const ModalType = {
  ADD: "Add",
  EDIT: "Update",
};
const { Option } = Select;
const SchEvaluationTabDetails = ({
  application,
  onSubmit,
  loading,
  handelApplicationProcess,
  onSubmitCommitteeApproval,
  onSubmitCommitteeRejected,
}) => {
  const [points, setPoints] = useState("");
  const _nonAcademicAchievement = useSelector(
    (state) => state.metaData.nonAcademicAchievement
  );

  const educationalAllowances = useSelector(
    (state) => state.metaData.educationalAllowances
  );
  const personalInterview = useSelector(
    (state) => state.metaData.personalInterview
  );
  const contributionToResearch = useSelector(
    (state) => state.metaData.contributionToResearch
  );
  const socialAndCommunityActivities = useSelector(
    (state) => state.metaData.socialAndCommunityActivities
  );
  const certificatesAndAwards = useSelector(
    (state) => state.metaData.certificatesAndAwards
  );
  const educationalSystems = useSelector(
    (state) => state.metaData.educationalSystems
  );
  const scoringSystem = useSelector((state) => state.metaData.scoringSystem);
  const assessmentExams = useSelector(
    (state) => state.metaData.assessmentExams
  );
  const sch_schools = useSelector((state) => state.metaData.SCH_schools);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [id, setID] = useState("");
  const [evaluationForm] = Form.useForm();

  // if evaluation type is nonAcademicAchievement
  const [showModalForNonAcademic, toggleModalForNonAcademic] = useState(false);
  const [idForNonAcademic, setidForNonAcademic] = useState("");
  const [evaluationFormForNonAcademic] = Form.useForm();

  // new evaluation flow
  const [evaluationModal, setEvaluationModal] = useState(false);
  const [selectedEvaluationId, setSelectedEvaluationId] = useState("");
  const [selectedEvaluationType, setSelectedEvaluationType] = useState("");
  const [selectedEvaluationTitle, setSelectedEvaluationTitle] = useState("");
  const [evaluationsForm] = Form.useForm();

  // new evaluation for current school flow
  const [currentSchoolModal, setCurrentSchoolEvaluationModal] = useState(false);
  const [currentSchoolEvaluationsForm] = Form.useForm();

  // new evaluation for educational system flow
  const [educationSystemModal, setEducationSystemModal] = useState(false);
  const [educationalSystemsGrades, setEducationalSystemsGrades] = useState([]);
  const [educationSystemForm] = Form.useForm();

  // new evaluation for assesment exam  flow
  const [assessmentExamModal, setAssessmentExamModal] = useState(false);
  const [assessmentExamRange, setAssessmentExamRange] = useState({});
  const [mathPoints, setMathPoints] = useState("");
  const [readingPoints, setReadingPoints] = useState("");
  const [assessmentExamForm] = Form.useForm();

  // new evaluation for AcademicScore  flow
  const [academicScoreModal, setAcademicScoreModal] = useState(false);
  const [academicScore, setAcademicScore] = useState({});
  const [academicScoreForm] = Form.useForm();

  // for adding coments  evaluation points
  const [showModalForAddComent, setshowModalForAddComent] = useState(false);
  const [addComentForm] = Form.useForm();

  // ..........comitte aproval process............ //
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [approveForm] = Form.useForm();
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [rejectForm] = Form.useForm();

  // ..........comitte aproval process............ //

  const hasAllMarkedTrue = application?.stage?.status?.code === "SG1500";
  const evaluationData = application?.evaluations;

  const ifSuperAdmin = useSelector(
    (state) =>
      state.login.userInfo?.hasCancelAccess ||
      state.login.userInfo?.role === "super-admin"
  );

  const handelChnageNonAcademicDropdown = (value) => {
    const selectedAchievement = _nonAcademicAchievement.find(
      (el) => el.id === Number(value)
    );
    setPoints(selectedAchievement.points);
    setID(value);
  };

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} required`,
      },
    ];
  };

  // ----------------new evaluation points handle-------------

  const handleEdit = (record) => {
    if (record.type === "nonAcademicAchievement") {
      setValuesForEdit(record);
      evaluationFormForNonAcademic.setFieldsValue({ ...record });
      toggleModalForNonAcademic(true);
      setidForNonAcademic(record.id);
    } else if (
      record?.type === "educationalAllowance" ||
      record?.type === "personalInterview" ||
      record?.type === "contributionToResearch" ||
      record?.type === "socialAndCommunityActivities" ||
      record?.type === "socialAndCommunityActivities" ||
      record?.type === "certificatesAndAwards"
    ) {
      setValuesForEdit(record);
      evaluationsForm.setFieldsValue({ ...record });
      setEvaluationModal(true);
      setSelectedEvaluationId(record.id);
      setSelectedEvaluationType(record.type);
      setSelectedEvaluationTitle(record.title);
    } else if (record?.type === "currentSchool") {
      setValuesForEdit(record);
      currentSchoolEvaluationsForm.setFieldsValue({ ...record });
      setCurrentSchoolEvaluationModal(true);
      setSelectedEvaluationId(record.id);
      setSelectedEvaluationType(record.type);
      setSelectedEvaluationTitle(record.title);
    } else if (record?.type === "educationSystem") {
      setValuesForEdit(record);
      educationSystemForm.setFieldsValue({ ...record });
      setEducationSystemModal(true);
      setSelectedEvaluationId(record.id);
      setSelectedEvaluationType(record.type);
      setSelectedEvaluationTitle(record.title);
    } else if (record?.type === "assessmentExamScore") {
      setValuesForEdit(record);
      assessmentExamForm.setFieldsValue({ ...record });
      setAssessmentExamModal(true);
      setSelectedEvaluationId(record.id);
      setSelectedEvaluationType(record.type);
      setSelectedEvaluationTitle(record.title);
    } else if (record?.type === "score") {
      setValuesForEdit(record);
      academicScoreForm.setFieldsValue({ ...record });
      setAcademicScoreModal(true);
      setSelectedEvaluationId(record.id);
      setSelectedEvaluationType(record.type);
      setSelectedEvaluationTitle(record.title);
    } else {
      setValuesForEdit(record);
      evaluationForm.setFieldsValue({ ...record });
      setPoints(record?.points);
      toggleModal(true);
    }
  };

  const handelChnageForEvaluationsDropdown = (value) => {
    let _selectedEvaluation;

    if (selectedEvaluationType === "personalInterview") {
      _selectedEvaluation = personalInterview.find(
        (el) => el.id === Number(value)
      );
    } else if (selectedEvaluationType === "educationalAllowance") {
      _selectedEvaluation = educationalAllowances.find(
        (el) => el.id === Number(value)
      );
    } else if (selectedEvaluationType === "contributionToResearch") {
      _selectedEvaluation = contributionToResearch.find(
        (el) => el.id === Number(value)
      );
    } else if (selectedEvaluationType === "socialAndCommunityActivities") {
      _selectedEvaluation = socialAndCommunityActivities.find(
        (el) => el.id === Number(value)
      );
    } else if (selectedEvaluationType === "certificatesAndAwards") {
      _selectedEvaluation = certificatesAndAwards.find(
        (el) => el.id === Number(value)
      );
    } else if (selectedEvaluationType === "currentSchool") {
      _selectedEvaluation = sch_schools.find((el) => el.id === Number(value));
    } else if (selectedEvaluationType === "educationSystem") {
      if (educationalSystemsGrades.length > 0) {
        _selectedEvaluation = educationalSystemsGrades.find(
          (el) => el.id === Number(value)
        );
      }
    } else if (selectedEvaluationType === "score") {
      if (Object.keys(academicScore).length > 0) {
        _selectedEvaluation = academicScore?.scores.find(
          (el) => el.letter === value
        );
      }
    }

    // Add other evaluation types and their corresponding arrays here

    if (_selectedEvaluation) {
      setPoints(_selectedEvaluation.points);
      setID(value);
    }
  };

  const handelChnageForEducationSystemDropdown = (id) => {
    const _educationSystemGrades = educationalSystems.find(
      (edu) => edu.id === Number(id)
    );
    if (_educationSystemGrades) {
      setEducationalSystemsGrades(_educationSystemGrades?.grades);
    }
    educationSystemForm.resetFields(["gradeId"]);
    setPoints("");
  };

  const handelChnageForAssesmentExameDropdown = (id) => {
    const _assesmentExame = assessmentExams.find(
      (edu) => edu.id === Number(id)
    );
    if (_assesmentExame) {
      setAssessmentExamRange(_assesmentExame);
    }
    assessmentExamForm.resetFields(["range"]);
    setPoints("");
  };
  const handelChnageForAcedemicScoreDropdown = (id) => {
    const _scoringSystem = scoringSystem.find((edu) => edu.id === Number(id));
    if (_scoringSystem) {
      setAcademicScore(_scoringSystem);
    }
    academicScoreForm.resetFields(["range"]);
    setPoints("");
  };

  const onCloseForm = async () => {
    toggleModal(false);
    toggleModalForNonAcademic(false);
    setshowModalForAddComent(false);
    setEvaluationModal(false);
    setCurrentSchoolEvaluationModal(false);
    setValuesForEdit(null);
    setIsApproveModal(false);
    setIsRejectModal(false);
    setPoints("");
    setSelectedEvaluationTitle("");
    setSelectedEvaluationType("");
    setEducationSystemModal(false);
    setAssessmentExamModal(false);
    setAcademicScoreModal(false);
    evaluationsForm.resetFields(["evaluationId"]);
    currentSchoolEvaluationsForm.resetFields(["evaluationId"]);
    educationSystemForm.resetFields(["evaluationId"]);
    assessmentExamForm.resetFields(["evaluationId"]);
  };

  const onSubmitForEvaluationPoints = (values) => {
    onSubmit(values, selectedEvaluationType, valuesForEdit, points, id);
    setEvaluationModal(false);
    evaluationsForm.resetFields();
  };

  //------------- new evaluation points handle end------------

  const onSubmitNonAcademicEvaluationPoint = (values) => {
    onSubmit(values, "nonAcad", valuesForEdit, points, id);
    toggleModalForNonAcademic(false);
    evaluationFormForNonAcademic.resetFields();
    setPoints("");
  };

  const onSubmitCurrentSchoolEvaluationPoint = (values) => {
    onSubmit(
      values,
      "currentSchool",
      valuesForEdit,
      points,
      id,
      values?.schoolName
    );
    setCurrentSchoolEvaluationModal(false);
    currentSchoolEvaluationsForm.resetFields();
    setPoints("");
  };

  const onSubmitEducationSystemEvaluationPoint = (values) => {
    onSubmit(values, "educationalSystems", valuesForEdit, points, id);
    setEducationSystemModal(false);
    educationSystemForm.resetFields();
  };
  const onSubmitAssesmentExameEvaluationPoint = (values) => {
    if (assessmentExamRange?.examType === "map") {
      let _points = (mathPoints + readingPoints) / 2;
      onSubmit(values, "assesmentExame", valuesForEdit, _points, id);
      setAssessmentExamModal(false);
    } else {
      onSubmit(values, "assesmentExame", valuesForEdit, points, id);
      setAssessmentExamModal(false);
    }
    assessmentExamForm.resetFields();
    setPoints("");
  };

  const onSubmitAcedemicScoreEvaluationPoint = (values) => {
    onSubmit(values, "acedemicScore", valuesForEdit, points, id);
    setAcademicScoreModal(false);
    academicScoreForm.resetFields();
    setPoints("");
  };

  const submitOtherEvaluationPoints = (values) => {
    onSubmit(values, "other", valuesForEdit, "", "");
    toggleModal(false);
    evaluationForm.resetFields();
    setPoints("");
  };
  const onSubmitCommnetOnPoints = (values) => {
    onSubmit(values, "comment", valuesForEdit, "", "");
    setshowModalForAddComent(false);
  };

  const unAcceptApproval = (values) => {
    onSubmitCommitteeApproval(values);
    setIsApproveModal(false);
  };
  const unRejectApproval = (values) => {
    onSubmitCommitteeRejected(values);
    setIsRejectModal(false);
  };

  const renderDescriptionForEvaluation = (record) => {
    if (record && record.type === "score") {
      if (record?.valueMeta && record?.valueMeta?.scoringSystem?.title) {
        return record.valueMeta.scoringSystem.title;
      } else {
        return record.value;
      }
    } else {
      return record?.value;
    }
  };

  const rangeValidator = (rule, value) => {
    if (
      value !== undefined &&
      (value < assessmentExamRange.minValue ||
        value > assessmentExamRange.maxValue)
    ) {
      return Promise.reject(
        new Error(
          `Value must be between ${assessmentExamRange.minValue} and ${assessmentExamRange.maxValue}`
        )
      );
    }
    const matchingScore = assessmentExamRange.scores.find(
      (score) => value >= score.from && value <= score.to
    );

    if (matchingScore) {
      setPoints(matchingScore.points);
    } else {
      setPoints(0);
    }
    return Promise.resolve();
  };
  const rangeValidatorForAcedemic = (rule, value) => {
    if (
      value !== undefined &&
      (value < academicScore.minValue || value > academicScore.maxValue)
    ) {
      return Promise.reject(
        new Error(
          `Value must be between ${academicScore.minValue} and ${academicScore.maxValue}`
        )
      );
    }
    const matchingScore = academicScore.scores.find(
      (score) => value >= score.from && value <= score.to
    );

    if (matchingScore) {
      setPoints(matchingScore.points);
    } else {
      setPoints(0);
    }
    return Promise.resolve();
  };
  const rangeValidatorForMathScore = (rule, value) => {
    if (
      value !== undefined &&
      (value < assessmentExamRange.minValue ||
        value > assessmentExamRange.maxValue)
    ) {
      return Promise.reject(
        new Error(
          `Value must be between ${assessmentExamRange.minValue} and ${assessmentExamRange.maxValue}`
        )
      );
    }
    const matchingScore = assessmentExamRange.scores
      .filter((el) => el.isMath === true)
      .find((score) => value >= score.from && value <= score.to);

    if (matchingScore) {
      setMathPoints(matchingScore.points);
    } else {
      setMathPoints(0);
    }
    return Promise.resolve();
  };
  const rangeValidatorForReadingScore = (rule, value) => {
    if (
      value !== undefined &&
      (value < assessmentExamRange.minValue ||
        value > assessmentExamRange.maxValue)
    ) {
      return Promise.reject(
        new Error(
          `Value must be between ${assessmentExamRange.minValue} and ${assessmentExamRange.maxValue}`
        )
      );
    }
    const matchingScore = assessmentExamRange.scores
      .filter((el) => el.isMath === false)
      .find((score) => value >= score.from && value <= score.to);

    if (matchingScore) {
      setReadingPoints(matchingScore.points);
    } else {
      setReadingPoints(0);
    }
    return Promise.resolve();
  };

  // if  evaluation statge
  if (
    application?.stage?.status?.code === "SG1480" ||
    application?.stage?.status?.code === "SG1500"
  ) {
    return (
      <div>
        {application ? (
          <Row gutter={[20, 0]}>
            <Col sm={24} md={24}>
              <Card hoverable className="antCardBorder antCard">
                <Row align="middle" justify="space-between">
                  <div>
                    <h5>Application Scoring Rubric</h5>
                    <p style={{ fontSize: "12px" }}></p>
                  </div>
                  <Row gutter={[20]}>
                    <Button
                      type="primary"
                      className="appButton"
                      onClick={() =>
                        handelApplicationProcess("send_for_committee_approval")
                      }
                      disabled={!hasAllMarkedTrue ? true : false}
                    >
                      Send for committe approval
                    </Button>
                    <Button
                      style={{ marginLeft: "1rem" }}
                      type="primary"
                      className="appButton"
                      onClick={() =>
                        handelApplicationProcess("recalculate_evaluation_score")
                      }
                      disabled={false}
                    >
                      Recalculate
                    </Button>
                  </Row>
                </Row>
                <br />
                <Row align="middle" justify="space-between">
                  <br />

                  <Row gutter={[20]}>
                    <Button
                      style={{ marginLeft: "1rem" }}
                      type="default"
                      className="appButton"
                      onClick={() => setshowModalForAddComent(true)}
                      disabled={false}
                    >
                      Add Comment
                    </Button>
                  </Row>
                </Row>
                <br />

                <Card hoverable className="antCardBorder antCard">
                  <Row align="middle" justify="space-between">
                    <Col>
                      <p style={{ color: "#007B85", fontSize: 18 }}>
                        Evaluator Comment
                      </p>
                      <p>{application?.evaluationComment}</p>
                    </Col>
                  </Row>
                </Card>

                <br />

                <CommonTable
                  data={evaluationData}
                  pagination={false}
                  // fetchRecords={(page) => setPage(page)}
                  // totalRecord={total}
                  columns={[
                    {
                      title: "Criteria",
                      dataIndex: "title",
                      key: "title",
                      render: (record) => (
                        <p className="answer_para">
                          {capitalizeFirstLetters(record)}
                        </p>
                      ),
                    },
                    {
                      title: "Description",
                      // dataIndex: "value",
                      key: "value",
                      render: (record) => (
                        <div>{renderDescriptionForEvaluation(record)}</div>
                      ),
                    },
                    {
                      title: "Points in numbers",
                      // dataIndex: "points",
                      key: "points",
                      render: (record) => (
                        <p className="answer_para">
                          {record?.isMarked ? record?.points : "N/A"}
                        </p>
                      ),
                    },
                    {
                      title: "Action",
                      dataIndex: "",
                      key: "x",
                      render: (item) => {
                        return (
                          <div style={{ display: "flex" }} className="d-flex">
                            <div style={{ marginRight: "10px" }}>
                              <Tooltip title="Update">
                                <Button
                                  disabled={item?.isApplicable ? false : true}
                                  className="d-flex justify-content-center align-items-center"
                                  shape="circle"
                                  icon={<EditOutlined />}
                                  onClick={() => handleEdit(item)}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                ></CommonTable>
                <div
                  style={{
                    marginTop: 40,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: 260,
                    marginLeft: 20,
                  }}
                >
                  <b>Total Points</b>
                  <strong>{application?.evaluationPoints}</strong>
                </div>
              </Card>
            </Col>
          </Row>
        ) : null}
        <CustomModal
          modalSmall={true}
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Scroing Rubric `}
        >
          <SCHEvaluationForm
            onSubmit={submitOtherEvaluationPoints}
            formName={evaluationForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
          />
        </CustomModal>
        <CustomModal
          modalSmall={true}
          visibility={showModalForNonAcademic}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Scroing Rubric `}
        >
          <Form
            layout="vertical"
            onFinish={onSubmitNonAcademicEvaluationPoint}
            form={evaluationFormForNonAcademic}
          >
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"evaluationId"}
                  label="Title"
                  rules={setRules(" Non Academic Achievement is requred")}
                >
                  <Select
                    placeholder="Select Non Academic Achievement"
                    onChange={(value) => {
                      handelChnageNonAcademicDropdown(value);
                    }}
                  >
                    {_nonAcademicAchievement?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  // name={"points"}
                  label="Points"
                  rules={setRules("Poinst")}
                >
                  <Input
                    type="number"
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                    min="0"
                    required
                  />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
        {/* for all evaluations type */}
        <CustomModal
          modalSmall={true}
          visibility={evaluationModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Scroing Rubric `}
        >
          <Form
            layout="vertical"
            onFinish={onSubmitForEvaluationPoints}
            form={evaluationsForm}
          >
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"evaluationId"}
                  label="Title"
                  rules={setRules("Evaluation is requred")}
                >
                  <Select
                    placeholder={`Select ${toTitleCase(
                      selectedEvaluationTitle
                    )}`}
                    onChange={(value) => {
                      handelChnageForEvaluationsDropdown(value);
                    }}
                  >
                    {selectedEvaluationType === "personalInterview" ? (
                      <>
                        {personalInterview?.map((el, i) => (
                          <Option key={i} value={`${el.id}`}>
                            {el.title}
                          </Option>
                        ))}
                      </>
                    ) : selectedEvaluationType === "contributionToResearch" ? (
                      <>
                        {contributionToResearch?.map((el, i) => (
                          <Option key={i} value={`${el.id}`}>
                            {el.title}
                          </Option>
                        ))}
                      </>
                    ) : selectedEvaluationType ===
                      "socialAndCommunityActivities" ? (
                      <>
                        {socialAndCommunityActivities?.map((el, i) => (
                          <Option key={i} value={`${el.id}`}>
                            {el.title}
                          </Option>
                        ))}
                      </>
                    ) : selectedEvaluationType === "certificatesAndAwards" ? (
                      <>
                        {certificatesAndAwards?.map((el, i) => (
                          <Option key={i} value={`${el.id}`}>
                            {el.title}
                          </Option>
                        ))}
                      </>
                    ) : (
                      <>
                        {educationalAllowances?.map((el, i) => (
                          <Option key={i} value={`${el.id}`}>
                            {el.title}
                          </Option>
                        ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  // name={"points"}
                  label="Points"
                  rules={setRules("Poinst")}
                >
                  <Input
                    type="number"
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                    min="0"
                    required
                  />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>

        {/* for current school */}
        <CustomModal
          modalSmall={true}
          visibility={currentSchoolModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Scroing Rubric `}
        >
          <Form
            layout="vertical"
            onFinish={onSubmitCurrentSchoolEvaluationPoint}
            form={currentSchoolEvaluationsForm}
          >
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"evaluationId"}
                  label="Title"
                  rules={setRules("Current School is requred")}
                >
                  <Select
                    placeholder="Select Current School"
                    onChange={(value) => {
                      handelChnageForEvaluationsDropdown(value);
                    }}
                  >
                    {sch_schools?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <p>OR</p>
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item name={"schoolName"} label="School Name">
                  <Input placeholder="Enter School Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  // name={"points"}
                  label="Points"
                  rules={setRules("Poinst")}
                >
                  <Input
                    type="number"
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                    min="0"
                    required
                  />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>

        {/* for educatinal system school */}
        <CustomModal
          modalSmall={true}
          visibility={educationSystemModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Scroing Rubric `}
        >
          <Form
            layout="vertical"
            onFinish={onSubmitEducationSystemEvaluationPoint}
            form={educationSystemForm}
          >
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"educationSystemId"}
                  label="Title"
                  rules={setRules("Education System is requred")}
                >
                  <Select
                    placeholder="Select Education System"
                    onChange={(value) => {
                      handelChnageForEducationSystemDropdown(value);
                    }}
                  >
                    {educationalSystems?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"gradeLevelId"}
                  label="Title"
                  rules={setRules("Grade is requred")}
                >
                  <Select
                    placeholder="Select Grade"
                    onChange={(value) => {
                      handelChnageForEvaluationsDropdown(value);
                    }}
                  >
                    {educationalSystemsGrades?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  // name={"points"}
                  label="Points"
                  rules={setRules("Poinst")}
                >
                  <Input
                    type="number"
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                    min="0"
                    required
                  />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>

        {/* for Assesment exame score */}
        <CustomModal
          modalSmall={true}
          visibility={assessmentExamModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Scroing Rubric `}
        >
          <Form
            layout="vertical"
            onFinish={onSubmitAssesmentExameEvaluationPoint}
            form={assessmentExamForm}
          >
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"assessmentExamId"}
                  label="Exam Type"
                  rules={setRules("Exam type is requred")}
                >
                  <Select
                    placeholder="Select Exam Type"
                    onChange={(value) => {
                      handelChnageForAssesmentExameDropdown(value);
                    }}
                  >
                    {assessmentExams?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {assessmentExamRange?.examType === "map" ? (
              <Row gutter={10}>
                <Col md={24}>
                  <Form.Item
                    name={"assessmentExamMathScore"}
                    label="Math Score"
                    rules={[
                      ...setRules("Math Score is required"),
                      { validator: rangeValidatorForMathScore },
                    ]}
                  >
                    <Input placeholder="Enter Math Score" type="number" />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item
                    // name={"points"}
                    label="Math Points"
                    rules={setRules("Math Points")}
                  >
                    <Input
                      type="number"
                      value={mathPoints}
                      onChange={(e) => setMathPoints(e.target.value)}
                      min="0"
                      required
                    />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item
                    name={"assessmentExamScore"}
                    label="Reading Score"
                    rules={[
                      ...setRules("Reading Score is required"),
                      { validator: rangeValidatorForReadingScore },
                    ]}
                  >
                    <Input placeholder="Enter Reading Score" type="number" />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item
                    // name={"points"}
                    label="Reading Points"
                    rules={setRules("Reading Point")}
                  >
                    <Input
                      type="number"
                      value={readingPoints}
                      onChange={(e) => setReadingPoints(e.target.value)}
                      min="0"
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row gutter={10}>
                <Col md={24}>
                  <Form.Item
                    name={"assessmentExamScore"}
                    label="Score"
                    rules={[
                      ...setRules("Score is required"),
                      { validator: rangeValidator },
                    ]}
                  >
                    <Input placeholder="Enter Score" type="number" />
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    // name={"points"}
                    label="Points"
                    rules={setRules("Points")}
                  >
                    <Input
                      type="number"
                      value={points}
                      onChange={(e) => setPoints(e.target.value)}
                      min="0"
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>

        {/* for Acedemic score */}
        <CustomModal
          modalSmall={true}
          visibility={academicScoreModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Scroing Rubric `}
        >
          <Form
            layout="vertical"
            onFinish={onSubmitAcedemicScoreEvaluationPoint}
            form={academicScoreForm}
          >
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"scoringSystemId"}
                  label="Score Type"
                  rules={setRules("Score type is requred")}
                >
                  <Select
                    placeholder="Select Test Type"
                    onChange={(value) => {
                      handelChnageForAcedemicScoreDropdown(value);
                    }}
                  >
                    {scoringSystem?.map((el, i) => (
                      <Option key={i} value={`${el.id}`}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              {academicScore?.isRange ? (
                <Col md={24}>
                  <Form.Item
                    name={"scoringSystemScore"}
                    label="Range"
                    rules={[
                      ...setRules("Range is required"),
                      { validator: rangeValidatorForAcedemic },
                    ]}
                  >
                    <Input placeholder="Enter Range" type="number" />
                  </Form.Item>
                </Col>
              ) : (
                <Col md={24}>
                  <Form.Item
                    name={"scoringSystemScore"}
                    label="Range"
                    rules={setRules("Range is required")}
                  >
                    <Select
                      placeholder="Select Range"
                      onChange={(value) => {
                        handelChnageForEvaluationsDropdown(value);
                      }}
                    >
                      {academicScore?.scores?.map((el, i) => (
                        <Option key={i} value={el.letter}>
                          {el.letter}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  // name={"points"}
                  label="Points"
                  rules={setRules("Poinst")}
                >
                  <Input
                    type="number"
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                    min="0"
                    required
                  />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>

        <CustomModal
          modalSmall={true}
          visibility={showModalForAddComent}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={"Add Comment"}
        >
          <Form
            layout="vertical"
            onFinish={onSubmitCommnetOnPoints}
            form={addComentForm}
          >
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"comment"}
                  label="Add Comment"
                  rules={setRules("comment")}
                >
                  <Input.TextArea
                    placeholder="Comment"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button
                htmlType="submit"
                // loading={loading}
              >
                Save
                {/* {loading ? "Saving..." : "Save"} */}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </div>
    );

    // if the evaluation statge is passed to committe approval
  } else if (
    application?.stage?.status?.code === "SG1580" ||
    application?.stage?.status?.code === "SG1600" ||
    application?.stage?.status?.code === "SG1620" ||
    application?.stage?.status?.code === "SG1640"
  ) {
    return (
      <div>
        {application ? (
          <Row gutter={[20, 0]}>
            <Col sm={24} md={24}>
              <Card hoverable className="antCardBorder antCard">
                <Row align="middle" justify="space-between">
                  <div>
                    <h5>Application Scoring Rubric</h5>
                    <p style={{ fontSize: "12px" }}></p>
                  </div>
                  {!ifSuperAdmin && (
                    <>
                      {application?.stage?.status?.code !== "SG1640" && (
                        <Row gutter={[20]}>
                          {!application?.hasSubmittedFeedback && (
                            <Button
                              type="primary"
                              className="appButton"
                              onClick={() => setIsApproveModal(true)}
                              disabled={false}
                            >
                              Approve
                            </Button>
                          )}
                          {!application?.hasSubmittedFeedback && (
                            <Button
                              style={{ marginLeft: "1rem" }}
                              type="primary"
                              className="appButton"
                              // onClick={() =>
                              //   handelApplicationProcess("recalculate_evaluation_score")
                              // }
                              onClick={() => setIsRejectModal(true)}
                              disabled={false}
                            >
                              Reject
                            </Button>
                          )}
                        </Row>
                      )}
                    </>
                  )}

                  {ifSuperAdmin &&
                    !["SG1640", "SG1760"].includes(
                      application?.stage?.status.code
                    ) && (
                      <Button
                        style={{ marginLeft: "1rem" }}
                        type="primary"
                        className="appButton"
                        onClick={() =>
                          handelApplicationProcess(
                            "recalculate_evaluation_score"
                          )
                        }
                        disabled={false}
                      >
                        Recalculate
                      </Button>
                    )}
                </Row>
                <br />
                <Card hoverable className="antCardBorder antCard">
                  {application?.stage?.employees.map((employee) => {
                    return (
                      <div
                        style={{
                          backgroundColor: "#f0f0f0",
                          paddingLeft: 10,
                          marginTop: 10,
                          width: 400,
                          borderRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <UserOutlined className="rh-log-icon" />
                          <p style={{ color: "#007B85", paddingTop: 12 }}>
                            {employee.name}
                          </p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CheckCircleOutlined className="rh-log-icon" />
                          <p style={{ paddingTop: 12 }}>Approval: </p>
                          {""}
                          <p style={{ color: "#007B85", paddingTop: 12 }}>
                            {employee.hasSubmittedFeedback
                              ? employee.hasApproved
                                ? "Approved"
                                : "Rejected"
                              : "Pending"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </Card>
                <br />

                <Card hoverable className="antCardBorder antCard">
                  <Row align="middle" justify="space-between">
                    <Col>
                      <p style={{ color: "#007B85", fontSize: 18 }}>
                        Evaluator Comment
                      </p>
                      <p>{application?.evaluationComment}</p>
                    </Col>
                  </Row>
                </Card>

                <br />

                <CommonTable
                  data={evaluationData}
                  pagination={false}
                  // fetchRecords={(page) => setPage(page)}
                  // totalRecord={total}
                  columns={[
                    {
                      title: "Criteria",
                      dataIndex: "title",
                      key: "title",
                      render: (record) => (
                        <p className="answer_para">
                          {capitalizeFirstLetters(record)}
                        </p>
                      ),
                    },
                    {
                      title: "Description",
                      // dataIndex: "value",
                      key: "value",
                      render: (record) => (
                        <p className="answer_para">
                          {renderDescriptionForEvaluation(record)}
                        </p>
                      ),
                    },
                    {
                      title: "Points in numbers",
                      // dataIndex: "points",
                      key: "points",
                      render: (record) => (
                        <p className="answer_para">
                          {record?.isMarked ? record?.points : "N/A"}
                        </p>
                      ),
                    },
                  ]}
                ></CommonTable>
                <div
                  style={{
                    marginTop: 40,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: 185,
                    marginLeft: 20,
                  }}
                >
                  <b>Total Points</b>
                  <strong>{application?.evaluationPoints}</strong>
                </div>
              </Card>
            </Col>
          </Row>
        ) : null}
        <CustomModal
          modalSmall={true}
          visibility={isApproveModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={"Approve"}
        >
          <Form
            layout="vertical"
            onFinish={unAcceptApproval}
            form={approveForm}
          >
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"comment"}
                  label="Add Comment"
                  rules={setRules("comment")}
                >
                  <Input.TextArea
                    placeholder="Comment"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
        <CustomModal
          modalSmall={true}
          visibility={isRejectModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={"Reject"}
        >
          <Form layout="vertical" onFinish={unRejectApproval} form={rejectForm}>
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"comment"}
                  label="Add Comment"
                  rules={setRules("comment")}
                >
                  <Input.TextArea
                    placeholder="Comment"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </div>
    );
  } else {
    return (
      <div className="container">
        <p>
          The evaluation tab will be made available once the review stage is
          completed. Thank you for your patience.
        </p>
      </div>
    );
  }
};

export default SchEvaluationTabDetails;
