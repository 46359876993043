import client from "apis/config";

export const getCashierConfigAPI = async () => {
  return await client.get(`/cashier/configurations`);
};
export const addCashierConfigAPI = async (data) => {
  return await client.post("/cashier/configurations", data);
};
export const editCashierConfigAPI = async (id, data) => {
  return await client.put(`/cashier/configurations/${id}`, data);
};

export const deleteCashierConfigApi = async (id) => {
  return await client.delete(`/cashier/configurations/${id}`);
};
