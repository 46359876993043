import { StageActionKey } from "utils/constants";

const initialState = {
  registration: [],
  interview: [],
  observation: [],
  transcript_review: [],
  entrance_test: [],
  pre_final_approval: [],
  final_review_approval: [],
  registration_fees: [],
  loading: false,
  totalRecord: 0,
};

const updateStageHelper = (allData, item) => {
  const updatedData = [...allData];
  const index = allData.findIndex((el) => el.id === item.id);
  if (index > -1) {
    updatedData[index] = item;
    return updatedData;
  } else return allData;
};
const StagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case StageActionKey.getStageDataReq:
      return {
        ...state,
        loading: true,
      };
    case StageActionKey.getStageDataFailure:
      return {
        ...state,
        loading: false,
      };
    case StageActionKey.getRegistartionStageData:
      return {
        ...state,
        registration: action.payload.data,
        totalRecord: action.payload.total,
        loading: false,
      };
    case StageActionKey.getInterviewStageData:
      return {
        ...state,
        interview: action.payload.data,
        totalRecord: action.payload.total,
        loading: false,
      };
    case StageActionKey.getObservationStageData:
      return {
        ...state,
        observation: action.payload.data,
        totalRecord: action.payload.total,
        loading: false,
      };
    case StageActionKey.getTranscriptStageData:
      return {
        ...state,
        transcript_review: action.payload.data,
        totalRecord: action.payload.total,
        loading: false,
      };
    case StageActionKey.getEntranceTestStageData:
      return {
        ...state,
        entrance_test: action.payload.data,
        totalRecord: action.payload.total,
        loading: false,
      };
    case StageActionKey.getPreFinalApprovalStageData:
      return {
        ...state,
        pre_final_approval: action.payload.data,
        totalRecord: action.payload.total,
        loading: false,
      };
    case StageActionKey.getFinalReviewStageData:
      return {
        ...state,
        final_review_approval: action.payload.data,
        totalRecord: action.payload.total,
        loading: false,
      };
    case StageActionKey.getRegistartionFeesStageData:
      return {
        ...state,
        registration_fees: action.payload.data,
        totalRecord: action.payload.total,
        loading: false,
      };

    //   add
    case StageActionKey.addStageDataReq:
      return {
        ...state,
        loading: true,
      };
    case StageActionKey.addStageDataSuccess:
      return {
        ...state,
        loading: false,
      };
    case StageActionKey.addStageDataFailure:
      return {
        ...state,
        loading: false,
      };
    case StageActionKey.addRegistartionStageData:
      return {
        ...state,
        registration: [...state.registration, action.payload],
      };
    case StageActionKey.addInterviewStageData:
      return {
        ...state,
        interview: [...state.interview, action.payload],
      };
    case StageActionKey.addObservationStageData:
      return {
        ...state,
        observation: [...state.observation, action.payload],
      };
    case StageActionKey.addTranscriptStageData:
      return {
        ...state,
        transcript_review: [...state.transcript_review, action.payload],
      };
    case StageActionKey.addEntranceTestStageData:
      return {
        ...state,
        entrance_test: [...state.entrance_test, action.payload],
      };
    case StageActionKey.addPreFinalApprovalStageData:
      return {
        ...state,
        pre_final_approval: [...state.pre_final_approval, action.payload],
      };
    case StageActionKey.addFinalReviewStageData:
      return {
        ...state,
        final_review_approval: [...state.final_review_approval, action.payload],
      };
    case StageActionKey.addRegistartionFeesStageData:
      return {
        ...state,
        registration_fees: [...state.registration_fees, action.payload],
      };

    //   edit

    case StageActionKey.editStageDataReq:
      return {
        ...state,
        loading: true,
      };
    case StageActionKey.editStageDataFailure:
      return {
        ...state,
        loading: false,
      };
    case StageActionKey.editStageDataSuccess:
      return {
        ...state,
        loading: false,
      };

    case StageActionKey.editRegistartionStageData:
      return {
        ...state,
        registration: updateStageHelper(state.registration, action.payload),
      };
    case StageActionKey.editInterviewStageData:
      return {
        ...state,
        interview: updateStageHelper(state.interview, action.payload),
      };
    case StageActionKey.editObservationStageData:
      return {
        ...state,
        observation: updateStageHelper(state.observation, action.payload),
      };
    case StageActionKey.editTranscriptStageData:
      return {
        ...state,
        transcript_review: updateStageHelper(
          state.transcript_review,
          action.payload
        ),
      };
    case StageActionKey.editEntranceTestStageData:
      return {
        ...state,
        entrance_test: updateStageHelper(state.entrance_test, action.payload),
      };
    case StageActionKey.editPreFinalApprovalStageData:
      return {
        ...state,
        pre_final_approval: updateStageHelper(
          state.pre_final_approval,
          action.payload
        ),
      };
    case StageActionKey.editFinalReviewStageData:
      return {
        ...state,
        final_review_approval: updateStageHelper(
          state.final_review_approval,
          action.payload
        ),
      };
    case StageActionKey.editRegistartionFeesStageData:
      return {
        ...state,
        registration_fees: updateStageHelper(
          state.registration_fees,
          action.payload
        ),
      };

    //   delete
    case StageActionKey.deleteStageDataReq:
      return {
        ...state,
        loading: true,
      };
    case StageActionKey.deleteStageDataSuccess:
      return {
        ...state,
        loading: false,
      };
    case StageActionKey.deleteStageDataFailure:
      return {
        ...state,
        loading: false,
      };
    case StageActionKey.deleteRegistartionStageData:
      return {
        ...state,
        registration: state.registration.filter(
          (el) => el.id !== action.payload
        ),
      };
    case StageActionKey.deleteInterviewStageData:
      return {
        ...state,
        interview: state.interview.filter((el) => el.id !== action.payload),
      };
    case StageActionKey.deleteObservationStageData:
      return {
        ...state,
        observation: state.observation.filter((el) => el.id !== action.payload),
      };
    case StageActionKey.deleteTranscriptStageData:
      return {
        ...state,
        transcript_review: state.transcript_review.filter(
          (el) => el.id !== action.payload
        ),
      };
    case StageActionKey.deleteEntranceTestStageData:
      return {
        ...state,
        entrance_test: state.entrance_test.filter(
          (el) => el.id !== action.payload
        ),
      };
    case StageActionKey.deletePreFinalApprovalStageData:
      return {
        ...state,
        pre_final_approval: state.pre_final_approval.filter(
          (el) => el.id !== action.payload
        ),
      };
    case StageActionKey.deleteFinalReviewStageData:
      return {
        ...state,
        final_review_approval: state.final_review_approval.filter(
          (el) => el.id !== action.payload
        ),
      };
    case StageActionKey.deleteRegistartionFeesStageData:
      return {
        ...state,
        registration_fees: state.registration_fees.filter(
          (el) => el.id !== action.payload
        ),
      };
    default:
      return state;
  }
};
export default StagesReducer;
