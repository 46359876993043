import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import { Button, Col, Form, Input, Row } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { stringifyNumber } from "utils/helpers/helpers";

const AddDistributionForm = ({
  visibility,
  onHide,
  title,
  onSubmit,
  formName,
  loading,
  filledInstallments,
}) => {
  const fixedInstallemnts = 3;
  const [installment, setInstallment] = React.useState(
    new Array(fixedInstallemnts).fill("")
  );

  React.useEffect(() => {
    if (filledInstallments) {
      setInstallment(filledInstallments);
    } else {
      setInstallment(new Array(fixedInstallemnts).fill(""));
    }
  }, [filledInstallments]);

  const addInstallment = () => {
    let newInstallment = [...installment];
    newInstallment.push("");
    setInstallment(newInstallment);
  };
  const removeInstallment = (index) => {
    let updatedInstallemnt = [...installment];
    updatedInstallemnt = updatedInstallemnt.filter(
      (_, i) => i !== index - fixedInstallemnts
    );
    setInstallment(updatedInstallemnt);
  };

  return (
    <CustomModal visibility={visibility} handleCancel={onHide} title={title}>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Form.Item
          name={"title"}
          label={"Title"}
          rules={[{ required: true, message: "Title is required" }]}
        >
          <Input placeholder="Title" style={{ width: "100%" }} />
        </Form.Item>
        {installment.map((el, index) => (
          <Row align="middle" justify="space-between" key={index + 1}>
            <Col md={index >= fixedInstallemnts ? 22 : 24}>
              <Form.Item
                label={`${stringifyNumber(index + 1)} Installment`}
                name={["installments", index]}
                rules={[
                  {
                    required: index < fixedInstallemnts,
                    message: "Missing percentage",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  type={"number"}
                  placeholder="Percentage"
                />
              </Form.Item>
            </Col>
            {index >= fixedInstallemnts ? (
              <Button
                icon={<MinusOutlined />}
                onClick={() => removeInstallment(index)}
                type="danger"
              />
            ) : null}
          </Row>
        ))}
        <Button
          type="dashed"
          onClick={addInstallment}
          block
          icon={<PlusOutlined />}
          style={{ marginBottom: 10 }}
        >
          Add Installment
        </Button>

        <Row justify="end">
          <Button loading={loading} htmlType="submit">
            {loading ? "Saving...." : "Save"}
          </Button>
        </Row>
      </Form>
    </CustomModal>
  );
};
export default AddDistributionForm;
