import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { Switch } from "antd";

import { fetchAllHeaders } from "apis/templates/templatesApis";
import { addNewHeader } from "apis/templates/templatesApis";
import { updateHeader } from "apis/templates/templatesApis";
import { deleteHeader } from "apis/templates/templatesApis";
import HeaderForm from "components/html-templates-forms/HeaderForm";
import NotificationAlertForm from "components/html-templates-forms/NotificationAlertForm";
import Axios from "axios";
import { addAnnouncementAPI } from "apis/accouncement-api/accouncement-api";
import { editAnnouncementAPI } from "apis/accouncement-api/accouncement-api";
import { deleteAnnouncementApi } from "apis/accouncement-api/accouncement-api";
import { getAnnouncementAPI } from "apis/accouncement-api/accouncement-api";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const AnnouncementAlerts = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [announcementId, setAnnouncementId] = useState("");

  const [announcementForm] = Form.useForm();

  const [isAnnouncementActive, setAnnouncementActive] = useState({
    isActive: false,
    id: announcementId,
  });

  const handleToggleAnnouncement = (status) => {
    setAnnouncementActive({ isActive: !status, id: announcementId });
  };

  const renderAlert = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            announcementForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Announcement Alerts"}
          headerSubTitle={"Manage Alerts"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };

  const getAllNotificationAlertData = async () => {
    try {
      setLoading(true);
      const response = await getAnnouncementAPI();
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addAlert = async (data) => {
    try {
      setLoading(true);
      const response = await addAnnouncementAPI({ ...data, isActive: false });
      if (response.status === 200) {
        successMessage({ message: "Accouncement added successfully" });
        await getAllNotificationAlertData();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateAlert = async (data) => {
    try {
      setLoading(true);
      const response = await editAnnouncementAPI({
        id: announcementId,
        ...data,
        isActive: announcementForm.getFieldValue("isActive"),
      });
      if (response.status === 200) {
        successMessage({ message: "Accouncement updated successfully" });
        await getAllNotificationAlertData();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateAlertStatus = async (data) => {
    console.log(data);
    try {
      setLoading(true);
      const response = await editAnnouncementAPI({
        id: data.id,
        ...data,
        isActive: !data.isActive,
      });
      if (response.status === 200) {
        successMessage({ message: "Accouncement updated successfully" });
        await getAllNotificationAlertData();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteAlert = async (id) => {
    try {
      setLoading(true);
      const response = await deleteAnnouncementApi(id);
      if (response.status === 200) {
        successMessage({ message: "Announcement deleted successfully" });
        await getAllNotificationAlertData();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateAlert({ ...values });
    } else {
      await addAlert({ ...values });
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    announcementForm.setFieldsValue({
      ...record,
    });
    setAnnouncementId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  useEffect(() => {
    getAllNotificationAlertData();
  }, []);

  console.log(valuesForEdit);

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderAlert}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "TitleEn",
              dataIndex: "titleEn",
              key: "titleEn",

              // render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "TitleAr",
              dataIndex: "titleAr",
              key: "titleAr",

              // render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Announcement Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Announcement?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteAlert(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
            {
              title: "Status",
              dataIndex: "",
              key: "isActive",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <Popconfirm
                      title="Are you sure you want to change the status of this anncouncement?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleUpdateAlertStatus(item)}
                    >
                      <Tooltip title="Status">
                        <Switch
                          checked={item.isActive}
                          onChange={handleToggleAnnouncement}
                          checkedChildren="Deactivate"
                          unCheckedChildren="Activate"
                          style={{ width: 100 }} // Set the desired fixed width here
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Header`}
        >
          <NotificationAlertForm
            onSubmit={onSubmit}
            formName={announcementForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            announcementId={announcementId}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default AnnouncementAlerts;
