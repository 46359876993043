import client from "apis/config";

export const fetchAllFsmPayments = async ({
  limit = "10",
  page,
  campus = "",
  from = "",
  to = "",
  type = "",
}) => {
  return await client.get(
    `/bookings/payments?limit=${limit}&page=${page}&campus=${campus}&from=${from}&to=${to}&type=${type}`
  );
};
export const fetchFSMPaymentDetials = async (id) => {
  return await client.get(`/categories/${id}`);
};
