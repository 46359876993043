import client from "apis/config";

export const updateDiscountAPI = async (data) => {
  return await client.put("applications/discount", data);
};

export const changeStudentAPI = async (data) => {
  return await client.put("applications/discount/student", data);
};

export const updateDiscountRule = async (data) => {
  return await client.put("applications/discount-rule", data);
};
