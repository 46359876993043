import * as React from "react";
import { _getNotificationTopicsAction } from "actions/notifications/NotificationActions";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Row,
} from "antd";
import {
  getNotificationTopicsAPI,
  sendNotificationAPI,
} from "apis/notification-panel/notification-panel";
import { useDispatch, useSelector } from "react-redux";
import { renderLoader } from "utils/helpers/helpers";
import { LanguageData } from "utils/constants";
import { TargetDeviceData } from "utils/constants";
import { NotificationTypeData } from "utils/constants";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditorToolbar } from "utils/constants";
import { useState } from "react";
import { uploadSingleFileToAWS } from "utils/helpers/helpers";
import ImagePicker from "components/ImagePicker/ImagePicker";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";

const Notifications = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);

  const _topicLists = useSelector((state) => state.notifications.topicLists);
  const topicListsArray = _topicLists;
  const [filteredTopics, setFilteredTopics] = useState([]);

  const [notificationType, setNotificationType] = useState("");

  const [file, setFile] = useState({
    image: null,
    imageAr: null,
  });
  const [uploading, setUpLoading] = useState(false);

  React.useEffect(() => {
    fetchNotificationTopics();
  }, []);

  const fetchNotificationTopics = async () => {
    setLoading(true);
    try {
      const response = await getNotificationTopicsAPI();
      setLoading(false);
      if (response.data && response.status === 200) {
        dispatch(_getNotificationTopicsAction(response.data.data));
      } else throw new Error(response.error ?? "Error in fetching topic lists");
    } catch (e) {
      setLoading(false);
    }
  };

  const filterTopicLists = (targetDevice) => {
    form.setFieldsValue({ topic: undefined });
    const filteredTopicList = topicListsArray.filter(
      (item) => item.Type === targetDevice
    );
    setFilteredTopics(filteredTopicList);
  };

  const onChooseFile = (fileName) => async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        // const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        // let splitUrl = awsFile.url.split("?");
        // splitUrl = splitUrl[0];
        // setFile({ ...file, [fileName]: splitUrl });

        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "notifications"
        );
        // setFile(path);
        setFile({ ...file, [fileName]: path });
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            handleUploadFileToENSServer(file).then((path) => {
              resolve({
                default: `${path}`,
              });
            });
          });
        });
      },
    };
  };

  const onRemoveFile = (fileName) => () => {
    setFile({ ...file, [fileName]: null });
  };

  const onSendNotifications = async (values) => {
    try {
      // setLoading(true);
      const params = {
        ...values,
        imageUrl: file.image ?? "",
        imageUrlAr: file.imageAr ?? "",
      };

      const ensNotificationResponse = await sendNotificationAPI(params);
      setLoading(false);
      if (ensNotificationResponse.status !== 200) {
        throw new Error(ensNotificationResponse || "An unknown error occured");
      } else {
        NotificationManager.success("Notification sent successfully");
        form.resetFields([
          "language",
          "topic",
          "targetType",
          "title",
          "titleAr",
          "subTitle",
          "subTitleAr",
          "notificationType",
          "date",
          "externalLink",
          "externalLinkAr",
        ]);
        setNotificationType("");
        setFile({ image: null, imageAr: null });
      }
    } catch (e) {
      setLoading(false);
      NotificationManager.error(e.message);
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading || uploading} indicator={renderLoader}>
        <Card
          className="antCardBorder"
          // hoverable
          style={{ paddingLeft: 20, paddingRight: 20, marginTop: 10 }}
        >
          <h5>Send Notification</h5>
          <br />

          <Form layout="vertical" form={form} onFinish={onSendNotifications}>
            {/* <Form.Item
                  label={"Environment"}
                  name={"environment"}
                  rules={[
                    {
                      required: true,
                      message: "Environment is required",
                    },
                  ]}
                >
                  <Select placeholder={"Select Environment"}>
                    {EnvironmentData.map((el) => (
                      <Option value={el.value} key={el.value}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item> */}
            <Row gutter={[20]}>
              <Col md={12}>
                <Form.Item
                  label={"Type of Notification"}
                  name={"notificationType"}
                  rules={[
                    {
                      required: true,
                      message: "Type of notification is required",
                    },
                  ]}
                >
                  <Select placeholder={"Select Type of Notification"}>
                    {NotificationTypeData.map((el) => (
                      <Option value={el.value} key={el.value}>
                        <p
                          // style={{ backgroundColor: "red", width: "100%" }}
                          onClick={() => setNotificationType(el.value)}
                        >
                          {el.title}
                        </p>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Target Devices"}
                  name={"targetType"}
                  rules={[
                    {
                      required: true,
                      message: "Target device is required",
                    },
                  ]}
                >
                  <Select placeholder={"Select Target Devices"}>
                    {TargetDeviceData.map((el) => (
                      <Option key={el.value} value={el.value}>
                        <p onClick={() => filterTopicLists(el.value)}>
                          {el.title}
                        </p>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col md={12}>
                <Form.Item
                  label={"Topic"}
                  name={"topic"}
                  rules={[
                    {
                      required: true,
                      message: "Topic is required",
                    },
                  ]}
                >
                  {form.getFieldValue("targetType") === "StudentNumber" ||
                  form.getFieldValue("targetType") === "ParentNumber" ? (
                    <Input />
                  ) : (
                    <Select placeholder={"Select Topic"}>
                      {filteredTopics.map((el) => (
                        <Option key={el.Name} value={el.Name}>
                          {`${el.Name} - ${el.Type}`}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Language"}
                  name={"language"}
                  rules={[
                    {
                      required: true,
                      message: "Language is required",
                    },
                  ]}
                >
                  <Select placeholder={"Select Language"}>
                    {LanguageData.map((el) => (
                      <Option value={el.value} key={el.value}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col md={12}>
                <Form.Item
                  label={"Title"}
                  name={"title"}
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder={"Enter Title"}></Input>
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Title (Arabic)"}
                  name={"titleAr"}
                  rules={[
                    {
                      required: true,
                      message: "Title (Arabic) is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    dir={"rtl"}
                    placeholder={"Enter Title in Arabic"}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col md={12}>
                <Form.Item
                  label={"Subtitle"}
                  name={"subTitle"}
                  rules={[
                    {
                      required: true,
                      message: "Subtitle is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder={"Enter subtitle"}></Input>
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item
                  label={"Subtitle (Arabic)"}
                  name={"subTitleAr"}
                  rules={[
                    {
                      required: true,
                      message: "Subtitle (Arabic) is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    dir="rtl"
                    placeholder={"Enter subtitle in Arabic"}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>

            {(notificationType == "News" || notificationType == "Payment") && (
              <Form.Item
                label={"Date"}
                name={"date"}
                rules={[
                  {
                    required: true,
                    message: "Date is required",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(date) =>
                    date && date.isBefore(moment(), "date")
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}
            <Row gutter={[20]}>
              <Col md={12}>
                <Form.Item label={"External Link"} name={"externalLink"}>
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"External Link (Arabic)"}
                  name={"externalLinkAr"}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            {(notificationType === "General" ||
              notificationType === "News" ||
              notificationType === "Announcement") && (
              <>
                <Form.Item
                  label={"Description"}
                  name={"description"}
                  valuePropName={"data"}
                  getValueFromEvent={(event, editor) =>
                    editor ? editor.getData() : null
                  }
                  rules={[
                    {
                      required: notificationType === "News",
                      message: "Description is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      //toolbar: EditorToolbar,
                      extraPlugins: [uploadPlugin],
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={"Description (Arabic)"}
                  name={"descriptionAr"}
                  rules={[
                    {
                      required: notificationType === "News",
                      message: "Description (Arabic) is required",
                      whitespace: true,
                    },
                  ]}
                  valuePropName={"data"}
                  getValueFromEvent={(event, editor) =>
                    editor ? editor.getData() : ""
                  }
                >
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      language: "ar",
                      //toolbar: EditorToolbar,
                    }}
                  />
                </Form.Item>
              </>
            )}

            {notificationType === "General" || notificationType === "News" ? (
              <Row align="bottom" justify="space-between">
                <Col md={12}>
                  <ImagePicker
                    file={file.image}
                    onChooseFile={onChooseFile("image")}
                    onRemoveFile={onRemoveFile("image")}
                    label={"Image (English)"}
                  />
                </Col>
                <Col md={12}>
                  <ImagePicker
                    file={file.imageAr}
                    onChooseFile={onChooseFile("imageAr")}
                    onRemoveFile={onRemoveFile("imageAr")}
                    label={"Image (Arabic)"}
                  />
                </Col>
              </Row>
            ) : null}
            {notificationType === "Payment" && (
              <Form.Item name={"isPaymentDue"} valuePropName={"checked"}>
                <Checkbox>{"Is Payment Due?"}</Checkbox>
              </Form.Item>
            )}

            <Button
              type={"primary"}
              size={"large"}
              loading={loading || uploading}
              htmlType={"submit"}
            >
              {loading
                ? "Loading..."
                : uploading
                ? "Uploading Image..."
                : "Send Notification"}
            </Button>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};
export default Notifications;
