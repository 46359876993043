import { Button, Form, Input, Select, Row, Col, Image } from "antd";
import React from "react";

import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import placeholderImage from "../../../../assets/img/default.png";

const { Option } = Select;
const AllFeaturesForm = ({
  onSubmit,
  loading,
  formName,
  onCloseForm,
  uploading,
  onChooseFile,
  file,
}) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Label (EN)"}
              name={"label"}
              rules={setRules("Label (EN)")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Label (AR)"}
              name={"labelAr"}
              rules={setRules("Label (AR)")}
            >
              <Input placeholder="Add here" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={setRules("Title (EN)")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="Add here" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        <Row align="bottom" gutter={[10]}>
          <Col md={12}>
            <Row align="bottom" gutter={[10]}>
              <Col>
                {<Image width={"10rem"} src={file ?? placeholderImage} />}
              </Col>

              <input
                type="file"
                onChange={(e) => onChooseFile(e)}
                name="image"
                required={!file}
                style={{ marginLeft: 10 }}
                accept="image/png, image/gif, image/jpeg"
              />
              <br />
            </Row>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading || uploading}
            // type="primary"
            className="ant__primary__button"
          >
            {loading ? "Updating..." : uploading ? "Uploafing" : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AllFeaturesForm;
