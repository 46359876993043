import { Button, Card, Col, Form, Input, Row, Select, Spin, Tabs } from "antd";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import { _updateApplications } from "actions/applications/applications-actions";

import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import PreFinalApprovalForm from "components/Forms/pre-final-approval-form/PreFinalApprovalForm";
import { useDispatch } from "react-redux";

import { addStageApproval } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { LoadingOutlined } from "@ant-design/icons";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { _getDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";
import SchEvaluationTabDetails from "components/sch/evaluation/SchEvaluationTabDetails";
import { updateEvaluationRubricPoints } from "apis/SCH/schRequests";
import { applicationProcess } from "apis/applications-apis/applications";
import SchEvaluationTabReadOnlyDetails from "components/sch/evaluation/SchEvaluationTabReadOnlyDetails";
import { addCommitteeApproval } from "apis/SCH/schRequests";
import { rejectCommitteeApproval } from "apis/SCH/schRequests";
import SchFinalApprovalAddRewards from "components/sch/evaluation/SchFinalApprovalAddRFewards";
import { onAssignRewardsForFinalApproval } from "apis/SCH/schRequests";
import { onAcceptSchForFinalApproval } from "apis/SCH/schRequests";
import { onAddRemarksForAcceptSchForFinalApproval } from "apis/SCH/schRequests";
import { onAddRemarksForRejectSchForFinalApproval } from "apis/SCH/schRequests";
import SCHApplicationInfo from "components/schApplicationInfo/SCHApplicationInfo";
const { TabPane } = Tabs;
const { Option } = Select;

const SCHFinalApproval = (props) => {
  const [showApproveModal, toggleApproveModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const [activeTab, setActiveTab] = React.useState("Father");

  // .............for accept application at finalApproval stage
  const [accpetForm, setAccpetForm] = React.useState(false);
  const [accpetApprovalForm] = Form.useForm();

  // .............for accept application at finalApproval stage
  // .............for reject application at finalApproval stage
  const [rejectForm, setRejectForm] = React.useState(false);
  const [rejectApprovalForm] = Form.useForm();

  // .............for accept application at finalApproval stage
  const applicationId = props?.application?.id;

  const stageStatusCode =
    props.application?.stage?.status?.code ||
    currentStageDetails?.stageStatus?.code;

  React.useEffect(() => {
    if (applicationId) {
      getLogs();
    }
  }, [applicationId]);

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} required`,
      },
    ];
  };

  const handelApplicationProcess = async (action, note) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: props.application?.id,
        action: action,
        note: note,
      });
      if (response?.status == 200) {
        setLoading(false);
        if (action === "send_for_committee_approval") {
          successMessage({
            message: "Application has been send for committe approval",
          });
        } else if (action === "recalculate_evaluation_score") {
          successMessage({
            message: "Evaluation points recalculated successfully",
          });
        } else {
          successMessage({
            message: "Comment added successfully",
          });
        }
        props.refetch();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const getLogs = async () => {
    try {
      const response = await getApplicationStagesLogs({ id: applicationId });
      if (response.status == 200) {
        let applicationCurrentStage = response.data.data.find(
          (item) => item.stageSlug == "final_approval"
        );
        setcurrentStageDetails(applicationCurrentStage);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onApproveClick = () => toggleApproveModal(true);

  const onSave = (values, campusCode) => {
    toggleApproveModal(false);
    onAssignReawardStagesApproval(values.id, campusCode);
  };

  const onAssignReawardStagesApproval = async (id, campusCode) => {
    try {
      setLoading(true);
      const data = {
        applicationId: applicationId,
        rewardId: Number(id),
        campus: campusCode,
      };
      const response = await onAssignRewardsForFinalApproval({ data: data });
      if (response.status == 200) {
        successMessage({ message: "Rewards assign successfully" });
        setLoading(false);
        props.refetch();
        getLogs();
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onAccept = (values) => {
    setAccpetForm(false);
    onAcceptFinalApprovalForSch(values);
  };
  const onAcceptFinalApprovalForSch = async (values) => {
    try {
      setLoading(true);
      const data = {
        appStageId: props.application?.stage?.id,
        isApproved: true,
        notes: values.notes,
      };
      const response = await onAddRemarksForAcceptSchForFinalApproval({
        data: data,
      });
      if (response.status === 200) {
        successMessage({ message: "Remarks added successfully" });
        setLoading(false);
        props.refetch();
        getLogs();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const onReject = (values) => {
    setRejectForm(false);
    onRejectFinalApprovalForSch(values);
  };
  const onRejectFinalApprovalForSch = async (values) => {
    try {
      setLoading(true);
      const data = {
        appStageId: props.application?.stage?.id,
        isApproved: false,
        notes: values.notes,
      };
      const response = await onAddRemarksForRejectSchForFinalApproval({
        data: data,
      });
      if (response.status === 200) {
        successMessage({ message: "Remarks added successfully" });
        setLoading(false);
        props.refetch();
        getLogs();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const renderRemarksDetails = () => {
    if (!props.application.hasStagePermission) return null;
    if (!currentStageDetails?.approvals.length) return null;
    return (
      <Card
        hoverable
        className="antCardBorder antCard"
        style={{ marginTop: 20 }}
      >
        <h6>Final Remarks</h6>
        <h4>
          {currentStageDetails?.approvals[0].isApproved
            ? "Accepted"
            : "Declined"}
        </h4>
        <hr />
        <p style={{ overflowX: "clip" }}>
          {currentStageDetails?.approvals[0].notes}
        </p>
      </Card>
    );
  };

  const getBtnTitle = () => {
    // waiting approval
    if (
      stageStatusCode === "SG830" ||
      stageStatusCode === "SG230" ||
      stageStatusCode === "SG530" ||
      stageStatusCode === "SG1130"
    ) {
      return "Add Remarks";
    } else if (
      [
        "SG840",
        "SG850",
        "SG890",
        "SG240",
        "SG250",
        "SG290",
        "SG540",
        "SG550",
        "SG590",
        "SG1140",
        "SG1150",
        "SG1190",
      ].includes(stageStatusCode)
    ) {
      // accepted, declined, or waiting approval in final approval stage
      return "Completed";
    } else if (
      stageStatusCode == "SG860" ||
      stageStatusCode == "SG260" ||
      stageStatusCode == "SG560" ||
      stageStatusCode === "SG1160"
    ) {
      // cancelled
      return "Cancelled";
    } else if (stageStatusCode === "SG1680" || stageStatusCode === "SG1700") {
      return "Add Rewards";
    } else return null;
  };

  const getTitle = () => {
    return props.application.stage?.status?.internalStatus || "";
  };

  const getDescription = () => {
    if (
      stageStatusCode == "SG830" ||
      stageStatusCode == "SG230" ||
      stageStatusCode == "SG530" ||
      stageStatusCode === "SG1130"
    ) {
      return "Please review the application and add your remarks";
    } else if (
      [
        "SG840",
        "SG850",
        "SG240",
        "SG250",
        "SG540",
        "SG550",
        "SG1140",
        "SG1150",
        "SG1190",
      ].includes(stageStatusCode)
    ) {
      return "The stage has been completed successfully";
    } else if (
      stageStatusCode == "SG860" ||
      stageStatusCode == "SG260" ||
      stageStatusCode == "SG560" ||
      stageStatusCode === "SG1160"
    ) {
      return "The stage has been cancelled";
    } else return null;
  };

  const updateOtherEvaluationPoints = async (data) => {
    try {
      setLoading(true);
      const response = await updateEvaluationRubricPoints({ data });

      if (response.status === 200) {
        successMessage({ message: "updated successfully" });
        setLoading(false);
        props.refetch();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onSubmit = (val, type, obj, score, id) => {
    if (type === "other") {
      const data = {
        id: obj.id,
        title: obj.title,
        titleAr: obj.titleAr,
        evaluationId: "",
        score: Number(val.points),
      };
      updateOtherEvaluationPoints(data);
    } else if (type === "comment") {
      handelApplicationProcess("evaluation_comment", val.comment);
    } else {
      const data = {
        id: obj.id,
        title: obj.title,
        titleAr: obj.titleAr,
        evaluationId: Number(id),
        score: Number(score),
      };

      updateOtherEvaluationPoints(data);
    }
  };

  const acceptByCommitteeAprovalteam = async (values) => {
    try {
      setLoading(true);
      const data = {
        appStageId: props.application?.stage?.id,
        isApproved: true,
        notes: values.comment,
      };
      const response = await addCommitteeApproval({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Approved successfully" });
        setLoading(false);
        props.refetch();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const rejectByCommitteeAprovalteam = async (values) => {
    try {
      setLoading(true);
      const data = {
        appStageId: props.application?.stage?.id,
        isApproved: false,
        notes: values.comment,
      };
      const response = await rejectCommitteeApproval({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Approved successfully" });
        setLoading(false);
        props.refetch();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onSubmitCommitteeApproval = (values) => {
    acceptByCommitteeAprovalteam(values);
  };
  const onSubmitCommitteeRejected = (values) => {
    rejectByCommitteeAprovalteam(values);
  };

  const rewardsConfig = props.application?.eService?.rewardsConfig.filter(
    (schType) =>
      schType.scholarshipTypeId ===
      props.application?.details?.scholarshipType?.valueMeta?.id
  );

  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={loader}>
        {props.application ? (
          <div>
            <Row gutter={[20, 0]}>
              <Col sm={24} md={12}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>
              {props.application.hasStagePermission && (
                <Col sm={24} md={12}>
                  <Row
                    gutter={[16, 10]}
                    justify={"space-between"}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Card hoverable className="antCardBorder antCard">
                        <Row align="middle" justify="space-between">
                          <div>
                            <h5>{getTitle()}</h5>
                            <p style={{ fontSize: "12px" }}>
                              {getDescription()}
                            </p>
                          </div>
                          <Row>
                            <div style={{ width: "20px" }}></div>

                            <Button
                              type="primary"
                              className="appButton"
                              onClick={onApproveClick}
                              disabled={[
                                "SG840",
                                "SG850",
                                "SG860",
                                "SG240",
                                "SG250",
                                "SG260",
                                "SG1140",
                                "SG1150",
                                "SG1160",
                              ].includes(stageStatusCode)}
                            >
                              {getBtnTitle()}
                            </Button>
                          </Row>
                          {stageStatusCode === "SG1720" && (
                            <Row>
                              <Button
                                type="primary"
                                className="appButton"
                                onClick={() => setAccpetForm(true)}
                              >
                                Accept
                              </Button>
                              <div style={{ width: "20px" }}></div>

                              <Button
                                type="primary"
                                className="appButton"
                                onClick={() => setRejectForm(true)}
                              >
                                Reject
                              </Button>
                            </Row>
                          )}
                        </Row>
                      </Card>
                      <SCHApplicationInfo application={props.application} />
                    </div>
                  </Row>
                </Col>
              )}
            </Row>
            {/* {["SG840", "SG850", "SG240", "SG250"].includes(stageStatusCode)
              ? renderRemarksDetails()
              : null} */}
            {renderRemarksDetails()}
            <Tabs
              activeKey={activeTab}
              onChange={(tab) => setActiveTab(tab)}
              style={{ marginTop: "1rem" }}
              tabBarGutter={40}
              // centered
            >
              <TabPane tab={<h6>Father</h6>} key="Father">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"sch_father_info"}
                />
              </TabPane>
              <TabPane tab={<h6>Mother</h6>} key="Mother">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"sch_mother_info"}
                />
              </TabPane>

              <TabPane tab={<h6>General</h6>} key="General">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"sch-detail"}
                />
              </TabPane>
              <TabPane tab={<h6>Students Details</h6>} key="StudentsDetails">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"sch_students_info"}
                />
              </TabPane>
              <TabPane tab={<h6>Criteria</h6>} key="Criteria">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"criteria"}
                />
              </TabPane>
              <TabPane
                tab={<h6>Documents</h6>}
                // tab={<h5 className={styles.tabTitle}>Documents</h5>}
                key="4"
              >
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>
              <TabPane tab={<h6>Evaluation</h6>} key="evaluation">
                <>
                  <SchEvaluationTabReadOnlyDetails
                    application={props.application}
                    onSubmit={onSubmit}
                    handelApplicationProcess={handelApplicationProcess}
                  />
                </>
              </TabPane>
              <TabPane tab={<h6>Stages History</h6>} key="logs">
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>

            <CustomModal
              visibility={showApproveModal}
              handleCancel={() => toggleApproveModal(false)}
              title="Add Rewards"
            >
              <SchFinalApprovalAddRewards
                onSave={onSave}
                rewardsConfig={rewardsConfig}
                schType={
                  props.application?.details?.scholarshipType?.valueMeta?.title
                }
                currentApplication={props.application}
              />
            </CustomModal>
            <CustomModal
              visibility={accpetForm}
              handleCancel={() => setAccpetForm(false)}
              title="Add Remarks"
            >
              <Form
                layout="vertical"
                onFinish={onAccept}
                form={accpetApprovalForm}
              >
                <Row gutter={10}>
                  <Col md={24}>
                    <Form.Item
                      name={"notes"}
                      label="Notes"
                      rules={setRules("Notes")}
                    >
                      <Input.TextArea
                        placeholder="Notes"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <br />
                <Row justify="end">
                  <Button htmlType="submit" loading={loading}>
                    {loading ? "Saving..." : "Save"}
                  </Button>
                </Row>
              </Form>
            </CustomModal>
            <CustomModal
              visibility={rejectForm}
              handleCancel={() => setRejectForm(false)}
              title="Add Remarks"
            >
              <Form
                layout="vertical"
                onFinish={onReject}
                form={rejectApprovalForm}
              >
                <Row gutter={10}>
                  <Col md={24}>
                    <Form.Item
                      name={"notes"}
                      label="Notes"
                      rules={setRules("Notes")}
                    >
                      <Input.TextArea
                        placeholder="Notes"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <br />
                <Row justify="end">
                  <Button htmlType="submit" loading={loading}>
                    {loading ? "Saving..." : "Save"}
                  </Button>
                </Row>
              </Form>
            </CustomModal>
          </div>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default SCHFinalApproval;
