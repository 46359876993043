import client from "apis/config";

export const getSccConductCasesDashboardStats = async ({
  year = "",
  term = "",
  month = "",
  campus = "",
  grade = "",
}) => {
  return await client.get(
    `scc/cases/dashboard?year=${year}&term=${term}&month=${month}&campus=${campus}&grade=${grade}`
  );
};
