import client from "apis/config";

export const getNewsAPI = async (page = 1) => {
  return await client.get(`news?page=${page}&limit=10`);
};
export const addNewsAPI = async (data) => {
  return await client.post("news", data);
};
export const updateNewsAPI = async (data, id) => {
  return await client.put(`news/${id}`, data);
};
export const deleteNewsAPI = async (id) => {
  return await client.delete(`news/${id}`);
};
