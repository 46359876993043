import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Form, Tooltip } from "antd";
import CustomModal from "components/CustomModal/CustomModal";
import RequestStatusesForm from "components/statusesTables/RequestStatusesForm";
import { getRequestApplicationStatuses } from "apis/statuses-apis/appandStageStatuses";
import { requestErrorHandel } from "utils/helpers/helpers";
import { updateRequestApplicationStatuses } from "apis/statuses-apis/appandStageStatuses";
import { successMessage } from "utils/helpers/helpers";
import CommonTable from "components/Tables/CommonTable";

function RequestStatuses() {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestStatuses, setRequestStatuses] = useState([]);
  const [formLayout] = useState("vertical");
  const [componentSize] = useState("large");
  const [editApplicationFields, setEditApplicationFiels] = useState(null);

  useEffect(() => {
    if (editApplicationFields) {
      form.setFieldsValue(editApplicationFields);
    } else {
      form.resetFields();
    }
  }, [editApplicationFields]);

  useEffect(() => {
    fetchRequestStatuses();
  }, []);

  const fetchRequestStatuses = async () => {
    try {
      setLoading(true);
      const response = await getRequestApplicationStatuses({
        type: "application",
      });
      if (response.status === 200) {
        setRequestStatuses(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const editApplication = (record) => {
    setVisible(true);
    setEditApplicationFiels(record);
    //  setApplication_id(record.id);
  };
  const onSubmitRequestStatuses = async (val) => {
    const data = {
      identifier: val.code,
      type: "application",
      internalStatus: val.internalStatus,
      internalStatusAr: val.internalStatusAr,
      externalStatus: val.externalStatus,
      externalStatusAr: val.externalStatusAr,
      description: val.description,
    };
    try {
      const resposne = await updateRequestApplicationStatuses({ data: data });
      if (resposne.status === 200) {
        setVisible(false);
        successMessage({ message: "Status has been updated" });
        fetchRequestStatuses();
      }
    } catch (error) {
      await requestErrorHandel({ error: error });
    }
  };
  const openModalForRequestStatuses = () => {
    return (
      <CustomModal
        visibility={visible}
        handleOk={() => setVisible(false)}
        handleCancel={() => setVisible(false)}
        title={<>Update Request Status</>}
      >
        <Form
          layout={formLayout}
          form={form}
          initialValues={{ layout: formLayout }}
          size={componentSize}
          onFinish={onSubmitRequestStatuses}
        >
          <RequestStatusesForm
            form={form}
            valuesForEdit={editApplicationFields}
            // loading={loading}
          />
        </Form>
      </CustomModal>
    );
  };
  return (
    <Card className="antCardBorder">
      <CommonTable
        key={"request_status"}
        loading={loading}
        data={requestStatuses}
        columns={[
          {
            title: "Identifier",
            dataIndex: "code",
            key: "code",
          },
          {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: "20%",
          },
          {
            title: "Internal Status (EN)",
            dataIndex: "internalStatus",
            key: "internalStatus",
          },
          {
            title: "Internal Status (AR)",
            dataIndex: "internalStatusAr",
            key: "internalStatusAr",
          },
          {
            title: "External Status (EN)",
            dataIndex: "externalStatus",
            key: "externalStatus",
          },
          {
            title: "External Status (AR)",
            dataIndex: "externalStatusAr",
            key: "externalStatusAr",
          },
          {
            title: "Action",
            dataIndex: "",
            key: "x",
            render: (record) => (
              <div>
                <Tooltip title="Update">
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => editApplication(record)}
                  />
                </Tooltip>
              </div>
            ),
          },
        ]}
      />
      {/* modal */}
      {openModalForRequestStatuses()}
      {/* modal */}
    </Card>
  );
}
export default RequestStatuses;
