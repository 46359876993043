import client from "apis/config";

export const getAllYearsAndHisTerms = async () => {
  return await client.get(`scc/years?limit=100`);
};

export const toggleSccYear = async ({ id }) => {
  return await client.post(`scc/years/status/${id}`);
};
export const toggleSccYearTerm = async ({ payload }) => {
  return await client.post("scc/years/term", payload);
};

export const getSccYearLogs = async ({ id }) => {
  return await client.get(`scc/years/term/${id}`);
};
export const getSccTermLogs = async ({ id, term }) => {
  return await client.get(`scc/years/term/${id}?term=${term}`);
};
