import { UPDATE_ESERVICES_ACTION } from "actions/eservices/eServicesAction";
import { DELETE_ESERVICES_ACTION } from "actions/eservices/eServicesAction";
import { ADD_ESERVICES_ACTION } from "actions/eservices/eServicesAction";
import { GET_ESERVICES_ACTION } from "actions/eservices/eServicesAction";

const initialState = {
  data: [],
  total: 0,
};

// helper
const updateEServices = (payload, allData) => {
  const dataClone = [...allData];
  const editedItemIndex = dataClone.findIndex((el) => el.id === payload.id);
  if (editedItemIndex > -1) {
    dataClone[editedItemIndex] = payload;
  }
  return dataClone;
};

const EServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ESERVICES_ACTION:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
      };
    case ADD_ESERVICES_ACTION:
      return {
        ...state,
        data: [action.payload, ...state.data],
        total: state.total + 1,
      };
    case UPDATE_ESERVICES_ACTION:
      return {
        ...state,
        data: updateEServices(action.payload, state.data),
      };
    case DELETE_ESERVICES_ACTION:
      return {
        ...state,
        data: state.data.filter((el) => el.id !== action.payload),
        total: state.total - 1,
      };
    default:
      return state;
  }
};
export default EServicesReducer;
