/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Button, Card, Col, Row, Spin, Table, Tabs } from "antd";
import styles from "../../../../styles/applicationDetails.module.css";
import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import EntranceTestScheduleFormForKG from "components/Forms/entrance-test-form/EntranceTestScheduleFormForKG";
import EntranceTestScheduleFormForHigherGrades from "components/Forms/entrance-test-form/EntranceTestScheduleFormForHigherGrades";
import TestResultHigherGradesForm from "components/Forms/entrance-test-form/TestResultHigherGradesForm";
import TestResultKGForm from "components/Forms/entrance-test-form/TestResultKGForm";
import { useDispatch, useSelector } from "react-redux";
import ApplicationInformationReadOnlyForm from "../../../../components/Forms/ApplicationInformationReadOnlyForm";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import {
  scheduleEntranceTestAPI,
  addEntranceTestMarksAPI,
} from "apis/applications-apis/entrance-test-apis";
import { requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { convertToTitleCase } from "utils/helpers/helpers";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import { NotificationManager } from "react-notifications";
import { _updateApplications } from "actions/applications/applications-actions";
import { applicationProcess } from "apis/applications-apis/applications";
import moment from "moment";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { renderLoader } from "utils/helpers/helpers";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";

const { TabPane } = Tabs;

const EntranceTestScheduleDetails = (props) => {
  const [scheduleModal, toggleScheduleModal] = React.useState(false);
  const [resultModal, toggleResultModal] = React.useState(false);
  const [grade, setGrade] = React.useState("1");
  const [schedule, setSchedule] = React.useState();
  // const [result, addResult] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const [autofillValues, setAutofill] = React.useState();
  const applicationId = props?.application?.id;
  const allGrades = useSelector((state) => state.metaData.grades);

  React.useEffect(() => {
    props.application && getLogs();
  }, [props.application]);

  const getLogs = async () => {
    try {
      setLoading(true);
      const response = await getApplicationStagesLogs({ id: applicationId });
      if (response.status == 200) {
        // const _currentStageDetails = response.data.data.find(
        //   (item) => item.stageSlug == application.stage.slug
        // );
        var applicationCurrentStage = currentStageDetails;
        if (
          applicationCurrentStage == undefined ||
          applicationCurrentStage == ""
        ) {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == props.application.stage.slug
          );
        } else {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == currentStageDetails.stageSlug
          );
        }
        setcurrentStageDetails(applicationCurrentStage);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  React.useEffect(() => {
    if (props.application) {
      const applicationsGrade = props.application?.childDetails.newGrade;
      const grade = allGrades.find(
        (el) => el.id.toString() === applicationsGrade.value
      );
      setGrade(grade.level);
    }
  }, [props.application]);

  const onFinishScheduleForm = async (val) => {
    setAutofill(undefined);
    let data = {
      applicationId: applicationId,
      interviewType: val.interviewType,
      address: val.interviewType === "online" ? "" : val.address,
      notes: val.notes,
      subjectsInterviewTimes: [],
    };
    if (grade > 0) {
      if (val.subjectEnglishDateAndTime) {
        data.subjectsInterviewTimes.push({
          subjectName: "english",
          time: val.subjectEnglishDateAndTime,
        });
      }
      if (val.subjectMathDateAndTime) {
        data.subjectsInterviewTimes.push({
          subjectName: "math",
          time: val.subjectMathDateAndTime,
        });
      }
      if (val.subjectArabicDateAndTime) {
        data.subjectsInterviewTimes.push({
          subjectName: "arabic",
          time: val.subjectArabicDateAndTime,
        });
      }
    }

    try {
      setLoading(true);
      const result = await scheduleEntranceTestAPI(data);
      if (result.data.statusCode === 200) {
        successMessage({ message: "Entrance test has been scheduled!" });
        toggleScheduleModal(false);
        setSchedule(result.data.data.scheduleId);
        props.refetch();
        getLogs();
      }
    } catch (e) {
      await requestErrorHandel({ error: e });
      setLoading(false);
      toggleScheduleModal(false);
    }
  };

  const onFinishResultForm = async (val) => {
    // if (grade > 0) {
    //   if (
    //     Number(val.subjectArabic.obtainMarks) >
    //     Number(val.subjectArabic.totalMarks)
    //   ) {
    //     NotificationManager.error(
    //       "Obtained marks in arabic can not be greater than the total marks"
    //     );

    //     return;
    //   }
    // }
    // let data = {
    //   scheduleId:
    //     currentStageDetails?.schedules[
    //       currentStageDetails?.schedules.length - 1
    //     ]?.id,
    //   isRecommended: val.recommendation,
    //   notes: val.notes,
    //   subjectResults: [],
    // };
    const schedules = currentStageDetails?.schedules;
    const schedule = schedules.reduce((max, obj) => {
      return obj.id > max.id ? obj : max;
    }, schedules[0]);

    let data = {
      scheduleId: schedule?.id,
      isRecommended: val.recommendation,
      notes: val.notes,
      subjectResults: [],
    };
    if (grade > 0) {
      if (val.subjectEnglish?.obtainMarks) {
        data.subjectResults.push({
          subjectName: "english",
          obtainedMarks: Number(val.subjectEnglish.obtainMarks),
        });
      }
      if (val.subjectMath?.obtainMarks) {
        data.subjectResults.push({
          subjectName: "math",
          obtainedMarks: Number(val.subjectMath.obtainMarks),
        });
      }
      if (val.subjectArabic?.obtainMarks) {
        if (
          Number(val.subjectArabic.obtainMarks) >
          Number(val.subjectArabic.totalMarks)
        ) {
          NotificationManager.error(
            "Obtained marks in arabic can not be greater than the total marks"
          );

          return;
        }
        data.subjectResults.push({
          subjectName: "arabic",
          totalMarks: Number(val.subjectArabic.totalMarks),
          obtainedMarks: Number(val.subjectArabic.obtainMarks),
        });
      }
    }

    try {
      setLoading(true);
      const result = await addEntranceTestMarksAPI(data);
      if (result.data.statusCode === 200) {
        successMessage({
          message:
            grade <= 0
              ? "Remarks added successfully"
              : "Result added scuccessfully",
        });
        toggleResultModal(false);
        // getDetails();
        props.refetch();

        getLogs();
        // dispatch(_updateApplications(applicationId, result.data.data));
      }
    } catch (e) {
      await requestErrorHandel({ error: e });
      toggleResultModal(false);
      setLoading(false);
    }
  };

  const handelApplicationProcess = async (action) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: props.application?.id,
        action: action,
        schoolId: "",
      });
      if (response?.status == 200) {
        // dispatch(_updateApplications(application.id, response.data.data));
        // getDetails();
        props.refetch();
        getLogs();
        successMessage({
          message: "Power School integrated successfully",
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const getTitle = () => {
    return currentStageDetails?.stageStatus?.internalStatus;
  };

  const getDescription = () => {
    if (currentStageDetails?.stageStatus?.code == "SG032") {
      return "Please schedule entrance exam for this application";
    } else if (currentStageDetails?.stageStatus?.code == "SG033") {
      return grade <= 0
        ? "Please add remarks for this application"
        : "Please add entrance exam result";
    } else if (currentStageDetails?.stageStatus?.code == "SG034") {
      return "Please integrate PS";
    } else if (currentStageDetails?.stageStatus?.code == "SG035") {
      return "The stage has been completed";
    } else if (currentStageDetails?.stageStatus?.code == "SG036") {
      return "Please schedule retest entrance exam for this application";
    } else if (currentStageDetails?.stageStatus?.code == "SG037") {
      return grade <= 0
        ? "Please add remarks for this application"
        : "Please add retest entrance exam result";
    } else if (currentStageDetails?.stageStatus?.code == "SG038") {
      return "Please re-integrate PS";
    } else if (currentStageDetails?.stageStatus?.code == "SG039") {
      return "The stage has been completed";
    } else if (currentStageDetails?.stageStatus?.code == "SG040") {
      return "The stage has been cancelled";
    }
  };

  const getButton = () => {
    if (
      currentStageDetails?.stageStatus?.code == "SG032" ||
      currentStageDetails?.stageStatus?.code == "SG036"
    ) {
      return {
        name: "Schedule Entrance Exam",
        onClick: () => toggleScheduleModal(true),
      };
    } else if (
      currentStageDetails?.stageStatus?.code == "SG033" ||
      currentStageDetails?.stageStatus?.code == "SG037"
    ) {
      return grade <= 0
        ? { name: "Add Remarks", onClick: () => toggleResultModal(true) }
        : { name: "Add Result", onClick: () => toggleResultModal(true) };
    } else if (
      currentStageDetails?.stageStatus?.code == "SG034" ||
      currentStageDetails?.stageStatus?.code == "SG038"
    ) {
      return {
        name: "Integrate PS",
        onClick: () => handelApplicationProcess("integrate_power_school"),
      };
    } else if (currentStageDetails?.stageStatus?.code == "SG040") {
      return { name: "The stage has been cancelled", onClick: () => null };
    } else if (
      currentStageDetails?.stageStatus?.code == "SG035" ||
      currentStageDetails?.stageStatus?.code == "SG039"
    ) {
      return { name: "Completed", onClick: () => props.history.goBack };
    } else {
      return { name: "", onClick: () => null };
    }
  };
  const getSubjectTimeFromLogs = (subjectName, scheduleArray) => {
    let item = scheduleArray.find((el) => el.subjectName === subjectName);
    return item ? moment(item.time) : "";
  };

  const renderScheduleDetails = () => {
    const lastIndex = currentStageDetails?.schedules?.length - 1;
    return currentStageDetails?.schedules?.map((_schedule, index) => {
      return (
        <Card
          key={_schedule.id}
          className="antCard"
          style={{ marginTop: "20px" }}
        >
          <div>
            {index === 1 ? <h3>Retest</h3> : null}
            <div>
              {grade <= 0 ? (
                <p>
                  Date:{" "}
                  {formateDateWithMoment(
                    _schedule.scheduleTime,
                    "DD MMM YYYY hh:mm A"
                  )}
                </p>
              ) : null}
              <p>
                <strong>Type</strong>:{" "}
                {convertToTitleCase(_schedule.scheduleType)}
              </p>
              {_schedule.scheduleType !== "online" ? (
                <p>
                  <strong>Instructions</strong>: {_schedule.address}
                </p>
              ) : null}
              <p>
                <strong>Notes</strong>: {_schedule.notes}
              </p>
            </div>
          </div>
          <Row
            justify="space-between"
            align="top"
            style={{ marginTop: "2rem" }}
          >
            <Col sm={24} md={12}>
              {grade > 0 ? (
                <Table
                  dataSource={_schedule.subjectsTestTiming}
                  columns={[
                    {
                      title: "Subject Name",
                      dataIndex: "",
                      key: "subjectName",

                      render: (record) =>
                        convertToTitleCase(record.subjectName),
                    },
                    {
                      title: "Date and Time",
                      dataIndex: "time",
                      key: "time",
                      render: (record) =>
                        moment(record).format("DD MMM YYYY hh:mm A"),
                    },
                  ]}
                  pagination={false}
                ></Table>
              ) : null}
            </Col>

            <Col>
              {(currentStageDetails?.stageStatus?.code == "SG033" ||
                currentStageDetails?.stageStatus?.code == "SG037") &&
              (props.application?.hasSchedulePermission || ifSuperAdmin) &&
              index == lastIndex ? (
                <Button
                  type="primary"
                  className="appButton"
                  onClick={onRescheduleTest}
                >
                  Reschedule
                </Button>
              ) : null}
            </Col>
          </Row>
        </Card>
      );
    });
  };

  const renderResultDetails = () => {
    return currentStageDetails?.schedules?.map((_schedule, index) => {
      if (_schedule.remarks.length == 0) {
        return null;
      }
      return (
        <>
          <Card
            key={index}
            className="antCardBorder antCard"
            style={{ margin: "1rem 0" }}
          >
            {index === 1 ? <h3>Retest</h3> : null}

            {grade > 0 && (
              <Table
                key={index}
                dataSource={_schedule.remarks[0].subjectResults}
                columns={[
                  {
                    title: "Subject Name",
                    dataIndex: "",
                    render: (record) => convertToTitleCase(record.subjectName),
                  },

                  {
                    title: "Obtained Marks",
                    dataIndex: "obtainedMarks",
                  },
                  {
                    title: "Total Marks",
                    dataIndex: "totalMarks",
                  },
                  {
                    title: "Percentage",
                    dataIndex: "",
                    render: (record) => {
                      if (record.totalMarks) {
                        return (
                          parseFloat(
                            record.obtainedMarks == 0
                              ? 0
                              : (
                                  (record.obtainedMarks / record.totalMarks) *
                                  100
                                ).toFixed(2)
                          ) + "%"
                        );
                      } else {
                        return "N/A";
                      }
                    },
                  },
                ]}
                pagination={false}
                style={{ margin: "1rem 0" }}
              ></Table>
            )}
            <h5>
              {_schedule.remarks[0].isRecommended
                ? "Recommended"
                : "Not Recommended"}
            </h5>
            <hr />
            <p>{_schedule.remarks[0].notes}</p>
          </Card>
        </>
      );
    });
  };

  const onRescheduleTest = () => {
    let schedule = currentStageDetails.schedules
      ? currentStageDetails.schedules[0]
      : null;
    if (schedule) {
      setAutofill(
        grade <= 0
          ? {
              interviewType: schedule.scheduleType,
              notes: schedule.notes,
              dateAndTime: moment(schedule.scheduleTime),
              address: schedule.address,
            }
          : {
              interviewType: schedule.scheduleType,
              notes: schedule.notes,
              subjectEnglishDateAndTime: getSubjectTimeFromLogs(
                "english",
                schedule.subjectsTestTiming
              ),
              subjectMathDateAndTime: getSubjectTimeFromLogs(
                "math",
                schedule.subjectsTestTiming
              ),
              subjectArabicDateAndTime: getSubjectTimeFromLogs(
                "arabic",
                schedule.subjectsTestTiming
              ),
              address: schedule.address,
            }
      );
    }
    toggleScheduleModal(true);
  };
  const ifSuperAdmin = useSelector(
    (state) => state.login.userInfo?.role === "super-admin"
  );
  const hasActionsPermision =
    ifSuperAdmin ||
    (props.application.hasStagePermission &&
      props.application?.hasSchedulePermission &&
      (currentStageDetails?.stageStatus?.code === "SG032" ||
        currentStageDetails?.stageStatus?.code === "SG036")) ||
    (props.application?.hasRemarksPermission &&
      ["SG033", "SG034", "SG037", "SG038"].includes(
        currentStageDetails?.stageStatus?.code
      ))
      ? true
      : false;

  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={renderLoader}>
        {props.application ? (
          <div>
            <Row gutter={[20, 12]}>
              <Col sm={24} md={hasActionsPermision ? 12 : 24}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>
              {hasActionsPermision && (
                <Col sm={24} md={12}>
                  <Card hoverable className="antCardBorder antCard">
                    <Row align="middle" justify="space-between">
                      <div>
                        <h5>{getTitle() || ""}</h5>
                        <p style={{ fontSize: "12px" }}>
                          {getDescription() || ""}
                        </p>
                      </div>
                      <Button
                        type="primary"
                        className="appButton"
                        disabled={
                          currentStageDetails?.stageStatus?.code == "SG035" ||
                          currentStageDetails?.stageStatus?.code == "SG039" ||
                          currentStageDetails?.stageStatus?.code == "SG040"
                            ? true
                            : false
                        }
                        onClick={getButton().onClick}
                      >
                        {getButton().name}
                      </Button>
                    </Row>
                  </Card>
                </Col>
              )}
            </Row>

            {props.application.hasStagePermission && (
              <>
                {[
                  "SG034",
                  "SG035",
                  "SG036",
                  "SG037",
                  "SG038",
                  "SG039",
                ].includes(currentStageDetails?.stageStatus?.code) ? (
                  <Card
                    hoverable
                    className="antCard"
                    style={{ margin: "1rem 0" }}
                  >
                    <h4>{grade <= 0 ? "Remarks" : "Entrance Exam Result"}</h4>
                    {renderResultDetails()}
                  </Card>
                ) : null}
                {[
                  "SG033",
                  "SG034",
                  "SG035",
                  "SG036",
                  "SG037",
                  "SG038",
                  "SG039",
                ].includes(currentStageDetails?.stageStatus?.code) ? (
                  <Card hoverable className="antCard">
                    <div>
                      <h5>Schedule Details</h5>
                      <br />
                    </div>
                    {renderScheduleDetails()}
                  </Card>
                ) : null}
              </>
            )}
            <Tabs className="applicationDetailsTab" defaultActiveKey="1">
              <TabPane
                tab={<h5 className={styles.tabTitle}>Father Information</h5>}
                key="1"
              >
                <div>
                  <ApplicationInformationReadOnlyForm
                    application={props.application}
                    type={"father"}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Mother Information</h5>}
                key="2"
              >
                <div>
                  <ApplicationInformationReadOnlyForm
                    application={props.application}
                    type={"mother"}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Child Information</h5>}
                key="3"
              >
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"child"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Documents</h5>}
                key="4"
              >
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Stages History</h5>}
                key="5"
              >
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>
            <CustomModal
              visibility={scheduleModal}
              title={"Schedule Entrance Exam"}
              handleCancel={() => toggleScheduleModal(false)}
            >
              {grade <= 0 ? (
                <EntranceTestScheduleFormForKG
                  onFinish={onFinishScheduleForm}
                  autoFill={autofillValues}
                  loading={loading}
                />
              ) : (
                <EntranceTestScheduleFormForHigherGrades
                  onFinish={onFinishScheduleForm}
                  autoFill={autofillValues}
                  loading={loading}
                  application={props.application}
                />
              )}
            </CustomModal>
            <CustomModal
              visibility={resultModal}
              title={
                grade <= 0
                  ? "Add Remarks (Kindergarten)"
                  : "Add Result (Grade 1 - 12)"
              }
              handleCancel={() => toggleResultModal(false)}
            >
              {grade <= 0 ? (
                <TestResultKGForm
                  onFinish={onFinishResultForm}
                  loading={loading}
                />
              ) : (
                <TestResultHigherGradesForm
                  onFinish={onFinishResultForm}
                  loading={loading}
                  application={props.application}
                />
              )}
            </CustomModal>
          </div>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default EntranceTestScheduleDetails;
