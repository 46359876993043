import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { getFullDayName } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { onPartialRefundRequest } from "views/FSM/bookings/api";

const { Option } = Select;

const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} is required`,
    },
  ];
};

const PartialRefundForLTB = ({ booking, refetch, closeModal }) => {
  const [partialReFundForm] = Form.useForm();
  const schedules = booking?.schedules;

  const [selectedRange, setSelectedRange] = useState({
    start: null,
    end: null,
  });
  const [loading, setLoading] = useState(false);
  const [selectedSlots, setSelectdSlots] = useState([]);
  const [uniqueDays, setUniqueDays] = useState([]);
  const [commonSlots, setCommonSlots] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [isAmountFieldDisable, setIsAmountFieldDisable] = useState(true);

  // Find the lowest and highest dates from schedules
  const lowestDate = moment.min(
    schedules.map((schedule) => moment(schedule.startAt))
  );
  const highestDate = moment.max(
    schedules.map((schedule) => moment(schedule.endAt))
  );
  useEffect(() => {
    const values = partialReFundForm.getFieldsValue(["startAt", "endAt"]);
    const startDate = moment(values.startAt).format("YYYY-MM-DD");
    const endDate = moment(values.endAt).format("YYYY-MM-DD");

    // Step 1: Filter schedules based on the date range
    const filteredSchedules = schedules.filter((schedule) => {
      const scheduleStartDate = moment(schedule.startAt).format("YYYY-MM-DD");
      const scheduleEndDate = moment(schedule.endAt).format("YYYY-MM-DD");
      return (
        moment(startDate).isSameOrBefore(scheduleEndDate) &&
        moment(endDate).isSameOrAfter(scheduleStartDate)
      );
    });

    // Step 2: Extract unique days from filtered schedules' details array
    const _uniqueDays = Array.from(
      new Set(
        filteredSchedules.reduce((days, schedule) => {
          const scheduleDays = schedule.details.map(
            (detail) => detail.dayOfWeek
          );
          return days.concat(scheduleDays);
        }, [])
      )
    );

    // Set unique days to state or use as needed
    setUniqueDays(_uniqueDays);
  }, [partialReFundForm, schedules, selectedRange]);

  // Function to disable dates
  const disabledDate = (current) => {
    return (
      current && (current < lowestDate || current > highestDate.endOf("day"))
    );
  };

  const handleDateChange = useCallback((dates) => {
    setSelectedRange({ start: dates[0], end: dates[1] });
  }, []);

  // Event handler for the Select dropdown
  const handleWeekDaySelect = (selected) => {
    setSelectedDays(selected);
    partialReFundForm.setFieldsValue({
      refundAmount: "",
    });
    // Find all details of the selected days from schedules
    const scheduleType = schedules[0].scheduleType;
    const detailsOfSelectedDays = schedules.reduce((allDetails, schedule) => {
      if (scheduleType === "fullDay") {
        // For fullDay type, filter details by selected days
        const detailsForDays = schedule.details.filter((detail) =>
          selected.includes(detail.dayOfWeek)
        );
        return allDetails.concat(detailsForDays);
      } else if (schedule.scheduleType === "slots") {
        // For slots type, find common time slots for the selected days
        const selectedDayDetails = schedule.details.filter((detail) =>
          selected.includes(detail.dayOfWeek)
        );

        // Find common slots
        const commonSlots = findCommonSlots(selectedDayDetails);

        // Store the common slots in state or use them as needed
        setCommonSlots(commonSlots);

        return allDetails;
      }
    }, []);

    if (scheduleType === "fullDay") {
      // Calculate the totalAmount for fullDay type
      const calculatedTotalAmount = detailsOfSelectedDays.reduce(
        (total, detail) => total + detail.grandTotal,
        0
      );

      // Set the refundAmount in the form
      partialReFundForm.setFieldsValue({ refundAmount: calculatedTotalAmount });
      setIsAmountFieldDisable(false);
    }
  };

  // Function to find common slots
  const findCommonSlots = (details) => {
    const commonSlotsMap = new Map();

    details.forEach((detail) => {
      const key = `${detail.startTime}-${detail.endTime}`;
      if (!commonSlotsMap.has(key)) {
        commonSlotsMap.set(key, {
          startTime: detail.startTime,
          endTime: detail.endTime,
        });
      }
    });

    return Array.from(commonSlotsMap.values());
  };

  const handleSelectSlots = (selectedSlot, index) => {
    const updatedDatta = commonSlots.map((slot) =>
      slot === selectedSlot ? { ...slot, selected: !slot.selected } : slot
    );
    setCommonSlots(updatedDatta);

    const updatedSelectedSlots = updatedDatta
      .filter((slot) => slot.selected)
      .map((slot) => ({ startTime: slot.startTime, endTime: slot.endTime }));

    setSelectdSlots(updatedSelectedSlots);
    const totalForSelectedSlots =
      calculateTotalForSelectedSlots(updatedSelectedSlots);

    partialReFundForm.setFieldsValue({
      refundAmount: totalForSelectedSlots,
    });
    setIsAmountFieldDisable(false);
  };

  // Function to calculate the total for selected slots in schedules' details
  const calculateTotalForSelectedSlots = (selectedSlots) => {
    let total = 0;

    schedules.forEach((schedule) => {
      schedule.details.forEach((detail) => {
        const slotKey = `${detail.startTime}-${detail.endTime}`;
        const isSelectedSlot = selectedSlots.some(
          (selectedSlot) =>
            slotKey === `${selectedSlot.startTime}-${selectedSlot.endTime}`
        );
        const isSelectedDay = selectedDays.includes(detail.dayOfWeek);

        if (isSelectedSlot && isSelectedDay) {
          total += detail.grandTotal;
        }
      });
    });

    return total;
  };

  const onFinish = async (values) => {
    try {
      const paylaod = {
        ...values,
        startAt: moment(values?.startAt).format("YYYY-MM-DD"),
        endAt: moment(values?.endAt).format("YYYY-MM-DD"),
        bookingId: booking?.id,
        isApproved: true,
        partialRefund: true,
        slots: selectedSlots,
      };

      setLoading(true);
      const response = await onPartialRefundRequest({ paylaod: paylaod });
      if (response.status === 200) {
        successMessage({ message: "Schdule has been udpated" });
        refetch();
        closeModal();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  return (
    <div>
      <Form
        layout="vertical"
        name={partialReFundForm}
        form={partialReFundForm}
        onFinish={onFinish}
      >
        <Row gutter={[20, 20]}>
          <Col md={12}>
            <Form.Item
              label="Start Date"
              name={"startAt"}
              rules={setRules("Start Date")}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                disabledDate={disabledDate}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="End Date"
              name={"endAt"}
              rules={setRules("End Date")}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                disabledDate={disabledDate}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Item label={"Select Week Days"} name={"days"}>
              <Select
                placeholder="Select"
                mode="multiple"
                onChange={handleWeekDaySelect}
              >
                {uniqueDays.map((el, i) => (
                  <Option value={el} key={i}>
                    {getFullDayName(el)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={40}>
          {commonSlots.map((slot, index) => {
            const isSelected = slot.selected;
            return (
              <Col md={6} key={index}>
                <div
                  key={index}
                  className={`ltb_slot_container ${
                    isSelected ? "selected" : ""
                  }`}
                  onClick={() => handleSelectSlots(slot, index)}
                >
                  <p>
                    {formatedDateWithAmAndPm(slot.startTime)} -{" "}
                    {formatedDateWithAmAndPm(slot.endTime)}
                  </p>
                </div>
              </Col>
            );
          })}
        </Row>

        <Row gutter={[20, 20]}>
          <Col md={12}>
            <Form.Item
              label="Refundable Amount"
              name={"refundAmount"}
              rules={setRules("Refundable Amount")}
            >
              <Input
                placeholder="Amount"
                type="number"
                disabled={isAmountFieldDisable}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          <Col md={24}>
            <Form.Item label="Notes" name={"notes"} rules={setRules("Notes")}>
              <TextArea placeholder="Notes" />
            </Form.Item>
          </Col>
        </Row>
        <br />

        <Row justify="end">
          <Button className="ant__primary__button" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            // loading={loading}
            type="primary"
            className="ant__primary__button"
          >
            {loading ? "Updating" : "Update"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default PartialRefundForLTB;
