import client from "apis/config";

export const fetchAllDiscounts = async ({
  limit = 10,
  page = 1,
  facilityId = "",
}) => {
  return await client.get(
    `/facility-discount?limit=${limit}&page=${page}&facilityId=${facilityId}`
  );
};

export const addNewDiscount = async ({ data }) => {
  return await client.post("/facility-discount", data);
};

export const updateDiscount = async ({ id, data }) => {
  return await client.put(`/facility-discount/${id}`, data);
};

export const deleteDiscount = async ({ id }) => {
  return await client.delete(`/facility-discount/${id}`);
};
export const getFacilitiesByDiscountCategoryType = async ({ type }) => {
  return await client.get(`/facilities/dropdown?discountType=${type}`);
};
