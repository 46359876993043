import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import placeholderImage from "../../../assets/img/default.png";

import { useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;
const TCCCourseForm = ({
  onSubmit,
  loading,
  formName,
  onChooseFile,
  uploading,
  file,
  setForGrades,
  valuesForEdit,
  onChooseIcon,
  icon,
}) => {
  const _grades = useSelector((state) => state.metaData.grades);
  const _departments = useSelector(
    (state) => state.metaData?.dropDowns?.departments
  );
  const [selectedGrades, setSelectedGrades] = React.useState([]);

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  useEffect(() => {
    const _prevGrades =
      valuesForEdit == null
        ? []
        : _grades?.filter((grade) =>
            valuesForEdit?.forGrades.includes(grade.id)
          );
    setSelectedGrades(_prevGrades);
    setForGrades(_prevGrades);
  }, [valuesForEdit]);

  const handleSelectAllGrades = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedGrades(_grades);
      setForGrades(_grades);
    } else {
      return value;
    }
  };
  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Title"} name={"title"} rules={setRules("Title")}>
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Code"} name={"code"} rules={setRules("Code")}>
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name={"departmentId"}
              rules={setRules("Department")}
              label={"Department"}
            >
              <Select placeholder="Select Department">
                {_departments?.map((el, i) => (
                  <Option key={el.id} value={el.id}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"description"}
              rules={setRules("Description")}
              label={"Description"}
            >
              <TextArea placeholder="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name={"descriptionAr"}
              rules={setRules("Description (AR)")}
              label={"Description (AR)"}
            >
              <TextArea placeholder="" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col lg={12} md={12} sm={24}>
            <Form.Item label={"Available Grades"}>
              <Select
                placeholder="Select grade"
                mode="multiple"
                value={
                  selectedGrades.length > 0
                    ? selectedGrades.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={handleSelectAllGrades}
              >
                <Option key="all" value="all">
                  Select All
                </Option>

                {_grades.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedGrades.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedGrades(
                          e.target.checked
                            ? [...selectedGrades, el]
                            : selectedGrades.filter((i) => i.id !== el.id)
                        );
                        setForGrades(
                          e.target.checked
                            ? [...selectedGrades, el]
                            : selectedGrades.filter((i) => i.id !== el.id)
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      {el.code}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col md={12}>
            <Form.Item
              name={"gradeId"}
              label="Grade"
              rules={setRules("Grade is requred")}
            >
              <Select
                placeholder="Select Grade"
                value={_grades?.map((el) => el.id)}
                mode="multiple"
              >
                {_grades?.map((gd) => (
                  <Option value={gd.id}>{gd.code}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col md={12}>
            <br />
            {/* <br /> */}
            <p></p>
            <Form.Item name={"isEnabled"} valuePropName={"checked"}>
              <Checkbox defaultChecked={false}>Active</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row align="bottom" gutter={[10]}>
          <Col md={12}>
            <Row>
              <p style={{ color: "red" }}>*</p>{" "}
              <p style={{ marginLeft: 2 }}>Cover Image</p>
            </Row>
            <Row align="bottom" gutter={[10]}>
              <Col>
                {<Image width={"10rem"} src={file ?? placeholderImage} />}
              </Col>
              <input
                type="file"
                onChange={(e) => onChooseFile(e)}
                name="coverImage"
                required={!file}
                style={{ marginLeft: 10 }}
                accept="image/png, image/gif, image/jpeg"
              />
              <br />
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <p style={{ color: "red" }}>*</p>{" "}
              <p style={{ marginLeft: 2 }}>Icon</p>
            </Row>
            <Row align="bottom" gutter={[10]}>
              <Col>
                {<Image width={"10rem"} src={icon ?? placeholderImage} />}
              </Col>
              <input
                type="file"
                onChange={(e) => onChooseIcon(e)}
                name="icon"
                required={!icon}
                style={{ marginLeft: 10 }}
                accept="image/png, image/gif, image/jpeg"
              />
              <br />
            </Row>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading || uploading}>
            {loading ? "Saving..." : uploading ? "Uploading..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default TCCCourseForm;
