export const GET_ALL_DOCUMETNS_LISTS = "GET_ALL_DOCUMETNS_LISTS";
export const ADD_NEW_DOCUMENT = "ADD_NEW_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const LOAD_MORE = "LOAD_MORE";

const getAllDocumentsLists = (data) => {
  return {
    type: GET_ALL_DOCUMETNS_LISTS,
    payload: data,
  };
};

const addNewDocument = (data) => {
  return {
    type: ADD_NEW_DOCUMENT,
    payload: data,
  };
};

//LOAD MORE
const loadMoreData = (data) => {
  return {
    type: LOAD_MORE,
    payload: data,
  };
};
export const _loadMoreDocumentConfigurationList = (data) => {
  return (dispatch) => {
    dispatch(loadMoreData(data));
  };
};
//LOAD MORE

const deleteDocument = (id) => {
  return {
    type: DELETE_DOCUMENT,
    id: id,
  };
};

const updateDocument = (id, data) => {
  return {
    type: UPDATE_DOCUMENT,
    id: id,
    payload: data,
  };
};
export const _getAllDocumentsLists = (data) => {
  return (dispatch) => {
    dispatch(getAllDocumentsLists(data));
  };
};

export const _addNewDocument = (data) => {
  return (dispatch) => {
    dispatch(addNewDocument(data));
  };
};

export const _deleteDocument = (id) => {
  return (dispatch) => {
    dispatch(deleteDocument(id));
  };
};

export const _updateDocument = (id, data) => {
  return (dispatch) => {
    dispatch(updateDocument(id, data));
  };
};
