import {
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Spin,
  Tooltip,
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  Popconfirm,
  Input,
} from "antd";

import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { fetchAllFSMBookingsWithTypes, processBooingRequest } from "../api";
import { useSelector } from "react-redux";
import ExpandedTableRow from "../ExpandedTableRow";
import { fetchAllFsmFacilities } from "views/FSM/facility-mangement/all-facilities/api";
import { successMessage } from "utils/helpers/helpers";
import moment from "moment";

const { Option } = Select;

const AllTypeBookings = (props) => {
  const serviceType = props.location.state.serviceType;
  const navigationParams = props.history.location?.state;

  const _campuses = useSelector((state) => state.metaData.campuses);
  const _bookingStatus = useSelector(
    (state) => state.metaData.applicationStatuses
  );

  const _bookingStages = useSelector(
    (state) => state.metaData.FSM_bookingTypes
  )?.find((bookingType) => bookingType.identifier === serviceType)?.stages;

  const dashboardCampus = navigationParams?.dashboardCampus;
  const dashboardFacility = navigationParams?.dashboardFacility;
  const dashboardStage = navigationParams?.dashboardStage;
  const dashboardStageStatus = navigationParams?.dashboardStageStatus;
  const dashboardApplicationStatus =
    navigationParams?.dashboardApplicationStatus;
  const dashboardGroupStatus = navigationParams?.dashboardGroupStatus;
  const fromDashboard = navigationParams?.fromDashboard;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [campus, setCampus] = useState(dashboardCampus ? dashboardCampus : "");
  const [facility, setFacility] = useState(
    dashboardFacility ? dashboardFacility : ""
  );
  const [search, setSearch] = useState(
    fromDashboard && dashboardGroupStatus ? dashboardGroupStatus : ""
  );
  const [stageStatuses, setStageStatuses] = useState([]);

  const [stage, setStage] = useState(dashboardStage ? dashboardStage : "");
  const [stageStatus, setStageStatus] = useState("");
  const [bookingStatus, setBookingStatus] = useState(
    dashboardApplicationStatus ? dashboardApplicationStatus : ""
  );

  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    if (fromDashboard) {
      const _stageStatuses = _bookingStages.find(
        (bookingStage) => bookingStage.slug === dashboardStage
      );
      if (_stageStatuses) {
        setStageStatuses(_stageStatuses?.statuses);
      }
    }
  }, []);

  const getStageStatuses = useCallback(
    (slug) => {
      const _stageStatuses = _bookingStages.find(
        (bookingStage) => bookingStage.slug === slug
      );
      if (_stageStatuses) {
        setStageStatuses(_stageStatuses?.statuses);
      }
    },
    [stageStatuses, setStageStatuses, dashboardStageStatus]
  );

  const handleChangeStageStatus = (slug) => {
    setStage(slug);
    getStageStatuses(slug);
  };

  useEffect(() => {
    getAllDayPassBookings();
  }, [
    startDate,
    endDate,
    campus,
    facility,
    page,
    search,
    stage,
    stageStatus,
    bookingStatus,
  ]);

  const getAllDayPassBookings = async () => {
    try {
      setLoading(true);
      const response = await fetchAllFSMBookingsWithTypes({
        type: serviceType,
        startAt: moment(startDate).isValid() ? startDate : "",
        endAt: moment(startDate).isValid() ? startDate : "",
        campus: campus,
        facility: facility,
        page: page,
        search: search,
        stage: stage,
        stageStatus: stageStatus,
        status: bookingStatus,
      });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  useEffect(() => {
    getAllFsmFacilities();
  }, []);

  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {}
  };

  // const onAddNewDayPassBooking = () => {
  //   props.history.push({
  //     pathname: `add-day-pass-booking`,
  //     //    state: { bookingId: record.id, serviceType },
  //   });
  // };

  const onAddNewDayPassBooking = (record) => {
    switch (serviceType) {
      case "DPB":
        props.history.push({
          pathname: `add-day-pass-booking`,
        });
        break;
      case "PHB":
        props.history.push({
          pathname: `add-per-head-booking`,
        });
        break;
      case "FFB":
        props.history.push({
          pathname: `add-full-facility-booking`,
        });
        break;
      case "EVB":
        props.history.push({
          pathname: `add-event-booking`,
        });
        break;
      case "LTB":
        props.history.push({
          pathname: `add-long-term-booking`,
        });
        break;

      default:
        break;
    }
  };
  const onViewDetials = (record) => {
    switch (serviceType) {
      case "DPB":
        props.history.push({
          pathname: `day-pass-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;
      case "PHB":
        props.history.push({
          pathname: `per-head-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;
      case "FFB":
        props.history.push({
          pathname: `full-facility-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;
      case "EVB":
        props.history.push({
          pathname: `event-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;
      case "LTB":
        props.history.push({
          pathname: `long-term-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;

      default:
        break;
    }
  };

  const expandedRowRender = (record) => {
    return (
      <div className="card__snippet">
        <ExpandedTableRow record={record} serviceType={serviceType} />
      </div>
    );
  };
  // Define the expandable configuration
  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record]);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record));
    }
  };
  const deleteBookig = async (id) => {
    const payload = {
      bookingId: id,
      action: "delete",
      notes: "",
    };
    try {
      const response = await processBooingRequest(payload);
      if (response.status === 200) {
        successMessage({ message: "Booking has been deleted successfully" });
        getAllDayPassBookings();
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const renderHeaderTableTitle = () => {
    switch (serviceType) {
      case "DPB":
        return "Day Pass Bookings";
        break;
      case "PHB":
        return "Per Head Bookings";
        break;
      case "FFB":
        return "Full Facility Bookings";
        break;
      case "EVB":
        return "Event Bookings";

        break;
      case "LTB":
        return "Long Term Bookings";
        break;

      default:
        break;
    }
  };
  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={onAddNewDayPassBooking}
          headerTitle={renderHeaderTableTitle()}
          headerSubTitle={""}
          headerBtnTitle={"Add New Booking"}
        />

        <Row gutter={10}>
          <Col md={6} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by campus"
                onChange={(e) => setCampus(e)}
                bordered={false}
                defaultValue={campus || undefined}
              >
                <Option value={""}>All</Option>
                {_campuses?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="facility" className="dropdown">
              <Select
                placeholder="Filter by facility"
                onChange={(e) => setFacility(e)}
                bordered={false}
                value={facility}
                defaultValue={facility || undefined}
              >
                <Option value={""}>All</Option>
                {facilities?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="startDate" className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Filter by start date"
                onChange={(date) =>
                  setStartDate(moment(date).format("YYYY-MM-DD"))
                }
                value={moment(startDate)}
              />
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="endDate" className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Filter by end date"
                onChange={(date) =>
                  setEndDate(moment(date).format("YYYY-MM-DD"))
                }
                value={moment(endDate)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={6} className="filterColumn">
            <Form.Item name="stage" className="dropdown">
              <Select
                placeholder="Filter by stage"
                onChange={(e) => handleChangeStageStatus(e)}
                bordered={false}
                defaultValue={stage || undefined}
              >
                <Option value={""}>All Stages</Option>
                {_bookingStages?.map((el, i) => (
                  <Option key={i} value={el.slug}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by stage status"
                onChange={(e) => setStageStatus(e)}
                bordered={false}
                defaultValue={stageStatus || undefined}
              >
                <Option value={""}>All</Option>
                {stageStatuses?.map((el, i) => (
                  <Option key={i} value={el.code}>
                    {el.internalStatus}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by booking status"
                onChange={(e) => setBookingStatus(e)}
                bordered={false}
                defaultValue={bookingStatus || undefined}
              >
                <Option value={""}>All Status</Option>
                {_bookingStatus?.map((el, i) => (
                  <Option key={i} value={el.code}>
                    {el.externalStatus}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} className="filterColumn">
            <Form.Item name="search" className="dropdown">
              <Input
                placeholder="Search"
                defaultValue={search}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          expandable={{
            expandedRowRender: expandedRowRender,
            expandedRowKeys: expandedRowKeys,
            onExpand: onExpand,
          }}
          columns={[
            {
              title: "Booking ID",
              dataIndex: "title",
              key: "title",
              render: (record) => (
                <span
                  onClick={() =>
                    onExpand(!expandedRowKeys.includes(record), record)
                  }
                >
                  <div className="row_expand_container">
                    <p style={{ color: "#007B85", padding: 0, margin: 0 }}>
                      {record}
                    </p>
                    {!expandedRowKeys.includes(record) ? (
                      <DownOutlined
                        style={{ color: "#007B85", marginLeft: 10 }}
                      />
                    ) : (
                      <UpOutlined
                        style={{ color: "#007B85", marginLeft: 10 }}
                      />
                    )}
                  </div>
                </span>
              ),
            },
            {
              title: "User",
              key: "userName",
              render: (record) => (
                <>
                  {record?.user?.firstName + " " + record?.user?.lastName}
                  <br />
                  {record?.user?.phoneNo}
                </>
              ),
            },
            {
              title: "Campus",
              key: "campus",
              render: (record) => record?.facility?.campus?.code,
            },
            {
              title: "Facility",
              key: "facility",
              render: (record) => record?.facility?.title,
            },
            {
              title: "Status",
              key: "bookingStatus",
              render: (record) => record?.bookingStatus?.internalStatus,
            },
            {
              title: "Current Stage",
              key: "currentStage",
              render: (record) => record?.currentStage?.title,
            },
            {
              title: "Current Stage Status",
              key: "currentStageStatus",
              render: (record) => record?.currentStage?.status?.internalStatus,
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="View Details">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EyeOutlined />}
                          onClick={() => onViewDetials(item)}
                        />
                      </Tooltip>
                    </div>
                    {item?.hasPermission && (
                      <Popconfirm
                        title="Are you sure you want to delete this?"
                        okText="Yes"
                        cancelText="No"
                        style={{ marginLeft: "30px" }}
                        onConfirm={() => deleteBookig(item.id)}
                      >
                        <Tooltip title="Delete">
                          <Button
                            className="d-flex justify-content-center align-items-center"
                            shape="circle"
                            icon={<DeleteOutlined />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    )}
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
      </Spin>
    </div>
  );
};

export default AllTypeBookings;
