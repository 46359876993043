import { Col, Row, Form, Select, List, Button } from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

import { useSelector } from "react-redux";
import { ENRExportFields } from "utils/constants";
import { requestErrorHandel } from "utils/helpers/helpers";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import { showValidationError } from "utils/helpers/helpers";
import { SCCExportFields } from "utils/constants";
import { fetchAllSccCategories } from "../scc-Categories/api";
import { fetchAllSccStandards } from "../scc-standards/api";
import { fetchAllSccOffenseIndicators } from "../scc-offense-indicators/api";
import { exportCasesToExcel } from "./api";
import { successMessage } from "utils/helpers/helpers";
import { _terms } from "utils/constants";

const { Option } = Select;

const ExportCaseModal = ({ formName, exportCasefilters }) => {
  const filterLayout = { md: 6 };
  const _caseType = "SCC";

  const _campuses = useSelector((state) => state.metaData.campuses);
  const _years = useSelector((state) => state.metaData.SCC_years);

  const _allschools = useSelector((state) => state.metaData.schools);
  const [_schools, setFilteredSchools] = useState(_allschools);
  const _caseStatuses = useSelector(
    (state) => state.metaData.applicationStatuses
  );

  const _caseStages = useSelector((state) => state.metaData.stages)?.filter(
    (caseType) => caseType.applicationType === _caseType
  );

  const [term, setTerm] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [standards, setStandards] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [stageStatuses, setStageStatuses] = useState([]);

  const [categoryId, setCategoryId] = useState(null);
  const [standardId, setStandardId] = useState(null);
  const [filters, setFilters] = useState({
    campus: "",
    school: "",
    category: "",
    standard: "",
    indicator: "",
    term: "",
    year: "",
    stage: "",
    stageStatus: "",
    status: "",
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stageChanged, setStageChanged] = useState(false);

  let serviceType = "SCC";

  useEffect(() => {
    fetchSccCategories();
    fetchSccStandards();
    fetchSccIndicators();
  }, []);

  const fetchSccCategories = async () => {
    try {
      const response = await fetchAllSccCategories({ limit: 1000 });
      if (response.status === 200) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchSccStandards = async () => {
    try {
      const response = await fetchAllSccStandards({ limit: 1000 });
      if (response.status === 200) {
        setStandards(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchSccIndicators = async () => {
    try {
      const response = await fetchAllSccOffenseIndicators({ limit: 1000 });
      if (response.status === 200) {
        setIndicators(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSelectCampus = (value) => {
    setFilters({ ...filters, campus: value });

    filterSchoolsByCampus(value);
  };

  const filterSchoolsByCampus = (value) => {
    if (value === "") {
      setFilteredSchools(_allschools);
      return;
    }
    setFilteredSchools(
      _allschools.filter((school) => school.campusId == value)
    );
  };

  const handleSelectSchool = (value) => {
    setFilters({ ...filters, school: value });
  };

  const handleCategoryChange = (e) => {
    if (e === "#") {
      setFilters({ ...filters, category: "", standard: "" });
      setCategoryId(null);
      setStandardId(null);
    } else {
      const categoryId = parseInt(e);
      setFilters({ ...filters, category: categoryId });
      setCategoryId(categoryId);
    }
  };

  const handleChangeStandards = (e) => {
    const standardId = parseInt(e);
    setFilters({ ...filters, standard: standardId });
    setStandardId(standardId);
  };

  const handleItemClick = (item) => {
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.filter((prevItem) => prevItem !== item);
      return [...updatedItems, item];
    });
    setFields((prevFields) =>
      prevFields.filter((prevItem) => prevItem !== item)
    );
  };

  const handleRemoveItemClick = (item) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.filter((prevItem) => prevItem !== item);
      return [...updatedFields, item];
    });
    setSelectedItems((prevItems) =>
      prevItems.filter((prevItem) => prevItem !== item)
    );
  };

  useEffect(() => {
    checkServiceTypeAndSetTheFieldsInState();
  }, []);
  const checkServiceTypeAndSetTheFieldsInState = () => {
    if (serviceType === "SCC") {
      setFields(SCCExportFields);
    }
  };
  const submitForExportToExcel = async () => {
    try {
      if (selectedItems.length === 0) {
        showValidationError("Fields must contain at least 1 element");
        return;
      }
      const data = {
        category: filters.category,
        standard: filters.standard,
        indicator: filters.indicator,
        campus: filters.campus,
        school: filters.school,
        term: filters.term,
        year: filters.year,
        stage: filters.stage,
        stageStatus: filters.stageStatus,
        status: filters.status,
        fields: selectedItems.map((item) => item.value),
      };
      console.log("data.......", data);
      setLoading(true);
      const response = await exportCasesToExcel({ payload: data });
      if (response.status === 200) {
        if (response.data?.data.length === 0) {
          successMessage({ message: "No Case Found" });
          setLoading(false);
        } else {
          // Remove HTML tags from description values
          const cleanedData = response.data?.data.map((item) => {
            return {
              ...item,
              description: item.description
                ? item.description.replace(/<[^>]*>?/gm, "")
                : "",
            };
          });

          setCsvData(cleanedData);
          successMessage({ message: "Cases exported successfully" });
          setLoading(false);
        }
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const exportCaseModalTitle = () => {
    // const _eService = AllApplicationsRoutes[0].subMenu.find(
    //   (item) => item.state.serviceType === serviceType
    // );
    return `Case.csv`;
  };

  useEffect(() => {
    if (exportCasefilters !== undefined && exportCasefilters !== null) {
      setFilters({
        ...filters,
        campus: exportCasefilters?.campus,
        school: exportCasefilters?.school,
        category: exportCasefilters?.category,
        standard: exportCasefilters?.standard,
        indicator: exportCasefilters?.indicator,
        term: exportCasefilters?.term,
        year: exportCasefilters?.year,
        stage: exportCasefilters.stage,
        stageStatus: exportCasefilters.stageStatus,
        status: exportCasefilters.status,
      });
      setStageStatuses(exportCasefilters?.stageStatuses);
      setCategoryId(exportCasefilters?.category);
      setStandardId(exportCasefilters?.standard);
    }
  }, [exportCasefilters]);

  const handleSelectStage = (value) => {
    setFilters({ ...filters, stage: value });

    handleStagesStatuses(value);
  };

  const handleStagesStatuses = (value) => {
    setStageChanged(true);
    setFilters({ ...filters, stageStatus: "" });
    if (value === "") {
      setStageStatuses([]);
      setStageChanged(false);
      return;
    }
    const _selectedStage = _caseStages.find((stage) => stage.slug === value);
    setStageStatuses(_selectedStage?.statuses ?? []);
  };
  const handleSelectStageStatus = (value) => {
    setFilters({ ...filters, stageStatus: value });
  };

  const handleSelectCaseStatus = (value) => {
    setFilters({ ...filters, status: value });
  };

  return (
    <div>
      <Form form={formName}>
        <Row gutter={[10, 0]} align="middle">
          {
            <>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Campus"
                    onChange={handleSelectCampus}
                    bordered={false}
                    value={filters.campus || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_campuses?.map((el, i) => (
                      <Option key={i} value={el.id}>
                        {el.code}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By School"
                    onChange={handleSelectSchool}
                    bordered={false}
                    value={filters.school || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_schools?.map((el, i) => (
                      <Option key={i} value={el.id}>
                        {el.abbreviation}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter by category"
                    onChange={(e) => handleCategoryChange(e)}
                    bordered={false}
                    value={filters.category || undefined}
                  >
                    <Option value={"#"}>All</Option>
                    {categories?.map((el, i) => (
                      <Option key={i} value={el.id}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter by standard"
                    onChange={(e) => handleChangeStandards(e)}
                    bordered={false}
                    value={filters.standard || undefined}
                  >
                    {categoryId &&
                      standards
                        .filter((el) => Number(el.categoryId) === categoryId)
                        .map((el, i) => (
                          <Option value={el.id} key={i}>
                            {el.title}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter by indicator"
                    onChange={(e) => setFilters({ ...filters, indicator: e })}
                    bordered={false}
                    value={filters.indicator || undefined}
                  >
                    {standardId &&
                      indicators
                        .filter((el) => Number(el.standardId) === standardId)
                        .map((el, i) => (
                          <Option value={el.id} key={i}>
                            {el.title}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter by Year"
                    onChange={(e) => setFilters({ ...filters, year: e })}
                    bordered={false}
                    value={filters.year || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_years?.map((el, i) => (
                      <Option key={i} value={el.id}>
                        {el.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter by Term"
                    onChange={(e) => setFilters({ ...filters, term: e })}
                    bordered={false}
                    value={filters.term || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_terms?.map((el, i) => (
                      <Option key={i} value={el.value}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Stage"
                    onChange={handleSelectStage}
                    // defaultValue={stage ? stage : "All Stages"}
                    bordered={false}
                    value={filters.stage || undefined}
                  >
                    <Option value={""}>All Stages</Option>
                    {_caseStages?.map((el, i) => (
                      <Option key={i} value={el.slug}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} className="filterColumn">
                {stageChanged ? (
                  <Form.Item className="dropdown">
                    <Select
                      onChange={handleSelectStageStatus}
                      placeholder="Filter By Stage Status"
                      value={filters.stageStatus || undefined}
                      bordered={false}
                    >
                      <Option value={""}>All</Option>
                      {stageStatuses?.map((el, i) => (
                        <Option key={i} value={el.code}>
                          {el.status}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item className="dropdown">
                    <Select
                      onChange={handleSelectStageStatus}
                      placeholder="Filter By Stage Status"
                      bordered={false}
                      value={filters.stageStatus || undefined}
                    >
                      <Option value={""}>All</Option>
                      {stageStatuses?.map((el, i) => (
                        <Option key={i} value={el.code}>
                          {el.status}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>

              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Case Status"
                    onChange={handleSelectCaseStatus}
                    // defaultValue={"All Applications"}
                    bordered={false}
                    value={filters.status}
                  >
                    <Option value={""}>All</Option>
                    {_caseStatuses?.map((el, i) => (
                      <Option key={i} value={el.code}>
                        {el.internalStatus}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          }
        </Row>
      </Form>
      <Row gutter={[16, 16]}>
        <Col md={12} sm={24}>
          <div style={{ backgroundColor: "#F7F7F7" }}>
            <div
              style={{
                backgroundColor: "#007B85",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#FFF", padding: 0, margin: 0 }}>
                Available Fields
              </p>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <List
                dataSource={fields}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="text"
                        onClick={() => handleItemClick(item)}
                        icon={<ArrowRightOutlined />}
                      />,
                    ]}
                  >
                    {item.title}
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Col>
        <Col md={12} sm={24}>
          <div style={{ backgroundColor: "#F7F7F7" }}>
            <div
              style={{
                backgroundColor: "#007B85",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#FFF", padding: 0, margin: 0 }}>
                Selected Fields
              </p>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <List
                dataSource={selectedItems}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="text"
                        onClick={() => handleRemoveItemClick(item)}
                        icon={<ArrowLeftOutlined />}
                      />,
                    ]}
                  >
                    {item.title}
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <Row justify={"end"} gutter={[20, 10]}>
        <Button type="primary" style={{ marginRight: 20 }}>
          {csvData.length > 0 && (
            <CSVLink
              data={csvData}
              filename={exportCaseModalTitle()}
              target="_blank"
            >
              Download
            </CSVLink>
          )}
        </Button>
        <Button type="primary" onClick={submitForExportToExcel}>
          {loading ? "Exporting..." : "Export to Excel"}
        </Button>
      </Row>
    </div>
  );
};
export default ExportCaseModal;
