import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Popconfirm, Spin, Tooltip } from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import BusForm from "components/Forms/buses-crud/BusForm";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { addBusAPI } from "apis/buses-cms/BusesCMSApi";
import { updateBusAPI } from "apis/buses-cms/BusesCMSApi";
import { deleteBusAPI } from "apis/buses-cms/BusesCMSApi";
import { getBusesAPI } from "apis/buses-cms/BusesCMSApi";

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const BusesCMS = () => {
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _terms = useSelector((state) => state.metaData.terms);

  const [page, setPage] = useState(1);
  const [busesList, setBusesList] = useState();
  const [total, setTotal] = useState();
  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [loading, setLoading] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [file, setFile] = useState();
  const [AddBusForm] = Form.useForm();
  const [terms, setTerms] = useState([]);

  // API FUNCTIONS

  const getBusesList = async () => {
    try {
      setLoading(true);
      const response = await getBusesAPI(page);
      setLoading(false);
      if (response.status === 200 && response.data?.data) {
        setBusesList(response.data.data);
        setTotal(response.data.total);
      } else {
        setBusesList();
        throw response;
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const addBus = async (data) => {
    try {
      setLoading(true);
      const response = await addBusAPI(data);
      setLoading(false);
      if (response.status == 200) {
        successMessage({ message: "Record added successfully" });
        await getBusesList();
        onCloseForm();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const updateBus = async (data) => {
    try {
      setLoading(true);
      const response = await updateBusAPI(data, valuesForEdit.id);
      setLoading(false);
      if (response.status == 200) {
        successMessage({ message: "Record updated successfully" });
        await getBusesList();
        onCloseForm();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const deleteBus = (id) => async () => {
    try {
      setLoading(true);
      const response = await deleteBusAPI(id);
      if (response.status == 200) {
        successMessage({ message: "Record deleted successfully" });
        await getBusesList();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBusesList();
  }, [page]);

  const renderHeader = () => {
    return (
      <TableHeader
        onAddNew={() => {
          AddBusForm.resetFields();
          toggleModal(true);
          setModalTitle(ModalType.ADD);
        }}
        headerTitle={"Buses"}
        headerSubTitle={"Manage buses"}
        headerBtnTitle={"Add New"}
      />
    );
  };

  const onSubmit = async (values) => {
    const params = {
      ...values,
      terms: terms.map((term) => term.name),
    };
    if (modalTitle === ModalType.EDIT) {
      await updateBus(params);
    } else {
      await addBus(params);
    }
  };
  const handleEdit = (record) => {
    setValuesForEdit(record);
    AddBusForm.setFieldsValue({
      ...record,
      campusId: _campuses.find((x) => x.code === record.campus).id,
    });
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
    setFile(undefined);
  };

  const showDriversNannyInfo = (record, type) => {
    return (
      <>
        <strong>
          {type} {" 1"}
        </strong>
        <br />
        <span>Name</span>
        {" - "}
        <span>{type == "Driver" ? record.driverName : record.nannyName}</span>
        <br />
        <span>Mobile No</span>
        {" - "}
        <span>
          {type == "Driver" ? record.driverMobileNo : record.driverMobileNo}
        </span>

        <br />
        <br />
        <strong>
          {type} {" 2"}
        </strong>
        <br />
        <span>Name</span>
        {" - "}
        <span>{type == "Driver" ? record.driver2Name : record.nanny2Name}</span>
        <br />
        <span>Mobile No</span>
        {" - "}
        <span>
          {type == "Driver" ? record.driver2MobileNo : record.nanny2MobileNo}
        </span>
      </>
    );
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={busesList}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Bus No",
              key: "busNo",
              dataIndex: "busNo",
            },
            {
              title: "Bus Plate No",
              dataIndex: "busPlateNo",
              key: "busPlateNo",
            },
            {
              title: "Seats Capacity",
              dataIndex: "busCapacity",
              key: "busCapacity",
            },
            {
              title: "Remaining Seats",
              dataIndex: "remaining",
              key: "remaining",
            },
            {
              title: "Campus",
              dataIndex: "campus",
              key: "campus",
            },
            {
              title: "Drivers Info",
              key: "driverInfo",
              render: (record) => showDriversNannyInfo(record, "Driver"),
            },
            {
              title: "Nanny Info",
              key: "nannyInfo",
              render: (record) => showDriversNannyInfo(record, "Nanny"),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Bus Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this bus?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={deleteBus(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Bus`}
        >
          <BusForm
            onSubmit={onSubmit}
            formName={AddBusForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            _campuses={_campuses}
            _terms={_terms}
            setTerms={setTerms}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default BusesCMS;
