import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import SccCategoriesForm from "./SccCategoriesForm";
import {
  addNewSccCategory,
  deleteSccCategory,
  fetchAllSccCategories,
  updateSccCategory,
} from "./api";
import { generateUniqueColumnKey } from "utils/helpers/helpers";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const SccCategories = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [recordId, setRecordId] = useState("");

  const [sccCategoryForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            sccCategoryForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Categories"}
          headerSubTitle={"Manage Categories"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllSccCategories();
  }, [page]);

  const getAllSccCategories = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSccCategories({ page: page });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addSccCategory = async (data) => {
    try {
      setLoading(true);
      const response = await addNewSccCategory({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Category added successfully" });
        await getAllSccCategories();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateSccCategory = async (data) => {
    try {
      setLoading(true);
      const response = await updateSccCategory({ id: recordId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Category updated successfully" });
        await getAllSccCategories();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteSccCategory = async (id) => {
    try {
      setLoading(true);
      const response = await deleteSccCategory({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Category deleted successfully" });
        await getAllSccCategories();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
    };
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateSccCategory(data);
    } else {
      await addSccCategory(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    sccCategoryForm.setFieldsValue({
      ...record,
      campusId: record?.campus?.id,
    });
    setRecordId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Unique Code",
              dataIndex: "identifier",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Title",
              dataIndex: "title",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Type",
              key: generateUniqueColumnKey(),

              render: (record) => (
                <span style={{ textTransform: "capitalize" }}>
                  {record?.type === "positive"
                    ? "Positive / Distinguish"
                    : "Negative"}
                </span>
              ),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              width: "15%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Category">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Category?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteSccCategory(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Category`}
        >
          <SccCategoriesForm
            onSubmit={onSubmit}
            formName={sccCategoryForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default SccCategories;
