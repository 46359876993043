import { Table } from "antd";

import React from "react";
import { generateUniqueColumnKey } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";

const AttendeesDetailInformation = ({ attendees }) => {
  return (
    <div className="schedule__details__container">
      <p>Attendees</p>
      <Table
        dataSource={attendees}
        pagination={{ pageSize: 10 }}
        columns={[
          {
            title: "Student Number",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span className="table_record">
                {record?.studentNumber ? record?.studentNumber : "N/A"}
              </span>
            ),
          },
          {
            title: "Name",
            key: generateUniqueColumnKey(),
            render: (record) => (
              <span className="table_record">
                {record?.firstName + " " + record?.lastName}
              </span>
            ),
          },

          {
            title: "Email",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span className="table_record">
                {record?.email ? record?.email : "N/A"}
              </span>
            ),
          },
          {
            title: "Gender",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span className="table_record">
                {record?.gender ? record?.gender : "N/A"}
              </span>
            ),
          },
          {
            title: "Date Of Birth",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span className="table_record">
                {record?.dob ? record?.dob : "N/A"}
              </span>
            ),
          },
        ]}
      ></Table>
    </div>
  );
};

export default AttendeesDetailInformation;
