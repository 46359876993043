import client from "apis/config";

export const getUsersAPI = async (page) => {
  return await client.get(`/users?page=${page}&limit=10`);
};
export const searchUsersAPI = async (search) => {
  return await client.get(`/users?search=${search}`);
};
export const addUserAPI = async (newUser) => {
  return await client.post("/users", newUser);
};
export const editUserAPI = async (updatedUser) => {
  return await client.put("/users", updatedUser);
};

export const deleteUserApi = async (userId) => {
  return await client.delete(`/users/${userId}`);
};

export const getCashiersAPI = async () => {
  return await client.get(`/users?cashiers=true&limit=1000`);
};
