import { Button, Form, Input, Select, Row, Col, Checkbox } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const { Option } = Select;
const SCHEvaluationForm = ({
  onSubmit,
  loading,
  formName,
  valuesForEdit,
  onHandelChangeScoreNonAcademicAchievment,
}) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" onFinish={onSubmit} form={formName}>
        <Row gutter={10}>
          <Col md={24}>
            <Form.Item
              name={"points"}
              label="Points"
              rules={setRules("Poinst")}
            >
              <Input type="number" min="0" />
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default SCHEvaluationForm;
