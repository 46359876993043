import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditorToolbar } from "utils/constants";

const { Option } = Select;

const AddDiscountRulesForm = ({
  visibility,
  onHide,
  title,
  onSubmit,
  formName,
  otherRules,
  loading,
}) => {
  const gradesRestrictionOptions = useSelector(
    (state) => state.metaData.dropDowns["gradesRestrictions"]
  );

  return (
    <CustomModal visibility={visibility} handleCancel={onHide} title={title}>
      {
        <Form layout="vertical" form={formName} onFinish={onSubmit}>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name={"title"}
                label={"Title"}
                rules={[{ required: true, message: "Title is required" }]}
              >
                <Input placeholder="Title" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"titleAr"}
                label={"Title (AR)"}
                rules={[{ required: true, message: "Title (AR) is required" }]}
              >
                <Input placeholder="Title (AR)" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name={"noOfKids"}
                label={"Maximum Number Of Kids"}
                rules={[
                  {
                    required: true,
                    message: "Max. number of kids is required",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Maximum number of kids"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"gradesRestrictions"}
                label={"Grades Restriction"}
                rules={[
                  { required: true, message: "Grades restriction is required" },
                ]}
              >
                <Select>
                  {gradesRestrictionOptions?.map((restriction) => (
                    <Option key={restriction.id} value={restriction.value}>
                      {restriction.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[20, 20]}>
            <Col span={8}>
              <Form.Item
                name={"allowedWithDiscounts"}
                label={"Allowed With Other Discounts"}
              >
                <Select mode="multiple">
                  {otherRules.map((rule) => (
                    <Option key={rule.id} value={rule.id}>
                      {rule.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={"Posting Account"}
                name={"postingAccount"}
                rules={[
                  {
                    required: true,
                    message: "Posting Account is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={"Rule Id"}
                name={"ruleId"}
                rules={[
                  {
                    required: true,
                    message: "Rule id is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[20, 20]}>
            <Col md={12}>
              <Form.Item
                name={"excludeHighestGrades"}
                valuePropName={"checked"}
              >
                <Checkbox>Exclude Highest Grades</Checkbox>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name={"isEnabled"}
                initialValue={true}
                valuePropName={"checked"}
              >
                <Checkbox>Active</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name={"terms"}
            label={"Discount Terms"}
            rules={[{ required: true, message: "Discount Terms is required" }]}
            valuePropName={"data"}
            getValueFromEvent={(event, editor) =>
              editor ? editor.getData() : null
            }
          >
            <CKEditor
              config={
                {
                  //toolbar: EditorToolbar,
                }
              }
              editor={ClassicEditor}
              key={"rulesTerms"}
            />
          </Form.Item>

          <Form.Item
            name={"termsAr"}
            label={"Discount Terms (AR)"}
            rules={[
              {
                required: true,
                message: "Discount Terms (AR) is required",
              },
            ]}
            valuePropName={"data"}
            getValueFromEvent={(event, editor) =>
              editor ? editor.getData() : null
            }
          >
            <CKEditor
              config={{
                language: "ar",
                //toolbar: EditorToolbar,
              }}
              editor={ClassicEditor}
              key={"rulesTermsAr"}
            />
          </Form.Item>

          <Row justify="end">
            <Button loading={loading} htmlType="submit">
              {loading ? "Saving...." : "Save"}
            </Button>
          </Row>
        </Form>
      }
    </CustomModal>
  );
};
export default AddDiscountRulesForm;
