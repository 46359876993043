import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Spin,
  Tooltip,
  Form,
  Select,
  Image,
  Row,
  Col,
  DatePicker,
} from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { fetchAllFsmPayments } from "./api";
import { useSelector } from "react-redux";
import moment from "moment";
import { onViewBookingDetails } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";

const { Option } = Select;

const FSMPayments = (props) => {
  const _campuses = useSelector((state) => state.metaData.campuses);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({
    campus: "",
    from: "",
    to: "",
  });

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          headerTitle={"Payments"}
          headerSubTitle={"Manage Payments"}
          hideBtnIcon={true}
        />
        <Row gutter={10}>
          <Col md={8} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter By Campus"
                onChange={(e) => setFilters({ ...filters, campus: e })}
                bordered={false}
                defaultValue={filters.campus || undefined}
              >
                <Option value={""}>All</Option>
                {_campuses?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} className="filterColumn">
            <Form.Item className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Booking from"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    from: moment(e).format("YYYY-MM-DD"),
                  })
                }
                bordered={false}
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col md={8} className="filterColumn">
            <Form.Item className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Booking to"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    to: moment(e).format("YYYY-MM-DD"),
                  })
                }
                bordered={false}
                defaultValue={filters.to || undefined}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };
  useEffect(() => {
    getAllPayments();
  }, [filters, page]);

  const getAllPayments = async () => {
    try {
      setLoading(true);
      const response = await fetchAllFsmPayments({
        page: page,
        campus: filters.campus,
        from: moment(filters.from).isValid() ? filters.from : "",
        to: moment(filters.to).isValid() ? filters.to : "",
        type: "",
      });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const showApplicationDetails = () => {
    return;
    props.history.push({
      pathname: `payment/details`,
      //    state: { applicationId: record.id, serviceType },
    });
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Booking ID",
              // dataIndex: "title",
              key: "title",
              render: (record) => (
                <p
                  className="payment_click_link"
                  onClick={() => onViewBookingDetails(record, props)}
                >
                  {record?.booking?.title}
                </p>
              ),
            },
            {
              title: "Date",
              // dataIndex: "type",
              key: "date",
              render: (record) => formatedDate(record?.createdAt),
            },
            {
              title: "Facility",
              // dataIndex: "type",
              key: "Facility",
              render: (record) => record?.booking?.facility?.title,
            },

            {
              title: "Campus",
              // dataIndex: "type",
              key: "campus",
              render: (record) => record?.booking?.facility?.campus?.code,
            },
            {
              title: "User",
              // dataIndex: "netTotal",
              key: "user",
              render: (record) => (
                <>
                  {record?.user?.firstName + " " + record?.user?.lastName}
                  <br />
                  {record?.user?.userName}
                </>
              ),
            },
            {
              title: "Amount",
              // dataIndex: "grandTotal",
              key: "grandTotal",
              render: (record) => {
                return "AED" + " " + record?.grandTotal;
              },
            },

            {
              title: "Status",
              // dataIndex: "status",
              key: "status",
              render: (record) => (
                <p style={{ textTransform: "capitalize" }}> {record?.status}</p>
              ),
            },

            // {
            //   title: "Action",
            //   dataIndex: "",
            //   key: "x",
            //   //   width: "45%",
            //   render: (item) => {
            //     return (
            //       <div style={{ display: "flex" }} className="d-flex">
            //         <div style={{ marginRight: "10px" }}>
            //           <Tooltip title="View Details">
            //             <Button
            //               className="d-flex justify-content-center align-items-center"
            //               shape="circle"
            //               icon={<EyeOutlined />}
            //               onClick={showApplicationDetails}
            //             />
            //           </Tooltip>
            //         </div>

            //         <Popconfirm
            //           title="Are you sure you want to delete this?"
            //           okText="Yes"
            //           cancelText="No"
            //           style={{ marginLeft: "30px" }}
            //           onConfirm={() => null}
            //         >
            //           <Tooltip title="Delete">
            //             <Button
            //               className="d-flex justify-content-center align-items-center"
            //               shape="circle"
            //               icon={<DeleteOutlined />}
            //             />
            //           </Tooltip>
            //         </Popconfirm>
            //       </div>
            //     );
            //   },
            // },
          ]}
        ></CommonTable>
      </Spin>
    </div>
  );
};

export default FSMPayments;
