import { Card, Col, Row } from "antd";
import moment from "moment";
import React from "react";

const SccConductCaseDetails = ({ caseDetails }) => {
  const Indicator = caseDetails?.type === "offense";
  return (
    <React.Fragment>
      {Indicator ? (
        <div className="conduct_case_general">
          <h3>Offense Indicator</h3>
          <Card className="antCardBorder antCard">
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Unique code</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="scc_value">
                  <p>{caseDetails?.indicator?.identifier}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Category</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.category?.title}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Standard</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.standard?.title}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Indicator</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.indicator?.title}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Act / Incidence /Situation </p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: caseDetails?.description,
                    }}
                  ></p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Source</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.source}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Employee</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>
                    {caseDetails?.reportedBy?.firstName +
                      " " +
                      caseDetails?.reportedBy?.middleName +
                      " " +
                      caseDetails?.reportedBy?.lastName}
                  </p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Incident Date & Time</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>
                    {moment(caseDetails?.dateOfOccurrence).format(
                      "DD MMM YYYY  hh:mm A"
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Incident Occurrence Location</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.locationOfOccurrence}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Teacher Name</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>
                    {caseDetails?.teacher?.firstName +
                      " " +
                      caseDetails?.teacher?.middleName +
                      " " +
                      caseDetails?.teacher?.lastName}
                  </p>
                </div>
              </Col>
            </Row>
            <div className="scc_divider" />
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title scc_conditional_text">
                  <p>Offense Level</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.offenseLevel?.title}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title scc_conditional_text">
                  <p>Number of Occurrences</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.occurrence}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title scc_conditional_text">
                  <p>Deduction Score</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="scc_value">
                  <p>{caseDetails?.score}</p>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      ) : (
        <div className="conduct_case_general">
          <h3>
            {caseDetails?.type === "distinguish_behaviour"
              ? "Distinguish Behaviour"
              : "Behaviour Indicator"}
          </h3>
          <Card className="antCardBorder antCard">
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Unique code</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="scc_value">
                  <p>{caseDetails?.indicator?.identifier}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Category</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.category?.title}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Standard</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.standard?.title}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Indicator</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.indicator?.title}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Act / Incidence / Situation </p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: caseDetails?.description,
                    }}
                  ></p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Source</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.source}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Employee</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>
                    {caseDetails?.reportedBy?.firstName +
                      " " +
                      caseDetails?.reportedBy?.middleName +
                      " " +
                      caseDetails?.reportedBy?.lastName}
                  </p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Incident Date & Time</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>
                    {moment(caseDetails?.dateOfOccurrence).format(
                      "DD MMM YYYY  hh:mm A"
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Incident Occurrence Location</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>{caseDetails?.locationOfOccurrence}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title">
                  <p>Teacher Name</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="scc_value">
                  <p>
                    {caseDetails?.teacher?.firstName +
                      " " +
                      caseDetails?.teacher?.middleName +
                      " " +
                      caseDetails?.teacher?.lastName}
                  </p>
                </div>
              </Col>
            </Row>
            <div className="scc_divider" />

            <Row gutter={[10, 10]}>
              <Col md={6}>
                <div className="scc_title scc_conditional_text">
                  <p>
                    {caseDetails?.type === "distinguish_behaviour" &&
                    caseDetails?.score < 0
                      ? "Deduction Score"
                      : "Reward Score"}
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="scc_value">
                  <p>{caseDetails?.score}</p>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default SccConductCaseDetails;
