import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Form, Input, Select, Row, Col } from "antd";
import React from "react";

import { useSelector } from "react-redux";
const { Option } = Select;

const SccOffenseLevelForm = ({ onSubmit, loading, formName }) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={setRules("Title EN")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title AR")}
            >
              <Input placeholder="Add here" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Form.Item
              label={"Description (EN)"}
              name={"description"}
              valuePropName={"data"}
              rules={[
                { required: true, message: "Description (EN) is required" },
              ]}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "en",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"descriptiveText"}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={24}>
            <Form.Item
              label={"Description (AR)"}
              name={"descriptionAr"}
              rules={[
                { required: true, message: "Description (AR) is required" },
              ]}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "en",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"descriptiveText"}
              />
            </Form.Item>
          </Col>
        </Row>

        <br />

        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant__primary__button"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default SccOffenseLevelForm;
