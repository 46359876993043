import client from "apis/config";
const endPointUrl = "scc/cases";

export const generateNewSccForm = async ({ data }) => {
  return await client.post(`/scc-documents`, data);
}



export const fetchAllSccForms = async ({
  campus = "",
  school = "",
  year = "",
  search=""
}) => {
  return await client.get(
    `/scc-documents?campus=${campus}&school=${school}&year=${year}&search=${search}`
  );
};


export const addNewSccForm = async ({ data }) => {
  return await client.post(`/scc-documents`, {
    studentNumber: data.studentNumber,
    templateId: data.templateId,
    name: "General Template",
    campus: data.campus || "",
    school: data.school || "",
    section: data.section,
    year: data.year,
    status: data.status,
    content: data.content,
    contentAr: data.contentAr
  });
};

export const updateSccForm = async ({ id, data }) => {
  return await client.put(`/scc-documents/${id}`, {
    studentNumber: data.studentNumber?.toString(),
    templateId: data.templateId || 1,
    name: "General Template",
    campus: data.campus || "AD",
    school: data.school || "B",
    section: data.section,
    year: data.year,
    status: data.status,
    content: data.content,
    contentAr: data.contentAr
  });
};

export const downloadFormPdf = async ({ id, lan }) => {
  return await client.get(`/scc-documents/getPDF/${id}/${lan}`);
};
export const deleteSccForm = async ({ id }) => {
  return await client.delete(`/scc-documents/${id}`);
};

export const getSccConductCaseDetails = async ({ caseId }) => {
  return await client.get(`scc/cases/detail/${caseId}`);
};
export const getSccConductCaseLogs = async ({ caseId }) => {
  return await client.get(`scc/cases/logs/${caseId}`);
};

export const getStudentsList = async ({ search }) => {
  return await client.get(`scc/students?search=${search}`);
};
export const getSearchedStudentsList = async ({ search }) => {
  return await client.get(`scc/searchStudents?search=${search}`);
};

export const handleGenerateForm = async ({ payload }) => {
  return await client.post("templates/generate", payload);
};

export const handleProcessConductCase = async ({ payload }) => {
  return await client.post("scc/cases/process", payload);
};
export const handleConductCaseStageApproval = async ({ payload }) => {
  return await client.post("scc/cases/approval", payload);
};

export const exportCasesToExcel = async ({ payload }) => {
  return await client.post("scc/cases/export", payload);
};
