import client from "apis/config";
const endPointUrl = "scc/indicators";

export const fetchAllSccDistinguishIndicators = async ({
  type = "distinguish_behaviour",
  limit = "10",
  page,
}) => {
  return await client.get(
    `/${endPointUrl}?type=${type}&limit=${limit}&page=${page}`
  );
};

export const addNewSccDistinguishIndicator = async ({ data }) => {
  return await client.post(`/${endPointUrl}`, data);
};

export const updateSccDistinguishIndicator = async ({ id, data }) => {
  return await client.put(`/${endPointUrl}/${id}`, data);
};

export const deleteSccDistinguishIndicator = async ({ id }) => {
  return await client.delete(`/${endPointUrl}/${id}`);
};
