import React, { useEffect, useState } from "react";
import "./style.css";

const _colorsArr = {
  AS001: "",
  AS002: "",
  AS003: "",
  AS004: "",
  AS005: "",
  AS006: "#DC3B3E",
  AS007: "#C450A6",
};

export default function DashboardApplicationStatuses(props) {
  const [data, setData] = useState([]);

  let allApplicationStats = props.applicationStatics;

  useEffect(() => {
    if (allApplicationStats.length > 0) {
      let totalCount = 0;
      allApplicationStats.forEach((item) => {
        totalCount = totalCount + item.count;
      });
      const allApplications = {
        code: "",
        status: "All",
        count: totalCount,
      };

      setData([allApplications, ...allApplicationStats]);
    }
  }, [allApplicationStats]);

  return (
    <div className="applicationsStatusesWrapper" style={{ cursor: "pointer" }}>
      <div className="applicationsStatusesList">
        {data.length > 0
          ? data.map((item, i) => {
              return (
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  key={item.code}
                  className="applicationsStatusItem"
                  onClick={() =>
                    props.filterApplicationsByApplicationStatus(item.code)
                  }
                >
                  <p
                    style={{
                      color: _colorsArr[item.code],
                      fontSize: "12px",
                    }}
                  >
                    {item.status}
                  </p>
                  <p>{item.count}</p>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
