import { Col, Row, Form, Input, Select, Button, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { ApproveOptions } from "utils/constants";

const { Option } = Select;

const PreFinalApprovalForm = ({
  onFinish,
  loading,
  showRetest,
  showInterview,
  onResetForm,
  handelRetestSubject,
}) => {
  const [preFinalForm] = Form.useForm();
  const [actionValue, setActionValue] = useState("");
  const [subjects, setSubjects] = useState([
    {
      id: "1",
      subjectName: "english",
      isCheck: false,
    },
    {
      id: "2",
      subjectName: "math",
      isCheck: false,
    },
    {
      id: "3",
      subjectName: "arabic",
      isCheck: false,
    },
  ]);
  let options = showRetest
    ? [
        ...ApproveOptions,
        {
          id: "3",
          title: "Retest Recommended",
          value: "retest",
        },
      ]
    : ApproveOptions;
  options = showInterview
    ? [
        ...options,
        { id: "4", title: "Interview Recommended", value: "interview" },
      ]
    : options;

  function isAnySubjectSelected(subjects) {
    return subjects.some((subject) => subject.isCheck === true);
  }

  const onSubmit = (values) => {
    if (actionValue === "retest") {
      if (!isAnySubjectSelected(subjects)) {
        NotificationManager.error("Please select at least one subject.");
        return;
      }
    }
    onFinish(values);
    setTimeout(() => preFinalForm.resetFields(), 800);
  };

  const handleSubjectToggle = (id) => {
    let _oldSubjects = [...subjects];
    const updatedSubjects = _oldSubjects.map((subject) =>
      subject.id === id ? { ...subject, isCheck: !subject.isCheck } : subject
    );

    setSubjects(updatedSubjects);
    handelRetestSubject(updatedSubjects);
  };

  return (
    <Form layout="vertical" form={preFinalForm} onFinish={onSubmit}>
      <Row justify="space-between">
        <Col lg={24}>
          <Form.Item
            label="Remarks"
            name="isApproved"
            rules={[
              {
                required: true,
                message: "Approve or decline option is required",
              },
            ]}
          >
            <Select>
              {options.map((el, index) => (
                <Option value={el.value} key={index}>
                  <p onClick={() => setActionValue(el.value)}>{el.title}</p>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={24}>
          {actionValue === "retest" && (
            <>
              <div>
                {subjects.map((sub) => {
                  return (
                    <div
                      key={sub.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "20%",
                        marginTop: "20px",
                      }}
                    >
                      <Checkbox
                        // defaultChecked="checked"
                        value={sub.isCheck}
                        onChange={() => handleSubjectToggle(sub.id)}
                      />
                      <p
                        style={{
                          paddin: 0,
                          margin: 0,
                          textTransform: "capitalize",
                          marginLeft: 20,
                        }}
                      >
                        {sub.subjectName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </Col>
        <Col lg={24}>
          <Form.Item
            label="Notes"
            name="notes"
            rules={[
              {
                required: true,
                message: "Notes are required",
                whitespace: true,
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
      <Button loading={loading} type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};
export default PreFinalApprovalForm;
