import client from "apis/config";

export const getOnBoardings = async ({ page }) => {
  return await client.get(`/onBoarding?limit=10&page=${page}`);
};

export const addNewOnBoarding = async ({ data }) => {
  return await client.post("/onBoarding", data);
};

export const updateOnBoarding = async ({ id, data }) => {
  return await client.put(`/onBoarding/${id}`, data);
};

export const deleteOnBoarding = async ({ id }) => {
  return await client.delete(`/onBoarding/${id}`);
};
