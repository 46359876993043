import client from "apis/config";

export const allDocumentsLists = async (queryParams) => {
  let endPoint = "documents";
  if (queryParams !== "") {
    endPoint = endPoint + queryParams;
  }
  return await client.get(endPoint);
};

export const addDocumentToList = async ({ data }) => {
  return await client.post("documents", data);
};

export const deleteDocument = async ({ id }) => {
  return await client.delete(`documents/${id}`);
};

export const updateDocumentinLists = async ({ id, data }) => {
  return await client.put(`documents/${id}`, data);
};
