import { getApplicationsDataAPI } from "apis/stages-api/stages-api";
import { getTCCApplicationMetaData } from "apis/stages-api/stages-api";
import { getFSMApplicationMetaData } from "apis/stages-api/stages-api";
import { getSCHApplicationMetaData } from "apis/stages-api/stages-api";
import { getAllConfigurationsAPI } from "apis/stages-api/stages-api";
import { MetaDataActionKey } from "utils/constants";
import { getSccCasesMetaData } from "views/SCC/scc-basic-reports/api";

export const getStageConfigAction = () => {
  return (dispatch) => {
    // this api returns all stages of every application type
    getAllConfigurationsAPI()
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({
            type: MetaDataActionKey.getStagesConfig,
            payload: res.data.data,
          });
        }
      })
      .catch((e) => {
        console.log({ e });
      });
  };
};
export const getStageMetaDataAction = () => {
  return (dispatch) => {
    getApplicationsDataAPI()
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({
            type: MetaDataActionKey.getStagesMetaData,
            payload: res.data.data,
          });
        }
      })
      .catch((e) => {});
  };
};
export const _getSCHApplicationMetaData = () => {
  return (dispatch) => {
    getSCHApplicationMetaData()
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({
            type: MetaDataActionKey.getSchApplicationMetaDataKey,
            payload: res.data.data,
          });
        }
      })
      .catch((e) => {});
  };
};
export const _getTCCApplicationMetaData = () => {
  return (dispatch) => {
    getTCCApplicationMetaData()
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({
            type: MetaDataActionKey.getTCCApplicationMetaDataKey,
            payload: res.data.data,
          });
        }
      })
      .catch((e) => {});
  };
};
export const _getFSMApplicationMetaData = () => {
  return (dispatch) => {
    getFSMApplicationMetaData()
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({
            type: MetaDataActionKey.getFSMApplicationMetaDataKey,
            payload: res.data.data,
          });
        }
      })
      .catch((e) => {});
  };
};
export const _getSccCaseMetaData = () => {
  return (dispatch) => {
    getSccCasesMetaData()
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({
            type: MetaDataActionKey.getSCCConductCaseMetaDataKey,
            payload: res.data.data,
          });
        }
      })
      .catch((e) => {});
  };
};
