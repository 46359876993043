/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col, Button, DatePicker, Input, Select, Form } from "antd";

const { Option } = Select;

export default function TestResultKGForm({ onFinish, loading }) {
  const [typeOfRecommendation, setTypeOfRecommendation] = React.useState([
    {
      id: "1",
      title: "Recommended",
      value: true,
    },
    {
      id: "2",
      title: "Not Recommended",
      value: false,
    },
  ]);

  const handleChange = (event) => {
    // setTypeOfRecommendation(event);
  };
  return (
    <div className="both-side-padding">
      <Form
        size="large"
        name="entranceResultForm"
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Row gutter={[20, 20]}>
          <Col lg={12} sm={24}>
            <Form.Item
              label="Recommendation"
              name="recommendation"
              rules={[
                {
                  required: true,
                  message: "Recommendation is required",
                },
              ]}
            >
              <Select onChange={handleChange}>
                {typeOfRecommendation.map((type) => {
                  return <Option value={type.value}>{type.title}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={24} sm={24}>
            <Form.Item
              label="Notes"
              name="notes"
              rules={[
                {
                  required: true,
                  message: "Notes are required",
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea placeholder="Notes" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Row>
      </Form>
    </div>
  );
}
