import React, { useState } from "react";
import "./style.css";
import { Card, Col, Row } from "antd";

import Chart from "react-apexcharts";
import TitleWithLine from "../title-with-line/TitleWithLine";
import CustomCard from "../title-with-line/CustomCard";

import IndicatorCaseTable from "../cases-table/IndicatorCaseTable";
import SchoolsCaseTable from "../cases-table/SchoolsCaseTable";
import OffenseLevelCaseTable from "../cases-table/OffenseLevelCaseTable";
import SchoolTypeCaseTable from "../cases-table/SchoolTypeCaseTable";
import { setChartAndTableTitleOsCaseType } from "utils/constants";

const ENSWideCamparison = ({ data, type, year }) => {
  const { chartTitle, tableTitle } = setChartAndTableTitleOsCaseType(type);
  const monthColors = {
    jan: "#5E9AD5",
    feb: "#4371C3",
    mar: "#E77B36",
    apr: "#A8A8A8",
    may: "#FFBB02",
    jun: "#72AB4E",
    jul: "#C06C84",
    aug: "#FFBB02",
    sep: "#4371C3",
    oct: "#E77B36",
    nov: "#A8A8A8",
    dec: "#FFBB02",
  };
  const getColorForMonth = (month) => {
    // Extract month name in lowercase (e.g., "Jul 2024" -> "jul")
    const monthName = month.split(" ")[0].toLowerCase();

    return monthColors[monthName] || "#cccccc"; // Default color if month is not found
  };

  const renderIndicatorsChart = () => {
    // Check if the data is present and has the necessary structure
    if (
      !data ||
      !data.indicatorsCases ||
      !Array.isArray(data.indicatorsCases)
    ) {
      return <div>No data available</div>;
    }

    // Destructure indicatorsCases and months from the provided data
    const { indicatorsCases, months } = data;

    // Define an array of colors for each month
    const monthColors = {
      jan: "#5E9AD5",
      feb: "#4371C3",
      mar: "#E77B36",
      apr: "#A8A8A8",
      may: "#FFBB02",
      jun: "#72AB4E",
      jul: "#C06C84",
      aug: "#FFBB02",
      sep: "#4371C3",
      oct: "#E77B36",
      nov: "#A8A8A8",
      dec: "#FFBB02",
    };

    // Function to get color for a month
    const getColorForMonth = (month) => {
      // Extract month name in lowercase (e.g., "Jul 2024" -> "jul")
      const monthName = month.split(" ")[0].toLowerCase();

      return monthColors[monthName] || "#cccccc"; // Default color if month is not found
    };

    // Prepare the series data for the chart
    const seriesData = months.map((month) => ({
      name: month,
      data: indicatorsCases.map((indicator) => {
        // Find the case count for each month or default to 0
        const monthData = indicator.months.find((m) => m.month === month);
        return monthData ? monthData.cases : 0;
      }),
    }));

    // Define chart options
    const options = {
      chart: {
        type: "bar",
        stacked: false,
        toolbar: { show: false },
      },
      xaxis: {
        // Show indicator names on the x-axis
        categories: indicatorsCases.map(({ indicator }) => indicator),
        formatter: (value) => value.replace(/<br\s*\/?>/g, "<br />"), // Convert line breaks
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",

          // columnWidth: "50%", // Adjust the width of the bars
        },
      },
      fill: {
        colors: months.map((month) => getColorForMonth(month)), // Apply color based on month
      },
      dataLabels: {
        enabled: false,
      },
      theme: {
        mode: "light",
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 3, // Adjust width of the stroke
        colors: ["transparent"], // Color of the stroke
      },
    };

    // Render the chart component with options and data
    return (
      <CustomCard>
        <TitleWithLine title={chartTitle} />
        <Chart
          options={options}
          series={seriesData}
          type="bar"
          width={"100%"}
          height={235}
        />
        <div className="chart-legend">
          {months.map((month) => (
            <div key={month} className="legend-item">
              <span
                style={{
                  display: "inline-block",
                  width: 16,
                  height: 16,
                  backgroundColor: getColorForMonth(month),
                  marginRight: 8,
                }}
              />
              <span>{month}</span>
            </div>
          ))}
        </div>
      </CustomCard>
    );
  };

  const renderNegativeCasesSchoolsChart = () => {
    const schoolsCases = data?.schoolsCases || {};

    // Define a mapping for school types
    const schoolTypeMapping = {
      PS: "Primary School",
      BS: "Boys School",
      GS: "Girls School",
    };

    // Extract unique months from the data
    const months = [
      ...new Set(
        Object.values(schoolsCases)
          .flat()
          .map((entry) => entry.month)
      ),
    ];

    // Prepare data series for ApexCharts
    const seriesData = months.map((month) => ({
      name: month, // Each series is a month
      data: Object.keys(schoolsCases).map(
        (schoolType) =>
          schoolsCases[schoolType].find((entry) => entry.month === month)
            ?.cases || 0
      ),
    }));

    // School names as categories
    const categories = Object.keys(schoolsCases).map(
      (schoolType) => schoolTypeMapping[schoolType]
    );

    // Define chart options
    const options = {
      chart: {
        type: "bar",
        stacked: false,
        toolbar: { show: false },
      },
      xaxis: {
        // Show indicator names on the x-axis
        categories: categories,
        formatter: (value) => value.replace(/<br\s*\/?>/g, "<br />"), // Convert line breaks
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "35%",

          // columnWidth: "50%", // Adjust the width of the bars
        },
      },
      fill: {
        colors: months.map((month) => getColorForMonth(month)), // Apply color based on month
      },
      dataLabels: {
        enabled: false,
      },
      theme: {
        mode: "light",
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 5, // Adjust width of the stroke
        colors: ["transparent"], // Color of the stroke
      },
    };
    return (
      <>
        <CustomCard>
          <Chart
            options={options}
            series={seriesData}
            type="bar"
            width={"100%"}
            height={255}
          />
          <div className="chart-legend">
            {months.map((month) => (
              <div key={month} className="legend-item">
                <span
                  style={{
                    display: "inline-block",
                    width: 16,
                    height: 16,
                    backgroundColor: getColorForMonth(month),
                    marginRight: 8,
                  }}
                />
                <span>{month}</span>
              </div>
            ))}
          </div>
        </CustomCard>
      </>
    );
  };

  const renderOffenseLevelCaseChart = () => {
    const offenseLevelCases = data?.offenseLevelCases || [];

    // Extract unique months from the data
    const months = [
      ...new Set(
        offenseLevelCases.flatMap((entry) =>
          entry.months.map((month) => month.month)
        )
      ),
    ];

    // Prepare data series for ApexCharts
    const seriesData = months.map((month) => ({
      name: month,
      data: offenseLevelCases.map(
        (entry) => entry.months.find((data) => data.month === month)?.cases || 0
      ),
    }));

    const options = {
      chart: {
        type: "bar",
        stacked: false,
        toolbar: { show: false },
      },
      xaxis: {
        // Show indicator names on the x-axis
        categories: offenseLevelCases.map((entry) => entry.offenseLevel),
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "25%",

          // columnWidth: "50%", // Adjust the width of the bars
        },
      },
      fill: {
        colors: months.map((month) => getColorForMonth(month)), // Apply color based on month
      },
      dataLabels: {
        enabled: false,
      },
      theme: {
        mode: "light",
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 5, // Adjust width of the stroke
        colors: ["transparent"], // Color of the stroke
      },
    };

    return (
      <CustomCard>
        <Chart
          options={options}
          series={seriesData}
          type="bar"
          width="100%"
          height={255}
        />
        <div className="chart-legend">
          {months.map((month) => (
            <div
              key={month}
              className="legend-item"
              style={{ marginBottom: "8px" }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: 16,
                  height: 16,
                  backgroundColor: getColorForMonth(month),
                  marginRight: 8,
                }}
              />
              <span>{month}</span>
            </div>
          ))}
        </div>
      </CustomCard>
    );
  };

  return (
    <div>
      <div id="report-counter"></div>
      <div style={{ marginTop: 20 }}>
        <Row gutter={[20, 20]}>
          {/* Responsive settings for the chart */}
          <Col xxl={16} xl={14} lg={24} md={24} sm={24} xs={24}>
            {renderIndicatorsChart()}
          </Col>

          {/* Responsive settings for the table or other content */}
          <Col xxl={8} xl={10} lg={24} md={24} sm={24} xs={24}>
            <IndicatorCaseTable data={data} title={tableTitle} />
          </Col>
        </Row>
        <br />
        <Row gutter={[20, 20]}>
          <Col md={24} sm={24} xs={24}>
            <SchoolsCaseTable data={data} title={year} />
          </Col>
        </Row>
      </div>
      <br />
      <div>
        <TitleWithLine
          title={
            type === "offense"
              ? "Negative Behaviour Cases Reported"
              : `${chartTitle + " " + "Cases Reported"}`
          }
        />

        <Row gutter={[20, 20]}>
          <Col md={12}>{renderNegativeCasesSchoolsChart()}</Col>
          <Col md={12}>
            <SchoolTypeCaseTable
              data={data}
              title={
                type === "offense" ? "Negative Behaviour Breakdown" : tableTitle
              }
            />
          </Col>
        </Row>
      </div>
      <br />
      {type === "offense" && (
        <div>
          <TitleWithLine title={"Offense Level Breakdown"} />
          <Row gutter={[20, 20]}>
            <Col md={12}>{renderOffenseLevelCaseChart()}</Col>
            <Col md={12}>
              <OffenseLevelCaseTable data={data} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ENSWideCamparison;
