/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import {
  Button,
  Checkbox,
  Form,
  Row,
  Select,
  Tooltip,
  Popconfirm,
  Spin,
  // Steps,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import * as React from "react";
import CommonTable from "components/Tables/CommonTable";

import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { renderLoader } from "utils/helpers/helpers";
import { getStageConfigDataAPI } from "apis/stages-api/stages-api";
import { requestErrorHandel } from "utils/helpers/helpers";
import { addStageDataAPI } from "apis/stages-api/stages-api";
import { editStageDataAPI } from "apis/stages-api/stages-api";
import { deleteStageDataAPI } from "apis/stages-api/stages-api";
import { getEServicesAPI } from "apis/e-services-api/EServicesApi";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
import { Capitalize } from "utils/helpers/helpers";
import { fetchAllFsmFacilities } from "views/FSM/facility-mangement/all-facilities/api";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Update",
};
const _teamType = {
  TEAM: "TEAM",
  SCHEDULE: "SCHEDULE",
  REMARKS: "REMARKS",
  VIEWONLY: "VIEWONLY",
};

const SccStagesConfiguration = (props) => {
  const dispatch = useDispatch();
  const currentStage = props.location.state;
  const isFSMStageConfig = ["EVB", "LTB", "FFB", "DPB", "PHB"].includes(
    currentStage?.applicationType
  );
  const isSccStageConfig = ["SCC"].includes(currentStage?.applicationType);

  const [registerForm] = Form.useForm();
  const [showModal, toggleModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  const [editId, setEditId] = React.useState("");
  const _stages = useSelector((state) => state.metaData.stages);
  const [totalRecords, setTotalRecords] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const [selectedCampuses, setSelectedCampuses] = React.useState([]);
  const [selectedSchools, setSelectedSchools] = React.useState([]);
  const [selectedGrades, setSelectedGrades] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [selectedFacilities, setSelectedFacilities] = React.useState([]);
  // for remarsk users if the currentStage slug is interview/observation/entranceTest
  const [selectedRemarksUsers, setSelectedRemarksUsers] = React.useState([]);
  const [selectedScheduleUsers, setSelectedScheduleUsers] = React.useState([]);

  // *********for remarsk users if the currentStage slug is interview/observation/entranceTest

  const [viewOnlyData, setViewOnlyData] = React.useState([]);
  const [selectedViewOnly, selectViewOnly] = React.useState([]);
  const [selectedStages, setSelectedStages] = React.useState([]);
  const [approvalType, setApprovalType] = React.useState("");
  const [campusesFilteredSchools, setCampusesFilteredSchools] = React.useState(
    []
  );
  const [existingUsers, setExistingUsers] = React.useState([]);
  const [existingViewOnyUsers, setExistingViewOnlyUsers] = React.useState([]);
  const [existingScheduedUsers, setExistingScheduedUsers] = React.useState([]);
  const [existingRemarksUsers, setExistingRemarksUsers] = React.useState([]);
  const [userAdded, setUserAdded] = React.useState(false);
  const [userRemoved, setUserRemoved] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [assignExistingApps, setAssignExistingApps] = React.useState(false);
  const [removeFromExistingApps, setRemoveFromExistingApps] =
    React.useState(false);
  const [applicationFeeNeeded, setApplicationFeeNeeded] = React.useState(false);
  const [addRemoveUsersFlags, setAddRemoveUsersFlags] = React.useState({
    team: {
      isUserAdded: false,
      isUserRemoved: false,
    },
    schedule: {
      isUserAdded: false,
      isUserRemoved: false,
    },
    remarks: {
      isUserAdded: false,
      isUserRemoved: false,
    },
    viewOnly: {
      isUserAdded: false,
      isUserRemoved: false,
    },
  });

  const [stageConfigurations, setStageConfigurations] = React.useState([]);

  const _campuses = useSelector((state) => state.metaData.campuses);
  const _allSchools = useSelector((state) => state.metaData.schools);
  const _grades = useSelector((state) => state.metaData.grades);
  const _users = useSelector((state) =>
    state.metaData.users.filter(
      (_usr) => _usr.hasSccAccess && !_usr.canViewOnly
    )
  );
  const _eServices = useSelector((state) =>
    state.eservices?.data?.filter(
      (eService) => eService?.applicationType === "ASR"
    )
  );
  const [loading, setLoading] = React.useState(false);

  const [facilities, setFacilities] = React.useState([]);
  const [sccCaseCreationUsers, setSccCaseCreationUsers] = React.useState([]);
  const [existingSccCaseCreationUsers, setExistingSccCaseCreationUsers] =
    React.useState([]);

  React.useEffect(() => {
    let _defStages = [];
    _stages.forEach((stage) => {
      if (
        stage.slug === "registration" ||
        stage.slug === "final_review_approval" ||
        stage.slug === "registration_fees"
      ) {
        _defStages.push(stage);
      }
    });
    setSelectedStages(_defStages);
    currentStage.applicationType === "ASR" && fetchEServices();
  }, []);

  React.useEffect(() => {
    if (currentStage.id) {
      fetchStageConfigurations(currentStage.id);
    }
  }, [currentStage, currentPage]);

  const fetchEServices = async () => {
    try {
      const paginationParams = `page=${1}&limit=${1000}`;
      const eServiceResponse = await getEServicesAPI(paginationParams);
      if (eServiceResponse.data && eServiceResponse.status === 200) {
        dispatch(_getEServicesAction(eServiceResponse.data));
      } else throw eServiceResponse.error;
    } catch (e) {
      console.log({ e });
    }
  };

  const fetchStageConfigurations = async (stageId) => {
    try {
      setLoading(true);
      const configurationResponse = await getStageConfigDataAPI(
        stageId,
        `?page=${currentPage}&limit=10`,
        isFSMStageConfig
      );
      if (configurationResponse.data && configurationResponse.status === 200) {
        setStageConfigurations(configurationResponse.data.data);
        setTotalRecords(configurationResponse.data.total);
        setLoading(false);
      } else throw configurationResponse.error;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  let selectedCampusesesIds = [];

  //  filter all schools againt the selected campuses
  const filterSchoolsByCampus = () => {
    selectedCampuses.forEach((campus) => {
      selectedCampusesesIds.push(campus.id);
    });
    setCampusesFilteredSchools(
      _allSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
    setSelectedSchools(
      selectedSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
  };

  React.useEffect(() => {
    filterSchoolsByCampus();
  }, [selectedCampuses]);

  React.useEffect(() => {
    let viewOnlyUserList = _users.filter(
      (user) => !selectedUsers?.some((_usr) => user.id === _usr.id)
    );
    viewOnlyUserList = viewOnlyUserList.filter(
      (user) => !selectedRemarksUsers?.some((_usr) => user.id === _usr.id)
    );
    viewOnlyUserList = viewOnlyUserList.filter(
      (user) => !selectedScheduleUsers?.some((_usr) => user.id === _usr.id)
    );
    setViewOnlyData(viewOnlyUserList);

    selectedViewOnly.length > 0 &&
      selectViewOnly(
        selectedViewOnly?.filter((viewOnly) =>
          viewOnlyUserList?.some((_usr) => viewOnly.id === _usr.id)
        )
      );
  }, [selectedUsers, selectedRemarksUsers, selectedScheduleUsers]);

  React.useEffect(() => {
    getAllFsmFacilities();
  }, []);
  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };

  const onEdit = (item) => {
    let viewOnlyUsers = item.users.filter((el) => el.canViewOnly);
    setAddRemoveUsersFlags({
      team: {
        isUserAdded: false,
        isUserRemoved: false,
      },
      schedule: {
        isUserAdded: false,
        isUserRemoved: false,
      },
      remarks: {
        isUserAdded: false,
        isUserRemoved: false,
      },
      viewOnly: {
        isUserAdded: false,
        isUserRemoved: false,
      },
    });
    toggleModal(true);
    setModalTitle(ModalType.EDIT);
    setEditId(item.id);
    setIsEdit(true);
    setSelectedCampuses(item.campuses);
    setSelectedSchools(item.schools);
    setSelectedGrades(item.grades);
    setSelectedFacilities(item?.facilities);
    currentStage.slug === "registration" &&
      setSelectedStages(item.defaultStages);
    setSelectedUsers(item.users.filter((el) => !el.canViewOnly));
    setSccCaseCreationUsers(item?.users?.filter((el) => el.canReportCase));
    if (
      (currentStage?.applicationType === "ENR" &&
        currentStage?.slug === "interview") ||
      (currentStage?.applicationType === "ENR" &&
        currentStage?.slug === "observation") ||
      (currentStage?.applicationType === "ENR" &&
        currentStage?.slug === "entrance_test")
    ) {
      setSelectedRemarksUsers(item?.users?.filter((el) => el.canAddRemarks));
      setSelectedScheduleUsers(item?.users?.filter((el) => el.canSchedule));
    }

    selectViewOnly(item.users.filter((el) => el.canViewOnly));
    registerForm.setFieldsValue({ eServiceId: item?.eService?.id });

    setExistingUsers(item.users);
    setExistingRemarksUsers(item?.users?.filter((el) => el.canAddRemarks));
    setExistingScheduedUsers(item?.users?.filter((el) => el.canSchedule));
    setExistingViewOnlyUsers(item.users.filter((el) => el.canViewOnly));
    setExistingSccCaseCreationUsers(
      item?.users?.filter((el) => el.canReportCase)
    );

    setApprovalType(item.approvalType);
    setUserAdded(false);
    setUserRemoved(false);
    setAssignExistingApps(false);
    setRemoveFromExistingApps(false);
    setApplicationFeeNeeded(item.applicationFeeNeeded);
  };

  const onAdd = () => {
    setAddRemoveUsersFlags({
      team: {
        isUserAdded: false,
        isUserRemoved: false,
      },
      schedule: {
        isUserAdded: false,
        isUserRemoved: false,
      },
      remarks: {
        isUserAdded: false,
        isUserRemoved: false,
      },
      viewOnly: {
        isUserAdded: false,
        isUserRemoved: false,
      },
    });
    let _defStages = [];
    _stages.forEach((stage) => {
      if (
        stage.slug === "registration" ||
        stage.slug === "final_review_approval" ||
        stage.slug === "registration_fees"
      ) {
        _defStages.push(stage);
      }
    });
    //  open modal to create new config
    setModalTitle(ModalType.ADD);
    registerForm.resetFields();
    toggleModal(true);
    setSelectedCampuses([]);
    setSelectedGrades([]);
    setSelectedFacilities([]);
    setSelectedStages(_defStages);
    setSelectedUsers([]);
    selectViewOnly([]);
    setSelectedCampuses([]);
    setSelectedSchools([]);
    setApprovalType("");
    setAssignExistingApps(false);
    setRemoveFromExistingApps(false);
    setUserAdded(false);
    setUserRemoved(false);
    setSelectedScheduleUsers([]);
    setSelectedScheduleUsers([]);
    setSccCaseCreationUsers([]);
    setIsEdit(false);
  };
  const onDelete = async (item) => {
    try {
      setLoading(true);
      const deleteResponse = await deleteStageDataAPI(
        item.id,
        isFSMStageConfig
      );
      if (deleteResponse.status == 200) {
        setLoading(false);
        NotificationManager.success("Record deleted");
        fetchStageConfigurations(currentStage.id);
      }
    } catch (e) {
      setLoading(false);
      console.log({ e });
      requestErrorHandel({ error: e });
    }
  };
  const validateInputs = () => {
    if (!isFSMStageConfig) {
      return (selectedCampuses?.length > 0 &&
        selectedSchools.length > 0 &&
        selectedGrades.length > 0 &&
        currentStage?.applicationType === "ENR" &&
        currentStage?.slug === "interview") ||
        (currentStage?.applicationType === "ENR" &&
          currentStage?.slug === "observation") ||
        (currentStage?.applicationType === "ENR" &&
          currentStage?.slug === "entrance_test")
        ? selectedRemarksUsers.length > 0
        : (currentStage?.applicationType === "ENR" &&
            currentStage?.slug === "interview") ||
          (currentStage?.applicationType === "ENR" &&
            currentStage?.slug === "observation") ||
          (currentStage?.applicationType === "ENR" &&
            currentStage?.slug === "entrance_test")
        ? selectedScheduleUsers.length > 0
        : selectedUsers.length > 0;
    } else {
      return (
        selectedCampuses?.length > 0 &&
        selectedSchools.length > 0 &&
        selectedFacilities.length > 0 &&
        selectedUsers.length > 0
      );
    }
  };
  const onSubmit = async (value) => {
    await onSubmitSccConductCaseManagementStageConfiguration(value);
  };

  const onSubmitSccConductCaseManagementStageConfiguration = async (value) => {
    if (validateInputs()) {
      let params = {
        stage: currentStage.id,
        campuses: selectedCampuses.map((param) => param.id),
        schools: selectedSchools.map((param) => param.id),
        grades: selectedGrades.map((param) => param.id),
        users: selectedUsers.map((param) => param.id),
        viewOnlyUsers: selectedViewOnly.map((param) => param.id),
        caseReportingUsers: sccCaseCreationUsers.map((param) => param.id),
        assignExistingApps: assignExistingApps,
        removeFromExistingApps: removeFromExistingApps,
        approvalType: "parallel",
      };

      try {
        setLoading(true);
        if (modalTitle === ModalType.ADD) {
          const response = await addStageDataAPI(params, isFSMStageConfig);
          if (response.status == 200) {
            NotificationManager.success("Record added successfully");
            await fetchStageConfigurations(currentStage.id);
          } else throw response.error;
        } else {
          const response = await editStageDataAPI(
            editId,
            params,
            isFSMStageConfig
          );
          if (response.status == 200) {
            NotificationManager.success("Record updated successfully");
            await fetchStageConfigurations(currentStage.id);
          } else throw response.error;
        }
        setLoading(false);
        toggleModal(false);
      } catch (e) {
        console.log({ e });
        setLoading(false);
        requestErrorHandel({ error: e });
        // toggleModal(false);
      }
    } else {
      NotificationManager.error("Please submit all fields");
      // toggleModal(false);
      return;
    }
  };

  const handleSelectAllCampus = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedCampuses(_campuses);
    } else {
      return value;
    }
  };
  const handleAllSccCaseCreationUsers = (value) => {
    if (value && value.length && value.includes("all")) {
      const filteredUsers = selectedUsers.filter((_usr) => _usr.hasSccAccess);
      setSccCaseCreationUsers(filteredUsers);
      compareExistingusers(existingSccCaseCreationUsers);
    } else {
      return value;
    }
  };
  const handleChangeSccCaseCreationUsers = async (e, el) => {
    let _sccCaseCreationUsers = [...sccCaseCreationUsers];

    if (e.target.checked) {
      _sccCaseCreationUsers = [..._sccCaseCreationUsers, el];
    } else {
      _sccCaseCreationUsers = [..._sccCaseCreationUsers].filter(
        (i) => i.id !== el.id
      );
    }
    setSccCaseCreationUsers(_sccCaseCreationUsers);
  };

  const handleSelectAllSchools = (value) => {
    if (value && value.length && value.includes("all")) {
      selectedCampuses.forEach((camp) => {
        selectedCampusesesIds.push(camp.id);
      });
      const _campuseSchools = _allSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      );
      setSelectedSchools(_campuseSchools);
    } else {
      return value;
    }
  };

  const handleSelectAllGrades = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedGrades(_grades);
    } else {
      return value;
    }
  };

  const handleSelectAllUser = (value) => {
    if (value && value.length && value.includes("all")) {
      const filteredUsers = _users.filter(
        (_usr) => _usr.hasSccAccess && !_usr.canViewOnly
      );

      setSelectedUsers(filteredUsers);
      compareExistingusers(filteredUsers);
    } else {
      return value;
    }
  };

  // for currentStage slug if interview/observation/entranceTest
  const handleSelectAllRemarksUser = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedRemarksUsers(_users);
      compareExistingusers(_users);
    } else {
      return value;
    }
  };
  const changeSelectedRemarksUsers = async (e, el) => {
    let _selectedRemarksUsersCopy = [...selectedRemarksUsers];
    if (e.target.checked) {
      _selectedRemarksUsersCopy = [..._selectedRemarksUsersCopy, el];
    } else {
      _selectedRemarksUsersCopy = [..._selectedRemarksUsersCopy].filter(
        (i) => i.id !== el.id
      );
    }
    setSelectedRemarksUsers(_selectedRemarksUsersCopy);
    compareExistingusers(
      _selectedRemarksUsersCopy,
      existingRemarksUsers,
      _teamType.REMARKS
    );
  };
  const handleSelectAllScheduleUser = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedScheduleUsers(_users);
      compareExistingusers(_users);
    } else {
      return value;
    }
  };
  const changeSelectedScheduleUsers = async (e, el) => {
    let _selectedScheduleUsersCopy = [...selectedScheduleUsers];
    if (e.target.checked) {
      _selectedScheduleUsersCopy = [..._selectedScheduleUsersCopy, el];
    } else {
      _selectedScheduleUsersCopy = [..._selectedScheduleUsersCopy].filter(
        (i) => i.id !== el.id
      );
    }
    setSelectedScheduleUsers(_selectedScheduleUsersCopy);
    compareExistingusers(
      _selectedScheduleUsersCopy,
      existingScheduedUsers,
      _teamType.SCHEDULE
    );
  };
  // **************for currentStage slug if interview/observation/entranceTest

  const changeSelectedUsers = async (e, el) => {
    let _selectedUsersCopy = [...selectedUsers];
    if (e.target.checked) {
      _selectedUsersCopy = [..._selectedUsersCopy, el];
    } else {
      _selectedUsersCopy = [..._selectedUsersCopy].filter(
        (i) => i.id !== el.id
      );
    }
    setSelectedUsers(_selectedUsersCopy);
    compareExistingusers(_selectedUsersCopy, existingUsers, _teamType.TEAM);
  };
  const compareExistingusers = (
    selectedUsersCopy,
    _existingUsers,
    teamType
  ) => {
    if (!selectedUsersCopy || !Array.isArray(selectedUsersCopy)) {
      console.error("selectedUsersCopy is not defined or not an array");
      return;
    }
    if (!_existingUsers || !Array.isArray(_existingUsers)) {
      console.error("_existingUsers is not defined or not an array");
      return;
    }
    let newSelectedUsersIds = selectedUsersCopy.map((param) => param.id);
    const existingUsersIds = _existingUsers.map((param) => param.id);

    const newUsers = newSelectedUsersIds.filter(
      (id) => existingUsersIds.indexOf(id) < 0
    );

    const removedUsers = existingUsersIds.filter(
      (id) => newSelectedUsersIds.indexOf(id) < 0
    );

    if (teamType === _teamType.VIEWONLY) {
      setAddRemoveUsersFlags({
        ...addRemoveUsersFlags,
        viewOnly: {
          isUserAdded: newUsers.length > 0,
          isUserRemoved: removedUsers.length > 0,
        },
      });
    } else if (teamType === _teamType.SCHEDULE) {
      setAddRemoveUsersFlags({
        ...addRemoveUsersFlags,
        schedule: {
          isUserAdded: newUsers.length > 0,
          isUserRemoved: removedUsers.length > 0,
        },
      });
    } else if (teamType === _teamType.REMARKS) {
      setAddRemoveUsersFlags({
        ...addRemoveUsersFlags,
        remarks: {
          isUserAdded: newUsers.length > 0,
          isUserRemoved: removedUsers.length > 0,
        },
      });
    } else if (teamType === _teamType.TEAM) {
      setAddRemoveUsersFlags({
        ...addRemoveUsersFlags,
        team: {
          isUserAdded: newUsers.length > 0,
          isUserRemoved: removedUsers.length > 0,
        },
      });
    }
    if (!isFSMStageConfig && removedUsers.length > 0) {
      setSccCaseCreationUsers(
        sccCaseCreationUsers.filter((item) =>
          newSelectedUsersIds.includes(item.id)
        )
      );
    }
  };

  const onChangeViewOnly = (checked, el) => {
    let _selectedViewOnlyCopy = [...selectedViewOnly];
    if (checked) {
      selectViewOnly([...selectedViewOnly, el]);
      _selectedViewOnlyCopy = [..._selectedViewOnlyCopy, el];
    } else {
      selectViewOnly(selectedViewOnly.filter((i) => i.id !== el.id));
      _selectedViewOnlyCopy = [..._selectedViewOnlyCopy].filter(
        (i) => i.id !== el.id
      );
    }

    compareExistingusers(
      _selectedViewOnlyCopy,
      existingViewOnyUsers,
      _teamType.VIEWONLY
    );
  };

  const handleSelectAllDefaultState = (value) => {
    const stage = _stages;
    if (value && value.length && value.includes("all")) {
      setSelectedStages(stage);
    } else {
      return value;
    }
  };

  const fetchRecords = async (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        {/* Table for old education types */}

        <CommonTable
          key={"#"}
          loading={loading}
          data={stageConfigurations}
          header={() => (
            <TableHeader
              headerTitle={`${currentStage.applicationType}-${currentStage?.title} Stage`}
              headerSubTitle={`Stage Configuration`}
              headerBtnTitle="Add New"
              onAddNew={onAdd}
            />
          )}
          columns={[
            {
              key: "id",
              title: "Campuses",
              dataIndex: "campuses",
              render: (item) => {
                return item.length === _campuses.length
                  ? "All"
                  : item.map((el) => el.code).join(", ");
              },
            },
            {
              key: "id",
              title: "Schools",
              dataIndex: "schools",
              render: (item) => {
                return item.length === _allSchools.length
                  ? "All"
                  : item.map((el) => el.abbreviation).join(", ");
              },

              //(item) => item.map((el) => el.abbreviation).join(", "),
            },
            {
              key: "id",
              title: "Grades",
              dataIndex: "grades",
              render: (item) => {
                return item.length === _grades.length
                  ? "All"
                  : item.map((el) => el.code).join(", ");
              },

              //(item) => item.map((el) => el.code).join(", "),
            },

            {
              key: "id",
              title: "Teams",
              dataIndex: "users",
              // render: (item) => item.map((el) => el.name).join(", "),
              render: (item) => {
                return item.length === _users.length
                  ? "All"
                  : item.map((el) => el.name).join(", ");
              },
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onEdit(item)}
                      />
                    </Tooltip>
                  </div>
                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
          totalRecord={totalRecords}
          pageSize={pageSize}
          fetchRecords={fetchRecords}
        ></CommonTable>

        <CustomModal
          visibility={showModal}
          handleCancel={() => toggleModal(false)}
          title={`${modalTitle} ${currentStage?.title} Configuration`}
        >
          <Form
            form={registerForm}
            layout="vertical"
            size="large"
            onFinish={onSubmit}
          >
            <Form.Item name="id" id="stage" style={{ display: "none" }}>
              <input defaultValue={currentStage.id} />
            </Form.Item>
            {/* if application type is ASR show a list of ASR Eservices in a dropdown */}
            {currentStage.applicationType === "ASR" && (
              <Form.Item
                label={"Service"}
                name={"eServiceId"}
                rules={[
                  {
                    required: true,
                    message: "Service is required",
                  },
                ]}
              >
                <Select placeholder={"Select Service"}>
                  {_eServices?.map((ASRService) => (
                    <Option value={ASRService.id} key={ASRService.id}>
                      {ASRService.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label={"Campuses"}>
              <Select
                placeholder="Select campus"
                mode={
                  currentStage.applicationType === "TFD"
                    ? undefined
                    : "multiple"
                }
                value={
                  selectedCampuses.length > 0
                    ? selectedCampuses.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={handleSelectAllCampus}
              >
                {currentStage.applicationType !== "TFD" && (
                  <Option key="all" value="all">
                    Select All
                  </Option>
                )}
                {_campuses.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedCampuses.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedCampuses(
                          e.target.checked
                            ? currentStage.applicationType === "TFD"
                              ? [el]
                              : [...selectedCampuses, el]
                            : selectedCampuses.filter((i) => i.id !== el.id)
                        );
                      }}
                    >
                      {el.code}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Schools"}>
              <Select
                placeholder="Select school"
                mode="multiple"
                value={
                  selectedSchools.length > 0
                    ? selectedSchools
                        .map((item) => item.abbreviation)
                        ?.join(", ")
                    : []
                }
                onChange={handleSelectAllSchools}
              >
                {campusesFilteredSchools.length > 1 && (
                  <Option key="all" value="all">
                    Select All
                  </Option>
                )}

                {currentStage.applicationType !== "TFD" &&
                  campusesFilteredSchools.map((el) => (
                    <Option value={el.id}>
                      <Checkbox
                        checked={
                          selectedSchools.findIndex((i) => i.id === el.id) > -1
                        }
                        onChange={(e) => {
                          setSelectedSchools(
                            e.target.checked
                              ? [...selectedSchools, el]
                              : selectedSchools.filter((i) => i.id !== el.id)
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.abbreviation}
                      </Checkbox>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Grades"}>
              <Select
                placeholder="Select grade"
                mode="multiple"
                value={
                  selectedGrades.length > 0
                    ? selectedGrades.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={handleSelectAllGrades}
              >
                <Option key="all" value="all">
                  Select All
                </Option>

                {currentStage.applicationType !== "TFD" &&
                  _grades.map((el, i) => (
                    <Option value={el.id} key={i}>
                      <Checkbox
                        checked={
                          selectedGrades.findIndex((i) => i.id === el.id) > -1
                        }
                        onChange={(e) => {
                          setSelectedGrades(
                            e.target.checked
                              ? [...selectedGrades, el]
                              : selectedGrades.filter((i) => i.id !== el.id)
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.code}
                      </Checkbox>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {(currentStage?.applicationType === "ENR" &&
              currentStage?.slug === "interview") ||
            (currentStage?.applicationType === "ENR" &&
              currentStage?.slug === "observation") ||
            (currentStage?.applicationType === "ENR" &&
              currentStage?.slug === "entrance_test") ? (
              <>
                <Form.Item label={"Schedule Users"}>
                  <Select
                    mode="multiple"
                    value={
                      selectedScheduleUsers?.length > 0
                        ? selectedScheduleUsers
                            .filter((el) => !el.canViewOnly)
                            .map((el) => el.name)
                            ?.join(", ")
                        : []
                    }
                    showSearch
                    filterOption
                    onChange={handleSelectAllScheduleUser}
                  >
                    <Option key="all" value="all">
                      Select All
                    </Option>
                    {_users.map((el, i) => (
                      <Option value={el.name} key={i}>
                        <Checkbox
                          checked={
                            selectedScheduleUsers?.findIndex(
                              (i) => i.id === el.id && !i.canViewOnly
                            ) > -1
                          }
                          onChange={(e) => {
                            changeSelectedScheduleUsers(e, el);
                          }}
                          style={{ width: "100%" }}
                        >
                          {el.name}
                        </Checkbox>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={"Remarks Users"}>
                  <Select
                    mode="multiple"
                    value={
                      selectedRemarksUsers?.length > 0
                        ? selectedRemarksUsers
                            .filter((el) => !el.canViewOnly)
                            .map((el) => el.name)
                            ?.join(", ")
                        : []
                    }
                    showSearch
                    filterOption
                    onChange={handleSelectAllRemarksUser}
                  >
                    <Option key="all" value="all">
                      Select All
                    </Option>
                    {_users.map((el, i) => (
                      <Option value={el.name} key={i}>
                        <Checkbox
                          checked={
                            selectedRemarksUsers?.findIndex(
                              (i) => i.id === el.id && !i.canViewOnly
                            ) > -1
                          }
                          onChange={(e) => {
                            changeSelectedRemarksUsers(e, el);
                          }}
                          style={{ width: "100%" }}
                        >
                          {el.name}
                        </Checkbox>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : (
              <Form.Item label={"Teams"}>
                <Select
                  mode="multiple"
                  value={
                    selectedUsers.length > 0
                      ? selectedUsers
                          .filter((el) => !el.canViewOnly)
                          .map((el) => el.name)
                          ?.join(", ")
                      : []
                  }
                  showSearch
                  filterOption
                  onChange={handleSelectAllUser}
                >
                  <Option key="all" value="all">
                    Select All
                  </Option>
                  {_users
                    .filter((_usr) => _usr.hasSccAccess)
                    .map((el, i) => (
                      <Option value={el.name} key={i}>
                        <Checkbox
                          checked={
                            selectedUsers.findIndex(
                              (i) => i.id === el.id && !i.canViewOnly
                            ) > -1
                          }
                          onChange={(e) => {
                            changeSelectedUsers(e, el);
                          }}
                          style={{ width: "100%" }}
                        >
                          {el.name}
                        </Checkbox>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label={"Conduct Case Creation Team"}>
              <Select
                mode="multiple"
                value={
                  sccCaseCreationUsers.length > 0
                    ? sccCaseCreationUsers
                        .filter((el) => !el.canViewOnly)
                        .map((el) => el.name)
                        ?.join(", ")
                    : []
                }
                showSearch
                filterOption
                onChange={handleAllSccCaseCreationUsers}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {selectedUsers.map((el, i) => (
                  <Option value={el.name} key={i}>
                    <Checkbox
                      checked={
                        sccCaseCreationUsers.findIndex(
                          (i) => i.id === el.id && !i.canViewOnly
                        ) > -1
                      }
                      onChange={(e) => {
                        handleChangeSccCaseCreationUsers(e, el);
                      }}
                      style={{ width: "100%" }}
                    >
                      {el.name}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={"View Only"}>
              <Select
                mode="multiple"
                value={
                  selectedViewOnly?.length > 0
                    ? selectedViewOnly.map((el) => el.name)?.join(", ")
                    : []
                }
                showSearch
                filterOption
                onChange={(e) => {
                  e?.includes("all") && selectViewOnly(viewOnlyData);
                }}
              >
                {viewOnlyData.length > 0 && (
                  <Option key="all" value="all">
                    Select All
                  </Option>
                )}
                {viewOnlyData
                  .filter((_usr) => _usr.hasSccAccess)
                  .map((el, i) => (
                    <Option value={el.name} key={i}>
                      <Checkbox
                        checked={
                          selectedViewOnly.findIndex(
                            (param) => param.id === el.id
                          ) > -1
                        }
                        onChange={(e) => {
                          onChangeViewOnly(e.target.checked, el);
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.name}
                      </Checkbox>
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <>
              <Form.Item>
                {!isEdit ? (
                  <>
                    <Checkbox
                      checked={assignExistingApps}
                      onChange={() =>
                        setAssignExistingApps(!assignExistingApps)
                      }
                    >
                      Assign Existing Cases
                    </Checkbox>
                  </>
                ) : (
                  <>
                    {addRemoveUsersFlags.viewOnly.isUserAdded ||
                    addRemoveUsersFlags.team.isUserAdded ||
                    addRemoveUsersFlags.remarks.isUserAdded ||
                    addRemoveUsersFlags.schedule.isUserAdded ? (
                      <>
                        <Checkbox
                          checked={assignExistingApps}
                          onChange={() =>
                            setAssignExistingApps(!assignExistingApps)
                          }
                        >
                          Assign Existing Cases
                        </Checkbox>
                      </>
                    ) : null}
                    {addRemoveUsersFlags.viewOnly.isUserRemoved ||
                    addRemoveUsersFlags.team.isUserRemoved ||
                    addRemoveUsersFlags.remarks.isUserRemoved ||
                    addRemoveUsersFlags.schedule.isUserRemoved ? (
                      <>
                        <Checkbox
                          checked={removeFromExistingApps}
                          onChange={() =>
                            setRemoveFromExistingApps(!removeFromExistingApps)
                          }
                        >
                          Remove Assigned Cases
                        </Checkbox>
                      </>
                    ) : null}
                  </>
                )}
              </Form.Item>
            </>

            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default SccStagesConfiguration;
