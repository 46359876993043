import React, { useEffect, useState } from "react";
import "./style.css";
import { Checkbox, Form, Input, Spin } from "antd";
import { formatedDate } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getArrivedBookingDetails } from "views/FSM/bookings/api";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { markArrivedBySecurityGuard } from "views/FSM/bookings/api";
import { successMessage } from "utils/helpers/helpers";

const RenderModal = ({
  isOpen,
  onClose,
  securityKey,
  onChangeSecurityKey,
  onSubmitSlotsForMarkArrivalBySecurityGuard,
  error,
  onFocus,
  loading,
}) => {
  return (
    <div className={`modal ${isOpen ? "show" : "hide"}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <Form layout="vertical">
          <Form.Item label="Security Key">
            <Input
              type="text"
              placeholder="Type security key"
              style={{ height: 50 }}
              value={securityKey}
              onChange={(e) => onChangeSecurityKey(e.target.value)}
              onFocus={onFocus}
            />

            <p style={{ color: "red", marginTop: "5px" }}>{error}</p>
          </Form.Item>
        </Form>
        <div
          className="m_a_button "
          style={{ width: "100%" }}
          onClick={onSubmitSlotsForMarkArrivalBySecurityGuard}
        >
          <p>{loading ? "Submitting.." : "Submit"}</p>
        </div>
        <div className="m_a_button_default" onClick={onClose}>
          <p>Cancel</p>
        </div>
      </div>
    </div>
  );
};

const MarkedArrived = ({ match }) => {
  const id = match.params.id;
  const [data, setData] = useState({});
  const [securityKey, setSecurityKey] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [isError, setIsError] = useState("");
  const [arrived, setArrived] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getBookingDetails();
  }, []);

  const getBookingDetails = async () => {
    try {
      const response = await getArrivedBookingDetails({ id: id });
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      setError(error);
      requestErrorHandel({ error: error });
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCheckboxChange = (slot, index) => {
    // Check if the slot is already selected
    const isSelected = selectedSlots.some(
      (selectedSlot) => selectedSlot.index === index
    );

    if (isSelected) {
      // If already selected, remove from the array
      const updatedSelectedSlots = selectedSlots.filter(
        (selectedSlot) => selectedSlot.index !== index
      );
      setSelectedSlots(updatedSelectedSlots);
    } else {
      // If not selected, add to the array
      setSelectedSlots((prevSelectedSlots) => [
        ...prevSelectedSlots,
        { ...slot, index },
      ]);
    }
  };

  const onSubmitSlotsForMarkArrivalBySecurityGuard = async () => {
    try {
      if (securityKey === "" || securityKey === undefined) {
        setIsError("Security Key is required");
        return;
      }
      // Extracting just the IDs from selectedSlots
      const slotIds = selectedSlots.map((slot) => slot.id);
      const payload = {
        securityKey: securityKey,
        slots: slotIds,
      };

      setIsLoading(true);
      const response = await markArrivedBySecurityGuard({
        id: id,
        payload: payload,
      });
      if (response.status === 200) {
        successMessage({ message: "Your Booking has been marked arrived" });
        setModalOpen(false);
        setArrived(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);

      requestErrorHandel({ error: error });
    }
  };

  const onHandelMarkArrived = () => {
    if (selectedSlots.length === 0) {
      return;
    }
    setModalOpen(true);
  };

  if (loading) {
    return (
      <div className="mark-arrival-container">
        <div className="m_a_spiner">
          <Spin spinning={loading} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="mark_arrival_parent_container">
        <div className="mark_arrival__container">
          <div className="mark_arrival__header">
            <p>Booking Details</p>
          </div>
          <div className="m_a_invalid_booking">
            <div className="m_a_invalid">
              <WarningOutlined style={{ fontSize: 60, color: "red" }} />
              <p>Invalid Booking</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (arrived) {
    return (
      <div className="mark_arrival_parent_container">
        <div className="mark_arrival__container">
          <div className="mark_arrival__header">
            <p>Booking Details</p>
          </div>
          <div className="m_a_invalid_booking">
            <div className="m_a_invalid">
              <CheckCircleOutlined style={{ fontSize: 60, color: "#007B85" }} />
              <p>Marked Arrived</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (Object.keys(data).length === 0) {
    return (
      <div className="mark_arrival_parent_container">
        <div className="mark_arrival__container">
          <div className="mark_arrival__header">
            <p>Booking Details</p>
          </div>
          <div className="m_a_invalid_booking">
            <div className="m_a_invalid">
              <CloseCircleOutlined style={{ fontSize: 60, color: "#007B85" }} />
              <p>No slots available today </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mark_arrival_parent_container">
      <div className="mark_arrival__container">
        <div className="mark_arrival__header">
          <p>Booking Details</p>
        </div>
        {Object.keys(data).length > 0 && (
          <div className="mark_arrival__body">
            <div className="m_a_facility">
              <div className="m_a_facility_cover">
                <img src={data?.facility?.gallery[0]} />
              </div>
              <div className="m_a_facility_detail">
                <p>{data?.facility?.title}</p>
                <span>{data?.facility?.campus?.code} Campus</span>
              </div>
            </div>
            <div className="m_a_sub_section">
              <div className="m_a_section">
                <p>Booking#</p>
                <span>{data?.title}</span>
              </div>
              <div className="m_a_section">
                <p>Date</p>
                <span>{formatedDate(data?.slots[0]?.date)}</span>
              </div>
            </div>
            <div className="ma_row" />
            <div className="m_a_section">
              <p>Schedule Details</p>
            </div>
            {data?.slots?.map((slot, index) => {
              return (
                <div className="m_a_slot_section" key={index}>
                  <div className="m_a_sub_section">
                    <div className="m_a_section_slot">
                      <p>Slot</p>
                      {slot?.scheduleType === "fullDay" ? (
                        <span className="date_slot">Full Day</span>
                      ) : (
                        <span className="date_slot">
                          {formatedDateWithAmAndPm(slot.startTime)} -{" "}
                          {formatedDateWithAmAndPm(slot.endTime)}
                        </span>
                      )}
                    </div>
                    <div className="m_a_section_slot">
                      <p>Number of people</p>
                      <span className="date_slot">{slot?.noOfAttendees}</span>
                    </div>
                  </div>
                  <div className="ma_row" />
                  {slot.attendeesNames.length > 0 && (
                    <div className="m_a_attendees">
                      <p>Attendees</p>
                      <div className="m_a_attendee">
                        {slot.attendeesNames.map((attendee, index) => {
                          return <span key={index}>{attendee}</span>;
                        })}
                      </div>
                    </div>
                  )}
                  <br />
                  <div className="m_a_checkbox">
                    <Checkbox
                      style={{ width: 20, height: 20, borderColor: "red" }}
                      onChange={() => handleCheckboxChange(slot, index)}
                      checked={selectedSlots.some(
                        (selectedSlot) => selectedSlot.index === index
                      )}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {Object.keys(data).length > 0 && (
          <div className="m_a_button_container">
            <div
              className={`m_a_button ${
                selectedSlots.length === 0 ? "disabled" : ""
              }`}
              onClick={onHandelMarkArrived}
            >
              <p>Mark Arrived</p>
            </div>
          </div>
        )}

        <RenderModal
          isOpen={isModalOpen}
          onClose={closeModal}
          securityKey={securityKey}
          onChangeSecurityKey={(value) => setSecurityKey(value)}
          onSubmitSlotsForMarkArrivalBySecurityGuard={
            onSubmitSlotsForMarkArrivalBySecurityGuard
          }
          error={isError}
          onFocus={() => setIsError("")}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default MarkedArrived;
