import sccDashboard from "../../assets/img/sccMenuIcons/sccDashboard@2x.png";
import behOffenseManagement from "../../assets/img/sccMenuIcons/behOffenseManagement@2x.png";
import cod from "../../assets/img/sccMenuIcons/cod@2x.png";

import sccReportIcon from "../../assets/img/sidebarIcons/report.png";
import caseManagementIcon from "../../assets/img/sidebarIcons/case management.png";
import documentGeneratorIcon from "../../assets/img/sidebarIcons/document generator.png";
import SccCodeOfConductIcon from "../../assets/img/sidebarIcons/code of conduct icon.png";

import SccBasicReports from "views/SCC/scc-basic-reports/SccBasicReports";
import SccOffenseBehaviourManagement from "views/SCC/scc-offense-management/SccOffenseBehaviourManagement";
import SccDocumentGenerator from "views/SCC/scc-document-generator/SccDocumentGenerator";
import SccDetails from "views/SCC/scc-detail-screen/SccDetails";
import SccDashboard from "views/SCC/scc-dashboard/SccDashboard";

const AllSccRoutes = [
  {
    path: "/students-code-of-conducts",
    name: "Student Code of conduct",
    icon: cod,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "scc",
    subMenu: [
      // {
      //   path: "/scc-dashboard",
      //   name: "Dashboard",
      //   icon: SccCodeOfConductIcon,
      //   component: SccDashboard,
      //   layout: "/admin",
      //   subPath: true,
      //   sideMenuTitle: "Dashboard",
      //   state: null,
      // },
      {
        path: "/scc-reports",
        name: "Reports",
        icon: sccReportIcon,
        component: SccBasicReports,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Reports",
        state: null,
      },
      {
        path: "/scc-behaviour-offense-management",
        name: "Case Management",
        icon: behOffenseManagement,
        component: caseManagementIcon,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Case Management",
        state: null,
      },
      {
        path: "/scc-document-generator",
        name: "Document Generator",
        icon: documentGeneratorIcon,
        component: SccDocumentGenerator,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Document Generator",
        state: null,
      },
      
    ],
  },
  {
    path: "/scc/conduct-case-details",
    name: "Details",
    icon: "nc-icon nc-bag-16",
    component: SccDetails,
    layout: "/admin",
    subPath: true,
  },
];
export default AllSccRoutes;
