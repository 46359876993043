import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
  message,
  Layout,
  Typography,
  Card,
  Tabs,
  Form,
  Select,
  Button,
  Upload,
  Space,
  Modal,
  Steps,
  Slider,
  List,
  Input,
  Row,
  Col,
  Popover,
  Tooltip,
  Divider,
  Checkbox,
  Spin,
  Popconfirm,
} from "antd";
import {
  QrcodeOutlined,
  UploadOutlined,
  SettingOutlined,
  FileImageOutlined,
  EyeOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import QRCode from "qrcode";
import fontkit from "@pdf-lib/fontkit";
import { degrees, grayscale, PDFDocument, rgb } from "pdf-lib";
import { saveAs } from "file-saver";
import ensLogo from "../../../assets/img/qrgeneratorimages/logo.png";
import shieldCheck from "../../../assets/img/shield-check.png";
import arrow from "../../../assets/img/qrgeneratorimages/arrow.png";
import timeline from "../../../assets/img/qrgeneratorimages/Timeline.png";

import arabicFont from "../../../assets/fonts/Tajawal/Tajawal-Regular.ttf";
import boldArabicFont from "../../../assets/fonts/Tajawal/Tajawal-Bold.ttf";
import { useEffect, useRef } from "react";
import { createValidationDocument } from "apis/qr-configuration/qrConfigurationApis";
import { useSelector } from "react-redux";

const { Header, Content, Sider } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { Step } = Steps;

const stampTypes = [
  {
    key: "commercial",
    english: "Recieved by ENS Commercial Opening Committee",
    arabic: "تم استلامه من قبل لجنة فتح المظاريف في المدارس",
  },
  {
    key: "financial",
    english: "Verified by ENS Financial Committee",
    arabic: "تم التحقق من قبل اللجنة المالية",
  },
  {
    key: "administration",
    english: "Verified by ENS Administration Committee",
    arabic: "تم التحقق من قبل لجنة الإدارة",
  },
  // Add more stamp types as needed
];

const StampList = ({ stamps, onSelectStamp, onEditStamp }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentStamp, setCurrentStamp] = useState(null);

  const handleEdit = (stamp) => {
    setCurrentStamp(stamp);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    onEditStamp(currentStamp);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (field, value) => {
    setCurrentStamp({ ...currentStamp, [field]: value });
  };

  return (
    <div>
      <List
        grid={{ gutter: 16, column: 2 }}
        dataSource={stamps}
        renderItem={(stamp) => (
          <List.Item>
            <Card
              title={stamp.title}
              actions={[
                <Button onClick={() => onSelectStamp(stamp)}>Select</Button>,
                <Button onClick={() => handleEdit(stamp)}>Edit</Button>,
              ]}
            >
              <img
                src={stamp.qrCodeUrl}
                alt="QR Code"
                style={{ width: "100px", height: "100px" }}
              />
              <Text>{stamp.titleAr}</Text>
            </Card>
          </List.Item>
        )}
      />

      <Modal
        title="Edit Stamp"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form layout="vertical">
          <Form.Item label="Title">
            <Input
              value={currentStamp?.title}
              onChange={(e) => handleChange("title", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Arabic Title">
            <Input
              value={currentStamp?.titleAr}
              onChange={(e) => handleChange("titleAr", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const PdfPreviewer = React.memo(({ pdfData }) => {
  // Create a Blob from the PDF data
  const pdfBlob = useMemo(
    () => new Blob([pdfData], { type: "application/pdf" }),
    [pdfData]
  );

  // Generate a URL for the Blob
  const pdfUrl = useMemo(() => URL.createObjectURL(pdfBlob), [pdfBlob]);

  useEffect(() => {
    // Cleanup: Revoke the object URL when the component unmounts or pdfData changes
    if (!pdfData) return null;
    return () => {
      URL.revokeObjectURL(pdfUrl);
    };
  }, [pdfUrl]);

  return (
    <div style={{ marginTop: "4px" }}>
      <iframe
        src={pdfUrl}
        title="PDF Preview"
        width="100%"
        height="800px"
        style={{ border: "1px solid #f0f0f0" }}
      />
      {/* <a
        href={pdfUrl}
        download="stamped_document.pdf"
        style={{ display: "block", marginTop: "10px" }}
      >
        Download PDF
      </a> */}
    </div>
  );
});

const QrStampGenerator = () => {
  const [password, setPassword] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [position, setPosition] = useState("top-left");
  const [margin, setMargin] = useState(30);
  const [qrSize, setQrSize] = useState(60);
  const [pdfData, setPdfData] = useState(null);
  const [iconVerifyBase64, setIconVerifyBase64] = useState("");
  const [iconArrowBase64, setIconArrowBase64] = useState("");
  const [iconEnsLogo, setIconEnsLogo] = useState("");
  const [iconTimeline, setIconTimeline] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [selectedStampType, setSelectedStampType] = useState(null);
  const [pin, setPin] = useState();
  const [referenceNumber, setReferenceNumber] = useState();
  const [urlLink, setUrlLink] = useState("");
  const [expiry, setExpiry] = useState(null);
  const [showCustomization, setShowCustomization] = useState(false);
  const [validatorTypes, setValidatorTypes] = React.useState([]);
  const [isValidIcon, setIsValidIcon] = useState(false);
  const [enableDownload, setEnableDownload] = useState(false);
  const [isStampReady, setIsStampReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isWatermarked, setIsWatermarked] = useState(true);
  const [validationConfirmed, setValidationConfirmed] = useState(false);
  const [latestPreview,setLatestPreview]=useState(false)


  const allowedValidator = useSelector(
    (state) => state.login.userInfo?.documentValidatorAccess
  );
  const ifSuperAdmin = useSelector(
    (state) => state.login.userInfo?.role === "super-admin"
  );
  console.log(allowedValidator);

  const availableValidators = ifSuperAdmin
    ? validatorTypes
    : allowedValidator[0]?.validationTypes;

  const [stamps, setStamps] = useState([
    { id: 1, title: "Commercial", titleAr: "تجاري", qrCodeUrl: "..." },
    { id: 2, title: "Financial", titleAr: "مالي", qrCodeUrl: "..." },
    // Add more stamps as needed
  ]);
  const [selectedStamp, setSelectedStamp] = useState(null);

  const baseURL = process.env.REACT_APP_BASE_URL;

  const MAX_DIMENSIONS = {
    background: { width: 150, height: 150 },
    logo: { width: 760, height: 150 },
    arrow: { width: 100, height: 100 },
    verify: { width: 100, height: 100 },
  };

  const handleFileUpload = (info, setIconFunction, type) => {
    const file = info.fileList[0]?.originFileObj || info.file;
    if (!file) {
      message.error("Failed to upload file.");
      return;
    }
    const isPng = file.type === "image/png";
    if (!isPng) {
      message.error("You can only upload PNG files!");
      return;
    }
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;
          const { width: maxWidth, height: maxHeight } = MAX_DIMENSIONS[type];

          if (width > maxWidth || height > maxHeight) {
            message.error(
              `Image dimensions for ${type} should be ${maxWidth}x${maxHeight}px or smaller.`
            );
            return;
          }
          message.success("Image uploaded successfully");
          setIconFunction(reader.result);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    } catch (error) {
      message.error("Failed to upload file.");
    }
  };

  const beforeUpload = (file, type) => {
    const isPng = file.type === "image/png";

    if (!isPng) {
      message.error("You can only upload PNG files!");
      return Upload.LIST_IGNORE;
    }
  };

  const handlePdfUpload = (info) => {
    if (info.file.status === "removed") {
      setPdfFile(null);
      setPdfData(null);
      message.info("PDF file removed.");
      return;
    }
    if (info.file) {
      setPdfFile(info.file);
      setPdfData(null);
      message.success(`${info.file.name} file uploaded successfully.`);
    } else {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleSelectStamp = (stamp) => {
    setSelectedStamp(stamp);
  };

  const handleEditStamp = (updatedStamp) => {
    setStamps((prevStamps) =>
      prevStamps.map((stamp) =>
        stamp.id === updatedStamp.id ? updatedStamp : stamp
      )
    );
  };

  const fetchValidatorTypes = async () => {
    try {
      const response = await fetch(
        `${baseURL}/validation-type?page=1&limit=10`
      );
      if (response.ok) {
        const data = await response.json();
        setValidatorTypes(data.data);
      } else {
        throw new Error("Failed to fetch validator types");
      }
    } catch (error) {
      console.error("Error fetching validator types:", error);
    }
  };

  const generateRandomAlphaNumericPin = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const randomPart = Array.from(
      { length: 5 },
      () => characters[Math.floor(Math.random() * characters.length)]
    ).join("");

    const timestampPart = Date.now().toString(36).slice(-3); // Get last 3 characters of base-36 timestamp

    return (randomPart + timestampPart).slice(0, 8); // Ensure it's exactly 8 characters
  };
  const generateReferenceNumber = () => {
    const timestamp = Date.now();

    const randomStr = Math.random().toString(36).substring(2, 8); // Generates a random string like 'a1b2c3'

    return `${timestamp}-${randomStr}`; // Example: "1634132413723-a1b2c3"
  };

  const generateQRCode = async () => {
    const referenceNumber = generateReferenceNumber();
    const pin = generateRandomAlphaNumericPin();
    setPin(pin)
    setReferenceNumber(referenceNumber);

    try {
      const url = `${window.location.origin}/verify-document?reference_number=${referenceNumber}`;
      const qrCodeDataUrl = await QRCode.toDataURL(url, {
        width: 128,
        margin: 2,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
      });
      setQrCodeUrl(qrCodeDataUrl);
      setUrlLink(url);
      setLatestPreview(true)
      return qrCodeDataUrl; // Return the QR Code URL
    } catch (err) {
      console.error("Error generating QR code:", err);
      message.error("Failed to generate QR code");
    }
  };

  useEffect(() => {
    fetchValidatorTypes();
  }, []);

  const addStampToPDF = async () => {
    if (!pdfFile) {
      message.error("Please upload a PDF and generate a QR code first");
      return;
    }
    if (!qrCodeUrl) {
      message.error("Please generate a QR code first");
      return;
    }

    setLoading(true); // Start loading spinner

    try {


      const pdfBytes = await pdfFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      pdfDoc.registerFontkit(fontkit);

      const pages = pdfDoc.getPages();
      const qrCodeImage = await pdfDoc.embedPng(qrCodeUrl);
      const fontBytes = await fetch(arabicFont).then((res) =>
        res.arrayBuffer()
      );
      const boldFontBytes = await fetch(boldArabicFont).then((res) =>
        res.arrayBuffer()
      );
      const arabicFontEmbed = await pdfDoc.embedFont(fontBytes);
      const boldArabicFontEmbed = await pdfDoc.embedFont(boldFontBytes);

      const padding = 10;
      const spacingBetweenElements = 5;
      const iconWidth = 15;
      const iconHeight = 15;
      const ensLogoWidth = 70;
      const ensLogoHeight = 12;
      const qrCodeSize = qrSize;
      const pinFontSize = 12;
      const textFontSize = 8;

      const stampHeight =
        padding +
        qrCodeSize +
        pinFontSize +
        textFontSize * 2 +
        iconHeight +
        4 * spacingBetweenElements;
      const stampWidth = Math.max(
        qrCodeSize + ensLogoWidth + padding * 3,
        textFontSize * 35
      );

      for (const page of pages) {
        const { width, height } = page.getSize();
        let stampX, stampY;

        switch (position) {
          case "top-left":
          case "bottom-left":
            stampX = margin;
            break;
          case "top-right":
          case "bottom-right":
            stampX = width - margin - stampWidth;
            break;
          default:
            stampX = margin;
        }

        switch (position) {
          case "top-left":
          case "top-right":
            stampY = height - margin - stampHeight;
            break;
          case "bottom-left":
          case "bottom-right":
            stampY = margin;
            break;
          default:
            stampY = height - margin - stampHeight;
        }

        // Draw white background rectangle
        page.drawRectangle({
          x: stampX - padding,
          y: stampY + 24,
          width: stampWidth,
          height: stampHeight,
          color: rgb(1, 1, 1),
          // borderWidth:1,
          // borderColor:rgb(0,0,0),
          // borderOpacity:1,
          opacity: 1,
        });
        console.log(
          iconTimeline,
          "\n",
          iconEnsLogo,
          "\n",
          iconVerifyBase64,
          "\n",
          iconArrowBase64
        );

        if (isWatermarked) {
          const previewText = "PREVIEW";
          const previewFontSize = 100;
          const previewTextWidth = boldArabicFontEmbed.widthOfTextAtSize(
            previewText,
            previewFontSize
          );
          const previewX = (width - previewTextWidth) / 2;
          const previewY = height / 4;

          page.drawText(previewText, {
            x: previewX,
            y: previewY,
            size: previewFontSize,
            font: boldArabicFontEmbed,
            color: rgb(0.75, 0.75, 0.75),
            rotate: degrees(45), // Rotate text to be diagonal
            opacity: 0.5, // Make it semi-transparent
          });
        }

        const timelineImage =
          (await pdfDoc.embedPng(iconTimeline)) ||
          (await pdfDoc.embedJpg(iconTimeline));
        page.drawImage(timelineImage, {
          x: stampX - padding,
          y: stampY + 37, // Adjust Y position as needed
          width: stampWidth,
          height: stampHeight - 30,
          opacity: 1, // Adjust opacity if needed
        });
        // Embed ENS Logo Image
        const iconENSLogoImage =
          (await pdfDoc.embedPng(iconEnsLogo)) ||
          (await pdfDoc.embedJpg(iconEnsLogo));
        page.drawImage(iconENSLogoImage, {
          x:
            stampX +
            stampWidth -
            ensLogoWidth -
            padding -
            spacingBetweenElements,
          y: stampY + (stampHeight - ensLogoHeight),
          width: ensLogoWidth,
          height: ensLogoHeight,
        });

        // Embed Verify Icon
        if (!iconVerifyBase64) {
          message.error("Icon image not loaded yet.");
          return;
        }

        const iconImage =
          (await pdfDoc.embedPng(iconVerifyBase64)) ||
          (await pdfDoc.embedJpg(iconVerifyBase64));

        // Embed Arrow Icon
        const arrowIconImage =
          (await pdfDoc.embedPng(iconArrowBase64)) ||
          (await pdfDoc.embedJpg(iconArrowBase64));

        // Embed QR Code Image
        page.drawImage(qrCodeImage, {
          x: stampX + padding / 2,
          y: stampY + stampHeight - padding * 1.6 - qrCodeSize,
          width: qrCodeSize,
          height: qrCodeSize,
        });

        // Add PIN Text below QR Code, centered
        const pinTextWidth = boldArabicFontEmbed.widthOfTextAtSize(
          pin,
          pinFontSize
        );
        const pinX = stampX + padding / 2 + qrCodeSize / 2 - pinTextWidth / 2;
        const pinY =
          stampY +
          stampHeight -
          padding -
          qrCodeSize -
          spacingBetweenElements -
          pinFontSize;

        page.drawText(pin, {
          x: pinX,
          y: pinY,
          size: pinFontSize,
          font: boldArabicFontEmbed,
          color: rgb(0, 0, 0),
        });

        // Calculate vertical center
        const centerY = stampY + stampHeight / 2;

        // Add Arabic Text in the center below QR Code and PIN
        const arabicTextWidth = arabicFontEmbed.widthOfTextAtSize(
          selectedStampType.titleAr,
          textFontSize
        );
        const arabicTextX = stampX + qrCodeSize + padding * 2 + iconWidth;
        const arabicTextY = centerY + textFontSize + 20;

        page.drawText(selectedStampType?.titleAr, {
          x: arabicTextX,
          y: arabicTextY,
          size: textFontSize,
          font: arabicFontEmbed,
          color: rgb(0, 0, 0),
        });

        // Add Arrow Icon next to Arabic Text
        page.drawImage(arrowIconImage, {
          x: arabicTextX - iconWidth - spacingBetweenElements,
          y: arabicTextY - iconHeight / 4,
          width: iconWidth,
          height: iconHeight,
        });

        // Add English Text below Arabic Text, centered
        const englishTextWidth = boldArabicFontEmbed.widthOfTextAtSize(
          selectedStampType?.title,
          textFontSize
        );
        const englishTextX = stampX + qrCodeSize + padding * 2;
        const englishTextY =
          arabicTextY - textFontSize - spacingBetweenElements;

        page.drawText(selectedStampType?.title, {
          x: englishTextX,
          y: englishTextY,
          size: textFontSize,
          font: boldArabicFontEmbed,
          color: rgb(0, 0, 0),
        });

        // Add Verify Icon below the texts, centered horizontally within the stamp
        const verifyIconX =
          stampX +
          qrCodeSize +
          padding * 2 +
          englishTextWidth / 2 -
          iconWidth / 2;
        const verifyIconY = englishTextY - iconHeight - spacingBetweenElements;

        page.drawImage(iconImage, {
          x: verifyIconX,
          y: verifyIconY,
          width: iconWidth,
          height: iconHeight,
        });
      }

      const pdfBytesWithStamp = await pdfDoc.save();

      const blob = new Blob([pdfBytesWithStamp], { type: "application/pdf" });

      const currentDate = new Date();
      const expiryInMilliseconds = expiry * 24 * 60 * 60 * 1000;
      const createdAt = new Date(currentDate.getTime() + expiryInMilliseconds);

      setPdfData(pdfBytesWithStamp);
      setShowPreview(true);
      setLatestPreview(false)
      message.success("PDF stamped and data saved successfully");
      return true
    } catch (error) {
      message.error("Failed to stamp PDF or save data");
      
      return false
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleSaveStamp = async () => {
    if (!pdfData || !qrCodeUrl) {
      message.error("Please generate QR code first");
      return;
    }

    setLoading(true);
   const isStamped = await addStampToPDF();

   if(!isStamped) return;
    const currentDate = new Date();

    const response = await createValidationDocument({
      pin: pin,
      reference: referenceNumber,
      stampType: selectedStampType?.title,
      expiryDateTime: "",
      data: JSON.stringify({
        title: selectedStampType?.title,
        titleAr: selectedStampType.titleAr,
        enableDownload: enableDownload,
        expiryValue: selectedStampType?.expiryValue,
        hasExpiry: expiry !== null,
        location: navigator.geolocation,
        ip_address: navigator.ip,
        current_date: currentDate.toISOString(),
      }),
    });

    console.log(response);
    message.success("Stamp saved successfully");
    
    setPdfFile(null);
    setQrCodeUrl("");
    setSelectedStampType(null);
    setPin("");
    setReferenceNumber("");
    setIsWatermarked(false); // Remove watermark after saving

    handleReset();
    setLoading(false);
  };

  useEffect(() => {
    // Convert shieldCheck image to base64
    const convertImageToBase64 = async () => {
      const response1 = await fetch(shieldCheck);
      const blob1 = await response1.blob();
      const reader1 = new FileReader();
      reader1.onloadend = () => {
        setIconVerifyBase64(reader1.result);
      };
      reader1.readAsDataURL(blob1);

      const response2 = await fetch(arrow);
      const blob2 = await response2.blob();
      const reader2 = new FileReader();
      reader2.onloadend = () => {
        setIconArrowBase64(reader2.result);
      };
      reader2.readAsDataURL(blob2);

      const response3 = await fetch(ensLogo);
      const blob3 = await response3.blob();
      const reader3 = new FileReader();
      reader3.onloadend = () => {
        setIconEnsLogo(reader3.result);
      };
      reader3.readAsDataURL(blob3);

      const response4 = await fetch(timeline);
      const blob4 = await response4.blob();
      const reader4 = new FileReader();
      reader4.onloadend = () => {
        setIconTimeline(reader4.result);
      };
      reader4.readAsDataURL(blob4);
    };

    convertImageToBase64();
  }, []);
  

  //-----------------------v2----------------------

  const [activeTab, setActiveTab] = useState("generate");
  const [customStamp, setCustomStamp] = useState({
    arabicText: "",
    englishText: "",
    textColor: "#000000",
    backgroundColor: "#ffffff",
    logo: null,
    arrow: null,
    verifyIcon: null,
    background: null,
  });


  const handleReset = () => {
    setUrlLink("");
    // setPdfData(null);
    setPdfFile(null);
    setQrCodeUrl("");
    setSelectedStampType(null);
    setIsStampReady(false);
    // setShowPreview(false);
    setIsWatermarked(true);
    setValidationConfirmed(false);
    message.success("All settings have been reset.");
  };

  const link = useCallback(() => {
    const updatedlink = urlLink;
    return updatedlink;
  }, [urlLink]);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ background: "#fff", padding: "0 20px" }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={3} style={{ margin: 0 }}>
              Document Validator
            </Title>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} onClick={handleReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Spin spinning={loading}>
          <Content style={{ padding: "24px" }}>
          <Card style={{ marginBottom: "24px" }}>
              <Row gutter={24}>
                <Form
                  layout="inline"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}
                >
                  <Col xs={24} sm={12}>
                    <Form.Item
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                      }}
                    >
                      <Text style={{ display: "block", marginBottom: "10px" }}>
                        Validation Type
                      </Text>
                      <Select
                        disabled={validationConfirmed && !isWatermarked}
                        value={
                          selectedStampType ? selectedStampType.id : undefined
                        }
                        onChange={(value) => {
                          const selected = availableValidators.find(
                            (type) => type.id === value
                          );
                          setSelectedStampType(selected);
                        }}
                        placeholder="Select Document Validation Type"
                        style={{ width: "300px", border: "1px solid #d9d9d9" }}
                      >
                        {availableValidators?.map((type) => (
                          <Option key={type.id} value={type.id}>
                            {type?.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                      }}
                    >
                      <Text style={{ display: "block", marginBottom: "10px" }}>
                        Upload PDF
                      </Text>

                      <Upload
                        maxCount={1}
                        fileList={pdfFile ? [pdfFile] : []}
                        accept=".pdf"
                        beforeUpload={() => false}
                        onChange={handlePdfUpload}
                        style={{ width: "100%", border: "1px solid #d9d9d9" }}
                        disabled={
                          !selectedStampType ||
                          validationConfirmed ||
                          !isWatermarked
                        } // Disable until QR code is generated
                      >
                        <Button
                          style={{ width: "300px" }}
                          icon={<UploadOutlined />}
                          disabled={
                            !selectedStampType ||
                            validationConfirmed ||
                            !isWatermarked
                          }
                        >
                          Upload PDF
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Form>
              </Row>
            </Card>
            <Divider />

            <Card>
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={
                    <span>
                      <QrcodeOutlined /> Generate Stamp
                    </span>
                  }
                  key="1"
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={12}>
                      <Form style={{ display: "flex", flexDirection:  "row", gap: "10px", width: "100%" }} layout="vertical">
                        <Form.Item style={{ width: "100%" }} label="Position">
                          <Select
                            onChange={(value) => {
                              setPosition(value);
                              setLatestPreview(true);
                            }}
                            disabled={
                              !selectedStampType || !pdfFile || !isWatermarked
                            }
                            defaultValue="top-left"
                            style={{ border: "1px solid #d9d9d9", width: "100%" }}
                          >
                            <Option value="top-left">Top Left</Option>
                            <Option value="top-right">Top Right</Option>
                            <Option value="bottom-left">Bottom Left</Option>
                            <Option value="bottom-right">Bottom Right</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item style={{ width: "100%" }} label="Margin">
                          <Select
                            onChange={(value) => {
                              setMargin(value);
                              setLatestPreview(true);
                            }}
                            disabled={
                              !selectedStampType || !pdfFile || !isWatermarked
                            }
                            defaultValue={30}
                            style={{ border: "1px solid #d9d9d9", width: "100%" }}
                          >
                            <Option value={10}>10</Option>
                            <Option value={20}>20</Option>
                            <Option value={30}>30</Option>
                            <Option value={40}>40</Option>
                            <Option value={50}>50</Option>
                            <Option value={60}>60</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item style={{ width: "100%" }} label="QR Code Size">
                          <Select
                            onChange={(value) => {
                              setQrSize(value);
                              setLatestPreview(true);
                            }}
                            disabled={
                              !selectedStampType || !pdfFile || !isWatermarked
                            }
                            defaultValue={60}
                            style={{ border: "1px solid #d9d9d9", width: "100%" }}
                          >
                            <Option value={40}>40</Option>
                            <Option value={50}>50</Option>
                            <Option value={60}>60</Option>
                            <Option value={70}>70</Option>
                            <Option value={80}>80</Option>
                          </Select>
                        </Form.Item>
                      </Form>
                      <Button
                        type="primary"
                        onClick={() => {
                          generateQRCode();
                          setIsStampReady(true);
                        }}
                        icon={<QrcodeOutlined />}
                        size="large"
                        style={{
                          width: "300px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "1rem",
                        }}
                        disabled={
                          !selectedStampType || !pdfFile || !isWatermarked
                        }
                      >
                        Generate QR Code
                      </Button>
                    </Col>
                    <Col xs={24} sm={12}>
                      <div style={{ textAlign: "center" }}>
                        <Card>
                          <div style={styles.qrContainer}>
                            {qrCodeUrl && urlLink && (
                              <>
                                <img
                                  src={qrCodeUrl}
                                  alt="QR Code"
                                  style={styles.qrCode}
                                />
                                <div style={styles.schoolInfo}>
                                  <div style={styles.divider}>
                                    <Text style={styles?.arabicText}>
                                      {selectedStampType?.titleAr}
                                    </Text>
                                    <Text style={styles?.englishText}>
                                      {selectedStampType?.title}
                                    </Text>
                                    <Text style={styles?.englishText}>
                                      <img
                                        src={iconVerifyBase64}
                                        alt="QR Code"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      />
                                    </Text>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
            <Divider />
            <Card style={{ width: "100%", height: "100%" }}>
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={
                    <span>
                      <QrcodeOutlined /> Preview and Save
                    </span>
                  }
                  key="1"
                >
                  <div>
                    {/* <Space
                style={{
                  borderBottom: "1px solid #d9d9d9",
                  padding: 16,
                  width: "100%",
                  marginBottom: 16,
                }}
              > */}
                      <Row gutter={24}>
                        <Col span={12}>
                          <Button
                            disabled={
                              !selectedStampType ||
                              !isStampReady ||
                              !isWatermarked
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "300px",
                              marginBottom:"2rem",
                              animation: latestPreview ? 'ping 1s infinite' : 'none',
                              '@keyframes ping': {
                                  '0%': { transform: 'scale(1)', opacity: 1 },
                                  '50%': { transform: 'scale(1.1)', opacity: 0.7 },
                                  '100%': { transform: 'scale(1)', opacity: 1 },
                              },
                            }}
                            size="large"
                             type={latestPreview ? "primary" : "default"} 
                            onClick={addStampToPDF}
                            icon={<DownloadOutlined />}
                          >
                             {latestPreview ? "Latest Preview (Click to View)" : "Preview"}
                          </Button>
                        </Col>

                      
                      </Row>

                    {/* </Space> */}

                    <Card style={styles.card}>
                      {showPreview && pdfData && (
                        <Spin spinning={loading}>
                          <PdfPreviewer pdfData={pdfData} />
                        </Spin>
                      )}
                    </Card>
                  </div>
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        justifyContent: "start",
                      }}
                    >
                      <Checkbox
                        checked={!isWatermarked}
                        disabled={!pdfData || !selectedStampType}
                        onChange={(e) => {
                          setValidationConfirmed(e.target.checked);
                          setIsWatermarked(!e.target.checked);
                        }}
                      >
                        Are you happy with the results? Do you want to complete the process?
                      </Checkbox>
                      <Popconfirm
                        title="You wont be able to edit this document after saving. Are you sure to proceed?"
                        okText="Yes"
                        cancelText="No"
                        style={{ marginLeft: "30px" }}
                        onConfirm={() => handleSaveStamp()}
                      >
                        <Tooltip title="Save">
                          <Button
                            style={{ width: "300px" }}
                            size="large"
                            type="primary"
                            // onClick={handleSaveStamp}
                            disabled={!pdfData || isWatermarked}
                          >
                            Save
                          </Button>
                        </Tooltip>
                      </Popconfirm>
                    </div>
                  </Card>
                </TabPane>
              </Tabs>
            </Card>
            <Divider />
          </Content>
        </Spin>
      </Layout>
    </Layout>
  );
};


const styles = {
  layout: {
    minHeight: "100vh",
    backgroundColor: "#f0f2f5",
  },
  header: {
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 24px",
  },
  logo: {
    height: "48px",
  },
  content: {
    display: "flex",
    width: "920px",
    gap: "4px",
    padding: "24px",
  },
  card: {
    minWidth: "80%",
    maxWidth: "800px",
    margin: "0 auto",
  },
  qrContainer: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    marginBottom: "16px",
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    gap: "10px",
  },
  qrCode: {
    width: "128px",
    height: "128px",
  },
  schoolInfo: {
    textAlign: "right",
  },
  divider: {
    borderTop: "1px solid #f0f0f0",
    paddingTop: "16px",
    marginTop: "16px",
  },
  arabicText: {
    display: "block",
    textAlign: "center",
    color: "#666666",
    fontFamily: "Naskh",
  },
  englishText: {
    display: "block",
    textAlign: "center",
  },
};

export default QrStampGenerator;
