/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import {
  Button,
  Form,
  Input,
  Row,
  Tooltip,
  Popconfirm,
  Select,
  Spin,
  Checkbox,
  Col,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAction,
  updateUserAction,
  addUserAction,
  deleteUserAction,
  searchUserAction,
} from "actions/users/userActions";
import { renderLoader } from "utils/helpers/helpers";
import _, { set } from "lodash";
import { Capitalize } from "utils/helpers/helpers";
import { convertToTitleCase } from "utils/helpers/helpers";

const ModalType = {
  ADD: "Add",
  EDIT: "Update",
};

const userRoles = [
  { value: "employee", title: "Employee" },
  { value: "super-admin", title: "Administrator" },
  { value: "facility-employee", title: "Booking Coordinator" },
];

const authenticationMethods = [
  { value: "sso", title: "SSO" },
  { value: "password", title: "Password" },
];

const { Option } = Select;

const UserList = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.allUsers.users);
  const totalRecords = useSelector((state) => state.allUsers.userTotal);
  const loading = useSelector((state) => state.allUsers.loading);
  const [userRole, setUserRole] = React.useState("");
  const [hasCashierAccess, setHasCashierAccess] = React.useState(false);
  const [hasCancelAccess, setHasCancelAccess] = React.useState(false);
  const [hasCmsAccess, setHasCmsAccess] = React.useState(false);
  const [hasTemplatesAccess, sethastemplateaccess] = React.useState(false);
  const [hasSccAccess, setHasSccAccess] = React.useState(false);
  const [hasDocumentGeneratorAccess, setHasSccDocumentGenerator] = React.useState(false);
  const [hasDocumentValidatorAccess  , setHasDocumentValidatorAccess] = React.useState(false);
  const [hasFSMAccess, setHasFSMAccess] = React.useState(false);

  const [showModal, toggleModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [editUserId, setEditUserId] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [userForm] = Form.useForm();

  const [hidePassword, setHidePassword] = React.useState(true);
  const [passwordRequired, setPasswordRequired] = React.useState(false);

  React.useEffect(() => {
    dispatch(getUserAction(page));
  }, [page]);

  const fetchRecords = (page) => {
    setPage(page);
  };

  const onEdit = (item) => {
    if (
      item.authenticationMethod === "sso" ||
      item.authenticationMethod === "security-key"
    ) {
      setHidePassword(true);
    } else {
      setHidePassword(false);
    }
    toggleModal(true);
    userForm.setFieldsValue(item);
    setHasCashierAccess(item.hasCashierAccess);
    setHasCancelAccess(item.hasCancelAccess);
    setHasCmsAccess(item.hasCmsAccess ?? false);
    setUserRole(item.role);
    sethastemplateaccess(item.hasTemplatesAccess);
    setHasSccAccess(item.hasSccAccess);
    setHasFSMAccess(item.hasFSMAccess);
    setHasSccDocumentGenerator(item.hasDocumentGeneratorAccess);
    setHasDocumentValidatorAccess(item.hasDocumentValidatorAccess);
    setEditUserId(item.id);
    setModalTitle(ModalType.EDIT);
  };
  const onDelete = (item) => {
    dispatch(deleteUserAction(item.id));
  };

  const onAdd = (item) => {
    toggleModal(true);
    setHidePassword(true);
    setHasCancelAccess(false);
    setHasCashierAccess(false);
    setHasCmsAccess(false);
    sethastemplateaccess(false);
    setHasSccDocumentGenerator(false);
    setHasDocumentValidatorAccess(false);
    setHasSccAccess(false);
    setHasFSMAccess(false);
    setModalTitle(ModalType.ADD);
    userForm.resetFields();
  };
  const onSubmitForm = (values) => {
    let params = {
      ...values,
      hasCashierAccess,
      hasCancelAccess,
      hasCmsAccess,
      hasTemplatesAccess,
      hasSccAccess,
      hasFSMAccess,
      hasDocumentGeneratorAccess,
      hasDocumentValidatorAccess,
      authenticationMethod:
        values?.role === "facility-employee"
          ? "security-key"
          : values?.authenticationMethod,
    };

    if (passwordRequired == false) {
      if (params.password == "" || params.password == undefined) {
        delete params.password;
      }
    }
    if (values?.role === "facility-employee") {
      if (params.email == "" || params.email == undefined) {
        delete params.email;
      }
    }
    if (modalTitle === ModalType.EDIT) {
      params = { ...params, id: editUserId };
      dispatch(
        updateUserAction(params, () => {
          toggleModal(false);
          setModalTitle("");
          userForm.resetFields();
        })
      );
    } else if (modalTitle === ModalType.ADD) {
      dispatch(
        addUserAction(params, () => {
          toggleModal(false);
          setModalTitle("");
          userForm.resetFields();
        })
      );
    } else return;
  };

  const onDebounceSearch = _.debounce(
    (keyword) => dispatch(searchUserAction(keyword)),
    800
  );

  const renderHeader = () => {
    return (
      <div>
        <TableHeader
          onAddNew={onAdd}
          headerTitle={"All Users"}
          headerSubTitle={"Manage Users"}
          headerBtnTitle={"Add New"}
        />
        <Input
          style={{ width: "50%" }}
          placeholder="Search Users"
          onChange={(e) => onDebounceSearch(e.target.value)}
        ></Input>
      </div>
    );
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          data={users}
          header={renderHeader}
          fetchRecords={fetchRecords}
          totalRecord={totalRecords}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "id",
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "id",
            },
            {
              title: "User Name",
              dataIndex: "userName",
              key: "id",
            },

            {
              title: "Role",
              dataIndex: "role",
              key: "role",
              render: (record) => {
                return (
                  userRoles.find((item) => item.value == record)?.title ?? ""
                );
              },
            },
            {
              title: "Cashier Access",
              dataIndex: "hasCashierAccess",
              key: "id",
              render: (record) => (
                <p style={{ textAlign: "center" }}>{record ? "Yes" : "No"}</p>
              ),
            },
            {
              title: "CMS Access",
              dataIndex: "hasCmsAccess",
              key: "hasCmsAccess",
              render: (record) => (
                <p style={{ textAlign: "center" }}>{record ? "Yes" : "No"}</p>
              ),
            },
            {
              title: "Document Generator Access",
              dataIndex: "hasDocumentGeneratorAccess",
              key: "hasDocumentGeneratorAccess",
              render: (record) => (
                <p style={{ textAlign: "center" }}>{record ? "Yes" : "No"}</p>
              ),
            },
            {
              title: "Document Validator Access",
              dataIndex: "hasDocumentValidatorAccess",
              key: "hasDocumentValidatorAccess",
              render: (record) => (
                <p style={{ textAlign: "center" }}>{record ? "Yes" : "No"}</p>
              ),
            },
            {
              title: "Position",
              dataIndex: "position",
              key: "position",
              render: (record) => Capitalize(record),
            },
            {
              title: "Authentication Method",
              dataIndex: "authenticationMethod",
              key: "authenticationMethod",
              render: (record) => convertToTitleCase(record),
            },
            // {
            //   title: "Role",
            //   dataIndex: "role",
            //   key: "role",
            // },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update User">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => onEdit(item)}
                          // onClick={(e) => handleEdit(e, record)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this user?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => onDelete(item)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={() => toggleModal(false)}
          handleOk={() => null}
          title={`${modalTitle} User`}
        >
          <Form form={userForm} layout="vertical" onFinish={onSubmitForm}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the user's name here",
                    },
                  ]}
                >
                  <Input placeholder="Name"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: userRole === "facility-employee" ? false : true,
                      message: "Please enter the user's email here",
                    },
                    {
                      type: "email",
                      message: "Email address is not valid",
                    },
                  ]}
                >
                  <Input placeholder="Email"></Input>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Username"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter username here",
                    },
                  ]}
                >
                  <Input placeholder="Username"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Position"
                  name="position"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the user's position",
                    },
                  ]}
                >
                  <Input placeholder="Position"></Input>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name={"role"}
                  label={"Role"}
                  rules={[
                    {
                      required: true,
                      message: "Please select user role",
                    },
                  ]}
                >
                  <Select
                    value={userRole}
                    onChange={(value) => {
                      setUserRole(value);
                      setHasCashierAccess(value === "super-admin");
                      setHasCancelAccess(value === "super-admin");
                      setHasCmsAccess(value === "super-admin");
                      setHasSccDocumentGenerator(value === "super-admin");
                      setHasSccAccess(value === "super-admin");
                      setHasFSMAccess(value === "super-admin");
                      setHasDocumentValidatorAccess(value === "super-admin");
                      if (value === "facility-employee") {
                        userForm.setFieldValue({
                          authenticationMethod: "security-key",
                        });
                      }
                    }}
                  >
                    {userRoles.map((el) => (
                      <Option value={el.value}>{el.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {userRole !== "facility-employee" ? (
                <Col span={12}>
                  <Form.Item
                    name={"authenticationMethod"}
                    label={"Authentication Method"}
                    rules={[
                      {
                        required: true,
                        message: "Please select a method",
                      },
                    ]}
                  >
                    <Select
                      onChange={(value) => {
                        if (value == "sso") {
                          setHidePassword(true);
                          setPasswordRequired(false);
                        } else {
                          setHidePassword(false);
                          setPasswordRequired(true);
                        }
                      }}
                    >
                      {authenticationMethods.map((el) => (
                        <Option value={el.value}>
                          {convertToTitleCase(el.title)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>

            <Row gutter={8} hidden={hidePassword}>
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: passwordRequired ? true : false,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Input placeholder="Password"></Input>
                </Form.Item>
              </Col>
            </Row>

            {userRole !== "facility-employee" ? (
              <>
                <Row gutter={8}>
                  {" "}
                  <Col span={8}>
                    <Checkbox
                      defaultChecked={hasCashierAccess}
                      checked={hasCashierAccess}
                      disabled={userRole === "super-admin"}
                      onChange={(e) => setHasCashierAccess(e.target.checked)}
                    >
                      Has Cashier Access
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      defaultChecked={hasCmsAccess}
                      checked={hasCmsAccess}
                      disabled={userRole === "super-admin"}
                      onChange={(e) => setHasCmsAccess(e.target.checked)}
                    >
                      Has CMS Access
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      defaultChecked={hasCancelAccess}
                      checked={hasCancelAccess}
                      disabled={userRole === "super-admin"}
                      onChange={(e) => setHasCancelAccess(e.target.checked)}
                    >
                      Has Cancel Access
                    </Checkbox>
                  </Col>
                </Row>
                <br />
                <Row gutter={8}>
                  <Col span={8}>
                    <Checkbox
                      defaultChecked={hasTemplatesAccess}
                      checked={hasTemplatesAccess}
                      disabled={userRole === "super-admin"}
                      onChange={(e) => sethastemplateaccess(e.target.checked)}
                    >
                      Has HTML Template Access
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      defaultChecked={hasSccAccess}
                      checked={hasSccAccess}
                      disabled={userRole === "super-admin"}
                      onChange={(e) => setHasSccAccess(e.target.checked)}
                    >
                      Has Student Code of Conduct Access
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      defaultChecked={hasDocumentGeneratorAccess}
                      checked={hasDocumentGeneratorAccess}
                      disabled={userRole === "super-admin"}
                      onChange={(e) => setHasSccDocumentGenerator(e.target.checked)}
                    >
                      Has Document Generator Access
                    </Checkbox>
                  </Col>
                  </Row>
                  <br />
                  <Row gutter={8}>
                  <Col span={8}>
                    <Checkbox
                      defaultChecked={hasDocumentValidatorAccess  }
                      checked={hasDocumentValidatorAccess  }
                      disabled={userRole === "super-admin"}
                      onChange={(e) => setHasDocumentValidatorAccess(e.target.checked)}
                    >
                      Has Document Validator Access
                    </Checkbox>
                  </Col>
                </Row>
              </>
            ) : (
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label="Security Code"
                    name="securityKey"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the Security Code",
                      },
                    ]}
                  >
                    <Input placeholder="Security Key"></Input>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <br></br>
            <br></br>
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : modalTitle}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default UserList;
