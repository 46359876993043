import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  DatePicker,
  Row,
  Col,
  Button,
  Checkbox,
  Card,
  TimePicker,
} from "antd";
import moment from "moment";
import { DeleteFilled } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const BlokSchedules = ({
  record,
  onCloseForm,
  getBlockSchedules,
  selectedBlockSchedul,
  isNew,
}) => {
  const [form] = Form.useForm();

  const _startAt = record?.schedule?.startAt
    ? moment(record.schedule.startAt)
    : record?.startAt
    ? moment(record.startAt)
    : null;

  const _endAt = record?.schedule?.endAt
    ? moment(record.schedule.endAt)
    : record?.endAt
    ? moment(record.endAt)
    : null;

  const [startAt, setStartAt] = useState(_startAt);
  const [endAt, setEndAt] = useState(_endAt);
  const [blockSchedules, setBlokScheudles] = useState([
    {
      startAt: "",
      endAt: "",
      weekDays: [
        {
          isChecked: false,
          day: "Saturday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Sunday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Monday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Tuesday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Wednesday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Thursday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Friday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
      ],
    },
  ]);

  const [errorMessages, setErrorMessages] = useState(
    blockSchedules.map((schedule) => ({
      weekDaysErrors: schedule.weekDays.map(() => ({
        dayError: "",
        fullDayError: "",
        slotsError: "",
      })),
    }))
  );

  // Transform server data function with filtered weekdays

  // const getUniqueWeekdaysBetweenDates = (startAt, endAt) => {
  //   const startDate = moment(startAt);
  //   const endDate = moment(endAt);
  //   const weekdaysSet = new Set(); // Use a Set to ensure unique values

  //   while (startDate.isSameOrBefore(endDate, "day")) {
  //     weekdaysSet.add(startDate.format("dddd")); // Add the day of the week to the set
  //     startDate.add(1, "day");
  //   }

  //   return Array.from(weekdaysSet); // Convert Set back to array
  // };

  const transformServerData = (serverData) => {
    const dayMapping = {
      sat: "Saturday",
      sun: "Sunday",
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
    };

    return serverData.map((schedule) => {
      // Get the list of days directly from the server data
      const defaultWeekDays = Object.values(dayMapping).map((day) => ({
        day,
        isChecked: false,
        fullDay: false,
        addSlots: false,
        slots: [],
        reason: "",
      }));

      // Only use the days provided from the server (e.g., 'sat', 'thu')
      schedule.days.forEach((serverDay) => {
        const mappedDay = dayMapping[serverDay.day.toLowerCase()]; // Map 'sat' -> 'Saturday'

        // Find the corresponding weekday in the defaultWeekDays array
        const weekDayIndex = defaultWeekDays.findIndex(
          (wd) => wd.day === mappedDay
        );

        if (weekDayIndex !== -1) {
          // Update the matched weekday with server data
          defaultWeekDays[weekDayIndex] = {
            ...defaultWeekDays[weekDayIndex],
            isChecked: true,
            fullDay: serverDay.fullDay,
            addSlots: serverDay.startTime !== "" || serverDay.endTime !== "",
            slots:
              serverDay.startTime && serverDay.endTime
                ? [
                    {
                      startTime: serverDay.startTime,
                      endTime: serverDay.endTime,
                    },
                  ]
                : [],
            reason: serverDay.reason || "",
          };
        }
      });

      return {
        startAt: moment(schedule.startAt),
        endAt: moment(schedule.endAt),
        weekDays: defaultWeekDays.filter((day) => day.isChecked), // Only show enabled days
      };
    });
  };

  useEffect(() => {
    if (record) {
      const blockSchedules =
        record.blockSchedules || record.schedule?.blockSchedules || [];
      const hasBlockSchedules = blockSchedules.length > 0;

      console.log("blockSchedules........", blockSchedules);

      if (selectedBlockSchedul.length > 0) {
        setBlokScheudles(selectedBlockSchedul);
      } else if (hasBlockSchedules) {
        const transformedData = transformServerData(blockSchedules);
        setBlokScheudles(transformedData);
      } else {
        setBlokScheudles((prevState) => prevState);
      }
    }
  }, [record]);

  const disabledDate = (current, index) => {
    // Convert current to a moment object if it's not already
    const currentDate = moment(current);

    if (isNew) {
      // Disable all past dates when creating a new entry
      return currentDate && currentDate < moment().startOf("day");
    } else {
      // Handle existing entries
      let disable = false;

      if (
        blockSchedules[index].startAt &&
        currentDate < moment(blockSchedules[index].startAt).startOf("day")
      ) {
        disable = true;
      }

      if (endAt && currentDate > moment(endAt).endOf("day")) {
        disable = true;
      }

      return disable;
    }
  };

  const getWeekdaysBetween = (start, end) => {
    const weekdays = [];
    let current = start.clone().startOf("day");

    while (current <= end) {
      const day = current.format("dddd");
      if (!weekdays.some((d) => d.day === day)) {
        weekdays.push({ day, fullDay: false, addSlots: false, slots: [1] });
      }
      current.add(1, "day");
    }

    return weekdays;
  };

  const handleDateChange = (date, key, index) => {
    const updatedBlockSchedules = [...blockSchedules];
    updatedBlockSchedules[index][key] = date;

    const { startAt, endAt } = updatedBlockSchedules[index];
    const formattedStartAt = startAt ? startAt.clone().startOf("day") : null;
    const formattedEndAt = endAt ? endAt.clone().startOf("day") : null;

    // Calculate weekdays between start and end dates
    if (formattedStartAt && formattedEndAt) {
      updatedBlockSchedules[index].weekDays = getWeekdaysBetween(
        formattedStartAt,
        formattedEndAt
      );
    } else {
      updatedBlockSchedules[index].weekDays = [];
    }

    setBlokScheudles(updatedBlockSchedules);
  };

  const addSchedule = () => {
    const updatedBlockSchedules = [...blockSchedules];
    const obj = {
      startAt: startAt,
      endAt: endAt,
      weekDays: [
        {
          isChecked: false,
          day: "Saturday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Sunday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Monday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Tuesday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Wednesday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Thursday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
        {
          isChecked: false,
          day: "Friday",
          fullDay: false,
          addSlots: false,
          slots: [],
          reason: "",
        },
      ],
    };
    updatedBlockSchedules.push(obj);
    setBlokScheudles(updatedBlockSchedules);
  };

  const onRemoveSchdule = (scheduleIndex) => {
    const updatedBlockSchedules = blockSchedules.filter(
      (_, index) => index !== scheduleIndex
    );
    setBlokScheudles(updatedBlockSchedules);
  };
  // Function to clear errors for a specific day and block
  const clearDayErrors = (blockIndex, dayIndex) => {
    const updatedBlockSchedules = [...blockSchedules];
    setErrorMessages((prevErrors) => {
      const newErrors = [...prevErrors];

      // Ensure that the error object exists at the given blockIndex
      if (!newErrors[blockIndex]) {
        newErrors[blockIndex] = {
          weekDaysErrors: updatedBlockSchedules[blockIndex].weekDays.map(
            () => ({
              dayError: "",
              fullDayError: "",
              slotsError: "",
            })
          ),
        };
      } else {
        newErrors[blockIndex] = {
          ...newErrors[blockIndex],
          weekDaysErrors: newErrors[blockIndex].weekDaysErrors.map(
            (error, index) =>
              index === dayIndex
                ? { dayError: "", fullDayError: "", slotsError: "" }
                : error
          ),
        };
      }

      return newErrors;
    });
  };

  // Function to handle Full Day checkbox change for a specific day
  const handleDayToggle = (dayIndex, blockIndex) => {
    const updatedBlockSchedules = [...blockSchedules];

    const updatedWeekDays = updatedBlockSchedules[blockIndex].weekDays.map(
      (day, index) =>
        index === dayIndex
          ? { ...day, isChecked: !day.isChecked, addSlots: false } // Uncheck Add Slots when Full Day is toggled
          : day
    );

    updatedBlockSchedules[blockIndex].weekDays = updatedWeekDays;
    setBlokScheudles(updatedBlockSchedules);
    clearDayErrors(blockIndex, dayIndex);
  };

  const handleFullDayToggle = (dayIndex, blockIndex) => {
    const updatedBlockSchedules = [...blockSchedules];

    const updatedWeekDays = updatedBlockSchedules[blockIndex].weekDays.map(
      (day, index) =>
        index === dayIndex
          ? { ...day, fullDay: !day.fullDay, addSlots: false, slots: [] } // Uncheck Add Slots when Full Day is toggled
          : day
    );

    updatedBlockSchedules[blockIndex].weekDays = updatedWeekDays;
    setBlokScheudles(updatedBlockSchedules);
    clearDayErrors(blockIndex, dayIndex);
  };

  const handleAddSlotsToggle = (dayIndex, blockIndex) => {
    // Make a copy of the blockSchedules
    const updatedBlockSchedules = [...blockSchedules];

    // Update the weekDays array for the specific index
    const updatedWeekDays = updatedBlockSchedules[blockIndex].weekDays.map(
      (day, index) => {
        if (index === dayIndex) {
          return {
            ...day,
            addSlots: !day.addSlots, // Toggle addSlots
            fullDay: false, // Ensure fullDay is false when addSlots is toggled
            slots: !day.addSlots ? [{ startTime: "", endTime: "" }] : day.slots, // Initialize slots array if addSlots is toggled on
          };
        }
        return day;
      }
    );

    // Apply the updated weekDays array to the updated blockSchedules
    updatedBlockSchedules[blockIndex].weekDays = updatedWeekDays;

    // Update state
    setBlokScheudles(updatedBlockSchedules);
    clearDayErrors(blockIndex, dayIndex);
  };

  // Function to handle adding a slot
  const handleAddSlot = (dayIndex, blockIndex) => {
    const updatedBlockSchedules = [...blockSchedules];
    const updatedWeekDays = updatedBlockSchedules[blockIndex].weekDays.map(
      (day, index) => {
        if (index === dayIndex) {
          return {
            ...day,
            slots: [...day.slots, { startTime: "", endTime: "" }],
          };
        }
        return day;
      }
    );

    updatedBlockSchedules[blockIndex].weekDays = updatedWeekDays;
    setBlokScheudles(updatedBlockSchedules);
  };

  // Function to handle removing a slot
  const handleRemoveSlot = (dayIndex, slotIndex, blockIndex) => {
    const updatedBlockSchedules = [...blockSchedules];
    const updatedWeekDays = updatedBlockSchedules[blockIndex].weekDays.map(
      (day, index) => {
        if (index === dayIndex) {
          const updatedSlots = day.slots.filter(
            (_, sIndex) => sIndex !== slotIndex
          );
          return { ...day, slots: updatedSlots };
        }
        return day;
      }
    );

    updatedBlockSchedules[blockIndex].weekDays = updatedWeekDays;
    setBlokScheudles(updatedBlockSchedules);
  };

  // Function to handle slot changes
  const handleSlotChange = (dayIndex, slotIndex, key, value, blockIndex) => {
    const updatedBlockSchedules = [...blockSchedules];
    const updatedWeekDays = updatedBlockSchedules[blockIndex].weekDays.map(
      (day, index) => {
        if (index === dayIndex) {
          const updatedSlots = day.slots.map((slot, sIndex) =>
            sIndex === slotIndex ? { ...slot, [key]: value } : slot
          );
          return { ...day, slots: updatedSlots };
        }
        return day;
      }
    );

    updatedBlockSchedules[blockIndex].weekDays = updatedWeekDays;
    setBlokScheudles(updatedBlockSchedules);
    clearDayErrors(blockIndex, dayIndex);
  };

  const handleChangeReason = (dayIndex, blockIndex, value) => {
    const updatedBlockSchedules = [...blockSchedules];

    // Update the specific reason for the given day in the specified block
    updatedBlockSchedules[blockIndex].weekDays[dayIndex].reason = value;

    setBlokScheudles(updatedBlockSchedules);
  };

  const onSaveSchedule = () => {
    let newErrorMessages = blockSchedules.map((schedule) => ({
      weekDaysErrors: schedule.weekDays.map(() => ({
        dayError: "",
        fullDayError: "",
        slotsError: "",
      })),
    }));

    let hasError = false;

    blockSchedules.forEach((schedule, blockIndex) => {
      let daySelected = false; // Track if any day is selected

      schedule.weekDays.forEach((day, dayIndex) => {
        // Reset errors for this specific day
        newErrorMessages[blockIndex].weekDaysErrors[dayIndex] = {
          dayError: "",
          fullDayError: "",
          slotsError: "",
        };

        // If the day is checked
        if (day.isChecked) {
          daySelected = true;

          // Validate if neither Full Day nor Slots are selected
          if (!day.fullDay && !day.addSlots) {
            newErrorMessages[blockIndex].weekDaysErrors[dayIndex].fullDayError =
              "Select Full Day or add Slots.";
            hasError = true;
          } else if (day.addSlots) {
            // If Slots are selected, validate the slot times
            let slotError = false;

            day.slots.forEach((slot) => {
              if (!slot.startTime || !slot.endTime) {
                slotError = true; // Mark as an error if any slot is missing start/end times
              }
            });

            // If there's an error in slot times, set the error message
            if (slotError) {
              newErrorMessages[blockIndex].weekDaysErrors[dayIndex].slotsError =
                "Both slot start and end times are required.";
              hasError = true;
            }
          }
        }
      });

      // Validate if no day is selected at all
      if (!daySelected) {
        schedule.weekDays.forEach((day, dayIndex) => {
          newErrorMessages[blockIndex].weekDaysErrors[dayIndex].dayError =
            "At least one day must be selected.";
        });
        hasError = true;
      }
    });

    setErrorMessages(newErrorMessages);

    // If there are no errors, proceed with saving the schedule
    if (!hasError) {
      getBlockSchedules(blockSchedules);
      onCloseForm();
    }
  };

  const disabledEndTime = (now, blockIndex, dayIndex, slotIndex) => {
    // Check for valid schedule and start time
    const schedule =
      blockSchedules[blockIndex]?.weekDays[dayIndex]?.slots[slotIndex];
    if (!schedule || !now || !schedule.startTime) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    }

    const selectedStartTime = moment(schedule.startTime, "HH:mm");

    const disabledHours = () => {
      const hours = [];
      for (let i = 0; i <= selectedStartTime.hours(); i++) {
        hours.push(i);
      }
      return hours;
    };

    const disabledMinutes = (selectedHour) => {
      if (selectedHour === selectedStartTime.hours()) {
        const minutes = [];
        for (let i = 0; i < selectedStartTime.minutes(); i++) {
          minutes.push(i);
        }
        return minutes;
      }
      return [];
    };

    const disabledSeconds = (selectedHour, selectedMinute) => {
      if (
        selectedHour === selectedStartTime.hours() &&
        selectedMinute === selectedStartTime.minutes()
      ) {
        return Array.from({ length: 60 }, (_, i) => i); // Disabling all seconds if on the same hour and minute
      }
      return [];
    };

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
      hideDisabledOptions: true,
    };
  };

  return (
    <Form form={form} onFinish={onSaveSchedule}>
      {blockSchedules.map((schedule, index) => {
        return (
          <Card key={index} style={{ marginBottom: 10 }}>
            <Row gutter={20}>
              <Col md={12}>
                <p>Start Date</p>
                <Form.Item
                  // name={`startAt-${index}`}
                  rules={[
                    { required: true, message: "Start date is required" },
                  ]}
                  // initialValue={schedule.startAt} // Display the selected end date
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Select Start Date"
                    disabledDate={(current) => disabledDate(current, index)} // Disable dates outside the startAt and endAt range
                    onChange={(date) =>
                      handleDateChange(date, "startAt", index)
                    }
                    value={schedule.startAt ? moment(schedule.startAt) : null}
                  />
                </Form.Item>
              </Col>

              <Col md={12}>
                <p>End Date</p>
                <Form.Item
                  // name={`endAt-${index}`}
                  rules={[{ required: true, message: "End date is required" }]}
                  // initialValue={schedule.endAt} // Display the selected end date
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Select End Date"
                    disabledDate={(current) => disabledDate(current, index)}
                    onChange={(date) => handleDateChange(date, "endAt", index)}
                    value={schedule.endAt ? moment(schedule.endAt) : null}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[20, 20]}>
              <Col md={24}>
                <p>Available Weekdays</p>
                {schedule?.weekDays?.map((day, i) => (
                  <React.Fragment key={i}>
                    <Row gutter={[20, 20]}>
                      <Col md={8}>
                        <Form.Item>
                          <Checkbox
                            checked={day.isChecked}
                            onChange={() => handleDayToggle(i, index)}
                          >
                            {day.day}
                          </Checkbox>
                        </Form.Item>
                        {errorMessages[index]?.weekDaysErrors[i]?.dayError && (
                          <div style={{ color: "red" }}>
                            {errorMessages[index].weekDaysErrors[i].dayError}
                          </div>
                        )}
                      </Col>
                      <Col md={8}>
                        <Form.Item>
                          <Checkbox
                            disabled={!day.isChecked}
                            checked={day.fullDay}
                            onChange={() => handleFullDayToggle(i, index)}
                          >
                            Full Day
                          </Checkbox>
                        </Form.Item>
                        {errorMessages[index]?.weekDaysErrors[i]
                          ?.fullDayError && (
                          <div style={{ color: "red" }}>
                            {
                              errorMessages[index].weekDaysErrors[i]
                                .fullDayError
                            }
                          </div>
                        )}
                      </Col>
                      <Col md={8}>
                        <Form.Item>
                          <Checkbox
                            disabled={!day.isChecked}
                            checked={day.addSlots}
                            onChange={() => handleAddSlotsToggle(i, index)}
                          >
                            Slots
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    {day.addSlots && (
                      <Card style={{ marginBottom: 20 }}>
                        {day.slots?.map((slot, slotIndex) => {
                          return (
                            <Row gutter={[10, 10]} key={slotIndex}>
                              <Col md={4}>Slot {slotIndex + 1}</Col>
                              <Col md={9}>
                                <Form.Item>
                                  <TimePicker
                                    style={{ width: "100%" }}
                                    placeholder="Start Time"
                                    format={"HH:mm"}
                                    value={
                                      slot.startTime
                                        ? moment(slot.startTime, "HH:mm")
                                        : null
                                    }
                                    onChange={(time) =>
                                      handleSlotChange(
                                        i,
                                        slotIndex,
                                        "startTime",
                                        time ? time.format("HH:mm") : "",
                                        index
                                      )
                                    }
                                    clearIcon={false}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={9}>
                                <Form.Item>
                                  <TimePicker
                                    style={{ width: "100%" }}
                                    placeholder="End Time"
                                    format={"HH:mm"}
                                    value={
                                      slot.endTime
                                        ? moment(slot.endTime, "HH:mm")
                                        : null
                                    }
                                    onChange={(time) =>
                                      handleSlotChange(
                                        i,
                                        slotIndex,
                                        "endTime",
                                        time ? time.format("HH:mm") : "",
                                        index
                                      )
                                    }
                                    disabled={
                                      !slot.startTime ||
                                      moment(slot.endTime, "HH:mm").isBefore(
                                        moment(slot.startTime, "HH:mm")
                                      )
                                    }
                                    disabledTime={(current) =>
                                      disabledEndTime(
                                        current,
                                        index,
                                        i,
                                        slotIndex
                                      )
                                    }
                                    clearIcon={false}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={2}>
                                <Button
                                  type="link"
                                  onClick={() =>
                                    handleRemoveSlot(i, slotIndex, index)
                                  }
                                  icon={<DeleteFilled />}
                                />
                              </Col>
                              {errorMessages[index]?.weekDaysErrors[i]
                                ?.slotsError && (
                                <div style={{ color: "red" }}>
                                  {
                                    errorMessages[index].weekDaysErrors[i]
                                      .slotsError
                                  }
                                </div>
                              )}
                            </Row>
                          );
                        })}
                        <Button
                          type="dashed"
                          onClick={() => handleAddSlot(i, index)}
                        >
                          + Add Slot
                        </Button>
                      </Card>
                    )}
                    {day.isChecked && (
                      <Row>
                        <Col md={24}>
                          <Form.Item>
                            <TextArea
                              placeholder="Reason"
                              value={day.reason}
                              onChange={(e) =>
                                handleChangeReason(i, index, e.target.value)
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </React.Fragment>
                ))}
              </Col>
            </Row>

            <Row gutter={20} justify={"end"}>
              <Col>
                {index !== 0 && (
                  <Button
                    type="dashed"
                    onClick={() => onRemoveSchdule(index)}
                    style={{ marginBottom: 20 }}
                  >
                    - Remove
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        );
      })}
      <Row gutter={[20, 20]} justify={"end"}>
        <Col>
          <Button
            type="primary"
            onClick={addSchedule}
            style={{ marginBottom: 20 }}
          >
            + Add Another Range
          </Button>
        </Col>
      </Row>

      <Row justify="start">
        <Button
          htmlType="submit"
          type="primary"
          className="ant__primary__button"
        >
          Save
        </Button>
        <div style={{ width: 30 }} />
        <Button className="ant__primary__button" onClick={onCloseForm}>
          Cancel
        </Button>
      </Row>
    </Form>
  );
};

export default BlokSchedules;
