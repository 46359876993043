import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import { handleUploadFileToENSServer } from "utils/helpers/helpers";
import OnBoardingForm from "./OnBoardingForm";
import { deleteOnBoarding } from "apis/on-boarding-requests/onBoardingRequests";
import { updateOnBoarding } from "apis/on-boarding-requests/onBoardingRequests";
import { addNewOnBoarding } from "apis/on-boarding-requests/onBoardingRequests";
import { getOnBoardings } from "apis/on-boarding-requests/onBoardingRequests";
import { generateUniqueColumnKey } from "utils/helpers/helpers";

const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const EnsOnboarding = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [recordId, setRecordId] = useState("");
  const [uploading, setUpLoading] = useState(false);
  const [uploadingAr, setUpLoadingAr] = useState(false);
  const [file, setFile] = useState();
  const [fileAr, setFileAr] = useState();

  const [onBoardingForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            setFile();
            setFileAr();
            onBoardingForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Onboarding"}
          headerSubTitle={"Manage Onboarding"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllOnboardings();
  }, [page]);

  const getAllOnboardings = async () => {
    try {
      setLoading(true);
      const response = await getOnBoardings({ page: page });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addNewOnboarding = async (data) => {
    try {
      setLoading(true);
      const response = await addNewOnBoarding({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Record added successfully" });
        await getAllOnboardings();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateOnboarding = async (data) => {
    try {
      setLoading(true);
      const response = await updateOnBoarding({ id: recordId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Record updated successfully" });
        await getAllOnboardings();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteOnboarding = async (id) => {
    try {
      setLoading(true);
      const response = await deleteOnBoarding({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Record deleted successfully" });
        await getAllOnboardings();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const data = { ...values, image: file, imageAr: fileAr };

    if (modalTitle === ModalType.EDIT) {
      await handleUpdateOnboarding(data);
    } else {
      await addNewOnboarding(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    record.image && setFile(record.image);
    record.imageAr && setFileAr(record.imageAr);
    onBoardingForm.setFieldsValue({
      ...record,
    });
    setRecordId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  const onChooseFile = async (e, type) => {
    try {
      if (e.target.files.length) {
        if (type === "fileEn") {
          setUpLoading(true);
          const path = await handleUploadFileToENSServer(
            e.target.files[0],
            "onBoarding"
          );
          setFile(path);
          setUpLoading(false);
        } else {
          setUpLoadingAr(true);
          const path = await handleUploadFileToENSServer(
            e.target.files[0],
            "onBoarding"
          );
          setFileAr(path);
          setUpLoadingAr(false);
        }
      }
    } catch (error) {
      if (type === "fileEn") {
        setUpLoading(false);
      } else {
        setUpLoadingAr(false);
      }
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Sorting",
              dataIndex: "sorting",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Title",
              dataIndex: "title",
              key: generateUniqueColumnKey(),
            },

            {
              title: "Image",
              key: generateUniqueColumnKey(),
              render: (record) => (
                <img src={record?.image} style={{ height: 50 }} />
              ),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Record Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this record?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteOnboarding(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Onboarding`}
        >
          <OnBoardingForm
            onSubmit={onSubmit}
            formName={onBoardingForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            onCloseForm={onCloseForm}
            uploading={uploading}
            uploadingAr={uploadingAr}
            onChooseFile={onChooseFile}
            file={file}
            fileAr={fileAr}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default EnsOnboarding;
