import React from "react";
import { Table } from "antd";
import TitleWithLine from "../title-with-line/TitleWithLine";

const OffenseLevelCaseTable = ({ data }) => {
  // Ensure offenseLevelCases is an array, default to empty array if not
  const offenseLevelCases = Array.isArray(data?.offenseLevelCases)
    ? data.offenseLevelCases
    : [];
  // Extract unique months from the data
  const months = [
    ...new Set(
      offenseLevelCases?.flatMap((entry) =>
        entry.months.map((month) => month.month)
      )
    ),
  ];

  // Prepare data for the table
  const tableData = offenseLevelCases.map((entry) => {
    const rowData = { offenseLevel: entry.offenseLevel };

    months.forEach((month) => {
      const monthData = entry.months.find((data) => data.month === month);
      rowData[month] = monthData ? monthData.cases : 0;
    });

    return rowData;
  });

  // Define columns for the Ant Design Table
  const columns = [
    {
      title: "Offense Level",
      dataIndex: "offenseLevel",
      key: "offenseLevel",
    },
    ...months.map((month) => ({
      title: month,
      dataIndex: month,
      key: month,
    })),
  ];

  // Inline styles
  const headerStyle = {
    textAlign: "center",
    padding: "1px 0",
    backgroundColor: "#f5f5f5",
    boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
    borderRadius: "7px 7px 0 0", // Round the top corners
  };
  const tableContainerStyle = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow to table
    borderRadius: "0 0 7px 7px", // Round the bottom corners
    overflow: "hidden", // Prevent overflow of shadow
  };

  // Check if there is any data to display
  const hasData = tableData.length > 0;

  // Conditionally set columns and data
  const tableColumns = hasData ? columns : [];

  return (
    <div style={tableContainerStyle}>
      {/* Table Header */}
      <div style={headerStyle}>
        <TitleWithLine title={"Offense Level Breakdown"} />
      </div>

      {/* Table Component */}
      <Table
        columns={tableColumns}
        dataSource={tableData}
        pagination={false} // Disable pagination for simplicity
        bordered
        size="small"
        rowKey="offenseLevel"
      />
    </div>
  );
};

export default OffenseLevelCaseTable;
