import client from "apis/config";

export const fetchAllDayPassBookings = async (limit = "1") => {
  return await client.get(`/bookings?limit=${limit}`);
};
export const fetchAllFullFacilityBookings = async (limit = "1") => {
  return await client.get(`/bookings?limit=${limit}`);
};
export const fetchAllEventsBookings = async (limit = "1") => {
  return await client.get(`/bookings?limit=${limit}`);
};
export const fetchAllLongTermBookings = async (limit = "1") => {
  return await client.get(`/bookings?limit=${limit}`);
};

export const fetchAllFSMBookingsWithTypes = async ({
  limit = "10",
  page,
  type = "",
  campus = "",
  facility = "",
  startAt = "",
  endAt = "",
  search = "",
  stage = "",
  status = "",
  stageStatus = "",
}) => {
  return await client.get(
    `/bookings/all?limit=${limit}&type=${type}&campus=${campus}&facility=${facility}&startAt=${startAt}&endAt=${endAt}&page=${page}&search=${search}&stage=${stage}&status=${status}&stageStatus=${stageStatus}`
  );
};
export const fetchFSMBookingsDetails = async ({ id }) => {
  return await client.get(`/bookings/details/${id}`);
};

export const feedBackBooingRequest = async (paylaod) => {
  return await client.post("/bookings/feedback", paylaod);
};
export const processBooingRequest = async (paylaod) => {
  return await client.post("/bookings/process", paylaod);
};
export const bookingStagesActionRequest = async (paylaod) => {
  return await client.post("/bookings/approval", paylaod);
};
export const getUsersForBookingRequest = async ({
  search = "",
  limit = "10",
}) => {
  return await client.get(
    `/users?parents=true&search=${search}&limit=${limit}`
  );
};

export const createBookingRequest = async (payload) => {
  return await client.post("/bookings/admin", payload);
};

export const bookingRefundRequest = async (payload) => {
  return await client.post("/bookings/refund", payload);
};
export const checkBookingAvailableDate = async ({
  facility,
  startAt,
  endAt,
  isSpecial = 0,
  bookingType = "",
}) => {
  return await client.get(
    `/bookings/availability?facility=${facility}&startAt=${startAt}&endAt=${endAt}&isSpecial=${isSpecial}&bookingType=${bookingType}`
  );
};

export const getBookingsHistoryLogs = async ({ id }) => {
  return await client.get(`/bookings/logs/${id}`);
};
export const onRescheduleBooking = async ({ payload }) => {
  return await client.post("/bookings/reschedule", payload);
};

export const getFacilitiesAndDiscountsForUser = async ({
  phoneNo,
  bookingType,
}) => {
  return await client.get(
    `/facilities/phone?phoneNo=${phoneNo}&bookingType=${bookingType}`
  );
};
export const onPartialRefundRequest = async ({ paylaod }) => {
  return await client.post("/bookings/refund", paylaod);
};

export const onUpdatePayment = async ({ id }) => {
  return await client.put(`/bookings/payment/${id}`);
};
export const onUpdatePosting = async ({ id }) => {
  return await client.put(`/bookings/posting/${id}`);
};

export const getArrivedBookingDetails = async ({ id }) => {
  return await client.get(`/bookings/arrived/${id}`);
};
export const markArrivedBySecurityGuard = async ({ id, payload }) => {
  return await client.post(`/bookings/arrived/${id}`, payload);
};

export const exportBookingToExcel = async ({ payload }) => {
  return await client.post("bookings/export", payload);
};

export const searchAttendeesByParent = async ({ parent }) => {
  return await client.get(`students/${parent}`);
};
