import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popconfirm, Spin, Tooltip } from "antd";
import { updateDropdownGroupsApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import { deleteDropdownGroupsApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import { addDropdownGroupsApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import { getDropdownGroupsApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { Capitalize } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";

const DropdownGroupsConfig = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editID, setEditID] = useState(null);
  const [groups, setGroups] = useState([]);
  const [form] = Form.useForm();

  const fetchGroups = async () => {
    try {
      setLoading(true);
      const response = await getDropdownGroupsApi();
      setGroups(response.data.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const onAddGroup = () => {
    setEditID(null);
    form.resetFields();
    setShowModal(true);
  };

  const onEditGroup = (values) => () => {
    setEditID(values.id);
    form.setFieldsValue(values);
    setShowModal(true);
  };
  const submitAddOrEditGroup = async (values) => {
    try {
      setLoading(true);
      if (editID) {
        await updateDropdownGroupsApi(editID, {
          value: values.value,
        });
      } else {
        await addDropdownGroupsApi({
          value: values.value,
        });
      }
      setShowModal(false);
      await fetchGroups();
      setLoading(false);
      successMessage({
        message: editID
          ? "Group value updated successfully"
          : "Group value added successfully",
      });
      setEditID(null);
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const deleteGroups = (value) => async () => {
    try {
      setLoading(true);
      await deleteDropdownGroupsApi(value.id);
      await fetchGroups();
      setLoading(false);
      successMessage({ message: "Group value deleted successfully" });
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          data={groups}
          header={() => (
            <div>
              <TableHeader
                headerTitle={"Dropdown Groups"}
                headerSubTitle={"Manage groups for application dropdowns"}
                headerBtnTitle="Add New"
                onAddNew={onAddGroup}
              />
            </div>
          )}
          columns={[
            {
              key: "id",
              title: "#",
              dataIndex: "id",
              render: (_, __, index) => index + 1,
            },
            {
              key: "id",
              title: "Group",
              dataIndex: "value",
              render: (item) => Capitalize(item),
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={onEditGroup(item)}
                      />
                    </Tooltip>
                  </div>

                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={deleteGroups(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
          hidePagination
        />
        <CustomModal
          visibility={showModal}
          handleOK={() => setShowModal(false)}
          handleCancel={() => setShowModal(false)}
          title={editID ? "Update Dropdown Group" : "Add Dropdown Group"}
        >
          <Form
            form={form}
            layout="vertical"
            size="large"
            onFinish={submitAddOrEditGroup}
          >
            <Form.Item name={"value"} label={"Group"} required>
              <Input />
            </Form.Item>
            <Button htmlType="submit">Submit</Button>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default DropdownGroupsConfig;
