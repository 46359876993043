/* eslint-disable eqeqeq */
import React from "react";
import { Card, Spin, Button, Row } from "antd";
import { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditorToolbar } from "utils/constants";
import { renderLoader } from "utils/helpers/helpers";

const Guidelines = () => {
  const [loading] = useState(false);
  const [termsEN, setTermsEN] = useState("");
  const [termsAR, setTermsAR] = useState("");

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <Card className="antCardBorder antCard">
          <div style={{ marginBottom: "20px" }}>
            <h5>Guidelines</h5>
          </div>
          <p>Guidelines (EN)</p>
          <CKEditor
            editor={ClassicEditor}
            data={termsEN}
            onReady={(editor) => {}}
            onChange={(event, editor) => {
              const data = editor.getData();
              setTermsEN(data);
            }}
            config={{
             //toolbar: EditorToolbar,
            }}
          />
          <br />
          <p>Guidelines (AR)</p>
          <CKEditor
            config={{
              language: "ar",
              //toolbar: EditorToolbar,
            }}
            editor={ClassicEditor}
            data={termsAR}
            onReady={(editor) => {}}
            onChange={(event, editor) => {
              const data = editor.getData();
              setTermsAR(data);
            }}
          />
          <Row justify={"end"}>
            <Button
              type="primary"
              style={{ width: "140px", marginTop: "2rem" }}
            >
              Save
            </Button>
          </Row>
        </Card>
      </Spin>
    </div>
  );
};
export default Guidelines;
