import client from "apis/config";

export const allDocumentsListConfiguration = async (queryParams) => {
  let endPoint = "documents/configurations";
  if (queryParams !== "") {
    endPoint = endPoint + queryParams;
  }
  return await client.get(endPoint);
};

export const addNewDocumentConfigurationAPI = async (data) => {
  return await client.post("documents/configurations", data);
};

export const deleteDocumentConfigurationAPI = async (id) => {
  return await client.delete(`documents/configurations/${id}`);
};

export const updateDocumentConfigurationAPI = async (id, data) => {
  return await client.put(`documents/configurations/${id}`, data);
};
