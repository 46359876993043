/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tabs,
} from "antd";
import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import PreFinalApprovalForm from "components/Forms/pre-final-approval-form/PreFinalApprovalForm";
import { useDispatch } from "react-redux";
import { addStageApproval } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { LoadingOutlined } from "@ant-design/icons";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import { applicationProcess } from "apis/applications-apis/applications";
import { _updateApplications } from "actions/applications/applications-actions";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { getApplicationDetails } from "apis/applications-apis/applications";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { useState } from "react";
import { useEffect } from "react";
import { getBusesAPI } from "apis/buses-cms/BusesCMSApi";
import { NotificationManager } from "react-notifications";
import { PopconfirmProps } from "utils/constants";

const { TabPane } = Tabs;

const Payment = (props) => {
  const [showApproveModal, toggleApproveModal] = React.useState(false);
  const [showBusModal, toggleBusModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const [activeTab, setActiveTab] = React.useState("details");
  const [busesList, setBusesList] = useState([]);
  const [bus, setBus] = useState(props.application?.bus?.id ?? undefined);

  const [search, setSearch] = useState("");

  const applicationId = props?.application?.id;
  const studentCampus = props?.application?.student?.campus;

  const getBusesList = async () => {
    try {
      setLoading(true);
      const response = await getBusesAPI(1, 1000, studentCampus);
      setLoading(false);
      if (response.status === 200 && response.data?.data) {
        const buses = response.data.data?.filter(
          (bus) => bus?.terms?.indexOf(props.application.eService.term) > -1
        );
        setBusesList(buses);
      } else {
        setBusesList([]);
        throw response;
      }
    } catch (e) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.application) getLogs();
  }, [props.application]);

  useEffect(() => {
    if (toggleBusModal && busesList.length === 0) getBusesList();
  }, [toggleBusModal]);

  const getLogs = async () => {
    try {
      setLoading(true);
      const response = await getApplicationStagesLogs({ id: applicationId });
      if (response.status == 200) {
        var applicationCurrentStage = currentStageDetails;
        if (
          applicationCurrentStage == undefined ||
          applicationCurrentStage == ""
        ) {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == props.application.stage.slug
          );
        } else {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == currentStageDetails.stageSlug
          );
        }
        setcurrentStageDetails(applicationCurrentStage);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const handelApplicationProcess = async (action, message) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: props.application?.id,
        action: action,
        schoolId: "",
        busId: bus ?? "",
      });
      if (response?.status == 200) {
        // dispatch(_updateApplications(application.id, response.data.data));
        successMessage({
          message: message ?? "Application status updated successfully",
        });
        props.refetch();
        getLogs();
        setLoading(false);
        toggleBusModal(false);
        getBusesList();
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  const onApproveClick = (action) => {
    handelApplicationProcess(action);
  };
  const onSubmitApprove = (values) => {
    //   approve, notes

    toggleApproveModal(false);
    values["appStageId"] = props.application?.stage.id;
    // setApprove(values.approve?.toUpperCase() || "");
    onAddStagesApproval(values);
  };

  const onAddStagesApproval = async (values) => {
    try {
      setLoading(true);
      const response = await addStageApproval({ data: values });
      if (response.status == 200) {
        successMessage({ message: "Remarks added successfully" });
        getLogs();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onAssignBus = () => {
    // handelApplicationProcess("assign_bus", "Bus assigned successfully", {
    //   busId: "",
    // });
    toggleBusModal(true);
  };

  const renderPaymentDetails = () => {
    let eServicetitle = props.application.eService.name;

    if (props.application.applicationType === "TRP") {
      eServicetitle = "Transportation";
    }

    if (!props.application.hasStagePermission) return null;
    if (!currentStageDetails?.approvals[0]) {
      return (
        <Card
          hoverable
          className="antCardBorder antCard"
          style={{ marginTop: 20 }}
        >
          <h6>Payment Detail</h6>

          <p>
            {eServicetitle} Fees Date & Time :
            <b>
              &nbsp;
              {props.application?.registrationFeeDateTime
                ? formateDateWithMoment(
                    props.application?.registrationFeeDateTime,
                    "DD MMM YYYY hh:mm A"
                  )
                : ""}
            </b>
          </p>
          <p>
            {" "}
            {eServicetitle} Fees Amount :
            <b>
              &nbsp;
              {props.application?.registrationFeeAmount
                ? props.application?.registrationFeeAmount + " AED"
                : ""}{" "}
            </b>
          </p>
        </Card>
      );
    } else {
      return (
        <Card className="antCardBorder">
          <h6>Final Remarks</h6>
          <h4>
            {currentStageDetails?.approvals[0].isApproved ? "Paid" : "UnPaid"}
          </h4>
          <hr />
          <p style={{ overflowX: "clip" }}>
            {currentStageDetails?.approvals[0].notes}
          </p>
        </Card>
      );
    }
  };

  const renderBusDetails = () => {
    const busDetails = props.application.bus;
    return (
      <Card
        hoverable
        className="antCardBorder antCard"
        style={{ marginTop: 20 }}
      >
        <h6>Bus Details</h6>
        {busDetails?.id ? (
          <div style={{ marginTop: 20 }}>
            <Table
              dataSource={[{ ...busDetails }]}
              pagination={false}
              columns={[
                {
                  title: "Bus No",
                  dataIndex: "busNo",
                },
                {
                  title: "Bus Plate",
                  dataIndex: "busPlateNo",
                },
                {
                  title: "Campus",
                  dataIndex: "campus",
                },
                {
                  title: "Driver Name",
                  dataIndex: "driverName",
                },
                {
                  title: "Route",
                  dataIndex: "route",
                },
                {
                  title: "Remaining Seats",
                  render: (item) => (
                    <span>
                      {item.remaining}/{item.busCapacity}
                    </span>
                  ),
                },
                {
                  title: "Driver",
                  render: (item) => (
                    <div>
                      <span>Driver 1</span>
                      <br />
                      <span className="ensBrandColor">
                        {item.driverName}-{item.driverMobileNo}
                      </span>
                      <br />
                      <span>Driver 2</span>
                      <br />
                      <span className="ensBrandColor">
                        {item.driver2Name}-{item.driver2MobileNo}
                      </span>
                    </div>
                  ),
                },
                {
                  title: "Nanny",
                  render: (item) => (
                    <div>
                      <span>Nanny 1</span>
                      <br />
                      <span className="ensBrandColor">
                        {item.nannyName}-{item.nannyMobileNo}
                      </span>
                      <br />
                      <span>Nanny 2</span>
                      <br />
                      <span className="ensBrandColor">
                        {item.nanny2Name}-{item.nanny2MobileNo}
                      </span>
                    </div>
                  ),
                },
                {
                  title: "Parent Note",
                  render: (record) => (
                    <div
                      dangerouslySetInnerHTML={{ __html: record.parentNote }}
                    />
                  ),
                },
              ]}
            />
          </div>
        ) : (
          <p>Not Assigned</p>
        )}
      </Card>
    );
  };

  const getBtnTitle = () => {
    if (
      // waiting payment or partially paid
      currentStageDetails?.stageStatus?.code == "SG940" ||
      currentStageDetails?.stageStatus?.code == "SG950" ||
      currentStageDetails?.stageStatus?.code == "SG340"
    ) {
      // payment completed
      return "Update Payment";
    } else if (
      currentStageDetails?.stageStatus?.code == "SG960" ||
      currentStageDetails?.stageStatus?.code == "SG350"
    ) {
      // fee paid
      return "Paid";
    } else {
      return "";
    }
  };

  const getTitle = () => {
    return currentStageDetails?.stageStatus?.internalStatus;
  };

  const getDescription = () => {
    if (
      currentStageDetails?.stageStatus?.code == "SG940" ||
      currentStageDetails?.stageStatus?.code == "SG950" ||
      currentStageDetails?.stageStatus?.code == "SG340"
    ) {
      // waiting payment
      return "Please click on the update payment button to update the payment status";
    } else if (
      currentStageDetails?.stageStatus?.code == "SG960" ||
      currentStageDetails?.stageStatus?.code == "SG350"
    ) {
      // fees paid
      return "The payment has been completed";
    } else {
      return "";
    }
  };

  const filteredBuses = busesList.filter((bus) => {
    return (
      bus.busNo.toLowerCase().includes(search.toLowerCase()) ||
      bus.busPlateNo.toLowerCase().includes(search.toLowerCase()) ||
      bus.driverName.toLowerCase().includes(search.toLowerCase())
    );
  });

  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={loader}>
        {props.application ? (
          <>
            <Row gutter={[20, 0]}>
              <Col sm={24} md={12}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>
              {
                <Col sm={24} md={12} style={{ width: "100%" }}>
                  <Card hoverable className="antCardBorder antCard">
                    <div>
                      <h5>{getTitle()}</h5>
                      <p style={{ fontSize: "12px" }}>{getDescription()}</p>
                    </div>
                    <Row align="middle" gutter={[20, 20]}>
                      {props.application?.hasStagePermission &&
                        (currentStageDetails?.stageStatus?.code === "SG345" ? (
                          <>
                            <Popconfirm
                              {...PopconfirmProps}
                              title={
                                "Are you sure you want to set items as delivered?"
                              }
                              onConfirm={() => {
                                handelApplicationProcess(
                                  "mark_delivered",
                                  "Application completed successfully"
                                );
                              }}
                            >
                              <Button type="primary">Items Delivered</Button>
                            </Popconfirm>
                          </>
                        ) : (
                          <Button
                            type="primary"
                            className="appButton"
                            onClick={() => onApproveClick("pay_fee")}
                            disabled={
                              // fees paid
                              currentStageDetails?.stageStatus?.code ==
                                "SG960" ||
                              currentStageDetails?.stageStatus?.code === "SG350"
                                ? true
                                : false
                            }
                          >
                            {getBtnTitle()}
                          </Button>
                        ))}
                      {props.application?.eService?.allocateBus &&
                      ["SG950", "SG960"].indexOf(
                        props.application.stage.status.code
                      ) > -1 &&
                      props.application.hasBusAssignPermission ? (
                        <Button
                          type="primary"
                          className="appButton"
                          onClick={onAssignBus}
                          style={{ marginLeft: 10 }}
                        >
                          {props.application.bus?.id
                            ? "Change / Remove Bus"
                            : "Assign Bus"}
                        </Button>
                      ) : null}
                    </Row>
                  </Card>
                </Col>
              }
            </Row>
            {/* sg350 */}
            {currentStageDetails?.stageStatus?.code === "SG960" ||
            currentStageDetails?.stageStatus?.code === "SG950" ||
            currentStageDetails?.stageStatus?.code === "SG350"
              ? renderPaymentDetails()
              : null}
            {props.application.applicationType === "TRP" && renderBusDetails()}
            <Tabs
              activeKey={activeTab}
              onChange={(tab) => setActiveTab(tab)}
              style={{ marginTop: "1rem" }}
              tabBarGutter={40}
            >
              <TabPane tab={<h6>Details</h6>} key="details">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={props.application.applicationType?.toLowerCase()}
                />
              </TabPane>
              <TabPane tab={<h6>Documents</h6>} key="documents">
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>
              {props.application?.applicationType === "ASR" &&
                props.application?.items &&
                props.application?.items?.length > 0 && (
                  <TabPane tab={<h6>Items</h6>} key={"items"}>
                    <ApplicationInformationReadOnlyForm
                      application={props.application}
                      type={"asr-items"}
                    />
                  </TabPane>
                )}
              <TabPane tab={<h6>Stages History</h6>} key="logs">
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>
            <CustomModal
              visibility={showApproveModal}
              handleCancel={() => toggleApproveModal(false)}
              title="Registration Fee Approval Remarks"
            >
              <PreFinalApprovalForm
                onFinish={onSubmitApprove}
                loading={loading}
              />
            </CustomModal>

            <CustomModal
              visibility={showBusModal}
              handleCancel={() => toggleBusModal(false)}
              title="Select Bus"
            >
              <div>
                {props.application.stage.status.code == "SG960" && (
                  <div style={{ marginTop: "-15px", paddingBottom: "10px" }}>
                    <small>
                      If you want to remove an assigned bus, please uncheck all
                      and submit
                    </small>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <Form.Item>
                    <Input
                      placeholder="Search Bus"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Item>
                </div>

                <Table
                  dataSource={filteredBuses.filter((bus) => bus.isActive)}
                  pagination={false}
                  columns={[
                    {
                      title: "Select",
                      render: (item) => (
                        <Checkbox
                          onChange={() => {
                            setBus(bus === item.id ? undefined : item.id);
                          }}
                          checked={bus === item.id}
                          defaultChecked={bus === item.id}
                        />
                      ),
                    },
                    {
                      title: "Bus No",
                      dataIndex: "busNo",
                    },
                    {
                      title: "Bus Plate",
                      dataIndex: "busPlateNo",
                    },
                    {
                      title: "Driver Name",
                      dataIndex: "driverName",
                    },
                    {
                      title: "Remaining/Capacity",
                      render: (item) => (
                        <p>
                          {item.remaining}/{item.busCapacity}
                        </p>
                      ),
                    },
                  ]}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <Button
                    onClick={() =>
                      handelApplicationProcess(
                        "assign_bus",
                        "Bus assigned successfully"
                      )
                    }
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </CustomModal>
          </>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default Payment;
