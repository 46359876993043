import {
  CalendarOutlined,
  CopyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import * as React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";

const CancellationLogs = ({ history }) => {
  return (
    <div>
      {history?.map((item) => {
        if (item.meta?.action === "cancel") {
          return (
            <div>
              <h6>{"Application Cancelled"}</h6>
              <div className="rh-log-item">
                <div className="rh-log-row">
                  <CopyOutlined className="rh-log-icon" />
                  <p>Notes: {item.meta.cancelNote}</p>
                </div>
                <div className="rh-log-row">
                  <CalendarOutlined className="rh-log-icon" />
                  <p>
                    {formateDateWithMoment(
                      item.createdAt,
                      "DD MMM YYYY hh:mm A"
                    )}
                  </p>
                </div>
                <div className="rh-log-row">
                  <UserOutlined className="rh-log-icon" />
                  <p>{item.user?.name + " - " + item.user?.userName}</p>
                </div>
              </div>
            </div>
          );
        } else if (item.meta.action === "activate_cancelled") {
          return (
            <div>
              <h6>{"Application Activated"}</h6>
              <div className="rh-log-item">
                <div style={{ display: "flex", padding: 0, margin: 0 }}>
                  <CopyOutlined className="rh-log-icon" />
                  <p>Notes: {item.meta.cancelNote}</p>
                </div>

                <div className="rh-log-row">
                  <CalendarOutlined className="rh-log-icon" />
                  <p>
                    {formateDateWithMoment(
                      item.createdAt,
                      "DD MMM YYYY hh:mm A"
                    )}
                  </p>
                </div>
                <div className="rh-log-row">
                  <UserOutlined className="rh-log-icon" />
                  <p>{item.user?.name + " - " + item.user?.userName}</p>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
export default CancellationLogs;
