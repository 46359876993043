import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { useSelector } from "react-redux";
const { Option } = Select;
const HeaderForm = ({ onSubmit, loading, formName, valuesForEdit }) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={24}>
            <Form.Item label={"Title"} name={"title"} rules={setRules("Title")}>
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={24}>
            <Form.Item
              rules={setRules("Content (EN)")}
              label={"Content (EN)"}
              name={"content"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) => editor?.getData() ?? null}
            >
              <CKEditor
                config={
                  {
                    //toolbar: EditorToolbar,
                  }
                }
                editor={ClassicEditor}
                key={"content"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={24}>
            <Form.Item
              rules={setRules("Content (AR)")}
              label={"Content (AR)"}
              name={"contentAr"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) => editor?.getData() ?? null}
            >
              <CKEditor
                config={{
                  //toolbar: EditorToolbar,
                  language: "ar",
                }}
                editor={ClassicEditor}
                key={"contentAr"}
              />
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default HeaderForm;
