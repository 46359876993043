import { _getDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
} from "antd";
import { addAdditionalDocumentsAPI } from "apis/applications-apis/applications";
import { deleteAdditionalDocumentAPI } from "apis/applications-apis/applications";
import { documentsFeedback } from "apis/applications-apis/applications";
import { applicationProcess } from "apis/applications-apis/applications";
import { getDistributionAPI } from "apis/distribution-apis/DistributionApis";
import { getSignUrl } from "apis/get-sign-url/getSignUrl";
import FormGroupItemForDocuments from "components/FormGroupItemForDocuemnts/FormGroupItemForDocuments";
import ApplicationInformationForm from "components/Forms/ApplicationInformationForm";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import axios from "axios";
import CreateNewDocumentListForm from "components/Forms/CreateNewDocumentListForm";
import CustomModal from "components/CustomModal/CustomModal";
import { applicationFeedback } from "apis/applications-apis/applications";
import { showValidationError } from "utils/helpers/helpers";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import { updateDiscountAPI } from "apis/applications-apis/discount-application";
import { getApplicationStudents } from "apis/applications-apis/applications";
import { changeStudentAPI } from "apis/applications-apis/discount-application";
import DiscountStudents from "components/DiscountComponents/DiscountStudentsTable/DiscountStudentsTable";
import DiscountOtherApplication from "components/DiscountComponents/DiscountOtherApplications/DiscountOtherApplications";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { calculateRemainingFees } from "utils/helpers/helpers";
import { roundToTwo } from "utils/helpers/helpers";
import { getSumOfFees } from "utils/helpers/helpers";
import { updateDiscountRule } from "apis/applications-apis/discount-application";

import {} from "@ant-design/icons";
import { useCallback } from "react";
import { getRulesAPI } from "apis/discount-rules/DiscountRulesApi";
import { _getRulesAction } from "actions/discount-rules/DiscountRulesActions";
import CancelApplicationForm from "components/Forms/CancelApplicationForm";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
import { getFileTypeFromUrl } from "utils/helpers/helpers";
const { Option } = Select;
const { TabPane } = Tabs;

const DiscountReview = (props) => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.login.userInfo?.role);
  const [form] = Form.useForm();

  // state variables for documents
  const [documentForm] = Form.useForm();
  const [childDocuments, setChildDocuments] = React.useState([]);
  const [parentDocuments, setParentDocuments] = React.useState([]);
  const [additionalDocuments, setAdditonalDocuments] = React.useState([]);
  let [imagesArray, sendToImagesArray] = React.useState([]);
  const [addDocumentModal, showAddDocumentModal] = React.useState(false);
  const [additionalDocumentForm] = Form.useForm();
  // ---state variables for documents ends ---

  const _otherDiscountApplications = useSelector(
    (state) =>
      state.allApplications?.applications?.filter(
        (el) =>
          el.applicationType === "TFD" &&
          el.id !== props.application.id &&
          el.submittedBy === props.application?.submittedBy &&
          el.eService?.term === props.application?.eService?.term
      ),
    shallowEqual
  );
  const [isCancelApplicationModalOpen, setIsCancelApplicationModalOpen] =
    useState(false);
  const stageStatusCode = props.application.stage?.status?.code;
  const applicationId = props?.application?.id;
  const _distributions = useSelector((state) => state.distributions.data);
  const _rules = useSelector((state) => state.rules.data, shallowEqual);
  const [rulesList, setRulesList] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [feesFetched, setFeesFetched] = useState(
    props.application?.students.filter((std) => std.invoices.length > 0)
      ?.length > 0
  );
  const [isFieldsDisabled, setFieldsDisabled] = React.useState(false);
  const fieldsApprovalPending = ["SG400", "SG410", "SG420", "SG430"].includes(
    stageStatusCode
  );
  const [allFieldsApproved, setAllFieldsApproved] = useState(false);
  const [activeTab, setActiveTab] = useState(
    fieldsApprovalPending ? "Details" : "Students"
  );
  const [selectedDistributions, selectDistributions] = useState([]);
  const [allowChangeDistribution, setAllowChangeDistribution] = useState(false);
  const [showDistributionModal, setShowDistributionModal] = useState(false);
  const [customDiscount, setCustomDiscount] = useState([]);
  const [isCustomDistribution, setCustomDistribution] = useState(null);

  const [discountChangesSaved, setChangesSaved] = useState(true);
  const [discountValue, setDiscountValue] = useState([]);

  const [changeStudentModal, showChangeStudentModal] = useState(false);
  const [otherStudents, setOtherStudents] = useState();
  const [selectedStudents, setSelectedStudents] = useState([]);

  const studentColumns = [
    {
      title: "Action",
      render: (record, index) => (
        <Checkbox
          onChange={(e) => toggleStudent(record, e.target.checked, index)}
          checked={
            selectedStudents.findIndex(
              (el) => el.studentNumber == record.studentNumber
            ) > -1
          }
        ></Checkbox>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Grade",
      dataIndex: "grade",
    },
    {
      title: "Campus",
      dataIndex: "campus",
    },
    {
      title: "School",
      dataIndex: "school",
    },
  ];
  const [file, setFile] = useState();
  const [docType, setDocType] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    fetchDistributions();
    fetchRules();
  }, []);

  useEffect(() => {
    if (_rules.length > 0) {
      const otherApplicationsRules = _otherDiscountApplications.map(
        (app) => app.discountRule.id
      );
      const allowedRules = _rules.filter(
        (rule) => !otherApplicationsRules.includes(rule.id)
      );
      setRulesList(allowedRules);
    }
  }, [_otherDiscountApplications, _rules, setRulesList]);

  useEffect(() => {
    if (props.application) {
      // new applikcation., under review, amendment review
      setFieldsDisabled(
        stageStatusCode !== "SG400" &&
          stageStatusCode !== "SG410" &&
          stageStatusCode !== "SG430"
      );

      documentForm.setFieldsValue(props.application.documents);
      let childDocumentsArray = [];
      let parentDocumentsArray = [];
      let additionalDocumentsArray = [];
      props.application.documents.forEach((document, index) => {
        if (document.documentOf === "child" && !document.isAdditionalDocument) {
          childDocumentsArray.push({ ...document, index });
        } else if (
          document.documentOf === "parent" &&
          !document.isAdditionalDocument
        ) {
          parentDocumentsArray.push({ ...document, index });
        } else {
          additionalDocumentsArray.push({ ...document, index });
        }
      });
      setChildDocuments(childDocumentsArray);
      setParentDocuments(parentDocumentsArray);
      setAdditonalDocuments(additionalDocumentsArray);

      if (props.application.students) {
        let distributions = props.application.students.map(
          (std) => std.distributionId
        );
        selectDistributions(distributions);
        setCustomDiscount(
          props.application.students.map((el, index) => {
            return {
              value: null,
              studentIndex: index,
              show: false,
            };
          })
        );
      }
      setFeesFetched(
        props.application?.students.filter((std) => std.invoices.length > 0)
          ?.length > 0
      );
      if (feesFetched) {
        for (let i = 0; i < props.application.students.length; i++) {
          const student = props.application.students[i];
          let sumOfFees = getSumOfFees(student.invoices);
          let totalDiscount = student.discountValue;
          if (totalDiscount > sumOfFees) {
            showValidationError(
              "The discount amount must be equal or less than the total fees. Please update the discount amount."
            );
            return;
          }
        }
      }
      setAllFieldsApproved(checkAllFieldsApproved());
    }
  }, [props.application]);

  useEffect(() => {
    if (
      feesFetched &&
      props.application.students &&
      selectedDistributions.length > 0
    ) {
      let result = props.application.students.map((student, stdNo) => {
        const selectedDistribution = _distributions.find(
          (d) => d.id == selectedDistributions[stdNo]
        );

        let totalDiscount =
          typeof customDiscount[stdNo] !== "undefined" &&
          customDiscount[stdNo]?.value !== null
            ? customDiscount[stdNo].value
            : student.discountValue;
        const sumOfFees = getSumOfFees(student.invoices);
        if (student.discountType === "percentage") {
          totalDiscount = (sumOfFees * totalDiscount) / 100;
        }
        let isDiscountEqualOrGreater = totalDiscount >= sumOfFees;
        let extras = student?.invoices.map(() => 0);
        let discounts = student?.invoices.map((invoice, invoiceNo) => {
          // in case of free distribution, id is 0; and discount values will be prefilled
          // if theres no custom discount, no need to calculate them
          if (
            // customDiscount[stdNo].value === null &&
            selectedDistributions[stdNo] === 0
          ) {
            return discountValue.length > 0
              ? discountValue[stdNo][invoiceNo]
              : invoice.discount;
          } else {
            if (isDiscountEqualOrGreater) return invoice.amount;
            const discountResult = calculateDiscount(
              selectedDistribution?.instalments[invoiceNo],
              totalDiscount,
              invoice
            );
            if (discountResult.extra > 0) {
              extras[invoiceNo] = discountResult.extra;
            }
            return discountResult.discount;
          }
        });
        discounts = shiftExtraDiscount(extras, student, discounts);
        return discounts;
      });
      setDiscountValue(result);
    }
  }, [feesFetched, selectedDistributions, props.application, customDiscount]);

  useEffect(() => {
    if (stageStatusCode == "SG440") fetchOtherStudents();
  }, [fieldsApprovalPending, stageStatusCode, props.application]);

  const checkAllFieldsApproved = () => {
    if (props.application.discountRule?.ruleStatus === "approved") {
      let fieldsApproved = false;
      let fields = Object.values(props.application.fields);
      fields = [...fields, ...props.application.documents];
      for (let i = 0; i < fields.length; i++) {
        let item = fields[i];
        if (item.status === "approved") {
          fieldsApproved = true;
        } else {
          fieldsApproved = false;
          return;
        }
      }
      return fieldsApproved;
    } else return false;
  };

  const handleFileInputChange = (e, element, id) => {
    var file = e.target.files[0];
    let oldImagesArray = [...imagesArray];
    oldImagesArray = oldImagesArray.filter((image) => image.element.id != id);

    sendToImagesArray([...oldImagesArray, { element: element, image: file }]);
  };

  const onFinishDocumentsForm = async (val) => {
    if (imagesArray.length) {
      for (let image of imagesArray) {
        const imageURL = await handleUploadFileToENSServer(
          image.image,
          "discount"
        );
        const getFieldValue = documentForm.getFieldValue(image.element.index);
        image.element.value = imageURL;
        image.element.note = getFieldValue.note;
        image.element.status = getFieldValue.status;

        documentForm.setFieldsValue({
          [image.element.index]: image.element,
        });
      }
      const updatedValues = documentForm.getFieldsValue();
      submitDocumentsForm(updatedValues);
    } else {
      let emptyImageFound = false;
      const allImages = Object.values(val);
      allImages.forEach((image) => {
        if (
          image.value === "" &&
          image.status === "approved" &&
          !image.notAvailable
        ) {
          emptyImageFound = true;
        }
      });

      if (emptyImageFound) {
        NotificationManager.error("Please upload all images");
        return;
      }

      try {
        const data = {
          applicationId: applicationId,
          documents: Object.values(val),
        };

        setLoading(true);

        const response = await documentsFeedback(data);

        if (response.data.statusCode === 200 && response.data.data) {
          props.refetch();
          setLoading(false);
          successMessage({ message: "Documents updated successfully" });
        } else setLoading(false);
      } catch (error) {
        setLoading(false);
        await requestErrorHandel({ error: error });
      }
    }
  };
  const onDeleteDocument = async (doc) => {
    try {
      const result = await deleteAdditionalDocumentAPI(doc.id);
      if (result.data.statusCode === 200) {
        successMessage({ message: "Document deleted!" });
      } else throw result.data;
    } catch (e) {
      requestErrorHandel({ error: e });
    }
  };

  const onSubmitAdditionalDocForm = async (val) => {
    setLoading(true);
    let document = {
      applicationId: applicationId,
      documentOf: val?.documentOf,
      documentType: docType,
      documentUrl: file,
    };
    try {
      const result = await addAdditionalDocumentsAPI(document);
      setLoading(false);
      if (result.data.statusCode === 200 && result.data.data) {
        props.refetch();
        showAddDocumentModal(false);
      }
    } catch (e) {
      setLoading(false);
      await requestErrorHandel({ error: e });
    }
  };

  const submitDocumentsForm = async (val) => {
    try {
      const data = {
        applicationId: applicationId,
        documents: Object.values(val),
      };

      setLoading(true);

      const response = await documentsFeedback(data);

      if (response.data.statusCode === 200 && response.data.data) {
        props.refetch();
        setLoading(false);
        successMessage({ message: "Documents uploaded successfully" });
        sendToImagesArray([]);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
      setLoading(false);
    }
  };
  const onSave = async () => {
    form
      .validateFields()
      .then(async (res) => {
        let fields = [];
        let discountRule = res?.discountRule;
        if (
          typeof discountRule.value === "number" &&
          discountRule.value !== props.application.discountRule?.id
        ) {
          // call update discount rule api
          try {
            setLoading(true);
            const response = await updateDiscountRule({
              applicationId,
              ruleId: discountRule.value,
            });

            setLoading(false);
          } catch (e) {
            setLoading(false);
          }
        }
        // remove discountRule from the res object
        fields = Object.entries(res)?.filter(([key]) => key !== "discountRule");
        fields = fields.map((el) => el[1]);
        try {
          if (fields.length > 0 || !!discountRule) {
            setLoading(true);
            const fieldsResponse = await applicationFeedback({
              applicationId: Number(props.application.id),
              fields: fields,
              discountRule: {
                status: discountRule.status,
                note: discountRule.note,
              },
            });
            if (fieldsResponse.status == 200) {
              successMessage({
                message: "Application status updated successfully",
              });
              setLoading(false);
              props.refetch();
            } else {
              throw fieldsResponse;
            }
          } else
            throw new Error(
              "Please make some changes in the application fields before saving"
            );
        } catch (e) {
          requestErrorHandel({ error: e });
          setLoading(false);
        }
      })
      .catch((e) => {
        showValidationError(
          `${e.errorFields[0]?.errors[0] || "Please enter all required fields"}`
        );
      });
    documentForm
      .validateFields()
      .then((res) => {
        res && Object.keys(res).length > 0 && onFinishDocumentsForm(res);
      })
      .catch((e) => {
        showValidationError(
          `${
            e.errorFields[0]?.errors[0] || "Please enter all required fields"
          } in documents`
        );
      });
  };

  const fetchDistributions = async () => {
    try {
      setLoading(true);
      const response = await getDistributionAPI(`page=1&limit=1000`);
      if (response.status == 200 && response.data) {
        dispatch(_getDistributionAction(response.data));
        setLoading(false);
      } else throw response.data;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  const fetchRules = useCallback(async () => {
    try {
      const paginationParams = `page=${1}&limit=1000`;
      const response = await getRulesAPI(paginationParams);
      if (response.data && response.status === 200) {
        dispatch(_getRulesAction(response.data));
      } else throw response.error;
    } catch (e) {
      console.log({ e });
    }
  }, [dispatch]);

  const fetchOtherStudents = async () => {
    try {
      setSelectedStudents(props.application.students);
      const response = await getApplicationStudents(applicationId);
      if (response.status == 200 && response.data?.data) {
        const selectedStudents = props.application.students.map(
          (std) => std.studentNumber
        );
        let others = response.data.data.filter(
          (std) => selectedStudents?.indexOf(std?.studentNumber) == -1
        );
        setOtherStudents(others);
      } else throw response;
    } catch (e) {
      console.log({ e });
      requestErrorHandel({ error: e });
    }
  };

  const fetchFees = async () => {
    try {
      const response = await processApplication(
        "update_invoices",
        "Fees details fetched successfully",
        () => {
          setFeesFetched(true);
        }
      );
    } catch (e) {
      requestErrorHandel({ error: e });
    }
  };

  const processApplication = async (action, message, onSuccess) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: applicationId,
        action: action,
      });
      successMessage({ message });
      onSuccess && onSuccess(response.data?.data);
      props.refetch();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };
  const calculateDiscount = (currentDistribution, totalDiscount, invoice) => {
    if (totalDiscount > -1 && currentDistribution > -1) {
      let extra = 0;
      let discount =
        (Number(totalDiscount) * Number(currentDistribution)) / 100;
      if (discount > invoice.amount) {
        extra = discount - invoice.amount;
        discount = invoice.amount;
      }
      discount = roundToTwo(discount);
      return { discount: discount, extra: extra };
    } else return 0;
  };
  const shiftExtraDiscount = (extras, student, discounts) => {
    let updatedDiscount = [...discounts];
    let extraIndex = extras.findIndex((el) => el > 0);
    if (extraIndex > -1) {
      let indexToUpdate = 0;
      let extraDiscount = extras[extraIndex];

      // decide which installment to push the extra discount to
      if (extraIndex === 0) {
        // if first index shift extra amount to next installment
        indexToUpdate = extraIndex + 1;
      } else if (extraIndex === student.invoices.length - 1) {
        indexToUpdate = extraIndex - 1;
      } else {
        // depends on remaining value of next or previous installment
        // if remaining amount is 0 for an installment, do not push there
        const previousRemaining =
          student.invoices[extraIndex]?.amount - updatedDiscount[extraIndex];
        indexToUpdate =
          previousRemaining === 0 ? extraIndex - 1 : extraIndex + 1;
        extraDiscount =
          extras[extraIndex] +
          extras[
            indexToUpdate === extraIndex - 1 ? extraIndex + 1 : extraIndex - 1
          ];
      }
      updatedDiscount[indexToUpdate] =
        updatedDiscount[indexToUpdate] + extraDiscount;
      let remaining =
        student.invoices[indexToUpdate].amount - updatedDiscount[indexToUpdate];
      if (remaining < 0) {
        // after shifting discount, the new discount might be excedding the fees again
        // shift extra discount to next or previous installment again here
        updatedDiscount[indexToUpdate] = student.invoices[indexToUpdate].amount;
        const previousRemaining =
          student.invoices[indexToUpdate - 1]?.amount -
          updatedDiscount[indexToUpdate - 1];
        let secondShiftIndex =
          previousRemaining === 0 ? indexToUpdate + 1 : indexToUpdate - 1;
        updatedDiscount[secondShiftIndex] = roundToTwo(
          discounts[secondShiftIndex] + Math.abs(remaining)
        );
      }
      return updatedDiscount;
    } else return discounts;
  };

  const handelApplicationProcess = async (action, note) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: props.application?.id,
        action: action,
        note: note,
      });
      if (response?.status == 200) {
        setLoading(false);
        successMessage({
          message: "Application status updated successfully",
        });
        if (action === "cancel_amendment" || action === "cancel_acceptance") {
          setFieldsDisabled(false);
        } else {
          setFieldsDisabled(true);
        }
        props.refetch();
      } else {
        setLoading(false);
        throw response;
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        props.application.studentAlreadyExists = true;
        props.refetch();
      }
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onConfirmApplication = async () => {
    let invalidDistribution = checkInvalidDistributions();
    if (invalidDistribution) {
      showValidationError(
        `The distribution for student ${
          invalidDistribution.name || "one of the students"
        } is invalid, please change the distribution before proceeding`
      );
      return;
    }
    const invalidDiscount = checkInvalidDiscount();
    if (invalidDiscount) {
      showValidationError(
        "Remaining fees should not be less than 0. Please select a valid discount or distribution"
      );
      return;
    }
    await updateDiscount();
    processApplication(
      "review_completed",
      "Application processed successfully"
    );
  };

  const isEditingDiscountValueManually = () => {
    return customDiscount.findIndex((d) => d.show === true) > -1;
  };

  const updateDiscount = async () => {
    const application = props.application;
    if (application && discountValue.length) {
      let invalidDistribution = checkInvalidDistributions();
      if (invalidDistribution) {
        showValidationError(
          `The distribution for student ${
            invalidDistribution.name || "one of the students"
          } is invalid, please change the distribution before proceeding`
        );
        return;
      }
      const invalidDiscount = checkInvalidDiscount();
      if (invalidDiscount) {
        showValidationError(
          "Remaining fees should not be less than 0. Please select a valid discount or distribution"
        );
        return;
      }
      try {
        const students = application.students.map((std, stdNo) => {
          let student = { ...std };
          [
            "invoices",
            "campus",
            "campusId",
            "grade",
            "gradeId",
            "name",
            "school",
            "schoolId",
          ].forEach((key) => delete student[key]);
          let services = std.invoices.map((invoice, invoiceNo) => {
            return {
              serviceId: invoice.serviceId,
              discount: discountValue[stdNo][invoiceNo],
            };
          });
          return {
            ...student,
            distributionId: selectedDistributions[stdNo],
            discountValue: customDiscount[stdNo].value ?? std.discountValue,
            services,
          };
        });
        const data = {
          applicationId,
          students,
        };
        const response = await updateDiscountAPI(data);
        setLoading(false);
        if (response.status === 200) {
          successMessage({ message: "Discount updated successfully" });
          setChangesSaved(true);
        } else throw response;
      } catch (e) {
        requestErrorHandel({ error: e });
        setLoading(false);
      }
    }
  };

  const renderFormActionButtons = (stageStatus) => {
    return (
      <div>
        {/* Start Approval Button */}
        {/* Waiting For Approval Initialization */}
        {stageStatus === "SG480" ? (
          <Row>
            <Button
              style={{ marginRight: "10px" }}
              danger
              onClick={() =>
                processApplication(
                  "cancel_acceptance",
                  "Confirmation has been cancelled"
                )
              }
            >
              Cancel Confirmation
            </Button>
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={() =>
                props.history.push("approval-steps", {
                  application: props.application,
                })
              }
            >
              Start Approval Process
            </Button>
          </Row>
        ) : null}

        {activeTab !== "logs" ? (
          <Row justify="end">
            {/* Send to Parent Button */}
            {/* under review, amendment review */}
            {!allFieldsApproved &&
            ["SG410", "SG430"].includes(stageStatusCode) &&
            !props.application.discountRuleUpdated ? (
              <Popconfirm
                placement="topLeft"
                title="Are you sure you want to return this application to parent for amendment?"
                onConfirm={() => {
                  handelApplicationProcess("send_to_parent");
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: "10px" }} type="primary">
                  Return to Parent
                </Button>
              </Popconfirm>
            ) : null}

            {/* Cancel Amendment Button */}
            {/* Pending Amendment */}
            {stageStatus === "SG420" ? (
              <Popconfirm
                placement="topLeft"
                title="Are you sure you want to cancel amendment?"
                onConfirm={() => {
                  handelApplicationProcess("cancel_amendment");
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: "10px" }}>
                  Cancel Amendment
                </Button>
              </Popconfirm>
            ) : null}

            {/* Save Application Button */}
            {/* new application, under review, amendment review, waiting acceptance */}
            {["SG400", "SG410", "SG430"].includes(stageStatus) ? (
              <Button type="primary" onClick={onSave}>
                Save Application
              </Button>
            ) : null}

            {/* Mark Review Completed */}
            {/* When all fields are approved */}

            {allFieldsApproved && stageStatus === "SG410" && (
              <Button
                type={"primary"}
                onClick={() => handelApplicationProcess("check_data_approval")}
                style={{ marginLeft: "10px" }}
              >
                Mark Review Completed
              </Button>
            )}
            {allFieldsApproved && stageStatus === "SG440" && (
              <Button
                type={"default"}
                danger
                style={{ marginLeft: "10px" }}
                onClick={() =>
                  processApplication(
                    "cancel_acceptance",
                    "Review Completion has been cancelled"
                  )
                }
              >
                Cancel Review
              </Button>
            )}
          </Row>
        ) : null}
      </div>
    );
  };
  const checkInvalidDistributions = () => {
    if (selectedDistributions.length) {
      let invalidDistribution = props.application?.students.find(
        (student, index) => {
          // selected distribution is 0 for free distribution type
          if (!student.invoices?.length || selectedDistributions[index] === 0) {
            return null;
          }
          let distributionInstallments = _distributions.find((d) => {
            return d.id == selectedDistributions[index];
          })?.instalments;
          return student.invoices.length !== distributionInstallments?.length;
        }
      );
      return invalidDistribution ?? null;
    } else return null;
  };
  const checkInvalidDiscount = () => {
    let fees = [];
    props.application.students.forEach((std, stdNo) => {
      std.invoices.forEach((invoice, invoiceNo) => {
        fees.push(
          calculateRemainingFees(
            invoice,
            stdNo,
            invoiceNo,
            std.discountType,
            discountValue,
            false,
            props.application.students
          )
        );
      });
    });

    return fees.findIndex((value) => value < 0) > -1;
  };
  const changeDistribution = (value, studentNo) => {
    setCustomDistribution(value?.toString() === "0" ? studentNo : null);
    let newDistribution = [...selectedDistributions];
    newDistribution[studentNo] = value;
    setChangesSaved(false);
    selectDistributions(newDistribution);
  };

  const confirmChangeDistribuition = () => {
    // if changing distribution is enabled, dont ask for confirmation, else
    // ask for confirmation to change in a modal
    if (
      !allowChangeDistribution &&
      props.application?.hasStagePermission &&
      stageStatusCode === "SG440" &&
      feesFetched
    )
      setShowDistributionModal(true);
  };

  // when user clicks on change student button
  const onChangeStudent = () => {
    showChangeStudentModal(true);
  };
  // when user selects or unselects a student from the table
  const toggleStudent = (student, checked, studentIndex) => {
    if (checked) {
      setSelectedStudents([student, ...selectedStudents]);
    } else {
      let students = [...selectedStudents];
      // let updatedDiscount = [...discountValue];
      // updatedDiscount.splice(studentIndex, 1);
      // setDiscountValue(updatedDiscount);
      setSelectedStudents(
        students.filter((el) => el.studentNumber !== student.studentNumber)
      );
    }
  };
  const hideChangeStudentModal = () => {
    showChangeStudentModal(false);
    setSelectedStudents(props.application.students);
  };

  // when user clicks on save after toggling students from table
  const saveChangeStudent = async () => {
    if (selectedStudents.length > 0) {
      try {
        setLoading(true);
        const data = {
          applicationId,
          students: selectedStudents.map((el) => el.studentNumber),
        };
        const response = await changeStudentAPI(data);
        hideChangeStudentModal();
        setChangesSaved(false);
        props.refetch();
        setLoading(false);
      } catch (e) {
        console.log({ e });
        setLoading(false);
        requestErrorHandel({ error: e });
      }
    } else {
      showValidationError(
        "Please select at least one of the students before saving"
      );
    }
  };
  const cancelCustomDistribution = () => {
    let updatedDistribution = [...selectedDistributions];
    updatedDistribution[isCustomDistribution] =
      props.application.students[isCustomDistribution].distributionId;
    selectDistributions(updatedDistribution);
    setCustomDistribution(null);
  };

  const onChooseFile = async (e) => {
    try {
      setUploading(true);
      if (e.target.files.length) {
        let fileType = getFileTypeFromUrl(e.target.files[0].name);
        if (fileType === "unknown") {
          NotificationManager.warning("File not supported");
          return;
        } else {
          const path = await handleUploadFileToENSServer(
            e.target.files[0],
            "discount"
          );
          setFile(path);
          setDocType(fileType);
          setUploading(false);
        }
      }
    } catch (error) {
      setUploading(false);
    }
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <Card hoverable className="antCardBorder antCard">
          <Row justify="space-between">
            <Col>
              <ApplicationInfo application={props.application} />
            </Col>
            <Col>
              {role === "super-admin" &&
              ["AS001", "AS002", "AS003"].includes(
                props.application?.status.code
              ) ? (
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to cancel this application?"
                  onConfirm={() => {
                    handelApplicationProcess("cancel");
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger type="primary">
                    Cancel Application
                  </Button>
                </Popconfirm>
              ) : null}
            </Col>
          </Row>
          {/* Cancel Application Button */}
        </Card>
        <br />
        <Card className="antCardBorder antCard">
          <Row justify="space-between" align="middle">
            <Col lg={14} sm={24}>
              <h5>Application Details</h5>
              {/* <br /> */}
              {props.application?.hasStagePermission && (
                <p>
                  Please review all the details and send it to parent. If
                  everything is okay, please confirm to send it to next phase.
                </p>
              )}
              <p>
                {stageStatusCode === "SG440" && !feesFetched && (
                  <>
                    <strong>Please fetch fees in the students tab.</strong>
                  </>
                )}
              </p>
            </Col>
            {props.application?.hasStagePermission && (
              <Col>
                {renderFormActionButtons(
                  props.application?.stage?.status?.code
                )}
              </Col>
            )}
          </Row>
          <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
            <TabPane key={"Details"} tab={<h6>Application Details</h6>}>
              {props.application?.hasStagePermission ? (
                <ApplicationInformationForm
                  application={props.application}
                  type={props.application.applicationType?.toLowerCase()}
                  isFieldsDisabled={isFieldsDisabled}
                  form={form}
                  onApproveAll={() => null}
                  hideApproveAll={true}
                  discountRules={rulesList}
                />
              ) : (
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={props.application.applicationType?.toLowerCase()}
                />
              )}
            </TabPane>
            <TabPane key={"Documents"} tab={<h6>Documents</h6>}>
              <Form name="tfd-document-form" form={documentForm}>
                {props.application?.documents?.length > 0 ? (
                  <>
                    {props.application?.hasStagePermission ? (
                      <Row gutter={[30, 10]} className="documentFormItemRow">
                        <Col md={12}>
                          <h6 style={{ marginBottom: "3rem" }}>
                            Parent Documents
                          </h6>
                          {parentDocuments.map((document, i) => {
                            return (
                              <Card
                                hoverable
                                className="documentInputCardItem"
                                key={i}
                              >
                                <FormGroupItemForDocuments
                                  key={i}
                                  doc={document}
                                  handleFileInputChange={handleFileInputChange}
                                  onDelete={onDeleteDocument}
                                  isFieldsDisabled={isFieldsDisabled}
                                  form={documentForm}
                                />
                              </Card>
                            );
                          })}
                        </Col>

                        <Col md={12}>
                          <h6 style={{ marginBottom: "3rem" }}>
                            Child Documents
                          </h6>
                          {childDocuments.map((document, i) => {
                            return (
                              <Card
                                hoverable
                                className="documentInputCardItem"
                                key={i}
                              >
                                <FormGroupItemForDocuments
                                  key={i}
                                  doc={document}
                                  handleFileInputChange={handleFileInputChange}
                                  onDelete={onDeleteDocument}
                                  isFieldsDisabled={isFieldsDisabled}
                                  form={documentForm}
                                />
                              </Card>
                            );
                          })}
                        </Col>

                        {additionalDocuments?.length > 0 && (
                          <Col md={12}>
                            <h6>Additional Documents</h6>
                            {additionalDocuments.map((document, i) => {
                              return (
                                <Card
                                  hoverable
                                  className="documentInputCardItem"
                                >
                                  <FormGroupItemForDocuments
                                    key={i}
                                    doc={document}
                                    handleFileInputChange={
                                      handleFileInputChange
                                    }
                                    onDelete={onDeleteDocument}
                                    isFieldsDisabled={isFieldsDisabled}
                                    form={documentForm}
                                  />
                                </Card>
                              );
                            })}
                          </Col>
                        )}
                        <Col md={12}>
                          <Card>
                            <div className={"additionalDocumentsHeading"}>
                              <h6 className={"documentsOwnerHeading"}>
                                Additional Document (If required)
                              </h6>
                              <Button
                                disabled={isFieldsDisabled}
                                type="primary"
                                onClick={() => showAddDocumentModal(true)}
                              >
                                Add New
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      <DocumentsReadOnlyForm application={props.application} />
                    )}
                  </>
                ) : (
                  renderNoDataPlaceholder(loading, "No Documents Submitted")
                )}
              </Form>
            </TabPane>
            <TabPane key={"Students"} tab={<h6>Students</h6>}>
              <br />
              <Row justify="space-between" align={"middle"}>
                <h6>Selected By Parent</h6>
                {props.application?.hasStagePermission && (
                  <Row justify="end">
                    {/* Confirm Button OR Mark Review Completed */}
                    {/* Waiting Acceptance and Fees is Fetched */}

                    {allFieldsApproved && stageStatusCode === "SG440" && (
                      <Button
                        style={{ marginLeft: 10, marginRight: 10 }}
                        type="primary"
                        onClick={fetchFees}
                      >
                        Fetch Fees
                      </Button>
                    )}

                    {feesFetched &&
                      !(
                        fieldsApprovalPending ||
                        stageStatusCode !== "SG440" ||
                        isEditingDiscountValueManually() ||
                        isCustomDistribution !== null
                      ) && (
                        <Button
                          disabled={
                            fieldsApprovalPending ||
                            stageStatusCode !== "SG440" ||
                            isEditingDiscountValueManually() ||
                            isCustomDistribution !== null
                          }
                          type="default"
                          style={{ marginLeft: 10 }}
                          onClick={onChangeStudent}
                        >
                          Change Student
                        </Button>
                      )}

                    {stageStatusCode === "SG440" &&
                      feesFetched &&
                      !discountChangesSaved && (
                        <Button
                          type={"primary"}
                          onClick={updateDiscount}
                          style={{ marginLeft: "20px" }}
                          disabled={
                            isCustomDistribution !== null ||
                            isEditingDiscountValueManually()
                          }
                        >
                          Save
                        </Button>
                      )}

                    {stageStatusCode === "SG440" &&
                      feesFetched &&
                      discountChangesSaved && (
                        <Button
                          type={"primary"}
                          style={{ marginLeft: "20px" }}
                          disabled={
                            (allowChangeDistribution &&
                              !discountChangesSaved) ||
                            isEditingDiscountValueManually() ||
                            isCustomDistribution !== null
                          }
                          onClick={onConfirmApplication}
                        >
                          Confirm
                        </Button>
                      )}
                  </Row>
                )}
              </Row>
              <br />

              <DiscountStudents
                hasStagePermission={props.application?.hasStagePermission}
                data={props.application.students}
                showInstallments={feesFetched}
                isCustom={isCustomDistribution}
                onChangeCustom={setCustomDistribution}
                onCancelCustomDistribution={cancelCustomDistribution}
                renderDistribution={(record, index) => (
                  <Select
                    style={{ width: "100%" }}
                    open={allowChangeDistribution ? undefined : false}
                    value={selectedDistributions[index]}
                    onChange={(value) => changeDistribution(value, index)}
                    onSelect={(value) =>
                      value === 0
                        ? changeDistribution(value, index)
                        : () => null
                    }
                    onClick={confirmChangeDistribuition}
                    // Waiting Acceptance
                    disabled={
                      stageStatusCode !== "SG440" ||
                      !feesFetched ||
                      isCustomDistribution ||
                      !props.application?.hasStagePermission ||
                      isEditingDiscountValueManually()
                    }
                  >
                    {[
                      ..._distributions,
                      {
                        title: "OPEN",
                        id: 0,
                        instalments: new Array(record.invoices?.length),
                      },
                    ].map((el) => (
                      <Option
                        disabled={
                          el.instalments.length !== record.invoices.length
                        }
                        value={el.id}
                      >
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                )}
                discountValue={discountValue} //calculated discount for each eservice
                customTotalDiscount={customDiscount} // total discount for each student
                setCustomTotalDiscount={setCustomDiscount}
                setChangesSaved={setChangesSaved}
                setDiscountValue={setDiscountValue}
                selectedDistributions={selectedDistributions}
                readOnly={stageStatusCode !== "SG440"}
              />
            </TabPane>
            <TabPane tab={<h6>Stages History</h6>} key="logs">
              <ApplicationLogs application={props.application} />
            </TabPane>
          </Tabs>
          <CustomModal
            visibility={addDocumentModal}
            handleCancel={() => showAddDocumentModal(false)}
            handleOk={() => showAddDocumentModal(false)}
            title={<>Additional Document</>}
            modalSmall={true}
          >
            <Form
              layout={"vertical"}
              // initialValues={{ layout: formLayout }}
              size={"middle"}
              form={additionalDocumentForm}
              onFinish={onSubmitAdditionalDocForm}
            >
              <CreateNewDocumentListForm
                // valuesForEdit={editApplicationFields}
                uploading={uploading}
                file={file}
                handelPickFile={onChooseFile}
                documentFor={["parent", "child"]}
                form={additionalDocumentForm}
                loading={loading}
              />
            </Form>
          </CustomModal>

          <CancelApplicationForm
            visibility={isCancelApplicationModalOpen}
            handleCancel={() => setIsCancelApplicationModalOpen(false)}
            onSubmit={(notes) =>
              handelApplicationProcess("cancel", undefined, notes)
            }
            loading={loading}
          />

          <CustomModal
            visibility={showDistributionModal}
            handleCancel={() => setShowDistributionModal(false)}
            handleOk={() => setShowDistributionModal(false)}
            title={"Confirmation"}
            modalSmall={true}
          >
            <p style={{ textAlign: "center", fontSize: 20 }}>
              Are you sure you want to change discount distribution?
            </p>
            <Row
              justify="center"
              style={{
                marginTop: 20,
              }}
            >
              <Button
                type={"primary"}
                onClick={() => {
                  setShowDistributionModal(false);
                  setTimeout(() => setAllowChangeDistribution(true), 200);
                }}
              >
                Yes
              </Button>
              <Button
                type={"primary"}
                onClick={() => setShowDistributionModal(false)}
                style={{ marginLeft: 10 }}
              >
                No
              </Button>
            </Row>
          </CustomModal>

          <CustomModal
            visibility={changeStudentModal}
            handleCancel={hideChangeStudentModal}
            handleOk={hideChangeStudentModal}
            title={"Change Student"}
          >
            <p>
              If you want to select other student, uncheck student from current
              selection
            </p>
            <b>Selected By Parent</b>
            <br />
            <Table
              dataSource={props.application.students}
              pagination={false}
              columns={studentColumns}
            ></Table>

            <br />
            <b>Other Student</b>
            <br />

            <Table
              dataSource={otherStudents}
              pagination={false}
              columns={studentColumns}
            ></Table>
            <Row justify="end" style={{ marginTop: 20 }}>
              <Button
                loading={loading}
                type={"primary"}
                onClick={saveChangeStudent}
              >
                {loading ? "Saving" : "Save"}
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                onClick={hideChangeStudentModal}
              >
                Cancel
              </Button>
            </Row>
          </CustomModal>
        </Card>
        <Card className="antCardBorder antCard" style={{ marginTop: 20 }}>
          <DiscountOtherApplication data={_otherDiscountApplications} />
        </Card>
      </Spin>
    </div>
  );
};

export default DiscountReview;
