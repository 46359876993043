/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import {
  Input,
  Form,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  Select,
  Button,
  Modal,
  DatePicker,
  Image,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../assets/img/default.png";
import checkedImg from "../../assets/img/checked.png";
import uncheckedImg from "../../assets/img/unchecked.png";
import moment from "moment";

import {
  CommentOutlined,
  SyncOutlined,
  FilePdfOutlined,
  EnvironmentOutlined,
  EditOutlined,
} from "@ant-design/icons";
import styles from "../UploadedDocumentListItem/style.module.css";
import { useState } from "react";
import { _getRulesAction } from "actions/discount-rules/DiscountRulesActions";
import { getRulesAPI } from "apis/discount-rules/DiscountRulesApi";
import ASRFormItem from "components/ASRFormItem/ASRFormItem";
import CustomModal from "components/CustomModal/CustomModal";
import { _getSCHApplicationMetaData } from "actions/meta-data/metaDataAction";
const { Option } = Select;

export default function CompactFormItem({
  isFieldsDisabled,
  name,
  formElement,
  index,
  handleFileInputChange,
  imagePreview,
  switchOnChangeForParent,
  onChangeNotes,
  onChangeASRItems,
  approveAllFields,
  changeDob,
  application,
  notRequired,
  discountRules,
  onUpdateEservice,
  revertTermValue,
  educationSystemGrades,
  onChangeStudentCurrentEeducationSystem,
}) {
  const ref = useRef();

  const inputFieldRef = useRef();
  const selectFieldRef = useRef();
  const dispatch = useDispatch();
  const [isSelectDisabled, setSelectDisabled] = useState(false);

  const [showPassportField, setShowPassportField] = useState(false);
  const [imageView, setImageViewer] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [visible, setVisible] = useState(false);
  const [changedTerms, setChangedTerm] = useState({
    old: "",
    new: formElement.value,
  });

  const [showTermConfirmation, setShowTermConfirmation] = useState(false);
  const [newEservice, setNewEservice] = useState();
  const authorizedPersons = [
    { id: "1", title: "Father", value: "father" },
    { id: "2", title: "Mother", value: "mother" },
  ];

  const inputFile = useRef(null);
  const _campuses = useSelector((state) => state.metaData.campuses);
  const choiceOfCampuses = _campuses.filter((campus) =>
    application?.eService?.availableCampuses.includes(campus.id)
  );

  const _terms = useSelector((state) => state.metaData.terms);
  const _eservices = useSelector((state) => state.eservices.data);
  const _countries = useSelector((state) => state.metaData.countries);
  const _allSchools = useSelector((state) => state.metaData.schools);
  const _tccSchools = useSelector((state) => state.metaData.TCC_schools);

  const _rules = discountRules;
  const dropDowns = useSelector((state) => state.metaData.dropDowns);
  const _educationalAllowances = useSelector(
    (state) => state.metaData.educationalAllowances
  );
  const _educationalSystems = useSelector(
    (state) => state.metaData.educationalSystems
  );
  const _scoringSystem = useSelector((state) => state.metaData.scoringSystem);
  const SCH_schools = useSelector((state) => state.metaData.SCH_schools);
  const assessmentExams = useSelector(
    (state) => state.metaData.assessmentExams
  );
  const nonAcademicAchievement = useSelector(
    (state) => state.metaData.nonAcademicAchievement
  );
  const contributionToResearch = useSelector(
    (state) => state.metaData.contributionToResearch
  );
  const socialAndCommunityActivities = useSelector(
    (state) => state.metaData.socialAndCommunityActivities
  );
  const certificatesAndAwards = useSelector(
    (state) => state.metaData.certificatesAndAwards
  );

  const [approveField, setApproveField] = useState(
    formElement.status === "approved" ? true : false
  );
  const [parentMobileNo, setMobileNo] = useState(formElement.value);

  const _grades = useSelector((state) => state.metaData.grades);

  const choiceAvailableGrades = _grades.filter((grade) =>
    application?.eService?.availableGrades.includes(grade.id)
  );

  const _scholarshipTypes = useSelector(
    (state) => state.metaData.dropDowns?.scholarshipTypes
  );

  const [profileImagePreviewState, setProfileImagePreviewState] =
    useState(null);

  const [checkboxChangeStatus, setCheckBocStatus] = useState(false);

  const eservicesTerms = _eservices
    .filter((eservice) => eservice.applicationType === "ENR")
    .map((eservice) => eservice.termId);

  const filteredTerms =
    application.applicationType === "ENR"
      ? _terms.filter((term) => eservicesTerms.includes(term.id))
      : _terms;

  const yesNoTrueFalseList = [
    {
      title: "Yes",
      value: "true",
    },
    {
      title: "No",
      value: "false",
    },
  ];

  if (name === "explanation") {
    formElement.title =
      "How does the student qualify for academic merit or Gifted and Talented?";
  } else if (name === "reasonForApplication") {
    formElement.title = "Why are you applying for the scholarship?";
  }

  useEffect(() => {
    getMapCoordinates();
  }, []);

  useEffect(() => {
    if (approveAllFields === true) {
      setApproveField(true);
      formElement.status = "approved";
      changeStatus(true, name, formElement, ref, inputFieldRef);
    } else if (approveAllFields === false) {
      setApproveField(false);
      formElement.status = "needAmendment";
      changeStatus(false, name, formElement, ref, inputFieldRef);
    }
  }, [approveAllFields]);

  const replaceImage = (e, element) => {
    inputFile.current.click();
  };

  const generateImagePreview = async (e) => {
    var file = e.target.files[0];

    const objectUrl = URL.createObjectURL(file);

    setProfileImagePreviewState(objectUrl);
  };
  const targetImage = (item) => {
    if (!item.includes("http")) {
      setImageViewer("");
      return;
    }
    const path = item.split(".");
    const fileName = path[path.length - 1];
    if (fileName == "pdf") {
      window.open(item);
    } else {
      setImageViewer(item);
    }
  };
  const onChangeASRItem = (valueName, value) => {
    onChangeASRItems(valueName, value, name, formElement);
  };
  const onChangeInnerStatus = (e) => {
    if (e.target.checked) {
      setSelectDisabled(true);
      setCheckBocStatus(false);
    } else {
      setSelectDisabled(false);
      setCheckBocStatus(true);
    }
  };
  const changeCheckedStatus = (name, formElement, ref, inputFieldRef) => {
    let newStatus = false;
    if (formElement.status !== "approved") {
      newStatus = true;
    }
    setApproveField(newStatus);
    changeStatus(newStatus, name, formElement, ref, inputFieldRef);
  };

  const changeStatus = (newStatus, name, formElement, ref, inputFieldRef) => {
    switchOnChangeForParent(newStatus, name, formElement, ref, inputFieldRef);
  };
  const renderFiles = (item) => {
    const path = item.split(".");
    const fileName = path[path.length - 1];
    if (fileName == "pdf") {
      return (
        <div
          onClick={(e) => {
            targetImage(formElement.value);
          }}
        >
          <FilePdfOutlined style={{ fontSize: "205%", color: "red" }} />
        </div>
      );
    } else {
      return (
        <img
          src={
            profileImagePreviewState === null &&
            (formElement.value === "" || !formElement.value.includes("http"))
              ? defaultImg
              : profileImagePreviewState != null
              ? profileImagePreviewState
              : formElement.value
          }
          alt="File"
          onClick={(e) => {
            setVisible(true);
            targetImage(formElement.value);
          }}
        />
      );
    }
  };
  const getMapCoordinates = () => {
    if (formElement.value && name === "mapLocation") {
      let location = formElement.value ? JSON.parse(formElement.value) : null;

      setCoordinates({
        latitude: location.lat,
        longitude: location.lng,
        address: location.formattedAddress,
      });
    }
  };

  const openMap = () => {
    if (coordinates)
      window.open(
        `https://maps.google.com?q=${
          coordinates.latitude + "," + coordinates.longitude
        }`
      );
  };

  const handelChangeStudentCurrentEduSystem = (e) => {
    onChangeStudentCurrentEeducationSystem(e);
  };

  const getFormItemLabel = (title) => {
    if (title === "Assessment exam score")
      return "Assessment Exam - Reading Score";
    if (title === "Assessment exam maths score")
      return "Assessment Exam - Maths Score";
    if (title === "Family Name") return "Family Member Name";
    else if (title === "Family Phone No") return "Family Memeber Phone Number";
    else return title;
  };

  return (
    <div>
      {formElement.valueType == "image" ? (
        <Row
          align="bottom"
          justify="start"
          style={{
            padding:
              application.applicationType === "ENR" ? "1rem 5vw" : "1rem",
          }}
        >
          <div className={styles.uploadedDocumentItemWrapper}>
            <div className={styles.documentItemDescWrapper}>
              <div className={styles.uploadedDocumentThumbnail}>
                <Form.Item name={[name, "value"]}>
                  {renderFiles(formElement.value)}
                </Form.Item>
                <Form.Item
                  style={{ display: "none" }}
                  name={[name, "id"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: `${formElement?.title} is required   `,
                    },
                  ]}
                >
                  <Input
                    ref={inputFieldRef}
                    style={{}}
                    placeholder=""
                    disabled={isFieldsDisabled}
                  />
                </Form.Item>
              </div>
              <div className={styles.documentItemTitle}>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleFileInputChange(e, formElement);
                    generateImagePreview(e);
                  }}
                />
                <p> {formElement?.title ? formElement?.title : "File"}</p>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                {isFieldsDisabled ||
                application?.stage?.status?.code === "SG003" ? null : (
                  <Popconfirm
                    title="Are you sure to replace this document?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={(e) => replaceImage(e)}
                  >
                    <Tooltip title="Replace document">
                      <Button
                        disabled={isFieldsDisabled}
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<SyncOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                )}
              </div>
            </div>
            <div className={styles.uploadedDocumentItemActions}>
              {/* {formElement.status} */}
              <Form.Item name={[name, "status"]}>
                {/* document checkbox */}
                <img
                  style={{
                    maxHeight: "18px",
                    maxWidth: "18px",
                    cursor:
                      isFieldsDisabled ||
                      application?.stage?.status?.code === "SG003"
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={(e) => {
                    if (
                      isFieldsDisabled ||
                      application?.stage?.status?.code === "SG003"
                    ) {
                      return;
                    }
                    changeCheckedStatus(name, formElement, ref, inputFieldRef);
                  }}
                  src={
                    (approveField && formElement.status === "approved") ||
                    formElement.status === "approved"
                      ? checkedImg
                      : uncheckedImg
                  }
                ></img>
                <span style={{ marginLeft: "4px" }}>
                  {formElement.status === "approved" ? "Approved" : "Approve"}
                </span>
              </Form.Item>

              <Form.Item
                name={[name, "note"]}
                rules={[
                  {
                    required:
                      approveField ||
                      formElement.status === "submitted" ||
                      formElement.status === "approved"
                        ? false
                        : true,
                    message: "Note for amendment is required",
                    whitespace: true,
                  },
                ]}
                validateStatus={
                  formElement.status === "approved" ||
                  (formElement.status === "submitted" ? "success" : null)
                }
              >
                {/* <Input placeholder="Add Comment" prefix={<CommentOutlined />} /> */}

                <Input
                  onChange={(e) => onChangeNotes(ref, name, formElement)}
                  disabled={
                    formElement.status === "approved" || isFieldsDisabled
                      ? true
                      : false
                  }
                  ref={ref}
                  placeholder="Add note for amendment"
                  prefix={<CommentOutlined />}
                />
              </Form.Item>
            </div>
          </div>
        </Row>
      ) : (
        <Row align="bottom" className="compactFormItemRow" justify="start">
          <Col className="compactFormItemCol" md={8}>
            {name == "campus" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "Campus type is required",
                    },
                  ]}
                >
                  <Select disabled={true}>
                    {_campuses?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id == formElement.value}
                      >
                        {el.code}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : name == "currentGrade" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Current Grade type is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {_grades?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id == formElement.value}
                    >
                      {el.code}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "motherLanguage" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Preferred communication language is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.languages?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.value}`}
                      selected={el.value == formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                        {/* <p>({el.valueAr})</p> */}
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "dob" ? (
              <Form.Item
                name={[name, "dob"]}
                label={formElement?.title}
                rules={[
                  {
                    required: formElement.value === null ? true : false,
                    message: "Date of birth is required",
                  },
                ]}
              >
                {/* date of birth */}
                <DatePicker
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD"
                  disabled={
                    isFieldsDisabled || formElement.status === "approved"
                      ? true
                      : false
                  }
                  defaultValue={moment(formElement.value, "YYYY-MM-DD")}
                  onChange={(moment, dateStr) => {
                    changeDob(dateStr);
                  }}
                />
              </Form.Item>
            ) : name == "otherLanguages" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={
                  notRequired
                    ? undefined
                    : [
                        {
                          required: true,
                          message: "Languages required",
                        },
                      ]
                }
              >
                <Select
                  mode="multiple"
                  defaultValue={["as", "aaa"]}
                  value={["as", "aaa"]}
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.languages?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.value}`}
                      selected={el.value == formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "newGrade" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "New grade is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {application?.applicationType === "SCH" ? (
                    <>
                      {choiceAvailableGrades?.map((el, i) => (
                        <Option
                          key={i}
                          value={`${el.id}`}
                          selected={el.id == formElement.value}
                        >
                          {el.code}
                        </Option>
                      ))}
                    </>
                  ) : (
                    <>
                      {_grades?.map((el, i) => (
                        <Option
                          key={i}
                          value={`${el.id}`}
                          selected={el.id == formElement.value}
                        >
                          {el.code}
                        </Option>
                      ))}
                    </>
                  )}
                </Select>
              </Form.Item>
            ) : name == "hasHealthIssue" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Health issue is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.yesNoList?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.value?.toLowerCase()}`}
                      selected={el.value?.toLowerCase() === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                        {/* <p>({el.valueAr})</p> */}
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "canRepeatCurrentGrade" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.yesNoList?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.value?.toLowerCase()}`}
                      selected={el.value?.toLowerCase() === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                        {/* <p>({el.valueAr})</p> */}
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "term" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Term is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                  onChange={(value) => {
                    setChangedTerm({
                      old: formElement.value,
                      new: value,
                    });
                    setNewEservice(undefined);
                    setShowTermConfirmation(true);
                  }}
                >
                  {filteredTerms?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id == formElement.value}
                    >
                      {el.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "country" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Nationality is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {_countries?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id == formElement.value}
                    >
                      {el.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "school" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "School is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {application?.applicationType === "TCC" ? (
                    <>
                      {_tccSchools?.map((el, i) => (
                        <Option
                          key={i}
                          value={`${el.id}`}
                          selected={el.id == formElement.value}
                        >
                          {el.abbreviation.toUpperCase()}
                        </Option>
                      ))}
                    </>
                  ) : (
                    <>
                      {_allSchools?.map((el, i) => (
                        <Option
                          key={i}
                          value={`${el.id}`}
                          selected={el.id == formElement.value}
                        >
                          {el.abbreviation.toUpperCase()}
                        </Option>
                      ))}
                    </>
                  )}
                </Select>
              </Form.Item>
            ) : name == "gender" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Gender is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.gender?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.value}`}
                      selected={el.value === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                        {/* <p>({el.valueAr})</p> */}
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "transportationAM" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: `${formElement?.title} is required`,
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.transportationMode?.map((el, i) => (
                    <Option
                      key={el.id}
                      value={`${el.value}`}
                      selected={el.value == formElement.value}
                    >
                      <p>{el.title}</p>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "transportationPM" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: `${formElement?.title} is required`,
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.transportationMode?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.value}`}
                      selected={el.value === formElement.value}
                    >
                      <p>{el.title}</p>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name === "details" ||
              name === "parentNote" ||
              name === "studentNote" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: `${formElement?.title} is required`,
                  },
                ]}
              >
                <Input.TextArea
                  ref={inputFieldRef}
                  disabled={
                    formElement.status === "approved" || isFieldsDisabled
                      ? true
                      : false
                  }
                  placeholder="Enter Details"
                />
              </Form.Item>
            ) : name == "mapLocation" ? (
              <>
                <Form.Item
                  label={"Map Location"}
                  name={[name, "value"]}
                  rules={[
                    {
                      required: true,
                      message: `${formElement?.title} is required`,
                    },
                  ]}
                  style={{ display: "none" }}
                ></Form.Item>
                <Form.Item label={"Map Location"}>
                  <Input
                    size="large"
                    disabled={
                      formElement.status === "approved" || isFieldsDisabled
                    }
                    readOnly
                    value={coordinates?.address || ""}
                    suffix={
                      <EnvironmentOutlined
                        style={{ cursor: "pointer" }}
                        onClick={openMap}
                      />
                    }
                  />
                </Form.Item>
              </>
            ) : name.includes("asr-item") ? (
              <ASRFormItem
                formElement={formElement}
                onChangeItem={onChangeASRItem}
                disabled={formElement.status === "approved" || isFieldsDisabled}
                formItemName={name}
                serviceType={application.eService?.type}
              />
            ) : name === "discountRule" ? (
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                  }
                >
                  {_rules.map((el) => (
                    <Option key={el.id} value={el.id}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "0" ? (
              <>
                <p>Emergency Contact 1</p>
                <Form.Item
                  name={[0, "value", "name"]}
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Contact 1 name is required",
                    },
                  ]}
                >
                  <Input
                    ref={inputFieldRef}
                    style={{}}
                    placeholder=""
                    disabled={
                      formElement.status === "approved" || isFieldsDisabled
                        ? true
                        : false
                    }
                  />
                </Form.Item>

                <Form.Item
                  name={[0, "value", "relation"]}
                  label="Relation"
                  rules={[
                    {
                      required: true,
                      message: "Contact 1 relation is required",
                    },
                  ]}
                >
                  <Select
                    ref={inputFieldRef}
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                    placeholder=""
                  >
                    {dropDowns.relations?.map((el, i) => (
                      <Option value={el.value} key={i}>
                        <Row justify="space-between">
                          <p>{el.title}</p>
                          {/* <p>({el.valueAr})</p> */}
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={[0, "value", "phoneNo"]}
                  label="Phone No"
                  rules={[
                    {
                      required: true,
                      message: "Contact 1 phone number is required",
                    },
                  ]}
                >
                  <Input
                    ref={inputFieldRef}
                    style={{}}
                    placeholder=""
                    disabled={
                      formElement.status === "approved" || isFieldsDisabled
                        ? true
                        : false
                    }
                  />
                </Form.Item>
              </>
            ) : name == "1" ? (
              <>
                <p>Emergency Contact 2</p>
                <Form.Item
                  name={[1, "value", "name"]}
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Contact 2 name is required",
                    },
                  ]}
                >
                  <Input
                    style={{}}
                    placeholder=""
                    ref={inputFieldRef}
                    disabled={
                      formElement.status === "approved" || isFieldsDisabled
                        ? true
                        : false
                    }
                  />
                </Form.Item>

                <Form.Item
                  name={[1, "value", "relation"]}
                  label="Relation"
                  rules={[
                    {
                      required: true,
                      message: "Contact 2 relation is required",
                    },
                  ]}
                >
                  <Select
                    ref={inputFieldRef}
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                    placeholder=""
                  >
                    {dropDowns.relations?.map((el, i) => (
                      <Option value={el.value} key={i}>
                        <Row justify="space-between">
                          <p>{el.title}</p>
                          {/* <p>({el.valueAr})</p> */}
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={[1, "value", "phoneNo"]}
                  label="Phone No"
                  rules={[
                    {
                      required: true,
                      message: "Contact 2 phone number is required",
                    },
                  ]}
                >
                  <Input
                    ref={inputFieldRef}
                    style={{}}
                    disabled={
                      formElement.status === "approved" || isFieldsDisabled
                        ? true
                        : false
                    }
                    placeholder=""
                  />
                </Form.Item>
              </>
            ) : name === "contactPriority" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Contact priority  is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.contactPriority?.map((el, i) => {
                    return (
                      <Option
                        key={i}
                        value={el.value}
                        selected={el.value == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.title}</p>
                          {/* <p>({el.valueAr})</p> */}
                        </Row>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            ) : name === "qualification" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Qualification is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.qualification?.map((el, i) => (
                    <Option
                      key={i}
                      value={el.value}
                      selected={el.value === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                        {/* <p>({el.valueAr})</p> */}
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name === "religion" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Religion is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {dropDowns.religion?.map((el, i) => (
                    <Option
                      key={i}
                      value={el.value}
                      selected={el.value === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                        {/* <p>({el.valueAr})</p> */}
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name === "phoneNo" ? (
              <div>
                <Form.Item
                  name={[name, "value"]}
                  rules={[
                    {
                      whitespace: true,
                      message: "Please enter a valid mobile number",
                    },
                  ]}
                  label={formElement?.title}
                >
                  <Input
                    ref={inputFieldRef}
                    style={{}}
                    disabled={
                      formElement.status === "approved" || isFieldsDisabled
                        ? true
                        : false
                    }
                    placeholder=""
                    onChange={(e) => setMobileNo(e.target.value)}
                  />
                </Form.Item>
              </div>
            ) : name === "isUAEResident" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "Residency is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                    onSelect={(e) => setShowPassportField(e)}
                  >
                    {yesNoTrueFalseList?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.value?.toLowerCase()}`}
                        selected={el.value?.toLowerCase() === formElement.value}
                        onChange={() => setShowPassportField(el.value)}
                      >
                        <Row justify="space-between">
                          <p>{el.title}</p>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : name === "firstChoiceCampus" ||
              name === "secondChoiceCampus" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "First choice campus is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                  >
                    {choiceOfCampuses?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.code}</p>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : name === "enrolledInENS" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "Student enrolled is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                  >
                    {yesNoTrueFalseList.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.value}`}
                        selected={el.value?.toLowerCase() == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.title}</p>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : name === "educationSystem" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "First choice campus is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handelChangeStudentCurrentEduSystem(e);
                    }}
                  >
                    {_educationalSystems?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.title}</p>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : name === "schoolInUAE" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "schoolInUAE is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                  >
                    {yesNoTrueFalseList.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.value?.toLowerCase()}`}
                        selected={el.value?.toLowerCase() === formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.title}</p>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : name === "educationalAllowance" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "EducationalAllowance is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                  >
                    {_educationalAllowances?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.title}</p>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : name == "currentSchool" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Current School is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {SCH_schools?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name == "assessmentExam" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Assessment Exam is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {assessmentExams?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name === "nonAcademicAchievement" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Assessment Exam is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {nonAcademicAchievement?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name === "contributionToResearch" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Assessment Exam is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {contributionToResearch?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name === "socialAndCommunityActivities" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Assessment Exam is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {socialAndCommunityActivities?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name === "certificatesAndAwards" ? (
              <Form.Item
                name={[name, "value"]}
                label={formElement?.title}
                rules={[
                  {
                    required: true,
                    message: "Assessment Exam is required",
                  },
                ]}
              >
                <Select
                  disabled={
                    isFieldsDisabled ||
                    isSelectDisabled ||
                    formElement.status === "approved"
                      ? true
                      : false
                  }
                >
                  {certificatesAndAwards?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : name === "scoringSystem" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "EducationalAllowance is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                  >
                    {_scoringSystem?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.title}</p>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : name === "gradeLevel" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={"Current Grade"}
                  rules={[
                    {
                      required: true,
                      message: "Grade level is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                  >
                    {educationSystemGrades.map((el, i) => {
                      return (
                        <Option
                          key={i}
                          value={`${el.id}`}
                          selected={el.id.toString() == formElement.value}
                        >
                          <Row justify="space-between">
                            <p>{el.gradeCode}</p>
                          </Row>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            ) : name === "scholarshipType" ? (
              <>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "EducationalAllowance is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                  >
                    {_scholarshipTypes?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.title}</p>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : name === "authorizedParent" ? (
              <>
                <br />
                <p style={{ fontSize: 16, fontWeight: "500" }}>
                  Authorized Person
                </p>
                <Form.Item
                  name={[name, "value"]}
                  label={formElement?.title}
                  rules={[
                    {
                      required: true,
                      message: "parentName is required",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      isFieldsDisabled ||
                      isSelectDisabled ||
                      formElement.status === "approved"
                        ? true
                        : false
                    }
                  >
                    {authorizedPersons?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.value}`}
                        selected={el.value == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.title}</p>
                        </Row>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : (
              <>
                {/* Normal textfield */}

                <Form.Item
                  name={[name, "value"]}
                  label={
                    getFormItemLabel(formElement?.title) +
                    (application?.applicationType === "UPD" &&
                    formElement?.valueMeta?.oldValue
                      ? " (" + formElement?.valueMeta?.oldValue + ")"
                      : "")
                  }
                  rules={
                    (name === "passportNumber" &&
                      (application?.father?.isUAEResident?.value === "true" ||
                        application?.mother?.isUAEResident?.value ===
                          "true")) ||
                    (name === "schoolName" &&
                      application?.criteria?.schoolInUAE?.value === "true") ||
                    (name === "emiratesId" &&
                      (application?.father?.isUAEResident?.value === "false" ||
                        application?.mother?.isUAEResident?.value === "false" ||
                        application?.studentDetails?.isUAEResident?.value ===
                          "false"))
                      ? false
                      : notRequired
                      ? undefined
                      : [
                          {
                            required: true,
                            message: `${formElement?.title} is required`,
                            whitespace: true,
                          },
                        ]
                  }
                >
                  <Input
                    ref={inputFieldRef}
                    style={{}}
                    disabled={
                      formElement.status === "approved" || isFieldsDisabled
                        ? true
                        : false
                    }
                    placeholder=""
                  />
                </Form.Item>
              </>
            )}
          </Col>

          <Form.Item
            style={{ display: "none" }}
            name={[name, "id"]}
            label={formElement?.title}
            rules={[
              {
                required: true,
                message: `${formElement?.title} is required   `,
              },
            ]}
          >
            <Input style={{}} placeholder="" />
          </Form.Item>

          <Form.Item
            style={{ display: "none" }}
            name={[name, "title"]}
            label={formElement?.title}
            rules={[
              {
                required: true,
                message: `${formElement?.title} is required   `,
              },
            ]}
          >
            <Input style={{}} placeholder="" />
          </Form.Item>

          <Col className="compactFormItemCol" md={5}>
            <Form.Item
              name={[name, "status"]}
              wrapperCol={{ offset: 8, span: 16 }}
            >
              {/* for input fields checkbox */}

              <img
                style={{
                  maxHeight: "18px",
                  maxWidth: "18px",
                  cursor:
                    isFieldsDisabled ||
                    application?.stage?.status?.code === "SG003"
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={(e) => {
                  if (
                    isFieldsDisabled ||
                    application?.stage?.status?.code === "SG003"
                  ) {
                    return;
                  }
                  changeCheckedStatus(name, formElement, ref, inputFieldRef);
                }}
                src={
                  (approveField && formElement.status === "approved") ||
                  formElement.status === "approved" ||
                  approveAllFields
                    ? checkedImg
                    : uncheckedImg
                }
              ></img>
              <span style={{ marginLeft: "4px" }}>
                {formElement.status === "approved" || approveAllFields
                  ? "Approved"
                  : "Approve"}
              </span>
            </Form.Item>
          </Col>
          <Col className="compactFormItemCol" md={8}>
            {/* {formElement.status} */}
            <Form.Item
              name={[name, "note"]}
              rules={[
                {
                  required:
                    approveField ||
                    formElement.status === "submitted" ||
                    formElement.status === "approved"
                      ? false
                      : true,
                  message: "Note for amendment is required",
                  whitespace: true,
                },
              ]}
            >
              <Input
                disabled={
                  formElement.status === "approved" ||
                  isFieldsDisabled ||
                  name == "campus"
                    ? true
                    : false
                }
                ref={ref}
                placeholder="Add note for amendment"
                prefix={<CommentOutlined />}
                onChange={(e) => onChangeNotes(ref, name, formElement)}
              />
            </Form.Item>
          </Col>
          {parentMobileNo == "" && name === "phoneNo" && (
            <Col md={8}>
              <Form.Item
                label={"Reason mobile number not available"}
                name={[name, "notAvailableReason"]}
                rules={[
                  {
                    required: true,
                    message: "Reason for phone no. not available is required",
                  },
                ]}
              >
                <Select
                  placeholder={"Select reason for phone no. not available"}
                  value={
                    formElement.notAvailableReason === 0
                      ? ""
                      : formElement.notAvailableReason
                  }
                  defaultValue={""}
                >
                  <Option key={0} value={0}>
                    {"None"}
                  </Option>
                  {dropDowns.mobileNoNotAvailable?.map((el) => (
                    <Option key={el.id} value={el.id}>
                      {el.id === 0 ? "" : el.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      )}

      <Modal
        className="image-previewer-modal"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        getContainer={false}
      >
        {imageView ? (
          <img src={imageView} width={"100%"} height={"100%"} alt="file" />
        ) : (
          <img src={defaultImg} width={"100%"} height={"100%"} alt="file" />
        )}
      </Modal>
      <CustomModal
        visibility={showTermConfirmation}
        handleCancel={() => {
          revertTermValue(changedTerms.old);
          setShowTermConfirmation(false);
          onUpdateEservice(undefined);
          setNewEservice(undefined);
        }}
        title={"Confirm Term Update"}
      >
        <b>Are you sure you want to update term?</b>
        <p>
          The eservice for this application will update as well, please select
          the new eservice
        </p>
        <Select
          style={{ width: "100%" }}
          onChange={(value) => {
            setNewEservice(value);
            onUpdateEservice(value);
          }}
          placeholder={"Select Eservice"}
          value={newEservice}
        >
          {_eservices.map((eservice) =>
            eservice.termId.toString() === changedTerms.new &&
            eservice.applicationType === "ENR" ? (
              <Option value={eservice.id} key={eservice.id}>
                {eservice.name}
              </Option>
            ) : null
          )}
        </Select>
        <Row gutter={[10]} justify={"end"} style={{ marginTop: 20 }}>
          <Col>
            <Button
              onClick={() => {
                setShowTermConfirmation(false);
              }}
              disabled={newEservice === undefined || newEservice === null}
            >
              Yes, Confirm
            </Button>
          </Col>
          <Button
            onClick={() => {
              revertTermValue(changedTerms.old);
              setShowTermConfirmation(false);
              onUpdateEservice(undefined);
              setNewEservice(undefined);
            }}
          >
            Cancel
          </Button>
        </Row>
      </CustomModal>
    </div>
  );
}
