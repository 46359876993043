import client from "apis/config";

export const fetchAllEventTypes = async ({
  limit = 10,
  type = "activity_type",
  page,
}) => {
  return await client.get(
    `/facility-data?type=${type}&limit=${limit}&page=${page}`
  );
};

export const addEventType = async ({ data }) => {
  return await client.post("/facility-data", data);
};

export const updateEventType = async ({ id, data }) => {
  return await client.put(`/facility-data/${id}`, data);
};

export const deleteEventType = async ({ id }) => {
  return await client.delete(`/facility-data/${id}`);
};
