import React from "react";
import CommonTable from "components/Tables/CommonTable";
import { renderFeesUnits } from "utils/helpers/helpers";
import { Capitalize } from "utils/helpers/helpers";
import {
  Col,
  Input,
  Row,
  Table,
  Tooltip,
  Form,
  Button,
  InputNumber,
} from "antd";
import { showValidationError } from "utils/helpers/helpers";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { calculateRemainingFees } from "utils/helpers/helpers";
import { getSumOfFees } from "utils/helpers/helpers";
import { roundToTwo } from "utils/helpers/helpers";

const DiscountStudents = (props) => {
  const [freeDistributionForm] = Form.useForm();

  const {
    data,
    renderDistribution,
    discountValue,
    setDiscountValue,
    readOnly,
    isCustom,
    onChangeCustom,
    onCancelCustomDistribution,
    showInstallments = true,
    customTotalDiscount, // [{value: 0, studentIndex: index, show: false}]
    setCustomTotalDiscount,
    setChangesSaved,
    selectedDistributions,
    hasStagePermission,
  } = props;

  const onClickTotalDiscount = (record, studentIndex) => (e) => {
    if (!readOnly) {
      if (e.detail === 3) {
        if (record.invoices?.length) {
          let tempCustomDiscount = [...customTotalDiscount];
          tempCustomDiscount[studentIndex].show = true;
          setCustomTotalDiscount(tempCustomDiscount);
        } else {
          showValidationError(
            "Please fetch fees before updating the discount value"
          );
        }
      }
    }
    return null;
  };

  const onDiscardCustomDiscount = (index) => () => {
    let updatedItem = customTotalDiscount[index];
    updatedItem.show = false;
    updatedItem.value = null;
    let updatedArray = [...customTotalDiscount];
    updatedArray[index] = updatedItem;
    setCustomTotalDiscount(updatedArray);
  };
  const onDoneCustomDiscount = (index, record) => () => {
    let updatedItem = customTotalDiscount[index];
    if (updatedItem.value > -1) {
      const sumOfFees = getSumOfFees(record.invoices);
      if (updatedItem.value > sumOfFees) {
        showValidationError(
          `Total discount should not be greater than the total fees: ${sumOfFees} AED `
        );
        return;
      }

      updatedItem.show = false;
      let updatedArray = [...customTotalDiscount];
      updatedArray[index] = updatedItem;
      setCustomTotalDiscount(updatedArray);
      setChangesSaved(false);
      if (selectedDistributions[index] === 0) {
        onChangeCustom(index);
      }
    } else {
      showValidationError("Please enter a valid discount value");
    }
  };

  const onChangeCustomDiscount = (index) => (e) => {
    let inputValue = e.target.value;
    const reg = /^\d+$/;

    if (reg.test(inputValue) || inputValue === "") {
      let updatedItem = customTotalDiscount[index];
      updatedItem.value = Number(inputValue);
      let updatedArray = [...customTotalDiscount];
      updatedArray[index] = updatedItem;
      setCustomTotalDiscount(updatedArray);
    }
  };

  const onSubmitFreeDist = (values) => {
    const totalFees = getSumOfFees(data[isCustom].invoices);
    let discounts = Object.values(values);
    discounts = discounts.map((d) => Number(d));
    let discountSum = discounts.reduce((a, b) => a + b);
    let totalDiscount =
      customTotalDiscount[isCustom].value ?? data[isCustom].discountValue;
    let discountType = data[isCustom]?.discountType;
    if (discountType === "percentage") {
      totalDiscount = (totalDiscount * totalFees) / 100;
    }
    if (totalDiscount === discountSum) {
      let updatedDiscount = [...discountValue];
      updatedDiscount[isCustom] = discounts;
      setDiscountValue(updatedDiscount);
      onChangeCustom(null);
    } else {
      showValidationError(
        "The total of all values should be equal to:" +
          ` ${totalDiscount} ${renderFeesUnits("fixed")}`
      );
    }
  };
  const getDiscountValue = (el, installmentIndex, studentIndex) => {
    return readOnly
      ? el.discount
      : `${
          discountValue && discountValue.length == data.length
            ? discountValue[studentIndex][installmentIndex]
            : 0
        }`;
  };
  const getTotalDiscount = (record, index, requiredType) => {
    const totalFees = getSumOfFees(record.invoices);
    const value =
      !readOnly && customTotalDiscount.length > 0
        ? customTotalDiscount[index]?.value ?? record.discountValue
        : record.discountValue;
    if (requiredType === record.discountType) {
      return customTotalDiscount?.length > 0 &&
        customTotalDiscount[index]?.show ? (
        <Input
          placeholder="Type new discount value"
          value={customTotalDiscount[index].value}
          onChange={onChangeCustomDiscount(index)}
          suffix={
            <Row gutter={2} align={"middle"}>
              <Col>
                <Tooltip title={"Done"}>
                  <CheckCircleOutlined
                    onClick={onDoneCustomDiscount(index, record)}
                    style={{ fontSize: 20 }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={"Discard"}>
                  <CloseCircleOutlined
                    onClick={onDiscardCustomDiscount(index)}
                    style={{ fontSize: 20 }}
                  />
                </Tooltip>
              </Col>
            </Row>
          }
        />
      ) : hasStagePermission ? (
        <Tooltip
          title={
            readOnly ? "" : "Triple click here to edit discount value manually"
          }
        >
          <div onClick={onClickTotalDiscount(record, index)}>
            {value}
            {requiredType === "percentage" ? "%" : " AED"}
          </div>
        </Tooltip>
      ) : (
        <div>
          {value}
          {requiredType === "percentage" ? "%" : " AED"}
        </div>
      );
    } else {
      if (requiredType === "percentage") {
        const discountPecent = roundToTwo((value / totalFees) * 100);
        return isNaN(discountPecent) ? "N/A" : discountPecent;
      } else {
        const discountFixed = roundToTwo((value * totalFees) / 100);
        return isNaN(discountFixed) ? "N/A" : discountFixed;
      }
    }
  };
  const getDiscountPercentage = (
    invoices,
    invoiceItem,
    installmentIndex,
    studentIndex
  ) => {
    const discount = getDiscountValue(
      invoiceItem,
      installmentIndex,
      studentIndex
    );
    let percentage = roundToTwo((discount / invoiceItem.amount) * 100);

    return isNaN(percentage) ? "0" : percentage;
  };
  const expandedRowRender = (record, studentIndex) => {
    if (!showInstallments)
      return <center>Please fetch fees to see the invoices.</center>;
    return record.invoices ? (
      <Form
        name={"free-distribution"}
        form={freeDistributionForm}
        layout={"vertical"}
        onFinish={onSubmitFreeDist}
      >
        <Table
          columns={[
            {
              title: "Service ID",
              dataIndex: "serviceId",
              key: "serviceId",
            },
            {
              title: "Title",
              dataIndex: "serviceName",
              key: "serviceId",
            },
            {
              title: "Fee Value",
              dataIndex: "amount",
              render: (record) => `${record} AED`,
              key: "serviceId",
            },
            {
              title: "Discount (AED)",
              key: "serviceId",
              render: (_, el, installmentIndex) =>
                isCustom == studentIndex ? (
                  <Form.Item
                    rules={[
                      { required: true, message: "Discount value is required" },
                      () => ({
                        validator(_, value) {
                          if (value > -1) {
                            return value > el.amount
                              ? Promise.reject(
                                  "Discount can not be greater than the fees amount"
                                )
                              : Promise.resolve();
                          } else {
                            return Promise.reject(
                              "Discount value can not be less than 0"
                            );
                          }
                        },
                      }),
                    ]}
                    label={``}
                    name={`i${installmentIndex}`}
                  >
                    <InputNumber controls={false} />
                  </Form.Item>
                ) : (
                  getDiscountValue(el, installmentIndex, studentIndex) +
                  renderFeesUnits("fixed")
                ),
            },
            {
              title: "Discount (%)",
              key: "serviceId",
              render: (_, el, installmentIndex) =>
                isCustom == studentIndex
                  ? "N/A"
                  : getDiscountPercentage(
                      record.invoices,
                      el,
                      installmentIndex,
                      studentIndex
                    ) + renderFeesUnits("percentage"),
            },
            {
              title: "Remaining",
              key: "serviceId",
              render: (_, el, installmentIndex) => {
                return isCustom == studentIndex
                  ? "N/A"
                  : calculateRemainingFees(
                      el,
                      studentIndex,
                      installmentIndex,
                      record.discountType,
                      discountValue,
                      readOnly,
                      data
                    ) + " AED";
              },
            },
          ]}
          footer={() => {
            return isCustom == studentIndex ? (
              <Row justify={"end"}>
                <Button type="primary" htmlType="submit">
                  Done
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={onCancelCustomDistribution}
                >
                  Cancel
                </Button>
              </Row>
            ) : null;
          }}
          dataSource={record.invoices}
          pagination={false}
          summary={() => {
            let totalFees = 0;
            let totalDiscount = 0;

            record.invoices.forEach((item, index) => {
              totalFees = totalFees + parseFloat(item.amount);
              totalDiscount =
                totalDiscount +
                parseFloat(getDiscountValue(item, index, studentIndex));
            });
            let totalRemaining = totalFees - totalDiscount;

            return (
              <>
                <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                  <p>
                    <strong>Total Fees: </strong>
                    {totalFees} AED
                  </p>
                </div>
                <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                  <p>
                    <strong>Remaining: </strong>
                    {totalRemaining} AED
                  </p>
                </div>
              </>
            );
          }}
        />
      </Form>
    ) : null;
  };

  return (
    <div>
      <CommonTable
        columns={[
          {
            title: "Student",
            dataIndex: "name",
          },
          {
            title: "Campus",
            dataIndex: "campus",
          },
          {
            title: "School",
            dataIndex: "school",
          },
          {
            title: "Grade",
            dataIndex: "grade",
          },
          {
            title: "Value (AED)",
            render: (_, record, index) =>
              getTotalDiscount(record, index, "fixed"),
          },
          {
            title: "Value (%)",
            render: (_, record, index) =>
              getTotalDiscount(record, index, "percentage"),
          },
          {
            title: "Discount Type",
            dataIndex: "discountType",
            render: (record) => Capitalize(record),
          },
          {
            title: "Distribution",
            dataIndex: "distributionId",
            render: (_, record, index) => renderDistribution(record, index),
          },
        ]}
        data={data}
        expandable={{
          expandedRowRender,
          defaultExpandAllRows: true,
          expandRowByClick: false,
          showExpandColumn: false,
        }}
      ></CommonTable>
    </div>
  );
};

export default DiscountStudents;
