import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { updateDropdownConfigurationAction } from "actions/dropdown-configuration/DropdownConfigurationActions";
import { deleteDropdownConfigurationAction } from "actions/dropdown-configuration/DropdownConfigurationActions";
import { getDropdownConfigurationAction } from "actions/dropdown-configuration/DropdownConfigurationActions";
import {
  Button,
  // Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { addDropdownCnfigurationApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import { deleteDropdownCnfigurationApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import { updateDropdownCnfigurationApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import { getDropdownGroupsApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import { getDropdownCnfigurationApi } from "apis/dropdown-configuration/DropdownConfigurationApi";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { DropdownGroupData } from "utils/constants";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel, Capitalize } from "utils/helpers/helpers";

const { Option } = Select;

const DropdownConfigurations = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [editId, setEditId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [dropdownGroupData, setDropdownGroupData] = React.useState([]);
  const [group, setGroup] = React.useState("");
  const [dropdownForm] = Form.useForm();
  const dispatch = useDispatch();

  const _allDropdowns = useSelector((state) => state.dropdownData.dropdowns);
  const _dropdDownMetaData = useSelector((state) => state.metaData.dropDowns);
  const filterList = _dropdDownMetaData ? Object.keys(_dropdDownMetaData) : [];

  const fetchGroups = async () => {
    try {
      setLoading(true);
      const response = await getDropdownGroupsApi();
      setDropdownGroupData(response.data.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    getAllDropdownConfigurations();
  }, [group]);

  const getAllDropdownConfigurations = async () => {
    try {
      setLoading(true);
      const queryParams = `group=${group}`;
      const response = await getDropdownCnfigurationApi(queryParams);
      if (response.status === 200) {
        setLoading(false);
        dispatch(getDropdownConfigurationAction(response.data.data));
      } else throw response;
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addDropdownConfigurations = async (data) => {
    try {
      setLoading(true);
      const response = await addDropdownCnfigurationApi(data);
      if (response.status === 200) {
        setLoading(false);
        // dispatch(addDropdownConfigurationAction(response.data.data));
        getAllDropdownConfigurations();
        setShowModal(false);
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
      setShowModal(false);
    }
  };
  const updateDropdownConfigurations = async (data, id) => {
    try {
      setLoading(true);
      const response = await updateDropdownCnfigurationApi(id, data);
      if (response.status === 200) {
        dispatch(updateDropdownConfigurationAction(response.data.data));
        setShowModal(false);
        setLoading(false);
      } else throw response;
    } catch (e) {
      requestErrorHandel({ error: e });
      setShowModal(false);
      setLoading(false);
    }
  };
  const deleteDropdownConfigurations = async (id) => {
    try {
      setLoading(true);
      const response = await deleteDropdownCnfigurationApi(id);
      if (response.status === 200) {
        dispatch(deleteDropdownConfigurationAction(id));
        setLoading(false);
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const onSubmit = (values) => {
    // set editId as "" when add
    // set editId some value when update
    if (editId !== "") {
      updateDropdownConfigurations(values, editId);
    } else {
      addDropdownConfigurations(values);
    }
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          data={_allDropdowns}
          header={() => (
            <div>
              <TableHeader
                headerTitle={"Application Dropdowns Content"}
                headerSubTitle={
                  "Manage content for applications dropdown fields"
                }
                headerBtnTitle="Add New"
                onAddNew={() => {
                  setShowModal(true);
                  setEditId("");
                  dropdownForm.resetFields();
                }}
              />
              <Select
                className="dropdown"
                placeholder="Filter By Group"
                bordered={false}
                onChange={(value) => {
                  setGroup(value);
                }}
                style={{ minWidth: "25%" }}
              >
                <Option value={""}>All</Option>
                {dropdownGroupData?.map((el, i) => (
                  <Option key={el.id} value={el.value}>
                    {Capitalize(el.value)}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          columns={[
            {
              key: "id",
              title: "#",
              dataIndex: "id",
              render: (_, __, index) => index + 1,
            },
            {
              key: "group",
              title: "Group",
              dataIndex: "group",
              render: (item) => Capitalize(item),
            },
            {
              key: "title",
              title: "Title (EN)",
              dataIndex: "title",
            },
            {
              key: "titleAr",
              title: "Title (AR)",
              dataIndex: "titleAr",
            },
            {
              key: "value",
              title: "Value",
              dataIndex: "value",
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setShowModal(true);
                          setEditId(item.id);
                          dropdownForm.setFieldsValue(item);
                        }}
                      />
                    </Tooltip>
                  </div>

                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => deleteDropdownConfigurations(item.id)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
          hidePagination
        />
        <CustomModal
          visibility={showModal}
          handleCancel={() => {
            setShowModal(false);
          }}
          title={
            editId !== "" ? "Update Dropdown Content" : "Add Dropdown Content"
          }
        >
          <Form
            form={dropdownForm}
            layout="vertical"
            size="large"
            onFinish={onSubmit}
          >
            <Form.Item
              label={"Select Group"}
              name={"group"}
              rules={[
                {
                  required: true,
                  message: "Group name is required",
                },
              ]}
            >
              <Select placeholder={"Select dropdown group"}>
                {dropdownGroupData.map((el) => (
                  <Option key={el.id} value={el.value}>
                    {Capitalize(el.value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={[
                {
                  required: true,
                  message: "Title is required",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Enter title"></Input>
            </Form.Item>

            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={[
                {
                  required: true,
                  message: "Title is required",
                  whitespace: true,
                },
              ]}
            >
              <Input dir="rtl" placeholder="Enter title"></Input>
            </Form.Item>

            <Form.Item
              label={"Value"}
              name={"value"}
              rules={[
                {
                  required: true,
                  message: "Value is required",
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Enter option/value"></Input>
            </Form.Item>

            {/* <Form.Item
              label={"Value (AR)"}
              name={"valueAr"}
              rules={[
                {
                  required: true,
                  message: "Value (Arabic) is required",
                },
              ]}
            >
              <Input
                dir="rtl"
                placeholder="Enter option/value in Arabic"
              ></Input>
            </Form.Item> */}

            <Form.Item label={"Description (EN)"} name={"description"}>
              <Input placeholder="Description"></Input>
            </Form.Item>

            <Form.Item label={"Description (AR)"} name={"descriptionAr"}>
              <Input dir="rtl" placeholder="Description in Arabic"></Input>
            </Form.Item>

            <Row justify="end">
              <Button htmlType="submit">Save</Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default DropdownConfigurations;
