import Dashboard from "views/dashboard/Dashboard.jsx";
import UserList from "views/screens/user-list/UserList.jsx";
import DocumentsConfiguration from "views/screens/documents-configuration/DocumentsConfiguration.jsx";
import Logs from "views/screens/logs/Logs.jsx";
import AdmissionStatuses from "../views/statuses-tables/admission-statuses/AdmissionStatuses.jsx";
import trigerNotification from "../assets/img/sibar/trigerNotification.png";
import NotificationsTriggers from "views/notifications-triggers/NotificationsTriggers.jsx";
import Services from "views/screens/services/Services.jsx";
import IFrameCashierScreen from "views/screens/iframe_cashier/IFrameCashierScreen.jsx";
import Notifications from "views/notifications/Notifications.jsx";
import EServicesConfiguration from "views/screens/eServicesConfiguration/EServicesConfiguration.jsx";
import StagesList from "views/screens/stages-configuration/StagesList.jsx";
import StageConfig from "views/screens/stages-configuration/StageConfig.jsx";
import TermsAndConditions from "views/screens/terms-conditions/TermsAndConditions.jsx";
import Guidelines from "views/screens/guidelines/Guidelines.jsx";
import AllApplicationsRoutes from "./AllApplicationsRoutes.js";
import DiscountRules from "views/screens/discount-rules/DiscountRules.jsx";
import DiscountRuleConfiguration from "views/screens/discount-rules/DiscountRuleConfiguration.jsx";

import documentConfigIcon from "../assets/img/sibar/documentConfigIcon.png";
import admissionStatusIcon from "../assets/img/sibar/admissionStatusIcon.png";
import dashboardIcon from "../assets/img/sibar/dashboardIcon.png";
import generalCnfigIcon from "../assets/img/sibar/generalCnfigIcon.png";
import cashiericon from "../assets/img/sibar/cashiericon.png";
import eservicesicon from "../assets/img/sibar/eservicesicon.png";
import usersIcon from "../assets/img/sibar/usersIcon.png";
import syncIcon from "../assets/img/fsm/fsmMangement.png";
import notificationIcon from "../assets/img/sibar/ens-notification-icon.svg";
import asrIcon from "../assets/img/sibar/ens-asr-icon.svg";
import AdministrationRoutes from "./AdministrationRoutes.js";
import DiscountDistribution from "views/screens/discount-distributions/DiscountDistributions.jsx";
import CMSRoutes from "./CMSRoutes.js";
import ScholarshipRewards from "views/screens/scholarship/rewards-config/ScholarshipRewards.jsx";
import FinancialStatus from "views/screens/scholarship/evaluation/FinancialStatus.jsx";
import EducationSystems from "views/screens/scholarship/evaluation/EducationSystems.jsx";
import SchoolRating from "views/screens/scholarship/evaluation/SchoolRating.jsx";
import EducationalAllowance from "views/screens/scholarship/evaluation/EducationalAllowance.jsx";
import ScoringSystems from "views/screens/scholarship/evaluation/ScoringSystems.jsx";
import NonAcademicAchievement from "views/screens/scholarship/evaluation/NoneAcademicAchievement.jsx";

import EvaluationPointsConfig from "views/screens/evaluation-points-config/EvaluationPointsConfig.jsx";
import TeachersConfig from "views/screens/tcc/teachers-config/TeachersConfig.js";
import CoursesConfig from "views/screens/tcc/courses-config/CoursesConfig.js";
import ScheduleConfig from "views/screens/tcc/schedule-config/ScheduleConfig.js";
import HtmlTemplatesRoutes from "./HtmlTemplateRoutes.js";
import TCCSectionConfig from "views/screens/tcc/sections-config/TCCSectionConfig.jsx";
import ASRServiceItems from "views/screens/asr_service_items/ASRServiceItems.jsx";
import BusesCMS from "views/screens/buses-crud/BusesCMS.jsx";
import fsmRoutes from "./FSM/fsmRoutes.js";
import sccRoutes from "./SCC/sccRoutes.js";
import CatMap from "views/screens/scholarship/evaluation/CatMap.jsx";
import PersonalInterview from "views/screens/scholarship/evaluation/PersonalInterview.jsx";
import ResearchContribution from "views/screens/scholarship/evaluation/ResearchContribution.jsx";
import CertificatesAndAwards from "views/screens/scholarship/evaluation/CertificatesAndAwards.jsx";
import CommunityParticipation from "views/screens/scholarship/evaluation/CommunityParticipation.jsx";
import Transactions from "views/screens/transactions/Transactions.jsx";
import SyncJobs from "views/screens/sync-jobs/SyncJobsList.jsx";
import SyncJobDetails from "views/screens/sync-jobs/SyncJobDetails.jsx";
import DocumentValidatorRoutes from "./DocumentValidatorRoutes.js";
import VerifyPinPage from "views/Document Validator/QRGenerator/VerifyPin.jsx";

const AdminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: dashboardIcon,
    component: Dashboard,
    layout: "/admin",
    subPath: false,
  },

  ...AllApplicationsRoutes,
  ...fsmRoutes,
  ...sccRoutes,
  ...DocumentValidatorRoutes,

  // ----------request type options --------

  {
    path: "/e-services",
    name: "E-Services",
    icon: eservicesicon,
    component: Services,
    layout: "/admin",
    subPath: false,
  },
  {
    path: "/users",
    name: "All Users",
    icon: usersIcon,
    component: UserList,
    layout: "/admin",
    subPath: false,
  },
  {
    path: "/transactions",
    name: "All Transactions",
    icon: usersIcon,
    component: Transactions,
    layout: "/admin",
    subPath: false,
  },
  {
    path: "/sync-jobs",
    name: "Sync Jobs",
    icon: syncIcon,
    component: SyncJobs,
    layout: "/admin",
    subPath: false,
  },
  {
    path: "/sync-jobs/details",
    name: "Sync Jobs Details",
    icon: syncIcon,
    component: SyncJobDetails,
    layout: "/admin",
    subPath: true,
  },
  // {
  //   path: "/documents-list",
  //   name: "All Documents",
  //   icon: documentListIcon,
  //   component: DocumentsList,
  //   layout: "/admin",
  //   subPath: false,
  // },
  {
    path: "/request-types/StageStatuses",
    name: "Stage Statuses",
    icon: admissionStatusIcon,
    component: AdmissionStatuses,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/NotificationsTriggers",
    name: "Notifications Triggers",
    icon: trigerNotification,
    component: NotificationsTriggers,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/stage-list",
    name: "Stages",
    icon: "",
    component: StagesList,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/stage-configuration",
    name: "Stages Configuration",
    icon: "",
    component: StageConfig,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/documents-configuration",
    name: "Documents Configuration",
    icon: documentConfigIcon,
    component: DocumentsConfiguration,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/request-configuration",
    name: "Request Configuration",
    icon: documentConfigIcon,
    component: TermsAndConditions,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/guidelines",
    name: "Guidelines",
    icon: documentConfigIcon,
    component: Guidelines,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/e-services/configuration",
    name: "E-Services Configuration",
    icon: generalCnfigIcon,
    component: EServicesConfiguration,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/e-services/sections",
    name: "Add Sections",
    icon: generalCnfigIcon,
    component: TCCSectionConfig,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/discount-rules",
    name: "Discount Rules",
    icon: generalCnfigIcon,
    component: DiscountRules,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/discount-rules/configuration",
    name: "Discount Rules Configuration",
    icon: generalCnfigIcon,
    component: DiscountRuleConfiguration,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/discount-distribution",
    name: "Discount Distributions",
    icon: generalCnfigIcon,
    component: DiscountDistribution,
    layout: "/admin",
    subPath: true,
  },
  // ------sch--------//
  {
    path: "/request-types/rewards",
    name: "Rewards Add",
    icon: generalCnfigIcon,
    component: ScholarshipRewards,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/evaluation-points-config",
    name: "Evaluation Points Config",
    icon: generalCnfigIcon,
    component: EvaluationPointsConfig,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/education-systems",
    name: "Education Systems",
    icon: generalCnfigIcon,
    component: EducationSystems,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/financial-status",
    name: "Financial Status",
    icon: generalCnfigIcon,
    component: FinancialStatus,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/school-rating",
    name: "School Rating",
    icon: generalCnfigIcon,
    component: SchoolRating,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/educational-allowance",
    name: "Educational Allowance",
    icon: generalCnfigIcon,
    component: EducationalAllowance,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/scoring-systems",
    name: "Scoring Systems",
    icon: generalCnfigIcon,
    component: ScoringSystems,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/non-academic-achievement",
    name: "Non Academic Achievement",
    icon: generalCnfigIcon,
    component: NonAcademicAchievement,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/cat-map",
    name: "CAT/MAP",
    icon: generalCnfigIcon,
    component: CatMap,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/personal-inteview",
    name: "Personal Inteview",
    icon: generalCnfigIcon,
    component: PersonalInterview,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/research-contribution",
    name: "Research Contribution",
    icon: generalCnfigIcon,
    component: ResearchContribution,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/certificates-and-awards",
    name: "Certificates And Awards",
    icon: generalCnfigIcon,
    component: CertificatesAndAwards,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/community-participation",
    name: "Community Participation",
    icon: generalCnfigIcon,
    component: CommunityParticipation,
    layout: "/admin",
    subPath: true,
  },
  // -------end of SCH routes--------

  // ------- TCC routes--------
  {
    path: "/request-types/teachers",
    name: "Add Teachers",
    icon: generalCnfigIcon,
    component: TeachersConfig,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/courses",
    name: "Add Courses",
    icon: generalCnfigIcon,
    component: CoursesConfig,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/request-types/schedules",
    name: "Add Schedule",
    icon: generalCnfigIcon,
    component: ScheduleConfig,
    layout: "/admin",
    subPath: true,
  },

  // -------end of TCC routes--------

  {
    path: "/request-types/service-items",
    name: "Service Items",
    icon: asrIcon,
    component: ASRServiceItems,
    layout: "/admin",
    subPath: true,
    // sideMenuTitle: "Service Items",
    // state: null,
  },
  // -------end of ARS request types routes--------

  {
    path: "/request-types/buses-cms",
    name: "Buses",
    icon: asrIcon,
    component: BusesCMS,
    layout: "/admin",
    subPath: true,
    // sideMenuTitle: "Buses",
    // state: null,
  },
  // -------end of TRP request types routes--------
  {
    path: "/cashier-app",
    name: "Cashier App",
    icon: cashiericon,
    component: IFrameCashierScreen,
    layout: "/admin",
    subPath: false,
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: notificationIcon,
    component: Notifications,
    layout: "/admin",
    subPath: false,
  },
  {
    path: "/application/logs",
    name: "Application Logs",
    icon: "nc-icon nc-settings",
    component: Logs,
    layout: "/admin",
    subPath: true,
  },
 
  ...AdministrationRoutes,
  ...CMSRoutes,
  ...HtmlTemplatesRoutes,
];

export default AdminRoutes;
