/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import {
  Input,
  Form,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  Select,
  Button,
  Modal,
  DatePicker,
  Image,
  Card,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../assets/img/default.png";
import checkedImg from "../../assets/img/checked.png";
import uncheckedImg from "../../assets/img/unchecked.png";
import moment from "moment";

import {
  CommentOutlined,
  SyncOutlined,
  FilePdfOutlined,
  EnvironmentOutlined,
  EditOutlined,
} from "@ant-design/icons";
import styles from "../UploadedDocumentListItem/style.module.css";
import { useState } from "react";
import { _getRulesAction } from "actions/discount-rules/DiscountRulesActions";
import { getRulesAPI } from "apis/discount-rules/DiscountRulesApi";
import { useCallback } from "react";
import { Capitalize } from "utils/helpers/helpers";
import ASRFormItem from "components/ASRFormItem/ASRFormItem";
import { NotificationManager } from "react-notifications";
import CustomModal from "components/CustomModal/CustomModal";
import { getEServicesAPI } from "apis/e-services-api/EServicesApi";
import { getAllEducationSystems } from "apis/SCH/schRequests";
import { _getSCHApplicationMetaData } from "actions/meta-data/metaDataAction";
import TCCCourseAssignedReadOnlySection from "./TCCCourseAssignedReadOnlySection";
const { Option } = Select;

export default function TCCCursesReadOnlyTabDetails({ application, name }) {
  const ref = useRef();

  const _tccCourses = useSelector((state) => state.metaData.TCC_courses);
  const [tccCourses, setTCCCourses] = useState([]);

  const [courses, setCourse] = useState(application?.courses);

  const inputFieldRef = useRef();
  const [approveField, setApproveField] = useState(false);
  const [notes, setNotes] = useState("");

  let mainCourseNo = 0;
  let alternativeCourseNo = 0;

  useEffect(() => {
    filterCourses();
  }, [courses]);
  const filterCourses = () => {
    const filteredCourses = _tccCourses.filter(
      (course) => courses.map((c) => c.courseId).indexOf(course.id) < 0
    );
    setTCCCourses(filteredCourses);
  };

  const onChangeNotes = (e) => {
    setNotes(e.target.value);
  };

  const onChanegStatusForField = (index, status) => {
    let _courses = [...courses];
    if (status === "approved") {
      _courses[index].status = "submitted";
      _courses[index].note = "";
    } else {
      _courses[index].status = "approved";
    }
    setCourse(_courses);
    // onFinish(_courses);
  };

  const onChangeAmendment = (index, e) => {
    let _courses = [...courses];
    _courses[index].status = "needAmendment";
    _courses[index].note = e.target.value;

    setCourse(_courses);
    // onFinish(_courses);
  };
  const handelChnageCourse = (item, index) => {
    let _courses = [...courses];
    _courses[index].courseId = item;

    setCourse(_courses);
    // onFinish(_courses);
    // filterCourses();
  };

  return (
    <div>
      <Form
        // onFinish={onFinishInfoForm}
        // form={form}
        layout={"vertical"}
        scrollToFirstError={"true"}
        className="applicationDetailsTabForm"
        size={"large"}
      >
        <p style={{ marginBottom: 10, fontSize: 16, fontWeight: "600" }}>
          Main Courses
        </p>
        {courses.map((item, index) => {
          if (!item.isAlternative) {
            mainCourseNo = mainCourseNo + 1;
            return (
              <Card
                className="antCardBorder antCard"
                style={{ marginBottom: 15 }}
              >
                <Row
                  align="bottom"
                  className="compactFormItemRow"
                  justify="start"
                >
                  <Col className="compactFormItemCol" md={8}>
                    <Form.Item
                      label={`Course ${mainCourseNo}`}
                      name={`courses-${item.id}`}
                      initialValue={item.title}
                      rules={[
                        {
                          required: true,
                          message: "Courses required",
                        },
                      ]}
                    >
                      <Select
                        ref={inputFieldRef}
                        value={item.courseId}
                        disabled={true}
                        placeholder=""
                        onChange={(e) => handelChnageCourse(e, index)}
                      >
                        {tccCourses.map((el, i) => (
                          <Option value={el.id} key={i}>
                            <Row justify="space-between">
                              <p>{el.title}</p>
                            </Row>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* <Col className="compactFormItemCol" md={5}>
                    <Form.Item
                      name={[name, "status"]}
                      wrapperCol={{ offset: 8, span: 16 }}
                    >

                      <img
                        style={{
                          maxHeight: "18px",
                          maxWidth: "18px",
                          cursor: "pointer",
                          cursor: "not-allowed",

                          opacity: 0.3,
                        }}
                        src={checkedImg}
                      ></img>
                      <span style={{ marginLeft: "4px" }}>{"Approved"}</span>
                    </Form.Item>
                  </Col> */}
                  {/* <Col className="compactFormItemCol" md={8}>
                    <Form.Item
                      name={`notes_${item.id}`}
                      rules={[
                        {
                          required:
                            approveField || item.status === "submitted"
                              ? false
                              : true,
                          message: "Note for amendment is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        ref={ref}
                        placeholder="Add note for amendment"
                        prefix={<CommentOutlined />}
                        
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
                <div>
                  <strong className="tcc_reson_tag">
                    Course Interest Explanation
                  </strong>
                  <p>{item?.reasonObj?.title ?? item?.reason}</p>
                </div>
                <div>
                  <strong className="tcc_reson_tag">
                    Relevant Strength For Course
                  </strong>
                  <p>{item?.strengthObj?.title ?? item?.strength}</p>
                </div>
                <TCCCourseAssignedReadOnlySection section={item.section} />
              </Card>
            );
          }
        })}
        <p style={{ marginBottom: 10, fontSize: 16, fontWeight: "600" }}>
          Alternative Courses
        </p>
        {courses.map((item, index) => {
          if (item.isAlternative) {
            alternativeCourseNo = alternativeCourseNo + 1;
            return (
              <Card
                className="antCardBorder antCard"
                style={{ marginBottom: 15 }}
              >
                <Row
                  align="bottom"
                  className="compactFormItemRow"
                  justify="start"
                >
                  <Col className="compactFormItemCol" md={8}>
                    <Form.Item
                      label={`Course ${alternativeCourseNo}`}
                      name={`courses-${item.id}`}
                      initialValue={item.title}
                      rules={[
                        {
                          required: true,
                          message: "Courses required",
                        },
                      ]}
                    >
                      <Select
                        ref={inputFieldRef}
                        value={item.courseId}
                        disabled={true}
                        placeholder=""
                      >
                        {tccCourses.map((el, i) => (
                          <Option value={el.id} key={i}>
                            <Row justify="space-between">
                              <p>{el.title}</p>
                            </Row>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* <Col className="compactFormItemCol" md={5}>
                    <Form.Item
                      name={[name, "status"]}
                      wrapperCol={{ offset: 8, span: 16 }}
                    >

                      <img
                        style={{
                          maxHeight: "18px",
                          maxWidth: "18px",
                          cursor: "pointer",
                          cursor: "not-allowed",

                          opacity: 0.3,
                        }}
                        src={checkedImg}
                      ></img>
                      <span style={{ marginLeft: "4px" }}>Approved</span>
                    </Form.Item>
                  </Col> */}
                  {/* <Col className="compactFormItemCol" md={8}>
                    <Form.Item
                      name={`notes_${item.id}`}
                      rules={[
                        {
                          required:
                            approveField || item.status === "submitted"
                              ? false
                              : true,
                          message: "Note for amendment is required",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        ref={ref}
                        placeholder="Add note for amendment"
                        prefix={<CommentOutlined />}
                        
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
                <div>
                  <strong className="tcc_reson_tag">
                    Course Interest Explanation
                  </strong>
                  <p>{item?.reasonObj?.title ?? item?.reason}</p>
                </div>
                <div>
                  <strong className="tcc_reson_tag">
                    Relevant Strength For Course
                  </strong>
                  <p>{item?.strengthObj?.title ?? item?.strength}</p>
                </div>
              </Card>
            );
          }
        })}
      </Form>
    </div>
  );
}
