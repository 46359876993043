import { UPDATE_RULES } from "actions/discount-rules/DiscountRulesActions";
import { GET_RULES_CONFIG } from "actions/discount-rules/DiscountRulesActions";
import { UPDATE_RULES_CONFIG } from "actions/discount-rules/DiscountRulesActions";
import { DELETE_RULES_CONFIG } from "actions/discount-rules/DiscountRulesActions";
import { ADD_RULES_CONFIG } from "actions/discount-rules/DiscountRulesActions";
import { DELETE_RULES } from "actions/discount-rules/DiscountRulesActions";
import { ADD_RULES } from "actions/discount-rules/DiscountRulesActions";
import { GET_RULES } from "actions/discount-rules/DiscountRulesActions";

const initialState = {
  data: [],
  dataTotal: 0,
  configurationData: [],
  configurationDataTotal: 0,
};

const updateRulesHelper = (rule, allData) => {
  let updatedRuleIndex = allData.findIndex(
    (ruleItem) => ruleItem.id === rule.id
  );
  if (updatedRuleIndex > -1) {
    let newData = [...allData];
    newData[updatedRuleIndex] = rule;
    return newData;
  } else {
    return allData;
  }
};

const RulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RULES:
      return {
        ...state,
        data: action.payload.data,
        dataTotal: action.payload.total,
      };
    case ADD_RULES:
      return {
        ...state,
        data: [action.payload, ...state.data],
      };
    case UPDATE_RULES:
      return {
        ...state,
        data: updateRulesHelper(action.payload, state.data),
      };
    case DELETE_RULES:
      return {
        ...state,
        data: state.data.filter((rule) => rule.id !== action.payload),
        dataTotal: state.dataTotal - 1,
      };
    case GET_RULES_CONFIG:
      return {
        ...state,
        configurationData: action.payload,
      };
    case ADD_RULES_CONFIG:
      return {
        ...state,
        configurationData: [action.payload, ...state.configurationData],
      };
    case UPDATE_RULES_CONFIG:
      return {
        ...state,
        configurationData: updateRulesHelper(
          action.payload,
          state.configurationData
        ),
      };
    case DELETE_RULES_CONFIG:
      return {
        ...state,
        configurationData: state.configurationData.filter(
          (item) => item.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default RulesReducer;
