/* eslint-disable no-unused-vars */
import { Card } from "antd";
import React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";
import FinalRemarks from "../final-remarks/FinalRemarks";
import { convertToTitleCase } from "utils/helpers/helpers";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";

function SingleInterview({ currentStageDetails }) {
  if (currentStageDetails?.schedules?.length) {
    return (
      <Card className="antCardBorder" style={{ marginTop: "10px" }}>
        <div
          style={{
            alignItems: "center",
          }}
        >
          <div>
            <div>
              <h5>Interview Details</h5>
              <br />
              <div>
                <h6>Interview Scheduled</h6>
                <p>
                  Date:{" "}
                  {formateDateWithMoment(
                    currentStageDetails?.schedules[0].scheduleTime,
                    "DD MMM YYYY hh:mm A"
                  )}
                </p>
                <p>
                  Type:{" "}
                  {convertToTitleCase(
                    currentStageDetails?.schedules[0].scheduleType
                  )}
                </p>
                {currentStageDetails?.schedules[0].scheduleType !== "online" ? (
                  <p>
                    Instructions: {currentStageDetails?.schedules[0].address}
                  </p>
                ) : null}
                <p style={{ overflowX: "clip" }}>
                  Notes: {currentStageDetails?.schedules[0].notes}
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: "2px 12px ",
                  width: "100%",
                  maxWidth: "350px",
                  borderRadius: "5px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CalendarOutlined style={{ marginRight: "10px" }} />
                  <p style={{ margin: "7px 0" }}>
                    Date of Action :{" "}
                    {formateDateWithMoment(
                      currentStageDetails?.schedules[0].createdAt,
                      "DD MMM YYYY hh:mm A"
                    )}
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <UserOutlined style={{ marginRight: "10px" }} />
                  <p style={{ margin: "7px 0 " }}>
                    Action Creator :{" "}
                    {currentStageDetails?.schedules[0].user?.name +
                      " - " +
                      currentStageDetails?.schedules[0].user?.userName}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {currentStageDetails?.schedules[0].scheduleType === "online" &&
          currentStageDetails?.schedules[0].preRecordedLink !== "" ? (
            <p>
              Interview Video Link:{" "}
              <a
                href={currentStageDetails?.schedules[0].preRecordedLink}
                target="__blank()"
              >
                Click here to see the pre-recorded video
              </a>
            </p>
          ) : null}
          <FinalRemarks remarks={currentStageDetails?.schedules[0]?.remarks} />
        </div>
      </Card>
    );
  } else return null;
}

export default SingleInterview;
