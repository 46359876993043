import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { useSelector } from "react-redux";
const { Option } = Select;
const PlaceholerForm = ({ onSubmit, loading, formName, valuesForEdit }) => {
  const dropDowns = useSelector((state) => state.metaData.dropDowns);
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Label (En)"}
              name={"labelEn"}
              rules={setRules("Label (En)")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Label (Ar)"}
              name={"labelAr"}
              rules={setRules("Label (Ar)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Placeholder For"}
              name={"placeholderFor"}
              rules={setRules("Placeholder For")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item
              label={"Category"}
              name={"category"}
              rules={setRules("Category")}
            >
              <Select
                value={dropDowns.placeholderCategories?.find((el) => el.id)}
                placeholder="Select Category"
              >
                {dropDowns.placeholderCategories?.map((category) => (
                  <Option value={category.value}>{category.title}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default PlaceholerForm;
