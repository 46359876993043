'use client'

import React, { useState, useEffect } from 'react'
import { Typography, Input, Button, message, Form, Spin } from 'antd'
import { LockOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons'
import { verifyPin } from 'apis/qr-configuration/qrConfigurationApis'

const { Title, Text } = Typography

const styles = {
  '@keyframes gradientAnimation': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
  '@keyframes floatAnimation': {
    '0%, 100%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-20px)' },
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(-45deg, #e6f7f8, #f0f9fa, #fdf0f4, #fae6ec)',
    backgroundSize: '400% 400%',
    animation: 'gradientAnimation 15s ease infinite',
    padding: '2rem',
    position: 'relative',
    overflow: 'hidden',
  },
  floatingShapes: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  shape: {
    position: 'absolute',
    backgroundColor: 'rgba(23, 139, 145, 0.1)',
    borderRadius: '50%',
    animation: 'floatAnimation 6s infinite',
  },
  card: {
    width: '100%',
    maxWidth: '720px',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(10px)',
    borderRadius: '20px',
    boxShadow: '0 8px 32px 0 rgba(23, 139, 145, 0.1)',
    border: '1px solid rgba(23, 139, 145, 0.18)',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
  },
  cardContent: {
    padding: '2rem',
  },
  title: {
    textAlign: 'center',
    color: '#178b91',
    marginBottom: '2rem',
    fontWeight: 700,
    fontSize: '2.5rem',
    textShadow: '2px 2px 4px rgba(23, 139, 145, 0.1)',
  },
  form: {
    marginBottom: '2rem',
  },
  input: {
    borderRadius: '10px',
    border: '2px solid rgba(23, 139, 145, 0.2)',
    background: 'rgba(255, 255, 255, 0.9)',
    color: '#178b91',
    fontSize: '1.1rem',
    padding: '12px',
    transition: 'all 0.3s ease',
  },
  inputIcon: {
    color: 'rgba(23, 139, 145, 0.7)',
  },
  submitButton: {
    background: 'linear-gradient(135deg, #178b91, #1a9ba2)',
    border: 'none',
    borderRadius: '10px',
    height: '50px',
    fontSize: '1.1rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    transition: 'all 0.3s ease',
    color: '#fff',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  verifiedData: {
    textAlign: 'center',
  },
  successIcon: {
    fontSize: '64px',
    color: '#178b91',
    marginBottom: '1rem',
  },
  verifiedTitle: {
    color: '#178b91',
    marginBottom: '2rem',
    fontSize: '2rem',
    textShadow: '2px 2px 4px rgba(23, 139, 145, 0.1)',
  },
  dataCard: {
    background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '10px',
    padding: '1.5rem',
    marginBottom: '2rem',
    boxShadow: '0 4px 6px rgba(23, 139, 145, 0.1)',
  },
  dataItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.75rem 0',
    borderBottom: '1px solid rgba(23, 139, 145, 0.1)',
  },
  dataLabel: {
    color: '#178b91',
    fontWeight: 600,
    fontSize: '0.9rem',
  },
  dataValue: {
    color: '#333',
    fontSize: '0.9rem',
    maxWidth: '60%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  downloadButton: {
    background: 'linear-gradient(135deg, #b42850, #c62d59)',
    border: 'none',
    borderRadius: '10px',
    height: '50px',
    fontSize: '1.1rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    transition: 'all 0.3s ease',
    color: '#fff',
  },
}

const FloatingShapes = () => {
  const shapes = Array(5).fill(null).map((_, i) => ({
    size: Math.random() * 100 + 50,
    left: `${Math.random() * 100}%`,
    top: `${Math.random() * 100}%`,
    animationDuration: `${Math.random() * 4 + 4}s`,
    animationDelay: `${Math.random() * 2}s`,
  }))

  return (
    <div style={styles.floatingShapes}>
      {shapes.map((shape, index) => (
        <div
          key={index}
          style={{
            ...styles.shape,
            width: shape.size,
            height: shape.size,
            left: shape.left,
            top: shape.top,
            animationDuration: shape.animationDuration,
            animationDelay: shape.animationDelay,
          }}
        />
      ))}
    </div>
  )
}

const calculateExpiryDate = (createdAt, expiryValue) => {
  const [amount, unit] = expiryValue.split(' ')
  const date = new Date(createdAt)

  switch (unit.toLowerCase()) {
    case 'day':
    case 'days':
      date.setDate(date.getDate() + parseInt(amount))
      break
    case 'week':
    case 'weeks':
      date.setDate(date.getDate() + parseInt(amount) * 7)
      break
    case 'month':
    case 'months':
      date.setMonth(date.getMonth() + parseInt(amount))
      break
    case 'year':
    case 'years':
      date.setFullYear(date.getFullYear() + parseInt(amount))
      break
    default:
      throw new Error('Invalid expiry unit')
  }

  return date.toLocaleString()
}

export default function VerifyPinPage() {
  const [loading, setLoading] = useState(false)
  const [referenceId, setReferenceId] = useState("")
  const [responseData, setResponseData] = useState(null)
  const [verified, setVerified] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const queryParams = new URLSearchParams(window.location.search)
      const referenceId = queryParams.get('reference_number')
      setReferenceId(referenceId)
    }
  }, [])

  const handlePinVerification = async (values) => {
    if (values.pin.length !== 8) {
      message.error('Please enter an 8-digit PIN.')
      return
    }

    try {
      setLoading(true)
      const response = await verifyPin({ pin: values.pin, reference: referenceId })
      console.log(response)
      if (response.status === 200) {
        setResponseData(response.data.data)
        setVerified(true)
        message.success('Document verified successfully!')
      } else {
        message.error('Invalid PIN code.')
      }
    } catch (err) {
      message.error('Error verifying PIN.')
    } finally {
      setLoading(false)
    }
  }

  const handleDownloadPDF = () => {
    if (responseData && responseData.fileURL) {
      const link = document.createElement('a')
      link.href = responseData.fileURL
      link.target = '_blank'
      link.download = 'verified_document.pdf'
      link.click()
    }
  }

  const renderVerificationForm = () => (
    <Form onFinish={handlePinVerification} layout="vertical" style={styles.form}>
      <Form.Item
        name="pin"
        rules={[
          { required: true, message: 'Please input your PIN!' },
          { len: 8, message: 'PIN must be 8 digits long' }
        ]}
      >
        <Input
          prefix={<LockOutlined style={styles.inputIcon} />}
          type="text"
          placeholder="Enter 8-digit PIN"
          maxLength={8}
          size="large"
          style={styles.input}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          block
          size="large"
          style={styles.submitButton}
        >
          Verify PIN
        </Button>
      </Form.Item>
    </Form>
  )

  const renderVerifiedData = () => {
    if (!responseData) return null

    const parsedData = JSON.parse(responseData.data)
    const expiryDate = parsedData.expiryValue ? calculateExpiryDate(responseData.createdAt, parsedData.expiryValue) : null

    return (
      <div style={styles.verifiedData}>
        <div style={styles.successIcon}>
          <CheckCircleOutlined />
        </div>
        <Title level={2} style={styles.verifiedTitle}>Document Verified Successfully</Title>
        <div style={styles.dataCard}>
          <DataItem label="Title" value={parsedData.title} />
          <DataItem label="Arabic Title" value={parsedData.titleAr} />
          <DataItem label="Stamp Type" value={responseData.stampType} />
          <DataItem label="Reference" value={responseData.reference} />
          <DataItem label="Created At" value={new Date(responseData.createdAt).toLocaleString()} />
          {expiryDate && (
            <DataItem label="Expires At" value={expiryDate} />
          )}
        </div>
        {responseData.fileURL && (
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            size="large"
            onClick={handleDownloadPDF}
            style={styles.downloadButton}
          >
            Download PDF
          </Button>
        )}
      </div>
    )
  }

  return (
    <div style={styles.container}>
      <FloatingShapes />
      <div style={styles.card}>
        <div style={styles.cardContent}>
          <Title level={2} style={styles.title}>
            {verified ? "Verification Result" : "Verify PIN"}
          </Title>
          {loading ? (
            <div style={styles.loadingContainer}>
              <Spin size="large" />
            </div>
          ) : verified ? (
            renderVerifiedData()
          ) : (
            renderVerificationForm()
          )}
        </div>
      </div>
    </div>
  )
}

const DataItem = ({ label, value }) => (
  <div style={styles.dataItem}>
    <Text strong style={styles.dataLabel}>{label}:</Text>
    <Text style={styles.dataValue}>{value}</Text>
  </div>
)