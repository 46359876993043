import client from "apis/config";
import notificationClient from "apis/notification-config";

export const getNotificationTopicsAPI = async () => {
  return await client.get("/notifications/topics");
};

export const sendNotificationAPI = async (data, endPoint) => {
  return await client.post(`/notifications`, data);
};
