import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { showValidationError } from "utils/helpers/helpers";
import { DownOutlined } from "@ant-design/icons";
import { getDistributionAPI } from "apis/distribution-apis/DistributionApis";
import { _getDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";
import { requestErrorHandel } from "utils/helpers/helpers";

const { Option } = Select;
const { Panel } = Collapse;

const ConfigureDiscountRulesForm = ({
  visibility,
  onHide,
  title,
  onSubmit,
  formName,
  loading,
  autofillValues,
}) => {
  const dispatch = useDispatch();
  const _discountTypeOptions = useSelector(
    (state) => state.metaData.dropDowns["discountType"]
  );
  const _grades = useSelector((state) =>
    state.metaData.grades.filter((grade) => grade.code !== "GRADUATED")
  );
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _allSchools = useSelector((state) => state.metaData.schools);
  const _distributions = useSelector((state) => state.distributions.data);

  const [selectedCampuses, setSelectedCampuses] = React.useState([]);
  const [selectedSchools, setSelectedSchools] = React.useState([]);
  const [campusesFilteredSchools, setCampusesFilteredSchools] = React.useState(
    []
  );
  const [selectedGrades, setSelectedGrades] = React.useState([]);

  React.useEffect(() => {
    fetchDistributions();
  }, []);
  React.useEffect(() => {
    if (autofillValues) {
      const gradeMapping = autofillValues.grades.map((grade) => {
        return {
          [`gradeId${grade.id}`]: grade,
        };
      });
      const values = Object.assign(
        {},
        autofillValues.students,
        ...gradeMapping
      );
      formName.setFieldsValue(values);
      setSelectedGrades(autofillValues.grades);
      setSelectedCampuses(autofillValues.campuses);
      setSelectedSchools(autofillValues.schools);
    }
  }, [autofillValues]);

  const fetchDistributions = async () => {
    try {
      const response = await getDistributionAPI(`page=1&limit=1000`);
      if (response.status == 200 && response.data) {
        dispatch(_getDistributionAction(response.data));
      } else throw response.data;
    } catch (e) {
      console.log({ e });
      requestErrorHandel({ error: e });
    }
  };
  //  filter all schools againt the selected campuses
  const filterSchoolsByCampus = () => {
    const selectedCampusesesIds = selectedCampuses.map((el) => el.id);

    selectedCampuses.forEach((campus) => {
      selectedCampusesesIds.push(campus.id);
    });
    setCampusesFilteredSchools(
      _allSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
    setSelectedSchools(
      selectedSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
  };

  const handleSelectAllGrades = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedGrades(_grades);
    } else {
      return value;
    }
  };

  const handleSelectAllCampus = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedCampuses(_campuses);
    } else {
      return value;
    }
  };

  const handleSelectAllSchools = (value) => {
    if (value && value.length && value.includes("all")) {
      const selectedCampusIds = selectedCampuses.map((el) => el.id);
      const _campuseSchools = _allSchools.filter((school) =>
        selectedCampusIds.includes(school.campusId)
      );
      setSelectedSchools(_campuseSchools);
    } else {
      return value;
    }
  };

  React.useEffect(() => {
    filterSchoolsByCampus();
  }, [selectedCampuses]);

  const getSelectedGrades = (allFields) => {
    const grades = _.flow(
      Object.entries,
      (formValues) =>
        formValues.filter(
          ([formKey, formValue]) =>
            formKey.includes("gradeId") && formValue.type && formValue.value
        ),
      Object.fromEntries,
      Object.values
    )(allFields);
    return grades;
  };
  const onSubmitForm = (value) => {
    const grades = getSelectedGrades(value);
    if (!(selectedGrades.length > 0)) {
      showValidationError(
        "Grades are required, select at least one of the grades"
      );
      return;
    }
    if (!(selectedCampuses.length > 0)) {
      showValidationError(
        "Campuses are required, select at least one of the campuses"
      );
      return;
    }
    if (!(selectedSchools.length > 0)) {
      showValidationError(
        "Schools are required, select at least one of the schools"
      );
      return;
    }
    let params = {
      grades: grades,
      students: value.students,
      campuses: selectedCampuses.map((campus) => campus.id),
      schools: selectedSchools.map((school) => school.id),
      distributionId: value.distributionId,
    };
    onSubmit(params, clearForm);
  };

  const clearForm = () => {
    setSelectedCampuses([]);
    setSelectedSchools([]);
    setSelectedGrades([]);
  };

  const onClose = () => {
    onHide();
    clearForm();
  };

  return (
    <CustomModal visibility={visibility} handleCancel={onClose} title={title}>
      {
        <Form layout="vertical" form={formName} onFinish={onSubmitForm}>
          <Form.Item
            label={"Grades"}
            style={{ padding: "0 0.8rem" }}
            rules={[
              {
                message: "Grades are required",
                validator: () => {
                  if (selectedGrades.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Grades are required");
                  }
                },
              },
            ]}
            initialValue={selectedGrades}
          >
            <Select
              placeholder="Select grades"
              mode="multiple"
              value={
                selectedGrades.length > 0
                  ? selectedGrades.map((item) => item.code)?.join(", ")
                  : []
              }
              onChange={handleSelectAllGrades}
            >
              <Option key="all" value="all">
                Select All
              </Option>

              {_grades.map((el, i) => (
                <Option value={el.id} key={i}>
                  <Checkbox
                    checked={
                      selectedGrades.findIndex((i) => i.id === el.id) > -1
                    }
                    onChange={(e) => {
                      setSelectedGrades(
                        e.target.checked
                          ? [...selectedGrades, el]
                          : selectedGrades.filter((i) => i.id !== el.id)
                      );
                    }}
                    style={{ width: "100%" }}
                  >
                    {el.code}
                  </Checkbox>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Collapse
            defaultActiveKey={["1"]}
            ghost
            expandIcon={({ isActive }) => (
              <DownOutlined rotate={isActive ? 180 : 0} />
            )}
            expandIconPosition={"start"}
          >
            <Panel
              key={"1"}
              header={
                <p style={{ padding: 0, margin: 0 }}>Discount Type & Value</p>
              }
            >
              <Table
                dataSource={selectedGrades}
                columns={[
                  {
                    title: "Grade",
                    dataIndex: "code",
                    key: "code",
                  },
                  {
                    title: "Discount",
                    dataIndex: "",
                    key: "id",
                    render: (record) => {
                      return (
                        <div key={record.id}>
                          <Form.Item
                            name={[`gradeId${record.id}`, "grade"]}
                            style={{ display: "none" }}
                            initialValue={record.id}
                          ></Form.Item>
                          <Form.Item
                            name={[`gradeId${record.id}`, "value"]}
                            style={{ padding: 0, margin: 0 }}
                            rules={[
                              {
                                message: "Discount value is required",
                                required: true,
                              },
                            ]}
                          >
                            <InputNumber
                              style={{ width: "100%" }}
                              placeholder="Enter Discount Value"
                            />
                          </Form.Item>
                        </div>
                      );
                    },
                  },
                  {
                    title: "Percentage",
                    dataIndex: "",
                    key: "id",
                    render: (record) => (
                      <Form.Item
                        key={record.id}
                        name={[`gradeId${record.id}`, "type"]}
                        style={{ padding: 0, margin: 0 }}
                        rules={[
                          {
                            message: "Discount type is required",
                            required: true,
                          },
                        ]}
                        initialValue={
                          _discountTypeOptions?.find(
                            (type) => type?.value === "percentage"
                          )?.value
                        }
                      >
                        <Select
                          placeholder={"Select Discount Type"}
                          defaultValue={
                            _discountTypeOptions?.find(
                              (type) => type?.value === "percentage"
                            )?.value
                          }
                        >
                          {_discountTypeOptions?.map((type) => (
                            <Option key={type.id} value={type.value}>
                              {type.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ),
                  },
                ]}
                pagination={false}
              ></Table>
            </Panel>
          </Collapse>
          <br />
          <div style={{ padding: "0 0.8rem" }}>
            <Form.Item
              label={"Campuses"}
              rules={[
                {
                  message: "Campuses are required",
                  validator: (_, __) => {
                    if (selectedCampuses.length > 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Campuses are required");
                    }
                  },
                },
              ]}
            >
              <Select
                placeholder="Select campuses"
                mode={"multiple"}
                value={
                  selectedCampuses.length > 0
                    ? selectedCampuses.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={handleSelectAllCampus}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {_campuses.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedCampuses.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedCampuses(
                          e.target.checked
                            ? [...selectedCampuses, el]
                            : selectedCampuses.filter((i) => i.id !== el.id)
                        );
                      }}
                    >
                      {el.code}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={"Schools"}
              rules={[
                {
                  message: "Schools are required",
                  validator: () => {
                    if (selectedSchools.length > 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Schools are required");
                    }
                  },
                },
              ]}
            >
              <Select
                placeholder="Select schools"
                mode="multiple"
                value={
                  selectedSchools.length > 0
                    ? selectedSchools
                        .map((item) => item.abbreviation)
                        ?.join(", ")
                    : []
                }
                onChange={handleSelectAllSchools}
              >
                {campusesFilteredSchools.length > 1 && (
                  <Option key="all" value="all">
                    Select All
                  </Option>
                )}

                {campusesFilteredSchools.map((el) => (
                  <Option value={el.id}>
                    <Checkbox
                      checked={
                        selectedSchools.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedSchools(
                          e.target.checked
                            ? [...selectedSchools, el]
                            : selectedSchools.filter((i) => i.id !== el.id)
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      {el.abbreviation}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Students"} name={"students"}>
              <Input placeholder="Student Ids" />
            </Form.Item>
            <Form.Item
              label={"Discount Distribution"}
              rules={[
                {
                  required: true,
                  message: "Distribution is required",
                },
              ]}
              name={"distributionId"}
            >
              <Select placeholder="Select a discount distribution">
                {_distributions.map((el) => (
                  <Option value={el.id}>{el.title}</Option>
                ))}
              </Select>
            </Form.Item>

            <Row justify="end">
              <Button loading={loading} htmlType="submit">
                {loading ? "Saving...." : "Save"}
              </Button>
            </Row>
          </div>
        </Form>
      }
    </CustomModal>
  );
};
export default ConfigureDiscountRulesForm;
