/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Tabs, Spin, Select } from "antd";

import { _updateApplications } from "actions/applications/applications-actions";

import {
  renderLoader,
  renderNoDataPlaceholder,
  requestErrorHandel,
} from "utils/helpers/helpers";
import { _deleteAdditionalDocuments } from "actions/applications/applications-actions";
import _ from "lodash";
import { getApplicationDetails } from "apis/applications-apis/applications";
// stages screens
import InterviewDetails from "../enr_stages/interview-details/InterviewDetails";
import RegistrationDetails from "../enr_stages/registration/RegistrationDetails";
import TranscriptReview from "views/screens/enr_stages/transcriptReview/TranscriptReview.jsx";
import RegistrationFee from "views/screens/enr_stages/registraionFee/RegistrationFee.jsx";
import FinalApproval from "views/screens/enr_stages/finalApproval/FinalApproval.jsx";
import ObservationDetails from "views/screens/enr_stages/observations/ObservationDetails.jsx";
import EntranceTestScheduleDetails from "views/screens/enr_stages/entrance_test/EntranceTestScheduleDetails.js";
import PreFinalApproval from "views/screens/enr_stages/pre-final-approval/PreFinalApproval.jsx";

import { useSelector } from "react-redux";
import Payment from "../other_application_stages/OtherApplicationsPayment";
import Review from "../other_application_stages/OtherApplicationsReview";
import OtherApplicationPreFinalApproval from "../other_application_stages/OtherApplicationsPreFinalApproval";
import OtherApplicationsFinalApproval from "../other_application_stages/OtherApplicationsFinalApproval";
import UpdateDetailsIntegration from "../update-details/IntegrationStage";
import SCHReview from "../scholarship/sch-stages/SCHReview";
import SCHEvaluation from "../scholarship/sch-stages/SCHEvaluation";
import SCHCommitteApproval from "../scholarship/sch-stages/SCHCommitteAproval";
import SCHFinalApproval from "../scholarship/sch-stages/SCHFinalApproval";
import TCCReview from "../tcc/tcc-stages/TCCReview";
import TCCPreFinalApproval from "../tcc/tcc-stages/TCCPreFinalApproval";
import TCCFinalApproval from "../tcc/tcc-stages/TCCFinalApproval";
import TCCPayment from "../tcc/tcc-stages/TCCPayment";

export default function ApplicationDetails(props) {
  const serviceType = props.location.state.serviceType;

  const [loading, setLoading] = useState(false);
  const applicationId = useSelector((state) => {
    return state.allApplications.currentApplicationId;
  });
  const [application, setApplication] = useState();

  useEffect(() => {
    applicationId && getDetails();
  }, [applicationId]);

  const getDetails = async () => {
    try {
      setLoading(true);
      const detailsResponse = await getApplicationDetails(applicationId);
      if (detailsResponse?.data?.data) {
        setApplication(detailsResponse.data.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };
  const renderStageDetails = (stage) => {
    let stageProps = { application, refetch: getDetails, loading, ...props };
    switch (serviceType) {
      case "ENR":
        switch (stage) {
          case "registration":
            return <RegistrationDetails {...stageProps} />;
          case "interview":
            return <InterviewDetails {...stageProps} />;
          case "observation":
            return <ObservationDetails {...stageProps} />;
          case "transcript_review":
            return <TranscriptReview {...stageProps} />;
          case "entrance_test":
            return <EntranceTestScheduleDetails {...stageProps} />;
          case "pre_final_approval":
            return <PreFinalApproval {...stageProps} />;
          case "final_review_approval":
            return <FinalApproval {...stageProps} />;
          case "registration_fees":
            return <RegistrationFee {...stageProps} />;
          default:
            return <div style={{ minHeight: "100vh" }}></div>;
        }
      case "TRP":
        switch (stage) {
          case "review":
            return <Review {...stageProps} />;
          case "pre_final_approval":
            return <OtherApplicationPreFinalApproval {...stageProps} />;
          case "final_approval":
            return <OtherApplicationsFinalApproval {...stageProps} />;
          case "payment":
          case "bus_allocation":
            return <Payment {...stageProps} />;
          default:
            return;
        }
      case "ASR":
        switch (stage) {
          case "review":
            return <Review {...stageProps} />;
          case "pre_final_approval":
            return <OtherApplicationPreFinalApproval {...stageProps} />;
          case "final_approval":
            return <OtherApplicationsFinalApproval {...stageProps} />;
          case "payment":
            return <Payment {...stageProps} />;
          default:
            return;
        }
      case "UPD":
        switch (stage) {
          case "review":
            return <Review {...stageProps} />;
          case "pre_final_approval":
            return <OtherApplicationPreFinalApproval {...stageProps} />;
          case "final_approval":
            return <OtherApplicationsFinalApproval {...stageProps} />;
          case "integration":
            return <UpdateDetailsIntegration {...stageProps} />;
          default:
            return;
        }
      case "SCH":
        switch (stage) {
          case "review":
            return <SCHReview {...stageProps} />;
            break;
          case "evaluation":
            return <SCHEvaluation {...stageProps} />;
            break;
          default:
          case "committee_approval":
            return <SCHCommitteApproval {...stageProps} />;
            break;
          case "final_approval":
            return <SCHFinalApproval {...stageProps} />;
            break;
        }
      case "TCC":
        switch (stage) {
          case "review":
            return <TCCReview {...stageProps} />;
            break;
          case "pre_final_approval":
            return <TCCPreFinalApproval {...stageProps} />;
            break;
          case "final_approval":
            return <TCCFinalApproval {...stageProps} />;
            break;
          case "payment":
            return <TCCPayment {...stageProps} />;
            break;
        }

      default:
        return;
    }
  };
  return (
    <Spin spinning={loading && !application} indicator={renderLoader}>
      {application
        ? renderStageDetails(application.stage.slug)
        : renderNoDataPlaceholder(loading)}
    </Spin>
  );
}
