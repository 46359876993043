import { Button, Form, Input, Select, Row, Col } from "antd";
import React from "react";

import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;
const CategoryForm = ({ onSubmit, loading, formName }) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={setRules("Title (EN)")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col md={12}>
            <Form.Item name={"description"} label={"Description (EN)"}>
              <TextArea placeholder="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item name={"descriptionAr"} label={"Description (AR)"}>
              <TextArea placeholder="" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button
            className="ant__primary__button"
            htmlType="submit"
            loading={loading}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default CategoryForm;
