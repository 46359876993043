import React, { useEffect, useState } from "react";
import "./calendar.css"; // Import the CSS file for styling
import { renderEventsBackgroundColor } from "utils/helpers/helpers";
import { Button, Form, Select, Row, Col, Spin } from "antd";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { renderEventTitleFromIndentifer } from "utils/helpers/helpers";
import CustomModal from "components/CustomModal/CustomModal";
import moment from "moment";
import DailyView from "./dailyview/DailyCalanderView";
import { getMonthlyBookings } from "./apis";
import { requestErrorHandel } from "utils/helpers/helpers";
import YearlyView from "./yearly-view/YearlyView";
import { renderLoader } from "utils/helpers/helpers";
import { fetchAllFsmFacilities } from "../facility-mangement/all-facilities/api";
import { onViewBookingDetails } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";

const { Option } = Select;

const FSMCalendar = (props) => {
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _bookingTypes = useSelector((state) => state.metaData.FSM_bookingTypes);
  const _bookingStatus = useSelector(
    (state) => state.metaData.applicationStatuses
  );

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(0);
  const [currentDateEvents, setCurrentDateEvents] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [campus, setCampus] = useState("");
  const [facility, setFacility] = useState("");
  const [bookingType, setBookingType] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [_booking, setBooking] = useState({});

  useEffect(() => {
    fetchAllMonthlyBookings();
  }, [currentMonth, campus, facility, bookingType, bookingStatus]);
  useEffect(() => {
    getAllFsmFacilities();
  }, []);

  const fetchAllMonthlyBookings = async () => {
    const _currentMonth = moment()
      .set({ year: currentYear, month: currentMonth })
      .startOf("month")
      .format("YYYY-MM-DD");

    try {
      setLoading(true);
      const response = await getMonthlyBookings({
        date: _currentMonth,
        status: bookingStatus,
        type: bookingType,
        facility: facility,
        campus: campus,
      });
      if (response.status === 200) {
        setBookings(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const renderMonthlyView = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const today = date.getDate();

    const daysInMonth = getDaysInMonth(currentYear, currentMonth);
    const firstDayOfMonth = getFirstDayOfMonth(currentYear, currentMonth);

    const days = [];
    let prevMonthDays = 0;
    let nextMonthDays = 0;

    if (firstDayOfMonth !== 0) {
      const prevMonth = month === 0 ? 11 : month - 1;
      const prevYear = month === 0 ? year - 1 : year;
      prevMonthDays = getDaysInMonth(prevYear, prevMonth);
    }

    const lastDayOfMonth = new Date(year, month, daysInMonth).getDay();
    if (lastDayOfMonth !== 6) {
      // const nextMonth = month === 11 ? 0 : month + 1;
      // const nextYear = month === 11 ? year + 1 : year;
      nextMonthDays = 6 - lastDayOfMonth;
    }

    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      days.push(
        <div key={`prev-month-${i}`} className="day other-month">
          <div className="day-label"> {prevMonthDays - i}</div>
        </div>
      );
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const isToday = day === today;

      let eventsForCurrentDay = bookings.filter((event) => {
        const eventDate = new Date(event.date);

        return (
          eventDate.getDate() === day &&
          eventDate.getMonth() === currentMonth &&
          eventDate.getFullYear() === currentYear
        );
      });
      // Render up to 2 events for the day

      days.push(
        <div key={day} className={`day ${isToday ? "today" : ""}`}>
          <div className={`day-label ${isToday ? "today-label" : ""}`}>
            {day}
          </div>
          {eventsForCurrentDay.length > 2
            ? eventsForCurrentDay.slice(0, 1).map((event, index) => (
                <React.Fragment key={index}>
                  <div
                    className="event"
                    // onClick={() => onViewBookingDetails(event, props)}
                    onClick={() => handleClickOnBooking(event)}
                  >
                    <div
                      className="event_detail"
                      style={{
                        backgroundColor: renderEventsBackgroundColor(
                          event?.booking?.bookingType?.identifier
                        ),
                      }}
                    >
                      <p className="custom_event_title">
                        {event?.booking?.title}
                      </p>
                      <p className="custom_event_sub_title">
                        Campus: {event?.booking?.facility?.campus?.code}
                      </p>
                    </div>
                    <div className="custom_event_type_title">
                      <p>{event?.booking?.bookingType?.identifier}</p>
                    </div>
                  </div>
                  <div
                    className="more_events_indicator"
                    onClick={() => onHandlePlusMoreEvents(eventsForCurrentDay)}
                  >
                    <p>+{eventsForCurrentDay.length - 1}</p>
                  </div>
                </React.Fragment>
              ))
            : eventsForCurrentDay?.map((event, index) => (
                <div
                  key={index}
                  className="event"
                  // onClick={() => onViewBookingDetails(event, props)}
                  onClick={() => handleClickOnBooking(event)}
                >
                  <div
                    className="event_detail"
                    style={{
                      backgroundColor: renderEventsBackgroundColor(
                        event?.booking?.bookingType?.identifier
                      ),
                    }}
                  >
                    <p className="custom_event_title">
                      {event?.booking?.title}
                    </p>
                    <p className="custom_event_sub_title">
                      Campus: {event?.booking?.facility?.campus?.code}
                    </p>
                  </div>
                  <div className="custom_event_type_title">
                    <p>{event?.booking?.bookingType?.identifier}</p>
                  </div>
                </div>
              ))}
        </div>
      );
    }

    for (let i = 1; i <= nextMonthDays; i++) {
      days.push(
        <div key={`next-month-${i}`} className="day other-month">
          <div className="day-label">{i}</div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {renderMonthlyViewCalendarFilters()}
        <Spin spinning={loading} indicator={renderLoader}>
          <div className="monthly-view">
            <div className="day-names">
              {[
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ].map((day) => (
                <div key={day} className="days-label">
                  {day}
                </div>
              ))}
            </div>
            <div className="days-grid">{days}</div>
          </div>
        </Spin>
      </React.Fragment>
    );
  };

  const renderMonthlyViewCalendarFilters = () => {
    return (
      <div className="calendar_filters">
        <Row gutter={10}>
          <Col md={8} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by campus"
                onChange={(e) => setCampus(e)}
                bordered={false}
                defaultValue={campus || undefined}
              >
                <Option value={""}>All</Option>
                {_campuses?.map((el, i) => (
                  <Option key={i} value={`${el.id}`}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by facility"
                onChange={(e) => setFacility(e)}
                bordered={false}
                defaultValue={facility || undefined}
              >
                <Option value={""}>All</Option>
                {facilities?.map((el, i) => (
                  <Option key={i} value={`${el.id}`}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by booking type"
                onChange={(e) => setBookingType(e)}
                bordered={false}
                defaultValue={bookingType || undefined}
              >
                <Option value={""}>All</Option>
                {_bookingTypes?.map((el, i) => (
                  <Option key={i} value={`${el.identifier}`}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={10}>
          <Col md={8} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by booking status"
                onChange={(e) => setBookingStatus(e)}
                bordered={false}
                defaultValue={bookingStatus || undefined}
              >
                <Option value={""}>All</Option>
                {_bookingStatus?.map((el, i) => (
                  <Option key={i} value={el.code}>
                    {el.externalStatus}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
      </div>
    );
  };

  const handlePreviousMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1));
    setCurrentYear((prevYear) =>
      currentMonth === 0 ? prevYear - 1 : prevYear
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth === 11 ? 0 : prevMonth + 1));
    setCurrentYear((prevYear) =>
      currentMonth === 11 ? prevYear + 1 : prevYear
    );
  };

  const onHandlePlusMoreEvents = (events) => {
    setOpen(true);
    setCurrentDateEvents(events);
  };

  const showModalViewIfEventsForSameDateisMoreThenTwo = () => {
    const selectedEventDate = currentDateEvents[0];
    return (
      <div>
        <div className="event_modal_current_slot">
          <h3>
            {moment(selectedEventDate?.startAt).format("DD")}{" "}
            {moment(selectedEventDate?.startAt).format("MMMM YYYY")}
          </h3>
        </div>
        {currentDateEvents?.map((event, index) => {
          return (
            <div key={index} className="events_in_modal">
              <div
                className="event modal_event"
                // onClick={() => onViewBookingDetails(event, props)}
                onClick={() => handleClickOnBooking(event)}
              >
                <div
                  className="event_detail"
                  style={{
                    backgroundColor: renderEventsBackgroundColor(
                      event?.booking?.bookingType?.identifier
                    ),
                  }}
                >
                  <p className="custom_event_title">{event?.booking?.title}</p>
                  <p className="custom_event_sub_title">
                    Campus: {event?.booking?.facility?.campus?.code}
                  </p>
                </div>
                <div className="custom_event_type_title">
                  <p>{event?.booking?.bookingType?.identifier}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderCaseView = (type) => {
    switch (type) {
      case 0:
        return renderMonthlyView();
        break;
      case 1:
        return <DailyView props={props} />;
        break;
      case 2:
        return <YearlyView facilities={facilities} props={props} />;
        break;

      default:
        return renderMonthlyView();
        break;
    }
  };

  const handleClickOnBooking = (booking) => {
    setBooking(booking);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onShowBookingDetails = (booking) => {
    onViewBookingDetails(booking, props);
  };

  return (
    <div className="calendar_container">
      <div className="calendar_actions">
        {selectedType === 0 ? (
          <div className="calendar_sub_actions">
            <ArrowLeftOutlined onClick={handlePreviousMonth} />
            <div className="calendar_current_month">{`${new Date(
              currentYear,
              currentMonth,
              1
            ).toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}`}</div>
            <ArrowRightOutlined onClick={handleNextMonth} />
          </div>
        ) : selectedType === 2 ? (
          <div className="calendar_sub_actions">
            <h5>Yearly View</h5>
          </div>
        ) : (
          <div className="calendar_sub_actions" />
        )}
        <div>
          <Button
            className="ant__primary__button"
            type={selectedType === 0 ? "primary" : "default"}
            style={{ marginRight: 10 }}
            onClick={() => setSelectedType(0)}
          >
            Monthly View
          </Button>
          <Button
            className="ant__primary__button"
            type={selectedType === 1 ? "primary" : "default"}
            style={{ marginRight: 10 }}
            onClick={() => setSelectedType(1)}
          >
            Daily View
          </Button>
          <Button
            className="ant__primary__button"
            type={selectedType === 2 ? "primary" : "default"}
            onClick={() => setSelectedType(2)}
          >
            Yearly View
          </Button>
        </div>
      </div>
      <div className="calendar">{renderCaseView(selectedType)}</div>
      <CustomModal
        visibility={open}
        handleCancel={() => setOpen(false)}
        handleOk={() => setOpen(false)}
        className="custom_events_modal"
      >
        {showModalViewIfEventsForSameDateisMoreThenTwo()}
      </CustomModal>
      <CustomModal
        visibility={openModal}
        handleCancel={onCloseModal}
        handleOk={() => null}
        title={"Booking Schedule"}
        modalSmall={true}
      >
        {Object.keys(_booking).length > 0 && (
          <div>
            <b style={{ fontSize: 16 }}>Booking Information</b>
            <Row gutter={10}>
              <Col md={24}>
                <div className="booking_user_details">
                  <p>
                    Title:{" "}
                    <span>
                      {_booking?.booking?.title} -{" "}
                      {renderEventTitleFromIndentifer(
                        _booking?.booking?.bookingType?.identifier
                      )}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col md={24}>
                <div className="booking_user_details">
                  <p>
                    Date: <span>{formatedDate(_booking?.startAt)}</span>
                  </p>
                </div>
              </Col>
            </Row>

            {_booking?.scheduleType === "slots" ? (
              <Row gutter={10}>
                <Col md={24}>
                  <div className="booking_user_details">
                    <p>
                      Slot:{" "}
                      <span>
                        {formatedDateWithAmAndPm(_booking?.startTime)} -
                        {formatedDateWithAmAndPm(_booking?.endTime)}
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row gutter={10}>
                <Col md={24}>
                  <div className="booking_user_details">
                    <p>
                      Slot: <span>Full Day</span>
                    </p>
                  </div>
                </Col>
              </Row>
            )}
            <Row gutter={10}>
              <Col md={24}>
                <div className="booking_user_details">
                  <p>
                    Campus:{" "}
                    <span>{_booking?.booking?.facility?.campus?.name}</span>
                  </p>
                  <p>
                    Facility: <span>{_booking?.booking?.facility?.title}</span>
                  </p>
                  <p>
                    Booking Status:{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {_booking?.status}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
            <br />
            <div>
              <b style={{ fontSize: 16 }}>User Information</b>
              <div className="booking_user_details">
                <p>
                  Full Name:{" "}
                  <span>
                    {_booking?.booking?.user?.firstName +
                      " " +
                      _booking?.booking?.user?.lastName}
                  </span>
                </p>
                <p>
                  Phone Number:{" "}
                  <span>
                    {_booking?.booking?.user?.phoneNo
                      ? _booking?.booking?.user?.phoneNo
                      : "N/A"}
                  </span>
                </p>
                <p>
                  Email:{" "}
                  <span>
                    {_booking?.booking?.user?.email
                      ? _booking?.booking?.user?.email
                      : "N/A"}
                  </span>
                </p>
                <p>
                  Gender:{" "}
                  <span>
                    {_booking?.booking?.user?.gender
                      ? _booking?.booking?.user?.gender
                      : "N/A"}
                  </span>
                </p>
              </div>
            </div>
            <br />
            <Row justify="end">
              <Button className="ant__primary__button" onClick={onCloseModal}>
                Close
              </Button>
              <div style={{ width: 30 }} />

              <Button
                type="primary"
                className="ant__primary__button"
                onClick={() => onShowBookingDetails(_booking)}
              >
                Show Details
              </Button>
            </Row>
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default FSMCalendar;
