import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { _getAllFAQsCategories } from "actions/faqs-category/faqsCatepgryAction";
import { Button, Popconfirm, Spin, Tooltip, Form, message } from "antd";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";

const EvaluationPointsConfig = (props) => {
  const renderHeader = () => {
    return (
      <TableHeader
        headerTitle={"Evaluation Points"}
        headerSubTitle={"Configure Evaluation Points"}
        headerBtnTitle={""}
      />
    );
  };

  const openConfigurations = (record) => {
    props.history.push({
      pathname: record.path,
    });
  };

  const evaluationConfig = [
    {
      title: "Education Systems",
      path: "/admin/request-types/education-systems",
    },

    {
      title: "Financial Status",
      path: "/admin/request-types/financial-status",
    },
    {
      title: "School Rating",
      path: "/admin/request-types/school-rating",
    },
    {
      title: "Educational Allowance",
      path: "/admin/request-types/educational-allowance",
    },
    {
      title: "Scoring Systems",
      path: "/admin/request-types/scoring-systems",
    },
    {
      title: "Non Academic Achievement",
      path: "/admin/request-types/non-academic-achievement",
    },
    {
      title: "CAT4 / MAP",
      path: "/admin/request-types/cat-map",
    },
    {
      title: "Personal Interview",
      path: "/admin/request-types/personal-inteview",
    },
    {
      title: "Contribution to research or creativity",
      path: "/admin/request-types/research-contribution",
    },
    {
      title: "Participation in social and community activities",
      path: "/admin/request-types/community-participation",
    },
    {
      title: "Certificates & Awards",
      path: "/admin/request-types/certificates-and-awards",
    },
  ];

  return (
    <div className="both-side-padding">
      <CommonTable
        header={renderHeader}
        data={evaluationConfig}
        hidePagination
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
          },

          {
            title: "Action",
            dataIndex: "",
            key: "x",
            render: (item) => {
              return (
                <div style={{ display: "flex" }} className="d-flex">
                  <Tooltip title={`Configure ${item.title}`}>
                    <Button
                      className="d-flex justify-content-center align-items-center"
                      shape="circle"
                      icon={<SettingOutlined />}
                      onClick={() => openConfigurations(item)}
                    />
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
      ></CommonTable>
    </div>
  );
};

export default EvaluationPointsConfig;
