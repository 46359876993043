import React, { useEffect, useState } from "react";
import "./dailyCalanderView.css";
import moment from "moment";

import { Calendar, Col, Row, Select, Form, Spin } from "antd";
import { useSelector } from "react-redux";
import { getDailyBookings, getMonthlyBookings } from "../apis";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import DailySchedule from "./daily-schedule/DailySchdule";
import { fetchAllFsmFacilities } from "views/FSM/facility-mangement/all-facilities/api";
import { renderEventsBackgroundColor } from "utils/helpers/helpers";

const { Option } = Select;

const DailyCalanderView = ({ props }) => {
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _bookingTypes = useSelector((state) => state.metaData.FSM_bookingTypes);
  const _bookingStatus = useSelector(
    (state) => state.metaData.applicationStatuses
  );
  const [facilities, setFacilities] = useState([]);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [dailyBookings, setDailyBookings] = useState([]);
  const [monthlyBookingDots, setMonthlyBookingDots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    campus: "",
    facility: "",
    bookingType: "",
    bookingStatus: "",
  });

  useEffect(() => {
    fetchAllDailyBookings();
  }, [currentDate, filters]);
  useEffect(() => {
    getAllFsmFacilities();
  }, []);
  useEffect(() => {
    fetchAllMonthlyBookings();
  }, [currentMonth]);

  const fetchAllMonthlyBookings = async () => {
    const _currentDate = moment(currentMonth).format("YYYY-MM-DD");

    try {
      const response = await getMonthlyBookings({
        date: _currentDate,
      });
      if (response.status === 200) {
        setMonthlyBookingDots(response.data.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchAllDailyBookings = async () => {
    try {
      setLoading(true);
      const _currentDate = moment(currentDate).format("YYYY-MM-DD");
      const response = await getDailyBookings({
        // date: "2023-12-30",
        date: _currentDate,
        campus: filters.campus,
        status: filters.bookingStatus,
        type: filters.bookingType,
        facility: filters.facility,
      });
      if (response.status === 200) {
        setDailyBookings(response.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };

  const onPanelChange = (value) => {
    setCurrentMonth(value);
    setCurrentDate(value);
  };
  const wrapperStyle = {
    border: `1px solid #DDDDDD`,
    borderRadius: 2,
    marginTop: 20,
  };
  // const dateCellRender = (value) => {
  //   const date = value.format("YYYY-MM-DD");

  //   const bookedEvent = monthlyBookingDots?.find(
  //     (booking) => booking.date === date
  //   );

  //   if (bookedEvent) {
  //     const backgroundColor = renderEventsBackgroundColor(
  //       bookedEvent.booking.bookingType.identifier
  //     );
  //     return <div className="booking-dot" style={{ backgroundColor }}></div>;
  //   } else {
  //     return null;
  //   }
  // };

  const dateCellRender = (value) => {
    const date = value.format("YYYY-MM-DD");

    const bookingsOnDate = monthlyBookingDots?.filter(
      (booking) => booking.date === date
    );

    if (bookingsOnDate.length > 0) {
      const uniqueBookingTypes = [
        ...new Set(
          bookingsOnDate.map(
            (booking) => booking.booking.bookingType.identifier
          )
        ),
      ];
      const limitedUniqueBookingTypes = uniqueBookingTypes.slice(0, 3);

      return (
        <div className="booking-dots-container">
          {limitedUniqueBookingTypes.map((type) => (
            <div
              key={type}
              className="booking-dot"
              style={{ backgroundColor: renderEventsBackgroundColor(type) }}
            ></div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="booking-dots-container">
          <div
            className="booking-dot"
            style={{ backgroundColor: "transparent" }}
          ></div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <Spin spinning={loading} indicator={renderLoader}>
        <div className="booking_layout__container">
          <div className="daily_view_calander_filters">
            <Row gutter={10}>
              <Col md={8} className="filterColumn">
                <Form.Item name="campus" className="dropdown">
                  <Select
                    placeholder="Filter by campus"
                    onChange={(e) => setFilters({ ...filters, campus: e })}
                    bordered={false}
                    defaultValue={filters.campus || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_campuses?.map((el, i) => (
                      <Option key={i} value={el.id}>
                        {el.code}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} className="filterColumn">
                <Form.Item name="campus" className="dropdown">
                  <Select
                    placeholder="Filter by facility"
                    onChange={(e) => setFilters({ ...filters, facility: e })}
                    bordered={false}
                    defaultValue={filters.facility || undefined}
                  >
                    <Option value={""}>All</Option>
                    {facilities?.map((el, i) => (
                      <Option key={i} value={el.id}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} className="filterColumn">
                <Form.Item name="campus" className="dropdown">
                  <Select
                    placeholder="Filter by booking type"
                    onChange={(e) => setFilters({ ...filters, bookingType: e })}
                    bordered={false}
                    defaultValue={filters.bookingType || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_bookingTypes?.map((el, i) => (
                      <Option key={i} value={el.identifier}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={10}>
              <Col md={8} className="filterColumn">
                <Form.Item name="campus" className="dropdown">
                  <Select
                    placeholder="Filter by booking status"
                    onChange={(e) =>
                      setFilters({ ...filters, bookingStatus: e })
                    }
                    bordered={false}
                    defaultValue={filters.bookingStatus || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_bookingStatus?.map((el, i) => (
                      <Option key={i} value={el.code}>
                        {el.externalStatus}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
          </div>
          <div className="bookings__layout">
            <div className="bookings__layout__left">
              <div style={wrapperStyle}>
                <Calendar
                  fullscreen={false}
                  onPanelChange={onPanelChange}
                  // headerRender={({ value, type, onChange, onTypeChange }) => {
                  //   return null;
                  // }}
                  value={moment(currentDate)}
                  onChange={(date) => setCurrentDate(date)}
                  dateCellRender={dateCellRender}
                />
              </div>
            </div>
            <div className="bookings__layout__right">
              <DailySchedule dailyBookings={dailyBookings} props={props} />
            </div>
          </div>
        </div>
      </Spin>
    </React.Fragment>
  );
};

export default DailyCalanderView;
