import {
  CalendarOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import * as React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";
import moment from "moment";

const RegistrationFeesLogs = ({ data }) => {
  const renderStageDescription = (oldStatus, newStatus) => {
    switch (oldStatus) {
      case "SG060":
        return `ERP Integrated`;
      case "SG056":
        return newStatus === "SG057" ? "Fees Paid" : "Waiting Payment";
      default:
        return oldStatus;
    }
  };
  return (
    <div>
      <h6>{renderStageDescription(data.oldStatus, data.newStatus)}</h6>
      <div className="rh-log-item">
        <div className="rh-log-row">
          <CalendarOutlined className="rh-log-icon" />
          <p>
            Date of Action :{" "}
            {formateDateWithMoment(data.createdAt, "DD MMM YYYY hh:mm A")}
          </p>
        </div>
        <div className="rh-log-row">
          <UserOutlined className="rh-log-icon" />
          <p>Action Creator : {data.user?.userName || data.user?.name}</p>
        </div>

        {data.meta && (
          <div>
            <div className="rh-log-row">
              <InfoCircleOutlined className="rh-log-icon" />
              <p>Status: {data.meta.status || "N/A"}</p>
            </div>

            <div className="rh-log-row">
              <InfoCircleOutlined className="rh-log-icon" />
              <p>Amount: {data.meta.amount || "N/A"}</p>
            </div>

            <div className="rh-log-row">
              <InfoCircleOutlined className="rh-log-icon" />
              <p>
                Payment Date:{" "}
                {moment(data.meta.dateTime).format("lll") || "N/A"}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationFeesLogs;
