export const GET_DISTRIBUTIONS = "GET_DISTRIBUTIONS";
export const ADD_DISTRIBUTIONS = "ADD_DISTRIBUTIONS";
export const UPDATE_DISTRIBUTIONS = "UPDATE_DISTRIBUTIONS";
export const DELETE_DISTRIBUTIONS = "DELETE_DISTRIBUTIONS";

export const _getDistributionAction = (data) => (dispatch) =>
  dispatch({
    type: GET_DISTRIBUTIONS,
    payload: data,
  });

export const _addDistributionAction = (data) => (dispatch) =>
  dispatch({
    type: ADD_DISTRIBUTIONS,
    payload: data,
  });

export const _updateDistributionAction = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_DISTRIBUTIONS,
    payload: data,
  });

export const _deleteDistributionAction = (id) => (dispatch) =>
  dispatch({
    type: DELETE_DISTRIBUTIONS,
    payload: id,
  });
