/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select, Checkbox, Upload } from "antd";
import { useSelector } from "react-redux";
import documentListIcon from "../../assets/img/sibar/documentListIcon.png";

import {
  FilePdfOutlined,
  UploadOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { allDocumentsLists } from "apis/documents-list/documentsListapis";

const CreateNewDocumentListForm = ({
  valuesForEdit,
  loading,
  form,
  handelPickFile,
  uploading,
  document,
  isFromDoc,
  file,
}) => {
  const { Option } = Select;
  const [download, enableDownload] = useState(false);
  const _documentsMappings = useSelector(
    (state) => state.metaData.documentsMappings
  );

  useEffect(() => {
    if (valuesForEdit) {
      enableDownload(valuesForEdit?.template.length > 0 ? true : false);
    }
  }, [valuesForEdit]);

  const mimeTypes = [
    { title: "Image", value: "image" },
    { title: "Pdf", value: "pdf" },
    { title: "Image + Pdf", value: "both" },
  ];

  const documentOf = [
    { title: "Parent", value: "parent" },
    { title: "Child", value: "child" },
  ];

  const documentRequiredFor = [
    { title: "Local", value: "local" },
    { title: "Expat", value: "expat" },
    { title: "Local + Expat", value: "both" },
  ];

  const renderPreviewFile = (localFile, url) => {
    let localFileType = "";
    let serverFileType = "";
    if (localFile) {
      const localpath = localFile.split(".");
      localFileType = localpath[localpath.length - 1];
    }
    // if (url) {
    //   const urlpath = url.split(".");
    //   serverFileType = urlpath[urlpath.length - 1];
    // }
    if (localFileType === "pdf" || serverFileType === "pdf") {
      return <FilePdfOutlined style={{ fontSize: "205%", color: "red" }} />;
    } else if (
      localFileType === "png" ||
      localFileType === "jpeg" ||
      localFileType === "jpg" ||
      localFileType === "pjpeg" ||
      localFileType === "pjp" ||
      serverFileType === "png" ||
      serverFileType === "jpeg" ||
      serverFileType === "jpg" ||
      serverFileType === "pjpeg" ||
      serverFileType === "pjp"
    ) {
      return (
        <img
          src={localFile}
          alt={"document"}
          style={{ height: "50px", width: "auto" }}
        />
      );
    } else return <p>Document file</p>;
  };

  return (
    <React.Fragment>
      {isFromDoc ? (
        <div>
          <Row gutter={[20, 0]}>
            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                label="Title (EN)"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="English title" />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                label="Title (AR)"
                name="titleAr"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Arabic title" dir="rtl" />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                label="Description (EN)"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Description is required",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="English description" />
              </Form.Item>
            </Col>

            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                label="Description (AR)"
                name="descriptionAr"
                rules={[
                  {
                    required: true,
                    message: "Description is required",
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Arabic description" dir="rtl" />
              </Form.Item>
            </Col>

            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                label="Mime Type"
                name="fileType"
                rules={[
                  {
                    required: true,
                    message: "Mime type is required",
                  },
                ]}
              >
                <Select
                  style={{ border: "#d9d9d9 1px solid" }}
                  onChange={(value) => form.setFieldsValue({ mimeType: value })}
                  placeholder={"Image / Pdf"}
                >
                  {mimeTypes.map((el, i) => (
                    <Option key={i} value={el.value}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                label="Document Type"
                name="documentOf"
                rules={[
                  {
                    required: true,
                    message: "Document type is required",
                  },
                ]}
              >
                <Select
                  style={{ border: "#d9d9d9 1px solid" }}
                  onChange={(value) =>
                    form.setFieldsValue({ documentType: value })
                  }
                  placeholder={"Parent / Child"}
                >
                  {/* {["Parent", "Child", "Additional"].map((el) => (
                  <Option value={el}>{el}</Option>
                ))} */}
                  {documentOf?.map((el, i) => (
                    <Option key={i} value={el.value}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                label="Document Required For"
                name="requiredFor"
                rules={[
                  {
                    required: true,
                    message: "Required for is required",
                  },
                ]}
              >
                <Select
                  style={{ border: "#d9d9d9 1px solid" }}
                  onChange={(value) =>
                    form.setFieldsValue({ requiredFor: value })
                  }
                  placeholder={"Local / Expat"}
                >
                  {documentRequiredFor.map((el, i) => (
                    <Option key={i} value={el.value}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                label="Document Mapping"
                name="mappingId"
                rules={[
                  {
                    required: true,
                    message: "Document Mapping is required",
                  },
                ]}
              >
                <Select
                  style={{ border: "#d9d9d9 1px solid" }}
                  onChange={(value) =>
                    form.setFieldsValue({ mappingId: value })
                  }
                  placeholder={"Document mapping"}
                >
                  {_documentsMappings.map((el, i) => (
                    <Option key={i} value={el.id}>
                      {el.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24} sm={24} style={{ width: "100%" }}>
              <Form.Item
                valuePropName="checked"
                label={""}
                name={"enableDownload"}
              >
                <Checkbox
                  checked={download}
                  onChange={(e) => enableDownload(e.target.checked)}
                  defaultChecked={download}
                >
                  Enable download
                </Checkbox>
              </Form.Item>
            </Col>
            {download === true && (
              <>
                <Col md={24} sm={24} style={{ width: "100%" }}>
                  <Form.Item
                    label="Instructions"
                    name="instructions"
                    rules={[
                      {
                        required: true,
                        message: "Instruction is required",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="Instructions in English" />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24} style={{ width: "100%" }}>
                  <Form.Item
                    label="Instructions (AR)"
                    name="instructionsAr"
                    rules={[
                      {
                        required: true,
                        message: "Instruction is required",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Instructions in Arabic"
                      dir={"rtl"}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24} style={{ width: "100%" }}>
                  <input
                    type="file"
                    onChange={(e) => handelPickFile(e)}
                    name="template"
                    required={
                      download && !(!!valuesForEdit?.template || !!document)
                    }
                  />
                  <br />
                  {document || valuesForEdit?.template
                    ? renderPreviewFile(document, valuesForEdit?.template)
                    : null}
                </Col>
              </>
            )}
          </Row>
          <Row justify={"end"}>
            {uploading ? (
              <Button loading={uploading}>{"uploading..."}</Button>
            ) : (
              <Button loading={loading} htmlType="submit">
                {valuesForEdit ? "Update" : loading ? "...Adding" : "Add"}
              </Button>
            )}
          </Row>
        </div>
      ) : (
        <div>
          <Row gutter={[20, 0]}>
            <Col md={24} sm={24}>
              <Form.Item
                label="Document Type"
                name="documentOf"
                rules={[
                  {
                    required: true,
                    message: "Document type is required",
                  },
                ]}
              >
                <Select
                  style={{ border: "#d9d9d9 1px solid" }}
                  onChange={(value) =>
                    form.setFieldsValue({ documentType: value })
                  }
                  placeholder={"Parent / Child"}
                >
                  {documentOf?.map((el, i) => (
                    <Option key={i} value={el.value}>
                      {el.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <>
              <Col md={24} sm={24}>
                <input
                  type="file"
                  onChange={(e) => handelPickFile(e)}
                  name="template"
                  required={
                    download && !(!!valuesForEdit?.template || !!document)
                  }
                  accept=".jpeg, .jpg, .png, .pjp, .pjpeg, .pdf, .doc, .docx"
                />
                <br />
                {file ? renderPreviewFile(file, valuesForEdit?.template) : null}
              </Col>
            </>
          </Row>
          <Row justify={"end"}>
            {uploading ? (
              <Button loading={uploading}>{"uploading..."}</Button>
            ) : (
              <Button loading={loading} htmlType="submit">
                {valuesForEdit ? "Update" : loading ? "...Adding" : "Add"}
              </Button>
            )}
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};
export default CreateNewDocumentListForm;
