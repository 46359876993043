import client from "apis/config";

export const getBannersAPI = async (page = 1) => {
  return await client.get(`banners?page=${page}&limit=10`);
};
export const addBannersAPI = async (data) => {
  return await client.post("banners", data);
};
export const updateBannersAPI = async (data, id) => {
  return await client.put(`banners/${id}`, data);
};
export const deleteBannersAPI = async (id) => {
  return await client.delete(`banners/${id}`);
};
