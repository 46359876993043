/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Checkbox,
  Table,
  Select,
  Spin,
  Popconfirm,
} from "antd";
import { useSelector } from "react-redux";
import { getApplicationDefaultStages } from "apis/applications-apis/applications";
import { requestErrorHandel } from "utils/helpers/helpers";
import { assignApplicationStages } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import { _updateApplications } from "actions/applications/applications-actions";
import { getGeneralConfigurations } from "apis/general-configuration/generalConfigurationapi";
import { LoadingOutlined } from "@ant-design/icons";
import { showValidationError } from "utils/helpers/helpers";
import { PopconfirmProps } from "utils/constants";
import { truncate } from "lodash";

const ApprovalSteps = (props) => {
  const { application } = props?.history?.location?.state;
  const id = application?.id;
  const applicationType = application?.applicationType;
  const eServiceType = application?.eService?.type;
  const grade = application?.childDetails?.newGrade;

  const _stages = useSelector((state) =>
    state.metaData.stages.filter(
      (stage) =>
        stage.slug !== "registration" &&
        stage.slug !== "review" &&
        stage.applicationType === applicationType
    )
  );
  const [loading, setLoading] = useState(false);
  const [defaultStagesId, setDefaultStagesId] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);
  const [allNotification, setAllNotification] = useState({
    emial: false,
    mobile: false,
    sms: false,
  });
  const [dataSource, setDataSource] = useState();

  const reOrderStages = () => {
    if (
      (applicationType === "ENR" &&
        // grade 6 to 12
        grade &&
        (grade.value == 9 ||
          grade.value == 10 ||
          grade.value == 11 ||
          grade.value == 12 ||
          grade.value == 13 ||
          grade.value == 14 ||
          grade.value == 15)) ||
      (application?.childDetails?.campus.metaValue == "AAC" &&
        grade.value >= 9 &&
        grade.value < 16)
    ) {
      const stagesClone = [..._stages];
      const secondPart = stagesClone.filter(
        (el) => el.slug !== "transcript_review" && el.slug !== "entrance_test"
      );
      const firstPart = stagesClone.splice(2, 2);
      const newArray = [...firstPart, ...secondPart];
      setDataSource(newArray);
    } else {
      // if transportation, remove bus allocation for now
      setDataSource(_stages.filter((el) => el.slug !== "bus_allocation"));
    }
  };
  useEffect(() => {
    reOrderStages();
    fetchGeneralConfigurationData();
    // for enr applications final_review_approval and registration_fees
    // is checked and disabled
    applicationType === "ENR" && fetchApplicationDefaultStages();
    if (
      applicationType === "ASR" ||
      applicationType === "TRP" ||
      applicationType === "TFD" ||
      applicationType === "UPD"
    ) {
      // for asr, trp, upd applications final_approval is checked and disabled
      // payment is checked and disabled if eservice is paid
      // payment is unchecked and disabled if eservice is free
      // integration is checked and disabled for upd
      selectOtherApplicationDefaultStage();
    }
  }, []);

  const fetchApplicationDefaultStages = async () => {
    try {
      let finalReviewId = _stages.find(
        (el) => el.slug === "final_review_approval"
      );
      let registrationFeeId = _stages.find(
        (el) => el.slug === "registration_fees"
      );
      let enrDefaultStages = [finalReviewId.id, registrationFeeId.id];
      // setDefaultStagesId(enrDefaultStages);
      setSelectedStages(enrDefaultStages);
      setLoading(true);
      const response = await getApplicationDefaultStages({ id: id });
      if (response.status == 200) {
        response.data.data.forEach((stage) => {
          if (
            stage.slug === "registration" ||
            stage.slug === "final_review_approval" ||
            stage.slug === "registration_fees"
          ) {
            return;
          }
          enrDefaultStages.push(stage.id);
        });
        setSelectedStages(
          enrDefaultStages.map((param, index) => {
            return param;
          })
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const selectOtherApplicationDefaultStage = () => {
    let finalApproval = _stages.find(
      (stage) => stage.slug === "final_approval"
    );
    let defaultStage = [finalApproval?.id];
    if (
      eServiceType === "paid" &&
      (applicationType === "TRP" || applicationType === "ASR")
    ) {
      let payment = _stages.find((stage) => stage.slug === "payment");
      defaultStage.push(payment?.id);
    } else if (applicationType === "UPD") {
      let integration = _stages.find((stage) => stage.slug === "integration");
      if (integration !== undefined && integration !== null) {
        defaultStage.push(integration?.id);
      }
    }

    setDefaultStagesId(defaultStage);
  };

  const fetchGeneralConfigurationData = async () => {
    try {
      const response = await getGeneralConfigurations();
      if (response.status == 200) {
        const data = response.data.data;
        setAllNotification({
          emial: data.email_notifications == 1 ? true : false,
          mobile: data.mobile_notifications == 1 ? true : false,
          sms: data.sms_notifications == 1 ? true : false,
          online: data.online_school == 1 ? true : false,
          enrolment: data.enrollment_school == 1 ? true : false,
          actual: data.actual_school == 1 ? true : false,
        });
      }
    } catch (error) {
      await requestErrorHandel({ error: error });
    }
  };
  const selectStages = (e, record, index) => {
    const stageId = record.id;
    const _selectedStages = [...selectedStages];
    if (e.target.checked) {
      if (_selectedStages.findIndex((el) => el == stageId) < 0) {
        setSelectedStages([stageId, ...selectedStages]);
      }
    } else {
      if (_selectedStages.findIndex((el) => el == stageId) !== -1) {
        _selectedStages.splice(
          _selectedStages.findIndex((el) => el == stageId),
          1
        ); //deleting
        setSelectedStages(_selectedStages);
      }
    }
  };

  const submitStages = async () => {
    let allStages = dataSource.filter(
      (el) => selectedStages.indexOf(el.id) > -1
    );
    allStages = allStages.map((el) => el.id);
    if (applicationType !== "ENR") {
      allStages = [...allStages, ...defaultStagesId];
    }

    try {
      if (allStages.length > 0) {
        setLoading(true);
        const response = await assignApplicationStages({
          appId: id,
          stages: allStages,
          email_notifications: allNotification.emial,
          sms_notifications: allNotification.sms,
          mobile_notifications: allNotification.mobile,
        });
        if (response.status == 200) {
          successMessage({
            message: "Application approval started successfully",
          });
          props.history.goBack();
          setLoading(false);
        }
      } else {
        showValidationError("Select one or more stages before submitting");
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  const getDisabledStagesOtherApplications = (record) => {
    return applicationType === "ASR" ||
      applicationType === "TRP" ||
      applicationType === "TFD" ||
      applicationType === "UPD"
      ? record.slug === "final_approval" ||
          record.slug === "payment" ||
          record.slug === "integration"
      : false;
  };
  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;

  return (
    <div className="both-side-padding">
      <Row gutter={[20, 12]} justify="end">
        <Col sm={24} md={24}>
          <Card hoverable className="antCardBorder antCard">
            {application && <ApplicationInfo application={application} />}
          </Card>
        </Col>
      </Row>
      <Spin spinning={loading} indicator={loader}>
        <Card
          hoverable
          className="antCardBorder antCard"
          style={{ marginTop: 20 }}
        >
          <Row gutter={[20, 12]} justify="space-between">
            <Col md={15}>
              <h5>Approval Stages</h5>
              <p>
                Please refine the stages that we need for this application in
                future, uncheck the stages which this application will not pass
                through
              </p>
            </Col>
            <Col md={3}>
              <Popconfirm
                className="confirm_popUp"
                {...PopconfirmProps}
                title={
                  "Are you sure you want to submit this application to the next stage?"
                }
                onConfirm={submitStages}
              >
                <Button loading={loading} type="primary" className="appButton">
                  Submit
                </Button>
              </Popconfirm>
            </Col>
          </Row>
          {/* <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Row>
            <Col lg={12}>
              <Select
                labelInValue
                size="large"
                defaultValue={{ value: "Select target school" }}
                style={{ width: "250px" }}
                // onChange={handleChange}
              >
                {campusRelatedSchools.map((school) => {
                  return <Option value={school.name}>{school.name}</Option>;
                })}
              </Select>
            </Col>
          </Row>
        </div> */}
          <div style={{ marginBottom: "20px" }}>
            <>
              <div style={{ marginBottom: "20px" }}>
                <h5>Select Notifications Type</h5>
              </div>
              <Row>
                <Col lg={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <p>Email</p>
                    <Checkbox
                      value={allNotification.emial}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          emial: !allNotification.emial,
                        })
                      }
                      checked={allNotification.emial}
                      // defaultChecked={allNotification.emial ? "checked" : ""}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Mobile Notification</p>
                    <Checkbox
                      value={allNotification.mobile}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          mobile: !allNotification.mobile,
                        })
                      }
                      checked={allNotification.mobile}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>SMS Notification</p>
                    <Checkbox
                      value={allNotification.sms}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          sms: !allNotification.sms,
                        })
                      }
                      checked={allNotification.sms}
                    />
                  </div>
                </Col>
              </Row>
            </>
          </div>
          <Table
            dataSource={dataSource}
            pagination={false}
            columns={[
              {
                title: "Order",
                dataIndex: "id",
                key: "id",
                render: (_, __, index) => {
                  return index + 1;
                },
              },
              {
                title: "Stage",
                dataIndex: "",
                key: "title",
                render: (record) => record?.title,
              },
              {
                title: "Add to process",
                dataIndex: "",
                render: (record, _, index) => {
                  if (applicationType === "ENR") {
                    return (
                      <Checkbox
                        style={{ minWidth: "5rem", justifyContent: "center" }}
                        value={record}
                        disabled={
                          record.slug === "final_review_approval" ||
                          record.slug === "registration_fees"
                        }
                        defaultChecked={
                          defaultStagesId.includes(record.id) ? "checked" : ""
                        }
                        checked={selectedStages.includes(record.id)}
                        onChange={(e) => selectStages(e, record, index)}
                      />
                    );
                  } else {
                    return (
                      <Checkbox
                        style={{ minWidth: "5rem", justifyContent: "center" }}
                        value={record}
                        onChange={(e) => selectStages(e, record, index)}
                        disabled={getDisabledStagesOtherApplications(record)}
                        defaultChecked={
                          defaultStagesId.includes(record.id) ? "checked" : ""
                        }
                      />
                    );
                  }
                },
              },
            ]}
          ></Table>
        </Card>
      </Spin>
    </div>
  );
};
export default ApprovalSteps;
