import client from "apis/config";

export const getRulesAPI = async (queryParams) => {
  return await client.get(`discount-rules?${queryParams}`);
};

export const addRulesAPI = async (data) => {
  return await client.post(`discount-rules`, data);
};

export const updateRulesAPI = async (data, id) => {
  return await client.put(`discount-rules/${id}`, data);
};

export const deleteRulesAPI = async (id) => {
  return await client.delete(`discount-rules/${id}`);
};

// -------- Rules Configuration ------------

export const getRulesConfigurationAPI = async (id, pagination) => {
  return await client.get(`discount-rules/configurations/${id}?${pagination}`);
};

export const addRulesConfigurationAPI = async (data) => {
  return await client.post(`discount-rules/configurations`, data);
};

export const updateRulesConfigurationAPI = async (id, data) => {
  return await client.put(`discount-rules/configurations/${id}`, data);
};

export const deleteRulesConfigurationAPI = async (id) => {
  return await client.delete(`discount-rules/configurations/${id}`);
};
