import client from "apis/config";
const endPointUrl = "scc/cases";

export const fetchAllSccOffenseManagementRequests = async ({
  limit = "10",
  campus = "",
  school = "",
  category = "",
  standard = "",
  indicator = "",
  year = "",
  term = "",
  search = "",
  status = "",
  stage = "",
  stageStatus = "",
  page,
}) => {
  return await client.get(
    `/${endPointUrl}?limit=${limit}&page=${page}&campus=${campus}&school=${school}&category=${category}&standard=${standard}&indicator=${indicator}&search=${search}&year=${year}&term=${term}&status=${status}&stage=${stage}&stageStatus=${stageStatus}`
  );
};

export const addNewSccOffenseManagement = async ({ data }) => {
  return await client.post(`/${endPointUrl}`, data);
};

export const updateSccOffenseManagement = async ({ id, data }) => {
  return await client.put(`/${endPointUrl}/${id}`, data);
};

export const deleteSccOffenseManagement = async ({ id }) => {
  return await client.delete(`/${endPointUrl}/${id}`);
};

export const getSccConductCaseDetails = async ({ caseId }) => {
  return await client.get(`scc/cases/detail/${caseId}`);
};
export const getSccConductCaseLogs = async ({ caseId }) => {
  return await client.get(`scc/cases/logs/${caseId}`);
};

export const getStudentsList = async ({ search }) => {
  return await client.get(`scc/students?search=${search}`);
};

export const handleProcessConductCase = async ({ payload }) => {
  return await client.post("scc/cases/process", payload);
};
export const handleConductCaseStageApproval = async ({ payload }) => {
  return await client.post("scc/cases/approval", payload);
};

export const exportCasesToExcel = async ({ payload }) => {
  return await client.post("scc/cases/export", payload);
};

export const getStudentListsFromENS = async ({ searchKey }) => {
  return await client.get(`/scc/SearchStudents?search=${searchKey}`);
};
export const getTeacherOrEmployeesListsFromENS = async ({ payload }) => {
  return await client.post("/kayan/searchEmployee", payload);
};
