/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Button, Card, Checkbox, Col, Popconfirm, Row, Spin, Tabs } from "antd";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import { applicationProcess } from "apis/applications-apis/applications";

import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import PreFinalApprovalForm from "components/Forms/pre-final-approval-form/PreFinalApprovalForm";
import { addStageApproval } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { _updateApplications } from "actions/applications/applications-actions";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { PopconfirmProps } from "utils/constants";
import styles from "styles/applicationDetails.module.css";
import { renderLoader } from "utils/helpers/helpers";
import UPDStudentTable from "components/UPDStudentsTable/UPDStudentsTable";

const { TabPane } = Tabs;

const UpdateDetailsIntegration = (props) => {
  const [showApproveModal, toggleApproveModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const [activeTab, setActiveTab] = React.useState("details");
  const [sisEnabled, setSISEnabled] = React.useState(false);
  const [erpEnabled, setERPEnabled] = React.useState(false);

  const applicationId = props?.application?.id;

  React.useEffect(() => {
    props.application && getLogs();
  }, [props.application]);

  const getLogs = async () => {
    try {
      // setLoading(true);
      const response = await getApplicationStagesLogs({ id: applicationId });

      if (response.status == 200) {
        var applicationCurrentStage = currentStageDetails;
        if (
          applicationCurrentStage == undefined ||
          applicationCurrentStage == ""
        ) {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == props.application.stage.slug
          );
        } else {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == currentStageDetails.stageSlug
          );
        }
        setcurrentStageDetails(applicationCurrentStage);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onSubmitApprove = (values) => {
    //   approve, notes

    toggleApproveModal(false);
    values["appStageId"] = props.application?.stage.id;
    // setApprove(values.approve?.toUpperCase() || "");
    onAddStagesApproval(values);
  };

  const onAddStagesApproval = async (values) => {
    try {
      setLoading(true);
      const response = await addStageApproval({ data: values });
      if (response.status == 200) {
        successMessage({ message: "Remarks added successfully" });
        props.refetch();
        setLoading(false);
        getLogs();
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const getTitle = () => {
    return currentStageDetails?.stageStatus?.internalStatus;
  };

  const handelApplicationProcess = async (action, message) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: props.application?.id,
        action: action,
        schoolId: "",
      });
      if (response?.status == 200) {
        props.refetch();
        getLogs();
        message &&
          successMessage({
            message,
          });
        setLoading(false);
        if (action === "check_posting") {
          return response.data?.data;
        }
      } else throw response;
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  const renderActionButtons = (stageStatus) => {
    if (stageStatus === "SG1240") {
      /* Integrate SIS Button */
      /* SG912, SG750, SG612 = SIS INTEGRATION REQUIRED */
      // application is pending and all data is aapproved
      return (
        <div className={styles.updateErpBtnRow}>
          <Popconfirm
            {...PopconfirmProps}
            title={"Are you sure you want to integrate SIS?"}
            onConfirm={() =>
              handelApplicationProcess(
                "integrate_sis",
                "SIS integrated successfully!"
              )
            }
            disabled={
              props.application.studentAlreadyExists ? !sisEnabled : false
            }
          >
            <Button type="primary">Integrate SIS</Button>
          </Popconfirm>
          {props.application.studentAlreadyExists && (
            <Checkbox
              onChange={(e) => setSISEnabled(e.target.checked)}
              value={sisEnabled}
              className={styles.studentExistsChecbox}
            >
              Student Already Exists - Student No:
              {props.application?.studentNumber || ""}
            </Checkbox>
          )}
        </div>
      );
    } else if (stageStatus === "SG1260") {
      /* Integrate DMS Button */
      /* SG916, SG770, SG616 = DMS INTEGRATION REQUIRED */
      return (
        <Popconfirm
          {...PopconfirmProps}
          onConfirm={() =>
            handelApplicationProcess(
              "integrate_dms",
              "DMS integrated successfully!"
            )
          }
          title={"Are you sure you want to integrate DMS"}
        >
          <Button type="primary">Integrate DMS</Button>
        </Popconfirm>
      );
    } else if (stageStatus === "SG1250") {
      /* Integrate ERP Button */
      return (
        <div className={styles.updateErpBtnRow}>
          <Popconfirm
            {...PopconfirmProps}
            title={"Are you sure you want to integrate ERP"}
            onConfirm={() =>
              handelApplicationProcess(
                "integrate_erp",
                "ERP integrated successfully!"
              )
            }
            disabled={
              props.application.studentAlreadyExists ? !erpEnabled : false
            }
          >
            <Button type="primary">Integrate ERP</Button>
          </Popconfirm>

          {props.application.studentAlreadyExists && (
            <Checkbox
              onChange={(e) => setERPEnabled(e.target.checked)}
              checked={erpEnabled}
              className={styles.studentExistsChecbox}
            >
              Student Already Exists - Student No:
              {props.application?.studentNumber || ""}
            </Checkbox>
          )}
        </div>
      );
    } else if (stageStatus === "SG1270") {
      /* Integrate ERP Button */
      return (
        <div className={styles.updateErpBtnRow}>
          <Popconfirm
            {...PopconfirmProps}
            title={"Are you sure you want to integrate eservices"}
            onConfirm={() =>
              handelApplicationProcess(
                "integrate_ens",
                "EServices integrated successfully!"
              )
            }
          >
            <Button type="primary">Integrate EServices</Button>
          </Popconfirm>
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={renderLoader}>
        {props.application ? (
          <div>
            <Row gutter={[20, 12]}>
              <Col sm={24} md={11}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>

              <Col sm={24} md={13}>
                <Card hoverable className="antCardBorder antCard">
                  <Row align="middle" justify="space-between">
                    <div>
                      <h5>{getTitle()}</h5>
                    </div>

                    <Row justify="space-between">
                      {props.application.hasStagePermission &&
                        renderActionButtons(
                          props.application?.stage?.status?.code
                        )}
                    </Row>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Tabs
              activeKey={activeTab}
              onChange={(tab) => setActiveTab(tab)}
              style={{ marginTop: "1rem" }}
              tabBarGutter={40}
            >
              <TabPane tab={<h6>Details</h6>} key="details">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={props.application.applicationType?.toLowerCase()}
                />
              </TabPane>
              <TabPane tab={<h6>Documents</h6>} key="documents">
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>
              <TabPane tab={<h6>Stages History</h6>} key="logs">
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>
            <UPDStudentTable application={props.application} />

            <CustomModal
              visibility={showApproveModal}
              handleCancel={() => toggleApproveModal(false)}
              title="Final Approval Remarks"
            >
              <PreFinalApprovalForm
                onFinish={onSubmitApprove}
                loading={loading}
              />
            </CustomModal>
          </div>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default UpdateDetailsIntegration;
