import client from "apis/config";
// fetchBoth Header and Footer
export const fetchAllHeadersAndFooters = async (limit = "10", type = "") => {
  return await client.get(`/templates/sections?limit=${limit}&type=${type}`);
};
// templates headers crud
export const fetchAllHeaders = async (limit = "10", type = "header") => {
  return await client.get(`/templates/sections?limit=${limit}&type=${type}`);
};

export const addNewHeader = async ({ data }) => {
  return await client.post("/templates/sections", data);
};

export const updateHeader = async ({ id, data }) => {
  return await client.put(`/templates/sections/${id}`, data);
};

export const deleteHeader = async ({ id }) => {
  return await client.delete(`/templates/sections/${id}`);
};
// templates Footer crud
export const fetchAllFooters = async (limit = "10", type = "footer") => {
  return await client.get(`/templates/sections?type=${type}`);
};

export const addNewFooter = async ({ data }) => {
  return await client.post("/templates/sections", data);
};

export const updateFooter = async ({ id, data }) => {
  return await client.put(`/templates/sections/${id}`, data);
};

export const deleteFooter = async ({ id }) => {
  return await client.delete(`/templates/sections/${id}`);
};
// templates section curud
export const fetchAllTemplates = async (limit = "10") => {
  return await client.get(`/templates?limit=${limit}`);
};

export const addNewTemplate = async ({ data }) => {
  return await client.post("/templates", data);
};

export const updateTemplate = async ({ id, data }) => {
  return await client.put(`/templates/${id}`, data);
};

export const deleteTemplate = async ({ id }) => {
  return await client.delete(`/templates/${id}`);
};
// templates placeholders curud
export const fetchAllPlaceholders = async (limit = "10", page) => {
  return await client.get(`/template-placeholders?limit=${limit}&page=${page}`);
};

export const addNewPlaceholder = async ({ data }) => {
  return await client.post("/template-placeholders", data);
};

export const updatePlaceholder = async ({ id, data }) => {
  return await client.put(`/template-placeholders/${id}`, data);
};

export const deletePlaceholder = async ({ id }) => {
  return await client.delete(`/template-placeholders/${id}`);
};
