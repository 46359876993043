import client from "apis/config";

export const getBusesAPI = async (page = 1, limit = 10, campus = "") => {
  return await client.get(`buses?page=${page}&limit=${limit}&campus=${campus}`);
};
export const addBusAPI = async (data) => {
  return await client.post("buses", data);
};
export const updateBusAPI = async (data, id) => {
  return await client.put(`buses/${id}`, data);
};
export const deleteBusAPI = async (id) => {
  return await client.delete(`buses/${id}`);
};
