import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import { generateUniqueColumnKey } from "utils/helpers/helpers";
import SccDistinguishIndicatorForm from "./SccDistinguishIndicatorForm";
import {
  fetchAllSccDistinguishIndicators,
  addNewSccDistinguishIndicator,
  updateSccDistinguishIndicator,
  deleteSccDistinguishIndicator,
} from "./api";

const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const SccDistinguishIndicators = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [recordId, setRecordId] = useState("");

  const [sccBehIndicatorForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            sccBehIndicatorForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Distinguish Indicators"}
          headerSubTitle={"Manage Distinguish Indicators"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllSccDistinguishIndicators();
  }, [page]);

  const getAllSccDistinguishIndicators = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSccDistinguishIndicators({ page: page });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addSccBehaviourIndicator = async (data) => {
    try {
      setLoading(true);
      const response = await addNewSccDistinguishIndicator({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Distinguish Indicator added successfully" });
        await getAllSccDistinguishIndicators();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateSccDistinguishIndicator = async (data) => {
    try {
      setLoading(true);
      const response = await updateSccDistinguishIndicator({
        id: recordId,
        data: data,
      });
      if (response.status === 200) {
        successMessage({
          message: "Distinguish Indicator updated successfully",
        });
        await getAllSccDistinguishIndicators();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteSccDistinguishIndicator = async (id) => {
    try {
      setLoading(true);
      const response = await deleteSccDistinguishIndicator({ id: id });
      if (response.status === 200) {
        successMessage({
          message: "Distinguish Indicator deleted successfully",
        });
        await getAllSccDistinguishIndicators();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      type: "distinguish_behaviour",
      score: Number(values?.score),
      categoryId: Number(values?.categoryId),
      standardId: Number(values?.standardId),
    };
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateSccDistinguishIndicator(data);
    } else {
      await addSccBehaviourIndicator(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    sccBehIndicatorForm.setFieldsValue({
      ...record,
      categoryId: record?.category?.id,
      standardId: record?.standard?.id,
    });
    setRecordId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Unique Code",
              dataIndex: "identifier",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Title",
              dataIndex: "title",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Category",
              key: generateUniqueColumnKey(),

              render: (record) => (
                <span style={{ textTransform: "capitalize" }}>
                  {record?.category?.title}
                </span>
              ),
            },
            {
              title: "Standard",
              key: generateUniqueColumnKey(),

              render: (record) => (
                <span style={{ textTransform: "capitalize" }}>
                  {record?.standard?.title}
                </span>
              ),
            },
            {
              title: "Score",
              dataIndex: "score",
              key: generateUniqueColumnKey(),
            },
            {
              title: "Action",
              dataIndex: "",
              key: generateUniqueColumnKey(),

              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Distinguish Indicator">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Distinguish Indicator?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() =>
                        handleDeleteSccDistinguishIndicator(item.id)
                      }
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Distinguish Indicator`}
        >
          <SccDistinguishIndicatorForm
            onSubmit={onSubmit}
            formName={sccBehIndicatorForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default SccDistinguishIndicators;
