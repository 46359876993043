import React, { useCallback, useEffect, useRef, useState } from "react";
import "../stylesScc.css";

import SccDashboardTabs from "./SccDashboardTabs";
import SccMonthlyReport from "./monthly/SccMonthlyReport";
import { useSelector } from "react-redux";
import { Row, Select, Spin } from "antd";
import { _terms } from "utils/constants";
import { _months } from "utils/constants";
import { getSccCasesReports } from "./api";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";

import GeneratePdfButton from "./pdf/GeneratePdf";
import ENSWideCamparison from "./comparison/ENSWideCamparison";
import { _types } from "utils/constants";
const { Option } = Select;

const SccBasicReports = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const _campuses = useSelector((state) => state.metaData.SCC_campus);
  const _years = useSelector((state) => state.metaData.SCC_years);
  const _grades = useSelector((state) => state.metaData.SCC_grades);

  const [data, setData] = useState({});
  const [monthlyData, setMonthlyData] = useState({});
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    year: _years[0].id,
    campus: "",
    term: "first",
    type: "behaviour",
  });

  const [currentYear, setCurrentYear] = useState("");

  useEffect(() => {
    const currentYear = _years.find((year) => year.id === filters.year);
    if (currentYear) {
      setCurrentYear(currentYear?.name);
    }
  }, [filters.year]);

  useEffect(() => {
    fetchSccCasesReports();
  }, [filters, selectedTab]);
  const fetchSccCasesReports = async () => {
    try {
      setLoading(true);
      const response = await getSccCasesReports({
        term: filters.term,
        campus: filters.campus,
        year: filters.year,
        type: filters.type,
      });
      if (response.status === 200) {
        setData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const renderCaseView = (tab) => {
    switch (tab) {
      case 0:
        return (
          <ENSWideCamparison
            data={data}
            type={filters.type}
            year={currentYear}
          />
        );
        break;
      case 1:
        return (
          <ENSWideCamparison
            data={data}
            type={filters.type}
            year={currentYear}
          />
        );
        break;
      default:
        break;
    }
  };

  const findYearName = _years.find((year) => year.id === filters.year);
  const findCampus = _campuses.find((campus) => campus.id === filters.campus);

  const pdfData = {
    filters: {
      ...filters,
      year: findYearName ? findYearName.name : "All",
      campus: findCampus ? findCampus.code : "All",
    },
    title: selectedTab === 0 ? "ENS Wide Comparison" : "Monthly Report",
    totalPositiveCase: data?.totalPositiveCases,
    totalNegativeCase: data?.negativeLevelCases?.total,
  };

  const onTab1 = () => {
    setSelectedTab(0);
    setFilters({
      ...filters,
      campus: "",
    });
  };
  const onTab2 = () => {
    setSelectedTab(1);
    setFilters({
      ...filters,
      campus: 1,
    });
  };

  return (
    <div className="general_container__scc">
      <div className="scc_dashboard_filters" id="pdf-filters">
        <div className="dropdown" style={{ width: "210px", marginLeft: 5 }}>
          <Select
            style={{ width: "100%" }}
            placeholder={"Filter By Year"}
            bordered={false}
            onChange={(e) => setFilters({ ...filters, year: e })}
            value={filters?.year || undefined}
          >
            {_years?.map((el, i) => (
              <Option key={i} value={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="dropdown" style={{ width: "210px", marginLeft: 5 }}>
          <Select
            style={{ width: "100%" }}
            placeholder={"Filter By Term"}
            bordered={false}
            onChange={(e) => setFilters({ ...filters, term: e })}
            value={filters?.term || undefined}
          >
            {_terms?.map((el, i) => (
              <Option key={i} value={el.value}>
                {el.title}
              </Option>
            ))}
          </Select>
        </div>
        {selectedTab === 1 && (
          <div className="dropdown" style={{ width: "210px", marginLeft: 5 }}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Filter By Campus"}
              bordered={false}
              onChange={(e) => setFilters({ ...filters, campus: e })}
              value={filters?.campus || undefined}
            >
              {_campuses?.map((el, i) => (
                <Option key={i} value={el.id}>
                  {el.code}
                </Option>
              ))}
            </Select>
          </div>
        )}

        <div className="dropdown" style={{ width: "210px", marginLeft: 5 }}>
          <Select
            style={{ width: "100%" }}
            placeholder={"Filter By Type"}
            bordered={false}
            onChange={(e) => setFilters({ ...filters, type: e })}
            value={filters?.type || undefined}
          >
            {_types?.map((el, i) => (
              <Option key={i} value={el.value}>
                {el.title}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <GeneratePdfButton pdfData={pdfData} />

      <SccDashboardTabs
        onTab1={onTab1}
        onTab2={onTab2}
        tabIndex={selectedTab}
      />
      <div id="capture">
        <Spin spinning={loading} indicator={renderLoader}>
          {renderCaseView(selectedTab)}
        </Spin>
      </div>
    </div>
  );
};

export default SccBasicReports;
