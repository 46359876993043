import client from "apis/config";

// Teachers crud
export const fetchAllTeachers = async (limit = "10") => {
  return await client.get(`/tcc-teachers?limit=${limit}`);
};

export const addNewTeacher = async ({ data }) => {
  return await client.post("/tcc-teachers", data);
};

export const updateTeacher = async ({ id, data }) => {
  return await client.put(`/tcc-teachers/${id}`, data);
};

export const deleteTeacher = async ({ id }) => {
  return await client.delete(`/tcc-teachers/${id}`);
};
// Courses crud
export const fetchAllCourses = async (limit = "10") => {
  return await client.get(`/tcc-courses?limit=${limit}`);
};

export const addNewCourse = async ({ data }) => {
  return await client.post("/tcc-courses", data);
};

export const updateCourse = async ({ id, data }) => {
  return await client.put(`/tcc-courses/${id}`, data);
};

export const deleteCourse = async ({ id }) => {
  return await client.delete(`/tcc-courses/${id}`);
};
// TCC schedules crud
export const fetchAllSchedules = async (limit = "10") => {
  return await client.get(`/tcc-schedules?limit=${limit}`);
};

export const addNewSchedules = async ({ data }) => {
  return await client.post("/tcc-schedules", data);
};

export const updateSchedules = async ({ id, data }) => {
  return await client.put(`/tcc-schedules/${id}`, data);
};

export const deleteSchedules = async ({ id }) => {
  return await client.delete(`/tcc-schedules/${id}`);
};
// TCC Sections crud
export const fetchAllSections = async (id) => {
  return await client.get(`/eservices/sections/${id}`);
};

export const addNewSection = async ({ data }) => {
  return await client.post("/eservices/sections", data);
};

export const updateSection = async ({ id, data }) => {
  return await client.put(`/eservices/sections/${id}`, data);
};

export const deleteSection = async ({ id }) => {
  return await client.delete(`/eservices/sections/${id}`);
};
