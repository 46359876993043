import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Form, Table, Tooltip, Spin } from "antd";
import CustomModal from "components/CustomModal/CustomModal";
import AdmissionStatusesFrom from "components/statusesTables/AdmissionStatusesFrom";
import { getAdmissionStatuses } from "apis/statuses-apis/appandStageStatuses";
import { requestErrorHandel } from "utils/helpers/helpers";
import { updategetAdmissionStatuses } from "apis/statuses-apis/appandStageStatuses";
import { successMessage } from "utils/helpers/helpers";
// import { convertToTitleCase } from "utils/helpers/helpers";
import { removeUndercore } from "utils/helpers/helpers";
import { capitalize } from "lodash";
import CommonTable from "components/Tables/CommonTable";
import { renderLoader } from "utils/helpers/helpers";

function SccStageStatuses(props) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [formLayout] = useState("vertical");
  const [componentSize] = useState("large");
  const [editApplicationFields, setEditApplicationFiels] = useState(null);
  const [loading, setLoading] = useState(false);
  const [admissionStatuses, setAdmissionStatuses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState();

  const serviceName = "SCC";

  useEffect(() => {
    if (editApplicationFields) {
      form.setFieldsValue(editApplicationFields);
    } else {
      form.resetFields();
    }
  }, [editApplicationFields]);

  useEffect(() => {
    fetchSccStageStatuses();
  }, [currentPage, pageSize]);

  const fetchSccStageStatuses = async () => {
    try {
      const queryParams = `?page=${currentPage}&limit=${pageSize}`;

      setLoading(true);
      const response = await getAdmissionStatuses({
        type: "stages",
        applicationType: serviceName,
        queryParams,
      });
      if (response.status === 200) {
        setAdmissionStatuses(response.data.data);
        setLoading(false);
        setTotalRecords(response.data.total);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onChangePagination = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const editApplication = (record) => {
    setVisible(true);
    setEditApplicationFiels(record);
  };
  const onSubmitAdmissionStatuses = async (val) => {
    try {
      setLoading(true);
      const data = {
        identifier: val.code,
        type: "stage",
        internalStatus: val.internalStatus,
        internalStatusAr: val.internalStatusAr,
        externalStatus: val.externalStatus,
        externalStatusAr: val.externalStatusAr,
        dashboardGroupStatus: val.dashboardGroupStatus,
        isEnablePayment: val.isEnablePayment,
        isPaymentNotification: val.isPaymentNotification,
      };

      const resposne = await updategetAdmissionStatuses({ data: data });
      if (resposne.status === 200) {
        setVisible(false);
        fetchSccStageStatuses();
        successMessage({ message: "Status updated succesfully" });
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  const openModalForAdmissionStatuses = () => {
    return (
      <CustomModal
        visibility={visible}
        handleOk={() => setVisible(false)}
        handleCancel={() => setVisible(false)}
        title={<>Update Stage Status</>}
      >
        <Form
          layout={formLayout}
          form={form}
          initialValues={{ layout: formLayout }}
          size={componentSize}
          onFinish={onSubmitAdmissionStatuses}
        >
          <AdmissionStatusesFrom
            form={form}
            valuesForEdit={editApplicationFields}
            // loading={loading}
          />
        </Form>
      </CustomModal>
    );
  };
  return (
    <Card className="antCardBorder" key={"admissionstatuses"}>
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          key={"admissionstatusestable"}
          loading={loading}
          data={admissionStatuses}
          columns={[
            {
              title: "Identifier",
              dataIndex: "code",
              key: "code",
            },

            {
              title: "Dashboard Group Status",
              dataIndex: "dashboardGroupStatus",
              key: "dashboardGroupStatus",
            },
            {
              title: "Application Type",
              dataIndex: "applicationType",
              key: "applicationType",
            },
            {
              title: "Stage",
              key: "stage",
              render: (item) => capitalize(removeUndercore(item.stage)),
            },

            {
              title: "Internal Status (EN)",
              dataIndex: "internalStatus",
              key: "internalStatus",
            },

            {
              title: "Internal Status (AR)",
              dataIndex: "internalStatusAr",
              key: "internalStatusAr",
            },
            {
              title: "External Status (EN)",
              dataIndex: "externalStatus",
              key: "externalStatus",
            },

            {
              title: "External Status (AR)",
              dataIndex: "externalStatusAr",
              key: "externalStatusAr",
            },
            {
              title: "Activate Payment",
              dataIndex: "isEnablePayment",
              key: "isEnablePayment",
            },
            {
              title: "Payment notification",
              dataIndex: "isPaymentNotification",
              key: "isPaymentNotification",
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (record) => (
                <div key={"action"}>
                  <Tooltip title="Update">
                    <Button
                      className="d-flex justify-content-center align-items-center"
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => editApplication(record)}
                    />
                  </Tooltip>
                </div>
              ),
            },
          ]}
          fetchRecords={onChangePagination}
          totalRecords={totalRecords}
          pageSize={pageSize}
        />
        {openModalForAdmissionStatuses()}
      </Spin>
    </Card>
  );
}

export default SccStageStatuses;
