/* eslint-disable eqeqeq */
import React from "react";
import "./style.css";
import underReview from "../../assets/img/underReview.svg";
import completed from "../../assets/img/completed.svg";
import inProgress from "../../assets/img/Inprogress.svg";
import declinedIcon from "../../assets/img/declinedIcon.svg";

//dashboard stage slug icons///
import registrationIcon from "../../assets/img/registrationIcon.png";
import applicationFeeIcon from "../../assets/img/applicationFeeIcon.png";
import entranceTestIcon from "../../assets/img/entranceTestIcon.png";
import finalIcon from "../../assets/img/finalIcon.png";
import interviewIcon from "../../assets/img/interviewIcon.png";
import observationIcon from "../../assets/img/observationIcon.png";
import prefinalIcon from "../../assets/img/prefinalIcon.png";
import transcriptIcon from "../../assets/img/TranscriptIcon.png";
import evaluationIcon from "../../assets/img/evaluation_icon.png";
import commiteeApproval from "../../assets/img/commitee_approval.png";

//dashboard stage slug icons///
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
// import { useSelector } from "react-redux";

export default function ApplicationStageCardStats({ item, onClick }) {
  // const _stages = useSelector((state) => state.metaData.stages);
  // const _stage = _stages?.find((stage) => stage.stage.slug === item.name);
  // let role = localStorage.getItem("currentUserRol");
  let statuses = item?.statuses;

  const renderStageIcon = (slug) => {
    if (
      slug == "application_fee" ||
      slug === "registration_fees" ||
      slug === "payment" ||
      slug === "integration"
    )
      return applicationFeeIcon;
    else if (slug == "registration" || slug == "review")
      return registrationIcon;
    else if (slug == "interview") return interviewIcon;
    else if (slug == "observation") return observationIcon;
    else if (
      slug == "transcript_review" ||
      slug == "application_posting" ||
      slug === "bus_allocation"
    )
      return transcriptIcon;
    else if (slug == "entrance_test") return entranceTestIcon;
    else if (slug == "pre_final_approval") return prefinalIcon;
    else if (slug == "final_review_approval" || slug == "final_approval")
      return finalIcon;
    else if (slug == "evaluation") return evaluationIcon;
    else if (slug == "committee_approval") return commiteeApproval;
    else if (slug == "cancellation_and_refunds") return commiteeApproval;
    else if (slug == "posting") return evaluationIcon;
    else if (slug == "reporting") return registrationIcon;
    else if (slug == "approval_level_1") return transcriptIcon;
    else if (slug == "approval_level_2") return evaluationIcon;
    else if (slug == "acknowledgement") return commiteeApproval;
  };

  const calculateTotalCount = () => {
    let total = item?.total || 0;

    if (!total) {
      // Otherwise, sum up the totals from all the stages
      statuses.forEach((stage) => {
        total += stage.total;
      });
    }

    return total;
  };

  return (
    <div key="stats" style={{ marginRight: 20 }}>
      {" "}
      <Card className="card-stats" style={{ marginRight: 15 }}>
        <CardBody>
          <Row>
            <Col md="4" xs="5">
              <div
                // className="icon-big  icon-warning"
                style={{ padding: "2px" }}
              >
                <img
                  alt="slugicon"
                  src={renderStageIcon(item.slug)}
                  style={{ height: "40px" }}
                />
              </div>
            </Col>

            <Col md="8" xs="7">
              <div className="numbers">
                <p className="card-category">{item?.title}</p>
                <CardTitle tag="p">{calculateTotalCount()}</CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          {/* <hr /> */}
          <div className="stats">
            <div style={{ padding: "20px 20px 2px" }}>
              <p style={{ margin: "2px" }}>Stage Statuses</p>
            </div>
          </div>

          <div className="stageStatsCardFooterWrapper">
            {statuses.length > 0
              ? statuses.map((val, i) => {
                  return (
                    <div
                      onClick={() => onClick(item.slug, val.title, val.code)}
                      key={i}
                      className="stageCardFooterItem"
                      style={{
                        width: statuses.length == 3 ? "33%" : "25%",
                        padding: "0px 4px",
                        cursor: "pointer",
                      }}
                    >
                      <div className="stageStatusTitle">
                        <p
                          style={{
                            fontSize: statuses.length > 3 ? 10 : 12,
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {val.title}
                        </p>
                      </div>
                      <div
                        key={val.title}
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <img
                          src={
                            i == 0
                              ? statuses.length == 2 ||
                                item.slug === "final_review_approval" ||
                                item.slug === "pre_final_approval"
                                ? inProgress
                                : underReview
                              : i == 1 && statuses.length == 2
                              ? completed
                              : i == 1 && statuses.length == 3
                              ? item.slug === "final_review_approval" ||
                                item.slug === "pre_final_approval"
                                ? completed
                                : inProgress
                              : item.slug === "final_review_approval" ||
                                item.slug === "pre_final_approval"
                              ? declinedIcon
                              : completed
                          }
                          alt="icon"
                        />
                        <p style={{ padding: 0, margin: 0 }}>{val.total}</p>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
