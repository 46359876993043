import { MetaDataActionKey } from "utils/constants";

const initialState = {
  stages: null,
  campuses: null,
  grades: null,
  users: null,
  schools: null,
  documents: null,
  currentStage: null,
  terms: null,
  countries: null,
  documentsMappings: null,
  applicationStatuses: null,
  requestTypes: null,
  bookingRequestTypes: [],
  asrEservices: [],
  // sch
  educationalSystems: [],
  SCH_schools: [],
  educationalAllowances: [],
  nonAcademicAchievement: [],
  personalInterview: [],
  contributionToResearch: [],
  socialAndCommunityActivities: [],
  certificatesAndAwards: [],
  scoringSystem: [],
  assessmentExams: [],

  // sch end
  TCC_schools: [],
  TCC_courses: [],
  TCC_teachers: [],
  TCC_schedules: [],
  // facilities
  FSM_bookingTypes: [],
  FSM_discountTypes: [],
  FSM_activityTypes: [],
  FSM_features: [],
  FSM_vats: [],
  FSM_categories: [],
  FSM_campuses: [],
  // scc
  SCC_grades: [],
  SCC_campus: [],
  SCC_years: [],
  SCC_Types: [],
};
const sortTermsList = (terms) => {
  var sortedYears = [];
  var tempYearArr = [];

  // get first year from the year range
  terms.forEach((singleYear, index) => {
    var tempSingleTimeArr = singleYear.name.split("-");
    tempYearArr.push({ year: tempSingleTimeArr[0], index: index });
  });

  // sort year in descending order
  tempYearArr.sort((a, b) => {
    return b.year - a.year;
  });

  // Assign original terms object to the new array
  tempYearArr.forEach((t) => {
    sortedYears.push(terms[t.index]);
  });
  return sortedYears;
};
const metaDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case MetaDataActionKey.getStagesConfig:
      return {
        ...state,
        schools: action.payload.schools,
        stages: action.payload.stages,
        users: action.payload.users,
        documentsMappings: action.payload.documentsMappings,
        applicationStatuses: action.payload.applicationStatuses,
      };
    case MetaDataActionKey.getStagesMetaData:
      return {
        ...state,
        grades: action.payload.grades,
        campuses: action.payload.campuses,
        documents: action.payload.documents,
        terms: sortTermsList(action.payload.terms),
        countries: action.payload.countries,
        requestTypes: action.payload.requestTypes,
        bookingRequestTypes: action.payload.bookingTypes,
        dropDowns: action.payload.dropDowns,
        asrEservices: action.payload.asrEServices,
        SCC_Types: action.payload.scc,
      };
    case MetaDataActionKey.setCurrentStage:
      return {
        ...state,
        currentStage: action.payload,
      };
    case MetaDataActionKey.getSchApplicationMetaDataKey:
      return {
        ...state,
        educationalAllowances: action.payload.educationalAllowances,
        SCH_schools: action.payload.schools,
        educationalSystems: action.payload.educationalSystems,
        scoringSystem: action.payload.scoringSystem,
        nonAcademicAchievement: action.payload.nonAcademicAchievement,
        personalInterview: action.payload.personalInterview,
        contributionToResearch: action.payload.contributionToResearch,
        socialAndCommunityActivities:
          action.payload.socialAndCommunityActivities,
        certificatesAndAwards: action.payload.certificatesAndAwards,
        assessmentExams: action.payload.assessmentExams,
      };
      break;
    case MetaDataActionKey.getTCCApplicationMetaDataKey:
      return {
        ...state,
        TCC_schools: action.payload.schools,
        TCC_courses: action.payload.courses,
        TCC_teachers: action.payload.teachers,
        TCC_schedules: action.payload.schedules,
      };
      break;
    case MetaDataActionKey.getFSMApplicationMetaDataKey:
      return {
        ...state,
        FSM_bookingTypes: action.payload.bookingTypes,
        FSM_discountTypes: action.payload.discountTypes,
        FSM_activityTypes: action.payload.activityTypes,
        FSM_vats: action.payload.vats,
        FSM_categories: action.payload.categories,
        FSM_campuses: action.payload.campuses,
        FSM_features: action.payload.features,
      };
      break;
    case MetaDataActionKey.getSCCConductCaseMetaDataKey:
      return {
        ...state,
        SCC_years: action.payload.years,
        SCC_campus: action.payload.campuses,
        SCC_grades: action.payload.grades,
      };
      break;
    default:
      return state;
  }
};
export default metaDataReducer;
