import { Button, Col, DatePicker, Form, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";
import { onRescheduleBooking } from "views/FSM/bookings/api";
import { checkBookingAvailableDate } from "views/FSM/bookings/api";

const BookingRescheduleModal = ({
  schedule,
  type,
  facility,
  handleCancel,
  refetch,
  bookingType,
}) => {
  const [availableDates, setAvailabeDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [startAt, setStartAt] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (Object.keys(schedule).length) {
      getBookingsAvailableDate();
    }
  }, [schedule]);

  useEffect(() => {
    if (Object.keys(schedule).length > 0) {
      setStartAt(moment(schedule?.startAt));
    }
  }, [schedule]);

  const getBookingsAvailableDate = async () => {
    try {
      setLoading(true);
      const startDate = moment(currentMonth)
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = moment(currentMonth).endOf("month").format("YYYY-MM-DD");
      const response = await checkBookingAvailableDate({
        facility: facility?.id,
        startAt: startDate,
        endAt: endDate,
        isSpecial: bookingType === "EVB" || bookingType === "LTB" ? 1 : 0,
      });

      if (response.status === 200) {
        setAvailabeDates(response.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const disabledDatesAndDays = (calendarDate) => {
    const formattedCalendarDate = calendarDate.format("YYYY-MM-DD");
    const todayDate = moment().format("YYYY-MM-DD");
    const currentScheduleDate = schedule?.startAt;

    // Check if the calendarDate matches any date in availableDates
    const isDateInAvailableDates = availableDates.some(
      (item) => item.date === formattedCalendarDate
    );
    return (
      formattedCalendarDate === todayDate ||
      !isDateInAvailableDates ||
      (currentScheduleDate && formattedCalendarDate === currentScheduleDate)
    );
  };

  const handleMonthChange = async (date, dateString) => {
    try {
      setCurrentMonth(date);
      if (schedule) {
        setLoading(true);
        const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
        const endDate = moment(date).endOf("month").format("YYYY-MM-DD");
        const response = await checkBookingAvailableDate({
          facility: facility.id,
          startAt: startDate,
          endAt: endDate,
          isSpecial: 0,
        });

        if (response.status === 200) {
          setAvailabeDates(response.data?.data);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
    // You can perform any action with the month name here
  };

  const handleFieldChange = (date) => {
    setStartAt(date);
  };

  const onUpdateSchedule = async () => {
    try {
      if (startAt === "" || startAt === null) {
        setError("Date is required");
        return;
      }
      const payload = {
        scheduleId: Number(schedule?.id),
        details: [
          {
            startAt: moment(startAt).format("YYYY-MM-DD"),
            noOfAttendees: schedule?.noOfAttendees,
            scheduleType: schedule?.scheduleType,
            attendeesNames: schedule?.attendeesNames,
          },
        ],
      };
      if (type === "PHB" || type === "FFB") {
        payload.details[0].startTime = schedule?.details[0].startTime;
        payload.details[0].endTime = schedule?.details[0].endTime;
      }

      setIsLoading(true);
      const response = await onRescheduleBooking({ payload: payload });
      if (response.status === 200) {
        successMessage({ message: "Schedule successfully updated" });
        refetch();
        setIsLoading(false);
        handleCancel();
      }
    } catch (error) {
      setIsLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onFieldFocus = () => {
    setError("");
  };
  return (
    <div>
      <Spin spinning={loading} indicator={renderLoader}>
        <Form layout="vertical">
          <Row gutter={40}>
            <Col md={24}>
              <Form.Item label="Date">
                <DatePicker
                  placeholder="date"
                  className="date__custom_width"
                  value={startAt}
                  onChange={(date) => handleFieldChange(date)}
                  disabledDate={disabledDatesAndDays}
                  onPanelChange={handleMonthChange}
                  onFocus={() => onFieldFocus("startAt")}
                  allowClear
                />
                <p style={{ color: "red", marginTop: "5px" }}>{error}</p>
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button className="ant__primary__button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="ant__primary__button"
              onClick={onUpdateSchedule}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default BookingRescheduleModal;
