import fsmBookingIcon from "../../assets/img/fsm/fsmBooking.png";
import fsmCalenderIcon from "../../assets/img/fsm/fsmCalender.png";
import fsmDashboardIcon from "../../assets/img/fsm/fsmDashboard.png";
import fsmfullfaciltiyIcon from "../../assets/img/fsm/fsmfullfaciltiy.png";
import fsmlogntermIcon from "../../assets/img/fsm/fsmlognterm.png";
import fsmeventbookingIcon from "../../assets/img/fsm/fsmeventbooking.png";
import fsmperheadIcon from "../../assets/img/fsm/fsmperhead.png";
import fsmrequesttypesIcon from "../../assets/img/fsm/fsmrequesttypes.png";
import fsmrequeststatusesIcon from "../../assets/img/fsm/fsmrequeststatuses.png";
import fsmMangementIcon from "../../assets/img/fsm/fsmMangement.png";
import fsmallIcon from "../../assets/img/fsm/fsmall.png";
import fsmscheduleIcon from "../../assets/img/fsm/fsmschedule.png";
import fsmfeaturesIcon from "../../assets/img/fsm/fsmfeatures.png";
import fsmeventstypesIcon from "../../assets/img/fsm/fsmeventstypes.png";
import fsmcategoryIcon from "../../assets/img/fsm/fsmcategory.png";
import fsmsubcategoryIcon from "../../assets/img/fsm/fsmsubcategory.png";
import fsmpaymentsIcon from "../../assets/img/fsm/fsmpayments.png";
import fsmcustomermanagIcon from "../../assets/img/fsm/fsmcustomermanag.png";
import fsmdiscountIcon from "../../assets/img/fsm/fsmdiscount.png";
import fsmalldiscountIcon from "../../assets/img/fsm/fsmalldiscount.png";
import fsmdiscounttypesIcon from "../../assets/img/fsm/fsmdiscounttypes.png";
import fsmvatIcon from "../../assets/img/fsm/fsmvat.png";
import fsmbannersIcon from "../../assets/img/fsm/fsmbanners.png";

import FSMDashboad from "views/FSM/dashboard/FSMDashboad";
import FSMCalender from "views/FSM/calender/FSMCalender";
import FSMRequestTypes from "views/FSM/request-types/FSMRequestTypes";
import FSMRequestStatus from "views/FSM/request-statuses/FSMRequestStatus";
import Categories from "../../views/FSM/facility-mangement/categories/Categories";
import SubCategories from "views/FSM/facility-mangement/sub-categories/SubCategories";
import AllDiscounts from "views/FSM/discount/all-discount/AllDiscount";
import DiscountTypes from "views/FSM/discount/disocunt-types/DisocuntTypes";
import Vat from "views/FSM/vat/Vat";
import FSMBanners from "views/FSM/fsm-banners/FSMBanners";
import EventTypes from "views/FSM/facility-mangement/events-types/EventTypes";
import AllFsmFeatures from "views/FSM/facility-mangement/features/AllFeatures";
import AllFacilities from "views/FSM/facility-mangement/all-facilities/AllFacilities";
import AddNewFacility from "views/FSM/facility-mangement/all-facilities/AddNewFacility";
import FacilitySchedules from "views/FSM/facility-mangement/faciltity-schedule/FacilitySchedules";
import FSMUserMangement from "views/FSM/user-management/UserManagement";

import DayPassBookings from "views/FSM/bookings/day-pas/DayPassBookings";
import FSMPayments from "views/FSM/payments/FSMPayments";
import AddNewPerHeadBooking from "views/FSM/bookings/per-head/AddNewPerHeadBooking";
import PerHeadBookings from "views/FSM/bookings/per-head/PerHeadBookings";
import BookingDetails from "views/FSM/bookings/BookingDetails";
import AddNewDayPassBooking from "views/FSM/bookings/day-pas/AddNewDayPassBooking";
import AddNewFullFacilityBooking from "views/FSM/bookings/full-facility/AddNewFullFacilityBooking";
import FullFacilityBookings from "views/FSM/bookings/full-facility/FullFacilityBookings";
import EventBookings from "views/FSM/bookings/event-booking/EventBookings";
import AddNewEventBooking from "views/FSM/bookings/event-booking/AddNewEventBooking";
import AddLongTermBooking from "views/FSM/bookings/long-term/AddLongTermBooking";
import LongTermBooking from "views/FSM/bookings/long-term/LongTermBooking";
import EditFacility from "views/FSM/facility-mangement/all-facilities/EditFacility";
import FSMPaymentDetails from "views/FSM/facility-mangement/fsm-application-detail-header/FSMPaymentDetails";
import FSMRefunds from "views/FSM/payments/FSMRefunds";
import AllTypeBookings from "views/FSM/bookings/allBookings/AllTypeBookings";

const AllBookingsRoutes = [
  {
    path: "/facility-services",
    name: "Facility Services",
    icon: fsmallIcon,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "fsm",
    subMenu: [
      {
        path: "/fsm-dashboard",
        name: "Dashboard",
        icon: fsmDashboardIcon,
        component: FSMDashboad,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Dashboard",
        state: null,
      },
      {
        path: "/fsm-calender",
        name: "Calender",
        icon: fsmCalenderIcon,
        component: FSMCalender,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Calendar",
        state: null,
      },
      {
        path: "/fsm-day-pass",
        name: "Day Pass",
        icon: fsmBookingIcon,
        component: AllTypeBookings,
        layout: "/admin",
        subPath: true,
        subType: true,
        sideMenuTitle: "Day Pass",
        state: { serviceType: "DPB" },
      },
      {
        path: "/fsm-per-head",
        name: "Per Head",
        icon: fsmperheadIcon,
        component: AllTypeBookings,
        layout: "/admin",
        subPath: true,
        subType: true,
        sideMenuTitle: "Per Head",
        state: { serviceType: "PHB" },
      },

      {
        path: "/fsm-full-facility",
        name: "Full Facility",
        icon: fsmfullfaciltiyIcon,
        component: AllTypeBookings,
        layout: "/admin",
        subPath: true,
        subType: true,
        sideMenuTitle: "Full Facility",
        state: null,
        state: { serviceType: "FFB" },
      },
      {
        path: "/fsm-events-booking",
        name: "Events Booking",
        icon: fsmeventbookingIcon,
        component: AllTypeBookings,
        layout: "/admin",
        subPath: true,
        subType: true,
        sideMenuTitle: "Events Booking",
        state: { serviceType: "EVB" },
      },
      {
        path: "/fsm-long-term",
        name: "Long Term",
        icon: fsmlogntermIcon,
        component: AllTypeBookings,
        layout: "/admin",
        subPath: true,
        subType: true,
        sideMenuTitle: "Long Term",
        state: { serviceType: "LTB" },
      },
    ],
  },
  {
    path: "/add-per-head-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddNewPerHeadBooking,
    layout: "/admin",
    subPath: true,
  },
  // add day pass booking
  {
    path: "/add-day-pass-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddNewDayPassBooking,
    layout: "/admin",
    subPath: true,
  },
  // add full facility booking
  {
    path: "/add-full-facility-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddNewFullFacilityBooking,
    layout: "/admin",
    subPath: true,
  },
  // add new event booking
  {
    path: "/add-event-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddNewEventBooking,
    layout: "/admin",
    subPath: true,
  },
  // add new long term booking
  {
    path: "/add-long-term-booking",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: AddLongTermBooking,
    layout: "/admin",
    subPath: true,
  },
  // booking details
  {
    path: "/per-head-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/day-pass-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/full-facility-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/event-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/long-term-booking/details",
    name: "Application Details",
    icon: "nc-icon nc-bag-16",
    component: BookingDetails,
    layout: "/admin",
    subPath: true,
  },
];

export default AllBookingsRoutes;
