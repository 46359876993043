import { DownOutlined, EyeOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Spin, Tooltip, Select, Form } from "antd";

import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useEffect } from "react";
import { useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import ExpandableYear from "./ExpandableYear";

import { getAllYearsAndHisTerms } from "../api";
import { requestErrorHandel } from "utils/helpers/helpers";

const SccYearsAndTerms = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const expandedRowRender = (record) => {
    return (
      <div className="card__snippet">
        <ExpandableYear
          record={record}
          props={props}
          data={data}
          reftech={fetchAllYearsAndTerms}
        />
      </div>
    );
  };
  // Define the expandable configuration
  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record]);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record));
    }
  };

  useEffect(() => {
    fetchAllYearsAndTerms();
  }, []);

  const fetchAllYearsAndTerms = async () => {
    try {
      setLoading(true);
      const response = await getAllYearsAndHisTerms();
      if (response.status === 200) {
        const responseData = response.data.data;
        const index = responseData.findIndex(
          (item) =>
            item.firstTermStatus === "open" || item.secondTermStatus === "open"
        );

        // If an object with open term is found, move it to the beginning of the array
        if (index !== -1) {
          const openTermObject = responseData.splice(index, 1)[0];
          responseData.unshift(openTermObject);
        }
        setData(responseData);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => null}
          headerTitle={"Years & Terms"}
          headerSubTitle={""}
          headerBtnTitle={""}
        />
      </>
    );
  };

  const onViewLogs = (item) => {
    props.history.push({
      pathname: `scc/year-logs`,
      state: { term: item },
    });
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          expandable={{
            expandedRowRender: expandedRowRender,
            expandedRowKeys: expandedRowKeys,
            onExpand: onExpand,
          }}
          columns={[
            {
              title: "Year",
              dataIndex: "name",
              key: "name",
              width: "33%",

              render: (record) => (
                <span
                  onClick={() =>
                    onExpand(!expandedRowKeys.includes(record), record)
                  }
                >
                  <div className="row_expand_container">
                    <p style={{ color: "#007B85", padding: 0, margin: 0 }}>
                      {record}
                    </p>
                    {!expandedRowKeys.includes(record) ? (
                      <DownOutlined
                        style={{ color: "#007B85", marginLeft: 10 }}
                      />
                    ) : (
                      <UpOutlined
                        style={{ color: "#007B85", marginLeft: 10 }}
                      />
                    )}
                  </div>
                </span>
              ),
              width: "33%",
            },

            {
              title: "Status",
              // dataIndex: "status",
              key: "status",
              width: "33%",

              render: (record) => {
                const isOpen =
                  record &&
                  (record.firstTermStatus === "open" ||
                    record.secondTermStatus === "open");
                const isClosing =
                  record.firstTermStatus === "closing" ||
                  record.secondTermStatus === "closing";
                return isClosing ? "Closing" : isOpen ? "Opened" : "Closed";
              },
              // width: "33%",
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",

              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div
                      style={{
                        marginRight: "10px",
                        height: 40,
                        width: 70,
                        borderRadius: 10,
                        border: "1px solid #EBEBE4",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => onViewLogs(item)}
                    >
                      Logs
                    </div>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
      </Spin>
    </div>
  );
};

export default SccYearsAndTerms;
