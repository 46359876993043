import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  ReloadOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Spin,
  Tooltip,
  Form,
  Select,
  Image,
  Row,
  Col,
  DatePicker,
  Input,
  Table,
  Checkbox,
  message,
} from "antd";
// import "./style.css";

// ... existing imports ...
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { resetTransactionsAPI } from "apis/transactions-api/transations-api";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { fetchAllFsmPayments } from "./api";

import { useSelector } from "react-redux";
import moment from "moment";
import { onViewBookingDetails } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";
import _ from "lodash";
import { getTransactionsAPI } from "apis/transactions-api/transations-api";

const { Option } = Select;
const { confirm } = Modal;

const Transactions = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  const _campuses = useSelector((state) => state.metaData.campuses);

  const showResetConfirm = () => {
    confirm({
      title: "Are you sure you want to reset the selected payments?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        resetSelectedTransactions();
      },
    });
  };

  const resetSelectedTransactions = async () => {
    try {
      setLoading(true);
      const response = await resetTransactionsAPI({ids:selectedTransactions});
      console.log(response,"response");
      if (response.status === 200) {
        console.log(response.data,"response inside");
        successMessage({message:"Payments reset successfully"});
        setSelectedTransactions([]);
        getAllPayments(); // Refresh the data
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    } finally {
      setLoading(false);
    }
  };

  const [filters, setFilters] = useState({
    campus: "",
    session_id: "",
    student_id: "",
    voucherNumber: "",
    journal_ref: "",
    payment_platform: "",
  });
  const resetPayment = async (transactionId) => {
    try {
      // Call your API here
      // const response = await resetPaymentApi(transactionId);
      // if (response.status === 200) {
      //   successMessage("Payment reset successfully");
      //   getAllPayments(); // Refresh the data
      // }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };
  const groupTransactions = (transactions) => {
    const grouped = _.groupBy(transactions, "session_id");
    console.log(grouped)
    return Object.entries(grouped).map(([session_id, transactions]) => ({
      key: session_id,
      session_id,
      stud_name: transactions[0].stud_name,
      stud_id: transactions[0].stud_id,
      grade: transactions[0].grade,
      total_amount: transactions
        .reduce((sum, t) => sum + parseFloat(t?.paid_amount), 0)
        .toFixed(2),
      transactions: transactions,
    }));
  };

  const toggleRowExpansion = (record) => {
    setExpandedRows((prev) => ({
      ...prev,
      [record.session_id]: !prev[record.session_id],
    }));
    
  };

  const columns = [
    {
      title: "",
      key: "expand",
      width: 50,
      render: (_, record) => (
        <Button
          type="text"
          onClick={() => toggleRowExpansion(record)}
          icon={
            expandedRows[record.session_id] ? (
              <DownOutlined />
            ) : (
              <RightOutlined />
            )
          }
        />
      ),
    },
    {
      title: "",
      key: "checkbox",
      width: 50,
      render: (_, record) => (
        <Checkbox
          checked={selectedRows.includes(record.session_id)}
          onChange={(e) => {
            const newSelectedRows = e.target.checked
              ? [...selectedRows, record.session_id]
              : selectedRows.filter((id) => id !== record.session_id);
            setSelectedRows(newSelectedRows);


            const allTransactionIds = record.transactions.map(t => t.id);
            setSelectedTransactions(e.target.checked ? 
              [...new Set([...selectedTransactions, ...allTransactionIds])] : 
              selectedTransactions.filter(id => !allTransactionIds.includes(id))
            );
          }}
        />
      ),
    },
    { title: "Session ID", dataIndex: "session_id", key: "session_id" },
    { title: "Student Name", dataIndex: "stud_name", key: "stud_name" },
    { title: "Student ID", dataIndex: "stud_id", key: "stud_id" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    {
      title: "Total Amount",
      key: "total_amount",
      render: (_, record) => `AED ${record.total_amount}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            icon={<ReloadOutlined />}
            onClick={showResetConfirm}
            disabled={selectedTransactions.length === 0}
          >
            Reset Selected
          </Button>
        </div>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    const allSelected = record.transactions.every(transaction => selectedTransactions.includes(transaction.id));

    const columns = [
      {
        title: "",
        key: "checkbox",
        width: 50,
        fixed: "left",
        render: (_, transaction) => (
          <Checkbox
            checked={selectedTransactions.includes(transaction.id)}
            onChange={(e) => {
              const newSelectedTransactions = e.target.checked
                ? [...selectedTransactions, transaction.id]
                : selectedTransactions.filter((id) => id !== transaction.id);
              setSelectedTransactions(newSelectedTransactions);
            }}
          />
        ),
      },
      // {
      //   title: "ID",
      //   dataIndex: "id",
      //   key: "id",
      //   fixed: "left",
      // },
      {
        title: "Session ID",
        dataIndex: "session_id",
        key: "session_id",
        fixed: "left",
      },
      {
        title: "Bank Trans ID",
        dataIndex: "bank_trans_id",
        key: "bank_trans_id",
      },
      {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        key: "paid_amount",
        render: (amount) => `AED ${amount}`,
      },
      { title: "Student Name", dataIndex: "stud_name", key: "stud_name" },
      { title: "Grade", dataIndex: "grade", key: "grade" },
      { title: "Student ID", dataIndex: "stud_id", key: "stud_id" },
      { title: "Campus ID", dataIndex: "campus_id", key: "campus_id" },
      { title: "Notify Email", dataIndex: "notify_email", key: "notify_email" },
      {
        title: "Mail Send",
        dataIndex: "mail_send",
        key: "mail_send",
      },
      { title: "Journal Ref", dataIndex: "journal_ref", key: "journal_ref", 
        render: (value) => (value ?? "Null"),
        
        
      },
      {
        title: "Journal Status",
        dataIndex: "journal_stat",
        key: "journal_stat",
      },
      { title: "Paid By", dataIndex: "paid_by", key: "paid_by" },
      {
        title: "Receipt Date",
        dataIndex: "receipt_date",
        key: "receipt_date",
        render: (date) => moment(date).format("YYYY-MM-DD"),
      },
      {
        title: "Service Call Status",
        dataIndex: "service_call_stat",
        key: "service_call_stat",
      },
      { title: "Service ID", dataIndex: "serviceID", key: "serviceID" },
      {
        title: "Original Due Amount",
        dataIndex: "original_due_amount",
        key: "original_due_amount",
        render: (amount) => `AED ${amount}`,
      },
      {
        title: "Payment Platform",
        dataIndex: "payment_platform",
        key: "payment_platform",
      },
      {
        title: "Voucher Number",
        dataIndex: "voucherNumber",
        key: "voucherNumber",
      },
    ];

    return (
      <div style={{ overflowX: "scroll", maxWidth: "90rem" }}>
        <Table
          columns={columns}
          dataSource={record.transactions}
          pagination={false}
          rowKey="bank_trans_id"
          scroll={{ x: "max-content" }}
        />
      </div>
    );
  };

  useEffect(() => {
    getAllPayments();

  
  }, [filters, page]);
  useEffect(() => {
    getAllPayments();
  
  }, []);

  const getAllPayments = async () => {
    try {
      setLoading(true);
      const transactionData = await getTransactionsAPI(filters.campus,filters.student_id,filters.session_id);

      let filteredData = transactionData.data.data;

      // Apply filters
      if (filters.campus) {
        filteredData = filteredData.filter(
          (item) => item.campus_id === filters.campus
        );
      }

      if (filters.session_id) {
        filteredData = filteredData.filter((item) =>
          item.session_id
            .toLowerCase()
            .includes(filters.session_id.toLowerCase())
        );
      }
      if (filters.student_id) {
        filteredData = filteredData.filter(
          (item) =>
            item.stud_id &&
            item.stud_id
              .toLowerCase()
              .includes(filters.student_id.toLowerCase())
        );
      }

      const groupedData = groupTransactions(filteredData);
      setData(groupedData);
      setTotal(groupedData.length);
      setLoading(false);
    } catch (error) {
      console.log(error,"errrr");
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          headerTitle={"Transactions"}
          headerSubTitle={"Manage Transaction"}
          hideBtnIcon={true}
        />
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col xs={24} sm={12} md={8}>
            <Form.Item className="dropdown">
              <Select
                placeholder="Select Campus"
                onChange={(value) => setFilters({ ...filters, campus: value })}
                value={filters.campus}
                bordered={false}
              >
                 <Option key="all" value="">
                    All
                  </Option>
                {_campuses.map((campus) => (
                  <Option key={campus.id} value={campus.code}>
                    {campus.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Form.Item className="dropdown">
              <Input
                placeholder="Student Number"
                onChange={(e) =>
                  setFilters({ ...filters, student_id: e.target.value })
                }
                value={filters.student_id}
                bordered={false}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Form.Item className="dropdown">
              <Input
                placeholder="Session ID"
                onChange={(e) =>
                  setFilters({ ...filters, session_id: e.target.value })
                }
                value={filters.session_id}
                bordered={false}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        {renderHeader()}
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            total: total,
            current: page,
            onChange: (page) => setPage(page),
          }}
          
          expandable={{
            expandedRowRender,
            expandedRowKeys: Object.keys(expandedRows).filter(
              (key) => expandedRows[key]
            ),
            onExpand: (expanded, record) => toggleRowExpansion(record),
          }}
        />
      </Spin>
    </div>
  );
};
export default Transactions;
