import React from "react";
import FSMBookingHeader from "./FSMBookingHeader";

const FSMPaymentDetails = () => {
  return (
    <div>
      <FSMBookingHeader />
    </div>
  );
};

export default FSMPaymentDetails;
