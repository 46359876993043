import {
  CalendarFilled,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Spin,
  Tooltip,
  Form,
  Select,
  Image,
  Col,
  Row,
  DatePicker,
} from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import {
  addNewFacilitySchedule,
  deleteFacilitySchedule,
  fetchAllFsmFacilitySchedules,
  updateFacilitySchedule,
} from "./api";
import FacilityScheduleForm from "./FacilityScheduleForm";
import { useSelector } from "react-redux";

import moment from "moment";
import ViewSchedules from "./ViewSchedules";
import { fetchAllFsmFacilities } from "../all-facilities/api";
import { generateUniqueColumnKey } from "utils/helpers/helpers";
import BlokSchedules from "../all-facilities/BlokSchedules";
import FSMScheduleTabs from "../fsm-application-detail-header/FSMScheduleTabs";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

let oldRecord = {};
let oldModelTitle = "";

const FacilitySchedules = () => {
  const _campuses = useSelector((state) => state.metaData.campuses);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [facilities, setFacilities] = useState([]);

  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [selectdScheduleTab, setSelectdScheduleTab] = useState(0);

  const [showModal, toggleModal] = useState(false);
  const [viewSchedule, setViewSchedule] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [scheduleType, setScheduleType] = useState("open");
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [recordId, setRecordId] = useState("");
  const [ViewScheduleData, setViewScheduleData] = useState({});
  const [campus, setCampus] = useState("");
  const [facility, setFacility] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [fsmFacilityScheduleForm] = Form.useForm();

  const daysOfWeek = [
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ];
  const [blockSchedules, setBlockSchedules] = useState([]);
  const [existingBlockSchedules, setExistingBlockSchedules] = useState([]);

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            fsmFacilityScheduleForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Facilities Schedules"}
          headerSubTitle={""}
          headerBtnTitle={"Add New"}
        />
        <Row gutter={10}>
          <Col md={12} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter By Campus"
                value={campus}
                bordered={false}
                onChange={(value) => setCampus(value)}
              >
                <Option value={""}>All</Option>
                {_campuses?.map((el, i) => (
                  <Option key={i} value={`${el.id}`}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} className="filterColumn">
            <Form.Item name="facility" className="dropdown">
              <Select
                placeholder="Select Facility"
                value={facility}
                bordered={false}
                onChange={(value) => setFacility(value)}
              >
                <Option value={""}>All</Option>
                {facilities?.map((el, i) => (
                  <Option key={i} value={`${el.id}`}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Start Date"
                value={startDate}
                onChange={(date) =>
                  setStartDate(moment(date).format("YYYY-MM-DD"))
                }
              />
            </Form.Item>
          </Col>
          <Col md={12} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="End Date"
                value={endDate}
                onChange={(date) =>
                  setEndDate(moment(date).format("YYYY-MM-DD"))
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };
  useEffect(() => {
    getAllFacilitySchedules();
  }, [campus, facility, startDate, endDate, page]);

  useEffect(() => {
    getAllFsmFacilities();
  }, []);

  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };

  const getAllFacilitySchedules = async () => {
    try {
      setLoading(true);
      const response = await fetchAllFsmFacilitySchedules({
        page: page,
        campus: campus,
        facility: facility,
        startAt: moment(startDate).isValid() ? startDate : "",
        endAt: moment(endDate).isValid() ? endDate : "",
      });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addFacilitySchedule = async (data) => {
    try {
      setLoading(true);
      const response = await addNewFacilitySchedule({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Facility Schedule added successfully" });
        await getAllFacilitySchedules();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateFacilitySchedule = async (data) => {
    try {
      setLoading(true);
      const response = await updateFacilitySchedule({
        id: oldRecord?.id || recordId,
        data: data,
      });
      if (response.status === 200) {
        successMessage({ message: "Facility Schedule updated successfully" });
        await getAllFacilitySchedules();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteFacilitySchedule = async (id) => {
    try {
      setLoading(true);
      const response = await deleteFacilitySchedule({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Facility Schedule deleted successfully" });
        await getAllFacilitySchedules();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    fsmFacilityScheduleForm.resetFields();
    setBlockSchedules([]);
    toggleModal(false);
    setValuesForEdit(null);
    setScheduleType("open");
    oldModelTitle = ModalType.ADD;
  };

  const transformBlockSchedules = (blockSchedules) => {
    return blockSchedules.map((block) => {
      // Convert start and end dates to the format required by the server
      const startAt = moment(block.startAt).format("YYYY-MM-DD");
      const endAt = moment(block.endAt).format("YYYY-MM-DD");

      // Map weekDays to schedules
      const schedules = block.weekDays.flatMap((day) => {
        // If no slots and `addSlots` is false, skip this day
        if (!day.addSlots && !day.fullDay) return [];

        // If full day, add schedule with empty slots
        if (day.fullDay) {
          return [
            {
              day: day.day.slice(0, 3).toLowerCase(), // Convert day to short format (e.g., "Wed")
              startTime: "",
              endTime: "",
              reason: day.reason,
            },
          ];
        }

        // Create schedule entries for each slot
        return day.slots.map((slot) => ({
          day: day.day.slice(0, 3).toLowerCase(), // Convert day to short format (e.g., "Wed")
          startTime: slot.startTime,
          endTime: slot.endTime,
          reason: day.reason,
        }));
      });

      return {
        startAt,
        endAt,
        schedules,
      };
    });
  };

  const getBlockSchedules = useCallback(async (blockSchedules) => {
    setBlockSchedules(blockSchedules);
    const transformedBlockSchedules = transformBlockSchedules(blockSchedules);

    const payload = {
      facilityId: oldRecord?.facility?.id,
      startAt: oldRecord?.startAt,
      endAt: oldRecord?.endAt,
      schedules: oldRecord?.days,
      blockSchedules: transformedBlockSchedules,
    };
    if (oldModelTitle === ModalType.EDIT) {
      await handleUpdateFacilitySchedule(payload);
    } else {
      await addFacilitySchedule(payload);
    }
  }, []);

  // Function to transform blockSchedules
  const transformExistingBlockSchedules = (esistingblockSchedules) => {
    return esistingblockSchedules.map((blockSchedule) => ({
      startAt: moment(blockSchedule.startAt).format(),
      endAt: moment(blockSchedule.endAt).format(),
      schedules: blockSchedule.days.map((day) => ({
        day: day.day,
        startTime: day.startTime,
        endTime: day.endTime,
        reason: day.reason,
        fullDay: day.fullDay,
      })),
    }));
  };

  const onSubmit = async (values) => {
    const payload = {
      facilityId: Number(values?.facilityId),
      startAt: moment(values.startAt).format(),
      endAt: moment(values.endAt).format(),
      schedules: [],
      blockSchedules: transformExistingBlockSchedules(existingBlockSchedules),
    };

    for (let i = 0; i < daysOfWeek.length; i++) {
      if (values[`day[${i}]`]) {
        const day = daysOfWeek[i].slice(0, 3).toLowerCase();
        const startTime = moment(values[`startTime[${i}]`]).format("HH:mm");
        const endTime = moment(values[`endTime[${i}]`]).format("HH:mm");
        const duration = parseInt(values[`duration[${i}]`], 10);

        payload.schedules.push({ day, startTime, endTime, duration });
      }
    }

    if (modalTitle === ModalType.EDIT) {
      await handleUpdateFacilitySchedule(payload);
    } else {
      await addFacilitySchedule(payload);
    }
  };

  const handleEdit = (type, record) => {
    // type 1 = open schedule
    // type 2 = blocked schedule
    fsmFacilityScheduleForm.resetFields();

    if (type === "open") {
      setValuesForEdit(record);
      setExistingBlockSchedules(record?.blockSchedules);

      const mappedDays = record.days.map((day, index) => {
        const dayIndex = daysOfWeek.findIndex(
          (d) => d.slice(0, 3).toLowerCase() === day.day
        );

        return {
          [`day[${dayIndex}]`]: true,
          [`duration[${dayIndex}]`]: String(day.duration),
          [`startTime[${dayIndex}]`]: moment(day.startTime, "HH:mm"),
          [`endTime[${dayIndex}]`]: moment(day.endTime, "HH:mm"),
        };
      });

      const mappedValues = {
        facilityId: record.facility?.id,
        startAt: moment(record.startAt),
        endAt: moment(record.endAt),
        // Other form fields mapping here...
      };

      fsmFacilityScheduleForm.setFieldsValue({
        ...mappedValues,
        ...Object.assign({}, ...mappedDays),
      });
    } else if (type === "block") {
      setBlockSchedules(record);
    }

    setRecordId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  const renderSchedule = () => {
    return (
      <FacilityScheduleForm
        onSubmit={onSubmit}
        formName={fsmFacilityScheduleForm}
        loading={loading}
        valuesForEdit={valuesForEdit}
        daysOfWeek={daysOfWeek}
        facilities={facilities}
      />
    );
  };

  const handleEditSchedule = (type, data) => {
    if (type === "open") {
      // Edit Open Schedule
      setScheduleType("open");
    } else if (type === "block") {
      // Edit blocked Schedule
      setScheduleType("block");
    }
    setViewSchedule(false);
    oldRecord = data;
    handleEdit(type, data);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Facility",
              // dataIndex: "description",
              key: generateUniqueColumnKey(),
              render: (record, index) => record?.facility?.title,
            },
            {
              title: "Start Date",
              dataIndex: "startAt",
              key: generateUniqueColumnKey(),
            },
            {
              title: "End Date",
              dataIndex: "endAt",
              key: generateUniqueColumnKey(),
            },
            // {
            //   title: "Detail",
            //   key: generateUniqueColumnKey(),

            //   render: (record) => {
            //     return (
            //       <p
            //         className="custom_tag"
            //         onClick={() => {
            //           setViewSchedule(true);
            //           setViewScheduleData(record);
            //         }}
            //       >
            //         View
            //       </p>
            //     );
            //   },
            // },

            {
              title: "Action",
              dataIndex: "",
              key: generateUniqueColumnKey(),

              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Facility Schedule Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => {
                            setViewSchedule(true);
                            setViewScheduleData(item);
                            setBlockSchedules([]);
                            setSelectdScheduleTab(0);
                            oldModelTitle = ModalType.EDIT;
                          }}
                          // onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>
                    {/* <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Facility Schedule Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<CalendarFilled />}
                          onClick={() => setViewSchedule(true)}
                        />
                      </Tooltip>
                    </div> */}

                    <Popconfirm
                      title="Are you sure you want to delete this Facility Schedule?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteFacilitySchedule(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Facility Schedule`}
        >
          {scheduleType === "open" ? (
            renderSchedule()
          ) : (
            <BlokSchedules
              isNew={false}
              selectedBlockSchedul={[]}
              record={blockSchedules}
              onCloseForm={onCloseForm}
              getBlockSchedules={getBlockSchedules}
            />
          )}
        </CustomModal>
        <CustomModal
          visibility={viewSchedule}
          handleCancel={() => setViewSchedule(false)}
          handleOk={() => null}
          title={"View Schedule"}
        >
          <FSMScheduleTabs
            selectedTab={selectdScheduleTab}
            onTab1={() => setSelectdScheduleTab(0)}
            onTab2={() => setSelectdScheduleTab(1)}
          />
          <br />
          <ViewSchedules
            selectedTab={selectdScheduleTab}
            data={ViewScheduleData}
            handleEditSchedule={handleEditSchedule}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default FacilitySchedules;
