import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import { fetchAllTemplates } from "apis/templates/templatesApis";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import { getSearchedStudentsList, handleGenerateForm } from "./api";

import {
  Editor,
  EditorTools,
  EditorUtils,
  ProseMirror,
} from "@progress/kendo-react-editor";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import "@progress/kendo-theme-default/dist/all.css";
import { fetchAllHeadersAndFooters } from "apis/templates/templatesApis";
import { requestErrorHandel } from "utils/helpers/helpers";
import "../../../index.css";

const { EditorState, EditorView } = ProseMirror;

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
  Pdf,
  Print,
  TableProperties,
  TableCellProperties,
  ForeColor,
  BackColor,
  InsertFile,
  CleanFormatting,
  BulletedList,
  NumberedList,
} = EditorTools;
const {
  pasteCleanup,
  sanitize,
  sanitizeClassAttr,
  sanitizeStyleAttr,
  removeAttribute,
  replaceImageSourcesFromRtf,
  setHtml,
  tableResizing,
} = EditorUtils;

const { Option } = Select;

const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} is required`,
    },
  ];
};

const onMount = (event) => {
  const { plugins, doc } = event.viewProps.state;
  return new EditorView(
    {
      mount: event.dom,
    },
    {
      ...event.viewProps,
      state: EditorState.create({
        doc,
        plugins: [...tableResizing(), ...plugins],
      }),
    }
  );
};

const SccDocumentGeneratorForm = ({
  onSubmit,
  loading,
  formName,
  valuesForEdit,
  getUser,
  isChecked,
  setIsChecked,
}) => {
  const formRef = useRef();
  const editorRefEn = useRef(null);
  const editorRefAr = useRef(null);
  const pdfExportComponent = useRef(null);
  const debounceTimeout = useRef(null);

  const [searchLoading, setSearchLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  const [userError, setUserError] = useState("");

  const [templateForms, setTemplateForms] = useState([]);
  const [selectedFormTemplate, setSelectedFormTemplate] = useState();
  const [editorContent, setEditorContent] = useState("");

  const [contentEn, setContentEn] = useState(valuesForEdit?.content || "");
  const [contentAr, setContentAr] = useState(valuesForEdit?.contentAr || "");

  const [sections, setSections] = useState();

  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [downloadType, setDownloadType] = useState("");

  const [isCompleteModalVisible, setIsCompleteModalVisible] = useState(false);

  const PdfTool = (props) => {
    console.log(props, "this is props");
    return (
      <Pdf
        {...props}
        // https://www.telerik.com/kendo-react-ui/components/pdfprocessing/api/PDFExportProps/
        savePdfOptions={{
          fileName: selectedFormTemplate,
          paperSize: "A4",
          margin: {
            left: "2cm",
            right: "2cm",
            top: "3cm",
            bottom: "4cm",
          },
          fonts: [
            {
              name: "Amiri",
              url: "../../../assets/fonts/Amiri/Amiri-Regular.ttf",
              style: "normal",
              weight: "normal",
              encode: true,
            },
          ],
          scale: 0.7,
          pageTemplate: (propsLast) => (
            <div style={{ width: "100%", height: "100%" }}>
              {/* Green sides */}
              {/* <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '0.5cm', backgroundColor: 'green' }} />
              <div style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: '0.5cm', backgroundColor: 'green' }} /> */}

              {/* Header */}
              <div
                style={{
                  position: "absolute",
                  top: "0.5cm",
                  left: "1cm",
                  right: "1cm",
                  height: "2cm",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // borderBottom: "1px solid #888",
                }}
              >
                {/* <img src="/path/to/your/logo.png" alt="Emirates Nation Schools Logo" style={{ height: '1.5cm' }} />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#333' }}>EMIRATES NATION SCHOOLS</div> */}
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "12pt",
                    lineHeight: "1.5",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: _headers?.find(
                      (header) => header.title === "Default Header"
                    ).content,
                  }}
                />
              </div>

              {/* Footer */}
              <div
                style={{
                  position: "absolute",
                  bottom: "0.5cm",
                  left: "1cm",
                  right: "1cm",
                  textAlign: "center",
                  color: "#888",
                  borderTop: "1px solid #888",
                  paddingTop: "0.2cm",
                  fontSize: "10px",
                }}
              >
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "12pt",
                    lineHeight: "1.5",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: _footers?.find(
                      (footer) => footer.title === "Default Footer"
                    ).content,
                  }}
                />

                {/* <div>© {new Date().getFullYear()} Emirates Nation Schools. All rights reserved.</div>
                <div>Page {props.pageNum} of {props.totalPages}</div> */}
              </div>
            </div>
          ),
        }}
      />
    );
  };

  const showDownloadConfirmation = (type) => {
    setDownloadType(type);
    setIsDownloadModalVisible(true);
  };

  const handleDownloadConfirm = async () => {
    setIsDownloadModalVisible(false);

    // First, save the latest changes
    const values = formName.getFieldsValue();
    if (valuesForEdit) {
      let modifiedEn = contentEn;
      let modifiedAr = contentAr;

      // Updated table and cell styles for full width and merged borders
      const tableStyle =
        "border-collapse: collapse; width: 100%;"; // Ensure full width and merged borders
      const tdStyle =
        "border: 1px solid rgba(128, 128, 128, 0.5); padding: 2px; text-align: center;";

      modifiedEn = modifiedEn
        .replace(/<table>/g, `<table style='${tableStyle}'>`)
        .replace(/<td>/g, `<td style='${tdStyle}'>`);

      modifiedAr = modifiedAr
        .replace(/<table>/g, `<table style='${tableStyle}'>`)
        .replace(/<td>/g, `<td style='${tdStyle}'>`);

      // Removed unnecessary replacements for table styles
      modifiedEn = modifiedEn
        .replace(/<p>/g, "<p style='font-size: 13px; '>");

      // For Arabic version
      modifiedAr = modifiedAr
        .replace(/<p>/g, "<p style='font-size: 13px; '>");

      function addStyleIfMissing(content, style) {
        console.log(content, style);
        // Wrap the content inside a <div> with the specified style
        return `<div style="${style}">${content}</div>`;
      }

      const divStyle = `
                padding-left: 2cm; 
                margin-right: 2cm;  
                font-size: 14px; 
                line-height: 1.2;
            `;

      // Apply the style if missing for English content
      if (modifiedEn.indexOf("<div") !== -1) {
        console.log(modifiedEn);
        modifiedEn = addStyleIfMissing(modifiedEn, divStyle);
      }

      // Apply the style if missing for Arabic content
      if (modifiedAr.indexOf("<div") !== -1) {
        console.log(modifiedAr, "inside add");
        modifiedAr = addStyleIfMissing(modifiedAr, divStyle);
      }

      const formData = {
        content: modifiedEn,
        contentAr: modifiedAr,
        status:
          formName?.getFieldValue("status") === "complete"
            ? "complete"
            : "draft",
        section: values.section || "A",
        templateId: selectedFormTemplate || valuesForEdit?.templateId,
        studentNumber:
          selectedUser?.studentNumber || valuesForEdit?.studentNumber,
        year: values.year,
      };

      try {
        const res = await onSubmit({
          isPdfDownloadAction: true,
          campus: selectedUser?.campus || valuesForEdit?.campus,
          grade: selectedUser?.grade || valuesForEdit?.grade,
          school: selectedUser?.school || valuesForEdit?.school,
          ...formData,
        });
        setSelectedUser({});
        if (downloadType === "en") {
          // handleExportPDFEn();
          // return await handleExportPDFEn();
          handleDownloadPdf(res?.id, "en");
        } else if (downloadType === "ar") {
          handleDownloadPdf(res?.id, "ar");
          // handleExportPDFAr();
        }
        return res;
      } catch (error) {
        requestErrorHandel({ error: error });
      }
    }
    // Then, call the appropriate download function
  };

  const handleDownloadPdf = async (id, lan) => {
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;

      const url = `${baseURL}/scc-documents/getPDF/${id}/${lan}`;

      window.open(url, "_blank");
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const getAllHeadersAndFooter = async () => {
    try {
      setLoading(true);
      const response = await fetchAllHeadersAndFooters();
      if (response.status === 200) {
        setSections(response.data.data);
        setLoading(false);
        console.log(response.data.data);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const _headers = sections?.filter((section) => section.type === "header");
  const _footers = sections?.filter((section) => section.type === "footer");

  const customFontNameSettings = {
    style: "font-family",
    defaultItem: { text: "Font", value: "Arial" },
    items: [
      { text: "Arial", value: "Arial, Helvetica, sans-serif" },
      { text: "Courier New", value: "Courier New, Courier, monospace" },
      { text: "Georgia", value: "Georgia, serif" },
      { text: "Jamhuria", value: "Jamhuria Regular" },
      { text: "Andalus", value: "Andalus Regular" },
      { text: "Times New Roman", value: "Times New Roman, Times, serif" },
      { text: "Roboto", value: "Roboto, sans-serif" },
      { text: "Amiri", value: "Amiri" },
      { text: "IBM Plex", value: "IBM Plex Sans Arabic, sans-serif" },
      { text: "Tajawal", value: "Tajawal, sans-serif" },
      { text: "Cairo", value: "Cairo, sans-serif" },
      { text: "Readex Pro", value: "Readex Pro, sans-serif" },
      { text: "Helvitica", value: "Helvitica, Helvitica, sans-serif" },
      { text: "Dejavo", value: "DejaVu Sans" },
    ],
    commandName: "fontName",
  };
  const CustomFontName = EditorTools.createStyleDropDownList(
    customFontNameSettings
  );

  const handleExportPDFEn = async () => {
    const editorInstance = editorRefEn.current;

    const templateName = templateForms.find(
      (template) =>
        template.id === selectedFormTemplate || valuesForEdit?.templateId
    )?.title;
    console.log(editorInstance, "handleExportPDFEn");

    if (editorInstance) {
      const domElement = editorInstance.view.dom;

      if (domElement) {
        console.log(domElement, "domElement");
        savePDF(
          domElement,
          {
            fileName: `${templateName}_${new Date().getFullYear()}.pdf`,
            paperSize: "A4",
            repeatHeaders: true,
            margin: {
              left: "2cm",
              right: "2cm",
              top: "3cm",
              bottom: "4cm",
            },
            scale: 0.7,
            pageTemplate: (propsLast) => (
              <div style={{ width: "100%", height: "100%" }}>
                {/* Green sides */}
                {/* <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '0.5cm', backgroundColor: 'green' }} />
                        <div style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: '0.5cm', backgroundColor: 'green' }} /> */}

                {/* Header */}
                <div
                  style={{
                    position: "absolute",
                    top: "0.5cm",
                    left: "1cm",
                    right: "1cm",
                    height: "2cm",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // borderBottom: "1px solid #888",
                  }}
                >
                  {/* <img src="/path/to/your/logo.png" alt="Emirates Nation Schools Logo" style={{ height: '1.5cm' }} />
                          <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#333' }}>EMIRATES NATION SCHOOLS</div> */}
                  <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "12pt",
                      lineHeight: "1.5",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: _headers?.find(
                        (header) => header.title === "Default Header"
                      ).content,
                    }}
                  />
                </div>

                {/* Footer */}
                <div
                  style={{
                    position: "absolute",
                    bottom: "0.5cm",
                    left: "1cm",
                    right: "1cm",
                    textAlign: "center",
                    color: "#888",
                    // borderTop: "1px solid #888",
                    paddingTop: "0.2cm",
                    fontSize: "10px",
                  }}
                >
                  <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "12pt",
                      lineHeight: "1.5",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: _footers?.find(
                        (footer) => footer.title === "Default Footer"
                      ).content,
                    }}
                  />

                  {/* <div>© {new Date().getFullYear()} Emirates Nation Schools. All rights reserved.</div>
                          <div>Page {props.pageNum} of {props.totalPages}</div> */}
                </div>
              </div>
            ),
          },
          () => {
            console.log("PDF has been saved successfully!");
            // if (pdfExportComponent.current) {
            //   pdfExportComponent.current.save();
            //   }
          }
        );
      } else {
        console.error("No element to export");
      }
    } else {
      console.error("Editor instance is not available");
    }
  };

  const fetchTemplateForms = async () => {
    try {
      const response = await fetchAllTemplates();
      const sccTemplates = response.data.data.filter((template) =>
        template.modules.includes("scc")
      );
      setTemplateForms(sccTemplates);
    } catch (error) {
      console.log(error);
    }
  };

  const sortedTemplateForms = [...templateForms].sort((a, b) => {
    const getFormNumber = (title) => {
      const match = title.match(/Form (\d+)/i);
      return match ? parseInt(match[1], 10) : Infinity;
    };

    const aNum = getFormNumber(a.title);
    const bNum = getFormNumber(b.title);

    return aNum - bNum;
  });

  const handleGeneratePdf = async () => {
    setLoading(true);
    try {
      const response = await handleGenerateForm({
        payload: {
          ...formName?.getFieldsValue(),
          studentNumber: JSON.stringify(selectedUser.STUDENT_NUMBER),
        },
      });
      const content = response?.data?.data;
      const viewEn = editorRefEn?.current?.view;
      const viewAr = editorRefAr?.current.view;
      setEditorContent(editorRefAr.current.view.dom.innerText);

      if (content.contentEn) {
        setHtml(viewEn, content.contentEn);
      }
      if (content.contentAR) {
        setHtml(viewAr, content.contentAR);
      }
      //  document.querySelector('.k-content').style.direction = 'rtl';
      setLoading(false);
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const handelSearchUser = useCallback(async () => {
    try {
      setSearchLoading(true);
      const response = await getSearchedStudentsList({
        search: searchQuery,
      });
      if (response.status === 200) {
        setUsers(response.data?.data);
        setSearchLoading(false);
      }
    } catch (error) {
      setSearchLoading(false);
    }
  }, [searchQuery]);

  const handleBlur = () => {
    setTimeout(() => setModalVisible(false), 200);
  };

  const handleFocus = () => {
    setModalVisible(true);
    setUserError("");
  };
  const handleSelectUser = async (user) => {
    setSelectedUser(user);
    setModalVisible(false);
    getUser(user);
  };
  const handleClear = () => {
    setSelectedUser({});
    setSearchQuery("");
  };

  const handleChangeFormTemplate = (e) => {
    setSelectedFormTemplate(e);
  };

  const handleSubmitForm = (values) => {
    console.log(values);

    if (
      !valuesForEdit &&
      (!selectedUser || Object.keys(selectedUser).length === 0)
    ) {
      setUserError("Student is required");
      return;
    }

    let modifiedEn = contentEn;
    let modifiedAr = contentAr;

    // Updated table and cell styles for full width and merged borders
    const tableStyle =
      "border-collapse: collapse; width: 100%;"; // Ensure full width and merged borders
    const tdStyle =
      "border: 1px solid rgba(128, 128, 128, 0.5); padding: 2px; text-align: center;";

    modifiedEn = modifiedEn
      .replace(/<table>/g, `<table style='${tableStyle}'>`)
      .replace(/<td>/g, `<td style='${tdStyle}'>`);

    modifiedAr = modifiedAr
      .replace(/<table>/g, `<table style='${tableStyle}'>`)
      .replace(/<td>/g, `<td style='${tdStyle}'>`);

    // Removed unnecessary replacements for table styles
    modifiedEn = modifiedEn
      .replace(/<p>/g, "<p style='font-size: 13px; '>");

    // For Arabic version
    modifiedAr = modifiedAr
      .replace(/<p>/g, "<p style='font-size: 13px; '>");

    function addStyleIfMissing(content, style) {
      console.log(content, style);
      // Wrap the content inside a <div> with the specified style
      return `<div style="${style}">${content}</div>`;
    }

    const divStyle = `
              padding-left: 2cm; 
              margin-right: 2cm;  
              font-size: 14px; 
              line-height: 1.2;
          `;

    // Apply the style if missing for English content
    if (modifiedEn.indexOf("<div") !== -1) {
      console.log(modifiedEn);
      modifiedEn = addStyleIfMissing(modifiedEn, divStyle);
    }

    // Apply the style if missing for Arabic content
    if (modifiedAr.indexOf("<div") !== -1) {
      console.log(modifiedAr, "inside add");
      modifiedAr = addStyleIfMissing(modifiedAr, divStyle);
    }


    const studentData = selectedUser || valuesForEdit?.selectedUser;
    const formData = {
      content: modifiedEn,
      contentAr: modifiedAr,
      status:
        formName?.getFieldValue("status") === "complete" ? "complete" : "draft",
      section: values?.section || valuesForEdit?.section,
      templateId: selectedFormTemplate || valuesForEdit?.templateId,
      studentNumber: studentData?.studentNumber || valuesForEdit?.studentNumber,
      year: values?.year || valuesForEdit.year,
    };

    onSubmit({
      isPdfDownloadAction: false,
      campus: studentData?.campus || valuesForEdit?.campus,
      grade: studentData?.grade || valuesForEdit?.grade,
      school: studentData?.school || valuesForEdit?.school,
      ...formData,
    });

    if (!valuesForEdit) {
      setSelectedUser({});
    }
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setIsCompleteModalVisible(true);
    } else {
      formName.setFieldsValue({ status: "draft" });
      setIsChecked(false);
    }
  };

  const handleCompleteConfirm = () => {
    formName.setFieldsValue({ status: "complete" });
    setIsChecked(true);
    setIsCompleteModalVisible(false);
  };

  const handleCompleteCancel = () => {
    setIsCompleteModalVisible(false);
    setIsChecked(false);
  };

  const handleEditorChangeEn = (data) => {
    setContentEn(data?.html);
  };

  const handleEditorChangeAr = (data) => {
    setContentAr(data?.html);
  };
  useEffect(() => {
    fetchTemplateForms();
    if (valuesForEdit && valuesForEdit?.studentNumber) {
      setSelectedUser(valuesForEdit?.selectedUser);
      getUser(valuesForEdit?.selectedStudent);
    }
    return () => {
      setSelectedUser({});
    };
  }, [valuesForEdit]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (searchQuery.trim() !== "") {
        handelSearchUser();
      } else {
        // Call the API when searchQuery is empty (cleared)
        handelSearchUser();
        setSelectedUser({});
      }
    }, 1000); // Adjust the delay time as needed

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    }; // Cleanup the timeout
  }, [searchQuery, handelSearchUser]);

  useEffect(() => {
    if (valuesForEdit?.selectedUser) {
      setSelectedUser(valuesForEdit?.selectedUser);
    }
  }, []);

  useEffect(() => {
    getAllHeadersAndFooter();
    if (editorRefAr.current) {
      const content = document.querySelector(".k-content");
      let editor = document.getElementsByClassName("k-editor")[0];
      let iFrame = editor.querySelector("iframe");
      iFrame.contentDocument.querySelector(".k-content");
      // .setAttribute("style", "font-family: 'Amiri';");
      console.log(iFrame, "fooo");
      if (content) {
        content.style.direction = "rtl";
      }
    }
  }, []);
  const styles = `
   p {
      color: #53d2fa;
      font-family: "Amiri";
      line-height: 1.1; /* Adjust line height for 1px spacing */
      word-spacing: 2px; /* Add 2px word spacing */
  }
`;
  // const onMount = (event) => {
  //   const iframeDocument = event.dom.ownerDocument;
  //   const style = iframeDocument.createElement("style");
  //   style.appendChild(iframeDocument.createTextNode(styles));
  //   iframeDocument.head.appendChild(style);
  // };

  const onIframeInit = (ev) => {
    const stylesheets = [
      "../../../index.css",
      "../../../assets/fonts/Amiri/Amiri-Regular.ttf",
      // '../assets/styles/rtlStyles.css' : undefined
    ];

    stylesheets.forEach((href) => {
      if (href && ev.iframe && ev.iframe.contentWindow) {
        const iframeDocument = ev.iframe.contentWindow.document;
        const linkElementWithiFrameStyles =
          iframeDocument.createElement("link");
        linkElementWithiFrameStyles.rel = "stylesheet";
        linkElementWithiFrameStyles.type = "text/css";
        linkElementWithiFrameStyles.href = href;
        iframeDocument.head.appendChild(linkElementWithiFrameStyles);
      }
    });
  };

  function removeStyleFromDivs(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const divs = doc.querySelectorAll("div[style]"); // Select all divs with a style attribute

    divs.forEach((div) => {
      div.removeAttribute("style"); // Remove the style attribute
    });

    return doc.body.innerHTML; // Return the modified HTML
  }

  useEffect(() => {
    if (valuesForEdit) {
      const freshContentEn = removeStyleFromDivs(valuesForEdit.content);
      const freshContentAr = removeStyleFromDivs(valuesForEdit.contentAr);
      setContentEn(valuesForEdit.content ? freshContentEn : "");
      setContentAr(valuesForEdit.contentAr ? freshContentAr : "");
      formName.setFieldsValue({
        content: valuesForEdit.content || "",
        contentAr: valuesForEdit.contentAr || "",
      });
    }
  }, [valuesForEdit, formName]);

  return (
    <div className="scc__genenral_container">
      <Spin spinning={isloading} indicator={renderLoader}>
        <Form
          ref={formRef}
          layout="vertical"
          form={formName}
          onFinish={handleSubmitForm}
        >
          <Row gutter={10}>
            <Col md={12}>
              <div className="customer-search-container">
                <Form.Item label={"Student"}>
                  {!valuesForEdit?.studentNumber ? (
                    <Input
                      style={{ border: userError ? "1px solid red" : "" }}
                      id="customer-search"
                      type="text"
                      disabled={valuesForEdit?.studentNumber}
                      placeholder="Search Student"
                      onFocus={handleFocus}
                      onChange={handleSearch}
                      value={
                        (Object.keys(selectedUser).length &&
                          selectedUser?.FIRST_NAME +
                            " " +
                            selectedUser?.LAST_NAME +
                            " (" +
                            selectedUser?.STUDENT_NUMBER +
                            ") ") ||
                        searchQuery
                      }
                      onBlur={handleBlur}
                      autoComplete="off"
                      suffix={
                        <CloseCircleOutlined
                          onClick={handleClear}
                          style={{
                            visibility:
                              searchQuery || selectedUser?.STUDENT_NUMBER
                                ? "visible"
                                : "hidden",
                            cursor: "pointer",
                          }}
                        />
                      }
                    />
                  ) : (
                    <h4>{valuesForEdit?.studentNumber}</h4>
                  )}
                </Form.Item>

                {isModalVisible && (
                  <div className="customer-search-modal">
                    {searchLoading ? (
                      <div className="spiner">
                        <Spin />
                      </div>
                    ) : users?.length === 0 ? (
                      <div className="custom_empty_div">
                        <p>No student found</p>
                      </div>
                    ) : (
                      <React.Fragment>
                        {users?.map((user, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => handleSelectUser(user)}
                              className="customer-search-result"
                            >
                              <div className="customer-search_result_container">
                                <div className="search_result_info">
                                  <p className="custom_title">
                                    {user?.FIRST_NAME +
                                      " " +
                                      user?.LAST_NAME +
                                      " (" +
                                      user?.STUDENT_NUMBER +
                                      ") "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </div>
                )}
                <p className="form_custom_error">{userError}</p>
              </div>
            </Col>
            <Col md={12}>
              <Form.Item
                label={"Templates"}
                name={"templateId"}
                rules={setRules("Form Templates")}
              >
                {!valuesForEdit?.templateId ? (
                  <Select
                    disabled={valuesForEdit?.templateId}
                    placeholder="Select"
                    onChange={(e) => handleChangeFormTemplate(e)}
                    defaultValue={valuesForEdit && valuesForEdit?.templateId}
                  >
                    {sortedTemplateForms.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <h6>
                    {
                      templateForms.find(
                        (template) => template?.id === valuesForEdit?.templateId
                      )?.title
                    }
                  </h6>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col md={12}>
              <Form.Item label={"Year"} name={"year"}>
                {!valuesForEdit?.year ? (
                  <Select
                    placeholder="Select"
                    // onChange={(value) => handleYearChange(value)}
                    defaultValue={valuesForEdit && valuesForEdit?.year}
                    disabled={valuesForEdit?.year}
                  >
                    {selectedUser?.studentYearGrade?.map((year, index) => (
                      <Option value={year.Year} key={index}>
                        {year.Year}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <h4>{valuesForEdit?.year}</h4>
                )}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label={"Section"} name={"section"}>
                {!valuesForEdit?.section ? (
                  <Input
                    disabled={valuesForEdit?.section}
                    style={{ border: userError ? "1px solid red" : "" }}
                    id="section"
                    type="text"
                    placeholder="Enter section"
                    defaultValue={valuesForEdit && valuesForEdit?.section}
                    // value={section}
                    // onBlur={handleBlur}
                    autoComplete="off"
                  />
                ) : (
                  <h4>{valuesForEdit?.section}</h4>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10} justify="end">
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <span
                onClick={handleGeneratePdf}
                style={{
                  display: "inline-block",
                  padding: "8px 16px",
                  backgroundColor: "#007B85",
                  marginBottom: "10px",
                  color: "white",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Generate Form
              </span>
            </Col>
          </Row>

          {/* <Row>
            <Col
              md={24}
              style={
                !togglePreviewMode ? { display: "none" } : { display: "block" }
              }
            >
              <Form.Item
                className="ckEditorEn"
                label={"Description (EN)"}
                name={"content"}
                rules={[
                  { required: true, message: "Description (EN) is required" },
                ]}
                valuePropName={"data"}
                getValueFromEvent={(event, editor) => {
                  console.log(editor.getData());
                  return editor ? editor.getData() : "";
                }}
              >
              </Form.Item>
            </Col>
            </Row> */}

          <Row gutter={10}>
            <Col md={12}>
              <h5>English Editor</h5>
              <Editor
                ref={editorRefEn}
                defaultContent={contentEn}
                onMount={onMount}
                className="k-editorEn"
                id="k-editorEn"
                tools={[
                  [Bold, Italic, Print, InsertFile, Underline, Strikethrough],
                  [Subscript, Superscript, ForeColor, BackColor],
                  [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                  [Indent, Outdent],
                  [OrderedList, UnorderedList],
                  // { name: 'FontSize', settings: fontSizeSettings },
                  // { name: 'ParagraphFormat', settings: paragraphSettings },
                  [FontSize],
                  [CustomFontName],

                  [CleanFormatting, BulletedList, NumberedList],
                  [FormatBlock],
                  [Undo, Redo],
                  [Link, Unlink, InsertImage, ViewHtml],
                  [InsertTable, TableProperties, TableCellProperties],
                  [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                  [DeleteRow, DeleteColumn, DeleteTable],
                  [MergeCells, SplitCell],
                ]}
                contentStyle={{
                  height: 430,
                }}
                onChange={handleEditorChangeEn}
              />
            </Col>

            <Col md={12}>
              <h5>Arabic Editor</h5>
              <PDFExport ref={pdfExportComponent} paperSize="A4">
                <Editor
                  ref={editorRefAr}
                  defaultContent={contentAr}
                  onMount={onMount}
                  className="k-editorAr"
                  id="k-editorAr"
                  tools={[
                    [Bold, Italic, Print, Underline, Strikethrough],
                    [Subscript, Superscript, ForeColor, BackColor],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize,
                    CustomFontName,
                    FormatBlock,
                    [Undo, Redo],
                    [InsertFile, CleanFormatting, BulletedList, NumberedList],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable, TableProperties, TableCellProperties],
                    [
                      AddRowBefore,
                      AddRowAfter,
                      AddColumnBefore,
                      AddColumnAfter,
                    ],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell],
                  ]}
                  // onMount={onMount}
                  // onIframeInit={onIframeInit}
                  contentStyle={{
                    height: 430,
                    direction: "rtl",
                    fontFamily: "Amiri, Arial, sans-serif", // Add the Arabic font here
                  }}
                  dir="rtl"
                  onChange={handleEditorChangeAr}
                />
              </PDFExport>
            </Col>
          </Row>
          <Divider />
          {valuesForEdit && (
            <Row>
              <Col md={12}>
                <span
                  onClick={() => showDownloadConfirmation("en")}
                  style={{
                    display: "inline-block",
                    padding: "8px 16px",
                    backgroundColor: "#007B85",
                    marginBottom: "10px",
                    color: "white",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Download PDF En
                </span>
              </Col>

              <Col md={12}>
                <span
                  onClick={() => {
                    // handleExportPDF()
                    showDownloadConfirmation("ar");
                  }}
                  style={{
                    display: "inline-block",
                    padding: "8px 16px",
                    backgroundColor: "#007B85",
                    marginBottom: "10px",
                    color: "white",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Download PDF Ar
                </span>
              </Col>
            </Row>
          )}
          {/* 
          <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink> */}

          <br />
          {valuesForEdit && (
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item>
                  <Checkbox checked={isChecked} onChange={handleCheckboxChange}>
                    Mark As Complete
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row justify="end">
            <Button
              htmlType="submit"
              loading={loading}
              className="ant__primary__button"
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </Spin>

      <Modal
        title="Confirm Download"
        visible={isDownloadModalVisible}
        onOk={handleDownloadConfirm}
        onCancel={() => setIsDownloadModalVisible(false)}
      >
        <p>
          Are you sure you want to download the file? This will save the latest
          changes before downloading.
        </p>
      </Modal>

      <Modal
        title="Confirm Completion"
        visible={isCompleteModalVisible}
        onOk={handleCompleteConfirm}
        onCancel={handleCompleteCancel}
      >
        <p>
          Are you sure you want to complete this document? You won't be able to
          edit it later.
        </p>
      </Modal>
    </div>
  );
};

export default SccDocumentGeneratorForm;