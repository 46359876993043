import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
const { Option } = Select;
const FaqsForm = ({ onSubmit, loading, formName, _requestTypes }) => {
  const _faqsCategories = useSelector(
    (state) => state.faqsCategories.faqsCategories
  );

  const [variantBox, setVariantBox] = useState({ variantsEn: "", variantsAr: "" });
  const [allVariant, setAllVariant] = useState([]);

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ]
  }
  const handleVariant = (e) => {
    const val = e.target.value;
    const name = e.target.name;

    setVariantBox((pre) => ({ ...pre, [name]: val }));
  }
  const submitTheVariant = () => {
    setAllVariant((previousVariant) => [...previousVariant, variantBox])
    setVariantBox({})
  }


  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Application Type"}
              name={"applicationType"}
              rules={setRules("ApplicationType")}
            >
              <Select
                value={_requestTypes?.find((el) => el.code)}
              >
                {_requestTypes?.map((el, i) => {
                  return (
                    <Option key={i} value={`${el.identifier}`}>
                      {el.identifier}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Faqs Category"}
              name={"categoryId"}
              rules={setRules("categoryId")}
            >
              <Select>
                {_faqsCategories?.map((el, i) => {
                  return (
                    <Option key={i} value={el.id}>
                      {el.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div>
          {allVariant.map((variant, i) => (
            <div className="flex justify-content-between">
              <div>
                {variant.variantsEn}
              </div>
              <div>
                {variant.variantsAr}
              </div>
            </div>
          ))}
        </div>

        <Form.Item
          className="pt-4"
          rules={setRules("Question (EN)")}
          label={"Question (EN)"}
          name={"question"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        >
          <CKEditor
            config={
              {
                //toolbar: EditorToolbar,
              }
            }
            editor={ClassicEditor}
            key={"question"}
          />
        </Form.Item>
        {/* <Form.Item
          className="pt-4"
          rules={setRules("Question (EN)")}
          label={"Question (EN) Variant"}
          name={"variant"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        
        >
        <input value={variantBox.en} name="en" type="text" onChange={handleVariant}/> 

          <CKEditor
            config={
              {
                //toolbar: EditorToolbar,
              }
            }
            editor={ClassicEditor}
            key={"question"}
          />
        </Form.Item> */}

        <Form.Item
          className="pt-4"
          rules={setRules("Question (AR)")}
          label={"Question (AR)"}
          name={"questionAr"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        >
          <CKEditor
            config={{
              //toolbar: EditorToolbar,
              language: "ar",
            }}
            editor={ClassicEditor}
            key={"questionAr"}
          />
        </Form.Item>



        <Form.Item
          className="pt-4"
          rules={setRules("Question (AR)")}
          label={"Question (AR) Variant"}
          name={"variantAR"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        >
          {/* <input value={variantBox.ar} name="ar" type="text" onChange={handleVariant}/>  */}


          <CKEditor
            config={{
              //toolbar: EditorToolbar,
              language: "ar",
            }}
            editor={ClassicEditor}
            key={"questionAr"}
          />
        </Form.Item>
        {/* <button onClick={submitTheVariant}>Submit</button> */}
        <div className="flex w-72    flex-col gap-6">

          <div class="relative h-10 my-32 w-full min-w-[200px]">
            <input
              class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-teal-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder="Enter English content for variant "
              value={variantBox.variantsEn} name="variantsEn" type="text" onChange={handleVariant}
            />
            <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-teal-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-teal-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-teal-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              English Variant
            </label>
            <input
              class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-teal-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder="Enter Arabic content for variant "
              value={variantBox.variantsAr} name="variantsAr" type="text" onChange={handleVariant}
            />
            <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-teal-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-teal-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-teal-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Arabic Variant
            </label>

            <button onClick={submitTheVariant}>Submit Variant</button>
          </div>
        </div>


        <Form.Item
          className="pt-4"
          rules={setRules("Answer (EN)")}
          label={"Answer (EN)"}
          name={"answer"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        >
          <CKEditor
            config={
              {
                //toolbar: EditorToolbar,
              }
            }
            editor={ClassicEditor}
            key={"Answer"}
          />
        </Form.Item>
        <Form.Item
          className="pt-4"
          rules={setRules("Answer (AR)")}
          label={"Answer (AR)"}
          name={"answerAr"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        >
          <CKEditor
            config={{
              //toolbar: EditorToolbar,
              language: "ar",
            }}
            editor={ClassicEditor}
            key={"answerAr"}
          />
        </Form.Item>
        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default FaqsForm;
