import { Card } from "antd";
import CommonTable from "components/Tables/CommonTable";
import React from "react";

const UPDStudentTable = ({ application }) => {
  return application.applicationType === "UPD" &&
    application.parentStudents?.length > 0 ? (
    <Card className="antCardBorder antCard" style={{ marginTop: 10 }} hoverable>
      <CommonTable
        data={application.parentStudents}
        header={() => <h6>Students</h6>}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Student Number",
            dataIndex: "studentNumber",
          },
          {
            title: "Campus",
            dataIndex: "campus",
          },
          {
            title: "Grade",
            dataIndex: "grade",
          },
        ]}
        pagination={false}
      />
    </Card>
  ) : null;
};

export default UPDStudentTable;
