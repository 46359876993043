import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Popconfirm, Row, Spin, Tooltip } from "antd";
import { deleteVacanciesAPI } from "apis/vacancies-cms/VacanciesCMSApi";
import { updateVacanciesAPI } from "apis/vacancies-cms/VacanciesCMSApi";
import { getVacanciesAPI } from "apis/vacancies-cms/VacanciesCMSApi";
import { addVacanciesAPI } from "apis/vacancies-cms/VacanciesCMSApi";
import CustomModal from "components/CustomModal/CustomModal";
import VacanciesForm from "components/Forms/cms/VacanciesForm";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const VacancyCMS = () => {
  const _campuses = useSelector((state) => state.metaData.campuses);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [vacancies, setVacancies] = useState();
  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [loading, setLoading] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [uploading, setUpLoading] = useState(false);
  const [file, setFile] = useState();
  const [AddVacancyForm] = Form.useForm();

  const _vacancyLocation = [
    { title: "Campus Based", value: "campuse_based" },
    { title: "Central Administration", value: "central_administration" },
  ];

  // API FUNCTIONS

  const getVacancies = async () => {
    try {
      setLoading(true);
      const response = await getVacanciesAPI(page);
      setLoading(false);
      if (response.status === 200 && response.data?.data) {
        setVacancies(response.data.data);
        setTotal(response.data.total);
      } else {
        setVacancies();
        throw response;
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const addVacancies = async (data) => {
    try {
      setLoading(true);
      const response = await addVacanciesAPI(data);
      setLoading(false);
      if (response.status == 200) {
        successMessage({ message: "Record added successfully" });
        await getVacancies();
        onCloseForm();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const updateVacancies = async (data) => {
    try {
      setLoading(true);
      const response = await updateVacanciesAPI(data, valuesForEdit.id);
      setLoading(false);
      if (response.status == 200) {
        successMessage({ message: "Record updated successfully" });
        await getVacancies();
        onCloseForm();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };
  const deleteVacancies = (id) => async () => {
    try {
      setLoading(true);
      const response = await deleteVacanciesAPI(id);
      if (response.status == 200) {
        successMessage({ message: "Record deleted successfully" });
        await getVacancies();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVacancies();
  }, [page]);

  const renderHeader = () => {
    return (
      <TableHeader
        onAddNew={() => {
          AddVacancyForm.resetFields();
          toggleModal(true);
          setModalTitle(ModalType.ADD);
        }}
        headerTitle={"Vacancies"}
        headerSubTitle={"Manage vacancies content for ENS Website"}
        headerBtnTitle={"Add New"}
      />
    );
  };
  const onChooseFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "vacancies"
        );
        setFile(path);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      descDocument: file,
      body: "Body",
      bodyAr: "Body Arabic",
    };
    if (modalTitle === ModalType.EDIT) {
      await updateVacancies(data);
    } else {
      await addVacancies(data);
    }
  };
  const handleEdit = (record) => {
    record.forCentralAdministration =
      record.campuses.length == 0 ? true : false;
    setValuesForEdit(record);
    record.descDocument && setFile(record.descDocument);
    AddVacancyForm.setFieldsValue(record);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  const onCloseForm = () => {
    setValuesForEdit(null);
    toggleModal(false);
    setFile(undefined);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={vacancies}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Subject",
              dataIndex: "subject",
            },
            {
              title: "Location",
              dataIndex: "campuses",
              render: (record) => {
                if (record?.length == 0)
                  return _vacancyLocation.find(
                    (item) => item.value == "central_administration"
                  )?.title;

                var all_campuses_codes = [];
                _campuses.forEach((item) => {
                  all_campuses_codes.push(item.code);
                });

                if (
                  record.sort().join(",") ===
                  all_campuses_codes.sort().join(",")
                )
                  return "Emirates National Schools";
                else return record.map((el) => el).join(", ");
              },
            },
            {
              title: "Department",
              dataIndex: "department",
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "id",
              render: (record) =>
                record ? (
                  <span style={{ color: "green" }}>Active</span>
                ) : (
                  <span style={{ color: "red" }}>Inactive</span>
                ),
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => {
                return (
                  <Row gutter={[5]}>
                    <Col>
                      <a
                        href={item.descDocument}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Tooltip title="Download File">
                          <Button
                            shape="circle"
                            icon={<DownloadOutlined />}
                          ></Button>
                        </Tooltip>
                      </a>
                    </Col>
                    <Col>
                      <Tooltip title="Update Vacancy">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </Col>
                    <Col>
                      <Popconfirm
                        title="Are you sure you want to delete this vacancy?"
                        okText="Yes"
                        cancelText="No"
                        style={{ marginLeft: "30px" }}
                        onConfirm={deleteVacancies(item.id)}
                      >
                        <Tooltip title="Delete">
                          <Button
                            className="d-flex justify-content-center align-items-center"
                            shape="circle"
                            icon={<DeleteOutlined />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    </Col>
                  </Row>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Vacancy`}
        >
          <VacanciesForm
            onSubmit={onSubmit}
            formName={AddVacancyForm}
            loading={loading}
            uploading={uploading}
            onChooseFile={onChooseFile}
            file={file}
            valuesForEdit={valuesForEdit}
            _campuses={_campuses}
            _vacancyLocation={_vacancyLocation}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default VacancyCMS;
