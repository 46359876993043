import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Checkbox,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { fetchAllDiscountTypes } from "../disocunt-types/api";
import { fetchAllFsmFacilities } from "views/FSM/facility-mangement/all-facilities/api";
import moment from "moment";
import { fsmDisocuntCategories } from "utils/constants";
import { fetchAllDiscounts, getFacilitiesByDiscountCategoryType } from "./api";
const { Option } = Select;

const AllDiscountForm = ({ onSubmit, loading, formName, valuesForEdit }) => {
  const _campuses = useSelector((state) => state.metaData.campuses);
  const [checkboxError, setCheckboxError] = useState("");

  const [discountTypes, setDiscountTypes] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [allDiscounts, setAllDiscounts] = useState([]);
  const [typeGeneral, setTypeGeneral] = useState("");

  const [thresholds, setThresholds] = useState([
    {
      noOfPersons: 0,
      discount: 0,
    },
  ]);

  useEffect(() => {
    if (valuesForEdit) {
      setTypeGeneral(valuesForEdit?.typeGenre);
      setThresholds(valuesForEdit?.thresholds);
      getAllFsmFacilities();
    }
  }, [valuesForEdit]);

  // useEffect(() => {
  //   getAllDiscounts();
  // }, [valuesForEdit]);

  // const getAllDiscounts = async () => {
  //   try {
  //     const response = await fetchAllDiscounts({ limit: 1000 });
  //     if (response.status === 200) {
  //       if (valuesForEdit) {
  //         let currentDiscountId = valuesForEdit?.id;
  //         const filterDiscounts = response.data.data.filter(
  //           (discount) => discount.id !== currentDiscountId
  //         );
  //         setAllDiscounts(filterDiscounts);
  //       } else {
  //         setAllDiscounts(response.data.data);
  //       }
  //     }
  //   } catch (error) {
  //     console.log("err0r", error);
  //   }
  // };

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const validateCheckboxGroup = (_, value) => {
    const { parent, nonParent } = formName.getFieldsValue();
    if (!parent && !nonParent) {
      setCheckboxError("At least one option must be selected");
      return Promise.reject(new Error(""));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    getAllDiscountTypes();
  }, []);

  const getAllDiscountTypes = async () => {
    try {
      const response = await fetchAllDiscountTypes(1000);
      if (response.status === 200) {
        setDiscountTypes(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };

  useEffect(() => {
    if (typeGeneral.length) {
      getAllFsmFacilities();
    }
  }, [typeGeneral]);

  const getAllFsmFacilities = useCallback(async () => {
    try {
      const response = await getFacilitiesByDiscountCategoryType({
        type: typeGeneral,
      });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [typeGeneral]);

  const handleCheckboxChange = () => {
    const { parent, nonParent } = formName.getFieldsValue();
    if (parent || nonParent) {
      setCheckboxError(""); // Clear error if any checkbox is selected
    }
  };

  const addThreshold = () => {
    setThresholds([...thresholds, { noOfPersons: 0, discount: 0 }]);
  };

  const removeThreshold = (index) => {
    setThresholds((prevThresholds) => {
      const newThresholds = [...prevThresholds];
      newThresholds.splice(index, 1);
      return newThresholds;
    });
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col lg={12} md={12} sm={24}>
            <Form.Item
              label={"Discount Categories"}
              name={"typeGenre"}
              rules={setRules("Discount Categories")}
            >
              <Select placeholder="Select" onChange={(e) => setTypeGeneral(e)}>
                {fsmDisocuntCategories.map((el, i) => (
                  <Option value={el.value} key={i}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {typeGeneral === "incremental" ? (
          <React.Fragment>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Title (EN)"}
                  name={"title"}
                  rules={setRules("Title (EN)")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Title (AR)"}
                  name={"titleAr"}
                  rules={setRules("Title (AR)")}
                >
                  <Input placeholder="Add here" type="text" dir="rtl" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  label={"Facilities"}
                  name={"facilities"}
                  rules={setRules("Facility")}
                >
                  <Select
                    placeholder="Select"
                    mode="multiple"
                    maxTagCount={2}
                    disabled={!typeGeneral.length}
                  >
                    {facilities.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  label={"Discount Type"}
                  name={"discountTypeId"}
                  rules={setRules("Discount type")}
                >
                  <Select placeholder="Select">
                    {discountTypes.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Start Date"}
                  name={"startAt"}
                  rules={setRules("Start Date")}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Date"
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"End Date"}
                  name={"endAt"}
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (moment(value).isAfter(getFieldValue("startAt"))) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(
                              "End date can not be earlier than the start date"
                            )
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Date"
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Usage Limit"}
                  name={"usageLimit"}
                  // rules={setRules("Usage Limit")}
                >
                  <Input placeholder="Usage Limit" type="number" min={0} />
                </Form.Item>
                <p className="form_item__subtitle">
                  How many times a user can avail this discount?
                </p>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Number of Kids"}
                  name={"noOfKids"}
                  // rules={setRules("Number of Kids")}
                >
                  <Input placeholder="Add here" type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col md={12}>
                <Form.Item
                  label={"Allowed With Other Discounts"}
                  name={"allowedWithOthers"}
                >
                  <Select placeholder="Select" mode="multiple">
                    {allDiscounts.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
            <br />
            <Row justify="space-between">
              <div>
                <p style={{ fontWeight: "bold" }}>Discount Thresholds</p>
              </div>
              <Button
                onClick={addThreshold}
                className="ant__primary__button"
                type="primary"
              >
                + Threshold
              </Button>
            </Row>
            <div>
              {thresholds.map((threshold, index) => (
                <Row gutter={10} key={index} align={"middle"}>
                  <Col md={10}>
                    <Form.Item
                      label={
                        typeGeneral === "incremental"
                          ? "Person Number"
                          : "Number of Persons"
                      }
                      name={["thresholds", index, "noOfPersons"]}
                      rules={setRules("Number of Persons")}
                      normalize={(value) => parseInt(value, 10)}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col md={10}>
                    <Form.Item
                      label="Discount %"
                      name={["thresholds", index, "discount"]}
                      rules={setRules("Discount")}
                      normalize={(value) => parseInt(value, 10)}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  {index !== 0 && (
                    <Col md={4}>
                      <div className="discount_remove__button">
                        <p onClick={() => removeThreshold(index)}>Remove</p>
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </div>

            <br />
            <p style={{ fontSize: 18 }}>
              Available for{" "}
              <small style={{ fontSize: 13 }}>(Select atleast one)</small>
            </p>
            <Row gutter={10}>
              <Col md={8}>
                <Form.Item
                  name="parent"
                  valuePropName="checked"
                  // rules={[{ validator: validateCheckboxGroup }]}
                >
                  <Checkbox
                    defaultChecked={false}
                    onChange={handleCheckboxChange}
                  >
                    Parent
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="nonParent"
                  valuePropName="checked"
                  // rules={[{ validator: validateCheckboxGroup }]}
                >
                  <Checkbox
                    defaultChecked={false}
                    onChange={handleCheckboxChange}
                  >
                    Non-Parent
                  </Checkbox>
                </Form.Item>
              </Col>

              {checkboxError && (
                <div style={{ color: "red", marginTop: -25 }}>
                  {checkboxError}
                </div>
              )}
              <br />
              <Form.Item
                name="checkboxGroup"
                rules={[{ validator: validateCheckboxGroup }]}
                style={{ display: "none" }} // Hide this item, it's only for validation
              >
                <input type="hidden" /> {/* Dummy input to satisfy Form.Item */}
              </Form.Item>
            </Row>
            <p style={{ marginLeft: 7 }}>Additional Condition</p>
            {/* <p>
              Must Be Employee{" "}
              <small>(Must be an employee to avail this discount)</small>
            </p> */}
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name="checkIfEmployee"
                  valuePropName="checked"
                  normalize={(value) => value || false}
                  // rules={[{ validator: validateCheckboxGroup }]}
                >
                  <Checkbox>
                    Must Be Employee{" "}
                    <small>(Must be an employee to avail this discount)</small>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24}>
                <Form.Item
                  label={"Description (EN)"}
                  name={"description"}
                  valuePropName={"data"}
                  rules={[
                    { required: true, message: "Description (EN) is required" },
                  ]}
                  getValueFromEvent={(event, editor) =>
                    editor ? editor.getData() : ""
                  }
                >
                  <CKEditor
                    config={{
                      language: "en",
                      //toolbar: EditorToolbar,
                    }}
                    editor={ClassicEditor}
                    key={"descriptiveText"}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={24}>
                <Form.Item
                  label={"Description (AR)"}
                  name={"descriptionAr"}
                  rules={[
                    { required: true, message: "Description (AR) is required" },
                  ]}
                  valuePropName={"data"}
                  getValueFromEvent={(event, editor) =>
                    editor ? editor.getData() : ""
                  }
                >
                  <CKEditor
                    config={{
                      language: "en",
                      //toolbar: EditorToolbar,
                    }}
                    editor={ClassicEditor}
                    key={"descriptiveText"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        ) : typeGeneral === "group" ? (
          <React.Fragment>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Title (EN)"}
                  name={"title"}
                  rules={setRules("Title (EN)")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Title (AR)"}
                  name={"titleAr"}
                  rules={setRules("Title (AR)")}
                >
                  <Input placeholder="Add here" type="text" dir="rtl" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  label={"Facilities"}
                  name={"facilities"}
                  rules={setRules("Facility")}
                >
                  <Select
                    placeholder="Select"
                    mode="multiple"
                    maxTagCount={2}
                    disabled={!typeGeneral.length}
                  >
                    {facilities.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  label={"Discount Type"}
                  name={"discountTypeId"}
                  rules={setRules("Discount type")}
                >
                  <Select placeholder="Select">
                    {discountTypes.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Start Date"}
                  name={"startAt"}
                  rules={setRules("Start Date")}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Date"
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"End Date"}
                  name={"endAt"}
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (moment(value).isAfter(getFieldValue("startAt"))) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(
                              "End date can not be earlier than the start date"
                            )
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Date"
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Usage Limit"}
                  name={"usageLimit"}
                  // rules={setRules("Usage Limit")}
                >
                  <Input placeholder="Usage Limit" type="number" min={0} />
                </Form.Item>
                <p className="form_item__subtitle">
                  How many times a user can avail this discount?
                </p>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Number of Kids"}
                  name={"noOfKids"}
                  // rules={setRules("Number of Kids")}
                >
                  <Input placeholder="Add here" type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col md={12}>
                <Form.Item
                  label={"Allowed With Other Discounts"}
                  name={"allowedWithOthers"}
                >
                  <Select placeholder="Select" mode="multiple">
                    {allDiscounts.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
            <br />
            <Row justify="space-between">
              <div>
                <p style={{ fontWeight: "bold" }}>Discount Thresholds</p>
              </div>
              <Button
                onClick={addThreshold}
                className="ant__primary__button"
                type="primary"
              >
                + Threshold
              </Button>
            </Row>
            <div>
              {thresholds.map((threshold, index) => (
                <Row gutter={10} key={index} align={"middle"}>
                  <Col md={10}>
                    <Form.Item
                      label="Number of Persons"
                      name={["thresholds", index, "noOfPersons"]}
                      rules={setRules("Number of Persons")}
                      normalize={(value) => parseInt(value, 10)}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col md={10}>
                    <Form.Item
                      label="Discount %"
                      name={["thresholds", index, "discount"]}
                      rules={setRules("Discount")}
                      normalize={(value) => parseInt(value, 10)}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  {index !== 0 && (
                    <Col md={4}>
                      <div className="discount_remove__button">
                        <p onClick={() => removeThreshold(index)}>Remove</p>
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </div>

            <br />
            <p style={{ fontSize: 18 }}>
              Available for{" "}
              <small style={{ fontSize: 13 }}>(Select atleast one)</small>
            </p>

            <Row gutter={10}>
              <Col md={8}>
                <Form.Item
                  name="parent"
                  valuePropName="checked"
                  // rules={[{ validator: validateCheckboxGroup }]}
                >
                  <Checkbox
                    defaultChecked={false}
                    onChange={handleCheckboxChange}
                  >
                    Parent
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="nonParent"
                  valuePropName="checked"
                  // rules={[{ validator: validateCheckboxGroup }]}
                >
                  <Checkbox
                    defaultChecked={false}
                    onChange={handleCheckboxChange}
                  >
                    Non-Parent
                  </Checkbox>
                </Form.Item>
              </Col>

              {checkboxError && (
                <div style={{ color: "red", marginTop: -25 }}>
                  {checkboxError}
                </div>
              )}
              <br />
              <Form.Item
                name="checkboxGroup"
                rules={[{ validator: validateCheckboxGroup }]}
                style={{ display: "none" }} // Hide this item, it's only for validation
              >
                <input type="hidden" /> {/* Dummy input to satisfy Form.Item */}
              </Form.Item>
            </Row>
            <p style={{ marginLeft: 7 }}>Additional Condition</p>
            {/* <p>
              Must Be Employee{" "}
              <small>(Must be an employee to avail this discount)</small>
            </p> */}
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name="checkIfEmployee"
                  valuePropName="checked"
                  normalize={(value) => value || false}
                  // rules={[{ validator: validateCheckboxGroup }]}
                >
                  <Checkbox>
                    Must Be Employee{" "}
                    <small>(Must be an employee to avail this discount)</small>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24}>
                <Form.Item
                  label={"Description (EN)"}
                  name={"description"}
                  valuePropName={"data"}
                  rules={[
                    { required: true, message: "Description (EN) is required" },
                  ]}
                  getValueFromEvent={(event, editor) =>
                    editor ? editor.getData() : ""
                  }
                >
                  <CKEditor
                    config={{
                      language: "en",
                      //toolbar: EditorToolbar,
                    }}
                    editor={ClassicEditor}
                    key={"descriptiveText"}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={24}>
                <Form.Item
                  label={"Description (AR)"}
                  name={"descriptionAr"}
                  rules={[
                    { required: true, message: "Description (AR) is required" },
                  ]}
                  valuePropName={"data"}
                  getValueFromEvent={(event, editor) =>
                    editor ? editor.getData() : ""
                  }
                >
                  <CKEditor
                    config={{
                      language: "en",
                      //toolbar: EditorToolbar,
                    }}
                    editor={ClassicEditor}
                    key={"descriptiveText"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Title (EN)"}
                  name={"title"}
                  rules={setRules("Title (EN)")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Title (AR)"}
                  name={"titleAr"}
                  rules={setRules("Title (AR)")}
                >
                  <Input placeholder="Add here" type="text" dir="rtl" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  label={"Facilities"}
                  name={"facilities"}
                  rules={setRules("Facility")}
                >
                  <Select
                    placeholder="Select"
                    mode="multiple"
                    maxTagCount={2}
                    disabled={!typeGeneral.length}
                  >
                    {facilities.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <Form.Item
                  label={"Discount Type"}
                  name={"discountTypeId"}
                  rules={setRules("Discount type")}
                >
                  <Select placeholder="Select">
                    {discountTypes.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Start Date"}
                  name={"startAt"}
                  rules={setRules("Start Date")}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Date"
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"End Date"}
                  name={"endAt"}
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (moment(value).isAfter(getFieldValue("startAt"))) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(
                              "End date can not be earlier than the start date"
                            )
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Date"
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Discount %"}
                  name={"discount"}
                  rules={setRules("Discount %")}
                >
                  <Input placeholder="Add here" type="number" min={0} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Max Discount Amount (AED)"}
                  name={"maxDiscount"}
                  // rules={setRules("Max Discount")}
                >
                  <Input placeholder="Max Discount" type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={12}>
                <Form.Item
                  label={"Usage Limit"}
                  name={"usageLimit"}
                  // rules={setRules("Usage Limit")}
                >
                  <Input placeholder="Usage Limit" type="number" min={0} />
                </Form.Item>
                <p className="form_item__subtitle">
                  How many times a user can avail this discount?
                </p>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Number of Kids"}
                  name={"noOfKids"}
                  // rules={setRules("Number of Kids")}
                >
                  <Input placeholder="Add here" type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col md={12}>
                <Form.Item
                  label={"Allowed With Other Discounts"}
                  name={"allowedWithOthers"}
                >
                  <Select placeholder="Select" mode="multiple">
                    {allDiscounts.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}

            <br />
            <p style={{ fontSize: 18 }}>
              Available for{" "}
              <small style={{ fontSize: 13 }}>(Select atleast one)</small>
            </p>

            <Row gutter={10}>
              <Col md={8}>
                <Form.Item
                  name="parent"
                  valuePropName="checked"
                  // rules={[{ validator: validateCheckboxGroup }]}
                >
                  <Checkbox
                    defaultChecked={false}
                    onChange={handleCheckboxChange}
                  >
                    Parent
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="nonParent"
                  valuePropName="checked"
                  // rules={[{ validator: validateCheckboxGroup }]}
                >
                  <Checkbox
                    defaultChecked={false}
                    onChange={handleCheckboxChange}
                  >
                    Non-Parent
                  </Checkbox>
                </Form.Item>
              </Col>

              {checkboxError && (
                <div style={{ color: "red", marginTop: -25 }}>
                  {checkboxError}
                </div>
              )}
              <br />
              <Form.Item
                name="checkboxGroup"
                rules={[{ validator: validateCheckboxGroup }]}
                style={{ display: "none" }} // Hide this item, it's only for validation
              >
                <input type="hidden" /> {/* Dummy input to satisfy Form.Item */}
              </Form.Item>
            </Row>
            <p style={{ marginLeft: 7 }}>Additional Condition</p>
            {/* <p>
              Must Be Employee{" "}
              <small>(Must be an employee to avail this discount)</small>
            </p> */}
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name="checkIfEmployee"
                  valuePropName="checked"
                  normalize={(value) => value || false}
                  // rules={[{ validator: validateCheckboxGroup }]}
                >
                  <Checkbox>
                    Must Be Employee{" "}
                    <small>(Must be an employee to avail this discount)</small>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24}>
                <Form.Item
                  label={"Description (EN)"}
                  name={"description"}
                  valuePropName={"data"}
                  rules={[
                    { required: true, message: "Description (EN) is required" },
                  ]}
                  getValueFromEvent={(event, editor) =>
                    editor ? editor.getData() : ""
                  }
                >
                  <CKEditor
                    config={{
                      language: "en",
                      //toolbar: EditorToolbar,
                    }}
                    editor={ClassicEditor}
                    key={"descriptiveText"}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={24}>
                <Form.Item
                  label={"Description (AR)"}
                  name={"descriptionAr"}
                  rules={[
                    { required: true, message: "Description (AR) is required" },
                  ]}
                  valuePropName={"data"}
                  getValueFromEvent={(event, editor) =>
                    editor ? editor.getData() : ""
                  }
                >
                  <CKEditor
                    config={{
                      language: "en",
                      //toolbar: EditorToolbar,
                    }}
                    editor={ClassicEditor}
                    key={"descriptiveText"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        )}
        <br />
        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant__primary__button"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AllDiscountForm;
