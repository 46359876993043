import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select } from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import TCCScheduleForm from "components/Forms/tcc-forms/TCCScheduleForm";
import { fetchAllSchedules } from "apis/TCC/tccRequestTypes";
import { addNewSchedules } from "apis/TCC/tccRequestTypes";
import { updateSchedules } from "apis/TCC/tccRequestTypes";
import { deleteSchedules } from "apis/TCC/tccRequestTypes";
import moment from "moment";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const ScheduleConfig = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [scheduleId, setScheduleId] = useState("");

  const [tccScheduleForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            tccScheduleForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"TCC Schedules"}
          headerSubTitle={"Manage TCC Schedules"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllSchedules();
  }, []);

  const getAllSchedules = async () => {
    try {
      setLoading(true);
      const response = await fetchAllSchedules();
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addSchedule = async (data) => {
    try {
      setLoading(true);
      const response = await addNewSchedules({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Schedule added successfully" });
        await getAllSchedules();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateSchedule = async (data) => {
    try {
      setLoading(true);
      const response = await updateSchedules({ id: scheduleId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Schedule updated successfully" });
        await getAllSchedules();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteSchedule = async (id) => {
    try {
      setLoading(true);
      const response = await deleteSchedules({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Schedule deleted successfully" });
        await getAllSchedules();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      fromTime: moment(values.fromTime).format("hh:mm"),
      toTime: moment(values.toTime).format("HH:mm"),
    };

    if (modalTitle === ModalType.EDIT) {
      await handleUpdateSchedule(data);
    } else {
      await addSchedule(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    const fromTimeFormatted = moment(record.fromTime, "HH:mm");
    const toTimeFormatted = moment(record.toTime, "HH:mm");
    tccScheduleForm.setFieldsValue({
      ...record,
      fromTime: fromTimeFormatted,
      toTime: toTimeFormatted,
    });
    setScheduleId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: "title",

              render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "From Time",
              dataIndex: "fromTime",
              key: "fromTime",

              render: (record) => <p className="answer_para">{record}</p>,
            },

            {
              title: "To Time",
              dataIndex: "toTime",
              key: "toTime",
              render: (record) => <p className="answer_para">{record}</p>,
            },

            {
              title: "Days",
              dataIndex: "days",
              key: "days",
              render: (record) => <p className="answer_para">{record}</p>,
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Schedule Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Schedule?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteSchedule(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Schedule`}
        >
          <TCCScheduleForm
            onSubmit={onSubmit}
            formName={tccScheduleForm}
            loading={loading}
            valuesForEdit={null}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default ScheduleConfig;
