import client from "apis/config";

export const getTransactionsAPI = async (
  campus = "",
  studentNumber = "",
  sessionId = "",
  page=1,
  limit=1000000
) => {
  return await client.get(
    `/payment_invoice_grid?campus=${campus}&studentNumber=${studentNumber}&sessionId=${sessionId}&page=${page}&limit=${limit}`
  );
};
export const resetTransactionsAPI = async (ids) => {
  return await client.post(`/payment_invoice_grid/reset`, ids);
};
export const getTransactionsHistoryAPI = async (page) => {
  return await client.get(`/payment_invoice_grid`);
};
export const searchTransactionAPI = async (search) => {
  return await client.get(`/payment_invoice_grid/${search}`);
};
export const addTransactionAPI = async (newTransaction) => {
  return await client.post("/payment_invoice_grid", newTransaction);
};
export const editTransactionAPI = async (updatedTransaction) => {
  return await client.put(`/payment_invoice_grid/${updatedTransaction?.id}`, updatedTransaction);
};

export const deleteTransactionAPI = async (TransactionId) => {
  return await client.delete(`/payment_invoice_grid/${TransactionId}`);
};


