import sccDashboard from "../../assets/img/sccMenuIcons/sccDashboard@2x.png";
import offenseLevel from "../../assets/img/sccMenuIcons/offenseLevel@2x.png";
import behIndicator from "../../assets/img/sccMenuIcons/behIndicator@2x.png";
import behOffenseManagement from "../../assets/img/sccMenuIcons/behOffenseManagement@2x.png";
import cod from "../../assets/img/sccMenuIcons/cod@2x.png";
import config from "../../assets/img/sccMenuIcons/config@2x.png";
import standard from "../../assets/img/sccMenuIcons/standard@2x.png";
import offenseIndicator from "../../assets/img/sccMenuIcons/offenseIndicator@2x.png";
import category from "../../assets/img/sccMenuIcons/category@2x.png";

import requesttypesIcon from "../../assets/img/fsm/fsmrequesttypes@2x.png";
import requeststatusesIcon from "../../assets/img/fsm/fsmrequeststatuses@2x.png";


import sccReportIcon from "../../assets/img/sidebarIcons/report.png";
import caseManagementIcon from "../../assets/img/sidebarIcons/case management.png";
import documentGeneratorIcon from "../../assets/img/sidebarIcons/document generator.png";
import SccCodeOfConductIcon from "../../assets/img/sidebarIcons/code of conduct icon.png";



import SccCategories from "views/SCC/scc-Categories/SccCategories";
import SccStandards from "views/SCC/scc-standards/SccStandards";
import SccOffenseLevels from "views/SCC/scc-offense-levels/SccOffenseLevels";
import SccOffenseIndicators from "views/SCC/scc-offense-indicators/SccOffenseIndicators";
import SccBasicReports from "views/SCC/scc-basic-reports/SccBasicReports";
import SccBehaviourIndicators from "views/SCC/scc-indicators/SccBehaviourIndicators";
import SccOffenseBehaviourManagement from "views/SCC/scc-offense-management/SccOffenseBehaviourManagement";
import SccDocumentGenerator from "views/SCC/scc-document-generator/SccDocumentGenerator";
import SccNotificationsTriggers from "views/SCC/configurations/scc-notification-triggers/SccNotificationsTriggers";
import SccStagesConfiguration from "views/SCC/configurations/scc-stages-config/SccStagesConfiguration";
import SccDocGeneratorConfiguration from "views/SCC/configurations/scc-doc-generator-config/SccDocGeneratorConfiguration";
import SccStageStatuses from "views/SCC/configurations/scc-statge-statuses/SccStageStatuses";
import SccStageList from "views/SCC/configurations/scc-stages-config/SccStageList";
import SccDetails from "views/SCC/scc-detail-screen/SccDetails";
import SccYearsAndTerms from "views/SCC/configurations/years-terms/SccYearsAndTerms";
import SccYearLogs from "views/SCC/configurations/years-terms/SccYearLogs";
import SccTermLogs from "views/SCC/configurations/years-terms/SccTermLogs";
import PdfContent from "views/SCC/scc-basic-reports/pdf/PdfContent";
import SccDashboard from "views/SCC/scc-dashboard/SccDashboard";
import SccDistinguishIndicators from "views/SCC/distinguish-indicators/SccDistinguishIndicators";

const sccRoutes = [
  {
    path: "/students-code-of-conducts",
    name: "Student Code of conduct",
    icon: cod,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "scc",
    subMenu: [
      {
        path: "/scc-dashboard",
        name: "Dashboard",
        icon: sccDashboard,
        component: SccDashboard,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Dashboard",
        state: null,
      },
      {
        path: "/scc-reports",
        name: "Reports",
        icon: sccReportIcon,
        component: SccBasicReports,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Reports",
        state: null,
      },
      {
        path: "/scc-behaviour-offense-management",
        name: "Case Management",
        icon: caseManagementIcon,
        component: SccOffenseBehaviourManagement,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Case Management",
        state: null,
      },
      {
        path: "/scc-document-generator",
        name: "Document Generator",
        icon: documentGeneratorIcon,
        component: SccDocumentGenerator,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Document Generator",
        state: null,
      },
     
      {
        path: "/scc-categories",
        name: "Categories",
        icon: category,
        component: SccCategories,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Categories",
        state: null,
      },
      {
        path: "/scc-standards",
        name: "Standards",
        icon: standard,
        component: SccStandards,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Standards / Offense Types",
        state: null,
      },
      {
        path: "/scc-offense-levels",
        name: "Offenses Levels",
        icon: offenseLevel,
        component: SccOffenseLevels,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Offenses Levels",
        state: null,
      },

      {
        path: "/scc-distinguish-indicators",
        name: "Indicators",
        icon: config,
        component: null,
        layout: "/admin",
        subPath: true,
        type: "scc",
        subType: "fsm-sub",
        sideMenuTitle: "Indicators",
        state: null,
        subMenu: [
          {
            path: "/scc-distinguish-indicators",
            name: "Distinguish",
            icon: offenseIndicator,
            component: SccDistinguishIndicators,
            layout: "/admin",
            subPath: true,
            sideMenuTitle: "Distinguish",
            state: null,
          },

          {
            path: "/scc-indicators",
            name: "Behaviour",
            icon: behIndicator,
            component: SccBehaviourIndicators,
            layout: "/admin",
            subPath: true,
            sideMenuTitle: "Behaviour",
            state: null,
          },

          {
            path: "/scc-offense-indicators",
            name: "Offense",
            icon: offenseIndicator,
            component: SccOffenseIndicators,
            layout: "/admin",
            subPath: true,
            sideMenuTitle: "Offense",
            state: null,
          },
        ],
      },

      {
        path: "/scc-config",
        name: "Configurations",
        icon: config,
        component: null,
        layout: "/admin",
        subPath: true,
        type: "scc",
        subType: "fsm-sub",
        sideMenuTitle: "Configurations",
        state: null,
        subMenu: [
          {
            path: "/scc-stages",
            name: "Stage Configuraiton",
            icon: requesttypesIcon,
            component: SccStageList,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Stage Configuraiton",
            state: null,
          },
          {
            path: "/scc-doc-generator-config",
            name: "Document Generator",
            icon: requesttypesIcon,
            component: SccDocGeneratorConfiguration,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Document Generator",
            state: null,
          },
          {
            path: "/scc-stage-statuses",
            name: "Stage Statuses",
            icon: requeststatusesIcon,
            component: SccStageStatuses,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Stage Statuses",
            state: null,
          },

          {
            path: "/scc-notifications-triggers",
            name: "Notifications Triggers",
            icon: requesttypesIcon,
            component: SccNotificationsTriggers,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Notifications Triggers",
            state: null,
          },
          {
            path: "/scc-years-and-terms",
            name: "Years & Terms",
            icon: requesttypesIcon,
            component: SccYearsAndTerms,
            layout: "/admin",
            subPath: true,
            subType: true,
            sideMenuTitle: "Years & Terms",
            state: null,
          },
        ],
      },
    ],
  },
  {
    path: "/scc/stage-confgiuration",
    name: "Stage Cnnfiguration",
    icon: "nc-icon nc-bag-16",
    component: SccStagesConfiguration,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/scc/conduct-case-details",
    name: "Details",
    icon: "nc-icon nc-bag-16",
    component: SccDetails,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/scc/year-logs",
    name: "Year Logs",
    icon: "nc-icon nc-bag-16",
    component: SccYearLogs,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/scc/term-logs",
    name: "Term Logs",
    icon: "nc-icon nc-bag-16",
    component: SccTermLogs,
    layout: "/admin",
    subPath: true,
  },
  {
    path: "/scc/stage-configuration",
    name: "Stages",
    icon: "nc-icon nc-bag-16",
    component: SccStagesConfiguration,
    layout: "/admin",
    subPath: true,
  },
];
export default sccRoutes;
