/* eslint-disable no-unused-vars */
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import {
  getStageConfigAction,
  getStageMetaDataAction,
} from "actions/meta-data/metaDataAction";
import { UserRoles } from "utils/constants";
import EmployeeRoutes from "routes/EmployeeRoutes";
import AdminRoutes from "routes/AdminRoutes";
import { _getSCHApplicationMetaData } from "actions/meta-data/metaDataAction";
import { _getTCCApplicationMetaData } from "actions/meta-data/metaDataAction";
import { _getFSMApplicationMetaData } from "actions/meta-data/metaDataAction";
import { _getSccCaseMetaData } from "actions/meta-data/metaDataAction";
var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "white",
      activeColor: "info",
      loading: false,
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    this.props.getStages();
    this.props.getStagesMetaData();
    this.props.getSCHMetaData();
    this.props.getTCCMetaData();
    this.props.getFSMMetaData();
    this.props.getSCCMetaData();
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      sessionStorage.setItem("currentPage", "1");
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  permissions = this.props?.user?.permissions;
  fmsPermissions = this.props?.user?.fmsPermissions;
  hasCashierAccess = this.props?.user?.hasCashierAccess;
  hasCmsAccess = this.props?.user?.hasCmsAccess;

  hasPermission = (route) => {
    switch (route.path) {
      case "/enr-applications":
        return this.permissions?.ENR;
      case "/trp-applications":
        return this.permissions?.TRP;
      case "/asr-applications":
        return this.permissions?.ASR;
      case "/update-details-applications":
        return this.permissions?.UPD;
      case "/discount-applications":
        return this.permissions?.TFD;
      case "/cashier-app":
        return this.hasCashierAccess;
      case "/events":
        return this.hasCmsAccess;
      case "/news":
        return this.hasCmsAccess;
      case "/banners":
        return this.hasCmsAccess;
      case "/vacancies":
        return this.hasCmsAccess;
      case "/AnnouncementAlerts":
        return this.hasCmsAccess;
      default:
        return true;
    }
  };

  render() {
    let isEmployee = this.props.user?.role === UserRoles.employee;

    return (
      <div>
        {this.state.loading ? (
          <div style={{ height: "50vh" }}>
            <ClipLoader size={50} color="#1A60A6" loading />
          </div>
        ) : (
          <div>
            {
              <Sidebar
                {...this.props}
                routes={isEmployee ? EmployeeRoutes : AdminRoutes}
                bgColor={this.state.backgroundColor}
                activeColor={this.state.activeColor}
              />
            }
            <div
              className={
                "main-panel d-flex flex-column justify-content-between h-100"
              }
              ref={this.mainPanel}
            >
              <DemoNavbar {...this.props} />

              <Switch>
                {isEmployee
                  ? EmployeeRoutes.map((el, key) =>
                      el.subMenu && el.subMenu.length > 0 ? (
                        el.subMenu.map((subMenuProp, subMenuKey) =>
                          this.hasPermission(subMenuProp.path) ? (
                            <Route
                              path={subMenuProp.layout + subMenuProp.path}
                              component={subMenuProp.component}
                              key={subMenuKey}
                              exact
                            />
                          ) : null
                        )
                      ) : this.hasPermission(el.path) ? (
                        <Route
                          path={el.layout + el.path}
                          component={el.component}
                          key={key}
                          exact
                        />
                      ) : null
                    )
                  : AdminRoutes.map((prop, key) =>
                      prop.subMenu && prop.subMenu.length > 0 ? (
                        prop.subMenu.map((subMenuProp, subMenuKey) =>
                          subMenuProp.subMenu &&
                          subMenuProp.subMenu.length > 0 ? (
                            subMenuProp.subMenu.map(
                              (nestedSubMenuProp, nestedSubMenuKey) => (
                                <Route
                                  path={
                                    nestedSubMenuProp.layout +
                                    nestedSubMenuProp.path
                                  }
                                  component={nestedSubMenuProp.component}
                                  key={nestedSubMenuKey}
                                  exact
                                />
                              )
                            )
                          ) : (
                            <Route
                              path={subMenuProp.layout + subMenuProp.path}
                              component={subMenuProp.component}
                              key={subMenuKey}
                              exact
                            />
                          )
                        )
                      ) : (
                        <Route
                          path={prop.layout + prop.path}
                          component={prop.component}
                          key={key}
                          exact
                        />
                      )
                    )}
              </Switch>

              <Footer fluid />
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    stages: state.metaData.stages,
    user: state.login.userInfo,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getStages: () => dispatch(getStageConfigAction()),
  getStagesMetaData: () => dispatch(getStageMetaDataAction()),
  getSCHMetaData: () => dispatch(_getSCHApplicationMetaData()),
  getTCCMetaData: () => dispatch(_getTCCApplicationMetaData()),
  getFSMMetaData: () => dispatch(_getFSMApplicationMetaData()),
  getSCCMetaData: () => dispatch(_getSccCaseMetaData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
