import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Spin,
  Tooltip,
  Form,
  Select,
  Col,
  Row,
} from "antd";
import "./style.css";
import { addNewFaq } from "apis/faq-requests/faqsRequests";
import { deleteFaq } from "apis/faq-requests/faqsRequests";
import { updateFaq } from "apis/faq-requests/faqsRequests";
import { getFAQS } from "apis/faq-requests/faqsRequests";
import CustomModal from "components/CustomModal/CustomModal";
import BusForm from "components/Forms/buses-crud/BusForm";
import FaqsForm from "components/Forms/faqs-form/FaqsForm";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { _getAllFAQsCategories } from "actions/faqs-category/faqsCatepgryAction";
import { getFAQSCategories } from "apis/faq-requests/faqsRequests";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const Faqs = () => {
  const dispatch = useDispatch();
  const _requestTypes = useSelector((state) => state.metaData.requestTypes);
  const [loading, setLoading] = useState(true);
  const [faqsData, setFaqsData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [appType, setAppType] = useState("");

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [faqId, setFaqId] = useState("");

  const [AddFaqsForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            AddFaqsForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"FAQs"}
          headerSubTitle={"Manage Faqs"}
          headerBtnTitle={"Add New"}
        />
        <Row gutter={10}>
          <Col md={12}>
            <Select
              style={{ width: 170, marginRight: 50 }}
              value={appType}
              onChange={(e) => {
                setAppType(e);
              }}
            >
              <Option key={"#"} value={""}>
                All
              </Option>
              {_requestTypes?.map((el, i) => {
                return (
                  <Option key={i} value={`${el.identifier}`}>
                    {el.identifier}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </>
    );
  };
  useEffect(() => {
    getAllFaqs();
  }, [page, appType]);

  useEffect(() => {
    getAllFaqsCategories();
  }, []);

  const getAllFaqsCategories = async () => {
    try {
      setLoading(true);
      const response = await getFAQSCategories({ limit: "1000", page: 1 });
      if (response.status === 200) {
        dispatch(_getAllFAQsCategories(response.data.data));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const getAllFaqs = async () => {
    try {
      setLoading(true);
      const response = await getFAQS({
        type: appType === "All" ? "" : appType,
        page: page,
      });
      if (response.status === 200) {
        setFaqsData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addFAQ = async (data) => {
    try {
      setLoading(true);
      const response = await addNewFaq({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Faq added successfully" });
        await getAllFaqs();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const updateFAQ = async (data) => {
    try {
      setLoading(true);
      const response = await updateFaq({ id: faqId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Faq updated successfully" });
        await getAllFaqs();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const deleteFAQ = async (id) => {
    try {
      setLoading(true);
      const response = await deleteFaq({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Faq deleted successfully" });
        await getAllFaqs();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const newValues = {
      ...values,
      categoryId: Number(values.categoryId),
    };
    if (modalTitle === ModalType.EDIT) {
      await updateFAQ(newValues);
    } else {
      await addFAQ(newValues);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    AddFaqsForm.setFieldsValue({
      ...record,
    });
    setFaqId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={faqsData}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "#",
              key: "id",
              dataIndex: "id",

              //   render: (record) => record.id,
            },
            {
              title: "Question",
              dataIndex: "question",
              key: "question",
              width: "45%",

              render: (record) => (
                <p className="answer_para">
                  {record.replace(/(<([^>]+)>)/gi, "")}
                </p>
              ),
            },

            {
              title: "Answer",
              dataIndex: "answer",
              key: "answer",
              width: "45%",
              render: (record) => (
                <p className="answer_para">
                  {record.replace(/(<([^>]+)>)/gi, "")}
                </p>
              ),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Faqs Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this faq?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => deleteFAQ(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Faqs`}
        >
          <FaqsForm
            onSubmit={onSubmit}
            formName={AddFaqsForm}
            loading={loading}
            valuesForEdit={null}
            _requestTypes={_requestTypes}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default Faqs;
