import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  message,
  Select,
  Checkbox,
} from "antd";
import React, { useState } from "react";
import "./style.css";
import { bookingStagesActionRequest } from "views/FSM/bookings/api";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import CustomModal from "components/CustomModal/CustomModal";
import TextArea from "antd/lib/input/TextArea";
import { processBooingRequest } from "views/FSM/bookings/api";
import { bookingRefundRequest } from "views/FSM/bookings/api";
import { Label } from "reactstrap";
import PartialyRefund from "./PartialyRefund";
import PartialRefundForLTB from "./PartialRefundForLTB";
import { onUpdatePayment } from "views/FSM/bookings/api";
import { onUpdatePosting } from "views/FSM/bookings/api";
const { Option } = Select;

const FSMBookingHeader = ({ booking, onStageAction, refetch, props }) => {
  const userInfo = booking?.user;
  const facility = booking?.facility;
  const paymentInfo = booking?.payments.find(
    (payment) => payment.type === "transaction"
  );

  const ifPaymentRefunded = booking?.payments.find(
    (payment) => payment.type === "refund"
  );
  const [cancelBookingForm] = Form.useForm();
  const [cancelApprovalForm] = Form.useForm();
  const [refundBookingForm] = Form.useForm();
  const [rejectRefundBookingForm] = Form.useForm();
  const [declineBookingForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isCancelApproval, setisCancelApproval] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [openRjectRefundModal, setOpenRjectRefundModal] = useState(false);
  const [loading, sesetLoading] = useState(false);
  const [refund, setRefund] = useState(false);
  const [isPartialRefund, setIsPartialRefund] = useState(false);

  const handleProcessBooking = async (action, notes) => {
    try {
      sesetLoading(true);
      const payload = {
        bookingId: booking?.id,
        action: action,
        notes: notes,
        refund: refund,
      };

      const response = await processBooingRequest(payload);
      if (response.status === 200) {
        if (action == "pay") {
          successMessage({ message: "Payment has been done" });
        } else if ("cancel_approval") {
          successMessage({ message: "Booking has been updated successfully" });
        } else {
          successMessage({ message: "Booking has been cancelled" });
        }
        refetch();
      } else if (response.status === 204) {
        successMessage({ message: "Booking has been cancelled" });
        props.history.goBack();
      }
      sesetLoading(false);
      setOpen(false);
      setisCancelApproval(false);
    } catch (error) {
      sesetLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleUpdatePayment = async () => {
    try {
      const response = await onUpdatePayment({ id: booking?.id });
      if (response.status === 200) {
        successMessage({ message: "Payment has been updated successfully" });
        refetch();
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdatePosting = async () => {
    try {
      const response = await onUpdatePosting({ id: booking?.id });
      if (response.status === 200) {
        successMessage({ message: "Posting has been updated successfully" });
        refetch();
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const renderActionButtons = (stageStatus, bookingStatus, slug) => {
    if (booking?.hasPermission) {
      return (
        <div>
          {/* cancel button */}

          {["AS001", "AS002", "AS003", "AS004"].includes(bookingStatus) &&
            slug !== "cancellation_and_refunds" && (
              <Popconfirm
                disabled={bookingStatus === "AS007"}
                placement="topLeft"
                title="Are you sure to cancel this booking?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => setOpen(true)}
              >
                <Button danger type="primary" className="ant__primary__button">
                  Cancel
                </Button>
              </Popconfirm>
            )}
          {["AS007"].includes(bookingStatus) && (
            <Button type="default" className="ant__primary__button" disabled>
              Cancelled
            </Button>
          )}
          {/* Approve button */}

          {["FSG140", "FSG1240", "FSG2340", "FSG3440", "FSG4540"].includes(
            stageStatus
          ) ? (
            <Button
              type="primary"
              className="ant__primary__button"
              onClick={() => onStageAction(true, "")}
            >
              Approve
            </Button>
          ) : null}
          {/* Dcline button */}
          {[
            "FSG4750",
            "FSG5000",
            "FSG350",
            "FSG1450",
            "FSG1700",
            "FSG2550",
            "FSG2800",
            "FSG3650",
            "FSG3900",
            "FSG350", //EVB pre-final
            "FSG600", //EVB final
            // "FSG3050", //PHB
            // "FSG4150", //DPB
            // "FSG5250", //FFB
          ].includes(stageStatus) ? (
            <Button
              danger
              className="ant__primary__button"
              onClick={() => setOpenDeclineModal(true)}
            >
              Decline
            </Button>
          ) : null}

          {/* Approve button */}

          {[
            "FSG4750",
            "FSG5000",
            "FSG350",
            "FSG1450",
            "FSG1700",
            "FSG2550",
            "FSG2800",
            "FSG3650",
            "FSG3900",
            "FSG350", //EVB pre-final
            "FSG600", //EVB final
          ].includes(stageStatus) ? (
            <Button
              type="primary"
              className="ant__primary__button"
              onClick={() => onStageAction(true, "")}
            >
              Approve
            </Button>
          ) : null}

          {/* Payment button */}
          {/* 
          {["FSG850", "FSG1950", "FSG3050", "FSG4150", "FSG5250"].includes(
            stageStatus
          ) ? (
            <Popconfirm
              disabled={bookingStatus === "AS007"}
              placement="topLeft"
              title="Are you sure to want to pay this booking?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleProcessBooking("pay", "")}
            >
              <Button type="primary" className="ant__primary__button">
                Mark Payment Completed
              </Button>
            </Popconfirm>
          ) : null} */}

          {["FSG850", "FSG1950", "FSG3050", "FSG4150", "FSG5250"].includes(
            stageStatus
          ) ? (
            <Popconfirm
              disabled={bookingStatus === "AS007"}
              placement="topLeft"
              title="Are you sure to want to update payment?"
              okText="Yes"
              cancelText="No"
              onConfirm={handleUpdatePayment}
            >
              <Button type="primary" className="ant__primary__button">
                Update Payment
              </Button>
            </Popconfirm>
          ) : null}
          {["FSG900", "FSG2000", "FSG3100", "FSG4200", "FSG5300"].includes(
            stageStatus
          ) && !booking?.isPosted ? (
            <Popconfirm
              disabled={bookingStatus === "AS007"}
              placement="topLeft"
              title="Are you sure to want to update posting?"
              okText="Yes"
              cancelText="No"
              onConfirm={handleUpdatePosting}
            >
              <Button type="primary" className="ant__primary__button">
                Update Posting
              </Button>
            </Popconfirm>
          ) : null}
          {/* refund button */}

          {["FSG1050", "FSG2150", "FSG3250", "FSG4350", "FSG5450"].includes(
            stageStatus
          ) ? (
            <Popconfirm
              //disabled={bookingStatus === "AS007"}
              placement="topLeft"
              title="Are you sure to want to reject refund request?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => setOpenRjectRefundModal(true)}
            >
              <Button danger type="ghost" className="ant__primary__button">
                Reject Refund
              </Button>
            </Popconfirm>
          ) : null}
          {["FSG1050", "FSG2150", "FSG3250", "FSG4350", "FSG5450"].includes(
            stageStatus
          ) ? (
            <Popconfirm
              //disabled={bookingStatus === "AS007"}
              placement="topLeft"
              title="Are you sure to want to initiate partial refund?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => setIsPartialRefund(true)}
            >
              <Button type="primary" className="ant__primary__button">
                Partial Refund
              </Button>
            </Popconfirm>
          ) : null}
          {["FSG1050", "FSG2150", "FSG3250", "FSG4350", "FSG5450"].includes(
            stageStatus
          ) ? (
            <Popconfirm
              //disabled={bookingStatus === "AS007"}
              placement="topLeft"
              title="Are you sure to want to process full refund?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handelRefundMethod(true)}
            >
              <Button type="primary" className="ant__primary__button">
                Full Refund
              </Button>
            </Popconfirm>
          ) : null}
          {["FSG1020", "FSG2120", "FSG3220", "FSG4320", "FSG5420"].includes(
            stageStatus
          ) ? (
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to approve this cancellation ?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => setisCancelApproval(true)}
            >
              <Button type="primary" className="ant__primary__button">
                Approve Cancellation
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const handelRefundOrRejectPayment = async (payload, action) => {
    try {
      sesetLoading(true);
      const response = await bookingRefundRequest(payload);
      if (response.status === 200) {
        if (action === true) {
          successMessage({ message: "Amount has been refund successfully" });
        } else {
          successMessage({
            message: "Refund amount for this booking has been rejected",
          });
        }
        sesetLoading(false);
      }
      refetch();
      sesetLoading(false);
      setOpenRefundModal(false);
    } catch (error) {
      sesetLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const onFinish = async (values) => {
    await handleProcessBooking("cancel", values?.notes);
  };
  const onFinishCancelApproval = async (values) => {
    await handleProcessBooking("cancel_approval", values?.notes);
  };

  const onRefund = async (values) => {
    const payload = {
      bookingId: booking?.id,
      isApproved: true,
      refundAmount: values?.refundAmount ? Number(values?.refundAmount) : "",
      notes: values?.notes ? values?.notes : "",
    };
    await handelRefundOrRejectPayment(payload, true);
  };
  const onReject = async (values) => {
    const payload = {
      bookingId: booking?.id,
      isApproved: false,
      notes: values?.notes,
    };
    await handelRefundOrRejectPayment(payload, false);
    setOpenRjectRefundModal(false);
  };
  const onDecline = async (values) => {
    onStageAction(false, values?.notes);
    setOpenDeclineModal(false);
  };

  const handelRefundMethod = (e) => {
    setOpenRefundModal(true);
    const refundedAmount =
      paymentInfo?.grandTotal + paymentInfo?.usedWalletAmount;
    const amount = e === true ? refundedAmount : undefined;

    // Set the field value for refundAmount
    refundBookingForm.setFieldsValue({
      refundAmount: amount,
    });
  };

  const BookingStatusCode = booking?.currentStage?.status?.code;

  return (
    <div className="fsm__booking_header__containr">
      <Card className="antCardBorder antCard">
        <Row gutter={10}>
          <Col md={12}>
            <h5>{booking?.title}</h5>
            <div className="stageInformation">
              <p>
                Current Stage: <span>{booking?.currentStage?.title}</span>
              </p>
              <div className="__dot" />
              <p>
                Stage Status:{" "}
                <span>{booking?.currentStage?.status?.internalStatus}</span>
              </p>
            </div>
            {booking?.autoApproval && (
              <div className="booking_auto_aproval_tag">
                <span>Auto Approved</span>
              </div>
            )}
          </Col>
          <Col md={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {renderActionButtons(
                booking?.currentStage?.status?.code,
                booking?.bookingStatus?.code,
                booking?.currentStage?.slug
              )}
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <Row gutter={10}>
        <Col md={12}>
          <Card className="antCardBorder antCard">
            <h5>Booking Overview</h5>
            <div className="booking_user_details">
              <p>
                Full Name:{" "}
                <span>{userInfo?.firstName + " " + userInfo?.lastName}</span>
              </p>
              <p>
                Phone Number:{" "}
                <span>{userInfo?.phoneNo ? userInfo?.phoneNo : "N/A"}</span>
              </p>
              <p>
                Email: <span>{userInfo?.email ? userInfo?.email : "N/A"}</span>
              </p>
              <p>
                Gender:{" "}
                <span>{userInfo?.gender ? userInfo?.gender : "N/A"}</span>
              </p>
              <p>
                Campus: <span>{facility?.campus?.name}</span>
              </p>
              <p>
                Facility: <span>{facility?.title}</span>
              </p>
              <p>
                Booking Status:{" "}
                <span>{booking?.bookingStatus?.internalStatus}</span>
              </p>
            </div>
          </Card>
        </Col>
        <Col md={12}>
          <Card className="antCardBorder antCard">
            <h5>Payment Details</h5>
            <div className="booking_type_info">
              <React.Fragment>
                {/* <p>
                  Payment Type: <span>{paymentInfo.type}</span>
                </p> */}
                <p>
                  Payment Status: <span>{paymentInfo.status}</span>
                </p>
                <p>
                  Sub Total: <span>AED {paymentInfo.netTotal.toFixed(2)}</span>
                </p>
                <p>
                  Discount Amount:{" "}
                  <span>AED {paymentInfo.discountAmount.toFixed(2)}</span>
                </p>
                <p>
                  Vat: <span>AED {paymentInfo.vatAmount.toFixed(2)}</span>
                </p>
                {paymentInfo?.usedWalletAmount > 0 && (
                  <p>
                    Wallet Amount:{" "}
                    <span>AED {paymentInfo?.usedWalletAmount.toFixed(2)}</span>
                  </p>
                )}
                <p>
                  Total:{" "}
                  <span>
                    AED{" "}
                    {(
                      paymentInfo.grandTotal +
                      (paymentInfo?.usedWalletAmount || 0)
                    ).toFixed(2)}
                  </span>
                </p>
                <br />
                {ifPaymentRefunded && (
                  <>
                    <h5>Refund</h5>
                    <p>
                      Refunded Amount:{" "}
                      <span>AED {ifPaymentRefunded.grandTotal}</span>
                    </p>
                  </>
                )}
              </React.Fragment>
            </div>
          </Card>
        </Col>
      </Row>
      <CustomModal
        visibility={open}
        handleCancel={() => setOpen(false)}
        handleOk={() => null}
        title={`Add Notes For Cancel Booking`}
        modalSmall={true}
      >
        <Form name={cancelBookingForm} onFinish={onFinish}>
          {["FSG900", "FSG2000", "FSG3100", "FSG4200", "FSG5300"].includes(
            BookingStatusCode
          ) && (
            <Row>
              <Col md={24}>
                <Form.Item>
                  <Checkbox
                    value={refund}
                    onChange={(e) => setRefund(!e.target.value)}
                  >
                    Initialized Refund
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={24}>
              <Form.Item name="notes" rules={setRules("Notes")}>
                <TextArea placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "adding..." : "Add Notes"}
            </Button>
          </Row>
        </Form>
      </CustomModal>

      <CustomModal
        visibility={isCancelApproval}
        handleCancel={() => setisCancelApproval(false)}
        handleOk={() => null}
        title={`Add Notes For Approve Cancellation`}
        modalSmall={true}
      >
        <Form name={cancelApprovalForm} onFinish={onFinishCancelApproval}>
          {paymentInfo?.type === "transaction" &&
            paymentInfo?.status === "completed" && (
              <Row>
                <Col md={24}>
                  <Form.Item>
                    <Checkbox
                      value={refund}
                      onChange={(e) => setRefund(!e.target.value)}
                    >
                      Initialized Refund
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            )}
          <Row>
            <Col md={24}>
              <Form.Item name="notes" rules={setRules("Notes")}>
                <TextArea placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "adding..." : "Add Notes"}
            </Button>
          </Row>
        </Form>
      </CustomModal>

      <CustomModal
        visibility={openRefundModal}
        handleCancel={() => setOpenRefundModal(false)}
        handleOk={() => null}
        title={`Approve Refund`}
        modalSmall={true}
      >
        <Form
          name={refundBookingForm}
          onFinish={onRefund}
          form={refundBookingForm}
        >
          <Row>
            <Col md={24}>
              <span>Refund Amount</span>
              <Form.Item name="refundAmount" rules={setRules("Refund Amount")}>
                <Input placeholder="Amount" type="number" />
              </Form.Item>
              <small>
                Refundable Amount:{" "}
                <b>{`AED ${
                  paymentInfo?.grandTotal + paymentInfo?.usedWalletAmount
                }`}</b>
              </small>
            </Col>
          </Row>

          <Row>
            <Col md={24}>
              <Label>Notes</Label>
              <Form.Item name="notes">
                <TextArea placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </CustomModal>
      <CustomModal
        visibility={openRjectRefundModal}
        handleCancel={() => setOpenRjectRefundModal(false)}
        handleOk={() => null}
        title={`Reject Refund`}
        modalSmall={true}
      >
        <Form
          name={rejectRefundBookingForm}
          onFinish={onReject}
          form={rejectRefundBookingForm}
        >
          <Row>
            <Col md={24}>
              <Label>Notes</Label>
              <Form.Item name="notes" rules={setRules("Notes")}>
                <TextArea placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </CustomModal>

      <CustomModal
        visibility={openDeclineModal}
        handleCancel={() => setOpenDeclineModal(false)}
        handleOk={() => null}
        title={`Decline Booking`}
        modalSmall={true}
      >
        <Form
          name={declineBookingForm}
          onFinish={onDecline}
          form={declineBookingForm}
        >
          <Row>
            <Col md={24}>
              <Label>Notes</Label>
              <Form.Item name="notes" rules={setRules("Notes")}>
                <TextArea placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </CustomModal>

      <CustomModal
        visibility={isPartialRefund}
        handleCancel={() => setIsPartialRefund(false)}
        handleOk={() => null}
        title={`Partial Refund Request`}
      >
        {booking?.bookingType?.identifier === "LTB" ? (
          <PartialRefundForLTB
            booking={booking}
            refetch={refetch}
            closeModal={() => setIsPartialRefund(false)}
          />
        ) : (
          <PartialyRefund
            booking={booking}
            refetch={refetch}
            closeModal={() => setIsPartialRefund(false)}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default FSMBookingHeader;
