import { Card } from "antd";
import React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";

function StageApprovalRemarks({ remarks, isTranscript }) {
  if (remarks.approvals?.length) {
    return (
      <Card className="antCardBorder" style={{ marginTop: "10px" }}>
        <h4>Final Remarks</h4>
        {isTranscript ? (
          <h6>
            {remarks?.approvals[0]?.isApproved
              ? "Recommended"
              : "Not Recommended"}
          </h6>
        ) : (
          <h6>{remarks?.approvals[0]?.isApproved ? "Approved" : "Rejected"}</h6>
        )}

        <hr />
        <p style={{ overflowX: "clip" }}>{remarks?.approvals[0]?.notes}</p>
        <div
          style={{
            backgroundColor: "#f0f0f0",
            padding: "2px 12px ",
            width: "100%",
            maxWidth: "350px",
            borderRadius: "5px",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CalendarOutlined style={{ marginRight: "10px" }} />
            <p style={{ margin: "7px 0" }}>
              {formateDateWithMoment(
                remarks?.approvals[0].createdAt,
                "DD MMM YYYY hh:mm A"
              )}
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserOutlined style={{ marginRight: "10px" }} />
            <p style={{ margin: "7px 0" }}>
              {remarks?.approvals[0].user?.name +
                " - " +
                remarks?.approvals[0].user?.userName}
            </p>
          </div>
        </div>
      </Card>
    );
  } else if (
    remarks.stageStatus?.code === "SG053" ||
    remarks.stageStatus?.code === "SG046"
  ) {
    return (
      <Card className="antCardBorder" style={{ marginTop: "10px" }}>
        <h6>Application sent for retest</h6>
      </Card>
    );
  } else return null;
}

export default StageApprovalRemarks;
