import client from "apis/config";


export const getAllQrAllowedUsersList = async () => {
  let endPoint = "users?documentValidator=true";
  return await client.get(endPoint);
};


export const getQrTypeList = async () => {
  return await client.get("validation-type?page=1&limit=10");
};

export const getQrTypeById = async (id) => {
  return await client.get(`validation-type/${id}`);
};

export const addNewQrType = async (data) => {
  return await client.post("validation-type", data);
};


export const updateQrType = async (id, data) => {
  return await client.put(`validation-type/${id}`, data);
};

export const deleteQrType = async (id) => {
  return await client.delete(`validation-type/${id}`);
};






export const getValidationDocumentConfigurations = async (page=1, limit=10) => {
  return await client.get(`validation-document-configurations?page=${page}&limit=${limit}`);
};

export const getValidationDocumentConfigurationById = async (id) => {
  return await client.get(`validation-document-configurations/${id}`);
};

export const createValidationDocumentConfiguration = async (data) => {
  return await client.post("validation-document-configurations", data);
};

export const updateValidationDocumentConfiguration = async (id, data) => {
  return await client.put(`validation-document-configurations/${id}`, data);
};

export const deleteValidationDocumentConfiguration = async (id) => {
  return await client.delete(`validation-document-configurations/${id}`);
};




export const createValidationDocument = async (data) => {
  return await client.post("validation-documents", data);
};

export const verifyPin = async (data) => {
  return await client.post("validation-documents/validate", data);
};

