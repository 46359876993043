import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  InputNumber,
} from "antd";

import React, { useEffect, useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Option } = Select;

const BusForm = ({
  onSubmit,
  loading,
  formName,
  _campuses,
  _terms,
  valuesForEdit,
  setTerms,
}) => {
  const [campusId, setCampusId] = useState("");
  const [selectedTerms, setSelectedTerms] = useState([]);
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };
  useEffect(() => {
    if (valuesForEdit == null) {
      setSelectedTerms([]); // Set selectedTerms to an empty array when valuesForEdit is null
      setTerms([]); // Set terms to an empty array when valuesForEdit is null
    } else {
      const _prevTerms = _terms?.filter((term) => {
        return valuesForEdit?.terms.includes(term.name);
      });
      setSelectedTerms(_prevTerms || []); // Set selectedTerms to _prevTerms if it's not null or undefined, otherwise, set it to an empty array
      setTerms(_prevTerms || []); // Set terms in the same way
    }
  }, [valuesForEdit]);

  const handleSelectAllTerms = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedTerms(_terms);
      setTerms(_terms);
    } else {
      return value;
    }
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Bus No"}
              name={"busNo"}
              rules={setRules("Subject")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              rules={setRules("Title AR")}
              label={"Bus Plate No"}
              name={"busPlateNo"}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* driver info */}
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Driver 1 Name"}
              name={"driverName"}
              rules={setRules("Driver 1 Name")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              rules={setRules("Driver 1 Mobile No")}
              label={"Driver 1 Mobile No"}
              name={"driverMobileNo"}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* driver info */}
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Driver 2 Name"}
              name={"driver2Name"}
              // rules={setRules("Driver 2 Name")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              // rules={setRules("Driver 2 Mobile No")}
              label={"Driver 2 Mobile No"}
              name={"driver2MobileNo"}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* nany 1 info */}
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Nanny 1 Name"}
              name={"nannyName"}
              rules={setRules("Nanny 2 Mobile No")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              rules={setRules("Nanny 1 Mobile No")}
              label={"Nanny 1 Mobile No"}
              name={"nannyMobileNo"}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* nany 2 info */}
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Nanny 2 Name"}
              name={"nanny2Name"}
              // rules={setRules("Nanny 2 Mobile No")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              // rules={setRules("Nanny 2 Mobile No")}
              label={"Nanny 2 Mobile No"}
              name={"nanny2MobileNo"}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* route and capicity */}
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Routes"}
              name={"route"}
              rules={setRules("Route")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              rules={setRules("Seats Capacity")}
              label={"Seats Capacity"}
              name={"busCapacity"}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Campus"}
              name={"campusId"}
              rules={setRules("Campus")}
            >
              <Select
                value={_campuses?.find((el) => el.code)}
                onChange={(e) => {
                  setCampusId(e);
                }}
              >
                {_campuses?.map((campus) => (
                  <Option value={campus.id}>{campus.code}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label={"Terms"}>
              <Select
                placeholder="Select campus"
                mode="multiple"
                value={
                  selectedTerms.length > 0
                    ? selectedTerms.map((item) => item.name)?.join(", ")
                    : []
                }
                onChange={handleSelectAllTerms}
              >
                {_terms.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedTerms.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedTerms(
                          e.target.checked
                            ? [...selectedTerms, el]
                            : selectedTerms.filter((i) => i.id !== el.id)
                        );
                        setTerms(
                          e.target.checked
                            ? [...selectedTerms, el]
                            : selectedTerms.filter((i) => i.id !== el.id)
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      {el.name}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          initialValue={true}
          label={"Status"}
          name={"isActive"}
          valuePropName={"checked"}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
        <Form.Item
          // rules={setRules("Parent Note (EN)")}
          label={"Parent Note (EN)"}
          name={"parentNote"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        >
          <CKEditor
            config={
              {
                //toolbar: EditorToolbar,
              }
            }
            editor={ClassicEditor}
            key={"parentNote"}
          />
        </Form.Item>
        <Form.Item
          // rules={setRules("Parent Note (AR)")}
          label={"Parent Note (AR)"}
          name={"parentNoteAr"}
          valuePropName={"data"}
          getValueFromEvent={(event, editor) => editor?.getData() ?? null}
        >
          <CKEditor
            config={{
              //toolbar: EditorToolbar,
              language: "ar",
            }}
            editor={ClassicEditor}
            key={"parentNoteAr"}
          />
        </Form.Item>

        <br />

        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default BusForm;
