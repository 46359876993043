const NameMap = {
  "/admin": "",

  "/admin/dashboard": "Dashboard",

  "/admin/enr-applications": "Admission Applications",
  "/admin/enr-applications/registration_details": "Registration",
  "/admin/enr-applications/interview_details": "Interview",
  "/admin/enr-applications/observation_details": "Observation",
  "/admin/enr-applications/transcript_review": "Transcript Review",
  "/admin/enr-applications/pre_final_approval": "Pre Final Approval",
  "/admin/enr-applications/final_approval": "Final Review Approval",
  "/admin/enr-applications/registration_fee": "Registration Fee",
  "/admin/enr-applications/logs": "Application Logs",

  "/admin/trp-applications": "Transportation Applications",
  "/admin/trp-applications/review": "Review",
  "/admin/trp-applications/prefinal-approval": "Pre Final Approval",
  "/admin/trp-applications/final-approval": "Final Review Approval",
  "/admin/trp-applications/payment": "Payment",
  "/admin/trp-applications/logs": "Application Logs",

  "/admin/asr-applications": "Additional Services Applications",
  "/admin/asr-applications/review": "Review",
  "/admin/asr-applications/prefinal-approval": "Pre Final Approval",
  "/admin/asr-applications/final-approval": "Final Review Approval",
  "/admin/asr-applications/payment": "Payment",
  "/admin/asr-applications/logs": "Application Logs",

  "/admin/discount-applications": "Discount Applications",

  "/admin/update-details-applications": "Update Personal Details Applications",
  "/admin/sch-applications": "Scholarship Details Applications",
  "/admin/tcc-applications": "TCC Details Applications",

  "/admin/request-types": "Request Types",
  "/admin/request-types/stage-list": "Stages",
  "/admin/request-types/stage-configuration": "Stage Configuration",
  "/admin/request-types/documents-configuration": "Documents Configuration",
  "/admin/request-types/terms-and-conditions": "Terms And Conditions",
  "/admin/request-types/NotificationsTriggers": "Notification Triggers",
  "/admin/request-types/fsm-cashier-configuration":
    "Cashier configuration for bookings",
  "/admin/request-types/StageStatuses": "Stage Statuses",
  "/admin/request-types/guidelines": "Guidelines",
  "/admin/request-types/discount-rules": "Discount Rules",
  "/admin/request-types/discount-rules/configuration": "Configuration",
  "/admin/request-types/discount-distribution": "Discount Distribution",

  "/admin/users": "Users",
  "/admin/documents-list": "Documents",
  "/admin/cashier-configuration": "Cashier Configuration",
  "/admin/RequestStatuses": "Request Statuses",
  "/admin/dropdown-content": "Dropdowns Configuration",
  "/admin/dropdown-groups": "Dropdown Groups",
  "/admin/e-services": "E Services",
  "/admin/e-services/configuration": "Configuration",
  "/admin/cashier-app": "Cashier App",
  "/admin/notifications": "Notifications",
  "/admin/general-configuration": "General Configuration",
  "/admin/service-items": "Service Items",
  "/admin/buses-cms": "Buses",

  "/admin/events": "Events Content Management",
  "/admin/news": "News Content Management",
  "/admin/vacancies": "Vacancies Content Management",
  "/admin/banners": "Banners Content Management",

  // Facility management service breadCrumbs
  "/admin/fsm-dashboard": "Dashboard",
  "/admin/fsm-calender": "Calendar",
  "/admin/fsm-day-pass": "Day Pass Bookings",
  "/admin/fsm-per-head": "Per Head Bookings",
  "/admin/fsm-full-facility": "Full Facility Bookings",
  "/admin/fsm-events-booking": "Event Bookings",
  "/admin/fsm-long-term": "Long Term Bookings",
  "/admin/fsm-booking-types": "Booking  Types",
  "/admin/fsm-request-statuses": "Booking  Statuses",
  "/admin/fsm-all-facilities": "All Facilities",
  "/admin/fsm-facilities-features": "Facilities Features",
  "/admin/fsm-facilities-schedule": "Facilities Schedules",
  "/admin/fsm-activity-types": "Activity Types",
  "/admin/fsm-categories": "Categories",
  "/admin/fsm-sub-categories": "Sub Categories",
  "/admin/fsm-payment": "Payments",
  "/admin/fsm-refunds": "Refunds",
  "/admin/fsm-all-discount": "All Discounts",
  "/admin/fsm-discount-types": "Disocunt Types",
  "/admin/fsm-vat": "Vat",
  "/admin/fsm-banners": "Banners",
  "/admin/add-facility": "Add Facility",
  "/admin/edit-facility": "Edit Facility",
  "/admin/add-per-head-booking": "Per Head Booking",
  "/admin/add-day-pass-booking": "Day Pass Booking",
  "/admin/add-full-facility-booking": "Full Facility Booking",
  "/admin/add-event-booking": "Event Booking",
  "/admin/add-long-term-booking": "Long Term Booking",
  "/admin/per-head-booking/details": "Booking Details",
  "/admin/day-pass-booking/details": "Booking Details",
  "/admin/full-facility-booking/details": "Booking Details",
  "/admin/event-booking/details": "Booking Details",
  "/admin/long-term-booking/details": "Booking Details",
  // SCC
  "/admin/scc-report": "Reports",
  "/admin/scc-dashboard": "Dashboard",
};
export default NameMap;
