import {
  Col,
  Row,
  Form,
  Select,
  List,
  Button,
  Typography,
  Input,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

import { useSelector } from "react-redux";
import { requestErrorHandel } from "utils/helpers/helpers";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import { successMessage } from "utils/helpers/helpers";
import { showValidationError } from "utils/helpers/helpers";
import { exportBookingFields } from "utils/constants";
import fsmRoutes from "routes/FSM/fsmRoutes";
import { fetchAllFsmFacilities } from "views/FSM/facility-mangement/all-facilities/api";
import moment from "moment";
import { exportBookingToExcel } from "../api";

const { Option } = Select;

const ExportBookingToExcelModal = ({
  _bookingType,
  formName,
  exportBookingfilters,
}) => {
  const filterLayout = { md: 6 };

  const _campuses = useSelector((state) => state.metaData.campuses);
  const _bookingStatus = useSelector(
    (state) => state.metaData.applicationStatuses
  );

  const _bookingStages = useSelector(
    (state) => state.metaData.FSM_bookingTypes
  )?.find((bookingType) => bookingType.identifier === _bookingType)?.stages;

  const [stageStatuses, setStageStatuses] = useState([]);
  const [stageStatus, setStageStatus] = useState("");
  const [stageChanged, setStageChanged] = useState(false);
  const [campus, setCampus] = useState("");
  const [stage, setStage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [facility, setFacility] = useState("");
  const [defaultStageStatus, setDefaultStageStatus] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  const [facilities, setFacilities] = useState([]);

  const [csvData, setCsvData] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllFsmFacilities();
  }, []);

  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {}
  };

  const handleSelectCampus = (value) => {
    setCampus(value);
  };
  const handleSelectFacility = (value) => {
    setFacility(value);
  };

  const handleSelectStage = (value) => {
    setStage(value);
    handleStagesStatuses(value);
  };

  const handleStagesStatuses = (value) => {
    setStageChanged(true);
    setStageStatus("");
    if (value === "") {
      setStageStatuses([]);
      setStageChanged(false);
      return;
    }
    const _selectedStage = _bookingStages.find((stage) => stage.slug === value);
    setStageStatuses(_selectedStage?.statuses ?? []);
  };
  const handleSelectStageStatus = (value) => {
    setDefaultStageStatus("");
    setStageStatus(value);
  };

  const handleSelectApplicationStatus = (value) => {
    setApplicationStatus(value);
  };

  const handleItemClick = (item) => {
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.filter((prevItem) => prevItem !== item);
      return [...updatedItems, item];
    });
    setFields((prevFields) =>
      prevFields.filter((prevItem) => prevItem !== item)
    );
  };

  const handleRemoveItemClick = (item) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.filter((prevItem) => prevItem !== item);
      return [...updatedFields, item];
    });
    setSelectedItems((prevItems) =>
      prevItems.filter((prevItem) => prevItem !== item)
    );
  };

  useEffect(() => {
    checkServiceTypeAndSetTheFieldsInState();
  }, []);
  const checkServiceTypeAndSetTheFieldsInState = () => {
    setFields(exportBookingFields);
  };
  const submitForExportToExcel = async () => {
    try {
      if (selectedItems.length === 0) {
        showValidationError("Fields must contain at least 1 element");
        return;
      }
      const data = {
        type: _bookingType,
        year: "",
        campus: campus,
        facility: facility,
        status: applicationStatus,
        startAt: moment(startDate).isValid() ? startDate : "",
        endAt: moment(endDate).isValid() ? endDate : "",
        stage: stage,
        stageStatus: stageStatus,
        fields: selectedItems.map((item) => item.value),
      };
      setLoading(true);
      const response = await exportBookingToExcel({ payload: data });
      if (response.status === 200) {
        if (response.data?.data.length === 0) {
          successMessage({ message: "No booking found" });
          setLoading(false);
        } else {
          setCsvData(response.data?.data);
          successMessage({ message: "Booking exported successfully" });

          setLoading(false);
        }

        setCsvData(response.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const exportApplicationsModalTitle = () => {
    const _bookingTypeService = fsmRoutes[0].subMenu[2].subMenu.find(
      (item) => item.state.serviceType === _bookingType
    );
    return `${_bookingTypeService?.sideMenuTitle}.csv`;
  };

  useEffect(() => {
    if (exportBookingfilters !== undefined && exportBookingfilters !== null) {
      setStartDate(exportBookingfilters?.startDate);
      setEndDate(exportBookingfilters?.endDate);
      setFacility(exportBookingfilters?.facility);
      setFacility(exportBookingfilters?.facility);

      setStage(exportBookingfilters?.stage);
      setStageStatus(exportBookingfilters?.stageStatus);
      setCampus(exportBookingfilters?.campus);
      setApplicationStatus(exportBookingfilters?.bookingStatus);
      setStageStatuses(exportBookingfilters?.stageStatuses);
    }
  }, [exportBookingfilters]);

  return (
    <div>
      <Form form={formName}>
        <Row gutter={[10, 0]} align="middle">
          {
            <>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Campus"
                    onChange={handleSelectCampus}
                    bordered={false}
                    value={campus || undefined}
                  >
                    <Option value={""}>All</Option>
                    {_campuses?.map((el, i) => (
                      <Option key={i} value={el.id}>
                        {el.code}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <Select
                    placeholder="Filter By Facility"
                    onChange={handleSelectFacility}
                    bordered={false}
                    value={facility || undefined}
                  >
                    <Option value={""}>All</Option>
                    {facilities?.map((el, i) => (
                      <Option key={el.id} value={el.id}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Filter by start date"
                    onChange={(date) =>
                      setStartDate(moment(date).format("YYYY-MM-DD"))
                    }
                    value={startDate ? moment(startDate) : null}
                  />
                </Form.Item>
              </Col>
              <Col md={6} className="filterColumn">
                <Form.Item className="dropdown">
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Filter by end date"
                    onChange={(date) =>
                      setEndDate(moment(date).format("YYYY-MM-DD"))
                    }
                    value={endDate ? moment(endDate) : null}
                  />
                </Form.Item>
              </Col>
            </>
          }

          <Col {...filterLayout} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter By Stage"
                onChange={handleSelectStage}
                // defaultValue={stage ? stage : "All Stages"}
                bordered={false}
                value={stage || undefined}
              >
                <Option value={""}>All Stages</Option>
                {_bookingStages?.map((el, i) => (
                  <Option key={i} value={el.slug}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...filterLayout} className="filterColumn">
            {stageChanged ? (
              <Form.Item className="dropdown">
                <Select
                  onChange={handleSelectStageStatus}
                  placeholder="Filter By Stage Status"
                  value={stageStatus || undefined}
                  bordered={false}
                >
                  <Option value={""}>All</Option>
                  {stageStatuses?.map((el, i) => (
                    <Option key={i} value={el.code}>
                      {el.internalStatus}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item className="dropdown">
                <Select
                  onChange={handleSelectStageStatus}
                  placeholder="Filter By Stage Status"
                  bordered={false}
                  value={stageStatus || undefined}
                >
                  <Option value={""}>All</Option>
                  {stageStatuses?.map((el, i) => (
                    <Option key={i} value={el.code}>
                      {el.internalStatus}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col {...filterLayout} className="filterColumn">
            <Form.Item className="dropdown">
              <Select
                placeholder="Filter By Booking Status"
                onChange={handleSelectApplicationStatus}
                // defaultValue={"All Applications"}
                bordered={false}
                value={applicationStatus}
              >
                <Option value={""}>All Bookings</Option>
                {_bookingStatus?.map((el, i) => (
                  <Option key={i} value={el.code}>
                    {el.internalStatus}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={[16, 16]}>
        <Col md={12} sm={24}>
          <div style={{ backgroundColor: "#F7F7F7" }}>
            <div
              style={{
                backgroundColor: "#007B85",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#FFF", padding: 0, margin: 0 }}>
                Available Fields
              </p>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <List
                dataSource={fields}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="text"
                        onClick={() => handleItemClick(item)}
                        icon={<ArrowRightOutlined />}
                      />,
                    ]}
                  >
                    {item.title}
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Col>
        <Col md={12} sm={24}>
          <div style={{ backgroundColor: "#F7F7F7" }}>
            <div
              style={{
                backgroundColor: "#007B85",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#FFF", padding: 0, margin: 0 }}>
                Selected Fields
              </p>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <List
                dataSource={selectedItems}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="text"
                        onClick={() => handleRemoveItemClick(item)}
                        icon={<ArrowLeftOutlined />}
                      />,
                    ]}
                  >
                    {item.title}
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <Row justify={"end"} gutter={[20, 10]}>
        <Button type="primary" style={{ marginRight: 20 }}>
          {csvData.length > 0 && (
            <CSVLink
              data={csvData}
              filename={exportApplicationsModalTitle()}
              target="_blank"
            >
              Download
            </CSVLink>
          )}
        </Button>
        <Button type="primary" onClick={submitForExportToExcel}>
          {loading ? "Exporting..." : "Export to Excel"}
        </Button>
      </Row>
    </div>
  );
};
export default ExportBookingToExcelModal;
