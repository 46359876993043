/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React from "react";
import {
  Row,
  Card,
  Col,
  Button,
  DatePicker,
  Input,
  Menu,
  Select,
  Form,
} from "antd";
import moment from "moment";
import ScheduleInterviewModal from "./ScheduleInterviewModal";
import CustomModal from "components/CustomModal/CustomModal";
import ScheduleDateTimePicker from "components/resuable/schedule-detail/ScheduleDateTimePicker";
const { Option } = Select;

function ScheduleInterviewComponent(props) {
  const [typeOfInterview, setTypeOfInterview] = React.useState([
    {
      id: "1",
      title: "In Person",
      value: "in_person",
    },
    {
      id: "2",
      title: "Online",
      value: "online",
    },
  ]);
  const [selectedInterViewType, setSelectedInterViewType] =
    React.useState("Online");

  const handleChange = (event) => {
    setSelectedInterViewType(event);
  };

  return (
    <div className="both-side-padding">
      <Row gutter={[20, 20]}>
        <Col lg={12}>
          <Form.Item
            label="Type"
            name="interviewType"
            rules={[
              {
                required: true,
                message: "Type is required",
              },
            ]}
          >
            <Select
              // defaultValue={selectedInterViewType}
              style={{ width: "100%" }}
              onChange={handleChange}
              placeholder="Type"
            >
              {typeOfInterview.map((type) => {
                return <Option value={type.value}>{type.title}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12}>
          <ScheduleDateTimePicker
            label={"Select date and time"}
            name={"dateTime"}
            rules={[
              {
                required: true,
                message: "Date and time is required",
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col lg={24}>
          {selectedInterViewType == "in_person" ? (
            <div style={{ marginTop: "10px" }}>
              <Form.Item
                label="Instructions"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Instructions required",
                    whitespace: true,
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Instructions"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
          ) : null}
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col lg={24}>
          <div>
            <Form.Item
              label="Notes"
              name="notes"
              rules={[
                {
                  required: true,
                  message: "Notes are required",
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea placeholder="Notes" style={{ width: "100%" }} />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Button
          className="appButton"
          type="primary"
          htmlType="submit"
          loading={props.loading}
        >
          {props.loading ? "Sending...." : "Send to parents"}
        </Button>
      </Row>
    </div>
  );
}

export default ScheduleInterviewComponent;
