import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Card from "antd/lib/card/Card";
import EmployeeLogs from "components/EmployeeLogs/EmployeeLogs";
import React, { useEffect, useState } from "react";
import { getStageDuration } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { getBookingsHistoryLogs } from "views/FSM/bookings/api";
import { getSccConductCaseLogs } from "../scc-offense-management/api";

const CaseHistoryInfo = ({ item }) => {
  return (
    <div className="booking__history_content">
      <p className="booking_stage_internal_status">
        {item?.newStatus?.internalStatus}
      </p>

      <div className="rh-log-item fsm_logs">
        <div className="rh-log-row">
          <CalendarOutlined className="rh-log-icon" />
          <p>{formateDateWithMoment(item.createdAt, "DD MMM YYYY hh:mm A")}</p>
        </div>
        <div className="rh-log-row">
          <UserOutlined className="rh-log-icon" />
          <p>{item.user?.name + " - " + item.user?.userName}</p>
        </div>
        {Object.keys(item.meta).length > 0 && (
          <>
            <div className="rh-log-row">
              <p>Notes: {item.meta?.notes}</p>
            </div>
            {item.meta?.action && (
              <div className="rh-log-row">
                <p>Action: {item.meta?.action}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const SccConductCaseLogs = ({ caseId, autoApproved }) => {
  const [loading, setLoading] = useState(true);
  const [caseLogs, setCaseLogs] = useState([]);

  useEffect(() => {
    getCaseLogs();
  }, []);

  const getCaseLogs = async () => {
    try {
      setLoading(true);
      const response = await getSccConductCaseLogs({ caseId: Number(caseId) });
      if (response.status === 200) {
        setCaseLogs(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const showStageTimeStats = (log) => {
    return log && log.timeLogs && log.timeLogs?.length > 0 ? (
      <>
        <span className="logsStageStartEndTime">Started: </span>
        <span>
          {formateDateWithMoment(
            log.timeLogs[0].startedAt,
            "DD, MMM YYYY, hh:mm A"
          )}
        </span>
        <br></br>
        <span className="logsStageStartEndTime">Completed: </span>
        <span>
          {log.timeLogs[0].endedAt
            ? formateDateWithMoment(
                log.timeLogs[0].endedAt,
                "DD, MMM YYYY, hh:mm A"
              )
            : "In Progress"}
        </span>
        <br />
        <span className="logsStageStartEndTime">Duration: </span>
        <span>{getStageDuration(log.timeLogs)}</span>
        <div style={{ marginBottom: "30px" }}></div>
      </>
    ) : null;
  };

  return (
    <div>
      <div className="fsm__logs__container">
        {loading ? (
          <div className="fsm__logs__spnier">
            <Spin spinning={loading} indicator={renderLoader}></Spin>
          </div>
        ) : (
          <React.Fragment>
            {caseLogs?.map((caseLog, index) => {
              if (caseLog?.timeLogs.length === 0) {
                return;
              } else {
                return (
                  <React.Fragment key={index}>
                    <Card className="antCardBorder antCard">
                      <div className="fsm__logs__slug_title">
                        {<p>{caseLog?.stageTitle + " " + "Stage"}</p>}

                        {showStageTimeStats(caseLog)}
                        <br />
                      </div>
                      {caseLog.employees.length > 0 && (
                        <EmployeeLogs employees={caseLog.employees} />
                      )}
                      {caseLog?.history?.length > 0 &&
                        caseLog?.history.map((history) => {
                          return <CaseHistoryInfo item={history} />;
                        })}
                    </Card>
                    <br />
                  </React.Fragment>
                );
              }
            })}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default SccConductCaseLogs;
