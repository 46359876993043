import client from "apis/config";
const endPointUrl = "scc/standards";

export const fetchAllSccStandards = async ({
  limit = "10",
  page,
  categoryId = "",
}) => {
  return await client.get(
    `/${endPointUrl}?limit=${limit}&page=${page}&category=${categoryId}`
  );
};

export const addNewSccStandards = async ({ data }) => {
  return await client.post(`/${endPointUrl}`, data);
};

export const updateSccStandards = async ({ id, data }) => {
  return await client.put(`/${endPointUrl}/${id}`, data);
};

export const deleteSccStandards = async ({ id }) => {
  return await client.delete(`/${endPointUrl}/${id}`);
};
