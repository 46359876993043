/* eslint-disable eqeqeq */
import React, { useState, useRef } from "react";
import { Row, Form, Spin, Col } from "antd";
import CompactFormItemReadOnly from "components/CompactFormItemReadOnly/CompactFormItemReadOnly";
import { applicationFeedback } from "../../apis/applications-apis/applications";
import { requestErrorHandel } from "utils/helpers/helpers";
import { LoadingOutlined } from "@ant-design/icons";

import { _updateApplications } from "actions/applications/applications-actions";
import { useDispatch, useSelector } from "react-redux";

const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;

export default function ApplicationInformationReadOnlyForm({
  application,
  type,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [infoForm] = Form.useForm();
  const inputEl = useRef([]);
  const [imagePreview, setImagePreview] = useState(null);

  const _educationalSystems = useSelector(
    (state) => state.metaData.educationalSystems
  );

  const [currentSchoolEducationSystemId, setCurrentSchoolEducationSystemId] =
    useState(null);
  const handleFileInputChange = async (e, element) => {
    var file = e.target.files[0];
    const base64 = await convertBase64(file);
    const objectUrl = URL.createObjectURL(file);
    setImagePreview(objectUrl);
    element.value = base64;
    infoForm.setFieldsValue({ profilePhoto: element });
  };

  const filterStudentGrades = _educationalSystems.find(
    (ed) =>
      ed.id ==
      (currentSchoolEducationSystemId === null
        ? application?.criteria?.educationSystem?.value
        : currentSchoolEducationSystemId)
  );

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChangeNotes = (e, name, formElement) => {
    if (e.target.value.length > 0) {
      if (formElement.status !== "needAmendment") {
        formElement.status = "needAmendment";
        infoForm.setFieldsValue({ [name]: formElement });
      }
    }
  };

  const switchOnChangeForParent = (e, name, formElement, ref) => {
    if (e.target.checked) {
      formElement.status = "approved";
      formElement.oldNote = formElement.note;
      formElement.note = "";
      ref.current.input.value = "";
      ref.current.input.disabled = true;
      ref.current.input.className =
        ref.current.input.className + " ant-input-disabled";
      ref.current.input.parentElement.className =
        ref.current.input.parentElement.className +
        " ant-input-affix-wrapper-disabled";

      infoForm.setFieldsValue({ [name]: formElement });
    } else {
      formElement.status = "needAmendment";
      ref.current.input.value = formElement.oldNote ?? "";
      formElement.note = formElement.oldNote ?? "";
      ref.current.input.disabled = false;
      ref.current.input.parentElement.classList.remove(
        "ant-input-affix-wrapper-disabled"
      );
      ref.current.input.classList.remove("ant-input-disabled");
      infoForm.setFieldsValue({ [name]: formElement });
    }
    //infoForm.setFieldsValue({ name: targetFormItem });
  };
  const onFinishInfoForm = async (val) => {
    let contacts = [];
    if (val[0] && val[1]) {
      contacts = [val[0], val[1]];
      contacts[0].value = JSON.stringify(contacts[0].value);
      contacts[1].value = JSON.stringify(contacts[1].value);
      delete val[0];
      delete val[1];
    }

    const fieldsArr = Object.values(val);
    contacts.forEach((contact) => {
      fieldsArr.push(contact);
    });

    try {
      setLoading(true);
      const bodyForFeedback = {
        applicationId: Number(application.id),
        fields: fieldsArr,
      };
      const response = await applicationFeedback(bodyForFeedback);
      if (response.status == 200) {
        setLoading(false);
        dispatch(_updateApplications(application.id, response.data.data));
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const renderInfoForm = () => {
    let arrayResult = [];
    if (type === "father") {
      Object.keys(application?.father).forEach((item, i) => {
        arrayResult.push({
          name: item,
          index: i,
          formElement: application?.father[item],
        });
      });
      Object.keys(application?.residence).forEach((item, i) => {
        arrayResult.push({
          name: item,
          index: i,
          formElement: application?.residence[item],
        });
      });
      Object.keys(application?.contacts).forEach((item, i) => {
        arrayResult.push({
          name: `${item}`,
          index: i,
          formElement: application?.contacts[item],
        });
      });

      // infoForm.setFieldsValue(application?.father);
      // infoForm.setFieldsValue(application?.residence);
      // infoForm.setFieldsValue(application?.contacts);

      // infoForm.setFieldsValue({
      //   0: {
      //     value: {
      //       name: application?.contacts[0].value.name,
      //       relation: application?.contacts[0].value.relation,
      //       phoneNo: application?.contacts[0].value.phoneNo,
      //     },
      //   },
      // });

      // infoForm.setFieldsValue({
      //   1: {
      //     value: {
      //       name: application?.contacts[1].value.name,
      //       relation: application?.contacts[1].value.relation,
      //       phoneNo: application?.contacts[1].value.phoneNo,
      //     },
      //   },
      // });
      const combinedValues = {
        ...application?.father,
        ...application?.residence,
        ...application?.contacts,
      };

      infoForm.setFieldsValue(combinedValues);
    } else if (type === "mother") {
      arrayResult = Object.keys(application?.mother).map((item, i) => {
        return { name: item, index: i, formElement: application?.mother[item] };
      });
      infoForm.setFieldsValue(application?.mother);
    } else if (type === "child") {
      let allENRChildFields = {};
      if (application) {
        if (
          application.authorizedPersons &&
          Object.keys(application.authorizedPersons).length > 0
        ) {
          allENRChildFields = {
            ...application.childDetails,
            ...application.authorizedPersons,
          };
        } else {
          allENRChildFields = {
            ...application.childDetails,
          };
        }
      }
      arrayResult = Object.keys(allENRChildFields).map((item, i) => {
        return {
          name: item,
          index: i,
          formElement: allENRChildFields[item],
        };
      });

      infoForm.setFieldsValue(allENRChildFields);
      infoForm.setFieldsValue({
        otherLanguages: {
          value: JSON.parse(allENRChildFields.otherLanguages.value),
        },
      });
    } else if (type === "trp") {
      let allTRPFields = {};
      if (application) {
        if (
          application.authorizedPersons &&
          Object.keys(application.authorizedPersons).length > 0
        ) {
          allTRPFields = {
            ...application.address,
            ...application.authorizedPersons,
            ...application.mode,
          };
        } else {
          allTRPFields = {
            ...application.address,
            ...application.mode,
          };
        }
      }

      arrayResult = application
        ? Object.keys(allTRPFields).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: allTRPFields[item],
            };
          })
        : [];
      infoForm.setFieldsValue(allTRPFields);
    } else if (type === "asr") {
      arrayResult = application
        ? Object.keys(application.fields).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.fields[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application.fields);
    } else if (type === "asr-items") {
      arrayResult = application
        ? Object.keys(application.items).map((item, i) => {
            infoForm.setFieldsValue({
              [`asr-item-${item}`]: application.items[item],
            });
            return {
              name: `asr-item-${item}`,
              index: i,
              formElement: application.items[item],
            };
          })
        : [];
    } else if (type === "tfd") {
      arrayResult = application
        ? Object.keys(application.fields).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.fields[item],
            };
          })
        : [];
      const discountRule = {
        id: application.discountRule.id,
        note: application.discountRule.ruleNote,
        status: application.discountRule.ruleStatus,
        title: "Discount Rule",
        titleAr: "Discount Rule",
        value: application.discountRule.title,
        valueType: "text",
      };
      infoForm.setFieldsValue({ ...application.fields, discountRule });
      arrayResult.push({
        name: "discountRule",
        index: 1,
        formElement: discountRule,
      });
    } else if (type === "upd") {
      arrayResult = application
        ? Object.keys(application.fields).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.fields[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application.fields);
    } else if (type === "sch_father_info") {
      arrayResult = application
        ? Object.keys(application.father).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.father[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application.father);
    } else if (type === "sch_mother_info") {
      arrayResult = application
        ? Object.keys(application.mother).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.mother[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application.mother);
    } else if (type === "sch-detail") {
      arrayResult = application
        ? Object.keys(application.details).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.details[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application.details);
    } else if (type === "criteria") {
      arrayResult = application
        ? Object.keys(application.criteria).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.criteria[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application.criteria);
    } else if (type === "sch_students_info") {
      arrayResult = application
        ? Object.keys(application.studentDetails).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.studentDetails[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application.studentDetails);
    } else if (type === "tcc_father_info") {
      arrayResult = application
        ? Object.keys(application?.father).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application?.father[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application?.father);
    } else if (type === "tcc_mother_info") {
      arrayResult = application
        ? Object.keys(application?.mother).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application?.mother[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application?.mother);
    } else if (type === "tcc_students_info") {
      arrayResult = application
        ? Object.keys(application?.studentDetails).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application?.studentDetails[item],
            };
          })
        : [];
      infoForm.setFieldsValue(application?.studentDetails);
    }
    arrayResult.sort(function (a, b) {
      return a.formElement.id - b.formElement.id;
    });

    return arrayResult.map((item, i) => (
      <Col
        md={item.name == 0 || item.name == 1 ? 12 : 8}
        key={i}
        className="readOnlyFormItemCol"
      >
        <CompactFormItemReadOnly
          key={i}
          {...item}
          refs={inputEl}
          handleFileInputChange={handleFileInputChange}
          imagePreview={imagePreview}
          switchOnChangeForParent={switchOnChangeForParent}
          onChangeNotes={onChangeNotes}
          application={application}
          educationSystemGrades={filterStudentGrades?.grades}
        />
      </Col>
    ));
  };

  return (
    <>
      <Form
        disabled
        onFinish={onFinishInfoForm}
        form={infoForm}
        layout={"vertical"}
        scrollToFirstError={"true"}
        className="applicationDetailsTabForm"
        size={"large"}
      >
        <Spin spinning={loading} indicator={loader}>
          <Row gutter={[20, 20]}>
            <> {renderInfoForm()}</>
          </Row>
        </Spin>
      </Form>
    </>
  );
}
