import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";
import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import { fetchAllTemplates } from "apis/templates/templatesApis";
import { addNewTemplate } from "apis/templates/templatesApis";
import { updateTemplate } from "apis/templates/templatesApis";
import { deleteTemplate } from "apis/templates/templatesApis";
import TemplateForm from "components/html-templates-forms/TemplateForm";
import { fetchAllHeadersAndFooters } from "apis/templates/templatesApis";
import { fetchAllPlaceholders } from "apis/templates/templatesApis";
import TemplateModal from "components/html-templates-forms/TemplateModal";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const Template = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sections, setSections] = useState([]);
  const [placeholderData, setPlaceholderData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [templateId, settemplateId] = useState("");

  const [templateForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            templateForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Templates"}
          headerSubTitle={"Manage Templates"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };

  useEffect(() => {
    getAllTemplates();
    getAllHeadersAndFooter();
    getAllPlaceholders();
  }, []);
  const getAllPlaceholders = async () => {
    try {
      setLoading(true);
      const response = await fetchAllPlaceholders(10000, page);
      if (response.status === 200) {
        setPlaceholderData(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const getAllHeadersAndFooter = async () => {
    try {
      setLoading(true);
      const response = await fetchAllHeadersAndFooters();
      if (response.status === 200) {
        setSections(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const getAllTemplates = async () => {
    try {
      setLoading(true);
      const response = await fetchAllTemplates();
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addTemplate = async (data) => {
    try {
      setLoading(true);
      const response = await addNewTemplate({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Template added successfully" });
        await getAllTemplates();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateTemplate = async (data) => {
    try {
      setLoading(true);
      const response = await updateTemplate({ id: templateId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Templates updated successfully" });
        await getAllTemplates();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteTemplate = async (id) => {
    try {
      setLoading(true);
      const response = await deleteTemplate({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Templates deleted successfully" });
        await getAllTemplates();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
    };
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateTemplate(data);
    } else {
      await addTemplate(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    templateForm.setFieldsValue({
      ...record,
      headerId: record?.header?.id,
      footerId: record?.footer?.id,
    });
    settemplateId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: "title",

              // render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "Active",
              //   dataIndex: "isEnabled",
              key: "isActive",
              render: (record) => (
                <p className="answer_para">{record?.isActive ? "YES" : "NO"}</p>
              ),
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Templates Header Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Template?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteTemplate(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        {/* <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Template`}
          className="template_modal"
        >
          <TemplateForm
            onSubmit={onSubmit}
            formName={templateForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            sections={sections}
            placeholderData={placeholderData}
          />
        </CustomModal> */}
        <TemplateModal
        isFormModalOpen={showModal}
        handleClose={onCloseForm}
        visibility={showModal}
        handleCancel={onCloseForm}
        handleOk={() => null}
        title={`${modalTitle} Form Document`}
      >
        <TemplateForm
            onSubmit={onSubmit}
            formName={templateForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            sections={sections}
            placeholderData={placeholderData}
          />
        {/* <SccDocumentGeneratorForm
        
          onSubmit={onSubmit}
          formName={sccDocumentForm}
          loading={loading}
          valuesForEdit={valuesForEdit}
          getUser={(user) => setUser(user)}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
        /> */}
      </TemplateModal>
      </Spin>
    </div>
  );
};

export default Template;
