import { Button, Select, Col, Form, Row } from "antd";
import CommonTable from "components/Tables/CommonTable";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { showValidationError } from "utils/helpers/helpers";
const { Option } = Select;

const SchFinalApprovalAddRewards = ({
  onSave,
  rewardsConfig,
  schType,
  currentApplication,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _preferredCampuses = _campuses?.filter(
    (_camp) =>
      _camp.code ===
        currentApplication.details.firstChoiceCampus?.valueMeta?.code ||
      _camp.code ===
        currentApplication.details.secondChoiceCampus?.valueMeta?.code
  );
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const selectedRecord = selectedRows[0];
    setSelectedRecord(selectedRows[0]);
    setSelectedRowKeys(selectedRowKeys);
  };

  const [selectedCampusCode, setSelectedCampusCode] = useState("");

  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.totalSeats - record.seatsAvailed < 1,
      // Column configuration not to be checked
      name: record.totalSeats,
    }),
  };

  const onSaveRewards = () => {
    if (selectedCampusCode === "") {
      showValidationError("Please select award campus");
      return;
    }
    onSave(selectedRecord, selectedCampusCode);
  };

  return (
    <div>
      <Row style={{ marginTop: 25, marginBottom: 25 }}>
        <Col md={24} className="filterColumn">
          <Form.Item name="campus" className="dropdown">
            <Select
              placeholder="Award Campus"
              onChange={(e) => {
                setSelectedCampusCode(e);
              }}
              bordered={false}
            >
              {_preferredCampuses?.map((el, i) => (
                <Option key={i} value={`${el.code}`}>
                  {el.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <h5 className="tcc_reson_tag">{schType} - Rewards</h5>
      <CommonTable
        rowSelection={rowSelection}
        data={rewardsConfig}
        rowKey={(record) => record.id}
        pagination={false}
        columns={[
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
          },
          {
            title: "Value",
            dataIndex: "value",
            key: "value",
          },
          {
            title: "Total Seats",
            dataIndex: "totalSeats",
            key: "totalSeats",
          },
          {
            title: "Seats Availed",
            dataIndex: "seatsAvailed",
            key: "seatsAvailed",
          },
        ]}
      ></CommonTable>
      <div className="save_reward_button">
        <Button
          disabled={
            selectedRowKeys.length > 0 && selectedCampusCode !== ""
              ? false
              : true
          }
          onClick={onSaveRewards}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
export default SchFinalApprovalAddRewards;
