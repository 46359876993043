import React, { useEffect, useState } from "react";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import SccReportingStage from "../scc-stages-screens/SccReportingStage";
import SccFinalApproval from "../scc-stages-screens/SccFinalApproval";
import SccAcknowledgement from "../scc-stages-screens/SccAcknowledgement";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getSccConductCaseDetails } from "../scc-offense-management/api";
import SccApprovalLevelTwo from "../scc-stages-screens/SccApprovalLevalTwo";
import SccApprovalLevelOne from "../scc-stages-screens/SccApprovalLevelOne";

const SccDetails = (props) => {
  const conductCaseType = "SCC";
  const conductCaseId = props.location.state.conductCaseId;
  const [conductCaseDetails, setConductCaseDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    conductCaseId && getDetails();
  }, [conductCaseId]);

  const getDetails = async () => {
    try {
      setLoading(true);
      const response = await getSccConductCaseDetails({
        caseId: conductCaseId,
      });
      if (response.status === 200) {
        setConductCaseDetails(response.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const renderApplicationOnStageBase = (stage) => {
    let stageProps = {
      conductCaseDetails,
      refetch: getDetails,
      loading,
      ...props,
    };
    switch (conductCaseType) {
      case "SCC":
        switch (stage) {
          case "reporting":
            return <SccReportingStage {...stageProps} />;
          case "approval_level_1":
            return <SccApprovalLevelOne {...stageProps} />;
          case "approval_level_2":
            return <SccApprovalLevelTwo {...stageProps} />;
          case "final_approval":
            return <SccFinalApproval {...stageProps} />;
          case "acknowledgement":
            return <SccAcknowledgement {...stageProps} />;
        }
        break;
      default:
        break;
    }
  };
  return (
    <div>
      {conductCaseDetails
        ? renderApplicationOnStageBase(conductCaseDetails?.currentStage?.slug)
        : renderNoDataPlaceholder(loading)}
    </div>
  );
};

export default SccDetails;
