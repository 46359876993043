/* eslint-disable eqeqeq */
import React from "react";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Row,
  Input,
  Spin,
  Switch,
  InputNumber,
} from "antd";
import { useState } from "react";
import { getGeneralConfigurations } from "apis/general-configuration/generalConfigurationapi";
import { requestErrorHandel } from "utils/helpers/helpers";
import { useEffect } from "react";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { addGeneralConfigurations } from "apis/general-configuration/generalConfigurationapi";
import { successMessage, errorMessage } from "utils/helpers/helpers";
import TextArea from "antd/lib/input/TextArea";

function GeneralConfiguration() {
  const [apiKey, setApiKey] = useState("");
  const [instagramApiKey, setInstagramApiKey] = useState("");
  const [instagramAppId, setInstagramAppId] = useState("");

  const [chatbotId, setChatbotId] = useState("");
  const [testingPinCode, setTestingPinCode] = useState("");
  //const [appFeeServiceId, setAppFeeServiceId] = useState("");
  //const [appRegServiceId, setAppRegServiceId] = useState("");
  const [loading, setLoading] = useState(false);
  const [allNotification, setAllNotification] = useState({
    emial: false,
    mobile: false,
    sms: false,
    online: false,
    enrolment: true,
    actual: false,
    chatbotStatus: false,
    testing_mode: false,
    external_sis: false,
    external_erp: false,
  });
  //const [termsEN, setTermsEN] = useState("");
  //const [termsAR, setTermsAR] = useState("");
  const [verifyCashier, setVerifyCashier] = useState(false);
  const [isEnableFSM, setisEnableFSM] = useState(false);

  useEffect(() => {
    fetchGeneralConfigurationData();
  }, []);
  const fetchGeneralConfigurationData = async () => {
    try {
      setLoading(true);
      const response = await getGeneralConfigurations();
      if (response.status == 200) {
        const data = response.data.data;
        setAllNotification({
          emial: data.email_notifications == 1 ? true : false,
          mobile: data.mobile_notifications == 1 ? true : false,
          sms: data.sms_notifications == 1 ? true : false,
          online: data.online_school == 1 ? true : false,
          enrolment: data.enrollment_school == 1 ? true : false,
          actual: data.actual_school == 1 ? true : false,
          chatbotStatus: data.chatbot_status == 1 ? true : false,
          testing_mode: data.testing_mode == 1 ? true : false,
          external_sis: data.external_sis == 1 ? true : false,
          external_erp: data.external_erp == 1 ? true : false,
        });

        setApiKey(data.api_key);
        setInstagramApiKey(data.instagram_api_key);
        setInstagramAppId(data.instagram_app_id);
        setChatbotId(data.chatbot_id);
        // setisEnableFSM(data?.isEnableFSM);
        setTestingPinCode(data.testing_pin_code);

        //setAppRegServiceId(data.registration_fee_service_id);
        //setAppFeeServiceId(data.app_fee_service_id);
        //setTermsAR(data.termsConditionsAr);
        // setTermsEN(data.termsConditions);
        setVerifyCashier(data.verify_cashier == 1);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onSaveNotification = async () => {
    try {
      if (
        (allNotification.testing_mode && !testingPinCode) ||
        testingPinCode.length < 4
      ) {
        return errorMessage({
          message: "Please enter 4 digits testing pin code",
        });
      }
      const data = {
        api_key: apiKey,
        instagram_api_key: instagramApiKey,
        instagram_app_id: instagramAppId,
        email_notifications: allNotification.emial,
        sms_notifications: allNotification.sms,
        mobile_notifications: allNotification.mobile,
        online_school: allNotification.online,
        enrollment_school: allNotification.enrolment,
        actual_school: allNotification.actual,
        // isEnableFSM: isEnableFSM,
        //termsConditions: termsEN,
        //termsConditionsAr: termsAR,
        //registration_fee_service_id: appRegServiceId,
        //app_fee_service_id: appFeeServiceId,
        verify_cashier: verifyCashier,
        chatbot_status: allNotification.chatbotStatus,
        chatbot_id: chatbotId,
        testing_pin_code: testingPinCode ? parseInt(testingPinCode) : 0,
        testing_mode: allNotification.testing_mode,
        external_sis: allNotification.external_sis,
        external_erp: allNotification.external_erp,
      };

      const response = await addGeneralConfigurations({ data: data });
      if (response.status == 200) {
        successMessage({ message: response.data.message });
        fetchGeneralConfigurationData();
      }
    } catch (error) {
      await requestErrorHandel({ error: error });
    }
  };
  const handleSyncPSStudents = async () => {};
  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={loader}>
        <div>
          <Card className="antCardBorder antCard">
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flex: 1,
                  width: "100%",
                  flexWrap: "wrap",
                  gap: "15px",
                }}
              >
                <div>
                  <h5>General Configuration</h5>
                </div>
                <Row gutter={[20]}>
                  <Col lg={12}>
                    <Button
                      type="primary"
                      style={{ width: "140px" }}
                      onClick={onSaveNotification}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Row>
          </Card>
          <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px" }}>
                <h5>Security Api Key</h5>
              </div>
              <div>
                <Input
                  placeholder="Api key"
                  style={{ width: "50%" }}
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
              </div>
            </Card>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                <h5>Meta App</h5>
              </div>
              <div
                style={{
                   marginBottom: "20px",
                  display: "flex",
                  flexDirection:"column",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <p>Instagram API Key</p>
                <TextArea
                  placeholder="Instagram API Key"
                  style={{ width: "50%" }}
                  rows={4}
                  value={instagramApiKey}
                  onChange={(e) => setInstagramApiKey(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection:"column",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <p>Meta APP ID</p>
                <Input
                  placeholder="Instagram APP ID"
                  style={{ width: "50%" }}
                  value={instagramAppId}
                  onChange={(e) => setInstagramAppId(e.target.value)}
                />
              </div>
              {/* <div>
                <Input
                  placeholder="Instagram APP ID"
                  style={{ width: "50%" }}
                  value={instagramAppId}
                  onChange={(e) => setInstagramAppId(e.target.value)}
                />
              </div> */}
            </Card>
          </div>

          {/* <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px" }}>
                <h5>Facility Service Managemenet</h5>
              </div>
              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Enable / Disable</p>
                    <Checkbox
                      value={isEnableFSM}
                      onChange={() => setisEnableFSM(!isEnableFSM)}
                      checked={isEnableFSM}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </div> */}
          <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px" }}>
                <h5>Enable / Disable Cashier Verification</h5>
              </div>
              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Verify Cashier</p>
                    <Checkbox
                      value={verifyCashier}
                      onChange={() => setVerifyCashier(!verifyCashier)}
                      checked={verifyCashier}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px" }}>
                <h5>Enable / Disable Chatbot</h5>
              </div>
              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <input type="checkbox" name="" id="" /> */}
                    <p>Chatbot</p>
                    <Checkbox
                      value={allNotification.chatbotStatus}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          chatbotStatus: !allNotification.chatbotStatus,
                        })
                      }
                      checked={allNotification.chatbotStatus}
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ marginBottom: "20px" }}>
                <h5>Chatbot ID</h5>
              </div>
              <div>
                <Input
                  placeholder="Chatbot ID"
                  style={{ width: "50%" }}
                  value={chatbotId}
                  onChange={(e) => setChatbotId(e.target.value)}
                />
              </div>

              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "26px",
                    }}
                  >
                    {/* <input type="checkbox" name="" id="" /> */}
                    <p>Chatbot History</p>
                    <Checkbox
                      value={allNotification.chatbotHistory}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          chatbotHistory: !allNotification.chatbotHistory,
                        })
                      }
                      checked={allNotification.chatbotHistory}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px" }}>
                <h5>Enable / Disable Testing Mode</h5>
              </div>
              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Test Mode</p>

                    <Switch
                      defaultChecked={allNotification.testing_mode}
                      checked={allNotification.testing_mode}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          testing_mode: !allNotification.testing_mode,
                        })
                      }
                      checkedChildren="Active"
                      unCheckedChildren="Deactive"
                      style={{ width: 100 }} // Set the desired fixed width here
                    />
                  </div>
                </Col>
              </Row>
              {allNotification.testing_mode && (
                <>
                  <div style={{ marginBottom: "20px" }}>
                    <h5>Testing Pin Code</h5>
                  </div>
                  <div>
                    <InputNumber
                      type="number"
                      placeholder="Enter Testing mode Pin Code"
                      value={testingPinCode}
                      style={{ width: "100%" }}
                      controls={false}
                      onChange={(e) => setTestingPinCode(e.target.value)}
                    />
                  </div>
                </>
              )}
            </Card>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px" }}>
                <h5>Data Source</h5>
              </div>
              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>External SIS</p>

                    <Switch
                      defaultChecked={allNotification.external_sis}
                      checked={allNotification.external_sis}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          external_sis: !allNotification.external_sis,
                        })
                      }
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      style={{ width: 100 }} // Set the desired fixed width here
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>External ERP</p>

                    <Switch
                      defaultChecked={allNotification.external_erp}
                      checked={allNotification.external_erp}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          external_erp: !allNotification.external_erp,
                        })
                      }
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      style={{ width: 100 }} // Set the desired fixed width here
                    />
                  </div>
                </Col>
              </Row>
              
            </Card>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px" }}>
                <h5>Enable / Disable Notifications</h5>
              </div>
              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Email</p>
                    <Checkbox
                      value={allNotification.emial}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          emial: !allNotification.emial,
                        })
                      }
                      checked={allNotification.emial}
                      // defaultChecked={allNotification.emial ? "checked" : ""}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Mobile Notification</p>
                    <Checkbox
                      value={allNotification.mobile}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          mobile: !allNotification.mobile,
                        })
                      }
                      checked={allNotification.mobile}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>SMS Notification</p>
                    <Checkbox
                      value={allNotification.sms}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          sms: !allNotification.sms,
                        })
                      }
                      checked={allNotification.sms}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px" }}>
                <h5>Enable / Disable Target Schools</h5>
              </div>
              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Online</p>
                    <Checkbox
                      value={allNotification.online}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          online: !allNotification.online,
                        })
                      }
                      checked={allNotification.online}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Enrolment</p>
                    <Checkbox
                      value={allNotification.enrolment}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          enrolment: !allNotification.enrolment,
                        })
                      }
                      checked={allNotification.enrolment}
                    ></Checkbox>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Actual</p>
                    <Checkbox
                      value={allNotification.actual}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          actual: !allNotification.actual,
                        })
                      }
                      checked={allNotification.actual}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
          {/* <div style={{ marginTop: "20px" }}>
            <Card className="antCardBorder antCard">
              <div style={{ marginBottom: "20px" }}>
                <h5>Sync Jobs</h5>
              </div>
              <Row>
                <Col
                  lg={12}
                  sm={24}
                  className="generalNotificationTriggerColumn"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>PS Students</p>
                    <SyncOutlined onClick={handleSyncPSStudents} />
                    <Checkbox
                      value={allNotification.online}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          online: !allNotification.online,
                        })
                      }
                      checked={allNotification.online}
                    />
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Enrolment</p>
                    <Checkbox
                      value={allNotification.enrolment}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          enrolment: !allNotification.enrolment,
                        })
                      }
                      checked={allNotification.enrolment}
                    ></Checkbox>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>Actual</p>
                    <Checkbox
                      value={allNotification.actual}
                      onChange={() =>
                        setAllNotification({
                          ...allNotification,
                          actual: !allNotification.actual,
                        })
                      }
                      checked={allNotification.actual}
                    />
                  </div> 
                </Col>
              </Row>
            </Card>
          </div> */}
        </div>
      </Spin>
    </div>
  );
}

export default GeneralConfiguration;
