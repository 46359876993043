import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  TimePicker,
} from "antd";
import InputColor from "react-input-color";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomModal from "components/CustomModal/CustomModal";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { fetchAllCategories } from "../categories/api";
import { fetchAllEventTypes } from "../events-types/api";
import { fetchAllVats } from "views/FSM/vat/api";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
import {
  addNewFsmFacility,
  fetchAllFsmFacilities,
  updateFsmFacility,
} from "./api";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import moment from "moment";
import { getFullDayName } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { mapping } from "utils/helpers/helpers";
import { renderFacilityTitleFromIndentifer } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { renderFacilityPriceSlotFromIndentifer } from "utils/helpers/helpers";
import { renderFacilityFullDayFromIndentifer } from "utils/helpers/helpers";

import placeholderImage from "../../../../assets/img/default.png";
import { getMimeType } from "utils/helpers/helpers";
import { studentListFields } from "utils/constants";
import { attendeedFields } from "utils/constants";
import FSMTabs from "../fsm-application-detail-header/FSMTabs";
import FSMScheduleTabs from "../fsm-application-detail-header/FSMScheduleTabs";
import BlokSchedules from "./BlokSchedules";

const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const EditFacility = (props) => {
  const record = props.location.state.record;
  const [scheduleForm] = Form.useForm();

  const FSM_campuses = useSelector((state) => state.metaData.FSM_campuses);
  const FSM_features = useSelector((state) => state.metaData.FSM_features);
  const FSM_activityTypes = useSelector(
    (state) => state.metaData.FSM_activityTypes
  );
  const FSM_vats = useSelector((state) => state.metaData.FSM_vats);
  const FSM_categories = useSelector((state) => state.metaData.FSM_categories);
  const _bookingRequestTypes = [
    {
      id: 1,
      typeIdentifier: "EVB",
      title: "Event Booking",
    },
    {
      id: 2,
      typeIdentifier: "LTB",
      title: "Long Term Booking",
    },
    {
      id: 3,
      typeIdentifier: "FFB",
      title: "Full Facility Booking",
    },
    {
      id: 4,
      typeIdentifier: "DPB",
      title: "Day Pass Booking",
    },
    {
      id: 5,
      typeIdentifier: "PHB",
      title: "Per Head Booking",
    },
  ];

  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [addFacilityForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [color, setColor] = React.useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCampusId, setSelectedCampusId] = useState(null);
  const [locationImage, setLocationImage] = useState(null);

  const [showModal, toggleModal] = useState(false);
  const [selectdScheduleTab, setSelectdScheduleTab] = useState(0);

  const [inputValue, setInputValue] = useState("");
  const [selectedBookingTypes, setSelectedBookingTypes] = useState([]);
  const [pricing, setPricing] = useState([]);
  const [daysBasedPricing, setDaysBasedPricing] = useState([]);
  const [errors, setErrors] = useState([]);
  const [tags, setTags] = useState([]);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [initialColor, setInitialColor] = useState("");

  const [schedule, setSchedule] = useState([
    {
      day: "Saturday",
      enabled: false,
      startTime: null,
      endTime: null,
      duration: "",
    },
    {
      day: "Sunday",
      enabled: false,
      startTime: null,
      endTime: null,
      duration: "",
    },
    {
      day: "Monday",
      enabled: false,
      startTime: null,
      endTime: null,
      duration: "",
    },
    {
      day: "Tuesday",
      enabled: false,
      startTime: null,
      endTime: null,
      duration: "",
    },
    {
      day: "Wednesday",
      enabled: false,
      startTime: null,
      endTime: null,
      duration: "",
    },
    {
      day: "Thursday",
      enabled: false,
      startTime: null,
      endTime: null,
      duration: "",
    },
    {
      day: "Friday",
      enabled: false,
      startTime: null,
      endTime: null,
      duration: "",
    },
  ]);
  const [blockSchedules, setBlockSchedules] = useState([]);

  const [scheduleStartAt, setScheduleStartAt] = useState(null);
  const [scheduleEndAt, setScheduleEndAt] = useState(null);
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [selectedBlockSchedules, setSelectedBlockSchedules] = useState([]);
  const [scheduleId, setScheduleId] = useState("");
  const [existingBlockSchedules, setExistingBlockSchedules] = useState([]);

  const daysOfWeek = [
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ];

  const [faciltyStages, setFacilityStages] = useState([
    {
      id: "1",
      title: "Review",
      value: "review",
      isChecked: false,
    },
    {
      id: "1",
      title: "Pre Final Approval",
      value: "pre_final_approval",
      isChecked: false,
    },
    {
      id: "1",
      title: "Final Approval",
      value: "final_approval",
      isChecked: false,
    },
    {
      id: "1",
      title: "Payment",
      value: "payment",
      isChecked: true,
    },
    {
      id: "1",
      title: "Cancellation & Refunds",
      value: "cancellation_and_refunds",
      isChecked: true,
    },
  ]);

  const [scheduleErrors, setScheduleErrors] = useState(
    Array(daysOfWeek.length).fill(null)
  );
  const [emptyFieldErrors, setEmptyFieldErrors] = useState({});
  const [facilities, setFacilities] = useState([]);
  const [attendeesInfoFields, setAttendeesInfoFields] =
    useState(attendeedFields);
  const [showAttendeesInfoFields, setShowAttendeesInfoFields] = useState(false);
  const [showSelectionRowForAttendees, setShowSelectionRowAttendees] =
    useState(false);
  const [selectedAttendeeFields, setSelectedAttendeeFields] = useState([]);
  const [errosForAteendeesInformation, setErrosForAteendeesInformation] =
    useState("");

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };
  useEffect(() => {
    if (record) {
      const parentBookingHours = Math.floor(record.parentBookingExpiry / 60);
      const parentBookingMinutes = record.parentBookingExpiry % 60;
      const adminBookingHours = Math.floor(record.adminBookingExpiry / 60);
      const adminBookingMinutes = record.adminBookingExpiry % 60;

      addFacilityForm.setFieldsValue({
        ...record,
        campusId: record?.campus?.id,
        categoryId: record?.category?.id,
        subCategoryId: record?.subCategory?.id,
        vatId: record?.vat?.id,
        allowedActivities: record?.allowedActivities?.map((event) => event?.id),
        features: record?.features?.map((feat) => feat?.id),
        availableForParent:
          ["both", "parent"].indexOf(record?.availableFor) > -1,
        availableForNoneParent:
          ["both", "guest"].indexOf(record?.availableFor) > -1,
        parent_hours: parentBookingHours,
        parent_minutes: parentBookingMinutes,
        admin_hours: adminBookingHours,
        admin_minutes: adminBookingMinutes,
      });
      setSelectedSchedules(
        Object.keys(record?.schedule).length > 0 ? record?.schedule?.days : []
      );
      setSelectedBlockSchedules(
        Object.keys(record?.schedule).length > 0
          ? record?.schedule?.blockSchedules[0]?.days
          : []
      );
      setSelectedCategoryId(record?.category?.id);
      setSelectedCampusId(record?.campus?.id);
      setInitialColor(record?.colorCode);
      setTags(record?.tags);
      setLocationImage(record?.locationImage ? record?.locationImage : null);

      if (record?.gallery.length > 0) {
        if (record?.gallery.length > 0) {
          const existingFiles = record.gallery.map((url) => ({
            fileUrl: url,
            isNew: false,
          }));
          setFiles(existingFiles);
        }
      }

      if (record?.pricing.length > 0) {
        const updatedBookingTypes = record?.pricing.map((item) => {
          return item.typeIdentifier;
        });
        const containsLTB = updatedBookingTypes.includes("LTB");

        if (containsLTB) {
          const findLTBDayBasedPricing = record?.pricing.find(
            (type) => type.typeIdentifier === "LTB"
          );

          if (
            findLTBDayBasedPricing &&
            findLTBDayBasedPricing.daysBasedPricing
          ) {
            setDaysBasedPricing(findLTBDayBasedPricing.daysBasedPricing);
          }
          addFacilityForm.setFieldsValue({
            notes: findLTBDayBasedPricing.notes,
            notesAr: findLTBDayBasedPricing.notesAr,
          });
        }
        setSelectedBookingTypes(updatedBookingTypes);
        setPricing(record?.pricing);
      }
      if (
        Object.keys(record?.schedule).length &&
        record?.schedule?.days?.length > 0
      ) {
        setScheduleId(record?.schedule?.id);
        const receivedSchedules = record?.schedule?.days;
        setScheduleStartAt(moment(record?.schedule?.startAt, "YYYY-MM-DD"));
        setScheduleEndAt(moment(record?.schedule?.endAt, "YYYY-MM-DD"));
        receivedSchedules.forEach((serverSchedule) => {
          const day = mapping[serverSchedule.day.toLowerCase()];
          const transformedSchedule = {
            day: day,
            enabled: true, // Set as needed based on server data
            startTime: moment(serverSchedule.startTime, "HH:mm"),
            endTime: moment(serverSchedule.endTime, "HH:mm"),
            duration: serverSchedule.duration.toString(), // Convert duration to string if needed
          };

          const matchingDayIndex = schedule.findIndex(
            (localSchedule) => localSchedule.day === transformedSchedule.day
          );

          if (matchingDayIndex !== -1) {
            schedule[matchingDayIndex] = transformedSchedule;
          } else {
            schedule.push(transformedSchedule);
          }
        });

        setSchedule([...schedule]); // Update the state with the modified schedule array
        setExistingBlockSchedules(record?.schedule?.blockSchedules);
      }
      if (record?.stages.length > 0) {
        setFacilityStages((prevStages) =>
          prevStages.map((stage) => ({
            ...stage,
            isChecked: record.stages.includes(stage.value) || stage.isChecked,
          }))
        );
      }
      setShowSelectionRowAttendees(record?.attendeesInfoRequired);
      // Update form fields based on record values
      if (
        record?.attendeesInfoMethod === "both" ||
        record?.attendeesInfoMethod === "manual"
      ) {
        addFacilityForm.setFieldsValue({
          manual: true,
          students: true,
        });
        setShowAttendeesInfoFields(true);
      } else {
        addFacilityForm.setFieldsValue({
          manual: record?.attendeesInfoMethod === "manual",
          students: record?.attendeesInfoMethod === "students",
        });
      }
      // Set initial values for the dropdown based on record values
      const initialValues = [];
      if (record?.attendeesLastNameRequired) initialValues.push("lastName");
      if (record?.attendeesEmailRequired) initialValues.push("email");
      if (record?.attendeesGenderRequired) initialValues.push("gender");
      if (record?.attendeesDobRequired) initialValues.push("dob");

      addFacilityForm.setFieldsValue({
        studentFields: initialValues,
      });
      // Set selectedAttendeeFields initially based on initialValues
      setSelectedAttendeeFields(initialValues);
    }
  }, [record]);

  useEffect(() => {
    getAllFsmFacilities();
  }, []);

  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onChooseFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const onFileSelected = async (e) => {
    // Handle the selected file(s) here
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 5) {
      // If more than 5 files are selected, alert the user
      alert("You can select a maximum of 5 files.");
      // Reset the file input
      e.target.value = null;
      return;
    }
    const newFiles = Array.from(selectedFiles).map((file) => ({
      fileUrl: URL.createObjectURL(file),
      isNew: true,
      file: file, // Assuming you need to store the file object as well
    }));

    setFiles([...files, ...newFiles]);
  };

  const onSaveSchedule = () => {
    const validationErrors = validateSchedule(schedule);

    if (validationErrors.length > 0) {
      // Display error messages in form fields or handle as needed
      console.error("Validation Errors:", validationErrors);

      // Optionally, you can update the UI to highlight the errors or show a message to the user.

      setLoading(false);
      return;
    }
    const selectedSchedules = schedule
      .filter((day) => day.enabled)
      .map(({ day, startTime, endTime, duration }) => ({
        day: day.slice(0, 3).toLowerCase(),
        startTime: startTime ? startTime.format("HH:mm") : null,
        endTime: endTime ? endTime.format("HH:mm") : null,
        duration: parseInt(duration, 10),
      }));

    // Now 'selectedSchedules' contains the schedules for checked days
    setSelectedSchedules(selectedSchedules);
    onCloseForm();
  };

  const handleCheckboxChange = (index) => (e) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[index].enabled = e.target.checked;
    setSchedule(updatedSchedule);
    setScheduleErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = null;
      return newErrors;
    });
  };

  const handleThresholdChange = (value, field, index) => {
    const updatedPricing = [...daysBasedPricing];
    // Convert to number if the field is "price" or "noOfDays"
    if (field === "price" || field === "noOfDays") {
      updatedPricing[index][field] = Number(value);
    } else {
      updatedPricing[index][field] = value;
    }
    setDaysBasedPricing(updatedPricing);
  };

  const handleCKEditorChange = (index, field, data) => {
    const updatedPricing = [...daysBasedPricing];
    updatedPricing[index][field] = data;
    setDaysBasedPricing(updatedPricing);
  };

  const handleTimeChange = (index, field) => (time) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[index][field] = time;
    setSchedule(updatedSchedule);
    setScheduleErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = null;
      return newErrors;
    });
  };

  const handleDurationChange = (index) => (value) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[index].duration = value;
    updatedSchedule[index].startTime = null;
    updatedSchedule[index].endTime = null;
    scheduleForm.setFieldsValue({
      [`startTime[${index}]`]: null,
      [`endTime[${index}]`]: null,
    });
    setSchedule(updatedSchedule);
    setScheduleErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = null;
      return newErrors;
    });
  };

  const validateSchedule = (schedule) => {
    const errors = [];

    schedule.forEach((item, index) => {
      if (item.enabled) {
        if (!item.duration || !item.startTime || !item.endTime) {
          errors.push(
            `Please enter duration, start time, and end time for ${daysOfWeek[index]}`
          );
          // Set error for the current schedule item
          setScheduleErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[
              index
            ] = `Please enter duration, start time, and end time`;
            return newErrors;
          });
        }
      }
    });

    return errors;
  };

  const disabledEndTime = (now, index) => {
    if (!schedule || !now || !schedule[index]?.startTime) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    }

    const selectedStartTime = moment(schedule[index]?.startTime);

    const disabledHours = () => {
      const hours = [];
      for (let i = 0; i <= selectedStartTime.hours(); i++) {
        hours.push(i);
      }
      return hours;
    };

    const disabledMinutes = (selectedHour) => {
      if (selectedHour === selectedStartTime.hours()) {
        const minutes = [];
        for (let i = 0; i < selectedStartTime.minutes(); i++) {
          minutes.push(i);
        }
        return minutes;
      }
      return [];
    };

    const disabledSeconds = (selectedHour, selectedMinute) => {
      if (
        selectedHour === selectedStartTime.hours() &&
        selectedMinute === selectedStartTime.minutes()
      ) {
        return [0, 1, 2, /*...*/ 59];
      }
      return [];
    };

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
      hideDisabledOptions: true,
    };
  };

  const getBlockSchedules = useCallback((blockSchedules) => {
    setBlockSchedules(blockSchedules);
  }, []);

  const renderOpenAndBlockSchedules = (tab) => {
    switch (tab) {
      case 0:
        return renderSchedule();
        break;
      case 1:
        return (
          <BlokSchedules
            isNew={false}
            selectedBlockSchedul={blockSchedules}
            record={record}
            onCloseForm={onCloseForm}
            getBlockSchedules={getBlockSchedules}
          />
        );
        break;

      default:
        return renderSchedule();
        break;
    }
  };

  const renderSchedule = () => {
    return (
      <div>
        <Form onFinish={onSaveSchedule} form={scheduleForm}>
          <Row gutter={20}>
            <Col md={12}>
              <p>Start Date</p>
              <Form.Item>
                <DatePicker
                  // format={"dd/mm/yyyy"}
                  style={{ width: "100%" }}
                  placeholder="Date"
                  value={scheduleStartAt}
                  onChange={(date) => setScheduleStartAt(date)}
                  disabledDate={(currentDate) => {
                    // Disable dates before today
                    return currentDate && currentDate < moment().startOf("day");
                  }}
                  clearIcon={false}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <p>End Date</p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: `End date is required`,
                  },
                ]}
              >
                <DatePicker
                  // format={"dd/mm/yyyy"}
                  style={{ width: "100%" }}
                  placeholder="Date"
                  value={scheduleEndAt}
                  onChange={(date) => setScheduleEndAt(date)}
                  disabledDate={(currentDate) => {
                    // Disable dates before today and the same as the start date
                    return (
                      currentDate &&
                      (currentDate < moment().startOf("day") ||
                        currentDate.isSame(scheduleStartAt, "day"))
                    );
                  }}
                  clearIcon={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <br />

          {daysOfWeek.map((day, index) => (
            <Form.Item
              key={index}
              validateStatus={scheduleErrors[index] ? "error" : ""}
              help={scheduleErrors[index]}
            >
              <Row gutter={8}>
                <Col md={6}>
                  <Checkbox
                    checked={schedule[index]?.enabled}
                    onChange={handleCheckboxChange(index)}
                  >
                    {day}
                  </Checkbox>
                </Col>
                <Col md={6}>
                  <Form.Item>
                    <Select
                      value={schedule[index]?.duration}
                      onChange={handleDurationChange(index)}
                      style={{ width: "100%" }}
                      placeholder="Slot Duration"
                      disabled={!schedule[index]?.enabled}
                    >
                      <Option value="30">30 minutes</Option>
                      <Option value="60">1 hour </Option>
                      <Option value="90">1 hour 30 minutes</Option>
                      <Option value="120">2 hours</Option>
                      <Option value="150">2 hours 30 minutes</Option>
                      <Option value="180">3 hours</Option>
                      <Option value="210">3 hours 30 minutes</Option>
                      <Option value="240">4 hours</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item>
                    <TimePicker
                      style={{ width: "100%" }}
                      placeholder="Start Time"
                      format={"HH:mm"}
                      value={schedule[index]?.startTime}
                      onChange={handleTimeChange(index, "startTime")}
                      clearIcon={false}
                      minuteStep={
                        schedule[index]?.duration.length &&
                        schedule[index]?.duration === "1 hour"
                          ? 60
                          : 30
                      }
                      disabled={schedule[index]?.duration === ""}
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    rules={[
                      {
                        required: schedule[index]?.enabled,
                        message: `endTime time required`,
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%" }}
                      placeholder="Start Time"
                      format={"HH:mm"}
                      value={schedule[index]?.endTime}
                      onChange={handleTimeChange(index, "endTime")}
                      clearIcon={false}
                      minuteStep={
                        schedule[index]?.duration.length &&
                        schedule[index]?.duration === "1 hour"
                          ? 60
                          : 30
                      }
                      disabled={schedule[index]?.duration === ""}
                      disabledTime={(current) =>
                        disabledEndTime(current, index)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          ))}
          <Row justify="start">
            <Button
              htmlType="submit"
              type="primary"
              className="ant__primary__button"
              // onClick={onSaveSchedule}
            >
              {"Save"}
            </Button>
            <div style={{ width: 30 }} />

            <Button className="ant__primary__button" onClick={onCloseForm}>
              Cancel
            </Button>
          </Row>
        </Form>
      </div>
    );
  };

  const renderScheduleData = () => {
    return (
      <div>
        <Row gutter={20}>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <div>
                  <p className="schedule__title">Start Date</p>
                  <p className="schedule__value">
                    {scheduleStartAt
                      ? formatedDate(scheduleStartAt)
                      : "No End Date"}
                  </p>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <p className="schedule__title">End Date</p>
                  <p className="schedule__value">
                    {scheduleEndAt
                      ? formatedDate(scheduleEndAt)
                      : "No End Date"}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <div className="schedule__slots">
          {selectedSchedules?.map((item, index) => {
            return (
              <div key={index}>
                <div className="schedule__day_title">
                  <div>
                    <p className="schedule__day">{getFullDayName(item?.day)}</p>
                  </div>
                  <div className="hr_row" />
                </div>
                <Row gutter={[20, 20]}>
                  <Col md={24}>
                    <Row gutter={10}>
                      <Col md={12}>
                        <div>
                          <p className="schedule__title">Start Time</p>
                          <p className="schedule__value">
                            {formatedDateWithAmAndPm(item?.startTime)}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div>
                          <p className="schedule__title">End Time</p>
                          <p className="schedule__value">
                            {formatedDateWithAmAndPm(item?.endTime)}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const renderBlockScheduleData = () => {
    return (
      <div>
        <Row gutter={20}>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <div>
                  <p className="schedule__title">Start Date</p>
                  <p className="schedule__value">
                    {scheduleStartAt
                      ? formatedDate(scheduleStartAt)
                      : "No End Date"}
                  </p>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <p className="schedule__title">End Date</p>
                  <p className="schedule__value">
                    {scheduleEndAt
                      ? formatedDate(scheduleEndAt)
                      : "No End Date"}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <div className="schedule__slots">
          {selectedBlockSchedules?.map((item, index) => {
            return (
              <div key={index}>
                <div className="schedule__day_title">
                  <div>
                    <p className="schedule__day">{getFullDayName(item?.day)}</p>
                  </div>
                  <div className="hr_row" />
                </div>
                <Row gutter={[20, 20]}>
                  <Col md={24}>
                    {item.fullDay ? (
                      "Full Day"
                    ) : (
                      <Row gutter={10}>
                        <Col md={12}>
                          <div>
                            <p className="schedule__title">Start Time</p>
                            <p className="schedule__value">
                              {formatedDateWithAmAndPm(item?.startTime)}
                            </p>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <p className="schedule__title">End Time</p>
                            <p className="schedule__value">
                              {formatedDateWithAmAndPm(item?.endTime)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const onupdateFacilitySchedule = () => {
    toggleModal(true);
    setModalTitle(ModalType.EDIT);
  };

  const handleInputChangeForTag = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === " " && inputValue.trim() !== "") {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const removeTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  const handleCheckboxForBookingTypes = (identifier) => {
    if (selectedBookingTypes.includes(identifier)) {
      setSelectedBookingTypes(
        selectedBookingTypes.filter((type) => type !== identifier)
      );
      setPricing(pricing.filter((item) => item.typeIdentifier !== identifier));
    } else {
      setSelectedBookingTypes([...selectedBookingTypes, identifier]);
      setPricing([
        ...pricing,
        {
          typeIdentifier: identifier,
          hourlyPrice: "",
          fullDayPrice: "",
          autoApprove: false,
          allowMultipleSlots: false,
          allowFullDay: false,
        },
      ]);
    }
  };

  const handlePriceChange = (e, typeIdentifier, priceType) => {
    const updatedPricing = pricing.map((item) => {
      if (item.typeIdentifier === typeIdentifier) {
        if (priceType === "hourly") {
          return { ...item, hourlyPrice: Number(e.target.value) };
        } else if (priceType === "fullDay") {
          return { ...item, fullDayPrice: Number(e.target.value) };
        }
      }
      return item;
    });
    setPricing(updatedPricing);
    setEmptyFieldErrors((prevErrors) => ({
      ...prevErrors,
      [`${typeIdentifier}_${priceType}`]: false,
    }));
  };
  const handleChangeGracePeriod = (e, typeIdentifier, periodType) => {
    const updatedPricing = pricing.map((item) => {
      if (item.typeIdentifier === typeIdentifier) {
        if (periodType === "gracePeriodDays") {
          return { ...item, gracePeriodDays: e.target.value };
        }
      }
      return item;
    });
    setPricing(updatedPricing);
    setEmptyFieldErrors((prevErrors) => ({
      ...prevErrors,
      [`${typeIdentifier}_gracePeriod_days`]: false,
    }));
  };

  const renderAvailableFor = (values) => {
    if (values?.availableForNoneParent && !values?.availableForParent) {
      return "guest"; // If only non-parent checkbox is checked, set availableFor to 'guest'
    } else if (!values?.availableForNoneParent && values?.availableForParent) {
      return "parent"; // If only parent checkbox is checked, set availableFor to 'parent'
    } else if (values?.availableForNoneParent && values?.availableForParent) {
      return "both"; // If both checkboxes are checked, set availableFor to 'both'
    } else {
      return "default_value"; // Handle other cases if necessary (none checked or other combination)
    }
  };
  const prepareDataForAttendees = () => {
    const transformedFields = {
      attendeesLastNameRequired: false,
      attendeesEmailRequired: false,
      attendeesGenderRequired: false,
      attendeesDobRequired: false,
    };

    selectedAttendeeFields.forEach((field) => {
      switch (field) {
        case "lastName":
          transformedFields.attendeesLastNameRequired = true;
          break;
        case "email":
          transformedFields.attendeesEmailRequired = true;
          break;
        case "gender":
          transformedFields.attendeesGenderRequired = true;
          break;
        case "dob":
          transformedFields.attendeesDobRequired = true;
          break;
        // Add other cases as needed
        default:
          break;
      }
    });

    return transformedFields;
  };

  const transformBlockSchedules = (blockSchedules) => {
    return blockSchedules.map((block) => {
      // Convert start and end dates to the format required by the server
      const startAt = moment(block.startAt).format("YYYY-MM-DD");
      const endAt = moment(block.endAt).format("YYYY-MM-DD");

      // Map weekDays to schedules
      const schedules = block.weekDays.flatMap((day) => {
        // If no slots and `addSlots` is false, skip this day
        if (!day.addSlots && !day.fullDay) return [];

        // If full day, add schedule with empty slots
        if (day.fullDay) {
          return [
            {
              day: day.day.slice(0, 3).toLowerCase(), // Convert day to short format (e.g., "Wed")
              startTime: "",
              endTime: "",
              reason: day.reason,
            },
          ];
        }

        // Create schedule entries for each slot
        return day.slots.map((slot) => ({
          day: day.day.slice(0, 3).toLowerCase(), // Convert day to short format (e.g., "Wed")
          startTime: slot.startTime,
          endTime: slot.endTime,
          reason: day.reason,
        }));
      });

      return {
        startAt,
        endAt,
        schedules,
      };
    });
  };
  const onSubmit = async (values) => {
    try {
      const newEmptyFieldErrors = {};
      // Check for empty pricing fields
      selectedBookingTypes.forEach((selectedType) => {
        const typePricing = pricing.find(
          (item) => item.typeIdentifier === selectedType
        );
        if (typePricing) {
          if (selectedType === "FFB" || selectedType === "PHB") {
            if (
              !typePricing.hourlyPrice ||
              parseFloat(typePricing.hourlyPrice) <= 0
            ) {
              newEmptyFieldErrors[`${selectedType}_hourly`] = true;
            }
          } else if (selectedType === "DPB") {
            if (
              !typePricing.fullDayPrice ||
              parseFloat(typePricing.fullDayPrice) <= 0
            ) {
              newEmptyFieldErrors[`${selectedType}_fullDay`] = true;
            }
          } else if (selectedType === "EVB") {
            // For EVB, check both hourly and fullDay prices
            if (
              !typePricing.hourlyPrice ||
              parseFloat(typePricing.hourlyPrice) <= 0
            ) {
              newEmptyFieldErrors[`${selectedType}_hourly`] = true;
            }
            if (
              !typePricing.fullDayPrice ||
              parseFloat(typePricing.fullDayPrice) <= 0
            ) {
              newEmptyFieldErrors[`${selectedType}_fullDay`] = true;
            }
          } else if (selectedType === "LTB") {
            // For LTB, check only hourly price
            if (
              !typePricing.hourlyPrice ||
              parseFloat(typePricing.hourlyPrice) <= 0
            ) {
              newEmptyFieldErrors[`${selectedType}_hourly`] = true;
            }
          }
        }
      });

      // Update state with new empty field errors
      setEmptyFieldErrors(newEmptyFieldErrors);
      // Validate daysBasedPricing
      const updatedErrors = daysBasedPricing.map(() => ({
        noOfDays: "",
        price: "",
      }));

      let hasErrors = false;
      // Find the object with typeIdentifier "LTB"
      const ltbType = selectedBookingTypes.find(
        (selectedType) => selectedType === "LTB"
      );

      if (ltbType) {
        const typePricing = pricing.find(
          (item) => item.typeIdentifier === "LTB"
        );
        if (typePricing && typePricing.allowFullDay) {
          daysBasedPricing.forEach((item, index) => {
            if (
              item.noOfDays === "" ||
              isNaN(item.noOfDays) ||
              item.noOfDays <= 0
            ) {
              updatedErrors[index].noOfDays = "This field is required";
              hasErrors = true;
            }

            if (item.price === "" || isNaN(item.price) || item.price < 0) {
              updatedErrors[index].price = "This field is required";
              hasErrors = true;
            }
          });

          setErrors(updatedErrors);

          // If there are errors in daysBasedPricing, stop submission
          if (hasErrors) {
            window.scrollTo({
              top: document.body.scrollHeight / 2,
              behavior: "smooth",
            });
            return;
          }
        }
      }

      // If any pricing field is empty, stop submission
      if (Object.values(newEmptyFieldErrors).some((value) => value)) {
        window.scrollTo({
          top: document.body.scrollHeight / 2,
          behavior: "smooth",
        });
        return;
      }
      if (values?.attendeesInfoRequired) {
        if (!values?.students && !values.manual) {
          setErrosForAteendeesInformation("Please Select atleast one method");
          window.scrollTo({
            top: document.body.scrollHeight / 2,
            behavior: "smooth",
          });
          return;
        }
      }
      setLoading(true);
      const newFiles = files.filter((fileObj) => fileObj.isNew);
      const oldFiles = files.filter((fileObj) => !fileObj.isNew);

      const uploadTasks = newFiles.map(async (fileObj) => {
        return await handleUploadFileToENSServer(
          fileObj.file,
          "facility-service"
        );
      });
      // Wait for all uploads to finish using Promise.all

      const newGalleryUrls = await Promise.all(uploadTasks);
      const allGalleryUrls = [
        ...oldFiles.map((fileObj) => fileObj.fileUrl),
        ...newGalleryUrls,
      ];

      // Preprocess pricing array
      const processedPricing = pricing.map((item) => {
        // Convert hourlyPrice to number for all types
        let hourlyPrice = parseFloat(item.hourlyPrice);
        if (isNaN(hourlyPrice)) hourlyPrice = 0;

        // Convert gracePeriodDays to number for all types
        let gracePeriodDays = parseFloat(item.gracePeriodDays);
        if (isNaN(gracePeriodDays)) gracePeriodDays = 0;

        // Convert fullDayPrice to number for all types
        let fullDayPrice = parseFloat(item.fullDayPrice);
        if (isNaN(fullDayPrice)) fullDayPrice = 0;

        // For FFB, PHB, and DPB, if the price is 0, set it to 0
        if (["FFB", "PHB", "DPB"].includes(item.typeIdentifier)) {
          if (hourlyPrice === 0) hourlyPrice = 0;
          if (fullDayPrice === 0) fullDayPrice = 0;
        }
        // Add daysBasedPricing array for LTB type
        let _daysBasedPricing = [];
        let notes = "";
        let notesAr = "";
        if (item.typeIdentifier === "LTB") {
          _daysBasedPricing = daysBasedPricing;
          notes = values?.notes || ""; // Set note or an empty string if not present
          notesAr = values?.notesAr || ""; // Initialize with values.thresholds or an empty array if not present
        }

        return {
          ...item,
          hourlyPrice,
          fullDayPrice,
          gracePeriodDays,
          ...(item.typeIdentifier === "LTB" && {
            daysBasedPricing: _daysBasedPricing,
            notes,
            notesAr,
          }), // Conditionally add daysBasedPricing
        };
      });

      const selectedStages = faciltyStages
        .filter((stage) => stage.isChecked)
        .map((stage) => stage.value);
      let attendeesInfoMethod;

      // Determine attendeesInfoMethod based on manual and studentList values
      if (values.manual && values.students) {
        attendeesInfoMethod = "both";
      } else if (values.manual) {
        attendeesInfoMethod = "manual";
      } else if (values.students) {
        attendeesInfoMethod = "students";
      }
      const parentHours = parseInt(values.parent_hours, 10);
      const parentMinutes = parseInt(values.parent_minutes, 10);
      const adminHours = parseInt(values.admin_hours, 10);
      const adminMinutes = parseInt(values.admin_minutes, 10);

      const parentBookingExpiry = parentHours * 60 + parentMinutes;
      const adminBookingExpiry = adminHours * 60 + adminMinutes;

      const transformedBlockSchedules = transformBlockSchedules(blockSchedules);

      const payload = {
        ...values,
        tags: tags,
        stages: selectedStages,
        pricing: processedPricing,
        capacity: Number(values?.capacity),
        gallery: allGalleryUrls,
        colorCode: color.hex,
        availableFor: renderAvailableFor(values),
        scheduleStartAt: moment(scheduleStartAt).format(),
        scheduleEndAt: moment(scheduleEndAt).format(),
        schedules: selectedSchedules,
        blockSchedules:
          transformedBlockSchedules.length > 0
            ? transformedBlockSchedules
            : existingBlockSchedules,
        scheduleId: scheduleId,
        locationImage: locationImage,
        attendeesInfoMethod,
        parentBookingExpiry,
        adminBookingExpiry,
        ...prepareDataForAttendees(),
      };
      const { availableForParent, availableForNoneParent, ...finalPayload } =
        payload;

      const response = await updateFsmFacility(record?.id, finalPayload);
      if (response.status === 200) {
        successMessage({ message: "Facility has been updated successfully" });
        setLoading(false);
        props.history.goBack();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onRemoveFileFromGallery = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const onChooseLocationFile = async (e) => {
    try {
      if (e.target.files.length) {
        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "facility-map"
        );
        setLocationImage(path);
      }
    } catch (error) {}
  };

  const handleCancelButton = () => {
    props.history.goBack();
  };

  const handleAutoApproval = (e, typeIdentifier) => {
    const updatingPricingArrayForAutoApprovai = pricing.map((item) => {
      if (item.typeIdentifier === typeIdentifier) {
        return { ...item, autoApprove: e.target.checked };
      }
      return item;
    });
    setPricing(updatingPricingArrayForAutoApprovai);
  };
  const handleAllowFullDay = (e, typeIdentifier) => {
    const updatingPricingArrayForFullDay = pricing.map((item) => {
      if (item.typeIdentifier === typeIdentifier) {
        return { ...item, allowFullDay: e.target.checked };
      }
      return item;
    });
    if (daysBasedPricing.length === 0) {
      addThreshold();
    }
    setPricing(updatingPricingArrayForFullDay);
  };
  const handleAllowMultipleSlots = (e, typeIdentifier) => {
    const updatingPricingArrayForSlots = pricing.map((item) => {
      if (item.typeIdentifier === typeIdentifier) {
        return { ...item, allowMultipleSlots: e.target.checked };
      }
      return item;
    });
    setPricing(updatingPricingArrayForSlots);
  };

  const addThreshold = () => {
    setDaysBasedPricing([...daysBasedPricing, { noOfDays: 0, price: 0 }]);
  };

  const removeThreshold = (index) => {
    setDaysBasedPricing((prevThresholds) => {
      const newDaysBasePricing = [...prevThresholds];
      newDaysBasePricing.splice(index, 1);
      return newDaysBasePricing;
    });
  };

  const handleChangeStage = (item) => {
    setFacilityStages((prevStages) =>
      prevStages.map((stage) =>
        stage.value === item.value
          ? { ...stage, isChecked: !stage.isChecked }
          : stage
      )
    );
  };

  const handleAttendeesInfoChange = (e) => {
    const attendeesInfoRequired = e.target.checked;
    setShowSelectionRowAttendees(attendeesInfoRequired);
    if (!attendeesInfoRequired) {
      setShowAttendeesInfoFields(false);
    }
  };

  const handleStudentListChange = (e) => {
    setErrosForAteendeesInformation("");

    const studentList = e.target.checked;
    if (studentList && addFacilityForm.getFieldValue("manual")) {
      setShowAttendeesInfoFields(true);
    } else {
      setShowAttendeesInfoFields(addFacilityForm.getFieldValue("manual"));
    }
  };

  const handleManualChange = (e) => {
    setErrosForAteendeesInformation("");

    const manual = e.target.checked;
    if (manual && addFacilityForm.getFieldValue("students")) {
      setShowAttendeesInfoFields(true);
    } else {
      setShowAttendeesInfoFields(manual);
      setSelectedAttendeeFields([]);
    }
  };
  const handleAttendeeFieldsChange = (selectedFields) => {
    setSelectedAttendeeFields(selectedFields);
  };

  const validateParentNonZero = (_, value) => {
    const parentHours =
      parseInt(addFacilityForm.getFieldValue("parent_hours"), 10) || 0;
    const parentMinutes =
      parseInt(addFacilityForm.getFieldValue("parent_minutes"), 10) || 0;

    if (parentHours === 0 && parentMinutes === 0) {
      return Promise.reject(new Error("Both hours and minutes cannot be 0"));
    }

    return Promise.resolve();
  };

  const validateAdminNonZero = (_, value) => {
    const adminHours =
      parseInt(addFacilityForm.getFieldValue("admin_hours"), 10) || 0;
    const adminMinutes =
      parseInt(addFacilityForm.getFieldValue("admin_minutes"), 10) || 0;

    if (adminHours === 0 && adminMinutes === 0) {
      return Promise.reject(new Error("Both hours and minutes cannot be 0"));
    }

    return Promise.resolve();
  };
  return (
    <div className="faclity__container">
      <Spin spinning={loading} indicator={renderLoader}>
        <Card className="antCardBorder antCard">
          <h5>Edit Facility</h5>
          <input
            multiple
            ref={fileInputRef}
            type="file"
            onChange={onFileSelected}
            name="coverImage"
            required={!files.length === 0}
            style={{ marginLeft: 10, display: "none" }}
            accept="image/png, image/gif, image/jpeg , video/mp4, video/mpeg, video/quicktime, video/x-msvideo, video/x-ms-wmv"
          />
          <Row gutter={10} className="faclity__gallery__view" align={"middle"}>
            <Col md={16}>
              <div className="images__container">
                {files?.map((fileObj, index) => {
                  const mimeType = getMimeType(fileObj.fileUrl);
                  return (
                    <div className="image__container" key={index}>
                      <div className="image__overlay">
                        <div className="image__remove">
                          <CloseCircleOutlined
                            onClick={() => onRemoveFileFromGallery(index)}
                          />
                        </div>
                        <div className="video_play_icon">
                          {(mimeType.startsWith("video/") ||
                            (fileObj.isNew &&
                              fileObj.file.type.startsWith("video/"))) && (
                            <PlayCircleOutlined />
                          )}
                        </div>
                      </div>
                      {fileObj.isNew ? (
                        <>
                          {fileObj.file.type.startsWith("image/") ? (
                            <img
                              src={URL.createObjectURL(fileObj.file)}
                              alt={`New Image ${index}`}
                            />
                          ) : (
                            <div className="video__wrapper">
                              <video controls={false}>
                                <source
                                  src={URL.createObjectURL(fileObj.file)}
                                  type={fileObj.file.type}
                                />
                              </video>
                            </div>
                          )}
                        </>
                      ) : mimeType.startsWith("image/") ? (
                        <img src={fileObj.fileUrl} alt={`Image ${index}`} />
                      ) : (
                        <div className="video__wrapper">
                          <video controls={false}>
                            <source src={fileObj.fileUrl} />
                          </video>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col md={8}>
              <div className="upload__gallery__container">
                <Button
                  disabled={files.length === 5}
                  type="primary"
                  className="ant__primary__button"
                  onClick={(e) => onChooseFile(e)}
                >
                  {"+ Upload Gallery"}
                </Button>
              </div>
            </Col>
          </Row>
          <br />
          <Form layout="vertical" form={addFacilityForm} onFinish={onSubmit}>
            <Row gutter={20}>
              <Col md={8}>
                <Form.Item
                  label={"Title (EN)"}
                  name={"title"}
                  rules={setRules("Title (EN)")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label={"Title (AR)"}
                  name={"titleAr"}
                  rules={setRules("Title (AR)")}
                >
                  <Input placeholder="Add here" type="text" dir="rtl" />
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24}>
                <Form.Item
                  label={"Select Campus"}
                  name={"campusId"}
                  rules={setRules("Campus")}
                >
                  <Select
                    placeholder="Select"
                    onChange={(e) => setSelectedCampusId(e)}
                  >
                    {FSM_campuses.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.code}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col lg={8} md={12} sm={24}>
                <Form.Item
                  label={"Select Facility Feature"}
                  name={"features"}
                  rules={setRules("Category")}
                >
                  <Select placeholder="Select" mode="multiple">
                    {FSM_features.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24}>
                <Form.Item
                  label={"Select Category"}
                  name={"categoryId"}
                  rules={setRules("Category")}
                >
                  <Select
                    placeholder="Select"
                    onChange={(e) => setSelectedCategoryId(e)}
                  >
                    {FSM_categories.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24}>
                <Form.Item
                  label={"Select Sub Category"}
                  name={"subCategoryId"}
                  rules={setRules("Sub Category")}
                >
                  <Select placeholder="Select" disabled={!selectedCategoryId}>
                    {selectedCategoryId &&
                      FSM_categories.find(
                        (cat) => cat.id === selectedCategoryId
                      )?.children.map((el) => (
                        <Option value={el.id} key={el.id}>
                          {el.title}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col lg={8} md={12} sm={24}>
                <Form.Item
                  label={"Allowed Activity Types"}
                  name={"allowedActivities"}
                  rules={setRules("Event")}
                >
                  <Select placeholder="Select Event" mode="multiple">
                    {FSM_activityTypes.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24}>
                <Form.Item label={"VAT"} name={"vatId"} rules={setRules("Vat")}>
                  <Select placeholder="Select VAT">
                    {FSM_vats.map((vat) => (
                      <Option value={vat.id} key={vat.id}>
                        {vat.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12} sm={24}>
                <Form.Item label={"Blocked By Facility"} name={"blockedById"}>
                  <Select placeholder="Select">
                    {facilities.map((el, i) => (
                      <Option value={el.id} key={i}>
                        {el.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label={"Color Code"} rules={setRules("Color Code")}>
                  <InputColor
                    initialValue={initialColor}
                    onChange={setColor}
                    placement="right"
                  />

                  <p>{color.hex}</p>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={8}>
                <Form.Item
                  label={"Capacity"}
                  name={"capacity"}
                  rules={setRules("Capacity")}
                >
                  <Input placeholder="Add here" type="number" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label={"ERP integration customer ID"}
                  name={"erpIntegrationId"}
                  rules={setRules("ERP integration customer ID")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label={"Service ID"}
                  name={"serviceId"}
                  rules={setRules("service ID")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={12}>
                <Form.Item
                  label={"Physical Address"}
                  name={"physicalAddress"}
                  rules={setRules("Physical Address")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={"Map Location URL"}
                  name={"mapLocation"}
                  rules={setRules("Map Location URL")}
                >
                  <Input placeholder="Add here" type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <p className="from__heading">Add Map location Image</p>
                <Row align="bottom" gutter={[10]}>
                  <Col>
                    {
                      <Image
                        width={"4rem"}
                        style={{ margin: "5px 0px" }}
                        src={locationImage ?? placeholderImage}
                      />
                    }
                  </Col>

                  <input
                    type="file"
                    onChange={(e) => onChooseLocationFile(e)}
                    name="image"
                    required={!locationImage}
                    style={{ marginLeft: 10 }}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <br />
                </Row>
              </Col>
            </Row>

            <br />
            <br />

            <Row gutter={20}>
              <Col md={12}>
                <p className="from__heading">Parent Booking Expiry</p>
                <Row gutter={20}>
                  <Col md={12}>
                    <Form.Item
                      label={"Hours"}
                      name={"parent_hours"}
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                        {
                          validator: validateParentNonZero,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Add here"
                        type="number"
                        min={0}
                        step={1} // This restricts input to integers
                        onKeyPress={(e) => {
                          // Prevents the user from typing decimal points
                          if (e.key === "." || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onFocus={() => {
                          addFacilityForm.setFields([
                            { name: "parent_minutes", errors: [] },
                          ]);
                          addFacilityForm.setFields([
                            { name: "parent_hours", errors: [] },
                          ]);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      label={"Minutes"}
                      name={"parent_minutes"}
                      rules={[
                        { required: true, message: "Field is required" },

                        { validator: validateParentNonZero },
                      ]}
                    >
                      <Input
                        placeholder="Add here"
                        type="number"
                        min={0}
                        step={1} // This restricts input to integers
                        max={59}
                        onKeyPress={(e) => {
                          // Prevents the user from typing decimal points
                          if (e.key === "." || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onFocus={() => {
                          addFacilityForm.setFields([
                            { name: "parent_minutes", errors: [] },
                          ]);
                          addFacilityForm.setFields([
                            { name: "parent_hours", errors: [] },
                          ]);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <p className="from__heading">Admin Booking Expiry</p>
                <Row gutter={20}>
                  <Col md={12}>
                    <Form.Item
                      label={"Hours"}
                      name={"admin_hours"}
                      rules={[
                        {
                          required: true,
                          message: "Field is required",
                        },
                        {
                          validator: validateAdminNonZero,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Add here"
                        type="number"
                        min={0}
                        step={1} // This restricts input to integers
                        onKeyPress={(e) => {
                          // Prevents the user from typing decimal points
                          if (e.key === "." || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onFocus={() => {
                          addFacilityForm.setFields([
                            { name: "admin_minutes", errors: [] },
                          ]);
                          addFacilityForm.setFields([
                            { name: "admin_hours", errors: [] },
                          ]);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      label={"Minutes"}
                      name={"admin_minutes"}
                      rules={[
                        { required: true, message: "Field is required" },

                        { validator: validateAdminNonZero },
                      ]}
                    >
                      <Input
                        placeholder="Add here"
                        type="number"
                        min={0}
                        step={1} // This restricts input to integers
                        max={59}
                        onKeyPress={(e) => {
                          // Prevents the user from typing decimal points
                          if (e.key === "." || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onFocus={() => {
                          addFacilityForm.setFields([
                            { name: "admin_minutes", errors: [] },
                          ]);
                          addFacilityForm.setFields([
                            { name: "admin_hours", errors: [] },
                          ]);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <br />

            <Row gutter={20}>
              <Col md={6}>
                <Form.Item
                  name={"hideCancellationPolicy"}
                  valuePropName={"checked"}
                >
                  <Checkbox>Hide Cancellation Policy</Checkbox>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item name={"hideTerms"} valuePropName={"checked"}>
                  <Checkbox>Hide Terms & Conditions</Checkbox>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item name={"hideGuidelines"} valuePropName={"checked"}>
                  <Checkbox>Hide Guidelines</Checkbox>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item name={"hideQrCode"} valuePropName={"checked"}>
                  <Checkbox>Hide Qr Code</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col md={6}>
                <Form.Item name={"isOpen"} valuePropName={"checked"}>
                  <Checkbox>Is Facility Open for Booking?</Checkbox>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item name={"vatIncluded"} valuePropName={"checked"}>
                  <Checkbox>VAT Included?</Checkbox>
                </Form.Item>
              </Col>
              <Col md={6}>
                <div style={{ height: 5 }} />
                <Form.Item name={"specifyDays"} valuePropName={"checked"}>
                  <Checkbox>Enabale Specify Days?</Checkbox>
                </Form.Item>
                <div style={{ marginLeft: 25, marginTop: -35 }}>
                  <small>(For LTB Booking)</small>
                </div>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col md={6}>
                <Form.Item
                  name={"attendeesInfoRequired"}
                  valuePropName={"checked"}
                >
                  <Checkbox onChange={handleAttendeesInfoChange}>
                    Attendees info required
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            {showSelectionRowForAttendees && (
              <>
                <p className="from__heading">
                  Attendees Information{" "}
                  <small>(Please provide the required details)</small>
                </p>
                <Row gutter={20}>
                  <Col md={6}>
                    <Form.Item name={"students"} valuePropName={"checked"}>
                      <Checkbox onChange={handleStudentListChange}>
                        Use Student List
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item name={"manual"} valuePropName={"checked"}>
                      <Checkbox onChange={handleManualChange}>
                        Manual Selection
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  {showAttendeesInfoFields && (
                    <Col md={8}>
                      <Form.Item
                        label={"Select Attendee Information Fields"}
                        name={"studentFields"}
                        initialValue={[]}
                      >
                        <Select
                          placeholder="Select Fields"
                          mode="multiple"
                          onChange={handleAttendeeFieldsChange}
                        >
                          <Option value="firstName" disabled>
                            First Name
                          </Option>
                          <Option value="lastName">Last Name</Option>
                          <Option value="email">Email</Option>
                          <Option value="gender">Gender</Option>
                          <Option value="dob">Date of Birth</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <p style={{ color: "red", marginTop: -20 }}>
                  {errosForAteendeesInformation}
                </p>
              </>
            )}
            <br />

            <p className="from__heading">
              Availabe For <small>(Who can book the facility?)</small>
            </p>

            <Row gutter={20}>
              <Col md={6}>
                <Form.Item
                  name={"availableForParent"}
                  valuePropName={"checked"}
                  rules={setRules("Available for")}
                >
                  <Checkbox>Parent</Checkbox>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  name={"availableForNoneParent"}
                  valuePropName={"checked"}
                >
                  <Checkbox>Non Parents</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <p className="from__heading">Allowed Stages</p>
            <Row gutter={[20, 20]}>
              {faciltyStages.map((stage, index) => {
                return (
                  <React.Fragment key={index}>
                    <Col md={6}>
                      <Checkbox
                        checked={stage.isChecked}
                        onChange={() => handleChangeStage(stage)}
                        disabled={
                          stage.value === "payment" ||
                          stage.value === "cancellation_and_refunds"
                        }
                      >
                        {stage.title}
                      </Checkbox>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
            <br />

            <p className="from__heading">Allowed Booking Types</p>
            <Row gutter={[20, 20]}>
              {_bookingRequestTypes.map((reType, index) => {
                return (
                  <React.Fragment key={index}>
                    <Col md={6}>
                      <Checkbox
                        checked={selectedBookingTypes.includes(
                          reType.typeIdentifier
                        )}
                        onChange={() =>
                          handleCheckboxForBookingTypes(reType.typeIdentifier)
                        }
                      >
                        {reType.title}
                      </Checkbox>
                    </Col>
                  </React.Fragment>
                );
              })}
            </Row>
            <br />

            <div className="card__snippet">
              <p className="from__heading" style={{ fontSize: 15 }}>
                Pricing
              </p>

              <p className="from__notes" style={{ fontSize: 14 }}>
                Prices listed are for hourly slots. For 30-minute slots, the
                price will be 50% of the listed price.
              </p>

              <br />

              <Row gutter={20}>
                {selectedBookingTypes.map((selectedType) => {
                  const selectedPricing = pricing.find(
                    (item) => item.typeIdentifier === selectedType
                  );

                  const showFullDayPriceInput = selectedType === "EVB";
                  const showFullDayPriceInputForLTB = selectedType === "LTB";

                  return (
                    <Col md={24} key={selectedType}>
                      <div className="facility_pricing_slots_container">
                        <p className="facility_pricing_slot_heading">
                          {renderFacilityTitleFromIndentifer(selectedType)}
                        </p>
                        <Row gutter={20}>
                          <Col md={8}>
                            <Checkbox
                              checked={selectedPricing?.autoApprove || false}
                              onChange={(e) =>
                                handleAutoApproval(e, selectedType)
                              }
                            >
                              Auto Approval
                            </Checkbox>
                          </Col>
                          {selectedType === "LTB" && (
                            <>
                              <Col md={8}>
                                <Checkbox
                                  checked={
                                    selectedPricing?.allowMultipleSlots || false
                                  }
                                  onChange={(e) =>
                                    handleAllowMultipleSlots(e, selectedType)
                                  }
                                >
                                  Allow Multiple Slots Bookings
                                </Checkbox>
                              </Col>
                              <Col md={8}>
                                <Checkbox
                                  checked={
                                    selectedPricing?.allowFullDay || false
                                  }
                                  onChange={(e) =>
                                    handleAllowFullDay(e, selectedType)
                                  }
                                >
                                  Allow Full Day Bookings
                                </Checkbox>
                              </Col>
                            </>
                          )}
                        </Row>
                        <br />
                        <Form.Item
                          label={
                            selectedType === "DPB"
                              ? renderFacilityFullDayFromIndentifer(
                                  selectedType
                                )
                              : renderFacilityPriceSlotFromIndentifer(
                                  selectedType
                                )
                          }
                          rules={setRules(
                            renderFacilityTitleFromIndentifer(selectedType) +
                              " " +
                              "Price"
                          )}
                        >
                          {showFullDayPriceInput ? (
                            <>
                              <Row gutter={20}>
                                <Col md={12}>
                                  <Input
                                    placeholder="Hourly Price"
                                    type="number"
                                    id={`${selectedType}_hourly`}
                                    value={selectedPricing?.hourlyPrice ?? ""}
                                    onChange={(e) =>
                                      e.target.value !== "0" &&
                                      handlePriceChange(
                                        e,
                                        selectedType,
                                        "hourly"
                                      )
                                    }
                                    min={0}
                                  />
                                  {emptyFieldErrors[
                                    `${selectedType}_hourly`
                                  ] && (
                                    <p style={{ color: "#ff4d4f" }}>
                                      {`This field is required`}
                                    </p>
                                  )}
                                </Col>
                                <Col md={12}>
                                  <p className="lable_for_fullDay">
                                    {renderFacilityFullDayFromIndentifer(
                                      selectedType
                                    )}
                                  </p>
                                  <Input
                                    placeholder="Full Day Price"
                                    type="number"
                                    id={`${selectedType}_fullDay`}
                                    value={selectedPricing?.fullDayPrice ?? ""}
                                    onChange={(e) =>
                                      e.target.value !== "0" &&
                                      handlePriceChange(
                                        e,
                                        selectedType,
                                        "fullDay"
                                      )
                                    }
                                    min={0}
                                  />
                                  {emptyFieldErrors[
                                    `${selectedType}_fullDay`
                                  ] && (
                                    <p style={{ color: "#ff4d4f" }}>
                                      {`This field is required`}
                                    </p>
                                  )}
                                </Col>
                              </Row>
                              <br />
                              <br />
                              <Row gutter={20}>
                                <Col md={12}>
                                  <p className="lable_for_fullDay">
                                    Grace Period (Days)
                                  </p>
                                  <Input
                                    placeholder="Add here"
                                    type="number"
                                    id={`${selectedType}_gracePeriod_days`}
                                    value={
                                      selectedPricing?.gracePeriodDays ?? ""
                                    }
                                    onChange={(e) =>
                                      handleChangeGracePeriod(
                                        e,
                                        selectedType,
                                        "gracePeriodDays"
                                      )
                                    }
                                    min={0}
                                    step={1} // This restricts input to integers
                                    onKeyPress={(e) => {
                                      // Prevents the user from typing decimal points
                                      if (e.key === "." || e.key === "-") {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {/* {emptyFieldErrors[
                                    `${selectedType}_gracePeriod_days`
                                  ] && (
                                    <p style={{ color: "#ff4d4f" }}>
                                      {`This field is required`}
                                    </p>
                                  )} */}
                                </Col>
                              </Row>
                            </>
                          ) : showFullDayPriceInputForLTB &&
                            selectedPricing?.allowFullDay ? (
                            <>
                              <Row gutter={20}>
                                <Col md={11}>
                                  <Input
                                    placeholder="Add here"
                                    type="number"
                                    id={`${selectedType}_hourly`}
                                    value={selectedPricing?.hourlyPrice ?? ""}
                                    onChange={(e) =>
                                      e.target.value !== "0" &&
                                      handlePriceChange(
                                        e,
                                        selectedType,
                                        "hourly"
                                      )
                                    }
                                    // min={1}
                                  />
                                  {emptyFieldErrors[
                                    `${selectedType}_hourly`
                                  ] && (
                                    <p style={{ color: "#ff4d4f" }}>
                                      {`This field is required`}
                                    </p>
                                  )}
                                </Col>
                                <Col md={11}>
                                  <p className="lable_for_fullDay">
                                    {" "}
                                    Grace Period (Days)
                                  </p>
                                  <Input
                                    placeholder="Add here"
                                    type="number"
                                    id={`${selectedType}_gracePeriod_days`}
                                    value={
                                      selectedPricing?.gracePeriodDays ?? ""
                                    }
                                    onChange={(e) =>
                                      handleChangeGracePeriod(
                                        e,
                                        selectedType,
                                        "gracePeriodDays"
                                      )
                                    }
                                    min={0}
                                    step={1} // This restricts input to integers
                                    onKeyPress={(e) => {
                                      // Prevents the user from typing decimal points
                                      if (e.key === "." || e.key === "-") {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {/* {emptyFieldErrors[
                                    `${selectedType}_gracePeriod_days`
                                  ] && (
                                    <p style={{ color: "#ff4d4f" }}>
                                      {`This field is required`}
                                    </p>
                                  )} */}
                                </Col>
                              </Row>
                              <br />
                              <Row
                                justify="space-between"
                                style={{ paddingRight: 15 }}
                              >
                                <p
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Full Day Bookings Pricing Tiers
                                </p>
                                <Button
                                  className="d-flex justify-content-center align-items-center"
                                  shape="circle"
                                  icon={<PlusCircleOutlined />}
                                  onClick={addThreshold}
                                />
                              </Row>
                              {daysBasedPricing.map((threshold, index) => (
                                <React.Fragment key={index}>
                                  <Row gutter={10} key={index} align={"middle"}>
                                    <Col md={11}>
                                      <Form.Item
                                        extra="Enter the maximum number of days for this pricing tier"
                                        label="Days Threshold"
                                        help={errors[index]?.noOfDays}
                                        validateStatus={
                                          errors[index]?.noOfDays ? "error" : ""
                                        }
                                      >
                                        <Input
                                          type="number"
                                          placeholder="Add here"
                                          value={threshold?.noOfDays}
                                          onChange={(e) =>
                                            handleThresholdChange(
                                              e.target.value,
                                              "noOfDays",
                                              index
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col md={11}>
                                      <Form.Item
                                        label="Price Per Day"
                                        extra="Specify the price for bookings up to the days threshold"
                                        help={errors[index]?.price}
                                        validateStatus={
                                          errors[index]?.price ? "error" : ""
                                        }
                                      >
                                        <Input
                                          type="number"
                                          placeholder="Add here"
                                          value={threshold?.price}
                                          onChange={(e) =>
                                            handleThresholdChange(
                                              e.target.value,
                                              "price",
                                              index
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    {index !== 0 && (
                                      <Col md={2}>
                                        <div className="discount_remove__button">
                                          <Button
                                            className="d-flex justify-content-center align-items-center"
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() =>
                                              removeThreshold(index)
                                            }
                                          />
                                        </div>
                                      </Col>
                                    )}
                                  </Row>

                                  <Row>
                                    <Col md={22}>
                                      <div
                                        className="hr_row"
                                        style={{ marginLeft: 0 }}
                                      />
                                      <br />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              ))}
                              {/* <Row gutter={10}>
                                <Col md={11}>
                                  <Form.Item
                                    help={errors[index]?.notes}
                                    validateStatus={
                                      errors[index]?.notes ? "error" : ""
                                    }
                                    label={"Notes (EN)"}
                                    valuePropName={"data"}
                                    getValueFromEvent={(event, editor) =>
                                      editor ? editor.getData() : ""
                                    }
                                  >
                                    <CKEditor
                                      config={{
                                        language: "en",
                                        //toolbar: EditorToolbar,
                                      }}
                                      editor={ClassicEditor}
                                      key={"notes"}
                                      data={selectedPricing?.notes}
                                      onChange={(event, editor) =>
                                        handleCKEditorChange(
                                          index,
                                          "notes",
                                          editor.getData()
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={11}>
                                  <Form.Item
                                    label={"Notes (AR)"}
                                    help={errors[index]?.notesAr}
                                    validateStatus={
                                      errors[index]?.notesAr ? "error" : ""
                                    }
                                    valuePropName={"data"}
                                    getValueFromEvent={(event, editor) =>
                                      editor ? editor.getData() : ""
                                    }
                                  >
                                    <CKEditor
                                      config={{
                                        language: "ar",
                                        //toolbar: EditorToolbar,
                                      }}
                                      editor={ClassicEditor}
                                      data={selectedPricing?.notesAr}
                                      // onChange={(event, editor) =>
                                      //   handleCKEditorChange(
                                      //     index,
                                      //     "notesAr",
                                      //     editor.getData()
                                      //   )
                                      // }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row> */}
                              <Row gutter={10}>
                                <Col md={11}>
                                  <Form.Item
                                    label={"Notes (EN)"}
                                    name={"notes"}
                                    valuePropName={"data"}
                                    getValueFromEvent={(event, editor) =>
                                      editor ? editor.getData() : ""
                                    }
                                    rules={setRules("Notes (EN)")}
                                  >
                                    <CKEditor
                                      config={{
                                        language: "en",
                                        //toolbar: EditorToolbar,
                                      }}
                                      editor={ClassicEditor}
                                      key={"notes"}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={11}>
                                  <Form.Item
                                    label={"Notes (AR)"}
                                    name={"notesAr"}
                                    valuePropName={"data"}
                                    getValueFromEvent={(event, editor) =>
                                      editor ? editor.getData() : ""
                                    }
                                    rules={setRules("Notes (AR)")}
                                  >
                                    <CKEditor
                                      config={{
                                        language: "ar",
                                        //toolbar: EditorToolbar,
                                      }}
                                      editor={ClassicEditor}
                                      key={"notesAr"}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <Row gutter={20}>
                                <Col md={12}>
                                  <Input
                                    placeholder="Add here"
                                    type="number"
                                    id={selectedType}
                                    value={
                                      selectedType === "DPB"
                                        ? selectedPricing?.fullDayPrice
                                        : selectedPricing?.hourlyPrice ?? ""
                                    }
                                    onChange={(e) => {
                                      e.target.value !== "0" &&
                                        handlePriceChange(
                                          e,
                                          selectedType,
                                          selectedType === "DPB"
                                            ? "fullDay"
                                            : "hourly"
                                        );
                                    }}
                                    min={0}
                                  />
                                  {emptyFieldErrors[
                                    `${selectedType}_${
                                      selectedType === "DPB"
                                        ? "fullDay"
                                        : "hourly"
                                    }`
                                  ] && (
                                    <p style={{ color: "#ff4d4f" }}>
                                      {`This field is required`}
                                    </p>
                                  )}
                                </Col>
                                <Col md={12}>
                                  <p className="lable_for_fullDay">
                                    {" "}
                                    Grace Period (Days)
                                  </p>
                                  <Input
                                    placeholder="Add here"
                                    type="number"
                                    id={`${selectedType}_gracePeriod_days`}
                                    value={
                                      selectedPricing?.gracePeriodDays ?? ""
                                    }
                                    onChange={(e) =>
                                      handleChangeGracePeriod(
                                        e,
                                        selectedType,
                                        "gracePeriodDays"
                                      )
                                    }
                                    min={0}
                                    step={1} // This restricts input to integers
                                    onKeyPress={(e) => {
                                      // Prevents the user from typing decimal points
                                      if (e.key === "." || e.key === "-") {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {/* {emptyFieldErrors[
                                    `${selectedType}_gracePeriod_days`
                                  ] && (
                                    <p style={{ color: "#ff4d4f" }}>
                                      {`This field is required`}
                                    </p>
                                  )} */}
                                </Col>
                              </Row>
                            </>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
            <br />
            {selectedSchedules?.length === 0 && (
              <Row
                gutter={10}
                className="faclity__gallery__view"
                align={"middle"}
              >
                <Col md={16}>
                  <p style={{ marginLeft: 20 }}>Facility Schedule</p>
                </Col>
                <Col md={8}>
                  {!selectedSchedules?.length && (
                    <div className="upload__gallery__container">
                      <Button
                        type="primary"
                        className="ant__primary__button"
                        onClick={() => toggleModal(true)}
                      >
                        {"Add Schedule"}
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            )}
            <br />
            {selectedSchedules?.length > 0 && (
              <React.Fragment>
                <p className="from__heading">Facility Schedule</p>
                <br />
                <div className="card__snippet">
                  <div className="schedule__delet__icon">
                    <Button
                      onClick={onupdateFacilitySchedule}
                      className="d-flex justify-content-center align-items-center"
                      shape="circle"
                      icon={<EditOutlined style={{ color: "#F97878" }} />}
                    />
                  </div>
                  {renderScheduleData()}
                </div>
              </React.Fragment>
            )}
            <br />

            {/* {selectedBlockSchedules?.length > 0 && (
              <React.Fragment>
                <p className="from__heading">Block Schedule</p>
                <br />
                <div className="card__snippet">
                  <div className="schedule__delet__icon">
                    <Button
                      onClick={onupdateFacilitySchedule}
                      className="d-flex justify-content-center align-items-center"
                      shape="circle"
                      icon={<EditOutlined style={{ color: "#F97878" }} />}
                    />
                  </div>
                  {renderBlockScheduleData()}
                </div>
              </React.Fragment>
            )} */}
            <br />
            <Form.Item
              label={"Description (EN)"}
              name={"description"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "en",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"requestTypeGuidelinesAR"}
              />
            </Form.Item>
            <Form.Item
              label={"Description (AR)"}
              name={"descriptionAr"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "ar",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"requestTypeGuidelinesAR"}
              />
            </Form.Item>
            <Form.Item
              label={"Terms & Conditions"}
              name={"terms"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                editor={ClassicEditor}
                config={
                  {
                    //toolbar: EditorToolbar,
                  }
                }
                key={"requestTypeTC"}
              />
            </Form.Item>
            <Form.Item
              label={"Terms & Conditions (AR)"}
              name={"termsAr"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "ar",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"requestTypeTCAR"}
              />
            </Form.Item>
            <Form.Item
              label={"Guidelines (EN)"}
              name={"guidelines"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={
                  {
                    //toolbar: EditorToolbar,
                  }
                }
                editor={ClassicEditor}
                key={"requestTypeGuidelines"}
              />
            </Form.Item>
            <Form.Item
              label={"Guidelines (AR)"}
              name={"guidelinesAr"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "ar",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"requestTypeGuidelinesAR"}
              />
            </Form.Item>
            <Form.Item
              label={"Cancellation Terms (EN)"}
              name={"cancellationTerms"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={
                  {
                    //toolbar: EditorToolbar,
                  }
                }
                editor={ClassicEditor}
                key={"cancellationTerms"}
              />
            </Form.Item>
            <Form.Item
              label={"Cancellation Terms (AR)"}
              name={"cancellationTermsAr"}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "ar",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"cancellationTerms"}
              />
            </Form.Item>
            <Row justify="end">
              <Button
                className="ant__primary__button"
                onClick={handleCancelButton}
              >
                Cancel
              </Button>
              <div style={{ width: 30 }} />

              <Button
                htmlType="submit"
                // loading={loading || uploading}
                type="primary"
                className="ant__primary__button"
              >
                {loading ? "Saving..." : "Update"}
              </Button>
            </Row>
          </Form>
        </Card>
      </Spin>
      <CustomModal
        visibility={showModal}
        handleCancel={onCloseForm}
        handleOk={() => null}
        title={`${modalTitle} Schedule`}
      >
        <FSMScheduleTabs
          onTab1={() => setSelectdScheduleTab(0)}
          onTab2={() => setSelectdScheduleTab(1)}
          selectedTab={selectdScheduleTab}
        />
        <br />
        {renderOpenAndBlockSchedules(selectdScheduleTab)}
      </CustomModal>
    </div>
  );
};

export default EditFacility;
