import client from "apis/config";

export const fetchAllFsmFacilitySchedules = async ({
  limit = 10,
  page = 1,
  campus = "",
  facility = "",
  startAt = "",
  endAt,
}) => {
  return await client.get(
    `/facilities/schedules?limit=${limit}&page=${page}&campus=${campus}&facility=${facility}&startAt=${startAt}&endAt=${endAt}`
  );
};

export const addNewFacilitySchedule = async ({ data }) => {
  return await client.post("/facilities/schedules", data);
};

export const updateFacilitySchedule = async ({ id, data }) => {
  return await client.put(`/facilities/schedules/${id}`, data);
};

export const deleteFacilitySchedule = async ({ id }) => {
  return await client.delete(`/facilities/schedules/${id}`);
};
