import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Col, Form, Image, Input, Row, Tooltip } from "antd";
import React from "react";
import { useState } from "react";
import { Capitalize } from "utils/helpers/helpers";

const ASRFormItem = ({
  disabled,
  formElement,
  onChangeItem,
  formItemName,
  serviceType,
}) => {
  const [values, setValues] = useState({
    price: formElement.price,
    quantity: formElement.quantity,
  });
  const [editPrice, enableEditPrice] = useState(false);
  const [editQty, enableEditQty] = useState(false);

  const editDisabled = disabled || editPrice || editQty;

  const onClickToEdit = (valueName) => (e) => {
    if (editDisabled || (valueName === "price" && serviceType === "free"))
      return;
    if (e.detail === 3) {
      valueName === "price" ? enableEditPrice(true) : enableEditQty(true);
    }
  };
  const onCancelEdit = (valueName) => () => {
    if (valueName === "price") enableEditPrice(false);
    else enableEditQty(false);
  };
  const onDoneEdit = (valueName, value) => () => {
    if (Number(value) > 0) {
      onChangeItem(valueName, value);
      valueName === "price" ? enableEditPrice(false) : enableEditQty(false);
    }
  };
  const onChangeValue = (valueName) => (e) => {
    setValues({
      ...values,
      [valueName]: e.target.value,
    });
  };
  const renderEditableField = (valueName) => {
    const showInputBox = valueName === "price" ? editPrice : editQty;
    return (
      <div>
        {showInputBox ? (
          <Row align="middle">
            <span>{Capitalize(valueName)}: </span>
            <Input
              onChange={onChangeValue(valueName)}
              value={values[valueName]}
              suffix={
                <Row align="middle">
                  <Tooltip title={"Done"}>
                    <CheckCircleOutlined
                      onClick={onDoneEdit(valueName, values[valueName])}
                      className={"ensBrandColor"}
                    />
                  </Tooltip>
                  <Tooltip title={"Cancel"}>
                    <CloseCircleOutlined
                      onClick={onCancelEdit(valueName)}
                      style={{ marginLeft: 8 }}
                      className={"ensBrandColor"}
                    />
                  </Tooltip>
                </Row>
              }
              type={"number"}
            />
          </Row>
        ) : (
          <Row
            justify="space-between"
            align="middle"
            onClick={onClickToEdit(valueName)}
          >
            <span>
              {Capitalize(valueName)}: {formElement[valueName]}{" "}
              {valueName === "price" ? "AED" : ""}
            </span>
            {!disabled && (
              <Tooltip
                title={
                  editDisabled ||
                  (valueName === "price" && serviceType === "free")
                    ? ""
                    : `Triple click to edit the ${valueName}`
                }
              >
                {!(valueName === "price" && serviceType === "free") && (
                  <EditOutlined
                    className="ensBrandColor"
                    style={{ marginLeft: 8 }}
                  />
                )}
              </Tooltip>
            )}
          </Row>
        )}
        <Form.Item
          style={{ display: "none" }}
          name={[formItemName, valueName]}
          label={formElement?.title}
        >
          <Input style={{}} placeholder="" />
        </Form.Item>
      </div>
    );
  };
  return (
    <Row gutter={[20]} style={{marginTop: 50}}>
      <Col md={10}>
        <Image  src={formElement.image} />
      </Col>
      <Col md={12}>
        <strong>{formElement.title}</strong>
        <br />
        <span>
          {Capitalize(formElement.variantType)}:{" "}
          {Capitalize(formElement.variant)}
        </span>
        {/* <br /> */}
        {renderEditableField("price")}
        {renderEditableField("quantity")}
      </Col>
    </Row>
  );
};
export default ASRFormItem;
