import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { EditorToolbar } from "utils/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
const { Option } = Select;
const TCCTeacherForm = ({
  onSubmit,
  loading,
  formName,
  tcc_schools,
  gendersDropDowns,
}) => {
  const _faqsCategories = useSelector(
    (state) => state.faqsCategories.faqsCategories
  );
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };
  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"First Name"}
              name={"firstName"}
              rules={setRules("First Name")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"First Name (AR)"}
              name={"firstNameAr"}
              rules={setRules("First Name (AR)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Middle Name"}
              name={"middleName"}
              rules={setRules("Middle Name")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Middle Name (AR)"}
              name={"middleNameAr"}
              rules={setRules("Middle Name (AR)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Last Name"}
              name={"lastName"}
              rules={setRules("Last Name")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Last Name (AR)"}
              name={"lastNameAr"}
              rules={setRules("Last Name (Ar)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"email"}
              rules={setRules("Email Address")}
              label={"Email Address"}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name={"phone"}
              rules={setRules("Phone Number")}
              label={"Phone Number"}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"employeeId"}
              rules={setRules("Employee Id")}
              label={"Employee Id"}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name={"schoolId"}
              rules={setRules("School")}
              label={"School"}
            >
              <Select placeholder="Select School">
                {tcc_schools?.map((el, i) => (
                  <Option key={el.id} value={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              name={"gender"}
              rules={setRules("Gender")}
              label={"Gender"}
            >
              <Select placeholder="Select Gender">
                {gendersDropDowns?.map((el, i) => (
                  <Option key={el.id} value={el.value}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button htmlType="submit" loading={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default TCCTeacherForm;
