import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./style.module.css";

export default function TableHeader({
  onAddNew,
  headerTitle,
  headerSubTitle,
  headerBtnTitle,
  hideBtnIcon,
  disabled = false,
  hasCreationAccess = true,
}) {
  if (!hasCreationAccess) {
    return (
      <div className={styles.tableHeaderWrapper}>
        <div>
          <h4>{headerTitle ? headerTitle : "Applications"}</h4>
          <p className={styles.tableHeaderSubTitle}>{headerSubTitle || ""}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.tableHeaderWrapper}>
        <div>
          <h4>{headerTitle ? headerTitle : "Applications"}</h4>
          <p className={styles.tableHeaderSubTitle}>{headerSubTitle || ""}</p>
        </div>

        {headerBtnTitle !== "" && (
          <Button
            className={styles.headerActionBtn}
            type="primary"
            icon={hideBtnIcon ? undefined : <PlusOutlined />}
            size={"large"}
            onClick={onAddNew}
            disabled={disabled}
          >
            {headerBtnTitle ? headerBtnTitle : ""}
          </Button>
        )}
      </div>
    );
  }
}
