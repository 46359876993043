// IndicatorCaseTable.js

import React from "react";
import { Table } from "antd";
import TitleWithLine from "../title-with-line/TitleWithLine";

const IndicatorCaseTable = ({ data, title }) => {
  // Check if the data is present and has the necessary structure
  if (!data || !data.indicatorsCases || !Array.isArray(data.indicatorsCases)) {
    return <div>No data available</div>;
  }

  // Destructure indicatorsCases and months from the provided data
  const { indicatorsCases, months } = data;

  // Define columns for the table
  const columns = [
    {
      title: "Title",
      dataIndex: "indicator",
      key: "indicator",
    },
    ...months.map((month) => ({
      title: month,
      dataIndex: month,
      key: month,
    })),
  ];

  // Define data for the table
  const tableData = indicatorsCases.map((indicator) => {
    const row = {
      key: indicator.indicator,
      indicator: indicator.indicator,
    };

    // Add a case count for each month
    months.forEach((month) => {
      const monthData = indicator.months.find((m) => m.month === month);
      row[month] = monthData ? monthData.cases : 0;
    });

    return row;
  });

  const hasData = tableData.length > 0;

  // Conditionally set columns and data
  const tableColumns = hasData ? columns : [];

  return (
    <>
      <div style={tableContainerStyle}>
        <div style={headerStyle}>
          <TitleWithLine title={title} />
        </div>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}
          bordered
          size="small"
          rowKey="school"
        />
      </div>
    </>
  );
};
// Inline styles
const headerStyle = {
  textAlign: "center",
  padding: "1px 0",
  backgroundColor: "#f5f5f5",
  boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
  borderRadius: "7px 7px 0 0", // Round the top corners
};
const tableContainerStyle = {
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow to table
  borderRadius: "0 0 7px 7px", // Round the bottom corners
  overflow: "hidden", // Prevent overflow of shadow
};

export default IndicatorCaseTable;
