/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Row,
  Col,
  Card,
  Button,
  Tabs,
  Form,
  Spin,
  Popconfirm,
  Select,
  DatePicker,
  Input,
  Checkbox,
} from "antd";
import { applicationFeedback } from "apis/applications-apis/applications";

import axios from "axios";
import checkedImg from "../../../../assets/img/checked.png";
import uncheckedImg from "../../../../assets/img/unchecked.png";
import CustomModal from "components/CustomModal/CustomModal";
import CreateNewDocumentListForm from "../../../../components/Forms/CreateNewDocumentListForm";
import styles from "styles/applicationDetails.module.css";

import ApplicationInformationForm from "components/Forms/ApplicationInformationForm";

import { _updateApplications } from "actions/applications/applications-actions";

import {
  getFileTypeFromUrl,
  handleUploadFileToENSServer,
  renderLoader,
  renderNoDataPlaceholder,
  requestErrorHandel,
  showValidationError,
  successMessage,
} from "../../../../utils/helpers/helpers";
import { applicationProcess } from "apis/applications-apis/applications";
import FormGroupItemForDocuments from "components/FormGroupItemForDocuemnts/FormGroupItemForDocuments";
import {
  addAdditionalDocumentsAPI,
  documentsFeedback,
} from "apis/applications-apis/applications";
import { deleteAdditionalDocumentAPI } from "apis/applications-apis/applications";
import { getSignUrl } from "apis/get-sign-url/getSignUrl";
import { _deleteAdditionalDocuments } from "actions/applications/applications-actions";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import { NotificationManager } from "react-notifications";
import { getApplicationScholls } from "apis/applications-apis/applications";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import moment from "moment";
import _ from "lodash";
import { PopconfirmProps } from "utils/constants";
import CancelApplicationForm from "components/Forms/CancelApplicationForm";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";

const { TabPane } = Tabs;
const { Option } = Select;

export default function RegistrationDetails(props) {
  const [documentForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [additionalDocumentForm] = Form.useForm();
  const [fatherForm] = Form.useForm();
  const [motherForm] = Form.useForm();
  const [childForm] = Form.useForm();
  const [activeTab, setActiveTab] = useState("father");
  const [changedTab, setChangeTab] = useState("");
  // const [studentAlreadyExists, studentAlreadyExists]
  const [approveAllDocuments, setApproveAllDocuments] = useState(false);
  const handleApproveAllDocuments = () => {
    const newApproveAllDocuments = !approveAllDocuments;
    setApproveAllDocuments(newApproveAllDocuments);

    const updateDocuments = (documents) => {
      return documents.map((doc) => {
        if (doc.value && doc.value.includes("http")) {
          const newStatus = newApproveAllDocuments ? "approved" : "submitted";
          const newNote = newApproveAllDocuments ? "" : doc.oldNote || "";

          doc.status = newStatus;
          doc.note = newNote;

          documentForm.setFieldsValue({
            [doc.index]: {
              ...doc,
              status: newStatus,
              note: newNote,
            },
          });
        }
        return doc;
      });
    };

    const updatedParentDocuments = updateDocuments(parentDocuments);
    const updatedChildDocuments = updateDocuments(childDocuments);
    const updatedAdditionalParentDocuments = updateDocuments(
      parentDocuments.filter((doc) => doc.isAdditionalDocument)
    );
    const updatedAdditionalChildDocuments = updateDocuments(
      childDocuments.filter((doc) => doc.isAdditionalDocument)
    );

    setParentDocuments(updatedParentDocuments);
    setChildDocuments(updatedChildDocuments);

    const allUpdatedDocuments = [
      ...updatedParentDocuments,
      ...updatedChildDocuments,
      ...updatedAdditionalParentDocuments,
      ...updatedAdditionalChildDocuments,
    ];

    if (props?.onDocumentsUpdate) {
      props?.onDocumentsUpdate(allUpdatedDocuments);
    }
  };

  const [allFormsData, setAllFormsData] = useState({
    father: null,
    mother: null,
    child: null,
    documents: null,
    appId: "",
  });
  const [dob, setDob] = useState();
  const [approveAll, setApproveAll] = useState({
    father: false,
    mother: false,
    child: false,
  });
  const dispatch = useDispatch();
  const application = props.application;
  const [formLayout] = useState("vertical");
  const [componentSize] = useState("large");
  const [applicationSaved, setApplicationSaved] = useState(false);
  const [serviceAmount, setServiceAmount] = useState();
  const [isCancelApplicationModalOpen, setIsCancelApplicationModalOpen] =
    useState(false);
  // eservice will update when terms is updated frm the child info tab
  const [updatedEservice, updateEservice] = useState("");

  const [childDocuments, setChildDocuments] = useState([]);
  const [parentDocuments, setParentDocuments] = useState([]);
  const [modalVisibllityState, setModalVisibility] = useState(false);
  const [isFieldsDisabled, setFieldsDisabled] = useState(false);
  const [isAcceptModal, setIsAcceptModal] = useState(false);
  const [isChangeCampusModal, setIsChangeCampusModal] = useState(false);
  const [additonalDocumentExists, setAdditonalDocumentExists] = useState(false);
  let [imagesArray, sendToImagesArray] = useState([]);
  const [applicationSchools, setApplicationSchools] = useState([]);
  const [targetSchoolId, setTargetSchoolId] = useState("");
  const [changeCampusId, setChangeCampusId] = useState("");
  const [expectedDate, setExpectedDate] = useState("");
  const [enrollmentNotes, setEnrollmentNotes] = useState("");
  const [erpEnabled, setERPEnabled] = useState(false);
  const [sisEnabled, setSISEnabled] = useState(false);
  const _campuses = useSelector((state) => state.metaData.campuses);

  const [file, setFile] = useState();
  const [docType, setDocType] = useState("");
  const [uploading, setUploading] = useState(false);

  const hasCancelAccess = useSelector(
    (state) =>
      state.login.userInfo?.hasCancelAccess ||
      state.login?.userInfo?.role === "super-admin"
  );

  const handleOk = () => {
    setModalVisibility(false);
  };

  const handleCancel = () => {
    setModalVisibility(false);
  };
  const fetchApplicationSchools = async () => {
    try {
      const response = await getApplicationScholls({ id: application?.id });
      if (response.status == 200) {
        setApplicationSchools(response.data.data);
      }
    } catch (error) {
      await requestErrorHandel({ error });
    }
  };
  useEffect(() => {
    if (application) {
      fetchApplicationSchools();
      const onlyChildDoc = [];
      const onlyParentDoc = [];
      setDob(application.childDetails?.dob?.value);
      application.documents.forEach((doc, index) => {
        if (additonalDocumentExists == false) {
          if (doc.isAdditionalDocument) {
            setAdditonalDocumentExists(true);
          }
        }
        if (doc.documentOf === "child") {
          onlyChildDoc.push({ ...doc, index: index });
        } else if (doc.documentOf === "parent") {
          onlyParentDoc.push({ ...doc, index: index });
        } else {
        }
      });

      onlyChildDoc.sort(function (a, b) {
        return a.id - b.id;
      });

      onlyParentDoc.sort(function (a, b) {
        return a.id - b.id;
      });

      setParentDocuments(onlyParentDoc);
      setChildDocuments(onlyChildDoc);

      documentForm.setFieldsValue(application.documents);

      setServiceAmount(application.serviceFeeAmount);
    }
  }, [application]);

  useEffect(() => {
    if (application) {
      if (
        application?.stage?.status?.code === "SG001" ||
        application?.stage?.status?.code === "SG002" ||
        application?.stage?.status?.code === "SG004" ||
        application?.stage?.status?.code === "SG005"
      ) {
        setFieldsDisabled(false);
      } else {
        setFieldsDisabled(true);
      }
    }
  }, [isFieldsDisabled, application]);

  const onSubmitAdditionalDocForm = async (val) => {
    setLoading(true);
    let document = {
      applicationId: application.id,
      documentOf: val?.documentOf,
      documentType: docType,
      documentUrl: file,
    };
    try {
      const result = await addAdditionalDocumentsAPI(document);
      setLoading(false);
      if (result.data.statusCode === 200 && result.data.data) {
        props.refetch();
        // getDetails();
        // dispatch(_updateApplications(applicationId, result.data.data));
        setModalVisibility(false);
      }
    } catch (e) {
      setLoading(false);
      await requestErrorHandel({ error: e });
    }
  };

  const onDeleteAdditionalDocument = async (doc) => {
    try {
      const result = await deleteAdditionalDocumentAPI(doc.id);
      if (result.data.statusCode === 200) {
        dispatch(
          _deleteAdditionalDocuments({
            appId: application.id,
            documentId: doc.id,
          })
        );
        props.refetch();
        successMessage({ message: "Document deleted!" });
        // dispatch(_updateApplications(applicationId, doc));
      } else throw result.data;
    } catch (e) {
      requestErrorHandel({ error: e });
    }
  };
  const acceptAndAssignSchool = () => {
    setIsAcceptModal(true);
  };

  const showChangeCampusModal = async () => {
    setIsChangeCampusModal(true);
  };
  const trasnferApplicationToAnotherCampus = async (campusId) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: application?.id,
        action: "update_campus",
        campusId: parseInt(campusId),
      });
      if (response?.status == 200) {
        setLoading(false);
        successMessage({
          message: "Application transferred successfully",
        });
        // getDetails();
        props.refetch();
        setIsChangeCampusModal(false);
        props.history.goBack();
      } else {
        setLoading(false);
        throw response;
      }
    } catch (error) {
      setLoading(false);
      setIsChangeCampusModal(false);
      await requestErrorHandel({ error: error });
    }
  };
  const handelApplicationProcess = async (action, note) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: application?.id,
        action: action,
        schoolId: Number(targetSchoolId),
        expectedDate: expectedDate,
        enrollmentNotes: enrollmentNotes,
        serviceFeeAmount: serviceAmount ? Number(serviceAmount) : null,
        note: note,
      });
      if (response?.status == 200) {
        setLoading(false);
        setServiceAmount();
        // dispatch(_updateApplications(application.id, response.data.data));
        if (action === "pay_fee") {
          successMessage({
            message: "Application payment updated successfully",
          });
        } else {
          successMessage({
            message: "Application status updated successfully",
          });
        }
        if (action === "cancel_amendment" || action === "cancel_acceptance") {
          setFieldsDisabled(false);
        } else {
          setFieldsDisabled(true);
        }
        if (action === "cancel") {
          setIsCancelApplicationModalOpen(false);
        }
        // getDetails();
        props.refetch();
        setIsAcceptModal(false);
      } else {
        setLoading(false);
        throw response;
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        application.studentAlreadyExists = true;
        props.refetch();
        // getDetails();
        // dispatch(_updateApplications(application.id, application));
      }

      setLoading(false);
      setIsAcceptModal(false);
      setServiceAmount();

      await requestErrorHandel({ error: error });
    }
  };
  const handleFileInputChange = async (e, element, id) => {
    var file = e.target.files[0];
    let oldImagesArray = [...imagesArray];
    oldImagesArray = oldImagesArray.filter((image) => image.element.id != id);

    sendToImagesArray([...oldImagesArray, { element: element, image: file }]);
  };

  const onFinishDocumentsForm = async (val) => {
    if (imagesArray.length) {
      for (let image of imagesArray) {
        const imageURL = await handleUploadFileToENSServer(
          image.image,
          "enrollments"
        );
        const getFieldValue = documentForm.getFieldValue(image.element.index);
        image.element.value = imageURL;
        image.element.note = getFieldValue.note;
        image.element.status = getFieldValue.status;

        documentForm.setFieldsValue({
          [image.element.index]: image.element,
        });
      }
      const updatedValues = documentForm.getFieldsValue();
      submitDocumentsForm(updatedValues);
    } else {
      let emptyImageFound = false;
      const allImages = Object.values(val);
      allImages.forEach((image) => {
        if (
          image.value === "" &&
          image.status === "approved" &&
          !image.notAvailable
        ) {
          emptyImageFound = true;
        }
      });

      if (emptyImageFound) {
        NotificationManager.error("Please upload all images");
        return;
      }

      try {
        const data = {
          applicationId: application.id,
          documents: Object.values(val),
        };

        setLoading(true);

        const response = await documentsFeedback(data);

        if (response.data.statusCode === 200 && response.data.data) {
          props.refetch();
          // getDetails();
          // dispatch(_updateApplications(applicationId, response.data.data));
          setLoading(false);
          setApplicationSaved(true);
          successMessage({ message: "Documents updated successfully" });
        } else setLoading(false);
      } catch (error) {
        setLoading(false);
        await requestErrorHandel({ error: error });
      }
    }
  };

  const submitDocumentsForm = async (val) => {
    try {
      const data = {
        applicationId: application.id,
        documents: Object.values(val),
      };

      setLoading(true);

      const response = await documentsFeedback(data);

      if (response.data.statusCode === 200 && response.data.data) {
        props.refetch();
        // getDetails();
        // dispatch(_updateApplications(applicationId, response.data.data));
        setLoading(false);
        successMessage({ message: "Documents uploaded successfully" });
        sendToImagesArray([]);
        setApplicationSaved(true);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const assignTargetSchoollModal = () => {
    return (
      <div style={{ width: "200px" }}>
        <CustomModal
          visibility={isAcceptModal}
          handleCancel={() => setIsAcceptModal(false)}
        >
          <div>
            <p>Assign Target School</p>
            <Select
              style={{ width: "100%" }}
              onChange={(e) => setTargetSchoolId(e)}
              defaultValue={"Select Target School"}
            >
              {applicationSchools?.map((el, i) => (
                <Option key={i} value={`${el.id}`}>
                  {el.abbreviation}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ marginTop: 10 }}>
            <p>Expected Start Date</p>
            <DatePicker
              className="select_date"
              style={{ width: "100%" }}
              format="YYYY-MM-DD"
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }}
              disabledTime={(current) => {
                let customDate = moment().format("hh:mm A");
                return current && current < moment(customDate, "hh:mm A");
              }}
              onChange={(dateString) => setExpectedDate(dateString)}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <p>Enrollment Notes</p>
            <Input.TextArea
              className="text_area"
              value={enrollmentNotes}
              placeholder="Enrollment notes"
              onChange={(e) => setEnrollmentNotes(e.target.value)}
            ></Input.TextArea>
          </div>
          {application.eService.allowAmountUpdate && (
            <div style={{ marginTop: 10 }}>
              <p>Service Amount</p>
              <Input
                value={serviceAmount}
                placeholder="Enter service amount"
                onChange={(e) => setServiceAmount(e.target.value)}
                type={"number"}
              ></Input>
            </div>
          )}
          <Button
            className="accept_button"
            onClick={() => {
              if (
                targetSchoolId == "" ||
                expectedDate == "" ||
                enrollmentNotes == ""
              ) {
                NotificationManager.error("Please enter all fields");
                return;
              }
              setIsAcceptModal(false);
              handelApplicationProcess("accept", targetSchoolId);
            }}
            type="default"
            style={{ marginTop: "20px" }}
            loading={loading}
          >
            {loading ? "Accepting...." : "Accept"}
          </Button>
        </CustomModal>
      </div>
    );
  };

  const changeCampusModal = () => {
    return (
      <div style={{ width: "100px !important" }}>
        <CustomModal
          visibility={isChangeCampusModal}
          handleCancel={() => setIsChangeCampusModal(false)}
          modalSmall={true}
        >
          <div>
            <p>Transfer Application To Another Campus</p>
            <Select
              style={{ width: "100%" }}
              onChange={(e) => {
                setChangeCampusId(e);
              }}
              defaultValue={"Select Campus"}
            >
              {_campuses?.map(
                (el, i) =>
                  el.code !== application.childDetails.campus.valueMeta && (
                    <Option key={i} value={`${el.id}`}>
                      {el.code}
                    </Option>
                  )
              )}
            </Select>
          </div>

          <Button
            onClick={() => {
              if (changeCampusId == "") {
                NotificationManager.error("Please select a campus");
                return;
              }
              trasnferApplicationToAnotherCampus(changeCampusId);
            }}
            type="default"
            style={{ marginTop: "20px", marginRight: "20px" }}
            loading={loading}
          >
            {loading ? "Transferring...." : "Submit"}
          </Button>
        </CustomModal>
      </div>
    );
  };

  const changeFormData = (val, approveAllFields) => {
    if (val["otherLanguages"]) {
      if (Array.isArray(val["otherLanguages"].value)) {
        val["otherLanguages"].value = JSON.stringify(
          val["otherLanguages"]?.value
        );
      }
    }

    if (val["dob"]) {
      val["dob"].value = dob;
    }

    let contacts = [];
    if (val[0] && val[1]) {
      contacts = [val[0], val[1]];
      contacts[0].value = JSON.stringify(contacts[0].value);
      contacts[1].value = JSON.stringify(contacts[1].value);
      delete val[0];
      delete val[1];
    }
    const fieldsArr = Object.values(val);
    contacts.forEach((contact) => {
      fieldsArr.push(contact);
    });
    // removed approve all, will add this later
    if (approveAllFields) {
      fieldsArr.forEach((item, index) => {
        fieldsArr[index].status = "approved";
      });
    }
    return fieldsArr;
  };

  const openCancelApplicationModal = () => {
    setIsCancelApplicationModalOpen(true);
  };

  const onSave = async () => {
    Promise.all([
      fatherForm.validateFields(),
      motherForm.validateFields(),
      childForm.validateFields(),
    ])
      .then(async (res) => {
        let allData = [];
        res.forEach((el, index) => {
          let approveAllValue = "";
          switch (index) {
            case 0:
              approveAllValue = approveAll.father;
              break;
            case 1:
              approveAllValue = approveAll.mother;
              break;
            case 2:
              approveAllValue = approveAll.child;
              break;
            default:
              return;
          }
          if (el && Object.keys(el).length > 0) {
            allData = [...changeFormData(el, approveAllValue), ...allData];
          }
        });
        try {
          if (allData.length > 0) {
            setLoading(true);
            const response = await applicationFeedback({
              applicationId: Number(application.id),
              fields: allData,
              eServiceId: updatedEservice,
            });
            if (response.status === 200) {
              setLoading(false);
              NotificationManager.success("Application updated successfully");
              props.refetch();
              // getDetails();
              // dispatch(_updateApplications(application.id, response.data.data));
              setApplicationSaved(true);
            } else {
              setLoading(false);
              throw response;
            }
          }
        } catch (e) {
          requestErrorHandel({ error: e });
          setLoading(false);
        }
      })
      .catch((e) => {
        showValidationError(
          `${e.errorFields[0]?.errors[0] || "Please enter all required fields"}`
        );
      });

    documentForm
      .validateFields()
      .then((res) => {
        res && Object.keys(res).length > 0 && onFinishDocumentsForm(res);
      })
      .catch((e) => {
        showValidationError(
          `${
            e.errorFields[0]?.errors[0] || "Please enter all required fields"
          } in documents`
        );
      });
  };

  const onChooseFile = async (e) => {
    try {
      setUploading(true);
      if (e.target.files.length) {
        let fileType = getFileTypeFromUrl(e.target.files[0].name);
        if (fileType === "unknown") {
          NotificationManager.warning("File not supported");
          return;
        } else {
          const path = await handleUploadFileToENSServer(
            e.target.files[0],
            "enrollments"
          );
          setFile(path);
          setDocType(fileType);
          setUploading(false);
        }
      }
    } catch (error) {
      setUploading(false);
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={renderLoader}>
        {props.application ? (
          <>
            {assignTargetSchoollModal()}
            {changeCampusModal()}
            <Row gutter={[20, 0]}>
              <Col sm={24} md={24}>
                <Card hoverable className="antCardBorder antCard">
                  <Row justify="space-between">
                    <Col>
                      <ApplicationInfo
                        application={application}
                        refetch={props.refetch}
                      />
                    </Col>
                    {application?.hasStagePermission ? (
                      <div className={styles.appDetailsHeaderBtnHolder}>
                        {hasCancelAccess &&
                        ["AS001", "AS002", "AS003"].includes(
                          application?.status.code
                        ) &&
                        application?.stage?.slug === "registration" ? (
                          <Popconfirm
                            disabled={application?.status?.code === "AS007"}
                            placement="topLeft"
                            title="Are you sure you want to cancel this application?"
                            onConfirm={() => openCancelApplicationModal()}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button danger type="primary">
                              Cancel Application
                            </Button>
                          </Popconfirm>
                        ) : null}
                        {application?.stage?.status?.code === "SG001" ||
                        application?.stage?.status?.code === "SG002" ? (
                          // application is pending and all data is aapproved
                          <Popconfirm
                            {...PopconfirmProps}
                            title="Are you sure you want to transfer this application to another campus?"
                            onConfirm={showChangeCampusModal}
                          >
                            <Button type="primary">Change Campus</Button>
                          </Popconfirm>
                        ) : null}
                        {application?.stage?.status?.code === "SG005" ? (
                          // application is pending and all data is aapproved
                          <Popconfirm
                            {...PopconfirmProps}
                            title="Are you sure you want to accept this application?"
                            onConfirm={acceptAndAssignSchool}
                          >
                            <Button type="primary">Accept Application</Button>
                          </Popconfirm>
                        ) : null}
                        {application?.stage?.status?.code === "SG006" ? (
                          // application is pending and all data is aapproved
                          <div className={styles.updateErpBtnRow}>
                            <Popconfirm
                              {...PopconfirmProps}
                              title={"Are you sure you want to integrate SIS?"}
                              disabled={
                                application.studentAlreadyExists
                                  ? !sisEnabled
                                  : false
                              }
                              onConfirm={() =>
                                handelApplicationProcess("integrate_sis")
                              }
                            >
                              <Button type="primary">Integrate SIS</Button>
                            </Popconfirm>

                            {application.studentAlreadyExists && (
                              <Checkbox
                                onChange={(e) =>
                                  setSISEnabled(e.target.checked)
                                }
                                value={sisEnabled}
                                className={styles.studentExistsChecbox}
                              >
                                Student Already Exists - Student No:
                                {application?.studentNumber || ""}
                              </Checkbox>
                            )}
                          </div>
                        ) : null}
                        {application?.stage?.status?.code === "SG007" ? (
                          //&& application.updatedERP == true
                          //&& application.pushedToArchiving == false
                          // application is pending and all data is aapproved
                          <Popconfirm
                            {...PopconfirmProps}
                            title={"Are you sure you want to integrate DMS?"}
                            onConfirm={() =>
                              handelApplicationProcess("integrate_dms")
                            }
                          >
                            <Button type="primary">Integrate DMS</Button>
                          </Popconfirm>
                        ) : null}
                        {application?.stage?.status?.code === "SG059" ||
                        application?.stage?.status?.code === "SG060" ? (
                          // application is pending and all data is aapproved
                          <div className={styles.updateErpBtnRow}>
                            <Popconfirm
                              {...PopconfirmProps}
                              onConfirm={() =>
                                handelApplicationProcess("integrate_erp")
                              }
                              disabled={
                                application.studentAlreadyExists
                                  ? !erpEnabled
                                  : false
                              }
                              title={"Are you sure you want to integrate ERP?"}
                            >
                              <Button type="primary">Integrate ERP</Button>
                            </Popconfirm>

                            {application.studentAlreadyExists && (
                              <Checkbox
                                onChange={(e) =>
                                  setERPEnabled(e.target.checked)
                                }
                                checked={erpEnabled}
                                className={styles.studentExistsChecbox}
                              >
                                Student Already Exists - Student No:
                                {application?.studentNumber || ""}
                              </Checkbox>
                            )}
                          </div>
                        ) : null}
                        {application.stage?.status.code === "SG006" ? (
                          <Popconfirm
                            {...PopconfirmProps}
                            title="Are you sure you want to cancel acceptance?"
                            onConfirm={() => {
                              handelApplicationProcess("cancel_acceptance");
                            }}
                          >
                            <Button style={{ marginLeft: 10 }}>
                              Cancel Acceptance
                            </Button>
                          </Popconfirm>
                        ) : null}
                      </div>
                    ) : null}
                  </Row>
                </Card>
              </Col>
            </Row>
            <Card
              hoverable
              className="antCardBorder antCard"
              style={{ marginTop: 20 }}
            >
              <Row
                justify="space-between"
                align="middle"
                // className={styles.applicationDetailsWrapper}
              >
                <Col lg={14} sm={24}>
                  <h5>Application Details</h5>
                  <p>
                    Please review all the details and send it to parent. If
                    everything is okay, please mark as approved to send it to
                    next phase.
                  </p>
                </Col>
                <Col lg={4} sm={24} md={24}>
                  {application?.hasStagePermission ? (
                    <Row justify="end">
                      {application?.stage?.status?.code === "SG010" ||
                      application?.stage?.status?.code === "SG009" ? (
                        <Button
                          style={{ marginRight: "10px" }}
                          type="primary"
                          onClick={() =>
                            props.history.push("approval-steps", {
                              application: props.application,
                            })
                          }
                        >
                          Start Approval Process
                        </Button>
                      ) : null}

                      {application?.stage?.status?.code === "SG003" ? (
                        <Popconfirm
                          {...PopconfirmProps}
                          title="Are you sure you want to cancel amendment?"
                          onConfirm={() => {
                            handelApplicationProcess("cancel_amendment");
                          }}
                        >
                          <Button
                            style={{ marginRight: "10px" }}
                            // className={styles.appDetailsEnableBtn}
                          >
                            Cancel Amendment
                          </Button>
                        </Popconfirm>
                      ) : null}

                      {application?.stage?.status?.code === "SG008" ? (
                        // if payment is required, check manually if payment is paid
                        <Popconfirm
                          {...PopconfirmProps}
                          title={"Are you sure you want to update payment?"}
                          onConfirm={() => handelApplicationProcess("pay_fee")}
                        >
                          <Button type="primary">Update Payment</Button>
                        </Popconfirm>
                      ) : null}

                      {activeTab !== "logs" ? (
                        <div style={{ display: "flex" }}>
                          {["SG002", "SG004"].includes(
                            application?.stage?.status?.code
                          ) ? (
                            <Popconfirm
                              {...PopconfirmProps}
                              title="Are you sure you want to return this application to parent for amendment?"
                              onConfirm={() => {
                                handelApplicationProcess("send_to_parent");
                              }}
                            >
                              <Button
                                style={{ marginRight: "10px" }}
                                type="primary"
                              >
                                Return to Parent
                              </Button>
                            </Popconfirm>
                          ) : null}

                          {["SG001", "SG002", "SG004", "SG005"].includes(
                            application?.stage?.status?.code
                          ) ? (
                            <Button type="primary" onClick={onSave}>
                              Save Application
                            </Button>
                          ) : null}
                        </div>
                      ) : null}
                    </Row>
                  ) : null}
                </Col>
              </Row>
              {/* <Row> */}
              <Tabs
                className="applicationDetailsTab"
                activeKey={activeTab}
                onChange={(key) => {
                  if (activeTab === "father") {
                    fatherForm
                      .validateFields()
                      .then((res) => {
                        setActiveTab(key);
                      })
                      .catch((e) => {
                        showValidationError(
                          `${
                            e.errorFields[0]?.errors[0] ||
                            "Please enter all required fields"
                          }`
                        );
                      });
                  } else if (activeTab === "mother") {
                    motherForm
                      .validateFields()
                      .then((res) => setActiveTab(key))
                      .catch((e) => {
                        showValidationError(
                          `${
                            e.errorFields[0]?.errors[0] ||
                            "Please enter all required fields"
                          }`
                        );
                      });
                  } else if (activeTab === "child") {
                    childForm
                      .validateFields()
                      .then((res) => setActiveTab(key))
                      .catch((e) => {
                        showValidationError(
                          `${
                            e.errorFields[0]?.errors[0] ||
                            "Please enter all required fields"
                          }`
                        );
                      });
                  } else if (activeTab === "documents") {
                    setAllFormsData({ ...allFormsData, documents: "changed" });
                    setActiveTab(key);
                    // documentForm
                    //   .validateFields()
                    //   .then((res) => {
                    //     setAllFormsData({ ...allFormsData, documents: "changed" });
                    //     setActiveTab(key);
                    //   })
                    //   .catch((e) => {
                    //     NotificationManager.error(
                    //       `${
                    //         e.errorFields[0]?.errors[0] ||
                    //         "Please enter all required fields"
                    //       }`
                    //     );
                    //   });
                  } else setActiveTab(key);
                }}
              >
                <TabPane
                  tab={<h5 className={styles.tabTitle}>Father Information</h5>}
                  key="father"
                >
                  {application?.hasStagePermission ? (
                    <div>
                      <ApplicationInformationForm
                        application={application}
                        type={"father"}
                        isFieldsDisabled={isFieldsDisabled}
                        form={fatherForm}
                        onApproveAll={(value) =>
                          setApproveAll({ ...approveAll, father: value })
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <ApplicationInformationReadOnlyForm
                        application={application}
                        type={"father"}
                        isFieldsDisabled={isFieldsDisabled}
                        form={fatherForm}
                        onApproveAll={(value) =>
                          setApproveAll({ ...approveAll, father: value })
                        }
                      />
                    </div>
                  )}
                </TabPane>

                <TabPane
                  tab={<h5 className={styles.tabTitle}>Mother Information</h5>}
                  key="mother"
                >
                  {application?.hasStagePermission ? (
                    <div>
                      <ApplicationInformationForm
                        application={application}
                        type={"mother"}
                        isFieldsDisabled={isFieldsDisabled}
                        form={motherForm}
                        onApproveAll={(value) =>
                          setApproveAll({ ...approveAll, mother: value })
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <ApplicationInformationReadOnlyForm
                        application={application}
                        type={"mother"}
                        isFieldsDisabled={isFieldsDisabled}
                        form={motherForm}
                        onApproveAll={(value) =>
                          setApproveAll({ ...approveAll, mother: value })
                        }
                      />
                    </div>
                  )}
                </TabPane>

                <TabPane
                  tab={<h5 className={styles.tabTitle}>Child Information</h5>}
                  key="child"
                  // disabled={activeTab === "father"}
                >
                  {application?.hasStagePermission ? (
                    <ApplicationInformationForm
                      application={application}
                      type={"child"}
                      isFieldsDisabled={isFieldsDisabled}
                      form={childForm}
                      onChangeDob={(str) => setDob(str)}
                      onApproveAll={(value) =>
                        setApproveAll({ ...approveAll, child: value })
                      }
                      onUpdateEservice={updateEservice}
                    />
                  ) : (
                    <ApplicationInformationReadOnlyForm
                      application={application}
                      type={"child"}
                      isFieldsDisabled={isFieldsDisabled}
                      form={childForm}
                      onChangeDob={(str) => setDob(str)}
                      onApproveAll={(value) =>
                        setApproveAll({ ...approveAll, child: value })
                      }
                      onUpdateEservice={updateEservice}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={<h5 className={styles.tabTitle}>Documents</h5>}
                  key="documents"
                >
                  {application?.hasStagePermission ? (
                    <>
                      <Form name="dynamic_form_nest_item" form={documentForm}>
                        <div
                          style={{
                            maxHeight: "18px",
                            maxWidth: "100%",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#fbfbfb",
                            marginTop:"10px",
                            gap:"4px"
                          }}
                        >
                          <img
                            className={styles.approveAllIcon}
                            src={
                              approveAllDocuments ? checkedImg : uncheckedImg
                            }
                            alt="Approve All"
                            onClick={handleApproveAllDocuments}
                            style={{
                              maxHeight: "18px",
                              maxWidth: "18px",

                              cursor:
                                isFieldsDisabled ||
                                application?.stage?.status?.code === "SG003"
                                  ? "not-allowed"
                                  : "pointer",
                              opacity: isFieldsDisabled ? 0.3 : 1,
                            }}
                          />
                          <span>Approve All</span>
                        </div>
                        <Row
                          gutter={[50, 10]}
                          className="documentFormItemRow"
                          justify="end"
                        >
                          <Col md={12} sm={24}>
                            <h6 style={{ marginBottom: "3rem" }}>
                              Parent Documents
                            </h6>
                            {parentDocuments.map((document, i) => {
                              if (document.isAdditionalDocument !== true)
                                return (
                                  <Card
                                    hoverable
                                    className="documentInputCardItem"
                                    key={i}
                                  >
                                    <FormGroupItemForDocuments
                                      key={i}
                                      doc={document}
                                      handleFileInputChange={
                                        handleFileInputChange
                                      }
                                      onDelete={onDeleteAdditionalDocument}
                                      isFieldsDisabled={isFieldsDisabled}
                                      application={application}
                                      form={documentForm}
                                    />
                                  </Card>
                                );
                              else return null;
                            })}
                          </Col>
                          <Col md={12}>
                            <h6 style={{ marginBottom: "3rem" }}>
                              Child Documents
                            </h6>

                            {childDocuments.map((document, i) => {
                              if (document.isAdditionalDocument !== true)
                                return (
                                  <Card
                                    hoverable
                                    className="documentInputCardItem"
                                    key={i}
                                  >
                                    <FormGroupItemForDocuments
                                      key={i}
                                      doc={document}
                                      handleFileInputChange={
                                        handleFileInputChange
                                      }
                                      onDelete={onDeleteAdditionalDocument}
                                      isFieldsDisabled={isFieldsDisabled}
                                      application={application}
                                      form={documentForm}
                                    />
                                  </Card>
                                );
                              else return null;
                            })}
                          </Col>
                        </Row>

                        {!additonalDocumentExists ? null : (
                          <>
                            <Row
                              gutter={[30, 30]}
                              style={{
                                padding: "2rem",
                                backgroundColor: "#fbfbfb",
                                margin: "0px",
                              }}
                              justify="start"
                            >
                              <Col md={12}>
                                <h6>Additional Documents</h6>
                              </Col>
                            </Row>

                            <Row
                              gutter={[30, 30]}
                              style={{
                                padding: "2rem",
                                backgroundColor: "#fbfbfb",
                                margin: "0px",
                              }}
                              justify="start"
                            >
                              <Col md={12}>
                                {parentDocuments.map((document, i) => {
                                  if (document.isAdditionalDocument == true)
                                    return (
                                      <Card
                                        hoverable
                                        className="documentInputCardItem"
                                      >
                                        <FormGroupItemForDocuments
                                          key={i}
                                          doc={document}
                                          handleFileInputChange={
                                            handleFileInputChange
                                          }
                                          onDelete={onDeleteAdditionalDocument}
                                          isFieldsDisabled={isFieldsDisabled}
                                          form={documentForm}
                                        />
                                      </Card>
                                    );
                                  else return null;
                                })}
                              </Col>

                              <Col md={12}>
                                {childDocuments.map((document, i) => {
                                  if (document.isAdditionalDocument == true)
                                    return (
                                      <Card
                                        hoverable
                                        className="documentInputCardItem"
                                      >
                                        <FormGroupItemForDocuments
                                          key={i}
                                          doc={document}
                                          handleFileInputChange={
                                            handleFileInputChange
                                          }
                                          onDelete={onDeleteAdditionalDocument}
                                          isFieldsDisabled={isFieldsDisabled}
                                          form={documentForm}
                                        />
                                      </Card>
                                    );
                                  else return null;
                                })}
                              </Col>
                            </Row>
                          </>
                        )}
                      </Form>

                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        style={{ marginTop: "1rem" }}
                      >
                        <Card>
                          <div className={styles.additionalDocumentsHeading}>
                            <h6 className={styles.documentsOwnerHeading}>
                              Additional Document (If required)
                            </h6>
                            <Button
                              disabled={isFieldsDisabled}
                              type="primary"
                              onClick={() => setModalVisibility(true)}
                            >
                              Add New
                            </Button>
                          </div>
                        </Card>
                      </Col>
                    </>
                  ) : (
                    <DocumentsReadOnlyForm application={application} />
                  )}
                </TabPane>

                <TabPane
                  tab={<h5 className={styles.tabTitle}>Stages History</h5>}
                  key="logs"
                >
                  <ApplicationLogs application={application} />
                </TabPane>
              </Tabs>
            </Card>

            <CustomModal
              visibility={modalVisibllityState}
              handleCancel={handleCancel}
              handleOk={handleOk}
              title={<>Additional Document</>}
              modalSmall={true}
            >
              <Form
                layout={formLayout}
                initialValues={{ layout: formLayout }}
                size={componentSize}
                form={additionalDocumentForm}
                onFinish={onSubmitAdditionalDocForm}
              >
                <CreateNewDocumentListForm
                  // valuesForEdit={editApplicationFields}
                  uploading={uploading}
                  file={file}
                  handelPickFile={onChooseFile}
                  documentFor={["parent", "child"]}
                  form={additionalDocumentForm}
                  loading={loading}
                />
              </Form>
            </CustomModal>

            <CancelApplicationForm
              visibility={isCancelApplicationModalOpen}
              handleCancel={() => setIsCancelApplicationModalOpen(false)}
              onSubmit={(notes) => handelApplicationProcess("cancel", notes)}
              loading={loading}
            />
          </>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
}
