import client from "apis/config";

export const fetchAllFsmFeatures = async ({
  limit = "10",
  page,
  type = "facility_features",
}) => {
  return await client.get(
    `/facility-data?type=${type}&limit=${limit}&page=${page}`
  );
};

export const addFsmFeature = async ({ data }) => {
  return await client.post("/facility-data", data);
};

export const updateFsmFeature = async ({ id, data }) => {
  return await client.put(`/facility-data/${id}`, data);
};

export const deleteFsmFeature = async ({ id }) => {
  return await client.delete(`/facility-data/${id}`);
};
