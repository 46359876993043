import { Button, Col, DatePicker, Form, Row, Spin, Select, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import { getFullDayName } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";
import { onRescheduleBooking } from "views/FSM/bookings/api";
import { checkBookingAvailableDate } from "views/FSM/bookings/api";
const { Option } = Select;

const LTBBookingRescheduleModal = ({
  schedule,
  type,
  facility,
  handleCancel,
  refetch,
  bookingType,
}) => {
  const [availableDates, setAvailabeDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [fieldErrors, setFieldErrors] = useState([]);

  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedScheduleType, setSelectdScheduleType] = useState(
    schedule?.scheduleType
  );

  const [scheduleFields, setScheduleFields] = useState([
    {
      startAt: moment(schedule?.startAt),
      endAt: moment(schedule?.endAt),
      noOfAttendees: schedule.noOfAttendees,
      startTime: "",
      endTime: "",
      scheduleType: schedule?.scheduleType,
      eventType: schedule?.eventType?.id,
      day: [],
    },
  ]);
  const [ltbAvailableWeekdays, setltbAvailableWeekdays] = useState([]);
  const [ltbCommonAvailableSlots, setltbCommonAvailableSlots] = useState([]);
  const [
    availableCapacityForSelectedDate,
    setAvailableCapacityForSelectedDate,
  ] = useState([]);

  useEffect(() => {
    if (Object.keys(schedule).length) {
      getBookingsAvailableDate();
    }
  }, [schedule]);

  useEffect(() => {
    if (Object.keys(schedule).length > 0) {
      const updatedScheduleField = { ...scheduleFields }; // Copy the object

      const details = schedule.details || [];
      const uniqueDays = [
        ...new Set(details.map((item) => item.dayOfWeek)),
      ].filter(Boolean);

      updatedScheduleField[0].day = uniqueDays; // Set the days

      setScheduleFields([updatedScheduleField]);

      setltbAvailableWeekdays(uniqueDays);
    }
  }, [schedule]);

  const getBookingsAvailableDate = async () => {
    try {
      setLoading(true);
      // Get the first day of the current year
      const startOfYear = moment().startOf("year").format("YYYY-MM-DD");

      // Get the last day of the current year
      const endOfYear = moment().endOf("year").format("YYYY-MM-DD");

      const response = await checkBookingAvailableDate({
        facility: facility?.id,
        startAt: startOfYear,
        endAt: endOfYear,
        isSpecial: 1,
      });

      if (response.status === 200) {
        setAvailabeDates(response.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const disabledDatesAndDays = (calendarDate) => {
    const formattedCalendarDate = calendarDate.format("YYYY-MM-DD");
    const todayDate = moment().format("YYYY-MM-DD");
    const currentScheduleDate = schedule?.startAt;

    // Check if the calendarDate matches any date in availableDates
    const isDateInAvailableDates = availableDates.some(
      (item) => item.date === formattedCalendarDate
    );
    return (
      formattedCalendarDate === todayDate ||
      !isDateInAvailableDates ||
      (currentScheduleDate && formattedCalendarDate === currentScheduleDate)
    );
  };

  const handleMonthChange = async (date, dateString) => {
    try {
      const yearOfSelectedDate = moment(date).year();

      // Check if the year has changed
      if (yearOfSelectedDate !== moment(currentMonth).year()) {
        setCurrentMonth(date);

        if (facility?.id) {
          setLoading(true);

          // Get the first day and last day of the selected year
          const startOfYear = moment(date).startOf("year").format("YYYY-MM-DD");
          const endOfYear = moment(date).endOf("year").format("YYYY-MM-DD");

          const response = await checkBookingAvailableDate({
            facility: facility?.id,
            startAt: startOfYear,
            endAt: endOfYear,
            isSpecial: 1,
          });

          if (response.status === 200) {
            setAvailabeDates(response.data?.data);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      setLoading(false);
    }
    // You can perform any action with the month name here
  };
  const handleFieldChange = (index, fieldName, value) => {
    if (facility) {
      if (fieldName === "noOfAttendees") {
        if (value > facility?.capacity) {
          const newErrors = [...fieldErrors];
          newErrors[index] = {
            noOfAttendees: `Available capacity is ${facility?.capacity}`,
          };
          setFieldErrors(newErrors);
        } else {
          const newErrors = [...fieldErrors];
          newErrors[index] = { noOfAttendees: "" };
          setFieldErrors(newErrors);
        }
      }
      if (fieldName === "startAt" || fieldName === "endAt") {
        const updatedFields = [...scheduleFields];
        updatedFields[index].day = [];
        setScheduleFields(updatedFields);
      }
    }

    const updatedFields = [...scheduleFields];
    updatedFields[index][fieldName] = value;
    setScheduleFields(updatedFields);
    onDateSelected(index);
  };
  const onDateSelected = (index) => {
    const selectedStartDate = moment(scheduleFields[index].startAt).format(
      "YYYY-MM-DD"
    );

    const _selectedDateCapacity = availableDates.find(
      (d) => d.date === selectedStartDate
    );

    const updatedCapacity = [...availableCapacityForSelectedDate]; // Copy the existing state
    updatedCapacity[index] = _selectedDateCapacity; // Update the specific index

    setAvailableCapacityForSelectedDate(updatedCapacity); // Update the state
  };
  const handleSelectSlots = (selectedSlot, index) => {
    const updatedDatta = ltbCommonAvailableSlots.map((slot) =>
      slot === selectedSlot ? { ...slot, selected: !slot.selected } : slot
    );
    setltbCommonAvailableSlots(updatedDatta);

    const updatedSelectedSlots = updatedDatta
      .filter((slot) => slot.selected)
      .map((slot) => ({ startTime: slot.startTime, endTime: slot.endTime }));

    setSelectedSlots(updatedSelectedSlots);
  };
  const handleSelectWeekDays = (selectedWeekdays, index) => {
    const updatedScheduleFields = [...scheduleFields];
    updatedScheduleFields[index] = {
      ...updatedScheduleFields[index],
      day: selectedWeekdays, // Set selected weekdays into the day array
    };

    setScheduleFields(updatedScheduleFields);
    setSelectedSlots([]);
  };
  // Function to clear the error for the respective field upon focus
  const onFieldFocus = (index, fieldName) => {
    const updatedErrors = [...fieldErrors];
    updatedErrors[index] = { ...updatedErrors[index], [fieldName]: "" };
    setFieldErrors(updatedErrors);
  };

  const isDateAvailable = (date) => {
    return availableDates.some((availableDate) => availableDate.date === date);
  };

  const getAvailableWeekdaysInRange = (startDate, endDate) => {
    const dayNames = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    let availability = {};

    let currentDate = new Date(startDate);
    endDate = new Date(endDate);

    while (currentDate <= endDate) {
      const dayOfWeek = dayNames[currentDate.getDay()];
      const dateString = currentDate.toISOString().split("T")[0];
      if (isDateAvailable(dateString) && !availability[dayOfWeek]) {
        availability[dayOfWeek] = true;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return Object.keys(availability).filter((day) => availability[day]);
  };

  const slotKey = (slot) => `${slot.startTime}-${slot.endTime}`;

  const getCommonAvailableSlots = (
    startDate,
    endDate,
    selectedWeekDays,
    availableDates
  ) => {
    let slotsByDay = selectedWeekDays.reduce(
      (acc, day) => ({ ...acc, [day]: new Map() }),
      {}
    );

    availableDates.forEach((availableDate) => {
      if (selectedWeekDays.includes(availableDate.dayOfWeek)) {
        availableDate.slotsAvailability.forEach((slot) => {
          const key = slotKey(slot);
          if (!slotsByDay[availableDate.dayOfWeek].has(key)) {
            slotsByDay[availableDate.dayOfWeek].set(key, slot);
          }
        });
      }
    });

    let commonSlotKeys = null;

    if (selectedWeekDays.length > 0) {
      commonSlotKeys = new Set(
        Array.from(slotsByDay[selectedWeekDays[0]]?.keys() || [])
      );

      selectedWeekDays.forEach((day) => {
        commonSlotKeys = new Set(
          [...commonSlotKeys].filter((key) => slotsByDay[day]?.has(key))
        );
      });
    }

    if (commonSlotKeys) {
      return Array.from(commonSlotKeys).map((key) =>
        slotsByDay[selectedWeekDays[0]].get(key)
      );
    }

    return [];
  };

  return (
    <div>
      <Spin spinning={loading} indicator={renderLoader}>
        <Form layout="vertical">
          <div id="scheduleFields">
            {scheduleFields.map((field, index) => (
              <div key={index}>
                <Row gutter={40}>
                  <Col md={8}>
                    <Form.Item label="Start Date">
                      <DatePicker
                        disabled={Object.keys(facility).length === 0}
                        placeholder="Start Date"
                        className="date__custom_width"
                        value={field.startAt}
                        onChange={(date) =>
                          handleFieldChange(index, "startAt", date)
                        }
                        disabledDate={disabledDatesAndDays}
                        onPanelChange={handleMonthChange}
                        onFocus={() => onFieldFocus(index, "startAt")}
                      />
                      <p style={{ color: "red", marginTop: "5px" }}>
                        {fieldErrors[index]?.startAt}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label="End Date">
                      <DatePicker
                        disabled={
                          !moment(scheduleFields[index].startAt).isValid()
                        }
                        placeholder="End Date"
                        className="date__custom_width"
                        value={field.endAt}
                        onChange={(date) =>
                          handleFieldChange(index, "endAt", date)
                        }
                        disabledDate={disabledDatesAndDays}
                        onPanelChange={handleMonthChange}
                        onFocus={() => onFieldFocus(index, "endAt")}
                      />
                      <p style={{ color: "red", marginTop: "5px" }}>
                        {fieldErrors[index]?.endAt}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item label={"Select Week Days"}>
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        allowClear
                        value={scheduleFields[index].day}
                        disabled={
                          !moment(scheduleFields[index].startAt).isValid() ||
                          !moment(scheduleFields[index].endAt).isValid()
                        }
                        onChange={(el) => handleSelectWeekDays(el, index)}
                        onFocus={() => {
                          onFieldFocus(index, "day");
                        }}
                      >
                        {ltbAvailableWeekdays.map((el, i) => {
                          return (
                            <Option value={el} key={i}>
                              {getFullDayName(el)}
                            </Option>
                          );
                        })}
                      </Select>
                      <p style={{ color: "red", marginTop: "5px" }}>
                        {fieldErrors[index]?.day}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item
                      label={`Number of People${
                        Object.keys(facility).length > 0 && facility?.capacity
                          ? ` (Available Capacity ${facility.capacity})`
                          : ""
                      }`}
                    >
                      <Input
                        disabled={
                          !moment(scheduleFields[index].startAt).isValid()
                        }
                        placeholder="Add here"
                        type="number"
                        value={field.noOfAttendees}
                        onChange={(e) =>
                          handleFieldChange(
                            index,
                            "noOfAttendees",
                            e.target.value
                          )
                        }
                        min={1}
                        onFocus={() => onFieldFocus(index, "noOfAttendees")}
                      />
                      <p style={{ color: "red", marginTop: "5px" }}>
                        {fieldErrors[index]?.noOfAttendees}
                      </p>
                    </Form.Item>
                  </Col>
                </Row>

                {selectedScheduleType === "slots" && <b>Available Slots</b>}
                <Row gutter={40}>
                  {selectedScheduleType === "slots" &&
                    ltbCommonAvailableSlots.map((slot, index) => {
                      const isSelected = slot.selected;
                      return (
                        <Col md={4}>
                          <div
                            key={index}
                            className={`ltb_slot_container ${
                              isSelected ? "selected" : ""
                            }`}
                            onClick={() => handleSelectSlots(slot, index)}
                          >
                            <p>
                              {formatedDateWithAmAndPm(slot.startTime)} -{" "}
                              {formatedDateWithAmAndPm(slot.endTime)}
                            </p>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            ))}
          </div>

          <br />
          <Row justify="end">
            <Button className="ant__primary__button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="ant__primary__button"
              // onClick={onUpdateSchedule}
            >
              {/* {isLoading ? "Updating..." : "Update"} */}
              update
            </Button>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default LTBBookingRescheduleModal;
