/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { Button, Card, Col, Row, Spin, Tabs } from "antd";
import styles from "../../../../styles/applicationDetails.module.css";

import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";

import { addStageApproval } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { LoadingOutlined } from "@ant-design/icons";

import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import TranscriptReviewForm from "components/Forms/transcript-review-form/TranscriptReviewForm";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import { _updateApplications } from "actions/applications/applications-actions";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";

const { TabPane } = Tabs;

const TranscriptReview = (props) => {
  const [showApproveModal, toggleApproveModal] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();

  const applicationId = props.application?.id;

  // React.useEffect(() => {
  //   getDetails();
  // }, [applicationId]);

  React.useEffect(() => {
    props.application && getLogs();
  }, [props.application]);

  const getLogs = async () => {
    try {
      setLoading(true);
      const response = await getApplicationStagesLogs({ id: applicationId });
      if (response.status == 200) {
        var applicationCurrentStage = currentStageDetails;
        if (
          applicationCurrentStage == undefined ||
          applicationCurrentStage == ""
        ) {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == props.application.stage.slug
          );
        } else {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == currentStageDetails.stageSlug
          );
        }
        setcurrentStageDetails(applicationCurrentStage);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onApproveClick = () => toggleApproveModal(true);
  const onSubmitApprove = (values) => {
    //   approve, notes

    toggleApproveModal(false);
    values["appStageId"] = props.application?.stage.id;
    // setApprove(values.approve?.toUpperCase() || "");
    onAddStagesApproval(values);
  };

  const onAddStagesApproval = async (values) => {
    try {
      setLoading(true);
      const response = await addStageApproval({ data: values });
      if (response.status == 200) {
        successMessage({ message: "Remarks added successfully" });
        // getDetails();
        props.refetch();
        // dispatch(_updateApplications(applicationId, response.data.data));
        getLogs();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const renderRemarksDetails = () => {
    if (!props.application.hasStagePermission) return null;
    return (
      <Card
        hoverable
        className="antCardBorder antCard"
        style={{ marginTop: 20 }}
      >
        <h6>Final Remarks</h6>
        <h4>
          {currentStageDetails?.approvals[0].isApproved
            ? "Recommended"
            : "Not Recommended"}
        </h4>
        <hr />
        <p style={{ overflowX: "clip" }}>
          {currentStageDetails?.approvals[0].notes}
        </p>
      </Card>
    );
  };

  const getBtnTitle = () => {
    if (currentStageDetails?.stageStatus?.code == "SG027") {
      return "Add Remarks";
    } else if (currentStageDetails?.stageStatus?.code == "SG028") {
      return "Completed";
    } else if (currentStageDetails?.stageStatus?.code == "SG029") {
      return "Cancelled";
    }
  };

  const getTitle = () => {
    return currentStageDetails?.stageStatus?.internalStatus;
  };

  const getDescription = () => {
    if (currentStageDetails?.stageStatus?.code == "SG027") {
      return "Please review the transcript in the below attached documents and add your remarks";
    } else if (currentStageDetails?.stageStatus?.code == "SG028") {
      return "The stage has been completed successfully";
    } else if (currentStageDetails?.stageStatus?.code == "SG029") {
      return "The stage has been cancelled";
    }
  };

  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={loader}>
        {props.application ? (
          <>
            <Row gutter={[20, 0]}>
              <Col sm={24} md={props.application.hasStagePermission ? 12 : 24}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>
              {props.application.hasStagePermission && (
                <>
                  <Col sm={24} md={12}>
                    <Card hoverable className="antCardBorder antCard">
                      <Row align="middle" justify="space-between">
                        <Col sm={32} md={15}>
                          <div>
                            <h5>{getTitle()}</h5>
                            <p style={{ fontSize: "12px" }}>
                              {getDescription()}
                            </p>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <Button
                            type="primary"
                            className="appButton"
                            onClick={onApproveClick}
                            disabled={
                              currentStageDetails?.stageStatus?.code ===
                                "SG028" ||
                              currentStageDetails?.stageStatus?.code === "SG029"
                            }
                          >
                            {getBtnTitle()}
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
            {currentStageDetails?.stageStatus?.code == "SG028"
              ? renderRemarksDetails()
              : null}
            <Tabs className="applicationDetailsTab" defaultActiveKey="1">
              <TabPane
                tab={<h5 className={styles.tabTitle}>Father Information</h5>}
                key="1"
              >
                <div>
                  <ApplicationInformationReadOnlyForm
                    application={props.application}
                    type={"father"}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Mother Information</h5>}
                key="2"
              >
                <div>
                  <ApplicationInformationReadOnlyForm
                    application={props.application}
                    type={"mother"}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Child Information</h5>}
                key="3"
              >
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"child"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Documents</h5>}
                key="4"
              >
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Stages History</h5>}
                key="5"
              >
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>
            <CustomModal
              visibility={showApproveModal}
              handleCancel={() => toggleApproveModal(false)}
              title="Transcript Review Remarks"
            >
              <TranscriptReviewForm
                onFinish={onSubmitApprove}
                loading={loading}
              />
            </CustomModal>
          </>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default TranscriptReview;
