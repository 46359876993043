import { Button, Card, Col, Popconfirm, Row, Form, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Label } from "reactstrap";

import CustomModal from "components/CustomModal/CustomModal";
import React, { useEffect, useState } from "react";
import {
  handleConductCaseStageApproval,
  handleProcessConductCase,
} from "../scc-offense-management/api";
import { requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";
import moment from "moment";

const SccHeader = ({ conductCaseDetails, refetch, finalApproval }) => {
  const [cancelCaseForm] = Form.useForm();
  const [declineCaseForm] = Form.useForm();
  const [inquireCaseForm] = Form.useForm();
  const [notesApprovalForm] = Form.useForm();
  const [notesStageApprovalForm] = Form.useForm();

  const details = conductCaseDetails;
  const studentDetail = conductCaseDetails?.student;

  const [isCancelCase, setIsCancelCase] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [isOpenInquireModal, setIsOpenInquireModal] = useState(false);
  const [noteForApproval, setnoteForApproval] = useState(false);
  const [noteForStageApproval, setNoteForStageApproval] = useState(false);
  const [noApprovalNeeded, setNoApprovalNeeded] = useState(false);

  const [loading, setLoading] = useState(false);

  const [showAll, setShowAll] = useState(false);

  const [stages, setStages] = useState([
    {
      id: 35,
      applicationType: "SCC",
      slug: "approval_level_1",
      title: "Approval Level 1",
      titleAr: "Approval Level 1",
      order: 2,
      isChecked: true,
    },
    {
      id: 36,
      applicationType: "SCC",
      slug: "approval_level_2",
      title: "Approval Level 2",
      titleAr: "Approval Level 2",
      order: 3,
      isChecked: true,
    },
    {
      id: 37,
      applicationType: "SCC",
      slug: "final_approval",
      title: "Final Approval",
      titleAr: "Final Approval",
      order: 4,
      isChecked: true,
    },
    {
      id: 38,
      applicationType: "SCC",
      slug: "acknowledgement",
      title: "Acknowledgement",
      titleAr: "Acknowledgement",
      order: 5,
      isChecked: true,
    },
  ]);

  useEffect(() => {
    if (conductCaseDetails?.needAcknowledgment !== undefined) {
      setStages((prevStages) => {
        // If needAcknowledgment is true, return the updated array with the stage
        if (conductCaseDetails.needAcknowledgment) {
          return prevStages.map((stage) =>
            stage.slug === "acknowledgement"
              ? { ...stage, isChecked: true }
              : stage
          );
        }

        // If needAcknowledgment is false, filter out the "acknowledgement" stage
        return prevStages.filter((stage) => stage.slug !== "acknowledgement");
      });
    }
  }, []);

  const handleShowAll = (isNegative) => {
    setShowAll(!showAll);
    if (isNegative) {
      const windowHeight = window.innerHeight;
      const scrollToPosition = windowHeight / 2;
      window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
    }
  };

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const handleCaseProcess = async (action, notes) => {
    const selectedStages = stages
      .filter((stage) => stage.isChecked) // Filter for checked stages
      .map((stage) => stage.slug); // Map to get their slugs

    try {
      const paylaod = {
        caseId: conductCaseDetails?.id,
        action: action,
        notes: notes,
        stages:
          action === "sendForApproval" && !noApprovalNeeded
            ? selectedStages
            : [],
        noApprovalNeeded: noApprovalNeeded,
      };

      const response = await handleProcessConductCase({ payload: paylaod });

      if (response.status === 200) {
        if (action === "cancel") {
          successMessage({ message: "Case has been cancelled" });
        } else {
          successMessage({ message: "Case has been Approved successfully" });
        }
        refetch();
        setnoteForApproval(false);
      }
    } catch (error) {
      console.log("error", error);
      requestErrorHandel({ error: error });
    }
  };

  const handleCaseStageApprovel = async (isApprove, notes, action) => {
    try {
      const paylaod = {
        caseId: conductCaseDetails?.id,
        isApproved: isApprove,
        notes: notes,
        action: action ? action : "",
      };
      const response = await handleConductCaseStageApproval({
        payload: paylaod,
      });
      if (response.status === 200) {
        if (isApprove) {
          successMessage({ message: "Case has been Approved successfully" });
        } else {
          successMessage({ message: "Case has been Declined successfully" });
        }
        refetch();
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };

  const onFinishCancelCase = (values) => {
    setIsCancelCase(false);
    handleCaseProcess("cancel", values?.notes);
  };
  const onDeclineCase = (values) => {
    setOpenDeclineModal(false);
    handleCaseStageApprovel(false, values?.notes);
  };

  const onReturnToParent = (values) => {
    setIsOpenInquireModal(false);
    handleCaseProcess("returnToParent", values?.notes);
  };

  const renderActionButtons = (
    stageStatus,
    conductCaseStatus,
    slug,
    termStatus
  ) => {
    if (!termStatus || !conductCaseDetails?.hasPermission) {
      return null;
    }
    return (
      <div>
        {/* cancel button */}
        {["AS001", "AS002", "AS003", "AS004"].includes(conductCaseStatus) && (
          <Popconfirm
            disabled={conductCaseStatus === "AS007"}
            placement="topLeft"
            title="Are you sure to cancel this Case?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => setIsCancelCase(true)}
          >
            <Button danger type="primary" className="ant__primary__button">
              Cancel
            </Button>
          </Popconfirm>
        )}

        {["SCC400"].includes(stageStatus) && (
          <Button type="default" className="ant__primary__button" disabled>
            Cancelled
          </Button>
        )}

        {/* submit for Approve button */}
        {["SCC100", "SCC200"].includes(stageStatus) ? (
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to submit this Case for approval?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => setnoteForApproval(true)}
            // onConfirm={() => handleCaseProcess("sendForApproval", "")}
          >
            <Button type="primary" className="ant__primary__button">
              Submit For Approval
            </Button>
          </Popconfirm>
        ) : null}

        {/* Dcline button */}
        {[
          "SCC600",
          "SCC1100",
          "SCC1600", //EVB final
        ].includes(stageStatus) ? (
          <Button
            danger
            className="ant__primary__button"
            onClick={() => setOpenDeclineModal(true)}
          >
            Decline
          </Button>
        ) : null}

        {/* stage approval button */}
        {["SCC600", "SCC1100", "SCC1600"].includes(stageStatus) ? (
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to approve this Case?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => setNoteForStageApproval(true)}
          >
            <Button type="primary" className="ant__primary__button">
              Approve
            </Button>
          </Popconfirm>
        ) : null}

        {/*  Waiting acknowledgement button */}
        {["SCC2300"].includes(stageStatus) ? (
          <Button
            type="primary"
            className="ant__primary__button"
            onClick={() => setIsOpenInquireModal(true)}
          >
            Respond to Parent's Inquiry
          </Button>
        ) : null}
      </div>
    );
  };

  const onSubmitNotesForApproval = (values) => {
    handleCaseProcess("sendForApproval", values?.notes);
  };
  const onSubmitNotesForStageApproval = (values) => {
    handleCaseStageApprovel(true, values?.notes, values.action);
    setNoteForStageApproval(false);
  };

  const handleAssignStage = (id) => {
    // Toggle the isChecked value of the selected stage
    setStages((prevStages) =>
      prevStages.map((stage) =>
        stage.id === id ? { ...stage, isChecked: !stage.isChecked } : stage
      )
    );
  };
  return (
    <div className="fsm__booking_header__containr">
      <Card className="antCardBorder antCard">
        <Row gutter={10}>
          <Col md={12}>
            <h5>{details?.title}</h5>
            <div className="stageInformation">
              <p>
                Current Stage: <span>{details?.currentStage?.title}</span>
              </p>
              <div className="__dot" />
              <p>
                Stage Status:{" "}
                <span>{details?.currentStage?.status?.internalStatus}</span>
              </p>
            </div>
          </Col>
          <Col md={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {renderActionButtons(
                conductCaseDetails?.currentStage?.status?.code,
                conductCaseDetails?.status?.code,
                conductCaseDetails?.currentStage?.slug,
                conductCaseDetails?.termStatus
              )}
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <div className="conduct_case_general">
        <h3>Student Information</h3>
        <Card className="antCardBorder antCard">
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Student Name</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="scc_value">
                <p>
                  {studentDetail?.firstName +
                    " " +
                    studentDetail?.middleName +
                    " " +
                    studentDetail?.lastName}
                </p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Student No</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="scc_value">
                <p>{studentDetail?.studentNumber}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Student Class</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="scc_value">
                <p>{details?.studentClass}</p>
              </div>
            </Col>
          </Row>

          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Campus</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="scc_value">
                <p>{studentDetail?.campus}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>School</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="scc_value">
                <p>{studentDetail?.school}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Grade</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="scc_value">
                <p>{studentDetail?.grade}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Father Name</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="scc_value">
                <p>{details?.fatherName}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Father Phone</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="scc_value">
                <p>{details?.fatherPhoneNo}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Mother Name</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="scc_value">
                <p>{details?.motherName}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Mother Phone</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="scc_value">
                <p>{details?.motherPhoneNo}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Year</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="scc_value">
                <p>{details?.yearName}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col md={6}>
              <div className="scc_title">
                <p>Term</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="scc_value">
                <p>{details?.term === "first" ? "Term1" : "Term2"}</p>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      <br />
      {details.inquiries.length > 0 && (
        <div className="conduct_case_general">
          <Row justify={"space-between"}>
            <h3>Inquiries</h3>
          </Row>
          <Card className="antCardBorder antCard" style={{ marginTop: 2 }}>
            {details?.inquiries
              .slice(0, showAll ? undefined : 1)
              .map((inquiry, index) => (
                <div style={{ marginTop: 12 }} key={index}>
                  <div className="conduct_case_inquiry_container">
                    <p className="conduct_case_inquiry_by">Parent</p>
                    <p className="conduct_case_inquiry_at">
                      {formatedDate(inquiry.inquiredAt)}
                    </p>
                    <p className="conduct_case_inquiry">{inquiry?.inquiry}</p>
                    <p className="conduct_case_inquiry_by">Respond</p>
                    {details?.currentStage?.status?.code === "SCC2300" &&
                    !inquiry.respondAt ? (
                      <p className="conduct_case_inquiry">Waiting Response</p>
                    ) : (
                      <>
                        <p className="conduct_case_inquiry_at">
                          {formatedDate(inquiry.respondAt)}
                        </p>
                        <p className="conduct_case_respond">
                          {inquiry?.response}
                        </p>
                      </>
                    )}
                  </div>
                  <br />
                </div>
              ))}
            {!showAll && (
              <p
                onClick={() => handleShowAll(false)}
                className="show_all_button"
              >
                Show All
              </p>
            )}
            {showAll && (
              <p
                onClick={() => handleShowAll(true)}
                className="show_all_button"
              >
                Show Less
              </p>
            )}
          </Card>
        </div>
      )}
      <CustomModal
        visibility={isCancelCase}
        handleCancel={() => setIsCancelCase(false)}
        handleOk={() => null}
        title={`Add Notes For Case Cancellation`}
        modalSmall={true}
      >
        <Form name={cancelCaseForm} onFinish={onFinishCancelCase}>
          <Row>
            <Col md={24}>
              <Form.Item
                name="notes"
                rules={[
                  {
                    required: true,
                    message: "Notes are required",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "adding..." : "Add Notes"}
            </Button>
          </Row>
        </Form>
      </CustomModal>
      <CustomModal
        visibility={openDeclineModal}
        handleCancel={() => setOpenDeclineModal(false)}
        handleOk={() => null}
        title={`Decline Case`}
        modalSmall={true}
      >
        <Form
          name={declineCaseForm}
          onFinish={onDeclineCase}
          form={declineCaseForm}
        >
          <Row>
            <Col md={24}>
              <Label>Notes</Label>
              <Form.Item
                name="notes"
                rules={[
                  {
                    required: true,
                    message: "Notes are required",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </CustomModal>
      <CustomModal
        visibility={isOpenInquireModal}
        handleCancel={() => setIsOpenInquireModal(false)}
        handleOk={() => null}
        title={`Respond to Parent's Inquiry`}
        modalSmall={true}
      >
        <Form
          name={inquireCaseForm}
          onFinish={onReturnToParent}
          form={inquireCaseForm}
        >
          <Row>
            <Col md={24}>
              <Label>Notes</Label>
              <Form.Item
                name="notes"
                rules={[
                  {
                    required: true,
                    message: "Notes are required",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea placeholder="Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </CustomModal>
      <CustomModal
        visibility={noteForApproval}
        handleCancel={() => setnoteForApproval(false)}
        handleOk={() => null}
        title={"Approval Process & Stage Selection"}
        modalSmall={true}
      >
        <Form
          name={notesApprovalForm}
          onFinish={onSubmitNotesForApproval}
          form={notesApprovalForm}
        >
          <Row>
            <Col>
              <Checkbox
                value={noApprovalNeeded}
                onChange={(e) => setNoApprovalNeeded(e.target.checked)}
              >
                No Approval Needed
              </Checkbox>
            </Col>
          </Row>
          <br />
          {!noApprovalNeeded && (
            <>
              <h5
                style={{
                  marginTop: 30,
                  marginBottom: 20,
                }}
              >
                Select Approval Stages
              </h5>
              <p style={{ lineHeight: "normal" }}>
                Please select the required approval stages for this case. Each
                stage represents a step in the review process. You can also add
                any necessary notes for the approvers to provide context or
                instructions.
              </p>
            </>
          )}
          {!noApprovalNeeded && (
            <Row>
              {stages.map((stage, index) => (
                <Col key={index} md={24}>
                  <Form.Item>
                    <Checkbox
                      disabled={stage.slug === "final_approval"}
                      checked={stage.isChecked}
                      onChange={() => handleAssignStage(stage.id)}
                    >
                      {stage.title}
                    </Checkbox>
                  </Form.Item>
                </Col>
              ))}
            </Row>
          )}
          <Row>
            <Col md={24}>
              <Label>Notes</Label>
              <Form.Item
                name="notes"
                rules={[
                  {
                    // required: true,
                    message: "Notes are required",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea placeholder="Add any notes or instructions for the next approver" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </CustomModal>
      <CustomModal
        visibility={noteForStageApproval}
        handleCancel={() => setNoteForStageApproval(false)}
        handleOk={() => null}
        title={"Add Notes"}
        modalSmall={true}
      >
        <Form
          name={notesStageApprovalForm}
          onFinish={onSubmitNotesForStageApproval}
          form={notesStageApprovalForm}
        >
          <Row>
            <Col md={24}>
              <Label>Notes</Label>
              <Form.Item
                name="notes"
                rules={[
                  {
                    required: true,
                    message: "Notes are required",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea placeholder="Notes" />
              </Form.Item>
              {finalApproval && (
                <>
                  <Label>Action</Label>
                  <Form.Item
                    name="action"
                    rrules={[
                      {
                        required: true,
                        message: "Action are required",
                        whitespace: true,
                      },
                    ]}
                  >
                    <TextArea placeholder="Add here" />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              className="ant__button_primary"
            >
              {loading ? "saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </CustomModal>
    </div>
  );
};

export default SccHeader;
