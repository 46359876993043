import React, { useEffect } from "react";
import { Button, Form, Input, Select, Row, Col, Image } from "antd";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import placeholderImage from "../../../assets/img/default.png";

const { Option } = Select;
const OnBoardingForm = ({
  onSubmit,
  loading,
  formName,
  onCloseForm,
  uploading,
  onChooseFile,
  file,
  uploadingAr,
  fileAr,
}) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={setRules("Title (EN)")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="Add here" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Sorting"}
              name={"sorting"}
              // rules={setRules("Sorting")}
            >
              <Input placeholder="Add here" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Form.Item
              label={"Description (EN)"}
              name={"description"}
              rules={[
                { required: true, message: "Description (EN) is required" },
              ]}
              valuePropName={"data"}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "en",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"description"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Form.Item
              label={"Description (AR)"}
              name={"descriptionAr"}
              valuePropName={"data"}
              rules={[
                { required: true, message: "Description (AR) is required" },
              ]}
              getValueFromEvent={(event, editor) =>
                editor ? editor.getData() : ""
              }
            >
              <CKEditor
                config={{
                  language: "ar",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                key={"descriptionAr"}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align="bottom" gutter={[10]}>
          <Col md={12}>
            <p>Image (En)</p>
            <Row align="bottom" gutter={[10]}>
              <Col>
                {<Image width={"10rem"} src={file ?? placeholderImage} />}
              </Col>

              <input
                type="file"
                onChange={(e) => onChooseFile(e, "fileEn")}
                name="image"
                required={!file}
                style={{ marginLeft: 10 }}
                accept="image/png, image/gif, image/jpeg"
              />
              <br />
            </Row>
          </Col>
          <Col md={12}>
            <p>Image (Ar)</p>

            <Row align="bottom" gutter={[10]}>
              <Col>
                {<Image width={"10rem"} src={fileAr ?? placeholderImage} />}
              </Col>
              <input
                type="file"
                onChange={(e) => onChooseFile(e, "fileAr")}
                name="imageAr"
                required={!fileAr}
                style={{ marginLeft: 10 }}
                accept="image/png, image/gif, image/jpeg"
              />
              <br />
            </Row>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading || uploading || uploadingAr}
            // type="primary"
            className="ant__primary__button"
          >
            {loading
              ? "Updating..."
              : uploading || uploadingAr
              ? "Uploading"
              : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default OnBoardingForm;
