/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import {
  Button,
  Form,
  Input,
  Row,
  Tooltip,
  Popconfirm,
  Select,
  Spin,
  Checkbox,
  Col,
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSyncJobs,
  searchSyncJobAction,
  addSyncJobAction,
  updateSyncJobAction,
  deleteSyncJobAction,
} from "actions/sync-jobs/syncJobActions";
import { renderLoader } from "utils/helpers/helpers";
import _, { set } from "lodash";
import { Capitalize } from "utils/helpers/helpers";
import { convertToTitleCase } from "utils/helpers/helpers";

import { useHistory } from 'react-router-dom';


const ModalType = {
  ADD: "Add",
  EDIT: "Update",
};

const userRoles = [
  { value: "employee", title: "Employee" },
  { value: "super-admin", title: "Administrator" },
  { value: "facility-employee", title: "Booking Coordinator" },
];

const authenticationMethods = [
  { value: "sso", title: "SSO" },
  { value: "password", title: "Password" },
];
const OccurUnit = {
  Hour: "hour",
  Minute: "minute",
  Second: "second",
};

// Convert enum to an array of options for Select component
const occurUnitOptions = Object.keys(OccurUnit).map((key) => ({
  value: OccurUnit[key],
  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
}));

const syncJobDurationUnitOptions = [
  { value: "every minute", label: "Every Minute" },
  { value: "every hour", label: "Every Hour" },
  { value: "every day", label: "Every Day" },
  { value: "every week", label: "Every Week" },
  { value: "every month", label: "Every Month" },
  { value: "every year", label: "Every Year" },
];

const { Option } = Select;

const SyncJobs = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.syncJobs.syncJobs);
  const history = useHistory();



  console.log(users, "all users");
  const totalRecords = useSelector((state) => state.syncJobs.syncJobTotal);
  const loading = useSelector((state) => state.syncJobs.loading);
  const [userRole, setUserRole] = React.useState("");
  const [hasCashierAccess, setHasCashierAccess] = React.useState(false);
  const [hasCancelAccess, setHasCancelAccess] = React.useState(false);
  const [hasCmsAccess, setHasCmsAccess] = React.useState(false);
  const [hasTemplatesAccess, sethastemplateaccess] = React.useState(false);
  const [hasSccAccess, setHasSccAccess] = React.useState(false);
  const [hasDocumentGeneratorAccess, setHasSccDocumentGenerator] =
    React.useState(false);
  const [hasFSMAccess, setHasFSMAccess] = React.useState(false);

  const [showModal, toggleModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [editUserId, setEditUserId] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [syncJobForm] = Form.useForm();

  const [hidePassword, setHidePassword] = React.useState(true);
  const [passwordRequired, setPasswordRequired] = React.useState(false);

  const [occurEveryUnit, setOccurEveryUnit] = React.useState(OccurUnit.Hour); // Default to Hour
  const [durationText, setDurationText] = React.useState("Every Hour"); // Default duration text

  const handleOccurEveryUnitChange = (value) => {
    setOccurEveryUnit(value);
    console.log(value, "this selction");
    switch (value) {
      case OccurUnit.Hour:
        syncJobForm.setFieldsValue({
          occurEveryUnit: value,
          duration: "Every Hour",
        });
        break;
      case OccurUnit.Minute:
        syncJobForm.setFieldsValue({
          occurEveryUnit: value,
          duration: "Every Minute",
        });
        break;
      case OccurUnit.Second:
        syncJobForm.setFieldsValue({
          occurEveryUnit: value,
          duration: "Every Second",
        });
        break;
      default:
        setDurationText("Every Hour");
    }
  };

  React.useEffect(() => {
    dispatch(getAllSyncJobs(page));
  }, [page]);

  const fetchRecords = (page) => {
    setPage(page);
  };

  const onEdit = (item) => {
    console.log(item, "item");
    toggleModal(true);
    syncJobForm.setFieldsValue(item);

    setEditUserId(item.id);
    setModalTitle(ModalType.EDIT);
  };
  const onDelete = (item) => {
    dispatch(deleteSyncJobAction(item.id));
  };

  const onAdd = (item) => {
    toggleModal(true);
    setHidePassword(true);
    setHasCancelAccess(false);
    setHasCashierAccess(false);
    setHasCmsAccess(false);
    sethastemplateaccess(false);
    setHasSccDocumentGenerator(false);
    setHasSccAccess(false);
    setHasFSMAccess(false);
    setModalTitle(ModalType.ADD);
    syncJobForm.resetFields();
  };
  const onSubmitForm = (values) => {
    let p = {
      name: "Testing Sync 2",
      duration: "every minute",
      syncTable: "Temp Table",
      isEnable: false,
      source: "Fist production job",
      message: "Production job ready to be deployed every 2 minutes",
      occurEveryValue: 1,
      occurEveryUnit: "minute",
    };
    console.log(p, "values", values);
    let params = {
      ...values,
      occurEveryValue: parseInt(values.occurEveryValue),
    };

    if (modalTitle === ModalType.EDIT) {
      params = { ...params, id: editUserId };
      dispatch(
        updateSyncJobAction(params, () => {
          toggleModal(false);
          setModalTitle("");
          syncJobForm.resetFields();
        })
      );
    } else if (modalTitle === ModalType.ADD) {
      dispatch(
        addSyncJobAction(params, () => {
          toggleModal(false);
          setModalTitle("");
          syncJobForm.resetFields();
        })
      );
    } else return;
  };

  const onDebounceSearch = _.debounce(
    (keyword) => dispatch(searchSyncJobAction(keyword)),
    800
  );


    const viewApplication = (record) => {
      history.push({
        pathname: `sync-jobs/details`,
        state: { applicationId: record.syncTable },
      });
    }
    

  const renderHeader = () => {
    return (
      <div>
        <TableHeader
          onAddNew={onAdd}
          headerTitle={"All Sync Jobs"}
          headerSubTitle={"Manage Jobs"}
          headerBtnTitle={"Add New"}
        />
        <Input
          style={{ width: "50%" }}
          placeholder="Search SyncJobs"
          onChange={(e) => onDebounceSearch(e.target.value)}
        ></Input>
      </div>
    );
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          data={users}
          header={renderHeader}
          fetchRecords={fetchRecords}
          totalRecord={totalRecords}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "id",
            },
            {
              title: "Duration",
              dataIndex: "duration",
              key: "id",
            },
            {
              title: "Sync Table",
              dataIndex: "syncTable",
              key: "id",
            },
            {
              title: "Active",
              dataIndex: "isEnable",
              key: "id",
              render: (record) => (
                <p style={{ textAlign: "center" }}>{record ? "Yes" : "No"}</p>
              ),
            },
            {
              title: "Source",
              dataIndex: "source",
              key: "source",
              render: (record) => (
                <p style={{ textAlign: "center" }}>{record ? "Yes" : "No"}</p>
              ),
            },
            {
              title: "Message",
              dataIndex: "message",
              key: "message",
              // render: (record) => (
              //   <p style={{ textAlign: "center" }}>{record ? "Yes" : "No"}</p>
              // ),
            },
            {
              title: "Value",
              dataIndex: "occurEveryValue",
              key: "occurEveryValue",
              // render: (record) => Capitalize(record),
            },
            {
              title: "Occur Every Unit",
              dataIndex: "occurEveryUnit",
              key: "occurEveryUnit",
              // render: (record) => convertToTitleCase(record),
            },
            // {
            //   title: "Role",
            //   dataIndex: "role",
            //   key: "role",
            // },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="View Application">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EyeOutlined />}
                          onClick={() => viewApplication(item)}
                        />
                      </Tooltip>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update User">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => onEdit(item)}
                          // onClick={(e) => handleEdit(e, record)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this user?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => onDelete(item)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={() => toggleModal(false)}
          handleOk={() => null}
          title={`${modalTitle} User`}
        >
          <Form form={syncJobForm} layout="vertical" onFinish={onSubmitForm}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Duration" name="duration">
                  <Input value={durationText} readOnly placeholder="Duration" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Occur Every Value"
                  name="occurEveryValue"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the occurrence value",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Occur Every Value" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Occur Every Unit"
                  name="occurEveryUnit"
                  rules={[
                    {
                      required: true,
                      message: "Please select the occurrence unit",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Occurrence Unit"
                    value={occurEveryUnit}
                    onChange={handleOccurEveryUnitChange}
                  >
                    {occurUnitOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Sync Table"
                  name="syncTable"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the sync table",
                    },
                  ]}
                >
                  <Input placeholder="Sync Table" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Is Enabled"
                  name="isEnable"
                  valuePropName="checked"
                >
                  <Checkbox>Enabled</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Source"
                  name="source"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Source",
                    },
                  ]}
                >
                  <Input placeholder="Source" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the message",
                    },
                  ]}
                >
                  <Input placeholder="Message" />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : modalTitle}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default SyncJobs;
