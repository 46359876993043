import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Spin,
  Tabs,
} from "antd";
import ApplicationInformationForm from "components/Forms/ApplicationInformationForm";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import * as React from "react";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import styles from "styles/applicationDetails.module.css";
import { renderLoader } from "utils/helpers/helpers";
import { applicationFeedback } from "apis/applications-apis/applications";
import { NotificationManager } from "react-notifications";
import { showValidationError } from "utils/helpers/helpers";
import { applicationProcess } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import FormGroupItemForDocuments from "components/FormGroupItemForDocuemnts/FormGroupItemForDocuments";
import { getSignUrl } from "apis/get-sign-url/getSignUrl";
import { documentsFeedback } from "apis/applications-apis/applications";
import axios from "axios";
import { deleteAdditionalDocumentAPI } from "apis/applications-apis/applications";
import { _deleteAdditionalDocuments } from "actions/applications/applications-actions";
import CreateNewDocumentListForm from "components/Forms/CreateNewDocumentListForm";
import CustomModal from "components/CustomModal/CustomModal";
import { addAdditionalDocumentsAPI } from "apis/applications-apis/applications";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { PopconfirmProps } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CancelApplicationForm from "components/Forms/CancelApplicationForm";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import SchEvaluationTabDetails from "components/sch/evaluation/SchEvaluationTabDetails";
import TCCCursesTabDetails from "components/tcc-courses-tab/TCCCursesTabDetails";
import TCCCursesReadOnlyTabDetails from "components/tcc-courses-tab/TCCCoursesReadOnlyTabDetails";
import { _toggleAllSectionsAssignedKey } from "actions/applications/applications-actions";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
import { getFileTypeFromUrl } from "utils/helpers/helpers";

const { TabPane } = Tabs;

const TCCReview = (props) => {
  const applicationId = props?.application?.id;
  const [loading, setLoading] = React.useState(false);
  const allSectionAssigned = useSelector(
    (state) => state.allApplications.allSectionAssigned
  );
  // const [activeTab, setActiveTab] = React.useState("tcc_courses");
  const [activeTab, setActiveTab] = React.useState("tcc_father_info");
  const [approveAll, setApproveAll] = React.useState({
    father: false,
    mother: false,
    students: false,
    courses: false,
  });
  const [isFieldsDisabled, setFieldsDisabled] = React.useState(false);
  let [imagesArray, sendToImagesArray] = React.useState([]);
  const [addDocumentModal, showAddDocumentModal] = React.useState(false);
  const [showMarkComplete, setShowMarkComplete] = useState(false);

  const [addNoteForCancel, setAddNoteForCancel] = useState(false);

  const hasCancelAccess = useSelector(
    (state) =>
      state.login.userInfo?.hasCancelAccess ||
      state.login?.userInfo?.role === "super-admin"
  );

  const [fatherInfoForm] = Form.useForm();
  const [motherInfoForm] = Form.useForm();
  const [studentInfoForm] = Form.useForm();
  const [coursesInfoForm] = Form.useForm();
  const [cancelApplicationForm] = Form.useForm();

  const [documentForm] = Form.useForm();
  const [additionalDocumentForm] = Form.useForm();

  const nonAdditionalDocuments = props.application?.documents.filter(
    (doc) => !doc.isAdditionalDocument
  );
  const [childDocuments, setChildDocuments] = React.useState([]);
  const [parentDocuments, setParentDocuments] = React.useState([]);
  const [studentdCourses, setStudentdCourses] = React.useState([]);
  const [additionalDocuments, setAdditonalDocuments] = React.useState();
  const [serviceAmount, setServiceAmount] = useState();
  const [isCancelApplicationModalOpen, setIsCancelApplicationModalOpen] =
    useState(false);
  const [dob, setDob] = useState();
  const [file, setFile] = useState();
  const [docType, setDocType] = useState("");

  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    props.refetch();
  }, [applicationId]);

  React.useEffect(() => {
    if (props.application) {
      setDob(props.application?.studentDetails?.dob?.value);
      documentForm.setFieldsValue(props.application.documents);
      let childDocumentsArray = [];
      let parentDocumentsArray = [];
      let additionalDocumentsArray = [];
      props.application.documents.forEach((document, index) => {
        if (document.documentOf === "child" && !document.isAdditionalDocument) {
          childDocumentsArray.push({ ...document, index });
        } else if (
          document.documentOf === "parent" &&
          !document.isAdditionalDocument
        ) {
          parentDocumentsArray.push({ ...document, index });
        } else {
          additionalDocumentsArray.push({ ...document, index });
        }
      });
      setChildDocuments(childDocumentsArray);
      setParentDocuments(parentDocumentsArray);
      setAdditonalDocuments(additionalDocumentsArray);
      setServiceAmount(props.application.serviceFeeAmount);
    }
  }, [props.application]);

  React.useEffect(() => {
    if (props.application) {
      const stageStatus = props.application?.stage?.status?.code;
      setFieldsDisabled(
        !(
          // new application, under review, amendment review, waiting acceptance
          // tcc stages
          (
            stageStatus === "SG2000" ||
            stageStatus === "SG2020" ||
            stageStatus === "SG2040" ||
            stageStatus === "SG2060" ||
            stageStatus === "SG2080"
          )
        )
      );
    }
  }, [isFieldsDisabled, props.application]);

  React.useEffect(() => {
    checkIfAllSectionAssigned();
  }, []);

  const checkIfAllSectionAssigned = () => {
    let allSectionAssigned = true;
    props.application.courses.forEach((_course) => {
      if (!_course?.isAlternative) {
        if (Object.keys(_course?.section).length === 0) {
          allSectionAssigned = false;
        }
      }
    });
    dispatch(_toggleAllSectionsAssignedKey(allSectionAssigned));
  };
  const renderActionButtons = (stageStatus, applicationStatus) => {
    if (props.application?.hasStagePermission) {
      return (
        <div className={styles.appDetailsHeaderBtnHolder}>
          {/* Cancel Application Button */}
          {hasCancelAccess &&
          ["AS001", "AS002", "AS003"].includes(applicationStatus) &&
          props.application?.stage?.slug === "review" ? (
            <Popconfirm
              disabled={applicationStatus === "AS007"}
              placement="topLeft"
              title="Are you sure to cancel this application?"
              onConfirm={() => {
                setIsCancelApplicationModalOpen(true);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button danger type="primary">
                Cancel Application
              </Button>
            </Popconfirm>
          ) : null}
          {["SG2080"].includes(stageStatus) && allSectionAssigned ? (
            <Button
              type="primary"
              onClick={() => {
                handelApplicationProcess("accept_tcc");
              }}
            >
              Accept{" "}
            </Button>
          ) : null}
          {props.application?.status.code !== "AS006" &&
          props.application?.status.code !== "AS007" &&
          props.application?.status.code !== "AS008" ? (
            <Button
              type="danger"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                // handelApplicationProcess("reject_sch");
                setAddNoteForCancel(true);
              }}
            >
              Mark Unaccepted{" "}
            </Button>
          ) : null}

          {/* Accept Application Button */}
          {/* SG140, SG740 = WAITING_ACCEPTANCE */}
          {/* {props.application?.stage?.status?.code === "SG740" ||
            props.application?.stage?.status?.code === "SG140" ||
            props.application?.stage?.status?.code === "SG1040" ? (
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to mark the review completed?"
              onConfirm={markReviewComplete(false)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Mark Review Completed</Button>
            </Popconfirm>
          ) : null} */}

          {/* Cancel Acceptance Button */}
          {props.application.stage?.status.code === "SG1400" ? (
            <Popconfirm
              {...PopconfirmProps}
              title="Are you sure you want to cancel this application?"
              onConfirm={() => {
                handelApplicationProcess("cancel_acceptance");
              }}
            >
              <Button style={{ marginLeft: 10 }}>Cancel Acceptance</Button>
            </Popconfirm>
          ) : null}
        </div>
      );
    } else return null;
  };

  const renderFormActionButtons = (stageStatus) => {
    if (props.application?.hasStagePermission) {
      return (
        <Row justify="end">
          {/* Start Approval Button */}
          {/* Waiting For Approval Initialization */}
          {/* {stageStatus === "SG780" ||
            stageStatus === "SG180" ||
            stageStatus === "SG1080" ? (
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={() =>
                props.history.push("approval-steps", {
                  application: props.application,
                })
              }
            >
              Start Approval Process
            </Button>
          ) : null} */}

          {/* Cancel Amendment Button */}
          {/* Pending Amendment */}
          {stageStatus === "SG2040" ? (
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to cancel amendment?"
              onConfirm={() => {
                handelApplicationProcess("cancel_amendment");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ marginRight: "10px" }}>Cancel Amendment</Button>
            </Popconfirm>
          ) : null}
          {activeTab !== "logs" ? (
            <div style={{ display: "flex" }}>
              {/* Send to Parent Button */}
              {/* under review, amendment review */}
              {["SG2020", "SG2060"].includes(stageStatus) ? (
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to return this application to parent for amendment?"
                  onConfirm={() => {
                    handelApplicationProcess("send_to_parent");
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginRight: "10px" }} type="primary">
                    Return to Parent
                  </Button>
                </Popconfirm>
              ) : null}

              {/* Save Application Button */}
              {/* new application, under review, amendment review, waiting acceptance */}
              {["SG2000", "SG2020", "SG2060", "SG2080"].includes(
                stageStatus
              ) ? (
                <Button type="primary" onClick={onSave}>
                  Save Application
                </Button>
              ) : null}
              <div style={{ display: "flex", marginLeft: "10px" }}>
                {/* Accept sch application */}

                {/* Don't Accept sch application */}
              </div>
            </div>
          ) : null}
        </Row>
      );
    } else return null;
  };
  const handelApplicationProcess = async (action, note) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: props.application?.id,
        action: action,
        // serviceFeeAmount: serviceAmount ? Number(serviceAmount) : null,
        note: note,
        // schoolId: Number(targetSchoolId),
        // expectedDate: expectedDate,
        // enrollmentNotes: enrollmentNotes,
      });
      if (response?.status == 200) {
        setLoading(false);
        setShowMarkComplete(false);
        setServiceAmount();
        successMessage({
          message: "Application status updated successfully",
        });
        if (action === "cancel_amendment" || action === "cancel_acceptance") {
          setFieldsDisabled(false);
        } else {
          setFieldsDisabled(true);
        }
        if (action === "cancel") {
          setIsCancelApplicationModalOpen(false);
        }
        props.refetch();
      } else {
        setLoading(false);
        throw response;
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        props.application.studentAlreadyExists = true;
        props.refetch();
      }
      setServiceAmount();
      setLoading(false);
      // setIsAcceptModal(false);
      await requestErrorHandel({ error: error });
    }
  };

  const markReviewComplete = (serviceFeeAdded) => () => {
    if (!serviceFeeAdded && props.application.eService.allowAmountUpdate) {
      setShowMarkComplete(true);
    } else {
      handelApplicationProcess("review_completed");
    }
  };

  const handleFileInputChange = (e, element, id) => {
    var file = e.target.files[0];
    let oldImagesArray = [...imagesArray];
    oldImagesArray = oldImagesArray.filter((image) => image.element.id != id);

    sendToImagesArray([...oldImagesArray, { element: element, image: file }]);
  };
  const onFinishDocumentsForm = async (val) => {
    if (imagesArray.length) {
      for (let image of imagesArray) {
        const imageURL = await handleUploadFileToENSServer(
          image.image,
          "tccEnrollments"
        );
        const getFieldValue = documentForm.getFieldValue(image.element.index);
        image.element.value = imageURL;
        image.element.note = getFieldValue.note;
        image.element.status = getFieldValue.status;

        documentForm.setFieldsValue({
          [image.element.index]: image.element,
        });
        // var response = await getSignUrl(image.image.type.split("/")[1]);

        // var config = {
        //   method: "put",
        //   url: response.data.data,
        //   headers: {
        //     "Content-Type": image.image.type,
        //   },
        //   data: image.image,
        // };

        // await axios(config).then((res) => {
        //   var list = res.config.url.split("?");
        //   var imageURL = list[0];

        //   const getFieldValue = documentForm.getFieldValue(image.element.index);

        //   image.element.value = imageURL;
        //   image.element.note = getFieldValue.note;
        //   image.element.status = getFieldValue.status;

        //   documentForm.setFieldsValue({
        //     [image.element.index]: image.element,
        //   });
        // });
      }
      const updatedValues = documentForm.getFieldsValue();
      submitDocumentsForm(updatedValues);
    } else {
      let emptyImageFound = false;
      const allImages = Object.values(val);
      allImages.forEach((image) => {
        if (
          image.value === "" &&
          image.status === "approved" &&
          !image.notAvailable
        ) {
          emptyImageFound = true;
        }
      });

      if (emptyImageFound) {
        NotificationManager.error("Please upload all images");
        return;
      }

      try {
        const data = {
          applicationId: applicationId,
          documents: Object.values(val),
        };

        setLoading(true);

        const response = await documentsFeedback(data);

        if (response.data.statusCode === 200 && response.data.data) {
          props.refetch();
          setLoading(false);
          successMessage({ message: "Documents updated successfully" });
        } else setLoading(false);
      } catch (error) {
        setLoading(false);
        await requestErrorHandel({ error: error });
      }
    }
  };
  const onDeleteDocument = async (doc) => {
    try {
      const result = await deleteAdditionalDocumentAPI(doc.id);
      if (result.data.statusCode === 200) {
        successMessage({ message: "Document deleted!" });
      } else throw result.data;
    } catch (e) {
      requestErrorHandel({ error: e });
    }
  };

  const onSubmitAdditionalDocForm = async (val) => {
    setLoading(true);
    let document = {
      applicationId: applicationId,
      documentOf: val?.documentOf,
      documentType: docType,
      documentUrl: file,
    };
    try {
      const result = await addAdditionalDocumentsAPI(document);
      setLoading(false);
      if (result.data.statusCode === 200 && result.data.data) {
        props.refetch();
        showAddDocumentModal(false);
      }
    } catch (e) {
      setLoading(false);
      await requestErrorHandel({ error: e });
    }
  };

  const submitDocumentsForm = async (val) => {
    try {
      const data = {
        applicationId: applicationId,
        documents: Object.values(val),
      };

      setLoading(true);

      const response = await documentsFeedback(data);

      if (response.data.statusCode === 200 && response.data.data) {
        props.refetch();
        setLoading(false);
        successMessage({ message: "Documents uploaded successfully" });
        sendToImagesArray([]);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
      setLoading(false);
    }
  };

  const changeFormData = (val, approveAllFields) => {
    const fieldsArr = Object.values(val);
    if (approveAllFields) {
      fieldsArr.forEach((_, index) => {
        fieldsArr[index].status = "approved";
      });
    }
    if (val["dob"]) {
      val["dob"].value = dob;
    }
    return fieldsArr;
  };

  const onSave = async () => {
    Promise.all([
      fatherInfoForm.validateFields(),
      motherInfoForm.validateFields(),
      studentInfoForm.validateFields(),
    ])
      .then(async (res) => {
        res.forEach((el, index) => {
          if (el.isUAEResident !== undefined) {
            if (el.isUAEResident.status === "needAmendment") {
              if (el.isUAEResident.value === "true") {
                el.passportNumber.status = "needAmendment";
                el.emiratesId.status = "approved";
                el.passportNumber.note = el.isUAEResident.note;
              } else {
                el.passportNumber.status = "approved";
                el.emiratesId.status = "needAmendment";
                el.emiratesId.note = el.isUAEResident.note;
              }
            }
          }
          if (el.schoolInUAE !== undefined) {
            if (el.schoolInUAE.status === "needAmendment") {
              if (el.schoolInUAE.value === "true") {
                el.schoolName.status = "needAmendment";
                el.currentSchool.status = "approved";
                el.schoolName.note = el.schoolInUAE.note;
              } else {
                el.schoolName.status = "approved";
                el.currentSchool.status = "needAmendment";
                el.currentSchool.note = el.schoolInUAE.note;
              }
            }
          }

          //   if (el.educationSystem !== undefined) {
          //     if (el.educationSystem.status === "needAmendment") {
          //       el.gradeLevel.status = "needAmendment";
          //       el.newGrade.status = "needAmendment";
          //       el.gradeLevel.note = el.educationSystem.note;
          //       el.newGrade.note = el.educationSystem.note;
          //     }
          //   }
        });
        // return;
        let allData = [];

        res.forEach((el, index) => {
          let approveAllValue = "";
          switch (index) {
            case 0:
              approveAllValue = approveAll.father;
              break;
            case 1:
              approveAllValue = approveAll.mother;
              break;
            case 2:
              approveAllValue = approveAll.students;
              break;
          }
          if (el && Object.keys(el).length > 0) {
            allData = [...changeFormData(el, approveAllValue), ...allData];
          }
        });

        const transformedCourses = studentdCourses.map((course) => ({
          id: course.id,
          courseId: course.courseId,
          canChangeCourse: course.canChangeCourse,
          canChangeSchedule: course.canChangeCourse
            ? true
            : course.canChangeSchedule,
          isAlternative: course.isAlternative,
          status: course.status,
          note: course.status === "needAmendment" ? course.note : "",
        }));

        try {
          if (allData.length > 0) {
            let response;
            setLoading(true);
            if (props.application.applicationType === "TCC") {
              response = await applicationFeedback({
                applicationId: Number(props.application.id),
                fields: allData,
                courses: transformedCourses,
              });
            } else {
              response = await applicationFeedback({
                applicationId: Number(props.application.id),
                fields: allData,
              });
            }
            if (response.status === 200) {
              setLoading(false);
              NotificationManager.success("Application updated successfully");
              props.refetch();
            } else {
              setLoading(false);
              throw response;
            }
          }
        } catch (e) {
          requestErrorHandel({ error: e });
          setLoading(false);
        }
      })
      .catch((e) => {
        showValidationError(
          `${e.errorFields[0]?.errors[0] || "Please enter all required fields"}`
        );
      });
    documentForm
      .validateFields()
      .then((res) => {
        res && Object.keys(res).length > 0 && onFinishDocumentsForm(res);
      })
      .catch((e) => {
        showValidationError(
          `${
            e.errorFields[0]?.errors[0] || "Please enter all required fields"
          } in documents`
        );
      });
  };
  const onDontAcceptApplication = (values) => {
    setAddNoteForCancel(false);
    handelApplicationProcess("reject_tcc", values.notes);
  };

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} required`,
      },
    ];
  };

  const onFinish = (data) => {
    setStudentdCourses(data);
  };

  const onChooseFile = async (e) => {
    try {
      setUploading(true);
      if (e.target.files.length) {
        let fileType = getFileTypeFromUrl(e.target.files[0].name);
        if (fileType === "unknown") {
          NotificationManager.warning("File not supported");
          return;
        } else {
          const path = await handleUploadFileToENSServer(
            e.target.files[0],
            "tccEnrollments"
          );
          setFile(path);
          setDocType(fileType);
          setUploading(false);
        }
      }
    } catch (error) {
      setUploading(false);
    }
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={renderLoader}>
        {props.application ? (
          <>
            <Card hoverable className="antCardBorder antCard">
              <Row justify="space-between">
                <Col md={12}>
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Col>
                {renderActionButtons(
                  props.application?.stage?.status?.code,
                  props.application?.status?.code
                )}
              </Row>
            </Card>
            <Card
              hoverable
              className="antCardBorder antCard"
              style={{ marginTop: 20, minHeight: "50vh" }}
            >
              <Row justify="space-between" align="middle">
                <Col lg={14} sm={24}>
                  <h5>Application Details</h5>
                  {/* {props.application.applicationType === "SCH" && (
                    <h6>Request For: {props.application.target}</h6>
                  )} */}
                  <br />
                  {props.application?.hasStagePermission && (
                    <p>
                      Please review all the details and send it to parent. If
                      everything is okay, please mark as approved to send it to
                      next phase.
                    </p>
                  )}
                </Col>

                <Col lg={4} sm={24} md={24}>
                  {renderFormActionButtons(
                    props.application?.stage?.status?.code
                  )}
                </Col>
              </Row>
              <Tabs
                activeKey={activeTab}
                onChange={(tab) => setActiveTab(tab)}
                style={{ marginTop: "1rem" }}
                tabBarGutter={40}
                // centered
              >
                <TabPane tab={<h6>Father</h6>} key="tcc_father_info">
                  {props.application?.hasStagePermission ? (
                    <ApplicationInformationForm
                      application={props.application}
                      type={"tcc_father_info"}
                      isFieldsDisabled={isFieldsDisabled}
                      form={fatherInfoForm}
                      onApproveAll={(value) =>
                        setApproveAll({ ...approveAll, father: value })
                      }
                      dropDowns={[]}
                    />
                  ) : (
                    <ApplicationInformationReadOnlyForm
                      application={props.application}
                      type={"tcc_father_info"}
                    />
                  )}
                </TabPane>
                <TabPane tab={<h6>Mother</h6>} key="tcc_mother_info">
                  {props.application?.hasStagePermission ? (
                    <ApplicationInformationForm
                      application={props.application}
                      type={"tcc_mother_info"}
                      isFieldsDisabled={isFieldsDisabled}
                      form={motherInfoForm}
                      onApproveAll={(value) =>
                        setApproveAll({ ...approveAll, mother: value })
                      }
                      dropDowns={[]}
                    />
                  ) : (
                    <ApplicationInformationReadOnlyForm
                      application={props.application}
                      type={"tcc_mother_info"}
                    />
                  )}
                </TabPane>

                <TabPane tab={<h6>Student info</h6>} key="tcc_students_info">
                  {props.application?.hasStagePermission ? (
                    <ApplicationInformationForm
                      application={props.application}
                      type={"tcc_students_info"}
                      isFieldsDisabled={isFieldsDisabled}
                      form={studentInfoForm}
                      onApproveAll={(value) =>
                        setApproveAll({ ...approveAll, students: value })
                      }
                      onChangeDob={(str) => setDob(str)}
                      dropDowns={[]}
                    />
                  ) : (
                    <ApplicationInformationReadOnlyForm
                      application={props.application}
                      type={"tcc_students_info"}
                    />
                  )}
                </TabPane>
                <TabPane tab={<h6>Courses</h6>} key="tcc_courses">
                  {props.application?.hasStagePermission ? (
                    <TCCCursesTabDetails
                      application={props.application}
                      isFieldsDisabled={isFieldsDisabled}
                      onFinish={onFinish}
                      form={coursesInfoForm}
                    />
                  ) : (
                    <TCCCursesReadOnlyTabDetails
                      application={props.application}
                    />
                  )}
                </TabPane>

                <TabPane tab={<h6>Documents</h6>} key="documents">
                  <Form name="dynamic_form_nest_item" form={documentForm}>
                    {props.application?.hasStagePermission ? (
                      <Row
                        gutter={[30, 10]}
                        className="documentFormItemRow"
                        // justify="end"
                      >
                        <Col md={12}>
                          <h6 style={{ marginBottom: "3rem" }}>
                            Parent Documents
                          </h6>
                          {parentDocuments.map((document, i) => {
                            return (
                              <Card
                                hoverable
                                className="documentInputCardItem"
                                key={i}
                              >
                                <FormGroupItemForDocuments
                                  key={i}
                                  doc={document}
                                  handleFileInputChange={handleFileInputChange}
                                  onDelete={onDeleteDocument}
                                  isFieldsDisabled={isFieldsDisabled}
                                  form={documentForm}
                                />
                              </Card>
                            );
                          })}
                        </Col>

                        <Col md={12}>
                          <h6 style={{ marginBottom: "3rem" }}>
                            Child Documents
                          </h6>
                          {childDocuments.map((document, i) => {
                            return (
                              <Card
                                hoverable
                                className="documentInputCardItem"
                                key={i}
                              >
                                <FormGroupItemForDocuments
                                  key={i}
                                  doc={document}
                                  handleFileInputChange={handleFileInputChange}
                                  onDelete={onDeleteDocument}
                                  isFieldsDisabled={isFieldsDisabled}
                                  form={documentForm}
                                />
                              </Card>
                            );
                          })}
                        </Col>

                        {additionalDocuments?.length > 0 && (
                          <Col md={12}>
                            <h6>Additional Documents</h6>
                            {additionalDocuments.map((document, i) => {
                              return (
                                <Card
                                  hoverable
                                  className="documentInputCardItem"
                                >
                                  <FormGroupItemForDocuments
                                    key={i}
                                    doc={document}
                                    handleFileInputChange={
                                      handleFileInputChange
                                    }
                                    onDelete={onDeleteDocument}
                                    isFieldsDisabled={isFieldsDisabled}
                                    form={documentForm}
                                  />
                                </Card>
                              );
                            })}
                          </Col>
                        )}
                        <Col md={12}>
                          <Card>
                            <div className={styles.additionalDocumentsHeading}>
                              <h6 className={styles.documentsOwnerHeading}>
                                Additional Document (If required)
                              </h6>
                              <Button
                                disabled={isFieldsDisabled}
                                type="primary"
                                onClick={() => showAddDocumentModal(true)}
                              >
                                Add New
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      <DocumentsReadOnlyForm application={props.application} />
                    )}
                  </Form>
                </TabPane>

                <TabPane tab={<h6>Stages History</h6>} key="logs">
                  <ApplicationLogs application={props.application} />
                </TabPane>
              </Tabs>
            </Card>
          </>
        ) : (
          <div>{renderNoDataPlaceholder(loading)}</div>
        )}
        <CancelApplicationForm
          visibility={isCancelApplicationModalOpen}
          handleCancel={() => setIsCancelApplicationModalOpen(false)}
          onSubmit={(notes) => handelApplicationProcess("cancel", notes)}
          loading={loading}
        />
        <CustomModal
          visibility={addDocumentModal}
          handleCancel={() => showAddDocumentModal(false)}
          handleOk={() => showAddDocumentModal(false)}
          title={<>Additional Document</>}
          modalSmall={true}
        >
          <Form
            layout={"vertical"}
            // initialValues={{ layout: formLayout }}
            size={"middle"}
            form={additionalDocumentForm}
            onFinish={onSubmitAdditionalDocForm}
          >
            <CreateNewDocumentListForm
              uploading={uploading}
              file={file}
              handelPickFile={onChooseFile}
              // valuesForEdit={editApplicationFields}
              documentFor={["parent", "child"]}
              form={additionalDocumentForm}
              loading={loading}
            />
          </Form>
        </CustomModal>
        <CustomModal
          visibility={showMarkComplete}
          handleCancel={() => showMarkComplete(false)}
          title={<>Mark Review Complete</>}
        >
          <p>Service Fee Amount</p>
          <Input
            value={serviceAmount}
            placeholder="Enter service amount"
            onChange={(e) => setServiceAmount(e.target.value)}
            type={"number"}
          />
          <Button
            loading={loading}
            style={{ marginTop: 10 }}
            onClick={markReviewComplete(true)}
          >
            {loading ? "Saving..." : "Done"}
          </Button>
        </CustomModal>
        <CustomModal
          visibility={addNoteForCancel}
          handleCancel={() => setAddNoteForCancel(false)}
          title={<>Add Notes</>}
          modalSmall={true}
        >
          <Form
            layout="vertical"
            onFinish={onDontAcceptApplication}
            form={cancelApplicationForm}
          >
            <Row gutter={10}>
              <Col md={24}>
                <Form.Item
                  name={"notes"}
                  label="Notes"
                  rules={setRules("Notes")}
                >
                  <Input.TextArea
                    placeholder="Notes"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default TCCReview;
