import { Button, Input } from "antd";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NotificationManager } from "react-notifications";

const CancelApplicationForm = ({
  onSubmit,
  loading,
  visibility,
  handleCancel,
}) => {
  const [cancellationNotes, setCancellationNotes] = useState("");

  useEffect(() => {
    if (!visibility) setCancellationNotes("");
  }, [visibility]);

  return (
    <div style={{ width: "100px !important" }}>
      <CustomModal
        visibility={visibility}
        handleCancel={handleCancel}
        modalSmall={true}
      >
        <div style={{ marginTop: 10 }}>
          <p>Add note for cancellation reason</p>
          <Input.TextArea
            value={cancellationNotes}
            placeholder="Cancellation notes"
            onChange={(e) => setCancellationNotes(e.target.value)}
          ></Input.TextArea>
        </div>

        <Button
          onClick={() => {
            if (cancellationNotes == "") {
              NotificationManager.error("Please add a note");
              return;
            }
            onSubmit(cancellationNotes);
          }}
          type="default"
          style={{ marginTop: "20px", marginRight: "20px" }}
          loading={loading}
        >
          {loading ? "Cancelling...." : "Submit"}
        </Button>
      </CustomModal>
    </div>
  );
};

export default CancelApplicationForm;
