export const GET_ALL_APPLICATIONS = "GET_ALL_APPLICATIONS";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const DELETE_ADDITONAL_DOCUMENT = "DELETE_ADDITONAL_DOCUMENT";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const SAVE_APPLICATION_ID = "SAVE_APPLICATION_ID";
export const TCC_ALL_SECTIONS_ASSIGNED = "TCC_ALL_SECTIONS_ASSIGNED";

const getAllApplications = (data) => {
  return {
    type: GET_ALL_APPLICATIONS,
    payload: data,
  };
};

export const _getAllApplications = (data) => {
  return (dispatch) => {
    dispatch(getAllApplications(data));
  };
};

export const _updateApplications = (id, data) => {
  return (dispatch) => {
    dispatch(updateApplications(id, data));
  };
};

export const _deleteApplication = (id) => {
  return (dispatch) => {
    dispatch(deleteApplication(id));
  };
};

const deleteApplication = (id) => {
  return {
    type: DELETE_APPLICATION,
    id: id,
  };
};

export const _saveApplicationId = (id) => {
  return (dispatch) => dispatch(saveApplicationId(id));
};

const saveApplicationId = (id) => {
  return {
    type: SAVE_APPLICATION_ID,
    payload: id,
  };
};

const updateApplications = (id, data) => {
  return {
    type: UPDATE_APPLICATION,
    id: id,
    payload: data,
  };
};

export const _deleteAdditionalDocuments = (data) => {
  return (dispatch) => dispatch(deleteAdditionalDocument(data));
};

const deleteAdditionalDocument = (data) => {
  return {
    type: DELETE_ADDITONAL_DOCUMENT,
    payload: data,
  };
};

const toggleAllSectionsAssignedKey = (key) => {
  return {
    type: TCC_ALL_SECTIONS_ASSIGNED,
    payload: key,
  };
};

export const _toggleAllSectionsAssignedKey = (key) => {
  return (dispatch) => {
    dispatch(toggleAllSectionsAssignedKey(key));
  };
};
