/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { FrownOutlined } from "@ant-design/icons";
import { Select, Spin, Card, Tabs, Row } from "antd";
import { getApplicationStagesStats } from "apis/applications-apis/applications";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col } from "reactstrap";
import { requestErrorHandel } from "utils/helpers/helpers";
import { useSelector } from "react-redux";
import DashboardStageStatus from "components/ApplicationStageCardStats/ApplicationStageCardStats";
import DashboardApplicationStatuses from "components/DashboardApplicationStatuses/DashboardApplicationStatuses";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
import "./Dashboard.css";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { LOCAL_STORAGE_KEYS } from "utils/constants";

const { Option } = Select;
const { TabPane } = Tabs;

const Dashboard = (props) => {
  const _terms = useSelector((state) => state.metaData.terms);
  const _campuses = useSelector((state) => state.metaData.campuses);
  const _permissions = useSelector(
    (state) => state.login.userInfo?.permissions
  );

  const _asrEservices = useSelector((state) => state.metaData.asrEservices);
  const [data, setData] = useState();
  const [selectedCampus, setSelectedCampus] = useState("");
  // const [selectedTerm, setSelectedTerm] = useState(
  //   _terms.find((t) => t.currentYear)?.id
  // );
  const [selectedTerm, setSelectedTerm] = useState("");
  const [selectedEService, setEService] = useState("all");
  const [ASRItem, selectASRItem] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState();
  const [hasNoPermission, setHasNoPermission] = useState(false);

  const eServiceParam = selectedEService === "all" ? "" : selectedEService;

  // useEffect(() => {
  //   if (_terms !== null) setSelectedTerm(_terms.find((t) => t.currentYear)?.id);
  // }, [_terms]);

  useEffect(() => {
    let preSelectedTab = localStorage.getItem(LOCAL_STORAGE_KEYS.DASHBOARD_TAB);
    if (preSelectedTab) {
      setActiveTab(preSelectedTab);
    } else if (_permissions) {
      let hasPermission = ["ENR", "ASR", "TRP", "TFD", "UPD"].find(
        (application) => _permissions[application] == true
      );
      hasPermission ? setActiveTab(hasPermission) : setHasNoPermission(true);
    }
  }, [_permissions]);

  useEffect(() => {
    fetchApplicationStageStats();
  }, [selectedTerm, selectedCampus, selectedEService, ASRItem]);

  const fetchApplicationStageStats = async () => {
    if (_permissions) {
      setLoading(true);
      let permissionArray = Object.entries(_permissions);
      if (permissionArray.length > 0) {
        permissionArray = permissionArray.filter(([_, value]) => value);
        setHasNoPermission(permissionArray.length === 0);
        const responses = permissionArray.map(async ([type, value]) => {
          try {
            let response = await getApplicationStagesStats({
              term: selectedTerm,
              campus: selectedCampus,
              eService: eServiceParam,
              applicationType: type.toLowerCase(),
              item: ASRItem,
            });
            setLoading(false);
            if (response.status === 200) {
              let data = {
                type: type,
                path: getPath(type),
                title: getTitle(type.toLowerCase()),
                stats: response.data.data.applicationStatics,
                stages: response.data.data.stagesStates,
              };
              return data;
            }
          } catch (e) {
            setLoading(false);
            requestErrorHandel({ error: e });
          }
        });
        Promise.all(responses).then((values) => {
          let updatedValues = [...values];
          let enrApplications = updatedValues.findIndex(
            (value) => value?.type === "ENR"
          );
          if (enrApplications > -1) {
            updatedValues.splice(enrApplications, 1);
            updatedValues.splice(0, 0, values[enrApplications]);
          }
          setData(updatedValues);
        });
      }
    } else {
      setHasNoPermission(true);
      setLoading(false);
    }
  };

  const onSelectCampus = (val) => {
    setSelectedCampus(val);
  };
  const onSelectTerms = (val) => {
    setSelectedTerm(val);
    setEService("all");
  };
  const onSelectEService = (value) => {
    setEService(value);
    selectASRItem("");
  };
  const onChangeASRItem = (value) => selectASRItem(value);

  const filterApplicationsByApplicationStatus = (
    applicationStatus,
    type,
    path
  ) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.DASHBOARD_TAB, type);
    props.history.push(path, {
      serviceType: type,
      dashboardCampus: selectedCampus,
      dashboardTerm: selectedTerm,
      dashboardApplicationStatus: applicationStatus,
      fromDashboard: true,
      eService: eServiceParam,
      asrItem: ASRItem,
    });
  };
  const filterDataByStageStatus = (
    slug,
    dashboardGroupStatus,
    code,
    type,
    path
  ) => {
    const dashboardStageSlug =
      slug === "application_fee" ? "registration" : slug;
    const dashboardStageStatus =
      slug === "application_fee" ||
      (slug === "application_posting" && (code === "SG617" || code === "SG618"))
        ? code
        : "";
    localStorage.setItem(LOCAL_STORAGE_KEYS.DASHBOARD_TAB, type);
    props.history.push(path, {
      serviceType: type,
      dashboardCampus: selectedCampus,
      dashboardTerm: selectedTerm,
      dashboardApplicationStatus: "",
      dashboardStage: dashboardStageSlug,
      dashboardStageStatus: dashboardStageStatus,
      fromDashboard: true,
      dashboardGroupStatus:
        slug !== "application_fee" &&
        !(
          slug == "application_posting" &&
          (code === "SG617" || code === "SG618")
        )
          ? "DASHBOARD:" + dashboardGroupStatus
          : "",
      eService: eServiceParam,
      asrItem: ASRItem,
    });
  };
  const getPath = (type) => {
    if (!type) {
      return "";
    }
    if (type === "TFD") {
      return `/admin/discount-applications`;
    } else if (type === "UPD") {
      return `/admin/update-details-applications`;
    } else return `/admin/${type.toLowerCase()}-applications`;
  };

  const getTitle = (type) => {
    switch (type) {
      case "enr":
        return "Enrollment";
      case "asr":
        return "Additional Services";
      case "trp":
        return "Transportation";
      case "tfd":
        return "Discount";
      case "upd":
        return "Update Details";
      case "sch":
        return "Scholarship";
      case "tcc":
        return "TCC Enrollment";
      default:
        return "";
    }
  };
  const renderStats = (dataItem) => {
    return dataItem ? (
      <div>
        {dataItem.stats && (
          <DashboardApplicationStatuses
            type={dataItem.type}
            applicationStatics={dataItem.stats}
            filterApplicationsByApplicationStatus={(code) =>
              filterApplicationsByApplicationStatus(
                code,
                dataItem.type,
                dataItem.path
              )
            }
          />
        )}
        <div style={{ padding: "1rem 0" }}></div>
        <Row>
          {dataItem.stages?.map((item, i) => {
            return (
              <Col sm={12} md={4} lg={4} key={i}>
                <DashboardStageStatus
                  key={i}
                  item={item}
                  onClick={(slug, groupStatus, code) => {
                    filterDataByStageStatus(
                      slug,
                      groupStatus,
                      code,
                      dataItem.type,
                      dataItem.path
                    );
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    ) : null;
  };

  const renderASRDropdowns = () => {
    return (
      <Row
        justify="space-between"
        style={{
          margin: "0.5rem 0 1rem 0",
        }}
      >
        <Col>
          <strong>Select Service</strong>
          <div className="dropdown mt-1">
            <Select
              placeholder={"Filter By EService"}
              onChange={onSelectEService}
              bordered={false}
              disabled={hasNoPermission}
              value={selectedEService}
              style={{ width: "100%" }}
            >
              <Option key={"#"} value={"all"}>
                All Additional Services
              </Option>
              {_asrEservices?.map((el, i) =>
                el.hideOnDashboard ? null : (
                  <Option key={el.id} value={`${el.id}`}>
                    {el.name}
                  </Option>
                )
              )}
            </Select>
          </div>
        </Col>
        <Col>
          <strong>Select Service Item</strong>
          <div className="dropdown mt-1">
            <Select
              placeholder={"Filter By Service Items"}
              onChange={onChangeASRItem}
              bordered={false}
              disabled={hasNoPermission}
              value={ASRItem}
              style={{ width: "100%" }}
            >
              <Option key={"#"} value={""}>
                All Items
              </Option>
              {_asrEservices
                ?.find(
                  (eservice) => eservice.id.toString() === selectedEService
                )
                ?.items?.map((el, i) => (
                  <Option key={el.id} value={`${el.id}`}>
                    {el.title}
                  </Option>
                ))}
            </Select>
          </div>
        </Col>
      </Row>
    );
  };

  const onChangeTab = (key) => {
    setActiveTab(key);
    if (key === "ASR") {
      setEService("all");
    }
    if (key !== "ASR" && selectedEService.length > 0) {
      setEService("");
    }
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <div className="dashboardApplicationFilterWrapper">
          <h5>Applications</h5>
          <div className="dashboardViewApplicationFilters">
            <div className="dropdown" style={{ width: "150px" }}>
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter By Campus"}
                onChange={onSelectCampus}
                bordered={false}
                disabled={hasNoPermission}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_campuses?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    onChange={() => setSelectedCampus(el.id)}
                  >
                    {el.code}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="dropdown" style={{ width: "150px" }}>
              <Select
                style={{ width: "100%" }}
                onChange={onSelectTerms}
                placeholder={"Filter By Term"}
                bordered={false}
                disabled={hasNoPermission}
                value={selectedTerm}
              >
                <Option key={"all"} value={""}>
                  All
                </Option>
                {_terms?.map((el) => (
                  <Option key={el.id} value={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="tab-card-container">
          {hasNoPermission ? (
            renderNoDataPlaceholder(
              loading,
              <div>
                <h4 style={{ textAlign: "center", opacity: 0.8 }}>
                  This Page Has Restricted Access
                </h4>
                <span>
                  You may not have the required permissions to view this page.
                  Please contact your system admin
                </span>
              </div>,
              <FrownOutlined style={{ fontSize: 30, opacity: 0.8 }} />
            )
          ) : (
            <Tabs activeKey={activeTab} onChange={onChangeTab} type={"card"}>
              {data &&
                data?.map((dataItem) =>
                  _permissions && _permissions[dataItem?.type] ? (
                    <TabPane
                      key={dataItem.type}
                      tab={
                        <Row
                          align={"middle"}
                          justify={"space-around"}
                          wrap={false}
                        >
                          <h6>{dataItem.title}</h6>
                          {dataItem.stats && dataItem.stats[0]?.count ? (
                            <div className="tab-badge">
                              <p>{dataItem.stats[0]?.count}</p>
                            </div>
                          ) : (
                            <p></p>
                          )}
                        </Row>
                      }
                    >
                      <Card
                        className="antCardBorder"
                        style={{ backgroundColor: "#EDEDED" }}
                      >
                        {activeTab === "ASR" && renderASRDropdowns()}
                        {renderStats(dataItem)}
                      </Card>
                    </TabPane>
                  ) : null
                )}
            </Tabs>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default Dashboard;
