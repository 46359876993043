/* eslint-disable no-unreachable */
import {
  ADD_NEW_DOCUMENT,
  DELETE_DOCUMENT,
  GET_ALL_DOCUMETNS_LISTS,
  LOAD_MORE,
  UPDATE_DOCUMENT,
} from "../../actions/documents-list/documentslistAction";

const initialState = {
  documentsLists: [],
  total: 0,
};

export default function documentlistReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DOCUMETNS_LISTS:
      return {
        ...state,
        documentsLists: action.payload.data,
        total: action.payload.total,
      };
      break;
    case ADD_NEW_DOCUMENT:
      return {
        ...state,
        documentsLists: [action.payload, ...state.documentsLists],
        total: state.total + 1,
      };
      break;
    case DELETE_DOCUMENT:
      const filterDocuments = state.documentsLists.filter(
        (_doc) => _doc.id !== action.id
      );
      return {
        ...state,
        documentsLists: filterDocuments,
        total: state.total - 1,
      };
      break;
    case UPDATE_DOCUMENT:
      const indexOFDocuemnt = state.documentsLists.findIndex(
        (_doc) => _doc.id === action.id
      );
      state.documentsLists[indexOFDocuemnt] = action.payload;
      const udpatedDocuments = [...state.documentsLists];
      return {
        ...state,
        documentsLists: udpatedDocuments,
      };
      break;
    case LOAD_MORE:
      return {
        ...state,
        documentsLists: state.documentsLists.concat(action.payload),
      };
      break;
    default:
      return state;
  }
}
