import client from "apis/config";

export const getCashierConfigurationForFsm = async ({
  page = 1,
  limit = 10,
  bookingType = "",
}) => {
  return client.get(
    `/cashier/configurations?type=facility&page=${page}&limit=${limit}&bookingType=${bookingType}`
  );
};
export const addCashierConfigurationForFsm = async ({ paylaod }) => {
  return client.post("/cashier/configurations?type=facility", paylaod);
};
export const updateCashierConfigurationForFsm = async ({ id, paylaod }) => {
  return client.put(`/cashier/configurations/${id}`, paylaod);
};
export const deleteCashierConfigurationForFsm = async ({ id }) => {
  return client.delete(`/cashier/configurations/${id}`);
};
