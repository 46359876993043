import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import { formateDateWithMoment } from "utils/helpers/helpers";

const ServiceFeeLogs = ({ history }) => {
  return history.length > 0
    ? history?.map((e) => {
        return e.meta.action === "service_amount_updated" ? (
          <div>
            <h6>Service Amount Updated</h6>
            <div className="rh-log-item">
              <p>Old Amount: {e.meta.oldAmount} AED</p>
              <p>New Amount: {e.meta.newAmount} AED</p>
              <div className="rh-log-row">
                <CalendarOutlined className="rh-log-icon" />
                <p>
                  Date of Action :{" "}
                  {formateDateWithMoment(e.createdAt, "DD MMM YYYY hh:mm A")}
                </p>
              </div>
              <div className="rh-log-row">
                <UserOutlined className="rh-log-icon" />
                <p>Action Creator : {e.user?.userName}</p>
              </div>
            </div>
          </div>
        ) : null;
      })
    : null;
};
export default ServiceFeeLogs;
