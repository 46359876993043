import { Button, Form, Input, Select, Row, Col } from "antd";
import React, { useEffect, useState } from "react";

import { fetchAllSccCategories } from "../scc-Categories/api";
const { Option } = Select;
const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} is required`,
    },
  ];
};
const SccStandardForm = ({ onSubmit, loading, formName }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchSccCategories();
  }, []);
  const fetchSccCategories = async () => {
    try {
      const response = await fetchAllSccCategories({ limit: 1000 });
      if (response.status === 200) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Unique Code"}
              name={"identifier"}
              rules={setRules("Unique Code")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Category"}
              name={"categoryId"}
              rules={setRules("Category")}
            >
              <Select placeholder="Select">
                {categories.map((el, i) => (
                  <Option value={el.id} key={i}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={setRules("Title EN")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title AR")}
            >
              <Input placeholder="Add here" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>

        <br />

        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant__primary__button"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default SccStandardForm;
