import client from "apis/config";

export const scheduleInterviewForObservation = async ({ data }) => {
  return await client.post("applications/schedule/interview", data);
};

export const addRemarksScheduledInterviewForObservation = async ({ data }) => {
  return await client.post("applications/schedule/interview/remarks", data);
};

export const addRemarksForInterview = async ({ data }) => {
  return await client.post("applications/schedule/interview/remarks", data);
};
