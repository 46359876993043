/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import {
  Button,
  Checkbox,
  Form,
  Row,
  Select,
  Tooltip,
  Popconfirm,
  Spin,
  // Steps,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import * as React from "react";
import CommonTable from "components/Tables/CommonTable";

import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
import { getCashierConfigAPI } from "apis/cashier-configurations/CashierConfigurations";
import { addCashierConfigAPI } from "apis/cashier-configurations/CashierConfigurations";
import { editCashierConfigAPI } from "apis/cashier-configurations/CashierConfigurations";
import { deleteCashierConfigApi } from "apis/cashier-configurations/CashierConfigurations";
import { getCashierAction } from "actions/users/userActions";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Update",
};

const CashierConfiguration = (props) => {
  const dispatch = useDispatch();

  const [cashierForm] = Form.useForm();
  const [showModal, toggleModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  const [editId, setEditId] = React.useState("");
  const [totalRecords, setTotalRecords] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const [selectedCampuses, setSelectedCampuses] = React.useState([]);
  const [selectedSchools, setSelectedSchools] = React.useState([]);
  const [selectedGrades, setSelectedGrades] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [campusesFilteredSchools, setCampusesFilteredSchools] = React.useState(
    []
  );
  const [isEdit, setIsEdit] = React.useState(false);

  const [cashierConfiguration, setConfigurations] = React.useState([]);

  const _campuses = useSelector((state) => state.metaData.campuses);
  const _allSchools = useSelector((state) => state.metaData.schools);
  const _grades = useSelector((state) =>
    state.metaData.grades.filter((grade) => grade.code !== "GRADUATED")
  );
  const _cashiers = useSelector((state) => state.allUsers.cashiers);

  const [loading, setLoading] = React.useState(false);

  let selectedCampusesesIds = [];

  React.useEffect(() => {
    fetchCashierConfigurations();
    fetchAllCashiers();
  }, []);

  const fetchCashierConfigurations = async () => {
    try {
      setLoading(true);
      const response = await getCashierConfigAPI();
      if (response.status == 200) {
        setConfigurations(response.data.data);
        setLoading(false);
      } else throw response;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  const fetchAllCashiers = () => {
    dispatch(getCashierAction());
  };

  //  filter all schools againt the selected campuses
  const filterSchoolsByCampus = () => {
    selectedCampuses.forEach((campus) => {
      selectedCampusesesIds.push(campus.id);
    });
    setCampusesFilteredSchools(
      _allSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
    setSelectedSchools(
      selectedSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
  };

  React.useEffect(() => {
    filterSchoolsByCampus();
  }, [selectedCampuses]);

  // when click on edit config, prepopulate form fields
  const onEdit = (item) => {
    toggleModal(true);
    setModalTitle(ModalType.EDIT);
    setEditId(item.id);
    setIsEdit(true);

    // autofill values
    setSelectedCampuses(item.campuses);
    setSelectedSchools(item.schools);
    setSelectedGrades(item.grades);
    setSelectedUsers(item.users);
  };
  const onAdd = () => {
    //  open modal to create new config
    setModalTitle(ModalType.ADD);
    cashierForm.resetFields();
    toggleModal(true);
    setIsEdit(false);

    setSelectedCampuses([]);
    setSelectedGrades([]);
    setSelectedUsers([]);
    setSelectedCampuses([]);
    setSelectedSchools([]);
  };
  const onDelete = async (item) => {
    try {
      setLoading(true);
      const deleteResponse = await deleteCashierConfigApi(item.id);
      if (deleteResponse.status == 200) {
        setLoading(false);
        NotificationManager.success("Record deleted");
        fetchCashierConfigurations();
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };
  const validateInputs = () => {
    return (
      selectedCampuses.length > 0 &&
      selectedSchools.length > 0 &&
      selectedGrades.length > 0 &&
      selectedUsers.length > 0
    );
  };
  const onSubmit = async (value) => {
    if (validateInputs()) {
      let params = {
        campuses: selectedCampuses.map((param) => param.id),
        schools: selectedSchools.map((param) => param.id),
        grades: selectedGrades.map((param) => param.id),
        users: selectedUsers.map((param) => param.id),
      };
      try {
        setLoading(true);
        if (modalTitle === ModalType.ADD) {
          const response = await addCashierConfigAPI(params);
          if (response.status == 200) {
            fetchCashierConfigurations();
            NotificationManager.success("Record added successfully");
          } else throw response.error;
        } else {
          const response = await editCashierConfigAPI(editId, params);
          if (response.status == 200) {
            NotificationManager.success("Record updated successfully");
            fetchCashierConfigurations();
          } else throw response.error;
        }
        setLoading(false);
        toggleModal(false);
      } catch (e) {
        setLoading(false);
        requestErrorHandel({ error: e });
      }
    } else {
      NotificationManager.error("Please submit all fields");
      return;
    }
  };

  const handleSelectAllCampus = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedCampuses(_campuses);
    } else {
      return value;
    }
  };

  const handleSelectAllSchools = (value) => {
    if (value && value.length && value.includes("all")) {
      selectedCampuses.forEach((camp) => {
        selectedCampusesesIds.push(camp.id);
      });
      const _campuseSchools = _allSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      );
      setSelectedSchools(_campuseSchools);
    } else {
      return value;
    }
  };

  const handleSelectAllGrades = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedGrades(_grades);
    } else {
      return value;
    }
  };

  const handleSelectAllUser = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedUsers(_cashiers);
    } else {
      return value;
    }
  };

  const changeSelectedUsers = async (e, el) => {
    let _selectedUsersCopy = [...selectedUsers];

    if (e.target.checked) {
      _selectedUsersCopy = [..._selectedUsersCopy, el];
    } else {
      _selectedUsersCopy = [..._selectedUsersCopy].filter(
        (i) => i.id !== el.id
      );
    }
    setSelectedUsers(_selectedUsersCopy);
  };

  const fetchRecords = async (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          key={"#"}
          loading={loading}
          data={cashierConfiguration}
          header={() => (
            <TableHeader
              headerTitle={`Cashier Configuration`}
              headerSubTitle={`Manage Cashiers`}
              headerBtnTitle="Add New"
              onAddNew={onAdd}
            />
          )}
          columns={[
            {
              key: "id",
              title: "Campuses",
              dataIndex: "campuses",
              render: (item) => {
                return item.length === _campuses.length
                  ? "All"
                  : item.map((el) => el.code).join(", ");
              },
            },
            {
              key: "id",
              title: "Schools",
              dataIndex: "schools",
              render: (item) => {
                return item.length === _allSchools.length
                  ? "All"
                  : item.map((el) => el.abbreviation).join(", ");
              },

              //(item) => item.map((el) => el.abbreviation).join(", "),
            },
            {
              key: "id",
              title: "Grades",
              dataIndex: "grades",
              render: (item) => {
                return item.length === _grades.length
                  ? "All"
                  : item.map((el) => el.code).join(", ");
              },

              //(item) => item.map((el) => el.code).join(", "),
            },

            {
              key: "id",
              title: "Users",
              dataIndex: "users",
              render: (item) => item.map((el) => el.name).join(", "),
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onEdit(item)}
                      />
                    </Tooltip>
                  </div>
                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
          totalRecord={totalRecords}
          pageSize={pageSize}
          fetchRecords={fetchRecords}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={() => toggleModal(false)}
          title={`${modalTitle} Cashier Configuration`}
        >
          <Form
            form={cashierForm}
            layout="vertical"
            size="large"
            onFinish={onSubmit}
          >
            <Form.Item label={"Campuses"}>
              <Select
                placeholder="Select campus"
                mode={"multiple"}
                value={
                  selectedCampuses.length > 0
                    ? selectedCampuses.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={handleSelectAllCampus}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {_campuses.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedCampuses.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedCampuses(
                          e.target.checked
                            ? [...selectedCampuses, el]
                            : selectedCampuses.filter((i) => i.id !== el.id)
                        );
                      }}
                    >
                      {el.code}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Schools"}>
              <Select
                placeholder="Select school"
                mode="multiple"
                value={
                  selectedSchools.length > 0
                    ? selectedSchools
                        .map((item) => item.abbreviation)
                        ?.join(", ")
                    : []
                }
                onChange={handleSelectAllSchools}
              >
                {campusesFilteredSchools.length > 1 && (
                  <Option key="all" value="all">
                    Select All
                  </Option>
                )}

                {campusesFilteredSchools.map((el) => (
                  <Option value={el.id}>
                    <Checkbox
                      checked={
                        selectedSchools.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedSchools(
                          e.target.checked
                            ? [...selectedSchools, el]
                            : selectedSchools.filter((i) => i.id !== el.id)
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      {el.abbreviation}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Grades"}>
              <Select
                placeholder="Select grade"
                mode="multiple"
                value={
                  selectedGrades.length > 0
                    ? selectedGrades.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={handleSelectAllGrades}
              >
                <Option key="all" value="all">
                  Select All
                </Option>

                {_grades.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedGrades.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedGrades(
                          e.target.checked
                            ? [...selectedGrades, el]
                            : selectedGrades.filter((i) => i.id !== el.id)
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      {el.code}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Users"}>
              <Select
                mode="multiple"
                value={
                  selectedUsers.length > 0
                    ? selectedUsers.map((el) => el.name)?.join(", ")
                    : []
                }
                placeholder="Select users"
                showSearch
                filterOption
                onChange={handleSelectAllUser}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {_cashiers?.map((el, i) => (
                  <Option value={el.name} key={i}>
                    <Checkbox
                      checked={
                        selectedUsers.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        changeSelectedUsers(e, el);
                      }}
                      // onChange={(e) => {
                      //   setSelectedUsers(
                      //     e.target.checked
                      //       ? [...selectedUsers, el]
                      //       : selectedUsers.filter((i) => i.id !== el.id)
                      //   );
                      //   setExistingUser(selectedUsers);
                      // }}
                      style={{ width: "100%" }}
                    >
                      {el.name}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default CashierConfiguration;
