import { SettingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Capitalize } from "utils/helpers/helpers";

const StagesList = (props) => {
  const serviceType = props.location.state.serviceName;
  const isServiceTypeBookings = ["EVB", "LTB", "FFB", "DPB", "PHB"].includes(
    serviceType
  );

  const applicationsStages = useSelector((state) => {
    return (
      state.metaData?.requestTypes.filter(
        (requestType) => requestType.identifier === serviceType
      )[0]?.stages || []
    );
  });
  const bookingsStages = useSelector((state) => {
    return (
      state.metaData?.bookingRequestTypes.filter(
        (requestType) => requestType.identifier === serviceType
      )[0]?.stages || []
    );
  });
  const stages = isServiceTypeBookings ? bookingsStages : applicationsStages;

  return (
    <div className="both-side-padding">
      <CommonTable
        data={stages}
        header={() => (
          <TableHeader
            headerTitle={`${serviceType} Stages`}
            headerSubTitle="Stage Configuration"
            headerBtnTitle=""
            onAddNew={() => null}
          />
        )}
        columns={[
          {
            key: "id",
            title: "#",
            dataIndex: "id",
            render: (_, __, index) => {
              return index + 1;
            },
          },
          {
            key: "title",
            title: "Title",
            dataIndex: "title",
            render: (data) => Capitalize(data),
          },
          // {
          //   key: "titleAr",
          //   title: "Title (AR)",
          //   dataIndex: "titleAr",
          //   render: (data) => Capitalize(data),
          // },
          {
            key: "",
            title: "Configure",
            render: (record) => {
              return (
                <div>
                  <Tooltip title="Configure Stage">
                    <Link
                      to={{
                        pathname: "/admin/request-types/stage-configuration",
                        state: record,
                      }}
                    >
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<SettingOutlined />}
                      ></Button>
                    </Link>
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
        fetchRecords={() => null}
      ></CommonTable>
    </div>
  );
};
export default StagesList;
