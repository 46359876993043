/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Row, Col, Button, DatePicker, Input, Select, Form } from "antd";
import moment from "moment";
import { isSameDateUntilMinutes } from "utils/helpers/helpers";
import ScheduleDateTimePicker from "components/resuable/schedule-detail/ScheduleDateTimePicker";
const { Option } = Select;

export default function EntranceTestScheduleFormForHigherGrades({
  onFinish,
  autoFill,
  loading,
  application,
}) {
  const [typeOfInterview, setTypeOfInterview] = React.useState([
    {
      id: "1",
      title: "In Person",
      value: "in_person",
    },
    {
      id: "2",
      title: "Online",
      value: "online",
    },
  ]);
  let stageData = application?.stage;
  const [selectedInterViewType, setSelectedInterViewType] =
    React.useState("Online");

  const [testScheduleForm] = Form.useForm();

  // State variable to keep track of whether it's a retest or normal scheduling
  const [isRetest, setIsRetest] = useState(false);

  // State variables to keep track of retest subjects
  const [retestSubjects, setRetestSubjects] = useState([]);

  const handleChange = (event) => {
    setSelectedInterViewType(event);
  };
  useEffect(() => {
    if (autoFill) {
      testScheduleForm.setFieldsValue(autoFill);
      setSelectedInterViewType(autoFill.interviewType);
    }
  }, [autoFill]);

  useEffect(() => {
    // Check if there are retestSubjects available
    if (stageData?.schedules && stageData.schedules.length > 0) {
      for (const schedule of stageData.schedules) {
        if (schedule && schedule.remarks && schedule.remarks.length > 0) {
          const remark = schedule.remarks[0];
          if (
            remark &&
            remark.retestSubjects &&
            remark.retestSubjects.length > 0
          ) {
            setRetestSubjects(remark.retestSubjects);
            setIsRetest(true); // Set the isRetest state to true if there are retestSubjects
            return; // Assuming there's only one remark for retesting
          }
        }
      }
    }

    // If no retestSubjects found, reset the isRetest state and retestSubjects array
    setRetestSubjects([]);
    setIsRetest(false);
  }, [stageData]);

  return (
    <div className="both-side-padding">
      {isRetest ? (
        <Form
          size="large"
          name="entranceTestFormForHigherGrades"
          layout={"vertical"}
          onFinish={onFinish}
          form={testScheduleForm}
        >
          <Row gutter={[20, 20]}>
            <Col lg={12} sm={24}>
              <Form.Item
                label="Type"
                name="interviewType"
                rules={[
                  {
                    required: true,
                    message: "Type is required",
                  },
                ]}
              >
                <Select
                  onChange={handleChange}
                  placeholder="Entrance Exam Type"
                >
                  {typeOfInterview.map((type) => {
                    return <Option value={type.value}>{type.title}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
            {isRetest && retestSubjects.includes("math") && (
              <Col md={12} sm={24}>
                <ScheduleDateTimePicker
                  label="Math Exam Date and Time"
                  name="subjectMathDateAndTime"
                  rules={[
                    {
                      required: true,
                      message: "Date and time is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          isSameDateUntilMinutes(
                            getFieldValue("subjectEnglishDateAndTime"),
                            value
                          ) ||
                          isSameDateUntilMinutes(
                            getFieldValue("subjectArabicDateAndTime"),
                            value
                          )
                        ) {
                          return Promise.reject(
                            new Error(
                              "Date and time can not be same as other subjects"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                />
              </Col>
            )}
            {isRetest && retestSubjects.includes("english") && (
              <Col md={12} sm={24}>
                <ScheduleDateTimePicker
                  label="English Exam Date and Time"
                  name="subjectEnglishDateAndTime"
                  rules={[
                    {
                      required: true,
                      message: "Date and time is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          isSameDateUntilMinutes(
                            getFieldValue("subjectMathDateAndTime"),
                            value
                          ) ||
                          isSameDateUntilMinutes(
                            getFieldValue("subjectArabicDateAndTime"),
                            value
                          )
                        ) {
                          return Promise.reject(
                            new Error(
                              "Date and time can not be same as other subjects"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                ></ScheduleDateTimePicker>
              </Col>
            )}
            {isRetest && retestSubjects.includes("arabic") && (
              <Col md={12} sm={24}>
                <ScheduleDateTimePicker
                  label="Arabic Exam Date and Time"
                  name="subjectArabicDateAndTime"
                  rules={[
                    {
                      required: true,
                      message: "Date and time is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          isSameDateUntilMinutes(
                            getFieldValue("subjectMathDateAndTime"),
                            value
                          ) ||
                          isSameDateUntilMinutes(
                            getFieldValue("subjectEnglishDateAndTime"),
                            value
                          )
                        ) {
                          return Promise.reject(
                            new Error(
                              "Date and time can not be same as other subjects"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                />
              </Col>
            )}
            <Col lg={24} sm={24}>
              {selectedInterViewType === "in_person" ? (
                <Form.Item
                  label="Instructions"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Instructions are required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Instructions"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={24} sm={24}>
              <Form.Item
                label="Notes"
                name="notes"
                rules={[
                  {
                    required: true,
                    message: "Notes are required",
                    whitespace: true,
                  },
                ]}
              >
                <Input.TextArea placeholder="Notes" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? "Sending...." : "Send To Parent"}
            </Button>
          </Row>
        </Form>
      ) : (
        <Form
          size="large"
          name="entranceTestFormForHigherGrades"
          layout={"vertical"}
          onFinish={onFinish}
          form={testScheduleForm}
        >
          <Row gutter={[20, 20]}>
            <Col lg={12} sm={24}>
              <Form.Item
                label="Type"
                name="interviewType"
                rules={[
                  {
                    required: true,
                    message: "Type is required",
                  },
                ]}
              >
                <Select
                  onChange={handleChange}
                  placeholder="Entrance Exam Type"
                >
                  {typeOfInterview.map((type) => {
                    return <Option value={type.value}>{type.title}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col md={12} sm={24}>
              <ScheduleDateTimePicker
                label="Math Exam Date and Time"
                name="subjectMathDateAndTime"
                rules={[
                  {
                    required: true,
                    message: "Date and time is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        isSameDateUntilMinutes(
                          getFieldValue("subjectEnglishDateAndTime"),
                          value
                        ) ||
                        isSameDateUntilMinutes(
                          getFieldValue("subjectArabicDateAndTime"),
                          value
                        )
                      ) {
                        return Promise.reject(
                          new Error(
                            "Date and time can not be same as other subjects"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              />
            </Col>

            <Col md={12} sm={24}>
              <ScheduleDateTimePicker
                label="English Exam Date and Time"
                name="subjectEnglishDateAndTime"
                rules={[
                  {
                    required: true,
                    message: "Date and time is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        isSameDateUntilMinutes(
                          getFieldValue("subjectMathDateAndTime"),
                          value
                        ) ||
                        isSameDateUntilMinutes(
                          getFieldValue("subjectArabicDateAndTime"),
                          value
                        )
                      ) {
                        return Promise.reject(
                          new Error(
                            "Date and time can not be same as other subjects"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              ></ScheduleDateTimePicker>
            </Col>

            <Col md={12} sm={24}>
              <ScheduleDateTimePicker
                label="Arabic Exam Date and Time"
                name="subjectArabicDateAndTime"
                rules={[
                  {
                    required: true,
                    message: "Date and time is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        isSameDateUntilMinutes(
                          getFieldValue("subjectMathDateAndTime"),
                          value
                        ) ||
                        isSameDateUntilMinutes(
                          getFieldValue("subjectEnglishDateAndTime"),
                          value
                        )
                      ) {
                        return Promise.reject(
                          new Error(
                            "Date and time can not be same as other subjects"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              />
            </Col>

            <Col lg={24} sm={24}>
              {selectedInterViewType === "in_person" ? (
                <Form.Item
                  label="Instructions"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Instructions are required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Instructions"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={24} sm={24}>
              <Form.Item
                label="Notes"
                name="notes"
                rules={[
                  {
                    required: true,
                    message: "Notes are required",
                    whitespace: true,
                  },
                ]}
              >
                <Input.TextArea placeholder="Notes" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? "Sending...." : "Send To Parent"}
            </Button>
          </Row>
        </Form>
      )}
    </div>
  );
}
