const initialState = {
  topicLists: [],
};

const NotificationReducers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NOTIFICATION_TOPIC_ACTION":
      return {
        ...state,
        topicLists: action.payload,
      };
    default:
      return state;
  }
};

export default NotificationReducers;
