/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Row, Card, Col, Button, Select, Tabs, Form, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";

import styles from "styles/applicationDetails.module.css";

import CustomModal from "components/CustomModal/CustomModal";
import SchedulingInterviewForm from "components/scheduleInterviewModal/SchedulingInterviewForm";
import AddRemarks from "components/scheduleInterviewModal/AddRemarks";
import { scheduleInterviewForObservation } from "apis/schedule-interview/schedule-interview";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { requestErrorHandel } from "utils/helpers/helpers";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import { successMessage } from "utils/helpers/helpers";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import { addRemarksForInterview } from "apis/schedule-interview/schedule-interview";
import { convertToTitleCase } from "utils/helpers/helpers";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import { modifyStageStatus } from "utils/helpers/helpers";
import { _updateApplications } from "actions/applications/applications-actions";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { getApplicationDetails } from "apis/applications-apis/applications";
import { renderLoader } from "utils/helpers/helpers";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";

const { TabPane } = Tabs;
function InterviewDetails(props) {
  const application = props?.application;
  const [visibility, setVisibility] = React.useState(false);
  const [visibilityRemarks, setVisibilityRemarks] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [scheduleInterviewForm] = Form.useForm();
  const [addRemarksForm] = Form.useForm();

  useEffect(() => {
    application && getLogs();
  }, [application]);

  const getLogs = async () => {
    try {
      const response = await getApplicationStagesLogs({ id: application.id });

      if (response.status == 200) {
        var applicationCurrentStage = currentStageDetails;
        if (
          applicationCurrentStage == undefined ||
          applicationCurrentStage == ""
        ) {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == application.stage.slug
          );
        } else {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == currentStageDetails.stageSlug
          );
        }
        setcurrentStageDetails(applicationCurrentStage);
      }
    } catch (error) {
      // await requestErrorHandel({ error: error });
    }
  };

  const getBtnTitle = () => {
    if (currentStageDetails?.stageStatus?.code == "SG015") {
      return "Schedule Interview";
    } else if (
      currentStageDetails?.stageStatus?.code == "SG016" ||
      currentStageDetails?.stageStatus?.code == "SG058"
    ) {
      return "Add Remarks";
    } else {
      return currentStageDetails?.stageStatus?.internalStatus;
    }
  };

  const getTitle = () => {
    return currentStageDetails?.stageStatus?.internalStatus;
  };

  const getDescription = () => {
    if (currentStageDetails?.stageStatus?.code == "SG015") {
      return "Please schedule interview for this application";
    } else if (currentStageDetails?.stageStatus?.code == "SG016") {
      return "Please add remarks for this application";
    } else if (currentStageDetails?.stageStatus?.code == "SG058") {
      return "Interview video link uploaded, Please add Remarks";
    } else if (currentStageDetails?.stageStatus?.code == "SG058") {
      return "Video uploaded. Please add remarks.";
    } else {
      return "The stage has been completed successfully";
    }
  };

  const handleCancel = () => {
    setVisibility(false);
  };
  const handleOk = () => {
    setVisibility(false);
  };

  const addRemarks = () => {
    setVisibilityRemarks(true);
  };
  const addRemarksHandler = () => {
    // const oldRemarksObj = [...remarksObj];
    // const data = {
    //   id: "1",
    // };
    // setRemarkseObj([data, ...oldRemarksObj]);
    setVisibilityRemarks(false);
  };

  const onFinishInterViewScheduleForm = async (value) => {
    value["applicationId"] = application.id;
    value["type"] = application.stage.slug;

    try {
      setLoading(true);
      const response = await scheduleInterviewForObservation({ data: value });

      if (response.status == 200) {
        setVisibility(false);
        props.refetch();
        setLoading(false);
        // getDetails();
        getLogs();
        //setLoading(false);
        successMessage({ message: "Interview scheduled successfully" });
        // dispatch(_updateApplications(applicationId, response.data.data));
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onFinishRemarks = async (value) => {
    try {
      setLoading(true);
      value["scheduleId"] = currentStageDetails?.schedules[0]?.id;
      const response = await addRemarksForInterview({ data: value });
      if (response.status == 200) {
        setLoading(false);
        setVisibilityRemarks(false);
        getLogs();
        successMessage({ message: "Remarks added successfully" });
        props.refetch();
        // getDetails();
        // dispatch(_updateApplications(applicationId, response.data.data));
      }
    } catch (error) {
      requestErrorHandel({ error: error });
      setLoading(false);
    }
  };

  const buttonClickAction = () => {
    const statusCode = currentStageDetails?.stageStatus?.code;
    if (statusCode == "SG015") {
      setVisibility(true);
    } else if (statusCode == "SG016" || statusCode === "SG058") {
      addRemarks();
    } else {
      return;
    }
  };
  const renderSchedulingInterviewModal = () => {
    return (
      <CustomModal
        visibility={visibility}
        handleCancel={handleCancel}
        handleOk={handleOk}
        title={"Schedule Interview"}
      >
        <Form
          size="large"
          name="scheduleInterviewForm"
          form={scheduleInterviewForm}
          onFinish={onFinishInterViewScheduleForm}
          layout={"vertical"}
        >
          <SchedulingInterviewForm handleOk={handleOk} loading={loading} />
        </Form>
      </CustomModal>
    );
  };
  const renderInterviewDetail = () => {
    if (!application.hasStagePermission) return null;
    return currentStageDetails?.schedules?.length > 0 ? (
      <Card className="antCardBorder" style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div>
              <div>
                <h5>Interview Details</h5>
              </div>
              <div>
                <p>
                  Date:{" "}
                  {formateDateWithMoment(
                    currentStageDetails?.schedules[0].scheduleTime,
                    "DD MMM YYYY hh:mm A"
                  )}
                </p>
                <p>
                  Type:{" "}
                  {convertToTitleCase(
                    currentStageDetails?.schedules[0].scheduleType
                  )}
                </p>
                {currentStageDetails?.schedules[0].scheduleType !== "online" ? (
                  <p>
                    Instructions: {currentStageDetails?.schedules[0].address}
                  </p>
                ) : null}
                {currentStageDetails?.schedules[0].scheduleType === "online" &&
                currentStageDetails?.schedules[0].preRecordedLink !== "" ? (
                  <p>
                    Interview Video Link:{" "}
                    <a
                      href={currentStageDetails?.schedules[0].preRecordedLink}
                      target="__blank()"
                    >
                      Click here to see the pre-recorded video
                    </a>
                  </p>
                ) : null}
                <p style={{ overflowX: "clip" }}>
                  Notes: {currentStageDetails?.schedules[0].notes}
                </p>
              </div>
            </div>
          </div>
          {(application?.hasSchedulePermission || ifSuperAdmin) &&
          currentStageDetails?.stageStatus?.code === "SG016" ? (
            <div>
              <Button
                type="primary"
                className="appButton"
                onClick={() => setVisibility(true)}
              >
                Reschedule
              </Button>
            </div>
          ) : null}
        </div>
      </Card>
    ) : null;
  };
  const renderRemarksDetails = () => {
    if (!application.hasStagePermission) return null;
    return currentStageDetails.schedules?.length > 0 ? (
      <Card
        hoverable
        className="antCardBorder antCard"
        style={{ marginTop: 20 }}
      >
        <h6>Final Remarks</h6>
        <h4>
          {currentStageDetails?.schedules[0].remarks[0].isRecommended
            ? "Recommended"
            : "Not Recommended"}
        </h4>
        <hr />
        <p>{currentStageDetails?.schedules[0].remarks[0].notes}</p>
      </Card>
    ) : null;
  };

  const renderRemarksModal = () => {
    return (
      <CustomModal
        visibility={visibilityRemarks}
        handleCancel={() => setVisibilityRemarks(false)}
        handleOk={() => setVisibilityRemarks(false)}
        title={"Add Remarks"}
      >
        <Form
          name="addRemarksForm"
          size="large"
          onFinish={onFinishRemarks}
          form={addRemarksForm}
          layout={"vertical"}
        >
          <AddRemarks addRemarksHandler={addRemarksHandler} loading={loading} />
        </Form>
      </CustomModal>
    );
  };

  const ifSuperAdmin = useSelector(
    (state) => state.login.userInfo?.role === "super-admin"
  );
  const hasActionsPermision =
    ifSuperAdmin ||
    (application.hasStagePermission &&
      ((application?.hasSchedulePermission &&
        currentStageDetails?.stageStatus?.code === "SG015") ||
        (application?.hasRemarksPermission &&
          currentStageDetails?.stageStatus?.code === "SG016")))
      ? true
      : false;
  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={renderLoader}>
        {application ? (
          <>
            <Row gutter={[20, 0]} justify="start">
              <Col sm={24} md={!hasActionsPermision ? 24 : 12}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>

              {hasActionsPermision && (
                <>
                  <Col lg={12}>
                    <Card hoverable className="antCardBorder antCard">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <h5>{getTitle()}</h5>
                          <p>{getDescription()}</p>
                        </div>
                        <div>
                          <Button
                            type="primary"
                            disabled={
                              currentStageDetails?.stageStatus?.code ===
                                "SG017" || // completed
                              currentStageDetails?.stageStatus?.code === "SG018" //cancelled
                            }
                            onClick={buttonClickAction}
                          >
                            {getBtnTitle()}
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
            {/* if completed, it measn remarks are done */}
            {currentStageDetails?.stageStatus?.code === "SG017"
              ? renderRemarksDetails()
              : null}

            {/* if remarks needed, it measn interview scheduling is done */}
            {currentStageDetails?.stageStatus?.code === "SG016" ||
            currentStageDetails?.stageStatus?.code === "SG017" ||
            currentStageDetails?.stageStatus?.code === "SG018" ||
            currentStageDetails?.stageStatus?.code === "SG058"
              ? renderInterviewDetail()
              : null}

            <Tabs className="applicationDetailsTab" defaultActiveKey="1">
              <TabPane
                tab={<h5 className={styles.tabTitle}>Father Information</h5>}
                key="1"
              >
                <div>
                  <ApplicationInformationReadOnlyForm
                    application={application}
                    type={"father"}
                  />
                </div>
              </TabPane>

              <TabPane
                tab={<h5 className={styles.tabTitle}>Mother Information</h5>}
                key="2"
              >
                <div>
                  <ApplicationInformationReadOnlyForm
                    application={application}
                    type={"mother"}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Child Information</h5>}
                key="3"
              >
                <ApplicationInformationReadOnlyForm
                  application={application}
                  type={"child"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Documents</h5>}
                key="4"
              >
                <DocumentsReadOnlyForm application={application} />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Stages History</h5>}
                key="5"
              >
                <ApplicationLogs application={application} />
              </TabPane>
            </Tabs>
            {renderSchedulingInterviewModal()}
            {renderRemarksModal()}
          </>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
}

export default InterviewDetails;
