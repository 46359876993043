import Dashboard from "views/dashboard/Dashboard.jsx";
import IFrameCashierScreen from "views/screens/iframe_cashier/IFrameCashierScreen";
import dashboardIcon from "../assets/img/sibar/dashboardIcon.png";
import cashiericon from "../assets/img/sibar/cashiericon.png";
import AllApplicationsRoutes from "./AllApplicationsRoutes";
import CMSRoutes from "./CMSRoutes.js";
import HtmlTemplatesRoutes from "./HtmlTemplateRoutes";
import AllBookingsRoutes from "./FSM/AllBookingsRoutes";
import AllSccRoutes from "./SCC/allSccRoutes";
import DocumentValidatorRoutes from "./DocumentValidatorRoutes";
import QrStampGenerator from "views/Document Validator/QRGenerator/QrStampGenerator";
import cmsIcon from "../assets/img/sibar/ens-enr-icon.svg";

const EmployeeRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: dashboardIcon,
    component: Dashboard,
    layout: "/admin",
    subPath: false,
  },
  
    {
      path: "/qr-document-generator",
      name: "QR Document Generator",
      icon: cmsIcon,
      component: QrStampGenerator,
      layout: "/admin",
      subPath: false,
      sideMenuTitle: "QR Stamp Generator",
      state: null,
    },
   
  ...AllApplicationsRoutes,
  ...AllBookingsRoutes,
  ...AllSccRoutes,

  ...CMSRoutes,
  // ...HtmlTemplatesRoutes,

  // {
  //   path: "/cashier-app",
  //   name: "Cashier App",
  //   icon: cashiericon,
  //   component: IFrameCashierScreen,
  //   layout: "/admin",
  //   subPath: false,
  // },
];
export default EmployeeRoutes;
