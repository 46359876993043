import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Image, Popconfirm, Spin, Tooltip } from "antd";
import { addBannersAPI } from "apis/banners-cms/BannersCMSApi";
import { deleteBannersAPI } from "apis/banners-cms/BannersCMSApi";
import { updateBannersAPI } from "apis/banners-cms/BannersCMSApi";
import { getBannersAPI } from "apis/banners-cms/BannersCMSApi";
import Axios from "axios";
import CustomModal from "components/CustomModal/CustomModal";
import BannersForm from "components/Forms/cms/BannersForm";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { uploadCMSFile } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { fetchAllFsmFacilities } from "views/FSM/facility-mangement/all-facilities/api";

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const BannersCMS = () => {
  const [page, setPage] = useState(1);
  const [banners, setBanners] = useState();
  const [totalRecords, setTotal] = useState(0);
  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [loading, setLoading] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [uploading, setUpLoading] = useState(false);
  const [file, setFile] = useState();
  const [AddBannersForm] = Form.useForm();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const _campuses = useSelector((state) => state.metaData.campuses);

  const targetOptions = [
    { title: "Website", value: "website" },
    { title: "Mobile App", value: "mobile_application" },
    { title: "Website + Mobile App", value: "both" },
  ];
  const actionTypeOptions = [
    { title: "Open In App", value: "Application" },
    { title: "Open In Browser", value: "ExternalWeb" },
  ];

  const screenOptions = [
    { title: "E-Service Screen", value: "EServices" },
    { title: "Payments Screen", value: "Payments" },
    { title: "Facilities List", value: "facilityList" },
    { title: "Facility Details", value: "facilityDetail" },
  ];

  const _vacancyLocation = [
    { title: "Campus Based", value: "campuse_based" },
    { title: "General", value: "central_administration" },
  ];

  //   targetOptions;
  // actionTypeOptions;
  // API FUNCTIONS

  useEffect(() => {
    getAllFsmFacilities();
  }, []);

  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };

  const getBanners = async () => {
    try {
      setLoading(true);
      const response = await getBannersAPI(page);
      setLoading(false);
      if (response.status === 200 && response.data?.data) {
        setBanners(response.data.data);
        setTotal(response.data.total);
      } else {
        setBanners();
        throw response;
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const addBanners = async (data) => {
    try {
      setLoading(true);
      const response = await addBannersAPI(data);
      setLoading(false);
      if (response.status == 200) {
        successMessage({ message: "Record added successfully" });
        await getBanners();
        onCloseForm();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const updateBanners = async (data) => {
    try {
      setLoading(true);
      const response = await updateBannersAPI(data, valuesForEdit.id);
      setLoading(false);
      if (response.status == 200) {
        successMessage({ message: "Record updated successfully" });
        await getBanners();
        onCloseForm();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const deleteBanners = (id) => async () => {
    try {
      setLoading(true);
      const response = await deleteBannersAPI(id);
      if (response.status == 200) {
        successMessage({ message: "Record deleted successfully" });
        await getBanners();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBanners();
  }, [page]);

  const renderHeader = () => {
    return (
      <TableHeader
        onAddNew={() => {
          setFile(undefined);
          AddBannersForm.resetFields();
          toggleModal(true);
          setModalTitle(ModalType.ADD);
        }}
        headerTitle={"Banners"}
        headerSubTitle={"Manage Banners content for ENS Website"}
        headerBtnTitle={"Add New"}
      />
    );
  };

  const onChooseFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "banners"
        );
        setFile(path);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  const onSubmit = async (values) => {
    let campuses = values.campuses || [];
    let targetType = "education";

    if (campuses === "all") {
      campuses = _campuses.map((el) => el.code);
    }
    if (values?.screenName === "facilityList") {
      // Ensure campuses is an array with one item
      campuses = Array.isArray(campuses) ? campuses.slice(0, 1) : [campuses];
    } else {
      // Ensure campuses is an array with multiple items
      campuses = Array.isArray(campuses) ? campuses : [campuses];
    }

    const data = {
      ...values,
      type: targetType,
      image: file,
      actionLink: values.actionLink ?? "",
      actionLinkAr: values.actionLinkAr ?? "",
      campuses: campuses,
    };
    console.log("data", data);
    // return;
    if (modalTitle === ModalType.EDIT) {
      await updateBanners(data);
    } else {
      await addBanners(data);
    }
  };
  const handleEdit = (record) => {
    console.log("record", record);
    setSelectedRecord(record);
    setValuesForEdit(record);
    let campuses;
    if (record.screenName === "facilityList") {
      // Ensure campuses is a single item array or an empty array
      campuses =
        Array.isArray(record.campuses) && record.campuses.length > 0
          ? record.campuses[0]
          : "";
    } else {
      // Ensure campuses is an array of multiple items or an empty array
      campuses =
        Array.isArray(record.campuses) && record.campuses.length > 0
          ? record.campuses
          : [];
    }
    record.image && setFile(record.image);
    AddBannersForm.setFieldsValue({
      ...record,
      campuses: campuses,
    });
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  const onCloseForm = () => {
    //setSelectedRecord(null);
    toggleModal(false);
    setValuesForEdit(null);
    setFile(undefined);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={banners}
          fetchRecords={(page) => {
            setPage(page);
          }}
          totalRecord={totalRecords}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              width: "30%",
            },
            {
              title: "Campus",
              dataIndex: "campuses",
              render: (record) => {
                if (record?.length == 0)
                  return _vacancyLocation.find(
                    (item) => item.value == "central_administration"
                  )?.title;

                var all_campuses_codes = [];
                _campuses.forEach((item) => {
                  all_campuses_codes.push(item.code);
                });

                if (
                  record.sort().join(",") ===
                  all_campuses_codes.sort().join(",")
                )
                  return "Emirates National Schools";
                else return record.map((el) => el).join(", ");
              },
            },
            {
              title: "Action Type",
              dataIndex: "actionType",
              render: (record) =>
                actionTypeOptions.find((item) => item.value == record)?.title ??
                "",
            },
            {
              title: "Target",
              dataIndex: "target",
              render: (record) =>
                targetOptions.find((item) => item.value == record)?.title ?? "",
              //Capitalize(record),
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "id",
              render: (record) =>
                record ? (
                  <span style={{ color: "green" }}>Active</span>
                ) : (
                  <span style={{ color: "red" }}>Inactive</span>
                ),
              //render: (record) => (record ? "Active" : "Inactive"),
            },
            {
              title: "Image",
              key: "id",
              dataIndex: "image",
              render: (record) => (
                <Image src={record} width={90} placeholder={true} />
              ),
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Banner">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this banner?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={deleteBanners(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Banner`}
        >
          <BannersForm
            onSubmit={onSubmit}
            formName={AddBannersForm}
            loading={loading}
            uploading={uploading}
            onChooseFile={onChooseFile}
            file={file}
            valuesForEdit={valuesForEdit}
            selectedRecord={selectedRecord}
            targetOptions={targetOptions}
            actionTypeOptions={actionTypeOptions}
            screenOptions={screenOptions}
            _campuses={_campuses}
            _facilities={facilities}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default BannersCMS;
