/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Table, Popconfirm, Tooltip, Button, Tag, Affix } from "antd";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  HistoryOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import {
  Capitalize,
  renderLoader,
  requestErrorHandel,
  successMessage,
} from "../../utils/helpers/helpers";
import { applicationProcess } from "apis/applications-apis/applications";
import { useDispatch } from "react-redux";

import { _deleteApplication } from "actions/applications/applications-actions";
import { EnsBrandColor } from "utils/constants";
import { MultipleApproveApplications } from "utils/constants";
import CustomModal from "components/CustomModal/CustomModal";
import PreFinalApprovalForm from "components/Forms/pre-final-approval-form/PreFinalApprovalForm";
import { addMultipleStageApproval } from "apis/applications-apis/applications";

export default function AllApplicationsTable({
  viewApplication,
  loading,
  fetchRecords,
  totalRecord,
  seeApplicationLogs,
  currentPage,
  pageSize,
  applicationType,
  fetchAllApplications,
}) {
  const allApplications = useSelector(
    (state) => state.allApplications.applications
  );
  const _scholarshipTypes = useSelector(
    (state) => state.metaData.dropDowns?.scholarshipTypes
  );

  const [modal, showModal] = useState(false);
  const [checkedApplications, checkApplication] = useState([]);
  const [isLoading, setIsLoading] = useState(loading);
  const role = useSelector((state) => state.login.userInfo?.role);

  const dispatch = useDispatch();

  let applicationSpecificColumns =
    applicationType === "ENR" ||
    applicationType === "TRP" ||
    applicationType === "ASR"
      ? [
          {
            title: "Student Info",
            dataIndex: "",
            key: "",
            render: (record) => {
              return (
                <div>
                  <p>
                    {applicationType !== "ENR"
                      ? record.student.campus
                      : record?.childDetails?.campus?.valueMeta || ""}
                  </p>
                  <p>
                    {applicationType !== "ENR"
                      ? record.student.grade
                      : record?.childDetails?.newGrade?.valueMeta || ""}
                  </p>
                  <p>{record?.studentNumber || ""}</p>
                </div>
              );
            },
          },

          {
            title: "Student Name",
            dataIndex: "",
            key: "first_name",
            render: (record) => {
              if (applicationType !== "ENR") {
                return record.student?.name || "N/A";
              } else {
                const firstName = record.childDetails?.firstName?.value;
                const middleName = record.childDetails?.middleName?.value;
                const lastName = record.childDetails?.lastName?.value;

                return firstName || lastName || middleName
                  ? firstName + " " + middleName + " " + lastName
                  : "N/A";
              }
            },
          },

          {
            title: "Paid Amount",
            key: "registrationFeeAmount",
            render: (record) =>
              record.registrationFeeAmount
                ? record.registrationFeeAmount + " AED"
                : "N/A",
            hidden: applicationType !== "TRP" ? true : false,
          },
        ].filter((item) => !item.hidden)
      : applicationType === "UPD"
      ? [
          {
            title: "Request For",
            key: "target",
            render: (record) => (
              <div>
                {record.target === "student" ? (
                  <>
                    <span style={{ color: "#007575" }}>
                      {record.student.studentNumber}
                    </span>
                    <br />
                    {record.student.name}
                  </>
                ) : (
                  <p>{Capitalize(record.target)}</p>
                )}
              </div>
            ),
          },
        ]
      : applicationType === "TCC"
      ? [
          {
            title: "School Center",
            key: "target",
            render: (record) => (
              <div>
                {record && record?.studentDetails?.school?.valueMeta
                  ? record?.studentDetails?.school?.valueMeta.toUpperCase()
                  : "N/A"}
              </div>
            ),
          },
          {
            title: "Student Info",
            dataIndex: "",
            key: "",
            render: (record) => {
              return (
                <div>
                  <p>
                    {applicationType == "TCC" &&
                    Object.keys(record.student).length > 0
                      ? record.student.grade
                      : record?.studentDetails?.currentGrade?.valueMeta || ""}
                  </p>
                  <p>{record?.studentNumber || ""}</p>
                </div>
              );
            },
          },
          {
            title: "Student Name",
            dataIndex: "",
            key: "first_name",
            render: (record) => {
              if (
                applicationType === "TCC" &&
                Object.keys(record.student).length > 0
              ) {
                return record.student?.name || "N/A";
              } else {
                const firstName = record.studentDetails?.firstName?.value;
                const middleName = record.studentDetails?.middleName?.value;
                const lastName = record.studentDetails?.lastName?.value;

                return firstName || lastName || middleName
                  ? firstName + " " + middleName + " " + lastName
                  : "N/A";
              }
            },
          },
        ]
      : // schelopership columns
      applicationType === "SCH"
      ? [
          {
            title: "Rewards",
            key: "target",
            render: (record) => (
              <div>
                {record.reward && record.reward.title
                  ? record.reward.title
                  : "N/A"}
              </div>
            ),
          },
          {
            title: "Student Info",
            key: "target",
            render: (record) => (
              <div>
                {record?.studentDetails?.firstName.value +
                  " " +
                  record?.studentDetails?.lastName.value}
              </div>
            ),
          },
          {
            title: "Scholarship Type",
            key: "target",
            render: (record) => {
              const sch_Type = _scholarshipTypes?.find(
                (schType) =>
                  schType.id ===
                  parseInt(record?.details?.scholarshipType?.value)
              );
              return <div>{sch_Type ? sch_Type?.title : "N/A"}</div>;
            },
          },
        ]
      : [
          // TFD
          {
            title: "Rule",
            dataIndex: "discountRule",
            key: "id",
            render: (record) => record.title,
          },
          {
            title: "Students",
            dataIndex: "students",
            key: "students",
            render: (record) =>
              record.length > 0
                ? record.map((std, index) => (
                    <>
                      <span style={{ color: "#007575" }}>
                        {std.studentNumber}
                      </span>
                      <br />
                      {std.name}

                      <br />
                      <br />
                    </>
                  ))
                : "N/A",
          },
          {
            title: "Campus",
            dataIndex: "students",
            key: "id",
            render: (record) => (record?.length > 0 ? record[0].campus : "N/A"),
          },
        ];

  applicationSpecificColumns =
    applicationType === "TRP"
      ? [
          ...applicationSpecificColumns,
          {
            title: "Bus Assigned",
            render: (item) => (item.bus?.id ? item.bus.busNo : "N/A"),
          },
        ]
      : applicationSpecificColumns;

  const generalColumns1 = [
    {
      title: "ID",
      dataIndex: "",
      key: "title",
      render: (record) => (record?.title !== "" ? record?.title : record?.id),
      fixed: "left",
    },
    {
      title: "Service",
      dataIndex: "eService",
      key: "eService",
      render: (record) => Capitalize(record.name || "N/A"),
    },
  ];
  const generalColumns2 = [
    {
      title: "Parent No",
      dataIndex: "",
      key: "phone",
      render: (record) => {
        if (applicationType === "TRP") {
          return record.authorizedPersons?.parentPhoneNo?.value || "N/A";
        } else if (applicationType === "ENR") {
          if (record?.father?.phoneNo?.value)
            return record?.father?.phoneNo.value;
          else if (record?.mother?.phoneNo?.value)
            return record?.mother?.phoneNo.value;
          else return "N/A";
        } else {
          return record.submittedBy || "N/A";
        }
      },
    },
    {
      title: "Date",
      dataIndex: "",
      key: "id",
      render: (record) => moment(record.createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "id",
      render: (record) => record.status.internalStatus,
    },
    {
      title: "Current Stage",
      dataIndex: "",
      key: "id",
      render: (record) => record.stage.title,
    },
    {
      title: "Current Stage Status",
      dataIndex: "",
      key: "id",
      render: (record) => record.stage.status.internalStatus,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "id",
      fixed: "right",
      render: (record) => (
        <div style={{ display: "flex", gap: "7px" }} className="d-flex">
          <Tooltip title="View Application">
            <Button
              className="d-flex justify-content-center align-items-center"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => viewApplication(record)}
            />
          </Tooltip>

          <Tooltip title="Application Logs">
            <Button
              className="d-flex justify-content-center align-items-center"
              shape="circle"
              icon={<HistoryOutlined />}
              onClick={() => seeApplicationLogs(record)}
            />
          </Tooltip>

          {
            <Tooltip title="Approve Multiple Applications">
              <Button
                className="d-flex justify-content-center align-items-center"
                shape="circle"
                disabled={
                  !(
                    MultipleApproveApplications.includes(
                      record.stage.status.code
                    ) && record.hasStagePermission
                  )
                }
                icon={
                  isChecked(record) ? (
                    <CheckCircleFilled style={{ color: EnsBrandColor }} />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
                onClick={toggleApplicationToApprove(record, isChecked(record))}
              />
            </Tooltip>
          }

          {role === "super-admin" && (
            <Popconfirm
              placement="topLeft"
              title="Are you sure to delete this application?"
              onConfirm={() => {
                handelApplicationProcess("delete", record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip>
                <Button
                  className="d-flex justify-content-center align-items-center"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  //onClick={() => viewApplication(record)}
                />
              </Tooltip>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];
  let allColumns = generalColumns1.concat(applicationSpecificColumns);
  allColumns = allColumns.concat(generalColumns2);
  const toggleApplicationToApprove = (application, checked) => () => {
    checkApplication(
      checked
        ? checkedApplications.filter((el) => el.id !== application.id)
        : [...checkedApplications, application]
    );
  };
  const onHideModal = () => {
    showModal(false);
    checkApplication([]);
  };
  const onClickApprove = () => showModal(true);
  const isChecked = (application) => {
    return checkedApplications.findIndex((el) => el.id === application.id) > -1;
  };
  const onSubmitMultipleApprove = async (values) => {
    try {
      setIsLoading(true);
      const data = {
        stages: checkedApplications.map((application) => application.stage.id),
        ...values,
      };
      const response = await addMultipleStageApproval(data);
      if (response.status === 200) {
        successMessage({ message: "Remarks added successfully" });
        setIsLoading(false);
        onHideModal();
        fetchRecords(currentPage, pageSize);
      } else throw response;
    } catch (e) {
      requestErrorHandel({ error: e });
      setIsLoading(false);
    }
  };
  const handelApplicationProcess = async (action, applicationId) => {
    try {
      setIsLoading(true);
      const response = await applicationProcess({
        applicationId: applicationId,
        action: action,
      });

      if (response?.status === 200) {
        setIsLoading(false);
        dispatch(_deleteApplication(applicationId));
        successMessage({
          message: "Application deleted successfully",
        });
        fetchAllApplications();
      } else {
        setIsLoading(false);
        throw response;
      }
    } catch (error) {
      setIsLoading(false);

      await requestErrorHandel({ error: error });
    }
  };

  return (
    <div key="app_div">
      <Table
        rowKey={"id"}
        key="apps_table"
        loading={{
          indicator: (
            <div>
              <Spin indicator={renderLoader} />
            </div>
          ),
          spinning: isLoading,
        }}
        columns={allColumns}
        dataSource={allApplications}
        pagination={{
          pageSize: pageSize,
          total: totalRecord,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          onChange: (page, pageSize) => {
            fetchRecords(page, pageSize);
          },
          current: currentPage,
          showSizeChanger: true,
        }}
      />
      {checkedApplications.length ? (
        <Affix offsetBottom={10}>
          <Button type="primary" onClick={onClickApprove}>
            Add Remarks For Selected Applications
          </Button>
        </Affix>
      ) : null}
      <CustomModal
        visibility={modal}
        handleOk={onHideModal}
        handleCancel={onHideModal}
        title={"Approve Multiple Applications"}
      >
        <p>
          * Adding Remarks For: <br />
          <b>
            {checkedApplications
              .map((application) => application.title)
              .join(", ")}
          </b>
        </p>
        <PreFinalApprovalForm
          loading={isLoading}
          onFinish={onSubmitMultipleApprove}
        />
      </CustomModal>
    </div>
  );
}
