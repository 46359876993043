import client from "apis/config";

export const fetchAllFSMBanners = async ({ limit = 10, page }) => {
  return await client.get(
    `/banners?type=facility-management&limit=${limit}&page=${page}`
  );
};

export const addNewFsmBanner = async ({ data }) => {
  return await client.post("/banners", data);
};

export const updateFsmBanner = async ({ id, data }) => {
  return await client.put(`/banners/${id}`, data);
};

export const deleteFsmBanner = async ({ id }) => {
  return await client.delete(`/banners/${id}`);
};
