/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { Button, Card, Col, Row, Select, Spin, Tabs } from "antd";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import { _updateApplications } from "actions/applications/applications-actions";

import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import PreFinalApprovalForm from "components/Forms/pre-final-approval-form/PreFinalApprovalForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { addStageApproval } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { LoadingOutlined } from "@ant-design/icons";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { getDistributionAPI } from "apis/distribution-apis/DistributionApis";
import { _getDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";
import DiscountStudents from "components/DiscountComponents/DiscountStudentsTable/DiscountStudentsTable";
import DiscountOtherApplication from "components/DiscountComponents/DiscountOtherApplications/DiscountOtherApplications";
import UPDStudentTable from "components/UPDStudentsTable/UPDStudentsTable";
const { TabPane } = Tabs;
const { Option } = Select;

const OtherApplicationPreFinalApproval = (props) => {
  const dispatch = useDispatch();
  const [showApproveModal, toggleApproveModal] = React.useState(false);
  const [approvalStatus, setApprove] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const [activeTab, setActiveTab] = React.useState("details");
  const applicationId = props?.application?.id;

  const _distributions = useSelector((state) => state.distributions.data);
  const _otherDiscountApplications = useSelector(
    (state) =>
      state.allApplications?.applications?.filter(
        (el) =>
          el.applicationType === "TFD" &&
          el.id !== props.application.id &&
          el.submittedBy === props.application?.submittedBy &&
          el.eService?.term === props.application?.eService?.term
      ),
    shallowEqual
  );

  const stageStatusCode =
    props.application?.stage?.status?.code ||
    currentStageDetails?.stageStatus?.code;

  React.useEffect(() => {
    if (props.application.applicationType === "TFD") {
      fetchDistributions();
    }
  }, [props.application]);

  React.useEffect(() => {
    if (applicationId) {
      getLogs();
    }
  }, [applicationId]);

  const fetchDistributions = async () => {
    try {
      setLoading(true);
      const response = await getDistributionAPI(`page=1&limit=1000`);
      if (response.status == 200 && response.data) {
        dispatch(_getDistributionAction(response.data));
        setLoading(false);
      } else throw response.data;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };
  const getLogs = async () => {
    try {
      const response = await getApplicationStagesLogs({ id: applicationId });
      if (response.status == 200) {
        let applicationCurrentStage = response.data.data.find(
          (item) => item.stageSlug == "pre_final_approval"
        );
        setcurrentStageDetails(applicationCurrentStage);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onApproveClick = () => toggleApproveModal(true);

  const onSubmitApprove = (values) => {
    //   approve, notes

    toggleApproveModal(false);
    values["appStageId"] = props.application?.stage.id;
    // setApprove(values.approve?.toUpperCase() || "");
    onAddStagesApproval(values);
    // props.refetch();
    // getLogs();
  };

  const onAddStagesApproval = async (values) => {
    try {
      setLoading(true);
      const response = await addStageApproval({ data: values });
      if (response.status == 200) {
        successMessage({ message: "Remarks added successfully" });
        setLoading(false);
        props.refetch();
        getLogs();
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const renderRemarksDetails = () => {
    if (!props.application.hasStagePermission) return null;
    if (!currentStageDetails?.approvals.length) return null;
    return (
      <Card
        hoverable
        className="antCardBorder antCard"
        style={{ marginTop: 20 }}
      >
        <h6>Final Remarks</h6>
        <h4>
          {currentStageDetails?.approvals[0].isApproved
            ? "Accepted"
            : "Declined"}
        </h4>
        <hr />
        <p style={{ overflowX: "clip" }}>
          {currentStageDetails?.approvals[0].notes}
        </p>
      </Card>
    );
  };

  const getBtnTitle = () => {
    // waiting approval
    if (
      stageStatusCode === "SG830" ||
      stageStatusCode === "SG230" ||
      stageStatusCode === "SG530" ||
      stageStatusCode === "SG1130"
    ) {
      return "Add Remarks";
    } else if (
      [
        "SG840",
        "SG850",
        "SG890",
        "SG240",
        "SG250",
        "SG290",
        "SG540",
        "SG550",
        "SG590",
        "SG1140",
        "SG1150",
        "SG1190",
      ].includes(stageStatusCode)
    ) {
      // accepted, declined, or waiting approval in final approval stage
      return "Completed";
    } else if (
      stageStatusCode == "SG860" ||
      stageStatusCode == "SG260" ||
      stageStatusCode == "SG560" ||
      stageStatusCode === "SG1160"
    ) {
      // cancelled
      return "Cancelled";
    } else return null;
  };

  const getTitle = () => {
    return props.application.stage?.status?.internalStatus || "";
  };

  const getDescription = () => {
    if (
      stageStatusCode == "SG830" ||
      stageStatusCode == "SG230" ||
      stageStatusCode == "SG530" ||
      stageStatusCode === "SG1130"
    ) {
      return "Please review the application and add your remarks";
    } else if (
      [
        "SG840",
        "SG850",
        "SG240",
        "SG250",
        "SG540",
        "SG550",
        "SG1140",
        "SG1150",
        "SG1190",
      ].includes(stageStatusCode)
    ) {
      return "The stage has been completed successfully";
    } else if (
      stageStatusCode == "SG860" ||
      stageStatusCode == "SG260" ||
      stageStatusCode == "SG560" ||
      stageStatusCode === "SG1160"
    ) {
      return "The stage has been cancelled";
    } else return null;
  };

  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={loader}>
        {props.application ? (
          <div>
            <Row gutter={[20, 0]}>
              <Col sm={24} md={12}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>
              {props.application.hasStagePermission && (
                <Col sm={24} md={12}>
                  <Card hoverable className="antCardBorder antCard">
                    <Row align="middle" justify="space-between">
                      <div>
                        <h5>{getTitle()}</h5>
                        <p style={{ fontSize: "12px" }}>{getDescription()}</p>
                      </div>
                      <Row>
                        <div style={{ width: "20px" }}></div>

                        <Button
                          type="primary"
                          className="appButton"
                          onClick={onApproveClick}
                          disabled={[
                            "SG840",
                            "SG850",
                            "SG860",
                            "SG240",
                            "SG250",
                            "SG260",
                            "SG1140",
                            "SG1150",
                            "SG1160",
                          ].includes(stageStatusCode)}
                        >
                          {getBtnTitle()}
                        </Button>
                      </Row>
                    </Row>
                  </Card>
                </Col>
              )}
            </Row>
            {/* {["SG840", "SG850", "SG240", "SG250"].includes(stageStatusCode)
              ? renderRemarksDetails()
              : null} */}
            {renderRemarksDetails()}
            <Tabs
              activeKey={activeTab}
              onChange={(tab) => setActiveTab(tab)}
              style={{ marginTop: "1rem" }}
              tabBarGutter={40}
              // centered
            >
              <TabPane tab={<h6>Details</h6>} key="details">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={props.application.applicationType?.toLowerCase()}
                />
              </TabPane>
              <TabPane tab={<h6>Documents</h6>} key="documents">
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>
              {props.application?.applicationType === "ASR" &&
                props.application?.items?.length > 0 && (
                  <TabPane tab={<h6>Items</h6>} key={"items"}>
                    {/* <ASRItemsList items={props.application.items} /> */}
                    <ApplicationInformationReadOnlyForm
                      application={props.application}
                      type={"asr-items"}
                    />
                  </TabPane>
                )}
              {props.application.applicationType === "TFD" && (
                <TabPane tab={<h6>Students</h6>} key="students">
                  <DiscountStudents
                    data={props.application.students}
                    renderDistribution={(record, index) => (
                      <Select
                        style={{ width: "100%" }}
                        value={record.distributionId}
                        disabled
                      >
                        {[
                          ..._distributions,
                          {
                            title: "OPEN",
                            id: 0,
                            instalments: new Array(record.invoices?.length),
                          },
                        ].map((el) => (
                          <Option disabled value={el.id}>
                            {el.title}
                          </Option>
                        ))}
                      </Select>
                    )}
                    readOnly
                  />
                </TabPane>
              )}
              <TabPane tab={<h6>Stages History</h6>} key="logs">
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>
            {props.application.applicationType === "TFD" && (
              <Card className="antCardBorder antCard" style={{ marginTop: 20 }}>
                <DiscountOtherApplication data={_otherDiscountApplications} />
              </Card>
            )}
            <UPDStudentTable application={props.application} />
            <CustomModal
              visibility={showApproveModal}
              handleCancel={() => toggleApproveModal(false)}
              title="Pre Final Approval Remarks"
            >
              <PreFinalApprovalForm
                onFinish={onSubmitApprove}
                loading={loading}
              />
            </CustomModal>
          </div>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default OtherApplicationPreFinalApproval;
