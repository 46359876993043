import React from "react";
import { UserOutlined } from "@ant-design/icons";

export default function EmployeeLogs({ employees }) {
  return (
    <div>
      <h6>Assigned To:</h6>
      <div className="rh-log-item">
        {employees?.length > 0 ? (
          employees.map((employee) => (
            <div className="rh-log-row">
              <UserOutlined className="rh-log-icon" />
              <p>
                {employee.name} - {employee.userName}
              </p>
            </div>
          ))
        ) : (
          <b>Not assigned to any employee</b>
        )}
      </div>
    </div>
  );
}
