/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { useSelector } from "react-redux";

const RequestStatusesForm = ({ documentFor, loading, form }) => {
  const { Option } = Select;

  return (
    <div>
      <Row gutter={[20, 0]}>
        <Col md={12} sm={24} style={{ width: "100%" }}>
          <Form.Item
            label="Identifier"
            name="code"
            rules={[
              {
                required: true,
                message: "Indetifier is required",
              },
            ]}
          >
            <Input placeholder="code" readOnly disabled />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} style={{ width: "100%" }}>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Description is required",
              },
            ]}
          >
            <Input placeholder="Description" />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} style={{ width: "100%" }}>
          <Form.Item
            label="Internal Status (EN)"
            name="internalStatus"
            rules={[
              {
                required: true,
                message: "InternalStatusEN is required",
              },
            ]}
          >
            <Input placeholder="InternalStatusEN" />
          </Form.Item>
        </Col>

        <Col md={12} sm={24} style={{ width: "100%" }}>
          <Form.Item
            label="Internal Status (AR)"
            name="internalStatusAr"
            rules={[
              {
                required: true,
                message: "InternalStatusAR is required",
              },
            ]}
          >
            <Input placeholder="EnternalStatusAR" dir="rtl" />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} style={{ width: "100%" }}>
          <Form.Item
            label="External Status (EN)"
            name="externalStatus"
            rules={[
              {
                required: true,
                message: "ExternalStatusEN is required",
              },
            ]}
          >
            <Input placeholder="ExternalStatusEN" />
          </Form.Item>
        </Col>

        <Col md={12} sm={24} style={{ width: "100%" }}>
          <Form.Item
            label="External Status (AR)"
            name="externalStatusAr"
            rules={[
              {
                required: true,
                message: "ExternalStatusAR is required",
              },
            ]}
          >
            <Input placeholder="ExternalStatusAR" dir="rtl" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"end"}>
        <Button loading={loading} htmlType="submit">
          Update
        </Button>
      </Row>
    </div>
  );
};
export default RequestStatusesForm;
