import client from "apis/config";

export const getEServicesAPI = async (pagination) => {
  return await client.get(`eservices?${pagination}`);
};

export const addEServicesAPI = async (data) => {
  return await client.post(`eservices`, data);
};

export const updateEServicesAPI = async (id, data) => {
  return await client.put(`eservices/${id}`, data);
};

export const deleteEServicesAPI = async (id, data) => {
  return await client.delete(`eservices/${id}`);
};
