import bannerIcon from "../assets/img/sibar/documentListIcon.png";
import contentIcon from "../assets/img/allapplicationicon.png";
import eventsIcon from "../assets/img/sibar/requestStatusIcon.png";
import cmsIcon from "../assets/img/sibar/ens-enr-icon.svg";
import newsIcon from "../assets/img/sibar/ens-asr-icon.svg";
import vacancyIcon from "../assets/img/sibar/ens-upd-icon.svg";

import EventsCMS from "views/cms/events/EventsCMS.jsx";
import NewsCMS from "views/cms/news/NewsCMS.jsx";
import BannersCMS from "views/cms/banners/BannersCMS.jsx";
import VacancyCMS from "views/cms/vacancies/Vacancies";
import Content from "views/cms/content/Content";
import AccouncementAlerts from "views/cms/accouncementAlerts/AnnouncementAlerts";

const CMSRoutes = [
  {
    path: "/cms",
    name: "Website CMS",
    icon: cmsIcon,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "cms",
    subMenu: [
      {
        path: "/events",
        name: "Events",
        icon: eventsIcon,
        component: EventsCMS,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Events",
      },
      {
        path: "/news",
        name: "News",
        icon: newsIcon,
        component: NewsCMS,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "News",
      },
      {
        path: "/vacancies",
        name: "Vacancies",
        icon: vacancyIcon,
        component: VacancyCMS,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Vacancies",
      },
      {
        path: "/banners",
        name: "Banners",
        icon: bannerIcon,
        component: BannersCMS,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Banners",
      },
      {
        path: "/AnnouncementAlerts",
        name: "Announcement Alerts",
        icon: bannerIcon,
        component: AccouncementAlerts,
        layout: "/admin",
        subPath: true,
        sideMenuTitle: "Announcement Alerts",
      },
      // {
      //   path: "/content",
      //   name: "Content",
      //   icon: contentIcon,
      //   component: Content,
      //   layout: "/admin",
      //   subPath: true,
      //   sideMenuTitle: "Content",
      // },
    ],
  },
];

export default CMSRoutes;
