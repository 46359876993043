import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select, Image } from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import TCCCourseForm from "components/Forms/tcc-forms/TCCCourseForm";
import { fetchAllCourses } from "apis/TCC/tccRequestTypes";
import { addNewCourse } from "apis/TCC/tccRequestTypes";
import { updateCourse } from "apis/TCC/tccRequestTypes";
import { deleteCourse } from "apis/TCC/tccRequestTypes";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const CoursesConfig = () => {
  const _requestTypes = useSelector((state) => state.metaData.requestTypes);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [teacherId, setTeacherId] = useState("");
  const [forGrades, setForGrades] = React.useState([]);

  const [uploading, setUpLoading] = useState(false);
  const [file, setFile] = useState();
  const [icon, setIcon] = useState();

  const [tccCourseForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            tccCourseForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"TCC Courses"}
          headerSubTitle={"Manage TCC Courses"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    try {
      setLoading(true);
      const response = await fetchAllCourses();
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addCourse = async (data) => {
    try {
      setLoading(true);
      const response = await addNewCourse({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Course added successfully" });
        await getAllCourses();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateCourse = async (data) => {
    try {
      setLoading(true);
      const response = await updateCourse({ id: teacherId, data: data });
      if (response.status === 200) {
        successMessage({ message: "Course updated successfully" });
        await getAllCourses();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteCourse = async (id) => {
    try {
      setLoading(true);
      const response = await deleteCourse({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Course deleted successfully" });
        await getAllCourses();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
    setFile(undefined);
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      isEnabled: values?.isEnabled !== undefined ? true : false,
      coverImage: file,
      icon: icon,
      forGrades: forGrades.map((param) => param.id),
      departmentId: Number(values.departmentId),
    };
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateCourse(data);
    } else {
      await addCourse(data);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    record.coverImage && setFile(record.coverImage);
    tccCourseForm.setFieldsValue({
      ...record,
    });
    setTeacherId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  const onChooseFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "tccEnrollments"
        );
        setFile(path);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };
  const onChooseIcon = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const path = await handleUploadFileToENSServer(
          e.target.files[0],
          "tccEnrollments"
        );
        setIcon(path);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Code",
              dataIndex: "code",
              key: "code",

              // render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",

              // render: (record) => <p className="answer_para">{record}</p>,
            },
            {
              title: "Department",
              dataIndex: "department",
              key: "department",

              // render: (record) => <p className="answer_para">{record}</p>,
            },

            {
              title: "Active",
              //   dataIndex: "isEnabled",
              key: "isEnabled",
              render: (record) => (
                <p className="answer_para">
                  {record?.isEnabled ? "YES" : "NO"}
                </p>
              ),
            },
            {
              title: "Cover Image",
              dataIndex: "coverImage",
              key: "coverImage",
              render: (record) => (
                <Image src={record} width={90} placeholder={true} />
              ),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Course Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Course?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => handleDeleteCourse(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Course`}
        >
          <TCCCourseForm
            onSubmit={onSubmit}
            formName={tccCourseForm}
            loading={loading}
            valuesForEdit={valuesForEdit}
            _requestTypes={_requestTypes}
            uploading={uploading}
            onChooseFile={onChooseFile}
            file={file}
            icon={icon}
            setForGrades={setForGrades}
            onChooseIcon={onChooseIcon}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default CoursesConfig;
