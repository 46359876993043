export const ENRRoutes = [
  {
    title: "Stages Configuration",
    path: "/admin/request-types/stage-list",
  },
  { title: "Stage Statuses", path: "/admin/request-types/StageStatuses" },
  {
    title: "Notifications Triggers",
    path: "/admin/request-types/NotificationsTriggers",
  },
  {
    title: "Documents Configuration",
    path: "/admin/request-types/documents-configuration",
  },
];
export const SCHRoutes = [
  {
    title: "Stages Configuration",
    path: "/admin/request-types/stage-list",
  },
  { title: "Stage Statuses", path: "/admin/request-types/StageStatuses" },
  {
    title: "Notifications Triggers",
    path: "/admin/request-types/NotificationsTriggers",
  },
  {
    title: "Documents Configuration",
    path: "/admin/request-types/documents-configuration",
  },
  {
    title: "Rewards",
    path: "/admin/request-types/rewards",
  },
  {
    title: "Evaluation Points",
    path: "/admin/request-types/evaluation-points-config",
  },
  // {
  //   title: "Financial Status",
  //   path: "/admin/request-types/financial-status",
  // },
  // {
  //   title: "School Rating",
  //   path: "/admin/request-types/school-rating",
  // },
  // {
  //   title: "Educational Allowance",
  //   path: "/admin/request-types/educational-allowance",
  // },
  // {
  //   title: "Academic Achievement",
  //   path: "/admin/request-types/academic-achievement",
  // },
  // {
  //   title: "Non Academic Achievement",
  //   path: "/admin/request-types/non-academic-achievement",
  // },
];
export const TCCRoutes = [
  {
    title: "Stages Configuration",
    path: "/admin/request-types/stage-list",
  },
  { title: "Stage Statuses", path: "/admin/request-types/StageStatuses" },
  {
    title: "Notifications Triggers",
    path: "/admin/request-types/NotificationsTriggers",
  },
  {
    title: "Documents Configuration",
    path: "/admin/request-types/documents-configuration",
  },
  {
    title: "Teachers",
    path: "/admin/request-types/teachers",
  },
  {
    title: "Courses",
    path: "/admin/request-types/courses",
  },
  {
    title: "Schedules",
    path: "/admin/request-types/schedules",
  },
];
export const TCCConfigRoutes = [
  {
    title: "Sections",
    path: "/admin/e-services/sections",
  },
  {
    title: "Configuration",
    path: "/admin/e-services/configuration",
  },
];
export const OtherRequestTypesRoutes = [
  {
    title: "Stages Configuration",
    path: "/admin/request-types/stage-list",
  },
  { title: "Stage Statuses", path: "/admin/request-types/StageStatuses" },
  {
    title: "Documents Configuration",
    path: "/admin/request-types/documents-configuration",
  },
  {
    title: "Notifications Triggers",
    path: "/admin/request-types/NotificationsTriggers",
  },
  {
    title: "Rules",
    path: "/admin/request-types/discount-rules",
  },
  {
    title: "Distributions",
    path: "/admin/request-types/discount-distribution",
  },
];

export const ASRRoutes = [
  {
    title: "Stages Configuration",
    path: "/admin/request-types/stage-list",
  },
  { title: "Stage Statuses", path: "/admin/request-types/StageStatuses" },
  {
    title: "Notifications Triggers",
    path: "/admin/request-types/NotificationsTriggers",
  },
  {
    title: "Documents Configuration",
    path: "/admin/request-types/documents-configuration",
  },
  {
    title: "Service Items",
    path: "/admin/request-types/service-items",
  },
];
export const TRPRoutes = [
  {
    title: "Stages Configuration",
    path: "/admin/request-types/stage-list",
  },
  { title: "Stage Statuses", path: "/admin/request-types/StageStatuses" },
  {
    title: "Notifications Triggers",
    path: "/admin/request-types/NotificationsTriggers",
  },
  {
    title: "Documents Configuration",
    path: "/admin/request-types/documents-configuration",
  },
  {
    title: "Buses",
    path: "/admin/request-types/buses-cms",
  },
];

// facility management bookings request types routes

export const BOOKING_TYPES_ROUTES = [
  {
    title: "Stages Configuration",
    path: "/admin/request-types/stage-list",
  },
  { title: "Stage Statuses", path: "/admin/request-types/StageStatuses" },
  {
    title: "Notifications Triggers",
    path: "/admin/request-types/NotificationsTriggers",
  },
  {
    title: "Cashier Configuration",
    path: "/admin/request-types/fsm-cashier-configuration",
  },
];
