/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { Button, Card, Col, Row, Spin, Tabs } from "antd";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import styles from "../../../../styles/applicationDetails.module.css";
import { applicationProcess } from "apis/applications-apis/applications";
import { _updateApplications } from "actions/applications/applications-actions";

import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import PreFinalApprovalForm from "components/Forms/pre-final-approval-form/PreFinalApprovalForm";

import { addStageApproval } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { LoadingOutlined } from "@ant-design/icons";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import DiscountOtherApplication from "components/DiscountComponents/DiscountOtherApplications/DiscountOtherApplications";
import { shallowEqual, useSelector } from "react-redux";
const { TabPane } = Tabs;

const PreFinalApproval = (props) => {
  const [showApproveModal, toggleApproveModal] = React.useState(false);
  const [approvalStatus, setApprove] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const [isRetestEnabled, setIsRetestEnabled] = React.useState(false);
  const applicationId = props?.application?.id;
  const [isInterviewEnabled, setInterviewEnabled] = React.useState(false);
  const [restedtSub, setRetestSub] = React.useState([]);

  React.useEffect(() => {
    props.application && getLogs();
  }, [props.application]);

  const getLogs = async () => {
    try {
      const response = await getApplicationStagesLogs({ id: applicationId });
      if (response.status == 200) {
        const _entranceExamDetails = response.data?.data?.find(
          (item) => item.stageSlug == "entrance_test"
        );

        if (
          _entranceExamDetails !== undefined &&
          _entranceExamDetails !== null &&
          _entranceExamDetails.schedules.length == 1
        ) {
          setIsRetestEnabled(true);
        }

        let interviewLogs = response.data?.data?.find(
          (item) => item.stageSlug == "interview"
        );
        setInterviewEnabled(
          interviewLogs && interviewLogs.stageStatus?.code == "SG013"
        );

        var applicationCurrentStage = currentStageDetails;
        if (
          applicationCurrentStage == undefined ||
          applicationCurrentStage == ""
        ) {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == props.application.stage.slug
          );
        } else {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == currentStageDetails.stageSlug
          );
        }
        setcurrentStageDetails(applicationCurrentStage);
      }
    } catch (error) {
      await requestErrorHandel({ error: error });
    }
  };

  const onApproveClick = () => toggleApproveModal(true);
  const onSubmitApprove = (values) => {
    //   approve, notes

    toggleApproveModal(false);
    values["appStageId"] = props.application?.stage.id;
    if (values.isApproved === "retest") {
      handelApplicationProcess("retest");
    } else if (values.isApproved === "interview") {
      handelApplicationProcess("recommend_interview");
    } else {
      onAddStagesApproval(values);
    }
  };

  const handelApplicationProcess = async (action) => {
    const retestSubjects = restedtSub
      .filter((subject) => subject.isCheck)
      .map((subject) => subject.subjectName);

    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: props.application?.id,
        action: action,
        schoolId: "",
        retestSubjects: action === "retest" ? retestSubjects : [],
      });
      if (response?.status == 200) {
        props.refetch();
        getLogs();
        successMessage({ message: "Application status updated successfully" });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const renderRetest = () => {
    if (!isRetestEnabled) return null;
    if (
      currentStageDetails?.stageStatus?.code == "SG043" ||
      currentStageDetails?.stageStatus?.code == "SG050"
    ) {
      return (
        <Button
          type="primary"
          className="appButton"
          onClick={() => handelApplicationProcess("retest")}
        >
          Retest
        </Button>
      );
    }
    return null;
  };

  const onAddStagesApproval = async (values) => {
    try {
      setLoading(true);
      const response = await addStageApproval({ data: values });
      if (response.status == 200) {
        successMessage({ message: "Remarks added successfully" });
        // getDetails();
        props.refetch();
        getLogs();
        setLoading(false);
        // dispatch(_updateApplications(applicationId, response.data.data));
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const renderRemarksDetails = () => {
    if (!props.application.hasStagePermission) return null;
    if (!currentStageDetails?.approvals[0]) return null;
    return (
      <Card
        hoverable
        className="antCardBorder antCard"
        style={{ marginTop: 20 }}
      >
        <h6>Final Remarks</h6>
        <h4>
          {currentStageDetails?.approvals[0].isApproved
            ? "Accepted"
            : "Declined"}
        </h4>
        <hr />
        <p style={{ overflowX: "clip" }}>
          {currentStageDetails?.approvals[0].notes}
        </p>
      </Card>
    );
  };

  const getBtnTitle = () => {
    if (currentStageDetails?.stageStatus?.code == "SG043") {
      return "Add Remarks";
    } else if (
      ["SG044", "SG045"].includes(currentStageDetails?.stageStatus?.code)
    ) {
      return "Completed";
    } else if (currentStageDetails?.stageStatus?.code == "SG047") {
      return "Cancelled";
    }
  };

  const getTitle = () => {
    if (currentStageDetails?.stageStatus?.code == "SG036") {
      return "Retest";
    } else if (currentStageDetails?.stageStatus?.code == "SG037") {
      return "Retest";
    } else if (currentStageDetails?.stageStatus?.code == "SG038") {
      return "Retest";
    }
    return currentStageDetails?.stageStatus?.internalStatus;
  };

  const getDescription = () => {
    if (currentStageDetails?.stageStatus?.code == "SG036") {
      return currentStageDetails?.stageStatus?.internalStatus;
    } else if (currentStageDetails?.stageStatus?.code == "SG037") {
      return currentStageDetails?.stageStatus?.internalStatus;
    } else if (currentStageDetails?.stageStatus?.code == "SG038") {
      return currentStageDetails?.stageStatus?.internalStatus;
    } else if (currentStageDetails?.stageStatus?.code == "SG043") {
      return "Please review the application and add your remarks";
    } else if (
      ["SG044", "SG045"].includes(currentStageDetails?.stageStatus?.code)
    ) {
      return "The stage has been completed successfully";
    } else if (currentStageDetails?.stageStatus?.code == "SG047") {
      return "The stage has been cancelled";
    }
  };
  const handelRetestSubject = (subjects) => {
    setRetestSub(subjects);
  };

  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={loader}>
        {props.application ? (
          <div>
            <Row gutter={[20, 0]}>
              <Col sm={24} md={props.application.hasStagePermission ? 12 : 24}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>
              {props.application.hasStagePermission && (
                <Col sm={24} md={12}>
                  <Card hoverable className="antCardBorder antCard">
                    <Row align="middle" justify="space-between">
                      <div>
                        <h5>{getTitle()}</h5>
                        <p style={{ fontSize: "12px" }}>{getDescription()}</p>
                      </div>
                      <Row>
                        {/* {renderRetest()} */}
                        <div style={{ width: "20px" }}></div>

                        {!["SG036", "SG037", "SG038"].includes(
                          currentStageDetails?.stageStatus?.code
                        ) && (
                          <Button
                            type="primary"
                            className="appButton"
                            onClick={onApproveClick}
                            disabled={
                              ["SG044", "SG045", "SG047"].includes(
                                currentStageDetails?.stageStatus?.code
                              )
                                ? true
                                : false
                            }
                          >
                            {getBtnTitle()}
                          </Button>
                        )}
                      </Row>
                    </Row>
                  </Card>
                </Col>
              )}
            </Row>
            {["SG044", "SG045", "SG046"].includes(
              currentStageDetails?.stageStatus?.code
            )
              ? renderRemarksDetails()
              : null}
            <Tabs className="applicationDetailsTab" defaultActiveKey="1">
              <TabPane
                tab={<h5 className={styles.tabTitle}>Father Information</h5>}
                key="1"
              >
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"father"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Mother Information</h5>}
                key="2"
              >
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"mother"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Child Information</h5>}
                key="3"
              >
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"child"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Documents</h5>}
                key="4"
              >
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Stages History</h5>}
                key="5"
              >
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>
            <CustomModal
              visibility={showApproveModal}
              handleCancel={() => toggleApproveModal(false)}
              title="Pre Final Approval Remarks"
            >
              <PreFinalApprovalForm
                onFinish={onSubmitApprove}
                loading={loading}
                showRetest={
                  isRetestEnabled &&
                  (currentStageDetails?.stageStatus?.code == "SG043" ||
                    currentStageDetails?.stageStatus?.code == "SG050")
                }
                showInterview={isInterviewEnabled}
                handelRetestSubject={handelRetestSubject}
              />
            </CustomModal>
          </div>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default PreFinalApproval;
