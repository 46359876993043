/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import { Input, Form, Row, Select, Modal } from "antd";
import { useSelector } from "react-redux";

import styles from "../UploadedDocumentListItem/style.module.css";
import { useState } from "react";
import defaultImg from "../../assets/img/default.png";
import { EnvironmentOutlined } from "@ant-design/icons";
import ASRFormItem from "components/ASRFormItem/ASRFormItem";
const { Option } = Select;

export default function CompactFormItemReadOnly({
  name,
  formElement,
  handleFileInputChange,
  application,
  educationSystemGrades,
}) {
  const inputFile = useRef(null);
  const _campuses = useSelector((state) => state.metaData.campuses);
  const choiceOfCampuses = _campuses.filter((campus) =>
    application?.eService?.availableCampuses.includes(campus.id)
  );
  const _scholarshipTypes = useSelector(
    (state) => state.metaData.dropDowns?.scholarshipTypes
  );
  const SCH_schools = useSelector((state) => state.metaData.SCH_schools);
  const _educationalAllowances = useSelector(
    (state) => state.metaData.educationalAllowances
  );
  const _scoringSystem = useSelector((state) => state.metaData.scoringSystem);

  const _terms = useSelector((state) => state.metaData.terms);
  const _countries = useSelector((state) => state.metaData.countries);
  const _allSchools = useSelector((state) => state.metaData.schools);
  const _tccSchools = useSelector((state) => state.metaData.TCC_schools);

  const _educationalSystems = useSelector(
    (state) => state.metaData.educationalSystems
  );
  const dropDowns = useSelector((state) => state.metaData.dropDowns);
  const [imageView, setImageViewer] = useState(null);
  const [visible, setVisible] = useState(false);
  const [coordinates, setCoordinates] = useState(null);

  const _grades = useSelector((state) => state.metaData.grades);
  const assessmentExams = useSelector(
    (state) => state.metaData.assessmentExams
  );
  const nonAcademicAchievement = useSelector(
    (state) => state.metaData.nonAcademicAchievement
  );
  const contributionToResearch = useSelector(
    (state) => state.metaData.contributionToResearch
  );
  const socialAndCommunityActivities = useSelector(
    (state) => state.metaData.socialAndCommunityActivities
  );
  const certificatesAndAwards = useSelector(
    (state) => state.metaData.certificatesAndAwards
  );
  const [profileImagePreviewState, setProfileImagePreviewState] =
    useState(null);

  const generateImagePreview = async (e) => {
    var file = e.target.files[0];

    const objectUrl = URL.createObjectURL(file);

    setProfileImagePreviewState(objectUrl);
  };
  const targetImage = (item) => {
    setImageViewer(item);
  };

  useEffect(() => {
    getMapCoordinates();
  }, []);

  const getMapCoordinates = () => {
    if (formElement.value && name === "mapLocation") {
      let location = formElement.value ? JSON.parse(formElement.value) : null;
      setCoordinates({
        latitude: location.lat,
        longitude: location.lng,
        address: location.formattedAddress,
      });
    }
  };

  const openMap = () => {
    if (coordinates)
      window.open(
        `https://maps.google.com?q=${
          coordinates.latitude + "," + coordinates.longitude
        }`
      );
  };
  const yesNoTrueFalseList = [
    {
      title: "Yes",
      value: "true",
    },
    {
      title: "No",
      value: "false",
    },
  ];

  return (
    <div
      style={{
        display:
          (name === "passportNumber" &&
            (application?.father?.isUAEResident?.value === "true" ||
              application?.mother?.isUAEResident?.value === "true" ||
              application?.studentDetails?.isUAEResident?.value === "true")) ||
          (name === "schoolName" &&
            application?.criteria?.schoolInUAE?.value === "true")
            ? "none"
            : "",
      }}
    >
      {formElement.valueType == "image" ? (
        <Row align="bottom" justify="start" style={{ padding: "1rem 5vw" }}>
          <div className={styles.uploadedDocumentItemWrapper}>
            <div className={styles.documentItemDescWrapper}>
              <div className={styles.uploadedDocumentThumbnail}>
                <Form.Item name={[name, "value"]}>
                  <img
                    src={
                      profileImagePreviewState === null &&
                      (formElement.value === "" ||
                        !formElement.value.includes("http"))
                        ? defaultImg
                        : profileImagePreviewState != null
                        ? profileImagePreviewState
                        : formElement.value
                    }
                    alt="profile"
                    onClick={(e) => {
                      setVisible(true);
                      targetImage(formElement.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  disabled={true}
                  style={{ display: "none" }}
                  name={[name, "id"]}
                  label={formElement?.title}
                >
                  <Input disabled={true} style={{}} placeholder="" />
                </Form.Item>
              </div>
              <div className={styles.documentItemTitle}>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleFileInputChange(e, formElement);
                    generateImagePreview(e);
                  }}
                />
                <p> {formElement?.title ? formElement?.title : "File Title"}</p>
              </div>
            </div>
            <div className={styles.uploadedDocumentItemActions}></div>
          </div>
        </Row>
      ) : (
        <>
          {name == "campus" ? (
            <>
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select disabled={true}>
                  {_campuses?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id == formElement.value}
                    >
                      {el.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : name == "currentGrade" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {_grades?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    selected={el.id == formElement.value}
                  >
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "motherLanguage" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {dropDowns.languages?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.value}`}
                    selected={el.value == formElement.value}
                  >
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "otherLanguages" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select
                disabled={true}
                mode="multiple"
                defaultValue={["as", "aaa"]}
                value={["as", "aaa"]}
              >
                {dropDowns.languages?.map((el, i) => (
                  <Option key={i} value={`${el.value}`}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "newGrade" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {_grades?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    selected={el.id == formElement.value}
                  >
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "hasHealthIssue" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {dropDowns.yesNoList?.map((el, i) => (
                  <Option
                    key={i}
                    value={el.value}
                    selected={el.value == formElement.value}
                  >
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "canRepeatCurrentGrade" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {dropDowns.yesNoList?.map((el, i) => (
                  <Option
                    key={i}
                    value={el.value}
                    selected={el.value == formElement.value}
                  >
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "term" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {_terms?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    selected={el.id == formElement.value}
                  >
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "country" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {_countries?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    selected={el.id == formElement.value}
                  >
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "school" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {application?.applicationType === "TCC" ? (
                  <>
                    {_tccSchools?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id == formElement.value}
                      >
                        {el.abbreviation.toUpperCase()}
                      </Option>
                    ))}
                  </>
                ) : (
                  <>
                    {_allSchools?.map((el, i) => (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id == formElement.value}
                      >
                        {el.name}
                      </Option>
                    ))}
                  </>
                )}
              </Select>
            </Form.Item>
          ) : name == "gender" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {dropDowns.gender?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.value}`}
                    selected={el.value == formElement.value}
                  >
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name === "details" ||
            name === "parentNote" ||
            name === "studentNote" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Input.TextArea disabled placeholder="Enter Details" />
            </Form.Item>
          ) : name == "transportationAM" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled>
                {dropDowns.transportationMode?.map((el, i) => (
                  <Option
                    key={el.id}
                    value={`${el.value}`}
                    selected={el.value == formElement.value}
                  >
                    <p>{el.title}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "transportationPM" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled>
                {dropDowns.transportationMode?.map((el, i) => (
                  <Option key={i} value={`${el.value}`}>
                    <p>{el.title}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name === "contactPriority" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled>
                {dropDowns.contactPriority?.map((el, i) => {
                  return (
                    <Option key={i} value={el.value}>
                      <p>{el.title}</p>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : name === "qualification" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled>
                {dropDowns.qualification?.map((el, i) => (
                  <Option key={i} value={el.value}>
                    <p>{el.title}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name === "religion" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled>
                {dropDowns.religion?.map((el, i) => (
                  <Option key={i} value={el.value}>
                    <p>{el.title}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name == "0" ? (
            <>
              <p>Emergency Contact 1</p>
              <Form.Item
                disabled={true}
                name={[0, "value", "name"]}
                label="Name"
              >
                <Input disabled={true} style={{}} placeholder="" />
              </Form.Item>

              <Form.Item name={[0, "value", "relation"]} label="Relation">
                <Input disabled={true} style={{}} placeholder="" />
              </Form.Item>
              <Form.Item name={[0, "value", "phoneNo"]} label="Phone No">
                <Input disabled={true} style={{}} placeholder="" />
              </Form.Item>
            </>
          ) : name == "1" ? (
            <>
              <p>Emergency Contact 2</p>
              <Form.Item
                disabled={true}
                name={[1, "value", "name"]}
                label="Name"
              >
                <Input disabled={true} style={{}} placeholder="" />
              </Form.Item>

              <Form.Item
                disabled={true}
                name={[1, "value", "relation"]}
                label="Relation"
              >
                <Input disabled={true} style={{}} placeholder="" />
              </Form.Item>
              <Form.Item
                disabled={true}
                name={[1, "value", "phoneNo"]}
                label="Phone No"
              >
                <Input disabled={true} style={{}} placeholder="" />
              </Form.Item>
            </>
          ) : name == "mapLocation" ? (
            <>
              <Form.Item
                label={"Map Location"}
                name={[name, "value"]}
                style={{ display: "none" }}
              ></Form.Item>
              <Form.Item label={"Map Location"}>
                <Input
                  size="large"
                  disabled
                  readOnly
                  value={coordinates?.address || ""}
                  suffix={
                    <EnvironmentOutlined
                      style={{ cursor: "pointer" }}
                      onClick={openMap}
                    />
                  }
                />
              </Form.Item>
            </>
          ) : name.includes("asr-item") ? (
            <ASRFormItem
              formElement={formElement}
              disabled
              formItemName={name}
            />
          ) : name === "firstChoiceCampus" || name === "secondChoiceCampus" ? (
            <>
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select disabled={true}>
                  {choiceOfCampuses?.map((el, i) => {
                    return (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.code}</p>
                        </Row>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          ) : name === "scholarshipType" ? (
            <>
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select disabled={true}>
                  {_scholarshipTypes?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id == formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : name === "enrolledInENS" ? (
            <>
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select disabled={true}>
                  {yesNoTrueFalseList.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.value}`}
                      selected={el.value?.toLowerCase() == formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : name === "educationSystem" ? (
            <>
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select disabled={true}>
                  {_educationalSystems?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id == formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : name === "gradeLevel" ? (
            <>
              <Form.Item name={[name, "value"]} label={"Current Grade"}>
                <Select disabled={true}>
                  {educationSystemGrades.map((el, i) => {
                    return (
                      <Option
                        key={i}
                        value={`${el.id}`}
                        selected={el.id.toString() == formElement.value}
                      >
                        <Row justify="space-between">
                          <p>{el.gradeCode}</p>
                        </Row>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          ) : name == "assessmentExam" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {assessmentExams?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    selected={el.id === formElement.value}
                  >
                    <Row justify="space-between">
                      <p>{el.title}</p>
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name === "nonAcademicAchievement" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {nonAcademicAchievement?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    selected={el.id === formElement.value}
                  >
                    <Row justify="space-between">
                      <p>{el.title}</p>
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name === "contributionToResearch" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {contributionToResearch?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    selected={el.id === formElement.value}
                  >
                    <Row justify="space-between">
                      <p>{el.title}</p>
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name === "socialAndCommunityActivities" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {socialAndCommunityActivities?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    selected={el.id === formElement.value}
                  >
                    <Row justify="space-between">
                      <p>{el.title}</p>
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name === "certificatesAndAwards" ? (
            <Form.Item name={[name, "value"]} label={formElement?.title}>
              <Select disabled={true}>
                {certificatesAndAwards?.map((el, i) => (
                  <Option
                    key={i}
                    value={`${el.id}`}
                    selected={el.id === formElement.value}
                  >
                    <Row justify="space-between">
                      <p>{el.title}</p>
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : name === "educationalAllowance" ? (
            <>
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select disabled={true}>
                  {_educationalAllowances?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id == formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : name === "scoringSystem" ? (
            <>
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select disabled={true}>
                  {_scoringSystem?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.id}`}
                      selected={el.id == formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : name === "schoolInUAE" ? (
            <>
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select disabled={true}>
                  {yesNoTrueFalseList.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.value?.toLowerCase()}`}
                      selected={el.value?.toLowerCase() === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : name === "isUAEResident" ? (
            <>
              <Form.Item name={[name, "value"]} label={formElement?.title}>
                <Select disabled={true}>
                  {yesNoTrueFalseList?.map((el, i) => (
                    <Option
                      key={i}
                      value={`${el.value?.toLowerCase()}`}
                      selected={el.value?.toLowerCase() === formElement.value}
                    >
                      <Row justify="space-between">
                        <p>{el.title}</p>
                      </Row>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : (
            <Form.Item
              disabled={true}
              name={[name, "value"]}
              label={
                formElement?.title +
                (application?.applicationType === "UPD" &&
                formElement?.valueMeta?.oldValue
                  ? " (" + formElement?.valueMeta?.oldValue + ")"
                  : "")
              }
            >
              <Input disabled={true} style={{}} placeholder="" />
            </Form.Item>
          )}

          <Form.Item
            style={{ display: "none" }}
            name={[name, "id"]}
            label={formElement?.title}
            disabled={true}
          >
            <Input disabled={true} style={{}} placeholder="" />
          </Form.Item>

          <Form.Item
            style={{ display: "none" }}
            name={[name, "title"]}
            label={formElement?.title}
            disabled={true}
          >
            <Input disabled={true} style={{}} placeholder="" />
          </Form.Item>
        </>
      )}
      <Modal
        className="image-previewer-modal"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {imageView ? (
          <img src={imageView} width={"100%"} height={"100%"} alt="file" />
        ) : (
          <img src={defaultImg} width={"100%"} height={"100%"} alt="file" />
        )}
      </Modal>
    </div>
  );
}
