import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import { Button, Checkbox, Form, InputNumber, Row, Select } from "antd";
import { useSelector } from "react-redux";

const { Option } = Select;

const TFDEServiceConfiguration = ({
  visibility,
  onHide,
  data,
  title,
  onSubmit,
  formName,
  tfdServices,
  loading,
}) => {
  const gradesRestrictionOptions = useSelector(
    (state) => state.metaData.dropDowns["gradesRestrictions"]
  );

  return (
    <CustomModal visibility={visibility} handleCancel={onHide} title={title}>
      {data && (
        <Form layout="vertical" form={formName} onFinish={onSubmit}>
          <Form.Item
            name={"noOfKids"}
            label={"Maximum Number Of Kids"}
            rules={[
              { required: true, message: "Max. number of kids is required" },
            ]}
          >
            <InputNumber
              placeholder="Maximum number of kids"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name={"gradesRestrictions"}
            label={"Grades Restriction"}
            rules={[
              { required: true, message: "Grades restriction is required" },
            ]}
          >
            <Select>
              {gradesRestrictionOptions.map((restriction) => (
                <Option key={restriction.id} value={restriction.value}>
                  {restriction.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={"allowedWithDiscounts"}
            label={"Allowed With Other Discounts"}
            rules={[
              {
                required: true,
                message: "Atleast one discount service is required",
              },
            ]}
          >
            <Select mode="multiple">
              {tfdServices.map((service) => (
                <Option key={service.id} value={service.id}>
                  {service.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name={"excludeHighestGrades"} valuePropName={"checked"}>
            <Checkbox>Exclude Highest Grades</Checkbox>
          </Form.Item>
          <Row justify="end">
            <Button loading={loading} htmlType="submit">
              {loading ? "Saving...." : "Save"}
            </Button>
          </Row>
        </Form>
      )}
    </CustomModal>
  );
};
export default TFDEServiceConfiguration;
