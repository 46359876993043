/* eslint-disable eqeqeq */
import React from "react";
import { Card, Spin, Button, Row, Form, Input, Checkbox } from "antd";
import { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditorToolbar } from "utils/constants";
import { renderLoader } from "utils/helpers/helpers";

const TermsAndConditions = (props) => {
  const applicationType = props.location.state.serviceName;
  const [loading] = useState(false);
  const [termsEN, setTermsEN] = useState("");
  const [termsAR, setTermsAR] = useState("");

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <Card className="antCardBorder antCard" style={{ padding: "2rem" }}>
          <div style={{ marginBottom: "20px" }}>
            <h5>{applicationType} Configuration</h5>
          </div>
          <Form layout="vertical">
            <Form.Item label={"Identifier"} name={"identifier"}>
              <Input />
            </Form.Item>
            <Form.Item label={"Title"} name={"title"}>
              <Input />
            </Form.Item>
            <Form.Item label={"Title (AR)"} name={"titleAr"}>
              <Input />
            </Form.Item>
            <Form.Item label={"Description"} name={"description"}>
              <Input />
            </Form.Item>
            <Form.Item label={"Description (AR)"} name={"descriptionAr"}>
              <Input />
            </Form.Item>
            <Form.Item label={"Terms & Conditions"} name={"terms"}>
              <CKEditor
                editor={ClassicEditor}
                data={termsEN}
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTermsEN(data);
                }}
                config={
                  {
                    //toolbar: EditorToolbar,
                  }
                }
              />
            </Form.Item>
            <Form.Item label={"Terms & Conditions (AR)"} name={"termsAr"}>
              <CKEditor
                config={{
                  language: "ar",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                data={termsAR}
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTermsAR(data);
                }}
              />
            </Form.Item>
            <Form.Item label={"Guidelines"} name={"guidelines"}>
              <CKEditor
                config={
                  {
                    //toolbar: EditorToolbar,
                  }
                }
                editor={ClassicEditor}
                data={termsAR}
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTermsAR(data);
                }}
              />
            </Form.Item>
            <Form.Item label={"Guidelines (AR)"} name={"guidelinesAr"}>
              <CKEditor
                config={{
                  language: "ar",
                  //toolbar: EditorToolbar,
                }}
                editor={ClassicEditor}
                data={termsAR}
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTermsAR(data);
                }}
              />
            </Form.Item>
            <Row>
              <Form.Item name={""}>
                <Checkbox>SIS required</Checkbox>
              </Form.Item>
              <Form.Item name={""}>
                <Checkbox>DMS required</Checkbox>
              </Form.Item>
              <Form.Item name={""}>
                <Checkbox>ERP required</Checkbox>
              </Form.Item>
            </Row>
            <Row justify={"end"}>
              <Button
                type="primary"
                style={{ width: "140px", marginTop: "2rem" }}
                htmlType={"submit"}
              >
                Save
              </Button>
            </Row>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};
export default TermsAndConditions;
