import client from "apis/config";

export const getSyncJobsAPI = async (page) => {
  return await client.get(`/sync-job?page=${page}&limit=10`);
};
export const getSyncJobsHistoryAPI = async (page) => {
  return await client.get(`/sync-history`);
};
export const searchSyncJobAPI = async (search) => {
  return await client.get(`/sync-job/${search}`);
};
export const addSyncJobAPI = async (newSyncJob) => {
  return await client.post("/sync-job", newSyncJob);
};
export const editSyncJobAPI = async (updatedSyncJob) => {
  return await client.put(`/sync-job/${updatedSyncJob?.id}`, updatedSyncJob);
};

export const deleteSyncJobAPI = async (syncJobId) => {
  return await client.delete(`/sync-job/${syncJobId}`);
};


