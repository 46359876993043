/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import * as React from "react";

import {
  addSccDocumentConfigurations,
  deleteSccDocumentConfigurations,
  editSccDocumentConfigurations,
  getSccDocumentConfigurations,
} from "apis/stages-api/stages-api";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { renderLoader, requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { errorMessage } from "utils/helpers/helpers";
import { NotificationContainer } from "react-notifications";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Update",
};
const _teamType = {
  TEAM: "TEAM",
  SCHEDULE: "SCHEDULE",
  REMARKS: "REMARKS",
  VIEWONLY: "VIEWONLY",
};

const SccDocGeneratorConfiguration = (props) => {
  const dispatch = useDispatch();
  const currentStage = props.location.state;
  const isFSMStageConfig = ["EVB", "LTB", "FFB", "DPB", "PHB"].includes(
    currentStage?.applicationType
  );
  const isSccStageConfig = ["SCC"].includes(currentStage?.applicationType);

  const [registerForm] = Form.useForm();
  const [showModal, toggleModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  const [editId, setEditId] = React.useState("");
  const _stages = useSelector((state) => state.metaData.stages);
  const [totalRecords, setTotalRecords] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const [selectedCampuses, setSelectedCampuses] = React.useState([]);
  const [selectedSchools, setSelectedSchools] = React.useState([]);
  const [selectedGrades, setSelectedGrades] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  // *********for remarsk users if the currentStage slug is interview/observation/entranceTest

  const [campusesFilteredSchools, setCampusesFilteredSchools] = React.useState(
    []
  );
  const [existingUsers, setExistingUsers] = React.useState([]);

  const [userAdded, setUserAdded] = React.useState(false);
  const [userRemoved, setUserRemoved] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const [applicationFeeNeeded, setApplicationFeeNeeded] = React.useState(false);
  const [addRemoveUsersFlags, setAddRemoveUsersFlags] = React.useState({
    team: {
      isUserAdded: false,
      isUserRemoved: false,
    },
    schedule: {
      isUserAdded: false,
      isUserRemoved: false,
    },
    remarks: {
      isUserAdded: false,
      isUserRemoved: false,
    },
    viewOnly: {
      isUserAdded: false,
      isUserRemoved: false,
    },
  });

  const [docConfigurations, setDocConfigurationConfigurations] = React.useState(
    []
  );

  const _campuses = useSelector((state) => state.metaData.campuses);
  const _allSchools = useSelector((state) => state.metaData.schools);
  const _grades = useSelector((state) => state.metaData.grades);
  const _users = useSelector((state) => state.metaData.users);

  console.log(_users);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    fetchDocGeneratorConfigurations();
    if (currentStage?.id) {
      fetchDocGeneratorConfigurations(currentStage?.id);
    }
  }, [currentStage, currentPage]);

  const fetchDocGeneratorConfigurations = async () => {
    try {
      setLoading(true);
      const configurationResponse = await getSccDocumentConfigurations(currentPage);
      console.log(configurationResponse.data.data);
      if (configurationResponse.data && configurationResponse.status === 200) {
        setDocConfigurationConfigurations(configurationResponse.data.data);
        setTotalRecords(configurationResponse.data.total);
        setLoading(false);
      } else throw configurationResponse.error;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  let selectedCampusesesIds = [];

  //  filter all schools againt the selected campuses
  const filterSchoolsByCampus = () => {
    selectedCampuses.forEach((campus) => {
      selectedCampusesesIds.push(campus.id);
    });
    setCampusesFilteredSchools(
      _allSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
    setSelectedSchools(
      selectedSchools.filter((school) =>
        selectedCampusesesIds.includes(school.campusId)
      )
    );
  };

  React.useEffect(() => {
    filterSchoolsByCampus();
  }, [selectedCampuses]);

  const onSubmit = async (value) => {
    await onSubmitSccDocumentConfiguration(value);
  };

  const onSubmitSccDocumentConfiguration = async (value) => {
    try {
      setLoading(true);
      const obj = {
        campuses: selectedCampuses.map((param) => param.id),
        schools: selectedSchools.map((param) => param.id),
        grades: selectedGrades.map((param) => param.id),
        users: selectedUsers.map((param) => param.id),
      };

      if (modalTitle === ModalType.ADD) {
        const response = await addSccDocumentConfigurations(obj);
        if (response.status == 200) {
          NotificationManager.success("Record added successfully");
          await fetchDocGeneratorConfigurations();
        } else {
          errorMessage({ message: "Record not added" });
          NotificationManager.error("Record not added");
          throw response.error;
        }
      } else {
        const response = await editSccDocumentConfigurations(editId, obj);
        if (response.status == 200) {
          NotificationManager.success("Record updated successfully");
          await fetchDocGeneratorConfigurations();
        } else {
          NotificationManager.error("Record not updated");
          throw response.error;
        }
      }
      setLoading(false);
      toggleModal(false);
    } catch (e) {
      console.log({ e });
      setLoading(false);
      requestErrorHandel({ error: e });
      // toggleModal(false);
    }
  };

  const onEdit = (item) => {
    toggleModal(true);
    setModalTitle(ModalType.EDIT);
    setEditId(item.id);
    setIsEdit(true);
    setSelectedCampuses(item.campuses);
    setSelectedSchools(item.schools);
    setSelectedGrades(item.grades);

    setExistingUsers(item.users);
    setSelectedUsers(item.users);
    setUserAdded(false);
    setUserRemoved(false);
  };

  const onAdd = () => {
    //  open modal to create new config
    setModalTitle(ModalType.ADD);
    registerForm.resetFields();
    toggleModal(true);
    setSelectedCampuses([]);
    setSelectedGrades([]);
    setSelectedUsers([]);
    setSelectedSchools([]);
    setUserAdded(false);
    setUserRemoved(false);

    setIsEdit(false);
  };
  const onDelete = async (item) => {
    try {
      setLoading(true);
      const deleteResponse = await deleteSccDocumentConfigurations(item.id);
      if (deleteResponse.status == 200) {
        setLoading(false);
        NotificationManager.success("Record deleted");
        fetchDocGeneratorConfigurations();
      }
    } catch (e) {
      setLoading(false);
      console.log({ e });
      requestErrorHandel({ error: e });
    }
  };

  const handleSelectAll = (value, type) => {
    switch (type) {
      case "campus":
        if (value && value.length && value.includes("all")) {
          if (selectedCampuses.length === _campuses.length) {
            setSelectedCampuses([]);
          } else {
            setSelectedCampuses(_campuses);
          }
        } else {
          return value;
        }
        break;
      case "schools":
        if (value && value.length && value.includes("all")) {
          if (selectedSchools.length === filterSchoolsByCampus.length) {
            setSelectedSchools([]);
          } else {
            selectedCampuses.forEach((camp) => {
              selectedCampusesesIds.push(camp.id);
            });
            const _campuseSchools = _allSchools.filter((school) =>
              selectedCampusesesIds.includes(school.campusId)
            );
            setSelectedSchools(_campuseSchools);
          }
        } else {
          return value;
        }
        break;
      case "grades":
        if (value && value.length && value.includes("all")) {
          if (selectedGrades.length === _grades.length) {
            setSelectedGrades([]);
          } else {
            setSelectedGrades(_grades);
          }
        } else {
          return value;
        }
        break;
      case "users":
        console.log(_users);
        if (value && value.length && value.includes("all")) {
          const accessUser = _users?.filter(
            (user) => user?.hasDocumentGeneratorAccess
          );
          setSelectedUsers(accessUser);
          compareExistingusers(accessUser);
        } else {
          return value;
        }
        break;
      default:
        return value;
    }
  };

  // **************for currentStage slug if interview/observation/entranceTest

  const changeSelectedUsers = async (e, el) => {
    let _selectedUsersCopy = [...selectedUsers];

    if (e.target.checked) {
      _selectedUsersCopy = [..._selectedUsersCopy, el];
    } else {
      _selectedUsersCopy = [..._selectedUsersCopy].filter(
        (i) => i.id !== el.id
      );
    }
    setSelectedUsers(_selectedUsersCopy);
    compareExistingusers(_selectedUsersCopy, existingUsers, _teamType.TEAM);
  };
  const compareExistingusers = (
    selectedUsersCopy,
    _existingUsers,
    teamType
  ) => {
    let newSelectedUsersIds = selectedUsersCopy?.map((param) => param.id);
    const existingUsersIds = _existingUsers?.map((param) => param.id);

    const newUsers = newSelectedUsersIds?.filter(
      (id) => existingUsersIds?.indexOf(id) < 0
    );

    const removedUsers = existingUsersIds?.filter(
      (id) => newSelectedUsersIds?.indexOf(id) < 0
    );

    if (teamType === _teamType.VIEWONLY) {
      setAddRemoveUsersFlags({
        ...addRemoveUsersFlags,
        viewOnly: {
          isUserAdded: newUsers.length > 0,
          isUserRemoved: removedUsers.length > 0,
        },
      });
    } else if (teamType === _teamType.SCHEDULE) {
      setAddRemoveUsersFlags({
        ...addRemoveUsersFlags,
        schedule: {
          isUserAdded: newUsers.length > 0,
          isUserRemoved: removedUsers.length > 0,
        },
      });
    } else if (teamType === _teamType.REMARKS) {
      setAddRemoveUsersFlags({
        ...addRemoveUsersFlags,
        remarks: {
          isUserAdded: newUsers.length > 0,
          isUserRemoved: removedUsers.length > 0,
        },
      });
    } else if (teamType === _teamType.TEAM) {
      setAddRemoveUsersFlags({
        ...addRemoveUsersFlags,
        team: {
          isUserAdded: newUsers.length > 0,
          isUserRemoved: removedUsers.length > 0,
        },
      });
    }
  };

  const fetchRecords = async (page) => {
    setCurrentPage(page);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        {/* Table for old education types */}
        <NotificationContainer />
        <CommonTable
          key={"#"}
          loading={loading}
          data={docConfigurations}
          header={() => (
            <TableHeader
              headerTitle={`Document Generator Configuration`}
              headerSubTitle={`Document Generator`}
              headerBtnTitle="Add New"
              onAddNew={onAdd}
            />
          )}
          columns={[
            {
              key: "id",
              title: "Campuses",
              dataIndex: "campuses",
              render: (item) => {
                return item.length === _campuses.length
                  ? "All"
                  : item.map((el) => el.code).join(", ");
              },
            },
            {
              key: "id",
              title: "Schools",
              dataIndex: "schools",
              render: (item) => {
                return item.length === _allSchools.length
                  ? "All"
                  : item.map((el) => el.abbreviation).join(", ");
              },

              //(item) => item.map((el) => el.abbreviation).join(", "),
            },
            {
              key: "id",
              title: "Grades",
              dataIndex: "grades",
              render: (item) => {
                return item.length === _grades.length
                  ? "All"
                  : item.map((el) => el.code).join(", ");
              },

              //(item) => item.map((el) => el.code).join(", "),
            },

            {
              key: "id",
              title: "Teams",
              dataIndex: "users",
              render: (item) => item.map((el) => el.name).join(", "),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onEdit(item)}
                      />
                    </Tooltip>
                  </div>
                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
          totalRecord={totalRecords}
          pageSize={pageSize}
          fetchRecords={fetchRecords}
        ></CommonTable>

        <CustomModal
          visibility={showModal}
          handleCancel={() => toggleModal(false)}
          title={`${modalTitle} Document Configuration`}
        >
          <Form
            form={registerForm}
            layout="vertical"
            size="large"
            onFinish={onSubmit}
          >
            <Form.Item name="id" id="stage" style={{ display: "none" }}>
              <input defaultValue={currentStage?.id} />
            </Form.Item>
            {/* if application type is ASR show a list of ASR Eservices in a dropdown */}

            <Form.Item label={"Campuses"}>
              <Select
                placeholder="Select campus"
                mode={
                  currentStage?.applicationType === "TFD"
                    ? undefined
                    : "multiple"
                }
                value={
                  selectedCampuses.length > 0
                    ? selectedCampuses.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={(value) => handleSelectAll(value, "campus")}
              >
                {currentStage?.applicationType !== "TFD" && (
                  <Option key="all" value="all">
                    {selectedCampuses.length === _campuses.length
                      ? "UnSelect All"
                      : "Select All"}
                  </Option>
                )}
                {_campuses.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedCampuses.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedCampuses(
                          e.target.checked
                            ? currentStage?.applicationType === "TFD"
                              ? [el]
                              : [...selectedCampuses, el]
                            : selectedCampuses.filter((i) => i.id !== el.id)
                        );
                      }}
                    >
                      {el.code}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Schools"}>
              <Select
                placeholder="Select school"
                mode="multiple"
                value={
                  selectedSchools.length > 0
                    ? selectedSchools
                        .map((item) => item.abbreviation)
                        ?.join(", ")
                    : []
                }
                onChange={(value) => handleSelectAll(value, "schools")}
              >
                {campusesFilteredSchools.length > 1 && (
                  <Option key="all" value="all">
                    {selectedSchools.length === campusesFilteredSchools.length
                      ? "UnSelect All"
                      : "Select All"}
                  </Option>
                )}

                {currentStage?.applicationType !== "TFD" &&
                  campusesFilteredSchools.map((el) => (
                    <Option value={el.id}>
                      <Checkbox
                        checked={
                          selectedSchools.findIndex((i) => i.id === el.id) > -1
                        }
                        onChange={(e) => {
                          setSelectedSchools(
                            e.target.checked
                              ? [...selectedSchools, el]
                              : selectedSchools.filter((i) => i.id !== el.id)
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.abbreviation}
                      </Checkbox>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Grades"}>
              <Select
                placeholder="Select grade"
                mode="multiple"
                value={
                  selectedGrades.length > 0
                    ? selectedGrades.map((item) => item.code)?.join(", ")
                    : []
                }
                onChange={(value) => handleSelectAll(value, "grades")}
              >
                <Option key="all" value="all">
                  {selectedGrades.length === _grades.length
                    ? "UnSelect All"
                    : "Select All"}
                </Option>

                {currentStage?.applicationType !== "TFD" &&
                  _grades.map((el, i) => (
                    <Option value={el.id} key={i}>
                      <Checkbox
                        checked={
                          selectedGrades.findIndex((i) => i.id === el.id) > -1
                        }
                        onChange={(e) => {
                          setSelectedGrades(
                            e.target.checked
                              ? [...selectedGrades, el]
                              : selectedGrades.filter((i) => i.id !== el.id)
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.code}
                      </Checkbox>
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label={"Teams"}>
              <Select
                mode="multiple"
                value={
                  selectedUsers.length > 0
                    ? selectedUsers
                        .filter((el) => !el.canViewOnly)
                        .map((el) => el.name)
                        ?.join(", ")
                    : []
                }
                showSearch
                filterOption
                onChange={(value) => handleSelectAll(value, "users")}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {_users
                  ?.filter((user) => user?.hasDocumentGeneratorAccess)
                  ?.map((el, i) => (
                    <Option value={el.name} key={i}>
                      <Checkbox
                        checked={
                          selectedUsers.findIndex(
                            (i) => i.id === el.id && !i.canViewOnly
                          ) > -1
                        }
                        onChange={(e) => {
                          changeSelectedUsers(e, el);
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.name}
                      </Checkbox>
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default SccDocGeneratorConfiguration;
