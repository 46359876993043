/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import {
  Button,
  Checkbox,
  Form,
  Row,
  Select,
  Tooltip,
  Popconfirm,
  Spin,
  // Steps,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import * as React from "react";
import CommonTable from "components/Tables/CommonTable";

import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { deleteStageDataAPI } from "apis/stages-api/stages-api";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
import { fetchAllFsmFacilities } from "views/FSM/facility-mangement/all-facilities/api";
import {
  addCashierConfigurationForFsm,
  deleteCashierConfigurationForFsm,
  getCashierConfigurationForFsm,
  updateCashierConfigurationForFsm,
} from "./api";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Update",
};
const _teamType = {
  TEAM: "TEAM",
  SCHEDULE: "SCHEDULE",
  REMARKS: "REMARKS",
  VIEWONLY: "VIEWONLY",
  FSMBOOKINGSCREATION: "FSMBOOKINGSCREATION",
};

const FsmCashierConfiguration = (props) => {
  const bookingType = props.location.state.serviceName;
  const [registerForm] = Form.useForm();
  const _users = useSelector((state) => state.metaData.users);

  const [showModal, toggleModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [total, setTotal] = React.useState();
  const [page, setPage] = React.useState(1);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [selectedFacilities, setSelectedFacilities] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [facilities, setFacilities] = React.useState([]);
  const [recordId, setRecordId] = React.useState("");

  React.useEffect(() => {
    getAllCashierConfigurationsForBookings();
  }, [page]);
  React.useEffect(() => {
    getAllFsmFacilities();
  }, []);

  const getAllCashierConfigurationsForBookings = async () => {
    try {
      setLoading(true);
      const response = await getCashierConfigurationForFsm({
        page: page,
        bookingType: bookingType,
      });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const getAllFsmFacilities = async () => {
    try {
      const response = await fetchAllFsmFacilities({ limit: 1000 });
      if (response.status === 200) {
        setFacilities(response.data.data);
      }
    } catch (error) {
      console.log("errpr", error);
    }
  };

  const onEdit = (item) => {
    toggleModal(true);
    setModalTitle(ModalType.EDIT);
    setSelectedFacilities(item?.facilities);
    setSelectedUsers(item.users.filter((el) => !el.canViewOnly));
    setRecordId(item.id);
  };

  const onAdd = () => {
    //  open modal to create new config
    setModalTitle(ModalType.ADD);
    setRecordId("");
    registerForm.resetFields();
    toggleModal(true);
    setSelectedFacilities([]);
    setSelectedUsers([]);
  };
  const onDelete = async (item) => {
    try {
      setLoading(true);
      const deleteResponse = await deleteCashierConfigurationForFsm({
        id: item.id,
      });
      if (deleteResponse.status == 200) {
        setLoading(false);
        NotificationManager.success("Record deleted");
        getAllCashierConfigurationsForBookings();
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };

  const onSubmit = async () => {
    await onSubmitCashierConfiguration();
  };

  const onSubmitCashierConfiguration = async (values) => {
    try {
      const payload = {
        bookingTypeIdentifier: bookingType,
        users: selectedUsers.map((item) => item.id),
        facilities: selectedFacilities.map((item) => item.id),
      };
      setLoading(true);
      if (modalTitle === ModalType.ADD) {
        const response = await addCashierConfigurationForFsm({
          paylaod: payload,
        });
        if (response.status === 200) {
          NotificationManager.success("Record added successfully");
          getAllCashierConfigurationsForBookings();
          setLoading(false);
        }
      } else {
        const response = await updateCashierConfigurationForFsm({
          paylaod: payload,
          id: recordId,
        });
        if (response.status === 200) {
          NotificationManager.success("Record updated successfully");
          getAllCashierConfigurationsForBookings();
          setLoading(false);
        }
      }
      setLoading(false);
      toggleModal(false);
      console.log("values", payload);
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleSelectAllFacilities = (value) => {
    if (value && value.length && value.includes("all")) {
      setSelectedFacilities(facilities);
    } else {
      return value;
    }
  };

  const handleSelectAllUser = (value) => {
    if (value && value.length && value.includes("all")) {
      const filteredUsers = _users.filter(
        (_usr) => _usr.hasCashierAccess && !_usr.canViewOnly
      );
      setSelectedUsers(filteredUsers);
    } else {
      return value;
    }
  };

  // **************for currentStage slug if interview/observation/entranceTest

  const changeSelectedUsers = async (e, el) => {
    let _selectedUsersCopy = [...selectedUsers];

    if (e.target.checked) {
      _selectedUsersCopy = [..._selectedUsersCopy, el];
    } else {
      _selectedUsersCopy = [..._selectedUsersCopy].filter(
        (i) => i.id !== el.id
      );
    }
    setSelectedUsers(_selectedUsersCopy);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        {/* Table for old education types */}

        <CommonTable
          key={"#"}
          loading={loading}
          data={data}
          header={() => (
            <TableHeader
              headerTitle={`${bookingType} Cashier Configuration`}
              headerSubTitle={`Manage Cashier Configuration`}
              headerBtnTitle="Add New"
              onAddNew={onAdd}
            />
          )}
          columns={[
            {
              key: "id",
              title: "Facilities",
              dataIndex: "facilities",
              render: (item) => {
                return item.length === facilities?.length
                  ? "All"
                  : item.map((el) => el.title).join(", ");
              },
            },
            {
              key: "id",
              title: "Teams",
              dataIndex: "users",
              render: (item) => item.map((el) => el.name).join(", "),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onEdit(item)}
                      />
                    </Tooltip>
                  </div>
                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
        ></CommonTable>

        <CustomModal
          visibility={showModal}
          handleCancel={() => toggleModal(false)}
          title={`${modalTitle} Cashier Configuration`}
        >
          <Form
            form={registerForm}
            layout="vertical"
            size="large"
            onFinish={onSubmit}
          >
            <Form.Item label={"Facilities"}>
              <Select
                placeholder="Select Facility"
                mode={"multiple"}
                value={
                  selectedFacilities.length > 0
                    ? selectedFacilities.map((item) => item.title)?.join(", ")
                    : []
                }
                onChange={handleSelectAllFacilities}
              >
                <Option key="all" value="all">
                  Select All
                </Option>

                {facilities.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={
                        selectedFacilities.findIndex((i) => i.id === el.id) > -1
                      }
                      onChange={(e) => {
                        setSelectedFacilities(
                          e.target.checked
                            ? [...selectedFacilities, el]
                            : selectedFacilities.filter((i) => i.id !== el.id)
                        );
                      }}
                    >
                      {el.title}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Teams"}>
              <Select
                mode="multiple"
                value={
                  selectedUsers.length > 0
                    ? selectedUsers
                        .filter((el) => !el.canViewOnly)
                        .map((el) => el.name)
                        ?.join(", ")
                    : []
                }
                showSearch
                filterOption
                onChange={handleSelectAllUser}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {_users
                  .filter((_usr) => _usr.hasCashierAccess)
                  .map((el, i) => (
                    <Option value={el.name} key={i}>
                      <Checkbox
                        checked={
                          selectedUsers.findIndex(
                            (i) => i.id === el.id && !i.canViewOnly
                          ) > -1
                        }
                        onChange={(e) => {
                          changeSelectedUsers(e, el);
                        }}
                        style={{ width: "100%" }}
                      >
                        {el.name}
                      </Checkbox>
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default FsmCashierConfiguration;
