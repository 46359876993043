import React from "react";

const SccTabs = ({ selectedTab, onDetialsTab, onLogsTab }) => {
  return (
    <div className="fsm__tabs__container">
      <div
        onClick={onDetialsTab}
        style={{
          borderBottomColor: selectedTab === 0 ? "#007B85" : "transparent",
        }}
        className="fsm_t_details"
      >
        <p style={{ color: selectedTab === 0 ? "#007B85" : "black" }}>
          Details
        </p>
      </div>
      <div
        onClick={onLogsTab}
        style={{
          borderBottomColor: selectedTab === 1 ? "#007B85" : "transparent",
        }}
        className="fsm_t_logs"
      >
        <p style={{ color: selectedTab === 1 ? "#007B85" : "black" }}>Logs</p>
      </div>
    </div>
  );
};

export default SccTabs;
