import client from "apis/config";

export const getVacanciesAPI = async (page = 1) => {
  return await client.get(`vacancies?page=${page}&limit=10`);
};
export const addVacanciesAPI = async (data) => {
  return await client.post("vacancies", data);
};
export const updateVacanciesAPI = async (data, id) => {
  return await client.put(`vacancies/${id}`, data);
};
export const deleteVacanciesAPI = async (id) => {
  return await client.delete(`vacancies/${id}`);
};
