import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { _getAllFAQsCategories } from "actions/faqs-category/faqsCatepgryAction";
import { Button, Popconfirm, Spin, Tooltip, Form, message } from "antd";
import { addNewFaq } from "apis/faq-requests/faqsRequests";
import { deleteFaqCategory } from "apis/faq-requests/faqsRequests";
import { updateFaqsCategory } from "apis/faq-requests/faqsRequests";
import { addNewFaqsCategory } from "apis/faq-requests/faqsRequests";
import { getFAQSCategories } from "apis/faq-requests/faqsRequests";
import { getFAQS } from "apis/faq-requests/faqsRequests";
import CustomModal from "components/CustomModal/CustomModal";
import BusForm from "components/Forms/buses-crud/BusForm";
import FaqsCategoryForm from "components/Forms/faqs-category-form/FaqsCategoryForm";
import FaqsForm from "components/Forms/faqs-form/FaqsForm";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const FaqsCategory = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [faqsCatData, setFaqsCatData] = useState([]);
  const [total, setTotal] = useState();
  const [faqCatId, setFaqCatId] = useState("");
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [AddFaqsCategoryForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <TableHeader
        onAddNew={() => {
          AddFaqsCategoryForm.resetFields();
          toggleModal(true);
          setModalTitle(ModalType.ADD);
        }}
        headerTitle={"FAQs Categories"}
        headerSubTitle={"Manage Faqs Categories"}
        headerBtnTitle={"Add New"}
      />
    );
  };
  useEffect(() => {
    getAllFaqsCategories();
  }, [page]);
  const getAllFaqsCategories = async () => {
    try {
      setLoading(true);
      const response = await getFAQSCategories({ limit: "10", page: page });
      if (response.status === 200) {
        setFaqsCatData(response.data.data);
        dispatch(_getAllFAQsCategories(response.data.data));
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const addFAQCategory = async (data) => {
    try {
      setLoading(true);
      const response = await addNewFaqsCategory({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Faqs Category added successfully" });
        await getAllFaqsCategories();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const updateFAQCateogry = async (data) => {
    try {
      setLoading(true);
      const response = await updateFaqsCategory({ id: faqCatId, data });
      if (response.status === 200) {
        successMessage({ message: "Category updated successfully" });
        await getAllFaqsCategories();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const deleleFAQCategory = async (id) => {
    try {
      setLoading(true);
      const response = await deleteFaqCategory({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Category deleted successfully" });
        await getAllFaqsCategories();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    if (modalTitle === ModalType.EDIT) {
      await updateFAQCateogry(values);
    } else {
      await addFAQCategory(values);
    }
  };
  const handleEdit = (record) => {
    setValuesForEdit(record);
    AddFaqsCategoryForm.setFieldsValue({
      ...record,
    });
    setFaqCatId(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={faqsCatData}
          fetchRecords={(page) => setPage(page)}
          totalRecord={2}
          columns={[
            {
              title: "#",
              key: "id",
              dataIndex: "id",
              //   render: (record) => record.id,
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Title AR",
              dataIndex: "titleAr",
              key: "titleAr",
              // render: (record) => record.code,
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Faqs Category Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this faq Category?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => deleleFAQCategory(item.id)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Faqs Category`}
        >
          <FaqsCategoryForm
            onSubmit={onSubmit}
            formName={AddFaqsCategoryForm}
            loading={loading}
            valuesForEdit={null}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default FaqsCategory;
