import { Button, Form, Input, Row, Col, Checkbox } from "antd";
import React from "react";

import TextArea from "antd/lib/input/TextArea";

const VatForm = ({ onSubmit, loading, formName }) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"VAT Title (EN)"}
              name={"title"}
              rules={setRules("VAT Title")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"VAT Title (AR)"}
              name={"titleAr"}
              rules={setRules("VAT Title")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col lg={12} md={12} sm={24}>
            <Form.Item
              label={"VAT Number"}
              name={"vatNumber"}
              rules={setRules("VAT Number")}
            >
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label={"Rate"} name={"rate"} rules={setRules("Rate")}>
              <Input placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col md={12}>
            <Form.Item label={"Description (EN)"} name={"description"}>
              <TextArea placeholder="Add here" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label={"Description (AR)"} name={"descriptionAr"}>
              <TextArea placeholder="Add here" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item name={"isActive"} valuePropName={"checked"}>
              <Checkbox>Is Active</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <br />

        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant__primary__button"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default VatForm;
