import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Spin,
  Tooltip,
  Form,
  Select,
  Image,
  Row,
  Col,
  DatePicker,
} from "antd";
// import "./style.css";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { useSelector } from "react-redux";
import ExpandedTableRow from "views/FSM/bookings/ExpandedTableRow";
import { getYearlyBookings } from "../apis";
import moment from "moment";
import { onViewBookingDetails } from "utils/helpers/helpers";

const { Option } = Select;

const YearlyView = ({ facilities, props }) => {
  const _campuses = useSelector((state) => state.metaData.campuses);

  const _bookingTypes = useSelector((state) => state.metaData.FSM_bookingTypes);
  const _bookingStatus = useSelector(
    (state) => state.metaData.applicationStatuses
  );
  const [currentYear, setCurrentYear] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [filters, setFilters] = useState({
    campus: "",
    facility: "",
    bookingType: "",
    bookingStatus: "",
    startAt: "",
    endAt: "",
  });

  const renderHeader = () => {
    return (
      <>
        <Row gutter={10}>
          <Col md={8} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter By Campus"
                onChange={(e) => setFilters({ ...filters, campus: e })}
                bordered={false}
                defaultValue={filters.campus || undefined}
              >
                <Option value={""}>All</Option>

                {_campuses?.map((el, i) => (
                  <Option key={i} value={`${el.id}`}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} className="filterColumn">
            <Form.Item name="bookingType" className="dropdown">
              <Select
                placeholder="Filter by booking type"
                onChange={(e) => setFilters({ ...filters, bookingType: e })}
                bordered={false}
                defaultValue={filters.bookingType || undefined}
              >
                <Option value={""}>All</Option>

                {_bookingTypes?.map((el, i) => (
                  <Option key={i} value={`${el.identifier}`}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <Select
                placeholder="Filter by Facility"
                onChange={(e) => setFilters({ ...filters, facility: e })}
                bordered={false}
                defaultValue={filters.facility || undefined}
              >
                <Option value={""}>All</Option>

                {facilities?.map((el, i) => (
                  <Option key={i} value={`${el.id}`}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          {/* <Col md={8} className="filterColumn">
            <Form.Item name="status" className="dropdown">
              <Select
                placeholder="Filter by booking status"
                onChange={(e) => setFilters({ ...filters, bookingStatus: e })}
                bordered={false}
                defaultValue={filters.bookingStatus || undefined}
              >
                <Option value={""}>All</Option>
                {_bookingStatus?.map((el, i) => (
                  <Option key={i} value={el.code}>
                    {el.externalStatus}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col md={8} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Filter by start date"
                onChange={(date) =>
                  setFilters({
                    ...filters,
                    startAt: moment(date).format("YYYY-MM-DD"),
                  })
                }
                bordered={false}
                value={filters.startAt}
              />
            </Form.Item>
          </Col>
          <Col md={8} className="filterColumn">
            <Form.Item name="campus" className="dropdown">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Filter by end date"
                onChange={(date) =>
                  setFilters({
                    ...filters,
                    endAt: moment(date).format("YYYY-MM-DD"),
                  })
                }
                bordered={false}
                value={filters.endAt}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };
  useEffect(() => {
    fetchYearlyBookings();
  }, [page, filters]);

  const fetchYearlyBookings = async () => {
    try {
      const _currentYear = moment(currentYear).format("YYYY");
      setLoading(true);
      const response = await getYearlyBookings({
        year: _currentYear,
        page: page,
        status: filters.bookingStatus,
        type: filters.bookingType,
        campus: filters.campus,
        facility: filters.facility,
        startAt: moment(filters.startAt).isValid() ? filters.startAt : "",
        endAt: moment(filters.endAt).isValid() ? filters.endAt : "",
      });
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const expandedRowRender = (record) => {
    return (
      <div className="card__snippet">
        {/* Additional details specific to the selected row */}
        {/* <p>Additional details for Booking ID: {record.bookingId}</p> */}
        <ExpandedTableRow
          record={record}
          serviceType={record?.bookingType?.identifier}
        />
      </div>
    );
  };
  // Define the expandable configuration
  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record]);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record));
    }
  };

  const onViewBookingScheduleDetail = (record) => {
    const serviceType = record?.bookingType?.identifier;
    switch (serviceType) {
      case "LTB":
        props.history.push({
          pathname: `long-term-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;
      case "PHB":
        props.history.push({
          pathname: `per-head-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;
      case "DPB":
        props.history.push({
          pathname: `day-pass-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;
      case "FFB":
        props.history.push({
          pathname: `full-facility-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;
      case "EVB":
        props.history.push({
          pathname: `event-booking/details`,
          state: { bookingId: record.id, serviceType },
        });
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          expandable={{
            expandedRowRender: expandedRowRender,
            expandedRowKeys: expandedRowKeys,
            onExpand: onExpand,
          }}
          columns={[
            {
              title: "Booking ID",
              dataIndex: "title",
              key: "title",
              render: (record) => (
                <span
                  onClick={() =>
                    onExpand(!expandedRowKeys.includes(record), record)
                  }
                >
                  <div className="row_expand_container">
                    <p style={{ color: "#007B85", padding: 0, margin: 0 }}>
                      {record}
                    </p>
                    {!expandedRowKeys.includes(record) ? (
                      <DownOutlined
                        style={{ color: "#007B85", marginLeft: 10 }}
                      />
                    ) : (
                      <UpOutlined
                        style={{ color: "#007B85", marginLeft: 10 }}
                      />
                    )}
                  </div>
                </span>
              ),
            },
            {
              title: "User Name",
              key: "userName",
              render: (record) => record?.user?.userName,
            },
            {
              title: "Campus",
              key: "campus",
              render: (record) => record?.facility?.campus?.code,
            },
            {
              title: "Facility",
              key: "facility",
              render: (record) => record?.facility?.title,
            },
            {
              title: "Current Stage",
              key: "currentStage",
              render: (record) => record?.currentStage?.title,
            },
            {
              title: "Current Stage Status",
              key: "currentStageStatus",
              render: (record) => record?.currentStage?.status?.internalStatus,
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              //   width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="View Details">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EyeOutlined />}
                          onClick={() => onViewBookingScheduleDetail(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => null}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
      </Spin>
    </div>
  );
};

export default YearlyView;
