import React, { useEffect, useState } from "react";
import FSMBookingHeader from "views/FSM/facility-mangement/fsm-application-detail-header/FSMBookingHeader";
import FSMDynamicInputFields from "../dynamicInputFieldsFormItems/FSMDynamicInputFields";
import { Button, Card, Form, Row, Spin } from "antd";
import ApplicationInformationForm from "components/Forms/ApplicationInformationForm";
import FSMDynamicInputReadOnlyFields from "../dynamicInputFieldsFormItems/FSMDynamicInputReadOnlyFields";
import { feedBackBooingRequest } from "views/FSM/bookings/api";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { processBooingRequest } from "views/FSM/bookings/api";
import { bookingStagesActionRequest } from "views/FSM/bookings/api";
import { renderLoader } from "utils/helpers/helpers";
import FSMTabs from "views/FSM/facility-mangement/fsm-application-detail-header/FSMTabs";
import FSMAplicationLogs from "views/FSM/facility-mangement/fsm-application-detail-header/FSMAplicationLogs";
import moment from "moment";
import CustomModal from "components/CustomModal/CustomModal";
import BookingRescheduleModal from "views/FSM/facility-mangement/fsm-application-detail-header/BookingRescheduleModal";
import LTBBookingRescheduleModal from "views/FSM/facility-mangement/fsm-application-detail-header/LTBBookingRescheduleModal";
import FSMBookingDetailsInformation from "views/FSM/facility-mangement/fsm-application-detail-header/FSMBookingDetailsInformation";

const BookingReviewStage = (props) => {
  const [bookingForm] = Form.useForm();
  const bookingFormRef = React.useRef();
  const [selectedTab, setSelectedTab] = useState(0);
  const bookingType = props.bookingDetails?.bookingType?.identifier;
  const hasPermission = props.bookingDetails?.hasPermission;

  const currentStage = props.bookingDetails?.currentStage?.status?.code;
  const [fields, setFields] = useState(props.bookingDetails?.fields || []);
  const [schedules, setSchedules] = useState(
    props.bookingDetails?.schedules || []
  );
  const [allFieldsApproved, setAllFieldsApproved] = useState(false);
  const allFieldDisbaled = ["FSG1220", "FSG120"].includes(currentStage);
  const isInstantBookingType = ["DPB", "FFB", "PHB"].includes(bookingType);

  const [reschudleModalOpen, setRescheduleModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSaveApplication = () => {
    const form = bookingFormRef.current;
    if (form) {
      form.submit(); // Trigger form submission
    }
  };

  const handleApproveAll = () => {
    if (
      allFieldDisbaled ||
      props.bookingDetails?.bookingStatus?.code === "AS007"
    ) {
      return;
    }
    const updatedFields = fields.map((field) => ({
      ...field,
      note: "",
      status: allFieldsApproved ? "submitted" : "approved", // Toggle status
    }));
    const updatedScheduleFields = schedules.map((field) => ({
      ...field,
      note: "",
      status: allFieldsApproved ? "submitted" : "approved", // Toggle status
    }));

    setFields(updatedFields);
    setSchedules(updatedScheduleFields);
    setAllFieldsApproved(!allFieldsApproved); // Toggle checkbox state
  };

  const handleImageClick = (index, type) => {
    const updatedFields = [...fields];
    const updatedScheduleFields = [...schedules];
    if (type === "fields") {
      const clickedField = updatedFields[index];
      clickedField.status =
        clickedField.status === "submitted"
          ? "approved"
          : clickedField.status === "needAmendment"
          ? "approved"
          : "submitted";
      clickedField.note = "";
      setFields(updatedFields);
    } else {
      const clickedScheduleField = updatedScheduleFields[index];
      clickedScheduleField.status =
        clickedScheduleField.status === "submitted"
          ? "approved"
          : clickedScheduleField.status == "needAmendment"
          ? "approved"
          : "submitted";
      clickedScheduleField.note = "";
      setSchedules(updatedScheduleFields);
    }
  };

  const handleNotesChange = (index, value, type) => {
    const updatedFields = [...fields];
    const updatedScheduleFields = [...schedules];
    if (type === "fields") {
      updatedFields[index] = {
        ...updatedFields[index],
        note: value,
        status: "needAmendment",
      };
      setFields(updatedFields);
    } else {
      updatedScheduleFields[index] = {
        ...updatedScheduleFields[index],
        note: value,
        status: "needAmendment",
      };
      setSchedules(updatedScheduleFields);
    }
  };

  const handleFieldChange = (index, value, fieldType) => {
    if (fields && fields.length > index) {
      const updatedFields = [...fields]; // Create a copy of the fields array
      const targetField = updatedFields[index];

      if (targetField) {
        // Update the specific field based on its type
        if (fieldType === "input") {
          targetField.value = value;
        } else if (fieldType === "dropdown") {
          targetField.value = value; // Update the value property for dropdowns
          // If additional properties need to be updated for dropdowns, modify them here
        } else {
          console.error("Invalid fieldType");
          return;
        }

        // Update the fields array with the modified field
        setFields(updatedFields); // Assuming setFields is a function to update the state
      } else {
        console.error("Field not found at the specified index");
      }
    } else {
      console.error("Fields array is empty or invalid");
    }
  };

  const onSubmit = async () => {
    const _fields = fields.map((field) => ({
      id: field.id,
      value: field.value,
      status: field.status,
      note: field.note || "", // Assuming field.note might be undefined, handle accordingly
    }));
    const _schedules = schedules.map((field) => ({
      id: field.id,
      status: field.status,
      note: field.note || "", // Assuming field.note might be undefined, handle accordingly
    }));

    const paylaod = {
      bookingId: props?.bookingDetails?.id,
      fields: _fields,
      schedules: _schedules,
    };
    setLoading(true);
    try {
      const response = await feedBackBooingRequest(paylaod);
      if (response.status === 200) {
        successMessage({ message: "Booking record updated successfuly" });
        props.refetch();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handelProcessBooking = async (action) => {
    try {
      const payload = {
        bookingId: props?.bookingDetails?.id,
        action: action,
      };
      const response = await processBooingRequest(payload);
      if (response.status === 200) {
        if (action === "sendToParent") {
          successMessage({
            message: "Booking has been send for amendment successfully",
          });
        } else {
          successMessage({
            message: "Amendment has been cancelled successfully",
          });
        }
        props.refetch();
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };
  const handleBookingStagesAction = async (action, notes) => {
    setLoading(true);
    try {
      const payload = {
        bookingId: props?.bookingDetails?.id,
        isApproved: action,
        notes: notes,
      };
      const response = await bookingStagesActionRequest(payload);

      if (response.status === 200) {
        successMessage({ message: "Booking has been Accepted" });
      }
      props.refetch();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const renderCaseView = (tab) => {
    const { bookingDetails } = props;
    const { bookingType } = bookingDetails || {};
    const bookingTypeId = bookingType?.identifier;
    const isInstantBookingType = ["DPB", "FFB", "PHB"].includes(bookingTypeId);
    switch (tab) {
      case 0:
        if (isInstantBookingType) {
          return (
            <FSMBookingDetailsInformation
              booking={bookingDetails}
              refetch={props.refetch}
            />
          );
        }
        if (hasPermission) {
          return (
            <FSMDynamicInputFields
              formRef={bookingFormRef}
              formName={bookingForm}
              onSubmit={onSubmit}
              fields={fields}
              schedules={schedules}
              handleImageClick={handleImageClick}
              onNotesChange={handleNotesChange}
              onFieldChange={handleFieldChange}
              allFieldsApproved={allFieldsApproved}
              handleApproveAll={handleApproveAll}
              allFieldDisbaled={
                allFieldDisbaled ||
                props.bookingDetails?.bookingStatus?.code === "AS007"
              }
              bookingType={props.bookingDetails?.bookingType}
              booking={props.bookingDetails}
            />
          );
        } else {
          return (
            <FSMDynamicInputReadOnlyFields
              formRef={bookingFormRef}
              formName={bookingForm}
              onSubmit={onSubmit}
              fields={fields}
              schedules={schedules}
              handleImageClick={handleImageClick}
              onNotesChange={handleNotesChange}
              onFieldChange={handleFieldChange}
              allFieldsApproved={allFieldsApproved}
              handleApproveAll={handleApproveAll}
              allFieldDisbaled={
                allFieldDisbaled ||
                props.bookingDetails?.bookingStatus?.code === "AS007"
              }
              bookingType={props.bookingDetails?.bookingType}
              booking={props.bookingDetails}
            />
          );
        }
        break;
      case 1:
        return (
          <FSMAplicationLogs
            bookingId={props.bookingDetails?.id}
            autoApproved={props.bookingDetails?.autoApproval}
          />
        );
      default:
        break;
    }
  };
  const startAtDate = moment(props.bookingDetails?.schedules[0].startAt);
  const currentDate = moment();

  const handleResheduleModal = (schedule) => {
    setRescheduleModalOpen(true);
  };

  return (
    <div>
      <Spin spinning={loading} indicator={renderLoader}>
        <FSMBookingHeader
          booking={props.bookingDetails}
          onStageAction={handleBookingStagesAction}
          refetch={props.refetch}
          props={props}
        />

        {/* dynamic fields form */}

        <div className="dynamic_form_items_container">
          {hasPermission && !isInstantBookingType && (
            <Card className="antCardBorder antCard">
              <Row justify={"end"}>
                {bookingType === "EVB" &&
                  startAtDate.isSameOrAfter(currentDate, "day") &&
                  props.bookingDetails?.currentStage?.slug === "review" && (
                    <Button
                      type="primary"
                      className="ant__primary__button"
                      onClick={handleResheduleModal}
                    >
                      Reschedule
                    </Button>
                  )}
                {[
                  "FSG110",
                  "FSG1210",
                  "FSG2310",
                  "FSG3410",
                  "FSG4510",
                ].includes(
                  props.bookingDetails?.currentStage?.status?.code
                ) && (
                  <Button
                    type="primary"
                    className="ant__primary__button"
                    onClick={() => handelProcessBooking("sendToParent")}
                  >
                    {"Return For Amendment"}
                  </Button>
                )}
                {[
                  "FSG1220",
                  "FSG120",
                  "FSG2320",
                  "FSG3420",
                  "FSG4520",
                ].includes(
                  props.bookingDetails?.currentStage?.status?.code
                ) && (
                  <Button
                    type="primary"
                    className="ant__primary__button"
                    onClick={() => handelProcessBooking("cancel-amendment")}
                  >
                    {"Cancel Amendment"}
                  </Button>
                )}
                {[
                  "FSG4500", //For FFB
                  "FSG4510",
                  "FSG4520",
                  "FSG4530",
                  "FSG100", // For EVB
                  "FSG110",
                  "FSG130",
                  "FSG140",
                  "FSG1200", //for LTB
                  "FSG1210",
                  "FSG1230",
                  "FSG1240",
                  "FSG3400", //for DPB
                  "FSG3410",
                  "FSG3430",
                  "FSG3430",
                  "FSG2300", //for FFB
                  "FSG2310",
                  "FSG2330",
                  "FSG2340",
                ].includes(
                  props.bookingDetails?.currentStage?.status?.code
                ) && (
                  <Button
                    type="primary"
                    className="ant__primary__button"
                    onClick={onSaveApplication}
                    disabled={["FSG120", "FSG4520"].includes(
                      props.bookingDetails?.currentStage?.status?.code
                    )}
                  >
                    {loading ? "Saving..." : "Save booking"}
                  </Button>
                )}
              </Row>
            </Card>
          )}

          <FSMTabs
            selectedTab={selectedTab}
            onLogsTab={() => setSelectedTab(1)}
            onDetialsTab={() => setSelectedTab(0)}
          />
          {["DPB", "FFB", "PHB"].includes(bookingType) ? (
            <div className="dynamic_form_items_container">
              {renderCaseView(selectedTab)}
            </div>
          ) : (
            <Card className="antCardBorder antCard">
              {renderCaseView(selectedTab)}
            </Card>
          )}
        </div>
      </Spin>

      <CustomModal
        visibility={reschudleModalOpen}
        handleCancel={() => setRescheduleModalOpen(false)}
        handleOk={() => null}
        title={`Reschedule -  ${props.bookingDetails?.schedules[0]?.title}`}
        modalSmall={bookingType === "EVB" ? true : false}
      >
        <BookingRescheduleModal
          schedule={props.bookingDetails?.schedules[0]}
          type={bookingType}
          facility={props.bookingDetails?.facility}
          handleCancel={() => setRescheduleModalOpen(false)}
          refetch={() => props.refetch()}
          bookingType={bookingType}
        />
      </CustomModal>
    </div>
  );
};

export default BookingReviewStage;
