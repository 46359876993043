/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import { NotificationManager } from "react-notifications";

import {
  _addNewDocumentConfiguration,
  _deleteDocumentConfiguration,
  _getAllDocumentsListsConfiguration,
  _updateDocumentConfiguration,
} from "actions/documents-configurations/documentsConfigurationsAction";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
import {
  addNewDocumentConfigurationAPI,
  allDocumentsListConfiguration,
  deleteDocumentConfigurationAPI,
  updateDocumentConfigurationAPI,
} from "apis/document-list-configuration/documentListConfigurationApis";
import { getEServicesAPI } from "apis/e-services-api/EServicesApi";
import {
  getAllQrAllowedUsersList,
  getQrTypeList,
} from "apis/qr-configuration/qrConfigurationApis";
import {
  renderLoader,
  requestErrorHandel,
  successMessage,
} from "utils/helpers/helpers";
import {
  getValidationDocumentConfigurations,
  getValidationDocumentConfigurationById,
  createValidationDocumentConfiguration,
  updateValidationDocumentConfiguration,
  deleteValidationDocumentConfiguration,
} from "apis/qr-configuration/qrConfigurationApis"; // Import new API functions
const { Option } = Select;

const ModalType = {
  ADD: "Add Qr Configuration",
  EDIT: "Update Qr Configuration",
};

const QrConfiguration = (props) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [showModal, toggleModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  const [editId, setEditId] = React.useState("");
  const [loading, setLoading] = React.useState();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const _totalRecords = useSelector((state) => state.documentsListConfig.total);

  const [usersWithValidatorAccess, setUsersWithValidatorAccess] =
    React.useState([]);
  const [validatorTypes, setValidatorTypes] = React.useState([]);

  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedValidatorType, setSelectedValidatorType] = React.useState([]);

  const [docConfigurationsList, setDocConfigurationsList] = React.useState([]);
  // Fetch users with validator access
  const fetchUsersWithValidatorAccess = async () => {
    try {
      const response = await getAllQrAllowedUsersList();
      const response2 = await getQrTypeList();
      const response3 = await getValidationDocumentConfigurations();
      console.log({ response3 });
      if (response.status === 200) {
        setValidatorTypes(response2.data.data);
        setUsersWithValidatorAccess(response.data.data);
        setDocConfigurationsList(response3.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching users:", error);
    }
  };

  const fetchRecords = async (page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    //   fetchAllDocumentsListConfigurations();
    //   dispatch(getStageMetaDataAction());
    fetchUsersWithValidatorAccess(); // Fetch users on component mount
  }, [currentPage]);
  // React.useEffect(() => {
  //   applicationType === "ASR" && fetchEServices();
  // }, []);

  const fetchAllDocumentsListConfigurations = async () => {
    try {
      setLoading(true);
      const response = await getValidationDocumentConfigurations();
      console.log({ response });
      // dispatch(_getAllDocumentsListsConfiguration(response.data));
      setDocConfigurationsList(response.data);
      setLoading(false);
    } catch (error) {
      //   dispatch(_getAllDocumentsListsConfiguration({ data: [], total: 0 }));
      setLoading(false);
    }
  };

  let selectedCampusesesIds = [];

  // React.useEffect(() => {
  //   filterSchoolsByCampus();
  // }, [selectedCampuses]);

  // React.useEffect(() => {
  //   if (campusesFilteredSchools?.length > 0 && applicationType === "TFD") {
  //     setSelectedSchools(campusesFilteredSchools);
  //   }
  // }, [campusesFilteredSchools]);

  const onEdit = async (item) => {
    toggleModal(true);
    setModalTitle(ModalType.EDIT);
    try {
      const response = await getValidationDocumentConfigurationById(item.id);
      console.log({ response, item });
      if (response.status === 200) {
        setSelectedValidatorType([]);
        setSelectedUser(null);
        form.setFieldsValue({
          userId: item.user,
          validatorIds: item.validationTypes,
        });
        setEditId(item.id);
        setSelectedUser(item.user);
        setSelectedValidatorType(item.validationTypes);
      }
    } catch (error) {
      console.error("Error fetching configuration by ID:", error);
    }
  };
  const onAdd = () => {
    //  open modal to create new config
    setModalTitle(ModalType.ADD);
    form.resetFields();
    setSelectedUser(null);
    setSelectedValidatorType(null);
    toggleModal(true);
  };
  const onDelete = (item) => {
    setLoading(true);
    deleteValidationDocumentConfiguration(item.id)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          //   dispatch(_deleteDocumentConfiguration(item.id));
          successMessage({ message: "Document configuration deleted!" });
          fetchUsersWithValidatorAccess();
        } else throw res;
      })
      .catch((e) => {
        requestErrorHandel({ error: e });
        setLoading(false);
      });
  };
  const addDocumentConfig = (params) => {
    setLoading(true);
    createValidationDocumentConfiguration(params)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setLoading(false);
          //   dispatch(_addNewDocumentConfiguration(res.data));
          toggleModal(false);
          successMessage({ message: "Document configuration added!" });
        } else throw res;
      })
      .catch((e) => {
        requestErrorHandel({ error: e });
        setLoading(false);
      });
  };
  const editDocumentConfig = async (params) => {
    setLoading(true);
    updateValidationDocumentConfiguration(editId, params)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setLoading(false);
          //   dispatch(_updateDocumentConfiguration(res.data));
          toggleModal(false);
          successMessage({ message: "Document configuration updated!" });
        } else throw res;
      })
      .catch((e) => {
        requestErrorHandel({ error: e });
        setLoading(false);
      });
  };

  const onSubmit = (value) => {
    // if (!selectedUser || !selectedValidatorType) {
    //   NotificationManager.error("Please select a user and validator type");
    //   return;
    // }
    let params = {
      userId: parseInt(selectedUser?.id),
      validationTypeIds: selectedValidatorType.map((type) => parseInt(type.id)),
    };
    modalTitle === ModalType.ADD
      ? addDocumentConfig(params)
      : editDocumentConfig(params);

    fetchUsersWithValidatorAccess();
  };
  console.log(
    usersWithValidatorAccess,
    usersWithValidatorAccess.find(
      (user) => user.id === parseInt(selectedUser?.id)
    )?.name
  );

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          key={"#"}
          loading={loading}
          data={docConfigurationsList}
          totalRecord={_totalRecords}
          fetchRecords={fetchRecords}
          header={() => (
            <TableHeader
              headerTitle={`QR - Documents Configuration`}
              headerSubTitle={`Manage configuration for single or multiple documents`}
              headerBtnTitle="Add New"
              onAddNew={onAdd}
            />
          )}
          columns={[
            {
              key: "user",
              title: "User",
              dataIndex: "user",
              width: "20%",
              render: (item) => <div>{item?.name}</div>,
            },
            {
              key: "validationTypes",
              title: "Validator Type",
              dataIndex: "validationTypes",
              render: (item) => item?.map((i) => i?.title)?.join(", "),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => (
                <div style={{ display: "flex" }} className="d-flex">
                  <div style={{ marginRight: "10px" }}>
                    <Tooltip title="Update">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onEdit(item)}
                      />
                    </Tooltip>
                  </div>

                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    okText="Yes"
                    cancelText="No"
                    style={{ marginLeft: "30px" }}
                    onConfirm={() => onDelete(item)}
                  >
                    <Tooltip title="Delete">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={() => {
            setSelectedValidatorType([]);
            setSelectedUser(null);
            toggleModal(false);
          }}
          title={
            editId !== ""
              ? "Update Documents Configuration"
              : "Add Documents Configuration"
          }
        >
          <Form form={form} layout="vertical" size="large" onFinish={onSubmit}>
            <Form.Item
              // name={"userId"}
              label={"User"}
            >
              <Select
                style={{ textDecoration: "capitalize" }}
                placeholder={"Select User"}
                value={selectedUser?.name}
              >
                {usersWithValidatorAccess?.map((user) => (
                  <Option

                  // value={user.id} key={user.id}
                  >
                    <p onClick={() => setSelectedUser(user)}>{user?.name}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              // name="campuses"
              // name={"validationTypeIds"}
              label={"Validator Type"}
            >
              <Select
                placeholder="Select Validator Type"
                mode={"multiple"}
                value={
                  selectedValidatorType?.length > 0
                    ? selectedValidatorType
                        ?.map((item) => item.title)
                        ?.join(", ")
                    : []
                }
              >
                <Option>
                  <Checkbox
                    checked={
                      selectedValidatorType?.length === validatorTypes.length
                    }
                    onChange={(e) => {
                      console.log(validatorTypes);
                      setSelectedValidatorType(
                        e.target.checked ? validatorTypes : []
                      );
                    }}
                  >
                    Select All
                  </Checkbox>
                </Option>
                {validatorTypes?.map((el, i) => (
                  <Option value={el.id} key={i}>
                    <Checkbox
                      checked={selectedValidatorType?.some(
                        (i) => i.id === el.id
                      )}
                      onChange={(e) => {
                        setSelectedValidatorType(
                          e.target.checked
                            ? selectedValidatorType?.length > 0
                              ? [...selectedValidatorType, el]
                              : [el]
                            : selectedValidatorType?.filter(
                                (i) => i.id !== el.id
                              )
                        );
                      }}
                    >
                      {el.title}
                    </Checkbox>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item label={"Validator Type"} name={"validatorTypeId"}>
              <Select
                placeholder={"Select Validator Type"}
                onChange={(value) =>
                  setSelectedValidatorType(
                    validatorTypes.find((type) => type.id === value)
                  )
                }
              >
                <Option value={0} key={1}>
                  Select All
                </Option>
                {validatorTypes?.map((type) => (
                  <Option value={type.id} key={type.title}>
                    {type.title}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}

            <Row justify="end">
              <Button htmlType="submit" loading={loading}>
                {loading ? "Saving" : "Save"}
              </Button>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};
export default QrConfiguration;
