import { DownOutlined, EyeOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Spin, Tooltip, Select } from "antd";

import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useEffect } from "react";
import { useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import { getSccTermLogs } from "../api";
import { requestErrorHandel } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";

const SccTermLogs = (props) => {
  const termId = props.location.state.termId;
  const term = props.location.state.term;
  const indentifer = props.location.state.indentifer;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (term) {
      fetchTermLogs();
    }
  }, []);

  const fetchTermLogs = async () => {
    try {
      const termIndentifer = term?.firstTermStatus ? "first" : "second";
      setLoading(true);
      const response = await getSccTermLogs({
        id: termId,
        term: termIndentifer,
      });
      if (response.status === 200) {
        setData(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => null}
          headerTitle={`${term?.name} ${indentifer} Activity Logs`}
          headerSubTitle={""}
          headerBtnTitle={""}
        />
      </>
    );
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Action Type",
              dataIndex: "status",
              key: "status",
            },
            {
              title: "Performed By",
              key: "performedBy",
              render: (record) => record?.performedBy?.userName,
            },
            {
              title: "Date",
              key: "createdAt",
              render: (record) => formatedDate(record?.createdAt),
            },
            {
              title: "Remarks",
              dataIndex: "remarks",
              key: "remarks",
            },
          ]}
        ></CommonTable>
      </Spin>
    </div>
  );
};

export default SccTermLogs;
