import { Table } from "antd";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React, { useState, useEffect, useCallback } from "react";
import { generateUniqueColumnKey } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import { formatedDate } from "utils/helpers/helpers";

const SchduleDetailInformation = ({ booking }) => {
  const combinedArray = booking?.schedules?.reduce((combined, schedule) => {
    if (schedule.details && schedule.details.length > 0) {
      // Push each detail into the combined array
      combined.push(...schedule.details);
    }
    return combined;
  }, []);

  return (
    <div className="schedule__details__container">
      <p>Schedule Details</p>
      <Table
        dataSource={combinedArray}
        pagination={{ pageSize: 10 }}
        columns={[
          {
            title: "Date",
            key: generateUniqueColumnKey(),
            render: (record) => (
              <span className="table_record">{formatedDate(record?.date)}</span>
            ),
          },
          {
            title: "Slot",
            key: generateUniqueColumnKey(),

            render: (record) => {
              if (record?.startTime && record?.endTime) {
                return (
                  <span className="table_record">
                    {formatedDateWithAmAndPm(record?.startTime) +
                      "-" +
                      formatedDateWithAmAndPm(record?.endTime)}
                  </span>
                );
              } else {
                return <span className="table_record">Full Day</span>;
              }
            },
          },

          {
            title: "Unite Price",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span className="table_record">
                {record?.unitPrice + " " + "AED"}
              </span>
            ),
          },
          {
            title: "Discount",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span className="table_record">
                {record?.discountAmount + " " + "AED"}
              </span>
            ),
          },
          {
            title: "VAT",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span className="table_record">
                {record?.vatAmount + " " + "AED"}
              </span>
            ),
          },
          {
            title: "Net Price",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span className="table_record">
                {record?.netTotal + " " + "AED"}
              </span>
            ),
          },
          {
            title: "Total",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span className="table_record">
                {record?.grandTotal + " " + "AED"}
              </span>
            ),
          },
          {
            title: "Payment Status",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span style={{ textTransform: "capitalize" }}>
                {record?.paymentStatus}
              </span>
            ),
          },
          {
            title: "Refund",
            key: generateUniqueColumnKey(),

            render: (record) => (
              <span style={{ textTransform: "capitalize" }}>
                {record?.refundStatus.replace("_", "  ")}
              </span>
            ),
          },
          {
            title: "Status",
            key: generateUniqueColumnKey(),
            render: (record) => (
              <span style={{ textTransform: "capitalize" }}>
                {record?.status}
              </span>
            ),
          },
        ]}
      ></Table>
    </div>
  );
};

export default SchduleDetailInformation;
