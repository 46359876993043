/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Row, Form } from "antd";
import CompactFormItem from "components/CompactFormItem/CompactFormItem";
import { LoadingOutlined } from "@ant-design/icons";

import { _updateApplications } from "actions/applications/applications-actions";
import { getSignUrl } from "apis/get-sign-url/getSignUrl";
import axios from "axios";
import checkedImg from "../../assets/img/checked.png";
import uncheckedImg from "../../assets/img/unchecked.png";
import { useSelector } from "react-redux";
import { handleUploadFileToENSServer } from "utils/helpers/helpers";

export default function ApplicationInformationForm({
  application,
  type,
  isFieldsDisabled,
  form,
  onApproveAll,
  hideApproveAll,
  onChangeDob,
  discountRules,
  onUpdateEservice,
}) {
  const inputEl = useRef([]);
  const [imagePreview, setImagePreview] = useState(null);

  const [approveAllFields, setApproveAllFields] = useState(undefined);
  const [formInfoArray, setFormInfoArray] = useState([]);
  const [isSchoolinUAE, setisSchoolinUAE] = useState(
    application?.criteria?.schoolInUAE?.value
  );

  const onChangeSchoolDropDown = (val) => {
    setisSchoolinUAE(val);
  };

  const [dob, setDob] = useState(application?.childDetails?.dob?.value);

  const _educationalSystems = useSelector(
    (state) => state.metaData.educationalSystems
  );
  const [currentSchoolEducationSystemId, setCurrentSchoolEducationSystemId] =
    useState(null);

  const filterStudentGrades = _educationalSystems.find(
    (ed) =>
      ed.id ==
      (currentSchoolEducationSystemId === null
        ? application?.criteria?.educationSystem?.value
        : currentSchoolEducationSystemId)
  );

  const onChangeStudentCurrentEeducationSystem = (id) => {
    setCurrentSchoolEducationSystemId(id);
  };

  const handleFileInputChange = async (e, element) => {
    var file = e.target.files[0];
    // setDisableBtn(true);

    var imageURL = await handleUploadFileToENSServer(
      file,
      application?.applicationType
    );
    element.value = imageURL;
    if (application?.applicationType === "ENR") {
      form.setFieldsValue({ profilePhoto: element });
    } else {
      form.setFieldsValue({ studentProfilePhoto: element });
    }
  };

  useEffect(() => {
    if (application) {
      checkAllApproved();
      prepareFormArray();
    }
  }, [application]);

  const checkAllApproved = () => {
    let allAreApproved = true;
    if (type === "father") {
      Object.keys(application?.father).forEach((item, i) => {
        if (application?.father[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });

      Object.keys(application?.residence).forEach((item, i) => {
        if (application?.residence[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });

      Object.keys(application?.contacts).forEach((item, i) => {
        if (application?.contacts[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });

      allAreApproved && setApproveAllFields(allAreApproved);
      // if (allAreApproved) {
      //   isFieldsDisabled = true;
      // }
    } else if (type === "mother") {
      Object.keys(application?.mother).map((item, i) => {
        if (application?.mother[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (type === "child") {
      Object.keys(application?.childDetails).map((item, i) => {
        if (application?.childDetails[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (type === "trp") {
      const allTRPFields = application
        ? {
            ...application?.address,
            ...application?.authorizedPersons,
            ...application?.mode,
          }
        : {};
      Object.keys(allTRPFields).map((item, i) => {
        if (allTRPFields[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (type === "asr") {
      Object.keys(application.fields).map((item) => {
        if (application.fields[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (type === "asr-items") {
      Object.keys(application.items).map((item) => {
        if (application.items[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
      // ---------------SCH applicatiin types for all approved-----------------
    } else if (type === "sch_father_info") {
      Object.keys(application?.father).forEach((item, i) => {
        if (application?.father[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (type === "sch_mother_info") {
      Object.keys(application?.mother).forEach((item, i) => {
        if (application?.mother[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (type === "sch-detail") {
      Object.keys(application?.details).forEach((item, i) => {
        if (application?.details[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });

      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (type === "sch_students_info") {
      Object.keys(application?.studentDetails).forEach((item, i) => {
        if (application?.studentDetails[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });

      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (type === "criteria") {
      Object.keys(application?.criteria).forEach((item, i) => {
        if (application?.criteria[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (application && type === "tcc_father_info") {
      Object.keys(application?.father).forEach((item, i) => {
        if (application?.father[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (application && type === "tcc_mother_info") {
      Object.keys(application?.mother).forEach((item, i) => {
        if (application?.mother[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    } else if (application && type === "tcc_students_info") {
      Object.keys(application?.studentDetails).forEach((item, i) => {
        if (application?.studentDetails[item].status !== "approved") {
          allAreApproved = false;
          return;
        }
      });
      allAreApproved && setApproveAllFields(allAreApproved);
    }
  };

  // when all approved selected or unselected, we need to update the application
  const selectUnselectAllApproved = (allAssproved) => {
    if (type === "father") {
      Object.keys(application.father).forEach((item, i) => {
        if (!allAssproved) {
          application.father[item].status = "needAmendment";
        } else {
          application.father[item].status = "approved";
        }
      });

      Object.keys(application?.residence).forEach((item, i) => {
        if (!allAssproved) {
          application.residence[item].status = "needAmendment";
        } else {
          application.residence[item].status = "approved";
        }
      });

      Object.keys(application?.contacts).forEach((item, i) => {
        if (!allAssproved) {
          application.contacts[item].status = "needAmendment";
        } else {
          application.contacts[item].status = "approved";
        }
      });
    } else if (type === "mother") {
      Object.keys(application?.mother).map((item, i) => {
        if (!allAssproved) {
          application.mother[item].status = "needAmendment";
        } else {
          application.mother[item].status = "approved";
        }
      });
    } else if (type === "child") {
      Object.keys(application?.childDetails).map((item, i) => {
        if (!allAssproved) {
          application.childDetails[item].status = "needAmendment";
        } else {
          application.childDetails[item].status = "approved";
        }
      });
    }
  };

  // change notes for fields other than documents
  const onChangeNotes = (ref, name, formElement) => {
    if (ref.current.input.value.length > 0) {
      //if (formElement.status !== "needAmendment") {
      formElement.status = "needAmendment";
      formElement.note = ref.current.input.value;
      form.setFieldsValue({ [name]: formElement });
      //}
    } else {
      formElement.status = "submitted";
      formElement.note = "";
      form.setFieldsValue({ [name]: formElement });
    }
  };

  const onChangeASRItems = (valueName, value, name, formElement) => {
    // valueName : price or quantity
    formElement[valueName] = value;
    form.setFieldsValue({ [name]: formElement });
  };

  const switchOnChangeForParent = (
    isChecked,
    name,
    formElement,
    ref,
    inputFieldRef
  ) => {
    if (isChecked) {
      formElement.status = "approved";
      // formElement.oldNote = formElement.note;
      // formElement.note = "";
      if (inputFieldRef.current?.input) {
        inputFieldRef.current.input.disabled = true;
      }

      //ant-form-item-explain

      ref.current.input.parentElement.classList.remove(
        "ant-form-item-has-error-leave"
      );

      ref.current.input.value = "";
      ref.current.input.disabled = true;
      ref.current.input.className =
        ref.current.input.className + " ant-input-disabled";
      ref.current.input.parentElement.className =
        ref.current.input.parentElement.className +
        " ant-input-affix-wrapper-disabled";
      const getFieldValue = form.getFieldValue(name);

      getFieldValue.status = "approved";
      formElement.oldNote = getFieldValue.note;

      getFieldValue.note = "";

      // form.setFieldsValue({ [name]: formElement });
      form.setFieldsValue({ [name]: getFieldValue });
    } else {
      formElement.status = "needAmendment";
      ref.current.input.value = ref.oldNote ?? "";
      formElement.note = formElement.oldNote ?? "";

      if (inputFieldRef.current?.input) {
        inputFieldRef.current.input.disabled = false;

        inputFieldRef.current.input.parentElement.classList.remove(
          "ant-input-affix-wrapper-disabled"
        );
        inputFieldRef.current.input.classList.remove("ant-input-disabled");
      }

      ref.current.input.disabled = false;
      ref.current.input.parentElement.classList.remove(
        "ant-input-affix-wrapper-disabled"
      );
      ref.current.input.classList.remove("ant-input-disabled");
      // try {
      //   formElement.value = JSON.parse(formElement.value);
      // } catch (e) {
      //   formElement.value = formElement.value;
      // }
      const getFieldValue = form.getFieldValue(name);

      String(getFieldValue.value);
      getFieldValue.status = "needAmendment";
      getFieldValue.value =
        name == "0" || name == "1"
          ? getFieldValue.value
          : String(getFieldValue.value);
      getFieldValue.note = formElement.oldNote;
      form.setFieldsValue({ [name]: getFieldValue });
      //revmoe all approve
      setApproveAllFields(undefined);
      onApproveAll(false);
    }
  };

  const changeDob = (dateStr) => {
    setDob(dateStr);
    onChangeDob(dateStr);
  };

  const onFinishInfoForm = (val) => {};

  const prepareFormArray = () => {
    let arrayResult = [];
    if (type === "father") {
      Object.keys(application?.father).forEach((item, i) => {
        arrayResult.push({
          name: item,
          index: i,
          formElement: application?.father[item],
        });
      });
      Object.keys(application?.residence).forEach((item, i) => {
        arrayResult.push({
          name: item,
          index: i,
          formElement: application?.residence[item],
        });
      });
      Object.keys(application?.contacts).forEach((item, i) => {
        arrayResult.push({
          name: `${item}`,
          index: i,
          formElement: application?.contacts[item],
        });
      });

      // form.setFieldsValue(application?.father);
      // form.setFieldsValue(application?.residence);
      // form.setFieldsValue(application?.contacts);

      // form.setFieldsValue({
      //   0: {
      //     value: {
      //       name: application?.contacts[0].value.name,
      //       relation: application?.contacts[0].value.relation,
      //       phoneNo: application?.contacts[0].value.phoneNo,
      //     },
      //   },
      // });

      // form.setFieldsValue({
      //   1: {
      //     value: {
      //       name: application?.contacts[1].value.name,
      //       relation: application?.contacts[1].value.relation,
      //       phoneNo: application?.contacts[1].value.phoneNo,
      //     },
      //   },
      // });
      const combinedValues = {
        ...application?.father,
        ...application?.residence,
        ...application?.contacts,
      };

      form.setFieldsValue(combinedValues);
    } else if (type === "mother") {
      arrayResult = Object.keys(application?.mother).map((item, i) => {
        return { name: item, index: i, formElement: application?.mother[item] };
      });
      form.setFieldsValue(application?.mother);
    } else if (type === "child") {
      let allENRChildFields = {};

      if (application) {
        // Check if authorizedPersons field exists
        if (
          application.authorizedPersons &&
          Object.keys(application.authorizedPersons).length > 0
        ) {
          allENRChildFields = {
            ...application.childDetails,
            ...application.authorizedPersons,
          };
        } else {
          allENRChildFields = { ...application.childDetails };
        }
      }
      arrayResult = Object.keys(allENRChildFields).map((item, i) => {
        if (item === "previousSchoolType" || item === "previousSchoolName") {
          if (allENRChildFields[item].value === "") {
            allENRChildFields[item].value = "none";
          }
        }
        return {
          name: item,
          index: i,
          formElement: allENRChildFields[item],
        };
      });
      form.setFieldsValue(allENRChildFields);
      try {
        allENRChildFields &&
          form.setFieldsValue({
            otherLanguages: {
              value: JSON.parse(allENRChildFields.otherLanguages.value),
            },
          });
      } catch (e) {
        console.log("error in parsing json", e);
      }
    } else if (type === "trp") {
      let allTRPFields = {};
      if (application) {
        if (
          application?.authorizedPersons &&
          Object.keys(application?.authorizedPersons).length > 0
        ) {
          allTRPFields = {
            ...application.address,
            ...application.authorizedPersons,
            ...application.mode,
          };
        } else {
          allTRPFields = {
            ...application.address,
            ...application.mode,
          };
        }
      }

      arrayResult = application
        ? Object.keys(allTRPFields).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: allTRPFields[item],
            };
          })
        : [];
      form.setFieldsValue(allTRPFields);
    } else if (type === "asr") {
      arrayResult = application
        ? Object.keys(application.fields).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.fields[item],
            };
          })
        : [];
      form.setFieldsValue(application.fields);
    } else if (type === "asr-items") {
      arrayResult = application
        ? Object.keys(application.items).map((item, i) => {
            form.setFieldsValue({
              [`asr-item-${item}`]: application.items[item],
            });
            return {
              name: `asr-item-${item}`,
              index: i,
              formElement: application.items[item],
            };
          })
        : [];
      // form.setFieldsValue(application.items);
    } else if (type === "tfd") {
      arrayResult = application
        ? Object.keys(application.fields).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.fields[item],
            };
          })
        : [];
      const discountRule = {
        id: application.discountRule.id,
        note: application.discountRule.ruleNote,
        status: application.discountRule.ruleStatus,
        title: "Discount Rule",
        titleAr: "Discount Rule",
        value: application.discountRule.title,
        valueType: "text",
      };
      form.setFieldsValue({ ...application.fields, discountRule });
      arrayResult.push({
        name: "discountRule",
        index: 1,
        formElement: discountRule,
      });
    } else if (type === "upd") {
      arrayResult = application
        ? Object.keys(application.fields).map((item, i) => {
            return {
              name: item,
              index: i,
              formElement: application.fields[item],
            };
          })
        : [];
      form.setFieldsValue(application.fields);
      // sch work----------------
    } else if (type === "sch_father_info") {
      arrayResult = Object.keys(application?.father).map((item, i) => {
        return {
          name: item,
          index: i,
          formElement: application?.father[item],
        };
      });
      form.setFieldsValue(application?.father);
    } else if (type === "sch_mother_info") {
      arrayResult = Object.keys(application?.mother).map((item, i) => {
        return {
          name: item,
          index: i,
          formElement: application?.mother[item],
        };
      });
      form.setFieldsValue(application?.mother);
    } else if (application && type === "sch-detail") {
      arrayResult = Object.keys(application?.details).map((item, i) => {
        return {
          name: item,
          index: i,
          formElement: application?.details[item],
        };
      });
      form.setFieldsValue(application?.details);
    } else if (application && type === "sch_students_info") {
      arrayResult = Object.keys(application?.studentDetails).map((item, i) => {
        return {
          name: item,
          index: i,
          formElement: application?.studentDetails[item],
        };
      });
      form.setFieldsValue(application?.studentDetails);
    } else if (application && type === "criteria") {
      arrayResult = Object.keys(application?.criteria).map((item, i) => {
        return {
          name: item,
          index: i,
          formElement: application?.criteria[item],
        };
      });
      form.setFieldsValue(application?.criteria);
    } else if (application && type === "tcc_father_info") {
      arrayResult = Object.keys(application?.father).map((item, i) => {
        return {
          name: item,
          index: i,
          formElement: application?.father[item],
        };
      });
      form.setFieldsValue(application?.father);
    } else if (application && type === "tcc_mother_info") {
      arrayResult = Object.keys(application?.mother).map((item, i) => {
        return {
          name: item,
          index: i,
          formElement: application?.mother[item],
        };
      });
      form.setFieldsValue(application?.mother);
    } else if (application && type === "tcc_students_info") {
      arrayResult = Object.keys(application?.studentDetails).map((item, i) => {
        return {
          name: item,
          index: i,
          formElement: application?.studentDetails[item],
        };
      });
      form.setFieldsValue(application?.studentDetails);
    }
    arrayResult.sort(function (a, b) {
      return a.formElement?.id - b.formElement?.id;
    });
    const photoIndex = arrayResult.findIndex(
      (el) => el.name === "studentProfilePhoto"
    );
    const photoItem = arrayResult[photoIndex];
    if (photoIndex > 0) {
      arrayResult.splice(photoIndex, 1);
      arrayResult.unshift(photoItem);
    }
    setFormInfoArray(arrayResult);
  };
  const getOptionalFields = (item, type) => {
    return (
      item.name === "workPhoneNo" ||
      item.name === "residencePhoneNo" ||
      item.name === "poBox" ||
      (type === "mother" && item.name === "occupation") ||
      (type === "mother" && item.name === "companyName") ||
      item.name === "otherLanguages" ||
      item.name === "restOfName" ||
      item.name === "restOfNameAr" ||
      // authorized persons
      item.name === "driverLicenceNo" ||
      item.name === "driverPhoneNo" ||
      item.name === "familyLicenceNo" ||
      item.name === "familyPhoneNo" ||
      item.name === "nannyLicenceNo" ||
      item.name === "nannyPhoneNo" ||
      item.name === "parentLicenceNo" ||
      (type === "trp" && item.name === "parentPhoneNo") ||
      item.name === "parentName" ||
      item.name === "familyName" ||
      item.name === "driverName" ||
      item.name === "nannyName" ||
      // discount application
      (type === "tfd" && item.name === "notes") ||
      // scholarship application
      (type === "sch" && item.name === "studentDetails") ||
      item.name === "restOfName" ||
      item.name === "middleName" ||
      item.name === "middleNameAr" ||
      item.name === "lastNameAr"
    );
  };
  const revertTermValue = (oldValue) => {
    application.childDetails["term"] = oldValue;
    form.setFieldsValue({ term: { value: oldValue } });
  };
  const renderform = () => {
    return formInfoArray.map((item, i) => {
      return (
        <CompactFormItem
          key={i}
          {...item}
          refs={inputEl}
          handleFileInputChange={handleFileInputChange}
          imagePreview={imagePreview}
          switchOnChangeForParent={switchOnChangeForParent}
          onChangeNotes={onChangeNotes}
          onChangeASRItems={onChangeASRItems}
          isFieldsDisabled={isFieldsDisabled}
          approveAllFields={approveAllFields}
          changeDob={changeDob}
          application={application}
          notRequired={getOptionalFields(item, type)}
          discountRules={discountRules}
          onUpdateEservice={onUpdateEservice}
          revertTermValue={revertTermValue}
          educationSystemGrades={filterStudentGrades?.grades}
          onChangeStudentCurrentEeducationSystem={
            onChangeStudentCurrentEeducationSystem
          }
          onChangeSchoolDropDown={onChangeSchoolDropDown}
          isSchoolinUAE={isSchoolinUAE}
        />
      );
    });
  };

  const approveAll = (e) => {
    let _allIsproved = approveAllFields ? false : true;
    setApproveAllFields(_allIsproved);
    // onApproveAll(_allIsproved);
    // selectUnselectAllApproved(_allIsproved);
  };
  return (
    <>
      <Form
        onFinish={onFinishInfoForm}
        form={form}
        layout={"vertical"}
        scrollToFirstError={"true"}
        className="applicationDetailsTabForm"
        size={"large"}
      >
        {!hideApproveAll && (
          <Row align="bottom" justify="center" style={{ padding: "1rem 5vw" }}>
            <img
              alt=""
              style={{
                maxHeight: "18px",
                maxWidth: "18px",
                cursor:
                  isFieldsDisabled ||
                  application?.stage?.status?.code === "SG003"
                    ? "not-allowed"
                    : "pointer",
              }}
              onClick={(e) => {
                if (application?.stage?.status?.code === "SG003") {
                  return;
                }
                if (!isFieldsDisabled) {
                  approveAll(e);
                }
              }}
              src={approveAllFields ? checkedImg : uncheckedImg}
            ></img>
            <span style={{ marginLeft: "5px" }}>Approve All</span>
          </Row>
        )}
        <> {renderform()}</>
      </Form>
    </>
  );
}
