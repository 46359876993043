import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select } from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getAllEducationSystems } from "apis/SCH/schRequests";
import EducationSystemForm from "components/Forms/sch-evalution-forms/EducationSystemForm";
import { addNewEducationSystem } from "apis/SCH/schRequests";
import { updateEducationSystem } from "apis/SCH/schRequests";
import { deleteEducationSystem } from "apis/SCH/schRequests";
const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const EducationSystem = () => {
  const _requestTypes = useSelector((state) => state.metaData.requestTypes);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [id, setID] = useState("");
  const _grades = useSelector((state) => state.metaData.grades);

  const [edSystemForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <TableHeader
        onAddNew={() => {
          edSystemForm.resetFields();
          setValuesForEdit(null);
          toggleModal(true);
          setModalTitle(ModalType.ADD);
        }}
        headerTitle={"Education  System"}
        headerSubTitle={"Manage Education  System"}
        headerBtnTitle={"Add New"}
      />
    );
  };

  useEffect(() => {
    getEducationSystem();
  }, []);

  const getEducationSystem = async () => {
    try {
      setLoading(true);
      const response = await getAllEducationSystems();
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const addEducationSystemPoint = async (data) => {
    const _gradesPoints = data.grades.map((_grd) => {
      if (_grd.points == null) {
        _grd.points = 0;
      }
      return _grd;
    });
    data.grades = _gradesPoints;
    try {
      setLoading(true);
      const response = await addNewEducationSystem({ data: data });
      if (response.status === 200) {
        successMessage({ message: "Education system  added successfully" });
        //await getEducationSystem();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateEducationSystemPoint = async (data) => {
    const _gradesPoints = data.grades.map((_grd) => {
      if (_grd.points == null) {
        _grd.points = 0;
      }
      return _grd;
    });
    data.grades = _gradesPoints;
    try {
      setLoading(true);
      const response = await updateEducationSystem({ id: id, data: data });
      if (response.status === 200) {
        successMessage({ message: "Education system updated successfully" });
        //await getEducationSystem();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteEducationSystemPoint = async (id) => {
    try {
      setLoading(true);
      const response = await deleteEducationSystem({ id: id });
      if (response.status === 200) {
        successMessage({ message: "Education system deleted successfully" });
        await getEducationSystem();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = async () => {
    await getEducationSystem();
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const gradesArray = [];
    for (const key in values) {
      if (values.hasOwnProperty(key) && key.startsWith("points_")) {
        const gradeId = key.split("_")[1]; // Extract gradeId from key
        const label = values[`label_${gradeId}`] || ""; // Get corresponding label or null
        const labelAr = values[`labelAr_${gradeId}`] || ""; // Get corresponding labelAr or null
        const points = parseInt(values[key]) || null; // Get corresponding points as integer or null

        const gradeObj = {
          gradeId: parseInt(gradeId),
          label: label,
          labelAr: labelAr,
          points: points,
        };
        gradesArray.push(gradeObj);
      }
    }

    const newValues = {
      title: values.title,
      titleAr: values.titleAr,
      isIBSystem: values.isIBSystem,
      grades: gradesArray,
    };

    if (modalTitle === ModalType.EDIT) {
      await handleUpdateEducationSystemPoint(newValues);
    } else {
      await addEducationSystemPoint(newValues);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    const pointsFields = [];
    const labelsFields = [];
    const labelArFields = [];

    record.grades.map((item) => {
      pointsFields.push(
        Object.assign({}, { [`points_${item.gradeId}`]: item.points })
      );
      labelsFields.push(
        Object.assign({}, { [`label_${item.gradeId}`]: item.label })
      );
      labelArFields.push(
        Object.assign({}, { [`labelAr_${item.gradeId}`]: item.labelAr })
      );
    });
    const _prevGrades = record.grades.map((grade) => grade.gradeId);
    const selectedGrades = _grades.filter((grade) =>
      _prevGrades.includes(grade.id)
    );

    delete record.grades;

    record.grades = selectedGrades;
    record.previsIBSystem = record.isIBSystem;
    //delete record.isIBSystem;
    edSystemForm.setFieldsValue({
      ...record,
      ...Object.assign({}, ...pointsFields),
      ...Object.assign({}, ...labelsFields),
      ...Object.assign({}, ...labelArFields),
    });

    setID(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
              render: (record) => (
                <p className="answer_para">
                  {record.replace(/(<([^>]+)>)/gi, "")}
                </p>
              ),
            },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Education system "
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() =>
                        handleDeleteEducationSystemPoint(item.id)
                      }
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Education system `}
        >
          <EducationSystemForm
            onSubmit={onSubmit}
            formName={edSystemForm}
            loading={loading}
            valuesForEdit={modalTitle === ModalType.ADD ? null : valuesForEdit}
            _requestTypes={_requestTypes}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default EducationSystem;
