import client from "apis/config";

export const fetchAllFsmFacilities = async ({
  limit = "10",
  campus = "",
  page = 1,
  category = "",
  subCategory = "",
  search = "",
}) => {
  return await client.get(
    `/facilities?limit=${limit}&campus=${campus}&page=${page}&category${category}&subCategory=${subCategory}&search=${search}`
  );
};
export const addNewFsmFacility = async (paylaod) => {
  return await client.post("/facilities", paylaod);
};
export const deleteFsmFacility = async (id) => {
  return await client.delete(`/facilities/${id}`);
};
export const updateFsmFacility = async (id, data) => {
  return await client.put(`/facilities/${id}`, data);
};
