import { Form, Button, Input, Select, Col, Row } from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { convertToTitleCase } from "utils/helpers/helpers";
const { Option } = Select;

const ActivateApplicationForm = ({
  onSubmit,
  loading,
  visibility,
  handleCancel,
  application,
}) => {
  const [activationNotes, setActivationNotes] = useState("");
  const [activationForm] = Form.useForm();

  useEffect(() => {
    if (!visibility) setActivationNotes("");
  }, [visibility]);

  const activationOptions = [
    { value: true, title: "Initial Stage " },
    { value: false, title: "Current Stage" },
  ];

  return (
    <div style={{ width: "100px !important" }}>
      <CustomModal
        visibility={visibility}
        handleCancel={handleCancel}
        modalSmall={true}
      >
        <Form layout="vertical" onFinish={onSubmit} form={activationForm}>
          <Row gutter={10}>
            <Col md={24}>
              <Form.Item
                label="Activation Stage"
                name="initialStage"
                rules={[
                  {
                    required:
                      application?.applicationType === "TCC" ? false : true,
                    message: "Activation Stage is required",
                  },
                ]}
              >
                <Select
                  // defaultValue={selectedInterViewType}
                  style={{ width: "100%" }}
                  // onChange={handleChange}
                  placeholder="Select Activation Stage"
                  defaultValue={
                    application?.applicationType === "TCC"
                      ? "initial Stage"
                      : undefined
                  }
                  disabled={
                    application?.applicationType === "TCC" ? true : false
                  }
                >
                  {activationOptions.map((type) => {
                    return <Option value={type.value}>{type.title}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col md={24}>
              <Form.Item
                name={"notes"}
                label="Notes"
                rules={[
                  {
                    required: true,
                    message: "Notes  required",
                  },
                ]}
              >
                <Input.TextArea placeholder="Notes" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <br />
          <Row justify="end">
            <Button htmlType="submit" loading={loading}>
              {loading ? "Saving..." : "Save"}
            </Button>
          </Row>
        </Form>
      </CustomModal>
    </div>
  );
};

export default ActivateApplicationForm;
