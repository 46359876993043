import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip, Form, Select } from "antd";

import CustomModal from "components/CustomModal/CustomModal";
import TableHeader from "components/TableHeader/TableHeader";
import CommonTable from "components/Tables/CommonTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { successMessage } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

import { fetchAllCommunityParticipation } from "apis/SCH/schRequests";
import { deleteCommunityParticipation } from "apis/SCH/schRequests";
import { updateCommunityParticipation } from "apis/SCH/schRequests";
import { addNewCommunityParticipation } from "apis/SCH/schRequests";
import CommunityParticipationForm from "components/Forms/sch-evalution-forms/CommunityParticipationForm";

const { Option } = Select;

const ModalType = {
  ADD: "Add",
  EDIT: "Edit",
};

const CommunityParticipation = () => {
  const _requestTypes = useSelector((state) => state.metaData.requestTypes);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);

  const [showModal, toggleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(ModalType.ADD);
  const [valuesForEdit, setValuesForEdit] = useState(null);
  const [id, setID] = useState("");

  const [communityParticipationForm] = Form.useForm();

  const renderHeader = () => {
    return (
      <>
        <TableHeader
          onAddNew={() => {
            communityParticipationForm.resetFields();
            toggleModal(true);
            setModalTitle(ModalType.ADD);
          }}
          headerTitle={"Community Participation"}
          headerSubTitle={"Manage Community Participation"}
          headerBtnTitle={"Add New"}
        />
      </>
    );
  };
  useEffect(() => {
    getAllCommunityParticipation();
  }, []);

  const getAllCommunityParticipation = async () => {
    try {
      setLoading(true);
      const response = await fetchAllCommunityParticipation();
      if (response.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const addCommunityParticipation = async (data) => {
    try {
      setLoading(true);
      const response = await addNewCommunityParticipation({ data: data });
      if (response.status === 200) {
        successMessage({
          message: "Community Participation  added successfully",
        });
        await getAllCommunityParticipation();
        onCloseForm();
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  const handleUpdateCommunityParticipation = async (data) => {
    try {
      setLoading(true);
      const response = await updateCommunityParticipation({
        id: id,
        data: data,
      });
      if (response.status === 200) {
        successMessage({
          message: "Community Participation updated successfully",
        });
        await getAllCommunityParticipation();
        onCloseForm();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const handleDeleteCommunityParticipation = async (id) => {
    try {
      setLoading(true);
      const response = await deleteCommunityParticipation({ id: id });
      if (response.status === 200) {
        successMessage({
          message: "Community Participation deleted successfully",
        });
        await getAllCommunityParticipation();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const onCloseForm = () => {
    toggleModal(false);
    setValuesForEdit(null);
  };

  const onSubmit = async (values) => {
    const newValues = {
      ...values,
      type: "social_and_community_activities",
      points: Number(values.points),
    };
    if (modalTitle === ModalType.EDIT) {
      await handleUpdateCommunityParticipation(newValues);
    } else {
      await addCommunityParticipation(newValues);
    }
  };

  const handleEdit = (record) => {
    setValuesForEdit(record);
    communityParticipationForm.setFieldsValue({
      ...record,
    });
    setID(record.id);
    setModalTitle(ModalType.EDIT);
    toggleModal(true);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          header={renderHeader}
          data={data}
          fetchRecords={(page) => setPage(page)}
          totalRecord={total}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: "title",

              render: (record) => (
                <p className="answer_para">
                  {record.replace(/(<([^>]+)>)/gi, "")}
                </p>
              ),
            },

            {
              title: "Points",
              dataIndex: "points",
              key: "points",
              // width: "40%",
              render: (record) => <p className="answer_para">{record}</p>,
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              // width: "45%",
              render: (item) => {
                return (
                  <div style={{ display: "flex" }} className="d-flex">
                    <div style={{ marginRight: "10px" }}>
                      <Tooltip title="Update Community Participation Info">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => handleEdit(item)}
                        />
                      </Tooltip>
                    </div>

                    <Popconfirm
                      title="Are you sure you want to delete this Community Participation?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() =>
                        handleDeleteCommunityParticipation(item.id)
                      }
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                );
              },
            },
          ]}
        ></CommonTable>
        <CustomModal
          visibility={showModal}
          handleCancel={onCloseForm}
          handleOk={() => null}
          title={`${modalTitle} Community Participation`}
        >
          <CommunityParticipationForm
            onSubmit={onSubmit}
            formName={communityParticipationForm}
            loading={loading}
            valuesForEdit={null}
            _requestTypes={_requestTypes}
          />
        </CustomModal>
      </Spin>
    </div>
  );
};

export default CommunityParticipation;
