// import React from "react";
import { Table, Typography } from "antd";
import TitleWithLine from "../title-with-line/TitleWithLine";

const CampusesCaseTable = ({ data, title }) => {
  // Ensure data and campusesCases are defined
  const campusesCases = data?.campusesCases || [];

  // Extract unique campuses
  const campuses = [...new Set(campusesCases.map((campus) => campus.campus))];

  // Define a mapping for school types
  const schoolTypeMapping = {
    PS: "Primary School",
    BS: "Boys School",
    GS: "Girls School",
  };

  // Extract unique school types
  const schoolTypes = Object.keys(schoolTypeMapping);

  // Prepare data for the table
  const tableData =
    campusesCases.length > 0
      ? schoolTypes.map((schoolType) => {
          const rowData = { school: schoolTypeMapping[schoolType] }; // Display full name

          campuses.forEach((campus) => {
            const campusData = campusesCases.find((c) => c.campus === campus);
            rowData[campus] = campusData?.schoolCases[schoolType] || 0;
          });

          return rowData;
        })
      : [];

  // Filter out rows with no data
  const filteredTableData = tableData.filter((row) =>
    Object.values(row).some((value) => value > 0)
  );

  // Define columns for the Ant Design Table
  const columns =
    campusesCases.length > 0
      ? [
          {
            title: "School Type",
            dataIndex: "school",
            key: "school",
          },
          ...campuses.map((campus) => ({
            title: campus,
            dataIndex: campus,
            key: campus,
          })),
          {
            title: "Total",
            dataIndex: "rowtotal",
            render: (_, record) => {
              const total = campuses.reduce(
                (sum, campus) => sum + (record[campus] || 0),
                0
              );

              return <span style={totalRowStyle}>{total}</span>;
            },
          },
        ]
      : campuses.map((campus) => ({
          title: campus,
          dataIndex: campus,
          key: campus,
        }));

  // Calculate totals for each campus
  const totalRow = { school: "Total" };
  const hasRowTotalColumn = columns.some((col) => col.dataIndex === "rowtotal");

  if (campusesCases.length > 0) {
    campuses.forEach((campus) => {
      const totalCases = campusesCases.reduce(
        (sum, campusData) =>
          sum + (campusData.campus === campus ? campusData.totalCases : 0),
        0
      );
      // Conditionally display "-" if totalCases is 0
      totalRow[campus] =
        totalCases === 0 && !hasRowTotalColumn ? "-" : totalCases;
    });

    // Add the totals row to the table data
    filteredTableData.push(totalRow);
  }

  // Inline styles
  const headerStyle = {
    textAlign: "center",
    padding: "1px 0",
    backgroundColor: "#f5f5f5",
    boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
    borderRadius: "7px 7px 0 0", // Round the top corners
  };
  const tableContainerStyle = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow to table
    borderRadius: "0 0 7px 7px", // Round the bottom corners
    overflow: "hidden", // Prevent overflow of shadow
  };

  const totalRowStyle = {
    backgroundColor: "#ffffff", // White background
    fontWeight: "bold", // Bold text
  };

  return (
    <div style={tableContainerStyle}>
      {/* Table Header */}
      <div style={headerStyle}>
        <TitleWithLine title={`ENS Students Cases ${title}`} />
      </div>

      {/* Table Component */}
      <Table
        columns={columns}
        dataSource={filteredTableData}
        pagination={false} // Disable pagination for simplicity
        bordered
        size="small"
        rowKey="school"
        rowClassName={(record) =>
          record.school === "Total" ? "total-row" : ""
        }
        components={{
          body: {
            row: ({ children, ...restProps }) => (
              <tr
                {...restProps}
                style={
                  restProps["data-row-key"] === "Total" ? totalRowStyle : {}
                }
              >
                {children}
              </tr>
            ),
          },
        }}
      />
    </div>
  );
};

export default CampusesCaseTable;
