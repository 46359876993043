import { addSyncJobAPI } from "apis/sync-jobs-api/sync-jobs-api";
import { searchSyncJobAPI } from "apis/sync-jobs-api/sync-jobs-api";
import { deleteSyncJobAPI } from "apis/sync-jobs-api/sync-jobs-api";
import { editSyncJobAPI } from "apis/sync-jobs-api/sync-jobs-api";
import { getSyncJobsAPI } from "apis/sync-jobs-api/sync-jobs-api";
import { addUserAPI } from "apis/user-apis/user-api";
import { editUserAPI } from "apis/user-apis/user-api";
import { deleteUserApi } from "apis/user-apis/user-api";
import { searchUsersAPI } from "apis/user-apis/user-api";
import { getCashiersAPI } from "apis/user-apis/user-api";
import { getUsersAPI } from "apis/user-apis/user-api";
import { SyncJobActionKey } from "utils/constants";

import { UserActionKey } from "utils/constants";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";

export const getAllSyncJobs = (page) => {
  return (dispatch) => {
    dispatch({ type: SyncJobActionKey.getSyncJobsReq });
    getSyncJobsAPI(page)
      .then((res) => {
        dispatch({
          type: SyncJobActionKey.getSyncJobsSuccess,
          payload: res.data,
        });
      })
      .catch(async (e) => {
        dispatch({ type: SyncJobActionKey.getSyncJobsFailure });
        await requestErrorHandel({ error: e });
      });
  };
};
export const searchSyncJobAction = (search) => {
  return (dispatch) => {
    dispatch({ type: SyncJobActionKey.searchSyncJobsReq });
    searchSyncJobAPI(search)
      .then((res) => {
        dispatch({
          type: SyncJobActionKey.searchSyncJobsSuccess,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({ type: SyncJobActionKey.searchSyncJobsFailure });
        requestErrorHandel({ error: e });
      });
  };
};
export const addSyncJobAction = (item, onSuccess, onFailure) => {
  return (dispatch) => {
    dispatch({ type: SyncJobActionKey.addSyncJobReq });
    addSyncJobAPI(item)
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({ type: SyncJobActionKey.addSyncJobSuccess, payload: item });
          onSuccess && onSuccess();
          successMessage({ message: res.data?.message || "SyncJob Added" });
        } else throw res.data;
      })
      .catch(async (e) => {
        onFailure && onFailure();
        dispatch({ type: SyncJobActionKey.addSyncJobFailure });
        await requestErrorHandel({ error: e });
      });
  };
};
export const updateSyncJobAction = (item, onSuccess, onFailure) => {
  return (dispatch) => {
    dispatch({ type: SyncJobActionKey.editSyncJobReq });
    editSyncJobAPI(item)
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({
            type: SyncJobActionKey.editSyncJobSuccess,
            payload: res.data.data,
          });
          onSuccess && onSuccess();
          successMessage({ message: res.data?.message || "SyncJob Updated" });
        } else throw res.data;
      })
      .catch(async (e) => {
        dispatch({ type: SyncJobActionKey.editSyncJobFailure });
        await requestErrorHandel({ error: e });
        onFailure && onFailure();
      });
  };
};
export const deleteSyncJobAction = (id) => {
  return (dispatch) => {
    dispatch({ type: SyncJobActionKey.deleteSyncJobReq });
    deleteSyncJobAPI(id)
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({ type: SyncJobActionKey.deleteSyncJobSuccess, payload: id });
          successMessage({ message: res.data?.message || "SyncJob Deleted" });
        } else throw res.data;
      })
      .catch(async (e) => {
        dispatch({ type: SyncJobActionKey.deleteSyncJobFailure });
        await requestErrorHandel({ error: e });
      });
  };
};

export const getCashierAction = (page, cashierParams) => {
  return; 
};
