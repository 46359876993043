import React, { useState } from "react";
import "./style.css";
import { Form, Input, Checkbox, Button, Row, Col, Select, Card } from "antd";
import checkedImg from "../../../assets/img/checked.png";
import uncheckedImg from "../../../assets/img/unchecked.png";
import { useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { formatedDate } from "utils/helpers/helpers";
import { getFullDayName } from "utils/helpers/helpers";
import { formatedDateWithAmAndPm } from "utils/helpers/helpers";
import CustomModal from "components/CustomModal/CustomModal";
import SchduleDetailInformation from "views/FSM/facility-mangement/fsm-application-detail-header/SchduleDetailInformation";
const { Option } = Select;

const FSMDynamicInputReadOnlyFields = ({
  formName,
  fields,
  schedules,
  onSubmit,
  formRef,
  bookingType,
  booking,
}) => {
  const FSM_activityTypes = useSelector(
    (state) => state.metaData.FSM_activityTypes
  );
  const [showAttendees, setShowAttendees] = useState(false);
  const [attendees, setAttendees] = useState([]);

  const uniqueTimeSlotsSet = new Set();
  schedules[0].details.forEach((item) => {
    const { startTime, endTime } = item;
    const timeSlot = `${startTime}-${endTime}`;
    uniqueTimeSlotsSet.add(timeSlot);
  });

  // Convert the set to an array of objects with start and end times
  const uniqueTimeSlotsArray = [...uniqueTimeSlotsSet].map((timeSlot) => {
    const [startTime, endTime] = timeSlot.split("-");
    return { startTime, endTime };
  });

  const details = schedules[0]?.details;

  const uniqueDays =
    details && details.length
      ? [...new Set(details.map((item) => item.dayOfWeek))].filter(Boolean)
      : [];

  const onHandleViewAll = (values) => {
    setAttendees(values);
    setShowAttendees(true);
  };

  const formatName = (name, maxLength) => {
    if (name.length <= maxLength) {
      return name;
    } else {
      const truncatedName = name.substring(0, maxLength - 3) + "...";
      return truncatedName;
    }
  };
  return (
    <React.Fragment>
      <Form onFinish={onSubmit} form={formName} ref={formRef}>
        <Row className="form_item_row" gutter={[20, 20]}>
          {fields?.map((field, index) => {
            return (
              <Col md={8} key={index}>
                <p className="form_item_row_lable">{field?.title}</p>
                {field.name === "activityType" ? (
                  <Form.Item>
                    <Select
                      disabled={true}
                      value={
                        FSM_activityTypes?.find(
                          (el) => el.id === parseInt(field.value)
                        )?.title || "" // Convert field.value to number
                      } // Set the value attribute to the field value
                    >
                      {FSM_activityTypes?.map((el, i) => (
                        <Option key={el.id} value={el.id}>
                          {el.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <TextArea
                      placeholder="Add here"
                      type="text"
                      disabled={true}
                      value={field.value || ""} // Assuming fieldValue for other fields
                    />
                  </Form.Item>
                )}
              </Col>
            );
          })}
        </Row>

        <Card>
          {schedules?.map((schedule, index) => {
            return (
              <Row className="compactFormItemRow" key={index}>
                <Col md={8}>
                  <b>Ref#: {schedule.title}</b>
                  <br />
                  <br />
                  <Row>
                    <Col md={24}>
                      <p>Activity Type: {schedule?.activityType?.title}</p>
                      <p>
                        Schedule Type:{" "}
                        {schedule?.scheduleType === "slots"
                          ? "Slots"
                          : "Full Day"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <p>Available Capacity: {booking?.facility?.capacity}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <p>Number of People: {schedule?.noOfAttendees}</p>
                    </Col>
                  </Row>
                  <Form.Item>
                    {/* check if need to show start date, end date or just date */}
                    {bookingType?.identifier === "LTB" ? (
                      <>
                        <Row>
                          <Col md={24}>
                            <p>
                              Start Date: {""}
                              {formatedDate(schedule?.startAt)}
                            </p>
                          </Col>
                          <Col md={24}>
                            <p>
                              End Date: {""}
                              {formatedDate(schedule?.endAt)}
                            </p>
                          </Col>
                        </Row>

                        {/* show days */}
                        {uniqueDays.length > 0 && (
                          <>
                            <br />
                            <b>Days Of Week</b>
                            <Row>
                              {uniqueDays.map((dayTitle, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <Col md={6}>
                                      <div className="schedule_date_container">
                                        <p className="schedule_date">
                                          {dayTitle && getFullDayName(dayTitle)}
                                        </p>
                                      </div>
                                    </Col>
                                  </React.Fragment>
                                );
                              })}
                            </Row>
                          </>
                        )}
                      </>
                    ) : (
                      <Row>
                        <Col md={24}>
                          <p>Event Date: {formatedDate(schedule?.startAt)}</p>
                        </Col>
                      </Row>
                    )}

                    {schedule?.scheduleType === "slots" ? (
                      <React.Fragment>
                        <br />

                        <b>Time Slots</b>
                        {uniqueTimeSlotsArray.map((slot, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Row>
                                <Col md={12}>
                                  <div className="schedule_date_container">
                                    <span>Start Time: {""}</span>
                                    <p className="schedule_date">
                                      {formatedDateWithAmAndPm(slot?.startTime)}
                                    </p>
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div className="schedule_date_container end">
                                    <span>End Time: {""} </span>
                                    <p className="schedule_date">
                                      {formatedDateWithAmAndPm(slot?.endTime)}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    ) : null}
                    {schedule?.attendeesNames.length > 0 && (
                      <>
                        <br />
                        <b>Attendees</b>
                        <Row align={"middle"}>
                          {schedule?.attendeesNames
                            .slice(0, 10)
                            .map((attendee, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Col md={6}>
                                    <div className="schedule_date_container">
                                      <p
                                        className="schedule_date"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {attendee && formatName(attendee, 10)}
                                      </p>
                                    </div>
                                  </Col>
                                </React.Fragment>
                              );
                            })}
                          {schedule?.attendeesNames?.length > 10 && (
                            <div style={{ marginLeft: 10, marginTop: 5 }}>
                              <div className="field_value">
                                <span
                                  className="view_all"
                                  onClick={() =>
                                    onHandleViewAll(schedule?.attendeesNames)
                                  }
                                >
                                  view all
                                </span>
                              </div>
                            </div>
                          )}
                        </Row>
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            );
          })}{" "}
        </Card>

        <Button htmlType="submit" />
      </Form>
      <Card>
        <SchduleDetailInformation booking={booking} />
      </Card>

      <CustomModal
        visibility={showAttendees}
        handleCancel={() => setShowAttendees(false)}
        handleOk={() => null}
        title={"Attendees"}
      >
        <div className="attendee__container">
          <Row gutter={20}>
            {attendees.map((attendee, index) => {
              return (
                <Col md={12} key={index}>
                  <p
                    className="attendee__title"
                    style={{ textTransform: "capitalize" }}
                  >
                    {attendee}
                  </p>
                </Col>
              );
            })}
          </Row>
        </div>
      </CustomModal>
    </React.Fragment>
  );
};

export default FSMDynamicInputReadOnlyFields;
