import CommonTable from "components/Tables/CommonTable";
import moment from "moment";
import React from "react";
import { Capitalize } from "utils/helpers/helpers";

const DiscountOtherApplication = (props) => {
  return (
    <CommonTable
      data={props.data}
      header={() => <h5>Other Discount Applications</h5>}
      columns={[
        {
          title: "ID",
          dataIndex: "",
          key: "title",
          render: (record) =>
            record?.title !== "" ? record?.title : record?.id,
        },
        {
          title: "Service",
          dataIndex: "eService",
          key: "eService",
          render: (record) => Capitalize(record.name || "N/A"),
        },
        {
          title: "Rule",
          dataIndex: "discountRule",
          key: "id",
          render: (record) => record.title,
        },
        {
          title: "Students",
          dataIndex: "students",
          key: "students",
          render: (record) =>
            record.length > 0
              ? record.map((std, index) => (
                  <>
                    <span style={{ color: "#007575" }}>
                      {std.studentNumber}
                    </span>
                    <br />
                    {std.name}

                    <br />
                    <br />
                  </>
                ))
              : "N/A",
        },
        {
          title: "Current Stage Status",
          dataIndex: "",
          key: "id",
          render: (record) => record.stage.status.internalStatus,
        },
        {
          title: "Submitted Date",
          dataIndex: "",
          key: "created_at",
          render: (record) => moment(record.createdAt).format("MMMM Do YYYY"),
        },
        {
          title: "Current Stage Status",
          dataIndex: "",
          key: "record_status",
          render: (record) => record.stage.status.internalStatus,
        },
      ]}
    ></CommonTable>
  );
};
export default DiscountOtherApplication;
