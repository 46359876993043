import React from "react";
import "./style.css";

const FSMScheduleTabs = ({ selectedTab, onTab1, onTab2 }) => {
  return (
    <div className="fsm__tabs__container">
      <div
        onClick={onTab1}
        style={{
          borderBottomColor: selectedTab === 0 ? "#007B85" : "transparent",
        }}
        className="fsm_t_details"
      >
        <p
          style={{
            color: selectedTab === 0 ? "#007B85" : "black",
            fontSize: 14,
          }}
        >
          Open Schedules
        </p>
      </div>
      <div
        onClick={onTab2}
        style={{
          borderBottomColor: selectedTab === 1 ? "#007B85" : "transparent",
        }}
        className="fsm_t_logs"
      >
        <p
          style={{
            color: selectedTab === 1 ? "#007B85" : "black",
            fontSize: 14,
          }}
        >
          Block Schedules
        </p>
      </div>
    </div>
  );
};

export default FSMScheduleTabs;
