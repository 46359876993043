import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Form, Popconfirm, Popover, Row, Spin, Tooltip } from "antd";
import CustomModal from "components/CustomModal/CustomModal";
import EServicesForm from "components/e-services/EServicesForm";
import moment from "moment";
import CommonTable from "components/Tables/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import { getEServicesAPI } from "apis/e-services-api/EServicesApi";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
import { requestErrorHandel } from "utils/helpers/helpers";
import { addEServicesAPI } from "apis/e-services-api/EServicesApi";
import { _addEServicesAction } from "actions/eservices/eServicesAction";
import { updateEServicesAPI } from "apis/e-services-api/EServicesApi";
import { _updateEServicesAction } from "actions/eservices/eServicesAction";
import { deleteEServicesAPI } from "apis/e-services-api/EServicesApi";
import { _deleteEServicesAction } from "actions/eservices/eServicesAction";
import { renderLoader } from "utils/helpers/helpers";
import { NotificationManager } from "react-notifications";
import { convertToTitleCase } from "utils/helpers/helpers";
import { fetchAllRewards } from "apis/SCH/schRequests";
import { fetchAllTemplates } from "apis/templates/templatesApis";
import { TCCConfigRoutes } from "routes/ApplicationTypeRoutes";

function Services(props) {
  const [form] = Form.useForm();
  const [visibility, setVisibility] = useState(false);
  const [formLayout] = useState("vertical");
  const [componentSize] = useState("large");
  const [editApplicationFields, setEditApplicationFiels] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const _eservices = useSelector((state) => state.eservices.data);
  const _grades = useSelector((state) => state.metaData.grades);
  const _scholarshipTypes = useSelector(
    (state) => state.metaData.dropDowns?.scholarshipTypes
  );
  const _totalRecords = useSelector((state) => state.eservices.total);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [availableCampuses, setAvailableCampuses] = React.useState([]);
  const [availableGrades, setAvailableGrades] = React.useState([]);
  const [allowedStatuses, setAllowedStatuses] = React.useState([]);
  const [showHideNote, setShowHideNote] = React.useState(false);
  const [allowedTCCGrades, setAllowedTCCGrades] = useState([]);
  const [rewardsData, setRewardsData] = useState([]);
  const [tempplatesData, setTempplatesData] = useState([]);
  const [applicableEvaluation, setApplicableEvaluation] = React.useState([]);
  const [visibleEvaluationfields, setVisibleEvaluationfields] = React.useState(
    []
  );

  useEffect(() => {
    getAllRewards();
    getAllTemplates();
  }, []);

  const getAllRewards = async () => {
    try {
      const response = await fetchAllRewards(1000);
      if (response.status === 200) {
        setRewardsData(response.data.data);
      }
    } catch (error) {
      requestErrorHandel({ error: error });
    }
  };
  const getAllTemplates = async () => {
    try {
      setLoading(true);
      const response = await fetchAllTemplates(10000);
      if (response.status === 200) {
        setTempplatesData(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  useEffect(() => {
    fetchEServices();
  }, [currentPage]);

  useEffect(() => {
    if (editApplicationFields) {
      form.setFieldsValue(editApplicationFields);
    } else {
      form.resetFields();
    }
  }, [editApplicationFields]);

  const fetchEServices = async () => {
    try {
      setLoading(true);
      const paginationParams = `page=${currentPage}&limit=${pageSize}`;
      const eServiceResponse = await getEServicesAPI(paginationParams);
      if (eServiceResponse.data && eServiceResponse.status === 200) {
        dispatch(_getEServicesAction(eServiceResponse.data));
        setLoading(false);
      } else throw eServiceResponse.error;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  const edit = (record) => {
    setVisibility(true);

    const fieldsToUpdate = record?.rewardsConfig.reduce((acc, seat) => {
      const fieldName = `totalSeats_${seat.scholarshipTypeId}_${seat.id}`;
      return {
        ...acc,
        [fieldName]: seat.totalSeats,
      };
    }, {});

    const initialValues = record?.allowedGradeCourses?.reduce(
      (acc, gradeCourse) => {
        const { id, mainCourses, alternativeCourses } = gradeCourse;
        acc[`gradeId_${id}`] = undefined;
        acc[`mainCourses_${id}`] = String(mainCourses);
        acc[`alternativeCourses_${id}`] = String(alternativeCourses);
        return acc;
      },
      {}
    );

    setEditApplicationFiels({
      ...record,
      startAt: record.startAt ? moment(record.startAt) : undefined,
      endAt: record.endAt ? moment(record.endAt) : undefined,
      term: record.termId,
      terms: record.terms,
      termsAr: record.termsAr,
      guidelines: record.guidelines,
      guidelinesAr: record.guidelinesAr,
      itemTypes:
        record?.itemTypes?.length > 0 ? record.itemTypes[0] : undefined,
      ...fieldsToUpdate,
      ...initialValues,
    });
  };
  const handleCancel = () => {
    setVisibility(false);
    setEditApplicationFiels(null);
  };

  const onSubmitEService = async (value) => {
    let allowedGradeCourses = [];
    if (value.applicationType === "TCC") {
      const gradeIds = Object.keys(value)
        .filter((key) => key.startsWith("gradeId"))
        .map((key) => parseInt(key.split("_")[1]));

      // Create the allowedGradeCourses array
      allowedGradeCourses = gradeIds.map((gradeId) => ({
        gradeId,
        mainCourses: parseInt(value[`mainCourses_${gradeId}`]),
        alternativeCourses: parseInt(value[`alternativeCourses_${gradeId}`]),
      }));
    }

    const rewardsArray = [];

    for (const key in value) {
      if (key.startsWith("totalSeats_")) {
        const scholarshipTypeId = parseInt(key.split("_")[1]);
        const rewardId = parseInt(key.split("_")[2]);
        const totalSeats = parseInt(value[key]);
        rewardsArray.push({
          rewardId,
          totalSeats: totalSeats ? totalSeats : 0,
          scholarshipTypeId,
        });
      }
    }

    const params = {
      ...value,
      availableCampuses: availableCampuses.map((param) => param.id),
      availableGrades: availableGrades.map((param) => param.id),
      allowNewApplicationOnStatuses: allowedStatuses.map((param) => param.code),
      allowAmountUpdate: value.allowAmountUpdate ? true : false,
      itemTypes: value.itemTypes ? [value.itemTypes] : undefined,
      rewards: rewardsArray,
      allowedGradeCourses: allowedGradeCourses,
      templateId: Number(value.templateId),
      hideNote: showHideNote,
      applicableEvaluations: applicableEvaluation.map((appEva) => appEva.value),
      visibleFieldsForEvaluations: visibleEvaluationfields.map(
        (appEva) => appEva.value
      ),
    };

    try {
      setLoading(true);
      if (editApplicationFields) {
        const updateEServiceResponse = await updateEServicesAPI(
          value.id,
          params
        );
        if (
          updateEServiceResponse.status === 200 &&
          updateEServiceResponse.data
        ) {
          dispatch(_updateEServicesAction(updateEServiceResponse.data.data));
          setLoading(false);
          //form.resetFields();
          //setEditApplicationFiels(null);
          NotificationManager.success("E-Service updated successfully");
        } else throw updateEServiceResponse.error;
      } else {
        const addEServiceResponse = await addEServicesAPI(params);
        if (addEServiceResponse.status === 200 && addEServiceResponse.data) {
          dispatch(_addEServicesAction(addEServiceResponse.data.data));
          setLoading(false);
          // form.resetFields();
          NotificationManager.success("E-Service added successfully");
        } else throw addEServiceResponse.error;
      }
      setVisibility(false);
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    }
  };
  const addEservice = () => {
    setVisibility(true);
    form.resetFields();
    setEditApplicationFiels(null);
  };
  const deleteEService = async (record) => {
    try {
      setLoading(false);
      const deleteEServiceResponse = await deleteEServicesAPI(record.id);
      if (deleteEServiceResponse.status === 200) {
        dispatch(_deleteEServicesAction(record.id));
        setLoading(false);
        NotificationManager.success("E-Service deleted successfully");
      } else throw deleteEServiceResponse.error;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };
  const openConfigurations = (record) => {
    props.history.push({
      pathname: `/admin/e-services/configuration`,
      state: record,
    });
  };

  const renderRoutes = (record, routes) => {
    return (
      <div className="menu-list">
        {routes.map((el) => {
          if (el.title === "Sections") {
            return (
              <Row
                justify="space-between"
                align="middle"
                className="menu-links"
                onClick={() =>
                  props.history.push({
                    pathname: el.path,
                    state: { serviceName: record },
                  })
                }
              >
                <p>{el.title}</p>
                <RightOutlined className="menu-links-icon" />
              </Row>
            );
          } else {
            return (
              <Row
                justify="space-between"
                align="middle"
                className="menu-links"
                onClick={() => openConfigurations(record)}
              >
                <p>{el.title}</p>
                <RightOutlined className="menu-links-icon" />
              </Row>
            );
          }
        })}
      </div>
    );
  };
  const getMenuContent = (record) => {
    return renderRoutes(record, TCCConfigRoutes);
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <Row justify="end">
          <Button
            type="primary"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              borderRadius: "5px",
            }}
            onClick={addEservice}
          >
            Add E-Service
          </Button>
        </Row>
        <CommonTable
          data={_eservices}
          columns={[
            {
              title: "Service",
              dataIndex: "name",
              key: "id",
            },
            {
              title: "Request Type",
              dataIndex: "applicationType",
              key: "id",
            },
            {
              title: "Category",
              dataIndex: "type",
              key: "id",
              render: (record) => convertToTitleCase(record),
            },
            {
              title: "Auto Approval",
              dataIndex: "autoApproval",
              key: "autoApproval",
              render: (record) => (record ? "Enabled" : "Disabled"),
            },
            {
              title: "Term",
              dataIndex: "term",
              key: "id",
            },
            {
              title: "Start Date",
              dataIndex: "startAt",
              key: "id",
              render: (record) => moment(record).format("YYYY-MM-DD"),
            },
            {
              title: "End Date",
              dataIndex: "endAt",
              key: "id",
              render: (record) => moment(record).format("YYYY-MM-DD"),
            },

            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: (record) => {
                return (
                  <div style={{ display: "flex", gap: 10 }} className="d-flex">
                    <Tooltip title="Update E-Service">
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        shape="circle"
                        icon={<EditOutlined />}
                        // onClick={() => edit(item)}
                        onClick={() => edit(record)}
                      />
                    </Tooltip>
                    <Popconfirm
                      title="Are you sure you want to delete this service?"
                      okText="Yes"
                      cancelText="No"
                      style={{ marginLeft: "30px" }}
                      onConfirm={() => deleteEService(record)}
                    >
                      <Tooltip title="Delete">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                    {(record.applicationType === "TFD" || record.allowGuest) &&
                    record.applicationType !== "TCC" &&
                    record.applicationType !==
                      "SCH" ? null : record.applicationType == "TCC" ? (
                      <Popover
                        content={getMenuContent(record)}
                        placement={"right"}
                      >
                        <Tooltip>
                          <Button
                            className="d-flex justify-content-center align-items-center"
                            shape="circle"
                            icon={<SettingOutlined />}
                            // onClick={() => openConfigurations(record)}
                          />
                        </Tooltip>
                      </Popover>
                    ) : (
                      <Tooltip title="Configure E-Service">
                        <Button
                          className="d-flex justify-content-center align-items-center"
                          shape="circle"
                          icon={<SettingOutlined />}
                          onClick={() => openConfigurations(record)}
                        />
                      </Tooltip>
                    )}
                  </div>
                );
              },
            },
          ]}
          fetchRecords={(page) => setCurrentPage(page)}
          totalRecord={_totalRecords}
          pageSize={pageSize}
        />
        <CustomModal
          visibility={visibility}
          title={!editApplicationFields ? "Add E-Service" : "Update E-Service"}
          handleCancel={handleCancel}
          handleOk={handleCancel}
        >
          <Form
            layout={formLayout}
            form={form}
            initialValues={{ layout: formLayout }}
            size={componentSize}
            onFinish={onSubmitEService}
            // selectedGrades={selectedGrades}
          >
            <EServicesForm
              form={form}
              valuesForEdit={editApplicationFields}
              loading={loading}
              isVisible={visibility}
              setAvailableCampuses={setAvailableCampuses}
              setAvailableGrades={setAvailableGrades}
              setAllowedStatuses={setAllowedStatuses}
              setApplicableEvaluation={setApplicableEvaluation}
              setVisibleEvaluationfields={setVisibleEvaluationfields}
              rewardsData={rewardsData}
              _scholarshipTypes={_scholarshipTypes}
              setAllowedTCCGrades={setAllowedTCCGrades}
              tempplatesData={tempplatesData}
              setShowHideNote={(val) => setShowHideNote(val)}
            />
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
}
export default Services;
