/* eslint-disable no-unused-vars */
import { Col, Row, Form, Input, Select, Button } from "antd";
import React from "react";

const { Option } = Select;

const TranscriptReviewForm = ({ onFinish, loading }) => {
  const [transcriptRecommendationList, setTranscriptRecommendationList] =
    React.useState([
      {
        id: "1",
        title: "Recommended",
        value: true,
      },
      {
        id: "2",
        title: " Not Recommended",
        value: false,
      },
    ]);
  const [transcriptRemaktsForm] = Form.useForm();
  return (
    <Form layout="vertical" form={transcriptRemaktsForm} onFinish={onFinish}>
      <Row justify="space-between">
        <Col lg={24}>
          <Form.Item
            label="Recommended"
            name="isApproved"
            rules={[
              {
                required: true,
                message: "Recommendation is required",
              },
            ]}
          >
            <Select>
              {transcriptRecommendationList.map((el, index) => (
                <Option value={el.value}>{el.title}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item
            className="transcript_note"
            label="Notes"
            name="notes"
            rules={[
              {
                required: true,
                message: "Notes are required",
                whitespace: true,
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
      <Button loading={loading} type="primary" htmlType="submit">
        {loading ? "Submitting..." : "Submit"}
      </Button>
    </Form>
  );
};
export default TranscriptReviewForm;
