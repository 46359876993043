/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Card, Col, Button, Select, Form, Tabs, Spin } from "antd";

// import styles from "../../styles/applicationDetails.module.css";
import styles from "../../../../styles/applicationDetails.module.css";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import CustomModal from "components/CustomModal/CustomModal";
import SchedulingInterviewForm from "components/scheduleInterviewModal/SchedulingInterviewForm";
import AddRemarks from "components/scheduleInterviewModal/AddRemarks";
import { scheduleInterviewForObservation } from "apis/schedule-interview/schedule-interview";
import { requestErrorHandel } from "utils/helpers/helpers";
import { successMessage } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import { addRemarksForInterview } from "apis/schedule-interview/schedule-interview";
import { convertToTitleCase } from "utils/helpers/helpers";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import { _updateApplications } from "actions/applications/applications-actions";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import moment from "moment";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import { useSelector } from "react-redux";

const { Option } = Select;
const { TabPane } = Tabs;

function ObservationDetails(props) {
  const [visibility, setVisibility] = React.useState(false);
  const [visibilityRemarks, setVisibilityRemarks] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const applicationId = props?.application.id;

  const [scheduleObservationForm] = Form.useForm();
  const [addRemarksForm] = Form.useForm();

  React.useEffect(() => {
    props.application && getLogs();
  }, [props.application]);

  const getLogs = async () => {
    try {
      const response = await getApplicationStagesLogs({ id: applicationId });
      if (response.status == 200) {
        var applicationCurrentStage = currentStageDetails;
        if (
          applicationCurrentStage == undefined ||
          applicationCurrentStage == ""
        ) {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == props.application.stage.slug
          );
        } else {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == currentStageDetails.stageSlug
          );
        }
        setcurrentStageDetails(applicationCurrentStage);
      }
    } catch (error) {
      // await requestErrorHandel({ error: error });
    }
  };
  const handleCancel = () => {
    setVisibility(false);
  };
  const handleOk = () => {
    // const oldScheduleObj = [...scheduleObj];
    // const data = {
    //   id: "1",
    //   interView: "inter devoe",
    // };
    // setScheduleObj([data, ...oldScheduleObj]);
    setVisibility(false);
  };
  const goTo = () => {
    setVisibility(true);
  };

  const addRemarks = () => {
    setVisibilityRemarks(true);
  };
  const addRemarksHandler = () => {
    // const oldRemarksObj = [...remarksObj];
    // const data = {
    //   id: "1",
    // };
    // setRemarkseObj([data, ...oldRemarksObj]);
    setVisibilityRemarks(false);
  };
  const onFinishObsrvationScheduleForm = async (value) => {
    try {
      setLoading(true);
      value["applicationId"] = props.application.id;
      value["type"] = props.application.stage.slug;
      const response = await scheduleInterviewForObservation({ data: value });
      if (response.status == 200) {
        setLoading(false);
        setVisibility(false);
        props.refetch();
        getLogs();
        successMessage({ message: "Observation scheduled successfully" });
      }
    } catch (error) {
      requestErrorHandel({ error: error });
      setLoading(false);
    }
  };

  const onFinishRemarks = async (value) => {
    try {
      setLoading(true);
      value["scheduleId"] = currentStageDetails?.schedules[0].id;
      const response = await addRemarksForInterview({ data: value });
      if (response.status == 200) {
        setVisibilityRemarks(false);
        setLoading(false);
        // getDetails();
        props.refetch();
        getLogs();
        successMessage({ message: "Remarks added successfully" });
        // dispatch(_updateApplications(applicationId, response.data.data));
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const renderScheduleObservationModal = () => {
    return (
      <CustomModal
        visibility={visibility}
        handleCancel={handleCancel}
        handleOk={handleOk}
        title={"Schedule Observation"}
      >
        <Form
          size="large"
          name="scheduleObservationForm"
          form={scheduleObservationForm}
          onFinish={onFinishObsrvationScheduleForm}
          layout={"vertical"}
        >
          <SchedulingInterviewForm handleOk={handleOk} loading={loading} />
        </Form>
      </CustomModal>
    );
  };
  const onReschedule = () => {
    const schedule = currentStageDetails?.schedules[0];
    if (schedule) {
      scheduleObservationForm.setFieldsValue({
        dateTime: moment(schedule.scheduleTime),
        notes: schedule.notes,
        interviewType: schedule.scheduleType,
        address: schedule.address,
      });
    }

    setVisibility(true);
  };
  const renderObservationDetails = () => {
    if (!props.application.hasStagePermission) return null;
    return currentStageDetails?.schedules?.length > 0 ? (
      <Card
        hoverable
        className="antCardBorder antCard"
        style={{ marginTop: 20 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div>
              <h5>Observation Details</h5>
            </div>
            <div>
              <p>
                Date:{" "}
                {formateDateWithMoment(
                  currentStageDetails?.schedules[0].scheduleTime,
                  "DD MMM YYYY hh:mm A"
                )}
              </p>
              <p>
                Type:{" "}
                {convertToTitleCase(
                  currentStageDetails?.schedules[0].scheduleType
                )}
              </p>
              {currentStageDetails?.schedules[0].scheduleType !== "online" ? (
                <p>Instructions: {currentStageDetails?.schedules[0].address}</p>
              ) : null}
              <p style={{ overflowX: "clip" }}>
                Notes: {currentStageDetails?.schedules[0].notes}
              </p>
            </div>
          </div>
          {currentStageDetails?.stageStatus?.code === "SG022" &&
          (props.application?.hasSchedulePermission || ifSuperAdmin) ? (
            <div>
              <Button
                type="primary"
                className="appButton"
                onClick={onReschedule}
              >
                Reschedule
              </Button>
            </div>
          ) : null}
        </div>
      </Card>
    ) : null;
  };
  const renderRemarksDetails = () => {
    if (!props.application.hasStagePermission) return null;
    return currentStageDetails?.schedules?.length > 0 ? (
      <Card
        hoverable
        className="antCardBorder antCard"
        style={{ marginTop: 20 }}
      >
        <h6>Final Remarks</h6>
        <h4>
          {currentStageDetails?.schedules[0].remarks[0].isRecommended
            ? "Recommended"
            : "Not Recommended"}
        </h4>
        <hr />
        <p style={{ overflowX: "clip" }}>
          {currentStageDetails?.schedules[0].remarks[0].notes}
        </p>
      </Card>
    ) : null;
  };

  const renderRemarksModal = () => {
    return (
      <CustomModal
        visibility={visibilityRemarks}
        handleCancel={() => setVisibilityRemarks(false)}
        handleOk={() => setVisibilityRemarks(false)}
        title={"Add Remarks"}
      >
        <Form
          name="addRemarksForm"
          size="large"
          onFinish={onFinishRemarks}
          form={addRemarksForm}
          layout={"vertical"}
        >
          <AddRemarks addRemarksHandler={addRemarksHandler} loading={loading} />
        </Form>
      </CustomModal>
    );
  };

  const getBtnTitle = () => {
    if (currentStageDetails?.stageStatus?.code == "SG021") {
      return "Schedule Observation";
    } else if (currentStageDetails?.stageStatus?.code == "SG022") {
      return "Add Remarks";
    } else {
      return "Completed";
    }
  };

  const getTitle = () => {
    return currentStageDetails?.stageStatus?.internalStatus;
  };

  const getDescription = () => {
    if (currentStageDetails?.stageStatus?.code == "SG021") {
      return "Please schedule observation for this application";
    } else if (currentStageDetails?.stageStatus?.code == "SG022") {
      return "Please add remarks for this application";
    } else {
      return "The stage has been completed successfully";
    }
  };
  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;

  const buttonClickAction = () => {
    if (currentStageDetails?.stageStatus?.code == "SG021") {
      setVisibility(true);
    } else if (currentStageDetails?.stageStatus?.code == "SG022") {
      addRemarks();
    } else {
      return;
    }
  };
  const ifSuperAdmin = useSelector(
    (state) => state.login.userInfo?.role === "super-admin"
  );
  const hasActionsPermision =
    ifSuperAdmin ||
    (props.application.hasStagePermission &&
      ((props.application?.hasSchedulePermission &&
        currentStageDetails?.stageStatus?.code === "SG021") ||
        (props.application?.hasRemarksPermission &&
          currentStageDetails?.stageStatus?.code === "SG022")))
      ? true
      : false;

  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={loader}>
        {props.application ? (
          <>
            <Row gutter={[20, 12]} justify="start">
              <Col sm={24} md={hasActionsPermision ? 12 : 24}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>

              {hasActionsPermision && (
                <>
                  <Col lg={12}>
                    <Card hoverable className="antCardBorder antCard">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <h5>{getTitle()}</h5>
                          <p>{getDescription()}</p>
                        </div>
                        <div>
                          <Button
                            type="primary"
                            disabled={
                              currentStageDetails?.stageStatus?.code ===
                                "SG023" || //completed
                              currentStageDetails?.stageStatus?.code === "SG024" //cancelled
                            }
                            onClick={buttonClickAction}
                          >
                            {getBtnTitle()}
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
            {/* if completed, it measn remarks are done */}
            {currentStageDetails?.stageStatus?.code === "SG023"
              ? renderRemarksDetails()
              : null}

            {/* if remarks needed, it measn interview scheduling is done */}
            {currentStageDetails?.stageStatus?.code === "SG022" ||
            currentStageDetails?.stageStatus?.code === "SG023"
              ? renderObservationDetails()
              : null}

            <Tabs className="applicationDetailsTab" defaultActiveKey="1">
              <TabPane
                tab={<h5 className={styles.tabTitle}>Father Information</h5>}
                key="1"
              >
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"father"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Mother Information</h5>}
                key="2"
              >
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"mother"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Child Information</h5>}
                key="3"
              >
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={"child"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Documents</h5>}
                key="4"
              >
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Stages History</h5>}
                key="5"
              >
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>
            {renderScheduleObservationModal()}
            {renderRemarksModal()}
          </>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
}

export default ObservationDetails;
