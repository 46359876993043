/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { Button, Card, Col, Row, Spin, Tabs } from "antd";
import styles from "../../../../styles/applicationDetails.module.css";
import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import PreFinalApprovalForm from "components/Forms/pre-final-approval-form/PreFinalApprovalForm";
import { useDispatch, useSelector } from "react-redux";
import { addStageApproval } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { LoadingOutlined } from "@ant-design/icons";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import { applicationProcess } from "apis/applications-apis/applications";
import { _updateApplications } from "actions/applications/applications-actions";
import { formateDateWithMoment } from "utils/helpers/helpers";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { getApplicationDetails } from "apis/applications-apis/applications";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";

const { TabPane } = Tabs;

const RegistrationFee = (props) => {
  const [showApproveModal, toggleApproveModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const application = props.application;
  const applicationId = props.application?.id;

  React.useEffect(() => {
    if (application) getLogs();
  }, [application]);

  const getLogs = async () => {
    try {
      const response = await getApplicationStagesLogs({ id: applicationId });
      if (response.status == 200) {
        var applicationCurrentStage = currentStageDetails;
        if (
          applicationCurrentStage == undefined ||
          applicationCurrentStage == ""
        ) {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == application.stage.slug
          );
        } else {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == currentStageDetails.stageSlug
          );
        }
        setcurrentStageDetails(applicationCurrentStage);
      }
    } catch (error) {
      await requestErrorHandel({ error: error });
    }
  };

  const handelApplicationProcess = async (action) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: application?.id,
        action: action,
        schoolId: "",
      });
      if (response?.status == 200) {
        // dispatch(_updateApplications(application.id, response.data.data));
        successMessage({ message: "Application status updated successfully" });
        props.refetch();
        getLogs();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  const onApproveClick = (action) => {
    if (currentStageDetails?.stageStatus?.code == "SG060") {
      handelApplicationProcess("integrate_erp");
    } else handelApplicationProcess(action);
  };
  const onSubmitApprove = (values) => {
    //   approve, notes

    toggleApproveModal(false);
    values["appStageId"] = application?.stage.id;
    // setApprove(values.approve?.toUpperCase() || "");
    onAddStagesApproval(values);
  };

  const onAddStagesApproval = async (values) => {
    try {
      setLoading(true);
      const response = await addStageApproval({ data: values });
      if (response.status == 200) {
        successMessage({ message: "Remarks added successfully" });
        getLogs();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const renderRemarksDetails = () => {
    if (!application.hasStagePermission) return null;
    if (!currentStageDetails?.approvals[0]) {
      return (
        <Card
          hoverable
          className="antCardBorder antCard"
          style={{ marginTop: 20 }}
        >
          <h6>Payment Detail</h6>

          <p>
            Registration Fees Date & Time :
            <b>
              {formateDateWithMoment(
                application?.registrationFeeDateTime,
                "DD MMM YYYY hh:mm A"
              )}
            </b>
          </p>
          <p>
            {" "}
            Registration fees Amount :
            <b>{application?.registrationFeeAmount} AED</b>
          </p>
        </Card>
      );
    } else {
      return (
        <Card className="antCardBorder">
          <h6>Final Remarks</h6>
          <h4>
            {currentStageDetails?.approvals[0].isApproved ? "Paid" : "UnPaid"}
          </h4>
          <hr />
          <p style={{ overflowX: "clip" }}>
            {currentStageDetails?.approvals[0].notes}
          </p>
        </Card>
      );
    }
  };

  const getBtnTitle = () => {
    if (currentStageDetails?.stageStatus?.code == "SG056") {
      return "Update Payment";
    } else if (currentStageDetails?.stageStatus?.code == "SG057") {
      return "Paid";
    } else if (currentStageDetails?.stageStatus?.code == "SG060") {
      return "Integrate ERP";
    } else {
      return "";
    }
  };

  const getTitle = () => {
    return currentStageDetails?.stageStatus?.internalStatus;
  };

  const getDescription = () => {
    if (currentStageDetails?.stageStatus?.code == "SG056") {
      return "Please click on the update payment button to update the payment status";
    } else if (currentStageDetails?.stageStatus?.code == "SG057") {
      return "The payment has been completed";
    } else if (currentStageDetails?.stageStatus?.code == "SG060") {
      return "Please integrate ERP";
    } else {
      return "";
    }
  };

  const loader = <LoadingOutlined style={{ fontSize: 45 }} spin />;
  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={loader}>
        {application ? (
          <>
            <Row gutter={[20, 0]}>
              <Col sm={24} md={12}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>
              <Col sm={24} md={12} style={{ width: "100%" }}>
                <Card hoverable className="antCardBorder antCard">
                  <Row align="middle" justify="space-between">
                    <div>
                      <h5>{getTitle()}</h5>
                      <p style={{ fontSize: "12px" }}>{getDescription()}</p>
                    </div>
                    {props.application?.hasStagePermission && (
                      <Button
                        type="primary"
                        className="appButton"
                        onClick={() => onApproveClick("pay_fee")}
                        disabled={
                          currentStageDetails?.stageStatus?.code == "SG057"
                            ? true
                            : false
                        }
                      >
                        {getBtnTitle()}
                      </Button>
                    )}
                  </Row>
                </Card>
              </Col>
            </Row>
            {currentStageDetails?.stageStatus?.code === "SG057"
              ? renderRemarksDetails()
              : null}
            <Tabs className="applicationDetailsTab" defaultActiveKey="1">
              <TabPane
                tab={<h5 className={styles.tabTitle}>Father Information</h5>}
                key="1"
              >
                <div>
                  <ApplicationInformationReadOnlyForm
                    application={application}
                    type={"father"}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Mother Information</h5>}
                key="2"
              >
                <div>
                  <ApplicationInformationReadOnlyForm
                    application={application}
                    type={"mother"}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Child Information</h5>}
                key="3"
              >
                <ApplicationInformationReadOnlyForm
                  application={application}
                  type={"child"}
                />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Documents</h5>}
                key="4"
              >
                <DocumentsReadOnlyForm application={application} />
              </TabPane>
              <TabPane
                tab={<h5 className={styles.tabTitle}>Stages History</h5>}
                key="5"
              >
                <ApplicationLogs application={application} />
              </TabPane>
            </Tabs>
            <CustomModal
              visibility={showApproveModal}
              handleCancel={() => toggleApproveModal(false)}
              title="Registration Fee Approval Remarks"
            >
              <PreFinalApprovalForm
                onFinish={onSubmitApprove}
                loading={loading}
              />
            </CustomModal>
          </>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default RegistrationFee;
