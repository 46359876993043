/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tabs,
} from "antd";
import ApplicationInformationReadOnlyForm from "components/Forms/ApplicationInformationReadOnlyForm";
import { applicationProcess } from "apis/applications-apis/applications";

import * as React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import PreFinalApprovalForm from "components/Forms/pre-final-approval-form/PreFinalApprovalForm";
import { addStageApproval } from "apis/applications-apis/applications";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getApplicationStagesLogs } from "apis/application-stages-logs/application-stages-logs";
import { LoadingOutlined } from "@ant-design/icons";
import { _updateApplications } from "actions/applications/applications-actions";
import ApplicationInfo from "components/resuable/application-details/ApplicationInfo";
import { getApplicationDetails } from "apis/applications-apis/applications";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import DocumentsReadOnlyForm from "components/Forms/DocumentsReadOnlyForm";
import ApplicationLogs from "components/Application-logs/ApplicationLogs";
import { PopconfirmProps } from "utils/constants";
import styles from "styles/applicationDetails.module.css";
import { renderLoader } from "utils/helpers/helpers";
import { shallowEqual, useSelector } from "react-redux";
import DiscountStudents from "components/DiscountComponents/DiscountStudentsTable/DiscountStudentsTable";
import { showValidationError } from "utils/helpers/helpers";
import DiscountOtherApplication from "components/DiscountComponents/DiscountOtherApplications/DiscountOtherApplications";
import UPDStudentTable from "components/UPDStudentsTable/UPDStudentsTable";
import { getDistributionAPI } from "apis/distribution-apis/DistributionApis";
import { _getDistributionAction } from "actions/discount-distributions/DiscountDistributionActions";
import { useDispatch } from "react-redux";

const { TabPane } = Tabs;
const { Option } = Select;

const OtherApplicationsFinalApproval = (props) => {
  const dispatch = useDispatch();
  const [showApproveModal, toggleApproveModal] = React.useState(false);
  const [approvalStatus, setApprove] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [currentStageDetails, setcurrentStageDetails] = React.useState();
  const [activeTab, setActiveTab] = React.useState("details");
  const [sisEnabled, setSISEnabled] = React.useState(false);
  const [erpEnabled, setERPEnabled] = React.useState(false);
  const _distributions = useSelector((state) => state.distributions.data);
  const _otherDiscountApplications = useSelector(
    (state) =>
      state.allApplications?.applications?.filter(
        (el) =>
          el.applicationType === "TFD" &&
          el.id !== props.application.id &&
          el.submittedBy === props.application?.submittedBy &&
          el.eService?.term === props.application?.eService?.term
      ),
    shallowEqual
  );

  const applicationId = props?.application?.id;

  const stageCode = currentStageDetails?.stageStatus?.code;

  React.useEffect(() => {
    if (props.application) {
      getLogs();
      props.application.applicationType === "TFD" && fetchDistributions();
    }
  }, [props.application]);

  const fetchDistributions = async () => {
    try {
      setLoading(true);
      const response = await getDistributionAPI(`page=1&limit=1000`);
      if (response.status == 200 && response.data) {
        dispatch(_getDistributionAction(response.data));
        setLoading(false);
      } else throw response.data;
    } catch (e) {
      requestErrorHandel({ error: e });
      setLoading(false);
    }
  };

  const getLogs = async () => {
    try {
      // setLoading(true);
      const response = await getApplicationStagesLogs({ id: applicationId });

      if (response.status == 200) {
        var applicationCurrentStage = currentStageDetails;
        if (
          applicationCurrentStage == undefined ||
          applicationCurrentStage == ""
        ) {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == props.application.stage.slug
          );
        } else {
          applicationCurrentStage = response.data.data.find(
            (item) => item.stageSlug == currentStageDetails.stageSlug
          );
        }
        setcurrentStageDetails(applicationCurrentStage);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const onApproveClick = async () => {
    if (stageCode == "SG617") {
      const application = await handelApplicationProcess("check_posting");
      if (!application) {
        return;
      }
      if (application?.stage?.status?.code !== "SG618") {
        showValidationError("Posting has not been updated yet.");
      } else {
        successMessage({ message: "Posting updated successfully" });
      }
      return;
    }
    toggleApproveModal(true);
  };
  const onSubmitApprove = (values) => {
    //   approve, notes

    toggleApproveModal(false);
    values["appStageId"] = props.application?.stage.id;
    // setApprove(values.approve?.toUpperCase() || "");
    onAddStagesApproval(values);
  };

  const onAddStagesApproval = async (values) => {
    try {
      setLoading(true);
      const response = await addStageApproval({ data: values });
      if (response.status == 200) {
        successMessage({ message: "Remarks added successfully" });
        props.refetch();
        setLoading(false);
        getLogs();
        // dispatch(_updateApplications(applicationId, response.data.data));
      }
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };

  const renderRemarksDetails = () => {
    if (!props.application.hasStagePermission) return null;
    if (!currentStageDetails?.approvals[0]) return null;
    return (
      <Card
        className="antCardBorder antCard"
        hoverable
        style={{ marginTop: 20 }}
      >
        <h6>Final Remarks</h6>
        <h4>
          {currentStageDetails?.approvals[0].isApproved
            ? "Accepted"
            : "Declined"}
        </h4>
        <hr />
        <p style={{ overflowX: "clip" }}>
          {currentStageDetails?.approvals[0].notes}
        </p>
      </Card>
    );
  };

  const getBtnTitle = () => {
    if (
      stageCode == "SG890" ||
      stageCode == "SG590" ||
      stageCode == "SG290" ||
      stageCode === "SG1190"
    ) {
      // waiting approval
      return "Add Remarks";
    } else if (
      [
        "SG900",
        "SG910",
        "SG300",
        "SG310",
        "SG618",
        "SG1200",
        "SG1210",
      ].includes(stageCode)
    ) {
      // accepted, declined
      return "Completed";
    } else if (stageCode == "SG617") {
      return "Check Posting";
    }
  };

  const getTitle = () => {
    return currentStageDetails?.stageStatus?.internalStatus;
  };

  const getDescription = () => {
    if (
      stageCode == "SG890" ||
      stageCode == "SG290" ||
      stageCode == "SG590" ||
      stageCode === "SG1190"
    ) {
      // waiting approval
      return "Please review the application and add your remarks";
    } else if (
      [
        "SG900",
        "SG910",
        "SG300",
        "SG310",
        "SG318",
        "SG618",
        "SG1200",
        "SG1210",
      ].includes(stageCode)
    ) {
      // accepted, declined
      return "The stage has been completed successfully";
    } else if (
      stageCode == "SG920" ||
      stageCode == "SG320" ||
      stageCode == "SG620" ||
      stageCode === "SG1220"
    ) {
      // cancelled
      return "The stage has been cancelled";
    } else if (stageCode == "SG617") {
      return "Please update posting status";
    } else if (stageCode == "SG600") {
      return "The application is accepted";
    } else if (stageCode == "SG610") {
      return "The application is declined";
    }
  };

  const handelApplicationProcess = async (action, message) => {
    try {
      setLoading(true);
      const response = await applicationProcess({
        applicationId: props.application?.id,
        action: action,
        schoolId: "",
      });
      if (response?.status == 200) {
        props.refetch();
        getLogs();
        message &&
          successMessage({
            message,
          });
        setLoading(false);
        if (action === "check_posting") {
          return response.data?.data;
        }
      } else throw response;
    } catch (error) {
      setLoading(false);
      await requestErrorHandel({ error: error });
    }
  };
  const renderActionButtons = (stageStatus) => {
    if (
      stageStatus === "SG912" ||
      stageStatus === "SG312" ||
      stageStatus === "SG612"
    ) {
      /* Integrate SIS Button */
      /* SG912, SG750, SG612 = SIS INTEGRATION REQUIRED */
      // application is pending and all data is aapproved
      return (
        <div className={styles.updateErpBtnRow}>
          <Popconfirm
            {...PopconfirmProps}
            title={"Are you sure you want to integrate SIS?"}
            onConfirm={() =>
              handelApplicationProcess(
                "integrate_sis",
                "SIS integrated successfully!"
              )
            }
            disabled={
              props.application.studentAlreadyExists ? !sisEnabled : false
            }
          >
            <Button type="primary">Integrate SIS</Button>
          </Popconfirm>
          {props.application.studentAlreadyExists && (
            <Checkbox
              onChange={(e) => setSISEnabled(e.target.checked)}
              value={sisEnabled}
              className={styles.studentExistsChecbox}
            >
              Student Already Exists - Student No:
              {props.application?.studentNumber || ""}
            </Checkbox>
          )}
        </div>
      );
    } else if (
      stageStatus === "SG916" ||
      stageStatus === "SG316" ||
      stageStatus === "SG616"
    ) {
      /* Integrate DMS Button */
      /* SG916, SG770, SG616 = DMS INTEGRATION REQUIRED */
      return (
        <Popconfirm
          {...PopconfirmProps}
          onConfirm={() =>
            handelApplicationProcess(
              "integrate_dms",
              "DMS integrated successfully!"
            )
          }
          title={"Are you sure you want to integrate DMS"}
        >
          <Button type="primary">Integrate DMS</Button>
        </Popconfirm>
      );
    } else if (
      stageStatus === "SG914" ||
      stageStatus === "SG314" ||
      stageStatus === "SG614"
    ) {
      /* Integrate ERP Button */
      return (
        <div className={styles.updateErpBtnRow}>
          <Popconfirm
            {...PopconfirmProps}
            title={"Are you sure you want to integrate ERP"}
            onConfirm={() =>
              handelApplicationProcess(
                "integrate_erp",
                "ERP integrated successfully!"
              )
            }
            disabled={
              props.application.studentAlreadyExists ? !erpEnabled : false
            }
          >
            <Button type="primary">Integrate ERP</Button>
          </Popconfirm>

          {props.application.studentAlreadyExists && (
            <Checkbox
              onChange={(e) => setERPEnabled(e.target.checked)}
              checked={erpEnabled}
              className={styles.studentExistsChecbox}
            >
              Student Already Exists - Student No:
              {props.application?.studentNumber || ""}
            </Checkbox>
          )}
        </div>
      );
    } else if (stageStatus === "SG317") {
      return (
        <Popconfirm
          {...PopconfirmProps}
          title={"Are you sure you want to set items as delivered?"}
          onConfirm={() => {
            handelApplicationProcess(
              "mark_delivered",
              "Application completed successfully"
            );
          }}
        >
          <Button type="primary">Items Delivered</Button>
        </Popconfirm>
      );
    } else {
      return (
        <Button
          type="primary"
          className="appButton"
          onClick={onApproveClick}
          disabled={[
            "SG900",
            "SG910",
            "SG920",
            "SG300",
            "SG310",
            "SG320",
            "SG600",
            "SG610",
            "SG620",
            "SG618",
            "SG1200",
            "SG1210",
            "SG1220",
          ].includes(stageCode)}
        >
          {getBtnTitle()}
        </Button>
      );
    }
  };
  return (
    <div className="both-side-padding">
      <Spin spinning={loading || props.loading} indicator={renderLoader}>
        {props.application ? (
          <div>
            <Row gutter={[20, 12]}>
              <Col sm={24} md={11}>
                <Card hoverable className="antCardBorder antCard">
                  <ApplicationInfo
                    application={props.application}
                    refetch={props.refetch}
                  />
                </Card>
              </Col>

              <Col sm={24} md={13}>
                <Card hoverable className="antCardBorder antCard">
                  <Row align="middle" justify="space-between">
                    <div>
                      <h5>{getTitle()}</h5>
                      <p style={{ fontSize: "12px" }}>{getDescription()}</p>
                    </div>

                    <Row justify="space-between">
                      {props.application.hasStagePermission &&
                        renderActionButtons(
                          props.application?.stage?.status?.code
                        )}
                    </Row>
                  </Row>
                </Card>
              </Col>
            </Row>
            {/* {[
              "SG600",
              "SG610",
              "SG900",
              "SG910",
              "SG920",
              "SG300",
              "SG310",
              "SG320",
            ].includes(stageCode)
              ? renderRemarksDetails()
              : null} */}
            {renderRemarksDetails()}
            <Tabs
              activeKey={activeTab}
              onChange={(tab) => setActiveTab(tab)}
              style={{ marginTop: "1rem" }}
              tabBarGutter={40}
            >
              <TabPane tab={<h6>Details</h6>} key="details">
                <ApplicationInformationReadOnlyForm
                  application={props.application}
                  type={props.application.applicationType?.toLowerCase()}
                />
              </TabPane>
              <TabPane tab={<h6>Documents</h6>} key="documents">
                <DocumentsReadOnlyForm application={props.application} />
              </TabPane>
              {props.application?.applicationType === "ASR" &&
                props.application?.items?.length > 0 && (
                  <TabPane tab={<h6>Items</h6>} key={"items"}>
                    {/* <ASRItemsList items={props.application.items} /> */}
                    <ApplicationInformationReadOnlyForm
                      application={props.application}
                      type={"asr-items"}
                    />
                  </TabPane>
                )}
              {props.application.applicationType === "TFD" && (
                <TabPane tab={<h6>Students</h6>} key="students">
                  <DiscountStudents
                    data={props.application.students}
                    renderDistribution={(record, index) => (
                      <Select
                        style={{ width: "100%" }}
                        value={record.distributionId}
                        disabled
                      >
                        {[
                          ..._distributions,
                          {
                            title: "OPEN",
                            id: 0,
                            instalments: new Array(record.invoices?.length),
                          },
                        ].map((el) => (
                          <Option disabled value={el.id}>
                            {el.title}
                          </Option>
                        ))}
                      </Select>
                    )}
                    readOnly
                  />
                </TabPane>
              )}
              <TabPane tab={<h6>Stages History</h6>} key="logs">
                <ApplicationLogs application={props.application} />
              </TabPane>
            </Tabs>
            {props.application.applicationType === "TFD" && (
              <Card className="antCardBorder antCard" style={{ marginTop: 20 }}>
                <DiscountOtherApplication data={_otherDiscountApplications} />
              </Card>
            )}
            <UPDStudentTable application={props.application} />

            <CustomModal
              visibility={showApproveModal}
              handleCancel={() => toggleApproveModal(false)}
              title="Final Approval Remarks"
            >
              <PreFinalApprovalForm
                onFinish={onSubmitApprove}
                loading={loading}
              />
            </CustomModal>
          </div>
        ) : (
          renderNoDataPlaceholder(loading)
        )}
      </Spin>
    </div>
  );
};
export default OtherApplicationsFinalApproval;
