import { UPDATE_DISTRIBUTIONS } from "actions/discount-distributions/DiscountDistributionActions";
import { DELETE_DISTRIBUTIONS } from "actions/discount-distributions/DiscountDistributionActions";
import { ADD_DISTRIBUTIONS } from "actions/discount-distributions/DiscountDistributionActions";
import { GET_DISTRIBUTIONS } from "actions/discount-distributions/DiscountDistributionActions";

const initialState = {
  data: [],
  dataTotal: 0,
};

const updateHelper = (distribution, allData) => {
  let updatedItemIndex = allData.findIndex(
    (distributionItem) => distributionItem.id === distribution.id
  );
  if (updatedItemIndex > -1) {
    let newData = [...allData];
    newData[updatedItemIndex] = distribution;
    return newData;
  } else {
    return allData;
  }
};

const DiscountDistributionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISTRIBUTIONS:
      return {
        ...state,
        data: action.payload.data,
        dataTotal: action.payload.total,
      };
    case ADD_DISTRIBUTIONS:
      return {
        ...state,
        data: [action.payload, ...state.data],
      };
    case UPDATE_DISTRIBUTIONS:
      return {
        ...state,
        data: updateHelper(action.payload, state.data),
      };
    case DELETE_DISTRIBUTIONS:
      return {
        ...state,
        data: state.data.filter((rule) => rule.id !== action.payload),
        dataTotal: state.dataTotal - 1,
      };
    default:
      return state;
  }
};

export default DiscountDistributionsReducer;
