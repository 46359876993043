/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { FrownOutlined } from "@ant-design/icons";
import { Select, Spin, Card, Tabs, Row } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col } from "reactstrap";
import { requestErrorHandel } from "utils/helpers/helpers";
import { useSelector } from "react-redux";
import DashboardStageStatus from "components/ApplicationStageCardStats/ApplicationStageCardStats";
import DashboardApplicationStatuses from "components/DashboardApplicationStatuses/DashboardApplicationStatuses";
import { _getEServicesAction } from "actions/eservices/eServicesAction";
import { renderNoDataPlaceholder } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import { LOCAL_STORAGE_KEYS } from "utils/constants";

import { getSccConductCasesDashboardStats } from "./api";
import { _months } from "utils/constants";
import { _terms } from "utils/constants";

const { Option } = Select;
const { TabPane } = Tabs;

const SccDashboard = (props) => {
  const _campuses = useSelector((state) => state.metaData.SCC_campus);
  const _years = useSelector((state) => state.metaData.SCC_years);
  const _grades = useSelector((state) => state.metaData.SCC_grades);
  const sccPermission = useSelector(
    (state) => state.login?.userInfo?.sccPermission
  );
  // Initialize and set the _permissions array based on the sccPermission value
  const _permissions = sccPermission ? { SCC: true } : { SCC: false };
  const [data, setData] = useState();

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState();
  const [hasNoPermission, setHasNoPermission] = useState(false);
  const [filters, setFilters] = useState({
    year: "",
    grade: "",
    month: "",
    campus: "",
    term: "",
  });

  useEffect(() => {
    if (_permissions) {
      let hasPermission = ["SCC"].find((cases) => _permissions[cases] == true);
      hasPermission ? setActiveTab(hasPermission) : setHasNoPermission(true);
    }
  }, [_permissions]);

  useEffect(() => {
    fetchSccCasesStageStats();
  }, [filters]);

  const fetchSccCasesStageStats = async () => {
    if (_permissions) {
      const currentYear = new Date().getFullYear();
      const formattedMonthYear = `${filters.month}-${currentYear}`;
      setLoading(true);
      let permissionArray = Object.entries(_permissions);
      if (permissionArray.length > 0) {
        permissionArray = permissionArray.filter(([_, value]) => value);
        setHasNoPermission(permissionArray.length === 0);
        const responses = permissionArray
          .slice(0, 1)
          .map(async ([type, value]) => {
            try {
              let response = await getSccConductCasesDashboardStats({
                campus: filters.campus,
                grade: filters.grade,
                year: filters.year,
                term: filters.term,
                month: formattedMonthYear,
              });

              setLoading(false);
              if (response.status === 200) {
                let data = {
                  type: type,
                  path: getPath(type),
                  title: getTitle(type.toLowerCase()),
                  stats: response.data.data.casesStatistics,
                  stages: response.data.data.stagesStates,
                };
                return data;
              }
            } catch (e) {
              setLoading(false);
              requestErrorHandel({ error: e });
            }
          });
        Promise.all(responses).then((values) => {
          if (!values.length) {
            setLoading(false);
            return;
          }

          setData(values);
        });
      }
    } else {
      setHasNoPermission(true);
      setLoading(false);
    }
  };

  const filterCasesByCaseStatus = (caseStatus, type, path) => {
    // localStorage.setItem(LOCAL_STORAGE_KEYS.DASHBOARD_TAB, type);

    props.history.push(path, {
      serviceType: type,
      dashboardCampus: filters.campus,
      dashboardTerm: filters.term,
      dashboardCaseStatus: caseStatus,
      fromDashboard: true,
    });
  };
  const filterDataByStageStatus = (
    slug,
    dashboardGroupStatus,
    code,
    type,
    path
  ) => {
    // localStorage.setItem(LOCAL_STORAGE_KEYS.DASHBOARD_TAB, type);
    props.history.push(path, {
      serviceType: type,
      dashboardCampus: filters.campus,
      dashboardTerm: filters.term,
      dashboardYear: filters.year,
      dashboardCaseStage: slug,
      dashboardCaseStatus: "",
      dashboardGroupStatus: "DASHBOARD:" + dashboardGroupStatus,
      fromDashboard: true,
    });
  };
  const getPath = (type) => {
    if (!type) {
      return "";
    }
    if (type === "SCC") {
      return `/admin/scc-behaviour-offense-management`;
    }
  };

  const getTitle = (type) => {
    return "";
  };
  const renderStats = (dataItem) => {
    return dataItem ? (
      <div>
        {dataItem.stats && (
          <DashboardApplicationStatuses
            type={dataItem.type}
            applicationStatics={dataItem.stats}
            filterApplicationsByApplicationStatus={(code) =>
              filterCasesByCaseStatus(code, dataItem.type, dataItem.path)
            }
          />
        )}
        <div style={{ padding: "1rem 0" }}></div>
        <Row>
          {dataItem.stages?.map((item, i) => {
            return (
              <Col sm={12} md={4} lg={4} key={i}>
                <DashboardStageStatus
                  key={i}
                  item={item}
                  onClick={(slug, groupStatus, code) => {
                    filterDataByStageStatus(
                      slug,
                      groupStatus,
                      code,
                      dataItem.type,
                      dataItem.path
                    );
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    ) : null;
  };

  return (
    <div className="both-side-padding">
      <Spin spinning={loading} indicator={renderLoader}>
        <div className="dashboardApplicationFilterWrapper">
          <h5>Dashboard</h5>

          <div className="scc_dashboard_filters" id="pdf-filters">
            <div className="dropdown" style={{ width: "150px", marginLeft: 5 }}>
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter By Year"}
                bordered={false}
                onChange={(e) => setFilters({ ...filters, year: e })}
                value={filters?.year || undefined}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_years?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="dropdown" style={{ width: "150px", marginLeft: 5 }}>
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter By Term"}
                bordered={false}
                onChange={(e) => setFilters({ ...filters, term: e })}
                value={filters?.term || undefined}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_terms?.map((el, i) => (
                  <Option key={i} value={el.value}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="dropdown" style={{ width: "150px", marginLeft: 5 }}>
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter By Month"}
                bordered={false}
                onChange={(e) => setFilters({ ...filters, month: e })}
                value={filters?.month || undefined}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_months?.map((el, i) => (
                  <Option key={i} value={el.value}>
                    {el.title}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="dropdown" style={{ width: "150px", marginLeft: 5 }}>
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter By Campus"}
                bordered={false}
                onChange={(e) => setFilters({ ...filters, campus: e })}
                value={filters?.campus || undefined}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_campuses?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="dropdown" style={{ width: "150px", marginLeft: 5 }}>
              <Select
                style={{ width: "100%" }}
                placeholder={"Filter By Grade"}
                bordered={false}
                onChange={(e) => setFilters({ ...filters, grade: e })}
                value={filters?.grade || undefined}
              >
                <Option key={"#"} value={""}>
                  All
                </Option>
                {_grades?.map((el, i) => (
                  <Option key={i} value={el.id}>
                    {el.code}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="tab-card-container">
          {hasNoPermission ? (
            renderNoDataPlaceholder(
              loading,
              <div>
                <h4 style={{ textAlign: "center", opacity: 0.8 }}>
                  This Page Has Restricted Access
                </h4>
                <span>
                  You may not have the required permissions to view this page.
                  Please contact your system admin
                </span>
              </div>,
              <FrownOutlined style={{ fontSize: 30, opacity: 0.8 }} />
            )
          ) : (
            <>
              {data &&
                data?.map((dataItem, index) =>
                  _permissions && _permissions[dataItem?.type] ? (
                    <Card
                      key={index}
                      className="antCardBorder"
                      style={{ backgroundColor: "#EDEDED" }}
                    >
                      {renderStats(dataItem)}
                    </Card>
                  ) : null
                )}
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default SccDashboard;
