import { Button, Form, Input, Select, Row, Col } from "antd";
import React from "react";

const DiscountTypeForm = ({ onSubmit, loading, formName }) => {
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  return (
    <div>
      <Form layout="vertical" form={formName} onFinish={onSubmit}>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Title (EN)"}
              name={"title"}
              rules={setRules("Title (EN)")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Title (AR)"}
              name={"titleAr"}
              rules={setRules("Title (AR)")}
            >
              <Input placeholder="" type="text" dir="rtl" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <Form.Item
              label={"Posting Account"}
              name={"postingAccount"}
              rules={setRules("Posting Account")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={"Rule Id"}
              name={"ruleId"}
              rules={setRules("Rule Id")}
            >
              <Input placeholder="" type="text" />
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Row justify="end">
          <Button
            htmlType="submit"
            loading={loading}
            className="ant__primary__button"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default DiscountTypeForm;
