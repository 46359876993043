import React from "react";
import { Table } from "antd";
import TitleWithLine from "../title-with-line/TitleWithLine";

const SchoolTypeCaseTable = ({ data, title }) => {
  const schoolsCases = data?.schoolsCases || {};

  // Define a mapping for school types
  const schoolTypeMapping = {
    PS: "Primary School",
    BS: "Boys School",
    GS: "Girls School",
  };

  // Extract unique months from the data
  const months = [
    ...new Set(
      Object.values(schoolsCases)
        .flat()
        .map((entry) => entry.month)
    ),
  ];

  // Prepare data for the table
  const tableData = Object.keys(schoolsCases).map((schoolType) => {
    const rowData = { school: schoolTypeMapping[schoolType] }; // Display full name

    months.forEach((month) => {
      const monthData = schoolsCases[schoolType].find(
        (entry) => entry.month === month
      );
      rowData[month] = monthData ? monthData.cases : 0;
    });

    return rowData;
  });

  // Add the totals row to the table data
  // Optionally, you can add a totals row if required

  // Define columns for the Ant Design Table
  const columns = [
    {
      title: "School",
      dataIndex: "school",
      key: "school",
    },
    ...months.map((month) => ({
      title: month,
      dataIndex: month,
      key: month,
    })),
  ];

  // Inline styles
  const headerStyle = {
    padding: "1px 0",
    backgroundColor: "#f5f5f5",
    boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
    borderRadius: "7px 7px 0 0", // Round the top corners
  };
  const tableContainerStyle = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow to table
    borderRadius: "0 0 7px 7px", // Round the bottom corners
    overflow: "hidden", // Prevent overflow of shadow,
  };

  const hasData = tableData.some((row) =>
    Object.values(row).some((value) => value > 0)
  );

  return (
    <div style={tableContainerStyle}>
      {/* Table Header */}
      <div style={headerStyle}>
        {/* <h2 style={titleStyle}>Negative Behaviour Cases Report</h2> */}
        <TitleWithLine title={title} />
      </div>
      {/* Table Component */}
      {hasData ? (
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false} // Disable pagination for simplicity
          bordered
          size="small"
          rowKey="school"
        />
      ) : (
        <Table
          columns={[]}
          dataSource={[]}
          pagination={false} // Disable pagination for simplicity
          bordered
          size="small"
          rowKey="school"
        />
      )}
    </div>
  );
};

export default SchoolTypeCaseTable;
