import client from "apis/config";

export const fetchAllSubCategories = async ({ limit = "10", page, type }) => {
  return await client.get(
    `/categories?limit=${limit}&page=${page}&type=${type}`
  );
};

export const addNewSubCategory = async ({ data }) => {
  return await client.post("/categories", data);
};

export const updateSubCategory = async ({ id, data }) => {
  return await client.put(`/categories/${id}`, data);
};

export const deleteSubCategory = async ({ id }) => {
  return await client.delete(`/categories/${id}`);
};
